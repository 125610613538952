import {
    CLIENTS_INIT_LOADING,
    CLIENTS_LOADING,
    CLIENTS_GET,
    CLIENTS_FILTER,
    CLIENTS_FILTER_SUB,
    CLIENTS_SORT,
    CLIENTS_SOCKET,
    CLIENTS_SOCKET_DELETE
} from "../actions/types";
import _ from 'lodash';

const initialState = {
    clients: [],
    filtered_clients: [],
    subscribe: [],
    filter: {
        client: '',
        status: '',
        _id: '',
        city: '',
        state: '',
        country: '',
        agent: '',
    },
    loading: false,
    init_loading: false,
    sort: {
        cat: 'client',
        dir: 'asc'
    }
};

export default function (state = initialState, action) {
    let filtered_data = [...state.filtered_clients];
    switch (action.type) {
        case CLIENTS_INIT_LOADING:
            return {
                ...state,
                init_loading: true
            };
        case CLIENTS_LOADING:
            return {
                ...state,
                loading: true
            };
        case CLIENTS_GET:
            var sortedClients = _.orderBy(action.payload, ['client'], ['asc']);
            return {
                ...state,
                clients: sortedClients,
                filtered_clients: sortedClients,
                subscribe: sortedClients,
                init_loading: false,
                loading: false
            };
        case CLIENTS_FILTER:
            var client_filter = action.payload;
            var client_data = state.clients;
            if (client_filter.client) {
                client_data = client_data.filter((item) => {
                    var filterBatch = item.client
                    return filterBatch.indexOf(client_filter.client) >= 0
                })
            }
            if (client_filter.status) {
                client_data = client_data.filter((item) => {
                    var filterBatch = item.status
                    if (item.status) {
                        return filterBatch.indexOf(client_filter.status) >= 0
                    }
                })
            }
            if (client_filter._id) {
                client_data = client_data.filter((item) => {
                    var filterBatch = item._id
                    return filterBatch.indexOf(client_filter._id) >= 0
                })
            }
            if (client_filter.city) {
                client_data = client_data.filter((item) => {
                    var filterBatch = item.address.city
                    return filterBatch.indexOf(client_filter.city) >= 0
                })
            }
            if (client_filter.state) {
                client_data = client_data.filter((item) => {
                    var filterBatch = item.address.state
                    return filterBatch.indexOf(client_filter.state) >= 0
                })
            }
            if (client_filter.country) {
                client_data = client_data.filter((item) => {
                    var filterBatch = item.address.country
                    return filterBatch.indexOf(client_filter.country) >= 0
                })
            }
            if (client_filter.agent) {
                client_data = client_data.filter((item) => {
                    var filterBatch = item.agent.map((a, b) => a._id)
                    return filterBatch.indexOf(client_filter.agent) >= 0
                })
            }
            var sort = state.sort;
            client_data = _.orderBy(client_data, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            return {
                ...state,
                filtered_clients: client_data,
                filter: client_filter,
                loading: false
            };
        case CLIENTS_FILTER_SUB:
            var agents = action.payload;
            var client_data = state.clients;
            var clients = [];
            agents.forEach(a => {
                var newArray = [];
                newArray = client_data.filter((item) => {
                    var filterBatch = item.agent.map((a, b) => a._id)
                    if (a) {
                        return filterBatch.indexOf(a) >= 0
                    } else {
                        return filterBatch
                    }
                })
                clients = [...clients, ...newArray];
            })

            var sort = state.sort;
            clients = _.orderBy(clients, [`client`.toLowerCase()], ['asc']);
            return {
                ...state,
                subscribe: clients,
                loading: false
            };
        case CLIENTS_SORT:
            var sort = action.payload;
            var sortedClients = _.orderBy(state.filtered_clients, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            return {
                ...state,
                filtered_clients: sortedClients,
                sort: sort,
                loading: false
            };
        // case ADD_CLIENT:
        //     return {
        //         ...state,
        //         //clients: [action.payload, ...state.clients],
        //     };
        // case SADD_CLIENT:
        //     var client_filter = state.filter;
        //     var newArray = [...state.clients, action.payload];
        //     var client_data = newArray.filter((item) => {
        //         var filterBatch = item.client
        //         return filterBatch.indexOf(client_filter.client) >= 0
        //     })
        //     client_data = client_data.filter((item) => {
        //         var filterBatch = item.address.city
        //         return filterBatch.indexOf(client_filter.city) >= 0
        //     })
        //     client_data = client_data.filter((item) => {
        //         var filterBatch = item.address.state
        //         return filterBatch.indexOf(client_filter.state) >= 0
        //     })
        //     client_data = client_data.filter((item) => {
        //         var filterBatch = item.address.country
        //         return filterBatch.indexOf(client_filter.country) >= 0
        //     })
        //     client_data = _.orderBy(client_data, [`${state.sort.cat}`.toLowerCase()], [state.sort.dir]);
        //     return {
        //         ...state,
        //         clients: newArray,
        //         filtered_clients: client_data,
        //     };
        // case UPDATE_CLIENT:
        //     return {
        //         ...state,
        //         //clients: data
        //     }
        // case UPDATE_CLIENT_SOCKET:
        //     let data = [...state.clients]
        //     var index = data.findIndex(obj => obj._id === action.payload._id)
        //     data[index] = action.payload

        //     const found = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //     if (found >= 0) {
        //         var filtered_index = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //         filtered_data[filtered_index] = action.payload;
        //     }

        //     return {
        //         ...state,
        //         clients: data,
        //         filtered_clients: filtered_data
        //     }
        case CLIENTS_SOCKET:
            var data = state.clients;
            var f_data = state.filtered_clients;
            var index = state.clients.findIndex(obj => obj._id === action.payload._id);
            var f_index = state.filtered_clients.findIndex(obj => obj._id === action.payload._id);

            if (index >= 0) {
                data[index] = action.payload;
                if (f_index >= 0) {
                    f_data[f_index] = action.payload;
                }
            } else {
                data = [action.payload, ...state.clients];
                var client_filter = state.filter;
                f_data = data;
                if (client_filter.client) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.client
                        return filterBatch.indexOf(client_filter.client) >= 0
                    })
                }
                if (client_filter.city) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.city
                        return filterBatch.indexOf(client_filter.city) >= 0
                    })
                }
                if (client_filter.state) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.state
                        return filterBatch.indexOf(client_filter.state) >= 0
                    })
                }
                if (client_filter.country) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.country
                        return filterBatch.indexOf(client_filter.country) >= 0
                    })
                }
                f_data = _.orderBy(f_data, [`${state.sort.cat}`.toLowerCase()], [state.sort.dir]);
            }
            return {
                ...state,
                clients: data,
                filtered_clients: f_data
            }
        // case DELETE_CLIENT:
        //     return {
        //         ...state,
        //         //clients: state.clients.filter(client => client._id !== action.payload),
        //     };
        case CLIENTS_SOCKET_DELETE:
            return {
                ...state,
                clients: state.clients.filter(client => client._id !== action.payload._id),
                filtered_clients: state.filtered_clients.filter(client => client._id !== action.payload._id),
            };
        default:
            return state;
    }
}