import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    ACC_REC_LOADING,
    GET_ACC_REC,
} from "./types";

export const setAccRecLoading = () => {
    return {
        type: ACC_REC_LOADING
    };
};

export const getAccRec = () => (dispatch, getState) => {
    dispatch(setAccRecLoading());
    axios.get(`${process.env.REACT_APP_API}/accounting/received`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: GET_ACC_REC,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const getAccRecId = (id) => (dispatch, getState) => {
    //dispatch(setAccRecLoading());
    return axios.get(`${process.env.REACT_APP_API}/accounting/received/${id}`, tokenConfig(getState))
        .then(res => {

            return res.data
            // dispatch({
            //     type: GET_ACC_REC,
            //     payload: res.data
            // })
        })
        .catch(err => {
            return err.response.data
            //dispatch(returnErrors(err.response.data, err.response.status))
        });
};

export const getCurrencies = (base) => (dispatch, getState) => {
    //dispatch(setAccRecLoading());
    return axios.get(`${process.env.REACT_APP_API}/accounting/currencies/exchange/${base}`, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //     type: GET_ACC_REC,
            //     payload: res.data
            // })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};