import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { FaRegMinusSquare, FaComments } from 'react-icons/fa';
import { green, pink, purple, blue } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        padding: '4px 8px',
        border: '1px solid',
        borderRadius: 140,
        lineHeight: 1.5,
        backgroundColor: '#0063cc',
        borderColor: '#0063cc',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0069d9',
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0),
    },
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: blue[500],
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
}));

export default function TasksButtonNav(props) {
    const classes = useStyles();

    //<Avatar className={classes.green} onClick={props.toggleSpaceChat}>
    //        <FaComments size="20" color="#fff" />
    //      </Avatar>

    //<BootstrapButton variant="contained" color="primary" className={classes.margin} onClick={props.toggleSpaceChat}>
    //      <FaComments size="20" color="#fff" />
    //    </BootstrapButton>

    return (
        <div>
            <Avatar className={classes.green}>
                <div style={{ fontSize: 18, color: '#fff' }}>
                    <AssignmentIcon />
                </div>
            </Avatar>
        </div>
    );
}