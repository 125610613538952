import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import companyReducer from './companyReducer';
import itemsReducer from './itemsReducer';
import clientsReducer from './ClientsReducer';
import buyersReducer from './buyersReducer';
import contactsReducer from './contactsReducer';
import offersReducer from './offersReducer';
import offerdetailReducer from './offerdetailReducer';
import countsReducer from './countsReducer';
import marketingReducer from './marketingReducer';
import urlReducer from './urlReducer';
import festivalsReducer from './festivalsReducer';
import pdfReducer from './pdfReducer';
import calendarReducer from './calendarReducer';
import holdsReducer from './holdsReducer';
import packagingReducer from './packagingReducer';
import arReducer from './arReducer';
import spotifyReducer from './spotifyReducer';
import socialsReducer from './socialsReducer';
import dashReducer from './dashReducer';
import finalsReducer from './finalsReducer';

export default combineReducers({
    error: errorReducer,
    auth: authReducer,
    company: companyReducer,
    items: itemsReducer,
    clients: clientsReducer,
    buyers: buyersReducer,
    contacts: contactsReducer,
    offers: offersReducer,
    offerdetail: offerdetailReducer,
    counts: countsReducer,
    marketing: marketingReducer,
    urldata: urlReducer,
    festivals: festivalsReducer,
    finals: finalsReducer,
    pdf: pdfReducer,
    calendar: calendarReducer,
    holds: holdsReducer,
    packaging: packagingReducer,
    ar: arReducer,
    dash: dashReducer,
    spotify: spotifyReducer,
    socials: socialsReducer,
})