import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PermissionsOffersAutocomplete from '../autocompletes/permissions-offers-autocomplete';

class ClientPermissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: { admin: false, clients: false, venues: false, buyers: false, offers: false, contacts: false, festivals: false },
            offer_options: { access_all: true, financials: false, counts: false, deal_terms: false },
            offer_edit: false,
            client_edit: false,
            venue_edit: false,
            buyer_edit: false,
            contact_edit: false,

            financial_checkbox: false,
            ticket_count_checkbox: false,
            access_client_attach_checkbox: false,
            agentSelect: [],
            client_permissions: { client_attachments: false, edit_client_basic: false, edit_client_pitch: false, financials: false, offers: [], ticket_counts: false, upload_client_attachments: false },
            clients_rolo: [],
            client_select: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    static propTypes = {
        offers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    componentDidMount() {
        if (this.props.client_access.length > 0) {
            var clients = [];
            this.props.client_access.map(item => {
                var cInd = this.props.clients.clients.findIndex(i => i._id === item._id);
                if (cInd >= 0) {
                    clients.push(this.props.clients.clients[cInd])
                }
            })
            this.setState({
                client_permissions: this.props.client_access[0].permissions,
                client_select: this.props.client_access[0]._id,
                clients_rolo: clients
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    toggleDetailEdit = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    }

    handleAgentChange = (agent) => {
        let check = Object.assign({}, this.state.client_permissions);
        check.offers = agent;
        this.setState({
            client_permissions: check
        });
    }

    handleCheckbox(event) {
        const name = event.target.name;
        let check = Object.assign({}, this.state.client_permissions);
        check[name] = !this.state.client_permissions[name];
        this.props.updatePermissions(check, this.state.client_select);
        this.setState({
            client_permissions: check
        });
    }

    handleClientSelect = (id) => {
        const ind = this.props.client_access.findIndex(item => item._id === id)
        if (ind >= 0) {
            this.setState({
                client_permissions: this.props.client_access[ind].permissions,
                client_select: this.props.client_access[ind]._id,
            })
        }
    }

    render() {

        return (
            <div style={{ display: 'flex', width: '100%', height: 550, overflowY: 'scroll' }}>
                <div style={{ width: '95%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', marginLeft: 60, marginTop: 30, width: '100%' }}>
                        <label style={{ width: '100%', fontSize: 15, color: 'rgb(1, 22, 48)' }}>User Permissions:</label>
                        <span style={{ display: 'flex', flexDirection: 'column', height: 'auto', marginTop: 20, width: '90%', backgroundColor: 'rgb(1, 22, 48)' }} className="form-control"></span>
                    </div>
                    <div style={{ width: '100%', height: 'auto', marginTop: 10, display: 'flex', flexDirection: 'row', marginLeft: 60 }}>
                        <div style={{ width: '25%' }}>
                            <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                                <thead>
                                    <tr>
                                        <th style={{ width: '100%', color: '#fff' }}>Artist</th>
                                    </tr>
                                </thead>
                            </table>
                            <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                                {this.state.clients_rolo.map((item, index) => {
                                    return (
                                        <tbody key={index}>
                                            <tr>
                                                <td style={{ width: '100%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12, backgroundColor: item._id === this.state.client_select ? '#e6f7ff' : '#fff', cursor: 'pointer' }} onClick={() => this.handleClientSelect(item._id)}>{item.client}</td>
                                            </tr>
                                        </tbody>
                                    )
                                })}
                            </table>
                        </div>
                        <div style={{ width: '60%' }}>
                            <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                                <thead>
                                    <tr>
                                        <th style={{ width: '60%', color: '#fff' }}>Description</th>
                                        <th style={{ width: '40%', color: '#fff', fontSize: 12 }}> </th>
                                    </tr>
                                </thead>
                            </table>
                            <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                                <tbody>
                                    <tr>
                                        <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Offers</td>
                                        <td style={{ width: '40%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            {/* <select style={{ width: '100%' }}>
                                            <option value="">All Offers (conf, pend, canc)</option>
                                        </select> */}
                                            <PermissionsOffersAutocomplete onAgentChange={this.handleAgentChange} agentSelect={this.state.client_permissions.offers} edit={true} agentList={['Confirmed', 'Pending', 'Cancelled']} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                                <thead>
                                    <tr>
                                        <th style={{ width: '60%', color: '#fff' }}> </th>
                                        <th style={{ width: '20%', color: '#fff', fontSize: 12 }}>Allow</th>
                                        <th style={{ width: '20%', color: '#fff', fontSize: 12 }}>Don't Allow</th>
                                    </tr>
                                </thead>
                            </table>
                            <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                                <tbody>
                                    <tr>
                                        <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Financials</td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={this.state.client_permissions.financials}
                                                color="default"
                                                value={this.state.client_permissions.financials}
                                                name="financials"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={!this.state.client_permissions.financials}
                                                color="default"
                                                value={!this.state.client_permissions.financials}
                                                name="financials"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Ticket Counts</td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={this.state.client_permissions.ticket_counts}
                                                color="default"
                                                value={this.state.client_permissions.ticket_counts}
                                                name="ticket_counts"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={!this.state.client_permissions.ticket_counts}
                                                color="default"
                                                value={!this.state.client_permissions.ticket_counts}
                                                name="ticket_counts"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Access Client Attachments</td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={this.state.client_permissions.client_attachments}
                                                color="default"
                                                value={this.state.client_permissions.client_attachments}
                                                name="client_attachments"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={!this.state.client_permissions.client_attachments}
                                                color="default"
                                                value={!this.state.client_permissions.client_attachments}
                                                name="client_attachments"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Upload Client Attachments</td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={this.state.client_permissions.upload_client_attachments}
                                                color="default"
                                                value={this.state.client_permissions.upload_client_attachments}
                                                name="upload_client_attachments"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={!this.state.client_permissions.upload_client_attachments}
                                                color="default"
                                                value={!this.state.client_permissions.upload_client_attachments}
                                                name="upload_client_attachments"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Edit Client Basic Info (address, links)</td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={this.state.client_permissions.edit_client_basic}
                                                color="default"
                                                value={this.state.client_permissions.edit_client_basic}
                                                name="edit_client_basic"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={!this.state.client_permissions.edit_client_basic}
                                                color="default"
                                                value={!this.state.client_permissions.edit_client_basic}
                                                name="edit_client_basic"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Edit Client Pitch</td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={this.state.client_permissions.edit_client_pitch}
                                                color="default"
                                                value={this.state.client_permissions.edit_client_pitch}
                                                name="edit_client_pitch"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                        <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                checked={!this.state.client_permissions.edit_client_pitch}
                                                color="default"
                                                value={!this.state.client_permissions.edit_client_pitch}
                                                name="edit_client_pitch"
                                                disabled={!this.props.edit}
                                                onChange={this.handleCheckbox}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


const mapStateToProps = state => ({
    offers: state.offers,
    clients: state.clients,
});

export default connect(mapStateToProps, null)(ClientPermissions);