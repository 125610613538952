import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import { updateStatus } from '../actions/database/globalActions';
import { FaExternalLinkSquareAlt, FaCaretUp, FaCaretDown, FaRedoAlt, FaMinusSquare } from 'react-icons/fa';
import { ContextMenu, MenuItem, ContextMenuTrigger, SubMenu } from "react-contextmenu";
import StatusDialog from '../components/contacts/status.dialog';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Menu, Item, Separator, Submenu, MenuProvider, theme, animation } from 'react-contexify';
import CustomChip from '../components/customStatus';

import "react-datepicker/dist/react-datepicker.css";

class FestivalRolodex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            artist: '',
            agent: '',
            startDate: '',
            endDate: '',
            venues: [],
            vvenueid: '',
            vvenuename: '',
            vaddress: '',
            vaddress2: '',
            vcity: '',
            vstate: '',
            vcountry: '',
            vzipcode: '',
            vcapacity: '',
            vwebsite: '',
            vphonenumber: '',
            vfaxnumber: '',
            vcreatedat: '',
            vupdatedat: '',
            viewvenue: false,
            sortdirection: 'asc',
            sortcat: 'client',
            statusdialog: false,
            statusrenderid: '',
            statusrender: '',
            statustitle: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.myRef = React.createRef();
        this.handleStatusDialog = this.handleStatusDialog.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        clients: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        festivals: PropTypes.object.isRequired,
    }

    handleStatusDialog = (item) => {
        if (item) {
            var festival = item.festival;
            this.setState({
                statusrenderid: item._id,
                statusrender: item.status,
                statustitle: festival,
                statusdialog: !this.state.statusdialog
            })
        }
    }

    closeStatusDialog = () => {
        this.setState({
            statusrenderid: '',
            statusrender: '',
            statustitle: '',
            statusdialog: !this.state.statusdialog
        })
    }

    saveStatus = async () => {
        const status = {
            status: this.state.statusrender
        }
        const type = "festivals";
        const renderid = this.state.statusrenderid;
        await this.props.updateStatus(type, status, renderid)
        this.setState({
            statustitle: '',
            statusrender: '',
            statusrenderid: '',
            statusdialog: !this.state.statusdialog
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleDatabaseCheckbox = (n, v) => {
        const e = {
            target: {
                name: n,
                value: v
            }
        }
        this.props.handleDatabaseCheckbox(e)
    }

    normCellRender = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center' }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {cellData.cell}
                </div>
            </MenuProvider>
        )
    }

    rowSelect(id) {
        if (!id) return;
        this.handleDatabaseCheckbox('festival_checked', id);
    }

    renderColumnData = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={this.props.festival_checked.includes(cellData._id) || false}
                        color="default"
                        value={cellData._id}
                        name="festival_checked"
                        onChange={this.props.handleDatabaseCheckbox}
                    />
                    {/* <Chip
                    size="small"
                    style={{ opacity: .8 }}
                    color={"primary"}
                    clickable
                    label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
                    onClick={this.props.iconRenderFestival.bind(this, cellData)}
                /> */}
                    <span style={{ width: 25, height: '100%' }} onClick={this.props.iconRenderFestival.bind(this, cellData)}>
                        <FaExternalLinkSquareAlt size="16" className="dashboard-offer-icons-exp" />
                    </span>
                    {/* <span style={{ width: 'auto', marginLeft: 10 }}>
                    <Chip
                        size="small"
                        variant="outlined"
                        color={cellData.status === "Inactive" ? "secondary" : "primary"}
                        clickable
                        label={cellData.status.charAt(0)}
                        onClick={() => this.handleStatusDialog(cellData)}
                    />
                </span> */}
                </div>
            </MenuProvider>
        )
    }

    startDateColumnData = ({ cellData, dataKey }) => {
        //console.log('REN', cellData)
        var sd = [];
        // cellData.renDates.map((da, j) => {
        //     const dates = da;
        //     var datea = [];
        //     dates.forEach(function (d) {
        //         var datearr = [];
        //         datearr.push(new Date(d.date), ...datea)
        //         datea = datearr;
        //     });
        //     var minDate = new Date(Math.min.apply(null, datea));
        //     sd = minDate;
        // })

        var datea = [];
        console.log(cellData)
        cellData.renDates.forEach(function (d) {
            var datearr = [];
            datearr.push(new Date(d.date), ...datea)
            datea = datearr;
        })
        var minDate = new Date(Math.min.apply(null, datea));
        sd = minDate;

        var newSd = new Date(sd);
        var startdate = `${(newSd.getMonth() + 1)}/${newSd.getDate()}/${newSd.getFullYear()}`;

        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                {startdate}
            </MenuProvider>
        )
    }

    endDateColumnData = ({ cellData, dataKey }) => {
        var ed = [];
        // cellData.dates.map((da, j) => {
        //     const dates = da.dates;
        //     var datea = [];
        //     dates.forEach(function (d) {
        //         var datearr = [];
        //         datearr.push(new Date(d.date), ...datea)
        //         datea = datearr;
        //     });
        //     var maxDate = new Date(Math.max.apply(null, datea));
        //     ed = maxDate;
        // })

        var datea = [];
        cellData.renDates.forEach(function (d) {
            var datearr = [];
            datearr.push(new Date(d.date), ...datea)
            datea = datearr;
        })
        var maxDate = new Date(Math.max.apply(null, datea));
        ed = maxDate;

        var newEd = new Date(ed);
        var enddate = `${(newEd.getMonth() + 1)}/${newEd.getDate()}/${newEd.getFullYear()}`;
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                {enddate}
            </MenuProvider>
        )
    }

    rowStyleFormat(row) {
        if (row.index < 0) return;
        const list = this.props.festivals.filtered_festivals;
        if (this.props.festival_checked.includes(list[row.index]._id)) {
            return {
                backgroundColor: '#e6f7ff'
            };
        }
        return {
            backgroundColor: null,
        };
    }

    // rowSelect(row) {
    //     if (row.index < 0) return;
    //     const list = this.props.festivals.filtered_festivals;
    //     this.handleDatabaseCheckbox('festival_checked', list[row.index]._id);
    // }

    rowDoubleClick(row) {
        if (row.index < 0) return;
        this.props.iconRenderFestival(row.rowData)
    }

    headerRowRenderer = ({ className, columns, style }) => {
        return (
            <div
                className={className}
                role='row'
                style={style}
            >
                {columns}
            </div>
        )
    }

    headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        if (this.props.festival_sort.sort_cat === dataKey) {
            return (
                <div style={{ width: '100%' }} onClick={() => this.props.toggleSortFestivals(dataKey)}><div style={{ display: 'flex', width: '100%' }}><div style={{ width: dataKey === 'festival' ? '90%' : '85%' }}>{label}</div><div style={{ width: '10%' }}>{this.props.festival_sort.sort_direction === 'asc' ? <FaCaretUp size="15" color="#cdcdcd" /> : <FaCaretDown size="15" color="#cdcdcd" />}</div></div></div>
            )
        } else {
            return (
                <div onClick={() => this.props.toggleSortCatFestivals(dataKey)}>{label}</div>
            )
        }
    }

    headerStartRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                <div style={{ width: '60%' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        color="default"
                        checked={this.props.festival_all_checked}
                        //value={props.checked}
                        name="festival_checked"
                        onChange={this.props.handleDatabaseAllCheckbox}
                    />
                </div>
                <div style={{ width: '40%', height: 30 }}>
                    {this.props.festival_sort.sort_cat ?
                        <FaRedoAlt style={{ marginTop: 9 }} size="12" color="#cdcdcd" onClick={() => this.props.clearSorted('festival_sort')} /> :
                        this.props.festival_checked.length > 0 ?
                            <FaMinusSquare style={{ marginTop: 6 }} size="18" color="#cdcdcd" onClick={() => this.props.handleDatabaseClearCheckbox('festival_checked')} />
                            : null}
                </div>
            </div>
        )
    }

    statusColumnData = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                    {/* <Chip
                        size="small"
                        variant="outlined"
                        color={cellData.status === "Inactive" ? "secondary" : "primary"}
                        clickable
                        label={cellData.status}
                        onClick={() => this.handleStatusDialog(cellData)}
                    /> */}
                    <CustomChip
                        size={.65}
                        // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                        color={cellData.status === "Inactive" ? "secondary" : "primary"}
                        label={cellData.status}
                        onClick={() => this.handleStatusDialog(cellData)}
                    />
                </div>
            </MenuProvider>
        )
    }

    render() {

        const MyAwesomeMenu = () => (
            <Menu id='menu_id' theme={theme.dark} animation={animation.fade}>
                <Item onClick={this.props.toggleRenderOffer}>View / Edit</Item>
                <Item onClick={() => this.props.deleteDialog('festival_checked', 'festival')}>Delete Festival</Item>
            </Menu>
        );

        // {this.props.festivals.filtered_festivals.map((item, index) => {
        //     var sd = [];
        //     var ed = [];
        //     item.dates.map((da, j) => {
        //         const dates = da.dates;
        //         var datea = [];
        //         dates.forEach(function (d) {

        //             var datearr = [];
        //             datearr.push(new Date(d.date), ...datea)
        //             datea = datearr;

        //         });
        //         var maxDate = new Date(Math.max.apply(null, datea));
        //         var minDate = new Date(Math.min.apply(null, datea));
        //         console.log(maxDate, minDate)
        //         sd = minDate;
        //         ed = maxDate;
        //     })

        //     var newSd = new Date(sd);
        //     var startdate = `${(newSd.getMonth() + 1)}/${newSd.getDate()}/${newSd.getFullYear()}`;

        //     var newEd = new Date(ed);
        //     var enddate = `${(newEd.getMonth() + 1)}/${newEd.getDate()}/${newEd.getFullYear()}`;

        //     return (
        //         <ContextMenuTrigger key={index} id={'SIMPLE'} >
        //             <table id="customers">
        //                 <tbody>
        //                     <tr style={{ backgroundColor: index % 2 === 0 ? "#F5F5F5" : "#fff" }}>
        //                         <td style={{ width: 50, backgroundColor: this.props.festival_checked.includes(item._id) === true ? '#e6f7ff' : null }}>
        //                             <div style={{ display: 'flex', width: 40, alignItems: 'center' }}>
        //                                 <Checkbox
        //                                     icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //                                     checkedIcon={<CheckBoxIcon fontSize="small" />}
        //                                     color="default"
        //                                     checked={this.props.festival_checked.includes(item._id) || false}
        //                                     value={item._id}
        //                                     name="festival_checked"
        //                                     onChange={this.props.handleDatabaseCheckbox}
        //                                 />
        //                                 <Chip
        //                                     size="small"
        //                                     style={{ opacity: .8 }}
        //                                     color={"primary"}
        //                                     clickable
        //                                     label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
        //                                     onClick={this.props.iconRenderFestival.bind(this, item)}
        //                                 />
        //                             </div>
        //                         </td>
        //                         <td style={{ width: 140, fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.festival_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('festival_checked', item._id)}>{item.festival}</td>
        //                         <td style={{ width: 80, fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.festival_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('festival_checked', item._id)}>{startdate}</td>
        //                         <td style={{ width: 80, fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.festival_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('festival_checked', item._id)}>{enddate}</td>
        //                         <td style={{ width: 130, fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.festival_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('festival_checked', item._id)}>{item.address.city}</td>
        //                         <td style={{ width: 130, fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.festival_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('festival_checked', item._id)}>{item.address.state}</td>
        //                         <td style={{ width: 130, fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.festival_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('festival_checked', item._id)}>{item.address.country}</td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //         </ContextMenuTrigger>
        //     )
        // })}

        // var festivals = this.props.festivals.filtered_festivals;
        // var sortedFestivals = _.orderBy(festivals, [`${this.props.festival_sort.sort_cat}`.toLowerCase()], [this.props.festival_sort.sort_direction]);


        //<Chip
        //    size="small"
        //    color={"default"}
        //    clickable
        //    label={<FaExpand size="10" color="#000" />}
        //    onClick={this.handleStatusDialog.bind(this, item)}
        ///>

        //item.status.substring(0, 1).toUpperCase()
        const list = this.props.festivals.filtered_festivals;

        if (this.props.festivals.loading) return <div>loading...</div>;
        else {
            return (
                <div style={{ width: '100%' }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={height}
                                headerHeight={30}
                                rowHeight={30}
                                overscanRowCount={10}
                                headerRowRenderer={this.headerRowRenderer.bind(this)}
                                rowCount={list.length}
                                rowGetter={({ index }) => list[index]}
                                rowStyle={this.rowStyleFormat.bind(this)}
                                //onRowClick={this.rowSelect.bind(this)}
                                onRowDoubleClick={this.rowDoubleClick.bind(this)}
                            >
                                <Column
                                    label=''
                                    width={75}
                                    dataKey='_id'
                                    headerRenderer={this.headerStartRenderer}
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.renderColumnData
                                    }
                                />
                                <Column
                                    label='Status'
                                    width={80}
                                    dataKey='status'
                                    headerRenderer={this.headerRenderer}
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.statusColumnData
                                    }
                                />
                                <Column
                                    width={200}
                                    label='Festival'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='festival'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.festival, id: rowData._id, width: 200 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={150}
                                    label='Start Date'
                                    dataKey='start_date'
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.startDateColumnData
                                    }
                                />
                                <Column
                                    width={150}
                                    label='End Date'
                                    dataKey='end_date'
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.endDateColumnData
                                    }
                                />
                                <Column
                                    width={150}
                                    label='City'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='address.city'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.city, id: rowData._id, width: 150 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={150}
                                    label='State | Prov'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='address.state'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.state, id: rowData._id, width: 150 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={150}
                                    label='Country'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='address.country'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.country, id: rowData._id, width: 150 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                            </Table>
                        )}
                    </AutoSizer>
                    <MyAwesomeMenu />
                    <StatusDialog open={this.state.statusdialog} handleClose={this.closeStatusDialog} handleChange={this.handleChange} saveStatus={this.saveStatus} {...this.state} />
                </div>
            )
        }
    }
}

function ObtenerMaxMinRangoFechas(DatosFechas) {
    let moments = DatosFechas.map(x => moment(x)),
        maxDate = moment.max(moments)
    return maxDate.format('YYYY-MM-DD')
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    festivals: state.festivals,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { updateStatus })(FestivalRolodex);