import axios from "axios";
import { tokenConfig, tokenConfigFile } from './authActions';
import { returnErrors } from './errorActions';
import {
    FINALS_LOADING,
    FINALS_LOAD,
    FINALS_FILTER,
    FINALS_SORT,
    FINALS_SOCKET,
    FINALS_SOCKET_DELETE
} from "./types";
import _ from 'lodash';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';

export const setFinalsLoading = () => {
    return {
        type: FINALS_LOADING
    };
};

export const finalsCompose = (finals) => async (dispatch, getState) => {
    dispatch(setFinalsLoading());
    const { offers } = getState();
    var filterOffers = offers.offers;
    arrayBuild(filterOffers, finals).then(arr => {
        filterFinalOffers(arr).then(data => {
            const fullArr = {
                all: arr,
                rol: data
            }
            dispatch({
                type: FINALS_LOAD,
                payload: fullArr
            })
        });
    });
}

export const applyFinalsFilter = (finals_filter) => (dispatch, getState) => {
    dispatch(setFinalsLoading());
    dispatch({
        type: FINALS_FILTER,
        payload: finals_filter
    })
};

export const finalsSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: FINALS_SORT,
        payload: sort
    })
};

export const finalsSocket = offer => (dispatch, getState) => {
    const { items } = getState();
    const { buyers } = getState();
    const { clients } = getState();
    const { festivals } = getState();
    const venues = items;

    var artist_name = "";
    var venue_name = "";
    var venue_address = {};
    var venue_city = "";
    var venue_state = "";
    var venue_country = "";
    var buyer_company = "";
    var buyer_address = {};

    clients.clients.map((c, i) => {
        if (offer.client_id === c._id) {
            artist_name = c.client
        }
    })
    venues.items.map((v, i) => {
        if (offer.venue_id === v._id) {
            venue_name = v.venue_name;
            venue_address = v.address;
            venue_city = v.address.city;
            venue_state = v.address.state;
            venue_country = v.address.country;
        }
    })
    festivals.items.map((f, i) => {
        if (offer.venue_id === f._id) {
            venue_name = f.festival;
            venue_address = f.address;
            venue_city = f.address.city;
            venue_state = f.address.state;
            venue_country = f.address.country;
        }
    })
    buyers.buyers.map((b, i) => {
        if (offer.buyer_id === b._id) {
            buyer_company = b.company;
            buyer_address = b.address;
        }
    })

    const newOffer = {
        ...offer,
        artist_name: artist_name,
        venue_name: venue_name,
        venue_address: venue_address,
        venue_city: venue_city,
        venue_state: venue_state,
        venue_country: venue_country,
        buyer_company: buyer_company,
        buyer_address: buyer_address
    }

    var final = { tickets_sold: null, gross_sales: null, net_sales: null, notes: null, settled: false };
    var settlement = newOffer.settlement;
    var sellable = null;
    const currency_symbol = getSymbolFromCurrency(newOffer.deal_currency);
    var newArr = [];
    newOffer.show_info.forEach(async (show) => {
        newOffer.sellable_cap.map(sel => {
            if (show._id === sel._id) {
                sellable = sel.amt
            }
        })
        newOffer.finals.map(fin => {
            if (fin._id + newOffer._id === show._id + newOffer._id) {
                final = fin
            }
        })
        newArr.push({ ...newOffer, finals: final, settlement: settlement, date: show.date, sellable: sellable, date_id: show._id, currency_symbol: currency_symbol });
    })

    dispatch({
        type: FINALS_SOCKET,
        payload: newArr
    })
}

export const finalsSocketDelete = offer => (dispatch) => {
    dispatch({
        type: FINALS_SOCKET_DELETE,
        payload: offer
    })
}

function arrayBuild(filterOffers, finals) {
    return new Promise(async (resolve, reject) => {
        var newArr = [];
        filterOffers.map(item => {
            const currency_symbol = getSymbolFromCurrency(item.deal_currency);
            item.show_info.forEach((show, ind) => {
                var final = { tickets_sold: null, gross_sales: null, net_sales: null, settled: false };
                var settlement = { deductions: null, overage: null, withholding: null };
                var sellable = null;
                item.sellable_cap.map(sel => {
                    if (show._id === sel._id) {
                        sellable = sel.amt
                    }
                })
                finals.map(fin => {
                    if (item._id === fin._id && fin.settlement) {
                        settlement = fin.settlement;
                    }
                    fin.finals.map(liv => {
                        if (fin._id + liv._id === item._id + show._id) {
                            final = liv
                        }
                    })
                })
                newArr.push({ ...item, finals: final, settlement: settlement, date: show.date, sellable: sellable, date_id: show._id, currency_symbol: currency_symbol })
            })
        })
        resolve(newArr)
    })
}

function filterFinalOffers(offers) {
    return new Promise(async (resolve, reject) => {
        let filtered_offers = [];
        filtered_offers = offers.filter(item => item.status === 'Confirmed')
        filtered_offers = filtered_offers.filter((item) => {
            var filterBatch = new Date(item.date)
            var start = new Date()
            return filterBatch < start
        })
        filtered_offers = _.orderBy(filtered_offers, function (dateObj) {
            return new Date(dateObj.date);
        }, 'desc');
        resolve(filtered_offers)
    })
}