import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { removeContactTag } from '../../actions/contactActions';
import TagsAccordion from './render-tags.accordion';
import BuyerTagsAutocomplete from '../../components/autocompletes/buyer-tags-autocomplete';
import VenueTagsAutocomplete from '../../components/autocompletes/venue-tags-autocomplete';
import { FaPenSquare, FaCheckSquare, FaPlusSquare, FaTimesCircle } from 'react-icons/fa';
import _ from 'lodash';

class ContactTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag1edit: false,
            tag2edit: false,
            tag3edit: false,
            tag4edit: false,
            tag5edit: false,
            tag6edit: false,
            tagarray: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggleTabInfo = this.toggleTabInfo.bind(this);
        this.toggleTabTags = this.toggleTabTags.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    componentDidMount() {
        // var contact_array = [];
        // this.props.contacts.contacts.map(item => {
        //     item.tags.map(tag => {
        //         if (tag.tag === this.props.id) {
        //             contact_array.push({ ...item, role: tag.role })
        //         }
        //     })
        // })
        // contact_array = _.orderBy(contact_array, [`name.last`.toLowerCase()], ['asc']);
        // this.setState({
        //     contactarray: contact_array,
        // })
        this.setState({
            tagarray: this.props.tagarray
        })
        const id = this.props.id;
        const socket = this.props.auth.socket;
        if (socket) {
            socket.on(`new_contact_tags/${id}`, (contact) => {
                //this.props.updateContactSocket(contact);
                console.log(contact)
                
                var tags = [];
                contact.tags.map(t => {
                    let data = [...contact.tags];
                    this.props.buyers.buyers.map(b => {
                        if (b._id === t.tag) {
                            var index = data.findIndex(obj => obj.tag === b._id);
                            data[index] = {
                                name: b.company,
                                tag: t.tag,
                                cat: 'Buyer Rolodex',
                                role: t.role,
                                address: b.address,
                                contact: b.contact
                            }
                            tags.push(data[index]);
                        }
                    })
                    this.props.clients.clients.map(c => {
                        if (c._id === t.tag) {
                            var index2 = data.findIndex(obj => obj.tag === c._id);
                            data[index2] = {
                                name: c.client,
                                tag: t.tag,
                                cat: 'Client Rolodex',
                                role: t.role,
                                address: c.address,
                                agent: c.agent,
                                contact: { main_phone: '-', email: '-', office: { dial: '-', ext: '-' }, cell: { dial: '-', ext: '-' }, fax: '' }
                            };
                            tags.push(data[index2]);
                        }
                    })
                    this.props.items.items.map(v => {
                        if (v._id === t.tag) {
                            var index = data.findIndex(obj => obj.tag === v._id);
                            data[index] = {
                                name: v.venue_name,
                                tag: t.tag,
                                cat: 'Venue Rolodex',
                                role: t.role,
                                address: v.address,
                                contact: v.contact,
                                capacity: v.capacity
                            };
                            tags.push(data[index]);
                        }
                    })
                })

                tags = _.orderBy(tags, [`name`.toLowerCase()], ['asc']);

                // var contact_array = [];
                // contact.tags.map(tag => {
                //     if (tag.tag === this.props.id) {
                //         contact_array.push({ ...contact, role: tag.role })
                //     }
                // })
                // var contact_array = [...this.state.tagarray, contact];
                // console.log(contact_array)
                //contact_array = [...this.state.contactarray, contact_array];
                // contact_array = _.orderBy(contact_array, [`name.last`.toLowerCase()], ['asc']);
                this.setState({
                    tagarray: tags
                })
            })
            socket.on(`updated_contact_tags/${id}`, (contact) => {
                console.log(contact)
                //this.props.updateContactSocket(contact);
                var data = this.state.tagarray.filter(cts => cts._id !== contact._id)
                console.log(data)
                this.setState({
                    tagarray: data
                })
            })
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleClick = () => {
        this.props.toggle();
    };

    toggleTabTags() {
        this.setState({
            render: 'tags'
        })
    }
    toggleTabInfo() {
        this.setState({
            render: 'info'
        })
    }

    toggleTag1 = () => {
        this.setState({
            tag1edit: !this.state.tag1edit
        })
    }
    toggleTag2 = () => {
        this.setState({
            tag2edit: !this.state.tag2edit
        })
    }
    toggleTag3 = () => {
        this.setState({
            tag3edit: !this.state.tag3edit
        })
    }
    toggleTag4 = () => {
        this.setState({
            tag4edit: !this.state.tag4edit
        })
    }
    toggleTag5 = () => {
        this.setState({
            tag5edit: !this.state.tag5edit
        })
    }
    toggleTag6 = () => {
        this.setState({
            tag6edit: !this.state.tag6edit
        })
    }

    updateTag = (props) => {
        this.props.updateTag(props)
    }

    removeTag = async (index) => {
        var tag_id = this.state.tagarray[index].tag

        const tags = {
            tag: tag_id,
            _id: this.props.id,
            send_type: '_id'
        }
        var log_status = await this.props.removeContactTag(tags);
        if (log_status.includes('Error:')) {
            alert(log_status);
        } else {
            //this.props.toggleSub('contacts');
        }
    }

    render() {

        return (
            <div style={{ width: '100%', height: 500 }}>
                <div style={{ width: '100%', height: '100%' }}>
                    <h2 style={{ marginTop: 30, color: '#000', fontSize: 18, marginLeft: 60, height: 15 }}>Tags:</h2>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', overflowY: 'scroll', minHeight: 420, maxHeight: 420 }}>
                        <TagsAccordion tagarray={this.state.tagarray} removeTag={this.removeTag} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
                        <button style={{ width: 160, height: 30, backgroundColor: 'rgb(1, 22, 48)', color: '#fff', marginTop: 10, fontSize: 14, borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }} onClick={() => this.props.toggleSub('new_client')}>Tag a Client</button>
                        <button style={{ width: 160, height: 30, backgroundColor: 'rgb(1, 22, 48)', color: '#fff', marginTop: 10, fontSize: 14, borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }} onClick={() => this.props.toggleSub('new_venue')}>Tag a Venue</button>
                        <button style={{ width: 160, height: 30, backgroundColor: 'rgb(1, 22, 48)', color: '#fff', marginTop: 10, fontSize: 14, borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }} onClick={() => this.props.toggleSub('new_buyer')}>Tag a Buyer</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    items: state.items,
    buyers: state.buyers,
    clients: state.clients,
    auth: state.auth
});

export default connect(mapStateToProps, { removeContactTag })(ContactTags);