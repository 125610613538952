import React, { Component } from 'react'
import BlackLogo from '../../assets/BlackLogo.png';

class DealMemoPDF extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: this.props.data.artist,
        };
    }

    componentDidMount() {
        //this.BuyerOpFormat(this.props.contactdata.officephone)
        //this.VenuePhoneFormat(this.props.venuedata.phone)
    }

    BuyerOpFormat = (text) => {
        let formatedNo = this.formatPhoneNumber(text);
        this.setState({ contactop: formatedNo });
    };

    VenuePhoneFormat = (text) => {
        let formatedNo = this.formatPhoneNumber(text);
        this.setState({ venuephone: formatedNo });
    };

    formatPhoneNumber = (text => {
        var cleaned = ("" + text).replace(/\D/g, "");
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = match[1] ? "+1 " : "",
                number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
                    ""
                );
            return number;
        }
        return text;
    })


    formatDate(date) {

        const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = new Date(date);
        const day = days[d.getDay()];
        const mon = monthNames[d.getMonth()];
        const time = `${day}. ${mon} ${d.getDate()}, ${d.getFullYear()}`;
        return time;
    }

    //onDocumentLoadSuccess({ numPages }) {
    //  this.setNumPages(numPages);
    //}

    render() {

        //const numberFormat = (value) =>
        //    new Intl.NumberFormat().format(value);

        const currencyFormat = (value) =>
            new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            }).format(value);

        //const captotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.capacity); }, 0);
        //const captotalval = parseInt(captotal, 10)

        //const compstotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.comps); }, 0);
        //const compstotalval = parseInt(compstotal, 10)

        //const killstotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.kills); }, 0);
        //const killstotalval = parseInt(killstotal, 10)

        //const grosstotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.gross); }, 0);
        //const grosstotalval = numberFormat(grosstotal)

        //const guarantee = numberFormat(this.state.guarantee)

        //const gross_tickets = captotal - compstotal - killstotal
        //const flat_tax_total = this.state.taxes
        //    .filter(tax => tax.type === "Flat")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        //const percent_tax = this.state.taxes
        //    .filter(tax => tax.type === "Percent")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const percent_tax_total = (percent_tax * .01) * grosstotal

        //const perticket_tax = this.state.taxes
        //    .filter(tax => tax.type === "Per Ticket")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const perticket_tax_total = perticket_tax * gross_tickets

        //const taxtotal = flat_tax_total + percent_tax_total + perticket_tax_total

        //const flat_exp_total = this.state.expenses
        //    .filter(exp => exp.type === "Flat")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        //const percent_exp = this.state.expenses
        //    .filter(exp => exp.type === "Percent")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const percent_exp_total = (percent_exp * .01) * grosstotal

        //const perticket_exp = this.state.expenses
        //    .filter(exp => exp.type === "Per Ticket")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const perticket_exp_total = perticket_exp * gross_tickets

        //const exptotal = flat_exp_total + percent_exp_total + perticket_exp_total;
        var data = this.props.data;

        var walkout = 0;
        var guarantee = Number(data.guarantee);
        var customwalkout = Number(data.custom_walkout);
        //var grosstotalval = Number(this.props.grosstotalval);
        //var exptotal = Number(this.props.exptotal);
        //var taxtotal = Number(this.props.taxtotal);

        const dealstartlang = [];
        const dealendlang = [];
        var dealtype = this.props.data.deal_type;
        if (dealtype === 'Flat') {
            dealstartlang.push("flat guarantee");
            dealendlang.push("");
            if (guarantee === Math.floor(guarantee)) {
                walkout = guarantee
            } else {
                walkout = guarantee.toFixed(2)
            }
        } else if (dealtype === 'Versus Net') {
            dealstartlang.push("guarantee, versus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Versus Gross') {
            dealstartlang.push("guarantee, versus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Plus') {
            dealstartlang.push("guarantee, plus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Promoter Profit') {
            dealstartlang.push("guarantee, plus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax, the approved expenses listed below, and a 15% promoter profit have been deducted.");
        } else if (dealtype === 'Bonus') {
            dealstartlang.push("Plus the following bonuses:");
            dealendlang.push("");
        } else if (dealtype === 'Versus Net (Custom Walkout)') {
            dealstartlang.push("guarantee, versus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Versus Gross (Custom Walkout)') {
            dealstartlang.push("guarantee, versus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Plus (Custom Walkout)') {
            dealstartlang.push("guarantee, plus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Promoter Profit (Custom Walkout)') {
            dealstartlang.push("guarantee, plus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax, the approved expenses listed below, and a 15% promoter profit have been deducted.");
        } else {
            dealstartlang.push("");
            dealendlang.push("");
        };

        var merch = `${data.merch.hard}% Hard<br/>${data.merch.soft}% Soft<br/>${data.merch.seller} sells`

        var venuestate = convertStateToAbbr(data.venue.state);
        console.log(data)

        var state = convertStateToAbbr(data.buyer.address.state);

        return (
            <html>
                <body>
                    <div>
                        <div style={{ width: 500 }}>
                            <img style={{ marginTop: 5, marginLeft: '2px' }} width="60px" height="62px" src={BlackLogo}></img>
                            <div style={{ width: '85%', height: '14px', marginTop: '4px', backgroundColor: '#000', display: 'flex', flexDirection: 'row', borderRadius: '5px' }}>
                                <div style={{ width: '50%' }}>
                                    <h1 style={{ color: '#fff', fontSize: '8px', marginLeft: '5px', paddingTop: '1.5px' }}>{this.props.data.artist_name.toUpperCase()}</h1>
                                </div>
                                <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', paddingTop: '1.5px' }}>
                                    <h1 style={{ color: '#fff', fontSize: '8px', paddingRight: 5 }}>Status:</h1>
                                    <h1 style={{ color: '#fff', fontSize: '8px', paddingRight: 5 }}>Pending</h1>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h1 style={{ color: '#000', fontSize: '5px', paddingLeft: 5 }}>Deal ID:</h1>
                                <h1 style={{ color: '#000', fontSize: '5px', paddingLeft: 5 }}>328402-1874293021</h1>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div style={{ width: '45%', borderRight: '2px solid #ddd' }}>
                                    <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row' }}>
                                        <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Show Date:</h1>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <h1 style={{ color: '#000', fontSize: '8px' }}>
                                                {this.props.data.show_info.map((item, index) => {
                                                    return (
                                                        <span>{this.formatDate(item.date)}<br /></span>
                                                    )
                                                })}
                                            </h1>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row' }}>
                                        <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>No. of Shows:</h1>
                                        <h1 style={{ color: '#000', fontSize: 8, fontFamily: 'Arial' }}>{data.number_of_shows}</h1>
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row' }}>
                                        <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Billing:</h1>
                                        <h1 style={{ color: '#000', fontSize: 8, fontFamily: 'Arial' }}>{data.billing}</h1>
                                    </div>
                                    <div>
                                        <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row' }}>
                                            <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Venue:</h1>
                                            <h1 style={{ color: '#000', fontSize: 8, fontFamily: 'Arial' }}>{data.venue.venue}</h1>
                                        </div>
                                        <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row' }}>
                                            <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Address:</h1>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Arial' }}>
                                                    {data.venue.address ?
                                                        data.venue.address : null}
                                                    {data.venue.address ?
                                                        <br style={{ lineHeight: 2 }} /> : null}
                                                    {data.venue.address2 ?
                                                        data.venue.address2 : null}
                                                    {data.venue.address2 ?
                                                        <br style={{ lineHeight: 2 }} /> : null}
                                                    {data.venue.city ?
                                                        data.venue.city + ", " + venuestate + " " + data.venue.postalcode : null}
                                                    {data.venue.city ?
                                                        <br style={{ lineHeight: 2 }} /> : null}
                                                    {data.venue.country ?
                                                        data.venue.country : null}
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row' }}>
                                            <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Venue Phone:</h1>
                                            <h1 style={{ color: '#000', fontSize: 8, fontFamily: 'Arial' }}>{this.formatPhoneNumber(data.venue.phone)}</h1>
                                        </div>
                                    </div>
                                    <div style={{ width: '95%', border: '1px solid #333', borderRadius: 10, marginTop: 10 }}>
                                        <div style={{ width: '100%', backgroundColor: '#000', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
                                            <h1 style={{ color: '#fff', fontSize: 8, marginLeft: 5, padding: 2, fontFamily: 'Arial' }}>Deal Terms</h1>
                                        </div>
                                        <div style={{ width: '100%', height: 'auto' }}>
                                            <div style={{ display: 'flex', marginLeft: 5, marginTop: 5, width: '100%', flexDirection: 'row' }}>
                                                <h1 style={{ color: '#333333', fontSize: 8, fontFamily: 'Arial' }}>Compensation:</h1>
                                                {data.dealtype === 'Flat' ?
                                                    <h1 style={{ color: '#333333', fontSize: 8, fontFamily: 'Arial', marginLeft: 5 }}>{currencyFormat(data.guarantee)} {dealstartlang}</h1> :
                                                    <h1 style={{ color: '#333333', fontSize: 8, fontFamily: 'Arial', marginLeft: 5 }}>{currencyFormat(data.guarantee)} {dealstartlang} {parseFloat(data.dealpercent).toFixed(0) + "%"} {dealendlang}</h1>}
                                            </div>
                                            <div style={{ display: 'flex', marginTop: 5, width: '100%', flexDirection: 'row' }}>
                                                <h1 style={{ color: '#333333', fontSize: 8, fontFamily: 'Arial', marginLeft: 5 }}>Walkout Potential:</h1>
                                                <h1 style={{ color: '#333333', fontSize: 8, fontFamily: 'Arial', marginLeft: 5 }}>{currencyFormat(walkout)}</h1>
                                            </div>
                                            <div style={{ display: 'flex', marginTop: 5, width: '100%', flexDirection: 'row' }}>
                                                <h1 style={{ color: '#333333', fontSize: 8, fontFamily: 'Arial', marginLeft: 5 }}>Deal Points:</h1>
                                                <h1 style={{ color: '#333333', fontSize: 8, fontFamily: 'Arial', marginLeft: 5 }}>{data.dealpoints}</h1>
                                            </div>
                                            <h1 style={{ color: '#333333', fontSize: 8, fontFamily: 'Arial', marginLeft: 5, marginTop: 5 }}>Deposit:</h1>
                                            <h1 style={{ color: '#333333', fontSize: 8, fontFamily: 'Arial', marginLeft: 5, marginTop: 5 }}>Due:</h1>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '40%' }}>
                                    <div style={{ width: '100%', borderBottom: '2px solid #ddd', height: 'auto' }}>
                                        <div style={{ display: 'flex', marginLeft: 5, marginTop: 5, width: '100%', flexDirection: 'row' }}>
                                            <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Buyer Company:</h1>
                                            <h1 style={{ color: '#000', fontSize: 8, fontFamily: 'Arial' }}>{data.buyer.company}</h1>
                                        </div>
                                        <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row' }}>
                                            <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Address:</h1>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <p style={{ color: '#000', fontSize: 8, fontFamily: 'Arial' }}>{data.buyer.address.address}<br style={{ lineHeight: 2 }} />{data.buyer.address.city}, {state} {data.buyer.address.postal_code}<br style={{ lineHeight: 2 }} />{data.buyer.address.country}</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row' }}>
                                            <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Buyer Contact:</h1>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row' }}>
                                            <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Production Contact:</h1>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 5, width: '100%', flexDirection: 'row' }}>
                                        <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Age Limit:</h1>
                                        <h1 style={{ color: '#000', fontSize: 8, fontFamily: 'Arial' }}>18+</h1>
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row', marginTop: 10 }}>
                                        <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Show Schedule:</h1>
                                        <table style={{ width: '65%' }}>
                                            <tbody>
                                                {data.show_info.map((item, index) => {
                                                    item.show_times.map((time, ind) => {
                                                        var showtime = toggle24hr(time.time);
                                                        return (
                                                            <tr key={ind}>
                                                                <td style={{ width: '35%', fontSize: 8, fontFamily: 'Arial' }}>{showtime}</td>
                                                                <td style={{ width: '65%', fontSize: 8, fontFamily: 'Arial' }}>{item.description}</td>
                                                            </tr>
                                                        )
                                                    })
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row', marginTop: 10 }}>
                                        <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Merch:</h1>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <h1 style={{ color: '#000', fontSize: 8, fontFamily: 'Arial' }}>{data.merchhard}% Hard<br />{data.merchsoft}% Soft<br /><br />{data.merchseller} Sells</h1>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: 5, width: '100%', flexDirection: 'row' }}>
                                        <h1 style={{ color: '#555555', fontSize: 7, width: '30%', fontFamily: 'Arial' }}>Merch Notes:</h1>
                                        <h1 style={{ color: '#000', fontSize: 8, fontFamily: 'Arial' }}>{data.merchnotes} Sells</h1>
                                    </div>
                                </div>
                            </div >
                            <div style={{ width: '85%', backgroundColor: '#000', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, border: '1px solid #000' }}>
                                <h1 style={{ color: '#fff', fontSize: 8, fontFamily: 'Arial' }}>Ticket Scaling</h1>
                            </div>
                            <div style={{ width: '85%', border: '1px solid #000' }}>
                                <table style={{ width: '100%', backgroundColor: '#000' }}>
                                    <thead style={{ backgroundColor: '#000' }}>
                                        <tr>
                                            <th style={{ color: '#fff', fontSize: 5, width: '3%', fontFamily: 'Arial' }}> </th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '15%', fontFamily: 'Arial' }}>Ticket Type</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '13%', fontFamily: 'Arial' }}>Capacity</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '12%', fontFamily: 'Arial' }}>Comps</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '12%', fontFamily: 'Arial' }}>Kills</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '12%', fontFamily: 'Arial' }}>Gross Price</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '11%', fontFamily: 'Arial' }}>Facility Fee</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '11%', fontFamily: 'Arial' }}>Add. Fee</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '11%', fontFamily: 'Arial' }}>Add. Fee 2</th>
                                        </tr>
                                    </thead>
                                    {data.ticket_scaling.map((item, index) => {
                                        return (
                                            <tbody style={{ backgroundColor: index % 2 ? '#f1f1f1' : '#fff' }}>
                                                <tr>
                                                    <td style={{ color: '#000', fontSize: 6, width: '3%', fontFamily: 'Arial' }}>{index + 1}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '15%', fontFamily: 'Arial' }}>{item.type}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '13%', fontFamily: 'Arial' }}>{item.capacity}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '12%', fontFamily: 'Arial' }}>{item.comps}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '12%', fontFamily: 'Arial' }}>{item.kills}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '12%', fontFamily: 'Arial' }}>{item.price}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '11%', fontFamily: 'Arial' }}>{item.fac_fee}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '11%', fontFamily: 'Arial' }}>{item.add_fee_A}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '11%', fontFamily: 'Arial' }}>{item.add_fee_B}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                    <tbody style={{ backgroundColor: '#f1f1f1' }}>
                                        <tr>
                                            <td style={{ color: '#000', fontSize: 6, width: '3%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '15%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '13%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '12%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '12%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '12%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '11%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '11%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '11%', height: 10 }}> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ width: '85%', backgroundColor: '#000', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, border: '1px solid #000' }}>
                                <h1 style={{ color: '#fff', fontSize: 8 }}>Taxes</h1>
                            </div>
                            <div style={{ width: '85%', border: '1px solid #000' }}>
                                <table style={{ width: '100%', backgroundColor: '#000' }}>
                                    <thead style={{ backgroundColor: '#000' }}>
                                        <tr>
                                            <th style={{ color: '#fff', fontSize: 5, width: '3%', fontFamily: 'Arial' }}> </th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '21%', fontFamily: 'Arial' }}>Tax</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '17%', fontFamily: 'Arial' }}>Amount</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '17%', fontFamily: 'Arial' }}>Cap</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '17%', fontFamily: 'Arial' }}>Capped Amount</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '25%', fontFamily: 'Arial' }}>Notes</th>
                                        </tr>
                                    </thead>
                                    {data.taxes.map((item, index) => {
                                        return (
                                            <tbody style={{ backgroundColor: '#fff' }}>
                                                <tr>
                                                    <td style={{ color: '#000', fontSize: 6, width: '3%', fontFamily: 'Arial' }}>{index + 1}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '21%', fontFamily: 'Arial' }}>{item.tax}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '17%', fontFamily: 'Arial' }}>{item.amount}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '17%', fontFamily: 'Arial' }}>{item.cap}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '17%', fontFamily: 'Arial' }}>{item.cappedamount}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '25%', fontFamily: 'Arial' }}>{item.notes}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                    <tbody style={{ backgroundColor: '#f1f1f1' }}>
                                        <tr>
                                            <td style={{ color: '#000', fontSize: 6, width: '3%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '21%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '17%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '17%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '17%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '25%', height: 10 }}> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ width: '85%', backgroundColor: '#000', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, border: '1px solid #000' }}>
                                <h1 style={{ color: '#fff', fontSize: 8, fontFamily: 'Arial' }}>Expenses</h1>
                            </div>
                            <div style={{ width: '85%', border: '1px solid #000' }}>
                                <table style={{ width: '100%', backgroundColor: '#000' }}>
                                    <thead style={{ backgroundColor: '#000' }}>
                                        <tr>
                                            <th style={{ color: '#fff', fontSize: 5, width: '3%', fontFamily: 'Arial' }}> </th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '21%', fontFamily: 'Arial' }}>Expense</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '17%', fontFamily: 'Arial' }}>Amount</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '17%', fontFamily: 'Arial' }}>Cap</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '17%', fontFamily: 'Arial' }}>Capped Amount</th>
                                            <th style={{ color: '#fff', fontSize: 5, width: '25%', fontFamily: 'Arial' }}>Notes</th>
                                        </tr>
                                    </thead>
                                    {data.expenses.map((item, index) => {
                                        return (
                                            <tbody style={{ backgroundColor: '#fff' }}>
                                                <tr>
                                                    <td style={{ color: '#000', fontSize: 6, width: '3%', fontFamily: 'Arial' }}>{index + 1}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '21%', fontFamily: 'Arial' }}>{item.expense}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '17%', fontFamily: 'Arial' }}>{item.amount}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '17%', fontFamily: 'Arial' }}>{item.cap}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '17%', fontFamily: 'Arial' }}>{item.cappedamount}</td>
                                                    <td style={{ color: '#000', fontSize: 6, width: '25%', fontFamily: 'Arial' }}>{item.notes}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                    <tbody style={{ backgroundColor: '#f1f1f1' }}>
                                        <tr>
                                            <td style={{ color: '#000', fontSize: 6, width: '3%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '21%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '17%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '17%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '17%', height: 10 }}> </td>
                                            <td style={{ color: '#000', fontSize: 6, width: '25%', height: 10 }}> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ width: '85%', backgroundColor: '#000', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, border: '1px solid #000' }}>
                                <h1 style={{ color: '#fff', fontSize: 8, fontFamily: 'Arial' }}>Exclusivity</h1>
                            </div>
                            <div style={{ width: '85%', border: '1px solid #000', minHeight: 20 }}>
                                <h1 style={{ color: '#fff', fontSize: 6, fontFamily: 'Arial' }}>{data.exclusivity}</h1>
                            </div>
                            <div style={{ width: '85%', backgroundColor: '#000', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, border: '1px solid #000' }}>
                                <h1 style={{ color: '#fff', fontSize: 8, fontFamily: 'Arial' }}>Production Notes</h1>
                            </div>
                            <div style={{ width: '85%', border: '1px solid #000', minHeight: 20 }}>
                                <h1 style={{ color: '#fff', fontSize: 6, fontFamily: 'Arial' }}>{data.productionnotes}</h1>
                            </div>
                            <div style={{ width: '85%', backgroundColor: '#000', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, border: '1px solid #000' }}>
                                <h1 style={{ color: '#fff', fontSize: 8, fontFamily: 'Arial' }}>Travel / Accommodations</h1>
                            </div>
                            <div style={{ width: '85%', border: '1px solid #000', minHeight: 20 }}>
                                <h1 style={{ color: '#fff', fontSize: 6, fontFamily: 'Arial' }}>{data.productionnotes}</h1>
                            </div>
                            <div style={{ width: '85%', backgroundColor: '#000', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, border: '1px solid #000' }}>
                                <h1 style={{ color: '#fff', fontSize: 8, fontFamily: 'Arial' }}>Deal Memo Notes</h1>
                            </div>
                            <div style={{ width: '85%', border: '1px solid #000', minHeight: 20 }}>
                                <h1 style={{ color: '#fff', fontSize: 6, fontFamily: 'Arial' }}>{data.productionnotes}</h1>
                            </div>
                        </div >
                    </div>
                </body>
            </html>
        )
    }
};

function toggle24hr(time, onoff) {
    if (onoff == undefined) onoff = isNaN(time.replace(':', ''))//auto-detect format
    var pm = time.toString().toLowerCase().indexOf('pm') > -1 //check if 'pm' exists in the time string
    time = time.toString().toLowerCase().replace(/[ap]m/, '').split(':') //convert time to an array of numbers
    time[0] = Number(time[0])
    if (onoff) {//convert to 24 hour:
        if ((pm && time[0] != 12)) time[0] += 12
        else if (!pm && time[0] == 12) time[0] = '00'  //handle midnight
        if (String(time[0]).length == 1) time[0] = '0' + time[0] //add leading zeros if needed
    } else { //convert to 12 hour:
        pm = time[0] >= 12
        if (!time[0]) time[0] = 12 //handle midnight
        else if (pm && time[0] != 12) time[0] -= 12
    }
    return onoff ? time.join(':') : time.join(':') + (pm ? ' PM' : ' AM')
}

const _MapAbbrFullName = { "AZ": "Arizona", "AL": "Alabama", "AK": "Alaska", "AR": "Arkansas", "CA": "California", "CO": "Colorado", "CT": "Connecticut", "DC": "District of Columbia", "DE": "Delaware", "FL": "Florida", "GA": "Georgia", "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "IA": "Iowa", "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MD": "Maryland", "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", "MO": "Missouri", "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", "NH": "New Hampshire", "NJ": "New Jersey", "NM": "New Mexico", "NY": "New York", "NC": "North Carolina", "ND": "North Dakota", "OH": "Ohio", "OK": "Oklahoma", "OR": "Oregon", "PA": "Pennsylvania", "RI": "Rhode Island", "SC": "South Carolina", "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VT": "Vermont", "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming", "AB": "Alberta", "BC": "British Columbia", "MB": "Manitoba", "NB": "New Brunswick", "NF": "Newfoundland", "NT": "Northwest Territory", "NS": "Nova Scotia", "NU": "Nunavut", "ON": "Ontario", "PE": "Prince Edward Island", "QC": "Quebec", "SK": "Saskatchewan", "YT": "Yukon" };

const _MapFullNameAbbr = { "arizona": "AZ", "alabama": "AL", "alaska": "AK", "arkansas": "AR", "california": "CA", "colorado": "CO", "connecticut": "CT", "districtofcolumbia": "DC", "delaware": "DE", "florida": "FL", "georgia": "GA", "hawaii": "HI", "idaho": "ID", "illinois": "IL", "indiana": "IN", "iowa": "IA", "kansas": "KS", "kentucky": "KY", "louisiana": "LA", "maine": "ME", "maryland": "MD", "massachusetts": "MA", "michigan": "MI", "minnesota": "MN", "mississippi": "MS", "missouri": "MO", "montana": "MT", "nebraska": "NE", "nevada": "NV", "newhampshire": "NH", "newjersey": "NJ", "newmexico": "NM", "newyork": "NY", "northcarolina": "NC", "northdakota": "ND", "ohio": "OH", "oklahoma": "OK", "oregon": "OR", "pennsylvania": "PA", "rhodeisland": "RI", "southcarolina": "SC", "southdakota": "SD", "tennessee": "TN", "texas": "TX", "utah": "UT", "vermont": "VT", "virginia": "VA", "washington": "WA", "westvirginia": "WV", "wisconsin": "WI", "wyoming": "WY", "alberta": "AB", "britishcolumbia": "BC", "manitoba": "MB", "newbrunswick": "NB", "newfoundland": "NF", "northwestterritory": "NT", "novascotia": "NS", "nunavut": "NU", "ontario": "ON", "princeedwardisland": "PE", "quebec": "QC", "saskatchewan": "SK", "yukon": "YT" }

function convertStateToAbbr(input) {
    if (input === undefined) return input;
    var strInput = input.trim();
    if (strInput.length === 2) {
        // already abbr, check if it's valid
        var upStrInput = strInput.toUpperCase();
        return _MapAbbrFullName[upStrInput] ? upStrInput : undefined;
    }
    var strStateToFind = strInput.toLowerCase().replace(/\ /g, '');
    var foundAbbr = _MapFullNameAbbr[strStateToFind];
    return foundAbbr;
}

function convertStateToFullName(input) {
    if (input === undefined) return input;
    var strInput = input.trim();
    if (strInput.length !== 2) {
        // already full name, return formatted fullname
        return _MapAbbrFullName[convertStateToAbbr(strInput)];
    }
    var strStateToFind = strInput.toLowerCase().replace(/\ /g, '');
    var foundFullName = _MapAbbrFullName[strStateToFind];
    return foundFullName;
}

export default DealMemoPDF;

