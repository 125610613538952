import React, { Component } from "react";
import { addPackaging, updatePackaging } from '../actions/packagingActions';
import { getSpotify, spotifyLogin } from '../actions/spotifyActions';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FaInfoCircle, FaAddressBook, FaSave, FaPlusSquare, FaMinusSquare, FaPlusCircle, FaEdit, FaTimesCircle, FaGlobeAmericas, FaMapMarkedAlt, FaSitemap, FaVoteYea } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CurrencyAutocomplete from '../components/autocompletes/currency-autocomplete';
import RenderNewContact from '../components/render-new-contact.component';
import RenderContacts from '../components/render-contacts.component';
import PackagingDates from './modal-components/packaging-dates.modal-component';
import PackagingSubmissions from './modal-components/packaging-submissions.modal-component';
import SearchAutocomplete from '../components/autocompletes/search-autocomplete';
import AgentAutocomplete from '../components/autocompletes/agent-autocomplete';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';


class PackagingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: true,
            addcontact: false,
            checkedItems: new Map(),
            render: 'info',
            artist: '',
            currency: 'USD',
            budget: '',
            status: 'Active',
            contact_name: '',
            contact_email: '',
            notes: '',
            tour_dates: '',
            website: '',
            facebook: '',
            instagram: '',
            spotify: '',
            additional_link: '',
            tour_dates: [],
            tour_venue_array: '',
            tour_date_add: '',
            tour_date_end_add: '',
            tour_venue_add: '',
            tour_city_add: '',
            tour_state_add: '',
            tour_country_add: '',
            artist_search: '',
            artist_select: { name: '' },
            blues: false,
            genre: { blues: false, classical: false, comedy: false, country: false, electronic: false, folk: false, hiphop: false, jazz: false, pop: false, rock: false, world: false },
            artist: '', artistid: '', artistbatch: { _id: '', client: '' },
            submissions: [], date_submitted: new Date(), submission_note: '',
            genre_map: '',
            id: '',
            createdAt: '',
            updatedAt: '',
            typing: false,
            typingTimeout: 0,
            render_type: 'enter',
            searchbar: false,
            date_range: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleInputArray = this.handleInputArray.bind(this);
        this.handleSelectArray = this.handleSelectArray.bind(this);
        this.handleArtistChange = this.handleArtistChange.bind(this);
        this.handleArtistSelect = this.handleArtistSelect.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        spotify: PropTypes.object.isRequired,
        packaging: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    componentDidMount() {
        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            var body = document.getElementsByTagName('body')[0];
            body.appendChild(tag);
        }
        loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBflTOEdbD8_Vkqy9AIwOfVTeCzLD_L1Es&libraries=places');
        console.log(window)
        this.props.spotifyLogin();
        if (this.props.type === 'render') {
            const data = this.props.render_packaging_data;
            this.setState({
                render_type: 'render',
                edit: false,
                additional_link: data.additional_link,
                artist: data.artist,
                artist_search: data.artist,
                budget: data.budget,
                contact_email: data.contact_email,
                contact_name: data.contact_name,
                currency: data.currency,
                facebook: data.facebook,
                instagram: data.instagram,
                notes: data.notes,
                spotify: data.spotify,
                status: data.status,
                submissions: data.submissions,
                tour_dates: data.tour_dates,
                website: data.website,
                genre_map: data.genre,
                id: data._id,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt
            })
        } else {
            this.setState({
                render_type: 'enter',
                edit: true
            })
        }
    }

    handleEventPositioned() {
        ReactTooltip.rebuild();
    }

    handleInput = (e) => {
        // Update the keyword of the input element
        this.setState({
            tour_venue_add: e
        })
    };

    handleInputArray = (val, index) => {
        var array = this.state.tour_dates;
        array[index].venue = val;
        console.log(val, index, array)
        this.setState({
            tour_dates: this.state.tour_dates.map((a, b) => {
                if (b === index) {
                    a.venue = val;
                    return a
                } else {
                    return a
                }
            })
        })
    };

    handleSelect = (coord, results, description) => {
        console.log(coord, results, description)
        var desc = description.split(',')
        var street = "";
        var route = "";
        var subpremise = "";
        var locality = "";
        var state = "";
        var country = "";
        var postal_code = "";

        results[0].address_components.map(item => {
            console.log(item)
            if (item.types.includes('subpremise')) {
                subpremise = item.long_name
            }
            if (item.types.includes('sublocality')) {
                locality = item.long_name
            }
            if (item.types.includes('street_number')) {
                street = item.long_name
            }
            if (item.types.includes('route')) {
                route = item.short_name
            }
            if (item.types.includes('locality')) {
                locality = item.long_name
            }
            if (item.types.includes('administrative_area_level_1')) {
                state = item.long_name
            }
            if (item.types.includes('country')) {
                country = item.long_name
            }
            if (item.types.includes('postal_code')) {
                postal_code = item.long_name
            }
        })
        this.setState({
            tour_venue_add: desc[0],
            tour_city_add: locality,
            tour_state_add: state,
            tour_country_add: country,
        })
    };

    handleSelectArray = (coord, results, description, pIndex) => {
        console.log(coord, results, description, pIndex)
        var desc = description.split(',')
        var street = "";
        var route = "";
        var subpremise = "";
        var locality = "";
        var state = "";
        var country = "";
        var postal_code = "";

        results[0].address_components.map(item => {
            console.log(item)
            if (item.types.includes('subpremise')) {
                subpremise = item.long_name
            }
            if (item.types.includes('sublocality')) {
                locality = item.long_name
            }
            if (item.types.includes('street_number')) {
                street = item.long_name
            }
            if (item.types.includes('route')) {
                route = item.short_name
            }
            if (item.types.includes('locality')) {
                locality = item.long_name
            }
            if (item.types.includes('administrative_area_level_1')) {
                state = item.long_name
            }
            if (item.types.includes('country')) {
                country = item.long_name
            }
            if (item.types.includes('postal_code')) {
                postal_code = item.long_name
            }
        })
        var td = this.state.tour_dates;
        td[pIndex].venue = desc[0];
        td[pIndex].city = locality;
        td[pIndex].state = state;
        td[pIndex].country = country;
        this.setState({
            tour_dates: td
        }, () => this.setState({
            tour_dates: td
        }))
    };

    onEdit = () => {
        this.setState({
            edit: !this.state.edit
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleArtistChange = (e) => {
        console.log(e)
        //var searchText = event.target.value; // this is the search text
        this.setState({
            artist_search: e
        });

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const itemStr = this.props.spotify.token;
            const item = JSON.parse(itemStr)
            const data = {
                data: this.state.artist_search,
                spotify_token: item.token
            }
            this.props.getSpotify(data)
        }, 200);
    }

    handleArtistSelect = (e) => {
        console.log(e)
        //var searchText = event.target.value; // this is the search text
        var genres = e.genres.join(', ');
        console.log(genres)
        this.setState({
            artist_search: e.name,
            spotify: e.external_urls.spotify,
            genre_map: genres
        });
    }

    handleAgentChange(agent) {
        if (agent) {
            this.setState({
                agent: agent,
            })
        } else {
            this.setState({
                agent: [],
            });
        }

    }

    handleClick = () => {
        this.props.toggle();
    };

    toggleSub = (e) => {
        this.setState({
            render: e,
            hover: false
        })
    }
    toggleNewContact = () => {
        this.setState({
            render: 'addcontact',
            hover2: false
        })
    }

    toggleAddContact = () => {
        this.setState({
            contacts: 'addcontacts',
        })
    }

    // handleClientChange(event) {
    //     const artist = event.target.value;
    //     var bearer = 'Bearer ' + 'efe71a9eb2e842fb964bf2ab32bd66f4';
    //     fetch(`https://api.spotify.com/v1/search?q=${artist}&type=artist`, { method: 'GET', headers: { 'Authorization': bearer, 'Content-Type': 'application/json' } })
    //         .then(response => response.json())
    //         .then((jsonData) => {
    //             console.log(jsonData)
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    //     this.setState({
    //         [event.target.name]: event.target.value
    //     });
    // }

    handleTourDateChange = date => {
        this.setState({
            tour_date_add: date
        });
    }

    handleTourDateEndChange = date => {
        this.setState({
            tour_date_end_add: date
        });
    };

    addTourDate = () => {
        var d = new Date();
        var id = `${d.getDate()}${d.getMonth()}${d.getFullYear()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}`;

        const tour_date = {
            _id: id,
            date: moment(this.state.tour_date_add).format('MM/DD/YYYY'),
            venue: this.state.tour_venue_add,
            city: this.state.tour_city_add,
            state: this.state.tour_state_add,
            country: this.state.tour_country_add
        }

        const newArray = []
        newArray.push(...this.state.tour_dates, tour_date);

        var sortArray = _.sortBy(newArray, function (dateObj) {
            return new Date(dateObj.date);
        });

        this.setState({
            tour_dates: sortArray,
            tour_date_add: '',
            tour_venue_add: '',
            tour_city_add: '',
            tour_state_add: '',
            tour_country_add: ''
        })
    }

    addTourDateRange = () => {
        if (this.state.tour_date_add) {
            var dateList = getDaysBetweenDates(this.state.tour_date_add, this.state.tour_date_end_add);

            console.log(dateList)
            const newArray = [...this.state.tour_dates]

            dateList.forEach(da => {
                var d = new Date();
                var id = `${d.getDate()}${d.getMonth()}${d.getFullYear()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}${Math.random()}`;

                const tour_date = {
                    _id: id,
                    date: da,
                    venue: '',
                    city: '',
                    state: '',
                    country: ''
                }
                newArray.push(tour_date);
            })

            var sortArray = _.sortBy(newArray, function (dateObj) {
                return new Date(dateObj.date);
            });

            this.setState({
                tour_dates: sortArray,
                tour_date_add: '',
                tour_date_end_add: '',
                tour_venue_add: '',
                tour_city_add: '',
                tour_state_add: '',
                tour_country_add: '',
                date_range: false
            })
        }
    }

    updateTourDate = (props) => {
        const val = this.state.tour_dates[props];
        this.setState({
            tour_date_add: new Date(val.date),
            tour_venue_add: val.venue,
            tour_city_add: val.city,
            tour_state_add: val.state,
            tour_country_add: val.country
        })
        const newArray = this.state.tour_dates.filter((e, i) => i !== props);
        this.setState({
            tour_dates: newArray
        })
    }

    deleteTourDate = (props) => {
        const newArray = this.state.tour_dates.filter((e, i) => i !== props);
        this.setState({
            tour_dates: newArray
        })
    }

    handleCheckbox = (e) => {
        var name = e.target.name;
        if (e.target.value === 'false') {
            this.setState(currentState => {
                const genre = { ...currentState.genre, [name]: true };
                return { genre };
            });
        } else {
            this.setState(currentState => {
                const genre = { ...currentState.genre, [name]: false };
                return { genre };
            });
        }
    }

    handleClientChange(artist) {
        if (artist) {
            this.setState({
                artist: artist.client,
                artistid: artist._id,
                artistbatch: artist
            })
        } else {
            this.setState({
                artist: '',
                artistid: '',
                artistbatch: ''
            });
        }
    }

    onDateSubmitted = date => {
        this.setState({
            date_submitted: date
        })
    }

    addSubmission = () => {
        const submission = {
            date: moment(this.state.date_submitted).format('MM/DD/YYYY'),
            client: this.state.artistid,
            note: this.state.submission_note,
            user: this.props.auth.user.tag
        }
        var stateSubs = this.state.submissions;
        const found = stateSubs.findIndex(obj => obj.client === this.state.artistid);
        if (found >= 0) {
            alert('Submission is already added.')
        } else {
            stateSubs = [...stateSubs, submission];
            var newSubs = [];
            stateSubs.map(item => {
                var client = this.props.clients.clients.filter(cl => cl._id === item.client);
                if(client && client[0]){
                    client = client[0]
                }
                newSubs.push({ ...item, c_sort: client.client });
            })
            newSubs = _.orderBy(newSubs, [`c_sort`.toLowerCase()], ['asc']);
            this.setState({
                submissions: newSubs,
                artist: '',
                artistid: '',
                artistbatch: { _id: '', client: '' },
                date_submitted: new Date(),
                submission_note: ''
            })
        }
    }

    updateSubmission = (index, client) => {
        const val = this.state.submissions[index];
        this.setState({
            artist: client,
            artistid: val.client,
            artistbatch: { _id: val.client, client: client },
            date_submitted: new Date(val.date_submitted),
            submission_note: val.note
        })
        const newArray = this.state.submissions.filter((e, i) => i !== index);
        this.setState({
            submissions: newArray
        })
    }

    deleteSubmission = (index, client) => {
        const newArray = this.state.submissions.filter((e, i) => i !== index);
        this.setState({
            submissions: newArray
        })
    }

    onSave = async () => {

        //const genre_map = Object.keys(this.state.genre).filter(key => this.state.genre[key] === true).map(function (k) { return k }).join(", ");
        if (!this.state.artist_search) {
            alert('Please enter an artist name')
        } else {
            var newSubmissions = [];
            this.state.submissions.forEach(item => {
                var newSub = {
                    date: item.date,
                    client: item.client,
                    note: item.note,
                    user: item.user
                }
                newSubmissions.push(newSub)
            });
            const packaging = {
                artist: this.state.artist_search,
                status: this.state.status,
                contact_name: this.state.contact_name,
                contact_email: this.state.contact_email,
                currency: this.state.currency,
                budget: this.withoutSign(this.state.budget),
                notes: this.state.notes,
                tour_dates: this.state.tour_dates,
                website: this.state.website,
                facebook: this.state.facebook,
                instagram: this.state.instagram,
                spotify: this.state.spotify,
                additional_link: this.state.additional_link,
                genre: this.state.genre_map,
                submissions: newSubmissions
            }

            const id = this.state.id;

            if (this.state.render_type === 'render') {
                console.log(packaging, id)
                const log_status = await this.props.updatePackaging(packaging, id);
                if (log_status.includes('Error:')) {
                    this.props.toggleErrorSnackbar(log_status);
                } else {
                    this.setState({
                        header: this.state.artist_search,
                        edit: false,
                    })
                    this.props.toggleStatusSnackbar('Packaging updated successfully');
                }
            } else {
                const log_status = await this.props.addPackaging(packaging)
                if (log_status.includes('Error:')) {
                    this.props.toggleErrorSnackbar(log_status);
                } else {
                    this.setState({
                        header: this.state.artist_search,
                        edit: false,
                        render_type: 'render',
                        id: log_status
                    })
                    this.props.toggleStatusSnackbar('Packaging added successfully');
                }
            }
        }

        // this.setState({
        //     artist: '',
        //     status: '',
        //     contact_name: '',
        //     contact_email: '',
        //     currency: '',
        //     budget: '',
        //     notes: '',
        //     tour_dates: '',
        //     website: '',
        //     facebook: '',
        //     instagram: '',
        //     spotify: '',
        //     additional_link: '',
        //     genre: { blues: false, classical: false, comedy: false, country: false, electronic: false, folk: false, hiphop: false, jazz: false, pop: false, rock: false, world: false },
        //     submissions: []
        // })

    }

    withoutSign = (value) => {
        console.log('VALUE', value)
        if (value) {
            var test = isNaN(value)
            if(test){
                var v = value.replace("$", "")
                return (
                    parseFloat(v.replace(/,/g, ''))
                );
            } else {
                return (
                    parseFloat(v)
                );
            }
        } else {
            return ""
        }
    };

    handleCurrencyChange = (event, set) => {
        console.log(event, set)

        this.setState(prevState => {
            let currency = Object.assign({}, prevState.currency);
            currency[set] = event.code;
            console.log(currency)
            return { currency };
        })
    }

    toggleStatus = () => {
        if (this.state.status === 'Active') {
            this.setState({
                status: "Inactive"
            })
        } else {
            this.setState({
                status: "Active"
            })
        }
    }

    toggleDateRange = () => {
        this.setState({
            searchbar: false,
            date_range: !this.state.date_range
        })
    }

    updateTourDateArray = (index, date) => {
        let array = this.state.tour_dates;
        array[index].date = date;
        array = _.sortBy(array, function (dateObj) {
            return new Date(dateObj.date);
        });
        this.setState({
            tour_dates: []
        }, () => this.setState({
            tour_dates: array
        }))
    }
    updateTourArray = (index, name, event) => {
        let array = this.state.tour_dates;
        array[index][name] = event.target.value;
        array = _.sortBy(array, function (dateObj) {
            return new Date(dateObj.date);
        });
        this.setState({
            tour_dates: array
        });
    }

    updateSubsArray = (index, name, event) => {
        let array = this.state.submissions;
        array[index][name] = event.target.value;
        array = _.sortBy(array, function (dateObj) {
            return new Date(dateObj.date);
        });
        this.setState({
            submissions: array
        });
    }

    render() {

        return (
            <div className="mode-t">
                <div className="mode-t-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50 }}>
                        <span className="close" onClick={this.handleClick}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        {this.state.edit ?
                            <span className="close" onClick={this.onSave}>
                                <FaSave size="20" color="#cdcdcd" />
                            </span> :
                            <span className="close" onClick={this.onEdit}>
                                <FaEdit size="18" color="#cdcdcd" />
                            </span>}
                        {this.state.status === "Active" ?
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>ACTIVE</p>
                                <div style={{ backgroundColor: 'green', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span> :
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>INACTIVE</p>
                                <div style={{ backgroundColor: 'red', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span>}
                        <h1 style={{ color: '#fff', marginLeft: 60, fontFamily: 'Helvetica', paddingTop: 12, fontSize: 22, fontWeight: 'bold' }}>SUPPORT / PACKAGING</h1>
                    </div>

                    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                        <div style={{ width: '5%', backgroundColor: 'rgb(1, 22, 48)', height: '91.6%' }}>
                            {/* {this.props.tooltip ? */}
                            <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                            </ReactTooltip>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'info' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'info' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('info')} data-tip={'Packaging Info'} onMouseEnter={this.handleEventPositioned}>
                                <FaInfoCircle style={{ fontSize: 20 }} />
                            </div>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'tour_dates' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'tour_dates' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('tour_dates')} data-tip={'Tour Dates'} onMouseEnter={this.handleEventPositioned}>
                                <FaMapMarkedAlt style={{ fontSize: 20 }} />
                            </div>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'submissions' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'submissions' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('submissions')} data-tip={'Submissions'} onMouseEnter={this.handleEventPositioned}>
                                <FaVoteYea style={{ fontSize: 20 }} />
                            </div>
                        </div>
                        {this.state.render === "info" ?
                            <div style={{ display: 'flex', width: '100%', height: 490, overflowY: 'scroll' }}>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 30 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Artist:</label>
                                        {this.state.edit ?
                                            <div style={{ marginLeft: 10, width: '55%', fontSize: 12 }}>
                                                <SearchAutocomplete searchList={this.props.spotify.spotify} onChange={this.handleArtistChange.bind(this)} onSelect={this.handleArtistSelect.bind(this)} searchSelect={this.state.artist_select} artist_search={this.state.artist_search} loading={this.props.spotify.loading} />
                                            </div> :
                                            <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={true} type="text" name="client" value={this.state.artist_search} />}
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Contact:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="contact_name" value={this.state.contact_name} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>E-mail:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="contact_email" value={this.state.contact_email} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Currency:</label>
                                        {this.state.edit ?
                                            <div style={{ width: '55%', marginLeft: 10, height: 30 }}>
                                                <CurrencyAutocomplete val={this.state.currency} onChange={this.handleCurrencyChange} set="packaging" />
                                            </div> :
                                            <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={true} type="text" value={this.state.currency} />}
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Budget:</label>
                                        <NumberFormat style={{ marginLeft: 10, width: '55%', height: 30, fontSize: 12, border: '.4px solid #000', borderRadius: 4 }} disabled={this.state.edit ? false : true} name="budget" value={this.state.budget} thousandSeparator={true} prefix={'$'} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 'auto', marginLeft: 60, marginTop: 30 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Notes:</label>
                                        <textarea style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000', minHeight: 100 }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="notes" value={this.state.notes} onChange={this.handleChange.bind(this)} />
                                    </div>
                                </div>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginTop: 30 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Website:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="website" value={this.state.website} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Facebook:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="facebook" value={this.state.facebook} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Instagram:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="instagram" value={this.state.instagram} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Spotify:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="spotify" value={this.state.spotify} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
                                        <label style={{ width: '25%', fontSize: 14 }}>Additional Link:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="additional_link" value={this.state.additional_link} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginTop: 20 }}>
                                        <label style={{ width: '25%', fontSize: 14 }}>Genre:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" value={this.state.genre_map} onChange={this.handleChange.bind(this)} />
                                    </div>
                                </div>
                            </div> :

                            this.state.render === 'contacts' ?
                                <RenderContacts toggleNewContact={this.toggleNewContact} /> :
                                this.state.render === 'tour_dates' ?
                                    <PackagingDates onChange={this.handleChange} handleTourDateChange={this.handleTourDateChange} handleTourDateEndChange={this.handleTourDateEndChange} addTourDate={this.addTourDate} addTourDateRange={this.addTourDateRange} updateTourDate={this.updateTourDate} deleteTourDate={this.deleteTourDate} toggleDateRange={this.toggleDateRange} handleInput={this.handleInput} handleSelect={this.handleSelect} updateTourDateArray={this.updateTourDateArray} updateTourArray={this.updateTourArray} handleSelectArray={this.handleSelectArray} handleInputArray={this.handleInputArray} tour_dates={this.state.tour_dates} {...this.state} /> :
                                    this.state.render === 'submissions' ?
                                        <PackagingSubmissions onChange={this.handleChange} onClientChange={this.handleClientChange} onDateSubmitted={this.onDateSubmitted} addSubmission={this.addSubmission} updateSubmission={this.updateSubmission} deleteSubmission={this.deleteSubmission} updateSubsArray={this.updateSubsArray} {...this.state} /> :
                                        <RenderNewContact toggleReturn={this.toggleContacts} />}

                    </div>

                </div>
            </div >
        );
    }
}

function getDaysBetweenDates(startDate, endDate) {
    var now = moment(startDate), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('MM/DD/YYYY'));
        now.add(1, 'days');
    }
    return dates;
};

const mapStateToProps = state => ({
    spotify: state.spotify,
    items: state.items,
    packaging: state.packaging,
    clients: state.clients,
    auth: state.auth
});

export default connect(mapStateToProps, { addPackaging, updatePackaging, spotifyLogin, getSpotify })(PackagingModal);