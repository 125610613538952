import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';
import FestivalAutocomplete from '../autocompletes/festival-autocomplete';
import AttachmentsAutocomplete from '../autocompletes/email-attachment-autocomplete';
import { FaEnvelopeOpenText, FaEnvelope, FaPlus } from 'react-icons/fa';
import { CONTACTS_SOCKET_DELETE } from '../../actions/types';

export default function ItineraryFinancialAddDialog(props) {
    const [stateId, setStateId] = useState(null);
    const [type, setType] = useState('Vs Net');
    const [percentage, setPercentage] = useState(0);
    const [capped, setCapped] = useState(false);
    const [cappedAmt, setCappedAmt] = useState(0);
    const [customCheckbox, setCustomCheckbox] = useState(false);

    useEffect(() => {
        console.log(props.data)
        if (props.data) {
            setStateId(props.data.id);
            setType(props.data.type);
            setPercentage(props.data.percentage);
            setCapped(props.data.capped);
            setCappedAmt(props.data.capped_amt);
        }
    }, [props.data.id])

    const cancelDialog = () => {
        props.onClose();
    }

    const addDeal = () => {
        var value = type + ' ' + percentage + '%';
        if(capped){
            value = value + ' capped @ ' + formatCurrency(cappedAmt)
        }
        props.save(stateId, value);
    }

    const handleCappedCheckbox = (rec) => {
        setCapped(!capped);
    }

    const handlePercentageChange = (values) => {
        const { formatted, value } = values;
        setPercentage(value)
    }

    const handleAmountChange = (values) => {
        const { formatted, value } = values;
        setCappedAmt(value)
    }

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={props.onClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: 350, marginBottom: 30, marginTop: 20 }}>
                    <div style={{ width: 350, height: 'auto', marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <table style={{ width: '100%', overflowY: 'scroll' }} id="email-dialog-table">
                                <thead>
                                    <tr>
                                        <th style={{ width: '25%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>Type</p></th>
                                        <th style={{ width: '20%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>Percentage</p></th>
                                        <th style={{ width: '20%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>Capped</p></th>
                                        <th style={{ width: '25%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>Cap Amount</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '25%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}><select style={{ height: 30, fontSize: 12, width: '100%' }} name="type" value={type} onChange={(e) => setType(e.target.value)}>
                                            <option value="Vs Net">Vs Net</option>
                                            <option value="Vs Gross">Vs Gross</option>
                                            <option value="Plus">Plus</option>
                                            <option value="Plus (Promoter Profit)">Plus (Promoter Profit)</option>
                                        </select></p></td>
                                        <td style={{ width: '20%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}><NumberFormat style={{ width: '100%' }} className="form-control-sm" value={percentage} thousandSeparator={true} suffix={'%'} onValueChange={(val) => handlePercentageChange(val)}/></p></td>
                                        <td style={{ width: '20%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}><Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            color="default"
                                            checked={capped}
                                            onChange={handleCappedCheckbox}
                                        /></p></td>
                                        <td style={{ width: '25%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>{capped ? <NumberFormat style={{ width: '100%' }} className="form-control-sm" disabled={!capped} value={cappedAmt} thousandSeparator={true} prefix={'$'} onValueChange={(val) => handleAmountChange(val)}/> : ''}</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={cancelDialog}>Cancel</Button>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={addDeal}>Add</Button>
            </DialogActions>
        </Dialog>
    );
}

function formatCurrency(number) {
    if (number) {
        var curr = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
        return curr
    } else {
        return ""
    }
}