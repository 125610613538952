import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import NumberFormat from 'react-number-format';
import { FaTimesCircle, FaEdit, FaCheckSquare, FaTrash, FaGripLinesVertical } from 'react-icons/fa';

const style = {
    border: '1px dashed gray',
    backgroundColor: '#f1f1f1',
    width: '100%'
};
const style2 = {
    border: '1px dashed gray',
    backgroundColor: '#f1f1f1',
    width: '100%'
};

export const Card = ({ index, show_index, type, cap, comps, kills, price, facfee, addfeeA, addfeeB, id, updateScaling, deleteScaling, handleArrayUpdate, edit, scaling_edit, scaling_val, clearScalingUpdate, saveScalingUpdate, handleArrayFormattedChange, currency_symbol, scaling_pin, show_select, handlePinnedArray2Change, moveCard }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive id searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, index, id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    console.log(drag)

    drag(drop(ref));
    return (<div style={edit ? { ...style, opacity } : { ...style2 }} ref={ref}>
        <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td style={{ width: '3%', fontSize: 13, cursor: 'move' }} ><FaGripLinesVertical size="18" color='#cdcdcd' /></td>
                    {scaling_edit === 'type' ?
                        <td style={{ width: '15%' }}><input style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} type="text" name="type" value={type} onChange={!scaling_pin ? (event) => handleArrayUpdate(event, id, 'scalingarray') : (event) => handlePinnedArray2Change(event, 'scalingarray', show_index)} /></td> :
                        <td style={{ width: '15%' }} onClick={() => updateScaling(id, 'type')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 15, paddingLeft: 5 }}>{type}</p></td>}
                    {scaling_edit === 'capacity' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} value={cap} thousandSeparator={true} onValueChange={(values) => handleArrayFormattedChange(values, id, 'capacity', 'scalingarray')} /></td> :
                        <td style={{ width: '11%' }} onClick={() => updateScaling(id, 'capacity')}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={cap} displayType={'text'} thousandSeparator={true} /></td>}
                    {scaling_edit === 'comps' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} value={comps} thousandSeparator={true} onValueChange={(values) => handleArrayFormattedChange(values, id, 'comps', 'scalingarray')} /></td> :
                        <td style={{ width: '11%' }} onClick={() => updateScaling(id, 'comps')}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={comps} displayType={'text'} thousandSeparator={true} /></td>}
                    {scaling_edit === 'kills' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} value={kills} thousandSeparator={true} onValueChange={(values) => handleArrayFormattedChange(values, id, 'kills', 'scalingarray')} /></td> :
                        <td style={{ width: '11%' }} onClick={() => updateScaling(id, 'kills')}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={kills} displayType={'text'} thousandSeparator={true} /></td>}
                    {scaling_edit === 'price' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} value={price} thousandSeparator={true} prefix={currency_symbol} onValueChange={(values) => handleArrayFormattedChange(values, id, 'price', 'scalingarray')} /></td> :
                        <td style={{ width: '11%' }} onClick={() => updateScaling(id, 'price')}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={price} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>}
                    {scaling_edit === 'fac_fee' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} value={facfee} thousandSeparator={true} prefix={currency_symbol} onValueChange={(values) => handleArrayFormattedChange(values, id, 'fac_fee', 'scalingarray')} /></td> :
                        <td style={{ width: '11%' }} onClick={() => updateScaling(id, 'fac_fee')}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={facfee} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>}
                    {scaling_edit === 'add_fee_A' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} value={addfeeA} thousandSeparator={true} prefix={currency_symbol} onValueChange={(values) => handleArrayFormattedChange(values, id, 'add_fee_A', 'scalingarray')} /></td> :
                        <td style={{ width: '11%' }} onClick={() => updateScaling(id, 'add_fee_A')}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={addfeeA} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>}
                    {scaling_edit === 'add_fee_B' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} value={addfeeB} thousandSeparator={true} prefix={currency_symbol} onValueChange={(values) => handleArrayFormattedChange(values, id, 'add_fee_B', 'scalingarray')} /></td> :
                        <td style={{ width: '11%' }} onClick={() => updateScaling(id, 'add_fee_B')}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={addfeeB} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>}
                    <td style={{ width: '8%', fontSize: 13 }}><FaTrash style={{ marginLeft: 10, cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => deleteScaling(id)} /></td>
                </tr>
            </tbody>
        </table>
    </div>);
};
