import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getCountRolodex, addCountsBulk, loadCountsRolodex, setCountsGrid, updateCountsGrid } from '../actions/countActions';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { FaExternalLinkSquareAlt, FaCaretUp, FaCaretDown, FaSave, FaMinusSquare, FaRedoAlt } from 'react-icons/fa';
import { ContextMenu, MenuItem, ContextMenuTrigger, SubMenu } from "react-contextmenu";
import { AutoSizer, Column, Table } from 'react-virtualized';
import CustomChip from '../components/customStatus';

import "react-datepicker/dist/react-datepicker.css";

class CountsRolodex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            artist: '',
            agent: '',
            startDate: '',
            endDate: '',
            entervenue: false,
            buyers: [],
            sortdirection: 'asc',
            sortcat: 'company',
            todayscount: [],
            todaysnotes: [],
            count_data: [],
            counts_loading: false,
            render_data: [],
            counts_checked: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCountChange = this.handleCountChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        festivals: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        counts: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        this.setState({
            counts_loading: true
        })
        const count_data = await this.props.getCountRolodex();
        this.setCountsGrid(count_data);

        const socket = this.props.auth.socket;
        if (socket) {
            socket.on(`log_counts_bulk`, async (counts) => { 
                let count_data = this.state.count_data;
                const show_index = count_data.findIndex(obj => obj._id === counts._id);

                if (show_index >= 0) {
                    count_data[show_index] = counts
                } else {
                    count_data = [...count_data, counts]
                }
                await this.updateCountsGrid(counts);
                await this.setState({
                    count_data: count_data 
                });
                this.props.countInputSocket(counts);
                // this.setState({
                //     countdata: counts.counts,
                //     count_render: sorted_tc_dates
                // })
            })
            // socket.on(`offers/update`, async (offer) => {
            //     let count_data = this.state.count_data;
            //     const show_index = count_data.findIndex(obj => obj._id === offer._id);

            //     if (show_index >= 0) {
            //         count_data[show_index] = counts
            //     } else {
            //         count_data = [...count_data, counts]
            //     }
            //     await this.updateCountsGrid(counts);
            //     await this.setState({
            //         count_data: count_data
            //     });
            //     this.props.countInputSocket(counts);
            //     // this.setState({
            //     //     countdata: counts.counts,
            //     //     count_render: sorted_tc_dates
            //     // })
            // })
        };
    }

    // shouldComponentUpdate(nextProps, nextState){
    //     if (nextState.count_data !== nextProps.counts.counts ) {
    //         this.setCountsGrid(nextProps.counts.counts)
    //         return true;
    //       }
    //       return false; //this is the missing piece
    // }

    // componentDidUpdate(prevProps, prevState){
    //     if (prevState.counts_checked !== this.props.counts_checked ) {
    //         this.setState({
    //             counts_checked: this.props.counts_checked
    //         })
    //         return true;
    //       }
    //       return false; //this is the missing piece
    // }

    async setCountsGrid(count_data) {
        var offers = this.props.offers.offers;
        offers = await this.filterCountsStart(offers);

        var arrData = [];
        offers.map((item, index) => {

            var id = item._id;
            var venueid = item.venue_id;
            var venue = [];
            var city = [];
            var state = [];
            this.props.items.items.map((venues, i) => {
                if (venueid === venues._id) {
                    venue = venues.venue_name
                    city = venues.address.city
                    state = venues.address.state
                }
            })

            this.props.festivals.items.map((venues, i) => {
                if (venueid === venues._id) {
                    venue = venues.festival
                    city = venues.address.city
                    state = venues.address.state
                }
            })

            var buyerid = item.buyer_id;
            var buyer = [];
            this.props.buyers.buyers.map((buyers, key) => {
                if (buyerid === buyers._id) {
                    buyer = buyers.company
                }
            })

            var artistid = item.client_id;
            var artist = [];
            this.props.clients.clients.map((client, key) => {
                if (artistid === client._id) {
                    artist = client.client
                }
            })


            var counts = [];
            count_data.map((c, i) => {
                if (id === c._id) {
                    counts = c.counts;
                }
            });

            var day = moment().format('YYYY-MM-DD').toString();

            var render = [];
            item.show_info.forEach((d, i) => {

                var countA = [];
                var countADate = [];
                var countB = [];
                var countBDate = [];
                var countC = [];
                var countCDate = [];
                var countD = [];
                var countDDate = [];
                var countT = [];
                var notesA = [];
                var notesB = [];
                var notesC = [];
                var notesT = [];
                var increaseA = '';
                var perincreaseA = '';
                var increaseB = '';
                var perincreaseB = '';
                var increaseC = '';
                var perincreaseC = '';
                var increaseT = '';
                var perincreaseT = '';
                var diffAVal = [];
                var diffBVal = [];
                var diffCVal = [];
                var diffTVal = [];

                counts.map(c => {
                    if (d._id === c._id) {
                        var sortDate = _.sortBy(c.count_data.filter(o => o.date !== day), function (dateObj) {
                            return new Date(dateObj.date);
                        }).reverse();
                        var sortToday = _.sortBy(c.count_data.filter(o => o.date === day), function (dateObj) {
                            return new Date(dateObj.date);
                        }).reverse();

                        if (sortDate[0]) {
                            countA.push(sortDate[0].count);
                            countADate.push(sortDate[0].date);
                            notesA.push(sortDate[0].notes);
                        }
                        if (sortDate[1]) {
                            countB.push(sortDate[1].count);
                            countBDate.push(sortDate[1].date);
                            notesB.push(sortDate[1].notes);
                        }
                        if (sortDate[2]) {
                            countC.push(sortDate[2].count);
                            countCDate.push(sortDate[2].date);
                            notesC.push(sortDate[2].notes);
                        }
                        if (sortDate[3]) {
                            countD.push(sortDate[3].count);
                            countDDate.push(sortDate[3].date);
                        }
                        if (sortToday[0]) {
                            countT.push(sortToday[0].count);
                            notesT.push(sortToday[0].notes);
                        }

                        var dA = moment(new Date(countADate)).utc();
                        var dB = moment(new Date(countBDate)).utc();
                        var dC = moment(new Date(countCDate)).utc();
                        var dD = moment(new Date(countDDate)).utc();
                        var dT = moment(new Date());
                        var diffA = dA.diff(dB, 'days');
                        var diffB = dB.diff(dC, 'days');
                        var diffC = dC.diff(dD, 'days');
                        var diffT = dT.diff(dA, 'days');
                        if (Number.isNaN(diffA)) { diffAVal = "-" } else { diffAVal = diffA };
                        if (Number.isNaN(diffB)) { diffBVal = "-" } else { diffBVal = diffB };
                        if (Number.isNaN(diffC)) { diffCVal = "-" } else { diffCVal = diffC };
                        if (Number.isNaN(diffT)) { diffTVal = "-" } else { diffTVal = diffT };

                        increaseA = countA - countB;
                        perincreaseA = parseFloat(((countA - countB) / countB) * 100).toFixed(0) + "%";

                        increaseB = countB - countC;
                        perincreaseB = parseFloat(((countB - countC) / countC) * 100).toFixed(0) + "%";

                        increaseC = countC - countD;
                        perincreaseC = parseFloat(((countC - countD) / countD) * 100).toFixed(0) + "%";

                        increaseT = countT - countA;
                        perincreaseT = parseFloat(((countT - countA) / countT) * 100).toFixed(0) + "%";
                    }
                })

                var nd = moment(d.date).utc().format('M/DD/YYYY');
                render.push({
                    ...item,
                    client: artist,
                    date: nd,
                    venue: venue,
                    city: city,
                    state: state,
                    sellable: item.sellable_cap[i].amt,
                    countT: countT,
                    perincreaseT: perincreaseT,
                    day: day,
                    diffTVal: diffTVal,
                    increaseT: increaseT,
                    perincreaseA: perincreaseA,
                    perincreaseB: perincreaseB,
                    perincreaseC: perincreaseC,
                    date_id: d._id,
                    countADate: countADate,
                    countBDate: countBDate,
                    countCDate: countCDate,
                    diffAVal: diffAVal,
                    diffBVal: diffBVal,
                    diffCVal: diffCVal,
                    increaseA: increaseA,
                    increaseB: increaseB,
                    increaseC: increaseC,
                    countA: countA,
                    countB: countB,
                    countC: countC,
                    index: index,
                    notesT: notesT,
                    notesA: notesA,
                    notesB: notesB,
                    notesC: notesC
                })
            })
            arrData.push(...render);
        })
        this.props.setCountsGrid(arrData);
        this.setState({
            render_data: arrData,
            count_data: count_data,
            counts_loading: false
        })
    }

    async setCountsGridSocket(offer) {
        // var offers = this.props.offers.offers;
        // offers = await this.filterCountsStart(offers);
        var id = offer._id;
        var venueid = offer.venue_id;
        var venue = [];
        var city = [];
        var state = [];
        this.props.items.items.map((venues, i) => {
            if (venueid === venues._id) {
                venue = venues.venue_name
                city = venues.address.city
                state = venues.address.state
            }
        })

        this.props.festivals.items.map((venues, i) => {
            if (venueid === venues._id) {
                venue = venues.festival
                city = venues.address.city
                state = venues.address.state
            }
        })

        var buyerid = offer.buyer_id;
        var buyer = [];
        this.props.buyers.buyers.map((buyers, key) => {
            if (buyerid === buyers._id) {
                buyer = buyers.company
            }
        })

        var artistid = offer.client_id;
        var artist = [];
        this.props.clients.clients.map((client, key) => {
            if (artistid === client._id) {
                artist = client.client
            }
        })


        var counts = [];
        this.state.count_data.map((c, i) => {
            if (id === c._id) {
                counts = c.counts;
            }
        });

        var day = moment().format('YYYY-MM-DD').toString();

        var render = [];
        offer.show_info.forEach((d, i) => {

            var countA = [];
            var countADate = [];
            var countB = [];
            var countBDate = [];
            var countC = [];
            var countCDate = [];
            var countD = [];
            var countDDate = [];
            var countT = [];
            var notesA = [];
            var notesB = [];
            var notesC = [];
            var notesT = [];
            var increaseA = '';
            var perincreaseA = '';
            var increaseB = '';
            var perincreaseB = '';
            var increaseC = '';
            var perincreaseC = '';
            var increaseT = '';
            var perincreaseT = '';
            var diffAVal = [];
            var diffBVal = [];
            var diffCVal = [];
            var diffTVal = [];

            counts.map(c => {
                if (d._id === c._id) {
                    var sortDate = _.sortBy(c.count_data.filter(o => o.date !== day), function (dateObj) {
                        return new Date(dateObj.date);
                    }).reverse();
                    var sortToday = _.sortBy(c.count_data.filter(o => o.date === day), function (dateObj) {
                        return new Date(dateObj.date);
                    }).reverse();

                    if (sortDate[0]) {
                        countA.push(sortDate[0].count);
                        countADate.push(sortDate[0].date);
                        notesA.push(sortDate[0].notes);
                    }
                    if (sortDate[1]) {
                        countB.push(sortDate[1].count);
                        countBDate.push(sortDate[1].date);
                        notesB.push(sortDate[1].notes);
                    }
                    if (sortDate[2]) {
                        countC.push(sortDate[2].count);
                        countCDate.push(sortDate[2].date);
                        notesC.push(sortDate[2].notes);
                    }
                    if (sortDate[3]) {
                        countD.push(sortDate[3].count);
                        countDDate.push(sortDate[3].date);
                    }
                    if (sortToday[0]) {
                        countT.push(sortToday[0].count);
                        notesT.push(sortToday[0].notes);
                    }

                    var dA = moment(new Date(countADate)).utc();
                    var dB = moment(new Date(countBDate)).utc();
                    var dC = moment(new Date(countCDate)).utc();
                    var dD = moment(new Date(countDDate)).utc();
                    var dT = moment(new Date());
                    var diffA = dA.diff(dB, 'days');
                    var diffB = dB.diff(dC, 'days');
                    var diffC = dC.diff(dD, 'days');
                    var diffT = dT.diff(dA, 'days');
                    if (Number.isNaN(diffA)) { diffAVal = "-" } else { diffAVal = diffA };
                    if (Number.isNaN(diffB)) { diffBVal = "-" } else { diffBVal = diffB };
                    if (Number.isNaN(diffC)) { diffCVal = "-" } else { diffCVal = diffC };
                    if (Number.isNaN(diffT)) { diffTVal = "-" } else { diffTVal = diffT };

                    increaseA = countA - countB;
                    perincreaseA = parseFloat(((countA - countB) / countB) * 100).toFixed(0) + "%";

                    increaseB = countB - countC;
                    perincreaseB = parseFloat(((countB - countC) / countC) * 100).toFixed(0) + "%";

                    increaseC = countC - countD;
                    perincreaseC = parseFloat(((countC - countD) / countD) * 100).toFixed(0) + "%";

                    increaseT = countT - countA;
                    perincreaseT = parseFloat(((countT - countA) / countT) * 100).toFixed(0) + "%";
                }
            })
            var nd = moment(d.date).utc().format('M/DD/YYYY');
            const newObj = {
                ...offer,
                client: artist,
                date: nd,
                venue: venue,
                city: city,
                state: state,
                sellable: offer.sellable_cap[i].amt,
                countT: countT,
                perincreaseT: perincreaseT,
                day: day,
                diffTVal: diffTVal,
                increaseT: increaseT,
                perincreaseA: perincreaseA,
                perincreaseB: perincreaseB,
                perincreaseC: perincreaseC,
                date_id: d._id,
                countADate: countADate,
                countBDate: countBDate,
                countCDate: countCDate,
                diffAVal: diffAVal,
                diffBVal: diffBVal,
                diffCVal: diffCVal,
                increaseA: increaseA,
                increaseB: increaseB,
                increaseC: increaseC,
                countA: countA,
                countB: countB,
                countC: countC,
                // index: index,
                notesT: notesT,
                notesA: notesA,
                notesB: notesB,
                notesC: notesC
            }
        })
    }

    updateCountsGrid = (cnts) => {
        //var counts = [];
        // var ren = this.state.render_data;
        // const show_index = ren.findIndex(obj => obj._id === cnts._id);
        // if (show_index >= 0) {
        //     var counts = cnts.counts;
        //     var single = ren[show_index];

        //     var day = moment().format('YYYY-MM-DD').toString();
        //     var countA = [];
        //     var countADate = [];
        //     var countB = [];
        //     var countBDate = [];
        //     var countC = [];
        //     var countCDate = [];
        //     var countD = [];
        //     var countDDate = [];
        //     var countT = [];
        //     var notesT = [];
        //     var increaseA = '';
        //     var perincreaseA = '';
        //     var increaseB = '';
        //     var perincreaseB = '';
        //     var increaseC = '';
        //     var perincreaseC = '';
        //     var increaseT = '';
        //     var perincreaseT = '';
        //     var diffAVal = [];
        //     var diffBVal = [];
        //     var diffCVal = [];
        //     var diffTVal = [];

        //     single.show_info.forEach((d, i) => {
        //         counts.map(c => {
        //             const date_index = ren.findIndex(obj => obj.date_id === c._id);
        //             if (d._id === c._id) {
        //                 var sortDate = _.sortBy(c.count_data.filter(o => o.date !== day), function (dateObj) {
        //                     return new Date(dateObj.date);
        //                 }).reverse();
        //                 var sortToday = _.sortBy(c.count_data.filter(o => o.date === day), function (dateObj) {
        //                     return new Date(dateObj.date);
        //                 }).reverse();

        //                 if (sortDate[0]) {
        //                     countA.push(sortDate[0].count);
        //                     countADate.push(sortDate[0].date);
        //                 }
        //                 if (sortDate[1]) {
        //                     countB.push(sortDate[1].count);
        //                     countBDate.push(sortDate[1].date);
        //                 }
        //                 if (sortDate[2]) {
        //                     countC.push(sortDate[2].count);
        //                     countCDate.push(sortDate[2].date);
        //                 }
        //                 if (sortDate[3]) {
        //                     countD.push(sortDate[3].count);
        //                     countDDate.push(sortDate[3].date);
        //                 }
        //                 if (sortToday[0]) {
        //                     countT.push(sortToday[0].count);
        //                     notesT.push(sortToday[0].notes);
        //                 }

        //                 var dA = moment(new Date(countADate));
        //                 var dB = moment(new Date(countBDate));
        //                 var dC = moment(new Date(countCDate));
        //                 var dD = moment(new Date(countDDate));
        //                 var dT = moment(new Date());
        //                 var diffA = dA.diff(dB, 'days');
        //                 var diffB = dB.diff(dC, 'days');
        //                 var diffC = dC.diff(dD, 'days');
        //                 var diffT = dT.diff(dA, 'days');
        //                 if (Number.isNaN(diffA)) { diffAVal = "-" } else { diffAVal = diffA };
        //                 if (Number.isNaN(diffB)) { diffBVal = "-" } else { diffBVal = diffB };
        //                 if (Number.isNaN(diffC)) { diffCVal = "-" } else { diffCVal = diffC };
        //                 if (Number.isNaN(diffT)) { diffTVal = "-" } else { diffTVal = diffT };

        //                 increaseA = countA - countB;
        //                 perincreaseA = parseFloat(((countA - countB) / countB) * 100).toFixed(0) + "%";

        //                 increaseB = countB - countC;
        //                 perincreaseB = parseFloat(((countB - countC) / countC) * 100).toFixed(0) + "%";

        //                 increaseC = countC - countD;
        //                 perincreaseC = parseFloat(((countC - countD) / countD) * 100).toFixed(0) + "%";

        //                 increaseT = countT - countA;
        //                 perincreaseT = parseFloat(((countT - countA) / countT) * 100).toFixed(0) + "%";


        //                 ren[date_index].countT = countT;
        //                 ren[date_index].perincreaseT = perincreaseT;
        //                 ren[date_index].day = day;
        //                 ren[date_index].diffTVal = diffTVal;
        //                 ren[date_index].increaseT = increaseT;
        //             }
        //         })
        //     })
        // } else {
        //     alert('Error updating count')
        // }

        this.props.updateCountsGrid(cnts);
        // this.setState({
        //     render_data: ren
        // })
    }

    filterCountsStart = (offers) => {
        let filtered_offers = [];
        filtered_offers = offers.filter((item) => {
            var filterbatch = item.status
            return filterbatch.indexOf('Confirmed') >= 0
        })
        // filtered_offers = filtered_offers.filter((item) => {
        //     var filterbatch = false;
        //     if (item.count_config) {
        //         if (item.count_config.active === undefined) {
        //             filterbatch = true
        //         } else {
        //             filterbatch = item.count_config.active
        //         }
        //     } else {
        //         filterbatch = true
        //     }
        //     return filterbatch === true || filterbatch === undefined
        // })
        filtered_offers = filtered_offers.filter((item) => {
            const today = moment();
            var val = _.get(item, 'show_info')
            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            var filterBatch = new Date(closest.date)
            var start = this.props.counts_filter.start_date
            return filterBatch >= start
        })

        // var counts_filter = this.props.counts_filter;
        // var filtered_start = count_data.filter((item) => {
        //     const today = moment();
        //     var val = _.get(item, 'show_info')
        //     const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
        //     var filterBatch = new Date(closest.date)
        //     var start = counts_filter.start_date
        //     return filterBatch > start
        // })
        // var filtered_end = filtered_start.filter((item) => {
        //     const today = new Date();
        //     var val = _.get(item, 'show_info')
        //     const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
        //     var filterBatch = new Date(furthest.date)
        //     var end = counts_filter.end_date
        //     return filterBatch <= end
        // })
        // var filteredArtist = filtered_end.filter((item) => {
        //     var filterBatch = item.client_id
        //     return filterBatch.indexOf(counts_filter.client) >= 0
        // })
        // var filteredVenue = filteredArtist.filter((item) => {
        //     var filterBatch = item.venue_name
        //     return filterBatch.indexOf(counts_filter.venue) >= 0
        // })
        // var filteredState = filteredVenue.filter((item) => {
        //     var filterBatch = item.venue_state
        //     return filterBatch.indexOf(counts_filter.state) >= 0
        // })
        // var filteredCity = filteredState.filter((item) => {
        //     var filterBatch = item.venue_city
        //     return filterBatch.indexOf(counts_filter.city) >= 0
        // })
        // var filteredCountry = filteredCity.filter((item) => {
        //     var filterBatch = item.venue_country
        //     return filterBatch.indexOf(counts_filter.country) >= 0
        // })
        return filtered_offers;
    }

    handleEventPositioned() {
        ReactTooltip.rebuild();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleCountChange(e, id, index, show_id) {
        //let keys = this.state.todayscount.remove(id);
        //let todays_counts = this.state.todayscount;
        //var sIndex = todays_counts.findIndex(obj => obj._id === id)
        if (e) {
            this.setState({
                todayscount: {
                    ...this.state.todayscount,
                    [id]: e
                }
            })
        } else {
            const todaysCount = {
                ...this.state.todayscount
            }
            delete todaysCount[id];

            this.setState({
                todayscount: {
                    ...todaysCount
                }
            })
        }

    }

    handleNotesChange(e, id, index, show_id) {
        //let keys = this.state.todayscount.remove(id);
        //let todays_counts = this.state.todayscount;
        //var sIndex = todays_counts.findIndex(obj => obj._id === id)
        if (e) {
            this.setState({
                todaysnotes: {
                    ...this.state.todaysnotes,
                    [id]: e
                }
            })
        } else {
            const todaysNotes = {
                ...this.state.todaysnotes
            }
            delete todaysNotes[id];

            this.setState({
                todaysnotes: {
                    ...todaysNotes
                }
            })
        }

    }

    handleStDateChange = date => {
        this.setState({
            startDate: date
        });
    };

    handleEndDateChange = date => {
        this.setState({
            endDate: date
        });
    };

    formatTime(timestamp) {
        const d = new Date(timestamp);
        const time = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
        return time;
    }

    toggleSort = () => {
        if (this.state.sortdirection === "asc") {
            this.setState({
                sortdirection: 'desc'
            })
        } else {
            this.setState({
                sortdirection: 'asc'
            })
        }
    }

    // handleSave = () => {

    //     var keys = Object.keys(this.props.todays_counts);
    //     var today = moment().format('M/D/YYYY');
    //     var values = [];
    //     var noteKeys = Object.keys(this.props.todays_notes);

    //     //this.props.counts.counts.map((item, index) => {
    //     //    for (var i = 0; i < keys.length; i++) {
    //     //        if (item._id === keys[i]) {
    //     //            values.push({
    //     //                _id: item._id,
    //     //                counts: {
    //     //                    _id: today,
    //     //                    count: this.state.todays_counts[item._id],
    //     //                    date: today
    //     //                }
    //     //            });
    //     //        }
    //     //    }
    //     //});

    //     for (var i = 0; i < keys.length; i++) {
    //         var notes = null;
    //         for (var b = 0; b < noteKeys.length; b++) {
    //             if (keys[i] === noteKeys[b]) {
    //                 notes = this.props.todays_notes[noteKeys[b]]
    //             }
    //         }
    //         var newKeys = keys[i].split(', ')
    //         const showid = newKeys[0];
    //         const dateid = newKeys[1];
    //         if(notes === null){
    //             var vIndex = this.props.counts.counts.findIndex(item => item._id === dateid);
    //             if(vIndex >= 0){
    //                 var sh = this.props.counts.counts[vIndex];
    //                 var sIndex = sh.counts.findIndex(item => item._id === showid);
    //                 if(sIndex >= 0){
    //                     var day = moment().format('YYYY-MM-DD').toString();
    //                     var sortDate = _.sortBy(sh.counts[sIndex].count_data.filter(o => o.date !== day), function (dateObj) {
    //                         return new Date(dateObj.date);
    //                     }).reverse();
    //                     notes = sortDate[0].notes;
    //                 } else {
    //                     notes = "";
    //                 }
    //             } else {
    //                 notes = "";
    //             }
    //         }
    //         const dateString = moment().format('YYYY-MM-DD').toString();
    //         const time = moment().format('HH:mm:ss')
    //         const tz = moment.tz.guess();

    //         values.push({
    //             _id: dateid,
    //             showid: showid,
    //             counts: {
    //                 date: dateString,
    //                 time: time,
    //                 tz: tz,
    //                 count: this.props.todays_counts[keys[i]],
    //                 notes: notes
    //             }
    //         });
    //     }

    //     const counts = {
    //         values
    //     };
    //     this.props.addCountsBulk(counts);
    //     this.props.clearTCInput();
    // }

    renderColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={this.props.counts_checked.includes(cellData._id + ", " + cellData.date_id) || false}
                    color="default"
                    value={cellData._id + ", " + cellData.date_id}
                    name="counts_checked"
                    onChange={this.props.handleDatabaseCheckbox}
                />
                {/* <Chip
                    size="small"
                    style={{ opacity: .8 }}
                    color={"primary"}
                    clickable
                    label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
                    onClick={() => this.iconRenderOffer(cellData._id)}
                /> */}
                <span style={{ width: 25, height: '100%' }} onClick={() => this.iconRenderOffer(cellData._id)}>
                    <FaExternalLinkSquareAlt size="16" className="dashboard-offer-icons-exp" />
                </span>
            </div>
        )
    }

    iconRenderOffer = (id) => {
        var i;
        this.props.offers.offers.map((item, index) => {
            if (item._id === id) {
                i = item;
            }
        })
        if (i) {
            this.props.iconRenderOffer(i)
        } else {
            alert('Error loading offer')
        }
    }

    dateColumnData = ({ cellData, dataKey }) => {
        const datesArray = cellData.show_info.map(d => moment(d.date).utc());
        var start = moment.min(datesArray).format('MM/DD/YYYY');
        return (
            start
        )
    }

    countTColumnData = ({ cellData, dataKey }) => {
        if (cellData.notesT) {

        }
        if (cellData.count_config && cellData.count_config.active || cellData.count_config.active === undefined) {
            if (cellData.countT > "") {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }} data-tip={cellData.perincreaseT === "Infinity%" || cellData.perincreaseT === "NaN%" ? cellData.day + "<br/>Day Wrap: " + cellData.diffTVal + "<br/>" + cellData.increaseT + " tickets sold<br/>" + cellData.notesT : cellData.day + "<br/>Day Wrap: " + cellData.diffTVal + "<br/>" + cellData.increaseT + " tickets sold<br/>(" + cellData.perincreaseT + " increase)<br/>" + cellData.notesT} onMouseEnter={this.handleEventPositioned}>{cellData.countT}</div>
                )
            } else {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}><input style={{ width: 80, height: 25, color: '#000', backgroundColor: this.props.todays_counts[cellData.date_id + ", " + cellData._id] ? '#e6f7ff' : '#fff', border: '.5px solid #555' }} type="number" value={this.props.todays_counts[cellData.date_id + ", " + cellData._id] || ""} tabIndex={cellData.index + 1} onChange={(e) => this.props.handleCountChange(e.target.value, cellData.date_id, cellData.index, cellData._id)} disabled={cellData.count_config && cellData.count_config.active ? false : cellData.count_config.active === undefined ? false : true} /></div>
                )
            }
        } else {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>-</div>
            )
        }
    }

    countNColumnData = ({ cellData, dataKey }) => {
        // return (
        //     <div style={{ display: 'flex', alignItems: 'center' }}> </div>
        // )
        if (cellData.count_config && cellData.count_config.active || cellData.count_config.active === undefined) {
            if (cellData.countT > "") {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }} onMouseEnter={this.handleEventPositioned}>{cellData.notesT}</div>
                )
            } else {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}><input style={{ width: 80, height: 25, color: '#000', backgroundColor: this.props.todays_counts[cellData.date_id + ", " + cellData._id] ? '#e6f7ff' : '#fff', border: '.5px solid #555', width: '100%' }} type="text" value={this.props.todays_notes[cellData.date_id + ", " + cellData._id] ? this.props.todays_notes[cellData.date_id + ", " + cellData._id] : cellData.notesA} tabIndex={cellData.index + 1} onChange={(e) => this.props.handleNotesChange(e.target.value, cellData.date_id, cellData.index, cellData._id)} /></div>
                )
            }
        } else {
            return (
                <div style={{ display: 'flex', alignItems: 'center', color: '#cdcdcd' }}>{cellData.count_config.notes ? cellData.count_config.notes : 'No counts collected'}</div>
            )
        }
    }

    countLocColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ width: '100%' }}>{cellData.city}, {cellData.state}</div>
        )
    }

    countAColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }} data-tip={cellData.perincreaseA === "Infinity%" || cellData.perincreaseA === "NaN%" ? cellData.countADate + "<br/>Day Wrap: " + cellData.diffAVal + "<br/>" + cellData.increaseA + " tickets sold" : cellData.countADate + "<br/>Day Wrap: " + cellData.diffAVal + "<br/>" + cellData.increaseA + " tickets sold<br/>(" + cellData.perincreaseA + " increase)"} onMouseEnter={this.handleEventPositioned}>
                {cellData.countA}
            </div>
        )
    }

    prevCountColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ width: '100%', display: 'flex' }}>
                <span style={{ width: 100, borderRight: '1px solid rgb(190, 190, 190)' }} data-tip={cellData.perincreaseA === "Infinity%" || cellData.perincreaseA === "NaN%" ? cellData.countADate + "<br/>Day Wrap: " + cellData.diffAVal + "<br/>" + cellData.increaseA + " tickets sold<br/>" + cellData.notesA : cellData.countADate + "<br/>Day Wrap: " + cellData.diffAVal + "<br/>" + cellData.increaseA + " tickets sold<br/>(" + cellData.perincreaseA + " increase)<br/>" + cellData.notesA} onMouseEnter={this.handleEventPositioned}>
                    {cellData.countA}
                </span>
                <span style={{ width: 100, borderRight: '1px solid rgb(190, 190, 190)', paddingLeft: 15 }} data-tip={cellData.perincreaseB === "Infinity%" || cellData.perincreaseB === "NaN%" ? cellData.countBDate + "<br/>Day Wrap: " + cellData.diffBVal + "<br/>" + cellData.increaseB + " tickets sold<br/>" + cellData.notesB : cellData.countBDate + "<br/>Day Wrap: " + cellData.diffBVal + "<br/>" + cellData.increaseB + " tickets sold<br/>(" + cellData.perincreaseB + " increase)<br/>" + cellData.notesB} onMouseEnter={this.handleEventPositioned}>
                    {cellData.countB}
                </span>
                <span style={{ width: 100, paddingLeft: 15 }} data-tip={cellData.perincreaseC === "Infinity%" || cellData.perincreaseC === "NaN%" ? cellData.countCDate + "<br/>Day Wrap: " + cellData.diffCVal + "<br/>" + cellData.increaseC + " tickets sold<br/>" + cellData.notesC : cellData.countCDate + "<br/>Day Wrap: " + cellData.diffCVal + "<br/>" + cellData.increaseC + " tickets sold<br/>(" + cellData.perincreaseC + " increase)<br/>" + cellData.notesC} onMouseEnter={this.handleEventPositioned}>
                    {cellData.countC}
                </span>
            </div>
        )
    }

    countBColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }} data-tip={cellData.perincreaseB === "Infinity%" || cellData.perincreaseB === "NaN%" ? cellData.countBDate + "<br/>Day Wrap: " + cellData.diffBVal + "<br/>" + cellData.increaseB + " tickets sold" : cellData.countBDate + "<br/>Day Wrap: " + cellData.diffBVal + "<br/>" + cellData.increaseB + " tickets sold<br/>(" + cellData.perincreaseB + " increase)"} onMouseEnter={this.handleEventPositioned}>
                {cellData.countB}
            </div>
        )
    }

    countCColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }} data-tip={cellData.perincreaseC === "Infinity%" || cellData.perincreaseC === "NaN%" ? cellData.countCDate + "<br/>Day Wrap: " + cellData.diffCVal + "<br/>" + cellData.increaseC + " tickets sold" : cellData.countCDate + "<br/>Day Wrap: " + cellData.diffCVal + "<br/>" + cellData.increaseC + " tickets sold<br/>(" + cellData.perincreaseC + " increase)"} onMouseEnter={this.handleEventPositioned}>
                {cellData.countC}
            </div>
        )
    }

    rowStyleFormat(row) {
        if (row.index < 0) return;
        const list = this.props.counts.counts_rol_filtered;
        if (this.props.counts_checked.includes(list[row.index]._id + ", " + list[row.index].date_id)) {
            return {
                backgroundColor: '#e6f7ff'
            };
        }
        return {
            backgroundColor: null,
        };
    }

    handleDatabaseCheckbox = (n, v) => {
        const e = {
            target: {
                name: n,
                value: v
            }
        }
        this.props.handleDatabaseCheckbox(e)
    }

    // rowSelect(row) {
    //     if (row.index < 0) return;
    //     const list = this.state.render_data;
    //     this.handleDatabaseCheckbox('counts_checked', list[row.index]._id+", "+list[row.index].date_id);
    // }

    rowDoubleClick(row) {
        if (row.index < 0) return;
        this.props.iconRenderOffer(row.rowData)
    }

    normCellRender = ({ cellData, dataKey }) => {
        return (
            <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center' }} onClick={this.rowSelect.bind(this, cellData.id + ", " + cellData.date_id)}>
                {cellData.cell}
            </div>
        )
    }

    rowSelect(id) {
        if (!id) return;
        this.handleDatabaseCheckbox('counts_checked', id);
    }

    headerRowRenderer = ({ className, columns, style }) => {
        return (
            <div
                className={className}
                role='row'
                style={style}
            >
                {columns}
            </div>
        )
    }

    headerRendererMain = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        if (this.props.counts_sort.sort_cat === dataKey) {
            return (
                <div style={{ width: '100%' }} onClick={() => this.props.toggleSortCounts(dataKey)}><div style={{ display: 'flex', width: '100%' }}><div style={{ width: dataKey === 'sellable' ? '80%' : dataKey === 'client' || dataKey === 'venue' ? '85%' : '90%' }}>{label}</div><div style={{ width: '10%' }}>{this.props.counts_sort.sort_direction === 'asc' ? <FaCaretUp size="15" color="#cdcdcd" /> : <FaCaretDown size="15" color="#cdcdcd" />}</div></div></div>
            )
        } else {
            return (
                <div onClick={() => this.props.toggleSortCatCounts(dataKey)}>{label}</div>
            )
        }
    }

    headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        var today = moment().format('M/DD/YYYY');
        // if (Object.keys(this.props.todays_counts).length > 0) {
        //     return (
        //         <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}><span style={{ width: '70%' }}>{today}</span><span style={{ width: '30%' }}><FaSave size="18" color="#fff" onClick={this.props.handleCountsSave} /></span></div>
        //     )
        // } else {
        return (
            <div style={{ width: '100%' }}>{today}</div>
        )
        // }
    }

    headerStartRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                <div style={{ width: '60%' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        color="default"
                        checked={this.props.counts_all_checked}
                        //value={props.checked}
                        name="counts_checked"
                        onChange={this.props.handleDatabaseAllCheckbox}
                    /></div>
                <div style={{ width: '40%', height: 30 }}>
                    {this.props.counts_sort.sort_cat ?
                        <FaRedoAlt style={{ marginTop: 9 }} size="12" color="#cdcdcd" onClick={() => this.props.clearSorted('counts_sort')} /> :
                        this.props.counts_checked.length > 0 ?
                            <FaMinusSquare style={{ marginTop: 6 }} size="18" color="#cdcdcd" onClick={() => this.props.handleDatabaseClearCheckbox('counts_checked')} />
                            : null}
                </div>
            </div>
        )
    }


    render() {

        // {this.state.counts_loading ?
        //     <h2 style={{ marginLeft: 20, marginTop: 20, fontSize: 12, color: '#000' }}>Loading..</h2> :
        //     filteredConfirmed.map((item, index) => {

        //         var id = item._id;
        //         var venueid = item.venue_id;
        //         var venue = [];
        //         var city = [];
        //         var state = [];
        //         this.props.items.items.map((venues, i) => {
        //             var key = i;
        //             if (venueid === venues._id) {
        //                 venue = venues.venue_name
        //                 city = venues.address.city
        //                 state = venues.address.state
        //             }
        //         })

        //         var buyerid = item.buyer_id;
        //         var buyer = [];
        //         this.props.buyers.buyers.map((buyers, key) => {
        //             if (buyerid === buyers._id) {
        //                 buyer = buyers.company
        //             }
        //         })

        //         var artistid = item.client_id;
        //         var artist = [];
        //         this.props.clients.clients.map((client, key) => {
        //             if (artistid === client._id) {
        //                 artist = client.client
        //             }
        //         })

        //         var counts = [];
        //         this.state.count_data.map((c, i) => {
        //             if (id === c._id) {
        //                 counts = c.counts;
        //             }
        //         });

        //         var day = moment().format('M/D/YYYY');

        //         var render = [];
        //         item.show_info.forEach((d, i) => {

        //             var countA = [];
        //             var countADate = [];
        //             var countB = [];
        //             var countBDate = [];
        //             var countC = [];
        //             var countCDate = [];
        //             var countD = [];
        //             var countDDate = [];
        //             var countT = [];
        //             var notesT = [];
        //             var increaseA = '';
        //             var perincreaseA = '';
        //             var increaseB = '';
        //             var perincreaseB = '';
        //             var increaseC = '';
        //             var perincreaseC = '';
        //             var increaseT = '';
        //             var perincreaseT = '';
        //             var diffAVal = [];
        //             var diffBVal = [];
        //             var diffCVal = [];
        //             var diffTVal = [];

        //             counts.map(c => {
        //                 if (d._id === c._id) {
        //                     var sortDate = _.sortBy(c.count_data.filter(o => o.date !== day), function (dateObj) {
        //                         return new Date(dateObj.date);
        //                     }).reverse();
        //                     var sortToday = _.sortBy(c.count_data.filter(o => o.date === day), function (dateObj) {
        //                         return new Date(dateObj.date);
        //                     }).reverse();

        //                     if (sortDate[0]) {
        //                         countA.push(sortDate[0].count);
        //                         countADate.push(sortDate[0].date);
        //                     }
        //                     if (sortDate[1]) {
        //                         countB.push(sortDate[1].count);
        //                         countBDate.push(sortDate[1].date);
        //                     }
        //                     if (sortDate[2]) {
        //                         countC.push(sortDate[2].count);
        //                         countCDate.push(sortDate[2].date);
        //                     }
        //                     if (sortDate[3]) {
        //                         countD.push(sortDate[3].count);
        //                         countDDate.push(sortDate[3].date);
        //                     }
        //                     if (sortToday[0]) {
        //                         countT.push(sortToday[0].count);
        //                         notesT.push(sortToday[0].notes);
        //                     }

        //                     var dA = moment(new Date(countADate));
        //                     var dB = moment(new Date(countBDate));
        //                     var dC = moment(new Date(countCDate));
        //                     var dD = moment(new Date(countDDate));
        //                     var dT = moment(new Date());
        //                     var diffA = dA.diff(dB, 'days');
        //                     var diffB = dB.diff(dC, 'days');
        //                     var diffC = dC.diff(dD, 'days');
        //                     var diffT = dT.diff(dA, 'days');
        //                     if (Number.isNaN(diffA)) { diffAVal = "-" } else { diffAVal = diffA };
        //                     if (Number.isNaN(diffB)) { diffBVal = "-" } else { diffBVal = diffB };
        //                     if (Number.isNaN(diffC)) { diffCVal = "-" } else { diffCVal = diffC };
        //                     if (Number.isNaN(diffT)) { diffTVal = "-" } else { diffTVal = diffT };

        //                     increaseA = countA - countB;
        //                     perincreaseA = parseFloat(((countA - countB) / countB) * 100).toFixed(0) + "%";

        //                     increaseB = countB - countC;
        //                     perincreaseB = parseFloat(((countB - countC) / countC) * 100).toFixed(0) + "%";

        //                     increaseC = countC - countD;
        //                     perincreaseC = parseFloat(((countC - countD) / countD) * 100).toFixed(0) + "%";

        //                     increaseT = countT - countA;
        //                     perincreaseT = parseFloat(((countT - countA) / countT) * 100).toFixed(0) + "%";
        //                 }
        //             })

        //             var nd = moment(d.date).format('M/DD/YYYY');
        //             render.push(
        //                 <ContextMenuTrigger key={i} id={'SIMPLE'} >
        //                     <table id="customers">
        //                         <tbody>
        //                             <tr style={{ backgroundColor: index % 2 === 0 ? "#F5F5F5" : "#fff" }}>
        //                                 <td style={{ width: '6%' }}>
        //                                     <Checkbox
        //                                         icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //                                         checkedIcon={<CheckBoxIcon fontSize="small" />}
        //                                         color="default"
        //                                         value={item._id}
        //                                         name="buyerchecked"
        //                                     />
        //                                     <Chip
        //                                         size="small"
        //                                         style={{ opacity: .8 }}
        //                                         color={"primary"}
        //                                         clickable
        //                                         label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
        //                                     />
        //                                 </td>
        //                                 <td style={{ width: '12%', fontSize: 12, height: 32, paddingLeft: 5 }}>{artist}</td>
        //                                 <td style={{ width: '8%', fontSize: 12, height: 32, paddingLeft: 5 }}>{nd}</td>
        //                                 <td style={{ width: '11%', fontSize: 12, height: 32, paddingLeft: 5 }}>{venue}</td>
        //                                 <td style={{ width: '13%', fontSize: 12, height: 32, paddingLeft: 5 }}>{city}, {state}</td>
        //                                 <td style={{ width: '8%', fontSize: 12, height: 32, paddingLeft: 5 }}>{item.sellable_cap[i].amt}</td>
        //                                 {countT > "" ?
        //                                     <td style={{ width: '8%', fontSize: 12, height: 32, paddingLeft: 5 }} data-tip={perincreaseT === "Infinity%" || perincreaseT === "NaN%" ? day + "<br/>Day Wrap: " + diffTVal + "<br/>" + increaseT + " tickets sold" : day + "<br/>Day Wrap: " + diffTVal + "<br/>" + increaseT + " tickets sold<br/>(" + perincreaseT + " increase)"} onMouseEnter={this.handleEventPositioned}>{countT}</td> :
        //                                     <td style={{ width: '8%', fontSize: 12, height: 32 }}><input style={{ width: '100%', height: '100%', color: '#000', backgroundColor: this.props.todays_counts[d._id] || this.props.todays_notes[d._id] ? '#e6f7ff' : '#fff', border: '.5px solid #555' }} type="number" value={this.props.todays_counts[d._id] || ""} onChange={(e) => this.props.handleCountChange(e.target.value, d._id, index, d._id)} /></td>}
        //                                 <td style={{ width: '8%', fontSize: 12, height: 32, paddingLeft: 5 }} data-tip={perincreaseA === "Infinity%" || perincreaseA === "NaN%" ? countADate + "<br/>Day Wrap: " + diffAVal + "<br/>" + increaseA + " tickets sold" : countADate + "<br/>Day Wrap: " + diffAVal + "<br/>" + increaseA + " tickets sold<br/>(" + perincreaseA + " increase)"} onMouseEnter={this.handleEventPositioned}>{countA}</td>
        //                                 <td style={{ width: '8%', fontSize: 12, height: 32, paddingLeft: 5 }} data-tip={perincreaseB === "Infinity%" || perincreaseB === "NaN%" ? countBDate + "<br/>Day Wrap: " + diffBVal + "<br/>" + increaseB + " tickets sold" : countBDate + "<br/>Day Wrap: " + diffBVal + "<br/>" + increaseB + " tickets sold<br/>(" + perincreaseB + " increase)"} onMouseEnter={this.handleEventPositioned}>{countB}</td>
        //                                 <td style={{ width: '8%', fontSize: 12, height: 32, paddingLeft: 5 }} data-tip={perincreaseC === "Infinity%" || perincreaseC === "NaN%" ? countCDate + "<br/>Day Wrap: " + diffCVal + "<br/>" + increaseC + " tickets sold" : countCDate + "<br/>Day Wrap: " + diffCVal + "<br/>" + increaseC + " tickets sold<br/>(" + perincreaseC + " increase)"} onMouseEnter={this.handleEventPositioned}>{countC}</td>
        //                                 {countT > "" ?
        //                                     <td style={{ width: '14%', fontSize: 12, height: 32, paddingLeft: 5 }}>{notesT}</td> :
        //                                     <td style={{ width: '14%', fontSize: 12, height: 32 }}><input style={{ width: '100%', height: '100%', color: '#000', backgroundColor: this.props.todays_counts[d._id] || this.props.todays_notes[d._id] ? '#e6f7ff' : '#fff', border: '.5px solid #555' }} type="text" value={this.props.todays_notes[d._id] || ""} onChange={(e) => this.props.handleNoteChange(e.target.value, d._id, index)} /></td>}
        //                             </tr>
        //                         </tbody>
        //                     </table>
        //                 </ContextMenuTrigger>
        //             )
        //         })
        //         return (
        //             <div key={index}>
        //                 {render}
        //             </div>
        //         )
        //     })}

        // var cat = this.state.sortcat;
        // var offers = this.props.offers.offers;

        // const filteredConfirmed = offers.filter((item) => {
        //     var filterbatch = item.status
        //     return filterbatch.indexOf('Confirmed') >= 0
        // })

        // var keys = Object.keys(this.props.todays_counts);
        // var keyfig = [];
        // if (keys[0] === "todaysCount") {
        //     keyfig = [];
        // } else {
        //     keyfig = keys;
        // }
        const list = this.props.counts.counts_rol_filtered;
        //const list = this.state.render_data;

        return (
            <div style={{ width: '100%', height: '100%' }}>
                {this.props.counts.loading ?
                    <h2 style={{ marginLeft: 20, marginTop: 20, fontSize: 12, color: '#000' }}>Loading..</h2> :
                    <div style={{ width: '100%', height: '100%' }}>
                        <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                        </ReactTooltip>
                        <AutoSizer>
                            {({ height, width, style }) => (
                                <Table
                                    width={width}
                                    height={height}
                                    headerHeight={30}
                                    rowHeight={30}
                                    overscanRowCount={10}
                                    headerRowRenderer={this.headerRowRenderer.bind(this)}
                                    rowCount={list.length}
                                    rowGetter={({ index }) => { return ({ ...list[index], index }) }}
                                    rowStyle={this.rowStyleFormat.bind(this)}
                                    //onRowClick={this.rowSelect.bind(this)}
                                    onRowDoubleClick={this.rowDoubleClick.bind(this)}
                                    style={style}
                                //onHeaderClick={}
                                >
                                    <Column
                                        label=''
                                        width={75}
                                        dataKey='_id'
                                        headerRenderer={this.headerStartRenderer}
                                        cellDataGetter={({ rowData }) => rowData}
                                        cellRenderer={
                                            this.renderColumnData
                                        }
                                    />
                                    <Column
                                        width={180}
                                        label='Client'
                                        dataKey='client'
                                        headerRenderer={this.headerRendererMain}
                                        cellDataGetter={({ rowData }) => { return ({ cell: rowData.client, id: rowData._id, date_id: rowData.date_id, width: 180 }) }}
                                        cellRenderer={
                                            this.normCellRender
                                        }
                                    />
                                    <Column
                                        width={120}
                                        label='Date'
                                        dataKey='date'
                                        cellDataGetter={({ rowData }) => { return ({ cell: rowData.date, id: rowData._id, date_id: rowData.date_id, width: 120 }) }}
                                        cellRenderer={
                                            this.normCellRender
                                        }
                                    />
                                    <Column
                                        width={150}
                                        label='Venue'
                                        dataKey='venue'
                                        headerRenderer={this.headerRendererMain}
                                        cellDataGetter={({ rowData }) => { return ({ cell: rowData.venue, id: rowData._id, date_id: rowData.date_id, width: 150 }) }}
                                        cellRenderer={
                                            this.normCellRender
                                        }
                                    />
                                    <Column
                                        width={200}
                                        label='City, State'
                                        dataKey='city'
                                        headerRenderer={this.headerRendererMain}
                                        cellDataGetter={({ rowData }) => rowData}
                                        cellRenderer={
                                            this.countLocColumnData
                                        }
                                    />
                                    <Column
                                        width={100}
                                        label='Capacity'
                                        dataKey='sellable'
                                        headerRenderer={this.headerRendererMain}
                                        cellDataGetter={({ rowData }) => { return ({ cell: rowData.sellable === 0 ? '-' : rowData.sellable, id: rowData._id, date_id: rowData.date_id, width: 100 }) }}
                                        cellRenderer={
                                            this.normCellRender
                                        }
                                    />
                                    <Column
                                        width={100}
                                        //label={today}
                                        headerRenderer={this.headerRenderer}
                                        dataKey='countT'
                                        cellDataGetter={({ rowData }) => rowData}
                                        cellRenderer={
                                            this.countTColumnData
                                        }
                                    />
                                    <Column
                                        width={300}
                                        label='Previous Counts'
                                        //headerRenderer={this.headerRenderer}
                                        dataKey='countA'
                                        cellDataGetter={({ rowData }) => rowData}
                                        cellRenderer={
                                            this.prevCountColumnData
                                        }
                                    />
                                    <Column
                                        width={150}
                                        label='Notes'
                                        dataKey='notes'
                                        cellDataGetter={({ rowData }) => rowData}
                                        cellRenderer={
                                            this.countNColumnData
                                        }
                                    />
                                </Table>
                            )}
                        </AutoSizer>

                        <ContextMenu id={'SIMPLE'}>
                            <MenuItem onClick={this.handleClick} data={{ item: 'item 1' }}>View / Edit</MenuItem>
                            <MenuItem onClick={this.handleClick} data={{ item: 'item 1' }}>Issue Contract</MenuItem>
                            <SubMenu title='Print'>
                                <MenuItem onClick={this.handleClick} data={{ item: 'subitem a1' }}>Deal Memo</MenuItem>
                                <MenuItem onClick={this.handleClick} data={{ item: 'subitem a1' }}>Contract</MenuItem>
                            </SubMenu>
                            <SubMenu title='Email'>
                                <MenuItem onClick={this.handleClick} data={{ item: 'subitem b1' }}>Deal Memo</MenuItem>
                                <MenuItem onClick={this.handleClick} data={{ item: 'subitem b1' }}>Contract</MenuItem>
                            </SubMenu>
                            <MenuItem onClick={this.handleClick} data={{ item: 'item 1' }}>Delete Offer</MenuItem>
                        </ContextMenu>
                    </div>}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    buyers: state.buyers,
    contacts: state.contacts,
    clients: state.clients,
    items: state.items,
    festivals: state.festivals,
    counts: state.counts,
    offers: state.offers,
    auth: state.auth
});

export default connect(mapStateToProps, { getCountRolodex, addCountsBulk, loadCountsRolodex, setCountsGrid, updateCountsGrid })(CountsRolodex);