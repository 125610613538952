import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { getGlobalVenues } from '../../actions/globalActions';
import axios from "axios";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AutoSizer, Column, Table } from 'react-virtualized';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SSMenu from '../../components/dashboard/ss-menu';
import VenueRolodexShared from "../../components/shared/venue-rolodex-shared";

class SharedModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            loading: true,
            ss_sub: 'welcome',
            venue_init: false,
            list: [],
            venue_logged: []
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        offerdetail: PropTypes.object.isRequired,
        marketing: PropTypes.object.isRequired
    }

    async componentDidMount() {
        var logged = [];
        this.props.items.items.map(item => {
            logged.push(item.venue_name + JSON.stringify(item.address));
        });
        this.setState({
            venue_logged: logged
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    toggleSub = async (s) => {
        if (s === 'venues' && !this.state.venues_init) {
            const data = await this.props.getGlobalVenues();
            this.setState({
                list: data,
                venues_init: true
            })
        }
        this.setState({
            ss_sub: s
        })
    }

    headerRowRenderer = ({ className, columns, style }) => {
        return (
            <div
                className={className}
                role='row'
                style={style}
            >
                {columns}
            </div>
        )
    }

    headerStartRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: 'auto' }}>
                <div style={{ width: '60%' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        color="default"
                        //checked={this.props.offer_all_checked}
                        //value={props.checked}
                        name="emp_all_checked"
                    //onChange={this.props.handleDatabaseAllCheckbox}
                    /></div>
                <div style={{ width: '40%', height: 30 }}>
                    {/* {this.props.offer_sort.sort_cat ?
                        <FaRedoAlt style={{ marginTop: 14 }} size="12" color="#cdcdcd" onClick={() => this.props.clearSorted('offer_sort')} /> :
                        this.props.offer_checked.length > 0 ?
                            <FaMinusSquare style={{ marginTop: 10 }} size="18" color="#cdcdcd" onClick={() => this.props.handleDatabaseClearCheckbox('offer_checked')} />
                            : null} */}
                </div>
            </div>
        )
    }

    renderColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', fontSize: 10 }}>
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    //checked={this.props.offer_checked.includes(cellData._id) || false}
                    color="default"
                    value={cellData._id}
                    name="emp_checked"
                //onChange={this.props.handleDatabaseCheckbox}
                />
                {/* <span style={{ width: 25, height: '100%' }}>
                    <FaExternalLinkSquareAlt size="16" className="dashboard-offer-icons-exp" />
                </span>
                <span style={{ width: 25, height: '100%' }}>
                    <FaEnvelope size="14" className="dashboard-offer-icons-email" />
                </span> */}
            </div>
        )
    }

    headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        // if (this.props.offer_sort.sort_cat === dataKey) {
        //     return (
        //         <div style={{ width: '100%' }} onClick={() => this.props.toggleSortOffers(dataKey)}><div style={{ display: 'flex', width: '100%' }}><div style={{ width: dataKey === 'artist_name' || dataKey === 'venue_name' || dataKey === 'buyer_company' ? '86%' : '80%' }}>{label}</div><div style={{ width: '10%' }}>{this.props.offer_sort.sort_direction === 'asc' ? <FaCaretUp size="15" color="#cdcdcd" /> : <FaCaretDown size="15" color="#cdcdcd" />}</div></div></div>
        //     )
        // } else {
        //     return (
        //         <div onClick={() => this.props.toggleSortCatOffers(dataKey)}>{label}</div>
        //     )
        // }
        return (
            <div style={{ width: '100%' }}>
                {label}
            </div>
        )
    }

    normCellRender = ({ cellData, dataKey }) => {
        return (
            // <MenuProvider key={cellData.id} id="menu_id" component="span" value={cellData.id}>
            <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center', fontSize: 10 }}>
                {cellData.cell}
            </div>
            // </MenuProvider>
        )
    }

    rowStyleFormat = (row) => {
        if (row.index < 0) return;
        const list = this.state.list;
        const listRow = list[row.index];
        const test = listRow.venue_name + JSON.stringify(listRow.address);
        if (this.state.venue_logged.includes(test)) {
            return {
                backgroundColor: '#e6f7ff'
            };
        } else {
            return {
                backgroundColor: null,
            };
        }
    }

    render() {

        return (
            <div className="mode-s">
                <div className="mode-s-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                        <span className="close" onClick={this.props.close}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 600, width: '100%', backgroundColor: '#fff' }}>
                        {this.state.ss_sub === 'welcome' ?
                            <div style={{ width: '100%', height: 600, overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                                <p style={{ fontSize: 16, color: '#000' }}>Welcome to the Solar System shared network!</p>

                                <span style={{ width: 'auto', borderRadius: 20, height: 'auto', padding: 10 }}>
                                    <span style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <button style={{ fontSize: 12, padding: 10 }} type="button" className="btn btn-secondary px-5" onClick={() => this.toggleSub('venues')}>Venues</button>
                                        <button style={{ fontSize: 12, padding: 10 }} type="button" className="btn btn-secondary px-5" onClick={() => this.toggleSub('buyers')}>Buyers</button>
                                        <button style={{ fontSize: 12, padding: 10 }} type="button" className="btn btn-secondary px-5" onClick={() => this.toggleSub('packaging')}>Packaging</button>
                                    </span>
                                </span>
                            </div> :
                            <div style={{ width: '100%', height: 600, display: 'flex', flexDirection: 'row' }} >
                                <span style={{ width: '15%', height: '100%' }}>
                                    <SSMenu />
                                </span>
                                <span style={{ width: '85%', height: 550 }}>
                                    <div style={{ width: '100%', backgroundColor: 'rgb(1, 22, 48)', height: 35 }}>
                                        <input style={{ width: 200, fontSize: 12, borderRadius: 4 }} type="text" placeholder="Search..." />
                                    </div>
                                    <div style={{ width: '100%', height: 520 }}>
                                        {this.state.ss_sub === 'venues' && this.state.venues_init ?
                                            // <VenueRolodexShared list={this.state.list}/> 
                                            <AutoSizer>
                                                {({ height, width }) => (
                                                    <Table
                                                        width={width}
                                                        height={height}
                                                        headerHeight={30}
                                                        rowHeight={30}
                                                        overscanRowCount={10}
                                                        headerRowRenderer={this.headerRowRenderer}
                                                        rowCount={this.state.list.length}
                                                        rowGetter={({ index }) => this.state.list[index]}
                                                        rowStyle={this.rowStyleFormat}
                                                    //onRowClick={this.rowSelect.bind(this)}
                                                    //onRowDoubleClick={this.rowDoubleClick.bind(this)}
                                                    >
                                                        <Column
                                                            label=''
                                                            width={75}
                                                            dataKey='_id'
                                                            headerRenderer={this.headerStartRenderer}
                                                            cellDataGetter={({ rowData }) => rowData}
                                                            cellRenderer={
                                                                this.renderColumnData
                                                            }
                                                        />
                                                        <Column
                                                            width={150}
                                                            label='Venue'
                                                            dataKey='venue_name'
                                                            headerRenderer={this.headerRenderer}
                                                            cellDataGetter={({ rowData }) => { return ({ cell: rowData.venue_name, id: rowData._id, width: 150 }) }}
                                                            // cellDataGetter={({ rowData }) => rowData}
                                                            cellRenderer={
                                                                this.normCellRender
                                                            }
                                                        />
                                                        <Column
                                                            width={100}
                                                            label='Capacity'
                                                            dataKey='capacity'
                                                            headerRenderer={this.headerRenderer}
                                                            cellDataGetter={({ rowData }) => { return ({ cell: rowData.capacity, id: rowData._id, width: 100 }) }}
                                                            // cellDataGetter={({ rowData }) => rowData}
                                                            cellRenderer={
                                                                this.normCellRender
                                                            }
                                                        />
                                                        <Column
                                                            width={200}
                                                            label='Address'
                                                            dataKey='address.address'
                                                            cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.address, id: rowData._id, width: 200 }) }}
                                                            // cellDataGetter={({ rowData }) => rowData}
                                                            cellRenderer={
                                                                this.normCellRender
                                                            }
                                                        />
                                                        <Column
                                                            width={200}
                                                            label='City'
                                                            dataKey='address.city'
                                                            cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.city, id: rowData._id, width: 200 }) }}
                                                            // cellDataGetter={({ rowData }) => rowData}
                                                            cellRenderer={
                                                                this.normCellRender
                                                            }
                                                        />
                                                        <Column
                                                            width={150}
                                                            label='State | Prov'
                                                            dataKey='address.state'
                                                            cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.state, id: rowData._id, width: 150 }) }}
                                                            // cellDataGetter={({ rowData }) => rowData}
                                                            cellRenderer={
                                                                this.normCellRender
                                                            }
                                                        />
                                                        <Column
                                                            width={150}
                                                            label='Country'
                                                            dataKey='address.country'
                                                            cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.country, id: rowData._id, width: 200 }) }}
                                                            // cellDataGetter={({ rowData }) => rowData}
                                                            cellRenderer={
                                                                this.normCellRender
                                                            }
                                                        />
                                                    </Table>
                                                )}
                                            </AutoSizer>
                                            : null}
                                    </div>
                                </span>
                            </div>}
                    </div>
                </div>
            </div>
        );
    }
}

function sortAtoZ(data) {
    return data.sort((a, b) => { if (Number(a.nd === true)) { return (Number(a.time.substring(0, 2) + 24) + (Number(a.time.substring(3, 5)) / 100)) - (Number(b.time.substring(0, 2)) + (Number(b.time.substring(3, 5)) / 100)) } else { return (Number(a.time.substring(0, 2)) + (Number(a.time.substring(3, 5)) / 100)) - (Number(b.time.substring(0, 2)) + (Number(b.time.substring(3, 5)) / 100)) } });
}

function sortAtoZsub(data) {
    return data.sort((a, b) => { if (Number(a.time.substring(0, 2)) <= 4) { return (Number(a.time.substring(0, 2) + 24) + (Number(a.time.substring(3, 5)) / 100)) - (Number(b.time.substring(0, 2)) + (Number(b.time.substring(3, 5)) / 100)) } else { return (Number(a.time.substring(0, 2)) + (Number(a.time.substring(3, 5)) / 100)) - (Number(b.time.substring(0, 2)) + (Number(b.time.substring(3, 5)) / 100)) } });
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    auth: state.auth,
    offerdetail: state.offerdetail,
    marketing: state.marketing,
    counts: state.counts,
});

export default connect(mapStateToProps, { getGlobalVenues })(SharedModal);