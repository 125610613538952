import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPackaging } from '../../actions/packagingActions';
import DatePicker from "react-datepicker";
import { FaInfoCircle, FaAddressBook, FaSave, FaPlusSquare, FaMinusSquare, FaPlusCircle, FaEdit, FaTimesCircle, FaGlobeAmericas, FaArrowsAltH, FaSearchLocation } from 'react-icons/fa';
import LocationAutocomplete from '../../components/autocompletes/location-autocomplete';
import PlacesCombo from '../../helpers/PlacesCombo';
import moment from 'moment';

class PackagingDates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactarray: [],
            addcontact: false,
            checkedItems: new Map(),
            render: 'info',
            clientname: '',
            city: '',
            state: '',
            country: '',
            agent1: '',
            agent2: '',
            agent3: '',
            agent4: '',
            website: '',
            facebook: '',
            instagram: '',
            twitter: '',
            youtube: '',
            bandcamp: '',
            spotify: '',
            applemusic: '',
            tour_search: false,
            packaging_edit: {},
            tour_dates: []
        };
        this.keyHandler = this.keyHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        packaging: PropTypes.object.isRequired,
    }

    componentDidMount() {
        this.setState({
            tour_dates: this.props.tour_dates
        })
        window.addEventListener('keydown', this.keyHandler, false)
    }

    async shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.tour_dates !== nextState.tour_dates) {
            this.setState({
                tour_dates: nextProps.tour_dates
            })
        } else {

        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.doSomething)
    }

    keyHandler(e) {
        var TABKEY = 9;
        if (e.keyCode === TABKEY) {
            var data = this.state.packaging_edit;
            var val = '';
            var ki = '';
            Object.keys(data).map((key) => {
                val = data[key];
                ki = key;
            })
            if (val === 'date') {
                data = { [ki]: 'venue' }
            } else if (val === 'venue') {
                data = { [ki]: 'city' }
            } else if (val === 'city') {
                data = { [ki]: 'state' }
            } else if (val === 'state') {
                data = { [ki]: 'country' }
            } else if (val === 'country') {
                var arr = this.state.tour_dates;
                var newIndex = arr.findIndex(obj => obj._id === ki);
                newIndex = newIndex + 1;
                var newKi = arr[newIndex];
                if (newKi) {
                    newKi = newKi._id;
                } else {
                    newKi = ''
                }
                data = { [newKi]: 'venue' }
            }
            this.setState({
                packaging_edit: data
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleCheckboxChange(event) {
        var isChecked = event.target.checked;
        var item = event.target.value;

        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    }
    handleCheckboxChangeUn(event) {
        var item = event.target.value;
        var isChecked = event.target.checked;
        this.state.checkedItems.delete(item, isChecked)

        this.setState(({ checkedItems: this.state.checkedItems }))
    }

    handleClick = () => {
        this.props.toggle();
    };

    toggleContacts = () => {
        this.setState({
            render: 'contacts',
            hover: false
        })
    }
    toggleInfo = () => {
        this.setState({
            render: 'info',
            hover2: false
        })
    }
    toggleNewContact = () => {
        this.props.toggleNewContact();
    }

    toggleAddContact = () => {
        this.setState({
            contacts: 'addcontacts',
        })
    }

    Date1Toggle = () => {
        this.setState({
            date1Toggle: true
        });
    }
    Date1ToggleB = () => {
        this.setState({
            date1Toggle: false
        });
    }
    Date2Toggle = () => {
        this.setState({
            date2Toggle: true
        });
    }
    Date2ToggleB = () => {
        this.setState({
            date2Toggle: false
        });
    }
    Date3Toggle = () => {
        this.setState({
            date3Toggle: true
        });
    }
    Date3ToggleB = () => {
        this.setState({
            date3Toggle: false
        });
    }
    Date4Toggle = () => {
        alert("Hit limit.  Please see system admin.")
    }

    togglePop = () => {
        this.setState({
            seen: !this.state.seen
        });
    };

    updateRouting = (index, name) => {
        var packaging_edit = {
            [index]: name
        }
        this.setState({
            packaging_edit: packaging_edit
        })

    };

    handleClickOutside = (e, target) => {
        if (e.relatedTarget === null) {
            this.setState({
                [target]: {}
            })
        }
    };

    handleSelectArray = (coord, results, description, index) => {
        this.props.handleSelectArray(coord, results, description, index);
        const e = { relatedTarget: null };
        this.handleClickOutside(e, 'packaging_edit');
    }

    render() {

        //<FaEdit style={{ cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.props.updateTourDate(index)} />

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'auto', alignItems: 'center' }}>
                <div style={{ minHeight: 400, display: 'flex', flexDirection: 'column', width: '100%', height: 'auto', alignItems: 'center' }}>
                    <table style={{ width: '90%', marginTop: 30 }} id="#customers">
                        <thead>
                            <tr>
                                <th style={{ width: '96.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>TOUR DATES</th>
                                {this.props.edit ?
                                    <th style={{ width: '3.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd', cursor: 'pointer' }} onClick={this.props.toggleDateRange}><FaArrowsAltH size="20" color="#cdcdcd" /></th> : null}
                            </tr>
                        </thead>
                    </table>
                    {this.props.edit ?
                        <div style={{ width: '90%', height: 'auto' }}>
                            {this.props.date_range ?
                                <table style={{ width: '100%' }} id="#customers">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '25%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Start Date</th>
                                            <th style={{ width: '25%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>End Date</th>
                                            <th style={{ width: '33%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}> </th>
                                            <th style={{ width: '3%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}> </th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '25%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                                <DatePicker
                                                    className="react-datepicker"
                                                    style={{ fontSize: 12 }}
                                                    selected={this.props.tour_date_add}
                                                    selectsStart
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    startDate={this.props.tour_date_add}
                                                    endDate={this.props.tour_date_end_add}
                                                    onChange={this.props.handleTourDateChange}
                                                />
                                            </th>
                                            <th style={{ width: '25%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                                <DatePicker
                                                    className="react-datepicker"
                                                    style={{ fontSize: 12 }}
                                                    selected={this.props.tour_date_end_add}
                                                    selectsEnd
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    startDate={this.props.tour_date_add}
                                                    endDate={this.props.tour_date_end_add}
                                                    minDate={this.props.tour_date_add}
                                                    onChange={this.props.handleTourDateEndChange}
                                                />
                                            </th>
                                            <th style={{ width: '33%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>

                                            </th>
                                            <th style={{ width: '3%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={this.props.addTourDateRange} >
                                                    <FaPlusSquare size="20" color="#cdcdcd" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                </table> :
                                <table style={{ width: '100%' }} id="#customers">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '9.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Date</th>
                                            <th style={{ width: '18%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Venue</th>
                                            <th style={{ width: '18.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>City</th>
                                            <th style={{ width: '19%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>State / Province</th>
                                            <th style={{ width: '18%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Country</th>
                                            <th style={{ width: '3%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}> </th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '9.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                                <DatePicker
                                                    className="react-datepicker"
                                                    selected={this.props.tour_date_add}
                                                    onChange={this.props.handleTourDateChange}
                                                //dateFormat="M/dd/yyyy h:mm aa z"
                                                />
                                            </th>
                                            <th style={{ width: '18%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                                <PlacesCombo Gkey={this.props.auth.user.api.google} pInput={this.props.handleInput} pSelect={this.props.handleSelect} tour_venue={this.props.tour_venue_add} />
                                                {/* <input style={{ width: '100%', height: '100%', color: '#000' }} type="text" name="tour_venue_add" value={this.props.tour_venue_add} onChange={this.props.onChange} /> */}
                                            </th>
                                            <th style={{ width: '18.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                                <input style={{ width: '100%', height: '100%', color: '#000' }} type="text" name="tour_city_add" value={this.props.tour_city_add} onChange={this.props.onChange} />
                                            </th>
                                            <th style={{ width: '19%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                                <input style={{ width: '100%', height: '100%', color: '#000' }} type="text" name="tour_state_add" value={this.props.tour_state_add} onChange={this.props.onChange} />
                                            </th>
                                            <th style={{ width: '18%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                                <select style={{ width: '100%', height: '100%', color: '#000' }} name="tour_country_add" value={this.props.tour_country_add} onChange={this.props.onChange}>
                                                    <option value=""> </option>
                                                    <option value="United States">United States</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Afganistan">Afghanistan</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="American Samoa">American Samoa</option>
                                                    <option value="Andorra">Andorra</option>
                                                    <option value="Angola">Angola</option>
                                                    <option value="Anguilla">Anguilla</option>
                                                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Armenia">Armenia</option>
                                                    <option value="Aruba">Aruba</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                    <option value="Bahamas">Bahamas</option>
                                                    <option value="Bahrain">Bahrain</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Barbados">Barbados</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Belize">Belize</option>
                                                    <option value="Benin">Benin</option>
                                                    <option value="Bermuda">Bermuda</option>
                                                    <option value="Bhutan">Bhutan</option>
                                                    <option value="Bolivia">Bolivia</option>
                                                    <option value="Bonaire">Bonaire</option>
                                                    <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                                    <option value="Botswana">Botswana</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                                    <option value="Brunei">Brunei</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                    <option value="Burundi">Burundi</option>
                                                    <option value="Cambodia">Cambodia</option>
                                                    <option value="Cameroon">Cameroon</option>
                                                    <option value="Canary Islands">Canary Islands</option>
                                                    <option value="Cape Verde">Cape Verde</option>
                                                    <option value="Cayman Islands">Cayman Islands</option>
                                                    <option value="Central African Republic">Central African Republic</option>
                                                    <option value="Chad">Chad</option>
                                                    <option value="Channel Islands">Channel Islands</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Christmas Island">Christmas Island</option>
                                                    <option value="Cocos Island">Cocos Island</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Comoros">Comoros</option>
                                                    <option value="Congo">Congo</option>
                                                    <option value="Cook Islands">Cook Islands</option>
                                                    <option value="Costa Rica">Costa Rica</option>
                                                    <option value="Cote DIvoire">Cote DIvoire</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="Curaco">Curacao</option>
                                                    <option value="Cyprus">Cyprus</option>
                                                    <option value="Czech Republic">Czech Republic</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Djibouti">Djibouti</option>
                                                    <option value="Dominica">Dominica</option>
                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                    <option value="East Timor">East Timor</option>
                                                    <option value="Ecuador">Ecuador</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="El Salvador">El Salvador</option>
                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                    <option value="Eritrea">Eritrea</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Falkland Islands">Falkland Islands</option>
                                                    <option value="Faroe Islands">Faroe Islands</option>
                                                    <option value="Fiji">Fiji</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="French Guiana">French Guiana</option>
                                                    <option value="French Polynesia">French Polynesia</option>
                                                    <option value="French Southern Ter">French Southern Ter</option>
                                                    <option value="Gabon">Gabon</option>
                                                    <option value="Gambia">Gambia</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Gibraltar">Gibraltar</option>
                                                    <option value="Great Britain">Great Britain</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Greenland">Greenland</option>
                                                    <option value="Grenada">Grenada</option>
                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                    <option value="Guam">Guam</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Guinea">Guinea</option>
                                                    <option value="Guyana">Guyana</option>
                                                    <option value="Haiti">Haiti</option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="Hong Kong">Hong Kong</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="India">India</option>
                                                    <option value="Iran">Iran</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Isle of Man">Isle of Man</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Kiribati">Kiribati</option>
                                                    <option value="Korea North">Korea North</option>
                                                    <option value="Korea Sout">Korea South</option>
                                                    <option value="Kuwait">Kuwait</option>
                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                    <option value="Laos">Laos</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lesotho">Lesotho</option>
                                                    <option value="Liberia">Liberia</option>
                                                    <option value="Libya">Libya</option>
                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Luxembourg">Luxembourg</option>
                                                    <option value="Macau">Macau</option>
                                                    <option value="Macedonia">Macedonia</option>
                                                    <option value="Madagascar">Madagascar</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Malawi">Malawi</option>
                                                    <option value="Maldives">Maldives</option>
                                                    <option value="Mali">Mali</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                    <option value="Martinique">Martinique</option>
                                                    <option value="Mauritania">Mauritania</option>
                                                    <option value="Mauritius">Mauritius</option>
                                                    <option value="Mayotte">Mayotte</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Midway Islands">Midway Islands</option>
                                                    <option value="Moldova">Moldova</option>
                                                    <option value="Monaco">Monaco</option>
                                                    <option value="Mongolia">Mongolia</option>
                                                    <option value="Montserrat">Montserrat</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Mozambique">Mozambique</option>
                                                    <option value="Myanmar">Myanmar</option>
                                                    <option value="Nambia">Nambia</option>
                                                    <option value="Nauru">Nauru</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherland Antilles">Netherland Antilles</option>
                                                    <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                                    <option value="Nevis">Nevis</option>
                                                    <option value="New Caledonia">New Caledonia</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Nicaragua">Nicaragua</option>
                                                    <option value="Niger">Niger</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Niue">Niue</option>
                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Palau Island">Palau Island</option>
                                                    <option value="Palestine">Palestine</option>
                                                    <option value="Panama">Panama</option>
                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                    <option value="Paraguay">Paraguay</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Phillipines">Philippines</option>
                                                    <option value="Pitcairn Island">Pitcairn Island</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Republic of Montenegro">Republic of Montenegro</option>
                                                    <option value="Republic of Serbia">Republic of Serbia</option>
                                                    <option value="Reunion">Reunion</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russia">Russia</option>
                                                    <option value="Rwanda">Rwanda</option>
                                                    <option value="St Barthelemy">St Barthelemy</option>
                                                    <option value="St Eustatius">St Eustatius</option>
                                                    <option value="St Helena">St Helena</option>
                                                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                                    <option value="St Lucia">St Lucia</option>
                                                    <option value="St Maarten">St Maarten</option>
                                                    <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                                    <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                                    <option value="Saipan">Saipan</option>
                                                    <option value="Samoa">Samoa</option>
                                                    <option value="Samoa American">Samoa American</option>
                                                    <option value="San Marino">San Marino</option>
                                                    <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Senegal">Senegal</option>
                                                    <option value="Seychelles">Seychelles</option>
                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                    <option value="Somalia">Somalia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                    <option value="Sudan">Sudan</option>
                                                    <option value="Suriname">Suriname</option>
                                                    <option value="Swaziland">Swaziland</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syria">Syria</option>
                                                    <option value="Tahiti">Tahiti</option>
                                                    <option value="Taiwan">Taiwan</option>
                                                    <option value="Tajikistan">Tajikistan</option>
                                                    <option value="Tanzania">Tanzania</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Togo">Togo</option>
                                                    <option value="Tokelau">Tokelau</option>
                                                    <option value="Tonga">Tonga</option>
                                                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                                    <option value="Tuvalu">Tuvalu</option>
                                                    <option value="Uganda">Uganda</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Erimates">United Arab Emirates</option>
                                                    <option value="Uraguay">Uruguay</option>
                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                    <option value="Vanuatu">Vanuatu</option>
                                                    <option value="Vatican City State">Vatican City State</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Vietnam">Vietnam</option>
                                                    <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                                    <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                                    <option value="Wake Island">Wake Island</option>
                                                    <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Zaire">Zaire</option>
                                                    <option value="Zambia">Zambia</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </select>
                                            </th>
                                            <th style={{ width: '3%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={this.props.addTourDate} >
                                                    <FaPlusSquare size="20" color="#cdcdcd" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>}
                        </div> : null}
                    <div style={{ marginTop: this.state.tour_dates ? 15 : 0, minHeight: 50, maxHeight: 250, width: '90%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd', overflowY: 'scroll' }} tabIndex={this.props.edit ? "0" : null} onBlur={this.props.edit ? (e) => this.handleClickOutside(e, 'packaging_edit') : null}>
                        {this.state.tour_dates.map((item, index) => {
                            return (
                                <div style={{ border: '1px dashed gray', paddingLeft: '0.5rem', backgroundColor: '#f1f1f1', width: '100%', height: 30 }}>
                                    <table style={{ width: '100%', height: 30 }}>
                                        <tbody> 
                                            <tr>
                                                {this.state.packaging_edit[item._id] === 'date' ?
                                                    <td style={{ width: '9.5%', color: '#000', fontSize: 12, padding: 5, height: 22 }}>
                                                        <DatePicker
                                                            className="react-datepicker"
                                                            selected={item.date.toString() !== 'Invalid date' ? new Date(moment(item.date)) : ''}
                                                            onChange={this.props.updateTourDateArray.bind(this, index)}
                                                        /></td> :
                                                    <td style={{ width: '9.5%', color: '#000', fontSize: 12, padding: 5, height: 22 }} onClick={this.props.edit ? () => this.updateRouting(item._id, 'date') : null}>{item.date.toString() !== 'Invalid date' ? moment(item.date).format('MM/DD/YYYY') : ''}</td>}
                                                {this.state.packaging_edit[item._id] === 'venue' ?
                                                    <td style={{ width: '18%', color: '#000', fontSize: 12, padding: 5, position: 'relative', zIndex: 105 }}><PlacesCombo Gkey={this.props.auth.user.api.google} pInput={(val) => this.props.handleInputArray(val, index)} pSelect={(coord, results, description) => this.handleSelectArray(coord, results, description, index)} tour_venue={item.venue} pIndex={index} /></td> :
                                                    <td style={{ width: '18%', color: '#000', fontSize: 12, padding: 5 }} onClick={this.props.edit ? () => this.updateRouting(item._id, 'venue') : null}>{item.venue}</td>}
                                                {this.state.packaging_edit[item._id] === 'city' ?
                                                    <td style={{ width: '18.5%', color: '#000', fontSize: 12, padding: 5 }}><input style={{ fontSize: 12, width: '100%', height: '100%' }} type="text" value={item.city} onChange={event => this.props.updateTourArray(index, 'city', event)}/></td> :
                                                    <td style={{ width: '18.5%', color: '#000', fontSize: 12, padding: 5 }} onClick={this.props.edit ? () => this.updateRouting(item._id, 'city') : null}>{item.city}</td>}
                                                {this.state.packaging_edit[item._id] === 'state' ?
                                                    <td style={{ width: '19%', color: '#000', fontSize: 12, padding: 5 }}><input style={{ fontSize: 12, width: '100%', height: '100%' }} type="text" value={item.state} onChange={event => this.props.updateTourArray(index, 'state', event)}/></td> :
                                                    <td style={{ width: '19%', color: '#000', fontSize: 12, padding: 5 }} onClick={this.props.edit ? () => this.updateRouting(item._id, 'state') : null}>{item.state}</td>}
                                                {this.state.packaging_edit[item._id] === 'country' ?
                                                    <td style={{ width: '18%', color: '#000', fontSize: 12, padding: 5 }}><input style={{ fontSize: 12, width: '100%', height: '100%' }} type="text" value={item.country} onChange={event => this.props.updateTourArray(index, 'country', event)}/></td> :
                                                    <td style={{ width: '18%', color: '#000', fontSize: 12, padding: 5 }} onClick={this.props.edit ? () => this.updateRouting(item._id, 'country') : null}>{item.country}</td>}
                                                <td style={{ width: '3%', color: '#000' }}>
                                                    {this.props.edit ?
                                                        <FaTimesCircle style={{ marginLeft: 10, cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.props.deleteTourDate(index)} /> : null}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ width: '90%' }}>
                        <label style={{ fontSize: 14, color: '#000' }}>Notes</label>
                    </div>
                    <textarea style={{ width: '90%', backgroundColor: '#fff', color: '#000', minHeight: 60, maxHeight: 60 }} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    items: state.items,
    contacts: state.contacts,
    packaging: state.packaging,
    auth: state.auth
});

export default connect(mapStateToProps, { getPackaging })(PackagingDates);