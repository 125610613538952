import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserProfile from '../../assets/user.png';
import VenueProfile from '../../assets/venue.png';
import BuyerProfile from '../../assets/buyer.png';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import { FaEnvelopeSquare, FaPhoneSquare, FaMosque, FaShoppingCart, FaMicrophoneAlt } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function TagsAccordion(props) {
    const classes = useStyles();

    //<img style={{ marginLeft: 20, marginTop: -5 }} width="35" height="35" src={item.cat === 'Venue Rolodex' ? VenueProfile : item.cat === 'Buyer Rolodex' ? BuyerProfile : UserProfile} />
    return (
        <div style={{ width: '95%', marginTop: 15 }} className={classes.root}>
            {props.tagarray.map((item, index) => {

                // var address = "";
                // if (item.address.address) { address = item.address.address + '<br/>' }
                // if (item.address.address2) { address = address + item.address.address2 + '<br/>' }
                // if (item.address.city) { address = address + item.address.city + ', ' }
                // if (item.address.state) { address = address + item.address.state }
                // if (item.address.postal_code) { address = address + item.address.postal_code + '<br/>' }
                // if (item.address.country) { address = address + item.address.country }

                return (
                    <Accordion key={index} style={{ backgroundColor: '#F5F5F5', marginTop: 5, position: 'relative', zIndex: 0 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={classes.heading}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 5, height: 'auto', alignItems: 'center' }}>
                                    {item.cat === 'Venue Rolodex' ?
                                        <div style={{ marginLeft: 20, marginTop: -5, width: 50, height: 50, borderRadius: 50, backgroundColor: '#efece7', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '.5px solid #504740' }}>
                                            <FaMosque size="20" color="#504740" />
                                        </div> : item.cat === 'Buyer Rolodex' ?
                                            <div style={{ marginLeft: 20, marginTop: -5, width: 50, height: 50, borderRadius: 50, backgroundColor: '#efece7', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '.5px solid #504740' }}>
                                                <FaShoppingCart size="20" color="#504740" />
                                            </div> :
                                            <div style={{ marginLeft: 20, marginTop: -5, width: 50, height: 50, borderRadius: 50, backgroundColor: '#efece7', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '.5px solid #504740' }}>
                                                <FaMicrophoneAlt size="20" color="#504740" />
                                            </div>}
                                    <h2 style={{ fontSize: 12, color: '#000', marginLeft: 20 }}>{item.name}</h2>
                                    <h2 style={{ fontSize: 12, color: '#000', marginLeft: 40 }}>({item.role})</h2>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ width: '100%' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 6 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '33.3%', marginLeft: 40 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <h2 style={{ fontSize: 12, color: '#000', fontWeight: 'bold' }}>Address:</h2>
                                            <FaEnvelopeSquare style={{ marginLeft: 10, cursor: 'pointer' }} size="15" color='#000' />
                                        </div>
                                        <h2 style={{ fontSize: 12, color: '#000' }}>{item.address.address}<br/>{item.address.address2 ? item.address.address2 : null}{item.address.address2 ? <br/> : null}{item.address.city}, {item.address.state} {item.address.postal_code}<br/>{item.address.country}</h2>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '33.3%', marginLeft: 20 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <h2 style={{ fontSize: 12, color: '#000', fontWeight: 'bold' }}>Email:</h2>
                                            <FaEnvelopeSquare style={{ marginLeft: 10, cursor: 'pointer' }} size="15" color='#000' />
                                        </div>
                                        <h2 style={{ fontSize: 12, color: '#000' }}><NumberFormat
                                            displayType={'text'}
                                            style={{ width: '100%', fontSize: 10, marginLeft: -10 }}
                                            className="form-control-sm"
                                            //value={item.contact.officephone}
                                            format="+# (###) ###-####" /></h2>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '33.3%', marginLeft: 20 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <h2 style={{ fontSize: 12, color: '#000', fontWeight: 'bold' }}>Main Phone:</h2>
                                            <FaPhoneSquare style={{ marginLeft: 10, cursor: 'pointer' }} size="15" color='#000' />
                                        </div>
                                        <h2 style={{ fontSize: 12, color: '#000' }}><NumberFormat
                                            displayType={'text'}
                                            style={{ width: '100%', fontSize: 10, marginLeft: -10 }}
                                            className="form-control-sm"
                                            value={item.contact.main_phone}
                                            format="+# (###) ###-####" /></h2>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginLeft: 40, marginTop: 15 }}>
                                    <h2 style={{ fontSize: 12, color: '#000', fontWeight: 'bold' }}>Notes:</h2>
                                    <h2 style={{ fontSize: 12, color: '#000' }}>{item.notes}</h2>
                                </div>
                                <div style={{ marginTop: 15, width: '100%', marginLeft: 40 }}>
                                    <button style={{ width: 80, backgroundColor: 'rgb(1, 22, 48)', height: 24, borderRadius: 5, fontSize: 10, color: '#cdcdcd' }} type="button" onClick={() => props.removeTag(index)}>Remove Tag</button>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}