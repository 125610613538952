import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaLongArrowAltLeft, FaPenSquare, FaSave, FaTimesCircle, FaTrash } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import CurrencyAutocomplete from '../autocompletes/currency-autocomplete-rel';
import UserAvatar from '../../modals/global/components/user.avatar';
import ConfirmDialog from '../global/confirm.alert';
import getBadWords from '../../helpers/curse-words';


export default function SettingsScreen(props) {
    const [stateUserName, setStateUserName] = useState(props.user.name);
    const [stateUsername, setStateUsername] = useState(props.user.username);
    const [stateUserAddress, setStateUserAddress] = useState(props.user.address ? props.user.address : { address: '', address2: '', city: '', state: '', postal_code: '', country: '' });
    const [stateUserContact, setStateUserContact] = useState(props.user.contact);
    const [stateUserEmail, setStateUserEmail] = useState(props.user.email);
    const [stateUserPhoto, setStateUserPhoto] = useState(props.user.profile_img ? props.user.profile_img : null);
    const [stateUserPhotoFile, setStateUserPhotoFile] = useState(null);
    const [stateGmail, setStateGmail] = useState(props.gmail);
    const [stateEdit, setStateEdit] = useState(false);

    useEffect(() => {
        setStateUserName(props.user.name);
    }, [props.user.name])
    useEffect(() => {
        setStateUserAddress(props.user.address);
    }, [props.user.address])
    useEffect(() => {
        setStateUserContact(props.user.contact);
    }, [props.user.contact])
    useEffect(() => {
        setStateUserEmail(props.user.email);
    }, [props.user.email])
    useEffect(() => {
        setStateUsername(props.user.username);
    }, [props.user.username])
    useEffect(() => {
        setStateGmail(props.gmail);
    }, [props.gmail])

    const handleChangeObj = (event, set) => {
        const name = event.target.name;
        const val = event.target.value;
        if (set === 'user_name') {
            setStateUserName(prevState => ({
                ...prevState,
                [name]: val
            }));
        } else if (set === 'user_address') {
            setStateUserAddress(prevState => ({
                ...prevState,
                [name]: val
            }));
        } else if (set === 'user_contact') {
            setStateUserContact(prevState => ({
                ...prevState,
                [name]: val
            }));
        }
    }

    const onSave = () => {
        const bw = getBadWords(stateUsername);
        if (bw.length > 0) {
            alert('Error: You have an unpermitted word in your requested username.')
        } else {
            props.onProfileSave(stateUserName, stateUsername, stateUserAddress, stateUserContact, stateUserPhotoFile);
            toggleEdit();
        }
    }

    const connect = () => {
        // setStateUserName(props.user_name);
        // setStateUserAddress(props.user_address);
        // setStateUserContact(props.user_contact);
        props.connectGmail();
    }

    const deleteConnect = async () => {
        var msg = "Are you sure you'd like to disconnect your google account?"
        const func = () => {
            props.removeGmail();
        };
        ConfirmDialog(msg, func);
    }

    const toggleEdit = () => {
        setStateEdit(!stateEdit)
    }

    const toggleProfileCancel = () => {
        setStateUserName(props.user.name);
        setStateUsername(props.user.username);
        setStateUserAddress(props.user.address ? props.user.address : { address: '', address2: '', city: '', state: '', postal_code: '', country: '' });
        setStateUserContact(props.user.contact);
        setStateEdit(false)
    }

    const loadImage = (file) => {
        setStateUserPhotoFile(file)
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div>
                {/* <div style={{ width: '100%', height: 30, backgroundColor: '#111', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ borderBottom: '2px solid #333', backgroundColor: '#111', width: '100%', display: 'flex' }}>
                        <button style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', width: 55, height: 'auto', justifyContent: 'center', alignItems: 'center', paddingTop: 20, backgroundColor: '#111', border: 0 }} onClick={() => props.subSelect('start')}>
                            <FaLongArrowAltLeft size={18} color="#cdcdcd" />
                            <h1 style={{ fontSize: 10, fontWeight: 'bold', color: '#cdcdcd' }}>BACK</h1>
                        </button>
                        {stateEdit ?
                            <button style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', width: 55, height: 'auto', justifyContent: 'center', alignItems: 'center', paddingTop: 20, backgroundColor: '#111', border: 0 }} onClick={toggleProfileCancel}>
                                <FaTimesCircle size={18} color="#cdcdcd" />
                                <h1 style={{ fontSize: 10, fontWeight: 'bold', color: '#cdcdcd' }}>CANCEL</h1>
                            </button> : null}
                        {stateEdit ?
                            <button style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', width: 55, height: 'auto', justifyContent: 'center', alignItems: 'center', paddingTop: 20, backgroundColor: '#111', border: 0 }} onClick={onSave}>
                                <FaSave size={18} color="#cdcdcd" />
                                <h1 style={{ fontSize: 10, fontWeight: 'bold', color: '#cdcdcd' }}>SAVE</h1>
                            </button> :
                            <button style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', width: 55, height: 'auto', justifyContent: 'center', alignItems: 'center', paddingTop: 20, backgroundColor: '#111', border: 0 }} onClick={toggleEdit}>
                                <FaPenSquare size={18} color="#cdcdcd" />
                                <h1 style={{ fontSize: 10, fontWeight: 'bold', color: '#cdcdcd' }}>EDIT</h1>
                            </button>}
                    </div>
                </div> */}
                <div style={{ width: '100%', height: 30, backgroundColor: '#111', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {stateEdit ?
                        <button className='custom-toggle-menu' type="button" onClick={toggleProfileCancel}><FaTimesCircle size={18} color="#cdcdcd" /></button> : null}
                    {stateEdit ?
                        <button className='custom-toggle-menu' type="button" onClick={onSave}><FaSave size={18} color="#cdcdcd" /></button> :
                        <button className='custom-toggle-menu' type="button" onClick={toggleEdit}><FaPenSquare size={18} color="#cdcdcd" /></button>}
                </div>
                <div style={{ height: '85vh', width: '100%', backgroundColor: '#fff', overflowY: 'scroll' }}>
                    <div style={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                        <div style={{ width: '100%', height: 'auto', padding: 10, borderBottom: '1px solid #111', borderTop: '1px solid #111', display: 'flex' }}>
                            {stateEdit ?
                                <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: 500, height: 'auto', paddingTop: 10, paddingLeft: 10, display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Name:</label>
                                            {/* <p style={{ fontSize: 12, width: 300 }}>{props.user.name.first} {props.user.name.last}</p> */}
                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td><input style={{ width: '50%', height: '100%' }} type="text" placeholder="first" name="first" value={stateUserName.first} onChange={(e) => handleChangeObj(e, 'user_name')} /><input style={{ width: '50%', height: '100%' }} type="text" placeholder="last" name="last" value={stateUserName.last} onChange={(e) => handleChangeObj(e, 'user_name')} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </span>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Username:</label>
                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td><input style={{ width: '50%', height: '100%' }} type="text" placeholder="Username" name="username" value={stateUsername} onChange={(e) => setStateUsername(e.target.value)} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </span>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Role:</label>
                                            {/* <p style={{ fontSize: 12, width: 300 }}>{props.user.role}</p> */}
                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textTransform: 'capitalize' }}>{props.user.role}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </span>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Address:</label>
                                            {/* <span style={{ width: 'auto', display: 'flex', flexDirection: 'column', width: 300 }}> */}
                                            {/* <p style={{ fontSize: 12 }}>{props.user.address.address ? props.user.address.address : null}{props.user.address.address ? <br /> : null}{props.user.address.address2 ? props.user.address.address2 : null}{props.user.address.address2 ? <br /> : null}{props.user.address.city ? props.user.address.city : null}{props.user.address.city ? ", " : null}{props.user.address.state ? props.user.address.state : null}{props.user.address.state ? " " : null}{props.user.address.postal_code ? props.user.address.postal_code : null}{props.user.address.city || props.user.address.state || props.user.address.postal_code ? <br /> : null}{props.user.address.country ? props.user.address.country : null}</p> */}
                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        {stateUserAddress.country ?
                                                            <tr>
                                                                <td><input style={{ width: '100%', height: '100%' }} type="text" placeholder="address" name="address" value={stateUserAddress.address} onChange={(e) => handleChangeObj(e, 'user_address')} /></td>
                                                            </tr> : null}
                                                        {stateUserAddress.country ?
                                                            <tr>
                                                                <td><input style={{ width: '100%', height: '100%' }} type="text" placeholder="address 2" name="address2" value={stateUserAddress.address2} onChange={(e) => handleChangeObj(e, 'user_address')} /></td>
                                                            </tr> : null}
                                                        {stateUserAddress.country ?
                                                            <tr>
                                                                <td><input style={{ width: '100%', height: '100%' }} type="text" placeholder="city" name="city" value={stateUserAddress.city} onChange={(e) => handleChangeObj(e, 'user_address')} /></td>
                                                            </tr> : null}
                                                        {stateUserAddress.country ?
                                                            <tr>
                                                                <td>
                                                                    {stateUserAddress.country === 'United States' ?
                                                                        <select style={{ width: '100%', height: '100%' }} placeholder="state" name="state" value={stateUserAddress.state} onChange={(e) => handleChangeObj(e, 'user_address')} >
                                                                            <option value=""> </option>
                                                                            <option value="Alabama">Alabama</option>
                                                                            <option value="Alaska">Alaska</option>
                                                                            <option value="Arizona">Arizona</option>
                                                                            <option value="Arkansas">Arkansas</option>
                                                                            <option value="California">California</option>
                                                                            <option value="Colorado">Colorado</option>
                                                                            <option value="Connecticut">Connecticut</option>
                                                                            <option value="Delaware">Delaware</option>
                                                                            <option value="District Of Columbia">District Of Columbia</option>
                                                                            <option value="Florida">Florida</option>
                                                                            <option value="Georgia">Georgia</option>
                                                                            <option value="Hawaii">Hawaii</option>
                                                                            <option value="Idaho">Idaho</option>
                                                                            <option value="Illinois">Illinois</option>
                                                                            <option value="Indiana">Indiana</option>
                                                                            <option value="Iowa">Iowa</option>
                                                                            <option value="Kansas">Kansas</option>
                                                                            <option value="Kentucky">Kentucky</option>
                                                                            <option value="Louisiana">Louisiana</option>
                                                                            <option value="Maine">Maine</option>
                                                                            <option value="Maryland">Maryland</option>
                                                                            <option value="Massachusetts">Massachusetts</option>
                                                                            <option value="Michigan">Michigan</option>
                                                                            <option value="Minnesota">Minnesota</option>
                                                                            <option value="Mississippi">Mississippi</option>
                                                                            <option value="Missouri">Missouri</option>
                                                                            <option value="Montana">Montana</option>
                                                                            <option value="Nebraska">Nebraska</option>
                                                                            <option value="Nevada">Nevada</option>
                                                                            <option value="New Hampshire">New Hampshire</option>
                                                                            <option value="New Jersey">New Jersey</option>
                                                                            <option value="New Mexico">New Mexico</option>
                                                                            <option value="New York">New York</option>
                                                                            <option value="North Carolina">North Carolina</option>
                                                                            <option value="North Dakota">North Dakota</option>
                                                                            <option value="Ohio">Ohio</option>
                                                                            <option value="Oklahoma">Oklahoma</option>
                                                                            <option value="Oregon">Oregon</option>
                                                                            <option value="Pennsylvania">Pennsylvania</option>
                                                                            <option value="Rhode Island">Rhode Island</option>
                                                                            <option value="South Carolina">South Carolina</option>
                                                                            <option value="South Dakota">South Dakota</option>
                                                                            <option value="Tennessee">Tennessee</option>
                                                                            <option value="Texas">Texas</option>
                                                                            <option value="Utah">Utah</option>
                                                                            <option value="Vermont">Vermont</option>
                                                                            <option value="Virginia">Virginia</option>
                                                                            <option value="Washington">Washington</option>
                                                                            <option value="West Virginia">West Virginia</option>
                                                                            <option value="Wisconsin">Wisconsin</option>
                                                                            <option value="Wyoming">Wyoming</option>
                                                                        </select> :
                                                                        <input style={{ width: '100%', height: '100%' }} type="text" placeholder="province" name="state" value={stateUserAddress.state} onChange={(e) => handleChangeObj(e, 'user_address')} />}
                                                                </td>
                                                            </tr> : null}
                                                        {stateUserAddress.country ?
                                                            <tr>
                                                                <td><input style={{ width: '100%', height: '100%' }} type="text" placeholder="postal code" name="postal_code" value={stateUserAddress.postal_code} onChange={(e) => handleChangeObj(e, 'user_address')} /></td>
                                                            </tr> : null}
                                                        <tr>
                                                            <td>
                                                                <select style={{ width: '100%', height: '100%' }} name="country" value={stateUserAddress.country} onChange={(e) => handleChangeObj(e, 'user_address')}>
                                                                    <option value=""> </option>
                                                                    <option value="United States">United States</option>
                                                                    <option value="Canada">Canada</option>
                                                                    <option value="Afganistan">Afghanistan</option>
                                                                    <option value="Albania">Albania</option>
                                                                    <option value="Algeria">Algeria</option>
                                                                    <option value="American Samoa">American Samoa</option>
                                                                    <option value="Andorra">Andorra</option>
                                                                    <option value="Angola">Angola</option>
                                                                    <option value="Anguilla">Anguilla</option>
                                                                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                                                    <option value="Argentina">Argentina</option>
                                                                    <option value="Armenia">Armenia</option>
                                                                    <option value="Aruba">Aruba</option>
                                                                    <option value="Australia">Australia</option>
                                                                    <option value="Austria">Austria</option>
                                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                                    <option value="Bahamas">Bahamas</option>
                                                                    <option value="Bahrain">Bahrain</option>
                                                                    <option value="Bangladesh">Bangladesh</option>
                                                                    <option value="Barbados">Barbados</option>
                                                                    <option value="Belarus">Belarus</option>
                                                                    <option value="Belgium">Belgium</option>
                                                                    <option value="Belize">Belize</option>
                                                                    <option value="Benin">Benin</option>
                                                                    <option value="Bermuda">Bermuda</option>
                                                                    <option value="Bhutan">Bhutan</option>
                                                                    <option value="Bolivia">Bolivia</option>
                                                                    <option value="Bonaire">Bonaire</option>
                                                                    <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                                                    <option value="Botswana">Botswana</option>
                                                                    <option value="Brazil">Brazil</option>
                                                                    <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                                                    <option value="Brunei">Brunei</option>
                                                                    <option value="Bulgaria">Bulgaria</option>
                                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                                    <option value="Burundi">Burundi</option>
                                                                    <option value="Cambodia">Cambodia</option>
                                                                    <option value="Cameroon">Cameroon</option>
                                                                    <option value="Canary Islands">Canary Islands</option>
                                                                    <option value="Cape Verde">Cape Verde</option>
                                                                    <option value="Cayman Islands">Cayman Islands</option>
                                                                    <option value="Central African Republic">Central African Republic</option>
                                                                    <option value="Chad">Chad</option>
                                                                    <option value="Channel Islands">Channel Islands</option>
                                                                    <option value="Chile">Chile</option>
                                                                    <option value="China">China</option>
                                                                    <option value="Christmas Island">Christmas Island</option>
                                                                    <option value="Cocos Island">Cocos Island</option>
                                                                    <option value="Colombia">Colombia</option>
                                                                    <option value="Comoros">Comoros</option>
                                                                    <option value="Congo">Congo</option>
                                                                    <option value="Cook Islands">Cook Islands</option>
                                                                    <option value="Costa Rica">Costa Rica</option>
                                                                    <option value="Cote DIvoire">Cote DIvoire</option>
                                                                    <option value="Croatia">Croatia</option>
                                                                    <option value="Cuba">Cuba</option>
                                                                    <option value="Curaco">Curacao</option>
                                                                    <option value="Cyprus">Cyprus</option>
                                                                    <option value="Czech Republic">Czech Republic</option>
                                                                    <option value="Denmark">Denmark</option>
                                                                    <option value="Djibouti">Djibouti</option>
                                                                    <option value="Dominica">Dominica</option>
                                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                                    <option value="East Timor">East Timor</option>
                                                                    <option value="Ecuador">Ecuador</option>
                                                                    <option value="Egypt">Egypt</option>
                                                                    <option value="El Salvador">El Salvador</option>
                                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                                    <option value="Eritrea">Eritrea</option>
                                                                    <option value="Estonia">Estonia</option>
                                                                    <option value="Ethiopia">Ethiopia</option>
                                                                    <option value="Falkland Islands">Falkland Islands</option>
                                                                    <option value="Faroe Islands">Faroe Islands</option>
                                                                    <option value="Fiji">Fiji</option>
                                                                    <option value="Finland">Finland</option>
                                                                    <option value="France">France</option>
                                                                    <option value="French Guiana">French Guiana</option>
                                                                    <option value="French Polynesia">French Polynesia</option>
                                                                    <option value="French Southern Ter">French Southern Ter</option>
                                                                    <option value="Gabon">Gabon</option>
                                                                    <option value="Gambia">Gambia</option>
                                                                    <option value="Georgia">Georgia</option>
                                                                    <option value="Germany">Germany</option>
                                                                    <option value="Ghana">Ghana</option>
                                                                    <option value="Gibraltar">Gibraltar</option>
                                                                    <option value="Great Britain">Great Britain</option>
                                                                    <option value="Greece">Greece</option>
                                                                    <option value="Greenland">Greenland</option>
                                                                    <option value="Grenada">Grenada</option>
                                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                                    <option value="Guam">Guam</option>
                                                                    <option value="Guatemala">Guatemala</option>
                                                                    <option value="Guinea">Guinea</option>
                                                                    <option value="Guyana">Guyana</option>
                                                                    <option value="Haiti">Haiti</option>
                                                                    <option value="Hawaii">Hawaii</option>
                                                                    <option value="Honduras">Honduras</option>
                                                                    <option value="Hong Kong">Hong Kong</option>
                                                                    <option value="Hungary">Hungary</option>
                                                                    <option value="Iceland">Iceland</option>
                                                                    <option value="Indonesia">Indonesia</option>
                                                                    <option value="India">India</option>
                                                                    <option value="Iran">Iran</option>
                                                                    <option value="Iraq">Iraq</option>
                                                                    <option value="Ireland">Ireland</option>
                                                                    <option value="Isle of Man">Isle of Man</option>
                                                                    <option value="Israel">Israel</option>
                                                                    <option value="Italy">Italy</option>
                                                                    <option value="Jamaica">Jamaica</option>
                                                                    <option value="Japan">Japan</option>
                                                                    <option value="Jordan">Jordan</option>
                                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                                    <option value="Kenya">Kenya</option>
                                                                    <option value="Kiribati">Kiribati</option>
                                                                    <option value="Korea North">Korea North</option>
                                                                    <option value="Korea Sout">Korea South</option>
                                                                    <option value="Kuwait">Kuwait</option>
                                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                                    <option value="Laos">Laos</option>
                                                                    <option value="Latvia">Latvia</option>
                                                                    <option value="Lebanon">Lebanon</option>
                                                                    <option value="Lesotho">Lesotho</option>
                                                                    <option value="Liberia">Liberia</option>
                                                                    <option value="Libya">Libya</option>
                                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                                    <option value="Lithuania">Lithuania</option>
                                                                    <option value="Luxembourg">Luxembourg</option>
                                                                    <option value="Macau">Macau</option>
                                                                    <option value="Macedonia">Macedonia</option>
                                                                    <option value="Madagascar">Madagascar</option>
                                                                    <option value="Malaysia">Malaysia</option>
                                                                    <option value="Malawi">Malawi</option>
                                                                    <option value="Maldives">Maldives</option>
                                                                    <option value="Mali">Mali</option>
                                                                    <option value="Malta">Malta</option>
                                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                                    <option value="Martinique">Martinique</option>
                                                                    <option value="Mauritania">Mauritania</option>
                                                                    <option value="Mauritius">Mauritius</option>
                                                                    <option value="Mayotte">Mayotte</option>
                                                                    <option value="Mexico">Mexico</option>
                                                                    <option value="Midway Islands">Midway Islands</option>
                                                                    <option value="Moldova">Moldova</option>
                                                                    <option value="Monaco">Monaco</option>
                                                                    <option value="Mongolia">Mongolia</option>
                                                                    <option value="Montserrat">Montserrat</option>
                                                                    <option value="Morocco">Morocco</option>
                                                                    <option value="Mozambique">Mozambique</option>
                                                                    <option value="Myanmar">Myanmar</option>
                                                                    <option value="Nambia">Nambia</option>
                                                                    <option value="Nauru">Nauru</option>
                                                                    <option value="Nepal">Nepal</option>
                                                                    <option value="Netherland Antilles">Netherland Antilles</option>
                                                                    <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                                                    <option value="Nevis">Nevis</option>
                                                                    <option value="New Caledonia">New Caledonia</option>
                                                                    <option value="New Zealand">New Zealand</option>
                                                                    <option value="Nicaragua">Nicaragua</option>
                                                                    <option value="Niger">Niger</option>
                                                                    <option value="Nigeria">Nigeria</option>
                                                                    <option value="Niue">Niue</option>
                                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                                    <option value="Norway">Norway</option>
                                                                    <option value="Oman">Oman</option>
                                                                    <option value="Pakistan">Pakistan</option>
                                                                    <option value="Palau Island">Palau Island</option>
                                                                    <option value="Palestine">Palestine</option>
                                                                    <option value="Panama">Panama</option>
                                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                                    <option value="Paraguay">Paraguay</option>
                                                                    <option value="Peru">Peru</option>
                                                                    <option value="Phillipines">Philippines</option>
                                                                    <option value="Pitcairn Island">Pitcairn Island</option>
                                                                    <option value="Poland">Poland</option>
                                                                    <option value="Portugal">Portugal</option>
                                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                                    <option value="Qatar">Qatar</option>
                                                                    <option value="Republic of Montenegro">Republic of Montenegro</option>
                                                                    <option value="Republic of Serbia">Republic of Serbia</option>
                                                                    <option value="Reunion">Reunion</option>
                                                                    <option value="Romania">Romania</option>
                                                                    <option value="Russia">Russia</option>
                                                                    <option value="Rwanda">Rwanda</option>
                                                                    <option value="St Barthelemy">St Barthelemy</option>
                                                                    <option value="St Eustatius">St Eustatius</option>
                                                                    <option value="St Helena">St Helena</option>
                                                                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                                                    <option value="St Lucia">St Lucia</option>
                                                                    <option value="St Maarten">St Maarten</option>
                                                                    <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                                                    <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                                                    <option value="Saipan">Saipan</option>
                                                                    <option value="Samoa">Samoa</option>
                                                                    <option value="Samoa American">Samoa American</option>
                                                                    <option value="San Marino">San Marino</option>
                                                                    <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                                    <option value="Senegal">Senegal</option>
                                                                    <option value="Seychelles">Seychelles</option>
                                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                                    <option value="Singapore">Singapore</option>
                                                                    <option value="Slovakia">Slovakia</option>
                                                                    <option value="Slovenia">Slovenia</option>
                                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                                    <option value="Somalia">Somalia</option>
                                                                    <option value="South Africa">South Africa</option>
                                                                    <option value="Spain">Spain</option>
                                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                                    <option value="Sudan">Sudan</option>
                                                                    <option value="Suriname">Suriname</option>
                                                                    <option value="Swaziland">Swaziland</option>
                                                                    <option value="Sweden">Sweden</option>
                                                                    <option value="Switzerland">Switzerland</option>
                                                                    <option value="Syria">Syria</option>
                                                                    <option value="Tahiti">Tahiti</option>
                                                                    <option value="Taiwan">Taiwan</option>
                                                                    <option value="Tajikistan">Tajikistan</option>
                                                                    <option value="Tanzania">Tanzania</option>
                                                                    <option value="Thailand">Thailand</option>
                                                                    <option value="Togo">Togo</option>
                                                                    <option value="Tokelau">Tokelau</option>
                                                                    <option value="Tonga">Tonga</option>
                                                                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                                                    <option value="Tunisia">Tunisia</option>
                                                                    <option value="Turkey">Turkey</option>
                                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                                                    <option value="Tuvalu">Tuvalu</option>
                                                                    <option value="Uganda">Uganda</option>
                                                                    <option value="United Kingdom">United Kingdom</option>
                                                                    <option value="Ukraine">Ukraine</option>
                                                                    <option value="United Arab Erimates">United Arab Emirates</option>
                                                                    <option value="Uraguay">Uruguay</option>
                                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                                    <option value="Vanuatu">Vanuatu</option>
                                                                    <option value="Vatican City State">Vatican City State</option>
                                                                    <option value="Venezuela">Venezuela</option>
                                                                    <option value="Vietnam">Vietnam</option>
                                                                    <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                                                    <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                                                    <option value="Wake Island">Wake Island</option>
                                                                    <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                                                    <option value="Yemen">Yemen</option>
                                                                    <option value="Zaire">Zaire</option>
                                                                    <option value="Zambia">Zambia</option>
                                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* </span> */}
                                        </span>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Contact Info:</label>
                                            {/* <p style={{ fontSize: 12, width: 300 }}>{props.user.contact.email ? props.user.contact.email + " (email)" : null}{props.user.contact.email ? <br /> : null}
                                {props.user.contact.office ? formatPhone(props.user.contact.office) + " (office)" : null}{props.user.contact.office ? <br /> : null}
                                {props.user.contact.mobile ? formatPhone(props.user.contact.mobile) + " (mobile)" : null}{props.user.contact.mobile ? <br /> : null}
                                {props.user.contact.home ? formatPhone(props.user.contact.home) + " (home)" : null}{props.user.contact.home ? <br /> : null}
                                {props.user.contact.fax ? formatPhone(props.user.contact.fax) + " (fax)" : null}{props.user.contact.fax ? <br /> : null}
                            </p> */}

                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Email</td>
                                                            <td><input style={{ width: '100%', height: '100%' }} type="text" name="email" disabled={true} value={stateUserEmail} onChange={(e) => handleChangeObj(e, 'user_contact')} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Office</td>
                                                            <td><NumberFormat style={{ width: '100%', height: '100%' }} name="office" value={stateUserContact.office} format="+# (###) ###-####" onChange={(e) => handleChangeObj(e, 'user_contact')} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mobile</td>
                                                            <td><NumberFormat style={{ width: '100%', height: '100%' }} name="mobile" value={stateUserContact.mobile} format="+# (###) ###-####" onChange={(e) => handleChangeObj(e, 'user_contact')} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Home</td>
                                                            <td><NumberFormat style={{ width: '100%', height: '100%' }} name="home" value={stateUserContact.home} format="+# (###) ###-####" onChange={(e) => handleChangeObj(e, 'user_contact')} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fax</td>
                                                            <td><NumberFormat style={{ width: '100%', height: '100%' }} name="fax" value={stateUserContact.fax} format="+# (###) ###-####" onChange={(e) => handleChangeObj(e, 'user_contact')} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {/* {props.user.contact.email ? props.user.contact.email + " (email)" : null}{props.user.contact.email ? <br /> : null}
                                {props.user.contact.office ? formatPhone(props.user.contact.office) + " (office)" : null}{props.user.contact.office ? <br /> : null}
                                {props.user.contact.mobile ? formatPhone(props.user.contact.mobile) + " (mobile)" : null}{props.user.contact.mobile ? <br /> : null}
                                {props.user.contact.home ? formatPhone(props.user.contact.home) + " (home)" : null}{props.user.contact.home ? <br /> : null}
                                {props.user.contact.fax ? formatPhone(props.user.contact.fax) + " (fax)" : null}{props.user.contact.fax ? <br /> : null} */}
                                            </div>
                                        </span>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Google Connect:</label>
                                            {/* <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {stateGmail ?
                                                                    <span style={{ width: 'auto', width: 200, display: 'flex' }}><p style={{ fontSize: 12, width: 'auto' }}>{stateGmail}</p><FaTrash style={{ marginLeft: 10, marginTop: 2.5, cursor: 'pointer' }} size={12} color="#cdcdcd" onClick={deleteConnect} /></span> :
                                                                    <button style={{ width: 'auto', padding: 5, height: 22, fontSize: 10, color: '#fff', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} className="btn btn-primary px-3" onClick={connect}>CONNECT</button>}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> */}
                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {stateGmail ?
                                                                    <span style={{ width: 'auto', width: 200, display: 'flex' }}><p style={{ fontSize: 12, width: 'auto' }}>{props.email}</p></span> :
                                                                    // <button style={{ width: 'auto', padding: 5, height: 22, fontSize: 10, color: '#fff', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} className="btn btn-primary px-3" onClick={connect}>CONNECT</button>}
                                                                    <span style={{ width: 'auto', width: 200, display: 'flex' }}><p style={{ fontSize: 12, width: 'auto' }}>Not connected</p></span>}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', height: 280, width: 300, backgroundColor: 'rgb(1, 22, 48)', marginTop: 15, marginLeft: 30, alignItems: 'center', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', borderRadius: 10 }}>
                                        <UserAvatar setUserPhoto={props.setUserPhoto} image={stateUserPhoto} loadImage={loadImage} edit={stateEdit} />
                                    </div>
                                </div> :
                                <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: 500, height: 'auto', paddingTop: 10, paddingLeft: 10, display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Name:</label>
                                            {/* <p style={{ fontSize: 12, width: 300 }}>{props.user.name.first} {props.user.name.last}</p> */}
                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td>{stateUserName.first} {stateUserName.last}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </span>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Username:</label>
                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td>{stateUsername}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </span>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Role:</label>
                                            {/* <p style={{ fontSize: 12, width: 300 }}>{props.user.role}</p> */}
                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textTransform: 'capitalize' }}>{props.user.role}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </span>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Address:</label>
                                            {/* <span style={{ width: 'auto', display: 'flex', flexDirection: 'column', width: 300 }}> */}
                                            {/* <p style={{ fontSize: 12 }}>{props.user.address.address ? props.user.address.address : null}{props.user.address.address ? <br /> : null}{props.user.address.address2 ? props.user.address.address2 : null}{props.user.address.address2 ? <br /> : null}{props.user.address.city ? props.user.address.city : null}{props.user.address.city ? ", " : null}{props.user.address.state ? props.user.address.state : null}{props.user.address.state ? " " : null}{props.user.address.postal_code ? props.user.address.postal_code : null}{props.user.address.city || props.user.address.state || props.user.address.postal_code ? <br /> : null}{props.user.address.country ? props.user.address.country : null}</p> */}
                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p style={{ fontSize: 12, width: 200 }}>{stateUserAddress && stateUserAddress.address ? stateUserAddress.address : null}{stateUserAddress.address ? <br /> : null}{stateUserAddress.address2 ? stateUserAddress.address2 : null}{stateUserAddress.address2 ? <br /> : null}{stateUserAddress.city ? stateUserAddress.city : null}{stateUserAddress.city ? ", " : null}{stateUserAddress.state ? stateUserAddress.state : null}{stateUserAddress.state ? " " : null}{stateUserAddress.postal_code ? stateUserAddress.postal_code : null}{stateUserAddress.city || stateUserAddress.state || stateUserAddress.postal_code ? <br /> : null}{stateUserAddress.country ? stateUserAddress.country : null}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* </span> */}
                                        </span>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Contact Info:</label>
                                            {/* <p style={{ fontSize: 12, width: 300 }}>{props.user.contact.email ? props.user.contact.email + " (email)" : null}{props.user.contact.email ? <br /> : null}
                                    {props.user.contact.office ? formatPhone(props.user.contact.office) + " (office)" : null}{props.user.contact.office ? <br /> : null}
                                    {props.user.contact.mobile ? formatPhone(props.user.contact.mobile) + " (mobile)" : null}{props.user.contact.mobile ? <br /> : null}
                                    {props.user.contact.home ? formatPhone(props.user.contact.home) + " (home)" : null}{props.user.contact.home ? <br /> : null}
                                    {props.user.contact.fax ? formatPhone(props.user.contact.fax) + " (fax)" : null}{props.user.contact.fax ? <br /> : null}
                                </p> */}

                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        {stateUserEmail ?
                                                            <tr>
                                                                <td>Email</td>
                                                                <td>{stateUserEmail}</td>
                                                            </tr> : null}
                                                        {stateUserContact.office ?
                                                            <tr>
                                                                <td>Office</td>
                                                                <td><NumberFormat displayType={'text'} value={stateUserContact.office} format="+# (###) ###-####" /></td>
                                                            </tr> : null}
                                                        {stateUserContact.mobile ?
                                                            <tr>
                                                                <td>Mobile</td>
                                                                <td><NumberFormat displayType={'text'} value={stateUserContact.mobile} format="+# (###) ###-####" /></td>
                                                            </tr> : null}
                                                        {stateUserContact.home ?
                                                            <tr>
                                                                <td>Home</td>
                                                                <td><NumberFormat displayType={'text'} value={stateUserContact.home} format="+# (###) ###-####" /></td>
                                                            </tr> : null}
                                                        {stateUserContact.fax ?
                                                            <tr>
                                                                <td>Fax</td>
                                                                <td><NumberFormat displayType={'text'} value={stateUserContact.fax} format="+# (###) ###-####" /></td>
                                                            </tr> : null}
                                                    </tbody>
                                                </table>
                                                {/* {props.user.contact.email ? props.user.contact.email + " (email)" : null}{props.user.contact.email ? <br /> : null}
                                    {props.user.contact.office ? formatPhone(props.user.contact.office) + " (office)" : null}{props.user.contact.office ? <br /> : null}
                                    {props.user.contact.mobile ? formatPhone(props.user.contact.mobile) + " (mobile)" : null}{props.user.contact.mobile ? <br /> : null}
                                    {props.user.contact.home ? formatPhone(props.user.contact.home) + " (home)" : null}{props.user.contact.home ? <br /> : null}
                                    {props.user.contact.fax ? formatPhone(props.user.contact.fax) + " (fax)" : null}{props.user.contact.fax ? <br /> : null} */}
                                            </div>
                                        </span>
                                        <span style={{ width: '100%', display: 'flex' }}>
                                            <label style={{ fontSize: 12, width: 200 }}>Google Connect:</label>
                                            <div style={{ fontSize: 12, width: 300 }}>
                                                <table id="dash-settings-contact-table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {stateGmail ?
                                                                    <span style={{ width: 'auto', width: 200, display: 'flex' }}><p style={{ fontSize: 12, width: 'auto' }}>{props.email}</p></span> :
                                                                    // <button style={{ width: 'auto', padding: 5, height: 22, fontSize: 10, color: '#fff', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} className="btn btn-primary px-3" onClick={connect}>CONNECT</button>}
                                                                    <span style={{ width: 'auto', width: 200, display: 'flex' }}><p style={{ fontSize: 12, width: 'auto' }}>Not connected</p></span>}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', height: 280, width: 300, backgroundColor: 'rgb(1, 22, 48)', marginTop: 15, marginLeft: 30, alignItems: 'center', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', borderRadius: 10 }} className="form-group">
                                        <UserAvatar setUserPhoto={props.setUserPhoto} image={props.user.profile_img} edit={stateEdit} />
                                    </div>
                                </div>}
                        </div>
                        <div style={{ width: '100%', height: 'auto', padding: 10, display: 'flex' }}>
                            {/* {props.edit ?
                    <form style={{ width: 'auto', height: 'auto', paddingTop: 10, paddingLeft: 10, display: 'flex', flexDirection: 'column' }}>
                        <span style={{ width: '100%', display: 'flex' }}>
                            <label style={{ fontSize: 12, width: 200 }}>Base Currency:</label>
                            <CurrencyAutocomplete val={props.company.settings.currency} onChange={props.handleCurrencyChange} set="base" />
                        </span>
                        <span style={{ width: '100%', display: 'flex' }}>
                            <label style={{ fontSize: 12, width: 200 }}>Standard Commission:</label>
                            <NumberFormat className="form-control-sm" style={{ fontSize: 12, border: '1px solid #444', borderRadius: 2, padding: 10 }} name="commission_percent" value={props.company.settings.commission} thousandSeparator={true} suffix={"%"} />
                        </span>
                        <span style={{ width: '100%', display: 'flex' }}>
                            <label style={{ fontSize: 12, width: 200 }}>Logo:</label>
                        </span>
                    </form> : */}
                            <form style={{ width: 'auto', height: 'auto', paddingTop: 10, paddingLeft: 10, display: 'flex', flexDirection: 'column' }}>
                                <span style={{ width: '100%', display: 'flex' }}>
                                    <label style={{ fontSize: 12, width: 200 }}>Company Name:</label>
                                    {/* <p style={{ fontSize: 12, width: 200 }}>{props.company.company}</p> */}
                                    <div style={{ fontSize: 12, width: 300 }}>
                                        <table id="dash-settings-contact-table">
                                            <tbody>
                                                <tr>
                                                    <td>{props.company.company}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                                <span style={{ width: '100%', display: 'flex' }}>
                                    <label style={{ fontSize: 12, width: 200 }}>Address:</label>
                                    <div style={{ fontSize: 12, width: 300 }}>
                                        <table id="dash-settings-contact-table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p style={{ fontSize: 12, width: 200 }}>{props.company.address.address ? props.company.address.address : null}{props.company.address.address ? <br /> : null}{props.company.address.address2 ? props.company.address.address2 : null}{props.company.address.address2 ? <br /> : null}{props.company.address.city ? props.company.address.city : null}{props.company.address.city ? ", " : null}{props.company.address.state ? props.company.address.state : null}{props.company.address.state ? " " : null}{props.company.address.postal_code ? props.company.address.postal_code : null}{props.company.address.city || props.company.address.state || props.company.address.postal_code ? <br /> : null}{props.company.address.country ? props.company.address.country : null}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* <p style={{ fontSize: 12, width: 300 }}>
                                <table id="dash-settings-contact-table">
                                    <tr>
                                        <td>{props.company.address.address}</td>
                                    </tr>
                                    {props.company.address.address2 ?
                                        <tr>
                                            <td>{props.company.address.address2}</td>
                                        </tr> : null}
                                    <tr>
                                        <td>{props.company.address.city}</td>
                                    </tr>
                                    <tr>
                                        <td>{props.company.address.state}</td>
                                    </tr>
                                    <tr>
                                        <td>{props.company.address.postal_code}</td>
                                    </tr>
                                    <tr>
                                        <td>{props.company.address.country}</td>
                                    </tr>
                                </table>
                            </p> */}
                                </span>
                                <span style={{ width: '100%', display: 'flex' }}>
                                    <label style={{ fontSize: 12, width: 200 }}>Base Currency:</label>
                                    {/* <p style={{ fontSize: 12, width: 200 }}>{props.company.settings.currency}</p> */}
                                    <div style={{ fontSize: 12, width: 300 }}>
                                        <table id="dash-settings-contact-table">
                                            <tbody>
                                                <tr>
                                                    <td>{props.company.settings.currency}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                                <span style={{ width: '100%', display: 'flex' }}>
                                    <label style={{ fontSize: 12, width: 200 }}>Standard Commission:</label>
                                    <div style={{ fontSize: 12, width: 300 }}>
                                        <table id="dash-settings-contact-table">
                                            <tbody>
                                                <tr>
                                                    <td><NumberFormat style={{ fontSize: 12, width: 200 }} displayType={'text'} name="commission_percent" value={props.company.settings.commission} thousandSeparator={true} suffix={"%"} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                                <span style={{ width: '100%', display: 'flex' }}>
                                    <label style={{ fontSize: 12, width: 200 }}>Logo:</label>
                                    <div style={{ fontSize: 12, width: 300 }}>
                                        <table id="dash-settings-contact-table_nb">
                                            <tbody>
                                                <tr>
                                                    <td><a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => openInNewTab(props.user.logo)}>View</a> | <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => downloadFile(props.user.logo)}>Download</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const openInNewTab = (url) => {
    var image = new Image();
    image.src = url;

    var w = window.open("");
    w.document.write(image.outerHTML);
}

const downloadFile = (url) => {
    var a = document.createElement("a");
    a.href = url;
    a.download = "Image.png"; //File name Here
    a.click(); //Downloaded file
}