import React, { Component } from "react";
import { connect } from 'react-redux';
import { FaInfoCircle, FaAddressBook, FaSave } from 'react-icons/fa';
import { Pie, Doughnut, Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ChartColors } from "./chart-data";
import 'react-phone-number-input/style.css';
import { colors } from "@material-ui/core";

class CountComparison extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            render: 'info',
            company: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            website: '',
            facebook: '',
            instagram: '',
            phone: '',
            fax: '',
            count_data: [],
            span: [],
            igGPositive: [
                {
                    x: 5,
                    y: 1
                },
                {
                    x: 10,
                    y: 1
                },
                {
                    x: 15,
                    y: 4
                }
            ],
            igMPositive: [
                {
                    x: 4,
                    y: 0
                },
                {
                    x: 8,
                    y: 0
                },
                {
                    x: 20,
                    y: 0
                }
            ],
            //negative: [
            //    {
            //        x: new Date("2020-04-10"),
            //        y: 5
            //    },
            //    {
            //        x: new Date("2020-04-21"),
            //        y: 7
            //    },
            //    {
            //        x: new Date(),
            //        y: 3
            //    }
            //]
        };
        this.handleChange = this.handleChange.bind(this);
        this.getEvenOdd = this.getEvenOdd.bind(this);
    }

    static propTypes = {
        clients: PropTypes.object.isRequired,
    }

    componentDidMount() {

        //this.props.countdata.map((item, index) => {
        //    var data = [];
        //    item.counts.map(date => {
        //        data.push([{ _id: date._id, date: new Date(date.date) }], ...data)
        //    })

        //    var minDate = new Date(Math.min.apply(null, data));
        //    console.log(item)
        //})

        var data2 = [];
        var span = [];
        this.props.countcomparedata.map((item, index) => {
            var _id = item.id;
            var data = [];

            item.dates.forEach(dg => {
                var min = dg.date
                var d_id = dg._id

                //var min = new Date(Math.min(...item.dates.map(e => new Date(e.date))));


                //var date = new Date(item.dates[0].date)
                //var weekout = moment(date)
                //    .subtract(7, "days")
                //    .format("YYYY-MM-DD");
                //var monthout = moment(date)
                //    .subtract(1, "month")
                //    .format("YYYY-MM-DD");

                //item.dates.map(date => {
                //    data.push(new Date(date.date), ...data)
                //})
                //dates.push(minDate, ...dates)
                //console.log(weekout, monthout)
                console.log(this.props.countdata)
                this.props.countdata.map((count, i) => {
                    if (count._id === _id) {
                        //data.push({ count: count.counts, min: min }, ...data);

                        console.log(count)
                        count.counts.forEach(con => {
                            if (con._id === d_id) {
                                var sortCounts = con.count_data.sort(function (a, b) {
                                    // Turn your strings into dates, and then subtract them
                                    // to get a value that is either negative, positive, or zero.
                                    return new Date(b.date) - new Date(a.date);
                                });

                                var daydiff = [];
                                sortCounts.map(d => {
                                    var nDate = moment(d.date);
                                    var sDate = moment(min);
                                    var diff = sDate.diff(nDate, 'days');
                                    console.log(d)

                                    span.push(diff);
                                    daydiff.push({ x: diff, y: d.count });
                                    //console.log(diff)
                                })
                                console.log(daydiff)
                                var color = getRandomColor();
                                data.push({ span: daydiff, color: color, artist: item.artist, date: dg, venueid: item.venueid, _id: _id, d_id: d_id }, ...data)
                            }
                        })
                        // var sortCounts = count.counts.sort(function (a, b) {
                        //     // Turn your strings into dates, and then subtract them
                        //     // to get a value that is either negative, positive, or zero.
                        //     return new Date(b.date) - new Date(a.date);
                        // });

                        // console.log(sortCounts)
                        // var daydiff = [];
                        // sortCounts.map(d => {
                        //     var nDate = moment(d.date);
                        //     var sDate = moment(min);
                        //     var diff = sDate.diff(nDate, 'days');
                        //     console.log(d)

                        //     span.push(diff);
                        //     daydiff.push({ x: diff, y: d.count });
                        //     //console.log(diff)
                        // })
                        // console.log(daydiff)
                        // var color = getRandomColor();
                        // data.push({ span: daydiff, color: color }, ...data)
                    }
                    //var minDate = new Date(Math.min.apply(null, data));
                })
                console.log(data)
            })
            data2.push(data)
            //console.log(item)
        })

        const uniqueSpan = Array.from(new Set(span)).sort(function (a, b) {
            return new Date(b) - new Date(a);
        }).reverse();

        console.log(uniqueSpan)
        this.setState({
            count_data: data2,
            span: uniqueSpan
        })
        //console.log(this.getEvenOdd(1, 90).join(' '));
    }

    getEvenOdd(a, b) {
        var inc = +(a < b) || -1,
            i,
            result = [];
        for (i = a; i !== b + inc; i += inc) {
            if (+(inc === 1) !== i % 2) {
                result.push(i);
            }
        }
        return result;
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleClick = () => {
        this.props.toggle();
    };

    render() {

        console.log(this.state.count_data)

        // var countData = [];
        // var labelData = [];
        // var chartData = [];

        // this.props.counts.compare.map((item, index) => {
        //     var counts = [];
        //     var dates = [];
        //     item.counts.map(data => {
        //         counts.push(data.count, ...counts)
        //         dates.push(data.date, ...dates)
        //     })
        //     //console.log(counts, dates)
        //     chartData.push({ labels: dates, datasets: [{ data: counts }] }, ...chartData);
        // })

        //var show1 = [];
        //this.state.count_data.map((c_data, ind) => {
        //console.log(c_data[0])
        //})
        //console.log(this.state.igGPositive, this.state.count_data)

        var colors = [];
        var newArray = [];
        this.state.count_data.forEach((o, ind) => {

            console.log(o)
            if (o[0] && o[0].color) {
                var color = o[0].color;
                colors.push({ color: color, _id: o[0]._id, d_id: o[0].d_id })
                newArray.push(
                    {
                        label: "Tickets Sold",
                        fill: false,
                        lineTension: 0.2,
                        backgroundColor: color,
                        borderColor: color,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBorderColor: color,
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: color,
                        pointHoverBorderWidth: 2,
                        pointRadius: 4,
                        pointHitRadius: 10,
                        data: o[0].span.map(z => ({ x: z.x, y: z.y }))
                    }
                )
            }
        })
        //this.state.count_data[0].map(o => console.log(o))

        this.getEvenOdd(1, 90).join(' ')
        const data = {
            labels: this.state.span,
            datasets: newArray
        };

        //[
        //    moment()
        //        .subtract(1, "month")
        //        .format("YYYY-MM-DD"),
        //    moment().format("YYYY-MM-DD")
        //],

        const lineOptions = {
            scales: {
                xAxes: [
                    {
                        //type: "time",
                        //time: {
                        //    unit: "day",
                        //    tooltipFormat: "lll"
                        //},
                        gridLines: {
                            display: true
                        },
                        ticks: {
                            maxTicksLimit: 31
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Days from show date'
                        }
                    }
                ],
                yAxes: [
                    {
                        stacked: false,
                        gridLines: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            display: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Tickets Sold'
                        }
                    }
                ]
            },
            legend: {
                display: false
            },
            tooltips: {
                enabled: true
            }
        };

        const option = {
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                        var total = meta.total;
                        var currentValue = dataset.data[tooltipItem.index];
                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                        return currentValue + ' (' + percentage + '%)';
                    },
                    title: function (tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    }
                }
            },
            legend: {
                display: false,
                position: 'top'
            }
        }

        //console.log(this.state.count_data)

        return (
            <div className="mode-t">
                <div className="mode-t-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50 }}>
                        <span className="close" onClick={this.handleClick}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        <h1 style={{ color: '#fff', marginLeft: 40, fontFamily: 'Helvetica', paddingTop: 12, fontSize: 22, fontWeight: 'bold' }}>COUNT COMPARISON</h1>
                    </div>
                    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                        <div style={{ display: 'flex', width: '100%', height: 545.4 }}>
                            <div style={{ width: '30%' }}>
                                <div style={{ height: '100%', width: '100%', backgroundColor: 'rgb(1, 22, 48)' }}>
                                    <div style={{ paddingTop: 20, paddingBottom: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignitems: 'center', width: '100%' }}>
                                        {this.props.countcomparedata.map((item, index) => {
                                            var clientid = item.artist;
                                            var client = [];
                                            var venue = "";
                                            console.log(item);
                                            this.props.clients.clients.map(c => {
                                                if (clientid === c._id) {
                                                    client = c.client;
                                                }
                                            })
                                            this.props.items.items.map(v => {
                                                if (item.venueid === v._id) {
                                                    venue = v.venue_name;
                                                }
                                            })
                                            var ren = [];
                                            item.dates.forEach((dg, ind) => {

                                                var ret = { _id: item.id, d_id: dg._id  }
                                                var bgColor = colorSpot(ret, colors);
                                                var date = moment(dg.date).format('MM/DD/YYYY')

                                                ren.push(
                                                    <h2 style={{ fontSize: 12, color: '#cdcdcd', padding: 15, width: '90%', border: '2px solid #cdcdcd', marginLeft: '5%', marginRight: '5%' }}><div style={{ width: '100%', display: 'flex' }}><div style={{ width: '15%' }}><button style={{ width: '90%', height: 28, backgroundColor: bgColor }} /></div>{client}<br />{date} - {venue}</div></h2>
                                                )
                                            })
                                            return (
                                                ren
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '70%' }}>
                                <div style={{ marginTop: 50, marginLeft: '5%', width: '90%', marginRight: '5%' }}>
                                    <Line
                                        data={data} options={lineOptions}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function colorSpot(ret, colors) {
    console.log(ret, colors)
    var _id = ret._id;
    var d_id = ret.d_id;
    var result = "";
    colors.map(item => {
        if(item._id === _id && item.d_id === d_id){
            result = item.color
        }
    })
    console.log(result);
    return result
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const mapStateToProps = state => ({
    items: state.items,
    clients: state.clients,
    counts: state.counts,
    auth: state.auth
});

export default connect(mapStateToProps, null)(CountComparison);