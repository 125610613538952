import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import DatePicker from "react-datepicker";
import ClientAutocomplete from "./client.autocomplete";
import VenueAutocomplete from "./venue.autocomplete";
import ConnectIdAutocomplete from "./connect-id.autocomplete";
import VenueModal from "../../modals/venue.modal";

export default function EntryDialog(props) {

    const closeDialog = () => {
        props.handleClose();
    };

    if (props.dates) {
        let moments = props.dates.map(d => moment(d));
        var max = moment.max(moments);
        var min = moment.min(moments);
        var maxDate = new Date(max._i);
        var minDate = new Date(min._i);
    }

    return (
        <Dialog
            style={{ zIndex: 2 }}
            open={props.open}
            onClose={props.handleClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {props.entrySelect === '' ?
                <div>
                    <DialogTitle style={{ backgroundColor: '#000', color: '#cdcdcd' }} id="alert-dialog-title">Event Type:</DialogTitle>
                    <DialogContent style={{ backgroundColor: '#cdcdcd', zIndex: 2 }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ padding: 15 }}>
                                <Button style={{ backgroundColor: '#333333', color: '#cdcdcd' }} onClick={() => props.setEntrySelect("holds")}>Holds</Button>
                            </div>
                            <div style={{ padding: 15, marginLeft: 20 }}>
                                <Button style={{ backgroundColor: '#333333', color: '#cdcdcd' }} onClick={() => props.setEntrySelect("calendar")}>Calendar Entry</Button>
                            </div>
                        </div>
                    </DialogContent>
                </div> :

                props.entrySelect === 'holds' ?
                    <div>
                        <DialogTitle style={{ backgroundColor: '#000', color: '#cdcdcd' }} id="alert-dialog-title">Hold Status:</DialogTitle>
                        <DialogContent style={{ backgroundColor: '#cdcdcd' }}>
                            <div style={{ marginBottom: 10 }}>
                                <ClientAutocomplete clientList={props.clientList} onChange={props.onClientChange} clientSelect={props.artistbatch} toggleNewModal={props.toggleNewModal} />
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <VenueAutocomplete venueList={props.venueList} festivalList={props.festivalList} onChange={props.onVenueChange} venueSelect={props.venuebatch} toggleNewVenue={props.toggleNewVenue} />
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <ConnectIdAutocomplete offersList={props.offersList} onChange={props.onConnectChange} connectSelect={props.connectbatch}/>
                                {/* <input
                                    style={{ width: '100%', fontSize: 12, paddingTop: '2%', paddingBottom: '2%', paddingLeft: '4%', height: 28 }}
                                    type="text"
                                    placeholder="Offer ID"
                                    value={props.connectid}
                                    onChange={props.handleChange}
                                /> */}
                            </div>
                            {props.dates.map((item, index) => {
                                return (
                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto', height: 'auto' }}>
                                        <input style={{ width: 80, marginTop: -15, fontSize: 12 }} placeholder={index === 0 ? "ex: 2h" : null} type="text" value={props.newholds[index]} onChange={(e) => props.enterHoldsArray(e.target.value, index)} />
                                        <p style={{ fontSize: 16, marginLeft: 10, width: 85 }}>{item}</p>
                                        <input style={{ width: 100, marginTop: -15, marginLeft: 10, fontSize: 12 }} type="text" placeholder="notes" value={props.newholdnotes[index]} onChange={(e) => props.enterHoldNotes(e.target.value, index)} />
                                    </div>
                                )
                            })}
                            <button style={{ fontSize: 10, marginTop: -5, marginleft: 10, backgroundColor: '#000', color: '#cdcdcd', borderRadius: 5 }} onClick={props.toggleEditRange}>edit date range</button>
                            {props.editrange ?
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto', height: 'auto', marginTop: 10 }}>
                                        <p style={{ fontSize: 12, marginLeft: 10, width: 80 }}>Start Date:</p>
                                        <div style={{ width: 100, marginLeft: 10, fontSize: 12, marginTop: -10 }}>
                                            <DatePicker
                                                className="react-datepicker"
                                                value={minDate}
                                                selected={minDate}
                                                onChange={props.handleStartDateChange}
                                                withPortal
                                                todayButton="Today"
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto', height: 'auto' }}>
                                        <p style={{ fontSize: 12, marginLeft: 10, width: 80 }}>End Date:</p>
                                        <div style={{ width: 100, marginLeft: 10, fontSize: 12, marginTop: -10 }}>
                                            <DatePicker
                                                className="react-datepicker"
                                                value={maxDate}
                                                selected={maxDate}
                                                onChange={props.handleEndDateChange}
                                                withPortal
                                                todayButton="Today"
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                        </div>
                                    </div>
                                </div> : null}
                        </DialogContent>
                    </div> :

                    <div>
                        <DialogTitle style={{ backgroundColor: '#000', color: '#cdcdcd' }} id="alert-dialog-title">Calendar Entry:</DialogTitle>
                        <DialogContent style={{ backgroundColor: '#cdcdcd' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: 280, height: 26 }}>
                                <p style={{ width: '45%' }}>Type of Event:</p>
                                <select style={{ width: '55%', fontSize: 12, padding: 5 }} name="eventtype" value={props.eventtype} onChange={props.handleChange}>
                                    <option value=""> </option>
                                    <option value="Public">Public</option>
                                    <option value="Private">Private</option>
                                </select>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: 280, height: 26, marginTop: 10 }}>
                                <p style={{ width: '45%' }}>Event Name:</p>
                                <input style={{ width: '55%', fontSize: 12, padding: 5 }} type="text" name="eventname" value={props.eventname} onChange={props.handleChange} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: 280, height: 26, marginTop: 10 }}>
                                <p style={{ width: '45%' }}>Start Date:</p>
                                <div style={{ width: '55%' }}>
                                    <DatePicker
                                        className="react-datepicker"
                                        value={minDate}
                                        selected={minDate}
                                        onChange={props.handleStartDateChange}
                                        withPortal
                                        todayButton="Today"
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: 280, height: 26, marginTop: 10 }}>
                                <p style={{ width: '45%' }}>End Date:</p>
                                <div style={{ width: '55%' }}>
                                    <DatePicker
                                        className="react-datepicker"
                                        value={maxDate}
                                        selected={maxDate}
                                        onChange={props.handleEndDateChange}
                                        withPortal
                                        todayButton="Today"
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            {props.eventtype === 'Private' ?
                                <div style={{ display: 'flex', flexDirection: 'row', width: 280, height: 26, marginTop: 10 }}>
                                    <p style={{ width: '45%' }}>CC:</p>
                                    <input style={{ width: '55%', fontSize: 12 }} type="text" />
                                </div> : null}
                            <div style={{ display: 'flex', flexDirection: 'row', width: 280, height: 80, marginTop: 10, paddingBottom: 10 }}>
                                <p style={{ width: '45%' }}>Notes:</p>
                                <textarea style={{ minWidth: '55%', maxWidth: '55%', minHeight: 80, maxHeight: 80, fontSize: 12, padding: 5 }} type="text" name="eventnotes" value={props.eventnotes} onChange={props.handleChange} />
                            </div>
                        </DialogContent>
                    </div>}


            <DialogActions style={{ backgroundColor: '#000' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: '#000' }} onClick={closeDialog}>Cancel</Button>
                {props.entrySelect === 'holds' && !props.renderholdsdialog || props.entrySelect === 'calendar' && !props.renderholdsdialog ?
                    <Button style={{ backgroundColor: '#cdcdcd', color: '#000' }} onClick={() => props.setEntrySelect("")} autoFocus>Back</Button> : null}
                {props.entrySelect === 'holds' && props.renderholdsdialog || props.entrySelect === 'calendar' && props.renderholdsdialog ?
                    <Button style={{ backgroundColor: '#cdcdcd', color: '#000' }} onClick={props.deleteHolds} autoFocus>Delete</Button> : null}
                <Button style={{ backgroundColor: '#cdcdcd', color: '#000' }} onClick={props.renderholdsdialog ? props.updateHolds : props.saveHolds} autoFocus>Save</Button>
            </DialogActions>
        </Dialog>
    );
}