import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    COUNTS_LOADING,
    GET_COUNTS,
    LOAD_COUNTS_ROL,
    FILTER_COUNTS,
    SORT_COUNTS,
    ADD_COUNT,
    ADD_COUNT_SOCKET,
    ADD_COUNT_BULK_SOCKET,
    UPDATE_COUNT,
    DELETE_COUNT,
    COUNT_COMPARE,
    COUNT_FETCH,
    SET_COUNTS_GRID,
    UPDATE_COUNTS_GRID
} from "./types";
import _ from 'lodash';
import moment from 'moment';

export const setCountsLoading = () => {
    return {
        type: COUNTS_LOADING
    };
};

export const getCountRolodex = () => (dispatch, getState) => {
    //dispatch(setCountsLoading());
    return axios.get(`${process.env.REACT_APP_API}/counts/`, tokenConfig(getState))
        .then(res => {
            if (res.data !== null) {
                dispatch({
                    type: GET_COUNTS,
                    payload: res.data
                })
                return res.data
            } else {
                dispatch({
                    type: GET_COUNTS,
                    payload: [{}]
                })
                return [{}]
            }
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};

export const getCounts = id => (dispatch, getState) => {
    //dispatch(setCountsLoading());
    return axios.get(`${process.env.REACT_APP_API}/counts/${id}`, tokenConfig(getState))
        .then(res => {
            if (res.data) {
                dispatch({
                    type: GET_COUNTS,
                    payload: res.data.counts
                })
                return res.data.counts
            } else {
                dispatch({
                    type: GET_COUNTS,
                    payload: null
                })
                return null
            }
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};

export const addCount = (count) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/counts/add`, count, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const addCountSocket = counts => (dispatch) => {
    dispatch({
        type: ADD_COUNT_SOCKET,
        payload: counts.counts
    })
}

export const addCountBulkSocket = counts => (dispatch) => {
    dispatch({
        type: ADD_COUNT_BULK_SOCKET,
        payload: counts
    })
}

export const updateCount = (count, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/counts/update/${id}`, count, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const addCountsBulk = (counts, id) => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API}/counts/add/bulk`, counts, tokenConfig(getState))
        .then(res => {

        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
}

export const deleteCount = id => dispatch => {
    axios.delete(`${process.env.REACT_APP_API}/counts/${id}`)
        .then(res =>
            dispatch({
                type: DELETE_COUNT,
                payload: id
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
}

export const countCompare = (ids) => (dispatch, getState) => {
    dispatch(setCountsLoading());
    axios.post(`${process.env.REACT_APP_API}/counts/fetch`, ids, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: COUNT_COMPARE,
                payload: res.data
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const countFetch = (ids) => (dispatch, getState) => {
    //dispatch(setCountsLoading());
    return axios.post(`${process.env.REACT_APP_API}/counts/fetch`, ids, tokenConfig(getState))
        .then(res => {
            return res.data
            // dispatch({
            //     type: COUNT_FETCH,
            //     payload: res.data
            // })
        })
        .catch(err => {
            return err.response.data
            //dispatch(returnErrors(err.response.data, err.response.status))
        });
};

export const loadCountsRolodex = (offers) => (dispatch, getState) => {
    dispatch(setCountsLoading());
    dispatch({
        type: LOAD_COUNTS_ROL,
        payload: offers
    })
};

export const applyCountsFilter = (counts_filter) => (dispatch, getState) => {
    dispatch(setCountsLoading());
    dispatch({
        type: FILTER_COUNTS,
        payload: counts_filter
    })
};

export const countsSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: SORT_COUNTS,
        payload: sort
    })
};

export const verifyCounts = data => (dispatch, getState) => {
    //dispatch(setCountsLoading());
    return axios.post(`${process.env.REACT_APP_API}/counts/verify`, data, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};

export const setCountsGrid = (arrData) => (dispatch, getState) => {
    dispatch(setCountsLoading());
    // var filtered_offers = offers;
    // var arrData = [];
    // filtered_offers.map((item, index) => {

    //     var id = item._id;
    //     var venueid = item.venue_id;
    //     var venue = [];
    //     var city = [];
    //     var state = [];
    //     this.props.items.items.map((venues, i) => {
    //         if (venueid === venues._id) {
    //             venue = venues.venue_name
    //             city = venues.address.city
    //             state = venues.address.state
    //         }
    //     })

    //     this.props.festivals.items.map((venues, i) => {
    //         if (venueid === venues._id) {
    //             venue = venues.festival
    //             city = venues.address.city
    //             state = venues.address.state
    //         }
    //     })

    //     var buyerid = item.buyer_id;
    //     var buyer = [];
    //     this.props.buyers.buyers.map((buyers, key) => {
    //         if (buyerid === buyers._id) {
    //             buyer = buyers.company
    //         }
    //     })

    //     var artistid = item.client_id;
    //     var artist = [];
    //     this.props.clients.clients.map((client, key) => {
    //         if (artistid === client._id) {
    //             artist = client.client
    //         }
    //     })


    //     var counts = [];
    //     count_data.map((c, i) => {
    //         if (id === c._id) {
    //             counts = c.counts;
    //         }
    //     });

    //     var day = moment().format('YYYY-MM-DD').toString();

    //     var render = [];
    //     item.show_info.forEach((d, i) => {

    //         var countA = [];
    //         var countADate = [];
    //         var countB = [];
    //         var countBDate = [];
    //         var countC = [];
    //         var countCDate = [];
    //         var countD = [];
    //         var countDDate = [];
    //         var countT = [];
    //         var notesT = [];
    //         var increaseA = '';
    //         var perincreaseA = '';
    //         var increaseB = '';
    //         var perincreaseB = '';
    //         var increaseC = '';
    //         var perincreaseC = '';
    //         var increaseT = '';
    //         var perincreaseT = '';
    //         var diffAVal = [];
    //         var diffBVal = [];
    //         var diffCVal = [];
    //         var diffTVal = [];

    //         counts.map(c => {
    //             if (d._id === c._id) {
    //                 var sortDate = _.sortBy(c.count_data.filter(o => o.date !== day), function (dateObj) {
    //                     return new Date(dateObj.date);
    //                 }).reverse();
    //                 var sortToday = _.sortBy(c.count_data.filter(o => o.date === day), function (dateObj) {
    //                     return new Date(dateObj.date);
    //                 }).reverse();

    //                 if (sortDate[0]) {
    //                     countA.push(sortDate[0].count);
    //                     countADate.push(sortDate[0].date);
    //                 }
    //                 if (sortDate[1]) {
    //                     countB.push(sortDate[1].count);
    //                     countBDate.push(sortDate[1].date);
    //                 }
    //                 if (sortDate[2]) {
    //                     countC.push(sortDate[2].count);
    //                     countCDate.push(sortDate[2].date);
    //                 }
    //                 if (sortDate[3]) {
    //                     countD.push(sortDate[3].count);
    //                     countDDate.push(sortDate[3].date);
    //                 }
    //                 if (sortToday[0]) {
    //                     countT.push(sortToday[0].count);
    //                     notesT.push(sortToday[0].notes);
    //                 }

    //                 var dA = moment(new Date(countADate));
    //                 var dB = moment(new Date(countBDate));
    //                 var dC = moment(new Date(countCDate));
    //                 var dD = moment(new Date(countDDate));
    //                 var dT = moment(new Date());
    //                 var diffA = dA.diff(dB, 'days');
    //                 var diffB = dB.diff(dC, 'days');
    //                 var diffC = dC.diff(dD, 'days');
    //                 var diffT = dT.diff(dA, 'days');
    //                 if (Number.isNaN(diffA)) { diffAVal = "-" } else { diffAVal = diffA };
    //                 if (Number.isNaN(diffB)) { diffBVal = "-" } else { diffBVal = diffB };
    //                 if (Number.isNaN(diffC)) { diffCVal = "-" } else { diffCVal = diffC };
    //                 if (Number.isNaN(diffT)) { diffTVal = "-" } else { diffTVal = diffT };

    //                 increaseA = countA - countB;
    //                 perincreaseA = parseFloat(((countA - countB) / countB) * 100).toFixed(0) + "%";

    //                 increaseB = countB - countC;
    //                 perincreaseB = parseFloat(((countB - countC) / countC) * 100).toFixed(0) + "%";

    //                 increaseC = countC - countD;
    //                 perincreaseC = parseFloat(((countC - countD) / countD) * 100).toFixed(0) + "%";

    //                 increaseT = countT - countA;
    //                 perincreaseT = parseFloat(((countT - countA) / countT) * 100).toFixed(0) + "%";
    //             }
    //         })

    //         var nd = moment(d.date).format('M/DD/YYYY');
    //         render.push({
    //             _id: item._id,
    //             client: artist,
    //             date: nd,
    //             venue: venue,
    //             city: city,
    //             state: state,
    //             sellable: item.sellable_cap[i].amt,
    //             countT: countT,
    //             perincreaseT: perincreaseT,
    //             day: day,
    //             diffTVal: diffTVal,
    //             increaseT: increaseT,
    //             perincreaseA: perincreaseA,
    //             perincreaseB: perincreaseB,
    //             perincreaseC: perincreaseC,
    //             date_id: d._id,
    //             countADate: countADate,
    //             countBDate: countBDate,
    //             countCDate: countCDate,
    //             diffAVal: diffAVal,
    //             diffBVal: diffBVal,
    //             diffCVal: diffCVal,
    //             increaseA: increaseA,
    //             increaseB: increaseB,
    //             increaseC: increaseC,
    //             countA: countA,
    //             countB: countB,
    //             countC: countC,
    //             index: index,
    //             notes: ''
    //         })
    //     })
    //     arrData.push(...render);
    // })
    dispatch({
        type: SET_COUNTS_GRID,
        payload: arrData
    })
    //return arrData
}

export const updateCountsGrid = (arrData) => (dispatch, getState) => {
    //dispatch(setCountsLoading());
    dispatch({
        type: UPDATE_COUNTS_GRID,
        payload: arrData
    })
}

// export const getSetCountsRolodex = () => (dispatch, getState) => {
//     return axios.get(`${process.env.REACT_APP_API}/counts/`, tokenConfig(getState))
//         .then(res => {
//             if (res.data !== null) {
//                 const offers = getState().offers.offers;
//                 offers = await this.filterCountsStart(offers);

//                 var arrData = [];
//                 offers.map((item, index) => {

//                     var id = item._id;
//                     var venueid = item.venue_id;
//                     var venue = [];
//                     var city = [];
//                     var state = [];
//                     this.props.items.items.map((venues, i) => {
//                         if (venueid === venues._id) {
//                             venue = venues.venue_name
//                             city = venues.address.city
//                             state = venues.address.state
//                         }
//                     })

//                     this.props.festivals.items.map((venues, i) => {
//                         if (venueid === venues._id) {
//                             venue = venues.festival
//                             city = venues.address.city
//                             state = venues.address.state
//                         }
//                     })

//                     var buyerid = item.buyer_id;
//                     var buyer = [];
//                     this.props.buyers.buyers.map((buyers, key) => {
//                         if (buyerid === buyers._id) {
//                             buyer = buyers.company
//                         }
//                     })

//                     var artistid = item.client_id;
//                     var artist = [];
//                     this.props.clients.clients.map((client, key) => {
//                         if (artistid === client._id) {
//                             artist = client.client
//                         }
//                     })


//                     var counts = [];
//                     count_data.map((c, i) => {
//                         if (id === c._id) {
//                             counts = c.counts;
//                         }
//                     });

//                     var day = moment().format('YYYY-MM-DD').toString();

//                     var render = [];
//                     item.show_info.forEach((d, i) => {

//                         var countA = [];
//                         var countADate = [];
//                         var countB = [];
//                         var countBDate = [];
//                         var countC = [];
//                         var countCDate = [];
//                         var countD = [];
//                         var countDDate = [];
//                         var countT = [];
//                         var notesT = [];
//                         var increaseA = '';
//                         var perincreaseA = '';
//                         var increaseB = '';
//                         var perincreaseB = '';
//                         var increaseC = '';
//                         var perincreaseC = '';
//                         var increaseT = '';
//                         var perincreaseT = '';
//                         var diffAVal = [];
//                         var diffBVal = [];
//                         var diffCVal = [];
//                         var diffTVal = [];

//                         counts.map(c => {
//                             if (d._id === c._id) {
//                                 var sortDate = _.sortBy(c.count_data.filter(o => o.date !== day), function (dateObj) {
//                                     return new Date(dateObj.date);
//                                 }).reverse();
//                                 var sortToday = _.sortBy(c.count_data.filter(o => o.date === day), function (dateObj) {
//                                     return new Date(dateObj.date);
//                                 }).reverse();

//                                 if (sortDate[0]) {
//                                     countA.push(sortDate[0].count);
//                                     countADate.push(sortDate[0].date);
//                                 }
//                                 if (sortDate[1]) {
//                                     countB.push(sortDate[1].count);
//                                     countBDate.push(sortDate[1].date);
//                                 }
//                                 if (sortDate[2]) {
//                                     countC.push(sortDate[2].count);
//                                     countCDate.push(sortDate[2].date);
//                                 }
//                                 if (sortDate[3]) {
//                                     countD.push(sortDate[3].count);
//                                     countDDate.push(sortDate[3].date);
//                                 }
//                                 if (sortToday[0]) {
//                                     countT.push(sortToday[0].count);
//                                     notesT.push(sortToday[0].notes);
//                                 }

//                                 var dA = moment(new Date(countADate));
//                                 var dB = moment(new Date(countBDate));
//                                 var dC = moment(new Date(countCDate));
//                                 var dD = moment(new Date(countDDate));
//                                 var dT = moment(new Date());
//                                 var diffA = dA.diff(dB, 'days');
//                                 var diffB = dB.diff(dC, 'days');
//                                 var diffC = dC.diff(dD, 'days');
//                                 var diffT = dT.diff(dA, 'days');
//                                 if (Number.isNaN(diffA)) { diffAVal = "-" } else { diffAVal = diffA };
//                                 if (Number.isNaN(diffB)) { diffBVal = "-" } else { diffBVal = diffB };
//                                 if (Number.isNaN(diffC)) { diffCVal = "-" } else { diffCVal = diffC };
//                                 if (Number.isNaN(diffT)) { diffTVal = "-" } else { diffTVal = diffT };

//                                 increaseA = countA - countB;
//                                 perincreaseA = parseFloat(((countA - countB) / countB) * 100).toFixed(0) + "%";

//                                 increaseB = countB - countC;
//                                 perincreaseB = parseFloat(((countB - countC) / countC) * 100).toFixed(0) + "%";

//                                 increaseC = countC - countD;
//                                 perincreaseC = parseFloat(((countC - countD) / countD) * 100).toFixed(0) + "%";

//                                 increaseT = countT - countA;
//                                 perincreaseT = parseFloat(((countT - countA) / countT) * 100).toFixed(0) + "%";
//                             }
//                         })

//                         var nd = moment(d.date).format('M/DD/YYYY');
//                         render.push({
//                             ...item,
//                             client: artist,
//                             date: nd,
//                             venue: venue,
//                             city: city,
//                             state: state,
//                             sellable: item.sellable_cap[i].amt,
//                             countT: countT,
//                             perincreaseT: perincreaseT,
//                             day: day,
//                             diffTVal: diffTVal,
//                             increaseT: increaseT,
//                             perincreaseA: perincreaseA,
//                             perincreaseB: perincreaseB,
//                             perincreaseC: perincreaseC,
//                             date_id: d._id,
//                             countADate: countADate,
//                             countBDate: countBDate,
//                             countCDate: countCDate,
//                             diffAVal: diffAVal,
//                             diffBVal: diffBVal,
//                             diffCVal: diffCVal,
//                             increaseA: increaseA,
//                             increaseB: increaseB,
//                             increaseC: increaseC,
//                             countA: countA,
//                             countB: countB,
//                             countC: countC,
//                             index: index,
//                             notes: ''
//                         })
//                     })
//                     arrData.push(...render);
//                 })

//                 dispatch({
//                     type: GET_COUNTS,
//                     payload: res.data
//                 })
//                 return res.data
//             } else {
//                 dispatch({
//                     type: GET_COUNTS,
//                     payload: [{}]
//                 })
//                 return [{}]
//             }
//         })
//         .catch(err => {
//             dispatch(returnErrors(err.response.data, err.response.status))
//             return err.response.data
//         });
// }

// export const emailCountReqAll = (data) => (dispatch, getState) => {
//     return axios.post(`${process.env.REACT_APP_API}/counts/email/all`, data, tokenConfig(getState))
//         .then(res => {
//             return res.data
//         })
//         .catch(err => {
//             //dispatch(returnErrors(err.response.data, err.response.status))
//             return err.response.data
//         });
// }

export const sendCountEmails = (data) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/email/counts/group`, data, tokenConfig(getState))
        .then(res => {
            console.log(res.data)
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}
