import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FaPlusSquare, FaMinusSquare } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert'
import { saveEmailGroup, removeEmailGroup } from '../../actions/companyActions';
import CountContactsAutocomplete from "../../components/autocompletes/count-contacts-autocomplete";
import NumberFormat from 'react-number-format';
import EmailGroupDialog from '../../components/database/email-group.dialog';
import EmailGroupsModal from '../email/email-groups.modal';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { refineProps } from "@fullcalendar/react";
import _ from 'lodash';

class OfferConfig extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            date1Toggle: false,
            date2Toggle: false,
            date3Toggle: false,
            date4Toggle: false,
            date5Toggle: false,
            date6Toggle: false,
            depositadd: 1,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            dates_array: [],
            show_select: '',
            link_data: [],
            contact_roll: [],
            group_dialog: false,
            group_edit_dialog: true,
            groups: [],
            loading: false,
            group_array: [],
            group_check: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTcConChange = this.handleTcConChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        offers: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        var render = [];
        this.setState({
            loading: true
        })



        // this.props.add_contacts.map(con => {
        //     this.props.contacts.contacts.map(cli => {
        //         if (con.contact_id === cli._id) {
        //             cli.role = con.role
        //             render.push(cli)
        //         }
        //     })
        // })

        this.props.contacts.contacts.map(item => {
            item.tags.map((t, i) => {
                if (t.tag === this.props.buyercompanyid || t.tag === this.props.venueid) {
                    const n = {
                        ...item,
                        role: t.role
                    }
                    render.push(n)
                }
            })
        });
        render = await getUnique(render, '_id');

        // this.props.contacts.contacts.map(item => {
        //     item.tags.map((t, i) => {
        //         if (t.tag === this.props.buyercompanyid || t.tag === this.props.venueid) {
        //             render.push(item) 
        //         }
        //     }) 
        // })
        var groups = [];
        if (this.props.auth.user.company.email) {
            this.props.count_config.group.forEach((gr, ind) => {
                this.props.auth.user.company.email.groups.map((item, index) => {
                    if (gr._id === item._id) {
                        groups.push(item)
                    }
                })
            })
        }

        this.setState({
            contact_roll: render,
            groups: groups,
            group_check: this.props.auth.user.company.email.groups,
            loading: false
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.count_config !== this.props.count_config) {
            // var render = [];
            // this.props.contacts.contacts.map(item => {
            //     item.tags.map((t, i) => {
            //         if (t.tag === this.props.buyercompanyid || t.tag === this.props.venueid) {
            //             const n = {
            //                 ...item,
            //                 role: t.role
            //             }
            //             render.push(n)
            //         }
            //     })
            // });
            // render = await getUnique(render, '_id');
            var groups = [];
            if (this.props.auth.user.company.email) {
                this.props.count_config.group.forEach((gr, ind) => {
                    this.props.auth.user.company.email.groups.map((item, index) => {
                        if (gr._id === item._id) {
                            groups = [...groups, item]
                        }
                    })
                })
            }
            this.setState({
                groups: groups,
            })
        }

        if (this.props.auth.user.company.email && prevProps.email_groups !== this.props.auth.user.company.email.groups) {
            var render = [];
            const filteredOffers = this.props.offers.offers.filter((item) => {
                const today = new Date();
                var val = _.get(item, 'show_info')
                const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                var filterBatch = new Date(closest.date)
                var start = today
                return filterBatch >= start
            })
            if (this.props.auth.user.company.email) {
                this.props.auth.user.company.email.groups.forEach(g => {
                    var o_fil = [];
                    filteredOffers.map(o => {
                        o.count_config.group.map(s => {
                            if (s._id === g._id) {
                                o_fil = [...o_fil, o]
                            }
                        })
                    });
                    const newObj = {
                        ...g,
                        subs: o_fil
                    }
                    render.push(newObj)
                })
            }
            this.setState({
                group_array: render
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    updateContractIssued = (props) => {
        this.props.updateContractIssued(props)
    }
    deleteContractIssued = (props) => {
        this.props.deleteContractIssued(props)
    }

    updateContractReceived = (props) => {
        this.props.updateContractReceived(props)
    }
    deleteContractReceived = (props) => {
        this.props.deleteContractReceived(props)
    }

    updateDepositReceived = (props) => {
        this.props.updateDepositReceived(props)
    }
    deleteDepositReceived = (props) => {
        this.props.deleteDepositReceived(props)
    }

    handleCheckbox = (e) => {
        this.setState({
            [e]: !this.state[e]
        })
    }

    checkMWF = () => {
        if (this.state.monday) {
            this.setState({
                monday: false,
                wednesday: false,
                friday: false
            })
        } else {
            this.setState({
                monday: true,
                wednesday: true,
                friday: true
            })
        }
    }

    checkTT = () => {
        if (this.state.tuesday) {
            this.setState({
                tuesday: false,
                thursday: false,
            })
        } else {
            this.setState({
                tuesday: true,
                thursday: true,
            })
        }
    }

    handleTcConChange(e) {
        if (e.target.value === 'Add New Group') {
            var render = [];
            const filteredOffers = this.props.offers.offers.filter((item) => {
                const today = new Date();
                var val = _.get(item, 'show_info')
                const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                var filterBatch = new Date(closest.date)
                var start = today
                return filterBatch >= start
            })
            if (this.props.auth.user.company.email) {
                this.props.auth.user.company.email.groups.forEach(g => {
                    var o_fil = [];
                    filteredOffers.map(o => {
                        o.count_config.group.map(s => {
                            if (s._id === g._id) {
                                o_fil = [...o_fil, o]
                            }
                        })
                    });
                    const newObj = {
                        ...g,
                        subs: o_fil
                    }
                    render.push(newObj)
                })
            }
            this.setState({
                group_dialog: true,
                group_array: render
            })
        } else {
            this.props.handleTcConChange(e);
        }
    }

    closeGroupDialog = () => {
        this.setState({
            group_dialog: false
        })
    }

    closeGroupEditDialog = () => {
        this.setState({
            group_edit_dialog: false
        })
    }

    removeGroupDialog = (group) => {
        console.log("GROUP", group)
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Delete Group</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Are you sure you'd like to delete the group {group.name} ({group.subs.length} offers subscribed)?
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={onClose}>No</button>
                            <button style={{ borderRadius: 10 }} onClick={() => { this.removeGroup(group._id); onClose(); }}>Yes</button>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }
        confirmAlert(options)
    }

    removeGroup = (id) => {
        const data = {
            value: id
        }
        this.props.removeEmailGroup(data);

        // var groups = this.state.group_array;
        // groups = groups.filter(item => item._id !== id);
        // this.setState({
        //     group_array: groups
        // })
    }

    render() {

        const show_index = this.props.links.findIndex(obj => obj._id === this.props.show_select);
        var showLinks = this.props.links[show_index];

        //<td style={{ color: '#000', width: '64.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><input style={{ width: '100%', height: '100%', fontSize: 12 }} type="text" name={index} value={item.url} onChange={this.props.handleAnnounceUrlChange} /></td>
        //<BuyerAutocomplete buyerList={this.props.buyers.buyers} onChange={this.props.onBuyerChange} buyerSelect={this.props.buyerbatch} />



        // {this.props.tc_check ?
        //     <div style={{ height: 'auto', width: '100%', padding: 10, borderTop: '1px solid rgb(1, 22, 48)' }}>
        //         {/* <h1 style={{ marginTop: 20, color: '#fff', fontSize: 14 }}>Ticket Count E-mails:</h1> */}
        //         <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
        //             <label style={{ fontSize: 14, color: '#000' }}>Ticket Count Contact:</label>
        //             {this.props.edit ?
        //                 <select style={{ width: 250, fontSize: 12, border: '.4px solid #000', height: 28, padding: 12, marginLeft: 10, color: '#fff' }} className="form-control-sm" disabled={this.props.buyercompany ? false : true} value={this.props.count_contact} name="count_contact" onChange={this.props.onChange}>
        //                     <option value=""> </option>
        //                     {this.props.contacts.contacts.map(item => {
        //                         var render = [];
        //                         item.tags.map((t, i) => {
        //                             if (t.tag === this.props.buyercompanyid) {
        //                                 render.push(
        //                                     <option key={i._id} value={item._id}>{item.name.first} {item.name.last} ({t.role})</option>
        //                                 )
        //                             }
        //                         })
        //                         return (
        //                             render
        //                         )
        //                     })}
        //                 </select> :
        //                 <input style={{ width: 250, fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12, marginLeft: 10 }} disabled={true} type="text" />}
        //             <button style={{ width: 'auto', backgroundColor: '#cdcdcd', color: '#000', fontSize: 12, borderRadius: 5, marginLeft: 20 }} onClick={this.props.checkMWF}>Check M/W/F</button>
        //             <button style={{ width: 'auto', backgroundColor: '#cdcdcd', color: '#000', fontSize: 12, borderRadius: 5, marginLeft: 10 }} onClick={this.props.checkTT}>Check T/Th</button>
        //         </div>
        //         <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        //             <table style={{ marginTop: 10, width: '100%', marginBottom: 20 }}>
        //                 <thead style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
        //                     <tr>
        //                         <th style={{ color: '#fff', width: '20%', fontSize: 14, border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>Monday</div></th>
        //                         <th style={{ color: '#fff', width: '20%', fontSize: 14, border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>Tuesday</div></th>
        //                         <th style={{ color: '#fff', width: '20%', fontSize: 14, border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>Wednesday</div></th>
        //                         <th style={{ color: '#fff', width: '20%', fontSize: 14, border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>Thursday</div></th>
        //                         <th style={{ color: '#fff', width: '20%', fontSize: 14, border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>Friday</div></th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     <tr>
        //                         <td style={{ color: '#fff', width: '20%', backgroundColor: '#fff', border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
        //                             <Checkbox
        //                                 icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //                                 checkedIcon={<CheckBoxIcon fontSize="small" />}
        //                                 color="default"
        //                                 checked={this.props.tc_auto.monday}
        //                                 onChange={() => this.props.handleTcAutoCheckbox("monday")}
        //                                 name="monday"
        //                             /></div></td>
        //                         <td style={{ color: '#000', width: '20%', backgroundColor: '#fff', border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
        //                             <Checkbox
        //                                 icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //                                 checkedIcon={<CheckBoxIcon fontSize="small" />}
        //                                 color="default"
        //                                 checked={this.props.tc_auto.tuesday}
        //                                 onChange={() => this.props.handleTcAutoCheckbox("tuesday")}
        //                                 name="tuesday"
        //                             /></div></td>
        //                         <td style={{ color: '#000', width: '20%', backgroundColor: '#fff', border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
        //                             <Checkbox
        //                                 icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //                                 checkedIcon={<CheckBoxIcon fontSize="small" />}
        //                                 color="default"
        //                                 checked={this.props.tc_auto.wednesday}
        //                                 onChange={() => this.props.handleTcAutoCheckbox("wednesday")}
        //                                 name="wednesday"
        //                             /></div></td>
        //                         <td style={{ color: '#000', width: '20%', backgroundColor: '#fff', border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
        //                             <Checkbox
        //                                 icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //                                 checkedIcon={<CheckBoxIcon fontSize="small" />}
        //                                 color="default"
        //                                 checked={this.props.tc_auto.thursday}
        //                                 onChange={() => this.props.handleTcAutoCheckbox("thursday")}
        //                                 name="thursday"
        //                             /></div></td>
        //                         <td style={{ color: '#000', width: '20%', backgroundColor: '#fff', border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
        //                             <Checkbox
        //                                 icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //                                 checkedIcon={<CheckBoxIcon fontSize="small" />}
        //                                 color="default"
        //                                 checked={this.props.tc_auto.friday}
        //                                 onChange={() => this.props.handleTcAutoCheckbox("friday")}
        //                                 name="friday"
        //                             /></div></td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //         </div>
        //     </div> : null}

        return (
            <div className="enterdealMain" >
                {this.state.loading ? null :
                    <div>
                        {/* <EmailGroupDialog open={this.state.group_dialog} handleClose={this.closeGroupDialog} group_dialog={this.state.group_dialog} saveEmailGroup={this.props.saveEmailGroup} /> */}
                        {/* <EmailGroupsModal open={this.state.group_dialog} close={this.closeGroupDialog} groups={this.props.auth.user.company.email ? this.props.auth.user.company.email.groups : []}/> */}
                        <EmailGroupsModal open={this.state.group_dialog} close={this.closeGroupDialog} groups={this.props.auth.user.company.email ? this.state.group_array : []} removeGroup={this.removeGroupDialog} saveEmailGroup={this.props.saveEmailGroup} />
                        <div style={{ width: '100%', height: 'auto' }}>
                            <div>
                                <h1 style={{ marginTop: 20, marginLeft: 50, color: '#000', fontSize: 14 }}>Announce / On Sale:</h1>
                            </div>
                            <div style={{ marginLeft: 50, height: 'auto', width: '90%' }} className="form-control">
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <h1 style={{ fontSize: 14, color: '#000', paddingTop: 5 }}>Show Date:</h1>
                                    <select style={{ width: 200, fontSize: 12, color: '#000', backgroundColor: '#fff', height: 28, marginLeft: 10 }} name="show_select" value={this.props.show_select} onChange={(e) => this.props.toggleDateSelect(e.target.value)}>
                                        {this.props.show_info.map((item, index) => {

                                            var firstItem = '';
                                            var lastItem = '';

                                            if (item.show_times[0]) {
                                                var ft = item.show_times[0].time;

                                                var FI = +ft.substr(0, 2);
                                                var f = (FI % 12) || 12;
                                                f = (f < 10) ? ("0" + f) : f;  // leading 0 at the left for 1 digit hours
                                                var ampmF = FI < 12 ? " AM" : " PM";
                                                firstItem = f + ft.substr(2, 3) + ampmF;

                                                var lt = item.show_times[item.show_times.length - 1].time;
                                                var LI = +lt.substr(0, 2);
                                                var l = (LI % 12) || 12;
                                                l = (l < 10) ? ("0" + l) : l;  // leading 0 at the left for 1 digit hours
                                                var ampmL = LI < 12 ? " AM" : " PM";
                                                lastItem = l + lt.substr(2, 3) + ampmL;
                                            }

                                            if (firstItem === "") {
                                                firstItem = "TBA"
                                            }

                                            var date = moment(item.date).format('M/DD/YYYY');
                                            return (
                                                <option value={item._id}>{date} ({firstItem})</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <table style={{ marginTop: 10, width: '100%', marginBottom: 20 }}>
                                        <thead style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                                            <tr>
                                                <th style={{ color: '#fff', width: '21%', padding: 5, border: '1px solid #ddd' }}> </th>
                                                <th style={{ color: '#fff', width: '14.5%', fontSize: 14, padding: 5, border: '1px solid #ddd' }}>Date</th>
                                                <th style={{ color: '#fff', width: '64.5%', fontSize: 14, padding: 5, border: '1px solid #ddd' }}>Ticket Link</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ color: '#fff', width: '21%', backgroundColor: 'rgb(1, 22, 48)', fontSize: 14, padding: 5, border: '1px solid #ddd' }}>Announce</td>
                                                {this.props.edit ?
                                                    <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><div style={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
                                                        <DatePicker
                                                            className="react-datepicker"
                                                            //showTimeSelect
                                                            selected={showLinks && showLinks.announce.date ? new Date(showLinks.announce.date) : ""}
                                                            onChange={this.props.handleAnnounceDateChange.bind(this, show_index, 'announce')}
                                                        //dateFormat="M/dd/yyyy h:mm aa z"
                                                        />
                                                    </div></td> :
                                                    showLinks && showLinks.announce.date ?
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12, paddingLeft: 5 }}>{moment(showLinks.announce.date).format('MM/DD/YYYY')}</td> :
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12 }}> </td>}
                                                {this.props.edit ?
                                                    <td style={{ color: '#000', width: '64.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><input style={{ width: '100%', height: '100%', fontSize: 12 }} type="text" name="url" value={showLinks ? showLinks.announce.url : ""} onChange={(event) => this.props.handleAnnounceUrlChange(event, show_index, 'announce')} /></td> :
                                                    showLinks ?
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12 }}>{showLinks.announce.url}</td> :
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12 }}> </td>}
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={{ color: '#fff', width: '21%', backgroundColor: 'rgb(1, 22, 48)', fontSize: 14, padding: 5, border: '1px solid #ddd' }}>Presale</td>
                                                {this.props.edit ?
                                                    <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><div style={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
                                                        <DatePicker
                                                            className="react-datepicker"
                                                            //showTimeSelect
                                                            selected={showLinks && showLinks.presale.date ? new Date(showLinks.presale.date) : ""}
                                                            onChange={this.props.handleAnnounceDateChange.bind(this, show_index, 'presale')}
                                                        //dateFormat="M/dd/yyyy h:mm aa z"
                                                        />
                                                    </div></td> :
                                                    showLinks && showLinks.presale.date ?
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12, paddingLeft: 5 }}>{moment(showLinks.presale.date).format('MM/DD/YYYY')}</td> :
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12 }}> </td>}
                                                {this.props.edit ?
                                                    <td style={{ color: '#000', width: '64.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><input style={{ width: '100%', height: '100%', fontSize: 12 }} type="text" name="url" value={showLinks ? showLinks.presale.url : ""} onChange={(event) => this.props.handleAnnounceUrlChange(event, show_index, 'presale')} /></td> :
                                                    showLinks ?
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12 }}>{showLinks.presale.url}</td> :
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12 }}> </td>}
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={{ color: '#fff', width: '21%', backgroundColor: 'rgb(1, 22, 48)', fontSize: 14, padding: 5, border: '1px solid #ddd' }}>On Sale</td>
                                                {this.props.edit ?
                                                    <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><div style={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
                                                        <DatePicker
                                                            className="react-datepicker"
                                                            //showTimeSelect
                                                            selected={showLinks && showLinks.on_sale.date ? new Date(showLinks.on_sale.date) : ""}
                                                            onChange={this.props.handleAnnounceDateChange.bind(this, show_index, 'on_sale')}
                                                        //dateFormat="M/dd/yyyy h:mm aa z"
                                                        />
                                                    </div></td> :
                                                    showLinks && showLinks.on_sale.date ?
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12, paddingLeft: 5 }}>{moment(showLinks.on_sale.date).format('MM/DD/YYYY')}</td> :
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12 }}> </td>}
                                                {this.props.edit ?
                                                    <td style={{ color: '#000', width: '64.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><input style={{ width: '100%', height: '100%', fontSize: 12 }} type="text" name="url" value={showLinks ? showLinks.on_sale.url : ""} onChange={(event) => this.props.handleAnnounceUrlChange(event, show_index, 'on_sale')} /></td> :
                                                    showLinks ?
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12 }}>{showLinks.on_sale.url}</td> :
                                                        <td style={{ color: '#000', width: '14.5%', backgroundColor: '#fff', border: '1px solid #ddd', height: 28, fontSize: 12 }}> </td>}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '90%', height: 'auto', marginTop: 20, marginLeft: 50 }} className="form-control">
                            <div style={{ height: 'auto', width: '100%' }}>
                                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                                    <label style={{ fontSize: 14, color: '#000' }}>Ticket Counts:</label>
                                    <span style={{ marginTop: -7 }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            disabled={!this.props.edit}
                                            color="default"
                                            checked={this.props.count_config.active}
                                            onChange={() => this.props.handleTcCheckbox("active")}
                                            name="active"
                                        />
                                    </span>
                                    {this.props.count_config.active ? null :
                                        <span style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label style={{ fontSize: 14, color: '#000', marginLeft: 25 }}>Custom Note:</label>
                                            <input style={{ width: 200, height: 26, color: '#000', fontSize: 12, marginLeft: 5 }} type="text" placeholder="No counts collected" disabled={!this.props.edit} name="notes" value={this.props.count_config.notes} onChange={this.props.handleTcConChange} />
                                        </span>}
                                </div>
                            </div>
                            {/* <div style={{ backgroundColor: 'rgb(1, 22, 48)', padding: 10 }}>
                        <h1 style={{ marginTop: 20, marginLeft: 50, color: '#fff', fontSize: 14 }}>Ticket Count E-mails:</h1>
                    </div> */}

                            {this.props.count_config.active ?
                                <div style={{ height: 'auto', width: '100%', padding: 10, borderTop: '1px solid rgb(1, 22, 48)' }}>
                                    {/* <h1 style={{ marginTop: 20, color: '#fff', fontSize: 14 }}>Ticket Count E-mails:</h1> */}
                                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ fontSize: 14, color: '#000' }}>Ticket Count Contact:</label>
                                        <CountContactsAutocomplete onContactChange={this.props.handleCountConChange} contactSelect={this.props.count_config.contacts} edit={this.props.edit} contactList={this.state.contact_roll} handleEmailBlur={this.props.handleEmailBlur} />
                                        {/* {this.props.edit ?
                                    <select style={{ width: 250, fontSize: 12, border: '.4px solid #000', height: 28, padding: 12, marginLeft: 10, color: '#fff' }} className="form-control-sm" disabled={this.props.buyercompany ? false : true} value={this.props.count_contact} name="count_contact" onChange={this.props.onChange}>
                                        <option value=""> </option>
                                        {this.props.contacts.contacts.map(item => {
                                            var render = [];
                                            item.tags.map((t, i) => {
                                                if (t.tag === this.props.buyercompanyid) {
                                                    render.push(
                                                        <option key={i._id} value={item._id}>{item.name.first} {item.name.last} ({t.role})</option>
                                                    )
                                                }
                                            })
                                            return (
                                                render
                                            )
                                        })}
                                    </select> :
                                    <input style={{ width: 250, fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12, marginLeft: 10 }} disabled={true} type="text" />} */}
                                        <span style={{ marginLeft: 20 }}></span>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    disabled={!this.props.edit}
                                                    color="default"
                                                    checked={this.props.count_config.email}
                                                    onChange={() => this.props.handleTcCheckbox("email")}
                                                    name="email"
                                                />
                                                <label style={{ fontSize: 9, color: '#000', marginTop: 5 }}>Add to email list</label>
                                            </div>
                                            {this.props.count_config.email ?
                                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                                                    <label style={{ fontSize: 12, color: '#000', marginTop: 5 }}>Assign Email Group:</label>
                                                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <select style={{ width: 200, height: 26, fontSize: 12 }} disabled={!this.props.edit} name="group" value="" onChange={this.handleTcConChange}>
                                                            <option value=""></option>
                                                            {this.props.auth.user.perm.admin ?
                                                                <option value="Add New Group">[Edit Email Groups]</option> : null}
                                                            {this.props.auth.user.company.email ? this.props.auth.user.company.email.groups.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item._id}>{item.name}</option>
                                                                )
                                                            }) : null}
                                                        </select>
                                                        {/* <FaMinusSquare style={{ marginLeft: 5, cursor: 'pointer' }} size={20} color="rgb(1, 22, 48)" />
                                                {this.props.count_config.group.length > 0 ? null :
                                                    <FaPlusSquare style={{ marginLeft: 5, cursor: 'pointer' }} size={20} color="rgb(1, 22, 48)" /> } */}
                                                    </span>
                                                    {this.state.groups.map((item, index) => {
                                                        return (
                                                            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <input style={{ width: 200, height: 26, fontSize: 12 }} type="text" disabled={true} value={item.name} />
                                                                <FaMinusSquare style={{ marginLeft: 5, cursor: 'pointer' }} size={20} color="rgb(1, 22, 48)" onClick={() => this.props.removeEmailGroupOffer(item._id)} />
                                                                {/* <FaPlusSquare style={{ marginLeft: 5, cursor: 'pointer' }} size={20} color="rgb(1, 22, 48)" /> */}
                                                            </span>
                                                        )
                                                    })}
                                                </div> : null}
                                        </div>
                                    </div>
                                </div> : null}
                        </div>
                        {/* <div style={{ width: '100%', height: 'auto' }}>
                    <div>
                        <h1 style={{ marginTop: 20, marginLeft: 50, color: '#000', fontSize: 14 }}>Ticket Count E-mails:</h1>
                    </div>
                    <div style={{ marginLeft: 50, height: 'auto', width: '90%' }} className="form-control">
                        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                            <label style={{ fontSize: 14, color: '#000' }}>Ticket Count Contact:</label>
                            {this.props.edit ?
                                <select style={{ width: 250, fontSize: 12, border: '.4px solid #000', height: 28, padding: 12, marginLeft: 10, color: '#000' }} className="form-control-sm" disabled={this.props.buyercompany ? false : true} value={this.props.count_contact} name="count_contact" onChange={this.props.onChange}>
                                    <option value=""> </option>
                                    {this.props.contacts.contacts.map(item => {
                                        var render = [];
                                        item.tags.map((t, i) => {
                                            if (t.tag === this.props.buyercompanyid) {
                                                render.push(
                                                    <option key={i._id} value={item._id}>{item.name.first} {item.name.last} ({t.role})</option>
                                                )
                                            }
                                        })
                                        return (
                                            render
                                        )
                                    })}
                                </select> :
                                <input style={{ width: 250, fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12, marginLeft: 10 }} disabled={true} type="text" />}
                            <button style={{ width: 'auto', backgroundColor: '#cdcdcd', color: '#000', fontSize: 12, borderRadius: 5, marginLeft: 20 }} onClick={this.checkMWF}>Check M/W/F</button>
                            <button style={{ width: 'auto', backgroundColor: '#cdcdcd', color: '#000', fontSize: 12, borderRadius: 5, marginLeft: 10 }} onClick={this.checkTT}>Check T/Th</button>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <table style={{ marginTop: 10, width: '100%', marginBottom: 20 }}>
                                <thead style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                                    <tr>
                                        <th style={{ color: '#fff', width: '20%', fontSize: 14, border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>Monday</div></th>
                                        <th style={{ color: '#fff', width: '20%', fontSize: 14, border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>Tuesday</div></th>
                                        <th style={{ color: '#fff', width: '20%', fontSize: 14, border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>Wednesday</div></th>
                                        <th style={{ color: '#fff', width: '20%', fontSize: 14, border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>Thursday</div></th>
                                        <th style={{ color: '#fff', width: '20%', fontSize: 14, border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>Friday</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ color: '#fff', width: '20%', backgroundColor: '#fff', border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                color="default"
                                                checked={this.state.monday}
                                                onChange={() => this.handleCheckbox("monday")}
                                                name="monday"
                                            /></div></td>
                                        <td style={{ color: '#000', width: '20%', backgroundColor: '#fff', border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                color="default"
                                                checked={this.state.tuesday}
                                                onChange={() => this.handleCheckbox("tuesday")}
                                                name="tuesday"
                                            /></div></td>
                                        <td style={{ color: '#000', width: '20%', backgroundColor: '#fff', border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                color="default"
                                                checked={this.state.wednesday}
                                                onChange={() => this.handleCheckbox("wednesday")}
                                                name="wednesday"
                                            /></div></td>
                                        <td style={{ color: '#000', width: '20%', backgroundColor: '#fff', border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                color="default"
                                                checked={this.state.thursday}
                                                onChange={() => this.handleCheckbox("thursday")}
                                                name="thursday"
                                            /></div></td>
                                        <td style={{ color: '#000', width: '20%', backgroundColor: '#fff', border: '1px solid #ddd' }}><div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                color="default"
                                                checked={this.state.friday}
                                                onChange={() => this.handleCheckbox("friday")}
                                                name="friday"
                                            /></div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}
                    </div>}
            </div>
        );
    }
}

function getUnique(arr, index) {
    const unique = arr
        .map(e => e[index])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);
    return unique;
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    offers: state.offers,
    auth: state.auth
});

export default connect(mapStateToProps, { saveEmailGroup, removeEmailGroup })(OfferConfig);