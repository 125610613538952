import {
    URL_DATA_LOADING,
    GET_URL_DATA,
    OFF_URL_DATA,
} from "../actions/types";

const initialState = {
    urldata: [{ id: null, clicks: null }],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case URL_DATA_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_URL_DATA:
            return {
                ...state,
                urldata: action.payload,
                loading: false
            };
        case OFF_URL_DATA:
            return {
                ...state,
                urldata: [{ id: '', clicks: '' }],
                loading: false
            };
        default:
            return state;
    }
}