import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default function StatusDialog(props) {

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: '#000', color: '#cdcdcd' }} id="alert-dialog-title">{props.statustitle}</DialogTitle>
            <DialogContent style={{ backgroundColor: '#cdcdcd' }}>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="status" name="statusrender" value={props.statusrender} onChange={props.handleChange}>
                            <FormControlLabel value="Active" control={<Radio color="primary" />} label="Active" />
                            <FormControlLabel value="Inactive" control={<Radio color="primary" />} label="Inactive" />
                        </RadioGroup>
                    </FormControl>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#000' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: '#000' }} onClick={props.handleClose}>Cancel</Button>
                <Button style={{ backgroundColor: '#cdcdcd', color: '#000' }} onClick={props.saveStatus} autoFocus>Save</Button>
            </DialogActions>
        </Dialog>
    );
}