import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { FaPlusSquare, FaTimesCircle } from 'react-icons/fa';
import ClientAutocomplete from '../../components/autocompletes/client-autocomplete';

class Submissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submissions_edit: {},

        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        contacts: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        packaging: PropTypes.object.isRequired,
    }

    componentDidMount() {

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    updateSubmission = (index, name) => {
        var submissions_edit = {
            [index]: name
        }
        this.setState({
            submissions_edit: submissions_edit
        })

    };

    handleClickOutside = (e, target) => {
        console.log("target", e, target)
        if (e.relatedTarget === null) {
            this.setState({
                [target]: {}
            })
        }
    };

    handleYearChange(event) {
        var year = event;
        this.setState({
            select_year: new Date(year),
        })
    }

    render() {

        console.log(this.props.submissions)

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'auto', alignItems: 'center' }}>
                <table style={{ width: '90%', marginTop: 30 }} id="#customers">
                    <thead>
                        <tr>
                            <th style={{ width: '100%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}><span style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><span style={{ width: 'auto' }}>SUBMISSIONS</span><span style={{ paddingLeft: 10 }}>
                                <DatePicker
                                    className="datePickerCustom"
                                    selected={this.props.select_year}
                                    onChange={date => this.props.handleYearChange(date)}
                                    showYearPicker
                                    dateFormat="yyyy"
                                    yearItemNumber={9}
                                />
                            </span> </span></th>
                        </tr>
                    </thead>
                </table>
                <table style={{ width: '90%' }} id="#customers">
                    <thead>
                        <tr>
                            <th style={{ width: '39.7%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Client(s)</th>
                            <th style={{ width: '15.3%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Date Submitted</th>
                            <th style={{ width: '40%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Notes</th>
                            <th style={{ width: '5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}> </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th style={{ width: '39.7%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff', border: '1px solid #ddd' }}><ClientAutocomplete clientList={this.props.clients.clients} onChange={this.props.onClientChange} clientSelect={this.props.artistbatch} toggleNewModal={this.props.toggleNewModal} sub="packaging" /></th>
                            <th style={{ width: '15.3%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff', border: '1px solid #ddd', height: 30 }}>
                                <DatePicker
                                    className="react-datepicker"
                                    style={{ fontSize: 12 }}
                                    selected={this.props.date_submitted}
                                    onChange={this.props.onDateSubmitted}
                                /></th>
                            <th style={{ width: '40%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff', border: '1px solid #ddd', height: 30 }}>
                                <input style={{ width: '100%', height: '100%', fontSize: 12, color: '#000' }} type="text" name="submission_note" value={this.props.submission_note} onChange={this.props.onChange} /></th>
                            <th style={{ width: '5%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff', border: '1px solid #ddd' }}>
                                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={this.props.addSubmission}>
                                    <FaPlusSquare size="20" color="#cdcdcd" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div style={{ marginTop: 15, minHeight: 50, maxHeight: 'auto', width: '90%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd' }} tabIndex={this.props.edit ? "0" : null} onBlur={this.props.edit ? (e) => this.handleClickOutside(e, 'submissions_edit') : null}>
                    {this.props.submissions.map((item, index) => {
                        var render = [];
                        if (Number(item.year) === new Date(this.props.select_year).getFullYear() && this.props.submissions.length > 0) {
                            item.subs.map(su => {
                                var date = moment(su.date_submitted).format('MM/DD/YYYY');
                                var client = "";
                                this.props.clients.clients.map((c, i) => {
                                    if (su.client === c._id) {
                                        client = c.client;
                                    };
                                });
                                render.push(
                                    <div style={{ border: '1px dashed gray', paddingLeft: '0.5rem', backgroundColor: '#f1f1f1', width: '100%' }}>
                                        <table style={{ width: '100%', height: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: '39.7%', color: '#000', fontSize: 12, padding: 5, height: 22 }}>{client}</td>
                                                    {this.props.edit && this.state.submissions_edit[su._id] === 'date' ?
                                                        <td style={{ width: '15.3%', color: '#000', fontSize: 12, padding: 5 }}>
                                                            <DatePicker
                                                                className="react-datepicker"
                                                                selected={su.date_submitted.toString() !== 'Invalid date' ? new Date(su.date_submitted) : ''}
                                                                onChange={this.props.updateSubsDateArray.bind(this, index)}
                                                            />
                                                        </td> :
                                                        <td style={{ width: '15.3%', color: '#000', fontSize: 12, padding: 5 }} onClick={this.props.edit ? () => this.updateSubmission(su._id, 'date') : null}>{date}</td>}
                                                    {this.props.edit && this.state.submissions_edit[item._id] === 'note' ?
                                                        <td style={{ width: '39%', color: '#000', fontSize: 12, padding: 5 }}><input style={{ fontSize: 12, width: '100%', height: '100%' }} type="text" value={su.note} onChange={event => this.props.updateSubsArray(index, 'note', event)} /></td> :
                                                        <td style={{ width: '39%', color: '#000', fontSize: 12, padding: 5 }} onClick={this.props.edit ? () => this.updateSubmission(su._id, 'note') : null}>{su.note}</td>}
                                                    <td style={{ width: '6%', color: '#000' }}>
                                                        {/* <FaEdit style={{ cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.props.updateSubmission(index, client)} /> */}
                                                        {this.props.edit ?
                                                            <FaTimesCircle style={{ marginLeft: 15, cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.props.deleteSubmission(index, su.client)} /> : null}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })

                        }

                        return (
                            render
                        )
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    items: state.items,
    contacts: state.contacts,
    clients: state.clients,
    packaging: state.packaging,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {})(Submissions);