import React, { Component, Suspense, lazy } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { addCountsBulk, applyCountsFilter, countsSort, sendCountEmails } from '../actions/countActions';
import { applyOfferFilter, offerCombine, offerSort, getOfferDetail, deleteOffer, addFinalsBulk, dbContractIssued } from '../actions/offerActions';
import { applyVenueFilter, venueSort } from '../actions/venueActions';
import { applyBuyerFilter, buyerSort } from '../actions/buyerActions';
import { applyContactFilter, contactSort } from '../actions/contactActions';
import { applyClientFilter, clientSort } from '../actions/clientActions';
import { applyFestivalFilter, festivalSort } from '../actions/festivalActions';
import { applyPackagingFilter, packagingSort } from '../actions/packagingActions';
import { applyArFilter } from '../actions/arActions';
import { applyFinalsFilter, finalsSort } from '../actions/finalsActions';
import { issuePdf } from '../actions/pdfActions';
import { connectGmail } from '../actions/authActions';
import { FaFilter, FaMailBulk, FaPlus, FaSave } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import ContactButtonGroup from '../components/contacts/contact-db.button-group';
import GridsButtonGroup from '../components/contacts/grids.button-group';
import DatabaseFilters from '../components/filters/database-filters';
//import VenueRolodex from '../database/venue-rolodex';
//import BuyerRolodex from '../database/buyer-rolodex';
//import ContactRolodex from '../database/contact-rolodex';
import ClientRolodex from '../database/client-rolodex';
import FestivalRolodex from '../database/festival-rolodex';
import CountsRolodex from '../database/ticket-counts-rolodex';
import PackagingRolodex from '../database/packaging-rolodex';
import ARRolodex from '../database/ar-rolodex';
import OfferRolodex from '../database/offer-rolodex';
import FinalsRolodex from '../database/finals-rolodex';
import EmailPdfDialog from '../components/global/email-pdf.dialog';
import EmailDialog from '../components/global/email-dialog';
import TcEmailDialog from '../components/dialogs/tc-email.dialogV2';
import ItineraryGenDialog from '../components/dialogs/itinerary-gen.dialog';
import ContractGenDialog from '../components/dialogs/contract-gen.dialog';
// import ContractIssuedLogDialog from '../components/dialogs/contract-issued-log.dialog';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
} from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";

const VenueRolodex = lazy(() => import('../database/venue-rolodex'));
const BuyerRolodex = lazy(() => import('../database/buyer-rolodex'));
const ContactRolodex = lazy(() => import('../database/contact-rolodex'));

class DatabaseScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            offer_sort: { sort_direction: 'asc', sort_cat: '' },
            venue_sort: { sort_direction: 'asc', sort_cat: 'venue_name' },
            buyer_sort: { sort_direction: 'asc', sort_cat: 'company' },
            contact_sort: { sort_direction: 'asc', sort_cat: 'name.last' },
            client_sort: { sort_direction: 'asc', sort_cat: 'client' },
            festival_sort: { sort_direction: 'asc', sort_cat: '' },
            packaging_sort: { sort_direction: 'asc', sort_cat: '' },
            ar_sort: { sort_direction: 'asc', sort_cat: '' },
            counts_sort: { sort_direction: 'asc', sort_cat: '' },
            finals_sort: { sort_direction: 'asc', sort_cat: '' },
            sortdirection: 'asc',
            sortcat: '',
            offer_filter: {
                deal_id: '',
                status: 'Confirmed',
                client: '',
                agent: '',
                start_date: '',
                end_date: '',
                venue: '',
                city: '',
                state: '',
                country: ''
            },
            venue_filter: {
                venue_name: '',
                status: 'Active',
                _id: '',
                city: '',
                state: '',
                country: '',
                min_capacity: '',
                max_capacity: '',
            },
            buyer_filter: {
                company: '',
                status: 'Active',
                _id: '',
                address: '',
                city: '',
                state: '',
                country: '',
                postal_code: '',
                buyer_name: '',
            },
            contact_filter: {
                last_name: '',
                first_name: '',
                status: 'Active',
                _id: '',
                role: '',
                city: '',
                state: '',
                country: '',
                postal_code: ''
            },
            client_filter: {
                client: '',
                status: 'Active',
                _id: '',
                city: '',
                state: '',
                country: '',
                agent: '',
            },
            festival_filter: {
                festival: '',
                status: 'Active',
                _id: '',
                start_date: '',
                end_date: '',
                city: '',
                state: '',
                country: '',
                min_capacity: '',
                max_capacity: '',
            },
            counts_filter: {
                client: '',
                agent: '',
                start_date: '',
                end_date: '',
                venue: '',
                city: '',
                state: '',
                country: '',
                capacity: null
            },
            packaging_filter: {
                // date_added: '',
                // artist: '',
                // status: '',
                // start_date: '',
                // end_date: '',
                // city: '',
                // state: '',
                // country: '',
                // genre: ''
                deal_id: '',
                status: '',
                artist: '',
                agent: '',
                start_date: '',
                end_date: '',
                venue: '',
                city: '',
                state: '',
                country: ''
            },
            ar_filter: {
                date_added: '',
                status: '',
                artist: '',
                contact: '',
                genre: '',
                city: '',
                state: '',
                country: ''
            },
            finals_filter: {
                deal_id: '',
                status: 'Confirmed',
                client: '',
                agent: '',
                start_date: new Date(moment().subtract(1, 'year')),
                end_date: new Date(),
                venue: '',
                city: '',
                state: '',
                country: '',
                unsettled: false
            },
            button_group: 'database',
            todays_counts: [],
            todays_notes: [],
            db_sub: 'offers',
            email_dialog: false,
            email_data: [],
            finals_sold_array: {},
            finals_gross_array: {},
            finals_net_array: {},
            finals_overage_array: {},
            finals_notes_array: {},
            email_tc_dialog: false,
            email_tc_data: [],
            itinerary_dialog: false,
            itinerary_array: [],
            itinerary_dialog_dir: null,

            contract_dialog: false,
            contract_array: [],
            contract_dialog_dir: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.offerFilterChange = this.offerFilterChange.bind(this);
        this.offerFilterDateChange = this.offerFilterDateChange.bind(this);
        this.venueFilterChange = this.venueFilterChange.bind(this);
        this.buyerFilterChange = this.buyerFilterChange.bind(this);
        this.contactFilterChange = this.contactFilterChange.bind(this);
        this.clientFilterChange = this.clientFilterChange.bind(this);
        this.festivalFilterChange = this.festivalFilterChange.bind(this);
        this.festivalFilterDateChange = this.festivalFilterDateChange.bind(this);
        this.handleCountChange = this.handleCountChange.bind(this);
        this.handleNotesChange = this.handleNotesChange.bind(this);
        this.handleFinalsChange = this.handleFinalsChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        offers: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        festivals: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        const edate = moment().add(1, 'years').format('MM/DD/YYYY');
        const sdate = moment().format('MM/DD/YYYY');
        //const t = d.setTime(d.getTime() - 3*28*24*60*60);
        //const enddate = `${t.getMonth() + 1}/${t.getDate()}/${t.getFullYear()}`
        this.startFilter(sdate, edate, 'offer_filter');
        this.startFilter(sdate, edate, 'festival_filter');
        this.startFilter(sdate, edate, 'counts_filter');
        this.startFilter(sdate, edate, 'packaging_filter');
        //await this.props.offerCombine();
    }

    filterChange = (event, f, ckbx) => {
        var name = event.target.name;
        var val = event.target.value;
        if (ckbx === 'checkbox') {
            let filter = Object.assign({}, this.state[f]);
            filter[name] = !this.state[f][name];
            this.setState({
                [f]: filter
            });
        } else {
            let filter = Object.assign({}, this.state[f]);
            filter[name] = val;
            this.setState({
                [f]: filter
            });
        }
    }

    filterDateChange = (n, v, f) => {
        var name = v;
        var val = n;
        let filter = Object.assign({}, this.state[f]);
        filter[name] = new Date(val);
        this.setState({
            [f]: filter
        });
    }

    filterCapChange = (values, nme, f) => {
        const { formattedcap, value } = values;
        if (value) {
            const name = nme;
            let filter = Object.assign({}, this.state[f]);
            filter[name] = value
            this.setState({
                [f]: filter
            })
        }

    }

    startFilter = (start, end, filter) => {
        this.setState(prevState => {
            let obj = Object.assign({}, prevState[filter]);
            obj.start_date = new Date(start);
            obj.end_date = new Date(end);
            return { [filter]: obj };
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    offerFilterChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let offer_filter = Object.assign({}, prevState.offer_filter);
            offer_filter[name] = val;
            return { offer_filter };
        })
    }

    offerFilterDateChange(n, v) {
        var name = n;
        var val = v;
        this.setState(prevState => {
            let offer_filter = Object.assign({}, prevState.offer_filter);
            offer_filter[name] = val;
            return { offer_filter };
        })
    }

    applyOfferFilter = (filter) => {
        if (this.state.db_sub === 'offers') {
            var fil = this.state.offer_filter;
            this.props.applyOfferFilter(fil);
        }
        // this.setState({
        //     offer_filter: filter
        // })
    }

    applyFilter = (filter) => {
        if (this.state.db_sub === 'offers') {
            var fil = this.state.offer_filter;
            this.props.applyOfferFilter(fil);
        } else if (this.state.db_sub === 'venues') {
            var fil = this.state.venue_filter;
            this.props.applyVenueFilter(fil);
        } else if (this.state.db_sub === 'buyers') {
            var fil = this.state.buyer_filter;
            this.props.applyBuyerFilter(fil);
        } else if (this.state.db_sub === 'clients') {
            var fil = this.state.client_filter;
            this.props.applyClientFilter(fil);
        } else if (this.state.db_sub === 'contacts') {
            var fil = this.state.contact_filter;
            this.props.applyContactFilter(fil);
        } else if (this.state.db_sub === 'festivals') {
            var fil = this.state.festival_filter;
            this.props.applyFestivalFilter(fil);
        } else if (this.state.db_sub === 'packaging') {
            var fil = this.state.packaging_filter;
            this.props.applyPackagingFilter(fil);
        } else if (this.state.db_sub === 'ar') {
            var fil = this.state.packaging_filter;
            this.props.applyArFilter(fil);
        } else if (this.state.db_sub === 'counts') {
            var fil = this.state.counts_filter;
            this.props.applyCountsFilter(fil);
        } else if (this.state.db_sub === 'finals') {
            var fil = this.state.finals_filter;
            this.props.applyFinalsFilter(fil);
        }
        // this.setState({
        //     offer_filter: filter
        // })
    }

    resetFilter = () => {
        var sub = this.state.db_sub;
        if (sub === 'offers') {
            const edate = moment().add(1, 'years').format('MM/DD/YYYY');
            const sdate = moment().format('MM/DD/YYYY');
            this.setState({
                offer_filter: {
                    deal_id: '',
                    status: 'Confirmed',
                    client: '',
                    agent: '',
                    start_date: new Date(sdate),
                    end_date: new Date(edate),
                    venue: '',
                    city: '',
                    state: '',
                    country: ''
                }
            })
        } else if (sub === 'venues') {
            this.setState({
                venue_filter: {
                    venue_name: '',
                    city: '',
                    state: '',
                    country: '',
                    min_capacity: '',
                    max_capacity: '',
                }
            })
        } else if (sub === 'buyers') {
            this.setState({
                buyer_filter: {
                    company: '',
                    city: '',
                    state: '',
                    country: '',
                    postal_code: '',
                    buyer_name: '',
                }
            })
        } else if (sub === 'contacts') {
            this.setState({
                contact_filter: {
                    last_name: '',
                    first_name: '',
                    role: '',
                    city: '',
                    state: '',
                    country: '',
                    postal_code: ''
                }
            })
        } else if (sub === 'clients') {
            this.setState({
                client_filter: {
                    client: '',
                    city: '',
                    state: '',
                    country: '',
                    agent: '',
                }
            })
        } else if (sub === 'festivals') {
            this.setState({
                festival_filter: {
                    festival: '',
                    start_date: '',
                    end_date: '',
                    city: '',
                    state: '',
                    country: '',
                    min_capacity: '',
                    max_capacity: '',
                }
            })
        } else if (sub === 'packaging') {
            this.setState({
                packaging_filter: {
                    deal_id: '',
                    status: '',
                    artist: '',
                    agent: '',
                    start_date: '',
                    end_date: '',
                    venue: '',
                    city: '',
                    state: '',
                    country: ''
                },
            })
        } else if (sub === 'ar') {
            this.setState({
                ar_filter: {
                    date_added: '',
                    status: '',
                    artist: '',
                    contact: '',
                    genre: '',
                    city: '',
                    state: '',
                    country: ''
                },
            })
        } else if (sub === 'counts') {
            const edate = moment().add(1, 'years').format('MM/DD/YYYY');
            const sdate = moment().format('MM/DD/YYYY');
            this.setState({
                counts_filter: {
                    client: '',
                    agent: '',
                    start_date: new Date(sdate),
                    end_date: new Date(edate),
                    venue: '',
                    city: '',
                    state: '',
                    country: ''
                },
            })
        } else if (sub === 'finals') {
            // const edate = moment().add(1, 'years').format('MM/DD/YYYY');
            // const sdate = moment().format('MM/DD/YYYY');
            this.setState({
                finals_filter: {
                    deal_id: '',
                    status: 'Confirmed',
                    client: '',
                    agent: '',
                    start_date: new Date(moment().subtract(1, 'year')),
                    end_date: new Date(),
                    venue: '',
                    city: '',
                    state: '',
                    country: '',
                    unsettled: false
                }
            })
        }
    }

    venueFilterChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let venue_filter = Object.assign({}, prevState.venue_filter);
            venue_filter[name] = val;
            return { venue_filter };
        })
    }

    applyVenueFilter = (filter) => {
        this.props.applyVenueFilter(filter);
        this.setState({
            venue_filter: filter
        })
    }

    buyerFilterChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let buyer_filter = Object.assign({}, prevState.buyer_filter);
            buyer_filter[name] = val;
            return { buyer_filter };
        })
    }

    applyBuyerFilter = (filter) => {
        this.props.applyBuyerFilter(filter);
        this.setState({
            buyer_filter: filter
        })
    }

    contactFilterChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let contact_filter = Object.assign({}, prevState.contact_filter);
            contact_filter[name] = val;
            return { contact_filter };
        })
    }

    applyContactFilter = (filter) => {
        this.props.applyContactFilter(filter);
        this.setState({
            contact_filter: filter
        })
    }

    clientFilterChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let client_filter = Object.assign({}, prevState.client_filter);
            client_filter[name] = val;
            return { client_filter };
        })
    }

    applyClientFilter = (filter) => {
        this.props.applyClientFilter(filter);
        this.setState({
            client_filter: filter
        })
    }

    festivalFilterChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let festival_filter = Object.assign({}, prevState.festival_filter);
            festival_filter[name] = val;
            return { festival_filter };
        })
    }

    festivalFilterDateChange(n, v) {
        var name = n;
        var val = v;
        this.setState(prevState => {
            let festival_filter = Object.assign({}, prevState.festival_filter);
            festival_filter[name] = val;
            return { festival_filter };
        })
    }

    applyFestivalFilter = (filter) => {
        this.props.applyFestivalFilter(filter);
        this.setState({
            festival_filter: filter
        })
    }

    applyCountsFilter = (filter) => {
        this.props.applyCountsFilter(filter);
        this.setState({
            counts_filter: filter
        })
    }

    applyArFilter = (filter) => {
        this.props.applyArFilter(filter);
        this.setState({
            ar_filter: filter
        })
    }

    applyFinalsFilter = (filter) => {
        this.props.applyFinalsFilter(filter);
        this.setState({
            finals_filter: filter
        })
    }

    packagingFilterChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let packaging_filter = Object.assign({}, prevState.packaging_filter);
            packaging_filter[name] = val;
            return { packaging_filter };
        })
    }

    packagingFilterDateChange(n, v) {
        var name = n;
        var val = v;
        this.setState(prevState => {
            let packaging_filter = Object.assign({}, prevState.packaging_filter);
            packaging_filter[name] = val;
            return { packaging_filter };
        })
    }

    applyPackagingFilter = (filter) => {
        this.props.applyPackagingFilter(filter);
        this.setState({
            packaging_filter: filter
        })
    }

    toggleSortOffers = async (e) => {
        if (this.state.offer_sort.sort_direction === "asc") {
            await this.setState(prevState => {
                let offer_sort = Object.assign({}, prevState.offer_sort);
                offer_sort.sort_direction = "desc";
                return { offer_sort };
            })
        } else {
            await this.setState(prevState => {
                let offer_sort = Object.assign({}, prevState.offer_sort);
                offer_sort.sort_direction = "asc";
                return { offer_sort };
            })
        }
        const sort = {
            cat: e,
            dir: this.state.offer_sort.sort_direction
        }
        await this.props.offerSort(sort)
    }

    toggleSortVenues = async (e) => {
        if (this.state.venue_sort.sort_direction === "asc") {
            await this.setState(prevState => {
                let venue_sort = Object.assign({}, prevState.venue_sort);
                venue_sort.sort_direction = "desc";
                return { venue_sort };
            })
        } else {
            await this.setState(prevState => {
                let venue_sort = Object.assign({}, prevState.venue_sort);
                venue_sort.sort_direction = "asc";
                return { venue_sort };
            })
        }
        const sort = {
            cat: e,
            dir: this.state.venue_sort.sort_direction
        }
        this.props.venueSort(sort)
    }

    toggleSortBuyers = () => {
        if (this.state.buyer_sort.sort_direction === "asc") {
            this.setState(prevState => {
                let buyer_sort = Object.assign({}, prevState.buyer_sort);
                buyer_sort.sort_direction = "desc";
                return { buyer_sort };
            })
            const sort = {
                cat: this.state.buyer_sort.sort_cat,
                dir: "desc"
            }
            this.props.buyerSort(sort)
        } else {
            this.setState(prevState => {
                let buyer_sort = Object.assign({}, prevState.buyer_sort);
                buyer_sort.sort_direction = "asc";
                return { buyer_sort };
            })
            const sort = {
                cat: this.state.buyer_sort.sort_cat,
                dir: "asc"
            }
            this.props.buyerSort(sort)
        }
    }

    toggleSortContacts = () => {
        if (this.state.contact_sort.sort_direction === "asc") {
            this.setState(prevState => {
                let contact_sort = Object.assign({}, prevState.contact_sort);
                contact_sort.sort_direction = "desc";
                return { contact_sort };
            })
            const sort = {
                cat: this.state.contact_sort.sort_cat,
                dir: "desc"
            }
            this.props.contactSort(sort)
        } else {
            this.setState(prevState => {
                let contact_sort = Object.assign({}, prevState.contact_sort);
                contact_sort.sort_direction = "asc";
                return { contact_sort };
            })
            const sort = {
                cat: this.state.contact_sort.sort_cat,
                dir: "asc"
            }
            this.props.contactSort(sort)
        }
    }

    toggleSortClients = () => {
        if (this.state.client_sort.sort_direction === "asc") {
            this.setState(prevState => {
                let client_sort = Object.assign({}, prevState.client_sort);
                client_sort.sort_direction = "desc";
                return { client_sort };
            })
            const sort = {
                cat: this.state.client_sort.sort_cat,
                dir: "desc"
            }
            this.props.clientSort(sort)
        } else {
            this.setState(prevState => {
                let client_sort = Object.assign({}, prevState.client_sort);
                client_sort.sort_direction = "asc";
                return { client_sort };
            })
            const sort = {
                cat: this.state.client_sort.sort_cat,
                dir: "asc"
            }
            this.props.clientSort(sort)
        }
    }

    toggleSortFestivals = () => {
        if (this.state.festival_sort.sort_direction === "asc") {
            this.setState(prevState => {
                let festival_sort = Object.assign({}, prevState.festival_sort);
                festival_sort.sort_direction = "desc";
                return { festival_sort };
            });
            const sort = {
                cat: this.state.festival_sort.sort_cat,
                dir: "desc"
            };
            this.props.festivalSort(sort);
        } else {
            this.setState(prevState => {
                let festival_sort = Object.assign({}, prevState.festival_sort);
                festival_sort.sort_direction = "asc";
                return { festival_sort };
            })
            const sort = {
                cat: this.state.festival_sort.sort_cat,
                dir: "asc"
            };
            this.props.festivalSort(sort);
        }
    }

    toggleSortCounts = () => {
        if (this.state.counts_sort.sort_direction === "asc") {
            this.setState(prevState => {
                let counts_sort = Object.assign({}, prevState.counts_sort);
                counts_sort.sort_direction = "desc";
                return { counts_sort };
            })
            const sort = {
                cat: this.state.counts_sort.sort_cat,
                dir: "desc"
            }
            this.props.countsSort(sort)
        } else {
            this.setState(prevState => {
                let counts_sort = Object.assign({}, prevState.counts_sort);
                counts_sort.sort_direction = "asc";
                return { counts_sort };
            })
            const sort = {
                cat: this.state.counts_sort.sort_cat,
                dir: "asc"
            }
            this.props.countsSort(sort)
        }
    }

    toggleSortFinals = async (e) => {
        if (this.state.finals_sort.sort_direction === "asc") {
            await this.setState(prevState => {
                let finals_sort = Object.assign({}, prevState.finals_sort);
                finals_sort.sort_direction = "desc";
                return { finals_sort };
            })
        } else {
            await this.setState(prevState => {
                let finals_sort = Object.assign({}, prevState.finals_sort);
                finals_sort.sort_direction = "asc";
                return { finals_sort };
            })
        }
        const sort = {
            cat: e,
            dir: this.state.finals_sort.sort_direction
        }
        await this.props.finalsSort(sort)
    }

    clearSorted = async (e) => {
        var data = { sort_direction: 'asc', sort_cat: '' };
        var sort = {};
        if (e === "venue_sort") {
            data = {
                sort_direction: 'asc',
                sort_cat: 'venue_name'
            }
            sort = {
                cat: 'venue_name',
                dir: "asc"
            }
        } else {
            data = {
                sort_direction: 'asc',
                sort_cat: ''
            }
            sort = {
                cat: '',
                dir: "asc"
            }
        }

        if (e === 'offer_sort') {
            await this.props.offerSort(sort);
        }
        if (e === 'venue_sort') {
            await this.props.venueSort(sort)
        }
        if (e === 'festival_sort') {
            await this.props.festivalSort(sort)
        }
        if (e === 'counts_sort') {
            await this.props.countsSort(sort)
        }
        if (e === 'finals_sort') {
            await this.props.finalsSort(sort)
        }
        this.setState({
            [e]: data
        })
    }

    toggleSortCatOffers = async (e) => {
        await this.setState(prevState => {
            let offer_sort = Object.assign({}, prevState.offer_sort);
            offer_sort.sort_cat = e;
            offer_sort.sort_direction = 'asc';
            return { offer_sort };
        })
        const sort = {
            cat: e,
            dir: 'asc'
        }
        await this.props.offerSort(sort)
    }

    toggleSortCatVenues = async (e) => {
        await this.setState(prevState => {
            let venue_sort = Object.assign({}, prevState.venue_sort);
            venue_sort.sort_cat = e;
            venue_sort.sort_direction = 'asc';
            return { venue_sort };
        })
        const sort = {
            cat: e,
            dir: 'asc'
        }
        await this.props.venueSort(sort)
    }

    toggleSortCatBuyers = (e) => {
        this.setState(prevState => {
            let buyer_sort = Object.assign({}, prevState.buyer_sort);
            buyer_sort.sort_cat = e;
            buyer_sort.sort_direction = 'asc';
            return { buyer_sort };
        })
        const sort = {
            cat: e,
            dir: 'asc'
        }
        this.props.buyerSort(sort)
    }

    toggleSortCatContacts = (e) => {
        this.setState(prevState => {
            let contact_sort = Object.assign({}, prevState.contact_sort);
            contact_sort.sort_cat = e;
            contact_sort.sort_direction = 'asc';
            return { contact_sort };
        })
        const sort = {
            cat: e,
            dir: 'asc'
        }
        this.props.contactSort(sort)
    }

    toggleSortCatClients = (e) => {
        this.setState(prevState => {
            let client_sort = Object.assign({}, prevState.client_sort);
            client_sort.sort_cat = e;
            client_sort.sort_direction = 'asc';
            return { client_sort };
        })
        const sort = {
            cat: e,
            dir: 'asc'
        }
        this.props.clientSort(sort)
    }

    toggleSortCatFestivals = (e) => {
        this.setState(prevState => {
            let festival_sort = Object.assign({}, prevState.festival_sort);
            festival_sort.sort_cat = e;
            festival_sort.sort_direction = 'asc';
            return { festival_sort };
        })
        const sort = {
            cat: e,
            dir: 'asc'
        }
        this.props.festivalSort(sort)
    }

    toggleSortCatPackaging = (e) => {
        this.setState(prevState => {
            let packaging_sort = Object.assign({}, prevState.packaging_sort);
            packaging_sort.sort_cat = e;
            packaging_sort.sort_direction = 'asc';
            return { packaging_sort };
        })
        const sort = {
            cat: e,
            dir: 'asc'
        }
        this.props.packagingSort(sort)
    }

    toggleSortCatCounts = (e) => {
        this.setState(prevState => {
            let counts_sort = Object.assign({}, prevState.counts_sort);
            counts_sort.sort_cat = e;
            counts_sort.sort_direction = 'asc';
            return { counts_sort };
        })
        const sort = {
            cat: e,
            dir: 'asc'
        }
        this.props.countsSort(sort)
    }

    toggleSortCatFinals = async (e) => {
        await this.setState(prevState => {
            let finals_sort = Object.assign({}, prevState.finals_sort);
            finals_sort.sort_cat = e;
            finals_sort.sort_direction = 'asc';
            return { finals_sort };
        })
        const sort = {
            cat: e,
            dir: 'asc'
        }
        // await this.props.offerSort(sort)
    }

    toggleButtons = (e) => {
        this.setState({
            button_group: e
        })
    }

    handleCountChange(e, dateid, index, id) {
        if (e) {
            // const tc = {
            //     dateid: dateid,
            //     showid: id,
            //     count: Number(e)
            // }
            var set = `${dateid}, ${id}`
            this.setState({
                todays_counts: {
                    ...this.state.todays_counts,
                    [set]: e
                }
            })
        } else {
            const todaysCount = {
                ...this.state.todays_counts
            }
            delete todaysCount[dateid + ", " + id];
            this.setState({
                todays_counts: {
                    ...todaysCount
                }
            })
        }
    }

    handleNotesChange(e, dateid, index, id) {
        //let keys = this.state.todayscount.remove(id);
        //let todays_counts = this.state.todayscount;
        //var sIndex = todays_counts.findIndex(obj => obj._id === id)
        // if (e) {
        var set = `${dateid}, ${id}`
        this.setState({
            todays_notes: {
                ...this.state.todays_notes,
                [set]: e
            }
        })
        // } else {
        //     const todaysNotes = {
        //         ...this.state.todays_notes
        //     }
        //     delete todaysNotes[dateid + ", " + id];
        //     this.setState({
        //         todays_notes: {
        //             ...todaysNotes
        //         }
        //     })
        // }

    }

    clearTCInput = () => {
        this.setState({
            todays_counts: {},
            todays_notes: {}
        })
    }

    handleCountsSave = () => {

        var keys = Object.keys(this.state.todays_counts);
        var today = moment().format('M/D/YYYY');
        var values = [];
        var noteKeys = Object.keys(this.state.todays_notes);

        for (var i = 0; i < keys.length; i++) {
            var notes = null;
            for (var b = 0; b < noteKeys.length; b++) {
                if (keys[i] === noteKeys[b]) {
                    notes = this.state.todays_notes[noteKeys[b]]
                }
            }
            var newKeys = keys[i].split(', ')
            const showid = newKeys[0];
            const dateid = newKeys[1];
            if (notes === null) {
                var vIndex = this.props.counts.counts.findIndex(item => item._id === dateid);
                if (vIndex >= 0) {
                    var sh = this.props.counts.counts[vIndex];
                    var sIndex = sh.counts.findIndex(item => item._id === showid);
                    if (sIndex >= 0) {
                        var day = moment().format('YYYY-MM-DD').toString();
                        var sortDate = _.sortBy(sh.counts[sIndex].count_data.filter(o => o.date !== day), function (dateObj) {
                            return new Date(dateObj.date);
                        }).reverse();
                        notes = sortDate[0].notes;
                    } else {
                        notes = "";
                    }
                } else {
                    notes = "";
                }
            }
            const dateString = moment().format('YYYY-MM-DD').toString();
            const time = moment().format('HH:mm:ss')
            const tz = moment.tz.guess();

            values.push({
                _id: dateid,
                showid: showid,
                counts: {
                    date: dateString,
                    time: time,
                    tz: tz,
                    count: this.state.todays_counts[keys[i]],
                    notes: notes
                }
            });
        }

        const counts = {
            values
        };
        this.props.addCountsBulk(counts);
        this.clearTCInput();
    }

    countInputSocket = (counts) => {
        // var keys = Object.keys(this.state.todays_counts);
        // var noteKeys = Object.keys(this.state.todays_notes);
        var dealid = counts._id;
        const todaysCount = {
            ...this.state.todays_counts
        }
        const todaysNotes = {
            ...this.state.todays_counts
        }
        counts.counts.forEach(item => {
            console.log(item)
            var showid = item._id;
            var set = `${showid}, ${dealid}`
            if (todaysCount[set]) {
                delete todaysCount[set];
            }
            if (todaysNotes[set]) {
                delete todaysNotes[set];
            }
        })
        this.setState({
            todays_counts: {
                ...todaysCount
            },
            todays_notes: {
                ...todaysNotes
            }
        })
    }

    handleFinalsChange(e, dateid, index, id, state) {
        if (e) {
            var set = `${dateid}, ${id}`
            this.setState({
                [state]: {
                    ...this.state[state],
                    [set]: e
                }
            })
        } else {
            const finalsInput = {
                ...this.state[state]
            }
            delete finalsInput[dateid + ", " + id];
            this.setState({
                [state]: {
                    ...finalsInput
                }
            })
        }
    }

    handleFinalsSave = () => {

        const soldArray = this.state.finals_sold_array;
        const grossArray = this.state.finals_gross_array;
        const netArray = this.state.finals_net_array;
        const overageArray = this.state.finals_overage_array;
        const notesArray = this.state.finals_notes_array;

        var soldKeys = Object.keys(soldArray);
        var grossKeys = Object.keys(grossArray);
        var netKeys = Object.keys(netArray);
        var overageKeys = Object.keys(overageArray);
        var notesKeys = Object.keys(notesArray);

        var allKeys = [...soldKeys, ...grossKeys, ...netKeys, ...overageKeys, ...notesKeys];
        const setKeys = new Set(allKeys);
        const uniqueKeys = Array.from(setKeys);


        var finals_arr = [];
        uniqueKeys.forEach(item => {
            var newKeys = item.split(', ')
            const showid = newKeys[0];
            const dealid = newKeys[1];
            var f = { tickets_sold: null, gross_sales: null, net_sales: null, _id: showid, deal_id: dealid, overage: null, notes: null };
            if (soldArray[item]) {
                f.tickets_sold = soldArray[item];
            }
            if (grossArray[item]) {
                f.gross_sales = grossArray[item];
            }
            if (netArray[item]) {
                f.net_sales = netArray[item];
            }
            if (overageArray[item]) {
                f.overage = overageArray[item];
            }
            if (notesArray[item]) {
                f.notes = notesArray[item];
            }
            finals_arr.push(f);
        })

        const data = {
            finals: finals_arr,
        };

        this.props.addFinalsBulk(data);
        this.clearFinInput();
        // var today = moment().format('M/D/YYYY');
        // var values = [];
        // var noteKeys = Object.keys(this.state.todays_notes);

        // for (var i = 0; i < soldKeys.length; i++) {
        //     var notes = null;
        //     for (var b = 0; b < noteKeys.length; b++) {
        //         if (keys[i] === noteKeys[b]) {
        //             notes = this.state.todays_notes[noteKeys[b]]
        //         }
        //     }
        //     var newKeys = keys[i].split(', ')
        //     const showid = newKeys[0];
        //     const dateid = newKeys[1];


        //     const dateString = moment().format('YYYY-MM-DD').toString();
        //     const time = moment().format('HH:mm:ss')
        //     const tz = moment.tz.guess();

        //     values.push({
        //         _id: dateid,
        //         showid: showid,
        //         counts: {
        //             date: dateString,
        //             time: time,
        //             tz: tz,
        //             count: this.state.todays_counts[keys[i]],
        //             notes: notes
        //         }
        //     });
        // }

        // const counts = {
        //     values
        // };
        // this.props.addCountsBulk(counts);
        // this.clearTCInput();
    }

    clearFinInput = () => {
        this.setState({
            finals_sold_array: {},
            finals_gross_array: {},
            finals_net_array: {},
            finals_overage_array: {},
            finals_notes_array: {},
        })
    }

    finalsInputSocket = (offer) => {
        const todaysSold = {
            ...this.state.finals_sold_array
        }
        const todaysGross = {
            ...this.state.finals_gross_array
        }
        const todaysNet = {
            ...this.state.finals_net_array
        }
        const todaysOverage = {
            ...this.state.finals_overage_array
        }
        const todaysNotes = {
            ...this.state.finals_notes_array
        }
        offer.show_info.forEach(item => {
            var showid = item._id;
            var set = `${showid}, ${offer._id}`
            if (todaysSold[set]) {
                delete todaysSold[set];
            }
            if (todaysGross[set]) {
                delete todaysGross[set];
            }
            if (todaysNet[set]) {
                delete todaysNet[set];
            }
            if (todaysOverage[set]) {
                delete todaysOverage[set];
            }
            if (todaysNotes[set]) {
                delete todaysNotes[set];
            }
        })
        this.setState({
            finals_sold_array: {
                ...todaysSold
            },
            finals_gross_array: {
                ...todaysGross
            },
            finals_net_array: {
                ...todaysNet
            },
            finals_overage_array: {
                ...todaysOverage
            },
            finals_notes_array: {
                ...todaysNotes
            }
        })
    }

    clearFilters = (t) => {
        if (t === 'offer') {
            this.setState({
                offer_filter: {
                    deal_id: '',
                    status: '',
                    client: '',
                    agent: '',
                    start_date: '',
                    end_date: '',
                    venue: '',
                    city: '',
                    state: '',
                    country: ''
                }
            })
        } else if (t === 'venue') {
            this.setState({
                venue_filter: {
                    venue_name: '',
                    city: '',
                    state: '',
                    country: '',
                    min_capacity: '',
                    max_capacity: '',
                }
            })
        } else if (t === 'buyer') {
            this.setState({
                buyer_filter: {
                    company: '',
                    city: '',
                    state: '',
                    country: '',
                    postal_code: '',
                    buyer_name: '',
                }
            })
        } else if (t === 'contact') {
            this.setState({
                contact_filter: {
                    last_name: '',
                    first_name: '',
                    role: '',
                    city: '',
                    state: '',
                    country: '',
                    postal_code: ''
                }
            })
        } else if (t === 'client') {
            this.setState({
                client_filter: {
                    client: '',
                    city: '',
                    state: '',
                    country: '',
                    agent: '',
                }
            })
        } else if (t === 'festival') {
            this.setState({
                festival_filter: {
                    festival: '',
                    start_date: '',
                    end_date: '',
                    city: '',
                    state: '',
                    country: '',
                    min_capacity: '',
                    max_capacity: '',
                }
            })
        } else if (t === 'finals') {
            this.setState({
                offer_filter: {
                    deal_id: '',
                    status: 'Confirmed',
                    client: '',
                    agent: '',
                    start_date: new Date(moment().subtract(1, 'year')),
                    end_date: new Date(),
                    venue: '',
                    city: '',
                    state: '',
                    country: '',
                    unsettled: false
                }
            })
        }
    }

    // toggleEmailDialog = (item) => {
    //     this.props.toggleEmailDialog(item);
    // }

    toggleEmailDialog = async (i) => {
        if (this.state.email_dialog) {
            this.setState({
                email_dialog: false,
                email_data: []
            })
        } else {
            const offer_data = await this.props.getOfferDetail(i._id);
            if (offer_data) {
                var newArray = [];
                const contactid = i.contacts.buyer_contact;
                const signatoryid = i.contacts.signatory;
                const addcontacts = [];
                const productionid = "";

                var buyer_contact = await this.props.contacts.contacts.filter(item => item._id === contactid);
                var signatory = await this.props.contacts.contacts.filter(item => item._id === signatoryid);
                var client = await this.props.clients.clients.filter(item => item._id === i.client_id);
                client = client[0];

                i.contacts.add_contacts.map(con => {
                    this.props.contacts.contacts.map(cli => {
                        if (con.contact_id === cli._id) {
                            cli.role = con.role
                            addcontacts.push(cli)
                        } else if (con.contact_id === 'Custom') {
                            var nc = { ...con, ...cli }
                            addcontacts.push({...con})
                        }
                    })
                })

                //FIX THIS!!!!!- need to check item.contacts...
                // var prod_contact = [];
                // if (productionid === 'Custom') {
                //     prod_contact = offer_data.production_contact;
                // } else if (productionid !== "") {
                //     var n = this.props.contacts.contacts.filter(item => item._id === productionid);
                //     prod_contact = n
                // }

                if (buyer_contact.length <= 0) {
                    buyer_contact = null
                } else {
                    buyer_contact[0].role = 'Buyer'
                    // buyer_contact[0].tags.map((t, ind) => {
                    //     if (t.tag === i.venue_id || t.tag === i.buyer_id) {
                    //         buyer_contact[0].role = t.role;
                    //     }
                    // })
                    buyer_contact = buyer_contact[0]
                }

                // if (prod_contact.length <= 0) {
                //     prod_contact = null
                // } else {
                //     prod_contact[0].tags.map((t, ind) => {
                //         if (t.tag === i.venue_id || t.tag === i.buyer_id) {
                //             prod_contact[0].role = t.role;
                //         }
                //     })
                //     prod_contact = prod_contact[0]
                // }

                if (signatory.length <= 0) {
                    signatory = null
                } else {
                    signatory[0].role = "Signatory"
                    // signatory[0].tags.map((t, ind) => {
                    //     if (t.tag === i.venue_id || t.tag === i.buyer_id) {
                    //         signatory[0].role = t.role;
                    //     }
                    // })
                    signatory = signatory[0]
                }

                var contact_data = [
                    buyer_contact,
                    // prod_contact,
                    signatory,
                    ...addcontacts
                ]
                contact_data = contact_data.filter(function (e) { return e != null; });
                contact_data = contact_data.filter((ele, ind) => ind === contact_data.findIndex(elem => elem._id === ele._id))

                let datesArray = i.show_info.map(d => moment(d.date).utc());
                var end = moment.max(datesArray).format('MM/DD/YYYY');
                var start = moment.min(datesArray).format('MM/DD/YYYY');

                var subject = "";
                if (start === end) {
                    subject = i.artist_name + " - " + start + " - " + i.venue_name + " (Contract)";
                } else {
                    subject = i.artist_name + " - " + start + "-" + end + " - " + i.venue_name + " (Contract)";
                }

                newArray.push({
                    ...offer_data, contact_data, subject, client
                })

                this.setState({
                    email_data: newArray,
                    email_dialog: true
                })
            }
        }
    }

    issuePdf = async (type, id, em) => {
        //var newPdf = await this.props.retreivePdf();
        var email = em;
        if (type === 'contract') {
            const offer_data = await this.props.getOfferDetail(id);
            var offer_base = {};

            var newArray = [];
            var deals = [];
            var contactid = "";
            var productionid = "";
            var signatoryid = "";

            var artist_name = [];
            var marketingid = "";
            var prod_contact = {};
            var marketing_contact = {};

            this.props.offers.offers.map(o => {
                if (id === o._id) {
                    offer_base = o;
                    if (offer_data.buyer_contact_id) {
                        contactid = offer_data.buyer_contact_id
                    } else if (o.contacts && o.contacts.buyer_contact) {
                        contactid = o.contacts.buyer_contact
                    }

                    if (offer_data.production_contact && offer_data.production_contact.contact_id) {
                        productionid = offer_data.production_contact.contact_id
                    } else if (o.contacts && o.contacts.production_contact) {
                        productionid = o.contacts.production_contact.contact_id
                    }

                    if (offer_data.signatory_id) {
                        signatoryid = offer_data.signatory_id
                    } else if (o.contacts && o.contacts.signatory) {
                        signatoryid = o.contacts.signatory
                    }
                }
            })

            var artistid = offer_base.client_id;
            var venueid = offer_base.venue_id;
            var buyerid = offer_base.buyer_id;

            var venue = [];
            this.props.items.items.map(v => {
                if (v._id === venueid) {
                    venue = {
                        venue: v.venue_name,
                        address: v.address.address,
                        address2: v.address.address2,
                        city: v.address.city,
                        state: v.address.state,
                        country: v.address.country,
                        postalcode: v.address.postal_code,
                        phone: v.contact.main_phone,
                        website: v.website
                    };

                };
            });
            this.props.festivals.items.map(v => {
                if (v._id === venueid) {
                    venue = {
                        venue: v.festival,
                        address: v.address.address || "",
                        address2: v.address.address2 || "",
                        city: v.address.city || "",
                        state: v.address.state || "",
                        country: v.address.country || "",
                        postalcode: v.address.postal_code || "",
                        website: v.website
                    };

                };
            });

            var buyer = [];
            this.props.buyers.buyers.map(b => {
                if (b._id === buyerid) {
                    buyer = {
                        company: b.company,
                        address: b.address,
                        contact: b.contact
                    };
                };
            });

            var buyer_contact = this.props.contacts.contacts.filter(item => item._id === contactid);
            var signatory = this.props.contacts.contacts.filter(item => item._id === signatoryid);

            var artist_name = "";
            var artistObj = {};
            this.props.clients.clients.map(c => {
                if (c._id === artistid) {
                    artistObj = c
                    artist_name = c.client;
                }
            });

            //FIX THIS!!!!!- need to check item.contacts...
            var prod_contact = {};
            if (productionid === 'Custom') {
                prod_contact = offer_data.production_contact;
            } else if (productionid !== "") {
                var n = this.props.contacts.contacts.filter(item => item._id === productionid);
                prod_contact = n[0]
            }

            //TEND - ADD WARNING FOR NO CONTACT EMAIL ADDRESS
            var ccContacts = [];
            if (email.cc.length > 0) {
                email.cc.forEach(item => {
                    var nc = this.props.contacts.contacts.filter(con => con._id === item);
                    if(nc.length > 0){
                        ccContacts = [...ccContacts, nc[0]];
                    } else {
                        ccContacts = [...ccContacts, item]
                    }
                    
                })
            }
            email.cc = ccContacts;
            var singleContacts = [];
            if (email.single.length > 0) {
                email.single.forEach(item => {
                    var nc = this.props.contacts.contacts.filter(con => con._id === item);
                    if (nc.length > 0) {
                        singleContacts = [...singleContacts, nc[0]];
                    } else {
                        singleContacts = [...singleContacts, item];
                    }
                })
            }
            email.single = singleContacts;
            var attachArray = email.attach;
            // if (email.attach.length > 0) {
            //     email.attach.forEach(item => {
            //         artistObj.attachments.map(art => {
            //             if (item._id === art._id) {
            //                 attachArray = [...attachArray, art]
            //             }
            //         })
            //     })
            // }
            email.attach = attachArray;

            newArray.push({
                ...offer_data, ...offer_base, venue, buyer, artist_name, buyer_contact, prod_contact, signatory, email
            })
            this.issuePdfDialog(type, newArray);
            // this.setState({
            //     email_dialog: false
            // })
        }
    }

    issuePdfDialogOld = (type, newArray) => {
        if (newArray.length > 0) {
            const addDialog = ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-z2">
                        <div style={{ width: '100%', height: '100%', padding: 15 }}>
                            <h1 style={{ fontSize: 22, fontWeight: 'bold', textTransform: 'capitalize' }}>Issue {type}</h1>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                Are you sure you'd like to issue the following {type}?
                                <p style={{ fontSize: 12, width: 350, overflow: 'hidden' }}>{newArray[0].artist_name + " - " + newArray[0].venue.venue + " - " + newArray[0].venue.city + ", " + newArray[0].venue.state}<br /><br />
                                    Message:<br />{newArray[0].email.body}<br /><br />
                                    {newArray[0].email.cc.length > 0 ?
                                        <>Recipients (cc):<br />{newArray[0].email.cc.map(item => {
                                            return (item.name.first + " " + item.name.last + " - " + item.contact.email)
                                        })}</> : null}
                                    {newArray[0].email.cc.length > 0 && newArray[0].email.single.length ? <><br /><br /></> : null}
                                    {newArray[0].email.single.length > 0 ?
                                        <>Recipients (single email):<br />{newArray[0].email.single.map(item => {
                                            if (item.name && item.name.first || item.name && item.name.last) {
                                                return (item.name.first + " " + item.name.last + " - " + item.contact.email)
                                            } else {
                                                return (item.contact.email)
                                            }
                                        })}</> : null}
                                </p>
                            </div>
                            <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                                <button style={{ borderRadius: 10 }} onClick={() => { this.issueFinish(); onClose(); }}>Send</button>
                                <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={onClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )
            }
            const options = {
                closeOnEscape: true,
                closeOnClickOutside: true,
                customUI: addDialog
            }
            confirmAlert(options)
        } else {
            alert("Error: please select a deal and recipient to issue pdf")
        }
    }

    issuePdfDialog = (type, newArray) => {
        if (newArray.length > 0) {
            const addDialog = ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-z2">
                        <div style={{ width: '100%', height: '100%', padding: 15 }}>
                            <h1 style={{ fontSize: 22, fontWeight: 'bold', textTransform: 'capitalize' }}>Issue {type}</h1>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                Are you sure you'd like to send?
                            </div>
                            <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                                <button style={{ borderRadius: 10 }} onClick={onClose}>Cancel</button>
                                <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { this.issueFinish(type, newArray); onClose(); }}>Send</button>
                            </div>
                        </div>
                    </div>
                )
            }
            const options = {
                closeOnEscape: true,
                closeOnClickOutside: true,
                customUI: addDialog,
                overlayClassName: 'custom-confirm-alert'
            }
            confirmAlert(options);
        } else {
            alert("Error: please select a deal and recipient to issue pdf")
        }
    }

    issueFinish = async (type, newArray) => {
        this.setState({
            email_dialog: false
        });
        if (type === 'dealmemo' || type === 'contract') {
            const pdfData = {
                pdfData: newArray[0],
                pdfType: type
            }
            this.props.toggleExtSnackbar('Sending Email');
            const log_status = await this.props.issuePdf(pdfData, type);
            // const logIssued = (d) => {
            //     this.logIssued(d)
            // }
            if (log_status.data === 'Email Sent') {
                this.props.toggleStatusSnackbar(log_status.data);
                if (type === 'contract') {
                    const contractissued = new Date().toString();
                    var issuedArray = {
                        date: contractissued,
                        note: '',
                        user: this.props.auth.user.tag
                    }
                    var issued = `${contractissued}, ${this.props.auth.user.tag}`
                    var data = {
                        issued: issued,
                        issuedArray: issuedArray
                    }
                    this.ContractIssuedLogDialog({ data: data, _id: pdfData.pdfData._id });
                }
                this.setState({
                    email_data: [],
                })
            } else if (log_status.data === 'access token expired') {
                this.gmailDialog();
            } else {
                this.props.toggleErrorSnackbar(log_status.data);
                this.setState({
                    email_dialog: true
                });
            }
        } else if (type === 'itinerary') {
            const pdfData = {
                pdfData: newArray,
                pdfType: type
            }
            this.props.toggleExtSnackbar('Sending Email');
            const log_status = await this.props.issuePdf(pdfData, type);
            if (log_status.data === 'Email Sent') {
                this.props.toggleStatusSnackbar(log_status.data);
                this.setState({
                    email_data: [],
                })
            } else if (log_status.data === 'access token expired') {
                this.gmailDialog();
            } else {
                this.props.toggleErrorSnackbar(log_status.data);
                this.setState({
                    email_dialog: true
                })
            }
        }
    }

    ContractIssuedLogDialog = ({ data, _id }) => {
        console.log(data, _id)
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold', textTransform: 'capitalize' }}>Contract Sent!</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Would you like to add this to the contract tracking log?
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.props.dbContractIssued(data, _id); onClose(); }}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={onClose}>No</button>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }
        confirmAlert(options)
    }

    logIssued = (pdfData) => {
        const contractissued = new Date().toString();
        var issuedArray = {
            date: contractissued,
            note: '',
            user: this.props.auth.user.tag
        }
        console.log(pdfData)
        // var issued = `${contractissued}, ${pdfData.pdfData._id}`
        var issued = `${contractissued}`
        var data = {
            issued: issued,
            issuedArray: issuedArray
        }
        console.log(data);

        // this.props.dbContractIssued(data, this.props.id);
        // //this.props.addContractIssued();
        // this.props.resetTracking('contract_issued');
    }

    gmailDialog = () => {
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Your access token is expired.  Would you like to reconnect your gmail account?
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={onClose}>Cancel</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { this.gmailConnect(); onClose(); }}>Yes</button>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog,
            overlayClassName: 'custom-confirm-alert'
        }
        confirmAlert(options);
    }

    gmailConnect = () => {
        this.props.connectGmail();
        this.setState({
            email_dialog: true
        });
    }

    dialogTcEmails = (type) => {
        var offers = this.props.offers.offers;
        offers = offers.filter((item) => {
            var filterBatch = item.status
            return filterBatch.indexOf('Confirmed') >= 0
        })
        offers = offers.filter((item) => {
            const today = new Date();
            var val = _.get(item, 'show_info')
            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            var filterBatch = new Date(closest.date)
            var start = today
            return filterBatch >= start
        })
        offers = offers.filter((item) => {
            var filterBatch = item.count_config.active
            return filterBatch === true
        })

        var emailArray = [];
        var mapObj = new Map();
        if (type === 'all') {
            var ren = [];
            // for(i = 0; i <= this.props.offer.offers.length; i++){
            //     for(v = 0; v <= this.props.offers.offers.length; i++){

            //     }
            // }
            // this.props.offer.offers.forEach(item => {
            //     var newObj = {

            //     }
            // })

            // this.props.offers.offers.reduce((acc, curr) => {
            //     const { key, buyer_id, venue_id } = curr;
            // })

            offers.forEach((item) => {
                if (item.count_config.active) {
                    item.show_info.forEach(show => {
                        item.show_date = show.date;
                        const k = JSON.stringify(item.count_config.contacts);
                        if (!mapObj.has(k)) {
                            mapObj.set(k, item)
                        } else {
                            // map.values(item)
                            var vals = mapObj.get(k);
                            // mapObj.delete(k)
                            if (vals.length > 0) {
                                mapObj.set(k, [...vals, item])
                            } else {
                                mapObj.set(k, [vals, item])
                            }

                            // mapObj.get(k).push(item)
                        }
                    })
                }
                // return map;
            })
            // const val = Array.from(this.props.offers.offers.reduce(function (map, item) {
            //     const k = item.counts_config.contacts.toString();
            //     if (!map.has(k)) {
            //         map.set(k, item)
            //     } else {
            //         // map.values(item)
            //         map.get(k).push(item)
            //     }
            //     return map;
            // }, new Map()).values());
        } else {
            offers.forEach((item) => {
                item.count_config.group.map(s => {
                    if (item.count_config.active) {
                        if (s._id === type) {
                            item.show_info.forEach(show => {
                                item.show_date = show.date;
                                const k = JSON.stringify(item.count_config.contacts);
                                if (!mapObj.has(k)) {
                                    mapObj.set(k, item)
                                } else {
                                    // map.values(item)
                                    var vals = mapObj.get(k);
                                    // mapObj.delete(k)
                                    if (vals.length > 0) {
                                        mapObj.set(k, [...vals, item])
                                    } else {
                                        mapObj.set(k, [vals, item])
                                    }

                                    // mapObj.get(k).push(item)
                                }
                            })
                        }
                    }
                })
            });
        }

        const values = Array.from(mapObj.values());
        const keys = Array.from(mapObj.keys());
        const filValues = values.filter((item, index) => keys[index] !== "[]");

        filValues.forEach((item, index) => {
            var shows = [];
            var contacts = [];
            if (item.length > 1) {
                item[0].count_config.contacts.forEach(con => {
                    const con_id = con._id;
                    const con_index = this.props.contacts.contacts.findIndex(i => i._id === con_id);
                    const con_email = this.props.contacts.contacts[con_index].contact.email;
                    const con_name = this.props.contacts.contacts[con_index].name;
                    const conObj = {
                        email: con_email,
                        name: con_name
                    }
                    contacts = [...contacts, conObj];
                })
                item.map(show => {
                    const single = show.artist_name + " - " + moment(show.show_date).utc().format('MM/DD/YYYY') + " - " + show.venue_name + " - " + show.venue_city + ", " + show.venue_state;
                    shows = [...shows, single]
                })
            } else {
                item.count_config.contacts.forEach(con => {
                    const con_id = con._id;
                    const con_index = this.props.contacts.contacts.findIndex(i => i._id === con_id);
                    const con_email = this.props.contacts.contacts[con_index].contact.email;
                    const con_name = this.props.contacts.contacts[con_index].name;
                    const conObj = {
                        email: con_email,
                        name: con_name
                    }
                    contacts = [...contacts, conObj];
                })
                const single = item.artist_name + " - " + moment(item.show_date).utc().format('MM/DD/YYYY') + " - " + item.venue_name + " - " + item.venue_city + ", " + item.venue_state;
                shows = [...shows, single]
            }
            var showsArr = Object.values(shows);
            var contactsArr = Object.values(contacts);
            // Object.values(shows).map((ite, ind) => {
            //     showsArr = [...showsArr, ite]
            // })
            // Object.values(contacts).map((ite, ind) => {
            //     contactsArr = [...contactsArr, ite]
            // })
            const email = {
                shows: showsArr,
                contacts: contactsArr,
                active: true
            }
            emailArray.push(email);
            // console.log(Object.keys(shows), Object.keys(contacts), Object.values(shows), Object.values(contacts))
        })

        this.setState({
            email_tc_dialog: true,
            email_tc_data: emailArray
        })
        // this.props.sendTcEmails('all')
    }

    sendTcEmails = async (arr, cc, message) => {
        const offersArr = arr.filter((item) => {
            var filterBatch = item.active
            return filterBatch === true
        })
        const data = {
            email: offersArr,
            user: this.props.auth.user.email,
            cc: cc,
            message: message
        }
        console.log(data)
        this.props.toggleExtSnackbar('Sending Email(s)');
        this.setState({
            email_tc_dialog: !this.state.email_tc_dialog
        })
        const log_status = await this.props.sendCountEmails(data);
        // const log_status = { type: 'success' }
        // const errCheck = log_status.filter(item => item.type === 'error');
        if (log_status.type === 'error') {
            // var errAlert = "error on 1 of your messages";
            // errCheck.map(item => {
            //     errAlert = errAlert + item.msg
            // })
            // alert(errAlert)
            this.props.toggleExtSnackbar('');
            // this.props.toggleErrorSnackbar(log_status.msg);
            alert(log_status.msg)
            this.setState({
                email_tc_dialog: true,
            })
        } else {
            var errArr = []
            log_status.msg.map(item => {
                if (item.type === 'error') {
                    errArr.push(item)
                }
            })
            if (errArr.length >= 1) {
                this.props.toggleExtSnackbar('');
                alert('Error sending the following emails:');
                this.setState({
                    email_tc_dialog: false,
                    email_tc_data: []
                })
            } else {
                this.props.toggleExtSnackbar('');
                this.props.toggleStatusSnackbar('Successfully sent emails!');
                this.setState({
                    email_tc_dialog: false,
                    email_tc_data: []
                })
            }
            // alert('emails successfully sent')
        }
        console.log(data)
    }

    closeTcEmails = () => {
        this.setState({
            email_tc_dialog: false,
            email_tc_data: []
        })
    }

    toggleItineraryDialog = (dir) => {
        if(!this.state.itinerary_dialog){
            var offer_roll = [];
            if (this.props.offer_checked.length > 0) {
                this.props.offer_checked.forEach(off => {
                    const oind = this.props.offers.offers.findIndex(o => o._id === off);
                    const odet = this.props.offers.offers[oind];
    
                    odet.show_info.forEach(s => {
                        const date = moment(s.date).format('MM/DD/YYYY');
    
                        const cli_id = this.props.clients.clients.findIndex(c => odet.client_id === c._id);
                        const cli_name = this.props.clients.clients[cli_id].client;
        
                        const ven_id = this.props.items.items.findIndex(v => v._id === odet.venue_id);
                        const fest_id = this.props.festivals.items.findIndex(v => v._id === odet.venue_id);
                        var venue = '';
                        var venue_address = {}
                        if(ven_id >= 0){
                            venue = this.props.items.items[ven_id].venue_name;
                            venue_address = this.props.items.items[ven_id].address;
                        }
                        if(fest_id >= 0){
                            venue = this.props.festivals.items[fest_id].festival;
                            venue_address = this.props.festivals.items[fest_id].address;
                        }
                        
        
                        const newObj = {
                            _id: off,
                            sub_id: s._id,
                            date: date,
                            client_name: cli_name,
                            venue_name: venue,
                            venue_address: venue_address,
                            fee_type: 'flat',
                            fee_amount: null,
                        };
                        offer_roll = [...offer_roll, newObj];
                    })
                    
                })
                this.setState({
                    itinerary_dialog: true,
                    itinerary_array: offer_roll,
                    itinerary_dialog_dir: dir
                })
                // this.props.downloadPdf('itinerary', 'offer')
            }
        } else {
            this.setState({
                itinerary_dialog: false,
                itinerary_array: [],
                itinerary_dialog_dir: null
            })
        }
    }

    generateItinerary = (data) => {
        console.log(data, "DATA HERE")
        if(data){
            if(this.state.itinerary_dialog_dir === 'download'){
                this.props.downloadPdf("itinerary", 'offers', data)
                this.setState({
                    itinerary_dialog: false,
                    itinerary_array: [],
                    itinerary_dialog_dir: null
                })
            } else {
                this.props.togglePdf("itinerary", 'offers', data)
                this.setState({
                    itinerary_dialog: false,
                    itinerary_array: [],
                    itinerary_dialog_dir: null
                })
            }
        }
    }

    toggleContractDialog = (dir) => {
        if(!this.state.contract_dialog){
            var offer_roll = [];
            if (this.props.offer_checked.length > 0) {
                this.props.offer_checked.forEach(off => {
                    const oind = this.props.offers.offers.findIndex(o => o._id === off);
                    const odet = this.props.offers.offers[oind];
    
                    odet.show_info.forEach(s => {
                        const date = moment(s.date).format('MM/DD/YYYY');
    
                        const cli_id = this.props.clients.clients.findIndex(c => odet.client_id === c._id);
                        const cli_name = this.props.clients.clients[cli_id].client;
        
                        const ven_id = this.props.items.items.findIndex(v => v._id === odet.venue_id);
                        const fest_id = this.props.festivals.items.findIndex(v => v._id === odet.venue_id);
                        var venue = '';
                        var venue_address = {}
                        if(ven_id >= 0){
                            venue = this.props.items.items[ven_id].venue_name;
                            venue_address = this.props.items.items[ven_id].address;
                        }
                        if(fest_id >= 0){
                            venue = this.props.festivals.items[fest_id].festival;
                            venue_address = this.props.festivals.items[fest_id].address;
                        }
                        
        
                        const newObj = {
                            _id: off,
                            sub_id: s._id,
                            date: date,
                            client_name: cli_name,
                            venue_name: venue,
                            venue_address: venue_address,
                        };
                        offer_roll = [...offer_roll, newObj];
                    })
                    
                })
                this.setState({
                    contract_dialog: true,
                    contract_array: offer_roll,
                    contract_dialog_dir: dir
                })
                // this.props.downloadPdf('itinerary', 'offer')
            }
        } else {
            this.setState({
                contract_dialog: false,
                contract_array: [],
                contract_dialog_dir: null
            })
        }
    }

    render() {

        return (

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <EmailDialog open={this.state.email_dialog} data={this.state.email_data} user_email={this.props.auth.user.email} issuePdf={this.issuePdf} handleClose={this.toggleEmailDialog} />
                <TcEmailDialog open={this.state.email_tc_dialog} close={this.closeTcEmails} send={this.sendTcEmails} array={this.state.email_tc_data} data={[]} />
                <ItineraryGenDialog open={this.state.itinerary_dialog} array={this.state.itinerary_array} onClose={this.toggleItineraryDialog} generate={this.generateItinerary} {...this.state} />
                <ContractGenDialog open={this.state.contract_dialog} array={this.state.contract_array} onClose={this.toggleContractDialog} generate={null} addendum={this.props.auth.user.company.templates} {...this.state} />

                {/* <div style={{ width: '5%', height: '100%' }}>
                    {this.state.button_group === "database" ?
                        <ContactButtonGroup toggleSub={this.props.toggleSub} toggleButtons={this.toggleButtons} subroute={this.props.subroute} /> :
                        <GridsButtonGroup toggleSub={this.props.toggleSub} toggleButtons={this.toggleButtons} subroute={this.props.subroute} />}
                </div> */}
                <div style={{ width: '100%', height: 30, backgroundColor: '#111', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <UncontrolledDropdown>
                        <DropdownToggle className='custom-toggle-menu'><FaPlus className='custom-toggle-icon' /></DropdownToggle>
                        <DropdownMenu style={{ border: '2px solid #333333', backgroundColor: '#000' }} right>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('offer_modal', 'render_offer_data')}>Offer</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('client_modal', 'render_client_data')}>Client</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('venue_modal', 'render_venue_data')}>Venue</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('buyer_modal', 'render_buyer_data')}>Buyer</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('contact_modal', 'render_contact_data')}>Contact</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('festival_modal', 'render_festival_data')}>Festival</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('packaging_modal', 'render_packaging_data')}>Packaging</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('ar_modal', 'render_ar_data')}>Artist Tracking (A&R)</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {/* <button style={{ width: 30, height: 28, backgroundColor: '#000', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }} type="button"><FaPlus size={12} color="#cdcdcd" /></button> */}
                    <button style={{ backgroundColor: this.props.filter ? '#0B6EFD' : '#000' }} type="button" onClick={this.props.toggleFilter} className='custom-toggle-menu'><FaFilter style={{ zIndex: 2 }} size={12} color="#fff" /></button>
                    <select className="db-menu-select" name="db_sub" value={this.state.db_sub} onChange={this.handleChange}>
                        <option value="offers">Offers</option>
                        <option value="clients">Clients</option>
                        <option value="venues">Venues</option>
                        <option value="buyers">Buyers</option>
                        <option value="contacts">Contacts</option>
                        <option value="festivals">Festivals</option>
                        <option value="packaging">Packaging</option>
                        <option value="ar">Artist Tracking</option>
                        <option value="counts">Ticket Counts</option>
                        <option value="finals">Final Totals</option>
                    </select>
                    {this.state.db_sub === 'finals' && Object.keys(this.state.finals_sold_array).length > 0 || this.state.db_sub === 'finals' && Object.keys(this.state.finals_gross_array).length > 0 || this.state.db_sub === 'finals' && Object.keys(this.state.finals_net_array).length > 0 || this.state.db_sub === 'finals' && Object.keys(this.state.finals_overage_array).length > 0 || this.state.db_sub === 'finals' && Object.keys(this.state.finals_notes_array).length > 0 || this.state.db_sub === 'counts' && Object.keys(this.state.todays_counts).length > 0 ?
                        <button style={{ width: 30, height: 28, backgroundColor: '#0B6EFD', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1, color: '#fff' }} type="button" onClick={this.state.db_sub === 'counts' ? this.handleCountsSave : this.state.db_sub === 'finals' ? this.handleFinalsSave : null}><FaSave size={12} color="#cdcdcd" /></button> : null}
                    {this.props.filter ?
                        <span style={{ display: 'flex' }}>
                            <button style={{ width: 'auto', padding: 5, height: 28, fontSize: 10, color: '#fff', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} type="button" className="btn btn-primary px-3" onClick={this.applyFilter}>APPLY</button>
                            <button style={{ padding: 5, height: 28, fontSize: 10, color: '#fff', backgroundColor: '#333', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} type="button" onClick={this.resetFilter}>RESET FILTER</button>
                        </span> : null}
                    {this.state.db_sub === 'counts' ?
                        <UncontrolledDropdown>
                            <DropdownToggle className='custom-toggle-menu-auto'>Email</DropdownToggle>
                            <DropdownMenu style={{ border: '2px solid #333333', backgroundColor: '#000' }} right>
                                <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.dialogTcEmails('all')}>All Ticket Count Emails</DropdownItem>
                                {this.props.auth.user.company.email.groups.map((item, index) => {
                                    return (
                                        <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} key={index} onClick={() => this.dialogTcEmails(item._id)}>{item.name}</DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown> : null}
                </div>
                <div style={{ width: '100%' }}>
                    <header style={{ height: this.props.filter ? 206 : 0 }}>
                        {this.props.filter ?
                            <DatabaseFilters offerFilterChange={this.offerFilterChange} offerFilterDateChange={this.offerFilterDateChange} applyOfferFilter={this.applyOfferFilter} applyVenueFilter={this.applyVenueFilter} applyBuyerFilter={this.applyBuyerFilter} applyContactFilter={this.applyContactFilter} applyClientFilter={this.applyClientFilter} applyFestivalFilter={this.applyFestivalFilter} applyCountsFilter={this.applyCountsFilter} applyPackagingFilter={this.applyPackagingFilter} applyArFilter={this.applyArFilter} applyFinalsFilter={this.applyFinalsFilter} venueFilterChange={this.venueFilterChange} buyerFilterChange={this.buyerFilterChange} contactFilterChange={this.contactFilterChange} clientFilterChange={this.clientFilterChange} festivalFilterChange={this.festivalFilterChange} festivalFilterDateChange={this.festivalFilterDateChange} packagingFilterChange={this.packagingFilterChange} packagingFilterDateChange={this.packagingFilterDateChange} handleChange={this.handleChange} clients={this.props.clients.clients} company={this.props.company} subroute={this.props.subroute} filterChange={this.filterChange} filterDateChange={this.filterDateChange} filterCapChange={this.filterCapChange} {...this.state} /> : null}

                    </header>
                    <div style={{ height: this.props.filter ? '56.65vh' : '85vh', width: '100%', backgroundColor: '#fff', display: 'flex', overflowY: 'scroll' }} className="chat-area" ref={this.myRef}>

                        {this.state.db_sub === 'buyers' ?
                            <Suspense fallback={<div>Loading...</div>}>
                                <BuyerRolodex handleDatabaseCheckbox={this.props.handleDatabaseCheckbox} handleDatabaseClearCheckbox={this.props.handleDatabaseClearCheckbox} handleDatabaseAllCheckbox={this.props.handleDatabaseAllCheckbox} buyer_checked={this.props.buyer_checked} buyer_all_checked={this.props.buyer_all_checked} toggleSub={this.props.toggleSub} filter={this.props.filter} iconRenderBuyer={this.props.iconRenderBuyer} toggleSortCatBuyers={this.toggleSortCatBuyers} toggleSortBuyers={this.toggleSortBuyers} deleteDialog={this.props.deleteDialog} {...this.state} />
                            </Suspense> :
                            this.state.db_sub === 'contacts' ?
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ContactRolodex handleDatabaseCheckbox={this.props.handleDatabaseCheckbox} handleDatabaseClearCheckbox={this.props.handleDatabaseClearCheckbox} handleDatabaseAllCheckbox={this.props.handleDatabaseAllCheckbox} contact_checked={this.props.contact_checked} contact_all_checked={this.props.contact_all_checked} toggleSub={this.props.toggleSub} filter={this.props.filter} iconRenderContact={this.props.iconRenderContact} toggleSortCatContacts={this.toggleSortCatContacts} toggleSortContacts={this.toggleSortContacts} deleteDialog={this.props.deleteDialog} {...this.state} />
                                </Suspense> :
                                this.state.db_sub === 'venues' ?
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <VenueRolodex handleDatabaseCheckbox={this.props.handleDatabaseCheckbox} handleDatabaseClearCheckbox={this.props.handleDatabaseClearCheckbox} handleDatabaseAllCheckbox={this.props.handleDatabaseAllCheckbox} venue_checked={this.props.venue_checked} venue_all_checked={this.props.venue_all_checked} toggleSub={this.props.toggleSub} toggleSubOffers={this.props.toggleSubOffers} filter={this.props.filter} iconRenderVenue={this.props.iconRenderVenue} subroute={this.props.subroute} venue_sort={this.state.venue_sort} toggleSortVenues={this.toggleSortVenues} toggleSortCatVenues={this.toggleSortCatVenues} deleteDialog={this.props.deleteDialog} />
                                    </Suspense> :
                                    this.state.db_sub === 'clients' ?
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <ClientRolodex handleDatabaseCheckbox={this.props.handleDatabaseCheckbox} handleDatabaseClearCheckbox={this.props.handleDatabaseClearCheckbox} handleDatabaseAllCheckbox={this.props.handleDatabaseAllCheckbox} client_checked={this.props.client_checked} client_all_checked={this.props.client_all_checked} toggleSub={this.props.toggleSub} filter={this.props.filter} iconRenderClient={this.props.iconRenderClient} toggleSortCatClients={this.toggleSortCatClients} toggleSortClients={this.toggleSortClients} deleteDialog={this.props.deleteDialog} togglePdf={this.props.togglePdf} downloadPdf={this.props.downloadPdf} {...this.state} />
                                        </Suspense> :
                                        this.state.db_sub === 'festivals' ?
                                            <Suspense fallback={<div>Loading...</div>}> 
                                                <FestivalRolodex handleDatabaseCheckbox={this.props.handleDatabaseCheckbox} handleDatabaseClearCheckbox={this.props.handleDatabaseClearCheckbox} handleDatabaseAllCheckbox={this.props.handleDatabaseAllCheckbox} festival_checked={this.props.festival_checked} festival_all_checked={this.props.festival_all_checked} toggleSub={this.props.toggleSub} filter={this.props.filter} iconRenderFestival={this.props.iconRenderFestival} toggleSortCatFestivals={this.toggleSortCatFestivals} toggleSortFestivals={this.toggleSortFestivals} clearSorted={this.clearSorted} deleteDialog={this.props.deleteDialog} {...this.state} />
                                            </Suspense> :
                                            this.state.db_sub === 'counts' ?
                                                <Suspense fallback={<div>Loading...</div>}>
                                                    <CountsRolodex handleCountChange={this.handleCountChange} handleNotesChange={this.handleNotesChange} handleDatabaseCheckbox={this.props.handleDatabaseCheckbox} todays_counts={this.state.todays_counts} todays_notes={this.state.todays_notes} handleDatabaseClearCheckbox={this.props.handleDatabaseClearCheckbox} handleDatabaseAllCheckbox={this.props.handleDatabaseAllCheckbox} counts_checked={this.props.counts_checked} counts_all_checked={this.props.counts_all_checked} iconRenderOffer={this.props.iconRenderOffer} clearTCInput={this.clearTCInput} counts_filter={this.state.counts_filter} toggleSortCounts={this.toggleSortCounts} toggleSortCatCounts={this.toggleSortCatCounts} clearSorted={this.clearSorted} handleCountsSave={this.handleCountsSave} countInputSocket={this.countInputSocket} {...this.state} />
                                                </Suspense> :
                                                this.state.db_sub === 'packaging' ?
                                                    <Suspense fallback={<div>Loading...</div>}>
                                                        <PackagingRolodex handleDatabaseCheckbox={this.props.handleDatabaseCheckbox} iconRenderPackaging={this.props.iconRenderPackaging} toggleSortCatPackaging={this.toggleSortCatPackaging} handleDatabaseClearCheckbox={this.props.handleDatabaseClearCheckbox} handleDatabaseAllCheckbox={this.props.handleDatabaseAllCheckbox} packaging_checked={this.props.packaging_checked} packaging_all_checked={this.props.packaging_all_checked} deleteDialog={this.props.deleteDialog} {...this.state} />
                                                    </Suspense> :
                                                    this.state.db_sub === 'ar' ?
                                                        <Suspense fallback={<div>Loading...</div>}>
                                                            <ARRolodex handleDatabaseCheckbox={this.props.handleDatabaseCheckbox} iconRenderAR={this.props.iconRenderAR} handleDatabaseClearCheckbox={this.props.handleDatabaseClearCheckbox} handleDatabaseAllCheckbox={this.props.handleDatabaseAllCheckbox} ar_checked={this.props.ar_checked} ar_all_checked={this.props.ar_all_checked} deleteDialog={this.props.deleteDialog} toggleRenderAR={this.props.toggleRenderAR} {...this.state} />
                                                        </Suspense> :
                                                        this.state.db_sub === 'offers' ?
                                                            <Suspense fallback={<div>Loading...</div>}>
                                                                <OfferRolodex handleDatabaseCheckbox={this.props.handleDatabaseCheckbox} handleDatabaseClearCheckbox={this.props.handleDatabaseClearCheckbox} toggleSub={this.props.toggleSub} iconRenderOffer={this.props.iconRenderOffer} toggleDealMemoPDFContext={this.props.toggleDealMemoPDFContext} toggleItineraryPDF={this.props.toggleItineraryPDF} countCompareContext={this.props.countCompareContext} offer_checked={this.props.offer_checked} offer_all_checked={this.props.offer_all_checked} toggleSortCatOffers={this.toggleSortCatOffers} toggleSortOffers={this.toggleSortOffers} handleDatabaseAllCheckbox={this.props.handleDatabaseAllCheckbox} clearSorted={this.clearSorted} toggleRenderOffer={this.props.toggleRenderOffer} togglePdf={this.props.togglePdf} copyOffer={this.props.copyOffer} toggleGeoMap={this.props.toggleGeoMap} toggleEmailDialog={this.toggleEmailDialog} tooltip={this.props.cal_tooltip} deleteDialog={this.props.deleteDialog} downloadPdf={this.props.downloadPdf} toggleItineraryDialog={this.toggleItineraryDialog} toggleContractDialog={this.toggleContractDialog} {...this.state} />
                                                            </Suspense> :
                                                            this.state.db_sub === 'finals' ?
                                                                <Suspense fallback={<div>Loading...</div>}>
                                                                    <FinalsRolodex handleDatabaseCheckbox={this.props.handleDatabaseCheckbox} handleDatabaseClearCheckbox={this.props.handleDatabaseClearCheckbox} toggleSub={this.props.toggleSub} iconRenderOffer={this.props.iconRenderOffer} toggleDealMemoPDFContext={this.props.toggleDealMemoPDFContext} toggleItineraryPDF={this.props.toggleItineraryPDF} countCompareContext={this.props.countCompareContext} finals_checked={this.props.finals_checked} offer_all_checked={this.props.finals_all_checked} toggleSortFinals={this.toggleSortFinals} toggleSortOffers={this.toggleSortOffers} handleDatabaseAllCheckbox={this.props.handleDatabaseAllCheckbox} clearSorted={this.clearSorted} toggleRenderOffer={this.props.toggleRenderOffer} togglePdf={this.props.togglePdf} copyOffer={this.props.copyOffer} toggleGeoMap={this.props.toggleGeoMap} toggleEmailDialog={this.toggleEmailDialog} tooltip={this.props.cal_tooltip} handleFinalsChange={this.handleFinalsChange} finalsInputSocket={this.finalsInputSocket} {...this.state} />
                                                                </Suspense> :
                                                                null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.clients,
    contacts: state.contacts,
    counts: state.counts,
    company: state.company,
    offers: state.offers,
    items: state.items,
    festivals: state.festivals,
    buyers: state.buyers,
    auth: state.auth,
});

export default connect(mapStateToProps, { addCountsBulk, applyCountsFilter, countsSort, offerCombine, offerSort, applyOfferFilter, applyVenueFilter, venueSort, applyBuyerFilter, buyerSort, applyContactFilter, contactSort, applyClientFilter, clientSort, applyFestivalFilter, festivalSort, applyPackagingFilter, packagingSort, applyArFilter, getOfferDetail, issuePdf, deleteOffer, addFinalsBulk, applyFinalsFilter, finalsSort, connectGmail, sendCountEmails, dbContractIssued })(DatabaseScreen);