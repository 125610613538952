import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PhoneInput from 'react-phone-input-2';
import countryDialCode from '../global/countries';
import getBadWords from '../../helpers/curse-words';

export default function RegistrationDialog(props) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [cellPhone, setCellPhone] = useState('');
    const [cellPhoneCountry, setCellPhoneCountry] = useState(null);
    const [officePhone, setOfficePhone] = useState('');
    const [officePhoneCountry, setOfficePhoneCountry] = useState(null);

    // useEffect(() => {

    // }, [])
 
    const register = () => {
        if (!firstName || !lastName || !username) {
            alert('Please add your first, last name, and username to continue.')
        } else {
            const bw = getBadWords(username);
            if (bw.length > 0) {
                alert('Error: You have an unpermitted word in your requested username.')
            } else {
                const name = {
                    first: firstName,
                    last: lastName
                }
                const add = {
                    address: address,
                    address2: address2,
                    city: city,
                    state: state,
                    country: country,
                    postal_code: postalCode,
                }
                const contact = {
                    mobile: cellPhone,
                    office: officePhone,
                    home: '',
                    fax: ''
                }
                const data = {
                    name: name,
                    username: username,
                    address: add,
                    contact: contact
                }
                props.register(data)
            }
        }
    }

    const handleCountryChange = async (val) => {
        const codes = await countryDialCode(val);
        setCountry(val);
        setCellPhoneCountry(codes.code);
        setOfficePhoneCountry(codes.code);
    }

    const handleUsernameChange = (text) => {
        var format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        const t = format.test(text)
        if (t) {
            alert('Cannot use special characters')
        } else {
            setUsername(text)
        }
    }

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            // onClose={props.onClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Register</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: 500, marginBottom: 30, marginTop: 10 }}>
                    <div style={{ width: '100%', height: 'auto', marginTop: 5 }}>
                        <span style={{ width: '100%', height: 'auto', display: 'flex' }}>
                            <p style={{ fontSize: 12 }}>It looks like this is your first time logging into the Solar System - welcome!  Please add some of your info below and hit 'Save' to get started.</p>
                        </span>
                        <p style={{ fontSize: 12, color: 'red' }}>* required</p>
                    </div>
                    <div style={{ width: '100%', height: 'auto', marginTop: 5, display: 'flex', flexDirection: 'column' }}>
                        <label style={{ fontSize: 14 }}>Name:*</label>
                        <span style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <input style={{ width: '50%', height: 30, fontSize: 12 }} type="text" name="firstName" value={firstName} placeholder="First" onChange={(e) => setFirstName(e.target.value)} />
                            <input style={{ width: '50%', height: 30, fontSize: 12 }} type="text" name="lastName" value={lastName} placeholder="Last" onChange={(e) => setLastName(e.target.value)} />
                        </span>
                    </div>
                    <div style={{ width: '100%', height: 'auto', marginTop: 5, display: 'flex', flexDirection: 'column' }}>
                        <label style={{ fontSize: 14 }}>Username:*</label>
                        <span style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <input style={{ width: '100%', height: 30, fontSize: 12 }} type="text" name="username" value={username} placeholder="Username" onChange={(e) => handleUsernameChange(e.target.value)} />
                        </span>
                    </div>
                    <div style={{ width: '100%', height: 'auto', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                        <label style={{ fontSize: 14 }}>Address:</label>
                        {country ?
                            <span style={{ width: '100%' }}>
                                <input style={{ width: '100%', height: 30, fontSize: 12 }} type="text" name="address" value={address} placeholder="Address" onChange={(e) => setAddress(e.target.value)} />
                                <input style={{ width: '100%', height: 30, fontSize: 12 }} type="text" name="address 2" value={address2} placeholder="Address2" onChange={(e) => setAddress2(e.target.value)} />
                                <input style={{ width: '100%', height: 30, fontSize: 12 }} type="text" name="city" value={city} placeholder="City" onChange={(e) => setCity(e.target.value)} />
                                {country === 'United States' ?
                                    <select style={{ width: '100%', height: 30, fontSize: 12 }} value={state} name="state" onChange={(e) => setState(e.target.value)}>
                                        <option value="">Select State</option>
                                        <option value="Alabama">Alabama</option>
                                        <option value="Alaska">Alaska</option>
                                        <option value="Arizona">Arizona</option>
                                        <option value="Arkansas">Arkansas</option>
                                        <option value="California">California</option>
                                        <option value="Colorado">Colorado</option>
                                        <option value="Connecticut">Connecticut</option>
                                        <option value="Delaware">Delaware</option>
                                        <option value="District Of Columbia">District Of Columbia</option>
                                        <option value="Florida">Florida</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Hawaii">Hawaii</option>
                                        <option value="Idaho">Idaho</option>
                                        <option value="Illinois">Illinois</option>
                                        <option value="Indiana">Indiana</option>
                                        <option value="Iowa">Iowa</option>
                                        <option value="Kansas">Kansas</option>
                                        <option value="Kentucky">Kentucky</option>
                                        <option value="Louisiana">Louisiana</option>
                                        <option value="Maine">Maine</option>
                                        <option value="Maryland">Maryland</option>
                                        <option value="Massachusetts">Massachusetts</option>
                                        <option value="Michigan">Michigan</option>
                                        <option value="Minnesota">Minnesota</option>
                                        <option value="Mississippi">Mississippi</option>
                                        <option value="Missouri">Missouri</option>
                                        <option value="Montana">Montana</option>
                                        <option value="Nebraska">Nebraska</option>
                                        <option value="Nevada">Nevada</option>
                                        <option value="New Hampshire">New Hampshire</option>
                                        <option value="New Jersey">New Jersey</option>
                                        <option value="New Mexico">New Mexico</option>
                                        <option value="New York">New York</option>
                                        <option value="North Carolina">North Carolina</option>
                                        <option value="North Dakota">North Dakota</option>
                                        <option value="Ohio">Ohio</option>
                                        <option value="Oklahoma">Oklahoma</option>
                                        <option value="Oregon">Oregon</option>
                                        <option value="Pennsylvania">Pennsylvania</option>
                                        <option value="Rhode Island">Rhode Island</option>
                                        <option value="South Carolina">South Carolina</option>
                                        <option value="South Dakota">South Dakota</option>
                                        <option value="Tennessee">Tennessee</option>
                                        <option value="Texas">Texas</option>
                                        <option value="Utah">Utah</option>
                                        <option value="Vermont">Vermont</option>
                                        <option value="Virginia">Virginia</option>
                                        <option value="Washington">Washington</option>
                                        <option value="West Virginia">West Virginia</option>
                                        <option value="Wisconsin">Wisconsin</option>
                                        <option value="Wyoming">Wyoming</option>
                                    </select> :
                                    <input style={{ width: '100%', height: 30, fontSize: 12 }} type="text" name="state" value={state} placeholder="State" onChange={(e) => setState(e.target.value)} />}
                                <input style={{ width: '100%', height: 30, fontSize: 12 }} type="text" name="postalCode" value={postalCode} placeholder="Postal Code" onChange={(e) => setPostalCode(e.target.value)} />
                            </span> : null}
                        <select style={{ width: '100%', height: 30, fontSize: 12 }} name="country" value={country} onChange={(e) => handleCountryChange(e.target.value)}>
                            <option value="">Select Country</option>
                            <option value="United States">United States</option>
                            <option value="Canada">Canada</option>
                            <option value="Afganistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bonaire">Bonaire</option>
                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                            <option value="Brunei">Brunei</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canary Islands">Canary Islands</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Channel Islands">Channel Islands</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos Island">Cocos Island</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote DIvoire">Cote DIvoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curaco">Curacao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands">Falkland Islands</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Ter">French Southern Ter</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Great Britain">Great Britain</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="India">India</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea North">Korea North</option>
                            <option value="Korea Sout">Korea South</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Midway Islands">Midway Islands</option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Nambia">Nambia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherland Antilles">Netherland Antilles</option>
                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                            <option value="Nevis">Nevis</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau Island">Palau Island</option>
                            <option value="Palestine">Palestine</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Phillipines">Philippines</option>
                            <option value="Pitcairn Island">Pitcairn Island</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                            <option value="Republic of Serbia">Republic of Serbia</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="St Barthelemy">St Barthelemy</option>
                            <option value="St Eustatius">St Eustatius</option>
                            <option value="St Helena">St Helena</option>
                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                            <option value="St Lucia">St Lucia</option>
                            <option value="St Maarten">St Maarten</option>
                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                            <option value="Saipan">Saipan</option>
                            <option value="Samoa">Samoa</option>
                            <option value="Samoa American">Samoa American</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Tahiti">Tahiti</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Erimates">United Arab Emirates</option>
                            <option value="Uraguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City State">Vatican City State</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                            <option value="Wake Island">Wake Island</option>
                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zaire">Zaire</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                        {/* <input style={{ width: '100%', height: 30, fontSize: 12 }} type="text" name="country" value={country} placeholder="Country" onChange={(e) => handleCountryChange(e.target.value)} /> */}
                    </div>
                    <div style={{ width: '100%', height: 'auto', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                        <label style={{ fontSize: 14 }}>Contact Info:</label>
                        <span style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', height: 28 }}>
                            <p style={{ width: '15%', fontSize: 12, color: '#000' }}>Mobile</p>
                            <PhoneInput
                                containerStyle={{ width: '85%', borderRadius: 5, border: '.4px solid #000' }}
                                inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                className="form-control-sm"
                                country={cellPhoneCountry}
                                disableCountryGuess={!cellPhoneCountry ? false : true}
                                // placeholder={'Mobile Phone'}
                                renderStringAsFlag={!cellPhoneCountry ? true : false}
                                value={cellPhone}
                                onChange={num => setCellPhone(num)} />
                        </span>
                        <span style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', height: 28 }}>
                            <p style={{ width: '15%', fontSize: 12, color: '#000' }}>Office</p>
                            <PhoneInput
                                containerStyle={{ width: '85%', borderRadius: 5, border: '.4px solid #000' }}
                                inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                className="form-control-sm"
                                country={officePhoneCountry}
                                disableCountryGuess={!officePhoneCountry ? false : true}
                                // placeholder={'Office Phone'}
                                renderStringAsFlag={!officePhoneCountry ? true : false}
                                value={officePhone}
                                onChange={num => setOfficePhone(num)} />
                        </span>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)', marginLeft: 10 }} onClick={register}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}