import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CurrencyAutocomplete from '../autocompletes/currency-autocomplete';
import DatePicker from "react-datepicker";

export default function ContractIssuedDialog(props) {
    const [stateCurrencySelect, setCurrencySelect] = useState(null);
    const [stateDateSelect, setDateSelect] = useState(new Date());
    const [stateNotes, setNotes] = useState("");
    const [stateUser, setUser] = useState(props.user.first + " " + props.user.last);

    const handleChange = (event) => {
        const value = event.target.value;
        setNotes(value);
    }

    const handleDateChange = (date) => {
        setDateSelect(date);
    };

    const handleSave = () => {
        const issued = {
            date: stateDateSelect,
            user: props.tag,
            note: stateNotes
        }
        console.log(issued);
        closeDialog();
    }

    const closeDialog = () => {
        props.handleClose();
    };

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={props.handleClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Contract Issued</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
                    <div
                        style={{
                            paddingRight: 17,
                            height: "100%",
                            width: "100%",
                            boxSizing: "content-box",
                            overflow: "scroll"
                        }}>
                        <table style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Date:</td>
                                    <td style={{ width: '50%' }}>
                                        <DatePicker
                                            className="react-datepicker"
                                            style={{ fontSize: 12 }}
                                            value={stateDateSelect}
                                            selected={stateDateSelect}
                                            onChange={handleDateChange}
                                            dateFormat="MM/dd/yyyy"
                                            todayButton="Today"
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>User:</td>
                                    <td style={{ width: '50%' }}><input style={{ width: '100%', fontSize: 12 }} type="text" disabled={true} value={stateUser}/></td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Notes:</td>
                                    <td style={{ width: '50%' }}><textarea style={{ width: '100%', maxWidth: '100%', height: 35, fontSize: 12 }} name="notes" value={stateNotes} onChange={handleChange}/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={closeDialog}>Close</Button>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}