import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { FaInfoCircle, FaTicketAlt, FaDollarSign, FaAtlas, FaBullhorn, FaCogs, FaDna, FaChartLine, FaRss, FaDonate, FaPaperclip } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
    },
    '& :focus': {
      outline: 'none'
    },
  },
}));

export default function OfferButtonGroup(props) {
  const classes = useStyles();

  console.log(props.dealsection)

  return (
    <div className={classes.root}>
      <ButtonGroup
        orientation="vertical"
        color="default"
        variant="text"
        aria-label="outlined button group"
        style={{ width: '100%' }}
      >
        <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Show Info' ? '#fff' : 'rgb(20, 20, 20)', height: 52, borderTop: '1px solid #333' }} onClick={() => props.toggleDealSection('Show Info')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaInfoCircle size="16" color={props.dealsection === 'Show Info' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Show Info' ? '#000' : '#fff' }}>Show Info</p></div></Button>
        <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Ticket Scaling' ? '#fff' : 'rgb(20, 20, 20)', height: 52, borderTop: '1px solid #333' }} onClick={() => props.toggleDealSection('Ticket Scaling')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaTicketAlt size="16" color={props.dealsection === 'Ticket Scaling' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Ticket Scaling' ? '#000' : '#fff' }}>Ticket Scaling</p></div></Button>
        <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Expenses' ? '#fff' : 'rgb(20, 20, 20)', height: 52, borderTop: '1px solid #333' }} onClick={() => props.toggleDealSection('Expenses')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaDollarSign size="16" color={props.dealsection === 'Expenses' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Expenses' ? '#000' : '#fff' }}>Expenses</p></div></Button>
        <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Deal Terms' ? '#fff' : 'rgb(20, 20, 20)', height: 52, borderTop: '1px solid #333' }} onClick={() => props.toggleDealSection('Deal Terms')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaAtlas size="16" color={props.dealsection === 'Deal Terms' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Deal Terms' ? '#000' : '#fff' }}>Deal Terms</p></div></Button>
        <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Additional Terms' ? '#fff' : 'rgb(20, 20, 20)', height: 52, borderTop: '1px solid #333', borderBottom: '1px solid #333' }} onClick={() => props.toggleDealSection('Additional Terms')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaBullhorn size="16" color={props.dealsection === 'Additional Terms' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Additional Terms' ? '#000' : '#fff' }}>Additional Terms</p></div></Button>
        <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Config' ? '#fff' : 'rgb(20, 20, 20)', height: 52, borderTop: '1px solid #333', borderBottom: '1px solid #333' }} onClick={() => props.toggleDealSection('Config')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaCogs size="16" color={props.dealsection === 'Config' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Config' ? '#000' : '#fff' }}>Config</p></div></Button>
        {props.type === "render" ?
            <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Deal Tracking' ? '#fff' : '#333', height: 52, borderTop: '1px solid #ddd', borderBottom: '1px solid rgb(20, 20, 20)' }} onClick={() => props.toggleDealSection('Deal Tracking')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaDna size="16" color={props.dealsection === 'Deal Tracking' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Deal Tracking' ? '#000' : '#fff' }}>Tracking</p></div></Button> : null}
        {props.type === "render" ?    
            <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Ticket Counts' ? '#fff' : '#333', height: 52, borderTop: '1px solid rgb(20, 20, 20)', borderBottom: '1px solid rgb(20, 20, 20)' }} onClick={() => props.toggleDealSection('Ticket Counts')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaChartLine size="16" color={props.dealsection === 'Ticket Counts' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Ticket Counts' ? '#000' : '#fff' }}>Ticket Counts</p></div></Button> : null}
        {props.type === "render" ?    
            <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Marketing' ? '#fff' : '#333', height: 52, borderTop: '1px solid rgb(20, 20, 20)', borderBottom: '1px solid rgb(20, 20, 20)' }} onClick={() => props.toggleDealSection('Marketing')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaRss size="16" color={props.dealsection === 'Marketing' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Marketing' ? '#000' : '#fff' }}>Marketing</p></div></Button> : null}
        {props.type === "render" ?
          <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Attachments' ? '#fff' : '#333', height: 52, borderTop: '1px solid rgb(20, 20, 20)', borderBottom: '1px solid #ddd' }} onClick={() => props.toggleDealSection('Attachments')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaPaperclip size="16" color={props.dealsection === 'Attachments' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Attachments' ? '#000' : '#fff' }}>Attachments</p></div></Button> : null}
        {props.type === "render" ?
          <Button style={{ width: '100%', backgroundColor: props.dealsection === 'Final Totals' ? '#fff' : 'rgb(20, 20, 20)', height: 52, borderTop: '1px solid #333', borderBottom: '1px solid #333' }} onClick={() => props.toggleDealSection('Final Totals')}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}><FaDonate size="16" color={props.dealsection === 'Final Totals' ? '#000' : '#fff'} /><p style={{ fontSize: 9, color: props.dealsection === 'Final Totals' ? '#000' : '#fff' }}>Final Totals</p></div></Button> : null}
      </ButtonGroup>
    </div>
  );
}