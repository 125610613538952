import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FestivalAutocomplete from '../autocompletes/festival-autocomplete';
import AttachmentsAutocomplete from '../autocompletes/email-attachment-autocomplete';
import { FaEnvelopeOpenText, FaEnvelope, FaPlus } from 'react-icons/fa';

export default function PitchDownloadDialog(props) {
    const [stateTitle, setStateTitle] = useState('');
    const [stateFilename, setStateFilename] = useState('');
    const [photosCheckbox, setPhotosCheckbox] = useState(false);
    const [stateFestivalSelect, setFestivalSelect] = useState([]);


    // useEffect(() => {
    //     setComissionSelect(props.val);
    // }, [props.val])

    const handleTitleChange = (e) => {
        // var name = e.target.name;
        var val = e.target.value;
        setStateTitle(val)
    };

    const handleFilenameChange = (e) => {
        // var name = e.target.name;
        var val = e.target.value;
        setStateFilename(val)
    };

    const cancelDialog = () => {
        props.onClose();
    };

    const handlePhotosCheckbox = (rec) => {
        setPhotosCheckbox(!photosCheckbox)
    }

    const handleFestivalChange = (obj) => {
        if (obj && obj.festival) {
            setFestivalSelect([obj]);
            // if (!stateFilename) {
            const filename = `${obj.festival} - Pitch Deck`;
            setStateFilename(filename)
            // }
            // if (!stateTitle) {
            const title = `${obj.festival} - Pitch Deck`;
            setStateTitle(title)
            // }
        } else {
            setFestivalSelect([])
        }
    };

    const handleFestivalBlur = (e) => {
        // console.log(e)
        setFestivalSelect([])
    };

    const downloadPdf = () => {
        const obj = {
            tag: stateFestivalSelect,
            filename: stateFilename+".pdf",
            title: stateTitle,
            imagesCheckbox: photosCheckbox
        }
        props.downloadPdf(obj);
        props.onClose();
    }

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={props.onClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Pitch Deck</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: 500, marginBottom: 30, marginTop: 20 }}>
                    <div style={{ width: '100%', height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Clients Selected:</h2>
                        </span>
                        <input style={{ width: '80%', height: 30, fontSize: 12 }} type="text" disabled={true} value={props.client_checked.length} onChange={handleFilenameChange} />
                    </div>
                    <div style={{ width: '100%', height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Festival Tag:</h2>
                        </span>
                        <div style={{ width: '80%' }}>
                            <FestivalAutocomplete onFestivalChange={handleFestivalChange} agentSelect={stateFestivalSelect} edit={true} handleEmailBlur={handleFestivalBlur} />
                        </div>
                    </div>
                    <div style={{ width: '100%', height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Filename:</h2>
                        </span>
                        <span style={{ width: '80%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', height: 30 }}>
                            <input style={{ width: '80%', height: 30, fontSize: 12 }} type="text" name="stateFilename" value={stateFilename} onChange={handleFilenameChange} />
                            <h2 style={{ width: '20%', fontSize: 12, color: '#adadad' }}>.pdf</h2>
                        </span>
                    </div>
                    <div style={{ width: '100%', height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Title:</h2>
                        </span>
                        <input style={{ width: '80%', height: 30, fontSize: 12 }} type="text" name="stateTitle" value={stateTitle} onChange={handleTitleChange} />
                    </div>
                    <div style={{ width: 500, height: 30, marginTop: 5, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Include Images:</h2>
                        </span>
                        <div style={{ width: '80%' }}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                color="default"
                                checked={photosCheckbox}
                                onChange={handlePhotosCheckbox}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={cancelDialog}>Cancel</Button>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={downloadPdf}>Create</Button>
            </DialogActions>
        </Dialog>
    );
}