import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCompanyUsersAdmin } from '../actions/companyActions';
import { FaRegCopyright, FaFilter, FaPlus, FaUserShield } from 'react-icons/fa';
import StatsScreen from '../components/admin/stats.screen';
import EmployeePermissionsScreen from '../components/admin/employee-permissions.screen';
import PaymentDetailsScreen from '../components/admin/payment-details.screen';
import AdminFilters from '../components/filters/admin-filters';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
// import UserModal from '../modals/global/user.modal';
import RegisterModal from '../components/auth/RegisterModal';
import PayAccountModal from '../modals/admin/pay_account.modal';
import moment from 'moment';
import _ from 'lodash';

import '../App.css';

class AdminScreen extends Component {

    constructor() {
        super();
        this.state = {
            error: null,
            startDate: null,
            endDate: null,
            agent_select: '',
            graph_data: [],
            guarantees: null,
            gross_earnings: null,
            commissions: null,
            no_shows: null,
            no_deals: null,
            guarantees_us: null,
            gross_earnings_us: null,
            commissions_us: null,
            no_shows_us: null,
            no_deals_us: null,
            admin_sub: 'stats',
            stats_filter: {
                agent: '',
                start_date: null,
                end_date: null
            },
            perm_view: false,
            user_data: {},
            comp_users: [],
            pending: {},
            user_modal: false,
            user_select: {},
            pay_account_modal: false,
            pay_account_select: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    static propTypes = {
        isAdmin: PropTypes.bool,
        auth_accounting: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        offers: PropTypes.object.isRequired,
        error: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        // var end = moment().add(1, 'year');
        // var end_date = moment().add(1, 'years').format('MM/DD/YYYY');
        // var start_date = moment().add(-1, 'days').format('MM/DD/YYYY')
        var end_date = '12/31/' + new Date().getFullYear().toString();
        var start_date = '01/01/' + new Date().getFullYear().toString();

        this.props.offers.offers.map(item => {
            if (item.prog.settled) {

            }
        })

        const stats_filter = {
            agent: '',
            start_date: new Date(start_date),
            end_date: new Date(end_date)
        }

        this.reloadStats(stats_filter);

        function getCompUsers(comp_users, getCompanyUsersAdmin) {
            return new Promise(async (resolve, reject) => {
                const adminUsers = await getCompanyUsersAdmin();
                var compUsers = [];
                comp_users.map(item => {
                    const index = adminUsers.findIndex(user => user._id === item.tag);
                    const newItem = adminUsers[index];
                    delete newItem._id;
                    compUsers = [...compUsers, { ...item, ...newItem }];
                })
                resolve(compUsers)
            })
        }

        const getCompanyUsersAdmin = async () => {
            const users = await this.props.getCompanyUsersAdmin()
            return users
        }

        const compUsers = await getCompUsers(this.props.company.comp_users, getCompanyUsersAdmin);
        this.setState({
            comp_users: compUsers
        })
        // this.setState({
        //     startDate: new Date(),
        //     endDate: new Date(end_date),
        //     stats_filter: stats_filter,
        //     graph_data: result,
        //     guarantees: guarantees,
        //     gross_earnings: gross_earnings,
        //     no_shows: no_shows,
        //     no_deals: no_deals
        // })


        const socket = this.props.auth.socket;
        if (socket) {
            socket.on(`clients/update/permissions/`, (contact) => {

            })
            socket.on(`employee/update/permissions/`, (contact) => {

            })
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    filterChange = (event, f) => {
        var name = event.target.name;
        var val = event.target.value;
        let filter = Object.assign({}, this.state[f]);
        filter[name] = val;
        this.setState({
            [f]: filter
        });
    }

    filterDateChange = (n, v, f) => {
        var name = v;
        var val = n;
        let filter = Object.assign({}, this.state[f]);
        filter[name] = new Date(val);
        this.setState({
            [f]: filter
        });
    }

    handleStartDateChange(date) {
        this.setState({
            startDate: date
        })
    }

    handleEndDateChange(date) {
        this.setState({
            endDate: date
        })
    }

    applyFilter = () => {
        if (this.state.admin_sub === 'stats') {
            var filter = this.state.stats_filter;
            this.reloadStats(filter);
        }
    }

    resetFilter = () => {
        if (this.state.admin_sub === 'stats') {
            var end_date = moment().add(1, 'years').format('MM/DD/YYYY');
            this.setState({
                stats_filter: {
                    agent: '',
                    start_date: new Date(),
                    end_date: new Date(end_date)
                }
            })
        }
    }

    togglePayModal = (acc) => {
        if (!this.state.pay_account_modal) {
            this.setState({
                pay_account_modal: true,
                pay_account_select: acc ? acc : null
            })
        } else {
            this.setState({
                pay_account_modal: false,
                pay_account_select: null
            })
        }
    }

    reloadStats = (filter) => {
        var offers = this.props.offers.offers;
        offers = offers.filter((item) => {
            var filterBatch = item.status
            return filterBatch.indexOf('Confirmed') >= 0
        })
        offers = offers.filter((item) => {
            const today = moment();
            var val = _.get(item, 'show_info')
            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            var filterBatch = new Date(closest.date)
            var start = new Date(filter.start_date)
            return filterBatch > start
        })
        offers = offers.filter((item) => {
            const today = new Date();
            var val = _.get(item, 'show_info')
            const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            var filterBatch = new Date(furthest.date)
            var end = new Date(filter.end_date)
            return filterBatch <= end
        })

        // if (filter.agent) {
        //     offers = offers.filter((item) => {
        //         var filterBatch = item.agent.map((a, b) => a._id)
        //         return filterBatch.indexOf(client_filter.agent) >= 0
        //     })
        // }
        var offer_filter_unset = offers.filter(item => item.prog.settled === false);
        var offer_filter_set = offers.filter(item => item.prog.settled === true);

        // var guarantees = offer_filter_set.reduce((previous, current) => previous + new Number(current.financials.guarantee), 0);
        var guarantees = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);
        if (guarantees !== Math.floor(guarantees)) {
            guarantees = guarantees.toFixed(2);
        }
        // var guarantees_us = offer_filter_unset.reduce((previous, current) => previous + new Number(current.financials.guarantee), 0);
        var guarantees_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);
        if (guarantees_us !== Math.floor(guarantees_us)) {
            guarantees_us = guarantees_us.toFixed(2);
        }
        // var gross_earnings = offer_filter_set.reduce((previous, current) => previous + new Number(current.financials.settlement_gross || 0), 0);
        var gross_earnings = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.settlement_gross) { return cnt + Number(o.settlement_gross) } else { return cnt + 0 }; }, 0);
        if (gross_earnings !== Math.floor(gross_earnings)) {
            gross_earnings = gross_earnings.toFixed(2);
        }
        // var gross_earnings_us = offer_filter_unset.reduce((previous, current) => previous + new Number(current.financials.settlement_gross || 0), 0);
        var gross_earnings_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.settlement_gross) { return cnt + Number(o.settlement_gross) } else if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);
        if (gross_earnings_us !== Math.floor(gross_earnings_us)) {
            gross_earnings_us = gross_earnings_us.toFixed(2);
        }
        // var commissions = offer_filter_set.reduce((previous, current) => previous + new Number(current.financials.commission || 0), 0);
        var commissions = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.commission) { return cnt + Number(o.commission) } else { return cnt + 0 }; }, 0);
        if (commissions !== Math.floor(commissions)) {
            commissions = commissions.toFixed(2);
        }
        // var commissions_us = offer_filter_unset.reduce((previous, current) => previous + new Number(current.financials.commission || 0), 0);
        var commissions_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.commission) { return cnt + Number(o.commission) } else if (o.settlement_gross) { return cnt + (Number(o.settlement_gross) * .1) } else if (o.guarantee) { return cnt + (Number(o.guarantee) * .1) } else { return cnt + 0 }; }, 0);
        if (commissions_us !== Math.floor(commissions_us)) {
            commissions_us = commissions_us.toFixed(2);
        }
        const no_shows = offer_filter_set.reduce((previous, current) => previous + new Number(current.show_info.length), 0);
        const no_deals = offer_filter_set.length;
        const no_shows_us = offer_filter_unset.reduce((previous, current) => previous + new Number(current.show_info.length), 0);
        const no_deals_us = offer_filter_unset.length;

        var pending = this.props.offers.offers;
        pending = pending.filter((item) => {
            var filterBatch = item.status
            return filterBatch.indexOf('Pending') >= 0
        })
        pending = pending.filter((item) => {
            const today = moment();
            var val = _.get(item, 'show_info')
            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            var filterBatch = new Date(closest.date)
            var start = new Date(filter.start_date)
            return filterBatch > start
        })
        pending = pending.filter((item) => {
            const today = new Date();
            var val = _.get(item, 'show_info')
            const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            var filterBatch = new Date(furthest.date)
            var end = new Date(filter.end_date)
            return filterBatch <= end
        })

        var pend_guarantees = pending.reduce((previous, current) => previous + new Number(current.financials.guarantee), 0);
        if (pend_guarantees !== Math.floor(pend_guarantees)) {
            pend_guarantees = pend_guarantees.toFixed(2);
        }
        const pend_no_shows = pending.reduce((previous, current) => previous + new Number(current.show_info.length), 0);
        const pend_no_deals = pending.length;

        const pendArray = {
            guarantees: pend_guarantees,
            no_shows: pend_no_shows,
            no_deals: pend_no_deals
        }

        var monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];

        var map_result = _.map(offers, function (item) {
            var d = new Date(item.show_info[0].date);
            var month = monthNames[d.getMonth()] + ", " + d.getFullYear();
            var guar = Number(item.financials.guarantee);
            return {
                "Month": month,
                "User_Count": guar
            };
        });

        var result_temp = _.reduce(map_result, function (memo, item) {
            if (memo[item.Month] === undefined) {
                memo[item.Month] = item.User_Count;
            } else {
                memo[item.Month] += item.User_Count;
            }
            return memo;
        }, {});

        var result = _.map(result_temp, function (value, key) {
            return {
                x: key,
                y: value
            };
        });

        this.setState({
            startDate: new Date(filter.start_date),
            endDate: new Date(filter.end_date),
            stats_filter: filter,
            graph_data: result,
            guarantees: guarantees,
            gross_earnings: gross_earnings,
            commissions: commissions,
            no_shows: no_shows,
            no_deals: no_deals,
            guarantees_us: guarantees_us,
            gross_earnings_us: gross_earnings_us,
            commissions_us: commissions_us,
            no_shows_us: no_shows_us,
            no_deals_us: no_deals_us,
            pending: pendArray
        })
    }

    togglePermissionsView = () => {
        this.setState({
            perm_view: !this.state.perm_view
        })
    }

    render() {

        console.log(this.props.auth_accounting, "AUTH HERE")

        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                {this.state.pay_account_modal ? <PayAccountModal close={this.togglePayModal} render_data={this.state.pay_account_select}/> : null}
                {/* <div style={{ width: '5%', height: '100%' }}>
                    {this.state.button_group === "database" ?
                        <ContactButtonGroup toggleSub={this.props.toggleSub} toggleButtons={this.toggleButtons} subroute={this.props.subroute} /> :
                        <GridsButtonGroup toggleSub={this.props.toggleSub} toggleButtons={this.toggleButtons} subroute={this.props.subroute} />}
                </div> */}
                <div style={{ width: '100%', height: 30, backgroundColor: '#111', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {/* <button style={{ width: 30, height: 28, backgroundColor: '#000', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }} type="button"><FaPlus size={12} color="#cdcdcd" /></button> */}
                    <UncontrolledDropdown>
                        <DropdownToggle className='custom-toggle-menu'><FaPlus className='custom-toggle-icon' /></DropdownToggle>
                        <DropdownMenu style={{ border: '2px solid #333333', backgroundColor: '#000' }} right>
                            {/* <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleUserModal()}>New Employee</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.togglePayModal()}>Payment Account</DropdownItem> */}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {/* <button style={{ width: 30, height: 28, backgroundColor: this.props.filter ? '#0B6EFD' : '#000', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1, color: '#fff' }} type="button" onClick={this.props.toggleFilter}><FaFilter size={12} color="#cdcdcd" /></button> */}
                    <button style={{ backgroundColor: this.props.filter ? '#0B6EFD' : '#000' }} type="button" onClick={this.props.toggleFilter} className='custom-toggle-menu'><FaFilter style={{ zIndex: 2 }} size={12} color="#fff" /></button>
                    <select className="db-menu-select" name="admin_sub" value={this.state.admin_sub} onChange={this.handleChange}>
                        <option value="stats">Company Stats</option>
                        <option value="payment">Payment Accounts</option>
                        <option value="permissions">Permissions</option>
                    </select>
                    {this.state.admin_sub === 'permissions' ?
                        <button style={{ width: 30, height: 28, backgroundColor: this.state.perm_view ? '#0B6EFD' : '#000', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1, color: '#fff' }} type="button" onClick={this.togglePermissionsView}><FaUserShield size={12} color="#cdcdcd" /></button> : null}
                    {this.props.filter ?
                        <span style={{ display: 'flex' }}>
                            <button style={{ width: 'auto', padding: 5, height: 28, fontSize: 10, color: '#fff', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} type="button" className="btn btn-primary px-3" onClick={this.applyFilter}>APPLY</button>
                            <button style={{ padding: 5, height: 28, fontSize: 10, color: '#fff', backgroundColor: '#333', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} type="button" onClick={this.resetFilter}>RESET FILTER</button>
                        </span> : null}
                </div>
                <div style={{ width: '100%' }}>
                    <header style={{ height: this.props.filter ? 206 : 0 }}>
                        {this.props.filter ?
                            <AdminFilters handleChange={this.handleChange} filterChange={this.filterChange} filterDateChange={this.filterDateChange} agents={this.props.company.agents} {...this.state} /> : null}

                    </header>
                    <div style={{ height: this.props.filter ? '56.65vh' : '85vh', width: '100%', backgroundColor: '#fff', display: 'flex', overflowY: 'scroll' }} className="chat-area" ref={this.myRef}>
                        {this.props.isAdmin ?
                            this.state.admin_sub === "stats" ?
                                <StatsScreen agents={this.props.company.agents} startDate={this.state.startDate} endDate={this.state.endDate} handleStartDateChange={this.handleStartDateChange} handleEndDateChange={this.handleEndDateChange} {...this.state} /> :
                                this.state.admin_sub === "permissions" ?
                                    <EmployeePermissionsScreen comp_users={this.state.comp_users} perm_view={this.state.perm_view} toggleUserModal={this.props.toggleUserModal} /> :
                                    this.state.admin_sub === "payment" ?
                                        <PaymentDetailsScreen accounting={this.props.auth_accounting} agents={this.props.company.agents} startDate={this.state.startDate} endDate={this.state.endDate} handleStartDateChange={this.handleStartDateChange} handleEndDateChange={this.handleEndDateChange} toggleModal={this.togglePayModal} {...this.state} /> :
                                        null : null}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isAdmin: state.auth.user.perm.admin,
    auth_accounting: state.auth.user.company.accounting,
    auth: state.auth,
    company: state.company,
    offers: state.offers,
    error: state.error
})

export default connect(mapStateToProps, { getCompanyUsersAdmin })(AdminScreen);