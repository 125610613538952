import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PermissionsOffersAutocomplete from '../autocompletes/permissions-offers-autocomplete';

export default function ClientPermissionsDialog(props) {
    const [stateTitle, setStateTitle] = useState('');
    const [stateType, setStateType] = useState('');
    const [financialCheckbox, setFinancialCheckbox] = useState(true);
    const [ticketCountCheckbox, setTicketCountCheckbox] = useState(true);
    const [accessClientAttachCheckbox, setAccessClientAttachCheckbox] = useState(true);
    const [uploadClientAttachCheckbox, setUploadClientAttachCheckbox] = useState(false);
    const [editClientPitchCheckbox, setEditClientPitchCheckbox] = useState(false);
    const [editClientBasicCheckbox, setEditClientBasicCheckbox] = useState(false);

    const [agentSelect, setAgentSelect] = useState(['Confirmed', 'Pending', 'Cancelled']);

    const [flatAmount, setFlatAmount] = useState(0);
    const [offersArray, setOffersArray] = useState([]);


    useEffect(() => {
        if (props.data.permissions) {
            const permissions = props.data.permissions;
            setAgentSelect(permissions.offers);
            setFinancialCheckbox(permissions.financials);
            setTicketCountCheckbox(permissions.ticket_counts);
            setAccessClientAttachCheckbox(permissions.client_attachments);
            setUploadClientAttachCheckbox(permissions.upload_client_attachments);
            setEditClientPitchCheckbox(permissions.edit_client_pitch);
            setEditClientBasicCheckbox(permissions.edit_client_basic);
        }
    }, [props.data])

    const cancelDialog = () => {
        setAgentSelect(['Confirmed', 'Pending', 'Cancelled']);
        setFinancialCheckbox(true);
        setTicketCountCheckbox(true);
        setAccessClientAttachCheckbox(true);
        setUploadClientAttachCheckbox(false);
        setEditClientPitchCheckbox(false);
        setEditClientBasicCheckbox(false);
        props.onClose();
    };

    const save = () => {
        const permissions = {
            offers: agentSelect,
            financials: financialCheckbox,
            ticket_counts: ticketCountCheckbox,
            client_attachments: accessClientAttachCheckbox,
            upload_client_attachments: uploadClientAttachCheckbox,
            edit_client_basic: editClientBasicCheckbox,
            edit_client_pitch: editClientPitchCheckbox,
        }
        const tag = props.data.tag;
        const email = props.data.email;
        props.save(permissions, tag, email);
    }

    const handleAgentChange = (agent) => {
        if (agent) {
            setAgentSelect(agent)
        } else {
            setAgentSelect([])
        }

    }

    const removeAccess = () => {
        const tag = props.data.tag;
        const email = props.data.email;
        props.removeAccess(tag, email)
    }

    const resetAccessToken = () => {
        const tag = props.data.tag;
        const email = props.data.email;
        props.resetAccessToken(tag, email)
    }

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            // onClose={props.onClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Client Access / Permissions</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: 500, marginBottom: 30, marginTop: 20 }}>
                    <div style={{ width: '100%', height: 30, marginTop: 5, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '100%', height: 30, display: 'flex' }}>
                            <h2 style={{ fontSize: 14 }}>{props.data.email}</h2>
                        </span>
                    </div>
                    <div style={{ width: '100%', height: 30, marginTop: 5, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '100%', height: 30, display: 'flex' }}>
                            <h2 style={{ fontSize: 14 }}>Permissions:</h2>
                        </span>
                    </div>
                    <div style={{ width: 500, height: 'auto', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                        <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                            <thead>
                                <tr>
                                    <th style={{ width: '60%', color: '#fff' }}>Description</th>
                                    <th style={{ width: '40%', color: '#fff', fontSize: 12 }}> </th>
                                </tr>
                            </thead>
                        </table>
                        <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                            <tbody>
                                <tr>
                                    <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Offers</td>
                                    <td style={{ width: '40%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        {/* <select style={{ width: '100%' }}>
                                            <option value="">All Offers (conf, pend, canc)</option>
                                        </select> */}
                                        <PermissionsOffersAutocomplete onAgentChange={handleAgentChange} agentSelect={agentSelect} edit={true} agentList={['Confirmed', 'Pending', 'Cancelled']} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                            <thead>
                                <tr>
                                    <th style={{ width: '60%', color: '#fff' }}> </th>
                                    <th style={{ width: '20%', color: '#fff', fontSize: 12 }}>Allow</th>
                                    <th style={{ width: '20%', color: '#fff', fontSize: 12 }}>Don't Allow</th>
                                </tr>
                            </thead>
                        </table>
                        <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                            <tbody>
                                <tr>
                                    <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Financials</td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={financialCheckbox}
                                            color="default"
                                            value={financialCheckbox}
                                            name="financials"
                                            onChange={() => setFinancialCheckbox(!financialCheckbox)}
                                        />
                                    </td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={!financialCheckbox}
                                            color="default"
                                            value={!financialCheckbox}
                                            name="financials"
                                            onChange={() => setFinancialCheckbox(!financialCheckbox)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Ticket Counts</td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={ticketCountCheckbox}
                                            color="default"
                                            value={ticketCountCheckbox}
                                            name="ticket_counts"
                                            onChange={() => setTicketCountCheckbox(!ticketCountCheckbox)}
                                        />
                                    </td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={!ticketCountCheckbox}
                                            color="default"
                                            value={!ticketCountCheckbox}
                                            name="ticket_counts"
                                            onChange={() => setTicketCountCheckbox(!ticketCountCheckbox)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Access Client Attachments</td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={accessClientAttachCheckbox}
                                            color="default"
                                            value={accessClientAttachCheckbox}
                                            name="access_client_attachments"
                                            onChange={() => setAccessClientAttachCheckbox(!accessClientAttachCheckbox)}
                                        />
                                    </td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={!accessClientAttachCheckbox}
                                            color="default"
                                            value={!accessClientAttachCheckbox}
                                            name="access_client_attachments"
                                            onChange={() => setAccessClientAttachCheckbox(!accessClientAttachCheckbox)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Upload Client Attachments</td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={uploadClientAttachCheckbox}
                                            color="default"
                                            value={uploadClientAttachCheckbox}
                                            name="upload_client_attachments"
                                            onChange={() => setUploadClientAttachCheckbox(!uploadClientAttachCheckbox)}
                                        />
                                    </td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={!uploadClientAttachCheckbox}
                                            color="default"
                                            value={!uploadClientAttachCheckbox}
                                            name="upload_client_attachments"
                                            onChange={() => setUploadClientAttachCheckbox(!uploadClientAttachCheckbox)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Edit Client Basic Info (address, links)</td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={editClientBasicCheckbox}
                                            color="default"
                                            value={editClientBasicCheckbox}
                                            name="edit_client_basic"
                                            onChange={() => setEditClientBasicCheckbox(!editClientBasicCheckbox)}
                                        />
                                    </td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={!editClientBasicCheckbox}
                                            color="default"
                                            value={!editClientBasicCheckbox}
                                            name="edit_client_basic"
                                            onChange={() => setEditClientPitchCheckbox(!editClientBasicCheckbox)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '60%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 12 }}>Edit Client Pitch</td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={editClientPitchCheckbox}
                                            color="default"
                                            value={editClientPitchCheckbox}
                                            name="edit_client_pitch"
                                            onChange={() => setEditClientPitchCheckbox(!editClientPitchCheckbox)}
                                        />
                                    </td>
                                    <td style={{ width: '20%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                            checked={!editClientPitchCheckbox}
                                            color="default"
                                            value={!editClientPitchCheckbox}
                                            name="edit_client_pitch"
                                            onChange={() => setEditClientPitchCheckbox(!editClientPitchCheckbox)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>



                    </div> 
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <div style={{ width: '100%', display: 'flex' }}>
                    <span style={{ width: '50%' }}>
                        {props.data.tag ?
                            <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={removeAccess}>Remove Access</Button> : null}
                        {!props.data.status ?
                            <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)', marginLeft: 10 }} onClick={resetAccessToken}>Resend</Button> : null}
                    </span>
                    <span style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={cancelDialog}>Cancel</Button>
                        <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)', marginLeft: 10 }} onClick={save}>Save</Button>
                    </span>
                </div>
            </DialogActions>
        </Dialog>
    );
}