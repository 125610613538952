import React, { Component } from 'react';
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-phone-number-input/style.css';
import { connect } from 'react-redux';
import AgentRoute from "./routes/AgentRoute";
import ClientRoute from "./routes/ClientRoute";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import LoadingMain from './components/global/loading-main';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordToken from './pages/ResetPasswordToken';
import ForgotPassword from './pages/ForgotPassword';
import GoogleLogin from './pages/GoogleLogin';
import PrivacyPolicy from './pages/PrivacyPolicy';
import store from './store';
import { loadUser } from './actions/authActions';
import PropTypes from 'prop-types';
import AgentLogin from './pages/AgentLogin';


class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      sidebarOpen: false
      //loading: true
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.onCloseSidebar = this.onCloseSidebar.bind(this);
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#000"
    store.dispatch(loadUser());


  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  onCloseSidebar() {
    this.setState({ sidebarOpen: false });
  }

  render() {

    return this.props.auth.isLoading === true ? (
      <LoadingMain />
    ) : (
      <div>
        <Switch>
          <PublicRoute exact path="/" component={AgentLogin} isAuthenticated={this.props.auth.isAuthenticated} slug={this.props.auth.user || null} />
          <PublicRoute path="/user/login:email" component={AgentLogin} isAuthenticated={this.props.auth.isAuthenticated} slug={this.props.auth.user || null} />
          <PublicRoute path="/create/password" component={ResetPasswordToken} isAuthenticated={this.props.auth.isAuthenticated} slug={this.props.auth.user || null} />
          <PublicRoute path="/reset/password" component={ForgotPassword} isAuthenticated={this.props.auth.isAuthenticated} slug={this.props.auth.user || null} />
          <PublicRoute path="/update/password" component={ResetPasswordToken} isAuthenticated={this.props.auth.isAuthenticated} slug={this.props.auth.user || null} />
          <PublicRoute path="/google/login" component={GoogleLogin} isAuthenticated={this.props.auth.isAuthenticated} slug={this.props.auth.user || null} />
          <Route path="/privacy-policy" component={PrivacyPolicy}/>
          {this.props.auth.user && this.props.auth.user.access === 'employee' ?
            <PrivateRoute path="/:slug" component={AgentRoute} isAuthenticated={this.props.auth.isAuthenticated} /> :
            <PrivateRoute path="/:slug" component={ClientRoute} isAuthenticated={this.props.auth.isAuthenticated} />}
        </Switch>
      </div>
    );
  };
};

// component={this.props.auth.user && this.props.auth.user.tag && this.props.auth.user.tag === '08012021101311919112153022' ? ClientRoute : AgentRoute}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(App);
