import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';
import ItineraryFinancialAddDialog from './itinerary-financial-add.dialog';
import FestivalAutocomplete from '../autocompletes/festival-autocomplete';
import AttachmentsAutocomplete from '../autocompletes/email-attachment-autocomplete';
import { FaEnvelopeOpenText, FaEnvelope, FaPlus } from 'react-icons/fa';
import { CONTACTS_SOCKET_DELETE } from '../../actions/types';

export default function ItineraryGenDialog(props) {
    const [stateTitle, setStateTitle] = useState('');
    const [stateType, setStateType] = useState('');
    const [stateFilename, setStateFilename] = useState('');
    const [financialCheckbox, setFinancialCheckbox] = useState(false);
    const [flatCheckbox, setFlatCheckbox] = useState(true);
    const [customCheckbox, setCustomCheckbox] = useState(false);
    const [flatAmount, setFlatAmount] = useState(0);
    const [stateFestivalSelect, setFestivalSelect] = useState([]);
    const [offersArray, setOffersArray] = useState([]);
    const [financialItinDialog, setFinancialItinDialog] = useState(false);
    const [financialItinDialogData, setFinancialItinDialogData] = useState({ type: 'Vs Net', percentage: 0, capped: false, capped_amt: 0 });


    useEffect(() => {
        if (props.array && props.array.length > 0) {
            setStateTitle(props.array[0].client_name);
            setOffersArray(props.array);
        }
    }, [props.array])

    const handleTitleChange = (e) => {
        // var name = e.target.name;
        var val = e.target.value;
        setStateTitle(val)
    };

    const handleFilenameChange = (e) => {
        // var name = e.target.name;
        var val = e.target.value;
        setStateFilename(val)
    };

    const cancelDialog = () => {
        props.onClose();
    };

    const handleFinancialCheckbox = (rec) => {
        setFinancialCheckbox(!financialCheckbox)
    }

    const handleFlatCheckbox = (rec) => {
        if (!flatCheckbox) {
            setFlatCheckbox(true);
            setCustomCheckbox(false);
        } else {
            setFlatCheckbox(false);
            setCustomCheckbox(true);
        }
    }

    const handleCustomCheckbox = (rec) => {
        if (!customCheckbox) {
            setCustomCheckbox(true);
            setFlatCheckbox(false);
        } else {
            setCustomCheckbox(false);
            setFlatCheckbox(true);
        }
    }

    const handleFestivalChange = (obj) => {
        if (obj && obj.festival) {
            setFestivalSelect([obj]);
            // if (!stateFilename) {
            const filename = `${obj.festival} - Pitch Deck`;
            setStateFilename(filename)
            // }
            // if (!stateTitle) {
            const title = `${obj.festival} - Pitch Deck`;
            setStateTitle(title)
            // }
        } else {
            setFestivalSelect([])
        }
    };

    const handleFestivalBlur = (e) => {
        // console.log(e)
        setFestivalSelect([])
    };

    const handleAmountChange = (values) => {
        const { formatted, value } = values;
        setFlatAmount(value)
    }

    const handleArrayTypeChange = (index, value) => {
        var arr = offersArray;
        arr[index].fee_type = value;
        console.log(arr)
        setOffersArray([...arr]);
    }

    const toggleFinancialItinDialog = (index) => {
        console.log(index)
        if (index >= 0) {
            const newObj = {
                id: index,
                type: 'Vs Net',
                percentage: 0,
                capped: false,
                capped_amt: 0
            }
            setFinancialItinDialogData(newObj)
            setFinancialItinDialog(!financialItinDialog)
        }
    }

    const handleCustomAmountChange = (values, index) => {
        const { formatted, value } = values;
        var arr = offersArray;
        arr[index].fee_amount = value;
        setOffersArray([...arr]);
    }

    const addCustomDeal = (id, value) => {
        var arr = offersArray;
        arr[id].fee_amount = value;
        setOffersArray([...arr]);
        setFinancialItinDialog(false);
    }

    const closeFinancialDialog = () => {
        const newObj = {
            id: null,
            type: 'Vs Net',
            percentage: 0,
            capped: false,
            capped_amt: 0
        }
        setFinancialItinDialogData(newObj)
        setFinancialItinDialog(false)
    }

    const create = () => {
        const options = {
            type: stateType,
            financials: financialCheckbox,
            name: stateTitle,
            flat_checkbox: flatCheckbox,
            flat_fee: flatAmount,
            custom_fee: offersArray,
        }
        props.generate(options);
    }

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={props.onClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <ItineraryFinancialAddDialog open={financialItinDialog} data={financialItinDialogData} onClose={closeFinancialDialog} save={addCustomDeal}/>
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Create Itinerary</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: 500, marginBottom: 30, marginTop: 20 }}>
                    <div style={{ width: '100%', height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Itinerary Type:</h2>
                        </span>
                        <select style={{ width: '80%', height: 30, fontSize: 12 }} name="stateType" value={stateType} onChange={(e) => setStateType(e.target.value)}>
                            <option value="">Headliner ({props.array && props.array.length > 0 ? props.array[0].client_name : ''})</option>
                            <option value="support">Support</option>
                        </select>
                    </div>
                    <div style={{ width: 500, height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Include Financials:</h2>
                        </span>
                        <div style={{ width: '80%' }}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                color="default"
                                checked={financialCheckbox}
                                onChange={handleFinancialCheckbox}
                            />
                        </div>
                    </div>
                    {stateType === 'support' ?
                        <div style={{ width: 500, height: 30, marginTop: 10 }}>
                            <span style={{ width: '100%', height: 30, display: 'flex', alignItems: 'flex-end', borderBottom: '1px solid #000' }}>
                                <h2 style={{ fontSize: 10 }}>Customize:</h2>
                            </span>
                        </div> : null}
                    {stateType === 'support' ?
                        <div style={{ width: '100%', height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                            <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                                <h2 style={{ fontSize: 10 }}>Artist Name:</h2>
                            </span>
                            <input style={{ width: '80%', height: 30, fontSize: 12 }} type="text" name="stateTitle" value={stateTitle} onChange={handleTitleChange} />
                        </div> : null}
                    {financialCheckbox && stateType === 'support' ?
                        <div style={{ width: 500, height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                            <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                                <h2 style={{ fontSize: 10 }}>Flat Fee (per show):</h2>
                            </span>
                            <div style={{ width: '80%', display: 'flex', flexDirection: 'row' }}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                    color="default"
                                    checked={flatCheckbox}
                                    onChange={handleFlatCheckbox}
                                />
                                {flatCheckbox ?
                                    // <input style={{ width: '50%', height: 30, fontSize: 12 }} type="text" name="stateTitle" value={stateTitle} placeholder="Amount" onChange={handleTitleChange} /> : null}
                                    <NumberFormat style={{ width: '50%', height: 30, border: '1px solid #000', fontSize: 12 }} className="form-control-sm" value={flatAmount} thousandSeparator={true} prefix={'$'} onValueChange={handleAmountChange} /> : null}
                            </div>
                        </div> : null}
                    {financialCheckbox && stateType === 'support' ?
                        <div style={{ width: 500, height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                            <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                                <h2 style={{ fontSize: 10 }}>Custom Fee:</h2>
                            </span>
                            <div style={{ width: '80%', display: 'flex' }}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                    color="default"
                                    checked={customCheckbox}
                                    onChange={handleCustomCheckbox}
                                />
                            </div>
                        </div> : null}
                    {financialCheckbox && stateType === 'support' && customCheckbox ?
                        <div style={{ width: 500, height: 'auto', marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <table style={{ width: '100%', overflowY: 'scroll' }} id="email-dialog-table">
                                    {offersArray.map((item, index) => {
                                        return (
                                            <tbody key={index}>
                                                <tr>
                                                    <td style={{ width: '15%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>{item.date}</p></td>
                                                    <td style={{ width: '25%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>{item.venue_name}</p></td>
                                                    <td style={{ width: '25%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>{item.venue_address.city} {item.venue_address.state}</p></td>
                                                    <td style={{ width: '10%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}><select style={{ height: 30, fontSize: 12 }} name="fee_type" value={item.fee_type} onChange={(e) => handleArrayTypeChange(index, e.target.value)}>
                                                        <option value="flat">Flat</option>
                                                        <option value="percentage">Percentage</option>
                                                    </select></p></td>
                                                    {item.fee_type === 'flat' ?
                                                        <td style={{ width: '25%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}><NumberFormat className="form-control-sm" value={Number(item.fee_amount)} thousandSeparator={true} prefix={'$'} onValueChange={(val) => handleCustomAmountChange(val, index)} /></p></td> :
                                                        <td style={{ width: '25%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0, display: 'flex', flexDirection: 'row' }}>{item.fee_amount ? <input style={{width: '100%', height: 30, fontSize: 12 }} type="text" name="stateTitle" value={item.fee_amount} onClick={() => toggleFinancialItinDialog(index)}/> : <button style={{ width: '100%', height: 30 }} onClick={() => toggleFinancialItinDialog(index)}>ADD</button>}</p></td>}
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>
                            </div>
                        </div> : null}
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={cancelDialog}>Cancel</Button>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={create}>Create</Button>
            </DialogActions>
        </Dialog>
    );
}