import React, { useEffect, useState } from "react";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block',
  },
  input: {
    width: '100%',
    fontSize: 12,
    paddingTop: '2%', paddingBottom: '2%', paddingLeft: '4%',
    height: 28,
    border: '.4px solid #000',
    borderRadius: 4
  },
  listbox: {
    width: '30%',
    margin: 0,
    padding: 10,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    fontSize: 12,
    maxHeight: 200,
    color: 'black',
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#e6f7ff',
      color: 'black',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#e6f7ff',
      color: 'black',
    },
  },
}));

export default function SearchAutocomplete({searchList, onChange, onSelect, searchSelect, artist_search, loading}) {
    const [stateSearchList, setSearchList] = useState(searchList);
    const [stateSearchSelect, setSearchSelect] = useState(searchSelect);

    useEffect(()=>{
        setSearchList(searchList);
       },[searchList])
    useEffect(()=>{
        setSearchSelect(searchSelect);
       },[searchSelect])

  const classes = useStyles();
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps, 
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    value: searchSelect,
    options: searchList,
    getOptionLabel: (option) =>  option.name || "" ,
    getOptionSelected: (option, value) => '"'+option.name+'"'.includes(value),
    onChange: (event, newValue) => {
        if(newValue){
        onSelect(newValue)
        } else if(!newValue){
        onSelect('')
        };
    }
  });
 
  return (
    <div>
      <div {...getRootProps()}>
        <input className={classes.input} {...getInputProps()} name="artist_search" value={artist_search} onChange={(e) => onChange(e.target.value)} />
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li style={{ padding: 5 }} {...getOptionProps({ option, index })}><img style={{marginRight: 10}} width="36" height="36" src={option.images[0] ? option.images[0].url : null} />{option.name}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}