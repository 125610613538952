import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    VENUES_INIT_LOADING,
    VENUES_LOADING,
    VENUES_GET,
    VENUES_FILTER,
    VENUES_SORT,
    VENUES_SOCKET,
    VENUES_SOCKET_DELETE,
    NOTIFICATIONS
} from "./types";

export const setVenuesLoading = () => {
    return {
      type: VENUES_LOADING
    };
};

export const setVenuesInitLoading = () => {
    return {
      type: VENUES_INIT_LOADING
    };
};

export const getVenues = (data) => async (dispatch, getState) => {
    dispatch(setVenuesInitLoading());
    await axios.post(`${process.env.REACT_APP_API_CLIENT}/venues/`, data, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: VENUES_GET,
            payload: res.data
        })
    })
    .catch(err =>
        dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const venueSocket = venue => (dispatch) => {
    dispatch({
        type: VENUES_SOCKET,
        payload: venue
    })
}

export const deleteVenueSocket = venue => (dispatch) => {
    dispatch({
        type: VENUES_SOCKET_DELETE,
        payload: venue
    })
}