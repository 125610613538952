import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ErrorSnackbar(props) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.closeSnackbar();
  };

  return (
    <Snackbar open={props.open} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} onClose={handleClose}>
        < Alert onClose={handleClose} severity="error">
          {props.message}
        </Alert>
    </Snackbar >
  );
}