import React, { PureComponent, useEffect, useState } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FaPlusSquare, FaMinusSquare, FaLink, FaUnlink } from 'react-icons/fa';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';
import TicketScalingMovable from '../../components/moveables/ticket-scaling-movable/ticket-scaling-movable';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';


class TicketScaling extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            checkboxfacilityfee: false,
            checkboxfacilityfeeapplyall: false,
            checkboxfacilityfeecustom: false,
            checkboxfacilityfeeapplyallvalue: '',
            checkboxaddfee1: false,
            checkboxaddfee1applyall: false,
            checkboxaddfee1custom: false,
            checkboxaddfee1applyallvalue: '',
            checkboxaddfee2: false,
            checkboxaddfee2applyall: false,
            checkboxaddfee2custom: false,
            checkboxaddfee2applyallvalue: '',
            dealcurrency: 'USD',
            tickettype1: '',
            ticketcap1: '',
            ticketcomps1: '',
            ticketkills1: '',
            ticketprice1: '',
            ticketfacfee1: '',
            ticketaddfeeA1: '',
            ticketaddfeeB1: '',
            ticketnotes: '',
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleBoxChange(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    }

    formatTime(timestamp) {
        const d = new Date(timestamp);
        const time = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
        return time;
    }

    handleCheckboxChange(event) {
        var isChecked = event.target.checked;
        var item = event.target.value;

        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    }

    handleCheckboxChangeUn(event) {
        var item = event.target.value;
        var isChecked = event.target.checked;

        this.state.checkedItems.delete(item, isChecked)

        this.setState(({ checkedItems: this.state.checkedItems }))
    }

    handleClick = () => {
        this.props.toggle();
    };

    addScaling = () => {
        var newValue = {
            type: this.props.tickettype1,
            capacity: this.props.ticketcap1,
            comps: this.props.ticketcomps1,
            kills: this.props.ticketkills1,
            price: this.props.ticketprice1,
            facfee: this.props.ticketff1,
            addfeeA: this.props.ticketaf1a,
            addfeeB: this.props.ticketaf1b
        }

        const newArray = []
        newArray.push(newValue, ...this.state.scalingarray)

        console.log(newArray)
    };

    deleteScaling = (props) => {
        this.props.deleteScaling(props)
    };

    render() {

        const currency_symbol = getSymbolFromCurrency(this.props.currency.ticket_scaling);

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <table style={{ marginLeft: 25, width: '86%', marginTop: 35 }}>
                    <thead>
                        <tr>
                            <th>
                                <button style={{ width: 'auto', height: 'auto', fontSize: 12, fontStyle: 'italic', cursor: this.props.edit ? 'pointer' : 'default' }} type="button" onClick={this.props.edit ? this.props.toggleCurrencyDialog : null}>
                                    Scaling Currency: {this.props.currency.ticket_scaling}
                                </button>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div style={{ display: 'flex' }}>
                    <form style={{ width: '90%', height: '100%' }} className="form-group">
                        <div style={{ marginTop: 10, marginLeft: 15, height: 600 }} className="form-control">
                            {this.props.show_info.length > 1 ?
                                <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', width: 'auto', marginLeft: 20 }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: "rgb(1, 22, 48)" }} fontSize="small" />}
                                            checked={this.props.seperate_scaling ? false : true}
                                            disabled={this.props.edit ? false : true}
                                            color="default"
                                            onChange={this.props.seperate_scaling ? () => this.props.toggleSeperateValues("seperate_scaling", "scalingarray") : null}
                                        />
                                        <p style={{ fontSize: 10, color: '#000', marginTop: 15 }}>Combined Scaling</p>
                                    </div>
                                    <div style={{ display: 'flex', width: 'auto', marginLeft: 20 }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: "rgb(1, 22, 48)" }} fontSize="small" />}
                                            checked={this.props.seperate_scaling ? true : false}
                                            disabled={this.props.edit ? false : true}
                                            color="default"
                                            onChange={!this.props.seperate_scaling ? () => this.props.toggleSeperateValues("seperate_scaling", "scalingarray") : null}
                                        />
                                        <p style={{ fontSize: 10, color: '#000', marginTop: 15 }}>Separate Scaling</p>
                                    </div>
                                    {this.props.seperate_scaling ?
                                        <select style={{ width: 150, fontSize: 12, color: '#000', height: 28, marginLeft: 20 }} name="show_select" value={this.props.show_select} onChange={(e) => this.props.toggleDateSelect(e.target.value)}>
                                            {this.props.show_info.map((item, index) => {

                                                var firstItem = '';
                                                var lastItem = '';

                                                if (item.show_times[0]) {
                                                    var ft = item.show_times[0].time;

                                                    var FI = +ft.substr(0, 2);
                                                    var f = (FI % 12) || 12;
                                                    f = (f < 10) ? ("0" + f) : f;  // leading 0 at the left for 1 digit hours
                                                    var ampmF = FI < 12 ? " AM" : " PM";
                                                    firstItem = f + ft.substr(2, 3) + ampmF;

                                                    var lt = item.show_times[item.show_times.length - 1].time;
                                                    var LI = +lt.substr(0, 2);
                                                    var l = (LI % 12) || 12;
                                                    l = (l < 10) ? ("0" + l) : l;  // leading 0 at the left for 1 digit hours
                                                    var ampmL = LI < 12 ? " AM" : " PM";
                                                    lastItem = l + lt.substr(2, 3) + ampmL;
                                                }

                                                if (firstItem === "") {
                                                    firstItem = "TBA"
                                                }

                                                var date = moment(item.date).format('M/DD/YYYY');
                                                return (
                                                    <option value={item._id}>{date} ({firstItem})</option>
                                                )
                                            })}
                                        </select> : null}
                                    {this.props.edit && this.props.seperate_scaling && this.props.scaling_pin ?
                                        <button style={{ height: 18, width: 20, borderRadius: 6, marginLeft: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#cdcdcd", padding: 0 }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedScalingArray("scaling_pin", "scalingarray") : null}><FaUnlink style={{ padding: 1 }} size="12" color={"#000"} /></button> :
                                        this.props.edit && this.props.seperate_scaling && !this.props.scaling_pin ?
                                            <button style={{ height: 18, width: 20, borderRadius: 6, marginLeft: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#111", padding: 0 }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedScalingArray("scaling_pin", "scalingarray") : null}><FaLink style={{ padding: 1 }} size="12" color={"#cdcdcd"} /></button> : null}
                                </div> : null}
                            <table style={{ marginLeft: 20, width: '86%' }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '18%' }}><label style={{ fontSize: 11 }}>Ticket Type</label></th>
                                        <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Capacity</label></th>
                                        <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Comps</label></th>
                                        <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Kills</label></th>
                                        <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Price</label></th>
                                        <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Fac Fee</label></th>
                                        <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Add Fee</label></th>
                                        <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Add Fee</label></th>
                                        <th style={{ width: '8%' }}> </th>
                                    </tr>
                                </thead>
                                {this.props.edit ?
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '18%' }}><select className="form-control-sm" style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} type="text" name="tickettype1" value={this.props.tickettype1} onChange={this.props.onChange}>
                                                <option value=""> </option>
                                                <option value="GA Seated">GA Seated</option>
                                                <option value="GA Standing">GA Standing</option>
                                                <option value="Pit">Pit</option>
                                                <option value="Reserved">Reserved</option>
                                                <option value="VIP">VIP</option>
                                                <option value="Other">Other</option>
                                            </select></td>
                                            <td style={{ width: '11%' }}><input style={{ width: '100%', fontSize: 12, border: '1px solid #000' }}
                                                className="form-control-sm"
                                                type="number"
                                                onChange={this.props.onChange}
                                                value={this.props.ticketcap1}
                                                name="ticketcap1"></input></td>
                                            <td style={{ width: '11%' }}><input style={{ width: '100%', fontSize: 12, border: '1px solid #000' }}
                                                className="form-control-sm"
                                                type="number"
                                                onChange={this.props.onChange}
                                                value={this.props.ticketcomps1}
                                                name="ticketcomps1"></input></td>
                                            <td style={{ width: '11%' }}><input style={{ width: '100%', fontSize: 12, border: '1px solid #000' }}
                                                className="form-control-sm"
                                                type="number"
                                                onChange={this.props.onChange}
                                                value={this.props.ticketkills1}
                                                name="ticketkills1"></input></td>
                                            <td style={{ width: '11%' }}>
                                                <NumberFormat className="form-control-sm" style={{ width: '100%', fontSize: 12, border: '1px solid #000' }} value={this.props.ticketprice1} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onTP1ValueChange} />
                                            </td>
                                            <td style={{ width: '11%' }}>
                                                <NumberFormat className="form-control-sm" style={{ width: '100%', fontSize: 12, border: '1px solid #000' }} value={this.props.ticketff1} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onFF1ValueChange} /></td>
                                            <td style={{ width: '11%' }}>
                                                <NumberFormat className="form-control-sm" style={{ width: '100%', fontSize: 12, border: '1px solid #000' }} value={this.props.ticketaf1a} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onAF1aValueChange} /></td>
                                            <td style={{ width: '11%' }}>
                                                <NumberFormat className="form-control-sm" style={{ width: '100%', fontSize: 12, border: '1px solid #000' }} value={this.props.ticketaf1b} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onAF1bValueChange} /></td>
                                            <td style={{ width: '8%', fontSize: 13 }}><FaPlusSquare style={{ cursor: 'pointer' }} size="20" color="rgb(1, 22, 48)" onClick={this.props.addScaling} /></td>
                                        </tr>
                                    </tbody> : null}
                            </table>
                            <div style={{ marginLeft: 20, marginTop: 15, minHeight: 300, maxHeight: 'auto', width: '86%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#b3b9c1' }}>
                                {this.props.edit ?
                                    <div tabIndex={this.props.edit ? "0" : null} onBlur={this.props.edit ? (e) => this.props.handleClickOutside(e, 'scaling_edit') : null}>
                                        <TicketScalingMovable scalingarray={this.props.scalingarray} updateScaling={this.props.updateScaling} deleteScaling={this.deleteScaling} handleArrayUpdate={this.props.handleArrayUpdate} edit={this.props.edit} scaling_edit={this.props.scaling_edit} scaling_val={this.props.scaling_val} handleArrayFormattedChange={this.props.handleArrayFormattedChange} currency_symbol={currency_symbol} seperate_scaling={this.props.seperate_scaling} scaling_pin={this.props.scaling_pin} show_select={this.props.show_select} handlePinnedArray2Change={this.props.handlePinnedArray2Change} droppedCards={this.props.droppedCards} />
                                    </div> :
                                    this.props.seperate_scaling ?
                                        this.props.scalingarray.map((item, index) => {
                                            if (item.show_id === this.props.show_select) {
                                                return (
                                                    <div style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%' }}>
                                                        <table style={{ width: '100%' }}>

                                                            <tbody key={index}>
                                                                <tr>
                                                                    <td style={{ width: '18%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 15, paddingLeft: 5 }}>{item.type}</p></td>
                                                                    <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.capacity} displayType={'text'} thousandSeparator={true} /></td>
                                                                    <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.comps} displayType={'text'} thousandSeparator={true} /></td>
                                                                    <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.kills} displayType={'text'} thousandSeparator={true} /></td>
                                                                    <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.price} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>
                                                                    <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.fac_fee} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>
                                                                    <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.add_fee_A} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>
                                                                    <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.add_fee_B} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>
                                                                    <td style={{ width: '8%', fontSize: 13 }}> </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>)
                                            }
                                        }) :
                                        this.props.scalingarray.map((item, index) => {
                                            return (
                                                <div style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%' }}>
                                                    <table style={{ width: '100%' }}>

                                                        <tbody key={index}>
                                                            <tr>
                                                                <td style={{ width: '18%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 15, paddingLeft: 5 }}>{item.type}</p></td>
                                                                <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.capacity} displayType={'text'} thousandSeparator={true} /></td>
                                                                <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.comps} displayType={'text'} thousandSeparator={true} /></td>
                                                                <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.kills} displayType={'text'} thousandSeparator={true} /></td>
                                                                <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.price} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>
                                                                <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.fac_fee} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>
                                                                <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.add_fee_A} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>
                                                                <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, height: 60 }} value={item.add_fee_B} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></td>
                                                                <td style={{ width: '8%', fontSize: 13 }}> </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>)
                                        })}
                            </div>
                            <div className="form-group">
                                <label style={{ marginLeft: 20, marginTop: 15, fontSize: 12 }}>DOS Ticket Increase:</label>
                                <NumberFormat style={{ color: '#000', fontSize: 12, width: 100, marginLeft: 10 }} value={!this.props.dos_increase || this.props.dos_increase === 0 ? '' : this.props.dos_increase} displayType={this.props.edit ? null : 'text'} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onDOSChange} />
                            </div>
                            <div className="form-group">
                                <label style={{ marginLeft: 20, fontSize: 12 }}>Notes:</label>
                                <textarea
                                    className="form-control"
                                    style={{ minWidth: '80%', maxWidth: '80%', marginLeft: 20, maxHeight: 100, minHeight: 100, border: '.4px solid #000', fontSize: 12, backgroundColor: this.props.edit ? '#fff' : '#FAFAFA', color: '#000' }}
                                    disabled={this.props.edit ? false : true}
                                    onChange={this.props.onChange}
                                    value={this.props.ticketscalingnotes}
                                    name="ticketscalingnotes"
                                    type="textarea"
                                ></textarea>
                            </div>
                        </div>
                    </form>
                    <div style={{ width: '20%', marginRight: 20 }}>
                        {this.props.seperate_scaling && this.props.show_info.length > 1 ?
                            <div className="ticketScalingCalcBundle">
                                <div style={{ marginLeft: 10, width: 'auto', marginTop: 10 }}>
                                    <p style={{ color: '#cdcdcd', fontSize: 14, fontWeight: 'bold' }}>Select Show Totals </p>
                                </div>
                                <div style={{ display: 'flex', marginLeft: 30, height: 15, marginTop: this.props.seperate_scaling && this.props.show_info.length > 1 ? 0 : 20 }} className="form-group">
                                    <label style={{ color: '#cdcdcd', fontSize: 14 }}>Total Capacity: </label>
                                    <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat value={this.props.single_captotalval} displayType={'text'} thousandSeparator={true} /></label>
                                </div>
                                <div style={{ display: 'flex', marginLeft: 30, height: 15 }} className="form-group">
                                    <label style={{ color: '#cdcdcd', fontSize: 14 }}>Total Comps: </label>
                                    <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat value={this.props.single_compstotalval} displayType={'text'} thousandSeparator={true} /></label>
                                </div>
                                <div style={{ display: 'flex', marginLeft: 30, height: 15 }} className="form-group">
                                    <label style={{ color: '#cdcdcd', fontSize: 14 }}>Total Kills: </label>
                                    <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat value={this.props.single_killstotalval} displayType={'text'} thousandSeparator={true} /></label>
                                </div>
                                <div style={{ display: 'flex', marginLeft: 30, height: 15, paddingBottom: 30 }} className="form-group">
                                    <label style={{ color: '#cdcdcd', fontSize: 14 }}>Gross Potential: </label>
                                    <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat value={this.props.single_gross_pot_val} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></label>
                                </div>
                            </div> : null}
                        <div className="ticketScalingCalcBundle_combined">
                            {this.props.seperate_scaling && this.props.show_info.length > 1 ?
                                <div style={{ marginLeft: 10, width: 'auto', marginTop: 10 }}>
                                    <p style={{ color: '#cdcdcd', fontSize: 14, fontWeight: 'bold' }}>Combined Totals </p>
                                </div> : null}
                            <div style={{ display: 'flex', marginLeft: 30, height: 15, marginTop: this.props.seperate_scaling && this.props.show_info.length > 1 ? 0 : 20 }} className="form-group">
                                <label style={{ color: '#cdcdcd', fontSize: 14 }}>Total Capacity: </label>
                                <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat value={this.props.captotalval} displayType={'text'} thousandSeparator={true} /></label>
                            </div>
                            <div style={{ display: 'flex', marginLeft: 30, height: 15 }} className="form-group">
                                <label style={{ color: '#cdcdcd', fontSize: 14 }}>Total Comps: </label>
                                <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat value={this.props.compstotalval} displayType={'text'} thousandSeparator={true} /></label>
                            </div>
                            <div style={{ display: 'flex', marginLeft: 30, height: 15 }} className="form-group">
                                <label style={{ color: '#cdcdcd', fontSize: 14 }}>Total Kills: </label>
                                <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat value={this.props.killstotalval} displayType={'text'} thousandSeparator={true} /></label>
                            </div>
                            <div style={{ display: 'flex', marginLeft: 30, height: 15, paddingBottom: 30 }} className="form-group">
                                <label style={{ color: '#cdcdcd', fontSize: 14 }}>Gross Potential: </label>
                                <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat value={this.props.gross_pot_val} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {})(TicketScaling);