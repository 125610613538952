import React from "react";
import { confirmAlert } from 'react-confirm-alert'

export default function ConfirmDialog(msg, func) {
    console.log(msg, func)
    const addDialog = ({ onClose }) => {
        return (
            <div className="react-confirm-alert-z2">
                <div style={{ width: 400, height: 'auto', padding: 15 }}>
                    <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Alert:</h1>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {msg}
                    </div>
                    <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                        <button style={{ borderRadius: 10 }} onClick={() => { func(); onClose(); }}>Delete</button>
                        <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => onClose()}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }
    const options = {
        closeOnEscape: true,
        closeOnClickOutside: true,
        customUI: addDialog
    }
    confirmAlert(options)
}