import React, { Component } from "react";
import { connect } from 'react-redux';
import RichTextEditor from 'react-rte';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClientPermissionsDialog from '../../components/dialogs/client-permissions.dialog';
import { accountLookup, grantClientAccess, grantClientAccessNewAccount, saveClientPermissions, removeClientPermissions, checkAccountStatus, resetNewAccountToken } from '../../actions/accessActions';
import { getClientAccess } from '../../actions/clientActions';
import { FaPlusCircle, FaMinusCircle, FaEdit } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';

class ClientPermissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            add_on: false,
            add_email: '',
            add_access: 'full',
            add_permissions: 'full',
            client_access: [],
            client_permissions_dialog: false,
            client_permissions_data: {},
            loading: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const id = this.props.id;
        this.setState({
            loading: true
        })
        const vals = await this.props.getClientAccess(id);
        if (vals.length > 0) {
            const n = [];
            await Promise.all(vals.map(async (item) => {
                const data = {
                    tag: item.tag
                }
                const pendCheck = await this.props.checkAccountStatus(data);
                const d = {
                    ...item,
                    status: pendCheck._init,
                }
                n.push(d)
            }))
            console.log(n, "N")
            this.setState({
                client_access: n,
                loading: false
            })
        } else {
            this.setState({
                loading: false
            })
        }

        const socket = this.props.auth.socket;
        if (socket) {
            socket.on(`clients/update/permissions/${id}`, async (user) => {
                var ca = this.state.client_access;
                const ind = ca.findIndex(item => item.tag === user.tag);
                if (ind >= 0) {
                    ca[ind].permissions = user.permissions;
                } else {
                    const data = {
                        tag: user.tag
                    }
                    const pendCheck = await this.props.checkAccountStatus(data);
                    const d = {
                        ...user,
                        status: pendCheck._init,
                    }
                    ca = [...ca, d]
                }
                console.log(ca, "USER SOCKET")
                this.setState({
                    client_access: ca
                })
            })
            socket.on(`clients/remove/permissions/${id}`, (user) => {
                console.log(user);
                var ca = this.state.client_access;
                ca = ca.filter(item => item.tag !== user);
                console.log(ca, "USER SOCKET REMOVE")
                this.setState({
                    client_access: ca
                })
            })
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    toggleAdd = () => {
        this.setState({
            add_on: !this.state.add_on
        })
    }

    emailValidate = (e) => {
        if (e === false && this.state.add_email) {
            alert('Please enter a valid email address')
        }
    }

    addAccess = async () => {
        if (this.state.add_email) {
            const email = this.state.add_email;
            this.setState({
                client_permissions_data: { email: email, permissions: null, new: true },
                client_permissions_dialog: true,
                add_email: ''
            })
        } else {
            alert('Please enter a valid email address')
        }

    }

    togglePermissionsDialog = (item) => {
        if (item) {
            this.setState({
                client_permissions_data: item,
                client_permissions_dialog: true
            })
        } else {
            this.setState({
                client_permissions_data: {},
                client_permissions_dialog: false
            })
        }
    }

    saveClientPermissions = async (perm, tag, email) => {
        const data = {
            permissions: perm,
            client_id: this.props.id,
            email: email
        }
        this.setState({
            client_permissions_dialog: false
        })
        if (tag) {
            const status = await this.props.saveClientPermissions(data, tag);
            console.log(status, 'STATUS')
            if (status) {
                this.setState({
                    client_permissions_data: {},
                    client_permissions_dialog: false
                })
            } else {
                this.setState({
                    client_permissions_dialog: true
                })
                alert('Error')
            }
        } else {
            const account_exists = await this.props.accountLookup(email)
            console.log(account_exists)
            if (account_exists) {
                const grant_access = await this.props.grantClientAccess(data)
                console.log(grant_access, 'grant_access1')
                if (grant_access) {
                    this.setState({
                        client_permissions_data: {},
                        client_permissions_dialog: false
                    })
                } else {
                    this.setState({
                        client_permissions_dialog: true
                    })
                    alert('Error')
                }
            } else {
                this.createNewAccountDialog(data)
            }
        }
    }

    removeClientPermissions = async (tag, email) => {
        const data = {
            client_id: this.props.id,
            email: email
        }
        const status = await this.props.removeClientPermissions(data, tag);
        console.log(status, 'STATUS')
        if (status) {
            this.setState({
                client_permissions_data: {},
                client_permissions_dialog: false
            })
        } else {
            alert('Error')
        }
    }

    createNewAccountDialog = (data) => {
        const addDialog = ({ onClose }) => {
            return (
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '50%' }} className="react-confirm-alert-z2">
                        <div style={{ width: '100%', height: '100%', padding: 15 }}>
                            <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>New Account</h1>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                {data.email} does not have an account setup in the SolarSystem.  Would you like to send them an email to create an account?  Please make sure their email address is entered accurately.
                            </div>
                            <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                                <button style={{ borderRadius: 10 }} onClick={onClose}>Cancel</button>
                                <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { this.createNewAccount(data); onClose(); }}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }
        confirmAlert(options)
    }

    createNewAccount = async (data) => {
        const grant_access = await this.props.grantClientAccessNewAccount(data)
        console.log(grant_access, 'grant_access2')
        if (grant_access) {
            this.setState({
                client_permissions_data: {},

            })
        } else {
            this.setState({
                client_permissions_dialog: true
            })
            alert('Error')
        }
    }

    resetAccessToken = async (tag, email) => {
        const data = {
            client_id: this.props.id,
            tag: tag,
            email: email
        }
        this.setState({
            client_permissions_dialog: false
        })
        const status = await this.props.resetNewAccountToken(data, tag);
        console.log(status, 'STATUS')
        if (status) {
            this.setState({
                client_permissions_data: {},
                client_permissions_dialog: false
            })
        } else {
            this.setState({
                client_permissions_dialog: true
            })
            alert('Error')
        }
    }

    render() {

        return (
            <div style={{ width: '100%', height: 500 }}>
                <ClientPermissionsDialog open={this.state.client_permissions_dialog} data={this.state.client_permissions_data} save={this.saveClientPermissions} onClose={this.togglePermissionsDialog} removeAccess={this.removeClientPermissions} resetAccessToken={this.resetAccessToken} />
                <div style={{ marginLeft: 50, height: 'auto', width: '70%', marginTop: 20, backgroundColor: 'rgb(1, 22, 48)' }} className="form-control">
                    <div style={{ width: '100%', marginTop: 10, display: 'flex' }}>
                        {this.state.attachment_bar ?
                            <FaMinusCircle style={{ cursor: 'point' }} color="#cdcdcd" size={18} onClick={this.toggleAdd} /> :
                            <FaPlusCircle style={{ cursor: 'point' }} color="#cdcdcd" size={18} onClick={this.toggleAdd} />}
                        <h1 style={{ color: '#cdcdcd', fontSize: 14, fontWeight: 'bold', marginLeft: 10 }}>Grant New User Access:</h1>
                    </div>
                    {/* {this.state.add_on ? */}
                    <div style={{ width: '100%', display: 'flex', marginBottom: 20 }}>
                        <div style={{ width: '80%' }}>
                            <div style={{ marginTop: 15, display: 'flex', width: '100%', alignItems: 'center' }} className="form-group">
                                <label style={{ fontSize: 14, width: '25%', color: '#cdcdcd' }}>Email Address:</label>
                                <input style={{ width: '60%', fontSize: 12, borderRadius: 1, color: '#000' }} className="form-control-sm" type="text" value={this.state.add_email} name="add_email" onBlur={() => this.emailValidate(isValidEmailAddress(this.state.add_email))} onChange={this.handleChange} />
                                <span style={{ width: '15%', height: 'auto' }}>
                                    <button style={{ fontSize: 12, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }} type="button" className="btn btn-secondary px-5" onClick={this.addAccess}>Add</button>
                                </span>
                            </div>
                            {/* <div style={{ marginTop: 15, display: 'flex', width: '100%' }} className="form-group">
                                <label style={{ fontSize: 14, width: '25%', color: '#cdcdcd' }}>Access:</label>
                                <select style={{ width: '70%', fontSize: 12, borderRadius: 1, color: '#000' }} className="form-control-sm" value={this.state.add_access} name="add_access" onChange={this.handleChange}>
                                    <option value="full">All Deals</option>
                                    <option value="confirmed">Confirmed Only</option>
                                    <option value="pending">Confirmed and Pending Only</option>
                                </select>
                            </div>
                            <div style={{ marginTop: 15, display: 'flex', width: '100%' }} className="form-group">
                                <label style={{ fontSize: 14, width: '25%', color: '#cdcdcd' }}>Permissions:</label>
                                <select style={{ width: '70%', fontSize: 12, borderRadius: 1, color: '#000' }} className="form-control-sm" value={this.state.add_permissions} name="add_permissions" onChange={this.handleChange}>
                                    <option value="full">Full Access</option>
                                    <option value="nofinancials">No Financials</option>
                                </select>
                            </div> */}
                            {/* <div style={{ marginTop: 15, display: 'flex', width: '100%' }} className="form-group">
                                    <label style={{ fontSize: 14, width: '25%', color: '#cdcdcd' }}>Financials:</label>
                                    <span style={{ width: '70%', fontSize: 12, display: 'flex', alignItems: 'center' }} className="form-control-sm">
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#cdcdcd' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: '#cdcdcd' }} fontSize="small" />}
                                            // checked={this.props.pitch_links}
                                            color="default"
                                            // value={this.props.pitch_links}
                                            name="pitch_links"
                                            // onChange={this.props.handleCheckboxChange}
                                        />
                                    </span>
                                </div> */}
                        </div>
                        {/* <div style={{ width: '20%' }}>
                            <span style={{ width: '100%', height: 'auto' }}>
                                <button style={{ fontSize: 12, height: 25, marginTop: 140, display: 'flex', alignItems: 'center', justifyContent: 'center' }} type="button" className="btn btn-secondary px-5" onClick={this.addAccess}>Add</button>
                            </span>
                        </div> */}
                    </div>
                    {/* : null} */}
                </div>
                <div style={{ marginTop: 20, width: '100%' }}>
                    <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                        <thead>
                            <tr>
                                <th style={{ width: '25%', border: '1px solid rgb(1, 22, 48)', color: '#fff' }}>Email</th>
                                <th style={{ width: '25%', border: '1px solid rgb(1, 22, 48)', color: '#fff' }}>Name</th>
                                <th style={{ width: '40%', border: '1px solid rgb(1, 22, 48)', color: '#fff' }}>Permissions</th>
                                <th style={{ width: '10%', border: '1px solid rgb(1, 22, 48)', color: '#fff' }}> </th>
                            </tr>
                        </thead>
                    </table>
                    <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="client_permissions_table">
                        {this.state.loading ?
                            <tbody>
                                <tr>
                                    <td style={{ width: '100%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>Loading...</td>
                                </tr>
                            </tbody> :
                            <tbody>
                                {this.state.client_access.map((item, index) => {
                                    return (
                                        <tr>
                                            <td style={{ width: '25%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>{item.email}</td>
                                            <td style={{ width: '25%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}><div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}><span>{item.name.first} {item.name.last}</span>{!item.status ? <span style={{ fontStyle: 'italic' }}>(Pending Account)</span> : ''}</div></td>
                                            <td style={{ width: '40%', border: '1px solid rgb(1, 22, 48)', color: '#000', fontSize: 10, fontStyle: 'italic', overflow: 'clip' }}>offers: {item.permissions.offers.map((o, i) => { if (i + 1 < item.permissions.offers.length) { return o + ", " } else { return o } })} | financials: {item.permissions.financials ? 'true' : 'false'} | edit basic info: {item.permissions.edit_client_basic ? 'true' : 'false'} | view ticket counts: {item.permissions.ticket_counts ? 'true' : 'false'} | edit pitch: {item.permissions.edit_client_pitch ? 'true' : 'false'} | view attachments: {item.permissions.client_attachments ? 'true' : 'false'} | upload attachments: {item.permissions.upload_client_attachments ? 'true' : 'false'}</td>
                                            <td style={{ width: '10%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}><span style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}><FaEdit size="18" color="#333" onClick={() => this.togglePermissionsDialog(item)} /></span></td>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                    </table>
                </div>
            </div>
        );
    }
}

function isValidEmailAddress(address) {
    return !!address.match(/.+@.+/);
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { accountLookup, grantClientAccess, grantClientAccessNewAccount, getClientAccess, saveClientPermissions, removeClientPermissions, checkAccountStatus, resetNewAccountToken })(ClientPermissions);