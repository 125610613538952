import axios from "axios";
import { tokenConfig, tokenConfigFile } from './authActions';
import { returnErrors } from './errorActions';
import {
    OFFERS_LOADING,
    OFFERDETAIL_LOADING,
    OFFERS_GET,
    OFFERS_DB_INIT,
    OFFERS_DASH_SET,
    OFFERS_CAL_SET,
    GET_OFFERDETAIL,
    OFFERS_FILTER,
    OFFERS_SORT,
    OFFERS_SOCKET,
    OFFERS_SOCKET_DELETE,
    NOTIFICATIONS
} from "./types";
import _ from 'lodash';
import moment from 'moment';

export const setOffersLoading = () => {
    return {
        type: OFFERS_LOADING
    };
};

export const setOfferDetailLoading = () => {
    return {
        type: OFFERDETAIL_LOADING
    };
};

function transpose2(item, getState){
    const data = {
        data: null
    }
    return axios.post(`${process.env.REACT_APP_API}/offers/transpose/${item._id}`, data, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => { return err.response.data })
}

// async function transpose(item, getState) {
//     const data = {
//         data: item
//     }
//     await axios.post(`${process.env.REACT_APP_API}/offers/update/transpose/${item._id}`, data, tokenConfig(getState))
//         .then(res => {})
//         .catch(err => {})
// }

// async function getAllDetail(oData, getState) {
//     await axios.get(`${process.env.REACT_APP_API}/offerdetail/`, tokenConfig(getState))
//         .then(res => {
//             var dataDetail = res.data;
//             // const results = dataDetail.filter(({ _id: id1 }) => !oData.some(({ _id: id2 }) => id2 === id1));

//             // dataDetail.map(async (item, index) => {
//             //     delete item.production_contact;
//             //     delete item.deposits2;

//             //     const data = {
//             //         ...item
//             //     }

//             //     await axios.post(`${process.env.REACT_APP_API}/offerdetail/update/${item._id}`, data, tokenConfig(getState))
//             //         .then(res => {})
//             //         .catch(err => {})
//             // })
//         })
//         .catch(err => {})
// }

// async function transposeDetail(array, getState) {
//     array.map(async (item, index) => {

//         const data = {
//             data: item
//         }
//         await axios.post(`${process.env.REACT_APP_API}/offers/update/transpose/${item._id}`, data, tokenConfig(getState))
//             .then(res => {})
//             .catch(err => {})
//     })
// }

export const getOffers = () => async (dispatch, getState) => {
    dispatch(setOffersLoading());
    await axios.get(`${process.env.REACT_APP_API}/offers/`, tokenConfig(getState))
        .then(async (res) => {
            // var newArray = [];
            // res.data.map((item, index) => {
            //     var ac = item.contacts.add_contacts;
            //     if (item.contacts.production_contact && Object.keys(item.contacts.production_contact).length > 0 && item.contacts.production_contact.contact_id) {
            //         var nc = item.contacts.production_contact;
            //         nc.role = 'Production Manager';
            //         ac = [...item.contacts.add_contacts, nc];
            //     }
            //     if (item.contacts.marketing_contact && Object.keys(item.contacts.marketing_contact).length > 0 && item.contacts.marketing_contact.contact_id) {
            //         var nc = item.contacts.marketing_contact;
            //         nc.role = 'Marketing Manager';
            //         ac = [...item.contacts.add_contacts, nc];
            //     }
            //     if (item.contacts.counts_contact && Object.keys(item.contacts.counts_contact).length > 0 && item.contacts.counts_contact.contact_id) {
            //         var nc = item.contacts.counts_contact;
            //         nc.role = 'Ticket Count Contact';
            //         ac = [...item.contacts.add_contacts, nc];
            //     }
            //     var contacts = {
            //         signatory: item.signatory_id || '',
            //         buyer_contact: item.buyer_contact_id || '',
            //         add_contacts: ac
            //     }
            //     item.contacts = contacts;
            //     delete item.signatory_id;
            //     delete item.buyer_contact_id;

            //     newArray.push(item)
            // })
            // res.data.map(async (item, index) => {
            //     // const nd = await getOfferDetail(item._id);
            //     const nd = await axios.get(`${process.env.REACT_APP_API}/offerdetail/${item._id}`, tokenConfig(getState))
            //         .then(res => {
            //             //dispatch({
            //             //    type: GET_OFFERDETAIL,
            //             //    payload: res.data
            //             //})
            //             return res.data
            //         })
            //         .catch(err => {
            //             //dispatch(returnErrors(err.response.data, err.response.status))
            //             return err.response.data
            //         });
            //     var prog = item.prog;
            //     if (nd && nd.deposits) {
            //         var depo = nd.deposits.deposits;
            //         prog.d_due = depo;
            //         if (depo.length <= 0) {
            //             prog.d_rec = true;
            //         };
            //     } else {
            //         prog.d_due = [];
            //     };
            //     item.prog = prog;
            //     await transpose(item, getState);

            // })
            // res.data.forEach(async (item) => {
            //     const status = await transpose(item, getState);
            //     console.log(status, 'status')
            // })

            // const data = res.data;
            // getAllDetail(data, getState);

            
            // for (const item of res.data){
            //     const res = await transpose2(item, getState);
            //     console.log(res)
            // }

            dispatch({
                type: OFFERS_GET,
                payload: res.data
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const getOfferDetail = id => (dispatch, getState) => {
    //dispatch(setOfferDetailLoading());
    return axios.get(`${process.env.REACT_APP_API}/offerdetail/${id}`, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: GET_OFFERDETAIL,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};

export const applyOfferFilter = (offer_filter) => (dispatch, getState) => {
    dispatch(setOffersLoading());
    dispatch({
        type: OFFERS_FILTER,
        payload: offer_filter
    })
};

export const offerSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: OFFERS_SORT,
        payload: sort
    })
};

export const addOffer = (full) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/offers/add`, full, tokenConfig(getState))
        .then(res => {
            // var offerid = {
            //     ...offerdetail,
            //     _id: res.data
            // }
            // axios.post(`http://54.213.249.65:5000/offerdetail/add`, offerid, tokenConfig(getState))
            return res.data
        })
        .catch(err => {
            return err.response.data
        });

}

export const updateOfferFull = (full, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/offers/full/update/${id}`, full, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
    // return axios.post(`http://54.213.249.65:5000/offerdetail/update/${id}`, offerdetail, tokenConfig(getState))
    //     .then(res => {
    //         return res.data
    //     })
    //     .catch(err => {
    //         //dispatch(returnErrors(err.response.data, err.response.status))
    //         return err.response.data
    //     });
}

export const updateOffer = (full, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/offers/update/${id}`, full, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

export const updateOfferStatus = (status, id) => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API}/offers/update/status/${id}`, status, tokenConfig(getState))
        .then(res => {

        })
        .catch(err => {

        });
    axios.post(`${process.env.REACT_APP_API}/offerdetail/update/status/${id}`, status, tokenConfig(getState))
        .then(res => {

        })
        .catch(err => {

        });

}

export const dbContractIssued = (data, id) => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API}/offers/update/tracking/k_issued/${id}`, data, tokenConfig(getState))
        .then(res => {

        })
        .catch(err => {

        });

}

export const dbContractReceived = (data, id) => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API}/offers/update/tracking/k_received/${id}`, data, tokenConfig(getState))
        .then(res => {

        })
        .catch(err => {

        });

}

export const dbDepositReceived = (data, id) => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API}/offers/update/tracking/dep_received/${id}`, data, tokenConfig(getState))
        .then(res => {

        })
        .catch(err => {

        });

}

// export const updateOfferDate = (dates, id) => (dispatch, getState) => {
//     axios.post(`${process.env.REACT_APP_API}/offers/update/dates/${id}`, dates, tokenConfig(getState))
//         .then(res => {

//         })
//         .catch(err => {

//         });
//     axios.post(`${process.env.REACT_APP_API}/offerdetail/update/dates/${id}`, dates, tokenConfig(getState))
//         .then(res => {

//         })
//         .catch(err => {

//         });

// }

// export const addOfferSocket = offer => (dispatch, getState) => {
//     const { items } = getState();
//     const { buyers } = getState();
//     const { clients } = getState();
//     const { festivals } = getState();
//     const venues = items;

//     var artist_name = "";
//     var venue_name = "";
//     var venue_address = {};
//     var venue_city = "";
//     var venue_state = "";
//     var venue_country = "";
//     var buyer_company = "";

//     clients.clients.map((c, i) => {
//         if (offer.client_id === c._id) {
//             artist_name = c.client
//         }
//     })
//     venues.items.map((v, i) => {
//         if (offer.venue_id === v._id) {
//             venue_name = v.venue_name;
//             venue_address = v.address;
//             venue_city = v.address.city;
//             venue_state = v.address.state;
//             venue_country = v.address.country;
//         }
//     })
//     festivals.items.map((f, i) => {
//         if (offer.venue_id === f._id) {
//             venue_name = f.festival;
//             venue_address = f.address;
//             venue_city = f.address.city;
//             venue_state = f.address.state;
//             venue_country = f.address.country;
//         }
//     })
//     buyers.buyers.map((b, i) => {
//         if (offer.buyer_id === b._id) {
//             buyer_company = b.company
//         }
//     })

//     const newOffer = {
//         ...offer,
//         artist_name: artist_name,
//         venue_name: venue_name,
//         venue_address: venue_address,
//         venue_city: venue_city,
//         venue_state: venue_state,
//         venue_country: venue_country,
//         buyer_company: buyer_company,
//     }

//     dispatch({
//         type: SADD_OFFER,
//         payload: newOffer
//     })
// }

// export const updateOfferSocket = offer => (dispatch, getState) => {
//     const { items } = getState();
//     const { buyers } = getState();
//     const { clients } = getState();
//     const { festivals } = getState();
//     const venues = items;

//     var artist_name = "";
//     var venue_name = "";
//     var venue_address = {};
//     var venue_city = "";
//     var venue_state = "";
//     var venue_country = "";
//     var buyer_company = "";

//     clients.clients.map((c, i) => {
//         if (offer.client_id === c._id) {
//             artist_name = c.client
//         }
//     })
//     venues.items.map((v, i) => {
//         if (offer.venue_id === v._id) {
//             venue_name = v.venue_name;
//             venue_address = v.address;
//             venue_city = v.address.city;
//             venue_state = v.address.state;
//             venue_country = v.address.country;
//         }
//     })
//     festivals.items.map((f, i) => {
//         if (offer.venue_id === f._id) {
//             venue_name = f.festival;
//             venue_address = f.address;
//             venue_city = f.address.city;
//             venue_state = f.address.state;
//             venue_country = f.address.country;
//         }
//     })
//     buyers.buyers.map((b, i) => {
//         if (offer.buyer_id === b._id) {
//             buyer_company = b.company
//         }
//     })

//     const newOffer = {
//         ...offer,
//         artist_name: artist_name,
//         venue_name: venue_name,
//         venue_address: venue_address,
//         venue_city: venue_city,
//         venue_state: venue_state,
//         venue_country: venue_country,
//         buyer_company: buyer_company,
//     }

//     dispatch({
//         type: UPDATE_OFFER,
//         payload: newOffer
//     })
// }

export const offerSocket = offer => (dispatch, getState) => {
    const { items } = getState();
    const { buyers } = getState();
    const { clients } = getState();
    const { festivals } = getState();
    const venues = items;

    var artist_name = "";
    var venue_name = "";
    var venue_address = {};
    var venue_city = "";
    var venue_state = "";
    var venue_country = "";
    var buyer_company = "";
    var buyer_address = {};

    clients.clients.map((c, i) => {
        if (offer.client_id === c._id) {
            artist_name = c.client
        }
    })
    venues.items.map((v, i) => {
        if (offer.venue_id === v._id) {
            venue_name = v.venue_name;
            venue_address = v.address;
            venue_city = v.address.city;
            venue_state = v.address.state;
            venue_country = v.address.country;
        }
    })
    festivals.items.map((f, i) => {
        if (offer.venue_id === f._id) {
            venue_name = f.festival;
            venue_address = f.address;
            venue_city = f.address.city;
            venue_state = f.address.state;
            venue_country = f.address.country;
        }
    })
    buyers.buyers.map((b, i) => {
        if (offer.buyer_id === b._id) {
            buyer_company = b.company;
            buyer_address = b.address;
        }
    })

    const newOffer = {
        ...offer,
        artist_name: artist_name,
        venue_name: venue_name,
        venue_address: venue_address,
        venue_city: venue_city,
        venue_state: venue_state,
        venue_country: venue_country,
        buyer_company: buyer_company,
        buyer_address: buyer_address
    }
    console.log(newOffer)

    dispatch({
        type: OFFERS_SOCKET,
        payload: newOffer
    })
}

export const offerCompose = offer => (dispatch, getState) => {
    const { items } = getState();
    const { buyers } = getState();
    const { clients } = getState();
    const { festivals } = getState();
    const venues = items;

    var artist_name = "";
    var venue_name = "";
    var venue_address = {};
    var venue_city = "";
    var venue_state = "";
    var venue_country = "";
    var buyer_company = "";
    var buyer_address = {};

    const cInd = clients.clients.findIndex(c => c._id === offer.client_id);
    artist_name = clients.clients[cInd].client;

    // clients.clients.map((c, i) => {
    //     if (offer.client_id === c._id) {
    //         artist_name = c.client
    //     }
    // })

    const vInd = venues.items.findIndex(v => v._id === offer.venue_id);
    venue_name = venues.items[vInd].venue_name;
    venue_address = venues.items[vInd].address;
    venue_city = venues.items[vInd].address.city;
    venue_state = venues.items[vInd].address.state;
    venue_country = venues.items[vInd].address.country;

    // venues.items.map((v, i) => {
    //     if (offer.venue_id === v._id) {
    //         venue_name = v.venue_name;
    //         venue_address = v.address;
    //         venue_city = v.address.city;
    //         venue_state = v.address.state;
    //         venue_country = v.address.country;
    //     }
    // })
    festivals.items.map((f, i) => {
        if (offer.venue_id === f._id) {
            venue_name = f.festival;
            venue_address = f.address;
            venue_city = f.address.city;
            venue_state = f.address.state;
            venue_country = f.address.country;
        }
    })
    buyers.buyers.map((b, i) => {
        if (offer.buyer_id === b._id) {
            buyer_company = b.company;
            buyer_address = b.address;
        }
    })

    const newOffer = {
        ...offer,
        artist_name: artist_name,
        venue_name: venue_name,
        venue_address: venue_address,
        venue_city: venue_city,
        venue_state: venue_state,
        venue_country: venue_country,
        buyer_company: buyer_company,
        buyer_address: buyer_address
    }

    return newOffer
}

// export const updateOfferStatusSocket = offer => (dispatch, getState) => {

//     const { items } = getState();
//     const { buyers } = getState();
//     const { clients } = getState();
//     const { festivals } = getState();
//     const venues = items;

//     var artist_name = "";
//     var venue_name = "";
//     var venue_address = {};
//     var venue_city = "";
//     var venue_state = "";
//     var venue_country = "";
//     var buyer_company = "";

//     clients.clients.map((c, i) => {
//         if (offer.client_id === c._id) {
//             artist_name = c.client
//         }
//     })
//     venues.items.map((v, i) => {
//         if (offer.venue_id === v._id) {
//             venue_name = v.venue_name;
//             venue_address = v.address;
//             venue_city = v.address.city;
//             venue_state = v.address.state;
//             venue_country = v.address.country;
//         }
//     })
//     festivals.items.map((f, i) => {
//         if (offer.venue_id === f._id) {
//             venue_name = f.festival;
//             venue_address = f.address;
//             venue_city = f.address.city;
//             venue_state = f.address.state;
//             venue_country = f.address.country;
//         }
//     })
//     buyers.buyers.map((b, i) => {
//         if (offer.buyer_id === b._id) {
//             buyer_company = b.company
//         }
//     })

//     const newOffer = {
//         ...offer,
//         artist_name: artist_name,
//         venue_name: venue_name,
//         venue_address: venue_address,
//         venue_city: venue_city,
//         venue_state: venue_state,
//         venue_country: venue_country,
//         buyer_company: buyer_company,
//     }

//     dispatch({
//         type: SUO_OFFER,
//         payload: newOffer
//     })
// }

export const deleteOffer = id => (dispatch, getState) => {
    return axios.delete(`${process.env.REACT_APP_API}/offers/${id}`, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const deleteOfferSocket = offer => (dispatch) => {
    dispatch({
        type: OFFERS_SOCKET_DELETE,
        payload: offer
    })
}

export const deleteOfferDetail = id => (dispatch, getState) => {
    return axios.delete(`${process.env.REACT_APP_API}/offerdetail/${id}`, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const getOfferDetailItinerary = ids => (dispatch, getState) => {
    dispatch(setOfferDetailLoading());
    axios.post(`${process.env.REACT_APP_API}/offerdetail/itinerary`, ids, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: GET_OFFERDETAIL,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const offerCombine = (from) => async (dispatch, getState) => {
    dispatch(setOffersLoading());

    const { offers } = getState();
    const { items } = getState();
    const { buyers } = getState();
    const { clients } = getState();
    const { festivals } = getState();
    const venues = items;

    // var newArray = [];
    const newArray = await offers.offers.map((item, index) => {
        var artist_name = "";
        var venue_address = {};
        var venue_name = "";
        var venue_city = "";
        var venue_state = "";
        var venue_country = "";
        var buyer_company = "";
        var buyer_address = {};

        var cli_ind = clients.clients.findIndex(c => item.client_id === c._id)
        const cli_single = clients.clients[cli_ind];
        if (cli_ind >= 0) {
            artist_name = cli_single.client
        }
       
        var ven_ind = venues.items.findIndex(v => item.venue_id === v._id)
        const ven_single = venues.items[ven_ind];
        if (ven_ind >= 0) {
            venue_name = ven_single.venue_name;
            venue_city = ven_single.address.city;
            venue_state = ven_single.address.state;
            venue_country = ven_single.address.country;
            venue_address = ven_single.address;
        }

        var fest_ind = festivals.items.findIndex(f => item.venue_id === f._id);
        const fest_single = festivals.items[fest_ind];
        if (fest_ind >= 0) {
            venue_name = fest_single.festival;
            venue_city = fest_single.address.city;
            venue_state = fest_single.address.state;
            venue_country = fest_single.address.country;
            venue_address = fest_single.address;
        }

        var buy_ind = buyers.buyers.findIndex(b => item.buyer_id === b._id);
        const buy_single = buyers.buyers[buy_ind];
        if (buy_ind >= 0) {
            buyer_company = buy_single.company;
            buyer_address = buy_single.address;
        }

        const items = {
            ...item,
            artist_name: artist_name,
            venue_name: venue_name,
            venue_address: venue_address,
            venue_city: venue_city,
            venue_state: venue_state,
            venue_country: venue_country,
            buyer_company: buyer_company,
            buyer_address: buyer_address
        }
        return items
        // newArray = [...newArray, items]
    })

    // const dashboard = await dashFunc(newArray)
    // const database = await databaseFunc(newArray);
    // var database = _.sortBy(newArray, function (dateObj) {
    //     return new Date(dateObj.show_info[0].date);
    // })

    // const passData = {
    //     dashboard: newArray,
    //     database: database,
    //     pay: newArray
    // }

    // dispatch({
    //     type: OFFERS_DB_INIT,
    //     payload: passData
    // })

    // const arr = JSON.parse(JSON.stringify(newArray))
    return newArray
};

export const offerCombineFinal = (array) => async (dispatch, getState) => {
    dispatch(setOffersLoading());

    var end_date = moment().add(1, 'years').format('MM/DD/YYYY');
    var start_date = moment().add(-1, 'days').format('MM/DD/YYYY');

    const dashFunc = (list) => {
        return new Promise((resolve, reject) => {
            var dash = _.orderBy(list, ['artist_name'], ['asc']);
            resolve(dash)
        })
    }
    const databaseFunc = (list) => {
        return new Promise((resolve, reject) => {
            var db = _.sortBy(list, function (dateObj) {
                return new Date(dateObj.show_info[0].date);
            }).filter((it) => {
                var filterBatch = it.status
                return filterBatch.indexOf('Confirmed') >= 0
            }).filter((it) => {
                const today = moment();
                var val = it.show_info;
                // const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                const closest = val[0];
                var filterBatch = new Date(closest.date)
                var start = new Date(start_date)
                return filterBatch > start
            })
            // .filter((it) => {
            //     const today = new Date();
            //     var val = it.show_info;
            //     // const furthest = await val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            //     const furthest = val[0];
            //     var filterBatch = new Date(furthest.date)
            //     var end = new Date(end_date)
            //     return filterBatch <= end
            // })
            resolve(db)
        })
    }

    console.log(array, 'ARRAY')
    const dashboard = await dashFunc(array)
    const database = await databaseFunc(array);
    // var database = _.sortBy(newArray, function (dateObj) {
    //     return new Date(dateObj.show_info[0].date);
    // })

    console.log(database, 'DATABASE')

    const passData = {
        dashboard: dashboard,
        database: database,
        pay: array
    }

    dispatch({
        type: OFFERS_DB_INIT,
        payload: passData
    })

    return false
};

export const setOffersCal = () => async (dispatch, getState) => {
    dispatch(setOffersLoading());
    dispatch({
        type: OFFERS_CAL_SET,
        payload: 'success'
    })
};

export const setDashboard = () => async (dispatch, getState) => {
    dispatch(setOffersLoading());
    dispatch({
        type: OFFERS_DASH_SET,
        payload: 'success'
    })
};

export const uploadAttachmentOffer = (file, name, key, type, notes, date, user, id) => async (dispatch, getState) => {


    // formData.set('key', key);
    // formData.set('type', type);
    // formData.set('notes', notes);

    const info = [{
        name: name,
        key: key,
        type: type,
        notes: notes,
        user: user,
        date: date
    }]

    console.log('INFO', info)
    const json = JSON.stringify(info);
    const blob = new Blob([json], {
        type: 'application/json'
    });

    var formData = new FormData();
    formData.append('file', file);
    formData.set('info', blob);

    let data = {};

    // convert the key/value pairs
    for (var pair of formData.entries()) {
        data[pair[0]] = pair[1];
    }

    // const headerObj = await tokenConfigFile(getState);
    // const token = getState().auth.token;

    // const request_config = {
    //     method: "post",
    //     url: `${process.env.REACT_APP_API}/offerdetail/attachment/upload/${id}`,
    //     headers: {
    //         "Content-type": "multipart/form-data",
    //         "x-auth-token": token,
    //     },
    //     data: formData
    //   };

    return axios.post(`${process.env.REACT_APP_API}/offerdetail/attachment/upload/${id}`, formData, tokenConfigFile(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });

    // return axios.post(`${process.env.REACT_APP_API}/offerdetail/attachment/upload/${id}`, { data: file }, tokenConfigFile(getState))
    //     .then(res => {
    //         return res.data
    //     })
    //     .catch(err => {
    //         return err.response.data
    //     });

    // return axios.post(`${process.env.REACT_APP_API}/offers/update/${id}`, full, tokenConfig(getState))
    //     .then(res => {
    //         return res.data
    //     })
    //     .catch(err => {
    //         return err.response.data
    //     });


    // return axios.post(`http://54.213.249.65:5000/offerdetail/update/${id}`, offerdetail, tokenConfig(getState))
    //     .then(res => {
    //         return res.data
    //     })
    //     .catch(err => {
    //         //dispatch(returnErrors(err.response.data, err.response.status))
    //         return err.response.data
    //     });
}

export const downloadAttachmentOffer = (data) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/offerdetail/attachment/download`, data, tokenConfig(getState))
        .then(res => {

            var a = document.createElement("a"); //Create <a>
            a.href = res.data.file; //Image Base64 Goes here
            a.download = res.data.title; //File name Here
            a.click(); //Downloaded file

            return "Success"
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const deleteAttachmentOffer = (data, deal_id) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/offerdetail/attachment/delete/${deal_id}`, data, tokenConfig(getState))
        .then(res => {
            return "Success"
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const offerDupCheck = (dupCheck) => async (dispatch, getState) => {
    const offers = getState().offers.offers;
    var check = [];
    offers.map(item => {
        if (item.client_id === dupCheck.client_id && item.venue_id === dupCheck.venue_id) {
            const today = moment();
            var val = _.get(item, 'show_info')
            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            // const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            var dupVal = _.get(dupCheck, 'show_info')
            const dupClosest = dupVal.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            // const dupFurthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            if (closest.date === dupClosest.date) {
                // check.push(item);
                check = [...check, item]
            }
        }
    })
    return check
}

export const getFinalsAll = () => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/offerdetail/finals`, null, tokenConfig(getState))
        .then(res => {
            if (res.data) {
                return res.data
            } else {
                return null
            }
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};

export const addFinalsBulk = data => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API}/offers/finals/add/bulk`, data, tokenConfig(getState))
        .then(res => {

        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
}