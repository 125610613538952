import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap';
import { FaFilter, FaSearch, FaBars, FaCalendarAlt, FaDatabase, FaPlaceOfWorship, FaShoppingCart, FaCalendar, FaExclamationCircle, FaGlobe, FaSignOutAlt } from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchbarAutocomplete from '../autocompletes/searchbar-autocomplete';
import UserAvatar from './user.avatar';
import ChatButtonNav from './navbar-chat.button';
import TasksButtonNav from './navbar-tasks.button';
import DatabaseIcons from '../offerdatabase/database-icons';
import ReactTooltip from 'react-tooltip';
import Logout from '../auth/Logout';

import 'bootstrap/dist/css/bootstrap.css';

const NavBar = (props) => {

    const [isSidebar, setIsSidebar] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const toggle = () => setIsOpen(!isOpen);
    const toggleSidebar = () => props.onSetSidebarOpen(isSidebar);

    const handleToolTip = () => {
        // const description = info.event._def.extendedProps.description
        // info.el.setAttribute("data-tip", description);
        ReactTooltip.rebuild();
    }

    return (
        <div>
            <ReactTooltip id="nav" multiline={true} clickable={false} delayHide={300} effect='solid' border={false} >
            </ReactTooltip>
            <Navbar style={{ backgroundColor: '#111111', borderBottom: '1px solid #333333' }} color="black" light expand="md">
                <NavbarText style={{ cursor: 'pointer', marginRight: 20, marginLeft: 6 }} onClick={props.sidebarOpen ? props.onCloseSidebar : toggleSidebar}><FaBars className="nav-button" size="19" /></NavbarText>
                <NavbarBrand style={{ color: '#cdcdcd', fontWeight: 'bold', width: 'auto' }}>{props.user.company.company.toUpperCase()}</NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>

                    </Nav> 
                    <NavbarText style={{ cursor: 'pointer', marginRight: 6 }} onClick={() => props.toggle('cal_tooltip')} data-tip="Tooltip" data-for='nav'><FaExclamationCircle style={{ marginLeft: 25 }} className="nav-button" size="19" color={props.cal_tooltip ? '#2ea1ff' : null} onMouseEnter={handleToolTip} /></NavbarText>
                    {/* <NavbarText style={{ cursor: 'pointer', marginRight: 6 }} onClick={() => props.toggle('ss_modal')} data-tip="Shared Network" data-for='nav'><FaGlobe style={{ marginLeft: 25 }} className="nav-button" size="22" color={props.ss_network ? '#2ea1ff' : null} onMouseEnter={handleToolTip} /></NavbarText> */}
                    <NavbarText style={{ cursor: 'pointer', marginRight: 6, marginLeft: 25 }}><SearchbarAutocomplete clientList={props.clients} venueList={props.venues} buyerList={props.buyers} onChange={props.handleSearchbarChange} clientSelect={props.artistbatch} /></NavbarText>
                    {/* <NavbarText style={{ cursor: 'pointer', marginRight: 6, marginLeft: 10 }} data-tip="Tasks" data-for='nav'><TasksButtonNav onMouseEnter={handleToolTip} /></NavbarText> */}
                    <NavbarText style={{ cursor: 'pointer', marginLeft: 10 }} data-tip="Chat" data-for='nav'><ChatButtonNav toggleSpaceChat={props.toggleSpaceChat} onMouseEnter={handleToolTip} /></NavbarText>
                    <UncontrolledDropdown>
                        <DropdownToggle style={{ cursor: 'pointer', marginRight: 15 }} nav><UserAvatar user={props.user} /></DropdownToggle>
                        <DropdownMenu style={{ border: '2px solid #333333', backgroundColor: '#000', marginLeft: -20, width: 80 }} right>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={props.logout}><FaSignOutAlt size={12} color="#cdcdcd" />  Logout</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Collapse>
            </Navbar>
        </div>
    );
}
//<NavbarText style={{ cursor: 'pointer', marginRight: 6 }}><input className="searchbar" type="text" placeholder="Search"></input><FaSearch style={{ marginLeft: 25 }} className="nav-button" size="19" /></NavbarText>

export default NavBar;