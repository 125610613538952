import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    BUYERS_INIT_LOADING,
    BUYERS_LOADING,
    BUYERS_GET,
    BUYERS_FILTER,
    BUYERS_SORT,
    BUYERS_SOCKET,
    BUYERS_SOCKET_DELETE,
    NOTIFICATIONS
} from "./types";

export const setBuyersLoading = () => {
    return {
        type: BUYERS_LOADING
    };
};

export const setBuyersInitLoading = () => {
    return {
        type: BUYERS_INIT_LOADING
    };
};

export const getBuyers = () => async (dispatch, getState) => {
    dispatch(setBuyersInitLoading());
    await axios.get(`${process.env.REACT_APP_API}/buyers/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: BUYERS_GET,
                payload: res.data
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const applyBuyerFilter = (buyer_filter) => (dispatch, getState) => {
    dispatch(setBuyersLoading());
    dispatch({
        type: BUYERS_FILTER,
        payload: buyer_filter
    })
};

export const buyerSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: BUYERS_SORT,
        payload: sort
    })
};

export const addBuyer = buyer => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/buyers/add`, buyer, tokenConfig(getState))
        .then(res => {
            dispatch({
               type: NOTIFICATIONS,
               payload: res.data
            })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const addBuyerSocket = buyer => (dispatch) => {
//     dispatch({
//         type: SADD_BUYER,
//         payload: buyer
//     })
// }

export const updateBuyer = (buyer, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/buyers/update/${id}`, buyer, tokenConfig(getState))
        .then(res => {
            dispatch({
               type: NOTIFICATIONS,
               payload: res.data
            })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const updateBuyerSocket = buyer => (dispatch) => {
//     dispatch({
//         type: UPDATE_BUYER_SOCKET,
//         payload: buyer
//     })
// }

export const buyerSocket = buyer => (dispatch) => {
    dispatch({
        type: BUYERS_SOCKET,
        payload: buyer
    })
}

export const buyerSocketDelete = buyer => (dispatch) => {
    dispatch({
        type: BUYERS_SOCKET_DELETE,
        payload: buyer
    })
}

export const deleteBuyer = id => (dispatch, getState) => {
    return axios.delete(`${process.env.REACT_APP_API}/buyers/${id}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: NOTIFICATIONS,
                payload: res.data
             })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const buyerDupCheck = (dupCheck) => async (dispatch, getState) => {
    const buyers = getState().buyers.buyers;
    var check = [];
    buyers.map(item => {
        if(item.company === dupCheck){
            check = [...check, item]
        }
    })
    return check
}