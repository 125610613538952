import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    COMPANY_LOADING,
    GET_COMP_USERS,
    COMPANY_EMAIL_GROUPS,
    COMPANY_TEMPLATES
} from "./types";

export const setCompanyLoading = () => {
    return {
        type: COMPANY_LOADING
    };
};

export const getCompanyUsers = () => (dispatch, getState) => {
    dispatch(setCompanyLoading());
    axios.get(`${process.env.REACT_APP_API}/users/`, tokenConfig(getState))
        .then(async (res) => {
            // getUserMap(res.data, getState)
            // .then(value => {
            //     dispatch({
            //         type: GET_COMP_USERS,
            //         payload: value
            //     })
            // })
            getUserRoles(res.data, dispatch, getState);
            // const co_emp = res.data.map(async (item) => {
            //     const role = await getUserRole(item.tag, getState);
            //     const newItem = { ...item, ...role }
            //     return newItem
            // })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

function getUserRoles(data, dispatch, getState){
    axios.get(`${process.env.REACT_APP_API}/users/roles`, tokenConfig(getState))
        .then(async (res) => {
            const newData = await data.map(item => {
                const index = res.data.findIndex(da => da._id === item.tag);
                var newItem = {};
                if(index >= 0){
                    newItem = { ...item, ...res.data[index] }
                }
                return newItem
            })
            dispatch({
                type: GET_COMP_USERS,
                payload: newData
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

// function getUserMap(data, getState) {
//     return new Promise(async (resolve, reject) => {
//         const co_emp = await data.map(async (item) => {
//             const role = await getUserRole(item.tag, getState);
//             const newItem = { ...item, ...role }
//             return newItem
//         })
//         resolve(co_emp);
//     })
// }

// async function getUserRole(id, getState) {
//     return await new Promise(async (resolve, reject) => {
//         return await axios.get(`${process.env.REACT_APP_API}/users/role/${id}`, tokenConfig(getState))
//             .then(res => {
//                 resolve(res.data)
//             })
//             .catch(err => {
//                 reject(err.response.data)
//             });
//     })
// }

// export function getUserRole(id, getState) {
//     return axios.get(`${process.env.REACT_APP_API}/users/role/${id}`, tokenConfig(getState))
//         .then(res => {
//             return res.data
//         })
//         .catch(err => {
//             return err.response.data
//         });
// };

export const getCompanyUsersAdmin = () => (dispatch, getState) => {
    dispatch(setCompanyLoading());
    return axios.get(`${process.env.REACT_APP_API}/users/admin`, tokenConfig(getState))
        .then(res => {
            return res.data
            // dispatch({
            //     type: GET_COMP_USERS,
            //     payload: res.data
            // })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const getUsersPerm = () => (dispatch, getState) => {
    dispatch(setCompanyLoading());
    axios.get(`${process.env.REACT_APP_API}/users/user_permissions`, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //     type: GET_COMP_USERS,
            //     payload: res.data
            // })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const requestDemo = set => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/users/demo/request`, set)
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
};

export const addEmployee = employee => (dispatch, getState) => {
    // return axios.post(`${process.env.REACT_APP_API}/users/register`, employee, tokenConfig(getState))
    return axios.post(`${process.env.REACT_APP_API}/users/employee/add`, employee, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const addEmployeeSend = employee => (dispatch, getState) => {
    // return axios.post(`${process.env.REACT_APP_API}/users/register`, employee, tokenConfig(getState))
    return axios.post(`${process.env.REACT_APP_API}/users/employee/add/send`, employee, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}
 
export const updateEmployee = (employee) => (dispatch, getState) => {
    // return axios.post(`${process.env.REACT_APP_API}/users/update/employee/${id}`, employee, tokenConfig(getState))
    return axios.post(`${process.env.REACT_APP_API}/users/employee/update`, employee, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const sendAccessEmail = email => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/users/register/send`, email, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
};

export const saveEmailGroup = data => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/company/email/group`, data, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const removeEmailGroup = data => (dispatch, getState) => {
    console.log(data)
    return axios.post(`${process.env.REACT_APP_API}/company/email/group/remove`, data, tokenConfig(getState))
        .then(res => {
            console.log(res.data)
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            console.log(err.response.data)
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const saveTemplates = data => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/company/templates/update`, data, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const companyEmailGroupsSocket = data => (dispatch, getState) => {
    dispatch({
        type: COMPANY_EMAIL_GROUPS,
        payload: data
    })
}

export const companyTemplatesSocket = data => (dispatch, getState) => {
    dispatch({
        type: COMPANY_TEMPLATES,
        payload: data
    })
}