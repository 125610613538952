import React, { Component } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateOffer, setOffersCal } from '../../actions/offerActions';
import { applyClientFilterSub } from '../../actions/clientActions';
import { addCalendarEntry, updateCalendarEntry, deleteCalendarEntry, applyCalendarFilter, applyCalendarFilter2, setCalendar, resetCalendar } from '../../actions/calendarActions';
import { addHolds, updateHolds, deleteHolds } from '../../actions/holdsActions';
import { FaPlusSquare, FaTimesCircle, FaPlus, FaFilter } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert'
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import EntryDialog from '../calendar/calendar-entry.dialog';
import DateDialog from '../calendar/select-date.dialog';
import CalendarButtonGroup from '../contacts/calendar.button-group';
import { calendarListener } from '../../helpers/socket-listeners/calendar.socket';
import _ from 'lodash';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import '../../../node_modules/@fullcalendar/daygrid/main.css';
import '../../../node_modules/@fullcalendar/timegrid/main.css';
import "react-datepicker/dist/react-datepicker.css";


class CalendarScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            sidebarOpen: false,
            title: [],
            date: [],
            submenu: '',
            pending: 'Pending',
            confirmed: 'Confirmed',
            cancelled: 'Cancelled',
            agent: '',
            pendingcheckbox: true,
            confirmedcheckbox: true,
            cancelledcheckbox: true,
            holdsdialog: false,
            dates: [],
            newholds: [],
            newholdnotes: [],
            entrySelect: '',
            newHoldsArray: [],
            connectid: '',
            artist: '',
            artistid: '',
            artistbatch: { _id: '', client: '' },
            connectbatch: { _id: '' },
            venue: '',
            venueid: '',
            venuebatch: { _id: '', venuename: '', address: [] },
            eventtype: '',
            eventname: '',
            eventnotes: '',
            renderholdsdialog: false,
            editrange: false,
            eventid: '',
            calendar_filter: { confirmed: true, pending: true, cancelled: false, holds: true, announce: false, cal_entries: true },
            client_filter: [""],
            agent_filter: [""],
            datedialog: false,
            dateselect: new Date()
        };
        this.handlePendCheckboxChange = this.handlePendCheckboxChange.bind(this);
        this.handleConfCheckboxChange = this.handleConfCheckboxChange.bind(this);
        this.handleCancCheckboxChange = this.handleCancCheckboxChange.bind(this);
        this.toggleCalendarFilter = this.toggleCalendarFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectDates = this.selectDates.bind(this);
        this.enterHoldsArray = this.enterHoldsArray.bind(this);
        this.enterHoldNotes = this.enterHoldNotes.bind(this);
        this.renderOffer = this.renderOffer.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleVenueChange = this.handleVenueChange.bind(this);
        this.handleConnectChange = this.handleConnectChange.bind(this);
        this.calendarComponentRef = React.createRef();
        this.toggleFilterSelect = this.toggleFilterSelect.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        offers: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        festivals: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        offerdetail: PropTypes.object.isRequired,
        calendar: PropTypes.object.isRequired,
        holds: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        await this.props.setOffersCal();
        await this.props.setCalendar();
        var log_status = await this.props.calendarListener();
        console.log(log_status)
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleEventPositioned(info) {
        const description = info.event._def.extendedProps.description
        info.el.setAttribute("data-tip", description);
        ReactTooltip.rebuild();
    }

    toggleFilter = () => {
        this.setState({
            submenu: 'Filter'
        })
    }
    toggleFilterOff = () => {
        this.setState({
            submenu: ''
        })
    }

    handlePendCheckboxChange = () => {
        if (this.state.pendingcheckbox === false) {
            this.setState({
                pendingcheckbox: true,
                pending: 'Pending'
            })
        } else {
            this.setState({
                pendingcheckbox: false,
                pending: 'na'
            })
        }
    }
    handleConfCheckboxChange = () => {
        if (this.state.confirmedcheckbox === false) {
            this.setState({
                confirmedcheckbox: true,
                confirmed: 'Confirmed'
            })
        } else {
            this.setState({
                confirmedcheckbox: false,
                confirmed: 'na'
            })
        }
    }
    handleCancCheckboxChange = () => {
        if (this.state.cancelledcheckbox === false) {
            this.setState({
                cancelledcheckbox: true,
                cancelled: 'Cancelled'
            })
        } else {
            this.setState({
                cancelledcheckbox: false,
                cancelled: 'na'
            })
        }
    }

    selectDates(info) {
        var dates = [];
        var startDate = moment(info.startStr, 'YYYY-MM-DD').add(1, 'day');
        var endDate = moment(info.endStr, 'YYYY-MM-DD').format('YYYY-MM-DD');
        dates.push(startDate.format('MM/DD/YYYY'));
        while (!startDate.isSame(endDate)) {
            startDate = startDate.add(1, 'days');
            dates.push(startDate.format('MM/DD/YYYY'));
        }

        this.setState({
            entrySelect: '',
            holdsdialog: !this.state.holdsdialog,
            dates: dates
        })
        //alert('selected ' + info.startStr + ' to ' + info.endStr);
    }

    setEntrySelect = (e) => {
        this.setState({
            entrySelect: e
        })
    }

    closeHoldsDialog = () => {
        this.setState({
            holdsdialog: !this.state.holdsdialog,
            dates: [],
            newholds: [],
            newholdnotes: [],
            entrySelect: '',
            newHoldsArray: [],
            artist: '',
            artistid: '',
            artistbatch: { _id: '', client: '' },
            venue: '',
            venueid: '',
            venuebatch: { _id: '', venuename: '', address: [] },
            connectbatch: { _id: '' },
            eventtype: '',
            eventname: '',
            eventnotes: '',
            editrange: false,
            eventid: '',
            renderholdsdialog: false
        })
    };

    closeDateDialog = () => {
        this.setState({
            datedialog: !this.state.datedialog,
        })
    };

    applyDateChange = () => {
        let calendarApi = this.calendarComponentRef.current.getApi();
        var date = moment(this.state.dateselect).format('YYYY-MM-DD')
        calendarApi.gotoDate(date); // call a method on the Calendar object

        this.setState({
            datedialog: !this.state.datedialog,
        })
    };

    enterHoldsArray(holds, index) {
        const { newholds } = this.state;
        const newHolds = [...newholds];
        newHolds[index] = holds;
        this.setState({ newholds: newHolds });
    };

    enterHoldNotes(notes, index) {
        const { newholdnotes } = this.state;
        const newNotes = [...newholdnotes];
        newNotes[index] = notes;
        this.setState({ newholdnotes: newNotes });
    };

    saveHolds = () => {
        if (this.state.entrySelect === "holds") {
            const fullarray = []
            for (var i = 0; i < this.state.dates.length; i++) {
                fullarray.push({ date: this.state.dates[i], hold: this.state.newholds[i], note: this.state.newholdnotes[i] });
            }
            let output = fullarray.filter(obj => obj.hold !== undefined);
            const entry = {
                holds: output,
                client: this.state.artistid,
                venue: this.state.venueid,
                type: this.state.entrySelect,
                connect_id: this.state.connectbatch._id
            }
            this.props.addHolds(entry);
            this.closeHoldsDialog();
        } else if (this.state.entrySelect === "calendar") {
            const datesArray = this.state.dates.map(d => moment(d));
            var start = moment.min(datesArray);
            var end = moment.max(datesArray);
            const entry = {
                //type: this.state.eventtype,
                event: this.state.eventname,
                start: start,
                end: end,
                desc: this.state.eventnotes,
            }
            this.props.addCalendarEntry(entry);
            this.closeHoldsDialog();
        } else {

        }
    }

    updateHolds = () => {
        const id = this.state.eventid
        if (this.state.entrySelect === "holds") {
            const fullarray = []
            for (var i = 0; i < this.state.dates.length; i++) {
                fullarray.push({ date: this.state.dates[i], hold: this.state.newholds[i], note: this.state.newholdnotes[i] });
            }
            let output = fullarray.filter(obj => obj.hold !== undefined);
            let output2 = output.filter(obj => obj.hold !== "");
            const entry = {
                holds: output2,
                client: this.state.artistid,
                venue: this.state.venueid,
                type: this.state.entrySelect,
                connect_id: this.state.connectid
            }
            this.props.updateHolds(entry, id);
            this.closeHoldsDialog();
        } else if (this.state.entrySelect === "calendar") {
            const datesArray = this.state.dates.map(d => moment(d));
            var start = moment.min(datesArray);
            var end = moment.max(datesArray);
            const entry = {
                type: this.state.eventtype,
                event: this.state.eventname,
                start: start,
                end: end,
                notes: this.state.eventnotes,
            }
            this.props.updateCalendarEntry(entry, id);
            this.closeHoldsDialog();
        } else {

        }
    }

    deleteHolds = () => {
        const id = this.state.eventid
        if (this.state.eventid) {
            if (this.state.entrySelect === "holds") {
                this.props.deleteHolds(id);
                this.closeHoldsDialog();
            } else if (this.state.entrySelect === "calendar") {
                this.props.deleteCalendarEntry(id);
                this.closeHoldsDialog();
            } else {

            }
        } else {
            alert('No event selected.')
        }
    }

    renderOffer(info) {
        ReactTooltip.hide();
        var id = info.event.id;
        console.log(info.event._def.extendedProps.sub, id)
        if (info.event._def.extendedProps.sub === "offer" || info.event._def.extendedProps.sub === "offer holds") {
            this.props.offers.offers.map(item => {
                if (item._id === id) {
                    this.props.iconRender(item);
                };
            });
        } else if (info.event._def.extendedProps.sub === "holds") {
            this.props.holds.holds.map(item => {
                var clientid = item.client;
                var venueid = item.venue;
                if (item._id === id) {
                    var dates = [];
                    var holds = [];
                    var notes = [];
                    item.holds.map(e => {
                        dates.push(e.date)
                        holds.push(e.hold)
                        notes.push(e.note)
                    });
                    var client = "";
                    const client_array = this.props.clients.clients;
                    console.log(client_array)
                    const c_index = client_array.findIndex(item => item._id === clientid)
                    if (c_index >= 0) {
                        client = client_array[c_index].client
                    }
                    var venue = [];
                    var address = [];
                    this.props.items.items.map(v => {
                        if (v._id === venueid) {
                            venue = v.venue_name
                            address = v.address
                        }
                    });
                    console.log(clientid, client, venue, venueid, address)
                    var dateArray = [];
                    let moments = item.holds.map((d, i) => moment(d.date));
                    var compare = item.holds.map((d, i) => { return ({ dates: moment(d.date).format('M/DD/YYYY') }) });
                    let holdsArray = item.holds.map((h, i) => { return ({ holds: h.hold }) });
                    let arr3 = compare.map((item, i) => Object.assign({}, item, holdsArray[i]));
                    var start = moment.min(moments);
                    var end = moment.max(moments);
                    while (start <= end) {
                        dateArray.push(moment(start).format('M/DD/YYYY'))
                        start = moment(start).add(1, 'days');
                    }
                    let merged = [];
                    for (let i = 0; i < dateArray.length; i++) {
                        merged.push({
                            ...(arr3.find((item) => item.dates === dateArray[i]))
                        });
                    }
                    var finalholds = merged.map((f, i) => f.holds);
                    finalholds = finalholds.map(h => { if (h === undefined) { return ("") } else { return (h) } });

                    this.setState({
                        eventid: id,
                        dates: dateArray,
                        newholds: finalholds,
                        newholdnotes: notes,
                        artist: client,
                        artistid: clientid,
                        artistbatch: { _id: clientid, client: client },
                        venue: venue,
                        venueid: venueid,
                        venuebatch: { _id: venueid, venue_name: venue, address: address },
                        entrySelect: 'holds',
                        holdsdialog: !this.state.holdsdialog,
                        renderholdsdialog: true
                    })
                };
            })
        } else if (info.event._def.extendedProps.sub === "cal_entries") {
            this.props.calendar.entries.map(item => {
                if (item._id === id) {
                    var dateArray = [];
                    var start = moment(item.start);
                    var end = moment(item.end);
                    while (start <= end) {
                        dateArray.push(moment(start).format('M/DD/YYYY'))
                        start = moment(start).add(1, 'days');

                    }

                    this.setState({
                        eventid: id,
                        eventtype: item.type,
                        eventname: item.event,
                        dates: dateArray,
                        eventnotes: item.notes,
                        entrySelect: 'calendar',
                        holdsdialog: !this.state.holdsdialog,
                        renderholdsdialog: true
                    });
                };
            })
        }
    };

    handleClientChange(artist) {
        if (artist) {
            this.setState({
                artist: artist.client,
                artistid: artist._id,
                artistbatch: artist
            })
        } else {
            this.setState({
                artist: '',
                artistid: '',
                artistbatch: ''
            });
        }
    }

    handleVenueChange(venue) {
        if (venue) {
            this.setState({
                venue: venue.venuename,
                venueid: venue._id,
                venuebatch: venue
            })
        } else {
            this.setState({
                venue: '',
                venueid: '',
                venuebatch: ''
            });
        }
    }

    handleConnectChange(conn) {
        if (conn) {
            this.setState({
                connectbatch: { _id: conn },
            })
        } else {
            this.setState({
                connectbatch: { _id: '' },
            });
        }
    }

    handleStartDateChange = date => {
        const fullarray = []
        for (var i = 0; i < this.state.dates.length; i++) {
            fullarray.push({ date: this.state.dates[i], hold: this.state.newholds[i], note: this.state.newholdnotes[i] });
        }

        var dateArray = [];
        var start = moment(date);
        let moments = this.state.dates.map((d, i) => moment(d));
        var compare = this.state.dates.map((d, i) => { return ({ dates: moment(d).format('M/DD/YYYY') }) });
        let holds = this.state.newholds.map((h, i) => { return ({ holds: h }) });

        let arr3 = compare.map((item, i) => Object.assign({}, item, holds[i]));
        //console.log(arr3)
        var end = moment.max(moments);

        if (start._d > end._d) {
            alert("The selected start date is greater than the end date.  Please select a new range.");
        } else {
            while (start <= end) {
                dateArray.push(moment(start).format('M/DD/YYYY'))
                start = moment(start).add(1, 'days');
            }

            //for (let i = 0; i < dateArray.length; i++) {
            //    for (let a = 0; a < compare.length; a++) {
            //        if (dateArray[i] === compare[a].dates)
            //            console.log(compare[a].holds)
            //    }
            //}

            let merged = [];
            for (let i = 0; i < dateArray.length; i++) {
                merged.push({
                    ...(arr3.find((item) => item.dates === dateArray[i]))
                });
            }
            var holdsArray = merged.map(h => { if (h.holds === undefined) { return ("") } else { return (h.holds) } });

            this.setState({
                dates: dateArray,
                newholds: holdsArray
            });
        }
    };

    handleEndDateChange = date => {
        const fullarray = []
        for (var i = 0; i < this.state.dates.length; i++) {
            fullarray.push({ date: this.state.dates[i], hold: this.state.newholds[i], note: this.state.newholdnotes[i] });
        }

        var dateArray = [];
        let moments = this.state.dates.map(d => moment(d));
        var end = moment(date);
        var start = moment.min(moments);

        if (start._d > end._d) {
            alert("The selected start date is greater than the end date.  Please select a new range.");
        } else {
            while (start <= end) {
                dateArray.push(moment(start).format('M/DD/YYYY'))
                start = moment(start).add(1, 'days');
            }
            this.setState({
                dates: dateArray
            });
        }
    };

    toggleEditRange = () => {
        this.setState({
            editrange: !this.state.editrange
        })
    }

    dateChange = async (info) => {
        var nS = new Date(info.event._instance.range.start);
        var nE = new Date(info.event._instance.range.end);
        var startDate = moment(nS).add(1, 'days');
        var stopDate = moment(nE).add(1, 'days');
        var id = info.event._def.publicId;
        let dateArray = await getDates(startDate, stopDate);

        if (info.event._def.extendedProps.sub === 'holds') {
            var holds = this.props.holds.holds;
            var index = holds.findIndex(obj => obj._id === id);
            var single = {};
            var sub_id = info.event._def.extendedProps.sub_id;
            if (index >= 0) {
                single = holds[index];
                single.holds.map(item => {
                    if (item._id === sub_id) {
                        item.date = new Date(startDate)
                    }
                })
            }
            const full = {
                holds: single
            }
            console.log(full)
            var log_status = await this.props.updateHolds(full, id);
            console.log(log_status)
        } else if (info.event._def.extendedProps.sub === 'offer') {
            var offers = this.props.offers.offers;
            var index = offers.findIndex(obj => obj._id === id);
            var single = {};
            var sub_id = info.event._def.extendedProps.sub_id;
            if (index >= 0) {
                single = offers[index];
                single.show_info.map(item => {
                    if (item._id === sub_id) {
                        item.date = new Date(startDate).toString()
                    }
                })
            }
            const full = {
                offer: single
            }
            console.log(full)
            var log_status = await this.props.updateOffer(full, id);
            console.log(log_status)
        }
    }

    dateChangeDialog = (info) => {
        var oS = new Date(info.oldEvent._instance.range.start);
        var oE = new Date(info.oldEvent._instance.range.end);
        var old_start = moment(oS).add(1, 'days');
        var old_end = moment(oE).add(1, 'days');

        var nS = new Date(info.event._instance.range.start);
        var nE = new Date(info.event._instance.range.end);
        var dateRange_start = moment(nS).add(1, 'days');
        var dateRange_end = moment(nE).add(1, 'days');

        const addDialog = ({ onClose }) => {
            return (
                <div class="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Move Event</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Are you sure you'd like to move this offer from {old_end.format('MM/DD/YYYY')} to {dateRange_end.format('MM/DD/YYYY')}?
                        </div>
                        <div style={{ marginTop: 10 }} class="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.dateChange(info); onClose(); }}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { this.cancelDateChange(info); onClose(); }}>No</button>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }
        confirmAlert(options)
    }

    cancelDateChange = (info) => {
        this.props.resetCalendar();
    }

    async toggleCalendarFilter(e) {
        var name = e;
        if (this.state.calendar_filter[name] === true) {
            await this.setState(prevState => {
                let calendar_filter = Object.assign({}, prevState.calendar_filter);
                calendar_filter[name] = false;
                return { calendar_filter };
            })
            var options = Object.keys(this.state.calendar_filter);
            options = options.filter(key => this.state.calendar_filter[key] === true)
            this.props.applyCalendarFilter(options)
        } else {
            await this.setState(prevState => {
                let calendar_filter = Object.assign({}, prevState.calendar_filter);
                calendar_filter[name] = true;
                return { calendar_filter };
            })
            var options = Object.keys(this.state.calendar_filter);
            options = options.filter(key => this.state.calendar_filter[key] === true)
            this.props.applyCalendarFilter(options)
        }
    }

    gotoPast = () => {
        // let calendarApi = this.calendarComponentRef.current.getApi();
        // calendarApi.gotoDate("2019-10-12"); // call a method on the Calendar object

        this.setState({
            datedialog: !this.state.datedialog
        })
    };

    addFilter = (s) => {
        var arr = this.state[s];
        arr.filter(item => item === "");
        if (arr.length <= 0) {
            alert("Cannot choose 'all' when adding more filter options")
        } else {
            var newObj = ""
            this.setState({
                [s]: [...this.state[s], newObj]
            })
        }
    }

    removeFilter = (s, i) => {
        var array = this.state[s];
        _.pullAt(array, i);
        this.setState({
            [s]: array
        })
    }

    applyFilter = () => {
        var options = {};
        var agentCheck = this.state.agent_filter.filter(item => item);
        var clientCheck = this.state.client_filter.filter(item => item);
        if (agentCheck.length > 0 && clientCheck.length === 0) {
            var clientArray = [];
            this.props.clients.subscribe.forEach(c => {
                clientArray.push(c._id)
            })
            options = {
                client: clientArray,
                agent: this.state.agent_filter
            }
        } else {
            options = {
                client: this.state.client_filter,
                agent: this.state.agent_filter
            }
        }
        this.props.applyCalendarFilter2(options);
    }

    resetFilter = () => {
        this.setState({
            client_filter: [""],
            agent_filter: [""]
        })
    }

    toggleFilterSelect = (event, index) => {
        var set = event.target.name;
        var value = event.target.value;
        let array = this.state[set];
        array[index] = value
        this.setState({
            [set]: array
        })
    }

    toggleFilterAgent = async (event, index) => {
        var set = event.target.name;
        var value = event.target.value || "";
        let array = this.state[set];
        array[index] = value
        this.setState({
            [set]: array
        })
        await this.props.applyClientFilterSub(array)
        var clients = [];
        this.state.client_filter.forEach(item => {
            this.props.clients.subscribe.map(c => {
                if (item === c._id) {
                    clients.push(c._id)
                }
            })
        })
        if (clients.length > 0) {
            this.setState({
                client_filter: clients
            })
        } else {
            this.setState({
                client_filter: [""]
            })
        }
    }

    handleDateChange = (date) => {
        this.setState({
            dateselect: new Date(date)
        })
    }

    render() {

        var events = this.props.calendar.events_filtered;

        return (

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '100%', height: 30, backgroundColor: '#111', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <UncontrolledDropdown>
                        <DropdownToggle className='custom-toggle-menu'><FaPlus className='custom-toggle-icon' /></DropdownToggle>
                        <DropdownMenu style={{ border: '2px solid #333333', backgroundColor: '#000' }} right>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('offer_modal', 'render_offer_data')}>Offer</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('venue_modal', 'render_venue_data')}>Venue</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('buyer_modal', 'render_buyer_data')}>Buyer</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('contact_modal', 'render_contact_data')}>Contact</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('client_modal', 'render_client_data')}>Client</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('festival_modal', 'render_festival_data')}>Festival</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('packaging_modal', 'render_packaging_data')}>Packaging</DropdownItem>
                            <DropdownItem style={{ fontSize: 14, color: '#cdcdcd' }} onClick={() => this.props.toggleNewModal('ar_modal', 'render_ar_data')}>A&R</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {/* <button style={{ width: 30, height: 28, backgroundColor: '#000', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }} type="button"><FaPlus size={12} color="#cdcdcd" /></button> */}
                    {/* <button style={{ width: 30, height: 28, backgroundColor: this.props.filter ? '#0B6EFD' : '#000', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1 }} type="button" onClick={this.props.toggleFilter}><FaFilter size={12} color="#cdcdcd" /></button> */}
                    <button style={{ backgroundColor: this.props.filter ? '#0B6EFD' : '#000' }} type="button" onClick={this.props.toggleFilter} className='custom-toggle-menu'><FaFilter style={{ zIndex: 2 }} size={12} color="#fff" /></button>
                    {this.props.filter ?
                        <span style={{ display: 'flex' }}>
                            <button style={{ width: 'auto', padding: 5, height: 28, fontSize: 10, color: '#fff', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} type="button" className="btn btn-primary px-3" onClick={this.applyFilter}>APPLY</button>
                            <button style={{ padding: 5, height: 28, fontSize: 10, color: '#fff', backgroundColor: '#333', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} type="button" onClick={this.resetFilter}>RESET FILTER</button>
                        </span> : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', height: '85vh', width: '100%' }}>
                    {this.props.filter ?
                        <div className="calendarSideNav">
                            <div style={{ width: '100%', backgroundColor: '#111' }}>
                                <p style={{ color: '#cdcdcd', fontSize: 15, marginLeft: '10%', marginTop: 25 }}>Agent(s):  <FaPlusSquare style={{ cursor: 'pointer' }} size="18" color="#cdcdcd" onClick={() => this.addFilter('agent_filter')} /></p>
                                {/* {this.state.agent_filter.length <= 0 ?
                                    <select style={{ color: '#000', fontSize: 12, marginLeft: '10%', width: '80%', height: 28 }} value={this.state.agent} name="agent" onChange={this.handleChange.bind(this)}>
                                        <option value="">All</option>
                                        {this.props.company.agents.map((a, c) => {
                                            console.log(a);
                                            return (
                                                <option value={a.tag}>{a.name.first} {a.name.last}</option>
                                            )
                                        })}
                                    </select> : */}
                                {this.state.agent_filter.map((item, index) => {
                                    return (
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} key={index}>
                                            <select style={{ color: '#000', fontSize: 12, marginLeft: '10%', width: '80%', height: 28, marginBottom: 10 }} value={item} name={`agent_filter`} onChange={(event) => this.toggleFilterAgent(event, index)}>
                                                {this.state.agent_filter.length <= 1 ?
                                                    <option value="">All</option> :
                                                    <option value=""> </option>}
                                                {this.props.company.agents.map((a, c) => {
                                                    return (
                                                        <option value={a.tag}>{a.name.first} {a.name.last}</option>
                                                    )
                                                })}
                                            </select>
                                            {index > 0 ?
                                                <div style={{ width: '10%', height: 28, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <FaTimesCircle style={{ cursor: 'pointer' }} size="12" color="#cdcdcd" onClick={() => this.removeFilter('agent_filter', index)} />
                                                </div> : null}
                                        </div>
                                    )
                                })}
                                <p style={{ color: '#cdcdcd', fontSize: 15, marginLeft: '10%', marginTop: 10 }}>Artist(s):  <FaPlusSquare style={{ cursor: 'pointer' }} size="18" color="#cdcdcd" onClick={() => this.addFilter('client_filter')} /></p>
                                {this.state.client_filter.map((item, index) => {
                                    return (
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} key={index}>
                                            <select style={{ color: '#000', fontSize: 12, marginLeft: '10%', width: '80%', height: 28, marginBottom: 10 }} value={item} name={`client_filter`} onChange={(event) => this.toggleFilterSelect(event, index)}>
                                                {this.state.client_filter.length <= 1 ?
                                                    <option value="">All</option> :
                                                    <option value=""> </option>}
                                                {this.props.clients.subscribe.map((a, c) => {
                                                    return (
                                                        <option value={a._id}>{a.client}</option>
                                                    )
                                                })}
                                            </select>
                                            {index > 0 ?
                                                <div style={{ width: '10%', height: 28, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <FaTimesCircle style={{ cursor: 'pointer' }} size="12" color="#cdcdcd" onClick={() => this.removeFilter('client_filter', index)} />
                                                </div> : null}
                                        </div>
                                    )
                                })}
                                {/* <button style={{ width: 80, marginLeft: '10%', height: 28, fontSize: 12, backgroundColor: '#cdcdcd', color: '#000', border: '1px solid #000', borderRadius: 5, fontWeight: 'bold', marginTop: 40 }}>APPLY</button> */}
                                {/* <div style={{ width: 'auto' }}>
                                    <button style={{ padding: 5, marginLeft: '10%', minHeight: 28, height: 'auto', fontSize: 10, color: '#fff', backgroundColor: '#333', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold', marginTop: 92, marginRight: '5%', width: '75%' }} onClick={this.resetFilter}>RESET FILTERS</button>
                                    <button style={{ width: 'auto', padding: 5, marginLeft: '10%', minHeight: 28, height: 'auto', fontSize: 10, color: '#fff', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold', marginRight: '5%', width: '75%' }} className="btn btn-primary px-3" onClick={this.applyFilter}>APPLY</button>
                                </div> */}
                            </div>
                        </div> : null}
                    <div style={{ height: '100%', width: 60 }}>
                        <CalendarButtonGroup toggleCalendarFilter={this.toggleCalendarFilter} calendar_filter={this.state.calendar_filter} />
                    </div>
                    <div style={{ height: '100%', width: this.props.filter ? '76.6%' : '95%', backgroundColor: '#fff', overflowY: 'scroll' }} >
                        {this.props.cal_tooltip ?
                            <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                            </ReactTooltip> : null}
                        <div style={{ marginLeft: 10, marginRight: 10, marginTop: 5, overflow: 'hidden', position: 'relative', zIndex: 0 }}>
                            <FullCalendar
                                defaultView="dayGridMonth"
                                headerToolbar={{
                                    left: 'prev,next,today',
                                    center: 'title',
                                    right: 'custom'
                                }}
                                customButtons={{
                                    custom: {
                                        text: 'Select Date',
                                        click: () => this.gotoPast(),
                                    },
                                }}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                ref={this.calendarComponentRef}
                                editable={true}
                                selectable={true}
                                eventClick={(info) => this.renderOffer(info)}
                                //eventDrop={(info) => this.dateChangeDialog(info)}
                                eventChange={(info) => this.dateChangeDialog(info)}
                                select={this.selectDates}
                                events={events}
                                eventContent={renderEventContent}
                                eventMouseEnter={this.handleEventPositioned}
                            />
                        </div>
                    </div>
                </div>
                <EntryDialog open={this.state.holdsdialog} handleClose={this.closeHoldsDialog} setEntrySelect={this.setEntrySelect} saveHolds={this.saveHolds} updateHolds={this.updateHolds} deleteHolds={this.deleteHolds} handleChange={this.handleChange} enterHoldsArray={this.enterHoldsArray} enterHoldNotes={this.enterHoldNotes} clientList={this.props.clients.clients} onClientChange={this.handleClientChange} artistbatch={this.state.artistbatch} venueList={this.props.items.items} festivalList={this.props.festivals.items} onVenueChange={this.handleVenueChange} venuebatch={this.state.venuebatch} toggleNewVenue={this.props.toggleNewVenue} toggleNewModal={this.props.toggleNewModal} handleStartDateChange={this.handleStartDateChange} handleEndDateChange={this.handleEndDateChange} toggleEditRange={this.toggleEditRange} offersList={this.props.offers.offers} onConnectChange={this.handleConnectChange} connectbatch={this.state.connectbatch} {...this.state} />
                {this.state.datedialog ?
                    <DateDialog open={this.state.datedialog} handleClose={this.closeDateDialog} handleDateChange={this.handleDateChange} applyDateChange={this.applyDateChange} {...this.state} /> : null}
            </div>
        );
    }
}

function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    console.log(new Date(currentDate), new Date(stopDate))
    while (new Date(currentDate) <= new Date(stopDate)) {
        dateArray.push({ date: new Date(currentDate).toString() });
        currentDate = moment(currentDate).add(1, 'days');
    }
    console.log(dateArray)
    return dateArray;
}

function renderEventContent(eventInfo) {
    //var artist = eventInfo.event.title.substring(0, 3);
    //var show = eventInfo.event.title.substring(6);
    return (
        <>
            {eventInfo.backgroundColor === "rgb(255, 124, 1)" ?
                <div style={{ display: 'flex', flexDirection: 'row', height: 22, overflowX: 'hidden', maxWidth: 180 }}>
                    <b style={{ height: '100%', fontSize: 10 }}>{eventInfo.event.title}</b>
                </div> :
                <div style={{ display: 'flex', flexDirection: 'column', height: eventInfo.event.extendedProps.sub === "event" ? 18 : 30, borderLeft: `4px solid ${eventInfo.event._def.ui.borderColor}`, width: '100%', overflowX: 'hidden', overflowY: 'hidden' }}>
                    {eventInfo.event.extendedProps.sub === "event" ?
                        <p style={{ height: '100%', fontSize: 10, color: '#000', paddingLeft: 6 }}><b>{eventInfo.event.title}</b></p> :
                        eventInfo.event.extendedProps.sub === 'holds' ?
                            <p style={{ height: '100%', fontSize: 10, color: '#000', paddingLeft: 6 }}><b>{eventInfo.event.extendedProps.hold}</b> {eventInfo.event.title}<br />{eventInfo.event.extendedProps.venue}</p> :
                            eventInfo.event.extendedProps.sub === 'announce' ?
                                <p style={{ height: '100%', fontSize: 10, color: '#000', paddingLeft: 6 }}><b>{eventInfo.event.extendedProps.type}</b> {eventInfo.event.title}<br />{eventInfo.event.extendedProps.venue}</p> :
                                eventInfo.event.extendedProps.sub === 'offer holds' ?
                                    <p style={{ height: '100%', fontSize: 10, color: '#000', paddingLeft: 6 }}><b>{eventInfo.event.extendedProps.hold}</b> {eventInfo.event.title}<br />{eventInfo.event.extendedProps.venue}</p> :
                                    <p style={{ height: '100%', fontSize: 10, color: '#000', paddingLeft: 6 }}><b>{eventInfo.event.title}</b><br />{eventInfo.event.extendedProps.venue}</p>}
                </div>}
        </>
    )
}

const mapStateToProps = state => ({
    offers: state.offers,
    items: state.items,
    festivals: state.festivals,
    buyers: state.buyers,
    clients: state.clients,
    contacts: state.contacts,
    offerdetail: state.offerdetail,
    calendar: state.calendar,
    holds: state.holds,
    company: state.company,
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { updateOffer, applyClientFilterSub, addCalendarEntry, updateCalendarEntry, applyCalendarFilter, applyCalendarFilter2, addHolds, updateHolds, setCalendar, setOffersCal, resetCalendar, calendarListener, deleteHolds, deleteCalendarEntry })(CalendarScreen);