import React, { Component } from "react";
import PropTypes from 'prop-types';
import { FaArrowLeft } from 'react-icons/fa';
import { getSocials, getGoogleTrendsTime, getGoogleTrendsRegion } from '../../actions/socialActions';
import { getSpotifyData } from '../../actions/spotifyActions';
import { Bar, Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import TrendsCompareDialog from "../../components/dialogs/trends-compare.dialog";
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import moment from 'moment';

class ClientSocials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instagramfollow: '',
            nav: 'socials',
            nav_sub: 'time',
            trendsTimeArray: [],
            tendsTime: [],
            trendsRegion: [],
            trendsRegionArray: [],
            trendsRegionArrayPrev: [],
            startDate: new Date(moment().subtract(1, 'year')),
            endDate: new Date(),
            regionDefault: true,
            regionSelect: null,
            compare_dialog: false,
            compare_val: null,
            trendsRegionSelect: 0
        };
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        spotify: PropTypes.object.isRequired,
        socials: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        const itemStr = this.props.spotify.token;
        const item = JSON.parse(itemStr)
        if (this.props.spotify_id && itemStr) {
            const data = {
                id: this.props.spotify_id,
                spotify_token: item.token
            }
            this.props.getSpotifyData(data);
        }

        const obj = {
            keyword: this.props.client,
            startDate: new Date(moment().subtract(1, 'year')),
            endDate: new Date(),
            region: null
        };

        const trendsTime = await this.props.getGoogleTrendsTime(obj);
        const trendsRegion = await this.props.getGoogleTrendsRegion(obj);
        console.log(trendsRegion, 'TRENDS REGION')

        var trendsTimeArray = [];
        var timesValues = [];
        const timeStr = trendsTime.toString();
        if (!timeStr.includes('error')) {
            trendsTime.default.timelineData.forEach((o, ind) => {
                const d = o.value.map(z => ({ x: new Date(o.formattedAxisTime), y: z }))
                timesValues.push(...d);
                // trendsTimeArray.push(
                //     {
                //         label: "Engagement",
                //         fill: false,
                //         lineTension: 0.2,
                //         backgroundColor: 'blue',
                //         borderColor: 'blue',
                //         // borderDash: [],
                //         // borderDashOffset: 0.0,
                //         borderJoinStyle: "miter",
                //         borderCapStyle: "butt",
                //         borderWidth: 2,
                //         pointBorderColor: 'black',
                //         pointBackgroundColor: "#fff",
                //         pointBorderWidth: 1,
                //         pointHoverRadius: 5,
                //         pointHoverBackgroundColor: "#fff",
                //         pointHoverBorderColor: 'blue',
                //         pointHoverBorderWidth: 2,
                //         pointRadius: 4,
                //         pointHitRadius: 10,
                //         data: o.value.map(z => ({ x: new Date(o.formattedAxisTime).toString(), y: z })),
                //         showLine: false
                //     }
                // )
            })
        }

        trendsTimeArray = {
            label: "Engagement",
            fill: false,
            lineTension: 0.2,
            backgroundColor: 'blue',
            borderColor: 'blue',
            // borderDash: [],
            // borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            borderCapStyle: "butt",
            borderWidth: 2,
            pointBorderColor: 'black',
            pointBackgroundColor: "blue",
            pointBorderWidth: .5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: 'black',
            pointHoverBorderWidth: 1,
            pointRadius: 5,
            pointHitRadius: 10,
            data: timesValues,
            showLine: true
        }

        var labels = [];
        if (timesValues && timesValues.length > 0) {
            var start = new Date(timesValues[0].x);
            const e = timesValues.length - 1;
            if (e >= 0) {
                var end = new Date(timesValues[e].x)
                while (start <= end) {
                    // labels = this.state.trendsTime.map(item => new Date(item.formattedAxisTime))
                    labels.push(new Date(start));
                    start.setDate(start.getDate() + 1);
                }
            }
        }
        const trendsData = {
            labels: labels,
            datasets: [trendsTimeArray]
        };

        const trendsRegionArray = [];
        const regionStr = trendsRegion.toString()
        if (!regionStr.includes('error')) {
            trendsRegion.default.geoMapData.map(item => {
                if (item.value[0] > 0) {
                    trendsRegionArray.push(item)
                }
            })
        }

        console.log(trendsData, 'TRENDS REGION ARRAY')
        this.setState({
            trendsTimeArray: trendsData,
            trendsTime: !timeStr.includes('error') ? trendsTime.default.timelineData : [],
            trendsRegion: !regionStr.includes('error') ? trendsRegion.default.geoMapData : [],
            trendsRegionArray: trendsRegionArray,
            regionDefault: true,
            regionSelect: null,
            compare_val: null,
            trendsRegionSelect: 0
        })


        var instagram = this.props.instagram;
        const social_data = {
            instagram: instagram
        }
        // const fb = await this.props.getSocials();
        // console.log("FB", fb)

        //console.log(instagram)
        //await fetch(`${instagram}/?__a=1`)
        //    .then(response => response.json())
        //    .then((jsonData) => {
        //        console.log(jsonData)
        //        this.setState({
        //            instagramfollow: jsonData.graphql.user.edge_followed_by.count
        //        })
        //    })
        //    .catch((error) => {
        //        console.log(error)
        //    })
        //fetch(`https://api.spotify.com/v1/tracks/09P3wPH0MvtLnhwfbtzrRq`)
        //    .then(response => response.json())
        //    .then((jsonData) => {
        //        console.log(jsonData)
        //    })
        //    .catch((error) => {
        //        console.log(error)
        //    })
    }

    selectRegion = async (item) => {
        console.log(item)
        const newPrev = [this.state.trendsRegionArray, ...this.state.trendsRegionArrayPrev];
        this.setState({
            trendsRegionArrayPrev: newPrev
        })
        const obj = {
            keyword: this.state.compare_val ? this.state.ind === 0 ? this.props.client : this.state.compare_val : this.props.client,
            startDate: new Date(moment().subtract(1, 'year')),
            endDate: new Date(),
            region: item.geoCode
        };
        const trendsRegion = await this.props.getGoogleTrendsRegion(obj);
        console.log(trendsRegion, 'TRENDS REGION')

        const regionStr = trendsRegion.toString()
        if (!regionStr.includes('error')) {
            const trendsRegionArray = [];
            trendsRegion.default.geoMapData.map(item => {
                if (item.value[0] > 0) {
                    trendsRegionArray.push(item)
                }
            })

            console.log(trendsRegionArray, 'TRENDS REGION ARRAY')
            this.setState({
                trendsRegion: trendsRegion.default.geoMapData,
                trendsRegionArray: trendsRegionArray,
                regionDefault: false,
                regionSelect: item.geoCode
            })
        }
    }

    resetInitialTrend = async () => {
        const obj = {
            keyword: this.props.client,
            startDate: new Date(moment().subtract(1, 'year')),
            endDate: new Date(),
            region: null
        };
        const trendsTime = await this.props.getGoogleTrendsTime(obj);
        const trendsRegion = await this.props.getGoogleTrendsRegion(obj);

        // function getTrendsTime(obj) {
        //     return new Promise(async (resolve, reject) => {
        //         const trendsTime = await this.props.getGoogleTrendsTime(obj);
        //         resolve(trendsTime)
        //     })
        // }
        // function getTrendsRegion(obj) {
        //     return new Promise(async (resolve, reject) => {
        //         const trendsRegion = await this.props.getGoogleTrendsRegion(obj);
        //         resolve(trendsRegion)
        //     })
        // }

        // getTrendsTime().then(item => {
        //     var trendsTimeArray = [];
        //     var timesValues = [];
        //     const timeStr = item.toString();
        //     if (!item.includes('error')) {
        //         item.default.timelineData.forEach((o, ind) => {
        //             const d = o.value.map(z => ({ x: new Date(o.formattedAxisTime), y: z }))
        //             timesValues.push(...d);
        //         })
        //     }
        // })
        // getTrendsRegion().then(item => {

        // })
        function setTrendsRegion(obj) {
            if (obj) {
                return new Promise(async (resolve, reject) => {
                    const d = obj.default.geoMapData.forEach(item => {
                        if (item.value[0]) {
                            // trendsRegionArray.push(item)
                            return item
                        }
                    })
                    resolve(d)
                })
            }
        }
        function setTrendsTime(obj) {
            if (obj) {
                return new Promise(async (resolve, reject) => {
                    const d = obj.default.timelineData.forEach((o, ind) => {
                        const p = o.value.map(z => ({ x: new Date(o.formattedAxisTime), y: z }))
                        return p
                    })
                    resolve(d)
                })
            }
        }

        var trendsTimeArray = {};
        var timesValues = [];
        const timeStr = trendsTime.toString();
        if (!timeStr.includes('error')) {
            trendsTime.default.timelineData.forEach((o, ind) => {
                const d = o.value.map(z => ({ x: new Date(o.formattedAxisTime), y: z }))
                timesValues.push(...d);
            })
            // timesValues = await setTrendsTime(trendsTime)
        }

        trendsTimeArray = {
            label: "Engagement",
            fill: false,
            lineTension: 0.2,
            backgroundColor: 'blue',
            borderColor: 'blue',
            // borderDash: [],
            // borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            borderCapStyle: "butt",
            borderWidth: 2,
            pointBorderColor: 'black',
            pointBackgroundColor: "blue",
            pointBorderWidth: .5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: 'black',
            pointHoverBorderWidth: 1,
            pointRadius: 5,
            pointHitRadius: 10,
            data: timesValues,
            showLine: true
        }

        var trendsRegionArray = [];
        const regionStr = trendsRegion.toString()
        if (!regionStr.includes('error')) {
            // trendsRegionArray = await setTrendsRegion(trendsRegion)
            trendsRegion.default.geoMapData.map(item => {
                if (item.value[0] > 0) {
                    trendsRegionArray.push(item)
                }
            })
        }

        var labels = [];
        if (timesValues && timesValues.length > 0) {
            var start = new Date(timesValues[0].x);
            const e = timesValues.length - 1;
            if (e >= 0) {
                var end = new Date(timesValues[e].x)
                while (start <= end) {
                    // labels = this.state.trendsTime.map(item => new Date(item.formattedAxisTime))
                    labels.push(new Date(start));
                    start.setDate(start.getDate() + 1);
                }
            }
        }
        const trendsData = {
            labels: labels,
            datasets: [trendsTimeArray]
        };

        console.log(trendsRegionArray, trendsRegion, regionStr, 'TRENDS REGION ARRAY')
        this.setState({
            startDate: new Date(moment().subtract(1, 'year')),
            endDate: new Date(),
            trendsTimeArray: trendsData,
            trendsTime: !timeStr.includes('error') ? trendsTime.default.timelineData : [],
            trendsRegion: !regionStr.includes('error') ? trendsRegion.default.geoMapData : [],
            trendsRegionArray: trendsRegionArray,
            regionDefault: true,
            compare_val: null,
            trendsRegionArrayPrev: [],
            regionSelect: null
        })
    }

    async handleStartDateChange(d) {
        if (d) {
            if (moment(d).isBefore(moment(this.state.endDate))) {
                const obj = {
                    keyword: this.state.compare_val ? [this.props.client, this.state.compare_val] : this.props.client,
                    startDate: new Date(d),
                    endDate: new Date(this.state.endDate),
                    region: this.state.regionSelect
                };
                const trendsTime = await this.props.getGoogleTrendsTime(obj);
                const trendsRegion = await this.props.getGoogleTrendsRegion(obj);

                var trendsTimeArray = [];
                var trendsTimeArray2 = [];
                var timesValues = [];
                var timesValues2 = [];
                var trendsData = {};

                const timeStr = trendsTime.toString();
                if (this.state.compare_val) {
                    const timeStr = trendsTime.toString();
                    if (!timeStr.includes('error')) {
                        trendsTime.default.timelineData.forEach((o, ind) => {
                            o.value.map((z, y) => {
                                if (y === 0) {
                                    timesValues.push({ x: new Date(o.formattedAxisTime), y: z })
                                } else if (y === 1) {
                                    timesValues2.push({ x: new Date(o.formattedAxisTime), y: z })
                                }
                            })
                        })
                    }
                    trendsTimeArray = {
                        label: this.props.client,
                        fill: false,
                        lineTension: 0.2,
                        backgroundColor: 'blue',
                        borderColor: 'blue',
                        // borderDash: [],
                        // borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        borderCapStyle: "butt",
                        borderWidth: 2,
                        pointBorderColor: 'black',
                        pointBackgroundColor: "blue",
                        pointBorderWidth: .5,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: 'black',
                        pointHoverBorderWidth: 1,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: timesValues,
                        showLine: true
                    }
                    trendsTimeArray2 = {
                        label: this.state.compare_val,
                        fill: false,
                        lineTension: 0.2,
                        backgroundColor: 'red',
                        borderColor: 'red',
                        // borderDash: [],
                        // borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        borderCapStyle: "butt",
                        borderWidth: 2,
                        pointBorderColor: 'black',
                        pointBackgroundColor: "red",
                        pointBorderWidth: .5,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: 'black',
                        pointHoverBorderWidth: 1,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: timesValues2,
                        showLine: true
                    }
                } else {
                    if (!timeStr.includes('error')) {
                        trendsTime.default.timelineData.forEach((o, ind) => {
                            const d = o.value.map(z => ({ x: new Date(o.formattedAxisTime), y: z }))
                            timesValues.push(...d);
                        })
                    }

                    trendsTimeArray = {
                        label: "Engagement",
                        fill: false,
                        lineTension: 0.2,
                        backgroundColor: 'blue',
                        borderColor: 'blue',
                        // borderDash: [],
                        // borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        borderCapStyle: "butt",
                        borderWidth: 2,
                        pointBorderColor: 'black',
                        pointBackgroundColor: "blue",
                        pointBorderWidth: .5,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: 'black',
                        pointHoverBorderWidth: 1,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: timesValues,
                        showLine: true
                    }
                }

                var labels = [];
                if (timesValues && timesValues.length > 0) {
                    var start = new Date(timesValues[0].x);
                    const e = timesValues.length - 1;
                    if (e >= 0) {
                        var end = new Date(timesValues[e].x)
                        while (start <= end) {
                            // labels = this.state.trendsTime.map(item => new Date(item.formattedAxisTime))
                            labels.push(new Date(start));
                            start.setDate(start.getDate() + 1);
                        }
                    }
                }
                if (this.state.compare_val) {
                    trendsData = {
                        labels: labels,
                        datasets: [trendsTimeArray, trendsTimeArray2]
                    };
                } else {
                    trendsData = {
                        labels: labels,
                        datasets: [trendsTimeArray]
                    };
                }

                const trendsRegionArray = [];
                const regionStr = trendsRegion.toString()
                if (!regionStr.includes('error')) {
                    trendsRegion.default.geoMapData.map(item => {
                        if (item.value[0] > 0) {
                            trendsRegionArray.push(item)
                        }
                    })
                }
                this.setState({
                    startDate: new Date(d),
                    trendsTimeArray: trendsData,
                    trendsTime: !timeStr.includes('error') ? trendsTime.default.timelineData : [],
                    trendsRegion: !regionStr.includes('error') ? trendsRegion.default.geoMapData : [],
                    trendsRegionArray: trendsRegionArray,
                    regionDefault: true,
                    trendsRegionArrayPrev: [],
                    trendsRegionSelect: 0
                })
            } else {
                alert('Please choose a start date thats before your select end date')
            }
        } else {
            alert('No date selected')
        }
    }

    async handleEndDateChange(d) {
        if (d) {
            if (moment(d).isAfter(moment(this.state.startDate))) {
                const obj = {
                    keyword: this.state.compare_val ? [this.props.client, this.state.compare_val] : this.props.client,
                    startDate: new Date(this.state.startDate),
                    endDate: new Date(d),
                    region: this.state.regionSelect
                };
                const trendsTime = await this.props.getGoogleTrendsTime(obj);
                const trendsRegion = await this.props.getGoogleTrendsRegion(obj);

                var trendsTimeArray = [];
                var trendsTimeArray2 = [];
                var timesValues = [];
                var timesValues2 = [];
                var trendsData = {};

                const timeStr = trendsTime.toString();
                if (this.state.compare_val) {
                    const timeStr = trendsTime.toString();
                    if (!timeStr.includes('error')) {
                        trendsTime.default.timelineData.forEach((o, ind) => {
                            o.value.map((z, y) => {
                                if (y === 0) {
                                    timesValues.push({ x: new Date(o.formattedAxisTime), y: z })
                                } else if (y === 1) {
                                    timesValues2.push({ x: new Date(o.formattedAxisTime), y: z })
                                }
                            })
                        })
                    }
                    trendsTimeArray = {
                        label: this.props.client,
                        fill: false,
                        lineTension: 0.2,
                        backgroundColor: 'blue',
                        borderColor: 'blue',
                        // borderDash: [],
                        // borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        borderCapStyle: "butt",
                        borderWidth: 2,
                        pointBorderColor: 'black',
                        pointBackgroundColor: "blue",
                        pointBorderWidth: .5,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: 'black',
                        pointHoverBorderWidth: 1,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: timesValues,
                        showLine: true
                    }
                    trendsTimeArray2 = {
                        label: this.state.compare_val,
                        fill: false,
                        lineTension: 0.2,
                        backgroundColor: 'red',
                        borderColor: 'red',
                        // borderDash: [],
                        // borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        borderCapStyle: "butt",
                        borderWidth: 2,
                        pointBorderColor: 'black',
                        pointBackgroundColor: "red",
                        pointBorderWidth: .5,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: 'black',
                        pointHoverBorderWidth: 1,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: timesValues2,
                        showLine: true
                    }
                } else {
                    if (!timeStr.includes('error')) {
                        trendsTime.default.timelineData.forEach((o, ind) => {
                            const d = o.value.map(z => ({ x: new Date(o.formattedAxisTime), y: z }))
                            timesValues.push(...d);
                        })
                    }

                    trendsTimeArray = {
                        label: "Engagement",
                        fill: false,
                        lineTension: 0.2,
                        backgroundColor: 'blue',
                        borderColor: 'blue',
                        // borderDash: [],
                        // borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        borderCapStyle: "butt",
                        borderWidth: 2,
                        pointBorderColor: 'black',
                        pointBackgroundColor: "blue",
                        pointBorderWidth: .5,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: 'black',
                        pointHoverBorderWidth: 1,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: timesValues,
                        showLine: true
                    }
                }

                var labels = [];
                if (timesValues && timesValues.length > 0) {
                    var start = new Date(timesValues[0].x);
                    const e = timesValues.length - 1;
                    if (e >= 0) {
                        var end = new Date(timesValues[e].x)
                        while (start <= end) {
                            // labels = this.state.trendsTime.map(item => new Date(item.formattedAxisTime))
                            labels.push(new Date(start));
                            start.setDate(start.getDate() + 1);
                        }
                    }
                }
                if (this.state.compare_val) {
                    trendsData = {
                        labels: labels,
                        datasets: [trendsTimeArray, trendsTimeArray2]
                    };
                } else {
                    trendsData = {
                        labels: labels,
                        datasets: [trendsTimeArray]
                    };
                }

                const trendsRegionArray = [];
                const regionStr = trendsRegion.toString()
                if (!regionStr.includes('error')) {
                    trendsRegion.default.geoMapData.map(item => {
                        if (item.value[0] > 0) {
                            trendsRegionArray.push(item)
                        }
                    })
                }
                this.setState({
                    endDate: new Date(d),
                    trendsTimeArray: trendsData,
                    trendsTime: !timeStr.includes('error') ? trendsTime.default.timelineData : [],
                    trendsRegion: !regionStr.includes('error') ? trendsRegion.default.geoMapData : [],
                    trendsRegionArray: trendsRegionArray,
                    regionDefault: true,
                    trendsRegionArrayPrev: [],
                    trendsRegionSelect: 0
                })
            } else {
                alert('Please choose an end date thats after your select start date')
            }
        } else {
            alert('No date selected')
        }
    }

    addTrendComparison = async (val) => {
        if (val) {
            const timeObj = {
                keyword: [this.props.client, val],
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                region: this.state.regionSelect
            };
            const trendsObj = {
                keyword: this.props.client,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                region: this.state.regionSelect
            };
            const trendsTime = await this.props.getGoogleTrendsTime(timeObj);
            const trendsRegion = await this.props.getGoogleTrendsRegion(trendsObj);

            var trendsTimeArray = [];
            var trendsTimeArray2 = [];
            var timesValues = [];
            var timesValues2 = [];
            const timeStr = trendsTime.toString();
            if (!timeStr.includes('error')) {
                trendsTime.default.timelineData.forEach((o, ind) => {
                    o.value.map((z, y) => {
                        if (y === 0) {
                            timesValues.push({ x: new Date(o.formattedAxisTime), y: z })
                        } else if (y === 1) {
                            timesValues2.push({ x: new Date(o.formattedAxisTime), y: z })
                        }
                    })
                    // timesValues.push(...d);
                    // trendsTimeArray.push(
                    //     {
                    //         label: "Engagement",
                    //         fill: false,
                    //         lineTension: 0.2,
                    //         backgroundColor: 'blue',
                    //         borderColor: 'blue',
                    //         // borderDash: [],
                    //         // borderDashOffset: 0.0,
                    //         borderJoinStyle: "miter",
                    //         borderCapStyle: "butt",
                    //         borderWidth: 2,
                    //         pointBorderColor: 'black',
                    //         pointBackgroundColor: "#fff",
                    //         pointBorderWidth: 1,
                    //         pointHoverRadius: 5,
                    //         pointHoverBackgroundColor: "#fff",
                    //         pointHoverBorderColor: 'blue',
                    //         pointHoverBorderWidth: 2,
                    //         pointRadius: 4,
                    //         pointHitRadius: 10,
                    //         data: o.value.map(z => ({ x: new Date(o.formattedAxisTime).toString(), y: z })),
                    //         showLine: false
                    //     }
                    // )
                })
            }

            trendsTimeArray = {
                label: this.props.client,
                fill: false,
                lineTension: 0.2,
                backgroundColor: 'blue',
                borderColor: 'blue',
                // borderDash: [],
                // borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                borderCapStyle: "butt",
                borderWidth: 2,
                pointBorderColor: 'black',
                pointBackgroundColor: "blue",
                pointBorderWidth: .5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: 'black',
                pointHoverBorderWidth: 1,
                pointRadius: 5,
                pointHitRadius: 10,
                data: timesValues,
                showLine: true
            }
            trendsTimeArray2 = {
                label: val,
                fill: false,
                lineTension: 0.2,
                backgroundColor: 'red',
                borderColor: 'red',
                // borderDash: [],
                // borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                borderCapStyle: "butt",
                borderWidth: 2,
                pointBorderColor: 'black',
                pointBackgroundColor: "red",
                pointBorderWidth: .5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: 'black',
                pointHoverBorderWidth: 1,
                pointRadius: 5,
                pointHitRadius: 10,
                data: timesValues2,
                showLine: true
            }

            var labels = [];
            if (timesValues && timesValues.length > 0) {
                var start = new Date(timesValues[0].x);
                const e = timesValues.length - 1;
                if (e >= 0) {
                    var end = new Date(timesValues[e].x)
                    while (start <= end) {
                        // labels = this.state.trendsTime.map(item => new Date(item.formattedAxisTime))
                        labels.push(new Date(start));
                        start.setDate(start.getDate() + 1);
                    }
                }
            }
            const trendsData = {
                labels: labels,
                datasets: [trendsTimeArray, trendsTimeArray2]
            };

            const trendsRegionArray = [];
            const regionStr = trendsRegion.toString()
            if (!regionStr.includes('error')) {
                trendsRegion.default.geoMapData.map(item => {
                    if (item.value[0] > 0) {
                        trendsRegionArray.push(item)
                    }
                })
            }

            this.setState({
                compare_dialog: false,
                compare_val: val,
                trendsTimeArray: trendsData,
                trendsTime: !timeStr.includes('error') ? trendsTime.default.timelineData : [],
                trendsRegion: !regionStr.includes('error') ? trendsRegion.default.geoMapData : [],
                trendsRegionArray: trendsRegionArray,
                trendsRegionSelect: 0,
                trendsRegionArrayPrev: [],
            })
        }
    }

    togglePrevRegion = () => {
        if (this.state.trendsRegionArrayPrev.length > 0) {
            this.setState({
                trendsRegionArray: this.state.trendsRegionArrayPrev[0]
            })
            const newPrev = this.state.trendsRegionArrayPrev.slice(1);
            this.setState({
                trendsRegionArrayPrev: newPrev,
                regionSelect: null
            })
        }

    }

    selectCompareRegion = async (ind) => {
        var trendsObj = {}
        if (ind === 0) {
            trendsObj = {
                keyword: this.props.client,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                region: null
            };
        } else if (ind === 1) {
            trendsObj = {
                keyword: this.state.compare_val,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                region: null
            };
        }
        const trendsRegion = await this.props.getGoogleTrendsRegion(trendsObj);
        var trendsRegionArray = [];
        const regionStr = trendsRegion.toString()
        if (!regionStr.includes('error')) {
            const d = trendsRegion.default.geoMapData;
            // if (d) {
            //     for (var i of d) {
            //         if (i.value[0] > 0) {
            //             trendsRegionArray.push(i)
            //             //return item
            //         }
            //     }
            // }
            trendsRegion.default.geoMapData.map(item => {
                if (item.value[0] > 0) {
                    trendsRegionArray.push(item)
                    //return item
                }
            })
        }
        console.log(trendsRegionArray)
        this.setState({
            trendsRegion: !regionStr.includes('error') ? trendsRegion.default.geoMapData : [],
            trendsRegionArray: trendsRegionArray,
            trendsRegionSelect: ind,
            trendsRegionArrayPrev: [],
            regionSelect: null
        })
    }

    removeComparison = async () => {
        const obj = {
            keyword: this.props.client,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            region: null
        };
        const trendsTime = await this.props.getGoogleTrendsTime(obj);
        const trendsRegion = await this.props.getGoogleTrendsRegion(obj);

        var trendsTimeArray = {};
        var timesValues = [];
        const timeStr = trendsTime.toString();
        if (!timeStr.includes('error')) {
            trendsTime.default.timelineData.forEach((o, ind) => {
                const d = o.value.map(z => ({ x: new Date(o.formattedAxisTime), y: z }))
                timesValues.push(...d);
            })
        }

        trendsTimeArray = {
            label: "Engagement",
            fill: false,
            lineTension: 0.2,
            backgroundColor: 'blue',
            borderColor: 'blue',
            borderJoinStyle: "miter",
            borderCapStyle: "butt",
            borderWidth: 2,
            pointBorderColor: 'black',
            pointBackgroundColor: "blue",
            pointBorderWidth: .5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: 'black',
            pointHoverBorderWidth: 1,
            pointRadius: 5,
            pointHitRadius: 10,
            data: timesValues,
            showLine: true
        }

        var trendsRegionArray = [];
        const regionStr = trendsRegion.toString()
        if (!regionStr.includes('error')) {
            // trendsRegionArray = await setTrendsRegion(trendsRegion)
            trendsRegion.default.geoMapData.map(item => {
                if (item.value[0] > 0) {
                    trendsRegionArray.push(item)
                }
            })
        }

        var labels = [];
        if (timesValues && timesValues.length > 0) {
            var start = new Date(timesValues[0].x);
            const e = timesValues.length - 1;
            if (e >= 0) {
                var end = new Date(timesValues[e].x)
                while (start <= end) {
                    // labels = this.state.trendsTime.map(item => new Date(item.formattedAxisTime))
                    labels.push(new Date(start));
                    start.setDate(start.getDate() + 1);
                }
            }
        }
        const trendsData = {
            labels: labels,
            datasets: [trendsTimeArray]
        };

        console.log(trendsRegionArray, trendsRegion, regionStr, 'TRENDS REGION ARRAY')
        this.setState({
            startDate: new Date(moment().subtract(1, 'year')),
            endDate: new Date(),
            trendsTimeArray: trendsData,
            trendsTime: !timeStr.includes('error') ? trendsTime.default.timelineData : [],
            trendsRegion: !regionStr.includes('error') ? trendsRegion.default.geoMapData : [],
            trendsRegionArray: trendsRegionArray,
            regionDefault: true,
            compare_val: null,
            trendsRegionArrayPrev: [],
            regionSelect: null
        })
    }

    render() {

        const data = {
            labels: ['Facebook', 'Instagram', 'Spotify'],
            datasets: [
                {
                    label: 'Social Numbers',
                    backgroundColor: [
                        'orange',
                        'blue',
                        'red',
                    ],
                    borderColor: 'black',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: [0, 0, this.props.spotify.artist_data],
                }
            ]
        };

        const lineOptions = {
            responsive: true,
            parsing: {
                xAxisKey: "date",
                yAxisKey: "value",
            },
            scales: {
                xAxes: [
                    {
                        type: "time",
                        time: {
                            unit: "month",
                            tooltipFormat: "ll"
                        },
                        // unitStepSize: 1000,
                        gridLines: {
                            display: true
                        },
                        // ticks: {
                        //     maxTicksLimit: 31
                        // },
                        scaleLabel: {
                            display: false,
                        },
                    }
                ],
                yAxes: [
                    {
                        stacked: false,
                        gridLines: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            display: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Search Engagement'
                        }
                    }
                ]
            },
            legend: {
                display: false
            },
            hover: {
                mode: 'nearest',
                intersect: false,
            },
            tooltips: {
                enabled: true,
            },
            line: {
                borderColor: "blue",
            },
            maintainAspectRatio: true
        };

        return (
            <div style={{ width: '100%', height: 400 }}>
                <TrendsCompareDialog open={this.state.compare_dialog} onClose={() => this.setState({ compare_dialog: false })} add={this.addTrendComparison} />
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 28, width: '100%', marginLeft: 10, marginTop: 10 }}>
                        <span>
                            <div style={{ width: 110, height: 28, padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: this.state.nav === 'socials' ? '#111' : '#ddd', border: '2px solid #fff', cursor: 'pointer', borderTopLeftRadius: 5, borderTopRightRadius: 5, border: '1px solid #000' }} onClick={() => this.setState({ nav: 'socials' })}>
                                <p style={{ fontSize: 12, color: this.state.nav === 'socials' ? '#fff' : '#000', fontWeight: 'bold' }}>Socials</p>
                            </div>
                        </span>
                        <span>
                            <div style={{ width: 110, height: 28, padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: this.state.nav === 'trends' ? '#111' : '#ddd', border: '2px solid #fff', cursor: 'pointer', borderTopLeftRadius: 5, borderTopRightRadius: 5, border: '1px solid #000' }} onClick={() => this.setState({ nav: 'trends' })}>
                                <p style={{ fontSize: 12, color: this.state.nav === 'trends' ? '#fff' : '#000', fontWeight: 'bold' }}>Google Trends</p>
                            </div>
                        </span>
                    </div>
                </div>
                {this.state.nav === 'socials' ?
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 372 }}>
                        <div style={{ width: '47.5%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', marginLeft: 60, marginTop: 30, width: '65%', backgroundColor: 'rgb(1, 22, 48)', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }} className="form-control">
                                <table style={{ width: '100%', marginTop: 10 }} id="customers">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '100%', fontSize: 14, paddingLeft: 10, backgroundColor: '#333', color: '#fff' }}>Facebook</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={{ width: '100%', marginBottom: 2 }} id="customers">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Followers</td>
                                            <td style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} thousandSeparator={true} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <button style={{ marginBottom: 10, width: '35%', backgroundColor: '#333', color: '#cdcdcd', fontSize: 12 }}>Log</button> */}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', marginLeft: 60, marginTop: 30, width: '65%', backgroundColor: 'rgb(1, 22, 48)', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }} className="form-control">
                                <table style={{ width: '100%', marginTop: 10 }} id="customers">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '100%', fontSize: 14, paddingLeft: 10, backgroundColor: '#333', color: '#fff' }}>Instagram</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={{ width: '100%', marginBottom: 2 }} id="customers">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Followers</td>
                                            <td style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={this.props.socials.instagram.data} thousandSeparator={true} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <button style={{ marginBottom: 10, width: '35%', backgroundColor: '#333', color: '#cdcdcd', fontSize: 12 }}>Log</button> */}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', marginLeft: 60, marginTop: 30, width: '65%', backgroundColor: 'rgb(1, 22, 48)', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }} className="form-control">
                                <table style={{ width: '100%', marginTop: 10 }} id="customers">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '100%', fontSize: 14, paddingLeft: 10, backgroundColor: '#333', color: '#fff' }}>Spotify</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={{ width: '100%', marginBottom: 2 }} id="customers">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Followers</td>
                                            <td style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={this.props.spotify.artist_data} thousandSeparator={true} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <button style={{ marginBottom: 10, width: '35%', backgroundColor: '#333', color: '#cdcdcd', fontSize: 12 }}>Log</button> */}
                            </div>
                        </div>
                        <div style={{ width: '47%', height: '100%' }}>
                            <div style={{ width: '100%', height: '85%', marginTop: '15%' }}>
                                <Bar
                                    style={{ paddingTop: 60 }}
                                    data={data}
                                    width={100}
                                    height={50}
                                    options={{
                                        maintainAspectRatio: false, legend: {
                                            display: false
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div> :
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '80%', backgroundColor: '#000', marginLeft: 25, marginTop: 15, borderRadius: 15, padding: 20 }}>
                                <div style={{ width: 300 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: 250, height: 28, marginLeft: 15 }}>
                                        <label style={{ width: '50%', fontSize: 12, color: '#fff' }}>Start Date:</label>
                                        <DatePicker
                                            className="react-datepicker"
                                            //disabled={this.props.edit ? false : true}
                                            //showTimeSelect
                                            selected={this.state.startDate}
                                            onChange={this.handleStartDateChange}
                                            todayButton="Today"
                                            showMonthDropdown
                                            showYearDropdown
                                        //dateFormat="M/dd/yyyy h:mm aa z"
                                        />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: 250, marginTop: 10, height: 28, marginLeft: 15 }}>
                                        <label style={{ width: '50%', fontSize: 12, color: '#fff' }}>End Date:</label>
                                        <DatePicker
                                            className="react-datepicker"
                                            //disabled={this.props.edit ? false : true}
                                            //showTimeSelect
                                            selected={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            todayButton="Today"
                                            showMonthDropdown
                                            showYearDropdown
                                        //dateFormat="M/dd/yyyy h:mm aa z"
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {/* <div style={{ display: 'flex', flexDirection: 'row', width: 250, height: 28, marginLeft: 15 }}>
                                        <label style={{ width: '35%', fontSize: 12, color: '#fff' }}>Region:</label>
                                        <select style={{ width: '65%', height: 28, backgroundColor: '#fff', color: '#000', fontSize: 12, borderRadius: 3 }}>
                                            <option value="">Worldwide</option>
                                            {this.state.trendsRegionArray.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.geoCode}>{item.geoName}</option>
                                                )
                                            })}
                                        </select>
                                    </div> */}
                                    <div style={{ display: 'flex', flexDirection: 'row', width: 250, height: 28, marginLeft: 15 }}>
                                        <button style={{ height: 28, fontSize: 12, borderRadius: 10, width: 150 }} className="btn btn-secondary px-5" onClick={this.resetInitialTrend}>Reset</button>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: 250, height: 28, marginLeft: 15, marginTop: 15 }}>
                                        <button style={{ height: 28, fontSize: 12, borderRadius: 10, width: 150 }} className="btn btn-secondary px-5" onClick={() => this.setState({ compare_dialog: true })}>Compare</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ width: '25%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 50 }}>
                                <span style={{ display: 'flex', width: '50%', alignItems: 'center' }}>
                                    {/* <div style={{ width: 110, height: 28, padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: this.state.nav === 'socials' ? '#111' : '#ddd', border: '2px solid #fff', cursor: 'pointer' }} onClick={() => this.setState({ nav: 'socials' })}> */}
                                    <button style={{ width: 160, height: 28, backgroundColor: this.state.nav_sub === 'time' ? '#111' : '#ddd', color: this.state.nav_sub === 'time' ? '#ddd' : '#111', fontSize: 12, marginLeft: 15, borderRadius: 10 }} onClick={() => this.setState({ nav_sub: 'time' })}>
                                        By Time
                                    </button>
                                </span>
                                <span style={{ display: 'flex', width: '50%', alignItems: 'center' }}>
                                    {/* <div style={{ width: 110, height: 28, padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: this.state.nav === 'socials' ? '#111' : '#ddd', border: '2px solid #fff', cursor: 'pointer' }} onClick={() => this.setState({ nav: 'socials' })}> */}
                                    <button style={{ width: 160, height: 28, backgroundColor: this.state.nav_sub === 'region' ? '#111' : '#ddd', color: this.state.nav_sub === 'region' ? '#ddd' : '#111', fontSize: 12, marginLeft: 15, borderRadius: 10 }} onClick={() => this.setState({ nav_sub: 'region' })}>
                                        By Region
                                    </button>
                                </span>
                                {this.state.compare_val ?
                                    this.state.nav_sub === 'region' ?
                                        <div style={{ marginTop: 15, width: 200, minHeight: 28, borderRadius: 5, display: 'flex', flexDirection: 'row', marginLeft: 15 }}>
                                            {/* <h2 style={{ fontSize: 12, color: '#ddd' }}>{this.state.compare_val}</h2> */}
                                            <button style={{ minHeight: 28, fontSize: 12, borderRadius: 10, width: '100%', border: this.state.trendsRegionSelect === 0 ? '1px dashed white' : '1px dashed blue', borderRadius: 5, backgroundColor: this.state.trendsRegionSelect === 0 ? 'blue' : '#fff', color: this.state.trendsRegionSelect === 0 ? 'white' : '#000' }} className="btn btn-secondary px-5" disabled={this.state.trendsRegionSelect === 0 ? true : false} onClick={() => this.selectCompareRegion(0)}>{this.props.client}</button>
                                        </div> :
                                        <div style={{ marginTop: 15, width: 200, minHeight: 28, borderRadius: 5, display: 'flex', flexDirection: 'row', marginLeft: 15 }}>
                                            {/* <h2 style={{ fontSize: 12, color: '#ddd' }}>{this.state.compare_val}</h2> */}
                                            <button style={{ minHeight: 28, fontSize: 12, borderRadius: 10, width: '100%', border: '1px dashed blue', borderRadius: 5, backgroundColor: '#fff', color: '#000' }} className="btn btn-secondary px-5" disabled={true}>{this.props.client}</button>
                                        </div> : null}
                                {this.state.compare_val ?
                                    this.state.nav_sub === 'region' ?
                                        <div style={{ marginTop: 5, width: 200, minHeight: 28, borderRadius: 5, display: 'flex', flexDirection: 'row', marginLeft: 15 }}>
                                            {/* <h2 style={{ fontSize: 12, color: '#ddd' }}>{this.state.compare_val}</h2> */}
                                            <button style={{ minHeight: 28, fontSize: 12, borderRadius: 10, width: '100%', border: this.state.trendsRegionSelect === 1 ? '1px dashed white' : '1px dashed red', borderRadius: 5, backgroundColor: this.state.trendsRegionSelect === 1 ? 'red' : '#fff', color: this.state.trendsRegionSelect === 1 ? 'white' : '#000' }} className="btn btn-secondary px-5" disabled={this.state.trendsRegionSelect === 1 ? true : false} onClick={() => this.selectCompareRegion(1)}>{this.state.compare_val}</button>
                                        </div> :
                                        <div style={{ marginTop: 5, width: 200, minHeight: 28, borderRadius: 5, display: 'flex', flexDirection: 'row', marginLeft: 15 }}>
                                            <button style={{ minHeight: 28, fontSize: 12, borderRadius: 10, width: '100%', border: '1px dashed red', borderRadius: 5, backgroundColor: '#fff', color: '#000' }} className="btn btn-secondary px-5" disabled={true}>{this.state.compare_val}</button>
                                            {/* <span style={{ minHeight: 28, fontSize: 12, borderRadius: 10, width: '100%', border: '1px dashed red', borderRadius: 5, backgroundColor: '#fff', color: '#000', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <h2 style={{ fontSize: 12, color: '#ddd' }}>{this.state.compare_val}</h2>
                                        </span> */}
                                        </div> : null}
                                {this.state.compare_val ?
                                    <div style={{ marginTop: 5, display: 'flex', width: 200, alignItems: 'center' }}>
                                        <button style={{ width: '100%', height: 28, backgroundColor: '#111', color: '#ddd', fontSize: 12, marginLeft: 15, borderRadius: 10 }} onClick={this.removeComparison}>
                                            Remove Comparison
                                        </button>
                                    </div> : null}
                            </div>
                            {this.state.nav_sub === 'time' ?
                                <div style={{ width: '65%', height: 200, padding: 20 }}>
                                    <Line
                                        data={this.state.trendsTimeArray} options={lineOptions}
                                    />
                                </div> :
                                <div style={{ width: '65%', height: 330, padding: 20 }}>
                                    <table style={{ width: '100%', height: 28 }}>
                                        {this.state.trendsRegionArrayPrev.length > 0 ?
                                            <thead style={{ backgroundColor: '#111' }}>
                                                <tr>
                                                    <th style={{ width: '40%', fontSize: 12, color: '#fff', border: '1px solid #000', height: 28 }}><span style={{ width: 'auto', height: '100%', border: '1px solid #ddd', cursor: 'pointer', padding: 5 }} onClick={this.togglePrevRegion}><FaArrowLeft size={10} color={'#ddd'} /></span> {this.state.regionDefault ? 'Region' : 'Subregion'}</th>
                                                    <th style={{ width: '60%', fontSize: 12, color: '#fff', border: '1px solid #000', height: 28 }}>Search Engagement</th>
                                                </tr>
                                            </thead> :
                                            <thead style={{ backgroundColor: '#111' }}>
                                                <tr>
                                                    <th style={{ width: '40%', fontSize: 12, color: '#fff', border: '1px solid #000', height: 28 }}>{this.state.regionDefault ? 'Region' : 'Subregion'}</th>
                                                    <th style={{ width: '60%', fontSize: 12, color: '#fff', border: '1px solid #000', height: 28 }}>Search Engagement</th>
                                                </tr>
                                            </thead>}
                                    </table>
                                    <div style={{ width: '100%', height: 302, overflowY: 'scroll' }}>
                                        <table style={{ width: '100%' }}>
                                            {this.state.trendsRegionArray.map((item, index) => {
                                                return (
                                                    <tbody key={index} style={{ border: '1px solid #000', cursor: 'pointer' }} onClick={() => this.selectRegion(item)}>
                                                        <tr>
                                                            <td style={{ width: '40%', fontSize: 12, color: '#000', height: 28 }}>{item.geoName}</td>
                                                            <td style={{ width: '60%', fontSize: 12, color: '#000', height: 28 }}>{item.value[0]}</td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })}
                                        </table>
                                    </div>
                                </div>}
                        </div>
                    </div>}
            </div>
        )
    }

}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    spotify: state.spotify,
    socials: state.socials
});

export default connect(mapStateToProps, { getSocials, getSpotifyData, getGoogleTrendsTime, getGoogleTrendsRegion })(ClientSocials);