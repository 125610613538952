import React, { useState, useEffect } from "react";
import CountrySelect from '../countrySelect';

export default function BuyerDbFilter(props) {
    const [stateFilter, setFilter] = useState(props.buyer_filter);

    useEffect(() => {
        setFilter(props.buyer_filter);
    }, [props.buyer_filter]) 

    const filterChange = (event) => {
        var name = event.target.name;
        var val = event.target.value;
        let filter = Object.assign({}, stateFilter);
        filter[name] = val;
        setFilter(filter)
    }

    const applyFilter = () => {
        props.applyBuyerFilter(stateFilter)
    }

    const clearFilter = () => {
        var filter = {
            company: '',
            status: 'Active',
            _id: '',
            address: '',
            city: '',
            state: '',
            country: '',
            postal_code: '',
            buyer_name: ''
        };
        setFilter(filter);
    }

    return (
        <div style={{ paddingBottom: 26, backgroundColor: '#111', border: '2px solid #111' }} className="navbar-nav">
                <div style={{ display: 'flex', marginTop: 70, marginLeft: 50 }}>
                    <div style={{ width: 100, marginTop: -30 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}>Id:</p>
                    </div>
                    <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}>Company Name:</p>
                    </div>
                    <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}>City:</p>
                    </div>
                    <div style={{ width: 150, marginTop: -30, marginLeft: 40 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}>Postal Code:</p>
                    </div>
                    {/* <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}> </p>
                    </div> */}
                </div>
                <div style={{ display: 'flex', marginLeft: 50 }} className="form-group">
                    <div style={{ width: 100, marginTop: -10 }}>
                        <input
                            className="form-control-sm"
                            style={{ width: 100, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                            onChange={(e) => props.filterChange(e, 'buyer_filter')}
                            value={stateFilter._id}
                            name="_id"
                            type="text"
                        />
                    </div>
                    <div style={{ width: 200, marginTop: -10, marginLeft: 40 }}>
                        <input
                            style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                            className="form-control-sm"
                            onChange={(e) => props.filterChange(e, 'buyer_filter')}
                            value={stateFilter.company}
                            name="company"
                            type="text"
                        />
                    </div>
                    <div style={{ width: 200, marginLeft: 40, marginTop: -10 }}>
                        <input
                            style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                            className="form-control-sm"
                            value={stateFilter.city}
                            name="city"
                            type="text"
                            onChange={(e) => props.filterChange(e, 'buyer_filter')}
                        />
                    </div>
                    <div style={{ width: 200, marginTop: -10, marginLeft: 40 }}>
                        <input
                            style={{ width: 100, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                            className="form-control-sm"
                            value={stateFilter.postal_code}
                            name="postal_code"
                            type="text"
                            onChange={(e) => props.filterChange(e, 'buyer_filter')}
                        />
                    </div>
                    {/* <div style={{ width: 200, marginTop: -10, marginLeft: 40, height: 16 }}>
                        <p style={{ fontSize: 10, color: '#cdcdcd', width: 200, height: 16 }}> </p>
                    </div> */}
                </div>
                <div style={{ display: 'flex', marginTop: 5, marginLeft: 50 }}>
                    <div style={{ width: 100 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}>Status:</p>
                    </div>
                    <div style={{ width: 200, marginLeft: 40 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}>Address:</p>
                    </div>
                    <div style={{ width: 200, marginLeft: 40 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}>State:</p>
                    </div>
                    <div style={{ width: 150, marginLeft: 40 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}>Country:</p>
                    </div>
                    {/* <div style={{ width: 200, marginLeft: 40 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}> </p>
                    </div> */}
                </div>
                <div style={{ display: 'flex', marginLeft: 50, marginTop: -10 }} className="form-group">
                    <div style={{ width: 100, height: 28 }}>
                        {/* <StatusAutocomplete onStatusChange={handleStatusChange} statusSelect={stateFilter.status} edit={props.edit} /> */}
                        <select style={{ width: 98, height: 26, fontSize: 12, padding: 0 }} className="form-control" value={stateFilter.status} name="status" onChange={(e) => props.filterChange(e, 'buyer_filter')}>
                            <option value=""> </option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                    </div>
                    <div style={{ width: 200, height: 28, marginLeft: 40 }}>
                        <input
                            style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                            className="form-control-sm"
                            value={stateFilter.address}
                            name="address"
                            type="text"
                            onChange={(e) => props.filterChange(e, 'buyer_filter')}
                        />
                    </div>
                    <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                        <input
                            style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                            className="form-control-sm"
                            value={stateFilter.state}
                            name="state"
                            type="text"
                            onChange={(e) => props.filterChange(e, 'buyer_filter')}
                        />
                    </div>
                    <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                        <CountrySelect country={stateFilter.country} filterChange={(e) => props.filterChange(e, 'buyer_filter')} />
                    </div>
                </div>
        </div>
    );
}