import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from 'moment';
import CountrySelect from '../countrySelect';

export default function ArDbFilter(props) {
    const [stateFilter, setFilter] = useState(props.ar_filter);

    useEffect(() => {
        setFilter(props.ar_filter);
    }, [props.ar_filter]) 

    const filterChange = (event) => {
        var name = event.target.name;
        var val = event.target.value;
        let filter = Object.assign({}, stateFilter);
        filter[name] = val;
        setFilter(filter)
    }

    const filterDateChange = (n, v) => {
        var name = v;
        var val = n;
        console.log(name, val)
        let filter = Object.assign({}, stateFilter);
        filter[name] = new Date(val);
        console.log(filter)
        setFilter(filter)
    }

    const applyFilter = () => {
        props.applyArFilter(stateFilter)
    }

    const clearFilter = () => {
        var filter = {
            _id: '',
            status: '',
            artist: '',
            agent: '',
            city: '',
            state: '',
            country: ''
        };
        setFilter(filter);
    }

    return (
        <div style={{ paddingBottom: 26, backgroundColor: '#111', border: '2px solid #111' }} className="navbar-nav">
            <div style={{ display: 'flex', marginTop: 70, marginLeft: 50 }} >
                <div style={{ width: 100, marginTop: -30 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Id:</p>
                </div>
                <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Artist:</p>
                </div>
                {/* <div style={{ width: 150, marginTop: -30, marginLeft: 40 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}>Start Date:</p>
                    </div> */}
                <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Genre:</p>
                </div>
                <div style={{ width: 150, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>State | Province:</p>
                </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 50, marginTop: -10 }} className="form-group">
                <input
                    className="form-control-sm"
                    style={{ width: 100, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                    onChange={(e) => props.filterChange(e, 'ar_filter')}
                    value={stateFilter._id}
                    name="_id"
                    type="text"
                />
                <div style={{ width: 200, marginLeft: 40 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                        onChange={(e) => props.filterChange(e, 'ar_filter')}
                        value={stateFilter.artist}
                        name="artist"
                        type="text"
                    />
                </div>
                {/* <div style={{ width: 150, marginLeft: 40, height: 28 }}>
                        <DatePicker
                            className="react-datepicker"
                            style={{ fontSize: 12 }}
                            value={stateFilter.start_date}
                            selected={stateFilter.start_date}
                            onChange={(v) => filterDateChange(v, 'start_date')}
                            todayButton="Today"
                            showMonthDropdown
                            showYearDropdown
                        />
                    </div> */}
                <div style={{ width: 200, marginLeft: 40 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5 }}
                        value={stateFilter.genre}
                        name="genre"
                        type="text"
                        onChange={(e) => props.filterChange(e, 'ar_filter')}
                    />
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5 }}
                        type="text"
                        name="state"
                        value={stateFilter.state}
                        onChange={(e) => props.filterChange(e, 'ar_filter')}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 5, marginLeft: 50 }}>
                <div style={{ width: 100 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Status:</p>
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Agent:</p>
                </div>
                {/* <div style={{ width: 150, marginLeft: 40 }}>
                        <p style={{ color: '#cdcdcd', fontSize: 10 }}>End Date:</p>
                    </div> */}
                <div style={{ width: 200, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>City:</p>
                </div>
                <div style={{ width: 150, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Country:</p>
                </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 50, marginTop: -10 }} className="form-group">
                <div style={{ width: 100, height: 28 }}>
                    {/* <StatusAutocomplete onStatusChange={handleStatusChange} statusSelect={stateFilter.status} edit={props.edit} /> */}
                    <select style={{ width: 98, height: 26, fontSize: 12, padding: 0 }} className="form-control" value={stateFilter.status} name="status" onChange={(e) => props.filterChange(e, 'ar_filter')}>
                        <option value=""> </option>
                        <option value="Active">Active</option>
                        <option value="Filled">Filled</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                </div>
                <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5 }}
                        type="text"
                        name="agent"
                        value={stateFilter.agent}
                        onChange={(e) => props.filterChange(e, 'ar_filter')}
                    />
                </div>
                {/* <div style={{ width: 150, marginLeft: 40, marginTop: -2, height: 28 }}>
                        <DatePicker
                            className="react-datepicker"
                            style={{ fontSize: 12 }}
                            value={stateFilter.end_date}
                            selected={stateFilter.end_date}
                            onChange={(v) => filterDateChange(v, 'end_date')}
                            todayButton="Today"
                            showMonthDropdown
                            showYearDropdown
                        />
                    </div> */}
                <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, height: 26, fontSize: 12 }}
                        value={stateFilter.city}
                        name="city"
                        type="text"
                        onChange={(e) => props.filterChange(e, 'ar_filter')}
                    />
                </div>
                <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                    <CountrySelect country={stateFilter.country} filterChange={(e) => props.filterChange(e, 'ar_filter')} />
                </div>
            </div>
        </div>
    );
}