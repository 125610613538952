import React, { Component } from 'react';
import WhiteLogo from '../assets/WhiteLogo.png';
import loginBackdrop from '../assets/loginBackdrop.png';
import googleIcon from '../assets/google-icon.png';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, googleLogin } from '../actions/authActions';
import { clearErrors } from '../actions/errorActions';
import { FaRegCopyright } from 'react-icons/fa';

import '../App.css';

class AgentLogin extends Component {

    constructor() {
        super();
        this.state = {
            error: null,
            email: "",
            password: "",
            width: 0,
            height: 0
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        error: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        if (this.props.match.params.email) {
            this.setState({
                email: this.props.match.params.email
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps) {
        const { error, isAuthenticated } = this.props;
        if (error !== prevProps.error) {
            if (error.id === 'LOGIN_FAIL') {
                alert(error.msg.msg);
            }
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        const user = {
            email,
            password
        }
        this.props.login(user);

        // if (status.type !== 'success') {
        //     alert(status.data)
        //     this.setState({
        //         email: "",
        //         password: "",
        //     })
        // }
    }

    toggleGoogleLogin = () => {
        this.props.googleLogin();
    }

    render() {

        //<img src={WhiteLogo} width="200" height="210" />

        return (
            <div className="App">
                <section className="App-header">
                    <img style={{ position: 'relative' }} width="auto" height="625" src={loginBackdrop} alt="login" />
                    <form
                        style={{ position: 'absolute', height: 'auto', top: '45%' }}
                        autoComplete="off"
                        onSubmit={this.onSubmit} 
                    >
                        <div >
                            <input
                                className="Login-input1"
                                placeholder="Email"
                                name="email"
                                type="email"
                                onChange={this.handleChange}
                                value={this.state.email}
                            />
                        </div>
                        <div >
                            <input
                                className="Login-input2"
                                placeholder="Password"
                                name="password"
                                onChange={this.handleChange}
                                value={this.state.password}
                                type="password"
                            />
                            <div className="form-group">
                                {this.state.error ? (
                                    <p style={{ color: 'red', fontSize: 12, width: '100%' }} className="text-danger">{this.state.error}</p>
                                ) : null}
                                <div className="mt-3">
                                    <button style={{ fontSize: 12, color: '#F2F2F2', fontWeight: 'bold' }} className="btn btn-secondary px-5" type="submit">Login</button>
                                </div>
                                <div className="mt-3">
                                    <button style={{ fontSize: 12, color: '#F2F2F2', fontWeight: 'bold' }} className="btn btn-secondary px-5" type="button" onClick={this.toggleGoogleLogin}><img width="30" height="30" src={googleIcon} alt="google" /> Google Login</button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="mt-2">
                                    <NavLink style={{ fontSize: 10, color: '#ddd' }} type="submit" to="/reset/password">Forgot Password</NavLink>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p style={{ fontSize: 10, color: 'rgb(184, 184, 184)' }}><FaRegCopyright style={{ marginTop: -2 }} size="12" color="rgb(184, 184, 184)" /> 2021 Solar System, LLC</p>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error
})

export default connect(mapStateToProps, { login, googleLogin, clearErrors })(AgentLogin);