import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function TrendsCompareDialog(props) {
    const [searchValue, setSearchValue] = useState('');
    const [addendumSelect, setAddendumSelect] = useState('');
    const [customCheckbox, setCustomCheckbox] = useState(false);

    useEffect(() => {
        
    }, [])

    const cancelDialog = () => {
        props.onClose();
    };

    const create = () => {
        const options = {

        }
        props.add(searchValue);
        // props.generate(options);
    }

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={props.onClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Compare</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: 500, marginBottom: 30, marginTop: 20 }}>
                    <div style={{ width: '100%', height: 'auto', marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ width: '80%', height: 30, display: 'flex' }}>
                            <h2 style={{ fontSize: 12 }}>Compare trend data with another artist or value:</h2>
                        </span>
                        <input style={{ width: '80%', height: 30, fontSize: 12 }} type="text" name="searchValue" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={cancelDialog}>Cancel</Button>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={create}>Add</Button>
            </DialogActions>
        </Dialog>
    );
}