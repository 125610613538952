import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadAttachmentOffer, downloadAttachmentOffer, deleteAttachmentOffer } from '../../actions/offerActions';
import { FaTimesCircle, FaFileDownload } from "react-icons/fa";
import { confirmAlert } from 'react-confirm-alert'
import StatusSnackbar from '../../components/global/snackbar.attach';
import moment from 'moment';

class Attachments extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            file_input: '',
            file_full: null,
            file_type: '',
            file_notes: '',
            attach_loading: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {

    }

    static propTypes = {
        tag: PropTypes.string.isRequired,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
    }


    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleFileChange = event => {
        this.setState({
            file_input: event.target.value,
            file_full: event.target.files[0],
            loaded: 0,
        })
    }

    uploadFile = async () => {
        const id = this.props.id;
        const date = new Date().toString();

        const key = `id:${id} type:${this.state.file_type} date:${date}`;
        const file = this.state.file_full;
        const name = this.state.file_input.replace("C:\\fakepath\\", "");
        const type = this.state.file_type;
        const notes = this.state.file_notes;
        const user = this.props.tag;

        // const file = {
        //     name: this.state.file_input.replace("C:\\fakepath\\", ""),
        //     file: formData,
        //     key: string,
        //     type: this.state.file_type,
        //     notes: this.state.file_notes
        // };

        console.log('ATTACH', file, name, key, type, notes, date, user, id);
        this.setState({
            attach_loading: true
        })
        const log_status = await this.props.uploadAttachmentOffer(file, name, key, type, notes, date, user, id);
        if (log_status.includes('Error:')) {
            //this.props.toggleErrorSnackbar(log_status);
            alert(log_status);
        } else {
            this.setState({
                file_input: '',
                file_full: null,
                file_type: '',
                file_notes: '',
                attach_loading: false
            })
        }
    }

    downloadFile = async (key, name) => {
        const data = {
            key: key,
            title: name
        }
        const log_status = await this.props.downloadAttachmentOffer(data);
        if (log_status.includes('Error:')) {
            //this.props.toggleErrorSnackbar(log_status);
            alert(log_status);
        }
    }

    deleteFile = async (id, key, onClose) => {
        const data = {
            key: key,
            id: id
        }
        const deal_id = this.props.id;
        const log_status = await this.props.deleteAttachmentOffer(data, deal_id);
        if (log_status.includes('Error:')) {
            //this.props.toggleErrorSnackbar(log_status);
            alert(log_status);
        }
        onClose();
    }

    deleteFileDialog = (id, key) => {
        console.log(id);
        const addDialog = ({ onClose }) => {
            return (
                <div class="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Delete File</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Are you sure you'd like to delete this file?
                        </div>
                        <div style={{ marginTop: 10 }} class="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => this.deleteFile(id, key, onClose)}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => this.handleDialogClose(onClose)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )
        }

        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }

        confirmAlert(options)
    }

    closeSnackbar = () => {
        this.setState({
            attach_loading: false
        })
    }

    handleDialogClose = (onClose) => {
        onClose();
    }

    render() {

        return (
            <div className="enterdealMain">
                <StatusSnackbar open={this.state.attach_loading} closeSnackbar={this.closeSnackbar} message={'Attachment uploading'} />
                <div style={{ width: '100%', height: '80%' }}>
                    <div style={{ marginTop: 15, marginLeft: 50, height: 250, width: '90%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd', overflowY: 'scroll' }}>
                        <table style={{ width: '100%', height: 25 }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '20%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Attachment</th>
                                    <th style={{ width: '10%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Type</th>
                                    <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Notes</th>
                                    <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Date</th>
                                    <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Added By</th>
                                    <th style={{ width: '10%', color: '#fff', backgroundColor: 'rgb(1, 22, 48)' }}> </th>
                                </tr>
                            </thead>
                        </table>
                        {this.props.attachments.map((item, index) => {

                            const d = new Date(item.date);
                            const date = `${(d.getMonth() + 1)}/${d.getDate()}/${d.getFullYear()}`;

                            var user = "";
                            this.props.company.comp_users.map((c, i) => {
                                console.log(c)
                                if (item.user === c.tag) {
                                    user = c.name.first + " " + c.name.last;
                                }
                            })

                            return (
                                <div key={index} className="attachment_table">
                                    <table style={{ width: '100%', height: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '20%', color: '#000', fontSize: 12, padding: 5 }}>{item.name}</td>
                                                <td style={{ width: '10%', color: '#000', fontSize: 12, padding: 5, textTransform: 'capitalize' }}>{item.type}</td>
                                                <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{item.notes}</td>
                                                <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{date}</td>
                                                <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{user}</td>
                                                <td style={{ width: '10%', color: '#000', padding: 5 }}>
                                                    <FaFileDownload className="attachment_btns" size="18" onClick={() => this.downloadFile(item.key, item.name)} />
                                                    <FaTimesCircle style={{ marginLeft: 15 }} className="attachment_btns" size="18" onClick={() => this.deleteFileDialog(item._id, item.key)} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    tag: state.auth.user.tag,
    company: state.company,
});

export default connect(mapStateToProps, { uploadAttachmentOffer, downloadAttachmentOffer, deleteAttachmentOffer })(Attachments);