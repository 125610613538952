import React, { Component } from 'react';
import WhiteLogo from '../assets/WhiteLogo.png';
import loginBackdrop from '../assets/loginBackdrop.png';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from "../components/history";
import { login, verify, updatePassword, logout, verifyToken, createPassword } from '../actions/authActions';
import { clearErrors } from '../actions/errorActions';
import { FaRegCopyright } from 'react-icons/fa';

import '../App.css';

class ResetPasswordToken extends Component {

    constructor() {
        super();
        this.state = {
            error: null,
            email: "",
            temp_pass: "",
            password: "",
            password_conf: "",
            type: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        error: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired,
    }

    async componentDidMount() {
        this.props.logout();
        // const url = new URL(window.location);
        // let search = url.search;
        // let params = new URLSearchParams(search);
        //let type = params.get('ty');
        //let token = params.get('tn');

        let type = await this.getUrlParameter('ty')
        let token = await this.getUrlParameter('tn')
        console.log(token, type, 'TK')

        if (!token) {
            this.props.history.push('/')
        }

        const user = {
            token: token
        }

        const ver = await this.props.verifyToken(user);

        if (ver.type === 'error') {
            alert('Invalid or expired access token.  Please request a new password reset email or reach out to your company admin.')
            this.props.history.push('/')
        }

        if (type.toString() === 'create' || type === 'create') {
            this.setState({
                email: ver.user,
                temp_pass: ver.pass,
                type: type.toString(),
                init: ver._init
            })
        } else if (type.toString() === 'reset' || type === 'reset') {
            this.setState({
                email: ver.user,
                temp_pass: ver.pass,
                type: type.toString(),
                init: ver._init
            })
        } else {
            alert('Invalid request.')
            this.props.history.push('/')
        }

        // if(type.toString() !== 'reset' || 'create') {
        //     alert('Invalid request.')
        //     this.props.history.push('/')
        // }

        // const user = {
        //     email: email,
        //     password: pass
        // }

        // //Attempt to login
        // this.props.login(user);

        // if(this.props.match.params.email){
        //     this.setState({
        //         email: this.props.match.params.email
        //     })
        // }
    }

    getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(window.location.href);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    // componentDidUpdate(prevProps) {
    //     const { error, isAuthenticated } = this.props;
    //     if (error !== prevProps.error) {
    //         if (error.id === 'LOGIN_FAIL') {
    //             alert(error.msg.msg);
    //         }
    //     }
    // }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.password === this.state.password_conf) {
            const user = {
                email: this.state.email,
                temp_pass: this.state.temp_pass,
                password: this.state.password
            }

            if (this.state.type === 'reset') {
                var status = await this.props.updatePassword(user);
                if (status.msg.includes('Error:')) {
                    alert(status.msg)
                    this.setState({
                        error: status.msg
                    })
                } else {
                    alert('Created successfully!')
                    this.props.history.push('/')
                }
            } else if (this.state.type === 'create') {
                var status = await this.props.createPassword(user);
                if (status.msg.includes('Error:')) {
                    alert(status.msg)
                    this.setState({
                        error: status.msg
                    })
                } else {
                    alert('Created successfully!')
                    this.props.history.push('/')
                }
            } else {
                alert('Error: something went wrong!')
                this.props.history.push('/')
            }
        } else {
            alert('Passwords do not match')
            this.setState({
                password: "",
                password_conf: "",
            })
        }

    }

    render() {
        //<img src={WhiteLogo} width="200" height="210" />

        return (
            <div className="App">
                <section className="App-header">


                    <img style={{ position: 'relative' }} width="auto" height="625" src={loginBackdrop} />
                    <form
                        style={{ position: 'absolute', top: '45%', height: 'auto' }}
                        autoComplete="off"
                        onSubmit={this.onSubmit}
                    >
                        <div >
                            <input
                                className="Login-input1"
                                placeholder="Password"
                                name="password"
                                type="password"
                                onChange={this.handleChange}
                                value={this.state.password}
                            />
                        </div>
                        <div >
                            <input
                                className="Login-input2"
                                placeholder="Re-enter Password"
                                name="password_conf"
                                onChange={this.handleChange}
                                value={this.state.password_conf}
                                type="password"
                            />
                            <div className="form-group">
                                {this.state.error ? (
                                    <p style={{ color: 'red', fontSize: 12, width: '100%' }} className="text-danger">{this.state.error}</p>
                                ) : null}
                                <div className="mt-3">
                                    <button style={{ fontSize: 12, color: '#F2F2F2', fontWeight: 'bold' }} className="btn btn-secondary px-5" type="submit">Change Password</button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="mt-2">
                                    <Link style={{ fontSize: 10, color: '#ddd' }} type="submit" to="/login">Return</Link>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p style={{ fontSize: 10, color: 'rgb(184, 184, 184)' }}><FaRegCopyright style={{ marginTop: -2 }} size="12" color="rgb(184, 184, 184)" /> 2021 Solar System, LLC</p>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error
})

export default connect(mapStateToProps, { login, verify, updatePassword, clearErrors, logout, verifyToken, createPassword })(ResetPasswordToken);