export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const INIT_LOADING = "INIT_LOADING";
export const INIT_LOADED = "INIT_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CONNECT_ON = "CONNECT_ON";
export const CONNECT_OFF = "CONNECT_OFF";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const CHAT_SOCKET = "CHAT_SOCKET";
export const USER_UPDATED = "USER_UPDATED";
export const USER_UPDATED_GOOGLE = "USER_UPDATED_GOOGLE";
export const USERS_LOAD = "USERS_LOAD";
export const USERS_IMGS = "USERS_IMGS";
export const USERS_SOCKET = "USERS_SOCKET";
export const USERS_SOCKET_DELETE = "USERS_SOCKET_DELETE";
export const COMPANY_LOADING = "COMPANY_LOADING";
export const COMPANY_EMAIL_GROUPS = "COMPANY_EMAIL_GROUPS";
export const COMPANY_TEMPLATES = "COMPANY_TEMPLATES";
export const GET_COMP_USERS = "GET_COMP_USERS";
export const VENUES_INIT_LOADING = "VENUES_INIT_LOADING";
export const VENUES_LOADING = "VENUES_LOADING";
export const VENUES_GET = "VENUES_GET";
export const VENUES_FILTER = "VENUES_FILTER";
export const VENUES_SORT = "VENUES_SORT";
export const VENUES_SOCKET = "VENUES_SOCKET";
export const VENUES_SOCKET_DELETE = "VENUES_SOCKET_DELETE";
export const BUYERS_INIT_LOADING = "BUYERS_INIT_LOADING";
export const BUYERS_LOADING = "BUYERS_LOADING";
export const BUYERS_GET = "BUYERS_GET";
export const BUYERS_FILTER = "BUYERS_FILTER";
export const BUYERS_SORT = "BUYERS_SORT";
export const BUYERS_SOCKET = "BUYERS_SOCKET";
export const BUYERS_SOCKET_DELETE = "BUYERS_SOCKET_DELETE";
export const CLIENTS_INIT_LOADING = "CLIENTS_INIT_LOADING";
export const CLIENTS_LOADING = "CLIENTS_LOADING";
export const CLIENTS_GET = "CLIENTS_GET";
export const CLIENTS_FILTER = "CLIENTS_FILTER";
export const CLIENTS_FILTER_SUB = "CLIENTS_FILTER_SUB";
export const CLIENTS_SORT = "CLIENTS_SORT";
export const CLIENTS_SOCKET = "CLIENTS_SOCKET";
export const CLIENTS_SOCKET_DELETE = "CLIENTS_SOCKET_DELETE";
export const CONTACTS_LOADING = "CONTACTS_LOADING";
export const CONTACTS_GET = "CONTACTS_GET";
export const CONTACTS_FILTER = "CONTACTS_FILTER";
export const CONTACTS_SORT = "CONTACTS_SORT";
export const CONTACTS_SOCKET = "CONTACTS_SOCKET";
export const CONTACTS_SOCKET_DELETE = "CONTACTS_SOCKET_DELETE";
export const DASH_LOADING = "DASH_LOADING";
export const DASH_SET = "DASH_SET";
export const OFFERS_LOADING = "OFFERS_LOADING";
export const OFFERS_GET = "OFFERS_GET";
export const OFFERS_DB_INIT = "OFFERS_DB_INIT";
export const OFFERS_DASH_SET = "OFFERS_DASH_SET";
export const OFFERS_CAL_SET = "OFFERS_CAL_SET";
export const OFFERS_SORT = "OFFERS_SORT";
export const OFFERS_FILTER_RESET = "OFFERS_FILTER_RESET";
export const OFFERS_FILTER = "OFFERS_FILTER";
export const OFFERS_SOCKET = "OFFERS_SOCKET";
export const OFFERS_SOCKET_DELETE = "OFFERS_SOCKET_DELETE";
export const OFFERDETAIL_LOADING = "OFFERDETAIL_LOADING";
export const GET_OFFERDETAIL = "GET_OFFERDETAIL";
export const COUNTS_LOADING = "COUNTS_LOADING";
export const LOAD_COUNTS_ROL = "LOAD_COUNTS_ROL";
export const GET_COUNTS = "GET_COUNTS";
export const FILTER_COUNTS = "FILTER_COUNTS";
export const SORT_COUNTS = "SORT_COUNTS";
export const ADD_COUNT = "ADD_COUNT";
export const ADD_COUNT_SOCKET = "ADD_COUNT_SOCKET";
export const ADD_COUNT_BULK_SOCKET = "ADD_COUNT_BULK_SOCKET";
export const UPDATE_COUNT = "UPDATE_COUNT";
export const DELETE_COUNT = "DELETE_COUNT";
export const COUNT_COMPARE = "COUNT_COMPARE";
export const COUNT_FETCH = "COUNT_FETCH";
export const SET_COUNTS_GRID = "SET_COUNTS_GRID";
export const UPDATE_COUNTS_GRID = "UPDATE_COUNTS_GRID";
export const MARKETING_LOADING = "MARKETING_LOADING";
export const GET_MARKETING = "GET_MARKETING";
export const GET_SHORT_URL = "GET_SHORT_URL";
export const ADD_SHORT_URL = "ADD_SHORT_URL";
export const DELETE_SHORT_URL = "DELETE_SHORT_URL";
export const URL_DATA_LOADING = "URL_DATA_LOADING";
export const GET_URL_DATA = "GET_URL_DATA";
export const OFF_URL_DATA = "OFF_URL_DATA";
export const FESTIVALS_LOADING = "FESTIVALS_LOADING";
export const FESTIVALS_GET = "FESTIVALS_GET";
export const FESTIVALS_FILTER = "FESTIVALS_FILTER";
export const FESTIVALS_SORT = "FESTIVALS_SORT";
export const FESTIVALS_SOCKET = "FESTIVALS_SOCKET";
export const FESTIVALS_SOCKET_DELETE = "FESTIVALS_SOCKET_DELETE";
export const PDF_LOADING = "PDF_LOADING";
export const PDF_LOADED = "PDF_LOADED";
export const PDF_CLEAR = "PDF_CLEAR";
export const CALENDAR_LOADING = "CALENDAR_LOADING";
export const CALENDAR_GET = "CALENDAR_GET";
export const CALENDAR_SOCKET = "CALENDAR_SOCKET";
export const CALENDAR_SOCKET_DELETE = "CALENDAR_SOCKET_DELETE";
export const CALENDAR_SET = "CALENDAR_SET";
export const CALENDAR_RESET = "CALENDAR_RESET";
export const CALENDAR_FILTER = "CALENDAR_FILTER";
export const CALENDAR_FILTER_SUB = "CALENDAR_FILTER_SUB";
export const HOLDS_LOADING = "HOLDS_LOADING";
export const GET_HOLDS = "GET_HOLDS";
export const ADD_HOLDS = "ADD_HOLDS";
export const HOLDS_SOCKET = "HOLDS_SOCKET";
export const HOLDS_SOCKET_DELETE = "HOLDS_SOCKET_DELETE";
export const UPDATE_HOLDS = "UPDATE_HOLDS";
export const DELETE_HOLDS = "DELETE_HOLDS";
export const PACKAGING_LOADING = "PACKAGING_LOADING";
export const PACKAGING_GET = "PACKAGING_GET";
export const PACKAGING_FILTER = "PACKAGING_FILTER";
export const PACKAGING_SOCKET = "PACKAGING_SOCKET";
export const PACKAGING_SOCKET_DELETE = "PACKAGING_SOCKET_DELETE";
export const PACKAGING_SORT = "PACKAGING_SORT";
export const SPOTIFY_LOADING = "SPOTIFY_LOADING";
export const SPOTIFY_LOADED = "SPOTIFY_LOADED";
export const GET_SPOTIFY = "GET_SPOTIFY";
export const GET_SPOTIFY_DATA = "GET_SPOTIFY_DATA";
export const SOCIALS_LOADING = "SOCIALS_LOADING";
export const GET_INSTAGRAM = "GET_INSTAGRAM";
export const AR_LOADING = "AR_LOADING";
export const AR_GET = "AR_GET";
export const AR_FILTER = "AR_FILTER";
export const AR_SOCKET = "AR_SOCKET";
export const AR_SOCKET_DELETE = "AR_SOCKET_DELETE";
export const ACC_REC_LOADING = "ACC_REC_LOADING";
export const GET_ACC_REC = "GET_ACC_REC";
export const FINALS_LOADING = "FINALS_LOADING";
export const FINALS_LOAD = "FINALS_LOAD";
export const FINALS_SORT = "FINALS_SORT";
export const FINALS_FILTER = "FINALS_FILTER";
export const FINALS_SOCKET = "FINALS_SOCKET";
export const FINALS_SOCKET_DELETE = "FINALS_SOCKET_DELETE";