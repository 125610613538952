import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FaTrashAlt, FaSave, FaPlusSquare } from "react-icons/fa";

export default function EmailGroupsModal(props) {
    const [stateGroup, setGroup] = useState("");

    const handleChange = (event) => {
        const value = event.target.value;
        setGroup(value)
    }

    const saveGroup = () => {
        if (stateGroup) {
            const array = props.groups;
            nameCheck(array, stateGroup).then(value => {
                const data = {
                    value: { name: value }
                }
                console.log(data, 'DATA')
                props.saveEmailGroup(data);
                setGroup('');
            }).catch(err => {
                alert('Name already exists.  Please choose a new group name.')
            })
        } else {
            alert('Please enter a group name.')
        }

    }

    return (
        // <div style={{ position: 'absolute', width: 400, height: 'auto', top: '40%', left: '40%' }} className="react-confirm-alert-z2">
        //     <div style={{ width: '100%', height: '100%', padding: 15 }}>
        //         <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Modify Email Groups</h1>
        //         <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        //             <table style={{ width: '100%', marginBottom: 10, marginTop: 10, overflowY: 'scroll' }} id="email-dialog-table">
        //                 <thead>
        //                     <tr>
        //                         {/* <td style={{ width: '5%' }}><p style={{ fontSize: 10, height: 'auto', margin: 0, padding: 0 }}>{index + 1}</p></td> */}
        //                         <th style={{ width: '5%' }}> </th>
        //                         <th style={{ width: '60%' }}>Name</th>
        //                         <th style={{ width: '35%' }}>Subscribers</th>
        //                     </tr>
        //                 </thead>
        //                 {props.groups.map((item, index) => {
        //                     return (
        //                         <tbody key={index}>
        //                             <tr>
        //                                 {/* <td style={{ width: '5%' }}><p style={{ fontSize: 10, height: 'auto', margin: 0, padding: 0 }}>{index + 1}</p></td> */}
        //                                 <td style={{ width: '5%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>{index + 1}</p></td>
        //                                 <td style={{ width: '60%' }}><p style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>{item.name}</p></td>
        //                                 <td style={{ width: '35%' }}> </td>
        //                             </tr>
        //                         </tbody>
        //                     )
        //                 })}
        //             </table>
        //         </div>
        //         <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
        //             <button style={{ borderRadius: 10 }} onClick={props.close}>Cancel</button>
        //             <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { props.save(); props.close() }}>Save</button>
        //         </div>
        //     </div>
        // </div>

        <Dialog
            style={{ zIndex: 100 }}
            open={props.open}
            onClose={props.handleClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: '#000', color: '#cdcdcd' }} id="alert-dialog-title">Edit Company Email Groups:</DialogTitle>
            <DialogContent style={{ backgroundColor: '#cdcdcd', zIndex: 2 }}>
                <div style={{ width: 400, display: 'flex', flexDirection: 'column', height: 390 }}>
                    <div style={{ width: 400, display: 'flex', flexDirection: 'row' }}>
                        <input style={{ width: '100%', fontSize: 12, height: 26 }} type="text" name="group" placeholder="Enter new group name.." value={stateGroup} onChange={handleChange} />
                        <FaPlusSquare style={{ marginLeft: 3, cursor: 'pointer' }} size={26} color="#333" onClick={saveGroup} />
                    </div>
                    <table style={{ width: 400, marginBottom: 10, marginTop: 10 }} id="email-dialog-table">
                        <thead>
                            <tr>
                                {/* <td style={{ width: '5%' }}><p style={{ fontSize: 10, height: 'auto', margin: 0, padding: 0 }}>{index + 1}</p></td> */}
                                <th style={{ width: '5%' }}> </th>
                                <th style={{ width: '60%' }}>Name</th>
                                <th style={{ width: '25%' }}><span style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Subscribers</span></th>
                                <th style={{ width: '10%' }}> </th>
                            </tr>
                        </thead>
                        {props.groups.map((item, index) => {
                            return (
                                <tbody key={index}>
                                    <tr>
                                        {/* <td style={{ width: '5%' }}><p style={{ fontSize: 10, height: 'auto', margin: 0, padding: 0 }}>{index + 1}</p></td> */}
                                        <td style={{ width: '5%' }}><span style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><p style={{ fontSize: 12, margin: 0, padding: 0 }}>{index + 1}</p></span></td>
                                        <td style={{ width: '60%' }}><p style={{ fontSize: 12, margin: 0, padding: 0 }}>{item.name}</p></td>
                                        <td style={{ width: '25%' }}><span style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><p style={{ fontSize: 12, margin: 0, padding: 0 }}>{item.subs ? item.subs.length === 0 ? "-" : item.subs.length : ""}</p></span></td>
                                        <td style={{ width: '10%' }}><span style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><FaTrashAlt style={{ cursor: 'pointer' }} size={16} color="#000" onClick={() => props.removeGroup(item)} /></span></td>
                                    </tr>
                                </tbody>
                            )
                        })}
                    </table>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#000' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: '#000' }} onClick={props.close}>Close</Button>
                {/* <Button style={{ backgroundColor: '#cdcdcd', color: '#000' }} onClick={() => { props.save(); props.close() }} autoFocus>Save</Button> */}
            </DialogActions>
        </Dialog>
    );
}

function nameCheck(array, stateGroup) {
    return new Promise(async (resolve, reject) => {
        const indexCheck = array.findIndex(item => item.name === stateGroup);
        if (indexCheck < 0) {
            resolve(stateGroup)
        } else {
            reject()
        }
    })
}