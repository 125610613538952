import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import _ from 'lodash';

class ClientFinancials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commission_percent: 10,
            end_date: new Date(moment()),
            start_date: new Date(moment().subtract(1, 'year')),
            conf_total: 0,
            conf_guarantees: 0,
            conf_gross: 0,
            commission_total: 0,
            conf_guarantees_us: 0,
            conf_gross_us: 0,
            commission_total_us: 0,
            settled_total: 0,
            unsettled_total: 0,
            pend_total: 0,
            pend_guarantees: 0,
        };
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    static propTypes = {
        offers: PropTypes.object.isRequired,
    }

    componentDidMount() {

        const offers = this.props.offers.offers;
        const artist_id = this.props.artist_id;

        var start_date = new Date(moment().subtract(1, 'year'));
        var end_date = new Date(moment());

        var offer_filter = offers.filter((item) => {
            const today = moment();
            var val = _.get(item, 'show_info')
            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            var filterBatch = new Date(closest.date)
            var start = new Date(start_date)
            return filterBatch > start
        })
        offer_filter = offer_filter.filter((item) => {
            const today = new Date();
            var val = _.get(item, 'show_info')
            const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            var filterBatch = new Date(furthest.date)
            var end = new Date(end_date)
            return filterBatch <= end
        })

        var offer_filter_conf = offer_filter.filter(item => item.status === 'Confirmed' && item.client_id === artist_id);
        var offer_filter_unset = offer_filter_conf.filter(item => item.prog.settled === false);
        var offer_filter_set = offer_filter_conf.filter(item => item.prog.settled === true);
        const comm_base = this.props.commission_base;

        // const guarantee_total_conf = offer_filter_conf.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);
        const guarantee_total_conf_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);
        const guarantee_total_conf_s = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);

        const gross_total_conf = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.settlement_gross) { return cnt + Number(o.settlement_gross) } else { return cnt + 0 }; }, 0);
        const gross_total_conf_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.settlement_gross) { return cnt + Number(o.settlement_gross) } else if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);

        const commission_total = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.commission) { return cnt + Number(o.commission) } else { return cnt + 0 }; }, 0);
        const commission_total_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.commission) { return cnt + Number(o.commission) } else if (o.settlement_gross) { return cnt + (Number(o.settlement_gross) * (comm_base / 100)) } else if (o.guarantee) { return cnt + (Number(o.guarantee) * (comm_base / 100)) } else { return cnt + 0 }; }, 0);

        var offer_filter_pend = offer_filter.filter(item => item.status === 'Pending' && item.client_id === artist_id);
        const guarantee_total_pend = offer_filter_pend.reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);

        this.setState({
            start_date: start_date,
            end_date: end_date,
            conf_total: offer_filter_conf.length,
            conf_guarantees: guarantee_total_conf_s,
            conf_gross: gross_total_conf,
            commission_total: commission_total,
            settled_total: offer_filter_set.length,
            unsettled_total: offer_filter_unset.length,
            conf_guarantees_us: guarantee_total_conf_us,
            conf_gross_us: gross_total_conf_us,
            commission_total_us: commission_total_us,
            pend_total: offer_filter_pend.length,
            pend_guarantees: guarantee_total_pend
        })

    }

    handleStartDateChange(d) {

        const offers = this.props.offers.offers;
        const artist_id = this.props.artist_id;

        var start_date = new Date(d);
        var end_date = new Date(this.state.end_date);

        var offer_filter = offers.filter((item) => {
            const today = moment();
            var val = _.get(item, 'show_info')
            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            var filterBatch = new Date(closest.date)
            var start = new Date(start_date)
            return filterBatch > start
        })
        offer_filter = offer_filter.filter((item) => {
            const today = new Date();
            var val = _.get(item, 'show_info')
            const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            var filterBatch = new Date(furthest.date)
            var end = new Date(end_date)
            return filterBatch <= end
        })

        var offer_filter_conf = offer_filter.filter(item => item.status === 'Confirmed' && item.client_id === artist_id);
        var offer_filter_unset = offer_filter_conf.filter(item => item.prog.settled === false);
        var offer_filter_set = offer_filter_conf.filter(item => item.prog.settled === true);
        const comm_base = this.props.commission_base;

        // const guarantee_total_conf = offer_filter_conf.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);
        const guarantee_total_conf_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);
        const guarantee_total_conf_s = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);

        const gross_total_conf = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.settlement_gross) { return cnt + Number(o.settlement_gross) } else { return cnt + 0 }; }, 0);
        const gross_total_conf_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.settlement_gross) { return cnt + Number(o.settlement_gross) } else if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);

        const commission_total = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.commission) { return cnt + Number(o.commission) } else { return cnt + 0 }; }, 0);
        const commission_total_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.commission) { return cnt + Number(o.commission) } else if (o.settlement_gross) { return cnt + (Number(o.settlement_gross) * (comm_base / 100)) } else if (o.guarantee) { return cnt + (Number(o.guarantee) * (comm_base / 100)) } else { return cnt + 0 }; }, 0);

        var offer_filter_pend = offer_filter.filter(item => item.status === 'Pending' && item.client_id === artist_id);
        const guarantee_total_pend = offer_filter_pend.reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);

        this.setState({
            start_date: start_date,
            conf_total: offer_filter_conf.length,
            conf_guarantees: guarantee_total_conf_s,
            conf_gross: gross_total_conf,
            commission_total: commission_total,
            settled_total: offer_filter_set.length,
            unsettled_total: offer_filter_unset.length,
            conf_guarantees_us: guarantee_total_conf_us,
            conf_gross_us: gross_total_conf_us,
            commission_total_us: commission_total_us,
            pend_total: offer_filter_pend.length,
            pend_guarantees: guarantee_total_pend
        })
    }

    handleEndDateChange(d) {

        const offers = this.props.offers.offers;
        const artist_id = this.props.artist_id;

        var start_date = new Date(this.state.start_date);
        var end_date = new Date(d);

        var offer_filter = offers.filter((item) => {
            const today = moment();
            var val = _.get(item, 'show_info')
            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            var filterBatch = new Date(closest.date)
            var start = new Date(start_date)
            return filterBatch > start
        })
        offer_filter = offer_filter.filter((item) => {
            const today = new Date();
            var val = _.get(item, 'show_info')
            const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            var filterBatch = new Date(furthest.date)
            var end = new Date(end_date)
            return filterBatch <= end
        })

        var offer_filter_conf = offer_filter.filter(item => item.status === 'Confirmed' && item.client_id === artist_id);
        var offer_filter_unset = offer_filter_conf.filter(item => item.prog.settled === false);
        var offer_filter_set = offer_filter_conf.filter(item => item.prog.settled === true);
        const comm_base = this.props.commission_base;

        // const guarantee_total_conf = offer_filter_conf.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);
        const guarantee_total_conf_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);
        const guarantee_total_conf_s = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);

        const gross_total_conf = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.settlement_gross) { return cnt + Number(o.settlement_gross) } else { return cnt + 0 }; }, 0);
        const gross_total_conf_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.settlement_gross) { return cnt + Number(o.settlement_gross) } else if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);

        const commission_total = offer_filter_set.map(x => x.financials).reduce(function (cnt, o) { if (o.commission) { return cnt + Number(o.commission) } else { return cnt + 0 }; }, 0);
        const commission_total_us = offer_filter_unset.map(x => x.financials).reduce(function (cnt, o) { if (o.commission) { return cnt + Number(o.commission) } else if (o.settlement_gross) { return cnt + (Number(o.settlement_gross) * (comm_base / 100)) } else if (o.guarantee) { return cnt + (Number(o.guarantee) * (comm_base / 100)) } else { return cnt + 0 }; }, 0);

        var offer_filter_pend = offer_filter.filter(item => item.status === 'Pending' && item.client_id === artist_id);
        const guarantee_total_pend = offer_filter_pend.reduce(function (cnt, o) { if (o.guarantee) { return cnt + Number(o.guarantee) } else { return cnt + 0 }; }, 0);

        this.setState({
            end_date: end_date,
            conf_total: offer_filter_conf.length,
            conf_guarantees: guarantee_total_conf_s,
            conf_gross: gross_total_conf,
            commission_total: commission_total,
            settled_total: offer_filter_set.length,
            unsettled_total: offer_filter_unset.length,
            conf_guarantees_us: guarantee_total_conf_us,
            conf_gross_us: gross_total_conf_us,
            commission_total_us: commission_total_us,
            pend_total: offer_filter_pend.length,
            pend_guarantees: guarantee_total_pend
        })
    }

    render() {

        // const offers = this.props.offers.offers;
        // const artist_id = this.props.artist_id;

        // var end_date = this.state.end_date;
        // var start_date = this.state.start_date;
        // var offer_filter = offers.filter((item) => {
        //     const today = moment();
        //     var val = _.get(item, 'show_info')
        //     const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
        //     var filterBatch = new Date(closest.date)
        //     var start = new Date(start_date)
        //     return filterBatch > start
        // })
        // offer_filter = offer_filter.filter((item) => {
        //     const today = new Date();
        //     var val = _.get(item, 'show_info')
        //     const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
        //     var filterBatch = new Date(furthest.date)
        //     var end = new Date(end_date)
        //     return filterBatch <= end
        // })

        // var offer_filter_conf = offer_filter.filter(item => item.status === 'Confirmed' && item.client_id === artist_id);
        // const guarantee_total_conf = offer_filter_conf.map(x => x.financials).reduce(function (cnt, o) { return cnt + Number(o.guarantee); }, 0);
        // const gross_total_conf = offer_filter_conf.map(x => x.financials).reduce(function (cnt, o) { return cnt + Number(o.settlement_gross); }, 0);
        // const commission_total = offer_filter_conf.map(x => x.financials).reduce(function (cnt, o) { return cnt + Number(o.commission); }, 0);

        //const captotalval = parseInt(captotal, 10)
        //console.log(guarantee_total_conf, offer_filter) 

        return (
            <div style={{ display: 'flex', width: '100%', height: 500, overflowY: 'scroll' }}>
                <div style={{ width: '95%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', alignItems: 'center', textAlign: 'center', marginLeft: 60, marginTop: 30, width: '30%', backgroundColor: 'rgb(1, 22, 48)' }} className="form-control">
                        <label style={{ width: '100%', fontSize: 14, color: '#cdcdcd' }}>Base Commission Percentage:</label>
                        <NumberFormat style={{ fontSize: 14, width: '40%', border: '.4px solid #cdcdcd', color: '#000', backgroundColor: '#fff', textAlign: 'center' }} displayType={this.props.edit ? null : 'text'} value={this.props.commission_base} thousandSeparator={true} suffix="%" onChange={this.props.handleCommChange} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', marginLeft: 60, marginTop: 30, width: '65%', backgroundColor: 'rgb(1, 22, 48)' }} className="form-control">
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10, height: 28 }}>
                            <label style={{ width: '16%', fontSize: 14, color: '#cdcdcd' }}>Start Date:</label>
                            <DatePicker
                                className="react-datepicker"
                                //disabled={this.props.edit ? false : true}
                                //showTimeSelect
                                selected={this.state.start_date}
                                onChange={this.handleStartDateChange}
                            //dateFormat="M/dd/yyyy h:mm aa z"
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10, height: 28 }}>
                            <label style={{ width: '16%', fontSize: 14, color: '#cdcdcd' }}>End Date:</label>
                            <DatePicker
                                className="react-datepicker"
                                //disabled={this.props.edit ? false : true}
                                //showTimeSelect
                                selected={this.state.end_date}
                                onChange={this.handleEndDateChange}
                            //dateFormat="M/dd/yyyy h:mm aa z"
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 30 }}>
                            <table style={{ width: '100%', marginBottom: 20 }} id="customers">
                                <tbody>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Confirmed Offers</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={this.state.conf_total} thousandSeparator={true} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ width: '100%', marginBottom: 20 }} id="customers">
                                <tbody>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Settled Shows</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={this.state.settled_total} thousandSeparator={true} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Settled Guarantees</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={Number(this.state.conf_guarantees).toFixed(2)} thousandSeparator={true} prefix={'$'} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Gross Earnings</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={Number(this.state.conf_gross).toFixed(2)} thousandSeparator={true} prefix={'$'} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Agency Commission</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={Number(this.state.commission_total).toFixed(2)} thousandSeparator={true} prefix={'$'} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ width: '100%', marginBottom: 20 }} id="customers">
                                <tbody>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Unsettled Shows</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={this.state.unsettled_total} thousandSeparator={true} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Unsettled Guarantees</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={Number(this.state.conf_guarantees_us).toFixed(2)} thousandSeparator={true} prefix={'$'} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Gross Earnings</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={Number(this.state.conf_gross_us).toFixed(2)} thousandSeparator={true} prefix={'$'} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Agency Commission</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={Number(this.state.commission_total_us).toFixed(2)} thousandSeparator={true} prefix={'$'} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ width: '100%', marginBottom: 10 }} id="customers">
                                <tbody>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Pending Offers</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={this.state.pend_total} thousandSeparator={true} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '25%', fontSize: 14, paddingLeft: 10, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }}>Pending Guarantees</td>
                                        <td style={{ width: '75%' }}><NumberFormat style={{ fontSize: 14, color: '#000', paddingLeft: 10 }} displayType={'text'} value={Number(this.state.pend_guarantees).toFixed(2)} thousandSeparator={true} prefix={'$'} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


const mapStateToProps = state => ({
    offers: state.offers,
});

export default connect(mapStateToProps, null)(ClientFinancials);