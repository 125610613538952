import {
    HOLDS_LOADING,
    GET_HOLDS,
    ADD_HOLDS,
    HOLDS_SOCKET,
    HOLDS_SOCKET_DELETE,
    UPDATE_HOLDS,
    DELETE_HOLDS,
} from "../actions/types";
import moment from 'moment';

const initialState = {
    holds: [],
    holds_cal: [],
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case HOLDS_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_HOLDS:
            var holds = action.payload;
            // var array = [];
            // if(holds && holds.length > 0){
            //     holds.map(item => {
            //         var color = [];
            //         color.push('#ddd');
            //         item.holds.forEach((h, i) => {
            //             //var start = moment(h.date).format('YYYY-MM-DD');
            //             const arrayOfApp = { title: item.artist_name, venue: item.venue_name, start: new Date(h.date), end: new Date(h.date), description: item.client + '<br>' + '( Holds )' + '<br>' + item.venue, id: item._id, backgroundColor: '#fff', borderColor: color, sub: "holds", hold: h.hold }
            //             array.push(arrayOfApp);
            //         })
            //     })
            // }
            return {
                ...state,
                holds: action.payload,
                //holds_cal: array,
                loading: false
            };
        case ADD_HOLDS:
            return {
                ...state,
                //contacts: [action.payload, ...state.contacts],
            };
        case HOLDS_SOCKET:
            var data = state.holds;
            var index = data.findIndex(obj => obj._id === action.payload._id);
            if (index >= 0) {
                data[index] = action.payload;
            } else {
                data = [action.payload, ...data]
            }
            return {
                ...state,
                holds: data
            };
        case HOLDS_SOCKET_DELETE:
            var data = state.holds.filter(holds => holds._id !== action.payload._id);
            return {
                ...state,
                holds: data,
            };
        case DELETE_HOLDS:
            return {
                ...state,
                holds: state.holds.filter(data => data._id !== action.payload._id),
            };
        default:
            return state;
    }
}