import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EmailAutocomplete from '../autocompletes/email-recipient-autocomplete';
import RichTextEditor from 'react-rte';
import AttachmentsAutocomplete from '../autocompletes/email-attachment-autocomplete';
import { FaEnvelopeOpenText, FaEnvelope, FaPlus } from 'react-icons/fa';

export default function TcEmailDialog(props) {
    const [emailArray, setEmailArray] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [stateRecipients, setStateRecipients] = useState([]);
    // const [stateMessage, setStateMessage] = useState("Hi {Count Contact} - Please send ticket counts for the following shows.");
    const [stateMessage, setStateMessage] = useState(RichTextEditor.createValueFromString('<p>Hi {contact_name},</p><p><br></p><p>Please send ticket counts for the following shows:</p><p><br></p><p>{shows}</p><p><br></p><p>Thanks,</p>', 'html'));

    useEffect(() => {
        const arr = props.array;
        setEmailArray(arr);
    }, [props.array])

    // useEffect(() => {
    //     var message = RichTextEditor.createEmptyValue();
    //     message._cache.html = "<p>Hi - <br/><br/>Please send ticket counts for the following shows</p>";
    //     console.log(message)
    //     setStateMessage(message);
    // }, [props])

    const onCheckboxChange = (i) => {
        var newArray = emailArray;
        newArray[i].active = !newArray[i].active;
        setEmailArray(newArray);
        setRerender(!rerender);
    }

    const handleRecChange = (rec) => {
        if (rec) {
            var newRec = stateRecipients.filter(item => item._id === rec._id);
            var newArr = [...rec, ...newRec]
            setStateRecipients(newArr)
        } else {
            setStateRecipients([])
        }
    }

    const handleEmailBlur = (rec) => {
        if (rec) {
            var newArr = [...stateRecipients, rec];
            setStateRecipients(newArr);
        }
    }

    const handleChange = (e) => {
        var name = e.target.name;
        var val = e.target.value;
        if (name === 'stateMessage') {
            setStateMessage(val)
        }
    };

    const handleMessageChange = (val) => {
        console.log("message", val.toString('html'))
        setStateMessage(val)
    };

    const onSend = () => {
        // const email = {
        //     array: emailArray,
        //     cc: stateRecipients,
        //     message: stateMessage.toString('html')
        // }
        // console.log(email)
        props.send(emailArray, stateRecipients, stateMessage.toString('html'))
    }

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: [
            "INLINE_STYLE_BUTTONS",
            "BLOCK_TYPE_BUTTONS",
            "LINK_BUTTONS",
            "HISTORY_BUTTONS"
        ],
        INLINE_STYLE_BUTTONS: [
            { label: "Bold", style: "BOLD", className: "custom-css-class" },
            { label: "Italic", style: "ITALIC" },
            { label: "Underline", style: "UNDERLINE" },
            {
                label: "Strikethrough",
                style: "STRIKETHROUGH"
            }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: "UL", style: "unordered-list-item" },
            { label: "OL", style: "ordered-list-item" },
            { label: "Blockquote", style: "blockquote" }
        ],
        LINK_BUTTONS: [
            { label: "Link", style: "LINK" },
            { label: "Remove Link", style: "REMOVE_LINK" }
        ],
        HISTORY_BUTTONS: [
            { label: "Undo", style: "UNDO" },
            { label: "Redo", style: "REDO" }
        ]
    };

    const email_count = emailArray.filter(item => item.active);

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={() => props.close()}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Send Count Emails</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div className="email-dialog-content-wrapper">
                    <table id="email-dialog-table">
                        <thead>
                            <tr>
                                <th>Total Emails: {email_count.length}</th>
                            </tr>
                        </thead>
                    </table>
                    <div className="email-dialog-table-wrapper">
                        <table id="email-dialog-table">
                            {emailArray.map((item, index) => {
                                return (
                                    <tbody key={index}>
                                        <tr>
                                            {/* <td style={{ width: '5%' }}><p style={{ fontSize: 10, height: 'auto', margin: 0, padding: 0 }}>{index + 1}</p></td> */}
                                            <td style={{ width: '5%' }}><Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#fff', color: '#000' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#fff', color: '#000' }} fontSize="small" />}
                                                color="default"
                                                checked={item.active}
                                                // value={props.checked}
                                                name="count_checked"
                                                onChange={() => onCheckboxChange(index)}
                                            /></td>
                                            <td style={{ width: '25%' }}>{item.contacts.map((con, cIndex) => {
                                                if (cIndex > 0) {
                                                    return (
                                                        <p key={cIndex} style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>{con.email}</p>
                                                    )
                                                } else {
                                                    return (
                                                        <p key={cIndex} style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>{con.email}</p>
                                                    )
                                                }
                                            })}</td>
                                            <td style={{ width: '70%' }}>{item.shows.map((show, sIndex) => {
                                                if (sIndex > 0) {
                                                    return (
                                                        <p key={sIndex} style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>{show}</p>
                                                    )
                                                } else {
                                                    return (
                                                        <p key={sIndex} style={{ fontSize: 12, height: 'auto', margin: 0, padding: 0 }}>{show}</p>
                                                    )
                                                }
                                            })}</td>
                                        </tr>
                                    </tbody>
                                )
                            })}
                        </table>
                    </div>
                    <div style={{ width: '100%', minHeight: 30, display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                        <span style={{ width: '10%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>CC:</h2>
                        </span>
                        <div style={{ width: '90%', height: 'auto' }}>
                            <EmailAutocomplete onAgentChange={handleRecChange} agentSelect={stateRecipients} edit={true} agentList={props.data.length > 0 ? props.data[0].contact_data : []} handleEmailBlur={handleEmailBlur} />
                        </div>
                    </div>
                    <div style={{ width: '100%', height: 'auto', minHeight: 180, marginTop: 15, display: 'flex', flexDirection: 'row', overflowY: 'scroll' }}>
                        <span style={{ width: '100%', height: 'auto' }}>
                            <RichTextEditor
                                autoFocus
                                value={stateMessage}
                                onChange={handleMessageChange}
                                className="e-rte-content"
                                toolbarConfig={toolbarConfig}
                            />
                        </span>
                        {/* <textarea style={{ width: '100%', height: 120, marginTop: 5, fontSize: 12, whiteSpace: 'pre-line' }} placeholder="Message" name="stateMessage" value={stateMessage} onChange={handleChange} /> */}
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={() => props.close()}>Close</Button>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={() => onSend()}>Send</Button>
            </DialogActions>
        </Dialog>
    );
}