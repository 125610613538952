import axios from "axios";
import { tokenConfig, tokenConfigFile } from './authActions';
import { returnErrors } from './errorActions';
import {
    OFFERS_LOADING,
    OFFERDETAIL_LOADING,
    OFFERS_GET,
    OFFERS_DB_INIT,
    OFFERS_DASH_SET,
    OFFERS_CAL_SET,
    GET_OFFERDETAIL,
    OFFERS_FILTER,
    OFFERS_SORT,
    OFFERS_SOCKET,
    OFFERS_SOCKET_DELETE,
    NOTIFICATIONS
} from "./types";
import _ from 'lodash';
import moment from 'moment';

export const setOffersLoading = () => {
    return {
        type: OFFERS_LOADING
    };
};

export const setOfferDetailLoading = () => {
    return {
        type: OFFERDETAIL_LOADING
    };
};

export const getOffers = () => async (dispatch, getState) => {
    dispatch(setOffersLoading());
    return axios.get(`${process.env.REACT_APP_API_CLIENT}/offers/`, tokenConfig(getState))
        .then(res => {
            var venue_array = [];
            var buyer_array = [];
            var contact_array = [];

            console.log(res.data, "RES DATA")
            res.data.map(item => {
                venue_array.push(item.venue_id);
                buyer_array.push(item.buyer_id);
                if (item.contacts.buyer_contact) {
                    contact_array.push(item.contacts.buyer_contact)
                }
                if (item.contacts.signatory) {
                    contact_array.push(item.contacts.signatory)
                }
                if (item.signatory) {
                    contact_array.push(item.contacts.signatory)
                }
                if (item.contacts.add_contacts.length > 0) {
                    item.contacts.add_contacts.map(c => {
                        if (c.contact_id) {
                            contact_array.push(c.contact_id)
                        }
                    })
                }
            })
            dispatch({
                type: OFFERS_GET,
                payload: res.data
            })

            const need_array = {
                venue_ids: venue_array,
                buyer_ids: buyer_array,
                contact_ids: contact_array
            }

            return need_array
        })
        .catch(err => {
            console.log(err.response.data)
            dispatch(returnErrors(err.response.data, err.response.status))
        });
};

export const getOfferDetail = id => (dispatch, getState) => {
    //dispatch(setOfferDetailLoading());
    return axios.get(`${process.env.REACT_APP_API_CLIENT}/offerdetail/${id}`, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: GET_OFFERDETAIL,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};

export const applyOfferFilter = (offer_filter) => (dispatch, getState) => {
    dispatch(setOffersLoading());
    dispatch({
        type: OFFERS_FILTER,
        payload: offer_filter
    })
};

export const offerSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: OFFERS_SORT,
        payload: sort
    })
};

export const offerSocket = offer => (dispatch, getState) => {
    const { items } = getState();
    const { buyers } = getState();
    const { clients } = getState();
    const { festivals } = getState();
    const venues = items;

    var artist_name = "";
    var venue_name = "";
    var venue_address = {};
    var venue_city = "";
    var venue_state = "";
    var venue_country = "";
    var buyer_company = "";
    var buyer_address = {};

    clients.clients.map((c, i) => {
        if (offer.client_id === c._id) {
            artist_name = c.client
        }
    })
    venues.items.map((v, i) => {
        if (offer.venue_id === v._id) {
            venue_name = v.venue_name;
            venue_address = v.address;
            venue_city = v.address.city;
            venue_state = v.address.state;
            venue_country = v.address.country;
        }
    })
    festivals.items.map((f, i) => {
        if (offer.venue_id === f._id) {
            venue_name = f.festival;
            venue_address = f.address;
            venue_city = f.address.city;
            venue_state = f.address.state;
            venue_country = f.address.country;
        }
    })
    buyers.buyers.map((b, i) => {
        if (offer.buyer_id === b._id) {
            buyer_company = b.company;
            buyer_address = b.address;
        }
    })

    const newOffer = {
        ...offer,
        artist_name: artist_name,
        venue_name: venue_name,
        venue_address: venue_address,
        venue_city: venue_city,
        venue_state: venue_state,
        venue_country: venue_country,
        buyer_company: buyer_company,
        buyer_address: buyer_address
    }

    dispatch({
        type: OFFERS_SOCKET,
        payload: newOffer
    })
}

export const offerCompose = offer => (dispatch, getState) => {
    const { items } = getState();
    const { buyers } = getState();
    const { clients } = getState();
    const { festivals } = getState();
    const venues = items;

    var artist_name = "";
    var venue_name = "";
    var venue_address = {};
    var venue_city = "";
    var venue_state = "";
    var venue_country = "";
    var buyer_company = "";
    var buyer_address = {};

    clients.clients.map((c, i) => {
        if (offer.client_id === c._id) {
            artist_name = c.client
        }
    })
    venues.items.map((v, i) => {
        if (offer.venue_id === v._id) {
            venue_name = v.venue_name;
            venue_address = v.address;
            venue_city = v.address.city;
            venue_state = v.address.state;
            venue_country = v.address.country;
        }
    })
    festivals.items.map((f, i) => {
        if (offer.venue_id === f._id) {
            venue_name = f.festival;
            venue_address = f.address;
            venue_city = f.address.city;
            venue_state = f.address.state;
            venue_country = f.address.country;
        }
    })
    buyers.buyers.map((b, i) => {
        if (offer.buyer_id === b._id) {
            buyer_company = b.company;
            buyer_address = b.address;
        }
    })

    const newOffer = {
        ...offer,
        artist_name: artist_name,
        venue_name: venue_name,
        venue_address: venue_address,
        venue_city: venue_city,
        venue_state: venue_state,
        venue_country: venue_country,
        buyer_company: buyer_company,
        buyer_address: buyer_address
    }

    return newOffer
}

export const deleteOfferSocket = offer => (dispatch) => {
    dispatch({
        type: OFFERS_SOCKET_DELETE,
        payload: offer
    })
}

export const getOfferDetailItinerary = ids => (dispatch, getState) => {
    dispatch(setOfferDetailLoading());
    axios.post(`${process.env.REACT_APP_API_CLIENT}/offerdetail/itinerary`, ids, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: GET_OFFERDETAIL,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const offerCombine = (from) => async (dispatch, getState) => {
    dispatch(setOffersLoading());
    const { offers } = getState();
    const { items } = getState();
    const { buyers } = getState();
    const { clients } = getState();
    const { festivals } = getState();
    const venues = items;

    var newArray = [];
    offers.offers.map((item, index) => {
        var artist_name = "";
        var venue_address = {};
        var venue_name = "";
        var venue_city = "";
        var venue_state = "";
        var venue_country = "";
        var buyer_company = "";
        var buyer_address = {};

        var cli_ind = clients.clients.findIndex(c => item.client_id === c._id)
        if (cli_ind >= 0) {
            artist_name = clients.clients[cli_ind].client
        }

        var ven_ind = venues.items.findIndex(v => item.venue_id === v._id)
        if (ven_ind >= 0) {
            venue_name = venues.items[ven_ind].venue_name;
            venue_city = venues.items[ven_ind].address.city;
            venue_state = venues.items[ven_ind].address.state;
            venue_country = venues.items[ven_ind].address.country;
            venue_address = venues.items[ven_ind].address;
        }

        var fest_ind = festivals.items.findIndex(f => item.venue_id === f._id);
        if (fest_ind >= 0) {
            venue_name = festivals.items[fest_ind].festival;
            venue_city = festivals.items[fest_ind].address.city;
            venue_state = festivals.items[fest_ind].address.state;
            venue_country = festivals.items[fest_ind].address.country;
            venue_address = festivals.items[fest_ind].address;
        }

        var buy_ind = buyers.buyers.findIndex(b => item.buyer_id === b._id);
        if (buy_ind >= 0) {
            buyer_company = buyers.buyers[buy_ind].company;
            buyer_address = buyers.buyers[buy_ind].address;
        }

        const items = {
            ...item,
            artist_name: artist_name,
            venue_name: venue_name,
            venue_address: venue_address,
            venue_city: venue_city,
            venue_state: venue_state,
            venue_country: venue_country,
            buyer_company: buyer_company,
            buyer_address: buyer_address
        }
        newArray = [...newArray, items]
    })


    // dispatch({
    //     type: OFFERS_DB_INIT,
    //     payload: newArray
    // })
    return newArray
};

export const offerCombineFinal = (array) => async (dispatch, getState) => {
    dispatch(setOffersLoading());

    var end_date = moment().add(1, 'years').format('MM/DD/YYYY');
    var start_date = moment().add(-1, 'days').format('MM/DD/YYYY');

    const dashFunc = (list) => {
        return new Promise((resolve, reject) => {
            var dash = _.orderBy(list, ['artist_name'], ['asc']);
            resolve(dash)
        })
    }
    const databaseFunc = (list) => {
        return new Promise((resolve, reject) => {
            var db = _.sortBy(list, function (dateObj) {
                return new Date(dateObj.show_info[0].date);
            }).filter((it) => {
                var filterBatch = it.status
                return filterBatch.indexOf('Confirmed') >= 0
            }).filter((it) => {
                const today = moment();
                var val = it.show_info;
                // const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                const closest = val[0];
                var filterBatch = new Date(closest.date)
                var start = new Date(start_date)
                return filterBatch > start
            })
            // .filter((it) => {
            //     const today = new Date();
            //     var val = it.show_info;
            //     // const furthest = await val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            //     const furthest = val[0];
            //     var filterBatch = new Date(furthest.date)
            //     var end = new Date(end_date)
            //     return filterBatch <= end
            // })
            resolve(db)
        })
    }

    const dashboard = await dashFunc(array)
    const database = await databaseFunc(array);
    // var database = _.sortBy(newArray, function (dateObj) {
    //     return new Date(dateObj.show_info[0].date);
    // })

    const passData = {
        dashboard: dashboard,
        database: database,
        pay: array
    }

    dispatch({
        type: OFFERS_DB_INIT,
        payload: passData
    })

    return false
};

export const setOffersCal = () => async (dispatch, getState) => {
    dispatch(setOffersLoading());
    dispatch({
        type: OFFERS_CAL_SET,
        payload: 'success'
    })
};

export const setDashboard = () => async (dispatch, getState) => {
    dispatch(setOffersLoading());
    dispatch({
        type: OFFERS_DASH_SET,
        payload: 'success'
    })
};

export const downloadAttachmentOffer = (data) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/offerdetail/attachment/download`, data, tokenConfig(getState))
        .then(res => {

            var a = document.createElement("a"); //Create <a>
            a.href = res.data.file; //Image Base64 Goes here
            a.download = res.data.title; //File name Here
            a.click(); //Downloaded file

            return "Success"
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const getFinalsAll = () => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/offerdetail/finals`, null, tokenConfig(getState))
        .then(res => {
            if (res.data) {
                return res.data
            } else {
                return null
            }
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};