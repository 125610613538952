import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { addCount, getCounts, updateCount } from '../../actions/countActions';
import { Pie, Doughnut, Line } from 'react-chartjs-2';
import moment from 'moment-timezone';
import { FaEdit, FaSave } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import { confirmAlert } from 'react-confirm-alert'

class TicketCounts extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            date1Toggle: false,
            date2Toggle: false,
            date3Toggle: false,
            date4Toggle: false,
            date5Toggle: false,
            date6Toggle: false,
            depositadd: 1,
            chart: "Line Chart (All Counts)",
            date: '',
            log_time: '',
            label_times: false,
            ticketcount: '',
            ticketcountval: '',
            countdata: [{}],
            count_render: [{}],
            loading: false,
            count_edit: '',
            tc_custom: false,
            count_update: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleCountChange = this.handleCountChange.bind(this);
        this.handleShowSelect = this.handleShowSelect.bind(this);

    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        counts: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        this.setState({
            loading: true
        })
        const id = this.props.id;
        var count_data = await this.props.getCounts(id);
        if (!count_data) {
            count_data = [{}]
        }
        var sorted_tc_dates = [{}];
        if (count_data[0]) {
            const show_index = count_data.findIndex(obj => obj._id === this.props.show_select);
            if (count_data[show_index]) {
                sorted_tc_dates = count_data[show_index].count_data
            }
        }
        this.setState({
            countdata: count_data,
            count_render: sorted_tc_dates,
        })
        setTimeout(
            () => this.setState({ loading: false }),
            250
        );
        const socket = this.props.auth.socket;
        if (socket) {
            socket.on(`new_counts/${id}`, (counts) => {
                console.log(counts)
                const show_index = counts.counts.findIndex(obj => obj._id === this.props.show_select);
                var sorted_tc_dates = [{}];
                if (show_index >= 0) {
                    sorted_tc_dates = counts.counts[show_index].count_data
                }
                this.setState({
                    countdata: counts.counts,
                    count_render: sorted_tc_dates
                })
            })
        };
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value
        if (name === 'log_time') {
            if (value.length < 6) { // missing :ss on chrome
                value += ':00'; // add it ourselves
            }
        }
        this.setState({
            [name]: value
        });
    }

    handleShowSelect = async (e) => {
        console.log(e.target.value)
        await this.props.toggleDateSelect(e.target.value)

        var count_data = this.state.countdata;
        if (!count_data) {
            count_data = [{}]
        }
        const show_index = count_data.findIndex(obj => obj._id === this.props.show_select);
        var sorted_tc_dates = [{}];
        if (count_data[0] && count_data[show_index]) {
            sorted_tc_dates = count_data[show_index].count_data
        }
        this.setState({
            count_render: sorted_tc_dates,
        })
    }

    handleDateChange = date => {
        this.setState({
            date: date
        });
    };

    formatTicketCountValue = (values) => {
        const { formattedticketcount, value } = values; this.setState({ ticketcount: formattedticketcount, ticketcountval: value })
    }

    toggleCheckbox = (e) => {
        let name = e.target.name;
        this.setState({
            [name]: !this.state[name]
        })
    }

    logCount = async () => {
        if (this.state.ticketcountval && this.state.date || this.state.ticketcountval && !this.state.tc_custom) {
            var date = "";
            var time = "";
            if (this.state.tc_custom) {
                date = moment(this.state.date);
                time = this.state.log_time;
            } else {
                date = moment();
                time = moment().format('HH:mm:ss')
            }
            const dateString = date.format('YYYY-MM-DD').toString();
            var tz = moment.tz.guess();
            console.log(dateString, tz, date)

            if (date < moment()) {
                if (this.state.countdata[0]._id === undefined) {
                    const id = this.props.id;
                    const count_data = [{
                        date: dateString,
                        time: time,
                        tz: tz,
                        count: Number(this.state.ticketcountval),
                        notes: '',
                    }];
                    const counts = [{
                        _id: this.props.show_select,
                        count_data: count_data
                    }];
                    const count = {
                        counts, id
                    }
                    this.props.addCount(count)
                    // console.log(countReturned)
                    // if (countReturned.includes('Error:')) {
                    //     alert(countReturned);
                    // } else {
                    this.setState({
                        date: '',
                        log_time: '',
                        ticketcount: '',
                        ticketcountval: '',
                        //countdata: countReturned
                    })
                    // }
                } else {
                    const count_data = {
                        date: dateString,
                        time: time,
                        tz: tz,
                        count: Number(this.state.ticketcountval),
                        notes: '',
                    };
                    // const counts = [{
                    //     _id: this.props.show_select,
                    //     count_data: count_data
                    // }];
                    const newArray = []
                    //if(this.props.counts._id === undefined){
                    //    newArray = count

                    //    const counts = {
                    //        _id: this.props.id,
                    //        counts: newArray
                    //    }

                    //    this.props.addCount(counts)

                    //} else {
                    let counts = [...this.state.countdata]
                    const data_index = this.state.countdata.findIndex(obj => obj._id === this.props.show_select);
                    var array_data = counts[data_index].count_data
                    newArray.push(...array_data, count_data)
                    // var lastArray = newArray.filter(function (el) {
                    //     return el._id != undefined;
                    // });
                    console.log(newArray)
                    const newCD = {
                        _id: this.props.show_select,
                        count_data: newArray
                    }
                    counts[data_index] = newCD
                    const count = {
                        _id: this.props.id,
                        counts: counts
                    }
                    console.log(count)
                    this.props.updateCount(count, this.props.id)
                    // console.log(countReturned)
                    // if (countReturned.includes('Error:')) {
                    //     alert(countReturned);
                    // } else {
                    this.setState({
                        date: '',
                        log_time: '',
                        ticketcount: '',
                        ticketcountval: '',
                        //countdata: countReturned
                    })
                    // }
                }
            } else {
                alert('Your selected date is in the future.  Please select today or a past date.')
            }
        } else {
            alert('Error: please input a correct ticket count and date.')
        }
    };

    toggleEdit = (index) => {
        const count_update = {
            count: this.state.count_render[index].count,
            index: index
        }
        console.log(count_update)
        this.setState({
            count_edit: index,
            count_update: count_update
        })
    }

    handleClickOutside = (e) => {
        var count = this.state.count_update;
        console.log(count);
        var index = count.index;
        if (Number(count.count) === this.state.count_render[index].count) {
            this.setState({
                count_edit: ''
            })
        } else if (count.count === "") {
            this.deleteCountDialog();
        } else {
            this.updateCountDialog();
        }
    }

    toggleTCCustom = () => {
        this.setState({
            tc_custom: !this.state.tc_custom
        })
    }

    handleCountChange = (event, index) => {
        let value = event.target.value
        // const show_index = this.state.countdata.findIndex(obj => obj._id === this.props.show_select);
        // let countdata = this.state.countdata
        // console.log(value, show_index, countdata)
        // var data = countdata[show_index];
        // data.count_data[index].count = value;
        // countdata[show_index] = data;
        // this.setState({
        //     countdata: countdata
        // })

        const count_update = {
            count: value,
            index: index
        }
        // let count_data = this.state.count_render;
        // count_data[index].count = value;
        // console.log(count_data)
        // this.setState({
        //     count_render: [{}]
        // }, () => this.setState({
        //     count_render: count_data
        // }))
        this.setState({
            count_update: count_update
        })
    }

    updateCountDialog = (item) => {
        const addDialog = ({ onClose }) => {
            return (
                <div class="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Update Ticket Count</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Are you sure you'd like to update the current ticket count?
                        </div>
                        <div style={{ marginTop: 10 }} class="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => this.handleDialogSave(onClose)}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => this.handleDialogClose(onClose)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )
        }

        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }

        confirmAlert(options)
    }

    deleteCountDialog = (item) => {
        const addDialog = ({ onClose }) => {
            return (
                <div class="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Delete Ticket Count</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Are you sure you'd like to delete this ticket count?
                        </div>
                        <div style={{ marginTop: 10 }} class="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => this.handleDialogSave(onClose)}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => this.handleDialogClose(onClose)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )
        }

        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }

        confirmAlert(options)
    }

    handleDialogClose = (onClose) => {
        this.setState({
            count_update: {},
            count_edit: ''
        })
        onClose();
    }

    handleDialogSave = async (onClose) => {
        const count_update = this.state.count_update;
        let count_render = this.state.count_render;
        const index = this.state.count_update.index;
        let count_data = this.state.countdata;
        const show_index = count_data.findIndex(obj => obj._id === this.props.show_select);

        if (count_update.count === "") {
            //count_render[index].count = Number(count_update.count);
            count_render.splice(index, 1);
            console.log(count_render)
            count_data[show_index].count_data = count_render;

        } else {
            count_render[index].count = Number(count_update.count);
            count_data[show_index].count_data = count_render;
        }
        const count = {
            _id: this.props.id,
            counts: count_data
        }
        const log_status = await this.props.updateCount(count, this.props.id);
        console.log(log_status)
        if (log_status.error) {
            alert(log_status.error);
        } else {
            this.setState({
                count_render: count_render,
                count_data: count_data,
                count_edit: '',
            })
        }

        onClose();
    }

    render() {
        var captotalval = this.props.captotalval;
        var compstotalval = this.props.compstotalval;
        var killstotalval = this.props.killstotalval;

        var single_captotalval = this.props.single_captotalval;
        var single_compstotalval = this.props.single_compstotalval;
        var single_killstotalval = this.props.single_killstotalval;
        //< CanvasJSChart options = { salesSpline }
        /* onRef = {ref => this.chart = ref} */
        // />

        var current_count = null;
        var last_count = null;
        if (this.state.count_render[0]._id) {
            this.state.count_render.sort((a, b) => {
                return new Date(a.date) -
                    new Date(b.date)
            }).reverse();
            current_count = this.state.count_render[0].count;
            if (this.state.count_render[1] && this.state.count_render[1].count) {
                last_count = this.state.count_render[1].count;
            }
        }

        const per_inc = ((current_count - last_count) / last_count) * 100;
        console.log(per_inc)
        var datelabels = [{}];
        //if (this.state.label_times) {
        //    datelabels = sorted_tc_dates.reduce((a, o) => (a.push(`${moment(o.date).format('MM/DD/YYYY')} ${o.time}`), a), []).reverse() 
        //} else {
        datelabels = this.state.count_render.reduce((a, o) => (a.push(moment(o.date).format('MM/DD/YYYY')), a), []).reverse()
        //};

        const countdata = this.state.count_render.reduce((a, o) => (a.push(o.count), a), []).reverse();

        var gross_cap = single_captotalval - single_compstotalval - single_killstotalval
        var unsold = gross_cap - current_count
        var sold_percentage = parseFloat((current_count / gross_cap * 100).toFixed(1));

        var date1 = [];
        if (this.state.count_render[0]) {
            date1 = this.state.count_render[0].date
        } else {
            date1 = null
        }
        var date2 = [];
        if (this.state.count_render[1]) {
            date2 = this.state.count_render[1].date
        } else {
            date2 = null
        }
        var wrap = [];
        if (date1 && date2) {
            wrap = moment(new Date(date1)).diff(new Date(date2), 'days');
        } else {
            wrap = ''
        }

        var ticketwrap = [];
        if (date1 && date2) {
            ticketwrap = this.state.count_render[0].count - this.state.count_render[1].count;
        } else {
            ticketwrap = '-'
        }

        const lineAllCounts = {
            labels: datelabels,
            datasets: [
                {
                    fill: false,
                    lineTension: 0.2,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 3,
                    pointHitRadius: 10,
                    data: countdata
                }
            ],
        }

        const doughnutTotalSales = {
            labels: ['Tickets Sold', 'Unsold'],
            datasets: [
                {
                    backgroundColor: [

                        '#00A6B4',
                        '#f5f5f5'
                    ],
                    hoverBackgroundColor: [

                        '#003350',
                        '#cdcdcd'
                    ],
                    data: [current_count, unsold]
                }
            ]
        }

        const option = {
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                        var total = meta.total;
                        var currentValue = dataset.data[tooltipItem.index];
                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                        return currentValue + ' (' + percentage + '%)';
                    },
                    title: function (tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    }
                }
            },
            legend: {
                display: false,
                position: 'top'
            }
        }

        //{sold_percentage.toString() === "Infinity" ?
        // <h2 style={{ position: 'absolute', top: 105, left: '49%', width: '100%', fontSize: 22 }}>-</h2> :
        //<h2 style={{ position: 'absolute', top: 105, left: '46%', width: '100%', fontSize: 22 }}>{sold_percentage}%</h2>}
        // <h2 style={{ position: 'absolute', top: 135, left: '46%', width: '100%', fontSize: 22 }}>Sold</h2>

        return (
            <div className="enterdealMain">
                {this.state.loading ?
                    <div style={{ marginLeft: 100, marginTop: 30 }}>
                        <h2 style={{ fontSize: 14, color: '#000' }}>Loading...</h2>
                    </div> :
                    <div style={{ width: '100%', height: '50%' }}>
                        <div style={{ marginLeft: 50, height: 'auto', width: '90%', marginTop: 20 }} className="form-control">
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                                        <h1 style={{ fontSize: 14, color: '#000', paddingTop: 5 }}>Show Date:</h1>
                                        <select style={{ width: 200, fontSize: 12, color: '#000', backgroundColor: '#fff', height: 28, marginLeft: 10 }} name="show_select" value={this.props.show_select} onChange={(e) => this.handleShowSelect(e)}>
                                            {this.props.show_info_conf.map((item, index) => {

                                                var firstItem = '';
                                                var lastItem = '';

                                                if (item.show_times[0]) {
                                                    var ft = item.show_times[0].time;

                                                    var FI = +ft.substr(0, 2);
                                                    var f = (FI % 12) || 12;
                                                    f = (f < 10) ? ("0" + f) : f;  // leading 0 at the left for 1 digit hours
                                                    var ampmF = FI < 12 ? " AM" : " PM";
                                                    firstItem = f + ft.substr(2, 3) + ampmF;

                                                    var lt = item.show_times[item.show_times.length - 1].time;
                                                    var LI = +lt.substr(0, 2);
                                                    var l = (LI % 12) || 12;
                                                    l = (l < 10) ? ("0" + l) : l;  // leading 0 at the left for 1 digit hours
                                                    var ampmL = LI < 12 ? " AM" : " PM";
                                                    lastItem = l + lt.substr(2, 3) + ampmL;
                                                }

                                                if (firstItem === "") {
                                                    firstItem = "TBA"
                                                }

                                                var date = moment(item.date).format('M/DD/YYYY');
                                                return (
                                                    <option value={item._id}>{date} ({firstItem})</option>
                                                )
                                            })}
                                        </select>
                                        <div style={{ display: 'flex', width: 'auto', marginLeft: 20 }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                color="default"
                                                name="label_times"
                                                checked={this.state.label_times}
                                                //value={this.state.label_times}
                                                onChange={this.toggleCheckbox}
                                            />
                                            <p style={{ marginLeft: 5, fontSize: 10, marginTop: 15, color: '#000' }}>Include times</p>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', overflowX: 'scroll', height: 'auto' }}>
                                        <table style={{ width: 'auto', marginTop: 10, textAlign: 'center', overflowX: 'scroll', marginBottom: 10 }} tabIndex={this.props.edit ? "0" : null} onBlur={(e) => this.handleClickOutside(e)}>
                                            <thead>
                                                <tr>
                                                    {this.state.count_render.map((item, index) => {
                                                        if (item.date) {
                                                            var date = moment(item.date).format('MM/DD/YYYY')
                                                            if (this.state.label_times && this.state.count_edit === index) {
                                                                var time = ""
                                                                var ft = item.time;
                                                                var FI = +ft.substr(0, 2);
                                                                var f = (FI % 12) || 12;
                                                                f = (f < 10) ? ("0" + f) : f;  // leading 0 at the left for 1 digit hours
                                                                var ampmF = FI < 12 ? " AM" : " PM";
                                                                time = f + ft.substr(2, 3) + ampmF;
                                                                return (
                                                                    <th style={{ fontSize: 12, maxWidth: 250, minWidth: 250, border: '1px solid #333333', height: 28, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', paddingLeft: 5 }}>{date} {time}</th>
                                                                )
                                                            } else if (!this.state.label_times && this.state.count_edit === index) {
                                                                return (
                                                                    <th style={{ fontSize: 12, maxWidth: 250, minWidth: 250, border: '1px solid #333333', height: 28, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', paddingLeft: 5 }}>{date}</th>
                                                                )
                                                            } else if (this.state.label_times && this.state.count_edit !== index) {
                                                                var time = ""
                                                                var ft = item.time;
                                                                var FI = +ft.substr(0, 2);
                                                                var f = (FI % 12) || 12;
                                                                f = (f < 10) ? ("0" + f) : f;  // leading 0 at the left for 1 digit hours
                                                                var ampmF = FI < 12 ? " AM" : " PM";
                                                                time = f + ft.substr(2, 3) + ampmF;
                                                                return (
                                                                    <th style={{ fontSize: 12, maxWidth: 250, minWidth: 250, border: '1px solid #333333', height: 28, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', paddingLeft: 5 }}>{date} {time}</th>
                                                                )
                                                            } else {
                                                                return (
                                                                    <th style={{ fontSize: 12, maxWidth: 250, minWidth: 250, border: '1px solid #333333', height: 28, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', paddingLeft: 5 }}>{date}</th>
                                                                )
                                                            }
                                                        } else {
                                                            return (
                                                                <th style={{ fontSize: 12, maxWidth: 250, minWidth: 250, border: '1px solid #333333', height: 28, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', paddingLeft: 5 }}><div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ width: '90%' }}>-</div><div style={{ width: '10%' }}> </div></div></th>
                                                            )
                                                        }
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {this.state.count_render.map((item, index) => {
                                                        if (item.count >= 0) {
                                                            if (this.state.count_edit === index) {
                                                                return (
                                                                    <th style={{ fontSize: 12, width: 280, height: 28, border: '1px solid #333333' }}><input style={{ width: '100%', height: '100%', textAlign: 'center' }} type="number" value={this.state.count_update ? this.state.count_update.count : item.count} onChange={(event) => this.handleCountChange(event, index)} /></th>
                                                                )
                                                            } else {
                                                                return (
                                                                    <th style={{ fontSize: 12, width: 280, height: 28, border: '1px solid #333333', paddingLeft: 5 }} onClick={() => this.toggleEdit(index)}><div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ width: '90%' }}>{item.count}</div><div style={{ width: '10%' }}> </div></div></th>
                                                                )
                                                            }
                                                        } else {
                                                            return (
                                                                <th style={{ fontSize: 12, width: 280, height: 28, border: '1px solid #333333', paddingLeft: 5 }}><div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ width: '90%' }}>No counts logged</div><div style={{ width: '10%' }}> </div></div></th>
                                                            )
                                                        }
                                                    })}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '95%' }}>
                                        <div style={{ width: '30%', marginTop: 30, marginBottom: 10, textAlign: 'center' }}>
                                            <table style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ fontSize: 12, width: '50%', border: '1px solid #333333', height: 28, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', paddingLeft: 5 }}>{wrap} Day Wrap</th>
                                                        <th style={{ fontSize: 12, width: '50%', border: '1px solid #333333', height: 28, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', paddingLeft: 5 }}>% Change</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ fontSize: 12, width: '15%', height: 28, border: '1px solid #333333', paddingLeft: 5 }}>{ticketwrap}</th>
                                                        {per_inc.toString() === "Infinity" || per_inc.toString() === "NaN" ?
                                                            <th style={{ fontSize: 12, width: '15%', height: 28, border: '1px solid #333333', paddingLeft: 5 }}>-</th> :
                                                            <th style={{ fontSize: 12, width: '15%', height: 28, border: '1px solid #333333', paddingLeft: 5 }}>{per_inc.toFixed(2)}%</th>}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table style={{ width: '100%', marginTop: 30 }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ fontSize: 12, width: '50%', border: '1px solid #333333', height: 28, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', paddingLeft: 5 }}>Capacity</th>
                                                        <th style={{ fontSize: 12, width: '50%', border: '1px solid #333333', height: 28, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', paddingLeft: 5 }}>% Sold</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ fontSize: 12, width: '15%', height: 28, border: '1px solid #333333', paddingLeft: 5 }}>{single_captotalval}</th>
                                                        {sold_percentage.toString() === "Infinity" || sold_percentage.toString() === "NaN" ?
                                                            <th style={{ fontSize: 12, width: '15%', height: 28, border: '1px solid #333333', paddingLeft: 5 }}>-</th> :
                                                            <th style={{ fontSize: 12, width: '15%', height: 28, border: '1px solid #333333', paddingLeft: 5 }}>{sold_percentage}%</th>}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div style={{ marginTop: 30, width: '75%', backgroundColor: 'rgb(1, 22, 48)', height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <select style={{ width: '90%', fontSize: 12 }} name="chart" value={this.state.chart} onChange={this.handleChange.bind(this)}>
                                                    <option value={"Line Chart (All Counts)"}>Line Chart (All Counts)</option>
                                                    <option value={"Doughnut Chart (Total Sales)"}>Doughnut Chart (Total Sales)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div style={{ width: '70%', height: 'auto', marginTop: 15, display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ width: '95%', height: 'auto' }}>
                                                {this.state.chart === "Line Chart (All Counts)" ?
                                                    <Line
                                                        data={lineAllCounts}
                                                        options={{
                                                            title: {
                                                                display: false,
                                                                text: 'Average Rainfall per month',
                                                                fontSize: 20
                                                            },
                                                            legend: {
                                                                display: false,
                                                                position: 'right'
                                                            },
                                                            scales: {
                                                                xAxes: [{
                                                                    title: "time",
                                                                    type: 'time',
                                                                    gridLines: {
                                                                        lineWidth: 2
                                                                    },
                                                                    time: {
                                                                        unit: "day",
                                                                        unitStepSize: 2,
                                                                        displayFormats: {
                                                                            millisecond: 'MMM DD',
                                                                            second: 'MMM DD',
                                                                            minute: 'MMM DD',
                                                                            hour: 'MMM DD',
                                                                            day: 'MMM DD',
                                                                            week: 'MMM DD',
                                                                            month: 'MMM DD',
                                                                            quarter: 'MMM DD',
                                                                            year: 'MMM DD',
                                                                        }
                                                                    }
                                                                }]
                                                            }
                                                        }}
                                                    /> :
                                                    <div style={{ position: 'relative', width: '100%', display: 'flex' }}>
                                                        < Doughnut
                                                            data={doughnutTotalSales}
                                                            options={option}
                                                        />
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: 50, height: 160, width: '90%', marginTop: 20, backgroundColor: 'rgb(1, 22, 48)', marginBottom: 20 }} className="form-control">
                            <div>
                                <h1 style={{ marginTop: 20, marginLeft: 15, color: '#cdcdcd', fontSize: 14 }}>Ticket Count Logger:</h1>
                            </div>
                            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', width: '80%', marginLeft: 15 }}>
                                <div style={{ marginTop: 15, display: 'flex', flexDirection: 'column', width: '40%' }} className="form-group">
                                    <label style={{ fontSize: 14, color: '#cdcdcd' }}>Ticket Count:</label>
                                    <NumberFormat className="form-control-sm" style={{ fontSize: 12, border: '1px solid #000', borderRadius: 1, padding: 10 }} name="ticketcount" value={this.state.ticketcount} thousandSeparator={true} onValueChange={this.formatTicketCountValue} />
                                </div>
                                <div style={{ marginTop: 15, display: 'flex', flexDirection: 'column', width: 'auto', marginLeft: 10 }} className="form-group">
                                    <label style={{ fontSize: 14, color: '#cdcdcd' }}>Date:</label>
                                    <div style={{ display: 'flex', height: 28 }}>
                                        <button style={{ width: 'auto', backgroundColor: !this.state.tc_custom ? '#cdcdcd' : 'rgb(1, 22, 48)', color: !this.state.tc_custom ? 'rgb(1, 22, 48)' : '#cdcdcd', fontSize: 12, cursor: 'pointer' }} onClick={this.toggleTCCustom}>NOW</button>
                                        <button style={{ width: 'auto', backgroundColor: this.state.tc_custom ? '#cdcdcd' : 'rgb(1, 22, 48)', color: this.state.tc_custom ? 'rgb(1, 22, 48)' : '#cdcdcd', fontSize: 12, cursor: 'pointer' }} onClick={this.toggleTCCustom}>CUSTOM</button>
                                    </div>
                                </div>
                                {this.state.tc_custom ?
                                    <div style={{ width: 'auto' }}>
                                        <div style={{ width: 120, marginLeft: 10, height: 28, marginTop: 28 }}>
                                            <DatePicker
                                                className="react-datepicker"
                                                style={{ fontSize: 12 }}
                                                //showTimeInput
                                                //dateFormat="MM/dd/yyyy, h:mm:ss a"
                                                //timeFormat="hh:mm"
                                                value={this.state.date}
                                                selected={this.state.date}
                                                onChange={this.handleDateChange.bind(this)}
                                            />
                                        </div>
                                        <input
                                            className="form-control-sm"
                                            style={{ width: 120, height: 30, fontSize: 12, marginLeft: 10, borderRadius: 4, marginTop: 5 }}
                                            type="time"
                                            name="log_time"
                                            value={this.state.log_time}
                                            onChange={this.handleChange}
                                        />
                                    </div> : null}
                                <button style={{ width: 50, backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', fontSize: 12, height: 28, marginTop: 44, cursor: 'pointer', marginLeft: 10 }} onClick={this.logCount}>LOG</button>
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    auth: state.auth,
    counts: state.counts,
});

export default connect(mapStateToProps, { addCount, getCounts, updateCount })(TicketCounts);