import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { updateContactTags } from '../../actions/contactActions';
import ContactTagsAutocomplete from '../../components/autocompletes/contact-tags-autocomplete';
import ClientTagsAutocomplete from '../../components/autocompletes/client-tags-autocomplete';
import BuyerTagsAutocomplete from '../../components/autocompletes/buyer-tags-autocomplete';
import VenueTagsAutocomplete from '../../components/autocompletes/venue-tags-autocomplete';
import { FaPenSquare, FaArrowLeft, FaPlusSquare, FaEdit, FaTimesCircle } from 'react-icons/fa';

class ContactTagsAlt2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag_array: [],
            artist: '', artistid: '', artistbatch: { _id: '', client: '' },
            venue: '', venueid: '', venuebatch: { _id: '', venuename: '', address: [] },
            buyercompany: '', buyercompanyid: '', buyerbatch: { _id: '', company: '', address: [] },
            contact_batch: { _id: '', first_name: '', last_name: '', company: '' },
            contact_id: '',
            client_batch: { _id: '', client: '' },
            sub_id: this.props.id,
            contact_role: '',
            contact_role_custom: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleVenueChange = this.handleVenueChange.bind(this);
        this.handleBuyerChange = this.handleBuyerChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        contacts: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleClick = () => {
        this.props.toggle();
    };

    handleClientChange(artist) {
        console.log(artist)
        if (artist) {
            this.setState({
                artist: artist.client,
                artistid: artist._id,
                artistbatch: artist
            })
        } else {
            this.setState({
                artist: '',
                artistid: '',
                artistbatch: ''
            });
        }
    }
    handleVenueChange(venue) {
        if (venue) {
            if (venue.venue_name) {
                this.setState({
                    venue: venue.venue_name,
                    venueid: venue._id,
                    venuebatch: venue
                })
            } else {
                this.setState({
                    venue: venue.festival,
                    venueid: venue._id,
                    venuebatch: venue
                })
            }
        } else {
            this.setState({
                venue: '',
                venueid: '',
                venuebatch: ''
            });
        }
    }
    handleBuyerChange(buyer) {
        if (buyer) {
            this.setState({
                buyercompany: buyer.company,
                buyercompanyid: buyer._id,
                buyerbatch: buyer
            })
        } else {
            this.setState({
                buyercompany: '',
                buyercompanyid: '',
                buyerbatch: ''
            });
        }
    }
    handleContactChange(contact) {
        console.log(contact)
        if (contact) {
            const contact_batch = {
                _id: contact._id, first_name: contact.name.first, last_name: contact.name.last, company: contact.company
            }
            this.setState({
                contact_batch: contact_batch,
                contact_id: contact._id
            })
        } else {
            this.setState({
                contact_batch: { _id: '', first_name: '', last_name: '', company: '' },
                contact_id: ''
            });
        }
    }

    addTag = () => {
        if (this.state.artist) {
            var tag = {};
            if (this.state.contact_role === 'Custom') {
                tag = {
                    date: moment().format('MM/DD/YYYY'),
                    _id: this.state.artistid,
                    role: this.state.contact_role_custom,
                    contact_id: this.state.sub_id
                }
            } else {
                tag = {
                    date: moment().format('MM/DD/YYYY'),
                    _id: this.state.artistid,
                    role: this.state.contact_role,
                    contact_id: this.state.sub_id
                }
            }

            const newArray = []
            newArray.push(...this.state.tag_array, tag);
            const batch = { _id: '', client: '' }

            this.setState({
                tag_array: newArray,
                //artistbatch: batch,
                artistbatch: { _id: '', client: '' },
                venue: '',
                venueid: '',
                artist: '',
                artistid: '',
                buyercompany: '',
                buyercompanyid: '',
                contact_role: ''
            })
        } else if (this.state.venue) {
            var tag = {};
            if (this.state.contact_role === 'Custom') {
                tag = {
                    date: moment().format('MM/DD/YYYY'),
                    _id: this.state.venueid,
                    role: this.state.contact_role_custom,
                    contact_id: this.state.sub_id
                }
            } else {
                tag = {
                    date: moment().format('MM/DD/YYYY'),
                    _id: this.state.venueid,
                    role: this.state.contact_role,
                    contact_id: this.state.sub_id
                }
            }

            const newArray = []
            newArray.push(...this.state.tag_array, tag);
            const batch = { _id: '', venuename: '', address: [] }

            this.setState({
                tag_array: newArray,
                //venuebatch: batch,
                venuebatch: { _id: '', venuename: '', address: [] },
                venue: '',
                venueid: '',
                artist: '',
                artistid: '',
                buyercompany: '',
                buyercompanyid: '',
                contact_role: ''
            })
        } else if (this.state.buyercompany) {
            var tag = {};
            if (this.state.contact_role === 'Custom') {
                tag = {
                    date: moment().format('MM/DD/YYYY'),
                    _id: this.state.buyercompanyid,
                    role: this.state.contact_role_custom,
                    contact_id: this.state.sub_id
                }
            } else {
                tag = {
                    date: moment().format('MM/DD/YYYY'),
                    _id: this.state.buyercompanyid,
                    role: this.state.contact_role,
                    contact_id: this.state.sub_id
                }
            }

            const newArray = []
            newArray.push(...this.state.tag_array, tag);
            const batch = { _id: '', company: '', address: [] }

            this.setState({
                tag_array: newArray,
                //buyerbatch: batch,
                buyerbatch: { _id: '', company: '', address: [] },
                venue: '',
                venueid: '',
                artist: '',
                artistid: '',
                buyercompany: '',
                buyercompanyid: '',
                contact_role: ''
            })
        }
    }

    updateTag = (props) => {
        const val = this.state.tag_array[props];
        this.props.contacts.contacts.map((item, index) => {
            if (item._id === val.contact_id) {
                const contact_batch = {
                    _id: item._id,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    company: item.company
                }
                this.setState({
                    contact_batch: contact_batch,
                    contact_id: item._id
                })
                const newArray = this.state.tag_array.filter((e, i) => i !== props);
                this.setState({
                    tag_array: newArray
                })
            }
        })
    }

    deleteTag = (props) => {
        const newArray = this.state.tag_array.filter((e, i) => i !== props);
        this.setState({
            tag_array: newArray
        })
    }

    saveTags = async () => {
        // var type = 'new';
        // this.props.contacts.contacts.map((item, inex) => {
        //     this.state.tag_array.map(t => {
        //         if (t.contact_id === item._id) {
        //             type = 'update'
        //         }
        //     })
        // })
        this.state.tag_array.forEach(async (item) => {
            const tags = {
                tags: [{ ...item }],
                sub_id: { tag: item._id },
            }
            var log_status = await this.props.updateContactTags(tags);
            console.log(log_status)
            if (log_status.includes('Error:')) {
                alert(log_status);
            } else {
                this.props.toggleSub('tags')
            }
        })

    }

    clearRole = () => {
        this.setState({
            contact_role: ''
        })
    }

    render() {
        console.log(this.state.tag_array)

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 500 }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 465, alignItems: 'center' }}>
                    <table style={{ width: '90%', marginTop: 30 }} id="#customers">
                        <thead>
                            <tr>
                                {this.props.render === 'new_client' ?
                                    <th style={{ width: '55%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Client</th> :
                                    this.props.render === 'new_buyer' ?
                                        <th style={{ width: '55%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Buyer</th> :
                                        this.props.render === 'new_venue' ?
                                            <th style={{ width: '55%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Venue</th> :
                                            <th style={{ width: '55%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Contact</th>}
                                <th style={{ width: '45%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Role</th>
                            </tr>
                        </thead>
                    </table>
                    <table style={{ width: '90%' }} id="#customers">
                        <thead>
                            <tr>
                                <th style={{ width: '55%', backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd' }}>
                                    {this.props.render === 'new_client' ?
                                        <ClientTagsAutocomplete tagsList={this.props.clients.clients} onChange={this.handleClientChange} tagSelect={this.state.artistbatch} /> :
                                        this.props.render === 'new_buyer' ?
                                            <BuyerTagsAutocomplete tagsList={this.props.buyers.buyers} onChange={this.handleBuyerChange} tagSelect={this.state.buyerbatch} /> :
                                            this.props.render === 'new_venue' ?
                                                <VenueTagsAutocomplete tagsList={this.props.items.items} onChange={this.handleVenueChange} tagSelect={this.state.venuebatch} /> :
                                                <ContactTagsAutocomplete tagsList={this.props.contacts.contacts} onChange={this.handleContactChange} tagSelect={this.state.contact_batch} />}
                                </th>
                                {/* <th style={{ width: '40%', backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                    <input style={{ width: '100%', height: '100%', backgroundColor: '#fff', color: '#000', fontSize: 12 }} type="text" name="contact_role" value={this.state.contact_role} onChange={this.handleChange} />
                                </th> */}
                                <th style={{ width: '40%', backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                    {this.state.contact_role !== 'Custom' ?
                                        <select style={{ width: '100%', height: '100%', backgroundColor: '#fff', color: '#000', fontSize: 12 }} type="text" name="contact_role" value={this.state.contact_role} onChange={this.handleChange}>
                                            <option value=""></option>
                                            <option value="Buyer">Buyer</option>
                                            <option value="Buyer">Buyer Assistant</option>
                                            <option value="Contract Admin">Contract Admin</option>
                                            <option value="Marketing">Marketing</option>
                                            <option value="Marketing Manager">Marketing Manager</option>
                                            <option value="Production">Production</option>
                                            <option value="Production Manager">Production Manager</option>
                                            <option value="Ticket Counts">Ticket Counts</option>
                                            <option value="Custom">Custom</option>
                                        </select> :
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <input style={{ width: '92%', height: '100%', backgroundColor: '#fff', color: '#000', fontSize: 12 }} type="text" name="contact_role_custom" value={this.state.contact_role_custom} onChange={this.handleChange} />
                                            <span style={{ width: '8%', height: '100%', backgroundColor: 'rgb(1, 22, 48)' }} onClick={this.clearRole}>
                                                <FaArrowLeft size="20" color="#cdcdcd" />
                                            </span>
                                        </div>}
                                </th>
                                <th style={{ width: '5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={this.props.addTag} >
                                        <FaPlusSquare size="20" color="#cdcdcd" onClick={this.addTag} />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div style={{ marginTop: this.state.tag_array ? 15 : 0, minHeight: 50, maxHeight: 'auto', width: '90%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd' }}>
                        {this.state.tag_array.map((item, index) => {
                            var tagged = [];
                            this.props.clients.clients.map((val, ind) => {
                                if (val._id === item._id) {
                                    tagged = val.client
                                }
                            })
                            this.props.items.items.map((val, ind) => {
                                if (val._id === item._id) {
                                    tagged = val.venue_name
                                }
                            })
                            this.props.buyers.buyers.map((val, ind) => {
                                if (val._id === item._id) {
                                    tagged = val.company
                                }
                            })
                            return (
                                <div style={{ border: '1px dashed gray', paddingLeft: '0.5rem', backgroundColor: '#f1f1f1', width: '100%' }} key={index}>
                                    <table style={{ width: '100%', height: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5, height: 50 }}>{item.date}</td>
                                                <td style={{ width: '79%', color: '#000', fontSize: 12, padding: 5 }}>{tagged} ({item.role})</td>
                                                <td style={{ width: '6%', color: '#000' }}>
                                                    <FaEdit style={{ cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.updateTag(index)} />
                                                    <FaTimesCircle style={{ marginLeft: 10, cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.deleteTag(index)} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
                    <button style={{ width: 160, height: 30, backgroundColor: 'rgb(1, 22, 48)', color: '#fff', marginTop: 40, fontSize: 14, borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', cursor: 'pointer' }} type="button" onClick={() => this.props.toggleSub('tags')}>Return</button>
                    <button style={{ width: 160, height: 30, backgroundColor: 'rgb(1, 22, 48)', color: '#fff', marginTop: 40, fontSize: 14, borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', cursor: 'pointer' }} onClick={this.saveTags}>Save</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    contacts: state.contacts,
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { updateContactTags })(ContactTagsAlt2);