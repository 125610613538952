import axios from "axios";
import { tokenConfig, tokenConfigFile } from './authActions';
import { returnErrors } from './errorActions';
import {
    CLIENTS_INIT_LOADING,
    CLIENTS_LOADING,
    CLIENTS_GET,
    CLIENTS_FILTER,
    CLIENTS_FILTER_SUB,
    CLIENTS_SORT,
    CLIENTS_SOCKET,
    CLIENTS_SOCKET_DELETE,
    NOTIFICATIONS
} from "./types";

// async function transpose(array, tknConfig){
//     var newArray = await array.map((item, index) => {
//         const links = [];
//         if(item.commission_base){
//             item.commission_base = Number(item.commission_base)
//         }
//         if(item.applemusic){
//             links.push({ desc: 'Apple Music', url: item.applemusic });
//             // delete item.applemusic;
//         }
//         if(item.bandcamp){
//             links.push({ desc: 'Bandcamp', url: item.bandcamp });
//             // delete item.bandcamp;
//         }
//         if(item.facebook){
//             links.push({ desc: 'Facebook', url: item.facebook });
//             // delete item.facebook;
//         }
//         if(item.instagram){
//             links.push({ desc: 'Instagram', url: item.instagram });
//             // delete item.instagram;
//         }
//         if(item.spotify && item.spotify.url || item.spotify && item.spotify.id){
//             links.push({ desc: 'Spotify', url: item.spotify.url });
//             item.spotify_id = item.spotify.id;
//             // delete item.spotify;
//         }
//         if(item.twitter){
//             links.push({ desc: 'Twitter', url: item.twitter });
//             // delete item.twitter;
//         }
//         if(item.website){
//             links.push({ desc: 'Website', url: item.website });
//             // delete item.website;
//         }
//         if(item.youtube){
//             links.push({ desc: 'YouTube', url: item.youtube });
//             // delete item.youtube;
//         }
//         item.links = links;
//         return item;
//         // const data = {
//         //     data: item
//         // }
//         // await axios.post(`${process.env.REACT_APP_API}/offers/update/transpose/${item._id}`, data, tokenConfig(getState))
//         //     .then(res => {})
//         //     .catch(err => {})
//     })
//     newArray.map(async (item) => {
//         const data = {
//             data: item
//         }
//         await axios.post(`${process.env.REACT_APP_API}/clients/update/transpose/${item._id}`, data, tknConfig)
//             .then(res => {})
//             .catch(err => {})
//     })
// }

export const setClientsLoading = () => {
    return {
        type: CLIENTS_LOADING
    };
};

export const setClientsInitLoading = () => {
    return {
        type: CLIENTS_INIT_LOADING
    };
};

export const getClients = () => async (dispatch, getState) => {
    dispatch(setClientsInitLoading());
    await axios.get(`${process.env.REACT_APP_API}/clients/`, tokenConfig(getState))
        .then(async (res) => {
            // const tknConfig = tokenConfig(getState);
            // await transpose(res.data, tknConfig);
            dispatch({
                type: CLIENTS_GET,
                payload: res.data
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const applyClientFilter = (client_filter) => (dispatch, getState) => {
    dispatch(setClientsLoading());
    dispatch({
        type: CLIENTS_FILTER,
        payload: client_filter
    })
};

export const applyClientFilterSub = (array) => (dispatch, getState) => {
    dispatch(setClientsLoading());
    dispatch({
        type: CLIENTS_FILTER_SUB,
        payload: array
    })
};

export const clientSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: CLIENTS_SORT,
        payload: sort
    })
};

export const addClient = client => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/clients/add`, client, tokenConfig(getState))
        .then(res => {
            dispatch({
               type: NOTIFICATIONS,
               payload: res.data
            })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const addClientSocket = client => (dispatch) => {
//     dispatch({
//         type: SADD_CLIENT,
//         payload: client
//     })
// }

export const updateClient = (client, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/clients/update/${id}`, client, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: NOTIFICATIONS,
                payload: res.data
             })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const updateClientSocket = client => (dispatch) => {
//     dispatch({
//         type: UPDATE_CLIENT_SOCKET,
//         payload: client
//     })
// }

export const clientSocket = client => (dispatch) => {
    dispatch({
        type: CLIENTS_SOCKET,
        payload: client
    })
}

export const deleteClient = id => (dispatch, getState) => {
    return axios.delete(`${process.env.REACT_APP_API}/clients/${id}`, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //    type: NOTIFICATIONS,
            //    payload: id
            // })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const deleteClientSocket = client => (dispatch) => {
    dispatch({
        type: CLIENTS_SOCKET_DELETE,
        payload: client
    })
}

export const uploadAttachmentClient = (file, name, key, type, notes, date, user, id) => async (dispatch, getState) => {

    const info = [{
        name: name,
        key: key,
        type: type,
        notes: notes,
        user: user,
        date: date
    }]

    const json = JSON.stringify(info);
    const blob = new Blob([json], {
        type: 'application/json'
    });

    var formData = new FormData();
    formData.append('file', file);
    formData.set('info', blob);

    return axios.post(`${process.env.REACT_APP_API}/clients/attachment/upload/${id}`, formData, tokenConfigFile(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });

}

export const downloadAttachmentClient = (data) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/clients/attachment/download`, data, tokenConfig(getState))
        .then(res => {

            var a = document.createElement("a"); //Create <a>
            a.href = res.data.file; //Image Base64 Goes here
            a.download = res.data.title; //File name Here
            a.click(); //Downloaded file

            return "Success"
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const getAttachment = (data) => async (dispatch, getState) => {
    return await axios.post(`${process.env.REACT_APP_API}/clients/attachment/get`, data, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const deleteAttachmentClient = (data, deal_id) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/clients/attachment/delete/${deal_id}`, data, tokenConfig(getState))
        .then(res => {
            return "Success"
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const getClientAccess = (id) => async (dispatch, getState) => {
    return axios.get(`${process.env.REACT_APP_API}/clients/access/${id}`, tokenConfig(getState))
        .then((res) => {
            // const tknConfig = tokenConfig(getState);
            // await transpose(res.data, tknConfig);
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
};