import React, { Component } from "react";
import { Link } from 'react-router-dom';

class RedirectMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        //<img src={satellite} width={200} height={200} />

        return (
            <div style={{ width: '100%', height: '100vh', backgroundColor: '#000' }}>
                <h2 style={{ color: '#cdcdcd', fontSize: 15, marginTop: 25, marginLeft: 20 }}>You have been logged out.  Please click <Link to="/agentlogin">here</Link> to login.</h2>
            </div>
        );
    }
}


export default RedirectMain;