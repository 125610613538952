import {
    DASH_LOADING,
    DASH_SET,
} from "../actions/types";

const initialState = {
    loading: false,
    data: [],
    filtered: [],
    artists: [],
    artists_filtered: [],
    render: [],
    render_filtered: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DASH_LOADING:
            return {
                ...state,
                loading: true
            };
        case DASH_SET:
            var dash = action.payload;
            return {
                ...state,
                data: dash.data,
                filtered: dash.filtered,
                artists: dash.artists,
                artists_filtered: dash.artists_filtered,
                render: dash.render,
                render_filtered: dash.render_filtered,
                loading: false
            };
        default:
            return state;
    }
}