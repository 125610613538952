import {
    FESTIVALS_LOADING,
    FESTIVALS_GET,
    FESTIVALS_FILTER,
    FESTIVALS_SORT,
    FESTIVALS_SOCKET,
    FESTIVALS_SOCKET_DELETE
} from "../actions/types";
import _ from 'lodash';
import moment from 'moment';

const initialState = {
    items: [],
    filtered_festivals: [],
    filter: {
        festival: '',
        status: 'Active',
        _id: '',
        start_date: moment().format('MM/DD/YYYY'),
        end_date: moment().add(1, 'years').format('MM/DD/YYYY'),
        city: '',
        state: '',
        country: '',
        min_capacity: '',
        max_capacity: '',
    },
    loading: false,
    sort: {
        cat: 'festival',
        dir: 'asc'
    }
};

export default function (state = initialState, action) {
    //var data = [...state.items];
    //var filtered_data = [...state.filtered_festivals];
    switch (action.type) {
        case FESTIVALS_LOADING:
            return {
                ...state,
                loading: true
            };
        case FESTIVALS_GET:
            //var sortedFestivals = _.orderBy(action.payload, ['festival'], ['asc']);
            var data = action.payload;
            var year = moment().format('YYYY');

            var newArray = [];
            var nonArray = [];
            data.map((item, index) => {
                const d = item.dates;
                const l = d.length;
                if (l > 0) {
                    item.dates.map(dates => {
                        var min = null;
                        var max = null;
                        if(dates.dates.length > 0){
                            min = dates.dates.reduce(function (a, b) { return new Date(a.date) < new Date(b.date) ? a : b; });
                            max = dates.dates.reduce(function (a, b) { return new Date(a.date) > new Date(b.date) ? a : b; });
                        } 
                        // else {
                        //     min = dates.dates.reduce(function (a, b) { if(a.date && b.date) { return new Date(a.date) < new Date(b.date) ? a : b; } else if (a.date) { return new Date (a.date) } else if (b.date) { return new Date (b.date) }});
                        //     max = dates.dates.reduce(function (a, b) { if(a.date && b.date) { return new Date(a.date) > new Date(b.date) ? a : b; } else if (a.date) { return new Date (a.date) } else if (b.date) { return new Date (b.date) }});
                        // }
                        var filter = state.filter;
                        // const nd = dates.dates.map(i => new Date(i.date))
                        if (max <= filter.end_date || min >= filter.start_date) {
                            newArray.push({ ...item, renDates: dates.dates, min: min, max: max })
                        }
                        // if (dates.year === year) {
                        //     // if(dates.year === year){
                        //     //     newArray.push({...item, renDates: dates.dates})
                        //     // }
                        //     newArray.push({ ...item, renDates: dates.dates })
                        // }
                    })
                } else {
                    newArray.push({ ...item, renDates: [{ date: '', _id: '' }], min: '', max: '' })
                }

            })
            // var sortedFestivals = _.sortBy(newArray, function (dateObj) {
            //     // var date = dateObj.dates.map((item, index) => {
            //     //     //if(item.year === "2021"){
            //     //         return new Date(item.dates[0].date);
            //     //     //}
            //     // })

            //     // var sorted = [];
            //     // dateObj.map((item, index) => {
            //     //     if(item.year === year){
            //     //         sorted.push(item)
            //     //     }
            //     // })

            //     if (dateObj.dates.length > 0) {
            //         return new Date(dateObj.min)
            //         // dateObj.dates.map((item, index) => {
            //         //     var min = item.dates.reduce(function (a, b) { return a < b ? a : b; }); 
            //         //     var max = item.dates.reduce(function (a, b) { return a > b ? a : b; });

            //         // })
            //         // return new Date(dateObj.dates[0].dates[0].date)
            //     }

            //     // if (sorted.length > 0) {
            //     //     return new Date(sorted.dates[0].dates[0].date)
            //     // }

            // });

            var sortedFestivals = _.sortBy(newArray, function (dateObj) {
                return new Date(dateObj.min.date);
            });

            var festival_filter = state.filter;
            var filtered_data = sortedFestivals;
            if (festival_filter.festival) {
                filtered_data = filtered_data.filter((item) => {
                    var filterBatch = item.festival
                    return filterBatch.indexOf(festival_filter.festival) >= 0
                })
            }
            if (festival_filter.status) {
                filtered_data = filtered_data.filter((item) => {
                    var filterBatch = item.status
                    if (item.status) {
                        return filterBatch.indexOf(festival_filter.status) >= 0
                    }
                })
            }
            if (festival_filter._id) {
                filtered_data = filtered_data.filter((item) => {
                    var filterBatch = item._id
                    return filterBatch.indexOf(festival_filter._id) >= 0
                })
            }
            if (festival_filter.city) {
                filtered_data = filtered_data.filter((item) => {
                    var filterBatch = item.address.city
                    return filterBatch.indexOf(festival_filter.city) >= 0
                })
            }
            if (festival_filter.state) {
                filtered_data = filtered_data.filter((item) => {
                    var filterBatch = item.address.state
                    return filterBatch.indexOf(festival_filter.state) >= 0
                })
            }
            if (festival_filter.country) {
                filtered_data = filtered_data.filter((item) => {
                    var filterBatch = item.address.country
                    return filterBatch.indexOf(festival_filter.country) >= 0
                })
            }
            if (festival_filter.start_date) {
                filtered_data = filtered_data.filter((item) => {
                    const today = new Date();
                    var val = _.get(item, 'renDates')
                    const closest = val.reduce((a, b) => new Date(a.date) - today < new Date(b.date) - today ? a : b);
                    var filterBatch = moment(closest.date).format('MM/DD/YYYY')
                    var start = festival_filter.start_date;
                    return new Date(filterBatch) > new Date(start)
                })
            }
            if (festival_filter.end_date) {
                filtered_data = filtered_data.filter((item) => {
                    const today = new Date();
                    var val = _.get(item, 'renDates')
                    const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                    var filterBatch = moment(furthest.date).format('MM/DD/YYYY')
                    var end = festival_filter.end_date;
                    return new Date(filterBatch) <= new Date(end)
                })
            }
            if (festival_filter.min_capacity) {
                filtered_data = filtered_data.filter((item) => {
                    var cap = Number(item.capacity);
                    if (cap > 0) {
                        return cap >= Number(festival_filter.min_capacity)
                    }
                })
            }
            if (festival_filter.max_capacity) {
                filtered_data = filtered_data.filter((item) => {
                    var cap = Number(item.capacity);
                    if (cap > 0) {
                        return cap >= Number(festival_filter.max_capacity)
                    }
                })
            }

            return {
                ...state,
                items: sortedFestivals,
                filtered_festivals: filtered_data,
                loading: false
            };
        case FESTIVALS_FILTER:
            var festival_filter = action.payload;
            var festival_data = state.items;
            if (festival_filter.festival) {
                festival_data = festival_data.filter((item) => {
                    var filterBatch = item.festival
                    return filterBatch.indexOf(festival_filter.festival) >= 0
                })
            }
            if (festival_filter.status) {
                festival_data = festival_data.filter((item) => {
                    var filterBatch = item.status
                    if (item.status) {
                        return filterBatch.indexOf(festival_filter.status) >= 0
                    }
                })
            }
            if (festival_filter._id) {
                festival_data = festival_data.filter((item) => {
                    var filterBatch = item._id
                    return filterBatch.indexOf(festival_filter._id) >= 0
                })
            }
            if (festival_filter.city) {
                festival_data = festival_data.filter((item) => {
                    var filterBatch = item.address.city
                    return filterBatch.indexOf(festival_filter.city) >= 0
                })
            }
            if (festival_filter.state) {
                festival_data = festival_data.filter((item) => {
                    var filterBatch = item.address.state
                    return filterBatch.indexOf(festival_filter.state) >= 0
                })
            }
            if (festival_filter.country) {
                festival_data = festival_data.filter((item) => {
                    var filterBatch = item.address.country
                    return filterBatch.indexOf(festival_filter.country) >= 0
                })
            }
            if (festival_filter.start_date) {
                festival_data = festival_data.filter((item) => {
                    const today = new Date();
                    var val = _.get(item, 'renDates')
                    const closest = val.reduce((a, b) => new Date(a.date) - today < new Date(b.date) - today ? a : b);
                    var filterBatch = moment(closest.date).format('MM/DD/YYYY')
                    // item.renDates.map((d, i) => {
                    //     var val = _.get(d, 'dates')
                    //     const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                    //     filterBatch = new Date(closest.date)
                    // })
                    var start = moment(new Date(festival_filter.start_date)).format('MM/DD/YYYY')
                    return new Date(filterBatch) > new Date(start)
                })
            }
            if (festival_filter.end_date) {
                festival_data = festival_data.filter((item) => {
                    const today = new Date();
                    var val = _.get(item, 'renDates')
                    const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                    var filterBatch = moment(furthest.date).format('MM/DD/YYYY')
                    // item.renDates.map((d, i) => {
                    //     var val = _.get(d, 'dates')
                    //     const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                    //     filterBatch = new Date(furthest.date)
                    // })
                    var end = moment(new Date(festival_filter.end_date)).format('MM/DD/YYYY')
                    return new Date(filterBatch) <= new Date(end)
                })
            }
            if (festival_filter.min_capacity) {
                festival_data = festival_data.filter((item) => {
                    var cap = Number(item.capacity);
                    if (cap > 0) {
                        return cap >= Number(festival_filter.min_capacity)
                    }
                })
            }
            if (festival_filter.max_capacity) {
                festival_data = festival_data.filter((item) => {
                    var cap = Number(item.capacity);
                    if (cap > 0) {
                        return cap >= Number(festival_filter.max_capacity)
                    }
                })
            }
            // var sort = state.sort;
            // festival_data = _.sortBy(festival_data, function (dateObj) {
            //     return new Date(dateObj.min.date);
            // });
            // festival_data = _.orderBy(festival_data, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            return {
                ...state,
                filtered_festivals: festival_data,
                filter: festival_filter,
                loading: false
            };
        case FESTIVALS_SORT:
            var sort = action.payload;
            var sortedFestivals = [];
            if (sort.cat === '') {
                sortedFestivals = _.sortBy(state.filtered_festivals, function (dateObj) {
                    return new Date(dateObj.min.date);
                });
            } else {
                sortedFestivals = _.orderBy(state.filtered_festivals, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            }
            return {
                ...state,
                filtered_festivals: sortedFestivals,
                sort: sort,
                loading: false
            };
        // case ADD_FESTIVAL:
        //     return {
        //         ...state,
        //         //clients: [action.payload, ...state.clients],
        //     };
        // case ADD_FESTIVAL_SOCKET:
        //     var festival_filter = state.filter;
        //     var newArray = [...state.items, action.payload];
        //     var festival_data = newArray.filter((item) => {
        //         var filterBatch = item.festival
        //         return filterBatch.indexOf(festival_filter.festival) >= 0
        //     })
        //     festival_data = festival_data.filter((item) => {
        //         var filterBatch = item.address.city
        //         return filterBatch.indexOf(festival_filter.city) >= 0
        //     })
        //     festival_data = festival_data.filter((item) => {
        //         var filterBatch = item.address.state
        //         return filterBatch.indexOf(festival_filter.state) >= 0
        //     })
        //     festival_data = festival_data.filter((item) => {
        //         var filterBatch = item.address.country
        //         return filterBatch.indexOf(festival_filter.country) >= 0
        //     })
        //     if (festival_filter.start_date) {
        //         festival_data = festival_data.filter((item) => {
        //             const today = moment();
        //             var filterBatch = ""
        //             item.dates.map((d, i) => {
        //                 var val = _.get(d, 'dates')
        //                 const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
        //                 filterBatch = new Date(closest.date)
        //             })
        //             var start = new Date(festival_filter.start_date)
        //             return filterBatch >= start
        //         })
        //     }
        //     if (festival_filter.end_date) {
        //         festival_data = festival_data.filter((item) => {
        //             const today = new Date();
        //             var filterBatch = ""
        //             item.dates.map((d, i) => {
        //                 var val = _.get(d, 'dates')
        //                 const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
        //                 filterBatch = new Date(furthest.date)
        //             })
        //             var end = new Date(festival_filter.end_date)
        //             return filterBatch <= end
        //         })
        //     }
        //     festival_data = _.orderBy(festival_data, [`${state.sort.cat}`.toLowerCase()], [state.sort.dir]);
        //     return {
        //         ...state,
        //         items: newArray,
        //         filtered_festivals: festival_data,
        //     };
        // case UPDATE_FESTIVAL:
        //     var index = data.findIndex(obj => obj._id === action.payload._id);
        //     data[index] = action.payload;
        //     return {
        //         ...state,
        //         //items: data
        //     }
        // case UPDATE_FESTIVAL_SOCKET:
        //     let newFest = action.payload;
        //     const d = newFest.dates;
        //     const l = d.length;
        //     var newObj = [];
        //     if (l > 0) {
        //         newFest.dates.map(dates => {
        //             var min = dates.dates.reduce(function (a, b) { return a < b ? a : b; });
        //             var max = dates.dates.reduce(function (a, b) { return a > b ? a : b; });
        //             var filter = state.filter;
        //             if (max <= filter.end_date || min >= filter.start_date) {
        //                 newObj = { ...newFest, renDates: dates.dates, min: min, max: max }
        //             }
        //         })
        //     } else {
        //         newObj = { ...newFest, renDates: [{ date: '', _id: '' }], min: '', max: '' }
        //     }
        //     var data = [...state.items];
        //     var filtered_data = [...state.filtered_festivals];
        //     var index = data.findIndex(obj => obj._id === newObj._id)
        //     data[index] = newObj
        //     const found = filtered_data.findIndex(obj => obj._id === newObj._id);
        //     if (found >= 0) {
        //         filtered_data[found] = newObj;
        //     }
        //     var sort = state.sort;
        //     filtered_data = _.sortBy(filtered_data, function (dateObj) {
        //         return new Date(dateObj.min.date);
        //     });

        //     return {
        //         ...state,
        //         items: data,
        //         filtered_festivals: filtered_data
        //     }
        case FESTIVALS_SOCKET:
            let newFest = action.payload;
            const d = newFest.dates;
            const l = d.length;
            var newObj;
            if (l > 0) {
                newFest.dates.map(idates => {
                    var min = null;
                    var max = null;
                    if(idates.dates.length > 0){
                        min = idates.dates.reduce(function (a, b) { return new Date(a.date) < new Date(b.date) ? a : b; });
                        max = idates.dates.reduce(function (a, b) { return new Date(a.date) > new Date(b.date) ? a : b; });
                    } 
                    var filter = state.filter;
                    if (max && new Date(max.date) <= new Date(filter.end_date) || min && new Date(min.date) >= new Date(filter.start_date)) {
                        newObj = { ...newFest, renDates: idates.dates, min: min, max: max }
                    }
                })
            } else {
                newObj = { ...newFest, renDates: [{ date: '', _id: '' }], min: '', max: '' }
            }
            var data = state.items;
            var f_data = state.filtered_festivals;
            var index = state.items.findIndex(obj => obj._id === newFest._id);
            var f_index = state.filtered_festivals.findIndex(obj => obj._id === newFest._id);
            if (index >= 0) {
                data[index] = newObj;
                if (f_index >= 0) {
                    f_data[f_index] = newObj;
                }
            } else {
                if (newObj) {
                    data = [newObj, ...state.items];
                } else {
                    data = [...state.items];
                }
                var festival_filter = state.filter;
                f_data = data;
                if (festival_filter.festival) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.festival
                        return filterBatch.indexOf(festival_filter.festival) >= 0
                    })
                }
                if (festival_filter.city) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.city
                        return filterBatch.indexOf(festival_filter.city) >= 0
                    })
                }
                if (festival_filter.state) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.state
                        return filterBatch.indexOf(festival_filter.state) >= 0
                    })
                }
                if (festival_filter.country) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.country
                        return filterBatch.indexOf(festival_filter.country) >= 0
                    })
                }
                if (festival_filter.start_date) {
                    f_data = f_data.filter((item) => {
                        const today = moment();
                        var filterBatch = "";
                        item.dates.map((d, i) => {
                            var val = _.get(d, 'dates')
                            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                            filterBatch = new Date(closest.date)
                        })
                        var start = new Date(festival_filter.start_date)
                        return filterBatch >= start
                    })
                }
                if (festival_filter.end_date) {
                    f_data = f_data.filter((item) => {
                        const today = new Date();
                        var filterBatch = ""
                        item.dates.map((d, i) => {
                            var val = _.get(d, 'dates')
                            const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                            filterBatch = new Date(furthest.date)
                        })
                        var end = new Date(festival_filter.end_date)
                        return filterBatch <= end
                    })
                }
                var sort = state.sort;
                if (sort.cat === '') {
                    f_data = _.sortBy(f_data, function (dateObj) {
                        return new Date(dateObj.min.date);
                    });
                } else {
                    f_data = _.orderBy(f_data, [`${sort.cat}`.toLowerCase()], [sort.dir]);
                }
            }


            return {
                ...state,
                items: data,
                filtered_festivals: f_data
            }
        // case DELETE_FESTIVAL:
        //     return {
        //         ...state,
        //         //items: state.items.filter(festival => festival._id !== action.payload),
        //     };
        case FESTIVALS_SOCKET_DELETE:
            return {
                ...state,
                items: state.items.filter(festival => festival._id !== action.payload._id),
                filtered_festivals: state.filtered_festivals.filter(festival => festival._id !== action.payload._id),
            };
        default:
            return state;
    }
}