import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CurrencyAutocomplete from '../autocompletes/currency-autocomplete';

export default function CurrencyDialog(props) {
    const [stateCurrencySelect, setCurrencySelect] = useState(props.val);

    useEffect(() => {
        setCurrencySelect(props.val);
    }, [props.val])

    const closeDialog = () => {
        props.handleClose();
    };

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={props.handleClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Currency</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
                    <div
                        style={{
                            paddingRight: 17,
                            height: "100%",
                            width: "100%",
                            boxSizing: "content-box",
                            overflow: "scroll"
                        }}>
                        <table style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Ticket Scaling:</td>
                                    <td style={{ width: '50%' }}><CurrencyAutocomplete val={stateCurrencySelect.ticket_scaling} onChange={props.handleCurrencyChange} set="ticket_scaling" /></td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Expenses:</td>
                                    <td style={{ width: '50%' }}><CurrencyAutocomplete val={stateCurrencySelect.expenses} onChange={props.handleCurrencyChange} set="expenses" /></td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Deal Terms:</td>
                                    <td style={{ width: '50%' }}><CurrencyAutocomplete val={stateCurrencySelect.deal_terms} onChange={props.handleCurrencyChange} set="deal_terms" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={closeDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}