import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    flexDirection: 'row',
    overflowX: 'scroll',
    // alignItems: 'center', 
    // height: 70, 
    // width: '100%'
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

export default function ChatAvatars(props){
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.connectedUsers.map((item, index) => {
        return (
          <div style={{ width: 'auto', height: 70, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
            {!item.img ?
              <StyledBadge
                onClick={() => props.clickUser(item)}
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar alt="Avatar" className={classes.orange}></Avatar>
              </StyledBadge> :
              <StyledBadge
                onClick={() => props.clickUser(item)}
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar alt="Avatar" className={classes.orange} src={item.img} onClick={() => props.clickUser(item)}></Avatar>
              </StyledBadge>}
            <p style={{ fontSize: 8 }}>{item.name}</p>
          </div>
        )
      })}

    </div>
  );
}