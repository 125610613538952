import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FaPlusSquare, FaCaretDown, FaTrash } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { getCurrencies } from '../../actions/accountingActions';
import DatePicker from "react-datepicker";
import getSymbolFromCurrency from 'currency-symbol-map';

class DealTerms extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            depositadd: 1,
            currencyconvert: false,
            convertcurrency: '',
            convertrate: '',
            convert: false,
            rates: [],
            isLoaded: false,
            gross_potential: null,
            tax_total: null,
            exp_total: null,
            walkout_potential: null,
            cvt_gross_potential: null,
            cvt_tax_total: null,
            cvt_exp_total: null,
            cvt_walkout_potential: null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        var walkout = 0;
        var guarantee = Number(this.props.guaranteeval);
        var customwalkout = Number(this.props.customwalkoutval);
        var grosstotalval = Number(this.props.grosstotalval);
        var exptotal = Number(this.props.exptotal);
        var taxtotal = Number(this.props.taxtotal);
        var bonustotal = this.props.bonustotal;

        const base = this.props.currency.deal_terms || 'USD';
        const currencies = await this.props.getCurrencies(base);
        const rates = currencies.rates;
        var rates_arr = Object.entries(rates).map(([key, value]) => { return { key: key, value: value } });
        if (currencies) {
            this.setState({
                isLoaded: true,
                rates: rates,
                rates_arr: rates_arr
            });
        }

        var expense_rate = 1;
        if (this.props.currency.expenses && this.props.currency.expenses !== this.props.currency.deal_terms) {
            expense_rate = rates[this.props.currency.expenses];
            if (expense_rate) {
                exptotal = exptotal * expense_rate;
                taxtotal = taxtotal * expense_rate;
            }
        }
        var scaling_rate = 1;
        if (this.props.currency.ticket_scaling && this.props.currency.ticket_scaling !== this.props.currency.deal_terms) {
            scaling_rate = rates[this.props.currency.ticket_scaling];
            if (scaling_rate) {
                grosstotalval = grosstotalval * scaling_rate;
            }
        }

        if (this.props.dealtype === "Flat") {
            var ng = guarantee + bonustotal;
            if (ng === Math.floor(ng)) {
                walkout = ng.toFixed(2)
            } else {
                walkout = ng.toFixed(2)
            }
        } else if (this.props.dealtype === "Plus") {
            var plus = (grosstotalval - guarantee - exptotal - taxtotal) * (this.props.dealpercentageval * .01)
            var plusamount = plus + guarantee + bonustotal;
            if (plusamount === Math.floor(plusamount)) {
                walkout = plusamount.toFixed(2)
            } else {
                walkout = plusamount.toFixed(2)
            }
        } else if (this.props.dealtype === "Vs Gross") {
            var grossamount = (grosstotalval - taxtotal) * (this.props.dealpercentageval * .01)
            if (grossamount <= this.props.guaranteeval) {
                if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (guarantee + bonustotal).toFixed(2);
                } else {
                    walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
                }
            } else if (grossamount > this.props.guaranteeval) {
                var ng = grossamount + bonustotal;
                if (ng === Math.floor(ng) && bonustotal === Math.floor(bonustotal)) {
                    walkout = Number(ng).toFixed(2)
                } else {
                    walkout = Number(ng).toFixed(2)
                }
            }
        } else if (this.props.dealtype === 'Vs Net') {
            var grossamount = (grosstotalval - taxtotal - exptotal) * (this.props.dealpercentageval * .01);
            if (grossamount <= this.props.guaranteeval) { 
                if (guarantee === Math.floor(this.props.guaranteeval) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (guarantee + bonustotal).toFixed(2);
                } else {
                    walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
                }
            } else if (grossamount > this.props.guaranteeval) {
                var ng = grossamount + bonustotal;
                if (ng === Math.floor(ng) && bonustotal === Math.floor(bonustotal)) {
                    walkout = Number(ng).toFixed(2)
                } else {
                    walkout = Number(ng).toFixed(2)
                }
            }
        } else if (this.props.dealtype === 'Promoter Profit') {
            var grossamount = (grosstotalval - taxtotal - exptotal - (exptotal * .15)) * (this.props.dealpercentageval * .01)
            if (grossamount <= this.props.guaranteeval) {
                if (guarantee === Math.floor(this.props.guaranteeval) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (guarantee + bonustotal).toFixed(2);
                } else {
                    walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
                }
            } else if (grossamount > this.props.guaranteeval) {
                var ng = grossamount + bonustotal;
                if (ng === Math.floor(ng) && bonustotal === Math.floor(bonustotal)) {
                    walkout = Number(ng).toFixed(2)
                } else {
                    walkout = Number(ng).toFixed(2)
                }
            }
        } else if (this.props.dealtype === "Plus (Custom Walkout)") {
            if (guarantee <= customwalkout) {
                if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (customwalkout + bonustotal).toFixed(2);
                } else {
                    walkout = customwalkout.toFixed(2) + bonustotal.toFixed(2);
                }
            } else if (guarantee > customwalkout) {
                if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (guarantee + bonustotal).toFixed(2);
                } else {
                    walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
                }
            }
        } else if (this.props.dealtype === "Vs Gross (Custom Walkout)") {
            if (guarantee <= customwalkout) {
                if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (customwalkout + bonustotal).toFixed(2);
                } else {
                    walkout = customwalkout.toFixed(2) + bonustotal.toFixed(2);
                }
            } else if (guarantee > customwalkout) {
                if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (guarantee + bonustotal).toFixed(2);
                } else {
                    walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
                }
            }
        } else if (this.props.dealtype === "Vs Net (Custom Walkout)") {
            if (guarantee <= customwalkout) {
                if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (customwalkout + bonustotal).toFixed(2);
                } else {
                    walkout = customwalkout.toFixed(2) + bonustotal.toFixed(2);
                }
            } else if (guarantee > customwalkout) {
                if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (guarantee + bonustotal).toFixed(2);
                } else {
                    walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
                }
            }
        } else if (this.props.dealtype === "Promoter Profit (Custom Walkout)") {
            if (guarantee <= customwalkout) {
                if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (customwalkout + bonustotal).toFixed(2);
                } else {
                    walkout = customwalkout.toFixed(2) + bonustotal.toFixed(2);
                }
            } else if (guarantee > customwalkout) {
                if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
                    walkout = (guarantee + bonustotal).toFixed(2);
                } else {
                    walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
                }
            }
        }
        this.setState({
            gross_potential: this.props.gross_pot_val,
            tax_total: Number(this.props.taxtotal),
            exp_total: Number(this.props.exptotal),
            walkout_potential: walkout
        })
    }

    componentDidUpdate(nextProps, nextState) {
        if (nextProps !== this.props) {
            var walkout = 0;
            var guarantee = Number(this.props.guaranteeval);
            var customwalkout = Number(this.props.customwalkoutval);
            var grosstotalval = Number(this.props.grosstotalval);
            var exptotal = Number(this.props.exptotal);
            var taxtotal = Number(this.props.taxtotal);
            var bonustotal = this.props.bonustotal;
            const rates = this.state.rates;

            var expense_rate = 1;
            if (this.props.currency.expenses && this.props.currency.expenses !== this.props.currency.deal_terms) {
                expense_rate = rates[this.props.currency.expenses];
                if (expense_rate) {
                    exptotal = exptotal * expense_rate;
                    taxtotal = taxtotal * expense_rate;
                }
            }
            var scaling_rate = 1;
            if (this.props.currency.ticket_scaling && this.props.currency.ticket_scaling !== this.props.currency.deal_terms) {
                scaling_rate = rates[this.props.currency.ticket_scaling];
                if (scaling_rate) {
                    grosstotalval = grosstotalval * scaling_rate;
                }
            }

            if (this.props.dealtype === "Flat") {
                var ng = guarantee + bonustotal;
                if (ng === Math.floor(ng)) {
                    walkout = ng.toFixed(2)
                } else {
                    walkout = ng.toFixed(2)
                }
            } else if (this.props.dealtype === "Plus") {
                var plus = (grosstotalval - guarantee - exptotal - taxtotal) * (this.props.dealpercentageval * .01)
                var plusamount = plus + guarantee + bonustotal;
                if (plusamount === Math.floor(plusamount)) {
                    walkout = plusamount.toFixed(2)
                } else {
                    walkout = plusamount.toFixed(2)
                }
            } else if (this.props.dealtype === "Vs Gross") {
                var grossamount = (grosstotalval - taxtotal) * (this.props.dealpercentageval * .01)
                if (grossamount <= this.props.guaranteeval) {
                    if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
                        walkout = (guarantee + bonustotal).toFixed(2);
                    } else {
                        walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
                    }
                } else if (grossamount > this.props.guaranteeval) {
                    var ng = grossamount + bonustotal;
                    if (ng === Math.floor(ng) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(ng).toFixed(2)
                    } else {
                        walkout = Number(ng).toFixed(2)
                    }
                }
            } else if (this.props.dealtype === 'Vs Net') {
                var grossamount = (grosstotalval - taxtotal - exptotal) * (this.props.dealpercentageval * .01)
                if (grossamount <= this.props.guaranteeval) {
                    if (guarantee === Math.floor(this.props.guaranteeval) && bonustotal === Math.floor(bonustotal)) {
                        walkout = (guarantee + bonustotal).toFixed(2);
                    } else {
                        walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
                    }
                } else if (grossamount > this.props.guaranteeval) {
                    var ng = grossamount + bonustotal;
                    if (ng === Math.floor(ng) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(ng).toFixed(2)
                    } else {
                        walkout = Number(ng).toFixed(2)
                    }
                }
            } else if (this.props.dealtype === 'Promoter Profit') {
                var grossamount = (grosstotalval - taxtotal - exptotal - (exptotal * .15)) * (this.props.dealpercentageval * .01)
                if (grossamount <= this.props.guaranteeval) {
                    if (guarantee === Math.floor(this.props.guaranteeval) && bonustotal === Math.floor(bonustotal)) {
                        walkout = (guarantee + bonustotal).toFixed(2);
                    } else {
                        walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
                    }
                } else if (grossamount > this.props.guaranteeval) {
                    var ng = grossamount + bonustotal;
                    if (ng === Math.floor(ng) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(ng).toFixed(2)
                    } else {
                        walkout = Number(ng).toFixed(2)
                    }
                }
            } else if (this.props.dealtype === "Plus (Custom Walkout)") {
                if (guarantee <= customwalkout) {
                    if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(customwalkout + bonustotal).toFixed(2);
                    } else {
                        walkout = Number(customwalkout + bonustotal).toFixed(2);
                    }
                } else if (guarantee > customwalkout) {
                    if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(guarantee + bonustotal).toFixed(2);
                    } else {
                        walkout = Number(guarantee + bonustotal).toFixed(2);
                    }
                }
            } else if (this.props.dealtype === "Vs Gross (Custom Walkout)") {
                if (guarantee <= customwalkout) {
                    if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(customwalkout + bonustotal).toFixed(2);
                    } else {
                        walkout = Number(customwalkout + bonustotal).toFixed(2);
                    }
                } else if (guarantee > customwalkout) {
                    if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(guarantee + bonustotal).toFixed(2);
                    } else {
                        walkout = Number(guarantee + bonustotal).toFixed(2);
                    }
                }
            } else if (this.props.dealtype === "Vs Net (Custom Walkout)") {
                if (guarantee <= customwalkout) {
                    if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(customwalkout + bonustotal).toFixed(2);
                    } else {
                        walkout = Number(customwalkout + bonustotal).toFixed(2);
                    }
                } else if (guarantee > customwalkout) {
                    if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(guarantee + bonustotal).toFixed(2);
                    } else {
                        walkout = Number(guarantee + bonustotal).toFixed(2);
                    }
                }
            } else if (this.props.dealtype === "Promoter Profit (Custom Walkout)") {
                if (guarantee <= customwalkout) {
                    if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(customwalkout + bonustotal).toFixed(2);
                    } else {
                        walkout = Number(customwalkout + bonustotal).toFixed(2);
                    }
                } else if (guarantee > customwalkout) {
                    if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
                        walkout = Number(guarantee + bonustotal).toFixed(2);
                    } else {
                        walkout = Number(guarantee + bonustotal).toFixed(2);
                    }
                }
            }
            this.setState({
                gross_potential: this.props.gross_pot_val,
                tax_total: Number(this.props.taxtotal),
                exp_total: Number(this.props.exptotal),
                walkout_potential: walkout
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    depositAdd = () => {
        if (this.state.depositadd < 4) {
            this.setState({
                depositadd: this.state.depositadd + 1
            })
        } else {
            alert("Hit deposit limit.  Please group together deposits if possible or contact the system admin.")
        }
    }
    depositMinus = () => {
        if (this.state.depositadd > 1) {
            this.setState({
                depositadd: this.state.depositadd - 1
            })
        } else {
            alert("Hit minimum deposit limit.  Can't remove any additional.")
        }
    }

    handleDeposit1DateChange = date => {
        this.setState({
            deposit1due: date
        });
    };

    updateDeposit = (props) => {
        this.props.updateDeposit(props)
    }

    toggleCurrencyConvert = async () => {
        const rates = this.state.rates;
        if (rates) {
            this.setState({
                currencyconvert: true
            });
        } else {
            this.setState({
                currencyconvert: false
            });
        }
    }
    onConvert = () => {
        const rates = this.state.rates;
        var rate = rates[this.state.convertcurrency];
        if (rate) {
            var cvt_gross_pot = this.state.gross_potential * rate;
            const cvt_gross_pot_val = parseInt(cvt_gross_pot, 10);
            const cvt_tax_total = this.state.tax_total * rate;
            const cvt_tax_total_val = parseInt(cvt_tax_total, 10);
            const cvt_exp_total = this.state.exp_total * rate;
            const cvt_exp_total_val = parseInt(cvt_exp_total, 10);
            const cvt_walkout = this.state.walkout_potential * rate;
            const cvt_walkout_val = parseInt(cvt_walkout, 10);

            this.setState({
                convertrate: rate,
                cvt_gross_potential: cvt_gross_pot_val,
                cvt_tax_total: cvt_tax_total_val,
                cvt_exp_total: cvt_exp_total_val,
                cvt_walkout_potential: cvt_walkout_val.toFixed(2)
            })
        }
    }

    offCurrencyConvert = () => {
        this.setState({
            currencyconvert: false,
            convertrate: '',
            convertcurrency: ''
        })
    }

    render() {

        var currency_symbol = getSymbolFromCurrency(this.props.currency.deal_terms);
        if (this.props.currency.deal_terms === 'CAD') {
            currency_symbol = 'CA$'
        }
        var expense_symbol = getSymbolFromCurrency(this.props.currency.expenses);
        if (this.props.currency.expenses === 'CAD') {
            expense_symbol = 'CA$'
        }
        var scaling_symbol = getSymbolFromCurrency(this.props.currency.ticket_scaling);
        if (this.props.currency.ticket_scaling === 'CAD') {
            scaling_symbol = 'CA$'
        }
        var convert_symbol = '';
        if (this.state.convertcurrency) {
            convert_symbol = getSymbolFromCurrency(this.state.convertcurrency);
        }
        if (this.state.convertcurrency === 'CAD') {
            convert_symbol = 'CA$'
        }

        // var formatter = new Intl.NumberFormat('fr-FR', {
        //     style: 'currency',
        //     currency: this.props.currency.deal_terms,

        //     // These options are needed to round to whole numbers if that's what you want.
        //     //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //     //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        //   });

        // var testval = formatter.format(2500);
        // console.log(testval)


        // var cvt_gross_pot = this.props.gross_pot_val * this.state.convertrate
        // const cvt_gross_pot_val = parseInt(cvt_gross_pot, 10);

        // const cvt_tax_total = this.props.taxtotal * this.state.convertrate
        // const cvt_tax_total_val = parseInt(cvt_tax_total, 10);

        // const cvt_exp_total = this.props.exptotal * this.state.convertrate
        // const cvt_exp_total_val = parseInt(cvt_exp_total, 10);

        // var walkout = 0;
        // var guarantee = Number(this.props.guaranteeval);
        // var customwalkout = Number(this.props.customwalkoutval);
        // var grosstotalval = Number(this.props.grosstotalval);
        // var exptotal = Number(this.props.exptotal);
        // var taxtotal = Number(this.props.taxtotal);

        // var bonustotal = this.props.bonustotal;
        // if (this.props.dealtype === "Flat") {
        //     var ng = guarantee + bonustotal;
        //     if (ng === Math.floor(ng)) {
        //         walkout = ng
        //     } else {
        //         walkout = ng.toFixed(2)
        //     }
        // } else if (this.props.dealtype === "Plus") {
        //     var plus = (grosstotalval - guarantee - exptotal - taxtotal) * (this.props.dealpercentageval * .01)
        //     var plusamount = plus + guarantee + bonustotal;
        //     if (plusamount === Math.floor(plusamount)) {
        //         walkout = plusamount
        //     } else {
        //         walkout = plusamount.toFixed(2)
        //     }
        // } else if (this.props.dealtype === "Vs Gross") {
        //     var grossamount = (grosstotalval - taxtotal) * (this.props.dealpercentageval * .01)
        //     if (grossamount <= this.props.guaranteeval) {
        //         if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = guarantee + bonustotal;
        //         } else {
        //             walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     } else if (grossamount > this.props.guaranteeval) {
        //         var ng = grossamount + bonustotal;
        //         if (ng === Math.floor(ng) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = Number(ng)
        //         } else {
        //             walkout = Number(ng).toFixed(2)
        //         }
        //     }
        // } else if (this.props.dealtype === 'Vs Net') {
        //     var grossamount = (grosstotalval - taxtotal - exptotal) * (this.props.dealpercentageval * .01)
        //     if (grossamount <= this.props.guaranteeval) {
        //         if (guarantee === Math.floor(this.props.guaranteeval) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = guarantee + bonustotal;
        //         } else {
        //             walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     } else if (grossamount > this.props.guaranteeval) {
        //         var ng = grossamount + bonustotal;
        //         if (ng === Math.floor(ng) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = Number(ng)
        //         } else {
        //             walkout = Number(ng).toFixed(2)
        //         }
        //     }
        // } else if (this.props.dealtype === 'Promoter Profit') {
        //     var grossamount = (grosstotalval - taxtotal - exptotal - (exptotal * .15)) * (this.props.dealpercentageval * .01)
        //     if (grossamount <= this.props.guaranteeval) {
        //         if (guarantee === Math.floor(this.props.guaranteeval) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = guarantee + bonustotal;
        //         } else {
        //             walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     } else if (grossamount > this.props.guaranteeval) {
        //         var ng = grossamount + bonustotal;
        //         if (ng === Math.floor(ng) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = Number(ng)
        //         } else {
        //             walkout = Number(ng).toFixed(2)
        //         }
        //     }
        // } else if (this.props.dealtype === "Plus (Custom Walkout)") {
        //     if (guarantee <= customwalkout) {
        //         if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = customwalkout + bonustotal;
        //         } else {
        //             walkout = customwalkout.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     } else if (guarantee > customwalkout) {
        //         if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = guarantee + bonustotal;
        //         } else {
        //             walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     }
        // } else if (this.props.dealtype === "Vs Gross (Custom Walkout)") {
        //     if (guarantee <= customwalkout) {
        //         if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = customwalkout + bonustotal;
        //         } else {
        //             walkout = customwalkout.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     } else if (guarantee > customwalkout) {
        //         if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = guarantee + bonustotal;
        //         } else {
        //             walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     }
        // } else if (this.props.dealtype === "Vs Net (Custom Walkout)") {
        //     if (guarantee <= customwalkout) {
        //         if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = customwalkout + bonustotal;
        //         } else {
        //             walkout = customwalkout.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     } else if (guarantee > customwalkout) {
        //         if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = guarantee + bonustotal;
        //         } else {
        //             walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     }
        // } else if (this.props.dealtype === "Promoter Profit (Custom Walkout)") {
        //     if (guarantee <= customwalkout) {
        //         if (customwalkout === Math.floor(customwalkout) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = customwalkout + bonustotal;
        //         } else {
        //             walkout = customwalkout.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     } else if (guarantee > customwalkout) {
        //         if (guarantee === Math.floor(guarantee) && bonustotal === Math.floor(bonustotal)) {
        //             walkout = guarantee + bonustotal;
        //         } else {
        //             walkout = guarantee.toFixed(2) + bonustotal.toFixed(2);
        //         }
        //     }
        // }

        // const cvt_walkout = walkout * this.state.convertrate;
        // const cvt_walkout_val = parseInt(cvt_walkout, 10);

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <table style={{ marginLeft: 25, width: '86%', marginTop: 35 }}>
                    <thead>
                        <tr>
                            <th>
                                <button style={{ width: 'auto', height: 'auto', fontSize: 12, fontStyle: 'italic', cursor: this.props.edit ? 'pointer' : 'default' }} type="button" onClick={this.props.edit ? this.props.toggleCurrencyDialog : null}>
                                    Deal Currency: {this.props.currency.deal_terms}
                                </button>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '55%', height: '100%', marginLeft: 35, marginTop: 15 }}>
                        <div style={{ display: 'flex', width: 800, height: 24 }}>
                            <div className="form-group">
                                <label style={{ width: 250, fontSize: 14 }}>Guarantee:</label>
                                <label style={{ width: 150, marginLeft: 22, fontSize: 14 }}>Deal Type:</label>
                                {this.props.dealtype === "Flat" || this.props.dealtype === "Bonus" || this.props.dealtype === "" ?
                                    null : <label style={{ width: 80, marginLeft: 20, fontSize: 14 }}>Percent:</label>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: 800 }}>
                            <NumberFormat style={{ width: 200, fontSize: 12, border: '.4px solid #000', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} className="form-control-sm" disabled={this.props.edit ? false : true} value={this.props.guaranteeval} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onGuaranteeValueChange} />
                            <input style={{ width: 50, fontSize: 12, borderTopRightRadius: 5, borderBottomRightRadius: 5, borderRight: '1px solid #000', borderTop: '1px solid #000', borderBottom: '1px solid #000' }} disabled value={this.props.currency.deal_terms} />
                            {this.props.edit ?
                                <select style={{ width: 150, marginLeft: 20, fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" name="dealtype" value={this.props.dealtype} onChange={this.props.onChange}>
                                    <option value=""> </option>
                                    <option value="Flat">Flat</option>
                                    <option value="Plus">Plus</option>
                                    <option value="Vs Gross">Vs Gross</option>
                                    <option value="Vs Net">Vs Net</option>
                                    <option value="Promoter Profit">Promoter Profit</option>
                                    <option value="Plus (Custom Walkout)">Plus (Custom Walkout)</option>
                                    <option value="Vs Gross (Custom Walkout)">Vs Gross (Custom Walkout)</option>
                                    <option value="Vs Net (Custom Walkout)">Vs Net (Custom Walkout)</option>
                                    <option value="Promoter Profit (Custom Walkout)">Promoter Profit (Custom Walkout)</option>
                                </select> :
                                <input style={{ fontSize: 12, border: '.4px solid #000', width: 150, marginLeft: 20, height: 28, borderRadius: 3, padding: 12 }} disabled={true} type="text" value={this.props.dealtype} />}
                            {this.props.dealtype === "Flat" || this.props.dealtype === "Bonus" || this.props.dealtype === "" ?
                                null : <NumberFormat style={{ width: 80, marginLeft: 20, fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.props.edit ? false : true} value={this.props.dealpercentageval} thousandSeparator={true} suffix={'%'} onValueChange={this.props.onDealPercValueChange} />}
                        </div>
                        {this.props.dealtype === "Plus (Custom Walkout)" || this.props.dealtype === "Vs Gross (Custom Walkout)" || this.props.dealtype === "Vs Net (Custom Walkout)" || this.props.dealtype === "Promoter Profit (Custom Walkout)" ?
                            <div>
                                <label style={{ height: 24, width: 150, marginTop: 30, fontSize: 14 }}>Custom Walkout:</label>
                                <NumberFormat style={{ width: 150, border: '.4px solid #000', fontSize: 12 }} className="form-control-sm" disabled={this.props.edit ? false : true} value={this.props.customwalkout} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onCustomWalkoutValueChange} />
                            </div> : null}
                        <div>
                            {!this.props.edit ?
                                null :
                                this.props.bonus_toggle ?
                                    <button style={{ marginTop: 15, color: '#cdcdcd', backgroundColor: 'rgb(1, 22, 48)', height: 28, fontSize: 12, borderRadius: 5 }} onClick={this.props.toggleBonus}>Remove Bonus</button> :
                                    <button style={{ marginTop: 15, color: '#cdcdcd', backgroundColor: 'rgb(1, 22, 48)', height: 28, fontSize: 12, borderRadius: 5 }} onClick={this.props.toggleBonus}>Add Bonus</button>}
                        </div>
                        <label style={{ marginTop: 15, color: '#000', height: 12, fontSize: 14 }}>Deal Points:</label>
                        <textarea
                            className="form-control"
                            style={{ width: '90%', border: '.4px solid #000', maxHeight: 250, marginBottom: 20, backgroundColor: this.props.edit ? '#fff' : '#FAFAFA', fontSize: 12, color: '#000' }}
                            disabled={this.props.edit ? false : true}
                            onChange={this.props.onChange}
                            value={this.props.dealpoints}
                            name="dealpoints"
                            type="textarea"
                        />
                        <div className="dealTermsCalcBundle">
                            <div style={{ display: 'flex', marginLeft: 30, marginTop: 10 }}>
                                <h2 style={{ color: '#cdcdcd', fontSize: 16, borderBottom: '2px solid #cdcdcd' }}>Offer Summary:</h2>
                            </div>
                            <div style={{ display: 'flex', marginLeft: 50, marginTop: 10, height: 15, width: '100%' }} className="form-group">
                                <label style={{ color: '#cdcdcd', fontSize: 14 }}>Gross Potential: </label>
                                <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat displayType={'text'} value={this.state.gross_potential} thousandSeparator={true} prefix={scaling_symbol} /></label>
                                {this.state.convertrate ?
                                    <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}>{'=>'}<NumberFormat style={{ marginLeft: 15 }} displayType={'text'} value={this.state.cvt_gross_potential} thousandSeparator={true} prefix={convert_symbol} /></label> : null}
                            </div>
                            <div style={{ display: 'flex', marginLeft: 50, height: 15 }} className="form-group">
                                <label style={{ color: '#cdcdcd', fontSize: 14 }}>Total Taxes: </label>
                                <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat displayType={'text'} value={this.state.tax_total} thousandSeparator={true} prefix={`(${expense_symbol}`} suffix={')'} /></label>
                                {this.state.convertrate ?
                                    <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}>{'=>'}<NumberFormat style={{ marginLeft: 15 }} displayType={'text'} value={this.state.cvt_tax_total} thousandSeparator={true} prefix={`(${convert_symbol}`} suffix={')'} /></label> : null}
                            </div>
                            <div style={{ display: 'flex', marginLeft: 50, height: 15 }} className="form-group">
                                <label style={{ color: '#cdcdcd', fontSize: 14 }}>Total Expenses: </label>
                                <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat displayType={'text'} value={this.state.exp_total} thousandSeparator={true} prefix={`(${expense_symbol}`} suffix={')'} /></label>
                                {this.state.convertrate ?
                                    <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}>{'=>'}<NumberFormat style={{ marginLeft: 15 }} displayType={'text'} value={this.state.cvt_exp_total} thousandSeparator={true} prefix={`(${convert_symbol}`} suffix={')'} /></label> : null}
                            </div>
                            <div style={{ display: 'flex', marginLeft: 50, height: 15 }} className="form-group">
                                <label style={{ color: '#cdcdcd', fontSize: 14 }}>Walkout Potential: </label>
                                <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}><NumberFormat displayType={'text'} value={this.state.walkout_potential} thousandSeparator={true} prefix={currency_symbol} /></label>
                                {this.state.convertrate ?
                                    <label style={{ color: '#cdcdcd', fontSize: 14, marginLeft: 15 }}>{'=>'}<NumberFormat style={{ marginLeft: 15 }} displayType={'text'} value={this.state.cvt_walkout_potential} thousandSeparator={true} prefix={convert_symbol} /></label> : null}
                            </div>
                            <div style={{ display: 'flex', marginRight: 30, height: 15, marginTop: 30, alignItems: 'center', justifyContent: 'flex-end' }} className="form-group">
                                {this.state.currencyconvert ?
                                    <button style={{ color: '#cdcdcd', fontSize: 14, fontWeight: 'bold', backgroundColor: 'rgb(1, 22, 48)' }} onClick={this.offCurrencyConvert}><FaCaretDown style={{ marginRight: 5 }} size="14" color="#cdcdcd" />Currency Converter</button> :
                                    <button style={{ color: '#cdcdcd', fontSize: 14, fontWeight: 'bold', backgroundColor: 'rgb(1, 22, 48)' }} onClick={this.toggleCurrencyConvert}>Currency Converter</button>}
                            </div>
                            {this.state.currencyconvert ?
                                <div style={{ display: 'flex', marginRight: 30, marginTop: 20, justifyContent: 'flex-end' }} className="form-group">
                                    <label style={{ color: '#cdcdcd', fontSize: 14 }}>Convert To: </label>
                                    <select style={{ color: '#000', fontSize: 12, marginLeft: 15 }} name="convertcurrency" value={this.state.convertcurrency} onChange={this.handleChange}>
                                        <option value=""></option>
                                        {this.state.rates_arr.map((item, index) => {
                                            return (
                                                <option key={index} value={item.key}>{item.key}</option>
                                            )
                                        })}
                                    </select>
                                    <button style={{ color: '#cdcdcd', fontSize: 14, fontWeight: 'bold', backgroundColor: '#333333', marginLeft: 15 }} onClick={this.onConvert}>Convert</button>
                                </div> : null}
                            {this.state.convertrate ?
                                <div style={{ display: 'flex', marginRight: 30, marginTop: 20, justifyContent: 'flex-end' }} className="form-group">
                                    <label style={{ color: '#cdcdcd', fontSize: 14 }}>Convert Rate: {this.state.convertrate}</label>
                                </div> : null}
                        </div>
                    </div>
                    <div style={{ width: '45%', marginTop: 15 }}>
                        <div className="depositBundle">
                            <div style={{ paddingLeft: 15, paddingRight: 25, height: 'auto', width: '80%', backgroundColor: 'rgb(1, 22, 48)', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }} className="form-control">
                                <label style={{ width: 150, color: '#cdcdcd', fontSize: 14 }}>Deposit(s):</label>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '40%', fontSize: 10, color: '#cdcdcd' }}>Amount</th>
                                            <th style={{ width: '50%', fontSize: 10, color: '#cdcdcd' }}>Due Date</th>
                                            <th style={{ width: '10%' }}> </th>
                                        </tr>
                                    </thead>
                                    {this.props.edit ?
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '40%' }}>
                                                    <NumberFormat style={{ width: '100%', fontSize: 12, border: '1px solid #333333' }} className="form-control-sm" disabled={this.props.edit ? false : true} value={this.props.deposit1} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onDeposit1ValueChange} /></td>
                                                <td style={{ width: '50%' }}>
                                                    <div style={{ width: '100%', height: 28 }}>
                                                        <DatePicker
                                                            className="date-input-field"
                                                            style={{ fontSize: 12, border: '1px solid #fff' }}
                                                            disabled={this.props.edit ? false : true}
                                                            value={this.props.deposit1due}
                                                            selected={this.props.deposit1due}
                                                            onChange={this.props.handleDeposit1DateChange}
                                                            todayButton="Today"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        /></div></td>
                                                <td style={{ width: '10%' }}><FaPlusSquare style={{ cursor: 'pointer', marginTop: 2 }} size="20" color="#cdcdcd" onClick={this.props.addDeposit} /></td>
                                            </tr>
                                        </tbody> : null}
                                </table>
                                <div style={{ marginTop: 15, minHeight: 50, maxHeight: 'auto', width: '100%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#b3b9c1', marginBottom: 15 }} tabIndex="0" onBlur={(e) => this.props.handleClickOutside(e, 'deposit_edit')}>
                                    {this.props.depositsarray.map((item, index) => {
                                        var date = new Date(item.due);
                                        var due = moment(item.due).format('M/DD/YYYY');
                                        return (
                                            <div style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%', height: 'auto' }}>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            {this.props.edit && this.props.deposit_edit[item._id] === 'amount' ?
                                                                <td style={{ width: '40%' }}><NumberFormat style={{ width: '100%', color: '#000', fontSize: 12, height: 50 }} name="amount" value={item.amount} thousandSeparator={true} prefix={currency_symbol} onValueChange={(values) => this.props.handleArrayFormattedChange(values, item._id, 'amount', 'depositsarray')} /></td> :
                                                                <td style={{ width: '40%' }} onClick={this.props.edit ? () => this.props.updateDeposit(item._id, 'amount') : null}><NumberFormat style={{ width: '100%', color: '#000', fontSize: 12, paddingLeft: 5 }} displayType={'text'} value={item.amount} thousandSeparator={true} prefix={currency_symbol} /></td>}
                                                            {this.props.edit && this.props.deposit_edit[item._id] === 'due' ?
                                                                <td style={{ width: '50%' }}><div style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }}>
                                                                    <DatePicker
                                                                        className="date-input-field"
                                                                        disabled={this.props.edit ? false : true}
                                                                        selected={date}
                                                                        onChange={this.props.handleArrayDateChange.bind(this, item._id, 'due', 'depositsarray')}
                                                                        todayButton="Today"
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                    />
                                                                </div></td> :
                                                                <td style={{ width: '50%' }} onClick={this.props.edit ? () => this.props.updateDeposit(item._id, 'due') : null}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 15 }}>{due}</p></td>}
                                                            {this.props.edit ?
                                                                <td style={{ width: '10%' }}><FaTrash style={{ marginLeft: 5, cursor: 'pointer' }} size="16" color='#cdcdcd' onClick={() => this.props.deleteDeposit(item._id)} /></td> :
                                                                <td style={{ width: '10%' }}> </td>}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })}
                                </div>
                                <label style={{ marginLeft: 10, color: '#cdcdcd', fontSize: 14 }}>Notes:</label>
                                <textarea
                                    className="form-control"
                                    style={{ maxHeight: 100, marginBottom: 15, backgroundColor: this.props.edit ? '#fff' : '#FAFAFA', fontSize: 12 }}
                                    disabled={this.props.edit ? false : true}
                                    onChange={this.props.onChange}
                                    value={this.props.depositnotes}
                                    name="depositnotes"
                                    type="textarea"
                                />
                            </div>
                        </div>
                        {this.props.bonus_toggle ?
                            <div style={{ paddingLeft: 15, paddingRight: 25, height: 'auto', width: '80%', backgroundColor: 'rgb(1, 22, 48)', marginTop: 15, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }} className="form-control">
                                <label style={{ color: '#cdcdcd', fontSize: 14 }}>Bonuses:</label>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '30%', fontSize: 11, color: '#cdcdcd' }}><label>Bonus Amount</label></th>
                                            <th style={{ width: '30%', fontSize: 11, color: '#cdcdcd' }}><label>Type</label></th>
                                            <th style={{ width: '30%', fontSize: 11, color: '#cdcdcd' }}><label>At Amount</label></th>
                                            <th style={{ width: '10%' }}> </th>
                                        </tr>
                                    </thead>
                                    {this.props.edit ?
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '30%', height: 28 }}><NumberFormat style={{ width: '100%', fontSize: 12, height: '100%' }} disabled={this.props.edit ? false : true} value={this.props.bonus1} thousandSeparator={true} prefix={currency_symbol} onValueChange={(e) => this.props.onBonusValueChange("bonus1", e)} /></td>
                                                <td style={{ width: '30%', height: 28 }}>
                                                    <select style={{ fontSize: 12, width: '100%', height: '100%', height: '100%' }} disabled={this.props.edit ? false : true} name="bonus1type" value={this.props.bonus1type} onChange={this.props.onChange} >
                                                        <option value=""> </option>
                                                        <option value="Tickets Sold">Tickets Sold</option>
                                                        <option value="Gross">Gross</option>
                                                    </select></td>
                                                <td style={{ width: '30%', height: 28 }}><NumberFormat style={{ width: '100%', fontSize: 12, height: '100%' }} disabled={this.props.edit ? false : true} value={this.props.bonus1atamount} thousandSeparator={true} prefix={this.props.bonus1type === 'Gross' ? currency_symbol : ''} onValueChange={(e) => this.props.onBonusValueChange("bonus1atamount", e)} /></td>
                                                <td style={{ width: '10%', height: 28 }}><FaPlusSquare style={{ cursor: 'pointer', marginTop: 2 }} size="20" color="#cdcdcd" onClick={this.props.addBonus} /></td>
                                            </tr>
                                        </tbody> : null}
                                </table>
                                <div style={{ marginTop: 5, minHeight: 50, maxHeight: 'auto', width: '100%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#b3b9c1', marginBottom: 15 }}>
                                    {this.props.bonusarray.map((item, index) => {
                                        return (
                                            <div style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%', height: 'auto' }}>
                                                <table style={{ width: '100%', height: 50 }}>
                                                    <tbody>
                                                        <tr>
                                                            {this.props.bonus_edit === 'bonus' ?
                                                                <td style={{ width: '30%' }}><NumberFormat style={{ width: '100%', color: '#000', fontSize: 12, height: '100%' }} value={item.bonus} thousandSeparator={true} prefix={'$'} onValueChange={(values) => this.props.handleArrayFormattedChange(values, index, 'bonus', 'bonusarray')} /></td> :
                                                                <td style={{ width: '30%' }} onClick={() => this.props.updateBonus(index, 'bonus')}><NumberFormat style={{ width: 100, color: '#000', fontSize: 12, paddingLeft: 5 }} displayType={'text'} value={item.bonus} thousandSeparator={true} prefix={'$'} /></td>}
                                                            {this.props.bonus_edit === 'bonus_type' ?
                                                                <td style={{ width: '30%', fontSize: 12, color: '#000' }}>
                                                                    <select style={{ fontSize: 12, width: '100%', height: '100%' }} name="type" value={item.type} onChange={(event) => this.props.handleArrayUpdate(event, index, 'bonusarray')}>
                                                                        <option value="Tickets Sold">Tickets Sold</option>
                                                                        <option value="Gross">Gross</option>
                                                                    </select>
                                                                </td> :
                                                                <td style={{ width: '30%', fontSize: 12, color: '#000', paddingLeft: 5, paddingTop: 5 }} onClick={() => this.props.updateBonus(index, 'bonus_type')}>@ {item.type}</td>}
                                                            {this.props.bonus_edit === 'at_amount' ?
                                                                <td style={{ width: '30%' }}><NumberFormat style={{ width: '100%', color: '#000', fontSize: 12, paddingLeft: 5, height: '100%' }} value={item.at_amount} thousandSeparator={true} prefix={item.type === 'Gross' ? '$' : ''} onValueChange={(values) => this.props.handleArrayFormattedChange(values, index, 'at_amount', 'bonusarray')} /></td> :
                                                                <td style={{ width: '30%' }} onClick={() => this.props.updateBonus(index, 'at_amount')}><NumberFormat style={{ width: '100%', color: '#000', fontSize: 12, paddingLeft: 5 }} displayType={'text'} value={item.at_amount} thousandSeparator={true} prefix={item.type === 'Gross' ? '$' : ''} /></td>}
                                                            {this.props.edit ?
                                                                <td style={{ width: '10%' }}><FaTrash style={{ marginLeft: 5, cursor: 'pointer' }} size="16" color='#cdcdcd' onClick={() => this.props.deleteBonus(item._id)} /></td> :
                                                                <td style={{ width: '10%' }}> </td>}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div> : null}
                    </div>
                </div >
            </div >
        );
    }
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { getCurrencies })(DealTerms);