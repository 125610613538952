import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    HOLDS_LOADING,
    GET_HOLDS,
    ADD_HOLDS,
    HOLDS_SOCKET,
    HOLDS_SOCKET_DELETE,
    UPDATE_HOLDS,
    DELETE_HOLDS,
} from "./types";

export const setHoldsLoading = () => {
    return {
        type: HOLDS_LOADING
    };
};

export const getHolds = () => (dispatch, getState) => {
    dispatch(setHoldsLoading());
    axios.get(`${process.env.REACT_APP_API}/holds/`, tokenConfig(getState))
        .then(res => {
            const { items } = getState();
            const { buyers } = getState();
            const { clients } = getState();
            const { festivals } = getState();
            const venues = items;

            var newArray = [];
            res.data.map((item, index) => {
                var artist_name = "";
                var venue_address = {};
                var venue_name = "";
                var buyer_company = "";
                var buyer_address = {};

                clients.clients.map((c, i) => {
                    if (item.client === c._id) {
                        artist_name = c.client
                    }
                })
                venues.items.map((v, i) => {
                    if (item.venue === v._id) {
                        venue_name = v.venue_name;
                        venue_address = v.address;
                    }
                })
                festivals.items.map((f, i) => {
                    if (item.venue === f._id) {
                        venue_name = f.festival;
                        venue_address = f.address;
                    }
                })
                buyers.buyers.map((b, i) => {
                    if (item.buyer === b._id) {
                        buyer_company = b.company;
                        buyer_address = b.address;
                    }
                })

                const items = {
                    ...item,
                    artist_name: artist_name,
                    venue_name: venue_name,
                    venue_address: venue_address,
                    buyer_company: buyer_company,
                    buyer_address: buyer_address
                }
                newArray.push(items)
            })

            dispatch({
                type: GET_HOLDS,
                payload: newArray
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const getHoldsConnect = id => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/holds/connect/get/${id}`, null, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

export const addHolds = entry => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API}/holds/add`, entry, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: ADD_HOLDS,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
}

export const holdsSocket = holds => (dispatch) => {
    dispatch({
        type: HOLDS_SOCKET,
        payload: holds
    })
}

export const holdsSocketDelete = holds => (dispatch) => {
    dispatch({
        type: HOLDS_SOCKET_DELETE,
        payload: holds
    })
}

export const updateHolds = (holds, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/holds/update/${id}`, holds, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const deleteHolds = id => async (dispatch, getState) => {
    return await axios.delete(`${process.env.REACT_APP_API}/holds/${id}`, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //     type: DELETE_HOLDS,
            //     payload: id
            // })
            return res.data
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}