import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    SPOTIFY_LOADING,
    SPOTIFY_LOADED,
    GET_SPOTIFY,
    GET_SPOTIFY_DATA,
} from "./types";

export const setSpotifyLoading = () => {
    return {
        type: SPOTIFY_LOADING
    };
};

export const spotifyLogin = () => (dispatch, getState) => {
    const token = getState().spotify.token;
    const item = JSON.parse(token)
    const now = new Date()

    if (item) {
        if (now.getTime() < item.expiry) {
            
        } else {
            localStorage.removeItem('spotify_token')
            axios.get(`${process.env.REACT_APP_API}/socials/spotify/login`, tokenConfig(getState))
                .then(res =>
                    dispatch({
                        type: SPOTIFY_LOADED,
                        payload: res.data
                    })
                )
                .catch(err =>
                    dispatch(returnErrors(err.response.data, err.response.status))
                );
        }
    } else {
        axios.get(`${process.env.REACT_APP_API}/socials/spotify/login`, tokenConfig(getState))
            .then(res =>
                dispatch({
                    type: SPOTIFY_LOADED,
                    payload: res.data
                })
            )
            .catch(err =>
                dispatch(returnErrors(err.response.data, err.response.status))
            );
    }
};

export const getSpotify = (data) => (dispatch, getState) => {
    dispatch(setSpotifyLoading());
    axios.post(`${process.env.REACT_APP_API}/socials/spotify/get/`, data, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: GET_SPOTIFY,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const getSpotifyData = data => (dispatch, getState) => {
    dispatch(setSpotifyLoading());
    axios.post(`${process.env.REACT_APP_API}/socials/spotify/get/data`, data, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: GET_SPOTIFY_DATA,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};