import axios from "axios";
import { tokenConfig, tokenConfigFile } from './authActions';
import { returnErrors } from './errorActions';
import {
    CLIENTS_INIT_LOADING,
    CLIENTS_LOADING,
    CLIENTS_GET,
    CLIENTS_FILTER,
    CLIENTS_FILTER_SUB,
    CLIENTS_SORT,
    CLIENTS_SOCKET,
    CLIENTS_SOCKET_DELETE,
    NOTIFICATIONS
} from "./types";

export const setClientsLoading = () => {
    return {
        type: CLIENTS_LOADING
    };
};

export const setClientsInitLoading = () => {
    return {
        type: CLIENTS_INIT_LOADING
    };
};

export const getClients = () => async (dispatch, getState) => {
    dispatch(setClientsInitLoading());
    await axios.get(`${process.env.REACT_APP_API_CLIENT}/clients/`, tokenConfig(getState))
        .then(async (res) => {
            // const tknConfig = tokenConfig(getState);
            // await transpose(res.data, tknConfig);
            dispatch({
                type: CLIENTS_GET,
                payload: res.data
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const clientSocket = client => (dispatch) => {
    dispatch({
        type: CLIENTS_SOCKET,
        payload: client
    })
}

export const deleteClientSocket = client => (dispatch) => {
    dispatch({
        type: CLIENTS_SOCKET_DELETE,
        payload: client
    })
}

export const uploadAttachmentClient = (file, name, key, type, notes, date, user, id) => async (dispatch, getState) => {

    const info = [{
        name: name,
        key: key,
        type: type,
        notes: notes,
        user: user,
        date: date
    }]

    const json = JSON.stringify(info);
    const blob = new Blob([json], {
        type: 'application/json'
    });

    var formData = new FormData();
    formData.append('file', file);
    formData.set('info', blob);

    return axios.post(`${process.env.REACT_APP_API_CLIENT}/clients/attachment/upload/${id}`, formData, tokenConfigFile(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });

}

export const downloadAttachmentClient = (data) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/clients/attachment/download`, data, tokenConfig(getState))
        .then(res => {

            var a = document.createElement("a"); //Create <a>
            a.href = res.data.file; //Image Base64 Goes here
            a.download = res.data.title; //File name Here
            a.click(); //Downloaded file

            return "Success"
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const getAttachment = (data) => async (dispatch, getState) => {
    return await axios.post(`${process.env.REACT_APP_API_CLIENT}/clients/attachment/get`, data, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const deleteAttachmentClient = (data, deal_id) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/clients/attachment/delete/${deal_id}`, data, tokenConfig(getState))
        .then(res => {
            return "Success"
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}