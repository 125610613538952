import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

export default function LocationAutocomplete(props) {
    const [value, setValue] = useState([]);

    if (value.value !== undefined) {
        var val = value.value.reference;
        var title = value.value.structured_formatting.main_text;
        geocodeByPlaceId(val)
            .then(results => props.handleLocation(results, title))
            .then(setValue([]))
            .catch(error => console.error(error));
    }

    return (
        <div style={{ width: '80%', fontSize: 12, border: '1px solid #000', borderRadius: 5 }}>
            {/* <GooglePlacesAutocomplete apiKey={props.Gkey}
                selectProps={{
                    placeholder: 'Search...',
                    value,
                    onChange: setValue,
                }}
            /> */}
            <GooglePlacesAutocomplete apiKey={props.Gkey}
                styles={{
                    textInputContainer: {
                        backgroundColor: "#fff0",
                        borderWidth: 1,
                        borderColor: '#000',
                        borderRadius: 3,
                        padding: 0,
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: "100%",
                        height: 22
                    },
                    textInput: {
                        color: "#000",
                        padding: 0,
                        margin: 0,
                    },
                }}
                selectProps={{
                    placeholder: 'Search...',
                    value,
                    onChange: setValue,
                }}
            />
        </div>
    )
}