import React, { Component } from 'react';
import WhiteLogo from '../assets/WhiteLogo.png';
import loginBackdrop from '../assets/loginBackdrop.png';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from "../components/history";
import { login, verify, updatePassword, logout, forgotPassword } from '../actions/authActions';
import { clearErrors } from '../actions/errorActions';
import { FaRegCopyright } from 'react-icons/fa';

import '../App.css';

class ForgotPassword extends Component {

    constructor() {
        super();
        this.state = {
            error: null,
            email: "",
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        error: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired,
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmit = async (e) => {
        e.preventDefault();
        const user = {
            email: this.state.email,
        }

        var status = await this.props.forgotPassword(user);
        if (status.type === 'error') {
            this.setState({
                error: status.msg
            })
        } else {
            alert(status.msg)
            this.props.history.push('/')
        }

    }

    render() {
        //<img src={WhiteLogo} width="200" height="210" />

        return (
            <div className="App">
                <section className="App-header">
                    <img style={{ position: 'relative' }} width="auto" height="625" src={loginBackdrop} alt="login" />
                    <form
                        style={{ position: 'absolute', top: '45%', height: 'auto' }}
                        autoComplete="off"
                        onSubmit={this.onSubmit}
                    >
                        <div >
                            <input
                                className="Login-input1"
                                placeholder="Email"
                                name="email"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.email}
                            />
                        </div>
                        <div >
                            <div className="form-group">
                                {this.state.error ? (
                                    <p style={{ color: 'red', fontSize: 12, width: '100%' }} className="text-danger">{this.state.error}</p>
                                ) : null}
                                <div className="mt-3">
                                    <button style={{ fontSize: 12, color: '#F2F2F2', fontWeight: 'bold' }} className="btn btn-secondary px-5" type="submit">Submit</button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="mt-2">
                                    <Link style={{ fontSize: 10, color: '#ddd' }} type="submit" to="/">Return</Link>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '82.5%' }}>
                        <p style={{ fontSize: 10, color: 'rgb(184, 184, 184)' }}><FaRegCopyright style={{ marginTop: -2 }} size="12" color="rgb(184, 184, 184)" /> 2021 Solar System, LLC</p>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error
})

export default connect(mapStateToProps, { login, verify, updatePassword, clearErrors, logout, forgotPassword })(ForgotPassword);