import React, { useState } from "react";
import StatsAdminFilter from './stats.admin.filter';

export default function AdminFilters(props) {

    return (
        <div>
            {props.admin_sub === 'stats' ?
                <StatsAdminFilter stats_filter={props.stats_filter} filterChange={props.filterChange} filterDateChange={props.filterDateChange} agents={props.agents}/> :
                null}
        </div>
    )
}