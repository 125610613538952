import React, { useEffect, useState } from "react";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { connect } from "react-redux";

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')`
  width: 100%;
  border: 1px solid #727272;
  background-color: #fff;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;

  &:hover {
    //border-color: #40a9ff;
  }

  &.focused {
    //border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    min-height: 30px;
    height: auto;
    box-sizing: border-box;
    padding: 4px 6px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
    <div {...props}>
        <span>{label}</span>
        <CloseIcon onClick={onDelete} />
    </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  width: '100%';
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  font-size: 12px;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 20px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Tag2 = styled(({ label, onDelete, ...props }) => (
    <div {...props}>
        <span>{label}</span>
    </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  width: '100%';
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  color: #333333;
  font-size: 12px;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('ul')`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-size: 12px;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

function FestivalAutocomplete({ festivals, onFestivalChange, agentSelect, edit, handleEmailBlur }) {
    const [stateAgentSelect, setAgentSelect] = useState(agentSelect);
    const [stateEmail, setEmail] = useState('');
    const [stateEdit, setEdit] = useState(edit);

    useEffect(() => {
        setAgentSelect(agentSelect);
    }, [agentSelect])
    useEffect(() => {
        setEdit(edit);
    }, [edit])

    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    const handleBlur = (e) => {
        if (stateEmail) {
            var newObj = { festival: '' };
            handleEmailBlur(newObj);
            setEmail('');
        }
    }

    var newArr = festivals.items;


    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        value,
        focused,
        setAnchorEl,
    } = useAutocomplete({
        id: 'customized-hook-demo',
        defaultValue: [],
        multiple: false,
        options: newArr,
        autoComplete: 'off',
        value: stateAgentSelect,
        getOptionLabel: (option) => option.festival,
        getOptionSelected: (option, value) => option._id === value._id,
        onChange: (event, newValue) => {
            if (newValue) {
                onFestivalChange(newValue)
            } else if (!newValue) {
                onFestivalChange('')
            };
        }
    });

    return (
        <NoSsr>
            <div>
                <div {...getRootProps()}>
                    <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''} onBlur={handleBlur} onChange={handleChange}>
                        {stateAgentSelect.map((option, index) => (
                            edit ?
                                <Tag label={option.festival} {...getTagProps({ index })} /> :
                                <Tag2 label={option.festival} {...getTagProps({ index })} />
                        ))}
                        <input style={{ fontSize: 12 }} disabled={edit ? false : true} {...getInputProps()} />
                    </InputWrapper>
                </div>
                {groupedOptions.length > 0 ? (
                    <Listbox {...getListboxProps()}>
                        {groupedOptions.map((option, index) => (
                            <li {...getOptionProps({ option, index })}>
                                <span>{option.festival}</span>
                                <CheckIcon size="6" />
                            </li>
                        ))}
                    </Listbox>
                ) : null}
            </div>
        </NoSsr>
    );
}

function mapStateToProps(state) {
    return { festivals: state.festivals };
}

export default connect(mapStateToProps, { })(FestivalAutocomplete)