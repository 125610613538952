import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import StatusDialog from '../components/offerdatabase/status.dialog';
import { deleteOffer, getOfferDetail, updateOfferStatus } from '../actions/offerActions';
import _ from 'lodash';
import moment from 'moment';
import { Menu, Item, Separator, Submenu, MenuProvider, theme, animation } from 'react-contexify';
import { FaExternalLinkSquareAlt, FaCaretDown, FaCaretUp, FaRedoAlt, FaMinusSquare, FaCircle, FaEnvelope } from 'react-icons/fa';
import { AutoSizer, Column, Table } from 'react-virtualized';
import CustomChip from '../components/customStatus';
import ReactTooltip from 'react-tooltip';
import getSymbolFromCurrency from 'currency-symbol-map';
import ItineraryGenDialog from '../components/dialogs/itinerary-gen.dialog';
import ContractGenDialog from '../components/dialogs/contract-gen.dialog';
import ContractIssuedDialog from '../components/database/contract-issued.dialog';
import ContractReceivedDialog from '../components/database/contract-received.dialog';
import 'react-contexify/dist/ReactContexify.min.css';
import 'react-virtualized/styles.css'

class OfferRolodex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusdialog: false,
            statustitle: '',
            statusrender: '',
            statusrenderid: '',
            statusstart: '',
            statusvenue: '',
            issued_dialog: false,
            itinerarydialog: false,
            contractdialog: false,
            received_dialog: false,
            select_deal: {}
        };
        this.handleChange = this.handleChange.bind(this);
        // this.handleStatusDialog = this.handleStatusDialog.bind(this);
        // this.saveStatus = this.saveStatus.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        offers: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    componentDidMount() {

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleEventPositioned() {
        ReactTooltip.rebuild(); 
    }

    handleStatusDialog = (item, start, venue) => {
        if (item) {
            this.setState({
                statusrenderid: item._id,
                statusrender: item.status,
                statustitle: item.artist_name,
                statusstart: start,
                statusvenue: venue,
                statusdialog: !this.state.statusdialog
            })
        }
    }

    closeStatusDialog = () => {
        this.setState({
            statustitle: '',
            statusrender: '',
            statusrenderid: '',
            statusstart: '',
            statusvenue: '',
            statusdialog: !this.state.statusdialog
        })
    }

    saveStatus = async () => {
        const status = {
            status: this.state.statusrender
        }
        await this.props.updateOfferStatus(status, this.state.statusrenderid)
        this.setState({
            statustitle: '',
            statusrender: '',
            statusrenderid: '',
            statusstart: '',
            statusvenue: '',
            statusdialog: !this.state.statusdialog
        })
    }

    handleDatabaseCheckbox = (n, v) => {
        const e = {
            target: {
                name: n,
                value: v
            }
        }
        this.props.handleDatabaseCheckbox(e)
    }

    basicColumnData = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: '100%', height: '100%' }}>
                    {cellData[dataKey]}
                </div>
            </MenuProvider>
        )
    }

    renderColumnData = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', fontSize: 10 }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={this.props.offer_checked.includes(cellData._id) || false}
                        color="default"
                        value={cellData._id}
                        name="offer_checked"
                        onChange={this.props.handleDatabaseCheckbox}
                    />
                    {/* <Chip
                        size="small"
                        style={{ opacity: .8 }}
                        color={"primary"}
                        clickable
                        label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
                        onClick={this.props.iconRenderOffer.bind(this, cellData)}
                    /> */}
                    <span style={{ width: 25, height: '100%' }} onClick={this.props.iconRenderOffer.bind(this, cellData)}>
                        <FaExternalLinkSquareAlt size="16" className="dashboard-offer-icons-exp" />
                    </span>
                    <span style={{ width: 25, height: '100%' }} onClick={() => this.props.toggleEmailDialog(cellData)}>
                        <FaEnvelope size="14" className="dashboard-offer-icons-email" />
                    </span>
                    {/* {item.main_link ?
                        <span style={{ width: 25, height: '100%' }} onClick={() => window.open(item.main_link)}>
                            <FaTicketAlt size="14" className="dashboard-offer-icons-link" />
                        </span> :
                        <span style={{ width: 25, height: '100%' }}>

                        </span>} */}
                </div>
            </MenuProvider>
        )
    }

    statusColumnData = ({ cellData, dataKey }) => {
        const datesArray = cellData.show_info.map(d => moment(d.date).utc());
        var start = moment.min(datesArray).format('MM/DD/YYYY');
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', fontSize: 10 }}>
                    {/* <Chip
                        size="small"
                        variant="outlined"
                        color={cellData.status === "Pending" ? "default" : cellData.status === "Confirmed" ? "primary" : "secondary"}
                        clickable
                        // label={cellData.status === "Confirmed" ? "Confirmed" : cellData.status === "Pending" ? "Pending" : cellData.status === "Cancelled" ? "Cancelled" : null}
                        label={cellData.status}
                        onClick={this.handleStatusDialog.bind(this, cellData, start, cellData.venue_name)}
                    /> */}
                    <CustomChip
                        size={.65}
                        // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                        color={cellData.status === "Pending" ? "default" : cellData.status === "Confirmed" ? "primary" : "secondary"}
                        label={cellData.status === "Confirmed" ? "Confirmed" : cellData.status === "Pending" ? "Pending" : cellData.status === "Cancelled" ? "Cancelled" : null}
                        onClick={this.handleStatusDialog.bind(this, cellData, start, cellData.venue_name)}
                    />
                    {/* {!cellData.prog.k_rec ?
                        <Chip
                            size="small"
                            variant="outlined"
                            color={"default"}
                            clickable
                            label={"mc"}
                            // onClick={this.handleStatusDialog.bind(this, cellData, start, cellData.venue_name)}
                        /> : null}
                    {!cellData.prog.d_rec ?
                        <Chip
                            size="small"
                            variant="outlined"
                            color={"default"}
                            clickable
                            label={"md"}
                            // onClick={this.handleStatusDialog.bind(this, cellData, start, cellData.venue_name)}
                        /> : null} */}
                </div>
            </MenuProvider>
        )
    }

    statusKColumnData = ({ cellData, dataKey }) => {
        // console.log(cellData)
        // var showDate = moment(cellData.show_info[0].date);
        var today = new Date();
        var two = moment(cellData.show_info[0].date).utc().subtract(2, 'weeks');
        var month = moment(cellData.show_info[0].date).utc().subtract(1, 'month');
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: '100%', height: '100%', fontSize: 10 }}>
                    {!cellData.prog.k_iss ?
                        <h2 style={{ fontSize: 9, fontweight: 'bold', marginTop: 10 }} className="grey_select" onClick={() => this.toggleIssuedDialog(cellData)}>NI</h2> :
                        !cellData.prog.k_rec && new Date(month._d) > today ?
                            <FaCircle size={8} className="grey_select" onClick={this.toggleReceivedDialog} /> :
                            !cellData.prog.k_rec && new Date(month._d) <= today && new Date(two._d) > today ?
                                <FaCircle size={8} className="yellow_select" onClick={this.toggleReceivedDialog} /> :
                                !cellData.prog.k_rec && new Date(two._d) <= today ?
                                    <FaCircle size={8} className="red_select" onClick={this.toggleReceivedDialog} /> :
                                    <p></p>}
                </div>
            </MenuProvider>
        )
    }

    statusDepColumnData = ({ cellData, dataKey }) => {
        var today = new Date();
        var due = null;
        var week = null;
        const currency_symbol = getSymbolFromCurrency(cellData.deal_currency);
        if (cellData.prog.d_due && cellData.prog.d_due.length > 0) {
            due = moment(cellData.prog.d_due[0].due).utc();
            week = moment(cellData.prog.d_due[0].due).utc().subtract(1, 'week');
        }
        if (cellData.prog.d_due && cellData.prog.d_due.length > 0) {
            var tip = "";
            var length = cellData.prog.d_due.length;
            cellData.prog.d_due.map((item, index) => {
                var du = moment(item.due).utc().format('MM/DD/YYYY');
                var am = item.amount;
                tip = tip + currency_symbol + am + ' due ' + du;
                if (index > length - 1) {
                    tip = tip + '<br/>'
                }
            })

            return (
                <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                    <div style={{ width: '100%', height: '100%', fontSize: 10 }}>
                        {!cellData.prog.d_rec && new Date(due) <= today ?
                            <FaCircle size={8} className="red_select" data-tip={tip} onMouseEnter={this.handleEventPositioned} /> :
                            !cellData.prog.d_rec && new Date(due) > today && new Date(week) <= today ?
                                <FaCircle size={8} className="yellow_select" data-tip={tip} onMouseEnter={this.handleEventPositioned} /> :
                                !cellData.prog.d_rec ?
                                    <FaCircle size={8} className="grey_select" data-tip={tip} onMouseEnter={this.handleEventPositioned} /> :
                                    <p></p>}
                    </div>
                </MenuProvider>
            )
        } else if (cellData.prog.d_due) {
            return (
                <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                    <div style={{ width: '100%', height: '100%', fontSize: 10 }}>
                        {!cellData.prog.d_rec && new Date(due) <= today ?
                            <FaCircle size={8} className="red_select" /> :
                            !cellData.prog.d_rec && new Date(due) > today && new Date(week) <= today ?
                                <FaCircle size={8} className="yellow_select" /> :
                                !cellData.prog.d_rec ?
                                    <FaCircle size={8} className="grey_select" /> :
                                    <p></p>}
                    </div>
                </MenuProvider>
            )
        } else {
            return (
                <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                    <div style={{ width: '100%', height: '100%', fontSize: 10 }}>
                        {!cellData.prog.d_rec ?
                            <FaCircle size={8} className="grey_select" /> :
                            <p></p>}
                    </div>
                </MenuProvider>
            )
        }
    }

    startDateColumnData = ({ cellData, dataKey }) => {
        const datesArray = cellData.row.show_info.map(d => moment(d.date).utc());
        var start = moment.min(datesArray).format('MM/DD/YYYY');
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center', fontSize: 10 }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {start}
                </div>
            </MenuProvider>
        )
    }

    endDateColumnData = ({ cellData, dataKey }) => {
        const datesArray = cellData.row.show_info.map(d => moment(d.date).utc());
        var end = moment.max(datesArray).format('MM/DD/YYYY');
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center', fontSize: 10 }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {end}
                </div>
            </MenuProvider>
        )
    }

    normCellRender = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData.id} id="menu_id" component="span" value={cellData.id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center', fontSize: 10 }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {cellData.cell}
                </div>
            </MenuProvider>
        )
    }

    rowSelect(id) {
        if (!id) return;
        this.handleDatabaseCheckbox('offer_checked', id);
    }

    rowStyleFormat(row) {
        if (row.index < 0) return;
        const list = this.props.offers.filtered_offers;
        if (this.props.offer_checked.includes(list[row.index]._id)) {
            return {
                backgroundColor: '#e6f7ff'
            };
        }
        return {
            backgroundColor: null,
        };
    }

    headerRowRenderer = ({ className, columns, style }) => {
        return (
            <div
                className={className}
                role='row'
                style={style}
            >
                {columns}
            </div>
        )
    }

    headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        if (this.props.offer_sort.sort_cat === dataKey) {
            return (
                <div style={{ width: '100%' }} onClick={() => this.props.toggleSortOffers(dataKey)}><div style={{ display: 'flex', width: '100%' }}><div style={{ width: dataKey === 'artist_name' || dataKey === 'venue_name' || dataKey === 'buyer_company' ? '86%' : '80%' }}>{label}</div><div style={{ width: '10%' }}>{this.props.offer_sort.sort_direction === 'asc' ? <FaCaretUp size="15" color="#cdcdcd" /> : <FaCaretDown size="15" color="#cdcdcd" />}</div></div></div>
            )
        } else {
            return (
                <div onClick={() => this.props.toggleSortCatOffers(dataKey)}>{label}</div>
            )
        }
    }

    headerRendererStatusDet = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        return (
            <div onClick={() => this.props.toggleSortCatOffers(dataKey)} data-tip={dataKey}>{label}</div>
        )
    }

    headerStartRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: 'auto' }}>
                <div style={{ width: '60%' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        color="default"
                        checked={this.props.offer_all_checked}
                        //value={props.checked}
                        name="offer_checked"
                        onChange={this.props.handleDatabaseAllCheckbox}
                    /></div>
                <div style={{ width: '40%', height: 30 }}>
                    {this.props.offer_sort.sort_cat ?
                        <FaRedoAlt style={{ marginTop: 14 }} size="12" color="#cdcdcd" onClick={() => this.props.clearSorted('offer_sort')} /> :
                        this.props.offer_checked.length > 0 ?
                            <FaMinusSquare style={{ marginTop: 10 }} size="18" color="#cdcdcd" onClick={() => this.props.handleDatabaseClearCheckbox('offer_checked')} />
                            : null}
                </div> 
            </div>
        )
    }

    // rowSelect(row) {
    //     if (row.index < 0) return;
    //     const list = this.props.offers.filtered_offers;
    //     this.handleDatabaseCheckbox('offer_checked', list[row.index]._id);
    // }

    rowDoubleClick(row) {
        if (row.index < 0) return;
        this.props.iconRenderOffer(row.rowData)
    }

    toggleIssuedDialog = (cellData) => {
        if(cellData){
            this.setState({
                issued_dialog: !this.state.issued_dialog,
                select_deal: cellData,
                select_currency: ''
            })
        } else {
            this.setState({
                issued_dialog: !this.state.issued_dialog,
                select_deal: {},
                select_currency: ''
            })
        }
    }

    toggleReceivedDialog = (cellData) => {
        if (cellData) {
            const currency_symbol = getSymbolFromCurrency(cellData.deal_currency);
            this.setState({
                received_dialog: !this.state.received_dialog,
                select_deal: cellData,
                select_currency: currency_symbol
            })
        } else {
            this.setState({
                received_dialog: !this.state.received_dialog,
                select_deal: {},
                select_currency: ''
            })
        }
    }


    render() {

        const list = this.props.offers.filtered_offers;
        const onClick = ({ event, props }) => this.props.toggleDealMemoPDFContext(props.ref.attributes[1].value);

        const MyAwesomeMenu = () => (
            <Menu id='menu_id' theme={theme.dark} animation={animation.fade}>
                <Item onClick={this.props.toggleRenderOffer}>View / Edit</Item>
                <Item onClick={() => this.props.copyOffer('offers_db')}>Copy Deal</Item>
                <Item onClick={() => this.props.deleteDialog('offer_checked', 'offer')}>Delete Deal</Item>
                <Item onClick={this.props.toggleGeoMap}>View Map</Item>
                {/* <Item onClick={onClick}>Analysis</Item> */}
                <Submenu label="Print PDF">
                    <Item onClick={() => this.props.togglePdf("dealmemo")}>Deal Memo</Item>
                    {/* <Item onClick={() => this.props.toggleContractDialog("print")}>Contract</Item> */}
                    <Item onClick={() => this.props.togglePdf("contract")}>Contract</Item>
                    <Item onClick={() => this.props.toggleItineraryDialog("print")}>Itinerary</Item>
                    {/* <Item onClick={() => this.props.togglePdf("itinerary_financial")}>Itinerary (Financial)</Item> */}
                    <Item onClick={() => this.props.togglePdf("counts")}>Ticket Counts</Item>
                    <Item onClick={() => this.props.togglePdf("deposit-report", 'offers')}>Deposit Report</Item>
                    <Item onClick={() => this.props.togglePdf("finals-statement", 'offers')}>Final Totals</Item>
                    <Item onClick={() => this.props.togglePdf("tour-settlement-statement", 'offers')}>Settlement Statement</Item>
                </Submenu>
                <Submenu label="Download PDF">
                    <Item onClick={() => this.props.downloadPdf('dealmemo', 'offer')}>Deal Memo</Item>
                    <Item onClick={() => this.props.downloadPdf('contract', 'offer')}>Contract</Item>
                    <Item onClick={() => this.props.toggleItineraryDialog("download")}>Itinerary</Item>
                    <Item onClick={() => this.props.downloadPdf('counts', 'offer')}>Ticket Counts</Item>
                    <Item onClick={() => this.props.downloadPdf('deposit-report', 'offer')}>Deposit Report</Item>
                    <Item onClick={() => this.props.downloadPdf('finals-statement', 'offer')}>Final Totals</Item>
                    <Item onClick={() => this.props.downloadPdf('tour-settlement-statement', 'offer')}>Settlement Statement</Item>
                </Submenu>
                {/* <Submenu label="Issue">
                    <Item onClick={() => this.props.togglePdf("dealmemo")}>Deal Memo</Item>
                    <Item onClick={() => this.props.togglePdf("contract")}>Contract</Item>
                    <Item onClick={() => this.props.togglePdf("itinerary")}>Itinerary</Item>
                </Submenu> */}
                <Submenu label="Analytics">
                    <Item onClick={this.props.countCompareContext}>Count Comparison</Item>
                </Submenu>
            </Menu>
        );


        if (this.props.offers.loading) return <div>loading...</div>;
        else {
            return (
                <div style={{ width: '100%' }}>
                    {this.props.tooltip ?
                        <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                        </ReactTooltip> : null}
                    <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={height}
                                headerHeight={30}
                                rowHeight={30}
                                overscanRowCount={10}
                                headerRowRenderer={this.headerRowRenderer.bind(this)}
                                rowCount={list.length}
                                rowGetter={({ index }) => list[index]}
                                rowStyle={this.rowStyleFormat.bind(this)}
                                //onRowClick={this.rowSelect.bind(this)}
                                onRowDoubleClick={this.rowDoubleClick.bind(this)}
                            >
                                <Column
                                    label=''
                                    width={130}
                                    dataKey='_id'
                                    headerRenderer={this.headerStartRenderer}
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.renderColumnData
                                    }
                                />
                                <Column
                                    label='Status'
                                    width={130}
                                    dataKey='status'
                                    headerRenderer={this.headerRenderer}
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.statusColumnData
                                    }
                                />
                                <Column
                                    label='MC'
                                    width={40}
                                    dataKey='Missing Contract'
                                    headerRenderer={this.headerRendererStatusDet}
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.statusKColumnData
                                    }
                                />
                                <Column
                                    label='MD'
                                    width={40}
                                    dataKey='Missing Deposit'
                                    headerRenderer={this.headerRendererStatusDet}
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.statusDepColumnData
                                    }
                                />
                                <Column
                                    width={200}
                                    label='Client'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='artist_name'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.artist_name, id: rowData._id, width: 200 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={130}
                                    label='Start Date'
                                    dataKey='start_date'
                                    cellDataGetter={({ rowData }) => { return ({ row: rowData, id: rowData._id, width: 130 }) }}
                                    cellRenderer={
                                        this.startDateColumnData
                                    }
                                />
                                <Column
                                    width={130}
                                    label='End Date'
                                    dataKey='end_date'
                                    cellDataGetter={({ rowData }) => { return ({ row: rowData, id: rowData._id, width: 130 }) }}
                                    cellRenderer={
                                        this.endDateColumnData
                                    }
                                />
                                <Column
                                    width={200}
                                    label='Venue'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='venue_name'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.venue_name, id: rowData._id, width: 200 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={150}
                                    label='City'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='venue_city'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.venue_city, id: rowData._id, width: 200 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={150}
                                    label='State | Prov'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='venue_state'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.venue_state, id: rowData._id, width: 150 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={150}
                                    label='Country'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='venue_country'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.venue_country, id: rowData._id, width: 150 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={200}
                                    label='Buyer'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='buyer_company'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.buyer_company, id: rowData._id, width: 200 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                            </Table>
                        )}
                    </AutoSizer>
                    <MyAwesomeMenu />
                    <StatusDialog open={this.state.statusdialog} handleClose={this.closeStatusDialog} handleChange={this.handleChange} saveStatus={this.saveStatus} {...this.state} />
                    {/* <ContractIssuedDialog open={this.state.issued_dialog} handleClose={this.toggleIssuedDialog} select_deal={this.state.select_deal} user={this.props.auth.user.name} tag={this.props.auth.user.tag}/>
                    <ContractReceivedDialog open={this.state.received_dialog} handleClose={this.toggleReceivedDialog} select_deal={this.state.select_deal} select_currency={this.state.select_currency} user={this.props.auth.user.name} tag={this.props.auth.user.tag}/> */}
                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    offers: state.offers,
    items: state.items,
    buyers: state.buyers,
    clients: state.clients,
    contacts: state.contacts,
    auth: state.auth
});

export default connect(mapStateToProps, { deleteOffer, getOfferDetail, updateOfferStatus })(OfferRolodex);