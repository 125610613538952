import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    COMPANY_LOADING,
    GET_COMP_USERS,
    COMPANY_EMAIL_GROUPS,
    COMPANY_TEMPLATES
} from "./types";

export const setCompanyLoading = () => {
    return {
        type: COMPANY_LOADING
    };
};

export const getCompanyUsers = () => (dispatch, getState) => {
    dispatch(setCompanyLoading());
    axios.get(`${process.env.REACT_APP_API_CLIENT}/users/`, tokenConfig(getState))
        .then(async (res) => {
            getUserRoles(res.data, dispatch, getState);
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

function getUserRoles(data, dispatch, getState){
    axios.get(`${process.env.REACT_APP_API_CLIENT}/users/roles`, tokenConfig(getState))
        .then(async (res) => {
            const newData = await data.map(item => {
                const index = res.data.findIndex(da => da._id === item.tag);
                var newItem = {};
                if(index >= 0){
                    newItem = { ...item, ...res.data[index] }
                }
                return newItem
            })
            dispatch({
                type: GET_COMP_USERS,
                payload: newData
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const updateEmployee = (employee, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/users/update/employee/${id}`, employee, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}