import React, { Component } from "react";
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { FaInfoCircle, FaAddressBook, FaSave, FaEdit } from 'react-icons/fa';
import { addBuyer, updateBuyer, buyerDupCheck } from '../actions/buyerActions';
import ReactTooltip from 'react-tooltip';
import RenderContacts from '../components/render-contacts.component';
import ContactTagsAlt from '../modals/modal-components/contact-tags-alt.modal-component';
import LocationAutocomplete from '../components/autocompletes/location-autocomplete';
import { confirmAlert } from 'react-confirm-alert'
import 'react-phone-input-2/lib/style.css'

class BuyerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            render: 'info',
            edit: true,
            id: '',
            header: '',
            company: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            website: '',
            facebook: '',
            instagram: '',
            phone: '',
            fax: '',
            enteredBy: '',
            render_type: 'enter',
            status: 'Active'
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.type === 'render') {
            const buyer_array = this.props.render_buyer_data;
            this.setState({
                render_type: 'render',
                edit: false,
                id: buyer_array._id,
                header: buyer_array.company.toUpperCase(),
                company: buyer_array.company,
                address: buyer_array.address.address,
                address2: buyer_array.address.address2,
                city: buyer_array.address.city,
                state: buyer_array.address.state,
                country: buyer_array.address.country,
                zipcode: buyer_array.address.postal_code,
                website: buyer_array.website,
                facebook: buyer_array.facebook,
                instagram: buyer_array.instagram,
                phone: buyer_array.contact.main_phone,
                fax: buyer_array.contact.fax,
                enteredBy: buyer_array.enteredBy,
                status: buyer_array.status,
            })
        } else {
            this.setState({
                render_type: 'enter',
                header: 'NEW BUYER'
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleEventPositioned() {
        ReactTooltip.rebuild();
    }

    handleClick = () => {
        this.props.toggle();
    };

    handleLocation = (results, title) => {
        var street = "";
        var route = "";
        var subpremise = "";
        var locality = "";
        var state = "";
        var country = "";
        var postal_code = "";

        results[0].address_components.map(item => {
            if (item.types.includes('subpremise')) {
                subpremise = item.long_name
            }
            if (item.types.includes('sublocality')) {
                locality = item.long_name
            }
            if (item.types.includes('street_number')) {
                street = item.long_name
            }
            if (item.types.includes('route')) {
                route = item.short_name
            }
            if (item.types.includes('locality')) {
                locality = item.long_name
            }
            if (item.types.includes('administrative_area_level_1')) {
                state = item.long_name
            }
            if (item.types.includes('country')) {
                country = item.long_name
            }
            if (item.types.includes('postal_code')) {
                postal_code = item.long_name
            }
        })
        this.setState({
            address: street + ' ' + route,
            address2: subpremise,
            country: country,
            city: locality,
            state: state,
            zipcode: postal_code,
            company: title
        })
    }

    toggleSub = (e) => {
        this.setState({
            render: e,
        })
    }

    toggleStatus = () => {
        if (this.state.status === 'Active') {
            this.setState({
                status: "Inactive"
            })
        } else {
            this.setState({
                status: "Active"
            })
        }
    }

    onEdit = () => {
        this.setState({
            edit: true
        })
    }

    onSave = async () => {
        if (!this.state.company) {
            alert('Please enter a buyer company name');
        } else {
            const dupCheck = this.state.company;

            var check = await this.props.buyerDupCheck(dupCheck);
            if (check.length > 0 && this.state.render_type === 'enter') { 
                this.duplicateDialog(check);
            } else {
                this.finishSave();
            }
        }
    }

    finishSave = async () => {
        const id = this.state.id;
        const address = {
            address: this.state.address,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            postal_code: this.state.zipcode,
        }

        const contact = {
            main_phone: this.state.phone,
            fax: this.state.fax
        }

        if (this.state.render_type === 'render') {

            const buyer = {
                company: this.state.company,
                address: address,
                website: this.state.website,
                facebook: this.state.facebook,
                instagram: this.state.instagram,
                contact: contact,
                status: this.state.status,
                enteredBy: this.state.enteredBy,
                updatedBy: this.props.auth.user._id
            }

            const log_status = await this.props.updateBuyer(buyer, id);
            if (log_status.includes('Error:')) {
                this.props.toggleErrorSnackbar(log_status);
            } else {
                this.props.toggleStatusSnackbar('Buyer updated successfully');
                this.setState({
                    header: this.state.company,
                    edit: false,
                    render_type: 'render'
                })
            }
        } else {

            const buyer = {
                company: this.state.company,
                address: address,
                website: this.state.website,
                facebook: this.state.facebook,
                instagram: this.state.instagram,
                contact: contact,
                status: this.state.status,
                enteredBy: this.props.auth.user.tag,
                updatedBy: ''
            }

            const log_status = await this.props.addBuyer(buyer);
            if (log_status.includes('Error:')) {
                this.props.toggleErrorSnackbar(log_status);
            } else {
                this.props.toggleStatusSnackbar('Buyer added successfully');
                this.setState({
                    header: this.state.company,
                    edit: false,
                    render_type: 'render',
                    id: log_status,
                })
            }
        }
    }

    duplicateDialog = async (check) => {
        const checkData = await check.map(item => {
            const obj = {
                company: item.company,
                address: item.address,
            }
            return obj
        })
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Duplicate</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ fontSize: 14, color: '#000', marginBottom: 10 }}>The following buyer is alredy entered in the system.  Would you like to continue saving?</p>
                            {checkData.map((item, index) => {
                                return (
                                    <p style={{ fontSize: 12, color: '#000' }} key={index}>{item.company} ({item.address.city}, {item.address.state})</p>
                                )
                            })}
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.finishSave(); onClose(); }}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={onClose}>No</button>
                        </div>
                    </div>
                </div>
            )
        }

        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }

        confirmAlert(options)
    }

    render() {

        return (
            <div className="mode-t">
                <div className="mode-t-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50 }}>
                        <span className="close" onClick={this.props.close}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        {this.state.edit ?
                            <span className="close" onClick={this.onSave}>
                                <FaSave size="20" color="#cdcdcd" />
                            </span> :
                            <span className="close" onClick={this.onEdit}>
                                <FaEdit size="18" color="#cdcdcd" />
                            </span>}
                        {this.state.status === "Active" ?
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>ACTIVE</p>
                                <div style={{ backgroundColor: 'green', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span> :
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>INACTIVE</p>
                                <div style={{ backgroundColor: 'red', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span>}
                        <h1 style={{ color: '#fff', marginLeft: 60, fontFamily: 'Helvetica', paddingTop: 12, fontSize: 22, fontWeight: 'bold' }}>{this.state.header}</h1>
                    </div>
                    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                        <div style={{ width: '5%', backgroundColor: 'rgb(1, 22, 48)', height: '91.6%' }}>
                            {/* {this.props.tooltip ? */}
                            <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                            </ReactTooltip>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'info' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'info' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('info')} data-tip={'Buyer Info'} onMouseEnter={this.handleEventPositioned}>
                                <FaInfoCircle style={{ fontSize: 20 }} />
                            </div>
                            {this.state.render_type !== 'enter' ?
                                <div style={{ height: 50, backgroundColor: this.state.render === 'contacts' || this.state.render === 'new_tags' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'contacts' || this.state.render === 'new_tags' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('contacts')} data-tip={'Contacts'} onMouseEnter={this.handleEventPositioned}>
                                    <FaAddressBook style={{ fontSize: 20 }} />
                                </div> : null}
                        </div>

                        {this.state.render === 'info' ?
                            <div style={{ display: 'flex', width: '100%', height: 450 }}>
                                <div style={{ width: '47.5%' }}>
                                    {this.state.render_type === 'enter' ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, width: '90%', marginTop: 30 }} className="form-group">
                                            <LocationAutocomplete handleLocation={this.handleLocation} Gkey={this.props.auth.user.api.google} />
                                        </div> : null}
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 30 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Buyer Company:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="company" value={this.state.company} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Address:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="address" value={this.state.address} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}> </label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="address2" value={this.state.address2} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Country:</label>
                                        <select style={{ marginLeft: 10, width: '55%', fontSize: 12, color: '#000', border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.country} name="country" onChange={this.handleChange.bind(this)}>
                                            <option value=""> </option>
                                            <option value="United States">United States</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Afganistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bonaire">Bonaire</option>
                                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canary Islands">Canary Islands</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Channel Islands">Channel Islands</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos Island">Cocos Island</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote DIvoire">Cote DIvoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curaco">Curacao</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="East Timor">East Timor</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands">Falkland Islands</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Ter">French Southern Ter</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Great Britain">Great Britain</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Hawaii">Hawaii</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="India">India</option>
                                            <option value="Iran">Iran</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea North">Korea North</option>
                                            <option value="Korea Sout">Korea South</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Laos">Laos</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libya">Libya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macau">Macau</option>
                                            <option value="Macedonia">Macedonia</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Midway Islands">Midway Islands</option>
                                            <option value="Moldova">Moldova</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Nambia">Nambia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherland Antilles">Netherland Antilles</option>
                                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                            <option value="Nevis">Nevis</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau Island">Palau Island</option>
                                            <option value="Palestine">Palestine</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Phillipines">Philippines</option>
                                            <option value="Pitcairn Island">Pitcairn Island</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                                            <option value="Republic of Serbia">Republic of Serbia</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russia">Russia</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="St Barthelemy">St Barthelemy</option>
                                            <option value="St Eustatius">St Eustatius</option>
                                            <option value="St Helena">St Helena</option>
                                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                            <option value="St Lucia">St Lucia</option>
                                            <option value="St Maarten">St Maarten</option>
                                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                            <option value="Saipan">Saipan</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Samoa American">Samoa American</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syria">Syria</option>
                                            <option value="Tahiti">Tahiti</option>
                                            <option value="Taiwan">Taiwan</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania">Tanzania</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Erimates">United Arab Emirates</option>
                                            <option value="Uraguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Vatican City State">Vatican City State</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                            <option value="Wake Island">Wake Island</option>
                                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zaire">Zaire</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            <label style={{ width: '25%', fontSize: 14 }}>City:</label>
                                            <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="city" value={this.state.city} onChange={this.handleChange.bind(this)} />
                                        </div> : null}
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            {this.state.country === 'United States' ?
                                                <label style={{ width: '25%', fontSize: 14 }}>State:</label> :
                                                <label style={{ width: '25%', fontSize: 14 }}>Province:</label>}
                                            {this.state.country === 'United States' ?
                                                <select style={{ marginLeft: 10, width: '55%', border: '.4px solid #000', fontSize: 12, color: '#000' }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.state} name="state" onChange={this.handleChange.bind(this)}>
                                                    <option value=""> </option>
                                                    <option value="Alabama">Alabama</option>
                                                    <option value="Alaska">Alaska</option>
                                                    <option value="Arizona">Arizona</option>
                                                    <option value="Arkansas">Arkansas</option>
                                                    <option value="California">California</option>
                                                    <option value="Colorado">Colorado</option>
                                                    <option value="Connecticut">Connecticut</option>
                                                    <option value="Delaware">Delaware</option>
                                                    <option value="District Of Columbia">District Of Columbia</option>
                                                    <option value="Florida">Florida</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Idaho">Idaho</option>
                                                    <option value="Illinois">Illinois</option>
                                                    <option value="Indiana">Indiana</option>
                                                    <option value="Iowa">Iowa</option>
                                                    <option value="Kansas">Kansas</option>
                                                    <option value="Kentucky">Kentucky</option>
                                                    <option value="Louisiana">Louisiana</option>
                                                    <option value="Maine">Maine</option>
                                                    <option value="Maryland">Maryland</option>
                                                    <option value="Massachusetts">Massachusetts</option>
                                                    <option value="Michigan">Michigan</option>
                                                    <option value="Minnesota">Minnesota</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="Missouri">Missouri</option>
                                                    <option value="Montana">Montana</option>
                                                    <option value="Nebraska">Nebraska</option>
                                                    <option value="Nevada">Nevada</option>
                                                    <option value="New Hampshire">New Hampshire</option>
                                                    <option value="New Jersey">New Jersey</option>
                                                    <option value="New Mexico">New Mexico</option>
                                                    <option value="New York">New York</option>
                                                    <option value="North Carolina">North Carolina</option>
                                                    <option value="North Dakota">North Dakota</option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Oklahoma">Oklahoma</option>
                                                    <option value="Oregon">Oregon</option>
                                                    <option value="Pennsylvania">Pennsylvania</option>
                                                    <option value="Rhode Island">Rhode Island</option>
                                                    <option value="South Carolina">South Carolina</option>
                                                    <option value="South Dakota">South Dakota</option>
                                                    <option value="Tennessee">Tennessee</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="Utah">Utah</option>
                                                    <option value="Vermont">Vermont</option>
                                                    <option value="Virginia">Virginia</option>
                                                    <option value="Washington">Washington</option>
                                                    <option value="West Virginia">West Virginia</option>
                                                    <option value="Wisconsin">Wisconsin</option>
                                                    <option value="Wyoming">Wyoming</option>
                                                </select> :
                                                <input style={{ marginLeft: 10, width: '55%', border: '.4px solid #000', fontSize: 12, color: '#000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} value={this.state.state} name="state" onChange={this.handleChange.bind(this)} />}
                                        </div> : null}
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            <label style={{ width: '25%', fontSize: 14 }}>Postal Code:</label>
                                            <input style={{ marginLeft: 10, width: '30%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="zipcode" value={this.state.zipcode} onChange={this.handleChange.bind(this)} />
                                        </div> : null}
                                </div>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginTop: 30 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Website:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="website" value={this.state.website} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Facebook:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="facebook" value={this.state.facebook} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Instagram:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="instagram" value={this.state.instagram} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Main Office:</label>
                                        <PhoneInput
                                            containerStyle={{ marginLeft: 10, width: '55%', borderRadius: 5, border: '.4px solid #000' }}
                                            inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                            className="form-control-sm"
                                            disabled={!this.state.edit ? true : false}
                                            placeholder={''}
                                            renderStringAsFlag={!this.state.edit ? true : false}
                                            value={this.state.phone}
                                            onChange={phone => this.setState({ phone })} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Fax Number:</label>
                                        <PhoneInput
                                            containerStyle={{ marginLeft: 10, width: '55%', borderRadius: 5, border: '.4px solid #000' }}
                                            inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                            className="form-control-sm"
                                            disabled={!this.state.edit ? true : false}
                                            placeholder={''}
                                            renderStringAsFlag={!this.state.edit ? true : false}
                                            value={this.state.fax}
                                            onChange={fax => this.setState({ fax })} />
                                    </div>
                                </div>
                            </div> :
                            this.state.render === 'contacts' ?
                                <RenderContacts toggleSub={this.toggleSub} toggleNewModal={this.props.toggleNewModal} {...this.state} /> :
                                <ContactTagsAlt toggleSub={this.toggleSub} sub_id={this.state.id} />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    items: state.items,
    auth: state.auth
});

export default connect(mapStateToProps, { addBuyer, updateBuyer, buyerDupCheck })(BuyerModal);