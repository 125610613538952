import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { addAR, updateAR } from '../actions/arActions';
import { spotifyLogin, getSpotify } from '../actions/spotifyActions';
import { FaInfoCircle, FaAddressBook, FaSave, FaPlusSquare, FaMinusSquare, FaEdit, FaUserAstronaut, FaCommentDollar, FaHandsHelping, FaChevronCircleUp, FaChevronCircleDown, FaArrowLeft, FaVoteYea } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import RenderContacts from '../components/render-contacts.component';
import AgentAutocomplete from '../components/autocompletes/agent-autocomplete';
import ClientSocials from '../modals/modal-components/client.socials.modal-component';
import ClientFinancials from '../modals/modal-components/client-financials.modal-component';
import ContactTagsAlt from '../modals/modal-components/contact-tags-alt.modal-component';
import SearchAutocomplete from '../components/autocompletes/search-autocomplete';
import ClientTags from './modal-components/client-tags.modal-component';

class ARModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addcontact: false,
            checkedItems: new Map(),
            edit: true,
            render: 'info',
            renderclientdata: {},
            instagramfollow: '',
            id: '',
            header: '',
            client: '',
            address: [],
            city: '',
            state: '',
            country: '',
            agent: [],
            website: '',
            facebook: '',
            instagram: '',
            website: '',
            facebook: '',
            instagram: '',
            twitter: '',
            youtube: '',
            bandcamp: '',
            status: 'Active',
            artist_search: "",
            artist_spotify: { id: '', url: '' },
            applemusic: '',
            genre: { blues: false, classical: false, comedy: false, country: false, electronic: false, folk: false, hiphop: false, jazz: false, pop: false, rock: false, world: false },
            genre_map: '',
            genre_filter: false,
            image: '',
            commission_base: 15,
            typing: false,
            typingTimeout: 0,
            render_type: 'enter',
            notes: '',
            contact: '',
            contact_email: '',
            spotify_id: '',
            links: [],
            type: 'A&R',
            add_options: ["Apple Music", "Bandcamp", "Facebook", "Instagram", "Spotify", "Twitter", "Website", "YouTube"],
            add_link: '',
            add_type: '',
            add_type_custom: '',
            artist: '', artistid: '', artistbatch: { _id: '', client: '' },
            date_submitted: new Date(),
            submissions: [],
            submission_note: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleLinksArrayChange = this.handleLinksArrayChange.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleArtistChange = this.handleArtistChange.bind(this);
        this.handleArtistSelect = this.handleArtistSelect.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        spotify: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        ar: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    componentDidMount() {
        this.props.spotifyLogin();
        if (this.props.type === 'render') {
            const ar_array = this.props.render_ar_data;
            var agents = [];
            this.props.company.comp_users.map((item, index) => {
                ar_array.agent.forEach(age => {
                    if (item.tag === age._id) {
                        const tag = {
                            _id: age._id,
                            name: item.name
                        }
                        agents.push(tag)
                    }
                })
            })
            var contact = '';
            var contact_email = '';
            var artist_spotify = { id: '', url: '' };
            if (ar_array.contact) {
                contact = ar_array.contact.name;
                contact_email = ar_array.contact.email;
            };
            if (ar_array.spotify) {
                artist_spotify = {
                    id: ar_array.spotify.id,
                    url: ar_array.spotify.url
                }
            }
            this.setState({
                render_type: 'render',
                edit: false,
                id: ar_array._id,
                header: ar_array.artist.toUpperCase(),
                type: ar_array.type ? ar_array.type : "A&R",
                client: ar_array.artist,
                artist_search: ar_array.artist,
                city: ar_array.address.city,
                state: ar_array.address.state,
                country: ar_array.address.country,
                agent: agents,
                website: ar_array.website,
                facebook: ar_array.facebook,
                instagram: ar_array.instagram,
                twitter: ar_array.twitter,
                youtube: ar_array.youtube,
                bandcamp: ar_array.bandcamp,
                artist_spotify: artist_spotify,
                applemusic: ar_array.applemusic,
                links: ar_array.links,
                genre_map: ar_array.genre,
                status: ar_array.status,
                image: ar_array.image,
                notes: ar_array.notes,
                contact: contact,
                contact_email: contact_email,
                submissions: ar_array.client_tags,
            })
        } else {
            this.setState({
                render_type: 'enter',
                header: 'ARTIST TRACKING'
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleEventPositioned() {
        ReactTooltip.rebuild();
    }

    handleLinksArrayChange(event) {
        var index = event.target.name;
        var links = this.state.links;
        links[index].url = event.target.value
        this.setState({
            links: links
        });
    }

    handleArtistChange = (e) => {
        //var searchText = event.target.value; // this is the search text
        this.setState({
            artist_search: e,
            client: e
        });

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const itemStr = this.props.spotify.token;
            const item = JSON.parse(itemStr)
            const data = {
                data: this.state.artist_search,
                spotify_token: item.token
            }
            this.props.getSpotify(data)
        }, 200);
    }

    handleArtistSelect = (e) => {
        //var searchText = event.target.value; // this is the search text
        var genres = e.genres.join(', ');
        var links = this.state.links;
        var spotify = {
            desc: 'Spotify',
            url: e.external_urls.spotify
        };
        links = [...links, spotify];
        this.setState({
            artist: e.name,
            artist_search: e.name,
            artist_spotify: { id: e.id, url: e.external_urls.spotify },
            spotify_id: e.id,
            links: links,
            genre_map: genres,
            image: e.images[0].url
        });
    }

    handleAgentChange(agent) {
        if (agent) {
            this.setState({
                agent: agent
            })
        } else {
            this.setState({
                agent: [],
            });
        }
    }

    handleCommChange = (e) => {
        const val = e.target.value.replace(/[%\s]/g, '');
        this.setState({
            commission_base: val
        });
    }

    handleClick = () => {
        this.props.close();
    };

    toggleSub = (e) => {
        this.setState({
            render: e,
        })
    }

    toggleNewContact = () => {
        this.setState({
            render: 'addcontact',
            hover2: false
        })
    }

    toggleAddContact = () => {
        this.setState({
            contacts: 'addcontacts',
        })
    }

    togglePop = () => {
        this.setState({
            seen: !this.state.seen
        });
    };

    onEdit = () => {
        this.setState({
            edit: !this.state.edit
        })
    }

    toggleStatus = () => {
        if (this.state.status === 'Active') {
            this.setState({
                status: "Inactive"
            })
        } else {
            this.setState({
                status: "Active"
            })
        }
    }

    toggleGenreFilter = (e) => {
        this.setState({
            genre_filter: e
        })
    }

    onSave = async () => {

        if (!this.state.client) {
            alert('Please enter an artist name');
        } else {
            const checkIndex = this.props.ar.ar.findIndex(item => item.artist === this.state.client)
            if (checkIndex >= 0 && this.state.render_type === 'enter') {
                alert('You already have an artist entered under this name.  Please enter a new name or update the previous artist profile.');
            } else {
                const address = {
                    city: this.state.city,
                    state: this.state.state,
                    country: this.state.country,
                }

                const contact = {
                    name: this.state.contact,
                    email: this.state.contact_email
                }

                const agents = [];
                this.state.agent.forEach(item => {
                    const tag = {
                        _id: item.tag,
                    }
                    agents.push(tag)
                });

                const ar = {
                    type: this.state.type,
                    artist: this.state.client,
                    address: address,
                    agent: agents,
                    website: this.state.website,
                    facebook: this.state.facebook,
                    instagram: this.state.instagram,
                    twitter: this.state.twitter,
                    youtube: this.state.youtube,
                    bandcamp: this.state.bandcamp,
                    spotify: this.state.artist_spotify,
                    applemusic: this.state.applemusic,
                    links: this.state.links,
                    spotify_id: this.state.spotify_id,
                    genre: this.state.genre_map,
                    status: this.state.status,
                    image: this.state.image,
                    notes: this.state.notes,
                    contact: contact,
                    client_tags: this.state.submissions
                }

                var id = this.state.id

                if (this.props.type === 'render') {
                    const log_status = await this.props.updateAR(ar, id)
                    if (log_status.includes('Error:')) {
                        this.props.toggleErrorSnackbar(log_status);
                    } else {
                        this.setState({
                            header: this.state.artist_search.toUpperCase(),
                            edit: false
                        })
                        this.props.toggleStatusSnackbar('Artist updated successfully');
                    }
                } else {
                    const log_status = await this.props.addAR(ar)
                    if (log_status.includes('Error:')) {
                        this.props.toggleErrorSnackbar(log_status);
                    } else {
                        this.setState({
                            header: this.state.artist_search.toUpperCase(),
                            edit: false,
                            render_type: 'render',
                            id: log_status,
                        })
                        this.props.toggleStatusSnackbar('Artist added successfully');
                    }
                    //this.props.toggleClientSnackbar();
                }
            }
        }
        //this.props.toggle();
    }

    addLink = () => {
        if (this.state.add_link && this.state.add_type) {
            if (this.state.add_type === 'Other') {
                if (this.state.add_options.includes(this.state.add_type_custom)) {
                    alert('Link type already exists.  Please create a new label.')
                } else {
                    const newLink = {
                        desc: this.state.add_type_custom,
                        url: this.state.add_link
                    }
                    this.setState({
                        links: [...this.state.links, newLink],
                        add_link: '',
                        add_type: ''
                    })
                }
            } else {
                const newLink = {
                    desc: this.state.add_type,
                    url: this.state.add_link
                }
                this.setState({
                    links: [...this.state.links, newLink],
                    add_link: '',
                    add_type: ''
                })
            }
        }
    }

    linkClear = () => {
        this.setState({
            add_type: ''
        })
    }

    handleClientChange(artist) {
        console.log(artist)
        if (artist) {
            this.setState({
                artist: artist.client,
                artistid: artist._id,
                artistbatch: artist
            })
        } else {
            this.setState({
                artist: '',
                artistid: '',
                artistbatch: ''
            });
        }
    }

    onDateSubmitted = date => {
        this.setState({
            date_submitted: date
        })
    }

    addSubmission = () => {
        const submission = {
            date: moment(this.state.date_submitted).format('MM/DD/YYYY'),
            client: this.state.artistid,
            note: this.state.submission_note,
            user: this.props.auth.user.tag
        }
        var stateSubs = this.state.submissions;
        const found = stateSubs.findIndex(obj => obj.client === this.state.artistid);
        if (found >= 0) {
            alert('Submission is already added.')
        } else {
            stateSubs = [...stateSubs, submission];
            var newSubs = [];
            stateSubs.map(item => {
                var client = this.props.clients.clients.filter(cl => cl._id === item.client);
                if(client && client[0]){
                    client = client[0]
                }
                newSubs.push({ ...item, c_sort: client.client });
            })
            newSubs = _.orderBy(newSubs, [`c_sort`.toLowerCase()], ['asc']);
            this.setState({
                submissions: newSubs,
                artist: '',
                artistid: '',
                artistbatch: { _id: '', client: '' },
                date_submitted: new Date(),
                submission_note: ''
            })
        }
    }

    updateSubmission = (index, client) => {
        const val = this.state.submissions[index];
        this.setState({
            artist: client,
            artistid: val.client,
            artistbatch: { _id: val.client, client: client },
            date_submitted: new Date(val.date_submitted),
            submission_note: val.note
        })
        const newArray = this.state.submissions.filter((e, i) => i !== index);
        this.setState({
            submissions: newArray
        })
    }

    deleteSubmission = (index, client) => {
        const newArray = this.state.submissions.filter((e, i) => i !== index);
        this.setState({
            submissions: newArray
        })
    }

    updateSubsArray = (index, name, event) => {
        let array = this.state.submissions;
        array[index][name] = event.target.value;
        array = _.sortBy(array, function (dateObj) {
            return new Date(dateObj.date);
        });
        this.setState({
            submissions: array
        });
    }

    render() {

        const options = this.state.add_options;
        const options_used = this.state.links.map(l => l.desc);
        var add_options = options.filter(item => !options_used.includes(item));

        return (
            <div className="mode-t">
                <div className="mode-t-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50 }}>
                        <span className="close" onClick={this.handleClick}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        {this.state.edit ?
                            <span className="close" onClick={this.onSave}>
                                <FaSave size="20" color="#cdcdcd" />
                            </span> :
                            <span className="close" onClick={this.onEdit}>
                                <FaEdit size="18" color="#cdcdcd" />
                            </span>}
                        {this.state.status === "Active" ?
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>ACTIVE</p>
                                <div style={{ backgroundColor: 'green', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span> :
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>INACTIVE</p>
                                <div style={{ backgroundColor: 'red', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span>}
                        <h1 style={{ color: '#fff', marginLeft: 60, fontFamily: 'Helvetica', paddingTop: 12, fontSize: 22, fontWeight: 'bold' }}>{this.state.header}</h1>
                    </div>

                    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                        <div style={{ width: '5%', backgroundColor: 'rgb(1, 22, 48)', height: '91.6%' }}>
                            {/* {this.props.tooltip ? */}
                            <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                            </ReactTooltip>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'info' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'info' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub("info")} data-tip={'Artist Info'} onMouseEnter={this.handleEventPositioned}>
                                <FaInfoCircle style={{ fontSize: 20 }} />
                            </div>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'contacts' || this.state.render === 'new_tags' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'contacts' || this.state.render === 'new_tags' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub("contacts")} data-tip={'Contacts'} onMouseEnter={this.handleEventPositioned}>
                                <FaAddressBook style={{ fontSize: 20 }} />
                            </div>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'socials' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'socials' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub("socials")} data-tip={'Artist Socials'} onMouseEnter={this.handleEventPositioned}>
                                <FaUserAstronaut style={{ fontSize: 20 }} />
                            </div>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'submissions' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'submissions' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('submissions')} data-tip={'Client Tags'} onMouseEnter={this.handleEventPositioned}>
                                <FaVoteYea style={{ fontSize: 20 }} />
                            </div>
                        </div>
                        {this.state.render === "info" ?
                            <div style={{ display: 'flex', width: '100%', height: 510, overflowY: 'scroll' }}>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 30 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Type:</label>
                                        <select style={{ marginLeft: 10, width: '55%', border: '.4px solid #000', fontSize: 12, color: '#000', border: '.4px solid #000', padding: 0 }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.type} name="type" onChange={this.handleChange.bind(this)}>
                                            <option value=""> </option>
                                            <option value="A&R">A&R</option>
                                            <option value="Local Support">Local Support</option>
                                            <option value="Misc">Misc</option>
                                        </select>
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Artist:</label>
                                        {this.state.edit ?
                                            <div style={{ marginLeft: 10, width: '55%', fontSize: 12 }}>
                                                <SearchAutocomplete disabled={this.state.edit ? false : true} searchList={this.props.spotify.spotify} onChange={this.handleArtistChange.bind(this)} onSelect={this.handleArtistSelect.bind(this)} searchSelect={this.state.client} artist_search={this.state.artist_search} loading={this.props.spotify.loading} />
                                            </div> :
                                            <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={true} type="text" name="client" value={this.state.client} onChange={this.handleChange.bind(this)} />}
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Country:</label>
                                        <select style={{ marginLeft: 10, width: '55%', fontSize: 12, color: '#000', border: '.4px solid #000', border: '.4px solid #000', padding: 0 }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.country} name="country" onChange={this.handleChange.bind(this)}>
                                            <option value=""> </option>
                                            <option value="United States">United States</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Afganistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bonaire">Bonaire</option>
                                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canary Islands">Canary Islands</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Channel Islands">Channel Islands</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos Island">Cocos Island</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote DIvoire">Cote DIvoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curaco">Curacao</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="East Timor">East Timor</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands">Falkland Islands</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Ter">French Southern Ter</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Great Britain">Great Britain</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Hawaii">Hawaii</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="India">India</option>
                                            <option value="Iran">Iran</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea North">Korea North</option>
                                            <option value="Korea Sout">Korea South</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Laos">Laos</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libya">Libya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macau">Macau</option>
                                            <option value="Macedonia">Macedonia</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Midway Islands">Midway Islands</option>
                                            <option value="Moldova">Moldova</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Nambia">Nambia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherland Antilles">Netherland Antilles</option>
                                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                            <option value="Nevis">Nevis</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau Island">Palau Island</option>
                                            <option value="Palestine">Palestine</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Phillipines">Philippines</option>
                                            <option value="Pitcairn Island">Pitcairn Island</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                                            <option value="Republic of Serbia">Republic of Serbia</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russia">Russia</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="St Barthelemy">St Barthelemy</option>
                                            <option value="St Eustatius">St Eustatius</option>
                                            <option value="St Helena">St Helena</option>
                                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                            <option value="St Lucia">St Lucia</option>
                                            <option value="St Maarten">St Maarten</option>
                                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                            <option value="Saipan">Saipan</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Samoa American">Samoa American</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syria">Syria</option>
                                            <option value="Tahiti">Tahiti</option>
                                            <option value="Taiwan">Taiwan</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania">Tanzania</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Erimates">United Arab Emirates</option>
                                            <option value="Uraguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Vatican City State">Vatican City State</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                            <option value="Wake Island">Wake Island</option>
                                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zaire">Zaire</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            <label style={{ width: '25%', fontSize: 14 }}>City:</label>
                                            <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="city" value={this.state.city} onChange={this.handleChange.bind(this)} />
                                        </div> : null}
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            {this.state.country === 'United States' ?
                                                <label style={{ width: '25%', fontSize: 14 }}>State:</label> :
                                                <label style={{ width: '25%', fontSize: 14 }}>Province:</label>}
                                            {this.state.country === 'United States' ?
                                                <select style={{ marginLeft: 10, width: '55%', border: '.4px solid #000', fontSize: 12, color: '#000' }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.state} name="state" onChange={this.handleChange.bind(this)}>
                                                    <option value=""> </option>
                                                    <option value="Alabama">Alabama</option>
                                                    <option value="Alaska">Alaska</option>
                                                    <option value="Arizona">Arizona</option>
                                                    <option value="Arkansas">Arkansas</option>
                                                    <option value="California">California</option>
                                                    <option value="Colorado">Colorado</option>
                                                    <option value="Connecticut">Connecticut</option>
                                                    <option value="Delaware">Delaware</option>
                                                    <option value="District Of Columbia">District Of Columbia</option>
                                                    <option value="Florida">Florida</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Idaho">Idaho</option>
                                                    <option value="Illinois">Illinois</option>
                                                    <option value="Indiana">Indiana</option>
                                                    <option value="Iowa">Iowa</option>
                                                    <option value="Kansas">Kansas</option>
                                                    <option value="Kentucky">Kentucky</option>
                                                    <option value="Louisiana">Louisiana</option>
                                                    <option value="Maine">Maine</option>
                                                    <option value="Maryland">Maryland</option>
                                                    <option value="Massachusetts">Massachusetts</option>
                                                    <option value="Michigan">Michigan</option>
                                                    <option value="Minnesota">Minnesota</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="Missouri">Missouri</option>
                                                    <option value="Montana">Montana</option>
                                                    <option value="Nebraska">Nebraska</option>
                                                    <option value="Nevada">Nevada</option>
                                                    <option value="New Hampshire">New Hampshire</option>
                                                    <option value="New Jersey">New Jersey</option>
                                                    <option value="New Mexico">New Mexico</option>
                                                    <option value="New York">New York</option>
                                                    <option value="North Carolina">North Carolina</option>
                                                    <option value="North Dakota">North Dakota</option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Oklahoma">Oklahoma</option>
                                                    <option value="Oregon">Oregon</option>
                                                    <option value="Pennsylvania">Pennsylvania</option>
                                                    <option value="Rhode Island">Rhode Island</option>
                                                    <option value="South Carolina">South Carolina</option>
                                                    <option value="South Dakota">South Dakota</option>
                                                    <option value="Tennessee">Tennessee</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="Utah">Utah</option>
                                                    <option value="Vermont">Vermont</option>
                                                    <option value="Virginia">Virginia</option>
                                                    <option value="Washington">Washington</option>
                                                    <option value="West Virginia">West Virginia</option>
                                                    <option value="Wisconsin">Wisconsin</option>
                                                    <option value="Wyoming">Wyoming</option>
                                                </select> :
                                                <input style={{ marginLeft: 10, width: '55%', border: '.4px solid #000', fontSize: 12, color: '#000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} value={this.state.state} name="state" onChange={this.handleChange.bind(this)} />}
                                        </div> : null}
                                    <div style={{ display: 'flex', minHeight: 30, marginLeft: 60, paddingTop: 5 }}>
                                        <label style={{ width: '25%', fontSize: 14 }}>Tracking Agent(s):</label>
                                        <div style={{ width: '55%' }}>
                                            <AgentAutocomplete onAgentChange={this.handleAgentChange} agentSelect={this.state.agent} edit={this.state.edit} agentList={this.props.company.agents} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', height: 40, marginLeft: 60, alignItems: 'center', marginTop: 20 }}>
                                        <label style={{ width: '25%', fontSize: 14 }}>Genre:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="genre_map" value={this.state.genre_map} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, marginLeft: 60, alignItems: 'center', marginTop: 20 }}>
                                        <label style={{ width: '25%', fontSize: 14 }}>Main Contact:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="contact" value={this.state.contact} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, marginLeft: 60, alignItems: 'center', marginTop: 20 }}>
                                        <label style={{ width: '25%', fontSize: 14 }}>Email Address:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="contact_email" value={this.state.contact_email} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    {this.state.genre_filter ?
                                        <div style={{ display: 'flex', height: 'auto', marginTop: 20 }}>
                                            <label style={{ width: '20%', fontSize: 14 }}> </label>
                                            <div style={{ marginLeft: 10, width: '65%', border: '.4px solid #ddd', borderRadius: 5 }}>
                                                <div style={{ display: 'flex', width: '100%', height: 'auto', marginTop: 10 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="blues"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.blues}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>Blues</p>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="classical"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.classical}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>Classical</p>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="comedy"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.comedy}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>Comedy</p>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="country"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.country}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>Country</p>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="electronic"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.electronic}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>Electronic</p>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%', height: 'auto', marginTop: 10 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="folk"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.folk}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>Folk</p>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="hiphop"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.hiphop}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>Hip Hop</p>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="jazz"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.jazz}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>Jazz</p>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="pop"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.pop}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>Pop</p>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="rock"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.rock}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>Rock</p>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%', height: 'auto', marginTop: 10 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="world"
                                                            onChange={this.handleCheckbox}
                                                            value={this.state.genre.world}
                                                        />
                                                        <p style={{ fontSize: 10, color: '#000' }}>World</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}
                                </div>
                                <div style={{ width: '47.5%' }}>
                                    {/* <div style={{ display: 'flex', height: 40, alignItems: 'center', marginTop: 30 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Website:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="website" value={this.state.website} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Facebook:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="facebook" value={this.state.facebook} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Instagram:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="instagram" value={this.state.instagram} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Twitter:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="twitter" value={this.state.twitter} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>YouTube:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="youtube" value={this.state.youtube} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Bandcamp:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="bandcamp" value={this.state.bandcamp} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Spotify:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="artist_spotify" value={this.state.artist_spotify.url} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Apple Music:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="applemusic" value={this.state.applemusic} onChange={this.handleChange.bind(this)} />
                                    </div> */}
                                    {this.state.edit ?
                                        <div>
                                            <label style={{ width: '25%', fontSize: 14, marginTop: 40 }}>Add Links:</label>
                                            <div style={{ display: 'flex', height: 40, alignItems: 'center', marginBottom: 15, borderBottom: '1px solid #ddd' }} className="form-group">
                                                {this.state.add_type !== 'Other' ?
                                                    <select style={{ width: '25%', height: 28, fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" name="add_type" value={this.state.add_type} onChange={this.handleChange}>
                                                        <option value=""></option>
                                                        {add_options.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item}>{item}</option>
                                                            )
                                                        })}
                                                        <option value="Other">Other</option>
                                                    </select> :
                                                    <span style={{ width: '25%', display: 'flex' }}>
                                                        <input style={{ width: '90%', height: 28, fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" placeholder="description" type="text" name="add_type_custom" value={this.state.add_type_custom} onChange={this.handleChange} />
                                                        <FaArrowLeft style={{ marginTop: 6, cursor: 'pointer' }} size="16" color="rgb(1, 22, 48)" onClick={this.linkClear} />
                                                    </span>}
                                                <input style={{ marginLeft: 10, width: '50%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" placeholder="url" disabled={this.state.edit ? false : true} type="text" name="add_link" value={this.state.add_link} onChange={this.handleChange} />
                                                <FaPlusSquare style={{ cursor: 'pointer' }} size="28" color="rgb(1, 22, 48)" onClick={this.addLink} />
                                            </div>
                                        </div> : null}
                                    {this.state.links.map((item, index) => {
                                        return (
                                            <div key={index} style={{ display: 'flex', height: 40, alignItems: 'center', marginTop: index === 0 && !this.state.edit ? 40 : null }} className="form-group">
                                                <label style={{ width: '25%', fontSize: 14 }}>{item.desc}</label>
                                                <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name={index} value={item.url} onChange={this.handleLinksArrayChange} />
                                            </div>
                                        )
                                    })}
                                    <div style={{ display: 'flex', minHeight: 150, paddingTop: 10, marginTop: this.state.links.length > 0 ? 0 : 40 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Notes:</label>
                                        <textarea style={{ marginLeft: 10, minWidth: '55%', maxWidth: '55%', minHeight: 150, maxHeight: 150, fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} name="notes" value={this.state.notes} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    {this.state.image ?
                                        <div style={{ marginTop: 20 }}>
                                            <img style={{ marginLeft: '30%' }} width="100" height="100" src={this.state.image} />
                                        </div> : null}
                                </div>
                            </div> :

                            this.state.render === 'contacts' ?
                                <RenderContacts toggleSub={this.toggleSub} {...this.state} /> :
                                this.state.render === 'socials' ?
                                    <ClientSocials instagram={this.state.instagram} {...this.state} /> :
                                    this.state.render === 'submissions' ?
                                        <ClientTags onChange={this.handleChange} onClientChange={this.handleClientChange} onDateSubmitted={this.onDateSubmitted} addSubmission={this.addSubmission} updateSubmission={this.updateSubmission} deleteSubmission={this.deleteSubmission} updateSubsArray={this.updateSubsArray} {...this.state} /> :
                                        <ClientFinancials artist_id={this.state.id} handleCommChange={this.handleCommChange} {...this.state} />}

                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    items: state.items,
    spotify: state.spotify,
    company: state.company,
    ar: state.ar,
    clients: state.clients,
    auth: state.auth
});

export default connect(mapStateToProps, { addAR, updateAR, spotifyLogin, getSpotify })(ARModal);