import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import DatePicker from "react-datepicker";
import ClientAutocomplete from "./client.autocomplete";
import VenueAutocomplete from "./venue.autocomplete";
import VenueModal from "../../modals/venue.modal";

export default function DateDialog(props) {

    const closeDialog = () => {
        props.handleClose();
    };

    return (
        // <Dialog
        //     style={{ zIndex: 2 }}
        //     open={props.open}
        //     onClose={props.handleClose}
        //     disableEnforceFocus={true}
        //     transitionDuration={0}
        //     aria-labelledby="alert-dialog-title"
        //     aria-describedby="alert-dialog-description"
        // >
        //     <DialogTitle style={{ backgroundColor: '#000', color: '#cdcdcd' }} id="alert-dialog-title">Select Date:</DialogTitle>
        //     <DialogContent style={{ backgroundColor: '#cdcdcd', zIndex: 2 }}>
        //         <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        //             <div style={{ width: 150, height: 'auto', fontSize: 12, color: '#000' }}>
        //                 <DatePicker
        //                     className="react-datepicker"
        //                     //value={this.props.date}
        //                     //selected={this.props.date}
        //                     //onChange={this.props.onDateChange}
        //                     dateFormat="EEE. MMMM d, yyyy"
        //                     todayButton="Today"
        //                     showMonthDropdown
        //                     showYearDropdown
        //                 />
        //             </div>
        //         </div>
        //     </DialogContent>

        //     <DialogActions style={{ backgroundColor: '#000' }}>
        //         <Button style={{ backgroundColor: '#cdcdcd', color: '#000' }} onClick={closeDialog}>Cancel</Button>
        //         <Button style={{ backgroundColor: '#cdcdcd', color: '#000' }} autoFocus>Select</Button>
        //     </DialogActions>
        // </Dialog>

        <div className="mode-sm">
            <div className="mode-sm-tag">
                <header style={{ height: 50, width: '100%', backgroundColor: 'rgb(1, 22, 48)', display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: 14, color: '#cdcdcd', paddingLeft: 20 }}>Select Date:</h1>
                </header>
                <div style={{ width: '100%', height: 100, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: 150, height: 26, fontSize: 12, color: '#000' }}>
                        <DatePicker
                            className="react-datepicker"
                            value={props.dateselect}
                            selected={props.dateselect}
                            onChange={props.handleDateChange}
                            dateFormat="EEE. MMMM d, yyyy"
                            todayButton="Today"
                            showMonthDropdown
                            showYearDropdown
                        />
                    </div>
                </div>
                <div style={{ width: '100%', height: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-start' }}>
                        <button style={{ backgroundColor: '#cdcdcd', color: '#000', marginLeft: 20, width: 80, fontSize: 12, display: 'flex', justifyContent: 'center' }} className="btn btn-secondary px-5" type="button" onClick={closeDialog}>Cancel</button>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                        <button style={{ backgroundColor: '#cdcdcd', color: '#000', marginRight: 20, width: 80, fontSize: 12, display: 'flex', justifyContent: 'center' }} className="btn btn-secondary px-5" type="button" onClick={props.applyDateChange}>Select</button>
                    </div>
                </div>
            </div>
        </div>
    );
}