import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FaIdCardAlt, FaAtom, FaMosque, FaShoppingCart, FaMicrophoneAlt, FaFlagCheckered, FaCalendarAlt, FaTicketAlt, FaBoxes, FaGrav, FaUniversalAccess, FaUsersCog } from 'react-icons/fa';
import { green, pink, purple, blue, blueGrey, indigo, red, teal, yellow, deepPurple, cyan } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        padding: '4px 8px',
        border: '1px solid',
        borderRadius: 140,
        lineHeight: 1.5,
        backgroundColor: '#0063cc',
        borderColor: '#0063cc',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0069d9',
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0),
    },
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: 'green',
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    blue: {
        color: '#fff',
        backgroundColor: blue[500],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    purple: {
        color: '#fff',
        backgroundColor: purple[500],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    indigo: {
        color: '#fff',
        backgroundColor: indigo[500],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    teal: {
        color: '#fff',
        backgroundColor: teal[500],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    blueGrey: {
        color: '#fff',
        backgroundColor: blueGrey[500],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    deepPurple: {
        color: '#fff',
        backgroundColor: deepPurple[500],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    yellow: {
        color: '#fff',
        backgroundColor: 'rgb(146, 146, 38)',
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    orange: {
        color: '#fff',
        backgroundColor: 'rgb(165, 107, 0)',
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    red: {
        color: '#fff',
        backgroundColor: 'rgb(126, 0, 0)',
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    cyan: {
        color: '#fff',
        backgroundColor: cyan[500],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
}));

export default function ChatButtonNav(props) {
    const classes = useStyles();

    //<Avatar className={classes.green} onClick={props.toggleSpaceChat}>
    //        <FaComments size="20" color="#fff" />
    //      </Avatar>


    // props.render === "admin" ?
    //     <Avatar className={classes.cyan}>
    //         <FaUsersCog size="22" color="#111" />
    //     </Avatar> : 

    return (
        <div>
            {props.render === "offers" ?
                <Avatar className={classes.green}>
                    <FaAtom size="20" color="#cdcdcd" />
                </Avatar> :
                props.render === "venues" ?
                    <Avatar className={classes.indigo}>
                        <FaMosque size="20" color="#cdcdcd" />
                    </Avatar> :
                    props.render === "buyers" ?
                        <Avatar className={classes.blue}>
                            <FaShoppingCart size="20" color="#cdcdcd" />
                        </Avatar> :
                        props.render === "contacts" ?
                            <Avatar className={classes.yellow}>
                                <FaIdCardAlt size="20" color="#cdcdcd" />
                            </Avatar> :
                            props.render === "clients" ?
                                <Avatar className={classes.orange}>
                                    <FaMicrophoneAlt size="20" color="#cdcdcd" />
                                </Avatar> :
                                props.render === "festivals" ?
                                    <Avatar className={classes.red}>
                                        <FaFlagCheckered size="20" color="#cdcdcd" />
                                    </Avatar> :
                                    props.render === "counts" ?
                                        <Avatar className={classes.deepPurple}>
                                            <FaTicketAlt size="20" color="#cdcdcd" />
                                        </Avatar> :
                                        props.render === "packaging" ?
                                            <Avatar className={classes.teal}>
                                                <FaBoxes size="20" color="#cdcdcd" />
                                            </Avatar> :
                                            props.render === "ar" ?
                                                <Avatar className={classes.yellow}>
                                                    <FaBoxes size="20" color="#cdcdcd" />
                                                </Avatar> :
                                                props.render === "calendar" ?
                                                    <Avatar className={classes.green}>
                                                        <FaCalendarAlt size="20" color="#cdcdcd" />
                                                    </Avatar> : null}
        </div>
    );
}