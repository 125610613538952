import React from 'react'
import { GoogleMap, useJsApiLoader, Marker, Polyline, DistanceMatrixService } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '100%'
};

const center = {
    lat: -34.397,
    lng: 150.644
};

function Map(props) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: props.GKey
    })

    const [map, setMap] = React.useState(null)

    // const onLoad = React.useCallback(function callback(map) {
    //     const bounds = new window.google.maps.LatLngBounds();
    //     map.fitBounds(bounds);
    //     setMap(map)
    // }, [])

    // const onUnmount = React.useCallback(function callback(map) {
    //     setMap(null)
    // }, [])

    // const dist = () => {
    //     return (
    //         <DistanceMatrixService
    //             options={{
    //                 destinations: props.coordsStr,
    //                 origins: props.coordsRevStr,
    //                 travelMode: "DRIVING",
    //             }}
    //             callback={(response) => { console.log(response.rows[0]); return (response) }}
    //         />
    //     )
    // }

    const onLoadPoly = polyline => {
        console.log('polyline: ', polyline)
    };

    var path = [];
    props.map_data.map((item, index) => {
        path.push({ lat: item.lat, lng: item.lng })
    })

    const options = {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        paths: path,
        zIndex: 1
    };

    const distCalc = async (data) => {
        console.log(data)
        for (let i = 0; i < data.length; i++){
            if(i === 0){
                await props.reportDistance(data[i], i)
            } else {
                await props.reportDistance(data[i-1], i)
            }
            
        }
        // data.map((item, index) => {
        //     // const newItem = {
        //     //     dist: item.distance.text,
        //     //     dur: item.duration.text,
        //     // }
        //     // if (index === 0) {
        //     //     props.reportDistance(newItem, 1)
        //     // } else if (index === 1) {
        //     //     props.reportDistance(newItem, 0)
        //     // } else {
        //     //     props.reportDistance(newItem, index)
        //     // }

        //     props.reportDistance(item, index)

        //     //const newArray = [...dist, newItem]
        // })
    }

    const mouseMarker = (index) => {
        props.markerHover(index)
    }

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: props.map_data[0].lat, lng: props.map_data[0].lng }}
            zoom={props.map_data.length > 1 ? 4 : 10}
        //onLoad={onLoad}
        //onUnmount={onUnmount}
        >
            { /* Child components, such as markers, info windows, etc. */}
            {props.map_data.length > 1 ?
                <Polyline
                    onLoad={onLoadPoly}
                    path={path}
                    options={options}
                /> :
                null}
            {props.map_data.length > 1 ?
                <DistanceMatrixService
                    options={{
                        destinations: props.coordsStr,
                        origins: props.coordsRevStr,
                        travelMode: "DRIVING",
                        unitSystem: window.google.maps.UnitSystem.IMPERIAL
                    }}
                    callback={(response) => { console.log(response); distCalc(response.rows) }}
                /> :
                null}
            {/* <></> */}
            {props.map_data.map((item, index) => {
                return (
                    <Marker key={index} position={{ lat: item.lat, lng: item.lng }} onMouseOver={(e) => mouseMarker(index)} onMouseOut={(e) => mouseMarker('')}/>
                )
            })}
        </GoogleMap>
    ) : <></>
}

export default React.memo(Map)