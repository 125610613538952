import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    FESTIVALS_LOADING,
    FESTIVALS_GET,
    FESTIVALS_FILTER,
    FESTIVALS_SORT,
    FESTIVALS_SOCKET,
    FESTIVALS_SOCKET_DELETE,
    NOtIFICATIONS
} from "./types";

export const setFestivalsLoading = () => {
    return {
        type: FESTIVALS_LOADING
    };
};

export const getFestivals = (data) => async (dispatch, getState) => {
    dispatch(setFestivalsLoading());
    await axios.post(`${process.env.REACT_APP_API_CLIENT}/festivals/`, data, tokenConfig(getState))
        .then(res => {
            console.log(res.data, "FESTIVALS LOAD")
            dispatch({
                type: FESTIVALS_GET,
                payload: res.data
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const festivalSocket = festival => (dispatch) => {
    dispatch({
        type: FESTIVALS_SOCKET,
        payload: festival
    })
}

export const deleteFestivalSocket = festival => (dispatch) => {
    dispatch({
        type: FESTIVALS_SOCKET_DELETE,
        payload: festival
    })
}