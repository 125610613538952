import React, { useEffect, useState } from "react";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')`
  width: 100%;
  border: 1px solid #727272;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    //border-color: #40a9ff;
  }

  &.focused {
    //border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
    <div {...props}>
        <span>{label}</span>
    </div>
))`
display: flex;
align-items: center;
height: 24px;
margin: 2px;
line-height: 22px;
background-color: #fafafa;
border: 1px solid #e8e8e8;
border-radius: 2px;
box-sizing: content-box;
padding: 0 4px 0 10px;
outline: 0;
overflow: hidden;
color: #333333;
font-size: 12px;

& span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

& svg {
  font-size: 12px;
  cursor: pointer;
  padding: 4px;
}
`;

const Tag2 = styled(({ label, onDelete, ...props }) => (
    <div {...props}>
        <span>{label}</span>
    </div>
    // <span>{label}</span>
))`
display: flex;
align-items: center;
height: 24px;
margin: 2px;
line-height: 22px;
background-color: #fafafa;
border: 1px solid #e8e8e8;
border-radius: 2px;
box-sizing: content-box;
padding: 0 4px 0 10px;
outline: 0;
overflow: hidden;
color: #333333;
font-size: 12px;

& span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

& svg {
  font-size: 12px;
  cursor: pointer;
  padding: 4px;
}
`;

const Listbox = styled('ul')`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-size: 12px;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

export default function CustomizedHook({ onAgentChange, agentSelect, edit, agentList }) {
    const [stateAgentSelect, setAgentSelect] = useState(agentSelect);
    const [stateEdit, setEdit] = useState(edit);

    useEffect(() => {
        setAgentSelect(agentSelect);
    }, [agentSelect])
    useEffect(() => {
        setEdit(edit);
    }, [edit])

    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        value,
        focused,
        setAnchorEl,
    } = useAutocomplete({
        id: 'customized-hook-demo',
        defaultValue: [],
        value: stateAgentSelect,
        multiple: true,
        options: agentList,
        autoComplete: 'off',
        getOptionLabel: (option) => option,
        getOptionSelected: (option, value) => option === value,
        onChange: (event, newValue) => {
            if (newValue) {
                onAgentChange(newValue)
            } else if (!newValue) {
                onAgentChange('')
            };
        }
    });

    return (
        <NoSsr>
            <div>
                <div {...getRootProps()}>
                    <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
                        {stateAgentSelect.map((option, index) => (
                            (index + 1) !== stateAgentSelect.length ?
                                <Tag2 label={option} {...getTagProps({ index })} /> :
                                <Tag label={option} {...getTagProps({ index })} />
                        ))}

                        <input disabled={edit ? false : true} {...getInputProps()} />
                    </InputWrapper>
                </div>
                {groupedOptions.length > 0 ? (
                    <Listbox {...getListboxProps()}>
                        {groupedOptions.map((option, index) => (
                            <li {...getOptionProps({ option, index })}>
                                <span>{option}</span>
                                <CheckIcon size="8" />
                            </li>
                        ))}
                    </Listbox>
                ) : null}
            </div>
        </NoSsr>
    );
}