import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FaPlusSquare, FaMinusSquare, FaTimesCircle, FaEdit, FaSave, FaTrash, FaCheckSquare, FaThumbtack, FaUnlink, FaLink, FaPlus, FaRegCopy } from 'react-icons/fa';
import { getHoldsConnect, addHolds, updateHolds, deleteHolds } from '../../actions/holdsActions';
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2';
import ClientAutocomplete from "../../components/autocompletes/client-autocomplete";
import VenueAutocomplete from "../../components/autocompletes/venue-autocomplete";
import BuyerAutocomplete from "../../components/autocompletes/buyer-autocomplete";
import moment from 'moment';
import DatePicker from "react-datepicker";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import ConfirmDialog from '../../components/global/confirm.alert';
import 'react-phone-input-2/lib/style.css'

class ShowInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date1Toggle: false,
            date2Toggle: false,
            date3Toggle: false,
            date4Toggle: false,
            date5Toggle: false,
            date6Toggle: false,
            showtimeadd: 4,
            productioncustomfn: '',
            productioncustomln: '',
            productioncustomemail: '',
            productioncustomofficephone: '',
            productioncustomcellphone: '',
            productioncustomsave: false,
            prod_roll: [],
            marketing_roll: [],
            counts_roll: [],
            holds_date: null,
            holds_status: '',
            holds_array: [],
            holds_id: '',
            holds_edit: false,
            updatedBy: [],
            buyer_id: '',
            venue_id: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.onHoldsArrayChange = this.onHoldsArrayChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        festivals: PropTypes.object.isRequired,
        holds: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        var prod_roll = [];
        var marketing_roll = [];
        var counts_roll = [];
        var holds_array = [];
        var holds_id = null;
        const id = this.props.id;
        const holds = this.props.holds.holds;
        var holdsIndex = holds.findIndex((h, i) => h.connect_id === id);
        // const holds = await this.props.getHoldsConnect(id);
        if (holdsIndex >= 0) {
            holds_array = holds[holdsIndex].holds;
            holds_id = holds[holdsIndex]._id;
        } else {
            holds_array = [];
        };
        // if (holds.type === 'error') {
        //     console.log(holds.msg);
        // } else if (holds.msg === null) {
        //     holds_array = [];
        // } else {
        //     holds_array = holds.msg.holds;
        //     holds_id = holds.msg._id;
        // }
        this.props.contacts.contacts.map(item => {
            item.tags.map((t, i) => {
                if (t.tag === this.props.venueid || t.tag === this.props.buyercompanyid) {
                    const n = {
                        _id: item._id,
                        name: item.name,
                        role: t.role
                    }
                    prod_roll.push(n)
                    marketing_roll.push(n)
                    counts_roll.push(n)
                }
            })
        });

        prod_roll = await getUnique(prod_roll, '_id');
        marketing_roll = await getUnique(marketing_roll, '_id');
        counts_roll = await getUnique(counts_roll, '_id');

        this.setState({
            buyer_id: this.props.venueid,
            venue_id: this.props.buyercompanyid,
            prod_roll: prod_roll,
            marketing_roll: marketing_roll,
            counts_roll: counts_roll,
            holds_array: holds_array,
            holds_id: holds_id
        })

    }

    async shouldComponentUpdate(nextProps, nextState) {
        const pIndex = nextProps.holds.holds.findIndex(obj => obj.connect_id === this.props.id);
        var pCheck = nextProps.holds.holds[pIndex];
        if (pIndex < 0) {
            pCheck = []
        } else {
            pCheck = pCheck.holds
        }
        console.log(nextProps, nextState);

        if (pCheck.length === nextState.holds_array.length || pCheck === nextState.holds_array) {

        } else {
            // var prod_roll = [];
            // var marketing_roll = [];
            // var counts_roll = [];
            var holds_array = [];
            var holds_id = null;
            const id = this.props.id;
            const holds = this.props.holds.holds;
            var holdsIndex = holds.findIndex((h, i) => h.connect_id === id);

            if (holdsIndex >= 0) {
                holds_array = holds[holdsIndex].holds;
                holds_id = holds[holdsIndex]._id;
            } else {
                holds_array = [];
            };

            this.setState({
                holds_array: holds_array,
                holds_id: holds_id
            })
        }

        if (nextProps.venue_id !== this.props.venue_id || nextProps.buyer_id !== this.props.buyer_id) {
            var prod_roll = [];
            var marketing_roll = [];
            var counts_roll = [];

            this.props.contacts.contacts.map(item => {
                item.tags.map((t, i) => {
                    if (t.tag === nextProps.venueid || t.tag === nextProps.buyercompanyid) {
                        const n = {
                            _id: item._id,
                            name: item.name,
                            role: t.role
                        }
                        prod_roll.push(n)
                        marketing_roll.push(n)
                        counts_roll.push(n)
                    }
                })
            });

            prod_roll = await getUnique(prod_roll, '_id');
            marketing_roll = await getUnique(marketing_roll, '_id');
            counts_roll = await getUnique(counts_roll, '_id');

            this.setState({
                prod_roll: prod_roll,
                marketing_roll: marketing_roll,
                counts_roll: counts_roll,
                // holds_array: holds_array,
                // holds_id: holds_id
            })
        } else {

        }
        // if (nextProps !== this.props) {

        // }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    updateShowtime = (props) => {
        this.props.updateShowtime(props)
    }

    deleteShowtime = (props) => {
        this.props.deleteShowtime(props)
    }

    updateDate = (props) => {
        this.props.updateDate(props)
    }

    deleteDate = (props) => {
        this.props.deleteDate(props)
    }

    handleHoldsDateChange = date => {
        this.setState({
            holds_date: date
        });
    };

    addHolds = () => {
        if (!this.state.holds_date || !this.state.holds_status) {
            alert('Please add a holds status and date.')
        } else {
            const id = this.props.id;
            const holdsCheck = this.props.holds.holds;
            var holdsIndex = holdsCheck.findIndex((h, i) => h.connect_id === id);
            var newDate = moment(this.state.holds_date);

            const holds = {
                date: new Date(newDate),
                hold: this.state.holds_status,
                note: this.state.holds_note,
            };
            const user = {
                user: this.props.auth.user.tag,
                date: new Date()
            };
            const holdsArray = [...this.state.holds_array, holds];
            if (holdsIndex >= 0) {
                const id = this.state.holds_id;
                const entry = {
                    holds: holdsArray,
                    client: this.props.artistbatch.id,
                    venue: this.props.venuebatch.id,
                    connect_id: this.props.id,
                    updatedBy: [user, ...this.state.updatedBy]
                }
                this.props.updateHolds(entry, id);
            } else {
                const entry = {
                    holds: holdsArray,
                    client: this.props.artistbatch.id,
                    venue: this.props.venuebatch.id,
                    connect_id: this.props.id,
                    enteredBy: user
                }
                this.props.addHolds(entry);
            }
            this.setState({
                // holds_array: holdsArray,
                holds_status: '',
                holds_date: new Date(moment(newDate).add(1, 'days'))
            })
        }
    }

    updateHoldsArray = () => {
        const user = {
            user: this.props.auth.user.tag,
            date: new Date()
        };
        const holdsArray = this.state.holds_array;
        const id = this.props.id;
        const holdsCheck = this.props.holds.holds;
        var holdsIndex = holdsCheck.findIndex((h, i) => h.connect_id === id);
        if (holdsIndex >= 0) {
            const id = this.state.holds_id;
            const entry = {
                holds: holdsArray,
                client: this.props.artistbatch.id,
                venue: this.props.venuebatch.id,
                connect_id: this.props.id,
                updatedBy: [user, ...this.state.updatedBy]
            }
            this.props.updateHolds(entry, id);
        } else {
            const entry = {
                holds: holdsArray,
                client: this.props.artistbatch.id,
                venue: this.props.venuebatch.id,
                connect_id: this.props.id,
                enteredBy: user
            }
            this.props.addHolds(entry);
        }
        this.setState({
            holds_edit: false
        })
    }

    deleteHoldsArray = (index) => {
        const holdsArray = this.state.holds_array.filter((a, b) => b !== index);
        this.setState({
            holds_array: holdsArray
        })
    }

    cancelHolds = () => {
        const id = this.props.id;
        var holds_array = [];
        var holds_id = null;
        var holds = this.props.holds.holds;
        var holdsIndex = holds.findIndex((h, i) => h.connect_id === id);
        if (holdsIndex >= 0) {
            holds_array = holds[holdsIndex].holds;
            holds_id = holds[holdsIndex]._id;
        } else {
            holds_array = [];
        };
        this.setState({
            holds_array: holds_array,
            holds_id: holds_id,
            holds_edit: false
        });
    }

    toggle = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    }

    onHoldsArrayChange(e) {
        var index = e.target.name;
        var val = e.target.value;
        var holds_array = this.state.holds_array;
        holds_array[index].hold = val;
        this.setState({
            holds_array: holds_array
        });
    }

    onHoldsArrayDateChange = (date, index) => {
        var holds_array = this.state.holds_array;
        holds_array[index].date = date;
        this.setState({
            holds_array: holds_array
        });
    }

    handleHoldsDialog = async () => {
        var msg = "Are you sure you'd like to permanently delete all of the holds for this deal?"
        const func = async () => {
            if (this.state.holds_id) {
                const id = this.state.holds_id;
                await this.props.deleteHolds(id);
            }
        };
        ConfirmDialog(msg, func);
    }

    render() {

        // var firstname = [];
        // var lastname = [];
        // var id = [];
        var conArray = this.props.contacts.contacts;

        var buyername = "";
        var buyerEmail = "";
        var buyerIndex = conArray.findIndex(obj => obj._id === this.props.buyername);
        if (buyerIndex >= 0) {
            var array = conArray[buyerIndex];
            buyername = array.name.first + " " + array.name.last;
            buyerEmail = array.contact.email
        };

        var signatory = "";
        var signatoryEmail = ""
        var sigIndex = conArray.findIndex(obj => obj._id === this.props.signatory);
        if (sigIndex >= 0) {
            var array = conArray[sigIndex];
            signatory = array.name.first + " " + array.name.last;
            signatoryEmail = array.contact.email;
        };

        // var production_contact = "";
        // var prodIndex = conArray.findIndex(obj => obj._id === this.props.production_contact);
        // if (prodIndex >= 0) {
        //     var array = conArray[prodIndex];
        //     production_contact = array.name.first + " " + array.name.last;
        // };

        // var marketing_contact = "";
        // var markIndex = conArray.findIndex(obj => obj._id === this.props.marketing_contact);
        // if (markIndex >= 0) {
        //     var array = conArray[markIndex];
        //     marketing_contact = array.name.first + " " + array.name.last;
        // };

        // var counts_contact = "";
        // var counIndex = conArray.findIndex(obj => obj._id === this.props.counts_contact);
        // if (counIndex >= 0) {
        //     var array = conArray[counIndex];
        //     counts_contact = array.name.first + " " + array.name.last;
        // };

        var add_contacts = [];
        this.props.add_contacts.map(c => {
            if (c.contact_id === 'Custom') {
                if (c.custom) {
                    var nc = {
                        name: c.custom.name,
                        role: c.role,
                        contact_id: c.contact_id,
                        contact: {
                            email: c.custom.email,
                            office: c.custom.office,
                            cell: c.custom.cell
                        }
                    }
                    add_contacts.push(nc)
                } else {
                    var nc = {
                        name: '',
                        role: c.role,
                        contact_id: c.contact_id,
                        contact: { email: '', office: '', cell: '' }
                    }
                    add_contacts.push(nc)
                }
            } else {
                var contIndex = conArray.findIndex(obj => obj._id === c.contact_id);
                if (contIndex >= 0) {
                    var nc = {
                        name: conArray[contIndex].name,
                        role: c.role,
                        contact_id: c.contact_id,
                        contact: conArray[contIndex].contact
                    }
                    // add_contacts = [...add_contacts, nc];
                    add_contacts.push(nc)
                };
            }
            // this.props.contacts.contacts.map((item, index) => {
            //     if (c.contact_id === item._id) {
            //         var nc = {
            //             name: item.name,
            //             role: c.role,
            //             contact_id: c.contact_id
            //         }
            //         add_contacts = [...add_contacts, nc];
            //     };
            // })
        });

        // this.props.contacts.contacts.map((item, index) => {
        //     // item.tags.map((t, j) => {
        //     //     if (t.tag === this.props.buyercompanyid) {
        //     //         firstname = item.name.first;
        //     //         lastname = item.name.last;
        //     //         id = item._id;
        //     //     }
        //     // });
        //     // if (this.props.buyername === item._id) {
        //     //     buyername = item.name.first + " " + item.name.last
        //     // };
        //     // if (this.props.signatory === item._id) {
        //     //     signatory = item.name.first + " " + item.name.last
        //     // };
        //     // if (this.props.production_contact === item._id) {
        //     //     production_contact = item.name.first + " " + item.name.last
        //     // };
        //     // if (this.props.marketing_contact === item._id) {
        //     //     marketing_contact = item.name.first + " " + item.name.last
        //     // };
        //     // if (this.props.counts_contact === item._id) {
        //     //     counts_contact = item.name.first + " " + item.name.last
        //     // };
        //     // this.props.add_contacts.map(c => {
        //     //     if (c.contact_id === item._id) {
        //     //         var nc = {
        //     //             name: item.name,
        //     //             role: c.role,
        //     //             contact_id: c.contact_id
        //     //         }
        //     //         add_contacts = [...add_contacts, nc];
        //     //     };
        //     // });
        // });

        const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        var prod_custom_cell_digit = this.props.production_contact_custom.cell_phone.search(/\d/);

        const show_index = this.props.show_info.findIndex(obj => obj._id === this.props.show_select);
        var newShowInfo = this.props.show_info[show_index]

        const merch_index = this.props.merch_array.findIndex(obj => obj._id === this.props.show_select);
        var merch_data = this.props.merch_array[merch_index]

        console.log("BUYER BATCH", this.props.buyerbatch)

        return (
            <div>
                {this.props.id ?
                    <p style={{ fontSize: 9, marginLeft: 5, marginTop: 2.5 }}><b>Connect ID:</b> {this.props.id}  <FaRegCopy style={{ cursor: 'pointer' }} size="9" color="#333" onClick={() => { navigator.clipboard.writeText(this.props.id) }} /></p> :
                    <p style={{ fontSize: 9, marginLeft: 5, marginTop: 2.5 }}> </p>}
                <div style={{ display: 'flex', height: 'auto', width: '100%', justifyContent: 'center', paddingBottom: 50 }}>
                    <form style={{ width: '30%', height: '100%', marginTop: 10, marginLeft: '2%' }}>
                        <div style={{ width: '85%' }} className="form-group">
                            <label style={{ width: '100%', height: 16, fontSize: 14 }}>Artist: </label>
                            {this.props.edit && this.props.render_type !== 'render' ?
                                <ClientAutocomplete clientList={this.props.clients.clients} onChange={this.props.onClientChange} clientSelect={this.props.artistbatch} toggleNewModal={this.props.toggleNewModal} /> :
                                <input style={{ width: '100%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12 }} disabled={true} type="text" value={this.props.artist} />}
                        </div>
                        <div style={{ width: '85%' }} className="form-group">
                            <label style={{ width: '100%', height: 16, fontSize: 14 }}>Status: </label>
                            {this.props.edit ?
                                <select style={{ width: '50%', fontSize: 12 }} className="form-control-sm" name="status" value={this.props.status} onChange={this.props.onChange}>
                                    <option value="Pending">Pending</option>
                                    <option value="Confirmed">Confirmed</option>
                                    <option value="Cancelled">Cancelled</option>
                                </select> :
                                <input style={{ width: '50%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12 }} disabled={true} type="text" value={this.props.status} />}
                        </div>
                        <div style={{ width: '85%' }} className="form-group">
                            <div style={{ width: '100%' }}>
                                <label style={{ width: '100%', height: 16, fontSize: 14 }}>Date(s): </label>
                            </div>
                            {this.props.edit ?
                                <div style={{ display: 'flex', width: '100%', height: 28 }}>
                                    <div style={{ width: '85%' }}>
                                        <DatePicker
                                            className="react-datepicker"
                                            disabled={this.props.edit ? false : true}
                                            style={{ fontSize: 12 }}
                                            value={this.props.date}
                                            selected={this.props.date}
                                            onChange={this.props.onDateChange}
                                            dateFormat="EEE. MMMM d, yyyy"
                                            todayButton="Today"
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>
                                    <div style={{ width: '15%' }}>
                                        <FaPlusSquare style={{ cursor: 'pointer', cursor: 'pointer', marginTop: 3 }} size="24" color="rgb(1, 22, 48)" onClick={this.props.addDate} />
                                    </div>
                                </div> : null}
                            <div style={{ marginTop: this.props.edit ? 15 : 0, minHeight: 50, maxHeight: 'auto', width: '100%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#b3b9c1' }} tabIndex={this.props.edit ? "0" : null} onBlur={this.props.edit ? (e) => this.props.handleClickOutside(e, 'dates_edit') : null}>
                                {this.props.show_info.map((item, index) => {
                                    var firstItem = '';
                                    var lastItem = '';

                                    if (item.show_times[0]) {
                                        var ft = item.show_times[0].time;

                                        var FI = +ft.substr(0, 2);
                                        var f = (FI % 12) || 12;
                                        f = (f < 10) ? ("0" + f) : f;  // leading 0 at the left for 1 digit hours
                                        var ampmF = FI < 12 ? " AM" : " PM";
                                        firstItem = f + ft.substr(2, 3) + ampmF;

                                        var lt = item.show_times[item.show_times.length - 1].time;
                                        var LI = +lt.substr(0, 2);
                                        var l = (LI % 12) || 12;
                                        l = (l < 10) ? ("0" + l) : l;  // leading 0 at the left for 1 digit hours
                                        var ampmL = LI < 12 ? " AM" : " PM";
                                        lastItem = l + lt.substr(2, 3) + ampmL;
                                    }
                                    const d = new Date(item.date);
                                    const day = days[d.getDay()];
                                    const mon = monthNames[d.getMonth()];
                                    const newDate = `${day}. ${mon} ${d.getDate()}, ${d.getFullYear()}`;
                                    return (
                                        <div key={index} style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%', height: 'auto' }}>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr style={{ backgroundColor: this.props.show_select === item._id ? 'rgb(1, 22, 48)' : null, height: 75 }}>
                                                        {this.props.dates_edit[item._id] === 'date' ?
                                                            <td style={{ width: '59%' }}><div style={{ width: '100%', height: 50, color: this.props.show_select === item._id ? '#cdcdcd' : '#000', display: 'flex' }}>
                                                                <div style={{ width: '20%' }}>
                                                                    <Checkbox
                                                                        icon={<CircleUnchecked style={{ color: '#cdcdcd' }} fontSize="small" />}
                                                                        checkedIcon={<CircleCheckedFilled style={{ color: '#cdcdcd' }} fontSize="small" />}
                                                                        color="default"
                                                                        checked={this.props.show_select === item._id ? true : false}
                                                                        onChange={() => this.props.toggleDateSelect(item._id)}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '80%' }}>
                                                                    <DatePicker
                                                                        className="date-input-field"
                                                                        selected={d}
                                                                        onChange={this.props.handleArrayDateChange.bind(this, item._id, 'date', 'datesarray')}
                                                                        dateFormat="EEE. MMMM d, yyyy"
                                                                    />
                                                                </div>
                                                            </div></td> :
                                                            <td style={{ width: '59%' }}><div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '20%' }}>
                                                                    <Checkbox
                                                                        icon={<CircleUnchecked style={{ color: '#cdcdcd' }} fontSize="small" />}
                                                                        checkedIcon={<CircleCheckedFilled style={{ color: '#cdcdcd' }} fontSize="small" />}
                                                                        color="default"
                                                                        checked={this.props.show_select === item._id ? true : false}
                                                                        onChange={() => this.props.toggleDateSelect(item._id)}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '80%' }} onClick={this.props.edit ? () => this.props.updateDate(item._id, 'date') : null}>
                                                                    <p style={{ color: this.props.show_select === item._id ? '#cdcdcd' : '#000', fontSize: 12, width: '100%', paddingTop: 15, paddingLeft: 15 }}>{newDate}</p>
                                                                </div>
                                                            </div></td>}
                                                        <td style={{ width: '30%' }}><p style={{ color: this.props.show_select === item._id ? '#cdcdcd' : '#000', fontSize: 12, width: '100%', paddingTop: 20, paddingLeft: 5, fontStyle: 'italic' }}>{firstItem} - {lastItem}</p></td>
                                                        {this.props.edit ?
                                                            <td style={{ width: '10%' }}><div style={{ display: 'flex', width: '100%', alignItems: 'center' }}><FaTrash style={{ cursor: 'pointer' }} size="15" color='#cdcdcd' onClick={() => this.deleteDate(item._id)} /></div></td> :
                                                            <td style={{ width: '10%' }}> </td>}
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div style={{ width: '85%' }} className="form-group">
                            <label style={{ width: '100%', height: 16, fontSize: 14 }}>Venue: </label>
                            {this.props.edit ?
                                <VenueAutocomplete venueList={this.props.items.items} festivalList={this.props.festivals.items} onChange={this.props.onVenueChange} venueSelect={this.props.venuebatch} toggleNewModal={this.props.toggleNewModal} /> :
                                <input style={{ width: '100%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12 }} disabled={true} type="text" value={this.props.venue} />}
                            {this.props.venue ?
                                <p style={{ fontSize: 10, fontStyle: 'italic', color: '#cdcdcd', width: this.props.edit ? '95%' : '100%', borderLeft: '.5px solid #000', borderRight: '.5px solid #000', borderBottom: '.5px solid #000', padding: 5, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, backgroundColor: 'rgb(1, 22, 48)' }}>{this.props.venuebatch.address.address ? this.props.venuebatch.address.address + ', ' : ''}{this.props.venuebatch.address.city ? this.props.venuebatch.address.city + ', ' : ''}{this.props.venuebatch.address.state ? this.props.venuebatch.address.state : ''}</p> : null}
                        </div>
                        <div style={{ width: '85%' }} className="form-group">
                            <label style={{ width: '100%', height: 16, fontSize: 14 }}>Buyer Company: </label>
                            {this.props.edit ?
                                <BuyerAutocomplete buyerList={this.props.buyers.buyers} onChange={this.props.onBuyerChange} buyerSelect={this.props.buyerbatch} toggleNewModal={this.props.toggleNewModal} /> :
                                <input style={{ width: '100%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12 }} disabled={true} type="text" value={this.props.buyercompany} />}
                            {this.props.buyercompany ?
                                <p style={{ fontSize: 10, fontStyle: 'italic', color: '#cdcdcd', width: this.props.edit ? '95%' : '100%', borderLeft: '.5px solid #000', borderRight: '.5px solid #000', borderBottom: '.5px solid #000', padding: 5, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, backgroundColor: 'rgb(1, 22, 48)' }}>{this.props.buyerbatch.address.address ? this.props.buyerbatch.address.address + ', ' : ''}{this.props.buyerbatch.address.city ? this.props.buyerbatch.address.city + ', ' : ''}{this.props.buyerbatch.address.state ? this.props.buyerbatch.address.state : ''}</p> : null}
                        </div>
                        <div style={{ width: '85%' }} className="form-group">
                            <label style={{ width: '100%', height: 16, fontSize: 14 }}>Buyer Name: </label>
                            {this.props.edit ?
                                <select style={{ width: '100%', fontSize: 12 }} className="form-control-sm" disabled={this.props.buyercompany ? false : true} value={this.props.buyername} name="buyername" onChange={this.props.onChange}>
                                    <option value=""> </option>
                                    {this.state.prod_roll.map((item, index) => {
                                        // var render = [];
                                        // item.tags.map((t, i) => {
                                        //     if (t.tag === this.props.buyercompanyid) {
                                        //         render.push(
                                        //             <option key={i._id} value={item._id}>{item.name.first} {item.name.last} ({t.role})</option>
                                        //         )
                                        //     }
                                        // })
                                        return (
                                            <option key={index} value={item._id}>{item.name.first} {item.name.last}</option>
                                        )
                                    })}
                                </select> :
                                <div>
                                    <input style={{ width: '100%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12 }} disabled={true} type="text" value={buyername} />
                                </div>}
                            {this.props.buyername ?
                                <p style={{ fontSize: 10, fontStyle: 'italic', color: '#cdcdcd', width: this.props.edit ? '95%' : '100%', borderLeft: '.5px solid #000', borderRight: '.5px solid #000', borderBottom: '.5px solid #000', padding: 5, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, backgroundColor: 'rgb(1, 22, 48)', cursor: 'pointer' }} onClick={() =>  navigator.clipboard.writeText(buyerEmail)}>{buyerEmail}</p> : null}
                        </div>
                        <div style={{ width: '85%' }} className="form-group">
                            <label style={{ width: '100%', height: 16, fontSize: 14 }}>Signatory: </label>
                            {this.props.edit ?
                                <select style={{ width: '100%', fontSize: 12 }} className="form-control-sm" disabled={this.props.buyercompany ? false : true} value={this.props.signatory} name="signatory" onChange={this.props.onChange}>
                                    <option value=""> </option>
                                    {this.state.prod_roll.map((item, index) => {
                                        // var render = [];
                                        // item.tags.map((t, i) => {
                                        //     if (t.tag === this.props.buyercompanyid) {
                                        //         render.push(
                                        //             <option key={i._id} value={item._id}>{item.name.first} {item.name.last} ({t.role})</option>
                                        //         )
                                        //     }
                                        // })
                                        return (
                                            <option key={index} value={item._id}>{item.name.first} {item.name.last}</option>
                                        )
                                    })}
                                </select> :
                                <div>
                                    <input style={{ width: '100%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12 }} disabled={true} type="text" value={signatory} />
                                </div>}
                            {this.props.signatory ?
                                <p style={{ fontSize: 10, fontStyle: 'italic', color: '#cdcdcd', width: this.props.edit ? '95%' : '100%', borderLeft: '.5px solid #000', borderRight: '.5px solid #000', borderBottom: '.5px solid #000', padding: 5, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, backgroundColor: 'rgb(1, 22, 48)', cursor: 'pointer' }} onClick={() =>  navigator.clipboard.writeText(signatoryEmail)}>{signatoryEmail}</p> : null}
                        </div>
                        {/* <div style={{ width: '85%' }} className="form-group">
                        <label style={{ width: '100%', height: 16, fontSize: 14 }}>Production Contact: </label>
                        {this.props.edit ?
                            <select style={{ width: '100%', fontSize: 12, height: 28, borderRadius: 3 }} className="form-control-sm" disabled={this.props.venue !== '' ? false : true} value={this.props.production_contact} name="production_contact" onChange={this.props.onChange}>
                                <option value=""> </option>
                                {this.state.prod_roll.map((item, index) => {
                                    // var render = [];
                                    // item.tags.map((t, i) => {
                                    //     if (t.tag === this.props.venueid || t.tag === this.props.buyercompanyid) {
                                    //         render.push(
                                    //             <option key={i._id} value={item._id}>{item.name.first} {item.name.last} ({t.role})</option>
                                    //         )
                                    //     }
                                    // })
                                    return (
                                        <option key={index} value={item._id}>{item.name.first} {item.name.last}</option>
                                    )
                                })}
                                <option value="Custom">Custom</option>
                            </select> :
                            <input style={{ width: '100%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12 }} disabled={true} type="text" value={production_contact} />}
                    </div>
                    {this.props.production_contact === "Custom" ?
                        <div>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <div className="form-group">
                                    <input
                                        style={{ width: '45%', fontSize: 12, border: '.4px solid #000' }}
                                        className="form-control-sm"
                                        placeholder="First Name"
                                        value={this.props.production_contact_custom.first_name}
                                        name="first_name"
                                        onChange={this.props.handleProdCustomChange}
                                    />
                                    <input
                                        style={{ width: '45%', fontSize: 12, marginLeft: 5, border: '.4px solid #000' }}
                                        className="form-control-sm"
                                        placeholder="Last Name"
                                        value={this.props.production_contact_custom.last_name}
                                        name="last_name"
                                        onChange={this.props.handleProdCustomChange}
                                    />
                                </div>
                            </div>
                            {!this.props.edit && !this.props.production_contact_custom.email ?
                                null :
                                <div style={{ width: '85%', display: 'flex', alignItems: 'center' }} className="form-group">
                                    <label style={{ width: '15%', fontSize: 12, paddingTop: 10 }}>Email:</label>
                                    <input
                                        style={{ width: '75%', fontSize: 12, border: '.4px solid #000' }}
                                        className="form-control-sm"
                                        placeholder="Email"
                                        value={this.props.production_contact_custom.email}
                                        name="email"
                                        onChange={this.props.handleProdCustomChange}
                                    />
                                </div>}
                            {!this.props.edit && !this.props.production_contact_custom.office_phone ?
                                null :
                                <div style={{ width: '85%', display: 'flex', alignItems: 'center' }} className="form-group">
                                    <label style={{ width: '15%', fontSize: 12, paddingTop: 10 }}>Office:</label>
                                    <PhoneInput
                                        containerClass='phone-input'
                                        inputStyle={{ width: '100%', height: 28, borderRadius: 5 }}
                                        country={'us'}
                                        placeholder={''}
                                        disabled={!this.props.edit ? true : false}
                                        renderStringAsFlag={!this.props.edit ? true : false}
                                        value={this.props.production_contact_custom.office_phone}
                                        onChange={officephone => this.props.handleProdCustomChange({ target: { name: "office_phone", value: officephone } })} />
                                </div>}
                            {!this.props.edit && !this.props.production_contact_custom.cell_phone ?
                                null :
                                <div style={{ width: '85%', display: 'flex', alignItems: 'center' }} className="form-group">
                                    <label style={{ width: '15%', fontSize: 12, paddingTop: 10 }}>Cell:</label>
                                    <PhoneInput
                                        containerClass='phone-input'
                                        inputStyle={{ width: '100%', height: 28, borderRadius: 5 }}
                                        country={'us'}
                                        placeholder={''}
                                        disabled={!this.props.edit ? true : false}
                                        renderStringAsFlag={!this.props.edit ? true : false}
                                        value={this.props.production_contact_custom.cell_phone}
                                        onChange={cellphone => this.props.handleProdCustomChange({ target: { name: "cell_phone", value: cellphone } })} />
                                </div>}
                        </div> : null}
                    <div style={{ width: '85%' }} className="form-group">
                        <label style={{ width: '100%', height: 16, fontSize: 14 }}>Marketing Contact: </label>
                        {this.props.edit ?
                            <select style={{ width: '100%', fontSize: 12, height: 28, borderRadius: 3 }} className="form-control-sm" disabled={this.props.venue !== '' ? false : true} value={this.props.marketing_contact} name="marketing_contact" onChange={this.props.onChange}>
                                <option value=""> </option>
                                {this.state.marketing_roll.map((item, index) => {
                                    // var render = [];
                                    // item.tags.map((t, i) => {
                                    //     if (t.tag === this.props.venueid || t.tag === this.props.buyercompanyid) {
                                    //         render.push(
                                    //             <option key={i._id} value={item._id}>{item.name.first} {item.name.last} ({t.role})</option>
                                    //         )
                                    //     }
                                    // })
                                    return (
                                        <option key={index} value={item._id}>{item.name.first} {item.name.last}</option>
                                    )
                                })}
                                <option value="Custom">Custom</option>
                            </select> :
                            <input style={{ width: '100%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12 }} disabled={true} type="text" value={marketing_contact} />}
                    </div>
                    {this.props.marketing_contact === "Custom" ?
                        <div>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <div className="form-group">
                                    <input
                                        style={{ width: '45%', fontSize: 12, border: '.4px solid #000' }}
                                        className="form-control-sm"
                                        placeholder="First Name"
                                        value={this.props.marketing_contact_custom.first_name}
                                        name="first_name"
                                        onChange={this.props.handleMktCustomChange}
                                    />
                                    <input
                                        style={{ width: '45%', fontSize: 12, marginLeft: 5, border: '.4px solid #000' }}
                                        className="form-control-sm"
                                        placeholder="Last Name"
                                        value={this.props.marketing_contact_custom.last_name}
                                        name="last_name"
                                        onChange={this.props.handleMktCustomChange}
                                    />
                                </div>
                            </div>
                            {!this.props.edit && !this.props.marketing_contact_custom.email ?
                                null :
                                <div style={{ width: '85%', display: 'flex', alignItems: 'center' }} className="form-group">
                                    <label style={{ width: '15%', fontSize: 12, paddingTop: 10 }}>Email:</label>
                                    <input
                                        style={{ width: '75%', fontSize: 12, border: '.4px solid #000' }}
                                        className="form-control-sm"
                                        placeholder="Email"
                                        value={this.props.marketing_contact_custom.email}
                                        name="email"
                                        onChange={this.props.handleMktCustomChange}
                                    />
                                </div>}
                            {!this.props.edit && !this.props.marketing_contact_custom.office_phone ?
                                null :
                                <div style={{ width: '85%', display: 'flex', alignItems: 'center' }} className="form-group">
                                    <label style={{ width: '15%', fontSize: 12, paddingTop: 10 }}>Office:</label>
                                    <PhoneInput
                                        containerClass='phone-input'
                                        inputStyle={{ width: '100%', height: 28, borderRadius: 5 }}
                                        country={'us'}
                                        placeholder={''}
                                        disabled={!this.props.edit ? true : false}
                                        renderStringAsFlag={!this.props.edit ? true : false}
                                        value={this.props.marketing_contact_custom.office_phone}
                                        onChange={officephone => this.props.handleMktCustomChange({ target: { name: "office_phone", value: officephone } })} />
                                </div>}
                            {!this.props.edit && !this.props.marketing_contact_custom.cell_phone ?
                                null :
                                <div style={{ width: '85%', display: 'flex', alignItems: 'center' }} className="form-group">
                                    <label style={{ width: '15%', fontSize: 12, paddingTop: 10 }}>Cell:</label>
                                    <PhoneInput
                                        containerClass='phone-input'
                                        inputStyle={{ width: '100%', height: 28, borderRadius: 5 }}
                                        country={'us'}
                                        placeholder={''}
                                        disabled={!this.props.edit ? true : false}
                                        renderStringAsFlag={!this.props.edit ? true : false}
                                        value={this.props.marketing_contact_custom.cell_phone}
                                        onChange={cellphone => this.props.handleMktCustomChange({ target: { name: "cell_phone", value: cellphone } })} />
                                </div>}
                        </div> : null}
                    <div style={{ width: '85%' }} className="form-group">
                        <label style={{ width: '100%', height: 16, fontSize: 14 }}>Ticket Count Contact: </label>
                        {this.props.edit ?
                            <select style={{ width: '100%', fontSize: 12, height: 28, borderRadius: 3 }} className="form-control-sm" disabled={this.props.venue !== '' ? false : true} value={this.props.counts_contact} name="counts_contact" onChange={this.props.onChange}>
                                <option value=""> </option>
                                {this.state.counts_roll.map((item, index) => {
                                    // var render = [];
                                    // item.tags.map((t, i) => {
                                    //     if (t.tag === this.props.venueid || t.tag === this.props.buyercompanyid) {
                                    //         render.push(
                                    //             <option key={i._id} value={item._id}>{item.name.first} {item.name.last} ({t.role})</option>
                                    //         )
                                    //     }
                                    // })
                                    return (
                                        <option key={index} value={item._id}>{item.name.first} {item.name.last}</option>
                                    )
                                })}
                                <option value="Custom">Custom</option>
                            </select> :
                            <input style={{ width: '100%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12 }} disabled={true} type="text" value={counts_contact} />}
                    </div> */}
                        <div style={{ width: '85%' }} className="form-group">
                            <label style={{ width: '100%', height: 16, fontSize: 14 }}>Additional Contacts: </label>
                            {this.props.edit ?
                                <select style={{ width: '100%', fontSize: 12, height: 28, borderRadius: 3, color: this.props.add_contact_id ? '#000' : '#cdcdcd' }} className="form-control-sm" disabled={this.props.venue !== '' ? false : true} value={this.props.add_contact_id} name="add_contact_id" onChange={this.props.onChange}>
                                    <option value="">Select Contact</option>
                                    {this.state.counts_roll.map((item, index) => {
                                        return (
                                            <option key={index} value={item._id}>{item.name.first} {item.name.last} ({item.role})</option>
                                        )
                                    })}
                                    <option value="Custom">Custom</option>
                                </select> : null}
                            {this.props.add_contact_id === "Custom" ?
                                <div>
                                    <div style={{ width: '100%', display: 'flex', marginTop: 5 }}>
                                        <input
                                            style={{ width: '45%', fontSize: 12, border: '.4px solid #000' }}
                                            className="form-control-sm"
                                            placeholder="First Name"
                                            value={this.props.add_contact_custom.first_name}
                                            name="first_name"
                                            onChange={this.props.handleCtsCustomChange}
                                        />
                                        <input
                                            style={{ width: '45%', fontSize: 12, marginLeft: 5, border: '.4px solid #000' }}
                                            className="form-control-sm"
                                            placeholder="Last Name"
                                            value={this.props.add_contact_custom.last_name}
                                            name="last_name"
                                            onChange={this.props.handleCtsCustomChange}
                                        />
                                    </div>
                                    <div style={{ width: '85%', display: 'flex', alignItems: 'center' }}>
                                        <label style={{ width: '15%', fontSize: 12, paddingTop: 10 }}>Email:</label>
                                        <input
                                            style={{ width: '75%', fontSize: 12, border: '.4px solid #000' }}
                                            className="form-control-sm"
                                            placeholder="Email"
                                            value={this.props.add_contact_custom.email}
                                            name="email"
                                            onChange={this.props.handleCtsCustomChange}
                                        />
                                    </div>
                                    <div style={{ width: '85%', display: 'flex', alignItems: 'center' }}>
                                        <label style={{ width: '15%', fontSize: 12, paddingTop: 10 }}>Office:</label>
                                        <PhoneInput
                                            containerClass='phone-input'
                                            inputStyle={{ width: '100%', height: 28, borderRadius: 5 }}
                                            country={'us'}
                                            placeholder={''}
                                            disabled={!this.props.edit ? true : false}
                                            renderStringAsFlag={!this.props.edit ? true : false}
                                            value={this.props.add_contact_custom.office_phone}
                                            onChange={officephone => this.props.handleCtsCustomChange({ target: { name: "office_phone", value: officephone } })} />
                                    </div>
                                    <div style={{ width: '85%', display: 'flex', alignItems: 'center' }}>
                                        <label style={{ width: '15%', fontSize: 12, paddingTop: 10 }}>Cell:</label>
                                        <PhoneInput
                                            containerClass='phone-input'
                                            inputStyle={{ width: '100%', height: 28, borderRadius: 5 }}
                                            country={'us'}
                                            placeholder={''}
                                            disabled={!this.props.edit ? true : false}
                                            renderStringAsFlag={!this.props.edit ? true : false}
                                            value={this.props.add_contact_custom.cell_phone}
                                            onChange={cellphone => this.props.handleCtsCustomChange({ target: { name: "cell_phone", value: cellphone } })} />
                                    </div>
                                </div> : null}
                            {this.props.edit ?
                                <span style={{ width: '100%', height: 28, display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                                    <select style={{ width: '85%', fontSize: 12, height: '100%', borderRadius: 3, color: this.props.add_contact_role ? '#000' : '#cdcdcd' }} className="form-control-sm" disabled={this.props.venue !== '' ? false : true} value={this.props.add_contact_role} name="add_contact_role" onChange={this.props.onChange}>
                                        <option value="">Select Role</option>
                                        <option value={"Assistant Talent Buyer"}>Assistant Talent Buyer</option>
                                        <option value={"Contract Admin"}>Contract Admin</option>
                                        <option value={"Marketing Assistant"}>Marketing Assistant</option>
                                        <option value={"Marketing Manager"}>Marketing Manager</option>
                                        <option value={"Production Assistant"}>Production Assistant</option>
                                        <option value={"Production Manager"}>Production Manager</option>
                                        <option value={"Ticket Count Contact"}>Ticket Count Contact</option>
                                        <option value="Custom">Custom</option>
                                    </select>
                                    <span style={{ width: '15%', height: '100%' }}>
                                        <FaPlusSquare style={{ cursor: 'pointer', marginLeft: 10 }} size="20" color="rgb(1, 22, 48)" onClick={this.props.addContact} />
                                    </span>
                                </span> : null}
                            <div style={{ width: '100%', minHeight: 80, borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#b3b9c1', marginTop: 10 }}>
                                {add_contacts.map((item, index) => {
                                    console.log(item)
                                    return (
                                        <div style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%' }} key={index}>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ color: '#000', fontSize: 12, width: '85%' }}>
                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: 40, justifyContent: 'center' }}>
                                                                <span style={{ width: '100%', display: 'flex', paddingLeft: 5, alignItems: 'center', paddingTop: 10 }}>{item.name.first} {item.name.last} <b style={{ fontSize: 10, paddingLeft: 5 }}>({item.role})</b></span>
                                                                <p style={{ fontStyle: 'italic', fontSize: 9, paddingLeft: 15, cursor: 'pointer' }} onClick={() =>  navigator.clipboard.writeText(item.contact.email)}>{item.contact.email}</p>
                                                            </div>
                                                        </td>
                                                        {this.props.edit ?
                                                            <td style={{ color: '#000', fontSize: 12, width: '15%' }}><FaTrash style={{ marginLeft: 10, cursor: 'pointer' }} size="15" color='#cdcdcd' onClick={() => this.props.deleteContact(index)} /></td> : null}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </form>
                    <form style={{ width: '60%', height: '100%', marginTop: 10 }} >
                        <div style={{ width: '100%', height: 'auto', backgroundColor: 'rgb(1, 22, 48)', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', borderRight: '1px solid rgb(1, 22, 48)', borderRadius: 0, display: 'flex' }} className="form-control">
                            <div style={{ width: '50%' }}>
                                <select style={{ width: '50%', fontSize: 12, color: '#fff', backgroundColor: 'rgb(1, 22, 48)', height: 28 }} name="show_select" value={this.props.show_select} onChange={(e) => this.props.toggleDateSelect(e.target.value)}>
                                    {this.props.show_info.map((item, index) => {

                                        var firstItem = '';
                                        var lastItem = '';

                                        if (item.show_times[0]) {
                                            var ft = item.show_times[0].time;

                                            var FI = +ft.substr(0, 2);
                                            var f = (FI % 12) || 12;
                                            f = (f < 10) ? ("0" + f) : f;  // leading 0 at the left for 1 digit hours
                                            var ampmF = FI < 12 ? " AM" : " PM";
                                            firstItem = f + ft.substr(2, 3) + ampmF;

                                            var lt = item.show_times[item.show_times.length - 1].time;
                                            var LI = +lt.substr(0, 2);
                                            var l = (LI % 12) || 12;
                                            l = (l < 10) ? ("0" + l) : l;  // leading 0 at the left for 1 digit hours
                                            var ampmL = LI < 12 ? " AM" : " PM";
                                            lastItem = l + lt.substr(2, 3) + ampmL;
                                        }

                                        if (firstItem === "") {
                                            firstItem = "TBA"
                                        }

                                        var date = moment(item.date).format('M/DD/YYYY');
                                        return (
                                            <option key={index} value={item._id}>{date} ({firstItem})</option>
                                        )
                                    })}
                                </select>
                                <div style={{ width: '85%', marginTop: 15 }} className="form-group">
                                    {this.props.edit && this.props.billing_pin && this.props.show_info.length > 1 ?
                                        <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}><div style={{ display: 'flex', width: '100%' }}><button style={{ width: 20, borderRadius: 6, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: "#cdcdcd" }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedArray("billing_pin", "show_info", "billing", show_index) : null}><FaUnlink style={{ padding: 1 }} size="12" color={"#000"} /></button> Billing: </div></div> :
                                        this.props.edit && !this.props.billing_pin && this.props.show_info.length > 1 ?
                                            < div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}><div style={{ display: 'flex', width: '100%' }}><button style={{ width: 20, borderRadius: 6, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: "#111" }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedArray("billing_pin", "show_info", "billing", show_index) : null}><FaLink style={{ padding: 1 }} size="12" color={"#cdcdcd"} /></button> Billing: </div></div> :
                                            < div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}>Billing:</div>}
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        {this.props.edit && newShowInfo ?
                                            <select style={{ width: '90%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, backgroundColor: '#fff', color: '#000', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} className="form-control-sm" value={newShowInfo.billing} name="billing" onChange={this.props.billing_pin ? (event) => this.props.handlePinnedArrayChange(event, 'show_info') : (event) => this.props.handleArrayUpdate(event, this.props.show_select, 'show_info')} >
                                                <option value=""> </option>
                                                <option value="100% Headline Billing">100% Headline Billing</option>
                                                <option value="75% Special Guest Billing">75% Special Guest Billing</option>
                                                <option value="Co-Headline Billing">Co-Headline Billing</option>
                                                <option value="Festival Billing">Festival Billing</option>
                                                <option value="Special Event Billing">Special Event Billing</option>
                                                <option value="Special Guest Billing">Special Guest Billing</option>
                                                <option value="Support Billing">Support Billing</option>
                                                <option value="Custom">Custom</option>
                                            </select> :
                                            this.props.edit ?
                                                <select style={{ width: '90%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, backgroundColor: '#fff', color: '#000', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} className="form-control-sm" disabled={this.props.show_info.length > 0 ? false : true} value="" name="billing" onChange={this.props.billing_pin ? (event) => this.props.handlePinnedArrayChange(event, 'show_info') : (event) => this.props.handleArrayUpdate(event, this.props.show_select, 'show_info')} >
                                                    <option value=""> </option>
                                                    <option value="100% Headline Billing">100% Headline Billing</option>
                                                    <option value="75% Special Guest Billing">75% Special Guest Billing</option>
                                                    <option value="Co-Headline Billing">Co-Headline Billing</option>
                                                    <option value="Festival Billing">Festival Billing</option>
                                                    <option value="Special Event Billing">Special Event Billing</option>
                                                    <option value="Special Guest Billing">Special Guest Billing</option>
                                                    <option value="Support Billing">Support Billing</option>
                                                    <option value="Custom">Custom</option>
                                                </select> :
                                                <input style={{ width: '90%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12, backgroundColor: '#FAFAFA', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} disabled={true} type="text" value={newShowInfo.billing} />}
                                    </div>
                                </div>
                                {newShowInfo && newShowInfo.billing === "Custom" ?
                                    <div style={{ width: '85%' }} className="form-group">
                                        <input
                                            style={{ width: '90%', fontSize: 12, backgroundColor: this.props.edit ? '#fff' : '#FAFAFA', color: '#000' }}
                                            className="form-control-sm"
                                            value={newShowInfo.billing_custom}
                                            name="billing_custom"
                                            onChange={this.props.billing_pin ? (event) => this.props.handlePinnedArrayChange(event, 'show_info') : (event) => this.props.handleArrayUpdate(event, this.props.show_select, 'show_info')}
                                        />
                                    </div> : null}
                                <div style={{ width: '85%' }} className="form-group">
                                    {this.props.edit && this.props.age_limit_pin && this.props.show_info.length > 1 ?
                                        <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}><div style={{ display: 'flex', width: '100%' }}><button style={{ width: 20, borderRadius: 6, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: "#cdcdcd" }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedArray("age_limit_pin", "show_info", "age_limit", show_index) : null}><FaUnlink style={{ padding: 1 }} size="12" color={"#000"} /></button> Age Limit: </div></div> :
                                        this.props.edit && !this.props.age_limit_pin && this.props.show_info.length > 1 ?
                                            < div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}><div style={{ display: 'flex', width: '100%' }}><button style={{ width: 20, borderRadius: 6, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: "#111" }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedArray("age_limit_pin", "show_info", "age_limit", show_index) : null}><FaLink style={{ padding: 1 }} size="12" color={"#cdcdcd"} /></button> Age Limit: </div></div> :
                                            <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}>Age Limit:</div>}
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        {this.props.edit && newShowInfo ?
                                            <select style={{ width: '50%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, backgroundColor: '#fff', color: '#000', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} className="form-control-sm" value={newShowInfo.age_limit} name="age_limit" onChange={this.props.age_limit_pin ? (event) => this.props.handlePinnedArrayChange(event, 'show_info') : (event) => this.props.handleArrayUpdate(event, this.props.show_select, 'show_info')}>
                                                <option value=""> </option>
                                                <option value="All Ages">All Ages</option>
                                                <option value="18+">18+</option>
                                                <option value="21+">21+</option>
                                                <option value="Custom">Custom</option>
                                            </select> :
                                            this.props.edit ?
                                                <select style={{ width: '50%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, backgroundColor: '#fff', color: '#000', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} className="form-control-sm" disabled={this.props.show_info.length > 0 ? false : true} value="" name="age_limit" onChange={this.props.age_limit_pin ? (event) => this.props.handlePinnedArrayChange(event, 'show_info') : (event) => this.props.handleArrayUpdate(event, this.props.show_select, 'show_info')}>
                                                    <option value=""> </option>
                                                    <option value="All Ages">All Ages</option>
                                                    <option value="18+">18+</option>
                                                    <option value="21+">21+</option>
                                                    <option value="Custom">Custom</option>
                                                </select> :
                                                <input style={{ width: '50%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12, backgroundColor: '#FAFAFA', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} disabled={true} type="text" value={newShowInfo.age_limit} />}
                                    </div>
                                </div>
                                <div style={{ width: '85%' }} className="form-group">
                                    {newShowInfo && newShowInfo.age_limit === "Custom" ?
                                        <input
                                            style={{ width: '50%', fontSize: 12, backgroundColor: this.props.edit ? '#fff' : '#FAFAFA', color: '#000' }}
                                            className="form-control-sm"
                                            value={newShowInfo.age_limit_custom}
                                            name="age_limit_custom"
                                            onChange={this.props.billing_pin ? (event) => this.props.handlePinnedArrayChange(event, 'show_info') : (event) => this.props.handleArrayUpdate(event, this.props.show_select, 'show_info')}
                                        /> : null}
                                </div>
                                <div style={{ width: '100%', height: 'auto', paddingBottom: 30 }}>
                                    {this.props.edit && this.props.merch_pin && this.props.show_info.length > 1 ?
                                        <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}><div style={{ display: 'flex', width: '100%' }}><button style={{ width: 20, borderRadius: 6, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: "#cdcdcd" }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedArray("merch_pin", "merch_array", "merch", merch_index) : null}><FaUnlink style={{ padding: 1 }} size="12" color={"#000"} /></button> Merch: </div></div> :
                                        this.props.edit && !this.props.merch_pin && this.props.show_info.length > 1 ?
                                            <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}><div style={{ display: 'flex', width: '100%' }}><button style={{ width: 20, borderRadius: 6, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: "#111" }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedArray("merch_pin", "merch_array", "merch", merch_index) : null}><FaLink style={{ padding: 1 }} size="12" color={"#cdcdcd"} /></button> Merch: </div></div> :
                                            <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}>Merch:</div>}
                                    <table style={{ width: '90%', marginLeft: '10%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '25%', fontSize: 14, color: '#cdcdcd' }}>Soft</td>
                                                <td style={{ width: '75%' }}><NumberFormat className="form-control-sm" style={{ width: '60%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12, backgroundColor: this.props.edit && this.props.show_info.length > 0 ? '#fff' : this.props.show_info.length <= 0 ? '#b3b9c1' : '#FAFAFA', color: '#000' }} disabled={this.props.show_info.length > 0 ? false : true} value={merch_data ? merch_data.soft : ''} thousandSeparator={true} suffix={'%'} onValueChange={this.props.merch_pin ? (values) => this.props.handlePinnedArrayFormattedChange(values, this.props.show_select, 'soft', 'merch_array') : (values) => this.props.handleArrayFormattedChange(values, this.props.show_select, 'soft', 'merch_array')} /></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '25%', fontSize: 14, color: '#cdcdcd' }}>Hard</td>
                                                <td style={{ width: '75%' }}><NumberFormat className="form-control-sm" style={{ width: '60%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12, backgroundColor: this.props.edit && this.props.show_info.length > 0 ? '#fff' : this.props.show_info.length <= 0 ? '#b3b9c1' : '#FAFAFA', color: '#000' }} disabled={this.props.show_info.length > 0 ? false : true} value={merch_data ? merch_data.hard : ''} thousandSeparator={true} suffix={'%'} onValueChange={this.props.merch_pin ? (values) => this.props.handlePinnedArrayFormattedChange(values, this.props.show_select, 'hard', 'merch_array') : (values) => this.props.handleArrayFormattedChange(values, this.props.show_select, 'hard', 'merch_array')} /></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '25%', fontSize: 14, color: '#cdcdcd' }}>Seller</td>
                                                {this.props.edit ?
                                                    <td style={{ width: '75%' }}><select className="form-control-sm" style={{ width: '60%', fontSize: 12, backgroundColor: '#fff', color: '#000' }} name="seller" disabled={this.props.show_info.length > 0 ? false : true} value={merch_data ? merch_data.seller : ''} onChange={this.props.merch_pin ? (event) => this.props.handlePinnedArrayChange(event, 'merch_array') : (event) => this.props.handleArrayUpdate(event, this.props.show_select, 'merch_array')}>
                                                        <option value=""> </option>
                                                        <option value="Artist">Artist</option>
                                                        <option value="Venue">Venue</option>
                                                    </select></td> :
                                                    <td style={{ width: '75%' }}><input style={{ width: '60%', fontSize: 12, border: '.4px solid #000', height: 28, borderRadius: 3, padding: 12, backgroundColor: '#FAFAFA', color: '#000' }} disabled={true} type="text" value={merch_data ? merch_data.seller : ''} /></td>}
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '25%', fontSize: 14, color: '#cdcdcd' }}>Notes</td>
                                                <td style={{ width: '75%' }}><textarea
                                                    style={{ maxHeight: 68, minHeight: 68, maxWidth: '90%', minWidth: '90%', fontSize: 12, backgroundColor: this.props.show_info.length <= 0 ? '#b3b9c1' : '#FAFAFA' }}
                                                    className="form-control"
                                                    type="textarea"
                                                    name="notes"
                                                    disabled={this.props.show_info.length > 0 ? false : true}
                                                    value={merch_data ? merch_data.notes : ''}
                                                    onChange={this.props.merch_pin ? (event) => this.props.handlePinnedArrayChange(event, 'merch_array') : (event) => this.props.handleArrayUpdate(event, this.props.show_select, 'merch_array')}
                                                /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div style={{ width: '50%' }}>
                                <div style={{ width: '95%' }}>
                                    {this.props.edit && this.props.show_times_pin && this.props.show_info.length > 1 ?
                                        <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}><div style={{ display: 'flex', width: '100%' }}><button style={{ width: 20, borderRadius: 6, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: "#cdcdcd" }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedArray("show_times_pin", "show_info", "show_times", show_index) : null}><FaUnlink style={{ padding: 1 }} size="12" color={"#000"} /></button> Show Times: </div></div> :
                                        this.props.edit && !this.props.show_times_pin && this.props.show_info.length > 1 ?
                                            <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}><div style={{ display: 'flex', width: '100%' }}><button style={{ width: 20, borderRadius: 6, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: "#111" }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedArray("show_times_pin", "show_info", "show_times", show_index) : null}><FaLink style={{ padding: 1 }} size="12" color={"#cdcdcd"} /></button> Show Times: </div></div> :
                                            <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}>Show Times:</div>}
                                    <div style={{ height: 'auto', width: '100%' }}>
                                        {this.props.edit ?
                                            <div style={{ width: '100%' }}>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '40%' }}><input
                                                                className="form-control-sm"
                                                                style={{ width: '100%', height: 28, fontSize: 12, backgroundColor: this.props.show_info.length <= 0 ? '#b3b9c1' : '#FAFAFA' }}
                                                                disabled={this.props.edit && this.props.show_info.length > 0 ? false : this.props.edit && this.props.show_info.length <= 0 ? true : true}
                                                                onChange={this.props.onChange}
                                                                value={this.props.showtime1}
                                                                name="showtime1"
                                                                type="time"
                                                            /></td>
                                                            <td style={{ width: '50%' }}><input
                                                                className="form-control-sm"
                                                                style={{ width: '100%', height: 28, fontSize: 12, backgroundColor: this.props.show_info.length <= 0 ? '#b3b9c1' : '#FAFAFA' }}
                                                                disabled={this.props.edit && this.props.show_info.length > 0 ? false : this.props.edit && this.props.show_info.length <= 0 ? true : true}
                                                                onChange={this.props.onChange}
                                                                value={this.props.showtime1note}
                                                                name="showtime1note"
                                                            /></td>
                                                            <td style={{ width: '10%' }}><div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><FaPlusSquare style={{ cursor: 'pointer' }} size="20" color="#cdcdcd" onClick={this.props.addShowtime} /></div></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '40%', fontSize: 12, color: '#cdcdcd' }}>
                                                                {/* <input style={{ width: 15, height: 'auto' }} type="checkbox" name="checkbox_next_day" checked={this.props.checkbox_next_day} onChange={this.props.handleCheckboxChange} /> */}
                                                                <Checkbox
                                                                    icon={<CheckBoxOutlineBlankIcon style={{ color: '#cdcdcd' }} fontSize="small" />}
                                                                    checkedIcon={<CheckBoxIcon style={{ color: '#cdcdcd' }} fontSize="small" />}
                                                                    checked={this.props.checkbox_next_day}
                                                                    color="default"
                                                                    value={this.props.checkbox_next_day}
                                                                    name="checkbox_next_day"
                                                                    onChange={this.props.handleCheckboxChange}
                                                                />
                                                                Next Day</td>
                                                            <td style={{ width: '50%' }}></td>
                                                            <td style={{ width: '10%' }}></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> : null}
                                        <div style={{ marginTop: this.props.edit ? 15 : 5, minHeight: 100, maxHeight: 'auto', width: '100%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#b3b9c1' }} tabIndex={this.props.edit ? "0" : null} onBlur={this.props.edit ? (e) => this.props.handleClickOutside(e, 'showtimes_edit') : null}>
                                            {newShowInfo ?
                                                newShowInfo.show_times.map((item, index) => {
                                                    var ts = ""
                                                    if (item.time) {
                                                        ts = item.time
                                                        var H = +ts.substr(0, 2);
                                                        var h = (H % 12) || 12;
                                                        h = (h < 10) ? ("0" + h) : h;  // leading 0 at the left for 1 digit hours
                                                        var ampm = H < 12 ? " AM" : " PM";
                                                        ts = h + ts.substr(2, 3) + ampm;
                                                    } else {
                                                        ts = "TBA"
                                                    }

                                                    return (
                                                        <div style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%', minHeight: 50 }}>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        {this.props.showtimes_edit[item._id] === 'showtime' ?
                                                                            <td style={{ width: '40%' }}><input style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} type="time" name="time" value={item.time} onChange={(event) => this.props.handleShowtimeArrayChange(event, this.props.show_select, index, 'show_info', 'show_times')} /></td> :
                                                                            <td style={{ width: '40%' }} onClick={this.props.edit ? () => this.props.updateShowtime(item._id, 'showtime') : null}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 15, paddingLeft: 5 }}>{ts}</p></td>}
                                                                        {this.props.showtimes_edit[item._id] === 'description' ?
                                                                            <td style={{ width: '50%' }}><input style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} type="text" name="description" value={item.description} onChange={(event) => this.props.handleShowtimeArrayChange(event, this.props.show_select, index, 'show_info', 'show_times')} /></td> :
                                                                            <td style={{ width: '50%' }} onClick={this.props.edit ? () => this.props.updateShowtime(item._id, 'description') : null}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 15, paddingLeft: 5 }}>{item.description}</p></td>}
                                                                        {this.props.edit ?
                                                                            <td style={{ width: '10%' }}><div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><FaTrash style={{ cursor: 'pointer' }} size="15" color='#cdcdcd' onClick={() => this.deleteShowtime(index)} /></div></td> :
                                                                            <td style={{ width: '10%' }}> </td>}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                }) : null}
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: 30, width: '100%' }} className="form-group">
                                        {this.props.edit && this.props.show_time_notes_pin && this.props.show_info.length > 1 ?
                                            <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}><div style={{ display: 'flex', width: '100%' }}><button style={{ width: 20, borderRadius: 6, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: "#cdcdcd" }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedArray("show_time_notes_pin", "show_info", "show_time_notes", show_index) : null}><FaUnlink style={{ padding: 1 }} size="12" color={"#000"} /></button> Showtime Notes: </div></div> :
                                            this.props.edit && !this.props.show_time_notes_pin && this.props.show_info.length > 1 ?
                                                <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}><div style={{ display: 'flex', width: '100%' }}><button style={{ width: 20, borderRadius: 6, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: "#111" }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedArray("show_time_notes_pin", "show_info", "show_time_notes", show_index) : null}><FaLink style={{ padding: 1 }} size="12" color={"#cdcdcd"} /></button> Showtime Notes: </div></div> :
                                                <div style={{ width: '100%', height: 16, fontSize: 14, color: '#cdcdcd', marginTop: 10, marginBottom: 10 }}>Showtime Notes:</div>}
                                        <textarea
                                            style={{ marginLeft: '1%', marginRight: '1%', maxHeight: 90, minHeight: 90, minWidth: '98%', maxWidth: '98%', fontSize: 12, backgroundColor: this.props.show_info.length <= 0 ? '#b3b9c1' : '#FAFAFA' }}
                                            className="form-control"
                                            disabled={this.props.edit && this.props.show_info.length > 0 ? false : this.props.edit && this.props.show_info.length <= 0 ? true : true}
                                            name="show_time_notes"
                                            value={newShowInfo ? newShowInfo.show_time_notes : ""}
                                            onChange={this.props.show_time_notes_pin ? (event) => this.props.handlePinnedArrayChange(event, 'show_info') : (event) => this.props.handleArrayUpdate(event, this.props.show_select, 'show_info')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.render_type === 'render' ?
                            <div style={{ width: '100%', height: 'auto', marginTop: 30 }}>
                                <div style={{ width: '100%', height: 'auto', backgroundColor: 'rgb(1, 22, 48)', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', borderRight: '1px solid rgb(1, 22, 48)', borderRadius: 0 }} className="form-control">
                                    <label style={{ width: '100%', height: 16, fontSize: 14, marginLeft: 20, color: '#fff', display: 'flex', alignItems: 'center' }}>Holds: {this.state.holds_edit ? <span style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}><FaSave style={{ cursor: 'pointer' }} size="12" color="#cdcdcd" onClick={this.updateHoldsArray} /><span style={{ fontSize: 22, height: 'auto', cursor: 'pointer', marginLeft: 5 }} onClick={this.cancelHolds}>&times;</span></span> : <span style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}><FaTrash style={{ cursor: 'pointer' }} size="12" color="#cdcdcd" onClick={this.handleHoldsDialog} /><span style={{ height: 'auto', marginLeft: 5 }}><FaEdit style={{ cursor: 'pointer' }} size="12" color="#cdcdcd" onClick={() => this.toggle('holds_edit')} /></span></span>}</label>
                                    {!this.state.holds_edit ?
                                        <span style={{ width: '90%', height: 'auto', marginLeft: '5%', marginRight: '5%', display: 'flex', marginBottom: 10, marginTop: 10 }}>
                                            <p style={{ width: 220, height: 25, fontSize: 12, color: '#fff' }}>
                                                {this.state.holds_array ? this.state.holds_array.map((item, index) => {
                                                    var d = new Date(item.date);
                                                    var mon = moment(d).format('M');
                                                    var day = moment(d).format('D');
                                                    var ren = "";
                                                    if (index === 0) {
                                                        ren = item.hold + mon + '/' + day;
                                                    } else {
                                                        ren = ", " + item.hold + mon + '/' + day;
                                                    }

                                                    return (
                                                        ren
                                                    )
                                                }) : null}
                                            </p>
                                        </span> :
                                        <span style={{ width: '90%', height: 'auto', marginLeft: '5%', marginRight: '5%', display: 'flex', marginBottom: 10, marginTop: 10 }}>
                                            <p style={{ width: 'auto', height: 25, fontSize: 12, color: '#fff', display: 'flex' }}>
                                                {this.state.holds_array.map((item, index) => {
                                                    var d = new Date(item.date);
                                                    var ren = "";
                                                    ren = <div style={{ width: 100 }}><span style={{ width: '100%', display: 'flex' }}><input style={{ width: '82%' }} type="text" value={item.hold} name={index} onChange={this.onHoldsArrayChange} /><span style={{ width: '18%', textAlign: 'center', cursor: 'pointer' }}><FaTrash color="#cdcdcd" size="12" onClick={() => this.deleteHoldsArray(index)} /></span></span><DatePicker
                                                        className="react-datepicker"
                                                        value={d}
                                                        selected={d}
                                                        onChange={(date) => this.onHoldsArrayDateChange(date, index)}
                                                        // withPortal
                                                        todayButton="Today"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    /></div>


                                                    return (
                                                        ren
                                                    )
                                                })}
                                            </p>
                                        </span>}
                                    <span style={{ width: '90%', marginLeft: '5%', marginRight: '5%', display: 'flex', marginBottom: 10, marginTop: 10 }}>
                                        <input style={{ width: 100, height: 25, fontSize: 12 }} type="text" placeholder="ex: 2h" name="holds_status" value={this.state.holds_status} onChange={this.handleChange} />
                                        <div style={{ width: 100, height: 25, marginLeft: 5, fontSize: 12 }}>
                                            <DatePicker
                                                className="react-datepicker"
                                                value={this.state.holds_date}
                                                selected={this.state.holds_date}
                                                onChange={this.handleHoldsDateChange}
                                                // withPortal
                                                todayButton="Today"
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                        </div>
                                        <button style={{ width: 50, backgroundColor: '#000', color: '#cdcdcd', fontSize: 12, marginLeft: 15, borderRadius: 10 }} onClick={() => this.addHolds()} type="button">ADD</button>
                                    </span>
                                </div>
                            </div> : null}
                    </form>
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: 300,
        margin: 100,
    },
    //style for font size
    resize: {
        fontSize: 50
    },
}

function getUnique(arr, index) {
    const unique = arr
        .map(e => e[index])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);
    return unique;
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    festivals: state.festivals,
    holds: state.holds,
    auth: state.auth
});

export default connect(mapStateToProps, { getHoldsConnect, addHolds, updateHolds, deleteHolds })(ShowInfo);