import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

export default function CustomChip(props) {
    const { size = 1, ...restProps } = props;
    const classes = useStyles({ size });
  
    return (
      <Chip
        className={classes.root}
        classes={{ deleteIcon: classes.deleteIcon }}
        variant="outlined"
        {...restProps}
      />
    );
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      fontSize: (props) => `${props.size * 0.90}rem`,
      height: (props) => `${props.size * 30}px`,
      borderRadius: "9999px",
      color: (props) => props.color
    },
    avatar: {
      "&&": {
        height: (props) => `${props.size * 24}px`,
        width: (props) => `${props.size * 24}px`,
        fontSize: (props) => `${props.size * 0.75}rem`
      }
    },
    deleteIcon: {
      height: (props) => `${props.size * 22}px`,
      width: (props) => `${props.size * 22}px`,
      color: "green"
    }
  }));