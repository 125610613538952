import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';
import StatusDialog from '../components/offerdatabase/status.dialog';
import { deleteOffer, getOfferDetail, updateOfferStatus, getFinalsAll, offerCompose } from '../actions/offerActions';
import { finalsCompose, finalsSocket, finalsSocketDelete } from '../actions/finalsActions';
import _ from 'lodash';
import moment from 'moment';
import { Menu, Item, Separator, Submenu, MenuProvider, theme, animation } from 'react-contexify';
import { FaExternalLinkSquareAlt, FaCaretDown, FaCaretUp, FaRedoAlt, FaMinusSquare, FaCircle, FaEnvelope } from 'react-icons/fa';
import { AutoSizer, Column, Table } from 'react-virtualized';
import CustomChip from '../components/customStatus';
import ReactTooltip from 'react-tooltip';
import getSymbolFromCurrency from 'currency-symbol-map';
import ContractIssuedDialog from '../components/database/contract-issued.dialog';
import ContractReceivedDialog from '../components/database/contract-received.dialog';
import 'react-contexify/dist/ReactContexify.min.css';
import 'react-virtualized/styles.css'

class FinalsRolodex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusdialog: false,
            statustitle: '',
            statusrender: '',
            statusrenderid: '',
            statusstart: '',
            statusvenue: '',
            issued_dialog: false,
            received_dialog: false,
            select_deal: {},
            finals_array: [],
            finals_all: []
        };
        this.handleChange = this.handleChange.bind(this);
        // this.handleStatusDialog = this.handleStatusDialog.bind(this);
        // this.saveStatus = this.saveStatus.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        offers: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        finals: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        // var filterOffers = await this.filterStart(this.props.offers.offers);
        var finals = await this.props.getFinalsAll();


        // var newArr = await arrayBuild(filterOffers, finals);
        // filterOffers = await filterFinalOffers(newArr);

        this.props.finalsCompose(finals);

        // if (finals) {
        //     this.setState({
        //         finals_array: filterOffers,
        //         finals_all: newArr
        //     })
        // }
        const socket = this.props.auth.socket;
        if (socket) {
            socket.on(`finals/update`, async (offer) => {
                this.props.finalsSocket(offer);
                // ADD CLIENT, VENUE, FESTIVAL, ETC
                // var offCompose = await this.props.offerCompose(offer);
                // let finals_array = this.props.finals.filtered_finals;
                // let finals_all = this.state.finals_all;
                // var final = { tickets_sold: null, gross_sales: null, net_sales: null, notes: null, settled: false };
                // var settlement = offCompose.settlement;
                // var sellable = null;
                // const currency_symbol = getSymbolFromCurrency(offCompose.deal_currency);
                // offCompose.show_info.map(async (show) => {
                //     const date_index = finals_array.findIndex(obj => obj._id === offCompose._id && obj.date_id === show._id);
                //     const date_index_all = finals_all.findIndex(obj => obj._id === offCompose._id && obj.date_id === show._id);
                //     if (date_index >= 0) {
                //         offCompose.sellable_cap.map(sel => {
                //             if (show._id === sel._id) {
                //                 sellable = sel.amt
                //             }
                //         })
                //         offCompose.finals.map(fin => {
                //             if (fin._id + offCompose._id === show._id + offCompose._id) {
                //                 final = fin
                //             }
                //         })
                //         var newObj = { ...offCompose, finals: final, settlement: settlement, date: show.date, sellable: sellable, date_id: show._id, currency_symbol: currency_symbol };
                //         finals_array[date_index] = newObj;
                //         finals_all[date_index_all] = newObj;
                //     } else {
                //         offCompose.sellable_cap.map(sel => {
                //             if (show._id === sel._id) {
                //                 sellable = sel.amt
                //             }
                //         })
                //         offCompose.finals.map(fin => {
                //             if (fin._id + offCompose._id === show._id + offCompose._id) {
                //                 final = fin
                //             }
                //         })
                //         var newObj = { ...offCompose, finals: final, settlement: settlement, date: show.date, sellable: sellable, date_id: show._id, currency_symbol: currency_symbol };
                //         finals_array = [...finals_array, newObj];
                //         finals_all = [...finals_all, newObj];

                //         finals_array = await filterFinalOffers(finals_array);
                //     }
                // })
                // this.setState({
                //     finals_array: finals_array,
                //     finals_all: finals_all
                // })
                // this.props.finalsInputSocket(offer);
            })
            socket.on(`offers/delete`, async (offer) => {
                this.props.finalsSocketDelete(offer);
                // let finals_array = this.props.finals.filtered_finals;
                // let finals_all = this.state.finals_all;
                // offer.show_info.map(show => {
                //     finals_array = finals_array.filter(obj => obj._id !== offer._id && obj.date_id !== show._id);
                //     finals_all = finals_all.filter(obj => obj._id !== offer._id && obj.date_id !== show._id);
                // })
                // this.setState({
                //     finals_array: finals_array,
                //     finals_all: finals_all
                // })
                // this.props.finalsInputSocket(offer);
            })
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleEventPositioned() {
        ReactTooltip.rebuild();
    }

    filterStart = (offers) => {
        let filtered_offers = [];
        filtered_offers = offers.filter((item) => {
            var filterbatch = item.status
            return filterbatch.indexOf('Confirmed') >= 0
        })
        return filtered_offers;
    }

    filterOffers = (offers) => {
        let filtered_offers = [];

        filtered_offers = offers.filter((item) => {
            const closest = item.date;
            var filterBatch = new Date(closest.date)
            var start = this.props.counts_filter.start_date
            return filterBatch < start
        })

        filtered_offers = _.orderBy(filtered_offers, function (dateObj) {
            return new Date(dateObj.date);
        }, 'desc');

        // var counts_filter = this.props.counts_filter;
        // var filtered_start = count_data.filter((item) => {
        //     const today = moment();
        //     var val = _.get(item, 'show_info')
        //     const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
        //     var filterBatch = new Date(closest.date)
        //     var start = counts_filter.start_date
        //     return filterBatch > start
        // })
        // var filtered_end = filtered_start.filter((item) => {
        //     const today = new Date();
        //     var val = _.get(item, 'show_info')
        //     const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
        //     var filterBatch = new Date(furthest.date)
        //     var end = counts_filter.end_date
        //     return filterBatch <= end
        // })
        // var filteredArtist = filtered_end.filter((item) => {
        //     var filterBatch = item.client_id
        //     return filterBatch.indexOf(counts_filter.client) >= 0
        // })
        // var filteredVenue = filteredArtist.filter((item) => {
        //     var filterBatch = item.venue_name
        //     return filterBatch.indexOf(counts_filter.venue) >= 0
        // })
        // var filteredState = filteredVenue.filter((item) => {
        //     var filterBatch = item.venue_state
        //     return filterBatch.indexOf(counts_filter.state) >= 0
        // })
        // var filteredCity = filteredState.filter((item) => {
        //     var filterBatch = item.venue_city
        //     return filterBatch.indexOf(counts_filter.city) >= 0
        // })
        // var filteredCountry = filteredCity.filter((item) => {
        //     var filterBatch = item.venue_country
        //     return filterBatch.indexOf(counts_filter.country) >= 0
        // })
        return filtered_offers;
    }

    handleStatusDialog = (item, start, venue) => {
        if (item) {
            this.setState({
                statusrenderid: item._id,
                statusrender: item.status,
                statustitle: item.artist_name,
                statusstart: start,
                statusvenue: venue,
                statusdialog: !this.state.statusdialog
            })
        }
    }

    closeStatusDialog = () => {
        this.setState({
            statustitle: '',
            statusrender: '',
            statusrenderid: '',
            statusstart: '',
            statusvenue: '',
            statusdialog: !this.state.statusdialog
        })
    }

    saveStatus = async () => {
        const status = {
            status: this.state.statusrender
        }
        await this.props.updateOfferStatus(status, this.state.statusrenderid)
        this.setState({
            statustitle: '',
            statusrender: '',
            statusrenderid: '',
            statusstart: '',
            statusvenue: '',
            statusdialog: !this.state.statusdialog
        })
    }

    handleDatabaseCheckbox = (n, v) => {
        const e = {
            target: {
                name: n,
                value: v
            }
        }
        this.props.handleDatabaseCheckbox(e)
    }

    basicColumnData = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: '100%', height: '100%' }}>
                    {cellData[dataKey]}
                </div>
            </MenuProvider>
        )
    }

    renderColumnData = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', fontSize: 10 }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={this.props.finals_checked.includes(cellData._id) || false}
                        color="default"
                        value={cellData._id}
                        name="finals_checked"
                        onChange={this.props.handleDatabaseCheckbox}
                    />
                    {/* <Chip
                        size="small"
                        style={{ opacity: .8 }}
                        color={"primary"}
                        clickable
                        label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
                        onClick={this.props.iconRenderOffer.bind(this, cellData)}
                    /> */}
                    <span style={{ width: 25, height: '100%' }} onClick={this.props.iconRenderOffer.bind(this, cellData)}>
                        <FaExternalLinkSquareAlt size="16" className="dashboard-offer-icons-exp" />
                    </span>
                    <span style={{ width: 25, height: '100%' }} onClick={() => this.props.toggleEmailDialog(cellData)}>
                        <FaEnvelope size="14" className="dashboard-offer-icons-email" />
                    </span>
                    {/* {item.main_link ?
                        <span style={{ width: 25, height: '100%' }} onClick={() => window.open(item.main_link)}>
                            <FaTicketAlt size="14" className="dashboard-offer-icons-link" />
                        </span> :
                        <span style={{ width: 25, height: '100%' }}>

                        </span>} */}
                </div>
            </MenuProvider>
        )
    }

    startDateColumnData = ({ cellData, dataKey }) => {
        const datesArray = cellData.row.show_info.map(d => moment(d.date).utc());
        var start = moment.min(datesArray).format('MM/DD/YYYY');
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center', fontSize: 10 }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {start}
                </div>
            </MenuProvider>
        )
    }

    endDateColumnData = ({ cellData, dataKey }) => {
        const datesArray = cellData.row.show_info.map(d => moment(d.date).utc());
        var end = moment.max(datesArray).format('MM/DD/YYYY');
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center', fontSize: 10 }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {end}
                </div>
            </MenuProvider>
        )
    }

    normCellRender = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData.id} id="menu_id" component="span" value={cellData.id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center', fontSize: 10 }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {cellData.cell}
                </div>
            </MenuProvider>
        )
    }

    rowSelect(id) {
        if (!id) return;
        this.handleDatabaseCheckbox('finals_checked', id);
    }
 
    rowStyleFormat(row) {
        if (row.index < 0) return;
        const list = this.props.finals.filtered_finals;
        if (this.props.finals_checked.includes(list[row.index]._id)) {
            return {
                backgroundColor: '#e6f7ff'
            };
        }
        return {
            backgroundColor: null,
        };
    }

    headerRowRenderer = ({ className, columns, style }) => {
        return (
            <div
                className={className}
                role='row'
                style={style}
            >
                {columns}
            </div>
        )
    }

    headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        if (this.props.finals.sort.cat === dataKey) {
            return (
                <div style={{ width: '100%' }} onClick={() => this.props.toggleSortFinals(dataKey)}><div style={{ display: 'flex', width: '100%' }}><div style={{ width: dataKey === 'artist_name' || dataKey === 'venue_name' || dataKey === 'buyer_company' ? '86%' : '80%' }}>{label}</div><div style={{ width: '10%' }}>{this.props.finals.sort.dir === 'asc' ? <FaCaretUp size="15" color="#cdcdcd" /> : <FaCaretDown size="15" color="#cdcdcd" />}</div></div></div>
            )
        } else {
            return (
                <div onClick={() => this.props.toggleSortFinals(dataKey)}>{label}</div>
            )
        }
    }

    headerStartRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: 'auto' }}>
                <div style={{ width: '60%' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        color="default"
                        checked={this.props.finals_all_checked}
                        //value={props.checked}
                        name="finals_checked"
                        onChange={this.props.handleDatabaseAllCheckbox}
                    /></div>
                <div style={{ width: '40%', height: 30 }}>
                    {this.props.finals.sort.cat ?
                        <FaRedoAlt style={{ marginTop: 14 }} size="12" color="#cdcdcd" onClick={() => this.props.clearSorted('finals_sort')} /> :
                        this.props.finals_checked.length > 0 ?
                            <FaMinusSquare style={{ marginTop: 10 }} size="18" color="#cdcdcd" onClick={() => this.props.handleDatabaseClearCheckbox('finals_checked')} />
                            : null}
                </div>
            </div>
        )
    }

    // rowSelect(row) {
    //     if (row.index < 0) return;
    //     const list = this.props.offers.filtered_offers;
    //     this.handleDatabaseCheckbox('offer_checked', list[row.index]._id);
    // }

    rowDoubleClick(row) {
        if (row.index < 0) return;
        this.props.iconRenderOffer(row.rowData)
    }

    toggleIssuedDialog = (cellData) => {
        if (cellData) {
            this.setState({
                issued_dialog: !this.state.issued_dialog,
                select_deal: cellData,
                select_currency: ''
            })
        } else {
            this.setState({
                issued_dialog: !this.state.issued_dialog,
                select_deal: {},
                select_currency: ''
            })
        }
    }

    toggleReceivedDialog = (cellData) => {
        if (cellData) {
            const currency_symbol = getSymbolFromCurrency(cellData.deal_currency);
            this.setState({
                received_dialog: !this.state.received_dialog,
                select_deal: cellData,
                select_currency: currency_symbol
            })
        } else {
            this.setState({
                received_dialog: !this.state.received_dialog,
                select_deal: {},
                select_currency: ''
            })
        }
    }

    locColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ width: '100%', fontSize: 10 }}>{cellData.venue_city}, {cellData.venue_state}</div>
        )
    }

    soldColumnData = ({ cellData, dataKey }) => {
        if (cellData.prog.settled) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 10 }}>{cellData.finals.tickets_sold ? cellData.finals.tickets_sold : '-'}</div>
            )
        } else {
            const check = cellData.date_id + ", " + cellData._id;
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}><input style={{ width: 80, height: 25, color: '#000', backgroundColor: this.props.finals_sold_array[check] || this.props.finals_gross_array[check] || this.props.finals_net_array[check] || this.props.finals_overage_array[check] || this.props.finals_notes_array[check] ? '#e6f7ff' : '#fff', border: '.5px solid #555', fontSize: 10 }} type="number" value={this.props.finals_sold_array[cellData.date_id + ", " + cellData._id] || ""} onChange={(e) => this.props.handleFinalsChange(e.target.value, cellData.date_id, cellData.index, cellData._id, 'finals_sold_array')} /></div>
            )
        }
    }

    grossColumnData = ({ cellData, dataKey }) => {
        if (cellData.prog.settled) {
            if (cellData.finals.gross_sales) {
                return (
                    <NumberFormat style={{ fontSize: 10, width: 100, height: 26 }} displayType={"text"} value={cellData.finals.gross_sales} thousandSeparator={true} prefix={cellData.currency_symbol} />
                )
            } else {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 10 }}>-</div>
                )
            }
        } else {
            const check = cellData.date_id + ", " + cellData._id;
            return (
                <NumberFormat style={{ fontSize: 10, width: 100, height: 26, backgroundColor: this.props.finals_sold_array[check] || this.props.finals_gross_array[check] || this.props.finals_net_array[check] || this.props.finals_overage_array[check] || this.props.finals_notes_array[check] ? '#e6f7ff' : '#fff', border: '.5px solid #555' }} value={this.props.finals_gross_array[cellData.date_id + ", " + cellData._id] || ""} thousandSeparator={true} prefix={cellData.currency_symbol} onValueChange={(value) => this.props.handleFinalsChange(value.value, cellData.date_id, cellData.index, cellData._id, 'finals_gross_array')} />
            )
        }
    }

    netColumnData = ({ cellData, dataKey }) => {
        if (cellData.prog.settled) {
            if (cellData.finals.net_sales) {
                return (
                    <NumberFormat style={{ fontSize: 10, width: 100, height: 26 }} displayType={"text"} value={cellData.finals.net_sales} thousandSeparator={true} prefix={cellData.currency_symbol} />
                )
            } else {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 10 }}>-</div>
                )
            }
        } else {
            const check = cellData.date_id + ", " + cellData._id;
            return (
                <NumberFormat style={{ fontSize: 10, width: 100, height: 26, backgroundColor: this.props.finals_sold_array[check] || this.props.finals_gross_array[check] || this.props.finals_net_array[check] || this.props.finals_overage_array[check] || this.props.finals_notes_array[check] ? '#e6f7ff' : '#fff', border: '.5px solid #555' }} value={this.props.finals_net_array[cellData.date_id + ", " + cellData._id] || ""} thousandSeparator={true} prefix={cellData.currency_symbol} onValueChange={(value) => this.props.handleFinalsChange(value.value, cellData.date_id, cellData.index, cellData._id, 'finals_net_array')} />
            )
        }
    }

    overageColumnData = ({ cellData, dataKey }) => {
        if (cellData.prog.settled) {
            if (cellData.settlement.overage) {
                return (
                    <NumberFormat style={{ fontSize: 10, width: 100, height: 26 }} displayType={"text"} value={cellData.settlement.overage} thousandSeparator={true} prefix={cellData.currency_symbol} />
                )
            } else {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 10 }}>-</div>
                )
            }
        } else {
            const check = cellData.date_id + ", " + cellData._id;
            return (
                <NumberFormat style={{ fontSize: 10, width: 100, height: 26, backgroundColor: this.props.finals_sold_array[check] || this.props.finals_gross_array[check] || this.props.finals_net_array[check] || this.props.finals_overage_array[check] || this.props.finals_notes_array[check] ? '#e6f7ff' : '#fff', border: '.5px solid #555' }} value={this.props.finals_overage_array[cellData.date_id + ", " + cellData._id] || ""} thousandSeparator={true} prefix={cellData.currency_symbol} onValueChange={(value) => this.props.handleFinalsChange(value.value, cellData.date_id, cellData.index, cellData._id, 'finals_overage_array')} />
            )
        }
    }

    guaranteeColumnData = ({ cellData, dataKey }) => {
        return (
            <NumberFormat style={{ fontSize: 10 }} displayType={'text'} value={cellData.financials.guarantee} thousandSeparator={true} prefix={cellData.currency_symbol} />
        )
    }

    walkoutColumnData = ({ cellData, dataKey }) => {
        if (cellData.financials.settlement_gross) {
            return (
                <NumberFormat style={{ fontSize: 10 }} displayType={'text'} value={cellData.financials.settlement_gross} thousandSeparator={true} prefix={cellData.currency_symbol} />
            )
        } else {
            return (
                <NumberFormat style={{ fontSize: 10 }} displayType={'text'} value={''} thousandSeparator={true} prefix={cellData.currency_symbol} />
            )
        }
    }

    notesColumnData = ({ cellData, dataKey }) => {
        if (cellData.prog.settled) {
            if (cellData.finals.notes) {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: 26, width: 100, fontSize: 10, color: '#000', overflowY: 'scroll' }}>{cellData.finals.notes}</div>
                )
            } else {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: 10 }}> </div>
                )
            }
        } else {
            const check = cellData.date_id + ", " + cellData._id;
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}><input style={{ width: 100, height: 25, color: '#000', backgroundColor: this.props.finals_sold_array[check] || this.props.finals_gross_array[check] || this.props.finals_net_array[check] || this.props.finals_overage_array[check] || this.props.finals_notes_array[check] ? '#e6f7ff' : '#fff', border: '.5px solid #555', fontSize: 10 }} type="text" value={this.props.finals_notes_array[cellData.date_id + ", " + cellData._id] || ""} onChange={(e) => this.props.handleFinalsChange(e.target.value, cellData.date_id, cellData.index, cellData._id, 'finals_notes_array')} /></div>
            )
        }
    }

    // handleFinalsChange(e, dateid, index, id, sub) {
    //     if (e) {
    //         // const tc = {
    //         //     dateid: dateid,
    //         //     showid: id,
    //         //     count: Number(e)
    //         // }
    //         var set = `${dateid}, ${id}`;
    //         var exists = this.state.finals_input[set];
    //         if(exists){
    //             var arr = this.state.finals_input;
    //             arr[set].sub = e
    //             this.setState({
    //                 finals_input: arr
    //             })
    //         } else {
    //             var obj = { sold: null, gross: null, net: null, overage: null };
    //             if(sub === 'sold'){
    //                 var obj = { sold: e, gross: null, net: null, overage: null }
    //             } else if(sub === 'gross'){
    //                 var arr = { sold: null, gross: e, net: null, overage: null }
    //             } else if(sub === 'net'){
    //                 var arr = { sold: null, gross: null, net: e, overage: null }
    //             } else if(sub === 'overage'){
    //                 var arr = { sold: null, gross: null, net: null, overage: e }
    //             }
    //             this.setState({
    //                 finals_input: {
    //                     ...this.state.finals_input,
    //                     [set]: obj
    //                 }
    //             })
    //         }
    //     } else {
    //         const finalInput = {
    //             ...this.state.finals_input
    //         }
    //         delete finalInput[dateid + ", " + id];
    //         this.setState({
    //             finals_input: {
    //                 ...finalInput
    //             }
    //         })
    //     }
    // }

    render() {

        console.log(this.props.finals_sort)

        const list = this.props.finals.filtered_finals;
        const onClick = ({ event, props }) => this.props.toggleDealMemoPDFContext(props.ref.attributes[1].value);

        const MyAwesomeMenu = () => (
            <Menu id='menu_id' theme={theme.dark} animation={animation.fade}>
                <Item onClick={this.props.toggleRenderOffer}>View / Edit</Item>
                <Item onClick={() => this.props.copyOffer('finals_db')}>Copy Deal</Item>
                <Item onClick={this.props.toggleGeoMap}>View Map</Item>
                {/* <Item onClick={onClick}>Analysis</Item> */}
                <Submenu label="Print PDF">
                    <Item onClick={() => this.props.togglePdf("dealmemo")}>Deal Memo</Item>
                    <Item onClick={() => this.props.togglePdf("contract")}>Contract</Item>
                    <Item onClick={() => this.props.togglePdf("itinerary")}>Itinerary</Item>
                    <Item onClick={() => this.props.togglePdf("counts")}>Ticket Counts</Item>
                    <Item onClick={() => this.props.togglePdf("finals-statement", 'finals')}>Final Totals</Item> 
                    <Item onClick={() => this.props.togglePdf("tour-settlement-statement", 'finals')}>Settlement Statement</Item>
                </Submenu>
                <Submenu label="Issue">
                    <Item onClick={() => this.props.togglePdf("dealmemo")}>Deal Memo</Item>
                    <Item onClick={() => this.props.togglePdf("contract")}>Contract</Item>
                    <Item onClick={() => this.props.togglePdf("itinerary")}>Itinerary</Item>
                </Submenu>
                {/* <Submenu label="Analytics">
                    <Item onClick={this.props.countCompareContext}>Count Comparison</Item>
                </Submenu> */}
            </Menu>
        );


        if (this.props.finals.loading) return <div>loading...</div>;
        else {
            return (
                <div style={{ width: '100%' }}>
                    {this.props.tooltip ?
                        <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                        </ReactTooltip> : null}
                    <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={height}
                                headerHeight={30}
                                rowHeight={30}
                                overscanRowCount={10}
                                headerRowRenderer={this.headerRowRenderer.bind(this)}
                                rowCount={list.length}
                                rowGetter={({ index }) => list[index]}
                                rowStyle={this.rowStyleFormat.bind(this)}
                                //onRowClick={this.rowSelect.bind(this)}
                                onRowDoubleClick={this.rowDoubleClick.bind(this)}
                            >
                                <Column
                                    label=''
                                    width={130}
                                    dataKey='_id'
                                    headerRenderer={this.headerStartRenderer}
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.renderColumnData
                                    }
                                />
                                <Column
                                    width={200}
                                    label='Client'
                                    dataKey='artist_name'
                                    headerRenderer={this.headerRenderer}
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.artist_name, id: rowData._id, dataKey: 'artist_name', width: 200 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={130}
                                    label='Date'
                                    dataKey='start_date'
                                    cellDataGetter={({ rowData }) => { return ({ row: rowData, id: rowData._id, dataKey: 'start_date', width: 130 }) }}
                                    cellRenderer={
                                        this.startDateColumnData
                                    }
                                />
                                {/* <Column
                                    width={130}
                                    label='End Date'
                                    dataKey='end_date'
                                    cellDataGetter={({ rowData }) => { return ({ row: rowData, id: rowData._id, width: 130 }) }}
                                    cellRenderer={
                                        this.endDateColumnData
                                    }
                                /> */}
                                <Column
                                    width={200}
                                    label='Venue'
                                    dataKey='venue_name'
                                    headerRenderer={this.headerRenderer}
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.venue_name, id: rowData._id, dataKey: 'venue_name', width: 200 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={200}
                                    label='City, State'
                                    dataKey='city'
                                    headerRenderer={this.headerRenderer}
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.locColumnData
                                    }
                                />
                                {/* <Column
                                    width={200}
                                    label='Buyer'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='buyer_company'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.buyer_company, id: rowData._id, width: 200 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                /> */}
                                <Column
                                    width={80}
                                    label='Capacity'
                                    //headerRenderer={this.headerRenderer}
                                    dataKey='sellable'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.sellable === 0 ? '-' : rowData.sellable, id: rowData._id, date_id: rowData.date_id, width: 100 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={80}
                                    label='Sold'
                                    //headerRenderer={this.headerRenderer}
                                    dataKey='sold'
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.soldColumnData
                                    }
                                />
                                <Column
                                    width={100}
                                    label='Gross Sales'
                                    //headerRenderer={this.headerRenderer}
                                    dataKey='gross'
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.grossColumnData
                                    }
                                />
                                <Column
                                    width={100}
                                    label='Net Sales'
                                    //headerRenderer={this.headerRenderer}
                                    dataKey='net'
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.netColumnData
                                    }
                                />
                                <Column
                                    width={100}
                                    label='Guarantee'
                                    //headerRenderer={this.headerRenderer}
                                    dataKey='guarantee'
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.guaranteeColumnData
                                    }
                                />
                                <Column
                                    width={100}
                                    label='Overage'
                                    //headerRenderer={this.headerRenderer}
                                    dataKey='overage'
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.overageColumnData
                                    }
                                />
                                <Column
                                    width={100}
                                    label='Walkout'
                                    //headerRenderer={this.headerRenderer}
                                    dataKey='walkout'
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.walkoutColumnData
                                    }
                                />
                                <Column
                                    width={150}
                                    label='Notes'
                                    //headerRenderer={this.headerRenderer}
                                    dataKey='notes'
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.notesColumnData
                                    }
                                />
                            </Table>
                        )}
                    </AutoSizer>
                    <MyAwesomeMenu />
                    <StatusDialog open={this.state.statusdialog} handleClose={this.closeStatusDialog} handleChange={this.handleChange} saveStatus={this.saveStatus} {...this.state} />
                    <ContractIssuedDialog open={this.state.issued_dialog} handleClose={this.toggleIssuedDialog} select_deal={this.state.select_deal} user={this.props.auth.user.name} tag={this.props.auth.user.tag} />
                    <ContractReceivedDialog open={this.state.received_dialog} handleClose={this.toggleReceivedDialog} select_deal={this.state.select_deal} select_currency={this.state.select_currency} user={this.props.auth.user.name} tag={this.props.auth.user.tag} />
                </div>
            );
        }
    }
}

function filterFinalOffers(offers) {
    return new Promise(async (resolve, reject) => {
        let filtered_offers = [];
        filtered_offers = offers.filter((item) => {
            var filterBatch = new Date(item.date)
            var start = new Date()
            return filterBatch < start
        })
        filtered_offers = _.orderBy(filtered_offers, function (dateObj) {
            return new Date(dateObj.date);
        }, 'desc');
        resolve(filtered_offers)
    })
}

function arrayBuild(filterOffers, finals) {
    return new Promise(async (resolve, reject) => {
        var newArr = [];
        filterOffers.map(item => {
            const currency_symbol = getSymbolFromCurrency(item.deal_currency);
            item.show_info.forEach((show, ind) => {
                var final = { tickets_sold: null, gross_sales: null, net_sales: null, settled: false };
                var settlement = { deductions: null, overage: null, withholding: null };
                var sellable = null;
                item.sellable_cap.map(sel => {
                    if (show._id === sel._id) {
                        sellable = sel.amt
                    }
                })
                finals.map(fin => {
                    if (item._id === fin._id && fin.settlement) {
                        settlement = fin.settlement;
                    }
                    fin.finals.map(liv => {
                        if (fin._id + liv._id === item._id + show._id) {
                            final = liv
                        }
                    })
                })
                newArr.push({ ...item, finals: final, settlement: settlement, date: show.date, sellable: sellable, date_id: show._id, currency_symbol: currency_symbol })
            })
        })
        resolve(newArr)
    })
}

const mapStateToProps = state => ({
    offers: state.offers,
    items: state.items,
    buyers: state.buyers,
    clients: state.clients,
    contacts: state.contacts,
    finals: state.finals,
    auth: state.auth
});

export default connect(mapStateToProps, { deleteOffer, getOfferDetail, updateOfferStatus, getFinalsAll, offerCompose, finalsCompose, finalsSocket, finalsSocketDelete })(FinalsRolodex);