import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";


const PublicRoute = ({ component: Component, isAuthenticated, slug, user_type, ...rest }) => {
    return (
        <Route {...rest} render={props =>
            !isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect to={`${slug.company.slug}`} />
            )
        }
        />
    );
}

export default PublicRoute;