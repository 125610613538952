import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addClient, updateClient, getAttachment } from '../../actions/clientActions';
import { spotifyLogin, getSpotify } from '../../actions/spotifyActions';
import { FaInfoCircle, FaAddressBook, FaSave, FaEdit, FaUserAstronaut, FaCommentDollar, FaPaperclip, FaCommentDots, FaPlusSquare, FaArrowLeft, FaUserShield, FaCogs } from 'react-icons/fa';
import AgentAutocomplete from '../../components/autocompletes/agent-autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ReactTooltip from 'react-tooltip';
import RichTextEditor from 'react-rte';

class PayAccountModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            edit: true,
            render: 'info',
            id: '',
            header: '',
            status: 'Active',
            render_type: 'enter',
            pay_type: '',
            account_name: '',
            bank_name: '',
            account_number: '',
            routing_number: '',
            swift_code: '',
            bank_address: [],
            bank_city: '',
            bank_state: '',
            bank_country: '',
            bank_postal_code: '',
            co_address_default: false,
            co_address: [],
            co_city: '',
            co_state: '',
            co_country: '',
            co_postal_code: '',
            ach_check: false,
            app_agents: [],
            app_contracts: false,
            app_settlement_statements: false,
            paypal_ff: false

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        spotify: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        console.log(this.props.render_data)
        if (this.props.render_data) {
            const data = this.props.render_data;
            if (data.type === 'Wire Transfer' || data.type === 'ACH Transfer') {
                this.setState({
                    pay_type: data.type,
                    account_name: data.account_name,
                    account_number: data.account_num,
                    bank_address: data.bank_address.address,
                    bank_city: data.bank_address.city,
                    bank_state: data.bank_address.state,
                    bank_postal_code: data.bank_address.postal_code,
                    bank_name: data.bank_name,
                    routing_number: data.routing_num,
                    swift_code: data.swift_code,
                    ach_check: data.ach,
                    edit: false,
                    render_type: 'render',
                    app_contracts: data.application.contracts,
                    app_settlement_statements: data.application.settlement_statements
                })
            } else {
                this.setState({
                    pay_type: data.type,
                    account_name: data.account_name,
                    edit: false,
                    render_type: 'render',
                    app_contracts: data.application.contracts,
                    app_settlement_statements: data.application.settlement_statements
                })
            }
        }

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleEventPositioned() {
        ReactTooltip.rebuild();
    }

    handleClick = () => {
        this.props.close();
    };

    toggleSub = (e) => {
        this.setState({
            render: e,
        })
    }

    onEdit = () => {
        this.setState({
            edit: !this.state.edit
        })
    }

    toggleStatus = () => {
        if (this.state.status === 'Active') {
            this.setState({
                status: "Inactive"
            })
        } else {
            this.setState({
                status: "Active"
            })
        }
    }

    handleAgentChange(agent) {
        if (agent) {
            this.setState({
                app_agents: agent,
            })
        } else {
            this.setState({
                app_agents: [],
            });
        }

    }

    onSave = async () => {

    }

    addLink = () => {
        if (this.state.add_link && this.state.add_type) {
            if (this.state.add_type === 'Other') {
                if (this.state.add_options.includes(this.state.add_type_custom)) {
                    alert('Link type already exists.  Please create a new label.')
                } else {
                    const newLink = {
                        desc: this.state.add_type_custom,
                        url: this.state.add_link
                    }
                    this.setState({
                        links: [...this.state.links, newLink],
                        add_link: '',
                        add_type: ''
                    })
                }
            } else {
                const newLink = {
                    desc: this.state.add_type,
                    url: this.state.add_link
                }
                this.setState({
                    links: [...this.state.links, newLink],
                    add_link: '',
                    add_type: ''
                })
            }
        }
    }

    linkClear = () => {
        this.setState({
            add_type: ''
        })
    }

    render() {
        console.log(this.props.auth.user.company.address, "COMPANY")

        return (
            <div className="mode-t">
                <div className="mode-t-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50 }}>
                        <span className="close" onClick={this.handleClick}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        {this.state.edit ?
                            <span className="close" onClick={this.onSave}>
                                <FaSave size="20" color="#cdcdcd" />
                            </span> :
                            <span className="close" onClick={this.onEdit}>
                                <FaEdit size="18" color="#cdcdcd" />
                            </span>}
                        <h1 style={{ color: '#fff', marginLeft: 60, fontFamily: 'Helvetica', paddingTop: 12, fontSize: 22, fontWeight: 'bold' }}>{this.state.header}</h1>
                    </div>
                    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                        <div style={{ width: '5%', backgroundColor: 'rgb(1, 22, 48)', height: '91.6%' }}>
                            {/* {this.props.tooltip ? */}
                            <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                            </ReactTooltip>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'info' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'info' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub("info")} data-tip={'Payment Details'} onMouseEnter={this.handleEventPositioned}>
                                <FaInfoCircle style={{ fontSize: 20 }} />
                            </div>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'application' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'application' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub("application")} data-tip={'Application'} onMouseEnter={this.handleEventPositioned}>
                                <FaCogs style={{ fontSize: 20 }} />
                            </div>
                        </div>
                        {this.state.render === "info" ?
                            <div style={{ display: 'flex', width: '100%', height: 490, overflowY: 'scroll' }}>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 40 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Payment Type:</label>
                                        <div style={{ marginLeft: 10, width: '55%', fontSize: 12 }}>
                                            <select style={{ marginLeft: 10, width: '55%', fontSize: 12, color: '#000', border: '.4px solid #000', border: '.4px solid #000', padding: 0, color: '#000' }} className="form-control-sm" disabled={this.state.render_type === 'render' ? true : false} value={this.state.pay_type} name="pay_type" onChange={this.handleChange.bind(this)}>
                                                <option value=""> </option>
                                                <option value="Wire Transfer">Wire Transfer</option>
                                                <option value="ACH Transfer">ACH Transfer</option>
                                                <option value="PayPal">PayPal</option>
                                                <option value="Venmo">Venmo</option>
                                                <option value="Zelle">Zelle</option>
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.pay_type === 'Wire Transfer' ?
                                        <div style={{ width: '100%', height: 'auto' }}>
                                            <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                <label style={{ width: '25%', fontSize: 14 }}>Account Name:</label>
                                                <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="account_name" value={this.state.account_name} onChange={this.handleChange.bind(this)} />
                                            </div>
                                            <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                <label style={{ width: '25%', fontSize: 14 }}>Bank Name:</label>
                                                <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="bank_name" value={this.state.bank_name} onChange={this.handleChange.bind(this)} />
                                            </div>
                                            <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                <label style={{ width: '25%', fontSize: 14 }}>Account Number:</label>
                                                <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="account_number" value={this.state.account_number} onChange={this.handleChange.bind(this)} />
                                            </div>
                                            <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                <label style={{ width: '25%', fontSize: 14 }}>Routing Number:</label>
                                                <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="routing_number" value={this.state.routing_number} onChange={this.handleChange.bind(this)} />
                                            </div>
                                            <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                <label style={{ width: '25%', fontSize: 14 }}>SWIFT Code:</label>
                                                <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="swift_code" value={this.state.swift_code} onChange={this.handleChange.bind(this)} />
                                            </div>
                                            <div style={{ height: 'auto', alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                <label style={{ fontSize: 12 }}>Accepts ACH Tranfsers</label>
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                    color="default"
                                                    name="ach_check"
                                                    checked={this.state.ach_check}
                                                    //onClick={this.toggleProjectedDates}
                                                    //value={this.state.toggle_proj_dates}
                                                    onChange={() => this.setState({ ach_check: !this.state.ach_check })}
                                                />
                                            </div>
                                        </div> :
                                        this.state.pay_type === 'ACH Transfer' ?
                                            <div style={{ width: '100%', height: 'auto' }}>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Account Name:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="account_name" value={this.state.account_name} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Bank Name:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="bank_name" value={this.state.bank_name} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Account Number:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="account_number" value={this.state.account_number} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Routing Number:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="routing_number" value={this.state.routing_number} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>SWIFT Code:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="swift_code" value={this.state.swift_code} onChange={this.handleChange.bind(this)} />
                                                </div>
                                            </div> :
                                            this.state.pay_type === "PayPal" ?
                                                <div style={{ width: '100%', height: 'auto', paddingTop: 50 }}>
                                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                        <label style={{ width: '25%', fontSize: 14 }}>Account name, @username, email, or mobile:</label>
                                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="account_name" value={this.state.account_name} onChange={this.handleChange.bind(this)} />
                                                    </div>
                                                    <div style={{ height: 'auto', alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                        <label style={{ fontSize: 12 }}>Request that payments be sent using "Friends and Family"</label>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                            color="default"
                                                            name="paypal_ff"
                                                            checked={this.state.paypal_ff}
                                                            //onClick={this.toggleProjectedDates}
                                                            //value={this.state.toggle_proj_dates}
                                                            onChange={() => this.setState({ paypal_ff: !this.state.paypal_ff })}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                this.state.pay_type === "Venmo" ?
                                                    <div style={{ width: '100%', height: 'auto', paddingTop: 50 }}>
                                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                            <label style={{ width: '25%', fontSize: 14 }}>Account name or mobile:</label>
                                                            <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="account_name" value={this.state.account_name} onChange={this.handleChange.bind(this)} />
                                                        </div>
                                                    </div>
                                                    :
                                                    this.state.pay_type === "Zelle" ?
                                                        <div style={{ width: '100%', height: 'auto', paddingTop: 50 }}>
                                                            <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                                                <label style={{ width: '25%', fontSize: 14 }}>Email or mobile:</label>
                                                                <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="account_name" value={this.state.account_name} onChange={this.handleChange.bind(this)} />
                                                            </div>
                                                        </div>
                                                        : null}
                                </div>
                                <div style={{ width: '47.5%' }}>
                                    {this.state.pay_type === 'Wire Transfer' || this.state.pay_type === 'ACH Transfer' ?
                                        <div style={{ width: '100%', height: 'auto', marginTop: 40 }}>
                                            <div style={{ width: '100%', height: 'auto', border: '1px solid #ddd', borderRadius: 20 }}>
                                                <h1 style={{ fontSize: 12, color: '#000', marginTop: 10, marginLeft: 10, fontWeight: 'bolder' }}>BANK ADDRESS</h1>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Address:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="bank_address" value={this.state.bank_address} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>City:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="bank_city" value={this.state.bank_city} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>State:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="bank_state" value={this.state.bank_state} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Country:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="bank_country" value={this.state.bank_country} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Postal Code:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="bank_postal_code" value={this.state.bank_postal_code} onChange={this.handleChange.bind(this)} />
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', height: 'auto', border: '1px solid #ddd', borderRadius: 20, marginTop: 10 }}>
                                                <h1 style={{ fontSize: 12, color: '#000', marginTop: 10, marginLeft: 10, fontWeight: 'bolder' }}>ASSOCIATED COMPANY ADDRESS</h1>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Default:</label>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                        color="default"
                                                        name="default_address"
                                                        checked={this.state.co_address_default}
                                                        //onClick={this.toggleProjectedDates}
                                                        //value={this.state.toggle_proj_dates}
                                                        onChange={() => this.setState({ co_address_default: !this.state.co_address_default })}
                                                    />
                                                    {this.props.auth.user.company.address ?
                                                        <div style={{ width: '40%' }}>
                                                            <p style={{ fontSize: 14, lineHeight: .8, paddingTop: 15 }}>{this.props.auth.user.company.address.address ? this.props.auth.user.company.address.address + '\n' : ''}{this.props.auth.user.company.address.address2 ? this.props.auth.user.company.address.address2 + '\n' : ''}{this.props.auth.user.company.address.city ? this.props.auth.user.company.address.city + ', ' : ''}{this.props.auth.user.company.address.state ? this.props.auth.user.company.address.state + ', ' : ''}{this.props.auth.user.company.address.postal_code ? this.props.auth.user.company.address.postal_code : ''}</p>
                                                        </div> : null}
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Address:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="co_address" value={this.state.co_address} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>City:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="co_city" value={this.state.co_city} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>State:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="co_state" value={this.state.co_state} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Country:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="co_country" value={this.state.co_country} onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                    <label style={{ width: '25%', fontSize: 14 }}>Postal Code:</label>
                                                    <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="co_postal_code" value={this.state.co_postal_code} onChange={this.handleChange.bind(this)} />
                                                </div>
                                            </div>
                                        </div> :
                                        this.state.pay_type === "Zelle" ?
                                            <div style={{ width: '100%', height: 'auto', marginTop: 40 }}>
                                                <div style={{ width: '100%', height: 'auto', border: '1px solid #ddd', borderRadius: 20, marginTop: 10 }}>
                                                    <h1 style={{ fontSize: 12, color: '#000', marginTop: 10, marginLeft: 10, fontWeight: 'bolder' }}>ASSOCIATED COMPANY ADDRESS</h1>
                                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                        <label style={{ width: '25%', fontSize: 14 }}>Address:</label>
                                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="co_address" value={this.state.co_address} onChange={this.handleChange.bind(this)} />
                                                    </div>
                                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                        <label style={{ width: '25%', fontSize: 14 }}>City:</label>
                                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="co_city" value={this.state.co_city} onChange={this.handleChange.bind(this)} />
                                                    </div>
                                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                        <label style={{ width: '25%', fontSize: 14 }}>State:</label>
                                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="co_state" value={this.state.co_state} onChange={this.handleChange.bind(this)} />
                                                    </div>
                                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                        <label style={{ width: '25%', fontSize: 14 }}>Country:</label>
                                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="co_country" value={this.state.co_country} onChange={this.handleChange.bind(this)} />
                                                    </div>
                                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 10 }} className="form-group">
                                                        <label style={{ width: '25%', fontSize: 14 }}>Postal Code:</label>
                                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="co_postal_code" value={this.state.co_postal_code} onChange={this.handleChange.bind(this)} />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                </div>
                            </div> :
                            this.state.render === "application" ?
                                <div style={{ display: 'flex', width: '100%', height: 490, overflowY: 'scroll' }}>
                                    <div style={{ width: '47.5%' }}>
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 40 }} className="form-group">
                                            <label style={{ width: '100%', fontSize: 14, fontWeight: 'bolder' }}>APPLICATION</label>
                                        </div>
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            <label style={{ width: '25%', fontSize: 14 }}>Contracts:</label>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                color="default"
                                                name="ach_check"
                                                checked={this.state.app_contracts}
                                                //onClick={this.toggleProjectedDates}
                                                //value={this.state.toggle_proj_dates}
                                                onChange={() => this.setState({ app_contracts: !this.state.app_contracts })}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            <label style={{ width: '25%', fontSize: 14 }}>Settlement Statements:</label>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                                color="default"
                                                name="ach_check"
                                                checked={this.state.app_settlement_statements}
                                                //onClick={this.toggleProjectedDates}
                                                //value={this.state.toggle_proj_dates}
                                                onChange={() => this.setState({ app_settlement_statements: !this.state.app_settlement_statements })}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            <label style={{ width: '25%', fontSize: 14 }}>Specify Agent(s):</label>
                                            <div style={{ width: '55%' }}>
                                                <AgentAutocomplete onAgentChange={this.handleAgentChange} agentSelect={this.state.app_agents} edit={this.state.edit} agentList={this.props.company.agents} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '47.5%' }}>



                                    </div>
                                </div>
                                :
                                null}

                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    items: state.items,
    spotify: state.spotify,
    company: state.company,
    auth: state.auth,
    clients: state.clients
});

export default connect(mapStateToProps, { addClient, updateClient, spotifyLogin, getSpotify, getAttachment })(PayAccountModal);