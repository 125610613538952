import React, { Component } from 'react'
import axios from "axios";
import { tokenConfig } from './authActions';
import { jsPDF } from 'jspdf';
import { renderToString } from 'react-dom/server';
import DealMemoPDF from '../components/pdfs/pdf-dealmemo';
import ItineraryPDF from '../components/pdfs/itinerary-pdf';
import ContractPDF from '../components/pdfs/contract-pdf';
import {
    PDF_LOADING,
    PDF_LOADED,
    PDF_CLEAR,
} from "./types";
import _ from 'lodash';
import moment from 'moment';

export const setPdfLoading = () => {
    return {
        type: PDF_LOADING
    };
};

export const clearPdfs = () => {
    return {
        type: PDF_CLEAR
    };
};

export const RenderDealMemo = (deals, i) => (dispatch, getState) => {
    dispatch(setPdfLoading());
    //deals.map(async (item, index) => {
    const string = renderToString(<DealMemoPDF data={deals[i]} />);

    const pdf = new jsPDF('p', 'px', 'a4', true);
    //pdf.addImage(BlackLogo, 'PNG', 10, 10, 60, 62);
    //imgpdf.save();
    pdf.setProperties({
        title: 'Artist PDF'
    });

    pdf.html(string, {
        //margin: [40, 60, 40, 60],
        //backgroundColor: 'lightyellow',
        html2canvas: {
            scale: .95, // default is window.devicePixelRatio,
        },
        x: 20,
        y: 10,
        callback: function (pdf) {
            addFooters(pdf)
            var datastring = pdf.output('bloburl');
            //const data = {
            //    src: datastring,
            //    pdf: pdf
            //}
            dispatch({
                type: PDF_LOADED,
                payload: datastring
            });
        }
    });

    // pdf.html(string, {
    //     callback: function (pdf) {
    //         var datastring = pdf.output('bloburl');
    //         //const data = {
    //         //    src: datastring,
    //         //    pdf: pdf
    //         //}
    //         dispatch({
    //             type: PDF_LOADED,
    //             payload: datastring
    //         });
    //         //pdf.save();

    //         //var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    //         //var x = window.open();
    //         //x.document.open();
    //         //x.document.write(embed);
    //         //x.document.close();
    //         //pdf.save('pdf');


    //         //var iframe = document.createElement('iframe');
    //         //iframe.setAttribute('style', 'position:absolute;right:0; top:0; bottom:0; height:100%; width:650px; padding:20px;');
    //         //document.body.appendChild(iframe);

    //         //iframe.src = pdf.output('datauristring');
    //     },
    //     x: 10,
    //     y: 10
    // });
    //})

};

const addFooters = doc => {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont('helvetica', 'italic')
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width - 50, 620, {
            align: 'center'
        })
    }
}

// export const RenderContract = () => (dispatch, getState) => {
//     const packaging = {
//         note: 'hello'
//     }
//     // axios.post('http://54.213.249.65:5000/pdfs/generate', packaging, tokenConfig(getState))
//     //     .then(res =>
//     //         
//     //     )
//     //     .catch(err =>
//     //         
//     //     );


//     axios.post('http://54.213.249.65:5000/pdf/generate', packaging, tokenConfig(getState))
//         .then(() => axios.get('http://54.213.249.65:5000/pdf/fetch', tokenConfig(getState), { responseType: 'blob' }))
//         .then((res) => {
//         })
// }

export const RenderContract = (deals, i) => (dispatch, getState) => {
    dispatch(setPdfLoading());
    // const element = renderToString(<ContractPDF data={deals[i]} />);
    // const doc = new jsPDF("p", "mm", 'a4', true);
    // doc.addHTML(element, function callbackfunction() {
    //   doc.save("sample.pdf");
    // })
    const string = renderToString(<ContractPDF data={deals[i]} />);

    const doc = new jsPDF('p', 'px', 'a4', true);
    //pdf.addImage(BlackLogo, 'PNG', 10, 10, 60, 62);
    //imgpdf.save();
    doc.setProperties({
        title: 'Artist PDF'
    });


    // var margins = {
    //     top: 40,
    //     left: 40,
    //     right: 40,
    //     bottom: 40,
    //     //useFor: 'page'
    // }

    // //pdf.text('', pdf.internal.pageSize.width, pdf.internal.pageSize.height-20);

    // // let margin = 36;
    // // let srcwidth = 500;
    // // let scale = (595.28 - margin * 2) / srcwidth;
    //doc.addHTML(document.getElementById('print-pdf'), 10, 10, {pagesplit: true, margin: {top: 10, right: 10, bottom: 10, left: 10, useFor: 'page'}}, function () {doc.save("Report.pdf")})
    doc.html(string, {
        //margin: [40, 60, 40, 60],
        //backgroundColor: 'lightyellow',
        html2canvas: {
            scale: .95 // default is window.devicePixelRatio,
        },
        x: 20,
        y: 10,
        callback: function (doc) {
            addFooters(doc)
            //doc.save()
            var datastring = doc.output('bloburl');
            dispatch({
                type: PDF_LOADED,
                payload: datastring
            });
        },
    });


    // // doc.addHTML(string, 25, 50, {
    // //     retina: true,
    // //     pagesplit: true,
    // //     margin: {
    // //         top: 50,
    // //         right: 25,
    // //         bottom: 50,
    // //         left: 25,
    // //         useFor: 'page' // This property is mandatory to keep the margin to supsequent pages
    // //     }
    // // }, function () {
    // //     doc.save('test.pdf');
    // // });


    // //var w = document.getElementById("print-section").offsetWidth;
    // //var h = document.getElementById("print-section").offsetHeight;
    // // html2canvas(string, {
    // //     dpi: 300, // Set to 300 DPI
    // //     scale: 3, // Adjusts your resolution
    // //     onrendered: function (canvas) {
    // //         var img = canvas.toDataURL("image/jpeg", 1);
    // //         //var doc = new jsPDF('L', 'px', [250, 600]);
    // //         doc.addImage(img, 'JPEG', 0, 0, 250, 600);
    // //         doc.save('sample-file.pdf');
    // //     }
    // // });

    // // html2canvas(document.getElementById("print-section")).then(canvas => {
    // //     var data = canvas.toDataURL();
    // //     var pdfExportSetting = {
    // //       content: [
    // //         {
    // //           image: data,
    // //           width: 500
    // //         }
    // //       ]
    // //     };
    // //     pdfMake.createPdf(pdfExportSetting).download("test_file.pdf");
};

export const RenderItinerary = (deals, i) => (dispatch, getState) => {
    dispatch(setPdfLoading());
    const string = renderToString(<ItineraryPDF data={deals} />);
    const pdf = new jsPDF('l', 'px', 'a4', true);
    pdf.setProperties({
        title: 'Artist PDF'
    });

    pdf.html(string, {
        callback: function (pdf) {
            var datastring = pdf.output('bloburl');
            dispatch({
                type: PDF_LOADED,
                payload: datastring
            });
        },
        x: 10,
        y: 10
    });
};

export const generatePdf = (pdfData, pdftype, dir) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/pdf/raptor/${pdftype}`, pdfData, tokenConfig(getState))

        .then(async (res) => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data 
            //})
            //var base64data = new Buffer.from(res.data).toString('base64');
            let pdf = 'data:application/pdf;base64,' + res.data.pdf;

            // const url = window.URL.createObjectURL(new Blob([res.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'file.pdf'); //or any other extension
            // document.body.appendChild(link);
            // link.click();

            // downloadPDF(pdf, 'surferblood');
            // var embed = "<embed width='100%' height='100%' src='" + pdf + "'/>";
            // var x = window.open();
            // x.document.open();
            // x.document.write(embed);

            //const blob = new Blob([res.data], { type: 'application/pdf' })

            //window.open(blob, '_blank');
            if (dir === 'download') {
                const downloadLink = document.createElement("a");
                const fileName = `${res.data.title}.pdf`;
                downloadLink.href = pdf;
                downloadLink.download = fileName;
                downloadLink.click();
            } else {
                const obj = {
                    url: pdf,
                    title: res.data.title
                }
                dispatch({
                    type: PDF_LOADED,
                    payload: obj
                });
            }
            const file = {
                pdf: pdf,
                title: res.data.title
            }
            return file
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const downloadPdf = (pdfData, pdftype) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/pdf/raptor/${pdftype}`, pdfData, tokenConfig(getState))
        .then(async (res) => {
            let pdf = 'data:application/pdf;base64,' + res.data.pdf;

            const downloadLink = document.createElement("a");
            // const fileName = `${res.data.title}.pdf`;
            downloadLink.href = pdf;
            downloadLink.download = pdfData.addData.filename;
            downloadLink.click();

            return true
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            console.log(err.response.data)
            return false
        });
}

export const retreivePdf = pdfData => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/pdf/retreive/contract`, pdfData, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            //var base64data = new Buffer.from(res.data).toString('base64');
            let pdf = 'data:application/pdf;base64,' + res.data

            dispatch({
                type: PDF_LOADED,
                payload: pdf
            });
            return pdf
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const issuePdf = (pdfData, pdftype) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/pdf/issue`, pdfData, tokenConfig(getState))
        .then(async (res) => {
            return res
        })
        .catch(err => {
            console.log(err.response.data)
            return err.response.data
        });
}

export function downloadPDF(pdf, name) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `${name}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

export const setPdfOffer = (type, offer_checked) => async (dispatch, getState) => {
    var id_array = [];
    offer_checked.forEach(item => {
        id_array.push({ _id: item })
    })
    const ids = {
        _id: id_array
    }
    const { auth } = getState();

    // var token = localStorage.getItem('token');
    var token = auth.token;

    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }
    if (token) {
        config.headers['x-auth-token'] = token;
    }
    //var newData = await this.props.generatePdf(ids);

    const { offers } = getState();
    const { clients } = getState();
    const { items } = getState();
    const { festivals } = getState();
    const { buyers } = getState();
    const { contacts } = getState();

    return await axios.post(`${process.env.REACT_APP_API}/offerdetail/itinerary`, ids, config)
        .then(async (res) => {
            var newArray = [];
            res.data.map((item, index) => {
                var deals = [];
                var buyerid = "";
                var artistid = "";
                var venueid = "";
                var contactid = "";
                var productionid = "";
                var marketingid = "";
                var signatoryid = "";
                var prod_contact = {};
                var marketing_contact = {};

                const oIndex = offers.offers.findIndex(o => o._id === item._id);
                const o = offers.offers[oIndex];

                if (item._id === o._id) {
                    item = { ...item, ...o };
                    buyerid = o.buyer_id;
                    artistid = o.client_id;
                    venueid = o.venue_id;

                    if (item.buyer_contact_id) {
                        contactid = item.buyer_contact_id
                    } else if (o.contacts && o.contacts.buyer_contact) {
                        contactid = o.contacts.buyer_contact
                    }

                    if (item.contacts) {
                        if (item.contacts.add_contacts) {
                            item.contacts.add_contacts.map(con => {
                                var lc = con.role.toLowerCase();
                                if (lc.includes('production')) {
                                    productionid = con.contact_id;
                                    if (con.contact_id === 'Custom') {
                                        const c = {
                                            ...con.custom,
                                            contact: con.custom
                                        }
                                        console.log(con.custom, "CON CUSTOM!!!!!!!!!")
                                        prod_contact = c;
                                    }
                                }
                                if (lc.includes('marketing')) {
                                    marketingid = con.contact_id;
                                    if (con.contact_id === 'Custom') {
                                        marketing_contact = con.custom;
                                    }
                                }
                            })
                        }
                    }
                    // if (item.production_contact && item.production_contact.contact_id) {
                    //     productionid = item.production_contact.contact_id
                    // } else if (o.contacts && o.contacts.production_contact) {
                    //     productionid = o.contacts.production_contact.contact_id
                    // }

                    if (item.signatory_id) {
                        signatoryid = item.signatory_id
                    } else if (o.contacts && o.contacts.signatory) {
                        signatoryid = o.contacts.signatory
                    }
                }
                var artist_name = "";
                var client_loan_out = "";
                var artistObj = {};
                clients.clients.map(c => {
                    if (c._id === artistid) {
                        artistObj = c;
                        artist_name = c.client;
                        client_loan_out = c.loan_out;
                    }
                });
                var venue = [];
                items.items.map(v => {
                    if (v._id === venueid) {
                        venue = {
                            venue: v.venue_name,
                            address: v.address.address,
                            address2: v.address.address2,
                            city: v.address.city,
                            state: v.address.state,
                            country: v.address.country,
                            postalcode: v.address.postal_code,
                            phone: v.contact.main_phone,
                            website: v.website
                        };

                    };
                });
                festivals.items.map(v => {
                    if (v._id === venueid) {
                        venue = {
                            venue: v.festival,
                            address: v.address.address || "",
                            address2: v.address.address2 || "",
                            city: v.address.city || "",
                            state: v.address.state || "",
                            country: v.address.country || "",
                            postalcode: v.address.postal_code || "",
                            website: v.website
                        };

                    };
                });

                var buyer = [];
                buyers.buyers.map(b => {
                    if (b._id === buyerid) {
                        buyer = {
                            company: b.company,
                            address: b.address,
                            contact: b.contact
                        };
                    };
                });

                var buyer_contact = contacts.contacts.filter(item => item._id === contactid);
                var signatory = contacts.contacts.filter(item => item._id === signatoryid);

                //FIX THIS!!!!!- need to check item.contacts...
                if (productionid === 'Custom') {
                    // prod_contact = item.production_contact;
                } else if (productionid !== "") {
                    var n = contacts.contacts.filter(item => item._id === productionid);
                    prod_contact = n[0]
                }
                if (marketingid === 'Custom') {
                    // prod_contact = item.production_contact;
                } else if (marketingid !== "") {
                    var n = contacts.contacts.filter(item => item._id === marketingid);
                    marketing_contact = n[0]
                }

                newArray.push({
                    ...item, venue, buyer, artist_name, buyer_contact, prod_contact, signatory, marketing_contact, client_loan_out
                })
            })
            // newArray = _.sortBy(newArray, function (dateObj) {
            //     return new Date(dateObj.show_info[0].date);
            // });

            newArray = _.sortBy(newArray, function (dateObj) {
                const d = new Date(dateObj.show_info[0].date)
                const date_utc = Date.UTC(d.getUTCFullYear(), d.getUTCMonth(),
                    d.getUTCDate(), d.getUTCHours(),
                    d.getUTCMinutes(), d.getUTCSeconds());
                return new Date(date_utc);
            });

            // this.setState({
            //     pdf_data: newArray,
            //     deal_select: newArray[0]._id
            // })
            return newArray

            // if (type === 'dealmemo' || type === 'contract') {
            //     const pdfData = {
            //         pdfData: newArray[0]
            //     }
            //     const gen = await this.props.generatePdf(pdfData, pdftype);
            //     console.log('gen', gen)
            //     if (gen) {
            //         this.setState({
            //             pdf_title: gen.title
            //         })
            //     }
            // } else {
            //     console.log(newArray)
            //     const pdfData = {
            //         pdfData: newArray
            //     }
            //     const gen = await this.props.generatePdf(pdfData, pdftype);
            //     console.log('gen', gen)
            //     if (gen) {
            //         this.setState({
            //             pdf_title: gen.title
            //         })
            //     }
            // }
            // this.setState({
            //     loading: false
            // })
        })
        .catch(err =>
            console.log(err)
        );

}

export const setPdfCounts = (type, offer_checked) => async (dispatch, getState) => {
    var newArray = [];
    var idArray = [];
    function countRender(offer_checked, offers, clients, venues, festivals) {
        return new Promise(async (resolve, reject) => {
            offer_checked.forEach(item => {
                var index = offers.findIndex(off => off._id === item);
                var obj = {};
                if (index >= 0) {
                    obj = offers[index];
                }
                var _id = obj._id;
                idArray.push({ _id });

                var artist_name = [];
                var artistid = obj.client_id;
                var venueid = obj.venue_id;
                var venue = [];

                clients.map(c => {
                    if (c._id === artistid) {
                        artist_name = c.client;
                    }
                });
                venues.map(v => {
                    if (v._id === venueid) {
                        venue = {
                            venue: v.venue_name,
                            address: v.address.address,
                            address2: v.address.address2,
                            city: v.address.city,
                            state: v.address.state,
                            country: v.address.country,
                            postalcode: v.address.postal_code,
                            phone: v.contact.main_phone,
                            website: v.website
                        };

                    };
                });
                festivals.map(v => {
                    if (v._id === venueid) {
                        venue = {
                            venue: v.festival,
                            address: v.address.address,
                            address2: v.address.address2,
                            city: v.address.city,
                            state: v.address.state,
                            country: v.address.country,
                            postalcode: v.address.postal_code,
                            website: v.website
                        };

                    };
                });

                const newItem = {
                    ...obj,
                    venue: venue,
                    artist_name: artist_name
                }

                newArray.push(newItem);
            })
            const ids = {
                _id: idArray
            }
            await axios.post(`${process.env.REACT_APP_API}/counts/fetch`, ids, tokenConfig(getState))
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    resolve(null)
                });
        })
    }

    const { offers } = getState();
    const { clients } = getState();
    const { items } = getState();
    const { festivals } = getState();
    // const { buyers } = getState();
    // const { contacts } = getState();

    const counts = await countRender(offer_checked, offers.offers, clients.clients, items.items, festivals.items);
    var data2 = [];
    var span = [];
    newArray.map((item, index) => {
        const _id = item._id;
        var data = [];

        item.show_info.forEach(dg => {
            var min = moment(dg.date).format('MM/DD/YYYY');
            const d_id = dg._id;

            var filteredCounts = counts.filter(cnts => cnts._id === _id);

            if (filteredCounts.length <= 0) {
                data2.push({ span: [{ date: null, count: null, notes: '' }], artist: item.artist_name, show_info: dg, venueid: item.venueid, _id: _id, d_id: d_id, date: min, ...item })
            } else {
                filteredCounts[0].counts.forEach(con => {
                    if (con._id === d_id) {
                        var sortCounts = con.count_data.sort(function (a, b) {
                            return new Date(b.date) - new Date(a.date);
                        });

                        var daydiff = [];
                        sortCounts.map(d => {
                            //var nDate = moment(d.date);
                            var nDate = moment(d.date).format('MM/DD/YYYY');
                            var sDate = moment(min);
                            var diff = sDate.diff(nDate, 'days');

                            //span.push(diff);
                            span.push(nDate);
                            //daydiff.push({ x: diff, y: d.count });
                            daydiff.push({ date: nDate, count: d.count, notes: d.notes });
                        })
                        //var color = getRandomColor();
                        //data.push({ span: daydiff, color: color, artist: item.artist, date: dg, venueid: item.venueid, _id: _id, d_id: d_id }, ...data)
                        data2.push({ span: daydiff, artist: item.artist_name, show_info: dg, venueid: item.venueid, _id: _id, d_id: d_id, date: min, ...item })
                        //data.push({ span: daydiff, artist: item.artist, show_info: dg, venueid: item.venueid, _id: _id, d_id: d_id, date: min })
                    }
                })
            }
        })
    })
    span = new Set(span);
    span = Array.from(span).sort((a, b) => new Date(b) - new Date(a));
    const val1 = highest(span);
    const span2 = removeItem(span, val1) || [];
    const val2 = highest(span2);
    const span3 = removeItem(span2, val2) || [];
    const val3 = highest(span3);
    //const spanFin = removeItem(span3, val3);

    // const uniqueSpan = Array.from(new Set(span)).sort(function (a, b) {
    //     return new Date(b) - new Date(a);
    // }).reverse();
    // console.log(uniqueSpan)

    const dateRange = {
        date1: val1,
        date2: val2,
        date3: val3
    }

    const pdfData = {
        pdfData: data2,
        dateRange: dateRange
    }

    return pdfData
}

const highest = arr => (arr || []).reduce((acc, el) => {
    acc.k[el] = acc.k[el] ? acc.k[el] + 1 : 1
    acc.max = acc.max ? acc.max < acc.k[el] ? el : acc.max : el
    return acc
}, { k: {} }).max

const removeItem = (arr, val) => {
    var array = [...arr]; // make a separate copy of the array
    var index = array.indexOf(val)
    if (index !== -1) {
        array.splice(index, 1);
        return array
    }
}





// } else if (pdftype === 'tour-settlement-statement' || pdftype === 'finals-statement' || pdftype === "deposit-report") {

//     var id_array = [];
//     this.props.offer_checked.forEach(item => {
//         id_array.push({ _id: item })
//     })
//     const ids = {
//         _id: id_array
//     }

//     var token = localStorage.getItem('token')
//     const config = {
//         headers: {
//             "Content-type": "application/json"
//         }
//     }
//     if (token) {
//         config.headers['x-auth-token'] = token;
//     }
//     //var newData = await this.props.generatePdf(ids);

//     await axios.post(`${process.env.REACT_APP_API}/offerdetail/itinerary`, ids, config)
//         .then(async (res) => {
//             var newArray = [];
//             res.data.map((item, index) => {
//                 var artist_name = [];
//                 var artistid = "";
//                 var venueid = "";

//                 this.props.offers.offers.map(o => {
//                     if (item._id === o._id) {
//                         item = { ...item, ...o };
//                         artistid = o.client_id;
//                         venueid = o.venue_id;

//                     }
//                 })

//                 var artist_name = "";
//                 var artistObj = {};
//                 this.props.clients.clients.map(c => {
//                     if (c._id === artistid) {
//                         artistObj = c;
//                         artist_name = c.client;
//                     }
//                 });
//                 var venue = [];
//                 this.props.items.items.map(v => {
//                     if (v._id === venueid) {
//                         venue = {
//                             venue: v.venue_name,
//                             address: v.address.address,
//                             address2: v.address.address2,
//                             city: v.address.city,
//                             state: v.address.state,
//                             country: v.address.country,
//                             postalcode: v.address.postal_code,
//                             phone: v.contact.main_phone,
//                             website: v.website
//                         };

//                     };
//                 });
//                 this.props.festivals.items.map(v => {
//                     if (v._id === venueid) {
//                         venue = {
//                             venue: v.festival,
//                             address: v.address.address || "",
//                             address2: v.address.address2 || "",
//                             city: v.address.city || "",
//                             state: v.address.state || "",
//                             country: v.address.country || "",
//                             postalcode: v.address.postal_code || "",
//                             website: v.website
//                         };

//                     };
//                 });

//                 newArray.push({
//                     ...item, venue, artist_name
//                 })
//             })
//             newArray = _.sortBy(newArray, function (dateObj) {
//                 const d = new Date(dateObj.show_info[0].date)
//                 const date_utc = Date.UTC(d.getUTCFullYear(), d.getUTCMonth(),
//                     d.getUTCDate(), d.getUTCHours(),
//                     d.getUTCMinutes(), d.getUTCSeconds());

//                 // const d_utc = new Date(date_utc)
//                 return new Date(date_utc);
//             });
//             this.setState({
//                 pdf_data: newArray,
//                 deal_select: newArray[0]._id
//             })
//             const pdfData = {
//                 pdfData: newArray
//             }
//             const gen = await this.props.generatePdf(pdfData, pdftype);
//             if (gen) {
//                 this.setState({
//                     pdf_title: gen.title
//                 })
//             }
//             this.setState({
//                 loading: false
//             })
//         })
//         .catch(err =>
//             console.log(err)
//         );

// } else if (pdftype === "pitch-deck") {
//     var data_array = [];
//     this.props.client_checked.forEach(item => {
//         const cIndex = this.props.clients.clients.findIndex(cli => cli._id === item);
//         if (cIndex >= 0) {
//             var client = this.props.clients.clients[cIndex];
//             data_array = [...data_array, client]
//         }
//     })

//     const pdfData = {
//         pdfData: data_array
//     }

//     // const gen = await this.props.generatePdf(pdfData, pdftype);
//     const gen = await this.props.downloadPdf(pdfData, pdftype);
//     if (gen) {
//         // this.setState({
//         //     pdf_title: gen.title
//         // })
//         console.log('gen true')
//     }
//     this.setState({
//         loading: false
//     })

//     // var token = localStorage.getItem('token')
//     // const config = {
//     //     headers: {
//     //         "Content-type": "application/json"
//     //     }
//     // }
//     // if (token) {
//     //     config.headers['x-auth-token'] = token;
//     // }


//     // await axios.post(`${process.env.REACT_APP_API}/offerdetail/itinerary`, ids, config)
//     //     .then(async (res) => {
//     //         var newArray = [];
//     //         res.data.map((item, index) => {
//     //             var artist_name = [];
//     //             var artistid = "";
//     //             var venueid = "";

//     //             this.props.offers.offers.map(o => {
//     //                 if (item._id === o._id) {
//     //                     item = { ...item, ...o };
//     //                     artistid = o.client_id;
//     //                     venueid = o.venue_id;

//     //                 }
//     //             })

//     //             var artist_name = "";
//     //             var artistObj = {};
//     //             this.props.clients.clients.map(c => {
//     //                 if (c._id === artistid) {
//     //                     artistObj = c;
//     //                     artist_name = c.client;
//     //                 }
//     //             });
//     //             var venue = [];
//     //             this.props.items.items.map(v => {
//     //                 if (v._id === venueid) {
//     //                     venue = {
//     //                         venue: v.venue_name,
//     //                         address: v.address.address,
//     //                         address2: v.address.address2,
//     //                         city: v.address.city,
//     //                         state: v.address.state,
//     //                         country: v.address.country,
//     //                         postalcode: v.address.postal_code,
//     //                         phone: v.contact.main_phone,
//     //                         website: v.website
//     //                     };

//     //                 };
//     //             });
//     //             this.props.festivals.items.map(v => {
//     //                 if (v._id === venueid) {
//     //                     venue = {
//     //                         venue: v.festival,
//     //                         address: v.address.address || "",
//     //                         address2: v.address.address2 || "",
//     //                         city: v.address.city || "",
//     //                         state: v.address.state || "",
//     //                         country: v.address.country || "",
//     //                         postalcode: v.address.postal_code || "",
//     //                         website: v.website
//     //                     };

//     //                 };
//     //             });

//     //             newArray.push({
//     //                 ...item, venue, artist_name
//     //             })
//     //         })
//     //         newArray = _.sortBy(newArray, function (dateObj) {
//     //             const d = new Date(dateObj.show_info[0].date)
//     //             const date_utc = Date.UTC(d.getUTCFullYear(), d.getUTCMonth(),
//     //                 d.getUTCDate(), d.getUTCHours(),
//     //                 d.getUTCMinutes(), d.getUTCSeconds());

//     //             // const d_utc = new Date(date_utc)
//     //             return new Date(date_utc);
//     //         });
//     //         this.setState({
//     //             pdf_data: newArray,
//     //             deal_select: newArray[0]._id
//     //         })
//     //         const pdfData = {
//     //             pdfData: newArray
//     //         }
//     //         const gen = await this.props.generatePdf(pdfData, pdftype);
//     //         if (gen) {
//     //             this.setState({
//     //                 pdf_title: gen.title
//     //             })
//     //         }
//     //         this.setState({
//     //             loading: false
//     //         })
//     //     })
//     //     .catch(err =>
//     //         console.log(err)
//     //     );

// } else {
//     this.setState({
//         error: true,
//         loading: false
//     })
//     alert('Error: no pdf to render')
// }