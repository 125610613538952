import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FaSave, FaFolder, FaUser, FaUnlockAlt, FaEdit } from 'react-icons/fa';
import { register } from '../../actions/authActions';
import { addEmployee, addEmployeeSend, updateEmployee, sendAccessEmail } from '../../actions/companyActions';
import { accountLookup } from '../../actions/accessActions';
import { clearErrors } from '../../actions/errorActions';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import LocationAutocomplete from '../autocompletes/location-autocomplete';
import Permissions from '../../components/auth/permissions.modal-component';
import ClientPermissions from '../../components/auth/permissions.client.modal-component';
import { confirmAlert } from 'react-confirm-alert';

class RegisterModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            render: 'info',
            id: '',
            header: 'NEW USER',
            edit: true,
            firstname: '',
            lastname: '',
            company: '',
            role: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            website: '',
            facebook: '',
            instagram: '',
            email: '',
            officephone: '',
            cellphone: '',
            homephone: '',
            fax: '',
            render_type: 'enter',
            admin: false,
            permissions: { admin: false, owner: false, access: '', clients: false, venues: false, buyers: false, offers: false, contacts: false, festivals: false },
            // offer_options: { access_all: true, financials: false, counts: false, deal_terms: false },
            offer_options: { full: false, read: false, write: false, delete: false, reports: false, financials: false },
            client_options: { full: false, read: false, write: false, delete: false, reports: false },
            venue_options: { full: false, read: false, write: false, delete: false, reports: false },
            buyer_options: { full: false, read: false, write: false, delete: false, reports: false },
            contact_options: { full: false, read: false, write: false, delete: false, reports: false },
            festival_options: { full: false, read: false, write: false, delete: false, reports: false },
            count_options: { full: false, read: false, write: false, delete: false, reports: false },
            packaging_options: { full: false, read: false, write: false, delete: false, reports: false },
            ar_options: { full: false, read: false, write: false, delete: false, reports: false },
            _regi: true,
            access: 'employee',
            client_access: [],
            _init: false,
            active: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePermChange = this.handlePermChange.bind(this);
        this.handlePermSubChange = this.handlePermSubChange.bind(this);
        this.handlePermOfferChange = this.handlePermOfferChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.bool,
        error: PropTypes.object.isRequired,
        register: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired,
    }

    componentDidMount() {
        // const { error, isAuthenticated } = this.props;
        // if(error !== prevProps.error) {
        //     if(error.id === 'REGISTER_FAIL') {
        //         alert(error.msg.msg);
        //     } else {
        //         alert('Created Successfully!')
        //     }
        // }

        if (this.props.type === 'render') {
            const data_array = this.props.admin_user_data;
            console.log(data_array, "DATA ARRAY")
            this.setState({
                render_type: 'render',
                edit: false,
                id: data_array._id,
                header: data_array.name.first.toUpperCase() + " " + data_array.name.last.toUpperCase(),
                firstname: data_array.name.first,
                lastname: data_array.name.last,
                role: data_array.role,
                address: data_array.address.address,
                address2: data_array.address.address2,
                city: data_array.address.city,
                state: data_array.address.state,
                country: data_array.address.country,
                zipcode: data_array.address.postal_code,
                email: data_array.email,
                officephone: data_array.contact.office,
                cellphone: data_array.contact.mobile,
                homephone: data_array.contact.home,
                fax: data_array.contact.fax,
                _regi: data_array._regi,
                access: data_array.access,
                client_access: data_array.access === 'client' ? data_array.client_access : [],
                _init: data_array._init,
                active: data_array.active
            })
            if (data_array.permissions) {
                const perm = data_array.permissions;
                this.setState({
                    permissions: perm,
                    admin: perm.admin,
                    offer_options: perm.offers,
                    client_options: perm.clients,
                    venue_options: perm.venues,
                    buyer_options: perm.buyers,
                    contact_options: perm.contacts,
                    festival_options: perm.festivals,
                    // count_options: perm.counts,
                    // packaging_options: perm.packaging,
                    // ar_options: perm.ar,
                })
            }
        } else {
            this.setState({
                render_type: 'enter',
                header: 'NEW USER'
            })
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleLocation = (results, title) => {
        var street = "";
        var route = "";
        var subpremise = "";
        var locality = "";
        var state = "";
        var country = "";
        var postal_code = "";

        results[0].address_components.map(item => {
            if (item.types.includes('subpremise')) {
                subpremise = item.long_name
            }
            if (item.types.includes('sublocality')) {
                subpremise = item.long_name
            }
            if (item.types.includes('street_number')) {
                street = item.long_name
            }
            if (item.types.includes('route')) {
                route = item.short_name
            }
            if (item.types.includes('locality')) {
                locality = item.long_name
            }
            if (item.types.includes('administrative_area_level_1')) {
                state = item.long_name
            }
            if (item.types.includes('country')) {
                country = item.long_name
            }
            if (item.types.includes('postal_code')) {
                postal_code = item.long_name
            }
        })
        this.setState({
            address: street + ' ' + route,
            address2: subpremise,
            country: country,
            city: locality,
            state: state,
            zipcode: postal_code,
            company: title,
        })
    }

    // onSubmit = e => {
    //     e.preventDefault();

    //     const { name, email, password } = this.state;

    //     const newUser = {
    //         name,
    //         email,
    //         password
    //     }

    //     this.props.register(newUser);
    // }

    onSaveNew = async () => {
        const email = this.state.email;
        const account_exists = await this.props.accountLookup(email)
        console.log(account_exists, email)
        if(account_exists){
            this.onSave();
        } else {
            this.saveNewDialog();
        }
    }

    saveNewDialog = () => {
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>New User</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Send account creation email to {this.state.email}?
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.onSave('send'); onClose(); }}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { this.onSave(); onClose(); }}>No, save only</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { onClose(); }}>Cancel</button>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }
        confirmAlert(options)
    }

    onSave = async (type) => {
        if (!this.state.firstname || !this.state.lastname) {
            alert('Please enter a first or last name to continue')
        } else {
            const name = {
                first: this.state.firstname,
                last: this.state.lastname
            };
            const address = {
                address: this.state.address,
                address2: this.state.address2,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                postal_code: this.state.zipcode
            };
            const contact = {
                // email: this.state.email,
                mobile: this.state.cellphone,
                office: this.state.officephone,
                home: this.state.homephone,
                fax: this.state.fax
            };

            // var permissions = { admin: false, owner: false, access: '', clients: false, venues: false, buyers: false, offers: false, contacts: false, festivals: false };
            var perm_state = this.state.permissions;
            const offers_perm_state = this.state.offer_options;
            const clients_perm_state = this.state.client_options;
            const venues_perm_state = this.state.venue_options;
            const buyers_perm_state = this.state.buyer_options;
            const contacts_perm_state = this.state.contact_options;
            const festivals_perm_state = this.state.festival_options;

            var permissions = {
                admin: perm_state.admin,
                owner: perm_state.owner,
                access: perm_state.access,
                offers: {
                    full: offers_perm_state.full,
                    read: offers_perm_state.read,
                    write: offers_perm_state.write,
                    delete: offers_perm_state.delete,
                    reports: offers_perm_state.reports,
                    financials: offers_perm_state.financials,
                },
                clients: {
                    full: clients_perm_state.full,
                    read: clients_perm_state.read,
                    write: clients_perm_state.write,
                    delete: clients_perm_state.delete,
                    reports: clients_perm_state.reports,
                },
                venues: {
                    full: venues_perm_state.full,
                    read: venues_perm_state.read,
                    write: venues_perm_state.write,
                    delete: venues_perm_state.delete,
                    reports: venues_perm_state.reports,
                },
                buyers: {
                    full: buyers_perm_state.full,
                    read: buyers_perm_state.read,
                    write: buyers_perm_state.write,
                    delete: buyers_perm_state.delete,
                    reports: buyers_perm_state.reports,
                },
                contacts: {
                    full: contacts_perm_state.full,
                    read: contacts_perm_state.read,
                    write: contacts_perm_state.write,
                    delete: contacts_perm_state.delete,
                    reports: contacts_perm_state.reports,
                },
                festivals: {
                    full: festivals_perm_state.full,
                    read: festivals_perm_state.read,
                    write: festivals_perm_state.write,
                    delete: festivals_perm_state.delete,
                    reports: festivals_perm_state.reports,
                }
            };
            var client_access = [];
            if (this.state.access === 'client') {
                client_access = this.state.client_access;
            } else if (this.state.access === 'employee') {
                if (perm_state.admin) {
                    permissions.offers = {
                        full: true,
                        read: true,
                        write: true,
                        delete: true,
                        reports: true,
                        financials: true,
                    };
                    permissions.clients = {
                        full: true,
                        read: true,
                        write: true,
                        delete: true,
                        reports: true,
                    };
                    permissions.venues = {
                        full: true,
                        read: true,
                        write: true,
                        delete: true,
                        reports: true,
                    };
                    permissions.buyers = {
                        full: true,
                        read: true,
                        write: true,
                        delete: true,
                        reports: true,
                    };
                    permissions.contacts = {
                        full: true,
                        read: true,
                        write: true,
                        delete: true,
                        reports: true,
                    };
                    permissions.festivals = {
                        full: true,
                        read: true,
                        write: true,
                        delete: true,
                        reports: true,
                    };
                } else {
                    if (offers_perm_state.full) {
                        permissions.offers = {
                            full: offers_perm_state.full,
                            read: true,
                            write: true,
                            delete: true,
                            reports: true,
                            financials: true,
                        };
                    };
                    if (clients_perm_state.full) {
                        permissions.clients = {
                            full: clients_perm_state.full,
                            read: true,
                            write: true,
                            delete: true,
                            reports: true,
                        };
                    };
                    if (venues_perm_state.full) {
                        permissions.venues = {
                            full: venues_perm_state.full,
                            read: true,
                            write: true,
                            delete: true,
                            reports: true,
                        };
                    };
                    if (buyers_perm_state.full) {
                        permissions.buyers = {
                            full: buyers_perm_state.full,
                            read: true,
                            write: true,
                            delete: true,
                            reports: true,
                        };
                    };
                    if (contacts_perm_state.full) {
                        permissions.contacts = {
                            full: contacts_perm_state.full,
                            read: true,
                            write: true,
                            delete: true,
                            reports: true,
                        };
                    };
                    if (festivals_perm_state.full) {
                        permissions.festivals = {
                            full: festivals_perm_state.full,
                            read: true,
                            write: true,
                            delete: true,
                            reports: true,
                        };
                    };
                }
            };

            const employee = {
                name: name,
                address: address,
                email: this.state.email,
                contact: contact,
                access: this.state.access,
                active: this.state.active,
                role: this.state.role,
                permissions: permissions,
                client_access: client_access
            }

            console.log(employee, 'EMPLOYEE')

            if (type === 'update') {
                const id = this.state.id;
                const log_status = await this.props.updateEmployee(employee, id)
                console.log(log_status, 'UPDATE EMPLOYEE')
                if (log_status.includes('Error:')) {
                    this.props.toggleErrorSnackbar(log_status);
                } else {
                    this.setState({
                        header: this.state.firstname + this.state.lastname,
                        edit: false,
                        render_type: 'render',
                    })
                    this.props.toggleStatusSnackbar('User updated successfully');
                }
            } else if (type === 'new') {
                const log_status = await this.props.addEmployee(employee)
                console.log(log_status, 'ADD EMPLOYEE')
                if (log_status.includes('Error:')) {
                    this.props.toggleErrorSnackbar(log_status);
                } else {
                    this.setState({
                        header: this.state.firstname + this.state.lastname,
                        edit: false,
                        render_type: 'render',
                    })
                    this.props.toggleStatusSnackbar('User added successfully');
                }
            } else if (type === 'send'){
                const log_status = await this.props.addEmployeeSend(employee)
                console.log(log_status, 'ADD EMPLOYEE SEND')
                if (log_status.includes('Error:')) {
                    this.props.toggleErrorSnackbar(log_status);
                } else {
                    this.setState({
                        header: this.state.firstname + this.state.lastname,
                        edit: false,
                        render_type: 'render',
                    })
                    this.props.toggleStatusSnackbar('User added successfully');
                }
            }
        }
    }

    updatePermissions = (perms, client_id) => {
        if (this.state.access === 'client') {
            var client_access = this.state.client_access;
            const index = client_access.findIndex(i => i._id === client_id);
            client_access[index].permissions = perms;
            this.setState({
                client_access: client_access
            });
        }
    }

    onSendEmailAccess = () => {
        const email = {
            email: this.state.email,
        }
        this.props.sendAccessEmail(email);
    }

    toggleSub = (e) => {
        this.setState({
            render: e,
        })
    }

    handleClick = () => {
        this.props.clearErrors();
        this.props.toggle();
    }

    onEdit = () => {
        this.setState({
            edit: true
        })
    }

    handlePermChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let permissions = Object.assign({}, prevState.permissions);
            permissions[name] = !permissions[name];
            console.log(permissions)
            return { permissions };
        })
    }

    handlePermSubChange(event, sub) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let options = Object.assign({}, prevState[sub]);
            options[name] = !options[name];
            return { [sub]: options };
        })
    }

    handlePermOfferChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        if (this.state.permissions.offers) {
            this.setState(prevState => {
                let permissions = Object.assign({}, prevState.permissions);
                permissions[name] = !permissions[name];
                return { permissions };
            })
        } else {
            const permissions = { admin: false, clients: true, venues: true, buyers: true, offers: true, contacts: true, festivals: true };
            this.setState({
                permissions: permissions
            })
        }
    }

    render() {

        return (
            <div className="mode-t">
                <div className="mode-t-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50, marginTop: -50 }}>
                        <span className="close" onClick={this.handleClick}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        {this.state.edit ?
                            <span className="close" onClick={this.state.render_type === 'enter' ? () => this.onSaveNew() : () => this.onSave()}>
                                <FaSave size="20" color="#cdcdcd" />
                            </span> :
                            <span className="close" onClick={this.onEdit}>
                                <FaEdit size="18" color="#cdcdcd" />
                            </span>}
                        {this.state.active ?
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>ACTIVE</p>
                                <div style={{ backgroundColor: 'green', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span> :
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>INACTIVE</p>
                                <div style={{ backgroundColor: 'red', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span>}
                        <h1 style={{ color: '#fff', marginLeft: 60, fontFamily: 'Helvetica', paddingTop: 12, fontSize: 22 }}>{this.state.header}</h1>
                    </div>
                    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                        <div style={{ width: '5%', backgroundColor: 'rgb(1, 22, 48)' }}>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'info' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'info' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer' }} onClick={() => this.toggleSub("info")}>
                                <FaUser style={{ marginTop: 15, fontSize: 20, marginLeft: 15 }} />
                            </div>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'permissions' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'permissions' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer' }} onClick={() => this.toggleSub("permissions")}>
                                <FaUnlockAlt style={{ marginTop: 15, fontSize: 20, marginLeft: 15 }} />
                            </div>
                        </div>
                        {this.state.render === 'info' ?
                            <div style={{ display: 'flex', width: '100%', height: 550, overflowY: 'scroll' }}>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 30 }} className="form-group">
                                        <label style={{ width: '15%', fontSize: 14 }}>Name:</label>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '65%', marginLeft: 10 }}>
                                            <input style={{ width: '50%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.render_type === 'enter' ? false : true} type="text" placeholder="First Name" name="firstname" value={this.state.firstname} onChange={this.handleChange.bind(this)} />
                                            <input style={{ marginLeft: 10, width: '50%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.render_type === 'enter' ? false : true} type="text" placeholder="Last Name" name="lastname" value={this.state.lastname} onChange={this.handleChange.bind(this)} />
                                        </div>
                                    </div>
                                    {this.state.render_type === 'enter' ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, width: '90%', marginTop: 15 }} className="form-group">
                                            <LocationAutocomplete handleLocation={this.handleLocation} Gkey={this.props.auth.user.api.google} />
                                        </div> : null}
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Home Address:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.render_type === 'enter' ? false : true} type="text" name="address" value={this.state.address} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}> </label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.render_type === 'enter' ? false : true} type="text" name="address2" value={this.state.address2} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>City:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.render_type === 'enter' ? false : true} type="text" name="city" value={this.state.city} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>State:</label>
                                        <select style={{ marginLeft: 10, width: '55%', height: 28, fontSize: 12, border: '.4px solid #000', color: '#000' }} className="form-control-sm" disabled={this.state.render_type === 'enter' ? false : true} value={this.state.state} name="state" onChange={this.handleChange.bind(this)}>
                                            <option value=""> </option>
                                            <option value="Alabama">Alabama</option>
                                            <option value="Alaska">Alaska</option>
                                            <option value="Arizona">Arizona</option>
                                            <option value="Arkansas">Arkansas</option>
                                            <option value="California">California</option>
                                            <option value="Colorado">Colorado</option>
                                            <option value="Connecticut">Connecticut</option>
                                            <option value="Delaware">Delaware</option>
                                            <option value="District Of Columbia">District Of Columbia</option>
                                            <option value="Florida">Florida</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Hawaii">Hawaii</option>
                                            <option value="Idaho">Idaho</option>
                                            <option value="Illinois">Illinois</option>
                                            <option value="Indiana">Indiana</option>
                                            <option value="Iowa">Iowa</option>
                                            <option value="Kansas">Kansas</option>
                                            <option value="Kentucky">Kentucky</option>
                                            <option value="Louisiana">Louisiana</option>
                                            <option value="Maine">Maine</option>
                                            <option value="Maryland">Maryland</option>
                                            <option value="Massachusetts">Massachusetts</option>
                                            <option value="Michigan">Michigan</option>
                                            <option value="Minnesota">Minnesota</option>
                                            <option value="Mississippi">Mississippi</option>
                                            <option value="Missouri">Missouri</option>
                                            <option value="Montana">Montana</option>
                                            <option value="Nebraska">Nebraska</option>
                                            <option value="Nevada">Nevada</option>
                                            <option value="New Hampshire">New Hampshire</option>
                                            <option value="New Jersey">New Jersey</option>
                                            <option value="New Mexico">New Mexico</option>
                                            <option value="New York">New York</option>
                                            <option value="North Carolina">North Carolina</option>
                                            <option value="North Dakota">North Dakota</option>
                                            <option value="Ohio">Ohio</option>
                                            <option value="Oklahoma">Oklahoma</option>
                                            <option value="Oregon">Oregon</option>
                                            <option value="Pennsylvania">Pennsylvania</option>
                                            <option value="Rhode Island">Rhode Island</option>
                                            <option value="South Carolina">South Carolina</option>
                                            <option value="South Dakota">South Dakota</option>
                                            <option value="Tennessee">Tennessee</option>
                                            <option value="Texas">Texas</option>
                                            <option value="Utah">Utah</option>
                                            <option value="Vermont">Vermont</option>
                                            <option value="Virginia">Virginia</option>
                                            <option value="Washington">Washington</option>
                                            <option value="West Virginia">West Virginia</option>
                                            <option value="Wisconsin">Wisconsin</option>
                                            <option value="Wyoming">Wyoming</option>
                                        </select>
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Country:</label>
                                        <select style={{ marginLeft: 10, width: '55%', fontSize: 12, height: 28, border: '.4px solid #000', color: '#000' }} className="form-control-sm" disabled={this.state.render_type === 'enter' ? false : true} name="country" value={this.state.country} onChange={this.handleChange.bind(this)}>
                                            <option value=""> </option>
                                            <option value="United States">United States</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Afganistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bonaire">Bonaire</option>
                                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canary Islands">Canary Islands</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Channel Islands">Channel Islands</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos Island">Cocos Island</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote DIvoire">Cote DIvoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curaco">Curacao</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="East Timor">East Timor</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands">Falkland Islands</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Ter">French Southern Ter</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Great Britain">Great Britain</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Hawaii">Hawaii</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="India">India</option>
                                            <option value="Iran">Iran</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea North">Korea North</option>
                                            <option value="Korea Sout">Korea South</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Laos">Laos</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libya">Libya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macau">Macau</option>
                                            <option value="Macedonia">Macedonia</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Midway Islands">Midway Islands</option>
                                            <option value="Moldova">Moldova</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Nambia">Nambia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherland Antilles">Netherland Antilles</option>
                                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                            <option value="Nevis">Nevis</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau Island">Palau Island</option>
                                            <option value="Palestine">Palestine</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Phillipines">Philippines</option>
                                            <option value="Pitcairn Island">Pitcairn Island</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                                            <option value="Republic of Serbia">Republic of Serbia</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russia">Russia</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="St Barthelemy">St Barthelemy</option>
                                            <option value="St Eustatius">St Eustatius</option>
                                            <option value="St Helena">St Helena</option>
                                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                            <option value="St Lucia">St Lucia</option>
                                            <option value="St Maarten">St Maarten</option>
                                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                            <option value="Saipan">Saipan</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Samoa American">Samoa American</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syria">Syria</option>
                                            <option value="Tahiti">Tahiti</option>
                                            <option value="Taiwan">Taiwan</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania">Tanzania</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Erimates">United Arab Emirates</option>
                                            <option value="Uraguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Vatican City State">Vatican City State</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                            <option value="Wake Island">Wake Island</option>
                                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zaire">Zaire</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Postal Code:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.render_type === 'enter' ? false : true} type="text" name="zipcode" value={this.state.zipcode} onChange={this.handleChange} />
                                    </div>
                                    {!this.state._init ?
                                        <button style={{ fontSize: 12, color: '#F2F2F2', fontWeight: 'bold', marginTop: 15, marginLeft: 60 }} className="btn btn-secondary px-5" type="button" onClick={this.onSendEmailAccess}>Send Access Email</button> : null}
                                </div>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginTop: 30 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Access Type:</label>
                                        <select style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="access" value={this.state.access} onChange={this.handleChange}>
                                            <option value="client">Client</option>
                                            <option value="employee">Employee</option>
                                        </select>
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Role:</label>
                                        <select style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="role" value={this.state.role} onChange={this.handleChange}>
                                            <option value=""> </option>
                                            <option value="agent">Agent</option>
                                            <option value="assistant">Assistant</option>
                                            <option value="finance">Business Finance</option>
                                            <option value="intern">Intern</option>
                                        </select>
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Email Address:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.render_type === 'enter' ? false : !this.state._init && this.state.edit ? false : true} type="text" name="email" value={this.state.email} onChange={this.handleChange} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Office Phone:</label>
                                        <PhoneInput
                                            containerStyle={{ marginLeft: 10, width: '55%', borderRadius: 5, border: '.4px solid #000' }}
                                            inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                            className="form-control-sm"
                                            disabled={this.state.render_type === 'enter' ? false : !this.state._init && this.state.edit ? false : true}
                                            placeholder={''}
                                            renderStringAsFlag={this.state.render_type === 'enter' ? true : !this.state._init && this.state.edit ? true : false}
                                            value={this.state.officephone}
                                            onChange={officephone => this.setState({ officephone })} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Cell Phone:</label>
                                        <PhoneInput
                                            containerStyle={{ marginLeft: 10, width: '55%', borderRadius: 5, border: '.4px solid #000' }}
                                            inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                            className="form-control-sm"
                                            disabled={this.state.render_type === 'enter' ? false : !this.state._init && this.state.edit ? false : true}
                                            placeholder={''}
                                            renderStringAsFlag={this.state.render_type === 'enter' ? true : !this.state._init && this.state.edit ? true : false}
                                            value={this.state.cellphone}
                                            onChange={cellphone => this.setState({ cellphone })} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Home Phone:</label>
                                        <PhoneInput
                                            containerStyle={{ marginLeft: 10, width: '55%', borderRadius: 5, border: '.4px solid #000' }}
                                            inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                            className="form-control-sm"
                                            disabled={this.state.render_type === 'enter' ? false : !this.state._init && this.state.edit ? false : true}
                                            placeholder={''}
                                            renderStringAsFlag={this.state.render_type === 'enter' ? true : !this.state._init && this.state.edit ? true : false}
                                            value={this.state.homephone}
                                            onChange={homephone => this.setState({ homephone })} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Fax Number:</label>
                                        <PhoneInput
                                            containerStyle={{ marginLeft: 10, width: '55%', borderRadius: 5, border: '.4px solid #000' }}
                                            inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                            className="form-control-sm"
                                            disabled={this.state.render_type === 'enter' ? false : !this.state._init && this.state.edit ? false : true}
                                            placeholder={''}
                                            renderStringAsFlag={this.state.render_type === 'enter' ? true : !this.state._init && this.state.edit ? true : false}
                                            value={this.state.fax}
                                            onChange={fax => this.setState({ fax })} />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Notes:</label>
                                        <textarea style={{ marginLeft: 10, width: '55%', fontSize: 12, minHeight: 100, maxHeight: 100, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} name="notes" value={this.state.notes} onChange={this.handleChange.bind(this)} />
                                    </div>
                                </div>
                            </div> :
                            this.state.render === 'permissions' ?
                                this.state.access === 'employee' ?
                                    <Permissions handlePermChange={this.handlePermChange} handlePermOfferChange={this.handlePermOfferChange} handlePermSubChange={this.handlePermSubChange} {...this.state} /> :
                                    <ClientPermissions handlePermChange={this.handlePermChange} handlePermOfferChange={this.handlePermOfferChange} handlePermSubChange={this.handlePermSubChange} updatePermissions={this.updatePermissions} {...this.state} /> :
                                null}
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    error: state.error
})

export default connect(mapStateToProps, { register, clearErrors, addEmployee, addEmployeeSend, updateEmployee, sendAccessEmail, accountLookup })(RegisterModal);