import React, { useEffect, useState } from 'react';
import { CSSTransitionGroup } from 'react-transition-group'
import ChatAvatars from './chat.avatars';
import ChatAvatar from './chat.avatar';
import CloseButton from './close-avatar';
import { FaPaperPlane, FaArrowLeft, FaArrowCircleLeft, FaTimes } from 'react-icons/fa';
import moment from 'moment';

export default function ChatComponent(props) {
    const [stateMessage, setStateMessage] = useState(null);
    const [stateMessageText, setStateMessageText] = useState('');
    const [stateMessageArray, setStateMessageArray] = useState(props.userMessageArray);

    useEffect(() => {
        setStateMessageArray(props.userMessageArray);
    }, [props.userMessageArray])

    console.log('ARRAY', stateMessage)

    const handleTextChange = (e) => {
        var name = e.target.name;
        var val = e.target.value;
        setStateMessageText(val);
    }

    const clickUser = (item) => {
        setStateMessage(item)
    }

    const clearUser = () => {
        setStateMessage(null)
    }

    const submitMessage = () => {
        const newMessage = {
            to: stateMessage.tag,
            message: stateMessageText,
            from: props.tag,
            date: new Date(),
        };
        const data = {
            message: newMessage,
            id: stateMessage.id
        }
        props.sendChatMessage(data)
        setStateMessageText('')
    }

    return (
        <CSSTransitionGroup
            transitionName="example"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={true}
            transitionLeaveTimeout={500}>
            <div style={{ height: 500, width: 300, position: 'absolute', bottom: 0, right: 0, backgroundColor: '#333333', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '2px solid #000', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', zIndex: 100 }}>
                <div style={{ height: 38, width: '100%', backgroundColor: '#000', display: 'flex', flexDirection: 'row', borderBottom: '1px solid #cdcdcd' }}>
                    <div style={{ height: 28, width: '50%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>
                        <h2 style={{ fontSize: 15, color: '#fff', fontWeight: 'bold', marginLeft: 10 }}>CHAT</h2>
                    </div>
                    <div style={{ height: '100%', width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {stateMessage ?
                            <div style={{ height: 'auto', width: 'auto', cursor: 'pointer', paddingRight: 7.5 }} onClick={clearUser}>
                                <FaArrowLeft color="#cdcdcd" size="15" />
                            </div> : null}
                        <div style={{ height: 'auto', width: 'auto', cursor: 'pointer', paddingRight: 7.5 }} onClick={props.toggleSpaceChat}>
                            <FaTimes size="15" color="#cdcdcd" />
                            {/* <CloseButton /> */}
                        </div>
                    </div>
                </div>
                {!stateMessage ?
                    <div style={{ width: '100%', height: 387, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ width: '90%', height: '30.7%', marginTop: 15, marginBottom: 15, backgroundColor: '#cdcdcd', boxShadow: '2px 2px 6px #111', display: 'flex', flexDirection: 'column', borderRadius: 5 }}>
                            <div style={{ display: 'flex', width: '100%', height: 30, backgroundColor: '#fff', justifyContent: 'center', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottom: '2px solid #000' }}>
                                <p style={{ fontSize: 13, color: '#000', paddingTop: 5 }}>Active Users</p>
                            </div>
                            <ChatAvatars connectedUsers={props.connectedUsers} clickUser={clickUser} />
                        </div>
                        <div style={{ width: '90%', height: '38%', backgroundColor: '#cdcdcd', boxShadow: '2px 2px 6px #111', display: 'flex', flexDirection: 'column', borderRadius: 5 }}>
                            <div style={{ display: 'flex', width: '100%', height: 30, backgroundColor: '#fff', justifyContent: 'center', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottom: '2px solid #000' }}>
                                <p style={{ fontSize: 13, color: '#000', paddingTop: 5 }}>Active Conversations</p>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '30.7%' }}>
                        </div>
                    </div> :
                    <div style={{ width: '100%', height: 387, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ width: '90%', height: '95%', marginTop: 15, marginBottom: 15, backgroundColor: '#cdcdcd', boxShadow: '2px 2px 6px #111', display: 'flex', flexDirection: 'column', borderRadius: 5 }}>
                            <div style={{ display: 'flex', width: '100%', height: 60, backgroundColor: '#fff', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottom: '2px solid #000' }}>
                                <span style={{ width: 'auto', position: 'absolute' }}>
                                    <ChatAvatar item={stateMessage} />
                                </span>
                                <span style={{ width: '100%', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ fontSize: 15, color: '#000' }}>{stateMessage.name}</p>
                                </span>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                {stateMessageArray.map((item, index) => {
                                    if (item.to === stateMessage.tag && item.from === props.tag || item.to === props.tag && item.from === stateMessage.tag) {
                                        var d = moment(item.date).format('MM/DD/YYYY hh:mm a');
                                        return (
                                            <span style={{ width: 225, height: 'auto', borderRadius: 15, backgroundColor: item.from === props.tag ? '#333' : 'rgb(0, 0, 143)', marginTop: 5, marginLeft: 5, marginRight: 5, alignSelf: item.from === props.tag ? 'flex-end' : 'flex-start' }}>
                                                <p style={{ fontSize: 8, paddingLeft: 10, paddingRight: 10, height: 5, color: '#ddd' }}>{d}</p>
                                                <p style={{ fontSize: 12, paddingLeft: 10, paddingRight: 10, color: '#fff' }}>{item.message}</p>
                                            </span>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>}
                <div style={{ width: '100%', height: 75, backgroundColor: '#000', alignItems: 'center', justifyContent: 'center', display: 'flex', borderTop: '1px solid #ddd' }}>
                    <input style={{ width: '75%', height: '50%', color: '#000', backgroundColor: '#f5f5f5', borderBottomLeftRadius: 5, borderTopLeftRadius: 5, fontSize: 12, border: '1px solid #cdcdcd' }} type="text" placeholder="type here.." disabled={stateMessage ? false : true} name="userMessageText" value={stateMessageText} onChange={handleTextChange} />
                    <div style={{ width: '15%', height: '50%', backgroundColor: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: 5, borderBottomRightRadius: 5, border: '1px solid #cdcdcd', cursor: 'pointer' }} onClick={submitMessage}>
                        <FaPaperPlane color="#cdcdcd" size="15" />
                    </div>
                </div>
            </div>
        </CSSTransitionGroup>
    );
}