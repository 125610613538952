import {
    COMPANY_LOADING,
    GET_COMP_USERS,
} from "../actions/types";
import _ from 'lodash';

const initialState = {
    comp_users: [],
    agents: [],
    loading: false,
    // templates: [],
    // email_groups: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COMPANY_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_COMP_USERS:
            const agents = action.payload.filter(item => item.role === 'agent')
            return {
                ...state,
                comp_users: action.payload,
                agents: agents,
                loading: false
            };
        default:
            return state;
    }
}