import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    MARKETING_LOADING,
    GET_MARKETING,
    GET_SHORT_URL,
    ADD_SHORT_URL,
    DELETE_SHORT_URL,
    URL_DATA_LOADING,
    GET_URL_DATA,
    OFF_URL_DATA,
} from "./types";

export const setMarketingLoading = () => {
    return {
        type: MARKETING_LOADING
    };
};

export const setUrlLoading = () => {
    return {
        type: URL_DATA_LOADING
    };
};

export const getMarketing = id => (dispatch, getState) => {
    //dispatch(setMarketingLoading());
    return axios.get(`${process.env.REACT_APP_API_CLIENT}/marketing/${id}`, tokenConfig(getState))
        .then(res => {
            if (res.data) {
                dispatch({
                    type: GET_MARKETING,
                    payload: res.data
                })
                return res.data
            } else {
                dispatch({
                    type: GET_MARKETING,
                    payload: null
                })
                return null
            }
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};

export const addShortUrl = (url) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/marketing/shorturl/add`, url, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
};

export const updateShortUrl = (url, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/marketing/shorturl/update/${id}`, url, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
};

export const getShortUrl = (ids) => (dispatch, getState) => {
    //dispatch(setUrlLoading());
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/marketing/shorturl`, ids, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
};

export const deleteShortUrl = (short_url_id, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/marketing/shorturl/delete/${id}`, short_url_id, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
};

export const offUrlData = () => (dispatch) => {
    dispatch({
        type: OFF_URL_DATA,
    })
};