import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import { updateStatus } from '../actions/database/globalActions';
import { FaExternalLinkSquareAlt, FaFacebookSquare, FaInstagramSquare, FaSpotify, FaTwitterSquare, FaCaretUp, FaCaretDown, FaRedoAlt, FaMinusSquare } from 'react-icons/fa';
import { ContextMenu, MenuItem, ContextMenuTrigger, SubMenu } from "react-contextmenu";
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Menu, Item, Separator, Submenu, MenuProvider, theme, animation } from 'react-contexify';
import PitchDownloadDialog from '../components/dialogs/pitch-download.dialog';
import CustomChip from '../components/customStatus';
import StatusDialog from '../components/contacts/status.dialog';

import "react-datepicker/dist/react-datepicker.css";

class ClientRolodex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            artist: '',
            agent: '',
            startDate: '',
            endDate: '',
            venues: [],
            vvenueid: '',
            vvenuename: '',
            vaddress: '',
            vaddress2: '',
            vcity: '',
            vstate: '',
            vcountry: '',
            vzipcode: '',
            vcapacity: '',
            vwebsite: '',
            vphonenumber: '',
            vfaxnumber: '',
            vcreatedat: '',
            vupdatedat: '',
            viewvenue: false,
            sortdirection: 'asc',
            sortcat: 'client',
            statusdialog: false,
            statusrenderid: '',
            statusrender: '',
            statustitle: '',
            dialog_download: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.myRef = React.createRef();
        this.toggleViewVenue = this.toggleViewVenue.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        clients: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleStDateChange = date => {
        this.setState({
            startDate: date
        });
    };

    handleEndDateChange = date => {
        this.setState({
            endDate: date
        });
    };

    formatTime(timestamp) {
        const d = new Date(timestamp);
        const time = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
        return time;
    }

    toggleViewVenue(e) {
        e.preventDefault()
        this.props.items.items.map(item => {
            if (this.state.checkedItems.has(item._id)) {
                this.setState({
                    vvenueid: item._id,
                    vvenuename: item.venuename,
                    vaddress: item.address,
                    vaddress2: item.address2,
                    vcity: item.city,
                    vstate: item.state,
                    vcountry: item.country,
                    vzipcode: item.zipcode,
                    vcapacity: item.capacity,
                    vwebsite: item.website,
                    vphonenumber: item.phonenumber,
                    vfaxnumber: item.faxnumber,
                    vcreatedat: item.createdAt,
                    vupdatedat: item.updatedAt,
                    viewvenue: true
                })
            }
        })
    }

    handleDatabaseCheckbox = (n, v) => {
        const e = {
            target: {
                name: n,
                value: v
            }
        }
        this.props.handleDatabaseCheckbox(e)
    }

    toggleLink = (link) => {
        if (link) {
            window.open(link, 'Data');
        }
    }

    normCellRender = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center' }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {cellData.cell}
                </div>
            </MenuProvider>
        )
    }

    rowSelect(id) {
        if (!id) return;
        this.handleDatabaseCheckbox('client_checked', id);
    }

    renderColumnData = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={this.props.client_checked.includes(cellData._id) || false}
                        color="default"
                        value={cellData._id}
                        name="client_checked"
                        onChange={this.props.handleDatabaseCheckbox}
                    />
                    {/* <Chip 
                    size="small"
                    style={{ opacity: .8 }}
                    color={"primary"}
                    clickable
                    label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
                    onClick={this.props.iconRenderClient.bind(this, cellData)}
                /> */}
                    <span style={{ width: 25, height: '100%' }} onClick={this.props.iconRenderClient.bind(this, cellData)}>
                        <FaExternalLinkSquareAlt size="16" className="dashboard-offer-icons-exp" />
                    </span>
                </div>
            </MenuProvider>
        )
    }

    agentsColumnData = ({ cellData, dataKey }) => {
        var agents = [];
        this.props.company.agents.map((a, c) => {
            cellData.agent.forEach((age, ind) => {
                if (a.tag === age._id) {
                    var n = '';
                    if (ind === 0) {
                        n = a.name.first + ' ' + a.name.last;
                    } else {
                        n = ', ' + a.name.first + ' ' + a.name.last;
                    }
                    agents.push(n)
                }
            })
        })
        agents.concat(', ');
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center' }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {agents}
                </div>
            </MenuProvider>
        )
    }

    socialsColumnData = ({ cellData, dataKey }) => {
        var facebook = '';
        var instagram = '';
        var twitter = '';
        var spotify = '';
        cellData.links.map(item => {
            if (item.desc === 'Facebook') {
                facebook = item.url;
            };
            if (item.desc === 'Instagram') {
                instagram = item.url;
            };
            if (item.desc === 'Twitter') {
                twitter = item.url;
            };
            if (item.desc === 'Spotify') {
                spotify = item.url;
            };
        })
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: '100%', display: 'flex' }}>
                    {facebook ? <FaFacebookSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(facebook)} /> : null}
                    {instagram ? <FaInstagramSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(instagram)} /> : null}
                    {twitter ? <FaTwitterSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(twitter)} /> : null}
                    {spotify ? <FaSpotify style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(spotify)} /> : null}
                </div>
            </MenuProvider>
        )
    }

    rowStyleFormat(row) {
        if (row.index < 0) return;
        const list = this.props.clients.filtered_clients;
        if (this.props.client_checked.includes(list[row.index]._id)) {
            return {
                backgroundColor: '#e6f7ff'
            };
        }
        return {
            backgroundColor: null,
        };
    }

    // rowSelect(row) {
    //     if (row.index < 0) return;
    //     const list = this.props.clients.filtered_clients;
    //     this.handleDatabaseCheckbox('client_checked', list[row.index]._id);
    // }

    rowDoubleClick(row) {
        if (row.index < 0) return;
        this.props.iconRenderClient(row.rowData)
    }

    headerRowRenderer = ({ className, columns, style }) => {
        return (
            <div
                className={className}
                role='row'
                style={style}
            >
                {columns}
            </div>
        )
    }

    headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        if (this.props.client_sort.sort_cat === dataKey) {
            return (
                <div style={{ width: '100%' }} onClick={() => this.props.toggleSortClients(dataKey)}><div style={{ display: 'flex', width: '100%' }}><div style={{ width: dataKey === 'client' ? '90%' : '85%' }}>{label}</div><div style={{ width: '10%' }}>{this.props.client_sort.sort_direction === 'asc' ? <FaCaretUp size="15" color="#cdcdcd" /> : <FaCaretDown size="15" color="#cdcdcd" />}</div></div></div>
            )
        } else {
            return (
                <div onClick={() => this.props.toggleSortCatClients(dataKey)}>{label}</div>
            )
        }
    }

    statusColumnData = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                    {/* <Chip
                        size="small"
                        variant="outlined"
                        color={cellData.status === "Inactive" ? "secondary" : "primary"}
                        clickable
                        label={cellData.status}
                        onClick={() => this.handleStatusDialog(cellData)}
                    /> */}
                    <CustomChip
                        size={.65}
                        // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                        color={cellData.status === "Inactive" ? "secondary" : "primary"}
                        label={cellData.status}
                        onClick={() => this.handleStatusDialog(cellData)}
                    />
                </div>
            </MenuProvider>
        )
    }

    handleStatusDialog = (item) => {
        if (item) {
            var name = item.client;
            this.setState({
                statusrenderid: item._id,
                statusrender: item.status,
                statustitle: name,
                statusdialog: !this.state.statusdialog
            })
        }
    }

    closeStatusDialog = () => {
        this.setState({
            statusrenderid: '',
            statusrender: '',
            statustitle: '',
            statusdialog: !this.state.statusdialog
        })
    }

    saveStatus = async () => {
        const status = {
            status: this.state.statusrender
        }
        const type = "clients";
        const renderid = this.state.statusrenderid;
        await this.props.updateStatus(type, status, renderid)
        this.setState({
            statustitle: '',
            statusrender: '',
            statusrenderid: '',
            statusdialog: !this.state.statusdialog
        })
    }

    headerStartRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: 'auto' }}>
                <div style={{ width: '50%' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        color="default"
                        checked={this.props.client_all_checked}
                        //value={props.checked}
                        name="client_checked"
                        onChange={this.props.handleDatabaseAllCheckbox}
                    /></div>
                <div style={{ width: '50%', height: 30 }}>
                    {this.props.client_checked.length > 0 ?
                        <FaMinusSquare style={{ marginTop: 10 }} size="18" color="#cdcdcd" onClick={() => this.props.handleDatabaseClearCheckbox('client_checked')} />
                        : null}
                </div>
            </div>
        )
    }

    toggleDownloadDialog = () => {
        this.setState({
            dialog_download: !this.state.dialog_download
        })
    }

    downloadPdf = (data) => {
        this.props.downloadPdf("pitch-deck", "clients", data)
    }

    render() {

        const MyAwesomeMenu = () => (
            <Menu id='menu_id' theme={theme.dark} animation={animation.fade}>
                <Item onClick={this.props.toggleRenderOffer}>View / Edit</Item>
                <Item onClick={() => this.props.deleteDialog('client_checked', 'client')}>Delete Client</Item>
                <Submenu label="Download">
                    <Item onClick={() => this.toggleDownloadDialog()}>Pitch Deck</Item>
                </Submenu>
            </Menu>
        );

        // {this.props.clients.filtered_clients.map((item, index) => {

        //     var agents = [];
        //     this.props.company.agents.map((a, c) => {
        //         item.agent.forEach(age => {
        //             if (a.tag === age._id) {
        //                 const n = a.name.first + ' ' + a.name.last;
        //                 agents.push(n)
        //             }
        //         })
        //     })

        //     agents.concat(', ');

        //     return (
        //         <ContextMenuTrigger key={index} id={'SIMPLE'} >
        //             <table id="customers">
        //                 <tbody>
        //                     <tr style={{ backgroundColor: index % 2 === 0 ? "#F5F5F5" : "#fff" }}>
        //                         <td style={{ width: '5%', backgroundColor: this.props.client_checked.includes(item._id) === true ? '#e6f7ff' : null }}>
        //                             <Checkbox
        //                                 icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //                                 checkedIcon={<CheckBoxIcon fontSize="small" />}
        //                                 color="default"
        //                                 checked={this.props.client_checked.includes(item._id) || false}
        //                                 value={item._id}
        //                                 name="client_checked"
        //                                 onChange={this.props.handleDatabaseCheckbox}
        //                             />
        //                             <Chip
        //                                 size="small"
        //                                 style={{ opacity: .8 }}
        //                                 color={"primary"}
        //                                 clickable
        //                                 label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
        //                                 onClick={this.props.iconRenderClient.bind(this, item)}
        //                             />
        //                         </td>
        //                         <td style={{ width: '15%', fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.client_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('client_checked', item._id)}>{item.client}</td>
        //                         <td style={{ width: '12%', fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.client_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('client_checked', item._id)}>{item.address.city}</td>
        //                         <td style={{ width: '10%', fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.client_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('client_checked', item._id)}>{item.address.state}</td>
        //                         <td style={{ width: '12%', fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.client_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('client_checked', item._id)}>{item.address.country}</td>
        //                         <td style={{ width: '12%', fontSize: 12, height: 32, paddingLeft: 5, backgroundColor: this.props.client_checked.includes(item._id) === true ? '#e6f7ff' : null }} onClick={() => this.handleDatabaseCheckbox('client_checked', item._id)}>{agents}</td>
        //                         <td style={{ width: '10%', fontSize: 12, height: 32, paddingLeft: 5 }}><div style={{ width: '100%', display: 'flex' }}>{item.facebook ? <FaFacebookSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(item.facebook)} /> : null}{item.instagram ? <FaInstagramSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(item.instagram)} /> : null}{item.twitter ? <FaTwitterSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(item.twitter)} /> : null}{item.spotify ? <FaSpotify style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(item.spotify.url)} /> : null}</div></td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //         </ContextMenuTrigger>
        //     )
        // })}

        // var clients = this.props.clients.filtered_clients;
        // var sortedClients = _.orderBy(clients, [`${this.props.client_sort.sort_cat}`.toLowerCase()], [this.props.client_sort.sort_direction]);


        //<Chip
        //    size="small"
        //    color={"default"}
        //    clickable
        //    label={<FaExpand size="10" color="#000" />}
        //    onClick={this.handleStatusDialog.bind(this, item)}
        ///>

        const list = this.props.clients.filtered_clients;
        //item.status.substring(0, 1).toUpperCase()

        if (this.props.clients.loading) return <div>loading...</div>;
        else {
            return (
                <div style={{ width: '100%' }}>
                    <PitchDownloadDialog open={this.state.dialog_download} downloadPdf={this.downloadPdf} client_checked={this.props.client_checked} onClose={this.toggleDownloadDialog}/>
                    <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={height}
                                headerHeight={30}
                                rowHeight={30}
                                overscanRowCount={10}
                                headerRowRenderer={this.headerRowRenderer.bind(this)}
                                rowCount={list.length}
                                rowGetter={({ index }) => list[index]}
                                rowStyle={this.rowStyleFormat.bind(this)}
                                //onRowClick={this.rowSelect.bind(this)}
                                onRowDoubleClick={this.rowDoubleClick.bind(this)}
                            >
                                <Column
                                    label=''
                                    width={75}
                                    dataKey='_id'
                                    headerRenderer={this.headerStartRenderer}
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.renderColumnData
                                    }
                                />
                                <Column
                                    label='Status'
                                    width={75}
                                    dataKey='status'
                                    headerRenderer={this.headerRenderer}
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.statusColumnData
                                    }
                                />
                                <Column
                                    width={220}
                                    label='Client'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='client'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.client, id: rowData._id, width: 220 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={150}
                                    label='City'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='address.city'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.city, id: rowData._id, width: 150 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={150}
                                    label='State | Prov'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='address.state'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.state, id: rowData._id, width: 150 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={150}
                                    label='Country'
                                    headerRenderer={this.headerRenderer}
                                    dataKey='address.country'
                                    cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.country, id: rowData._id, width: 150 }) }}
                                    cellRenderer={
                                        this.normCellRender
                                    }
                                />
                                <Column
                                    width={200}
                                    label='Agent(s)'
                                    dataKey='agent'
                                    cellDataGetter={({ rowData }) => { return ({ agent: rowData.agent, id: rowData._id, width: 200 }) }}
                                    cellRenderer={
                                        this.agentsColumnData
                                    }
                                />
                                <Column
                                    width={200}
                                    label='Socials'
                                    dataKey='socials'
                                    cellDataGetter={({ rowData }) => rowData}
                                    cellRenderer={
                                        this.socialsColumnData
                                    }
                                />
                            </Table>
                        )}
                    </AutoSizer>
                    <MyAwesomeMenu />
                    <StatusDialog open={this.state.statusdialog} handleClose={this.closeStatusDialog} handleChange={this.handleChange} saveStatus={this.saveStatus} {...this.state} />
                </div>
            )
        }
    }
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    company: state.company,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { updateStatus })(ClientRolodex);