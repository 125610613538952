import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import NumberFormat from 'react-number-format';
import { FaTimesCircle, FaEdit, FaCheckSquare, FaTrash, FaGripLinesVertical } from 'react-icons/fa';

const style = {
    border: '1px dashed gray',
    backgroundColor: '#f1f1f1',
    width: '100%'
};
const style2 = {
    border: '1px dashed gray',
    backgroundColor: '#f1f1f1',
    width: '100%'
};

export const Card = ({ index, id, show_index, setTax, tax, type, amount, cap, cappedamount, notes, deleteTax, edit, gross_tickets, gross_tickets_single, gross_pot_val, gross_pot_val_single, tax_edit, tax_val, clearTaxUpdate, saveTaxUpdate, handleArrayUpdate, handleArrayFormattedChange, currency_symbol, tax_pin, handlePinnedArray2Change, moveCard }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, index, id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    var tax_gross_single;
    if (type === "Flat") {
        tax_gross_single = amount;
    } else if (type === "Per Ticket") {
        var t = gross_tickets_single * amount;
        if (cap === true || cap === "true") {
            if (t > cappedamount) {
                t = cappedamount
            }
        }
        tax_gross_single = t.toLocaleString("en", { useGrouping: false, maximumFractionDigits: 2 });
    } else if (type === "Percent") {
        var t = gross_pot_val_single * (amount / 100);
        if (cap === true || cap === "true") {
            if (t > cappedamount) {
                t = cappedamount
            }
        }
        tax_gross_single = t.toLocaleString("en", { useGrouping: false, maximumFractionDigits: 2 });
    } else if (type === "Percent (divisor)") {
        var t = gross_pot_val_single - (gross_pot_val_single / (1 + (amount / 100)));
        if (cap === true || cap === "true") {
            if (t > cappedamount) {
                t = cappedamount
            }
        }
        tax_gross_single = t.toLocaleString("en", { useGrouping: false, maximumFractionDigits: 2 });
    };

    return (<div key={index} style={edit ? { ...style, opacity } : { ...style2 }} ref={ref}>
        <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td style={{ width: '3%', fontSize: 13, cursor: 'move' }}><FaGripLinesVertical size="18" color='#cdcdcd' /></td>
                    {tax_edit === 'tax' ?
                        <td style={{ width: '15%' }}><input style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} type="text" name="tax" value={tax} onChange={!tax_pin ? (event) => handleArrayUpdate(event, id, 'taxesarray') : (event) => handlePinnedArray2Change(event, 'taxesarray', show_index)} /></td> :
                        <td style={{ width: '15%' }} onClick={() => setTax(id, 'tax')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{tax}</p></td>}
                    {tax_edit === 'type' ?
                        <td style={{ width: '11%' }}>
                            <select style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} type="text" name="type" value={type} onChange={!tax_pin ? (event) => handleArrayUpdate(event, id, 'taxesarray') : (event) => handlePinnedArray2Change(event, 'taxesarray', show_index)}>
                                <option value="Flat">Flat</option>
                                <option value="Percent">Percent (multiplier)</option>
                                <option value="Percent (divisor)">Percent (divisor)</option>
                                <option value="Per Ticket">Per Ticket</option>
                            </select>
                        </td> :
                        <td style={{ width: '11%' }} onClick={() => setTax(id, 'type')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{type}</p></td>}
                    {tax_edit === 'amount' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} thousandSeparator={true} prefix={type === 'Flat' || type === 'Per Ticket' ? currency_symbol : ""} suffix={type === 'Percent' || type === 'Percent (divisor)' ? '%' : ""} value={amount} onValueChange={(values) => handleArrayFormattedChange(values, id, 'amount', 'taxesarray')} /></td> :
                        <td style={{ width: '11%' }} onClick={() => setTax(id, 'amount')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={type === 'Flat' || type === 'Per Ticket' ? currency_symbol : ''} suffix={type === 'Percent' || type === 'Percent (divisor)' ? '%' : ''} /></p></td>}
                    {tax_edit === 'cap' ?
                        <td style={{ width: '8%' }}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 50, backgroundColor: '#fff', border: '1px solid #000', borderRadius: 4 }}><input style={{ fontSize: 12 }}
                            type="checkbox"
                            name="cap"
                            value={cap === 'true' ? false : true}
                            checked={cap === 'true' ? true : false}
                            onChange={!tax_pin ? (event) => handleArrayUpdate(event, id, 'taxesarray') : (event) => handlePinnedArray2Change(event, 'taxesarray', show_index)}></input></div></td> :
                        <td style={{ width: '8%' }} onClick={() => setTax(id, 'cap')}><div style={{ color: '#000', fontSize: 12, width: '100%', display: 'flex', alignItems: 'center' }}><input style={{ marginTop: 3, width: '100%' }}
                            type="checkbox"
                            disabled={true}
                            checked={cap === 'true' || cap === true ? true : false}></input></div></td>}
                    {tax_edit === 'capped_amount' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} name="capped_amount" value={cappedamount} thousandSeparator={true} prefix={currency_symbol} onValueChange={(values) => handleArrayFormattedChange(values, id, 'capped_amount', 'taxesarray')} /></td> :
                        cappedamount > 0 ?
                            <td style={{ width: '11%' }} onClick={() => setTax(id, 'capped_amount')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={cappedamount} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td> :
                            <td style={{ width: '11%', fontSize: 12, color: '#000' }} onClick={() => setTax(id, 'capped_amount')}>-</td>}
                    <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={tax_gross_single} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td>
                    {tax_edit === 'notes' ?
                        <td style={{ width: '18%' }}><input style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} type="text" name="notes" value={notes} onChange={!tax_pin ? (event) => handleArrayUpdate(event, id, 'taxesarray') : (event) => handlePinnedArray2Change(event, 'taxesarray', show_index)} /></td> :
                        <td style={{ width: '18%' }} onClick={() => setTax(id, 'notes')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{notes}</p></td>}
                    <td style={{ width: '8%' }}><FaTrash style={{ marginLeft: 10, cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => deleteTax(id)} /></td>
                </tr>
            </tbody>
        </table>
    </div>);
};