import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getPackaging } from '../actions/packagingActions';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import ReactTooltip from 'react-tooltip';
import socketIOClient from "socket.io-client";
import moment from 'moment';
import { updateStatus } from '../actions/database/globalActions';
import Status2Dialog from '../components/contacts/status2.dialog';
import { FaExternalLinkSquareAlt, FaCaretUp, FaCaretDown, FaMinusSquare } from 'react-icons/fa';
import { ContextMenu, MenuItem, ContextMenuTrigger, SubMenu } from "react-contextmenu";
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Menu, Item, Separator, Submenu, MenuProvider, theme, animation } from 'react-contexify';
import CustomChip from '../components/customStatus';

import "react-datepicker/dist/react-datepicker.css";

class PackagingRolodex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            artist: '',
            agent: '',
            startDate: '',
            endDate: '',
            sidebarOpen: false,
            seen: false,
            entervenue: false,
            buyers: [],
            sortdirection: 'asc',
            sortcat: 'company',
            todayscount: [],
            statusdialog: false,
            statusrenderid: '',
            statusrender: '',
            statustitle: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.myRef = React.createRef();
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        counts: PropTypes.object.isRequired,
        packaging: PropTypes.object.isRequired,
    }

    componentDidMount() {
        if (this.props.packaging.packaging.length <= 0) {
            this.props.getPackaging();
        }
    }

    handleEventPositioned() {

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    toggleSort = () => {
        if (this.state.sortdirection === "asc") {
            this.setState({
                sortdirection: 'desc'
            })
        } else {
            this.setState({
                sortdirection: 'asc'
            })
        }
    }

    toggleSortCatCompany = () => {
        this.setState({
            sortcat: 'company'
        })
    }
    toggleSortCatCity = () => {
        this.setState({
            sortcat: 'city'
        })
    }
    toggleSortCatState = () => {
        this.setState({
            sortcat: 'state'
        })
    }
    toggleSortCatCountry = () => {
        this.setState({
            sortcat: 'country'
        })
    }
    toggleSortCatZipcode = () => {
        this.setState({
            sortcat: 'zipcode'
        })
    }

    renderColumnData = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={this.props.packaging_checked.includes(cellData._id) || false}
                        color="default"
                        value={cellData._id}
                        name="packaging_checked"
                        onChange={this.props.handleDatabaseCheckbox}
                    />
                    {/* <Chip
                    size="small"
                    style={{ opacity: .8 }}
                    color={"primary"}
                    clickable
                    label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
                    onClick={this.props.iconRenderPackaging.bind(this, cellData)}
                /> */}
                    <span style={{ width: 25, height: '100%' }} onClick={this.props.iconRenderPackaging.bind(this, cellData)}>
                        <FaExternalLinkSquareAlt size="16" className="dashboard-offer-icons-exp" />
                    </span>
                </div>
            </MenuProvider>
        )
    }

    statusColumnData = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Chip
                    size="small"
                    variant="outlined"
                    style={{ opacity: .8 }}
                    label={cellData.status}
                    color={cellData.status === "Inactive" || cellData.status === "Filled" ? "secondary" : "primary"}
                    clickable
                    onClick={() => this.handleStatusDialog(cellData)}
                /> */}
                    <CustomChip
                        size={.65}
                        // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                        color={cellData.status === "Inactive" || cellData.status === 'Filled' ? "secondary" : "primary"}
                        label={cellData.status}
                        onClick={() => this.handleStatusDialog(cellData)}
                    />
                </div>
            </MenuProvider>
        )
    }

    headerStartRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                <div style={{ width: '50%' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        color="default"
                        checked={this.props.packaging_all_checked}
                        //value={props.checked}
                        name="packaging_checked"
                        onChange={this.props.handleDatabaseAllCheckbox}
                    />
                </div>
                <div style={{ width: '50%' }}>
                    {this.props.packaging_checked.length > 0 ?
                        <FaMinusSquare size="18" color="#cdcdcd" onClick={() => this.props.handleDatabaseClearCheckbox('packaging_checked')} />
                        : null}
                </div>
            </div>
        )
    }

    rangeColumnData = ({ cellData, dataKey }) => {
        let moments = cellData.tour_dates.map(d => moment(d.date).utc());
        var max = moment.max(moments).format('MM/DD/YYYY');
        var min = moment.min(moments).format('MM/DD/YYYY');

        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center' }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {`${min} - ${max}`}
                </div>
            </MenuProvider>
        )
    }

    locColumnData = ({ cellData, dataKey }) => {
        let loc = cellData.tour_dates.map(d => d.city && d.state ? d.city + ", " + d.state : d.city ? d.city : d.state ? d.state : null);
        var start = loc[0];
        var length = loc.length - 1;
        var end = loc[length];
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center' }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {start && end ? start + " - " + end : start ? start : end ? end : null}
                </div>
            </MenuProvider>
        )
    }

    rowStyleFormat(row) {
        if (row.index < 0) return;
        const list = this.props.packaging.filtered_packaging;
        if (this.props.packaging_checked.includes(list[row.index]._id)) {
            return {
                backgroundColor: '#e6f7ff'
            };
        }
        return {
            backgroundColor: null,
        };
    }

    // rowSelect(row) {
    //     if (row.index < 0) return;
    //     const list = this.props.packaging.packaging;
    //     this.handleDatabaseCheckbox('packaging_checked', list[row.index]._id);
    // }

    normCellRender = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center' }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {cellData.cell}
                </div>
            </MenuProvider>
        )
    }

    rowSelect(id) {
        if (!id) return;
        this.handleDatabaseCheckbox('packaging_checked', id);
    }

    handleDatabaseCheckbox = (n, v) => {
        const e = {
            target: {
                name: n,
                value: v
            }
        }
        this.props.handleDatabaseCheckbox(e)
    }

    rowDoubleClick(row) {
        if (row.index < 0) return;
        const list = this.props.packaging.packaging;
        this.props.iconRenderPackaging(row.rowData)
    }

    headerRowRenderer = ({ className, columns, style }) => {
        return (
            <div
                className={className}
                role='row'
                style={style}
            >
                {columns}
            </div>
        )
    }

    headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        if (this.props.packaging_sort.sort_cat === dataKey) {
            return (
                <div style={{ width: '100%' }} onClick={() => this.props.toggleSortPackaging(dataKey)}><div style={{ display: 'flex', width: '100%' }}><div style={{ width: '85%' }}>{label}</div><div style={{ width: '10%' }}>{this.props.packaging_sort.sort_direction === 'asc' ? <FaCaretUp size="15" color="#cdcdcd" /> : <FaCaretDown size="15" color="#cdcdcd" />}</div></div></div>
            )
        } else {
            return (
                <div onClick={() => this.props.toggleSortCatPackaging(dataKey)}>{label}</div>
            )
        }
    }

    handleStatusDialog = (item) => {
        if (item) {
            let moments = item.tour_dates.map(d => moment(d.date).utc());
            var max = moment.max(moments).format('MM/DD/YYYY');
            var min = moment.min(moments).format('MM/DD/YYYY');
            var title = <span style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}><span>{item.artist}</span><span>{min} - {max}</span></span>;
            this.setState({
                statusrenderid: item._id,
                statusrender: item.status,
                statustitle: title,
                statusdialog: !this.state.statusdialog
            })
        }
    }

    closeStatusDialog = () => {
        this.setState({
            statusrenderid: '',
            statusrender: '',
            statustitle: '',
            statusdialog: !this.state.statusdialog
        })
    }

    saveStatus = async () => {
        const status = {
            status: this.state.statusrender
        }
        const type = "packaging";
        const renderid = this.state.statusrenderid;
        await this.props.updateStatus(type, status, renderid)
        this.setState({
            statustitle: '',
            statusrender: '',
            statusrenderid: '',
            statusdialog: !this.state.statusdialog
        })
    }

    render() {

        const MyAwesomeMenu = () => (
            <Menu id='menu_id' theme={theme.dark} animation={animation.fade}>
                <Item onClick={this.props.toggleRenderOffer}>View / Edit</Item>
                <Item onClick={() => this.props.deleteDialog('packaging_checked', 'packaging')}>Delete Packaging</Item>
            </Menu>
        );

        // {this.props.packaging.packaging.map((item, index) => {

        //     var date_added = moment(item.createdAt).format('MM/DD/YYYY');

        //     let moments = item.tour_dates.map(d => moment(d.date));
        //     var max = moment.max(moments).format('MM/DD/YYYY');
        //     var min = moment.min(moments).format('MM/DD/YYYY');

        //     let loc = item.tour_dates.map(d => d.city + ", " + d.state);
        //     var start = loc[0];
        //     var length = loc.length - 1;
        //     var end = loc[length];

        //     return (
        //         <ContextMenuTrigger key={index} id={'SIMPLE'}>
        //             <table id="customers">
        //                 <tbody>
        //                     <tr style={{ backgroundColor: "#fff" }}>
        //                         <td style={{ width: '6%' }}>
        //                             <Checkbox
        //                                 icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //                                 checkedIcon={<CheckBoxIcon fontSize="small" />}
        //                                 color="default"
        //                                 name="buyerchecked"
        //                             />
        //                             <Chip
        //                                 size="small"
        //                                 style={{ opacity: .8 }}
        //                                 color={"primary"}
        //                                 clickable
        //                                 label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
        //                                 onClick={this.props.iconRenderPackaging.bind(this, item)}
        //                             />
        //                         </td>
        //                         <td style={{ width: '10%', fontSize: 12, height: 32, paddingLeft: 5 }}>{date_added}</td>
        //                         <td style={{ width: '6%', fontSize: 12, height: 32, paddingLeft: 5 }}>
        //                             <Chip
        //                                 size="small"
        //                                 variant="outlined"
        //                                 style={{ opacity: .8 }}
        //                                 label={item.status}
        //                                 color={item.status === "Inactive" ? "secondary" : "primary"}
        //                                 clickable
        //                             //onClick={this.props.iconRenderOffer.bind(this, item)}
        //                             /></td>
        //                         <td style={{ width: '16%', fontSize: 12, height: 32, paddingLeft: 5 }}>{item.artist}</td>
        //                         <td style={{ width: '23%', fontSize: 12, height: 32, paddingLeft: 5 }}>{min} - {max}</td>
        //                         <td style={{ width: '23%', fontSize: 12, height: 32, paddingLeft: 5 }}>{start} - {end}</td>
        //                         <td style={{ width: '16%', fontSize: 12, height: 32, paddingLeft: 5 }}>{item.genre}</td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //         </ContextMenuTrigger>
        //     )
        // })}

        const list = this.props.packaging.filtered_packaging;

        if (this.props.packaging.loading) return <div>loading...</div>;
        return (
            <div style={{ width: '100%' }}>
                <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                </ReactTooltip>
                <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            width={width}
                            height={height}
                            headerHeight={30}
                            rowHeight={30}
                            overscanRowCount={10}
                            //headerRowRenderer={this.headerRowRenderer.bind(this)}
                            rowCount={list.length}
                            rowGetter={({ index }) => list[index]}
                            rowStyle={this.rowStyleFormat.bind(this)}
                            //onRowClick={this.rowSelect.bind(this)}
                            onRowDoubleClick={this.rowDoubleClick.bind(this)}
                        >
                            <Column
                                label=''
                                width={75}
                                dataKey='_id'
                                headerRenderer={this.headerStartRenderer}
                                cellDataGetter={({ rowData }) => rowData}
                                cellRenderer={
                                    this.renderColumnData
                                }
                            />
                            <Column
                                width={80}
                                label='Status'
                                headerRenderer={this.headerRenderer}
                                dataKey='status'
                                //cellDataGetter={({ rowData }) => rowData.name.last}
                                cellDataGetter={({ rowData }) => rowData}
                                cellRenderer={
                                    this.statusColumnData
                                }
                            />
                            <Column
                                width={120}
                                label='Date Added'
                                headerRenderer={this.headerRenderer}
                                dataKey='date_added'
                                // cellDataGetter={({ rowData }) => rowData}
                                // cellRenderer={
                                //     this.dateAddedColumnData
                                // }
                                cellDataGetter={({ rowData }) => { return ({ cell: moment(rowData.createdAt).format('MM/DD/YYYY'), id: rowData._id, width: 120 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            />
                            <Column
                                width={200}
                                label='Artist'
                                dataKey='artist'
                                cellDataGetter={({ rowData }) => { return ({ cell: rowData.artist, id: rowData._id, width: 200 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            />
                            <Column
                                width={250}
                                label='Date Range'
                                headerRenderer={this.headerRenderer}
                                dataKey='date_range'
                                //cellDataGetter={({ rowData }) => rowData.name.first}
                                cellDataGetter={({ rowData }) => { return ({ tour_dates: rowData.tour_dates, id: rowData._id, width: 250 }) }}
                                cellRenderer={
                                    this.rangeColumnData
                                }
                            />
                            <Column
                                width={250}
                                label='Location'
                                dataKey='location'
                                cellDataGetter={({ rowData }) => { return ({ tour_dates: rowData.tour_dates, id: rowData._id, width: 250 }) }}
                                cellRenderer={
                                    this.locColumnData
                                }
                            />
                            <Column
                                width={200}
                                label='Genre(s)'
                                dataKey='genre'
                                cellDataGetter={({ rowData }) => { return ({ cell: rowData.genre, id: rowData._id, width: 200 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            />
                            {/* <Column
                                width={150}
                                label='Country'
                                headerRenderer={this.headerRenderer}
                                dataKey='address.country'
                                cellDataGetter={({ rowData }) => rowData.address.country}
                            /> */}
                        </Table>
                    )}
                </AutoSizer>
                <MyAwesomeMenu />
                <Status2Dialog open={this.state.statusdialog} handleClose={this.closeStatusDialog} handleChange={this.handleChange} saveStatus={this.saveStatus} {...this.state} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    buyers: state.buyers,
    contacts: state.contacts,
    clients: state.clients,
    items: state.items,
    counts: state.counts,
    offers: state.offers,
    packaging: state.packaging,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { getPackaging, updateStatus })(PackagingRolodex);