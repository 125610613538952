import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function StatusSnackbarExt(props) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.closeSnackbar();
  };

  return (
    <Snackbar open={props.open} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} onClose={handleClose}>
      < Alert style={{ backgroundColor: '#2197f3' }} onClose={handleClose} severity="success">
        {props.message}
      </Alert>
    </Snackbar >
  );
}