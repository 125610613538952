import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
// import {
//     VENUES_LOADING,
//     GLOBAL_VENUES_GET,
// } from "./types";

// export const setVenuesLoading = () => {
//     return {
//       type: VENUES_LOADING
//     };
// };

export const getGlobalVenues = () => async (dispatch, getState) => {
    // dispatch(setVenuesLoading());
    return await axios.get(`${process.env.REACT_APP_API}/global/venues/`, tokenConfig(getState))
    .then(res => {
        return res.data
        // dispatch({
        //     type: GLOBAL_VENUES_GET,
        //     payload: res.data
        // })
    })
    .catch(err => {
        return err.response.data
        // dispatch(returnErrors(err.response.data, err.response.status))
    });
};