import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadAttachmentClient, downloadAttachmentClient, deleteAttachmentClient } from '../../actions/clientActions';
import { FaPlusCircle, FaMinusCircle, FaFileDownload, FaTimesCircle } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert'

class ClientAttachments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commission_percent: 15,
            attachment_bar: false,
            file_input: '',
            file_type: '',
            file_notes: '',
            attachments: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    static propTypes = {
        offers: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        tag: PropTypes.string.isRequired,
    }

    componentDidMount() {
        this.setState({
            attachments: this.props.attachments
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.attachments !== this.props.attachments){
            this.setState({
                attachments: this.props.attachments
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleFileChange = event => {
        this.setState({
            file_input: event.target.value,
            file_full: event.target.files[0],
            loaded: 0,
        })
    }

    toggleAttachmentAdd = () => {
        this.setState({
            attachment_bar: !this.state.attachment_bar
        })
    }

    uploadFile = async () => {
        const id = this.props.id;
        const date = new Date().toString();

        const key = `id:${id} type:${this.state.file_type} date:${date}`;
        const file = this.state.file_full;
        const name = this.state.file_input.replace("C:\\fakepath\\", "");
        const type = this.state.file_type;
        const notes = this.state.file_notes;
        const user = this.props.tag;

        // const file = {
        //     name: this.state.file_input.replace("C:\\fakepath\\", ""),
        //     file: formData,
        //     key: string,
        //     type: this.state.file_type,
        //     notes: this.state.file_notes
        // };

        const log_status = await this.props.uploadAttachmentClient(file, name, key, type, notes, date, user, id);
        if (log_status.includes('Error:')) {
            //this.props.toggleErrorSnackbar(log_status);
            alert(log_status);
        }
        this.setState({
            file_input: '',
            file_full: null,
            file_type: '',
            file_notes: ''
        })
    }

    downloadFile = async (key, name) => {
        const data = {
            key: key,
            title: name
        }
        const log_status = await this.props.downloadAttachmentClient(data);
        if (log_status.includes('Error:')) {
            //this.props.toggleErrorSnackbar(log_status);
            alert(log_status);
        }
    }

    deleteFileDialog = (id, key) => {
        console.log(id);
        const addDialog = ({ onClose }) => {
            return (
                <div class="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Delete File</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Are you sure you'd like to delete this file?
                        </div>
                        <div style={{ marginTop: 10 }} class="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => this.deleteFile(id, key, onClose)}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => this.handleDialogClose(onClose)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )
        }

        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }

        confirmAlert(options)
    }

    handleDialogClose = (onClose) => {
        onClose();
    }

    deleteFile = async (id, key, onClose) => {
        const data = {
            key: key,
            id: id
        }
        const deal_id = this.props.id;
        const log_status = await this.props.deleteAttachmentClient(data, deal_id);
        if (log_status.includes('Error:')) {
            //this.props.toggleErrorSnackbar(log_status);
            alert(log_status);
        }
        onClose();
    }

    render() {

        return (
            <div style={{ width: '100%', height: 530, overflowY: 'scroll' }}>
                {!this.props.loading ?
                    <div>
                        <div style={{ width: '100%', height: 'auto' }}>
                            <div style={{ marginLeft: 50, height: 'auto', width: '70%', marginTop: 20, backgroundColor: 'rgb(1, 22, 48)' }} className="form-control">
                                <div style={{ width: '100%', marginTop: 10, display: 'flex' }}>
                                    {this.state.attachment_bar ?
                                        <FaMinusCircle style={{ cursor: 'point' }} color="#cdcdcd" size={18} onClick={this.toggleAttachmentAdd} /> :
                                        <FaPlusCircle style={{ cursor: 'point' }} color="#cdcdcd" size={18} onClick={this.toggleAttachmentAdd} />}
                                    <h1 style={{ color: '#cdcdcd', fontSize: 14, fontWeight: 'bold', marginLeft: 10 }}>Upload File:</h1>
                                </div>
                                {this.state.attachment_bar ?
                                    <div style={{ width: '100%', display: 'flex', marginBottom: 20 }}>
                                        <div style={{ width: '80%' }}>
                                            <div style={{ marginTop: 15, display: 'flex', width: '100%' }} className="form-group">
                                                <label style={{ fontSize: 14, width: '25%', color: '#cdcdcd' }}> </label>
                                                <input style={{ width: '70%', fontSize: 12, borderRadius: 1, color: '#fff' }} className="form-control-sm" type="file" value={this.state.file_input} name="file_input" onChange={this.handleFileChange} />
                                            </div>
                                            <div style={{ marginTop: 15, display: 'flex', width: '100%' }} className="form-group">
                                                <label style={{ fontSize: 14, width: '25%', color: '#cdcdcd' }}>Type:</label>
                                                <select style={{ width: '70%', fontSize: 12, borderRadius: 1, color: '#000' }} className="form-control-sm" value={this.state.file_type} name="file_type" onChange={this.handleChange}>
                                                    <option value=""></option>
                                                    <option value="bio">Bio</option>
                                                    <option value="logo">Logo</option>
                                                    <option value="photo">Photo</option>
                                                    <option value="rider">Rider</option>
                                                    <option value="W9">W9</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                            <div style={{ marginTop: 15, display: 'flex', width: '100%' }} className="form-group">
                                                <label style={{ fontSize: 14, width: '25%', color: '#cdcdcd' }}>Notes:</label>
                                                <textarea style={{ minWidth: '70%', maxWidth: '70%', fontSize: 12, border: '1px solid #000', borderRadius: 1, minHeight: 60, maxHeight: 60 }} className="form-control-sm" value={this.state.file_notes} name="file_notes" onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div style={{ width: '20%' }}>
                                            <span style={{ width: '100%', height: 'auto' }}>
                                                <button style={{ fontSize: 12, height: 25, marginTop: 140, display: 'flex', alignItems: 'center', justifyContent: 'center' }} type="button" className="btn btn-secondary px-5" onClick={this.uploadFile}>Upload</button>
                                            </span>
                                        </div>
                                    </div> : null}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

                            </div>
                        </div>
                        <div style={{ width: '100%', height: '50%' }}>
                            <div style={{ marginTop: 15, marginLeft: 50, minHeight: 100, width: '90%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd', overflowY: 'scroll' }}>
                                <table style={{ width: '100%', height: 25 }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Assets (photo, logo, bio):</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table style={{ width: '100%', height: 25 }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '7.5%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Type</th>
                                            <th style={{ width: '7.5%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}> </th>
                                            <th style={{ width: '20%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Attachment</th>
                                            <th style={{ width: '22%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Notes</th>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Date</th>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Added By</th>
                                            <th style={{ width: '8%', color: '#fff', backgroundColor: 'rgb(1, 22, 48)' }}> </th>
                                        </tr>
                                    </thead>
                                </table>
                                {this.state.attachments.map((item, index) => {

                                    const d = new Date(item.date);
                                    const date = `${(d.getMonth() + 1)}/${d.getDate()}/${d.getFullYear()}`;

                                    var user = "";
                                    this.props.company.comp_users.map((c, i) => {
                                        if (item.user === c.tag) {
                                            user = c.name.first + " " + c.name.last;
                                        }
                                    })
                                    if (item.type !== 'rider' && item.type !== 'W9') {
                                        return (
                                            <div key={index} className="attachment_table_client">
                                                <table style={{ width: '100%', height: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '7.5%', color: '#000', fontSize: 12, padding: 5 }} className="letter_cap">{item.type}</td>
                                                            <td style={{ width: '7.5%', color: '#000', fontSize: 12, padding: 5 }}><img style={{ width: 'auto', height: 24 }} alt="img" src={item.file} /></td>
                                                            <td style={{ width: '20%', color: '#000', fontSize: 12, padding: 5 }}>{item.name}</td>
                                                            <td style={{ width: '22%', color: '#000', fontSize: 12, padding: 5 }}>{item.notes}</td>
                                                            <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{date}</td>
                                                            <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{user}</td>
                                                            <td style={{ width: '8%', color: '#000', padding: 5 }}>
                                                                <FaFileDownload className="attachment_btns" size="18" onClick={() => this.downloadFile(item.key, item.name)} />
                                                                <FaTimesCircle style={{ marginLeft: 15 }} className="attachment_btns" size="18" onClick={() => this.deleteFileDialog(item._id, item.key)} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div style={{ marginTop: 15, marginLeft: 50, minHeight: 100, width: '90%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd', overflowY: 'scroll' }}>
                                <table style={{ width: '100%', height: 25 }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Rider / Stage Plot:</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table style={{ width: '100%', height: 25 }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Type</th>
                                            <th style={{ width: '20%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Attachment</th>
                                            <th style={{ width: '22%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Notes</th>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Date</th>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Added By</th>
                                            <th style={{ width: '8%', color: '#fff', backgroundColor: 'rgb(1, 22, 48)' }}> </th>
                                        </tr>
                                    </thead>
                                </table>
                                {this.state.attachments.map((item, index) => {

                                    const d = new Date(item.date);
                                    const date = `${(d.getMonth() + 1)}/${d.getDate()}/${d.getFullYear()}`;

                                    var user = "";
                                    this.props.company.comp_users.map((c, i) => {
                                        if (item.user === c.tag) {
                                            user = c.name.first + " " + c.name.last;
                                        }
                                    })
                                    if (item.type === 'rider') return (
                                        <div key={index} className="attachment_table_client">
                                            <table style={{ width: '100%', height: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{item.type}</td>
                                                        <td style={{ width: '20%', color: '#000', fontSize: 12, padding: 5 }}>{item.name}</td>
                                                        <td style={{ width: '22%', color: '#000', fontSize: 12, padding: 5 }}>{item.notes}</td>
                                                        <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{date}</td>
                                                        <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{user}</td>
                                                        <td style={{ width: '8%', color: '#000', padding: 5 }}>
                                                            <FaFileDownload className="attachment_btns" size="18" onClick={() => this.downloadFile(item.key, item.name)} />
                                                            <FaTimesCircle style={{ marginLeft: 15 }} className="attachment_btns" size="18" onClick={() => this.deleteFileDialog(item._id, item.key)} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}
                            </div>
                            <div style={{ marginTop: 15, marginLeft: 50, minHeight: 100, width: '90%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd', overflowY: 'scroll' }}>
                                <table style={{ width: '100%', height: 25 }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>W9 / W8BEN:</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table style={{ width: '100%', height: 25 }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Type</th>
                                            <th style={{ width: '20%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Attachment</th>
                                            <th style={{ width: '22%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Notes</th>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Date</th>
                                            <th style={{ width: '15%', color: '#fff', fontSize: 12, padding: 5, backgroundColor: 'rgb(1, 22, 48)' }}>Added By</th>
                                            <th style={{ width: '8%', color: '#fff', backgroundColor: 'rgb(1, 22, 48)' }}> </th>
                                        </tr>
                                    </thead>
                                </table>
                                {this.state.attachments.map((item, index) => {

                                    const d = new Date(item.date);
                                    const date = `${(d.getMonth() + 1)}/${d.getDate()}/${d.getFullYear()}`;

                                    var user = "";
                                    this.props.company.comp_users.map((c, i) => {
                                        if (item.user === c.tag) {
                                            user = c.name.first + " " + c.name.last;
                                        }
                                    })
                                    if (item.type === 'W9') return (
                                        <div key={index} className="attachment_table_client">
                                            <table style={{ width: '100%', height: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{item.type}</td>
                                                        <td style={{ width: '20%', color: '#000', fontSize: 12, padding: 5 }}>{item.name}</td>
                                                        <td style={{ width: '22%', color: '#000', fontSize: 12, padding: 5 }}>{item.notes}</td>
                                                        <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{date}</td>
                                                        <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5 }}>{user}</td>
                                                        <td style={{ width: '8%', color: '#000', padding: 5 }}>
                                                            <FaFileDownload className="attachment_btns" size="18" onClick={() => this.downloadFile(item.key, item.name)} />
                                                            <FaTimesCircle style={{ marginLeft: 15 }} className="attachment_btns" size="18" onClick={() => this.deleteFileDialog(item._id, item.key)} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div> :
                    <div style={{ marginLeft: 100, marginTop: 30 }}>
                        <h2 style={{ fontSize: 14, color: '#000' }}>Loading...</h2>
                    </div>}
            </div>
        )
    }

}


const mapStateToProps = state => ({
    offers: state.offers,
    company: state.company,
    tag: state.auth.user.tag,
});

export default connect(mapStateToProps, { uploadAttachmentClient, downloadAttachmentClient, deleteAttachmentClient })(ClientAttachments);