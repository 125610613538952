import { Data } from "@react-google-maps/api";
import {
    AR_LOADING,
    AR_GET,
    AR_FILTER,
    AR_SOCKET,
    AR_SOCKET_DELETE
} from "../actions/types";
import _ from 'lodash';

const initialState = {
    ar: [],
    filtered_ar: [],
    filter: {
        artist: '',
        city: '',
        state: '',
        country: '',
        agent: '',
    },
    loading: false,
    sort: {
        cat: 'artist',
        dir: 'asc'
    }
};

export default function (state = initialState, action) {
    let filtered_data = [...state.filtered_ar];
    switch (action.type) {
        case AR_LOADING:
            return {
                ...state,
                loading: true
            };
        case AR_GET:
            var sortedArtists = _.orderBy(action.payload, ['artist'], ['asc']);
            return {
                ...state,
                ar: sortedArtists,
                filtered_ar: sortedArtists,
                loading: false
            };
        case AR_FILTER:
            var ar_filter = action.payload;
            var data = state.ar;
            if (ar_filter.artist) {
                data = data.filter((item) => {
                    var filterBatch = item.artist
                    return filterBatch.indexOf(ar_filter.artist) >= 0
                })
            }
            if (ar_filter.status) {
                data = data.filter((item) => {
                    var filterBatch = item.status
                    return filterBatch.indexOf(ar_filter.status) >= 0
                })
            }
            data = _.orderBy(data, ['artist'], ['asc']);

            return {
                ...state,
                filtered_ar: data,
                filter: ar_filter,
                loading: false
            };
        // case ADD_AR:
        //     return {
        //         ...state,
        //         ar: [action.payload, ...state.ar],
        //     };
        // case UPDATE_AR:
        //     let data = [...state.ar];
        //     var index = data.findIndex(obj => obj._id === action.payload._id);
        //     data[index] = action.payload;
        //     return {
        //         ...state,
        //         ar: data,
        //     }
        case AR_SOCKET:
            var data = state.ar;
            var f_data = state.filtered_ar;
            var index = data.findIndex(obj => obj._id === action.payload._id);
            var f_index = f_data.findIndex(obj => obj._id === action.payload._id);
            if (index >= 0) {
                data[index] = action.payload;
                if (f_index >= 0) {
                    f_data[f_index] = action.payload;
                }
            } else {
                data = [action.payload, ...data];
                var ar_filter = state.filter;
                f_data = data;
                if (ar_filter.artist) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.artist
                        return filterBatch.indexOf(ar_filter.artist) >= 0
                    })
                }
                if (ar_filter.status) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.status
                        return filterBatch.indexOf(ar_filter.status) >= 0
                    })
                }
                f_data = _.orderBy(f_data, ['artist'], ['asc']);
            }
            return {
                ...state,
                ar: data,
                filtered_ar: f_data
            }
        case AR_SOCKET_DELETE:
            return {
                ...state,
                ar: state.ar.filter(ar => ar._id !== action.payload._id),
                filtered_ar: state.filtered_ar.filter(ar => ar._id !== action.payload._id),
            };
        default:
            return state;
    }
}