import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Geocode from "react-geocode";
import Map from '../components/map.component';
import { FaPrint, FaDownload, FaForward, FaBackward, FaBars, FaEye } from 'react-icons/fa';
import { RenderDealMemo, RenderItinerary, RenderContract, clearPdfs } from '../actions/pdfActions';

class MapsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            pdfindex: 0,
            loading: true,
            pdf_data: [],
            lat: "",
            lng: "",
            map_data: [],
            map_loaded: false,
            coords: '',
            coordsRev: '',
            dist: [],
            markerSelect: '',
        };
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        offerdetail: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        pdf: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        festivals: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        console.log(this.props.data)
        Geocode.setApiKey(this.props.auth.user.api.google);
        Geocode.setLanguage("en");

        var newArray = [];
        var allCoords = [];
        var allCoordsRev = [];
        await Promise.all(this.props.data.map(async (item, index) => {
            var client = "";
            var venue = "";
            var addressStr = "";
            var address = {};

            this.props.clients.clients.map(c => {
                if (item.artist === c._id) {
                    client = c.client;
                }
            })
            this.props.items.items.map(v => {
                if (item.venueid === v._id) {
                    venue = v.venue_name;
                    address = v.address;
                    addressStr = JSON.stringify(v.address);
                }
            })
            this.props.festivals.items.map(v => {
                if (item.venueid === v._id) {
                    venue = v.festival;
                    address = v.address;
                    addressStr = JSON.stringify(v.address);
                }
            })

            var coord = {};
            if (!item.coords) {
                coord = await Geocode.fromAddress(addressStr).then(
                    (response) => {
                        const { lat, lng } = response.results[0].geometry.location;
                        var coordObj = {
                            lat: lat,
                            lng: lng
                        }
                        return coordObj
                    },
                    (error) => {
                        console.error('ERROR', error);
                        var coordObj = {
                            lat: null,
                            lng: null
                        }
                        return coordObj
                    }
                )
            } else {
                coord = {
                    lat: item.coords.lat,
                    lng: item.coords.lng
                }
            }
            const newCoords = {
                lat: coord.lat,
                lng: coord.lng,
            }
            const newCoordsRev = {
                lng: coord.lng,
                lat: coord.lat
            }
            const newItem = {
                lat: coord.lat,
                lng: coord.lng,
                client: client,
                venue: venue,
                address: address,
                dates: item.dates
            }
            console.log(newItem)
            newArray[index] = newItem;
            allCoords[index] = newCoords;
            allCoordsRev[index] = newCoordsRev;
            //newArray = await [...newArray, newItem]
            //var newArray = [...this.state.map_dataj
            //newArray[index] = newItem;
            //const newArray = [...this.state.map_data, newItem]
        }))
        // var coordsStr = [];
        // allCoords.map((item, index) => {
        //     coordsStr[index] = {item.lat, item.lng}
        // })
        var coordsStr = allCoords;
        var coordsRevStr = allCoordsRev;
        //coordsStr = Object.assign(coordsStr);
        console.log(coordsStr, coordsRevStr, newArray)

        this.setState({
            map_data: newArray,
            coords: coordsStr,
            coordsRev: coordsRevStr,
            map_loaded: true
        })
    }

    reportDistance = (item, index) => {
        console.log(item)
        var data = this.state.map_data;
        var row = item.elements[index]

        data[index].dist = row.distance.text;
        data[index].dur = row.duration.text;

        console.log(data)
        // var newArray = [...this.state.dist, props]
        // this.setState({
        //     dist: newArray
        // })
        this.setState({
            map_data: data
        })
    }

    markerHover = (index) => {
        this.setState({
            markerSelect: index
        })
    }

    render() {
        //var length = this.props.offerdetail.offerdetail.length;
        //<img src={satellite} width={200} height={200} />
        //console.log(this.props.offerdetail.offerdetail)

        return (
            <div className="mode-s" >
                <div className="mode-s-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                        <span className="close" onClick={this.props.close}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        <span className="close">
                            <FaPrint size="18" color="#cdcdcd" />
                        </span>
                    </div>
                    <div style={{ width: '100%', height: 600, display: 'flex' }}>
                        <div style={{ width: '15%', height: 600, backgroundColor: 'rgb(1, 22, 48)', overflowY: 'scroll' }}>
                            {this.state.map_data.map((item, index) => {
                                const datesArray = item.dates.map(d => moment(d.date));
                                var end = moment.max(datesArray).format('MM/DD/YYYY');
                                var start = moment.min(datesArray).format('MM/DD/YYYY');
                                return (
                                    <div key={index} style={{ width: '100%' }}>
                                        {index > 0 ?
                                            <div style={{ width: '95%', border: '2px solid #333', backgroundColor: '#333', height: 'auto', borderTopRightRadius: 5, borderBottomRightRadius: 5, marginBottom: 6 }}>
                                                <h2 style={{ fontSize: 10, color: '#fff', paddingLeft: 5, paddingTop: 5 }}>Distance: {item.dist}</h2>
                                                <h2 style={{ fontSize: 10, color: '#fff', paddingLeft: 5 }}>Duration: {item.dur}</h2>
                                            </div> : null}
                                        <div style={{ width: '95%', border: '2px solid #333', backgroundColor: this.state.markerSelect === index ? '#cdcdcd' : 'rgb(1, 22, 48)', height: 'auto', borderTopRightRadius: 5, borderBottomRightRadius: 5, marginBottom: 6 }}>
                                            {/* <h2 style={{ fontSize: 12, color: '#fff', paddingLeft: 5, paddingTop: 5, fontWeight: 'bold' }}>{item.client}</h2> */}
                                            {start === end ?
                                                <h2 style={{ fontSize: 10, color: this.state.markerSelect === index ? '#000' : '#fff', paddingLeft: 5 }}>{start}</h2> :
                                                <h2 style={{ fontSize: 10, color: this.state.markerSelect === index ? '#000' : '#fff', paddingLeft: 5 }}>{start} - {end}</h2>}
                                            <h2 style={{ fontSize: 10, color: this.state.markerSelect === index ? '#000' : '#fff', paddingLeft: 5 }}>{item.venue}<br />{item.address.address}<br />{item.address.address2 ? item.address.address2 : null}{item.address.address2 ? <br /> : null}{item.address.city}, {item.address.state} {item.address.postal_code}</h2>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ width: '85%', height: '100%', backgroundColor: '#515659' }}>
                            {this.state.map_loaded === true && this.state.map_data.length > 0 ?
                                <Map GKey={this.props.auth.user.api.google} lat={this.state.lat} lng={this.state.lng} map_data={this.state.map_data} coordsStr={this.state.coords} coordsRevStr={this.state.coordsRev} reportDistance={this.reportDistance} markerHover={this.markerHover} /> : null}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

// function GeocodeStr(addressStr) {
//     Geocode.fromAddress(addressStr).then(
//         async (response) => {
//             const { lat, lng } = response.results[0].geometry.location;

//             const newItem = {
//                 lat: lat,
//                 lng: lng,
//                 client: client,
//                 venue: venue,
//                 address: address
//             }
//             var newArray = [...this.state.map_data];
//             newArray[index] = newItem;
//             //const newArray = [...this.state.map_data, newItem]
//             console.log(newArray)
//             await this.setState({
//                 map_data: newArray
//             })
//         },
//         (error) => {
//             console.error(error);
//         }
//     );
// }

const mapStateToProps = state => ({
    offerdetail: state.offerdetail,
    clients: state.clients,
    pdf: state.pdf,
    items: state.items,
    festivals: state.festivals,
    buyers: state.buyers,
    auth: state.auth
});

export default connect(mapStateToProps, { RenderDealMemo, RenderItinerary, RenderContract, clearPdfs })(MapsModal);