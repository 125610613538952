import React, { useState } from "react";
import DatePicker from "react-datepicker";
import VenueDbFilter from './venue.db.filter';
import BuyerDbFilter from './buyer.db.filter';
import OfferDbFilter from './offer.db.filter';
import ContactDbFilter from './contact.db.filter';
import ClientDbFilter from './client.db.filter';
import FestivalDbFilter from './festival.db.filter';
import CountsDbFilter from './counts.db.filter';
import PackagingDbFilter from './packaging.db.filter';
import ArDbFilter from './ar.db.filter';
import FinalsDbFilter from './finals.db.filter';

export default function DatabaseFilters(props) {

    return (
        <div>
            {props.db_sub === 'venues' ?
                <VenueDbFilter venue_filter={props.venue_filter} applyVenueFilter={props.applyVenueFilter} filterChange={props.filterChange} /> :
                props.db_sub === 'buyers' ?
                    <BuyerDbFilter buyer_filter={props.buyer_filter} applyBuyerFilter={props.applyBuyerFilter} filterChange={props.filterChange} /> :
                    props.db_sub === 'contacts' ?
                        <ContactDbFilter contact_filter={props.contact_filter} applyContactFilter={props.applyContactFilter} filterChange={props.filterChange} /> :
                        props.db_sub === 'clients' ?
                            <ClientDbFilter client_filter={props.client_filter} applyClientFilter={props.applyClientFilter} company={props.company} filterChange={props.filterChange} /> :
                            props.db_sub === 'festivals' ?
                                <FestivalDbFilter festival_filter={props.festival_filter} applyFestivalFilter={props.applyFestivalFilter} filterChange={props.filterChange} filterDateChange={props.filterDateChange} filterCapChange={props.filterCapChange} /> :
                                props.db_sub === 'counts' ?
                                    <CountsDbFilter counts_filter={props.counts_filter} applyCountsFilter={props.applyCountsFilter} clients={props.clients} filterChange={props.filterChange} /> :
                                    props.db_sub === 'packaging' ?
                                        <PackagingDbFilter packaging_filter={props.packaging_filter} applyPackagingFilter={props.applyPackagingFilter} filterChange={props.filterChange} /> :
                                        props.db_sub === 'ar' ?
                                            <ArDbFilter ar_filter={props.ar_filter} applyArFilter={props.applyArFilter} filterChange={props.filterChange} /> :
                                            props.db_sub === 'finals' ?
                                                <FinalsDbFilter finals_filter={props.finals_filter} applyFinalsFilter={props.applyFinalsFilter} clients={props.clients} filterChange={props.filterChange} filterDateChange={props.filterDateChange} agents={props.company.agents} /> :
                                                props.db_sub === 'offers' ?
                                                    <OfferDbFilter offer_filter={props.offer_filter} applyOfferFilter={props.applyOfferFilter} clients={props.clients} filterChange={props.filterChange} filterDateChange={props.filterDateChange} agents={props.company.agents} /> : null}
        </div>
    );
}