import React, { useEffect, useState } from "react";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';

var data = require('currency-codes/data');

const useStyles = makeStyles((theme) => ({
    label: {
        display: 'block'
    },
    input: {
        width: '100%',
        fontSize: 12,
        paddingTop: '2%', paddingBottom: '2%', paddingLeft: '4%',
        height: 28,
        color: '#000'
    },
    listbox: {
        width: 220,
        margin: 0,
        padding: 10,
        zIndex: 1,
        position: 'relative',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        fontSize: 14,
        maxHeight: 200,
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            backgroundColor: '#e6f7ff',
            color: 'black',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#e6f7ff',
            color: 'black',
        },
    },
    listboxAb: {
        width: 200,
        margin: 0,
        padding: 10,
        zIndex: 1,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        fontSize: 14,
        maxHeight: 200,
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            backgroundColor: '#e6f7ff',
            color: 'black',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#e6f7ff',
            color: 'black',
        },
    },
}));

export default function CurrencyAutocomplete({ val, onChange, set }) {
    const [stateCurrencySelect, setCurrencySelect] = useState(val);

    useEffect(() => {
        setCurrencySelect(val);
    }, [val])

    console.log(stateCurrencySelect, val, data)
    const classes = useStyles();
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-demo',
        value: stateCurrencySelect,
        options: data,
        getOptionLabel: (option) => option.code ? option.code : stateCurrencySelect,
        getOptionSelected: (option, value) => option.code === value,
        onChange: (event, newValue) => {
            if (newValue) {
                onChange(newValue, set)
            } else if (!newValue) {
                onChange('')
            };
        }
    });

    return (
        <div>
            <div {...getRootProps()}>
                <input className={classes.input} placeholder={stateCurrencySelect} placeholderTextColor={'#000'} {...getInputProps()} />
            </div>
            {groupedOptions.length > 0 ? (
                <ul className={set === 'Packaging' ? classes.listboxAb : classes.listbox} {...getListboxProps()}>
                    {groupedOptions.map((option, index) => (
                        <li style={{ padding: 5 }} {...getOptionProps({ option, index })}>{option.currency} ({option.code})</li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
}