import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    FESTIVALS_LOADING,
    FESTIVALS_GET,
    FESTIVALS_FILTER,
    FESTIVALS_SORT,
    FESTIVALS_SOCKET,
    FESTIVALS_SOCKET_DELETE,
    NOtIFICATIONS
} from "./types";

export const setFestivalsLoading = () => {
    return {
        type: FESTIVALS_LOADING
    };
};

// async function transpose(array, getState) {
//     array.map(async (item, index) => {
//         const data = {
//             data: item
//         }
//         await axios.post(`${process.env.REACT_APP_API}/festivals/update/transpose/${item._id}`, data, tokenConfig(getState))
//             .then(res => {})
//             .catch(err => {})
//     })
// }

export const getFestivals = () => async (dispatch, getState) => {
    dispatch(setFestivalsLoading());
    await axios.get(`${process.env.REACT_APP_API}/festivals/`, tokenConfig(getState))
        .then(res => {
            // var newArray = [];
            // res.data.map((item, index) => {
            //     item.submissions = [];
            //     newArray.push(item)
            // })
            // transpose(newArray, getState);

            dispatch({
                type: FESTIVALS_GET,
                payload: res.data
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const applyFestivalFilter = (festival_filter) => (dispatch, getState) => {
    dispatch(setFestivalsLoading());
    dispatch({
        type: FESTIVALS_FILTER,
        payload: festival_filter
    })
};

export const festivalSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: FESTIVALS_SORT,
        payload: sort
    })
};

export const addFestival = festival => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/festivals/add`, festival, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_FESTIVAL,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const addFestivalSocket = festival => (dispatch) => {
//     dispatch({
//         type: ADD_FESTIVAL_SOCKET,
//         payload: festival
//     })
// }

export const updateFestival = (festival, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/festivals/update/${id}`, festival, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const updateFestivalSocket = festival => (dispatch) => {
//     dispatch({
//         type: UPDATE_FESTIVAL_SOCKET,
//         payload: festival
//     })
// }

export const festivalSocket = festival => (dispatch) => {
    dispatch({
        type: FESTIVALS_SOCKET,
        payload: festival
    })
}

export const deleteFestival = id => (dispatch, getState) => {
    return axios.delete(`${process.env.REACT_APP_API}/festivals/${id}`, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: DELETE_FESTIVAL,
            //    payload: id
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const deleteFestivalSocket = festival => (dispatch) => {
    dispatch({
        type: FESTIVALS_SOCKET_DELETE,
        payload: festival
    })
}

export const addSubmissions = (submissions, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/submissions/add/${id}`, submissions, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_FESTIVAL,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const festivalDupCheck = (dupCheck) => async (dispatch, getState) => {
    const festivals = getState().festivals.items;
    var check = [];
    festivals.map(item => {
        if(item.festival === dupCheck){
            check = [...check, item]
        }
    })
    return check
}