import React, { Component } from "react";
import { connect } from 'react-redux';
import RichTextEditor from 'react-rte';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';

class ClientPitch extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {


    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {

        return (
            <div style={{ width: '100%', height: 500 }}>
                <div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                    <h2 style={{ marginTop: 30, color: '#000', fontSize: 18, marginLeft: 60, height: 15 }}>Client Pitch:</h2>
                    <RichTextEditor
                        autoFocus
                        value={this.props.pitch}
                        onChange={this.props.onPitchChange}
                        disabled={this.props.edit ? false : true}
                    />
                    {this.props.pitch_attach_array.length > 0 ?
                        <p style={{ fontSize: 12, color: '#000', marginLeft: 60, marginTop: 15 }}>Attachments: {this.props.pitch_attach_array.map((item, index) => { var vals = ""; this.props.attachments.map((a, b) => { if (a._id === item) { if (index === 0) { vals = vals + a.name } else { vals = vals + ', ' + a.name } } }); return (vals) })}</p> : null}
                    <span style={{ display: 'flex' }}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                            checked={this.props.pitch_image}
                            disabled={this.props.edit ? false : true}
                            color="default"
                            value={this.props.pitch_image}
                            name="pitch_image"
                            onChange={this.props.handleCheckboxChange}
                        />
                        <p style={{ fontSize: 12, color: '#000', marginLeft: 60, marginTop: 15 }}>Include Photo</p>
                    </span>
                    {this.props.pitch_image ?
                        <table style={{ width: '90%', height: 'auto', marginLeft: '5%', marginRight: '5%' }}>
                            {this.props.attachments.length > 0 ?
                                this.props.attachments.map((item, index) => {
                                    if (item.type === 'photo') {
                                        return (
                                            <tbody>
                                                <tr style={{ border: '.5px solid #ddd' }}>
                                                    <td style={{ width: 25, color: '#000', fontSize: 12, padding: 5 }}>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                            checked={this.props.pitch_image_select === item._id}
                                                            disabled={this.props.edit ? false : true}
                                                            color="default"
                                                            value={item._id}
                                                            name="pitch_image_select"
                                                            onChange={this.props.handleCheckboxChange}
                                                        />
                                                    </td>
                                                    <td style={{ width: 25, color: '#000', fontSize: 12, padding: 5 }}><img style={{ width: 'auto', height: 24 }} src={item.file} /></td>
                                                    <td style={{ width: 200, color: '#000', fontSize: 12, padding: 5 }}>{item.name}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    }
                                }) :
                                <tbody>
                                    <tr style={{ border: '.5px solid #ddd' }}>
                                        <td style={{ width: 25, color: '#000', fontSize: 12, padding: 5 }}></td>
                                        <td style={{ width: 25, color: '#000', fontSize: 12, padding: 5 }}></td>
                                        <td style={{ width: 200, color: '#000', fontSize: 12, padding: 5 }}>No files saved</td>
                                    </tr>
                                </tbody>}
                        </table> : null}
                    <span style={{ display: 'flex' }}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                            checked={this.props.pitch_links}
                            disabled={this.props.edit ? false : true}
                            color="default"
                            value={this.props.pitch_links}
                            name="pitch_links"
                            onChange={this.props.handleCheckboxChange}
                        />
                        <p style={{ fontSize: 12, color: '#000', marginLeft: 60, marginTop: 15 }}>Include Client Social Links</p>
                    </span>
                    {this.props.pitch_links ?
                        <table style={{ width: '90%', height: 'auto', marginLeft: '5%', marginRight: '5%' }}>
                            {this.props.links.length > 0 ?
                                this.props.links.map((item, index) => {
                                    return (
                                        <tbody>
                                            <tr style={{ border: '.5px solid #ddd' }}>
                                                <td style={{ width: 25, color: '#000', fontSize: 12, padding: 5 }}>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                        checked={this.props.pitch_links_array.includes(item._id)}
                                                        disabled={this.props.edit ? false : true}
                                                        color="default"
                                                        value={item._id}
                                                        name="pitch_links_array"
                                                        onChange={this.props.handleCheckboxChange}
                                                    />
                                                </td>
                                                <td style={{ width: 125, color: '#000', fontSize: 12, padding: 5 }}>{item.desc}</td>
                                                <td style={{ width: 200, color: '#000', fontSize: 12, padding: 5 }}>{item.url}</td>
                                            </tr>
                                        </tbody>
                                    )
                                }) :
                                <tbody>
                                    <tr style={{ border: '.5px solid #ddd' }}>
                                        <td style={{ width: 25, color: '#000', fontSize: 12, padding: 5 }}></td>
                                        <td style={{ width: 325, color: '#000', fontSize: 12, padding: 5 }}>No Socials Logged</td>
                                    </tr>
                                </tbody>}
                        </table> : null}
                    {/* <span style={{ display: 'flex' }}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                            checked={this.props.pitch_attach}
                            disabled={this.props.edit ? false : true}
                            color="default"
                            value={this.props.pitch_attach}
                            name="pitch_attach"
                            onChange={this.props.handleCheckboxChange}
                        />
                        <p style={{ fontSize: 12, color: '#000', marginLeft: 60, marginTop: 15 }}>Add Attachment(s)</p>
                    </span>
                    {this.props.pitch_attach ?
                        <table style={{ width: '90%', height: 'auto', marginLeft: '5%', marginRight: '5%' }}>
                            {this.props.attachments.length > 0 ?
                                this.props.attachments.map((item, index) => {
                                    if (item.type === 'photo') {
                                        return (
                                            <tbody>
                                                <tr style={{ border: '.5px solid #ddd' }}>
                                                    <td style={{ width: 25, color: '#000', fontSize: 12, padding: 5 }}>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                                            checked={this.props.pitch_attach_array.includes(item._id)}
                                                            disabled={this.props.edit ? false : true}
                                                            color="default"
                                                            value={item._id}
                                                            name="pitch_attach_array"
                                                            onChange={this.props.handleCheckboxChange}
                                                        />
                                                    </td>
                                                    <td style={{ width: 25, color: '#000', fontSize: 12, padding: 5 }}><img style={{ width: 'auto', height: 24 }} src={item.file} /></td>
                                                    <td style={{ width: 200, color: '#000', fontSize: 12, padding: 5 }}>{item.name}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    }
                                }) :
                                <tbody>
                                    <tr style={{ border: '.5px solid #ddd' }}>
                                        <td style={{ width: 25, color: '#000', fontSize: 12, padding: 5 }}></td>
                                        <td style={{ width: 25, color: '#000', fontSize: 12, padding: 5 }}></td>
                                        <td style={{ width: 200, color: '#000', fontSize: 12, padding: 5 }}>No files saved</td>
                                    </tr>
                                </tbody>}
                        </table> : null} */}
                </div>
            </div>
        );
    }
}

export default connect(null, {})(ClientPitch);