import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import StatusAutocomplete from '../autocompletes/status-autocomplete';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CountrySelect from '../countrySelect';

export default function FinalsDbFilter(props) {
    const [stateFilter, setFilter] = useState(props.finals_filter);

    useEffect(() => {
        setFilter(props.finals_filter);
    }, [props.finals_filter])

    return (
        <div style={{ paddingBottom: 26, backgroundColor: '#111111', border: '2px solid #111', width: '100%' }} className="navbar-nav">
            {/* <span style={{ display: 'flex' }}>
                <button style={{ width: 'auto', padding: 5, height: 28, fontSize: 10, color: '#fff', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} className="btn btn-primary px-3" onClick={applyFilter}>APPLY</button>
                <button style={{ padding: 5, height: 28, fontSize: 10, color: '#fff', backgroundColor: '#333', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold' }} onClick={clearFilter}>RESET FILTERS</button>
            </span> */}
            <div style={{ display: 'flex', marginTop: 70, marginLeft: 50 }} >
                <div style={{ width: 100, marginTop: -30 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}> </p>
                </div>
                <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Client:</p>
                </div>
                <div style={{ width: 150, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Start Date:</p>
                </div>
                <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Venue:</p>
                </div>
                <div style={{ width: 150, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>State | Province:</p>
                </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 50, marginTop: -10 }} className="form-group">
                <div style={{ width: 100, height: 28, display: 'flex', flexDirection: 'row' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        color="default"
                        checked={stateFilter.unsettled}
                        value={!stateFilter.unsettled}
                        name="unsettled"
                        onChange={(e) => props.filterChange(e, 'finals_filter', 'checkbox')}
                    />
                    <label style={{ fontSize: 12, color: '#cdcdcd' }}>Unsettled Only</label>
                    {/* <select style={{ width: 98, height: 26, fontSize: 12, padding: 0 }} className="form-control" value={stateFilter.status} name="status" onChange={(e) => props.filterChange(e, 'finals_filter')}>
                        <option value="">All</option>
                        <option value="Unsettled">Unsettled Only</option>
                    </select> */}
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <select style={{ width: 200, fontSize: 12, height: 28 }} className="form-control-sm" value={stateFilter.client} name="client" onChange={(e) => props.filterChange(e, 'finals_filter')}>
                        <option value=""> </option>
                        {props.clients.map((item) => {
                            return (
                                <option key={item._id} value={item._id}>{item.client}</option>
                            )
                        })}
                    </select>
                </div>
                <div style={{ width: 150, marginLeft: 40, height: 28 }}>
                    <DatePicker
                        className="react-datepicker"
                        style={{ fontSize: 12 }}
                        value={stateFilter.start_date}
                        selected={stateFilter.start_date}
                        onChange={(v) => props.filterDateChange(v, 'start_date', 'finals_filter')}
                        maxDate={new Date()}
                        todayButton="Today"
                        showMonthDropdown
                        showYearDropdown
                    />
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5 }}
                        value={stateFilter.venue}
                        name="venue"
                        type="text"
                        onChange={(e) => props.filterChange(e, 'finals_filter')}
                    />
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5 }}
                        type="text"
                        name="state"
                        value={stateFilter.state}
                        onChange={(e) => props.filterChange(e, 'finals_filter')}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 5, marginLeft: 50 }}>
                <div style={{ width: 100 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Deal Id:</p>
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Agent:</p>
                </div>
                <div style={{ width: 150, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>End Date:</p>
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>City:</p>
                </div>
                <div style={{ width: 150, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Country:</p>
                </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 50, marginTop: -10 }} className="form-group">
                <input
                    className="form-control-sm"
                    style={{ width: 100, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                    onChange={(e) => props.filterChange(e, 'finals_filter')}
                    value={stateFilter.deal_id}
                    name="deal_id"
                    type="text"
                />
                <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                    <select style={{ width: 200, height: 26, fontSize: 12, padding: 0 }} className="form-control" value={stateFilter.agent} type="agent" name="agent" onChange={(e) => props.filterChange(e, 'finals_fiter')}>
                        <option value="">All</option>
                        {props.agents ? props.agents.map((item, index) => {
                            return (
                                <option key={index} value={item.tag}>{item.name.first} {item.name.last}</option>
                            )
                        }) : null}
                    </select>
                </div>
                <div style={{ width: 150, marginLeft: 40, marginTop: -2, height: 28 }}>
                    <DatePicker
                        className="react-datepicker"
                        style={{ fontSize: 12 }}
                        value={stateFilter.end_date}
                        selected={stateFilter.end_date}
                        onChange={(v) => props.filterDateChange(v, 'end_date', 'finals_filter')}
                        maxDate={new Date()}
                        todayButton="Today"
                        showMonthDropdown
                        showYearDropdown
                    />
                </div>
                <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, height: 26, fontSize: 12 }}
                        value={stateFilter.city}
                        name="city"
                        type="text"
                        onChange={(e) => props.filterChange(e, 'finals_filter')}
                    />
                </div>
                <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                    <CountrySelect country={stateFilter.country} filterChange={(e) => props.filterChange(e, 'finals_filter')} />
                </div>
            </div>
            {/* <div style={{ width: 'auto' }}>
                <button style={{ padding: 5, marginLeft: '10%', minHeight: 28, height: 'auto', fontSize: 10, color: '#fff', backgroundColor: '#333', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold', marginTop: 92, marginRight: '5%', width: '90%' }} onClick={clearFilter}>RESET FILTERS</button>
                <button style={{ width: 'auto', padding: 5, marginLeft: '10%', minHeight: 28, height: 'auto', fontSize: 10, color: '#fff', border: '1px solid #111', borderRadius: 5, fontWeight: 'bold', marginRight: '5%', width: '90%' }} className="btn btn-primary px-3" onClick={applyFilter}>APPLY</button>
            </div> */}
        </div>
    );
}