import {
    PACKAGING_LOADING,
    PACKAGING_GET,
    PACKAGING_FILTER,
    PACKAGING_SOCKET,
    PACKAGING_SOCKET_DELETE,
    PACKAGING_SORT
} from "../actions/types";
import _ from 'lodash';
import moment from 'moment';

const initialState = {
    packaging: [],
    filtered_packaging: [],
    loading: false,
    filter: {
        deal_id: '',
        status: 'Active',
        artist: '',
        agent: '',
        start_date: moment().format('MM/DD/YYYY'),
        end_date: moment().add(1, 'years').format('MM/DD/YYYY'),
        venue: '',
        city: '',
        state: '',
        country: ''
    },
    sort: {
        cat: '',
        dir: 'asc'
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PACKAGING_LOADING:
            return {
                ...state,
                loading: true
            };
        case PACKAGING_GET:
            var dataArray = action.payload;
            var packaging_filter = state.filter;
            var filteredArray = action.payload;
            if (packaging_filter.start_date) {
                filteredArray = filteredArray.filter((item) => {
                    const today = moment().add(-1, 'days');
                    var val = _.get(item, 'tour_dates');
                    var closest = "";
                    if (val.length >= 1) {
                        closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                    } else {
                        closest = { date: null };
                    }
                    var filterBatch = new Date(closest.date)
                    var start = new Date(packaging_filter.start_date)
                    return filterBatch >= start
                })
            }
            if (packaging_filter.end_date) {
                filteredArray = filteredArray.filter((item) => {
                    const today = new Date();
                    var val = _.get(item, 'tour_dates')
                    var furthest = "";
                    if (val.length >= 1) {
                        furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                    } else {
                        furthest = { date: null };
                    }
                    var filterBatch = new Date(furthest.date)
                    var end = new Date(packaging_filter.end_date)
                    return filterBatch <= end
                })
            }
            if (packaging_filter.artist) {
                filteredArray = filteredArray.filter((item) => {
                    var filterBatch = item.artist
                    return filterBatch.indexOf(packaging_filter.artist) >= 0
                })
            }
            filteredArray = _.sortBy(filteredArray, function (dateObj) {
                return new Date(dateObj.createdAt);
            });
            // if(packaging_filter.status){
            //     filteredArray = filteredArray.filter((item) => {
            //         var filterBatch = item.status
            //         return filterBatch.indexOf(packaging_filter.status) >= 0
            //     })
            // }
            // filteredArray = _.sortBy(filteredArray, function (dateObj) {
            //     return new Date(dateObj.tour_dates[0].date);
            // });
            return {
                ...state,
                packaging: dataArray,
                filtered_packaging: filteredArray,
                loading: false
            };
        case PACKAGING_FILTER:
            packaging_filter = action.payload;
            filteredArray = state.packaging;
            if (packaging_filter.start_date) {
                filteredArray = state.packaging.filter((item) => {
                    const today = moment().add(-1, 'days');
                    var val = _.get(item, 'tour_dates');
                    var closest = "";
                    if (val.length >= 1) {
                        closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                    } else {
                        closest = { date: null };
                    }
                    var filterBatch = new Date(closest.date)
                    var start = new Date(packaging_filter.start_date)
                    return filterBatch >= start
                })
            }
            if (packaging_filter.end_date) {
                filteredArray = filteredArray.filter((item) => {
                    const today = new Date();
                    var val = _.get(item, 'tour_dates')
                    var furthest = "";
                    if (val.length >= 1) {
                        furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                    } else {
                        furthest = { date: null };
                    }
                    var filterBatch = new Date(furthest.date)
                    var end = new Date(packaging_filter.end_date)
                    return filterBatch <= end
                })
            }
            if (packaging_filter.artist) {
                filteredArray = filteredArray.filter((item) => {
                    var filterBatch = item.artist
                    return filterBatch.indexOf(packaging_filter.artist) >= 0
                })
            }
            if (packaging_filter.status) {
                filteredArray = filteredArray.filter((item) => {
                    var filterBatch = item.status
                    return filterBatch.indexOf(packaging_filter.status) >= 0
                })
            }
            var sort = state.sort;
            if (sort.cat === '' || sort.cat === 'date_added') {
                filteredArray = _.sortBy(filteredArray, function (dateObj) {
                    return new Date(dateObj.createdAt);
                });
            } else {
                filteredArray = _.orderBy(filteredArray, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            }
            // filteredArray = _.sortBy(filteredArray, function (dateObj) {
            //     return new Date(dateObj.tour_dates[0].date);
            // });
            return {
                ...state,
                filtered_packaging: filteredArray,
                filter: packaging_filter,
                loading: false
            };
        // case ADD_PACKAGING:
        //     return {
        //         ...state,
        //         //clients: [action.payload, ...state.clients],
        //     };
        // case ADD_PACKAGING_SOCKET:
        //     return {
        //         ...state,
        //         packaging: [action.payload, ...state.packaging],
        //     };
        // case UPDATE_PACKAGING:
        //     let data = [...state.packaging];
        //     var index = data.findIndex(obj => obj._id === action.payload._id);
        //     data[index] = action.payload;

        //     var filtered_data = state.filtered_packaging;
        //     var found = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //     if (found >= 0) {
        //         filtered_data[found] = action.payload;
        //     }

        //     return {
        //         ...state,
        //         packaging: data,
        //         filtered_packaging: filtered_data
        //     }
        case PACKAGING_SOCKET:
            var data = state.packaging;
            var f_data = state.filtered_packaging;
            var index = state.packaging.findIndex(obj => obj._id === action.payload._id);
            var f_index = state.filtered_packaging.findIndex(obj => obj._id === action.payload._id);
            if (index >= 0) {
                data[index] = action.payload;
                if (f_index >= 0) {
                    f_data[f_index] = action.payload;
                }
            } else {
                data = [action.payload, ...state.packaging];
                packaging_filter = state.filter;
                f_data = data;

                if (packaging_filter.start_date) {
                    f_data = f_data.filter((item) => {
                        const today = moment().add(-1, 'days');
                        var val = _.get(item, 'tour_dates');
                        var closest = "";
                        if (val.length >= 1) {
                            closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                        } else {
                            closest = { date: null };
                        }
                        var filterBatch = new Date(closest.date)
                        var start = new Date(packaging_filter.start_date)
                        return filterBatch >= start
                    })
                }
                if (packaging_filter.end_date) {
                    f_data = f_data.filter((item) => {
                        const today = new Date();
                        var val = _.get(item, 'tour_dates')
                        var furthest = "";
                        if (val.length >= 1) {
                            furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                        } else {
                            furthest = { date: null };
                        }
                        var filterBatch = new Date(furthest.date)
                        var end = new Date(packaging_filter.end_date)
                        return filterBatch <= end
                    })
                }
                if (packaging_filter.artist) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.artist
                        return filterBatch.indexOf(packaging_filter.artist) >= 0
                    })
                }
                if (packaging_filter.status) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.status
                        return filterBatch.indexOf(packaging_filter.status) >= 0
                    })
                }
                sort = state.sort;
                if (sort.cat === '' || sort.cat === 'date_added') {
                    f_data = _.sortBy(f_data, function (dateObj) {
                        return new Date(dateObj.createdAt);
                    });
                } else {
                    f_data = _.orderBy(f_data, [`${sort.cat}`.toLowerCase()], [sort.dir]);
                }
            }
            return {
                ...state,
                packaging: data,
                filtered_packaging: f_data
            }
        // case DELETE_PACKAGING:
        //     return {
        //         ...state,
        //         packaging: state.packaging.filter(packaging => packaging._id !== action.payload),
        //     };
        case PACKAGING_SOCKET_DELETE:
            return {
                ...state,
                packaging: state.packaging.filter(packaging => packaging._id !== action.payload._id),
                //filtered_packaging: state.filtered_buyers.filter(buyer => buyer._id !== action.payload._id),
            };
        case PACKAGING_SORT:
            var sort = action.payload;
            var sortedPackaging = [];
            if (sort.cat === '' || sort.cat === 'date_added') {
                sortedPackaging = _.sortBy(state.filtered_packaging, function (dateObj) {
                    return new Date(dateObj.createdAt);
                });
            } else {
                sortedPackaging = _.orderBy(state.filtered_packaging, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            }
            return {
                ...state,
                filtered_packaging: sortedPackaging,
                sort: sort,
                loading: false
            };
        default:
            return state;
    }
}