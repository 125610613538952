import React, { useState, useCallback, useEffect } from 'react';
import { Card } from './Card';
import update from 'immutability-helper';
const style = {
    width: '100%'
};
export const Container = ({ expensearray, updateExpense, deleteExpense, edit, gross_tickets, gross_pot_val, expense_edit, expense_val, clearExpenseUpdate, saveExpenseUpdate, handleArrayUpdate, handleArrayFormattedChange, currency_symbol, expense_pin, seperate_expenses, show_select, handlePinnedArray2Change, droppedCards }) => {
    {
        const [cards, setCards] = useState(expensearray);

        console.log(cards, expense_edit)

        useEffect(() => {
            setCards(expensearray);
        }, [expensearray])

        const moveCard = useCallback((dragIndex, hoverIndex) => {
            const dragCard = cards;
            //const dragCard = cards[dragIndex];
            // setCards(update(cards, {
            //     $splice: [
            //         [dragIndex, 1],
            //         [hoverIndex, 0, dragCard],
            //     ],
            // }));
            [dragCard[dragIndex], dragCard[hoverIndex]] = [
                dragCard[hoverIndex],
                dragCard[dragIndex]
            ];
            droppedCards([...dragCard], 'expensearray')
        }, [cards]);
        const renderCard = (card, index) => {
            console.log(card)
            return (<Card index={index} id={card._id} key={card._id} show_index={index} expense={card.expense} type={card.type} amount={card.amount} cap={card.cap} cappedamount={card.capped_amount} notes={card.notes} setExpense={updateExpense} deleteExpense={deleteExpense} edit={edit} gross_tickets={gross_tickets} gross_pot_val={gross_pot_val} expense_edit={expense_edit[card._id]} expense_val={expense_val[card._id]} clearExpenseUpdate={clearExpenseUpdate} saveExpenseUpdate={saveExpenseUpdate} handleArrayUpdate={handleArrayUpdate} handleArrayFormattedChange={handleArrayFormattedChange} currency_symbol={currency_symbol} expense_pin={expense_pin} handlePinnedArray2Change={handlePinnedArray2Change} moveCard={moveCard} />);
        };
        return (<>
            {seperate_expenses ?
                <div style={style}>{cards.map((card, i) => { if (card.show_id === show_select) { return (renderCard(card, i)) } })}</div> :
                <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>}
        </>);
    }
};
