import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { addShortUrl, updateShortUrl, getShortUrl, deleteShortUrl, offUrlData, getMarketing } from '../../actions_client/marketingActions';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Pie, Doughnut, Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { FaRedo, FaTimesCircle } from "react-icons/fa";
import { confirmAlert } from 'react-confirm-alert'
import moment from 'moment';

class Marketing extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            date1Toggle: false,
            date2Toggle: false,
            date3Toggle: false,
            date4Toggle: false,
            date5Toggle: false,
            date6Toggle: false,
            depositadd: 1,
            ticketlink: '',
            gen_notes: '',
            slug: '',
            description: '',
            slugcheckbox: false,
            descriptioncheckbox: false,
            marketing_data: [],
            link_data: [],
            clicks_loading: false,
            url_hover: '',
            links: [],
            loading: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loading: true
        })
        const id = this.props.id;
        const marketing_data = await this.props.getMarketing(id);

        if (marketing_data && marketing_data.short_url.length > 0) {
            console.log(marketing_data)
            this.setState({
                link_data: marketing_data.short_url,
                clicks_loading: true
            })
            var ids = [];
            marketing_data.short_url.map((item, index) => {
                item.url_data.map((d, i) => {
                    ids.push({ id: d._id });
                })
            })
            const urlid = {
                urlid: ids
            }
            const log_status = await this.props.getShortUrl(urlid);
            if (log_status.includes('Error:')) {
                alert(log_status);
            } else {
                console.log(log_status)
                this.setState({
                    marketing_data: log_status,
                    clicks_loading: false
                })
            }
        }
        const socket = this.props.auth.socket;
        if (socket) {
            socket.on(`new_marketing/${id}`, (marketing) => {
                console.log(marketing)
                this.setState({
                    link_data: marketing.short_url
                })
            })
            socket.on(`updated_marketing/${id}`, (marketing) => {
                console.log(marketing)
                this.setState({
                    link_data: marketing.short_url
                })
            })
        };

        // var links = [];
        // this.props.links.map((item, index) => {
        //     console.log(item)
        //     if (item.announce.url) {
        //         const nL = {
        //             type: 'Announce',
        //             url: item.announce.url
        //         }
        //         links.push(nL)
        //     }
        //     if (item.presale.url) {
        //         const nL = {
        //             type: 'Presale',
        //             url: item.presale.url
        //         }
        //         links.push(nL)
        //     }
        //     if (item.on_sale.url) {
        //         const nL = {
        //             type: 'On Sale',
        //             url: item.on_sale.url
        //         }
        //         links.push(nL)
        //     }
        // })
        this.setLinks();

        setTimeout(
            () => this.setState({ loading: false }),
            200
        );
    }

    componentWillUnmount() {
        this.props.offUrlData();
    }

    static propTypes = {
        auth: PropTypes.bool,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        marketing: PropTypes.object.isRequired,
        urldata: PropTypes.object.isRequired,
    }


    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSlugCheckbox = () => {
        this.setState({
            slugcheckbox: !this.state.slugcheckbox
        })
    }

    toggleDateSelect = async (e) => {
        await this.props.toggleDateSelect(e);
        this.setLinks();
    }

    setLinks = () => {
        var index = this.props.links.findIndex(obj => obj._id === this.props.show_select);
        var links = [];
        if (index >= 0) {
            let show_links = this.props.links[index];
            if (show_links.announce.url) {
                const nL = {
                    type: 'Announce',
                    url: show_links.announce.url
                }
                links.push(nL)
            }
            if (show_links.presale.url) {
                const nL = {
                    type: 'Presale',
                    url: show_links.presale.url
                }
                links.push(nL)
            }
            if (show_links.on_sale.url) {
                const nL = {
                    type: 'On Sale',
                    url: show_links.on_sale.url
                }
                links.push(nL)
            }
        }
        this.setState({
            links: links
        })
    }

    handleDescriptionCheckbox = () => {
        this.setState({
            descriptioncheckbox: !this.state.descriptioncheckbox
        })
    }

    generateShortUrl = async () => {

        if (this.state.ticketlink.substring(0, 4) === 'http') {
            if (this.state.link_data.length < 1) {
                const url = {
                    url: this.state.ticketlink.split(" ")[0],
                    slug: this.state.slug,
                    id: this.props.id,
                    description: this.state.description,
                    show_id: this.props.show_select,
                    notes: this.state.gen_notes,
                };
                //console.log(url, "add")
                const log_status = await this.props.addShortUrl(url);
                if (log_status.includes('Error:')) {
                    alert(log_status);
                } else {
                    console.log(log_status)
                }
            } else {
                const show_index = this.state.link_data.findIndex(obj => obj._id === this.props.show_select);
                var marketing_data = this.state.link_data[show_index];

                const url = {
                    url: this.state.ticketlink.split(" ")[0],
                    slug: this.state.slug,
                    id: this.props.id,
                    description: this.state.description,
                    show_id: this.props.show_select,
                    notes: this.state.gen_notes,
                    marketing_data: marketing_data
                };

                //console.log(url, "update")
                var id = this.props.id;
                //const items = []
                //items.push(url, ...this.state.shorturl)
                //console.log(items)
                const log_status = await this.props.updateShortUrl(url, id);
                if (log_status.includes('Error:')) {
                    alert(log_status);
                } else {
                    console.log(log_status)
                }
            }
            this.setState({
                ticketlink: this.props.ticketlink,
                slug: '',
                description: '',
                slugcheckbox: false,
                descriptioncheckbox: false,
            })
        } else {
            alert("Invalid link: link must start with http:// or https://.  Plesae navigate to the 'Config' screen and update it.");
        };
    }

    refreshClicks = async () => {
        this.setState({
            clicks_loading: true
        })
        //this.props.offUrlData();
        var ids = [];
        if (this.state.link_data) {
            this.state.link_data.map((item, index) => {
                item.url_data.forEach(el => {
                    ids.push({ id: el._id });
                })
            })
        }
        const urlid = {
            urlid: ids
        }
        //this.props.getShortUrl(urlid);

        const log_status = await this.props.getShortUrl(urlid);
        if (log_status.includes('Error:')) {
            alert(log_status);
        } else {
            console.log(log_status)
            this.setState({
                marketing_data: log_status,
                clicks_loading: false
            })
        }
    }

    deleteShortUrl = (item) => {
        var id = this.props.id;
        const short_url_id = {
            short_url_id: item
        };
        this.props.deleteShortUrl(short_url_id, id)
    }

    handleClickNo = () => {
        alert('you clicked no')
    }

    deleteDialog = (item) => {
        const addDialog = ({ onClose }) => {
            return (
                <div class="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Delete Url</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Are you sure you'd like to delete the following trackable url and all of it's data?
                            <div>
                                {item.url}
                            </div>
                        </div>
                        <div style={{ marginTop: 10 }} class="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.deleteShortUrl(item._id); onClose(); }}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={onClose}>No</button>
                        </div>
                    </div>
                </div>
            )
        }

        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }

        confirmAlert(options)
    }

    toggleHover = (index) => {
        this.setState({
            url_hover: index
        })
    }

    toggleHoverOff = () => {
        this.setState({
            url_hover: ''
        })
    }

    render() {
        //< div style = {{ marginTop: 8, minHeight: 50, maxHeight: 'auto', width: '80%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd', marginBottom: 15 }}>
        //</div >

        const show_index = this.state.link_data.findIndex(obj => obj._id === this.props.show_select);
        var marketing_data = this.state.link_data[show_index];

        var labels = [];
        var clicks = [];

        if (marketing_data) {
            console.log(this.state.link_data)
            marketing_data.url_data.map((item, index) => {
                labels.push((index + 1) + " " + item.description);
                var ids = item._id;
                this.state.marketing_data.map((c, j) => {
                    if (ids === c.id) {
                        clicks.push(c.clicks)
                    }
                })
            })
        }

        const doughnutTotalSales = {
            labels: labels,
            datasets: [
                {
                    backgroundColor: [
                        '#B21F00',
                        '#C9DE00',
                        '#2FDE00',
                        '#00A6B4',
                        '#6800B4'
                    ],
                    hoverBackgroundColor: [
                        '#501800',
                        '#4B5000',
                        '#175000',
                        '#003350',
                        '#35014F'
                    ],
                    data: clicks
                }
            ]
        }

        const option = {
            animation: {
                duration: 0
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                        var total = meta.total;
                        var currentValue = dataset.data[tooltipItem.index];
                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                        return currentValue + ' clicks (' + percentage + '%)';
                    },
                    title: function (tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    }
                }
            },
            legend: {
                display: true,
                position: 'right'
            }
        }

        return (
            <div className="enterdealMain">
                {this.state.loading ?
                    <div style={{ marginLeft: 100, marginTop: 30 }}>
                        <h2 style={{ fontSize: 14, color: '#000' }}>Loading...</h2>
                    </div> :
                    <div style={{ width: '100%', height: '50%' }}>
                        <div style={{ marginLeft: 50, marginTop: 15, width: '60%' }} className="form-group">
                            <label style={{ fontSize: 14, width: '25%', color: '#000' }}>Select Show:</label>
                            <select style={{ width: 200, fontSize: 12, color: '#000', backgroundColor: '#fff', borderRadius: 1 }} className="form-control-sm" name="show_select" value={this.props.show_select} onChange={(e) => this.toggleDateSelect(e.target.value)}>
                                {this.props.show_info_conf.map((item, index) => {

                                    var firstItem = '';
                                    var lastItem = '';

                                    if (item.show_times[0]) {
                                        var ft = item.show_times[0].time;

                                        var FI = +ft.substr(0, 2);
                                        var f = (FI % 12) || 12;
                                        f = (f < 10) ? ("0" + f) : f;  // leading 0 at the left for 1 digit hours
                                        var ampmF = FI < 12 ? " AM" : " PM";
                                        firstItem = f + ft.substr(2, 3) + ampmF;

                                        var lt = item.show_times[item.show_times.length - 1].time;
                                        var LI = +lt.substr(0, 2);
                                        var l = (LI % 12) || 12;
                                        l = (l < 10) ? ("0" + l) : l;  // leading 0 at the left for 1 digit hours
                                        var ampmL = LI < 12 ? " AM" : " PM";
                                        lastItem = l + lt.substr(2, 3) + ampmL;
                                    }

                                    if (firstItem === "") {
                                        firstItem = "TBA"
                                    }

                                    var date = moment(item.date).format('M/DD/YYYY');
                                    return (
                                        <option value={item._id}>{date} ({firstItem})</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div style={{ marginLeft: 50, height: 'auto', width: '90%', marginTop: 20, backgroundColor: 'rgb(1, 22, 48)' }} className="form-control">
                            <div style={{ width: '100%', marginTop: 10 }}>
                                <h1 style={{ color: '#cdcdcd', fontSize: 14, fontWeight: 'bold' }}>Trackable URL Generator:</h1>
                            </div>
                            <div style={{ width: '100%', display: 'flex', marginBottom: 20 }}>
                                <div style={{ width: '50%' }}>
                                    <div style={{ marginTop: 15, display: 'flex', width: '100%' }} className="form-group">
                                        <label style={{ fontSize: 14, width: '25%', color: '#cdcdcd' }}>Select URL:</label>
                                        <select style={{ width: '70%', fontSize: 12, border: '1px solid #000', borderRadius: 1 }} className="form-control-sm" value={this.state.ticketlink} name="ticketlink" onChange={this.handleChange}>
                                            <option value={""}> </option>
                                            {this.state.links.map((item, index) => {
                                                return (
                                                    <option value={item.url + " " + item.type}>({item.type}) {item.url}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div style={{ marginTop: 15, display: 'flex', width: '100%' }} className="form-group">
                                        <label style={{ fontSize: 14, width: '25%', color: '#cdcdcd' }}>Notes:</label>
                                        <textarea style={{ minWidth: '70%', maxWidth: '70%', fontSize: 12, border: '1px solid #000', borderRadius: 1, minHeight: 60, maxHeight: 60 }} className="form-control-sm" value={this.state.gen_notes} name="gen_notes" onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} fontSize="small" />}
                                            color="primary"
                                            checked={this.state.slugcheckbox}
                                            onChange={this.handleSlugCheckbox}
                                            name="offerid"
                                        />
                                        <p style={{ marginLeft: 5, fontSize: 10, marginTop: 15, color: '#cdcdcd' }}>Custom URL Slug</p>
                                        {this.state.slugcheckbox ?
                                            <input style={{ width: 80, fontSize: 12, marginLeft: 15 }} type="text" disabled={true} value="alien.link/" /> : null}
                                        {this.state.slugcheckbox ?
                                            <input style={{ width: 120, fontSize: 12 }} type="text" value={this.state.slug} name="slug" onChange={this.handleChange.bind(this)} /> : null}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -10 }}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} fontSize="small" />}
                                            color="primary"
                                            checked={this.state.descriptioncheckbox}
                                            onChange={this.handleDescriptionCheckbox}
                                            name="offerid"
                                        />
                                        <p style={{ marginLeft: 5, fontSize: 10, marginTop: 15, color: '#cdcdcd' }}>Campaign / Description</p>
                                        {this.state.descriptioncheckbox ?
                                            <input style={{ width: 200, fontSize: 12, marginLeft: 15 }} type="text" name="description" value={this.state.description} onChange={this.handleChange.bind(this)} /> : null}
                                    </div>
                                    <div style={{ marginTop: 5, display: 'flex', width: '100%' }} className="form-group">
                                        <button style={{ width: 'auto', backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)', fontSize: 12, marginLeft: 5, borderRadius: 10 }} onClick={this.generateShortUrl}>GENERATE</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            {this.state.link_data.length > 0 ?
                                <div style={{ marginLeft: 50, width: '45%', height: 'auto', marginTop: 15, backgroundColor: 'rgb(1, 22, 48)', minHeight: 250 }} className="form-control">
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <h1 style={{ marginTop: 15, marginLeft: 5, color: '#cdcdcd', fontSize: 14 }}>Trackable URLs:</h1>
                                    </div>
                                    <table style={{ width: '100%' }} id="shorturl">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}> </th>
                                                <th style={{ width: '30%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>URL</th>
                                                <th style={{ width: '45%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>Campaign / Description</th>
                                                <th style={{ width: '20%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}><div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ width: '80%' }}>Clicks</div><div style={{ width: '20%' }}><FaRedo style={{ cursor: 'pointer' }} size="10" color="#cdcdcd" onClick={this.refreshClicks} /></div></div></th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div style={{ height: 180, backgroundColor: '#b3b9c1', width: '100%', overflowY: 'scroll', marginBottom: 15 }}>
                                        <table style={{ width: '100%' }} id="shorturl">
                                            {this.state.link_data ?
                                                this.state.link_data.map((item, index) => {
                                                    if (item._id === this.props.show_select) {
                                                        var render = [];
                                                        item.url_data.map((url, i) => {
                                                            render.push(
                                                                <tbody key={i}>
                                                                    <tr>
                                                                        <td style={{ width: '5%', color: '#000', fontSize: 12, padding: 5 }}>{i + 1}</td>
                                                                        {this.state.url_hover === i ?
                                                                            <td style={{ width: '30%', color: '#000', fontSize: 12, padding: 5 }} onMouseEnter={() => this.toggleHover(i)} onMouseLeave={() => this.toggleHoverOff(index)}><div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}><div style={{ width: '85%' }} onClick={() => openLink(url.url)}>{url.url}</div><div style={{ width: '15%' }}><FaTimesCircle style={{ cursor: 'pointer' }} size="15" color='#cdcdcd' onClick={() => this.deleteDialog(url)} /></div></div></td> :
                                                                            <td style={{ width: '30%', color: '#000', fontSize: 12, padding: 5 }} onMouseEnter={() => this.toggleHover(i)} onMouseLeave={() => this.toggleHoverOff(index)}>{url.url}</td>}
                                                                        <td style={{ width: '45%', color: '#000', fontSize: 12, padding: 5 }}>{url.description}</td>
                                                                        {this.state.clicks_loading ?
                                                                            <td style={{ width: '20%', color: '#000', fontSize: 12, padding: 5, backgroundColor: '#fff' }}>Loading..</td> :
                                                                            this.state.marketing_data[0] && !this.state.clicks_loading ?
                                                                                this.state.marketing_data.map((data, j) => {
                                                                                    if (url._id === data.id) {
                                                                                        return (
                                                                                            <td style={{ width: '60%', color: '#000', fontSize: 12, padding: 5, backgroundColor: '#fff' }}>{data.clicks}</td>
                                                                                        )
                                                                                    }
                                                                                }) :
                                                                                <td style={{ width: '20%', color: '#000', fontSize: 12, padding: 5, backgroundColor: '#fff' }}>-</td>}
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        })
                                                    }
                                                    return (
                                                        render
                                                    )
                                                }) : null}
                                        </table>
                                    </div>
                                </div> : null}
                            <div style={{ width: '45%', height: 'auto' }}>
                                <div style={{ position: 'relative', width: '100%', display: 'flex', marginTop: 40, paddingLeft: 50 }}>
                                    {!this.props.urldata.loading ?
                                        < Pie
                                            data={doughnutTotalSales}
                                            options={option}
                                        /> : null}
                                </div>
                            </div>
                        </div>
                    </div>}
                <div style={{ width: '100%', height: '50%' }}>
                </div>
            </div>
        );
    }
}

function openLink(url) {
    return (
        window.open('http://' + url, '_blank')
    )
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    auth: state.auth,
    marketing: state.marketing,
    urldata: state.urldata,
});

export default connect(mapStateToProps, { addShortUrl, updateShortUrl, getShortUrl, deleteShortUrl, offUrlData, getMarketing })(Marketing);