import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PhoneInput from 'react-phone-input-2';
import countryDialCode from '../global/countries';
import getBadWords from '../../helpers/curse-words';

export default function UsernameDialog(props) {
    const [username, setUsername] = useState('');

    const register = () => {
        if (!username) {
            alert('Please enter a username to continue.')
        } else {
            if (username.length < 4) {
                alert('Username must be at least 4 characters.')
            } else {
                const bw = getBadWords(username);
                if (bw.length > 0) {
                    alert('Error: You have an unpermitted word in your requested username.')
                } else {
                    const data = {
                        username: username,
                    }
                    props.register(data)
                }
            }
        }
    }

    const handleUsernameChange = (text) => {
        var format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        const t = format.test(text)
        if (t) {
            alert('Cannot use special characters')
        } else {
            setUsername(text)
        }
    }

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            // onClose={props.onClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Register</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: 500, marginBottom: 30, marginTop: 10 }}>
                    <div style={{ width: '100%', height: 'auto', marginTop: 5 }}>
                        <span style={{ width: '100%', height: 'auto', display: 'flex' }}>
                            <p style={{ fontSize: 12 }}>We have added usernames to the Solar System!  You'll need to create before you can continue.  Please enter a valid username and click save.</p>
                        </span>
                    </div>
                    <div style={{ width: '100%', height: 'auto', marginTop: 5, display: 'flex', flexDirection: 'column' }}>
                        <label style={{ fontSize: 14 }}>Username:</label>
                        <span style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <input style={{ width: '100%', height: 30, fontSize: 12 }} type="text" name="username" value={username} placeholder="Username" onChange={(e) => handleUsernameChange(e.target.value)} />
                        </span>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)', marginLeft: 10 }} onClick={register}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}