import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    VENUES_INIT_LOADING,
    VENUES_LOADING,
    VENUES_GET,
    VENUES_FILTER,
    VENUES_SORT,
    VENUES_SOCKET,
    VENUES_SOCKET_DELETE,
    NOTIFICATIONS
} from "./types";

export const setVenuesLoading = () => {
    return {
      type: VENUES_LOADING
    };
};

export const setVenuesInitLoading = () => {
    return {
      type: VENUES_INIT_LOADING
    };
};

// async function transpose(array, getState) {
//     const tag = getState().auth.user.tag;
//     const user = {
//         user: tag,
//         date: new Date(),
//         note: ''
//     }
//     array.map(async (item, index) => {
//         const newData = {
//             venue_name: item.venue_name,
//             address: item.address,
//             capacity: item.capacity,
//             contact: item.contact,
//             coords: item.coords,
//             website: item.website,
//             user: user
//         }
//         const data = {
//             data: newData
//         }
//         await axios.post(`${process.env.REACT_APP_API}/global/venues/add`, data, tokenConfig(getState))
//             .then(res => )
//             .catch(err => )
//     })
// }

export const getVenues = () => async (dispatch, getState) => {
    dispatch(setVenuesInitLoading());
    await axios.get(`${process.env.REACT_APP_API}/venues/`, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: VENUES_GET,
            payload: res.data
        })
    })
    .catch(err =>
        dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const applyVenueFilter = (venue_filter) => async (dispatch, getState) => {
    dispatch(setVenuesLoading());
    dispatch({
        type: VENUES_FILTER,
        payload: venue_filter
    })
};

export const venueSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: VENUES_SORT,
        payload: sort
    })
};

export const addVenue = venue => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/venues/add`, venue, tokenConfig(getState))
    .then(res => { 
        dispatch({
           type: NOTIFICATIONS,
           payload: res.data
        })
        return res.data
    })
    .catch(err => {
        //dispatch(returnErrors(err.response.data, err.response.status))
        return err.response.data
    });
}

export const updateVenue = (venue, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/venues/update/${id}`, venue, tokenConfig(getState))
    .then(res => {
        dispatch({
           type: NOTIFICATIONS,
           payload: res.data
        })
        return res.data
    })
    .catch(err => {
        //dispatch(returnErrors(err.response.data, err.response.status))
        return err.response.data
    });
}

export const deleteVenue = id => (dispatch, getState) => {
    return axios.delete(`${process.env.REACT_APP_API}/venues/${id}`, tokenConfig(getState))
    .then(res => {
        return res.data
    })
    .catch(err => {
        //dispatch(returnErrors(err.response.data, err.response.status))
        return err.response.data
    });
}

// export const addVenueSocket = venue => (dispatch) => {
//     dispatch({
//         type: SADD_VENUE,
//         payload: venue
//     })
// }

// export const updateVenueSocket = venue => (dispatch) => {
//     dispatch({
//         type: UPDATE_VENUE_SOCKET,
//         payload: venue
//     })
// }

export const venueSocket = venue => (dispatch) => {
    dispatch({
        type: VENUES_SOCKET,
        payload: venue
    })
}

export const deleteVenueSocket = venue => (dispatch) => {
    dispatch({
        type: VENUES_SOCKET_DELETE,
        payload: venue
    })
}

export const venueDupCheck = (dupCheck) => async (dispatch, getState) => {
    const venues = getState().items.items;
    var check = [];
    venues.map(item => {
        if(item.venue_name === dupCheck){
            check = [...check, item]
        }
    })
    return check
}