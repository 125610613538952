import React, { useState, useEffect } from "react";
import CountrySelect from '../countrySelect';
import moment from 'moment';

export default function CountsDbFilter(props) {
    const [stateFilter, setFilter] = useState(props.counts_filter);

    useEffect(() => {
        setFilter(props.counts_filter);
    }, [props.counts_filter]) 

    const filterChange = (event) => {
        var name = event.target.name;
        var val = event.target.value;
        let filter = Object.assign({}, stateFilter);
        filter[name] = val;
        setFilter(filter)
    }

    const filterDateChange = (n, v) => {
        var name = v;
        var val = n;
        console.log(name, val)
        let filter = Object.assign({}, stateFilter);
        filter[name] = new Date(val);
        console.log(filter)
        setFilter(filter)
    }

    const applyFilter = () => {
        props.applyCountsFilter(stateFilter)
    }

    const clearFilter = () => {
        const edate = moment().add(1, 'years').format('MM/DD/YYYY');
        const sdate = moment().format('MM/DD/YYYY');
        var filter = {
            client: '',
            agent: '',
            start_date: new Date(sdate),
            end_date: new Date(edate),
            venue: '',
            city: '',
            state: '',
            country: ''
        };
        setFilter(filter);
    }

    return (
        <div style={{ paddingBottom: 26, backgroundColor: '#111', border: '2px solid #111' }} className="navbar-nav">
            <div style={{ display: 'flex', marginTop: 70, marginLeft: 50 }} >
                <div style={{ width: 200, marginTop: -30 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Artist:</p>
                </div>
                {/* <div style={{ width: 150, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Start Date:</p>
                </div> */}
                <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Venue:</p>
                </div>
                <div style={{ width: 150, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>State | Province:</p>
                </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 50, marginTop: -10 }} className="form-group">
                <div style={{ width: 200 }}>
                    <select style={{ width: 200, fontSize: 12, height: 28 }} className="form-control-sm" value={stateFilter.client} name="client" onChange={(e) => props.filterChange(e, 'counts_filter')}>
                        <option value=""> </option>
                        {props.clients.map((item) => {
                            return (
                                <option key={item._id} value={item._id}>{item.client}</option>
                            )
                        })}
                    </select>
                </div>
                {/* <div style={{ width: 150, marginLeft: 40, height: 28 }}>
                    <DatePicker
                        className="react-datepicker"
                        style={{ fontSize: 12 }}
                        value={stateFilter.start_date}
                        selected={stateFilter.start_date}
                        onChange={(v) => filterDateChange(v, 'start_date')}
                        todayButton="Today"
                        showMonthDropdown
                        showYearDropdown
                    />
                </div> */}
                <div style={{ width: 200, marginLeft: 40 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5 }}
                        value={stateFilter.venue}
                        name="venue"
                        type="text"
                        onChange={(e) => props.filterChange(e, 'counts_filter')}
                    />
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5 }}
                        type="text"
                        name="state"
                        value={stateFilter.state}
                        onChange={(e) => props.filterChange(e, 'counts_filter')}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 5, marginLeft: 50 }}>
                <div style={{ width: 200 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Agent:</p>
                </div>
                {/* <div style={{ width: 150, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>End Date:</p>
                </div> */}
                <div style={{ width: 200, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>City:</p>
                </div>
                <div style={{ width: 150, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Country:</p>
                </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 50, marginTop: -10 }} className="form-group">
                <div style={{ width: 200, height: 28 }}>
                    <select style={{ width: 200, height: 26, fontSize: 12, padding: 0 }} className="form-control" value={stateFilter.agent} type="agent" name="agent" onChange={(e) => props.filterChange(e, 'counts_filter')}>
                        <option value=""> </option>
                        <option value="Christian Morales">Christian Morales</option>
                        <option value="Joey Massa">Joey Massa</option>
                        <option value="Jorge Avila">Jorge Avila</option>
                        <option value="Phil Pirrone">Phil Pirrone</option>
                    </select>
                </div>
                {/* <div style={{ width: 150, marginLeft: 40, marginTop: -2, height: 28 }}>
                    <DatePicker
                        className="react-datepicker"
                        style={{ fontSize: 12 }}
                        value={stateFilter.end_date}
                        selected={stateFilter.end_date}
                        onChange={(v) => filterDateChange(v, 'end_date')}
                        todayButton="Today"
                        showMonthDropdown
                        showYearDropdown
                    />
                </div> */}
                <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 200, height: 26, fontSize: 12 }}
                        value={stateFilter.city}
                        name="city"
                        type="text"
                        onChange={(e) => props.filterChange(e, 'counts_filter')}
                    />
                </div>
                <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                    <CountrySelect country={stateFilter.country} filterChange={(e) => props.filterChange(e, 'counts_filter')} />
                </div>
            </div>
        </div>
    );
}