import {
    MARKETING_LOADING,
    GET_MARKETING,
    GET_SHORT_URL,
    DELETE_SHORT_URL,
    ADD_SHORT_URL,
} from "../actions/types";

const initialState = {
    items: {
        _id: null,
        ticketlink: null,
        shorturl: []
    },
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MARKETING_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_MARKETING:
            if (action.payload !== null) {
                return {
                    ...state,
                    items: action.payload,
                    loading: false
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    items: [{ shorturl: [{}] }]
                }
            };
        case GET_SHORT_URL:
            return {
                ...state,
                items: action.payload,
                loading: false
            };
        case ADD_SHORT_URL:
            return {
                ...state,
                items: action.payload,
                loading: false
            };
        case DELETE_SHORT_URL:
            return {
                ...state,
                items: state.items.filter(v => v._id !== action.payload),
                loading: false
            };
        default:
            return state;
    }
}