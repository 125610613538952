import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserProfile from '../../assets/user.png';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import { FaEnvelopeSquare, FaPhoneSquare } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function ContactsAccordion(props) {
    const classes = useStyles();

    console.log(props.contactarray)
    return (
        <div style={{ width: '95%', marginTop: 15 }} className={classes.root}>
            {props.contactarray.map((item, index) => {
                console.log(item)

                return (
                    <Accordion key={index} style={{ backgroundColor: '#F5F5F5', marginTop: 5 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={classes.heading}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 5, height: 'auto', alignItems: 'center' }}>
                                    <img style={{marginLeft: 20}} width="35" height="35" src={UserProfile} />
                                    <h2 style={{ fontSize: 12, color: '#000', marginLeft: 20 }}>{item.name.last}, </h2>
                                    <h2 style={{ fontSize: 12, color: '#000', marginLeft: 5 }}>{item.name.first}</h2>
                                    <h2 style={{ fontSize: 12, color: '#000', marginLeft: 40 }}>({item.role})</h2>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ width: '100%' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 6 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '33.3%', marginLeft: 40 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <h2 style={{ fontSize: 12, color: '#000', fontWeight: 'bold' }}>Email:</h2>
                                            <FaEnvelopeSquare style={{ marginLeft: 10, cursor: 'pointer' }} size="15" color='#000' />
                                        </div>
                                        <h2 style={{ fontSize: 12, color: '#000' }}>{item.contact.email}</h2>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '33.3%', marginLeft: 20 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <h2 style={{ fontSize: 12, color: '#000', fontWeight: 'bold' }}>Office Phone:</h2>
                                            <FaPhoneSquare style={{ marginLeft: 10, cursor: 'pointer' }} size="15" color='#000' />
                                        </div>
                                        <h2 style={{ fontSize: 12, color: '#000' }}><NumberFormat
                                            displayType={'text'}
                                            style={{ width: '100%', fontSize: 10, marginLeft: -10 }}
                                            className="form-control-sm"
                                            value={item.contact.office.dial}
                                            format="+# (###) ###-####" /></h2>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '33.3%', marginLeft: 20 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <h2 style={{ fontSize: 12, color: '#000', fontWeight: 'bold' }}>Cell Phone:</h2>
                                            <FaPhoneSquare style={{ marginLeft: 10, cursor: 'pointer' }} size="15" color='#000' />
                                        </div>
                                        <h2 style={{ fontSize: 12, color: '#000' }}><NumberFormat
                                            displayType={'text'}
                                            style={{ width: '100%', fontSize: 10, marginLeft: -10 }}
                                            className="form-control-sm"
                                            value={item.contact.cell.dial}
                                            format="+# (###) ###-####" /></h2>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginLeft: 40, marginTop: 15 }}>
                                    <h2 style={{ fontSize: 12, color: '#000', fontWeight: 'bold' }}>Notes:</h2>
                                    <h2 style={{ fontSize: 12, color: '#000' }}>{item.notes}</h2>
                                </div>
                                <div style={{marginTop: 15, width: '100%', marginLeft: 40}}>
                                    <button style={{width: 80, backgroundColor: 'rgb(1, 22, 48)', height: 24, borderRadius: 5, fontSize: 10, color: '#cdcdcd'}} type="button" onClick={() => props.removeTag(index)}>Remove Tag</button>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}