import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import NumberFormat from 'react-number-format';
import { FaTimesCircle, FaEdit, FaCheckSquare, FaTrash, FaGripLinesVertical } from 'react-icons/fa';

const style = {
    border: '1px dashed gray',
    backgroundColor: '#f1f1f1',
    width: '100%'
};
const style2 = {
    border: '1px dashed gray',
    backgroundColor: '#f1f1f1',
    width: '100%'
};

export const Card = ({ index, id, show_index, expense, type, amount, cap, cappedamount, notes, setExpense, deleteExpense, edit, gross_tickets, gross_pot_val, expense_edit, expense_val, clearExpenseUpdate, saveExpenseUpdate, handleArrayUpdate, handleArrayFormattedChange, currency_symbol, expense_pin, handlePinnedArray2Change, moveCard }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, index, id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    var exp_gross_single;
    if (type === "Flat") {
        exp_gross_single = amount;
    } else if (type === "Per Ticket") {
        exp_gross_single = gross_tickets * amount;
        if (cap === true || cap === "true") {
            if (exp_gross_single > cappedamount) {
                exp_gross_single = cappedamount
            }
        }
    } else if (type === "Percent") {
        exp_gross_single = gross_pot_val * (amount / 100);
        if (cap === true || cap === "true") {
            if (exp_gross_single > cappedamount) {
                exp_gross_single = cappedamount
            }
        }
    };

    return (<div style={edit ? { ...style, opacity } : { ...style2 }} ref={ref}>
        <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td style={{ width: '3%', fontSize: 13, cursor: 'move' }}><FaGripLinesVertical size="18" color='#cdcdcd' /></td>
                    {expense_edit === 'expense' ?
                        <td style={{ width: '15%' }}><input style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} type="text" name="expense" value={expense} onChange={!expense_pin ? (event) => handleArrayUpdate(event, id, 'expensearray') : (event) => handlePinnedArray2Change(event, 'expensearray', show_index)} /></td> :
                        <td style={{ width: '15%' }} onClick={() => setExpense(id, 'expense')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{expense}</p></td>}
                    {expense_edit === 'type' ?
                        <td style={{ width: '11%' }}>
                            <select style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} type="text" name="type" value={type} onChange={!expense_pin ? (event) => handleArrayUpdate(event, id, 'expensearray') : (event) => handlePinnedArray2Change(event, 'expensearray', show_index)}>
                                <option value="Flat">Flat</option>
                                <option value="Percent">Percent</option>
                                <option value="Per Ticket">Per Ticket</option>
                            </select>
                        </td> :
                        <td style={{ width: '11%' }} onClick={() => setExpense(id, 'type')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{type}</p></td>}
                    {expense_edit === 'amount' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} value={amount} thousandSeparator={true} prefix={type === 'Flat' || type === 'Per Ticket' ? currency_symbol : ''} suffix={type === 'Percent' ? '%' : ''} onValueChange={(values) => handleArrayFormattedChange(values, id, 'amount', 'expensearray')} /></td> :
                        <td style={{ width: '11%' }} onClick={() => setExpense(id, 'amount')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={type === 'Flat' || type === 'Per Ticket' ? currency_symbol : null} suffix={type === 'Percent' ? '%' : null} /></p></td>}
                    {expense_edit === 'cap' ?
                        <td style={{ width: '8%' }}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 50, backgroundColor: '#fff', border: '1px solid #000', borderRadius: 4 }}><input style={{ fontSize: 12 }}
                            type="checkbox"
                            checked={cap === 'true' ? true : false}
                            name="cap"
                            value={cap === 'true' ? false : true}
                            checked={cap === 'true' ? true : false}
                            onChange={!expense_pin ? (event) => handleArrayUpdate(event, id, 'expensearray') : (event) => handlePinnedArray2Change(event, 'expensearray', show_index)}></input></div></td> :
                        <td style={{ width: '8%' }} onClick={() => setExpense(id, 'cap')}><div style={{ color: '#000', fontSize: 12, width: '100%', display: 'flex', alignItems: 'center' }}><input style={{ marginTop: 3, width: '100%' }}
                            type="checkbox"
                            disabled={true}
                            checked={cap === 'true' || cap === true ? true : false}></input></div></td>}
                    {expense_edit === 'capped_amount' ?
                        <td style={{ width: '11%' }}><NumberFormat style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} value={cappedamount} thousandSeparator={true} prefix={currency_symbol} onValueChange={(values) => handleArrayFormattedChange(values, id, 'capped_amount', 'expensearray')} /></td> :
                        <td style={{ width: '11%' }} onClick={() => setExpense(id, 'capped_amount')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={cappedamount} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td>}
                    <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={exp_gross_single} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td>
                    {expense_edit === 'notes' ?
                        <td style={{ width: '18%' }}><input style={{ color: '#000', fontSize: 12, width: '100%', height: 50 }} type="text" name="notes" value={notes} onChange={!expense_pin ? (event) => handleArrayUpdate(event, id, 'expensearray') : (event) => handlePinnedArray2Change(event, 'expensearray', show_index)} /></td> :
                        <td style={{ width: '18%' }} onClick={() => setExpense(id, 'notes')}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{notes}</p></td>}
                    <td style={{ width: '8%' }}><FaTrash style={{ marginLeft: 10, cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => deleteExpense(id)} /></td>
                </tr>
            </tbody>
        </table>
    </div>);
};
