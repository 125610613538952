import React, { useState, useCallback, useEffect } from 'react';
import { Card } from './Card';
import update from 'immutability-helper';
const style = {
    width: '100%'
};
export const Container = ({ taxesarray, updateTax, deleteTax, edit, gross_tickets, gross_tickets_single, gross_pot_val, gross_pot_val_single, tax_edit, tax_val, clearTaxUpdate, saveTaxUpdate, handleArrayUpdate, handleArrayFormattedChange, currency_symbol, tax_pin, seperate_taxes, show_select, handlePinnedArray2Change, droppedCards }) => {
    {
        const [cards, setCards] = useState(taxesarray);

        useEffect(() => {
            setCards(taxesarray);
        }, [taxesarray])

        const moveCard = useCallback((dragIndex, hoverIndex) => {
            const dragCard = cards;
            // setCards(update(cards, {
            //     $splice: [
            //         [dragIndex, 1],
            //         [hoverIndex, 0, dragCard],
            //     ],
            // }));
            [dragCard[dragIndex], dragCard[hoverIndex]] = [
                dragCard[hoverIndex],
                dragCard[dragIndex]
            ];
            droppedCards([...dragCard], 'taxesarray')
        }, [cards]);
        const renderCard = (card, index) => {
            console.log(card)
            return (<Card index={index} key={card._id} id={card._id} show_index={index} setTax={updateTax} tax={card.tax} type={card.type} amount={card.amount} cap={card.cap} cappedamount={card.capped_amount} notes={card.notes} deleteTax={deleteTax} edit={edit} gross_tickets={gross_tickets} gross_tickets_single={gross_tickets_single} gross_pot_val={gross_pot_val} gross_pot_val_single={gross_pot_val_single} tax_edit={tax_edit[card._id]} tax_val={tax_val[card._id]} clearTaxUpdate={clearTaxUpdate} saveTaxUpdate={saveTaxUpdate} handleArrayUpdate={handleArrayUpdate} handleArrayFormattedChange={handleArrayFormattedChange} currency_symbol={currency_symbol} tax_pin={tax_pin} handlePinnedArray2Change={handlePinnedArray2Change} moveCard={moveCard} />);
        };
        return (<>
            {seperate_taxes ?
                <div style={style}>{cards.map((card, i) => { if (card.show_id === show_select) { return (renderCard(card, i)) } })}</div> :
                <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>}
        </>);
    }
};
