import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveTemplates } from '../actions/companyActions';
import { FaPenSquare, FaTimesCircle, FaSave, FaPlus } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert'
import CreateTemplateDialog from '../modals/templates/create-template.modal';
import _ from 'lodash';

class TemplatesScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            temp_type: 'Contract',
            create_dialog: false,
            templates_array: props.templates ? props.templates : [],
            template_name: '',
            new_template_name: '',
            default_addendum: [],
            init_load: false,
            def: [],
            templates: JSON.stringify({ verbiage: 'HELLO' })
        };
        this.selectTempName = this.selectTempName.bind(this);
        this.handleVerbiageChange = this.handleVerbiageChange.bind(this);
        this.handleSectionChange = this.handleSectionChange.bind(this);
    }

    async componentDidMount() {
        var contract = [];
        var name = "";
        const templates = _.cloneDeep(this.props.auth.user.company.templates);
        console.log(templates, 'templates')
        if (this.state.init_load) {
            templates.map((item, index) => {
                if (item.name === this.state.template_name) {
                    contract = item.addendum;
                }
            })
            this.setState({
                default_addendum: contract,
                templates_array: templates,
                templates: templates
            })
        } else {
            templates.map((item, index) => {
                if (item.cat === 'Contract' && item.default) {
                    contract = item.addendum;
                    name = item.name;
                }
            })
            this.setState({
                default_addendum: contract,
                template_name: name,
                templates_array: templates,
                def: contract,
                init_load: true,
                templates: templates
            })
        }

        const socket = this.props.auth.socket;
        if (socket) {
            socket.on(`company/templates`, (templates) => {
                this.setState({
                    templates: templates
                })
            })
        };
    }

    // componentDidUpdate(prevProps, nextProps) {
    //     if (JSON.stringify(this.props.co_auth.templates) !== nextProps.templates) {
    //         console.log("THIS RAN")
    //     }
    // }

    selectTempName = (e) => {
        const name = e.target.value;
        const checkIndex = this.state.templates.findIndex(item => item.name === this.state.template_name);
        if (checkIndex < 0) {
            this.saveCheckDialog(name);
        } else {
            var contract = [];
            this.state.templates.map((item, index) => {
                if (item.name === name) {
                    contract = item.addendum;
                }
            })
            this.setState({
                template_name: name,
                default_addendum: contract
            })
        }
    }

    toggleEdit = () => {
        this.setState({
            edit: !this.state.edit
        })
    }

    toggleCancel = () => {
        var contract = [];
        const templates = this.state.templates
        templates.map((item, index) => {
            if (item.name === this.state.template_name) {
                contract = item.addendum;
            }
        })
        this.setState({
            default_addendum: contract,
            edit: false
        })
    }

    toggleNewTempDialog = () => {
        this.setState({
            create_dialog: !this.state.create_dialog
        })
    }

    createNewTemplate = (name) => {
        var check = this.state.templates.findIndex(item => item.name === name);
        if (check >= 0) {
            alert('Name already exists')
        } else {
            this.setState({
                default_addendum: [{ section: '', verbiage: '' }],
                templates_array: [...this.state.templates_array, { cat: this.state.temp_type, name: name, default: false, addendum: [] }],
                template_name: name,
                edit: true
            })
        }
    }

    editDialog = () => {
        const textline = '{company}'
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Edit</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <p>Note: use \n to add a line break and {textline} to add your company name</p>
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.toggleEdit(); onClose(); }}>Ok</button>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }
        confirmAlert(options)
    }

    saveDialogDefaultCheck = () => {
        const checkIndex = this.state.templates.findIndex(item => item.name === this.state.template_name);
        if (checkIndex >= 0) {
            console.log(this.state.templates[checkIndex], "HERE")
            if (this.state.templates[checkIndex].default) {
                this.saveDialog()
            } else {
                this.saveTemplate();
            }
        }
    }

    saveDialog = () => {
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Edit</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            By saving, you will be overwriting the default template for your company in the database.  Are you sure you would like to continue?
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={onClose}>Cancel</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { this.saveTemplate(); onClose(); }}>Save</button>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }
        confirmAlert(options)
    }

    saveCheckDialog = (name) => {
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Edit</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            Unsaved template -- would you like to save it?
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.closeTemplate(name); onClose(); }}>No</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { this.saveTemplate(); onClose(); }}>Save</button>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }
        confirmAlert(options)
    }

    saveTemplate = async () => {
        var templates = this.state.templates_array;
        var index = templates.findIndex(item => item.name === this.state.template_name);
        if (index >= 0) {
            templates[index].addendum = this.state.default_addendum;
        }
        const data = {
            templates: templates
        }

        const logstatus = await this.props.saveTemplates(data);
        if (logstatus.type === 'error') {
            alert(logstatus.msg)
        } else {
            this.setState({
                edit: false
            })
        }
    }

    closeTemplate = (name) => {
        var contract = [];
        const templates = this.state.templates;
        templates.map((item, index) => {
            if (item.name === name) {
                contract = item.addendum;
            }
        })
        var templatesArr = this.state.templates_array.filter(item => item.name !== this.state.template_name);
        this.setState({
            templates_array: templatesArr,
            template_name: name,
            default_addendum: contract,
            edit: false
        })
    }

    handleVerbiageChange = (e) => {
        // e.preventDefault();
        const index = e.target.name;
        const val = e.target.value;
        var t = this.state.default_addendum;
        t[index].verbiage = val;
        this.setState({
            default_addendum: t
        })
    }

    handleSectionChange = (e) => {
        e.preventDefault();
        const index = e.target.name;
        const val = e.target.value;
        var temp = this.state.default_addendum;
        temp[index].section = val;
        this.setState({
            default_addendum: temp
        })
    }

    addRow = () => {
        this.setState({
            default_addendum: [...this.state.default_addendum, { section: '', verbiage: '' }]
        })
    }

    removeRow = (i) => {
        const newArray = this.state.default_addendum.filter((item, index) => index !== i);
        this.setState({
            default_addendum: newArray
        })
    }

    render() {

        return (

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}>
                {this.state.create_dialog ?
                    <CreateTemplateDialog close={this.toggleNewTempDialog} save={this.createNewTemplate} /> : null}
                <div style={{ width: '100%', height: 30, backgroundColor: '#111', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <select style={{ width: 220, height: '80%', backgroundColor: '#111', color: '#fff', border: '0px', fontWeight: 'bold', marginLeft: 10, fontSize: 14 }} name="template_select" value={''} readOnly>
                        <option value="">Templates</option>
                        <option value="Email Options">Email Options</option>
                    </select>
                </div>
                <div style={{ height: '85vh', width: '100%', backgroundColor: '#fff', overflowY: 'scroll' }}>
                    {this.props.loading ?
                        <div style={{ paddingLeft: 20, paddingTop: 10 }}>
                            <h1 style={{ fontSize: 14, color: '#000' }}>Loading..</h1>
                        </div> :
                        <div style={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                            <div style={{ width: '100%', height: 'auto', paddingTop: 10, paddingLeft: 10, display: 'flex', flexDirection: 'column', borderBottom: '1px solid #111', borderTop: '1px solid #111', paddingBottom: 10 }}>
                                <span style={{ width: 500, display: 'flex' }}>
                                    <label style={{ fontSize: 12, width: 200 }}>Template Type:</label>
                                    <div style={{ fontSize: 12, width: 300 }}>
                                        <table id="dash-settings-contact-table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <select style={{ width: '100%', height: '100%', height: 28 }} disabled={true} value={this.state.template_type}>
                                                            <option value="Contract">Contract</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                                <span style={{ width: 630, display: 'flex' }}>
                                    <label style={{ fontSize: 12, width: 200 }}>Name:</label>
                                    <div style={{ fontSize: 12, width: 300 }}>
                                        <table id="dash-settings-contact-table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <select style={{ width: '100%', height: '100%', height: 28 }} value={this.state.template_name} onChange={this.selectTempName}>
                                                            {this.state.templates_array.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.name}>{item.name}{item.default ? ' (default)' : ''}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <button style={{ marginLeft: 10, width: 120, height: 28, backgroundColor: '#000', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2, fontSize: 10, color: '#cdcdcd' }} type="button" onClick={this.toggleNewTempDialog}>Create New Template</button>
                                    {/* <button className='custom-toggle-menu-b' type="button" onClick={toggleCancel}>
                                    <FaPlus size={16} color="#000" />
                                </button> */}
                                </span>
                            </div>
                            <div style={{ width: '100%', height: 'auto', display: 'flex' }}>
                                {this.state.edit ?
                                    <button className='custom-toggle-menu-b' type="button" onClick={this.toggleCancel}><FaTimesCircle size={18} color="#000" /></button> : null}
                                {this.state.edit ?
                                    <button className='custom-toggle-menu-b' type="button" onClick={this.saveDialogDefaultCheck}><FaSave size={18} color="#000" /></button> :
                                    <button className='custom-toggle-menu-b' type="button" onClick={this.editDialog}><FaPenSquare size={18} color="#000" /></button>}
                            </div>
                            {/* {stateTempName === '' ? */}
                            <div style={{ width: '100%', height: 'auto', padding: 10, display: 'flex' }}>
                                <div style={{ width: '100%', height: 'auto', paddingTop: 10, paddingLeft: 10, display: 'flex', flexDirection: 'column', overflowX: 'scroll' }}>
                                    {this.state.default_addendum.map((item, index) => {
                                        return (
                                            <div key={index} style={{ width: '100%', display: 'flex' }}>
                                                <span style={{ width: 200, display: 'flex', flexDirection: 'column' }}>
                                                    {!this.state.edit ?
                                                        <label style={{ fontSize: 12, width: 200 }}>{String.fromCharCode(65 + index)}. {item.section}</label> :
                                                        <span style={{ height: 28, width: 200, display: 'flex' }}>
                                                            <p style={{ fontSize: 12, width: 15, paddingTop: 5 }}>{String.fromCharCode(65 + index)}.</p>
                                                            <input style={{ fontSize: 12, width: 185, height: 28 }} name={index} value={item.section} onChange={(e) => this.handleSectionChange(e)} />
                                                        </span>}
                                                    {this.state.edit ?
                                                        <button style={{ width: 60, height: 28, backgroundColor: '#000', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 10, color: '#cdcdcd', marginLeft: 15, marginTop: 10, padding: 2 }} type="button" onClick={() => this.removeRow(index)}>Remove</button> : null}
                                                </span>
                                                <div style={{ fontSize: 12, width: 600 }}>
                                                    <table id="dash-settings-contact-table">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <textarea style={{ width: '100%', height: 200 }}
                                                                        disabled={!this.state.edit}
                                                                        name={index}
                                                                        value={!this.state.edit ? verbiageSetup(item.verbiage, this.props.auth.user.company.company) : item.verbiage}
                                                                        // dangerouslySetInnerHTML={{__html: item.verbiage.replace('\n', '<br />')}}
                                                                        onChange={(e) => this.handleVerbiageChange(e)}
                                                                    ></textarea>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        )
                                    })}
                                    {this.state.edit ?
                                        <button style={{ width: 120, height: 28, backgroundColor: '#000', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2, fontSize: 10, color: '#cdcdcd' }} type="button" onClick={this.addRow}>Add New Row</button> : null}
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        );
    }
}

const verbiageSetup = (string, company) => {
    var renderStr = string.replace(/\\n/g, '\n');
    renderStr = renderStr.replace(/{company}/g, company);
    return renderStr
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { saveTemplates })(TemplatesScreen);