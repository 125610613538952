import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import { FaTimesCircle, FaEdit } from 'react-icons/fa';
import { dbContractIssued, dbContractReceived, dbDepositReceived } from '../../actions/offerActions';
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";

class OfferTracking extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            date1Toggle: false,
            date2Toggle: false,
            date3Toggle: false,
            date4Toggle: false,
            date5Toggle: false,
            date6Toggle: false,
            depositadd: 1,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        tag: PropTypes.string.isRequired,
        contacts: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    updateContractIssued = (props) => {
        this.props.updateContractIssued(props)
    }
    deleteContractIssued = (props) => {
        this.props.deleteContractIssued(props)
    }

    updateContractReceived = (props) => {
        this.props.updateContractReceived(props)
    }
    deleteContractReceived = (props) => {
        this.props.deleteContractReceived(props)
    }

    updateDepositReceived = (props) => {
        this.props.updateDepositReceived(props)
    }
    deleteDepositReceived = (props) => {
        this.props.deleteDepositReceived(props)
    }

    addIssued = () => {
        var issuedArray = {
            date: this.props.contractissued.toString(),
            note: this.props.contractissuednotes,
            user: this.props.tag
        }
        var issued = `${this.props.contractissued.toString()}, ${this.props.tag}`
        var data = {
            issued: issued,
            issuedArray: issuedArray
        }
        this.props.dbContractIssued(data, this.props.id);
        //this.props.addContractIssued();
        this.props.resetTracking('contract_issued');
    }

    addContractReceived = () => {
        var receivedArray = {
            date: this.props.contractreceived.toString(),
            note: this.props.contractreceivednotes,
            user: this.props.tag
        }
        var received = `${this.props.contractreceived.toString()}, ${this.props.tag}`
        var data = {
            received: received,
            receivedArray: receivedArray
        }
        this.props.dbContractReceived(data, this.props.id);
        this.props.resetTracking('contract_received');
    }

    addDepositReceived = () => {
        console.log(this.props.depositreceivedamount)
        var newObj = {
            date: this.props.depositreceiveddate.toString(),
            amount: Number(this.props.depositreceivedamount),
            note: this.props.depositreceivednotes,
            user: this.props.tag
        }
        var receivedArray = [];
        if (this.props.depositsreceivedarray.length > 0) {
            receivedArray = [...this.props.depositsreceivedarray, newObj];
        } else {
            receivedArray = [newObj]
        }
        var received = `${this.props.depositreceiveddate.toString()}, ${this.props.depositreceivedamount}, ${this.props.tag}`
        var data = {
            received: received,
            receivedArray: receivedArray,
            client: this.props.artistid
        }
        console.log(data);
        this.props.dbDepositReceived(data, this.props.id);
        //this.props.addContractReceived();
        this.props.resetTracking('deposit_received');
    }

    render() {

        const dep_rec_total = this.props.depositsreceivedarray.reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const dep_due_total = this.props.depositsarray.reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        return (
            <div className="enterdealMain">
                {/* <div style={{ width: '100%', height: 'auto' }}>
                    <div style={{ display: 'flex' }}>
                        <h1 style={{ marginLeft: 50, color: '#000', fontSize: 14, marginTop: 20 }}>Contract Status:</h1>
                        {this.props.contractreceived === true || this.props.contractreceivedarray.length > 0 ?
                            <div style={{ width: 'auto', height: '100%', marginTop: 15, marginLeft: 10 }}>
                                <Chip
                                    size="small"
                                    //variant="outlined"
                                    color="primary"
                                    icon={<DoneIcon />}
                                    label={'Completed'}
                                />
                            </div> : null}
                    </div>
                    <div style={{ marginLeft: 50, height: 'auto', width: '90%' }} className="form-control">
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ width: '50%' }}>
                                <div style={{ marginTop: 10, display: 'flex', width: '100%' }} className="form-group">
                                    <label style={{ fontSize: 14, width: '25%' }}>Received:</label>
                                    <div style={{ marginLeft: 10, minHeight: 50, maxHeight: 'auto', width: '60%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd', marginBottom: 15 }}>
                                        {this.props.contractreceivedarray.map((item, index) => {
                                            const d = new Date(item.date);
                                            const date = `${(d.getMonth() + 1)}/${d.getDate()}/${d.getFullYear()}`;

                                            var user = "";
                                            this.props.company.comp_users.map((c, i) => {
                                                console.log(c)
                                                if (item.user === c.tag) {
                                                    user = c.name.first + " " + c.name.last;
                                                }
                                            })

                                            return (
                                                <div style={{ border: '1px dashed gray', paddingTop: '1rem', paddingLeft: '0.5rem', backgroundColor: '#f1f1f1', width: '100%' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <p style={{ color: '#000', fontSize: 12, width: '40%' }}>{date}</p>
                                                        <p style={{ color: '#000', fontSize: 12, width: '40%' }}>{item.note}</p>
                                                        {this.props.edit ?
                                                            <div style={{ width: '20%', display: 'flex' }}>
                                                                <FaEdit style={{ cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.updateContractReceived(index)} />
                                                                <FaTimesCircle style={{ marginLeft: 10, cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.deleteContractReceived(index)} />
                                                            </div> : null}
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: -15 }}>
                                                        <p style={{ color: '#000', fontSize: 9 }}>Logged by: {user}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '50%' }}>


                            </div>
                        </div>
                    </div>
                </div> */}
                <div style={{ width: '100%', height: 'auto' }}>
                    <div style={{ display: 'flex' }}>
                        <h1 style={{ marginTop: 20, marginLeft: 50, color: '#000', fontSize: 14 }}>Deposit Status:</h1>
                        {/* {dep_rec_total >= dep_due_total ?
                            <div style={{ width: 'auto', height: '100%', marginTop: 15, marginLeft: 10 }}>
                                <Chip
                                    size="small"
                                    color="primary"
                                    icon={<DoneIcon />}
                                    label={'Completed'}
                                />
                            </div> : null} */}
                    </div>
                    <div style={{ marginLeft: 50, height: 'auto', width: '90%', display: 'flex', flexDirection: 'row' }} className="form-control">
                        <div style={{ width: '42%' }}>
                            <div style={{ marginTop: 10, display: 'flex', width: '100%' }} className="form-group">
                                <label style={{ fontSize: 14, width: '30%', marginTop: 15 }}>Deposits Due:</label>
                                <div style={{ minHeight: 50, maxHeight: 'auto', width: '55%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd', marginBottom: 15 }}>
                                    {this.props.depositsarray.length > 0 ?
                                        this.props.depositsarray.map((item, index) => {
                                            const d = new Date(item.due);
                                            const date = `${(d.getMonth() + 1)}/${d.getDate()}/${d.getFullYear()}`

                                            return (
                                                <div style={{ border: '1px dashed gray', paddingTop: '1rem', paddingLeft: '0.5rem', backgroundColor: '#f1f1f1', width: '100%' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 6 }}>
                                                        <NumberFormat style={{ width: 100, color: '#000', fontSize: 12 }} displayType={'text'} value={item.amount} thousandSeparator={true} prefix={'$'} />
                                                        <p style={{ color: '#000', fontSize: 12, width: 100 }}>{date}</p>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <div style={{ border: '1px dashed gray', paddingTop: '1rem', paddingLeft: '0.5rem', backgroundColor: '#f1f1f1', width: '100%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 6 }}>
                                                <p style={{ color: '#000', fontSize: 12, width: 200 }}>No deposits due</p>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '58%' }}>
                            <div style={{ marginTop: 10, display: 'flex', width: '100%', alignItems: 'center' }} className="form-group">
                                <label style={{ fontSize: 14, width: '25%' }}>Received:</label>
                                <div style={{ minHeight: 50, maxHeight: 'auto', width: '60%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd', marginBottom: 15 }}>
                                    {this.props.depositsreceivedarray.map((item, index) => {
                                        console.log(item)
                                        const d = new Date(item.date);
                                        const date = `${(d.getMonth() + 1)}/${d.getDate()}/${d.getFullYear()}`;

                                        var user = "";
                                        this.props.company.comp_users.map((c, i) => {
                                            console.log(c)
                                            if (item.user === c.tag) {
                                                user = c.name.first + " " + c.name.last;
                                            }
                                        })

                                        return (
                                            <div style={{ border: '1px dashed gray', paddingTop: '1rem', paddingLeft: '0.5rem', backgroundColor: '#f1f1f1', width: '100%' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <NumberFormat style={{ width: 'auto', fontSize: 12, color: '#000' }} displayType={'text'} value={item.amount} thousandSeparator={true} prefix={'$'} />
                                                    <p style={{ color: '#000', fontSize: 12, width: 'auto', marginLeft: 5 }}>received {date}</p>
                                                    <p style={{ color: '#000', fontSize: 12, width: 'auto', marginLeft: 5 }}>{item.note}</p>
                                                    {this.props.edit ?
                                                        <div style={{ width: '15%', display: 'flex' }}>
                                                            <FaEdit style={{ cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.updateDepositReceived(index)} />
                                                            <FaTimesCircle style={{ marginLeft: 10, cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.deleteDepositReceived(index)} />
                                                        </div> : null}
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -15 }}>
                                                    <p style={{ color: '#000', fontSize: 9 }}>Logged by: {user}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', height: 100 }}></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    company: state.company,
    tag: state.auth.user.tag
});

export default connect(mapStateToProps, { dbContractIssued, dbContractReceived, dbDepositReceived })(OfferTracking);