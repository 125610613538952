import React, { useState } from "react";

export default function CreateTemplateDialog(props) {
    const [tempName, setTempName] = useState('');

    const setName = (e) => {
        setTempName(e.target.value);
    }

    return (
        <div style={{ position: 'absolute', width: 400, height: 'auto', top: '40%', left: '40%' }} className="react-confirm-alert-z2">
            <div style={{ width: '100%', height: '100%', padding: 15 }}>
                <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Add New Template</h1>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    What would you like to call your new template?
                    <input style={{ width: 250, height: 28, color: '#000', fontSize: 12 }} type="text" value={tempName} onChange={(e) => setName(e)} />
                </div>
                <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                    <button style={{ borderRadius: 10 }} onClick={props.close}>Cancel</button>
                    <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { props.save(tempName); props.close() }}>Create</button>
                </div>
            </div>
        </div>
    );
}