import React, { PureComponent } from "react";
import { addFestival, updateFestival, festivalDupCheck } from '../actions/festivalActions';
import { connect } from 'react-redux';
import { FaInfoCircle, FaAddressBook, FaSave, FaPlusSquare, FaMinusSquare, FaEdit, FaTimesCircle, FaVoteYea, FaArrowsAltH } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';
import RenderNewContact from '../components/render-new-contact.component';
import RenderContacts from '../components/render-contacts.component';
import LocationAutocomplete from '../components/autocompletes/location-autocomplete';
import CurrencyAutocomplete from '../components/autocompletes/currency-autocomplete-rel';
import Submissions from './modal-components/submissions.modal-component';
import moment from 'moment';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import getSymbolFromCurrency from 'currency-symbol-map';
import { confirmAlert } from 'react-confirm-alert'


class FestivalModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            edit: true,
            addcontact: false,
            checkedItems: new Map(),
            render: 'info',
            header: '',
            status: 'Active',
            festival: '',
            venue: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            postalcode: '',
            capacity: '',
            currency: 'USD',
            budget: '',
            agent: [],
            website: '',
            facebook: '',
            instagram: '',
            twitter: '',
            datesarray: [],
            tags: [],
            render_type: 'enter',
            select_year: new Date(),
            select_array: [],
            proj_dates: [],
            toggle_proj_dates: false,
            submissions: [],
            artist: '', artistid: '', artistbatch: { _id: '', client: '' },
            date_submitted: new Date(),
            submission_note: '',
            date_range: false,
            date: '',
            end_date: '',
            select_year: new Date(),
            edit_index: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.editDate = this.editDate.bind(this);
    }

    componentDidMount() {
        var year = moment().year();
        //var year = date.getFullYear() + 1;

        if (this.props.type === 'render') {
            const festival_obj = this.props.render_festival_data;
            const d = _.cloneDeep(festival_obj.dates);

            this.setState({
                render_type: 'render',
                edit: false,
                id: festival_obj._id,
                header: festival_obj.festival,
                status: festival_obj.status,
                festival: festival_obj.festival,
                datesarray: d,
                venue: festival_obj.venue,
                address: festival_obj.address.address,
                address2: festival_obj.address.address2,
                city: festival_obj.address.city,
                state: festival_obj.address.state,
                country: festival_obj.address.country,
                postalcode: festival_obj.address.postal_code,
                capacity: '',
                currency: festival_obj.address.currency,
                budget: '',
                website: festival_obj.website,
                facebook: festival_obj.facebook,
                instagram: festival_obj.instagram,
                tags: festival_obj.tags,
                submissions: festival_obj.submissions,
            })
            festival_obj.dates.map(dates => {
                console.log(moment(dates.year), year)
                if (moment(dates.year).year() === year) {
                    this.setState({
                        select_array: dates.dates
                    })
                }
            })
        } else {
            this.setState({
                render_type: 'enter',
                header: 'NEW FESTIVAL'
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleEventPositioned() {
        ReactTooltip.rebuild();
    }

    handleFormattedChange = (values, set) => {
        const { formattedValue, value } = values;
        console.log(formattedValue, value)
        this.setState({ [set]: value })
    }

    toggleSub = (e) => {
        this.setState({
            render: e,
        })
    }

    handleYearChange(event) {
        var year = event;
        var year_val = moment(event).year();
        console.log(year, year_val)

        console.log(year_val)
        var index = this.state.datesarray.findIndex(obj => obj.year === year_val.toString());
        console.log(index, this.state.datesarray)
        var select_array = [];
        if (index >= 0) {
            select_array.push(...this.state.datesarray[index].dates)
        } else if (index < 0 && this.state.toggle_proj_dates) {
            const yearsArray = this.state.datesarray.map(d => moment(d.year));
            var max = moment.max(yearsArray);
            var latest_dates = [];
            console.log(this.state.datesarray);
            this.state.datesarray.map(item => {
                if (item.year === max._i) {
                    latest_dates = item.dates
                }
            })

            console.log('latest', latest_dates)
            var datesArray = [];
            var select_y = year_val;
            var state_year = new Date(this.state.select_year);
            var current_y = moment(max._i).year();
            var diff = select_y - current_y;

            if (diff !== 0) {
                latest_dates.map(d => {
                    // if(Number(select_y) < Number(current_y)){
                    //     diff = (select_y-1) - current_y
                    // } else {
                    //     diff = select_y - current_y
                    // }
                    var day = moment(d.date).day();
                    var nD = moment(d.date);
                    if (diff !== 0) {
                        if (diff > 0) {
                            nD.add(diff, 'years');
                        } else if (diff < 0) {
                            var m = Math.abs(diff);
                            nD.subtract(m, 'years');
                        }
                        if (day < 5) {
                            nD.add((day - 1), 'days');
                        } else {
                            nD.add((day - 1), 'days').subtract(1, 'weeks');
                        }
                        nD.weekday(day)
                        // if(day === 6){
                        //     nD.subtract(7, 'days')
                        // }
                    }
                    var newDate = {
                        date: new Date(nD._d),
                        type: 'projected'
                    }
                    select_array.push(newDate)
                })
                // console.log(datesArray)
                // select_array = [{
                //     year: select_y.toString(),
                //     dates: datesArray
                // }]
            } else {
                select_array = this.state.select_array;
            }

        }

        console.log(select_array)
        this.setState({
            select_year: new Date(year),
            select_array: select_array
        })
    }

    handleAgentChange(agent) {
        if (agent) {
            this.setState({
                agent: agent,
            })
        } else {
            this.setState({
                agent: [],
            });
        }

    }

    addDate = () => {
        if (this.state.date) {
            var newDate = {
                date: moment(this.state.date).format('MM/DD/YYYY').toString(),
            }
            var year_val = new Date(this.state.date).getFullYear();
            let dates_array = [...this.state.datesarray];
            var index = dates_array.findIndex(obj => obj.year === year_val.toString());
            if (index >= 0) {
                const check = dates_array[index].dates.findIndex(item => item.date === moment(this.state.date).format('MM/DD/YYYY').toString())
                if (check >= 0) {
                    alert("Date already logged")
                } else {
                    dates_array[index].dates.push(newDate)
                }
            } else {
                const val = {
                    year: year_val.toString(),
                    dates: [newDate]
                }
                dates_array[dates_array.length] = val
            }
            // if (dates_array.length > 0) {
            //     for (var i = 0; i < dates_array.length; i++) {
            //         if (dates_array[i].year === year_val.toString()) {
            //             // var a = [...dates_array[i].dates, newDate];
            //             // console.log(a)
            //             dates_array[i].dates.push(newDate)
            //         } else {
            //             const val = {
            //                 year: year_val.toString(),
            //                 dates: [newDate]
            //             }
            //             console.log(val)
            //             dates_array[dates_array.length] = val
            //         }
            //     }


            //     // dates_array.map(item => {
            //     //     if (item.year === year_val.toString()) {
            //     //         item.dates.push(newDate)
            //     //         console.log(item)
            //     //     } else {
            //     //         const val = {
            //     //             year: year_val.toString(),
            //     //             dates: [newDate]
            //     //         }
            //     //         console.log(val)
            //     //         dates_array.push(val)
            //     //     }
            //     // })
            // } else {
            //     const val = {
            //         year: year_val.toString(),
            //         dates: [newDate]
            //     }
            //     console.log(val)
            //     dates_array.push(val)
            // }

            // const newArray = []
            // newArray.push(...this.state.datesarray, newValue)

            // console.log(newArray)

            this.setState({
                datesarray: dates_array,
                date: '',
            })

            console.log(dates_array)
            // var year_val = this.state.select_year.getFullYear();
            // var dates_array = [];
            dates_array.map(dates => {
                if (dates.year === this.state.select_year.getFullYear().toString()) {
                    const datesSort = _.sortBy(dates.dates, function (dateObj) {
                        return new Date(dateObj.date);
                    });
                    console.log(datesSort)
                    this.setState({
                        select_array: datesSort
                    })
                } else {

                }
            })
        } else {
            alert('Please select a date to add.')
        }
    }

    addDateRange = () => {
        if (this.state.date && this.state.end_date) {
            var dateList = getDaysBetweenDates(this.state.date, this.state.end_date);

            var year_val = new Date(this.state.date).getFullYear();
            let dates_array = [...this.state.datesarray];
            var index = dates_array.findIndex(obj => obj.year === year_val.toString());

            dateList.forEach(d => {
                var newDate = {
                    date: d.toString(),
                }
                if (index >= 0) {
                    dates_array[index].dates.push(newDate)
                } else {
                    const val = {
                        year: year_val.toString(),
                        dates: [newDate]
                    }
                    if (dates_array.length === 0) {
                        dates_array[index + 1] = val
                        index = index + 1;
                    } else {
                        index = dates_array.length;
                        dates_array.push(val);
                    }
                }

            })

            this.setState({
                datesarray: dates_array,
                date: '',
                end_date: ''
            })

            // var year_val = this.state.select_year.getFullYear();
            // var dates_array = [];
            dates_array.map(dates => {
                if (dates.year === this.state.select_year.getFullYear().toString()) {
                    this.setState({
                        select_array: dates.dates
                    })
                } else {

                }
            })
        } else {
            alert('Please select a date to add.')
        }
    }

    toggleProjectedDates = (val) => {
        var year_val = moment(this.state.select_year).year();
        if (val === false) {
            var index = this.state.datesarray.findIndex(obj => obj.year === year_val.toString());
            if (index < 0) {
                const yearsArray = this.state.datesarray.map(d => moment(d.year));
                var max = moment.max(yearsArray);
                var latest_dates = [];
                this.state.datesarray.map(item => {
                    if (item.year === max._i) {
                        latest_dates = item.dates
                    }
                })

                console.log(latest_dates)
                var datesArray = [];
                var select_y = this.state.select_year.getFullYear();
                latest_dates.map(d => {
                    var current_y = new Date().getFullYear();
                    var diff = select_y - current_y
                    var day = moment(d.date).day();
                    var nD = moment(d.date).add(diff, 'years');
                    var future_day = moment(nD._d).day();
                    console.log(future_day, nD)

                    if (day < 5) {
                        nD.add((day - 1), 'days');
                    } else {
                        nD.add((day - 1), 'days').subtract(1, 'weeks');
                    }
                    //console.log(nD)
                    nD.weekday(day)
                    // if(day === 6){
                    //     nD.subtract(7, 'days')
                    // }
                    console.log(day, nD)
                    var newDate = {
                        date: new Date(nD._d),
                        type: 'projected'
                    }
                    datesArray.push(newDate)
                })
                this.setState({
                    toggle_proj_dates: true,
                    select_array: datesArray
                })
            } else {
                this.setState({
                    toggle_proj_dates: true,
                })
            }
        } else {
            console.log(this.state.toggle_proj_dates)
            console.log(year_val)
            var index = this.state.datesarray.findIndex(obj => obj.year === year_val.toString());
            if (index >= 0) {
                var select_array = [];
                select_array = this.state.datesarray[index].dates
                console.log(select_array)
                this.setState({
                    toggle_proj_dates: false,
                    select_array: select_array
                })
            } else {
                this.setState({
                    toggle_proj_dates: false,
                    select_array: []
                })
            }
        }
    }

    getProjectedDate = () => {
        const yearsArray = this.state.datesarray.map(d => moment(d.year));
        var max = moment.max(yearsArray);
        var latest_dates = [];
        this.state.datesarray.map(item => {
            if (item.year === max._i) {
                latest_dates = item.dates
            }
        })

        var datesArray = [];
        var select_y = this.state.select_year.getFullYear();
        latest_dates.map(d => {
            var current_y = new Date().getFullYear();
            var diff = select_y - current_y
            var day = moment(d.date).day();
            var nD = moment(d.date).add(diff, 'years');
            if (day < 5) {
                nD.add((day - 1), 'days');
            } else {
                nD.add((day - 1), 'days').subtract(1, 'weeks');
            }
            console.log(nD)
            nD.weekday(day)
            // if(day === 6){
            //     nD.subtract(7, 'days')
            // }
            console.log(day, nD)
            var newDate = {
                date: new Date(nD._d),
                type: 'projected'
            }
            datesArray.push(newDate)
        })
        const proj_dates = {
            year: select_y.toString(),
            dates: datesArray
        }
        this.setState({
            select_array: datesArray
        })
        console.log(proj_dates)

    }

    // updateDate = (index) => {
    //     if (index >= 0) {
    //         const date = this.state.select_array[index];
    //         const newDate = new Date(date.date)
    //         this.setState({
    //             date: newDate,
    //         })
    //         const newArray = this.state.select_array.filter((e, i) => i !== index);
    //         this.setState({
    //             select_array: newArray
    //         })
    //     }
    // }

    deleteDate = (props) => {
        var year_val = new Date(this.state.select_year).getFullYear();
        let dates_array = [...this.state.datesarray];
        var index = dates_array.findIndex(obj => obj.year === year_val.toString());
        if (index >= 0) {
            const newDates = dates_array[index].dates.filter((e, i) => e.date !== moment(props).format('MM/DD/YYYY').toString());
            dates_array[index].dates = newDates;
            const newArraySelect = this.state.select_array.filter((e, i) => e.date !== moment(props).format('MM/DD/YYYY').toString());
            this.setState({
                datesarray: dates_array,
                select_array: newArraySelect
            })
        } else {
            alert('error: out of range')
        }
    }

    handleDateChange = date => {
        this.setState({
            date: date
        });
    };

    handleEndDateChange = date => {
        this.setState({
            end_date: date
        });
    };

    handleClick = () => {
        this.props.toggle();
    };

    onEdit = () => {
        this.setState({
            edit: !this.state.edit
        })
    }

    onSave = async () => {
        if (!this.state.festival || this.state.datesarray.length <= 0) {
            alert('Please enter a festival name and date range');
        } else {
            const dupCheck = this.state.festival;
            var check = await this.props.festivalDupCheck(dupCheck);
            if (check.length > 0 && this.state.render_type === 'enter') {
                this.duplicateDialog(check);
            } else {
                this.finishSave();
            }
        }
    }

    finishSave = async () => {
        const address = {
            address: this.state.address,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            postal_code: this.state.postal_code
        }

        // var y = new Date(this.state.datesarray[0].date)
        // var yd = y.getFullYear();

        // const dates = {
        //     year: yd,
        //     dates: this.state.datesarray
        // }

        const festival = {
            festival: this.state.festival,
            status: this.state.status,
            venue: this.state.venue,
            address: address,
            dates: this.state.datesarray,
            capacity: this.state.capacity,
            currency: this.state.currency,
            budget: this.state.budget,
            website: this.state.website,
            facebook: this.state.facebook,
            instagram: this.state.instagram,
            twitter: this.state.twitter,
            submissions: this.state.submissions
        }

        const id = this.state.id;

        if (this.state.render_type === 'render') {
            const log_status = await this.props.updateFestival(festival, id);
            console.log(log_status)
            if (log_status.includes('Error:')) {
                this.props.toggleErrorSnackbar(log_status);
            } else {
                this.setState({
                    header: this.state.festival,
                    edit: false,
                })
                this.props.toggleStatusSnackbar('Festival updated successfully');
            }
        } else {
            const log_status = await this.props.addFestival(festival);
            if (log_status.includes('Error:')) {
                this.props.toggleErrorSnackbar(log_status);
            } else {
                this.setState({
                    header: this.state.festival,
                    edit: false,
                    render_type: 'render',
                    id: log_status
                })
                this.props.toggleStatusSnackbar('Festival added successfully');
            }
        }
    }

    confirmDate = (i) => {
        let select_array = this.state.select_array;
        console.log(select_array)
        var val = select_array[i].date;
        var year_val = moment(val).year();
        console.log(val, year_val)
        var index = this.state.datesarray.findIndex(obj => obj.year === year_val.toString());
        let array = this.state.datesarray;
        if (index >= 0) {
            var datesArray = [...this.state.datesarray[index].dates, { date: val }];
            if (this.state.datesarray[index]._id) {
                array[index] = {
                    _id: this.state.datesarray[index]._id,
                    year: this.state.datesarray[index].year,
                    dates: datesArray
                }
            } else {
                array[index] = {
                    year: this.state.datesarray[index].year,
                    dates: datesArray
                }
            }
            console.log(datesArray)
        } else {
            const newDate = {
                year: year_val.toString(),
                dates: [{ date: val }]
            }
            array.push(newDate)
        }
        select_array[i].type = '';
        console.log(array, select_array)
        this.setState({
            select_array: [],
            // datesarray: []
        }, () => this.setState({
            select_array: select_array,
            // datesarray: this.state.datesarray
        }))
        console.log(this.state.select_array)
    }

    editDateSelect = (i) => {
        this.setState({
            edit_index: i
        })
    }

    editDate(oldDate, date, i) {
        console.log(oldDate, date, i)
        let select_array = this.state.select_array;
        var val = date;
        var year_val = moment(val).year();

        var index = this.state.datesarray.findIndex(obj => obj.year === year_val.toString());
        let array = this.state.datesarray;

        if (index >= 0) {

            var datesArray = [...array[index].dates];
            var dIndex = datesArray.findIndex(obj => new Date(obj.date) === new Date(oldDate))

            if (dIndex >= 0) {
                datesArray[dIndex].date = date;

            } else {
                datesArray = [...array[index].dates, { date: date }];
            }

            if (this.state.datesarray[index]._id) {
                array[index] = {
                    _id: this.state.datesarray[index]._id,
                    year: this.state.datesarray[index].year,
                    dates: datesArray
                }

            } else {
                array[index] = {
                    year: this.state.datesarray[index].year,
                    dates: datesArray
                }

            }
            console.log(datesArray)
        } else {

            const newDate = {
                year: year_val.toString(),
                dates: [{ date: date }]
            }

            array.push(newDate)
        }

        select_array[i].date = date
        // select_array[i].type = '';
        console.log(array, select_array)
        this.setState({
            select_array: [],
            // datesarray: array
        }, () => this.setState({
            select_array: select_array,
            datesarray: array,
            edit_index: null
        }))
    }

    handleLocation = (results, title) => {
        var street = "";
        var route = "";
        var subpremise = "";
        var locality = "";
        var state = "";
        var country = "";
        var postal_code = "";

        results[0].address_components.map(item => {
            if (item.types.includes('subpremise')) {
                subpremise = item.long_name
            } else if (item.types.includes('sublocality')) {
                subpremise = item.long_name
            }
            if (item.types.includes('street_number')) {
                street = item.long_name
            }
            if (item.types.includes('route')) {
                route = item.short_name
            }
            if (item.types.includes('locality')) {
                locality = item.long_name
            }
            if (item.types.includes('administrative_area_level_1')) {
                state = item.long_name
            }
            if (item.types.includes('country')) {
                country = item.long_name
            }
            if (item.types.includes('postal_code')) {
                postal_code = item.long_name
            }
        })
        this.setState({
            address: street + ' ' + route,
            address2: subpremise,
            country: country,
            city: locality,
            state: state,
            postalcode: postal_code,
            venue: title
        })
    }

    handleCurrencyChange = (event, set) => {
        console.log(event, set)
        this.setState({
            [set]: event.code
        })
    }

    handleClientChange(artist) {
        if (artist) {
            this.setState({
                artist: artist.client,
                artistid: artist._id,
                artistbatch: artist
            })
        } else {
            this.setState({
                artist: '',
                artistid: '',
                artistbatch: ''
            });
        }
    }

    onDateSubmitted = date => {
        this.setState({
            date_submitted: date
        })
    }

    handleYearChangeSub = (event) => {
        var year = event;
        this.setState({
            select_year: new Date(year),
        })
    }

    addSubmission = async () => {
        const submission = {
            date_submitted: moment(this.state.date_submitted).format('MM/DD/YYYY'),
            client: this.state.artistid,
            note: this.state.submission_note
        }
        var stateSubs = this.state.submissions;
        const exists = stateSubs.filter(sub => Number(sub.year) === new Date(this.state.select_year).getFullYear());
        const found = stateSubs.findIndex(obj => Number(obj.year) === new Date(this.state.select_year).getFullYear());
        if (exists.length > 0) {
            var test = false;
            stateSubs.map(item => {
                item.subs.map(s => {
                    if (s.client === this.state.artistid) {
                        test = true
                    }
                })
            });
            if (!test) {
                stateSubs[found].subs = [...stateSubs[found].subs, submission]
            } else {
                alert('Submission is already added.')
            }
        } else {
            const updatedSub = {
                year: new Date(this.state.select_year).getFullYear(),
                subs: [submission]
            }

            stateSubs = [...stateSubs, updatedSub]
        }
        this.setState({
            submissions: stateSubs,
            artist: '',
            artistid: '',
            artistbatch: { _id: '', client: '' },
            date_submitted: new Date(),
            submission_note: ''
        })
        console.log(stateSubs, exists)
    }

    updateSubmission = (index, client) => {
        const val = this.state.submissions[index];
        this.setState({
            artist: client,
            artistid: val.client,
            artistbatch: { _id: val.client, client: client },
            date_submitted: new Date(val.date_submitted),
            submission_note: val.note
        })
        const newArray = this.state.submissions.filter((e, i) => i !== index);
        this.setState({
            submissions: newArray
        })
    }

    updateSubsArray = (index, name, event) => {
        console.log(index, name, event.target.value)
        let array = this.state.submissions;
        array[index][name] = event.target.value;
        // array = _.sortBy(array, function (dateObj) {
        //     return new Date(dateObj.date);
        // });
        console.log(array)
        this.setState({
            submissions: array
        })
    }

    updateSubsDateArray = (index, date) => {
        let array = this.state.submissions;
        array[index].date_submitted = date;
        array = _.sortBy(array, function (dateObj) {
            return new Date(dateObj.date_submitted);
        });
        this.setState({
            submissions: []
        }, () => this.setState({
            submissions: array
        }))
    }

    deleteSubmission = (index, client) => {
        // const newArray = this.state.submissions.filter((e, i) => i !== index);
        // this.setState({
        //     submissions: newArray
        // })
        var stateSubs = this.state.submissions;
        var specSub = stateSubs[index];
        var newArray = specSub.subs.filter((e, i) => e.client !== client);
        specSub.subs = newArray;
        stateSubs[index] = specSub;

        this.setState({
            submissions: []
        }, () => this.setState({
            submissions: stateSubs
        }))
    }

    toggleDateRange = () => {
        this.setState({
            date_range: !this.state.date_range
        })
    }

    toggleStatus = () => {
        if (this.state.status === 'Active') {
            this.setState({
                status: "Inactive"
            })
        } else {
            this.setState({
                status: "Active"
            })
        }
    }

    duplicateDialog = async (check) => {
        const checkData = await check.map(item => {
            var maxYear = item.dates.reduce(function (a, b) { return new Date(a.year) > new Date(b.year) ? a : b; });
            var dates = '';
            if (new Date(item.min.date).getFullYear().toString() === maxYear.year) {
                if (new Date(item.min.date) === new Date(item.max.date)) {
                    dates = moment(item.min.date).format('MM/DD/YYYY');
                } else {
                    dates = moment(item.min.date).format('MM/DD/YYYY') + ' - ' + moment(item.max.date).format('MM/DD/YYYY');
                };
            }
            const obj = {
                festival: item.festival,
                address: item.address,
                dates: dates
            }
            return obj
        })
        let array = checkData.filter(item => {
            if (item.dates) {
                return item
            }
        });
        // let array = checkData.filter( (ele, ind) => ind === check.findIndex( elem => elem._id === ele._id && elem.dates === ele.dates))
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Duplicate</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ fontSize: 14, color: '#000', marginBottom: 10 }}>The following festival is alredy entered in the system.  Would you like to continue saving?</p>
                            {array.map((item, index) => {
                                return (
                                    <p style={{ fontSize: 12, color: '#000' }} key={index}>{item.festival} - {item.dates} - {item.address.city}, {item.address.state}</p>
                                )
                            })}
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.finishSave(); onClose(); }}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={onClose}>No</button>
                        </div>
                    </div>
                </div>
            )
        }

        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }

        confirmAlert(options)
    }

    render() {


        console.log(this.state.select_array, "SELECT ARR!")
        const currency_symbol = getSymbolFromCurrency(this.props.currency);

        const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return (
            <div className="mode-t">
                <div className="mode-t-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50 }}>
                        <span className="close" onClick={this.props.close}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        {this.state.edit ?
                            <span className="close" onClick={this.onSave}>
                                <FaSave size="20" color="#cdcdcd" />
                            </span> :
                            <span className="close" onClick={this.onEdit}>
                                <FaEdit size="18" color="#cdcdcd" />
                            </span>}
                        {this.state.status === "Active" ?
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>ACTIVE</p>
                                <div style={{ backgroundColor: 'green', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span> :
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>INACTIVE</p>
                                <div style={{ backgroundColor: 'red', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span>}
                        <h1 style={{ color: '#fff', marginLeft: 60, fontFamily: 'Helvetica', paddingTop: 12, fontSize: 22, fontWeight: 'bold' }}>{this.state.header}</h1>
                    </div>

                    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                        <div style={{ width: '5%', backgroundColor: 'rgb(1, 22, 48)', height: '91.6%' }}>
                            {/* {this.props.tooltip ? */}
                            <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                            </ReactTooltip>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'info' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'info' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('info')} data-tip={'Festival Info'} onMouseEnter={this.handleEventPositioned}>
                                <FaInfoCircle style={{ fontSize: 20 }} />
                            </div>
                            {this.state.render_type !== 'enter' ?
                                <div style={{ height: 50, backgroundColor: this.state.render === 'contacts' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'contacts' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('contacts')} data-tip={'Contacts'} onMouseEnter={this.handleEventPositioned}>
                                    <FaAddressBook style={{ fontSize: 20 }} />
                                </div> : null}
                            {this.state.render_type !== 'enter' ?
                                <div style={{ height: 50, backgroundColor: this.state.render === 'submissions' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'submissions' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('submissions')} data-tip={'Submissions'} onMouseEnter={this.handleEventPositioned}>
                                    <FaVoteYea style={{ fontSize: 20 }} />
                                </div> : null}
                        </div>
                        {this.state.render === "info" ?
                            <div style={{ display: 'flex', width: '100%', height: 520, overflowY: 'scroll' }}>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 30 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Festival Name:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="festival" value={this.state.festival} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    {this.state.render_type === 'enter' ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, width: '90%', marginTop: 20 }} className="form-group">
                                            <LocationAutocomplete handleLocation={this.handleLocation} Gkey={this.props.auth.user.api.google} />
                                        </div> : null}
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 20 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Venue:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="venue" value={this.state.venue} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 15 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Capacity:</label>
                                        <NumberFormat style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.capacity} thousandSeparator={true} onValueChange={(values) => this.handleFormattedChange(values, 'capacity')} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 15 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Address:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="address" value={this.state.address} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}> </label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="address2" value={this.state.address2} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Country:</label>
                                        <select style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000', padding: 5, color: '#000' }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.country} name="country" onChange={this.handleChange.bind(this)}>
                                            <option value=""> </option>
                                            <option value="United States">United States</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Afganistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bonaire">Bonaire</option>
                                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canary Islands">Canary Islands</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Channel Islands">Channel Islands</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos Island">Cocos Island</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote DIvoire">Cote DIvoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curaco">Curacao</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="East Timor">East Timor</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands">Falkland Islands</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Ter">French Southern Ter</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Great Britain">Great Britain</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Hawaii">Hawaii</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="India">India</option>
                                            <option value="Iran">Iran</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea North">Korea North</option>
                                            <option value="Korea Sout">Korea South</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Laos">Laos</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libya">Libya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macau">Macau</option>
                                            <option value="Macedonia">Macedonia</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Midway Islands">Midway Islands</option>
                                            <option value="Moldova">Moldova</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Nambia">Nambia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherland Antilles">Netherland Antilles</option>
                                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                            <option value="Nevis">Nevis</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau Island">Palau Island</option>
                                            <option value="Palestine">Palestine</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Phillipines">Philippines</option>
                                            <option value="Pitcairn Island">Pitcairn Island</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                                            <option value="Republic of Serbia">Republic of Serbia</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russia">Russia</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="St Barthelemy">St Barthelemy</option>
                                            <option value="St Eustatius">St Eustatius</option>
                                            <option value="St Helena">St Helena</option>
                                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                            <option value="St Lucia">St Lucia</option>
                                            <option value="St Maarten">St Maarten</option>
                                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                            <option value="Saipan">Saipan</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Samoa American">Samoa American</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syria">Syria</option>
                                            <option value="Tahiti">Tahiti</option>
                                            <option value="Taiwan">Taiwan</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania">Tanzania</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Erimates">United Arab Emirates</option>
                                            <option value="Uraguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Vatican City State">Vatican City State</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                            <option value="Wake Island">Wake Island</option>
                                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zaire">Zaire</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            <label style={{ width: '25%', fontSize: 14 }}>City:</label>
                                            <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="city" value={this.state.city} onChange={this.handleChange.bind(this)} />
                                        </div> : null}
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            {this.state.country === 'United States' ?
                                                <label style={{ width: '25%', fontSize: 14 }}>State:</label> :
                                                <label style={{ width: '25%', fontSize: 14 }}>Province:</label>}
                                            {this.state.country === 'United States' ?
                                                <select style={{ marginLeft: 10, width: '55%', border: '.4px solid #000', fontSize: 12, color: '#000' }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.state} name="state" onChange={this.handleChange.bind(this)}>
                                                    <option value=""> </option>
                                                    <option value="Alabama">Alabama</option>
                                                    <option value="Alaska">Alaska</option>
                                                    <option value="Arizona">Arizona</option>
                                                    <option value="Arkansas">Arkansas</option>
                                                    <option value="California">California</option>
                                                    <option value="Colorado">Colorado</option>
                                                    <option value="Connecticut">Connecticut</option>
                                                    <option value="Delaware">Delaware</option>
                                                    <option value="District Of Columbia">District Of Columbia</option>
                                                    <option value="Florida">Florida</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Idaho">Idaho</option>
                                                    <option value="Illinois">Illinois</option>
                                                    <option value="Indiana">Indiana</option>
                                                    <option value="Iowa">Iowa</option>
                                                    <option value="Kansas">Kansas</option>
                                                    <option value="Kentucky">Kentucky</option>
                                                    <option value="Louisiana">Louisiana</option>
                                                    <option value="Maine">Maine</option>
                                                    <option value="Maryland">Maryland</option>
                                                    <option value="Massachusetts">Massachusetts</option>
                                                    <option value="Michigan">Michigan</option>
                                                    <option value="Minnesota">Minnesota</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="Missouri">Missouri</option>
                                                    <option value="Montana">Montana</option>
                                                    <option value="Nebraska">Nebraska</option>
                                                    <option value="Nevada">Nevada</option>
                                                    <option value="New Hampshire">New Hampshire</option>
                                                    <option value="New Jersey">New Jersey</option>
                                                    <option value="New Mexico">New Mexico</option>
                                                    <option value="New York">New York</option>
                                                    <option value="North Carolina">North Carolina</option>
                                                    <option value="North Dakota">North Dakota</option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Oklahoma">Oklahoma</option>
                                                    <option value="Oregon">Oregon</option>
                                                    <option value="Pennsylvania">Pennsylvania</option>
                                                    <option value="Rhode Island">Rhode Island</option>
                                                    <option value="South Carolina">South Carolina</option>
                                                    <option value="South Dakota">South Dakota</option>
                                                    <option value="Tennessee">Tennessee</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="Utah">Utah</option>
                                                    <option value="Vermont">Vermont</option>
                                                    <option value="Virginia">Virginia</option>
                                                    <option value="Washington">Washington</option>
                                                    <option value="West Virginia">West Virginia</option>
                                                    <option value="Wisconsin">Wisconsin</option>
                                                    <option value="Wyoming">Wyoming</option>
                                                </select> :
                                                this.state.country === 'Canada' ?
                                                    <select style={{ marginLeft: 10, width: '55%', border: '.4px solid #000', fontSize: 12, padding: 5, color: '#000' }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.state} name="state" onChange={this.handleChange.bind(this)}>
                                                        <option value=""> </option>
                                                        <option value="Alberta">Alberta</option>
                                                        <option value="British Columbia">British Columbia</option>
                                                        <option value="Manitoba">Manitoba</option>
                                                        <option value="New Brunswick">New Brunswick</option>
                                                        <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                                        <option value="Nova Scotia">Nova Scotia</option>
                                                        <option value="Ontario">Ontario</option>
                                                        <option value="Prince Edward Island">Prince Edward Island</option>
                                                        <option value="Quebec">Quebec</option>
                                                        <option value="Saskatchewan">Saskatchewan</option>
                                                    </select> :
                                                    <input style={{ marginLeft: 10, width: '55%', border: '.4px solid #000', fontSize: 12, color: '#000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} value={this.state.state} name="state" onChange={this.handleChange.bind(this)} />}
                                        </div> : null}
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            <label style={{ width: '25%', fontSize: 14 }}>Postal Code:</label>
                                            <input style={{ marginLeft: 10, width: '30%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="postalcode" value={this.state.postalcode} onChange={this.handleChange.bind(this)} />
                                        </div> : null}
                                </div>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', width: '85%', justifyContent: 'flex-end' }}>
                                        <p style={{ marginRight: 2, fontSize: 10, marginTop: 15, color: '#000' }}>Include Projected Dates</p>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: 'rgb(1, 22, 48)' }} fontSize="small" />}
                                            color="default"
                                            name="toggle_proj_dates"
                                            checked={this.state.toggle_proj_dates}
                                            //onClick={this.toggleProjectedDates}
                                            //value={this.state.toggle_proj_dates}
                                            onChange={() => this.state.toggle_proj_dates ? this.toggleProjectedDates(true) : this.toggleProjectedDates(false)}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Year:</label>
                                        <div style={{ display: 'flex', width: '55%', marginLeft: 10 }}>
                                            <DatePicker
                                                selected={this.state.select_year}
                                                onChange={date => this.handleYearChange(date)}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                yearItemNumber={9}
                                            />
                                        </div>
                                    </div>
                                    {this.state.edit ?
                                        <div>
                                            <div style={{ display: 'flex', height: 'auto', alignItems: 'center' }} className="form-group">
                                                <label style={{ width: '25%', fontSize: 14 }}>Date(s):</label>
                                                {!this.state.date_range ?
                                                    <div style={{ display: 'flex', width: '55%', marginLeft: 10 }}>
                                                        <div style={{ width: '80%' }}>
                                                            <DatePicker
                                                                className="react-datepicker"
                                                                style={{ fontSize: 12 }}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                value={this.state.date}
                                                                selected={this.state.date}
                                                                onChange={this.handleDateChange}
                                                            />
                                                        </div>
                                                        <FaArrowsAltH style={{ cursor: 'pointer', marginLeft: 10, marginTop: 4 }} size="20" color="rgb(1, 22, 48)" onClick={this.toggleDateRange} />
                                                        <FaPlusSquare style={{ cursor: 'pointer', marginLeft: 10, marginTop: 4 }} size="20" color="rgb(1, 22, 48)" onClick={this.addDate} />
                                                    </div> :
                                                    <div style={{ display: 'flex', width: '55%', marginLeft: 10 }}>
                                                        <div style={{ width: '80%', display: 'flex', flexDirection: 'column' }}>
                                                            <DatePicker
                                                                className="react-datepicker"
                                                                style={{ fontSize: 12 }}
                                                                selected={this.state.date}
                                                                selectsStart
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                startDate={this.state.date}
                                                                endDate={this.state.end_date}
                                                                onChange={this.handleDateChange}
                                                            />
                                                            <DatePicker
                                                                className="react-datepicker"
                                                                style={{ fontSize: 12 }}
                                                                selected={this.state.end_date}
                                                                selectsEnd
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                startDate={this.state.date}
                                                                endDate={this.state.end_date}
                                                                minDate={this.state.date}
                                                                onChange={this.handleEndDateChange}
                                                            />
                                                        </div>
                                                        <FaArrowsAltH style={{ cursor: 'pointer', marginLeft: 10, marginTop: 4 }} size="20" color="rgb(1, 22, 48)" onClick={this.toggleDateRange} />
                                                        <FaPlusSquare style={{ cursor: 'pointer', marginLeft: 10, marginTop: 4 }} size="20" color="rgb(1, 22, 48)" onClick={this.addDateRange} />
                                                    </div>}
                                            </div>
                                        </div> : null}
                                    <div style={{ display: 'flex', height: 'auto', marginTop: 15 }} className="form-group">
                                        {this.state.edit ?
                                            <label style={{ width: '25%', fontSize: 14 }}> </label> :
                                            <label style={{ width: '25%', fontSize: 14 }}>Date(s)</label>}
                                        <div style={{ minHeight: 50, maxHeight: 'auto', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd', width: '48%', marginLeft: 10 }}>
                                            {this.state.select_array.length > 0 ?
                                                this.state.select_array.map((item, index) => {
                                                    const d = new Date(item.date);
                                                    const day = days[d.getDay()];
                                                    const mon = monthNames[d.getMonth()];
                                                    const newDate = `${day}. ${mon} ${d.getDate()}, ${d.getFullYear()}`;

                                                    return (
                                                        <div key={index} style={{ border: item.type === 'projected' ? '1px dashed blue' : '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', height: 50 }}>
                                                                {!this.state.edit || this.state.edit && this.state.edit_index !== index ?
                                                                    <p style={{ color: '#000', fontSize: 12, width: '80%', paddingTop: '1rem', paddingLeft: '0.5rem' }} onClick={() => this.editDateSelect(index)}>{newDate}</p> :
                                                                    // <div style={{ color: '#000', fontSize: 12, width: '80%', height: '100%' }} onBlur={() => this.editDateSelect(null)}>
                                                                    <div style={{ color: '#000', fontSize: 12, width: '80%', height: '100%' }}>
                                                                        <DatePicker
                                                                            className="react-datepicker"
                                                                            style={{ fontSize: 12 }}
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            value={new Date(item.date)}
                                                                            selected={new Date(item.date)}
                                                                            onChange={(date) => this.editDate(item.date, date, index)}
                                                                            onClickOutside={() => this.editDateSelect(null)}
                                                                        />
                                                                    </div>}
                                                                {this.state.edit && item.type !== "projected" ?
                                                                    <div style={{ width: '20%', display: 'flex', paddingTop: '1rem' }}>
                                                                        {/* <FaEdit style={{ cursor: 'pointer' }} size="16" color='#cdcdcd' onClick={() => this.updateDate(index)} /> */}
                                                                        <FaTimesCircle style={{ marginLeft: 10, cursor: 'pointer' }} size="16" color='#cdcdcd' onClick={() => this.deleteDate(item.date)} />
                                                                    </div> :
                                                                    item.type === "projected" && this.state.edit ?
                                                                        <button style={{ width: 'auto', height: '100%', backgroundColor: '#333', color: '#cdcdcd', fontSize: 10 }} type="button" onClick={() => this.confirmDate(index)}>Confirm</button> : null}
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                !this.state.edit ?
                                                    <div style={{ border: '1px dashed gray', paddingTop: '1rem', paddingLeft: '0.5rem', backgroundColor: '#f1f1f1', width: '100%' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <p style={{ color: '#000', fontSize: 12, width: '100%' }}>No dates logged</p>
                                                        </div>
                                                    </div> :
                                                    null}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginTop: 15 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Currency:</label>
                                        <div style={{ marginLeft: 10, width: '55%' }}>
                                            <CurrencyAutocomplete val={this.state.currency} onChange={this.handleCurrencyChange} set="currency" />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Budget:</label>
                                        <NumberFormat style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.budget} thousandSeparator={true} prefix={currency_symbol} onValueChange={(values) => this.handleFormattedChange(values, 'budget')} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Website:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="website" value={this.state.website} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Facebook:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="facebook" value={this.state.facebook} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Instagram:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="instagram" value={this.state.instagram} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center' }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Twitter:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} name="twitter" value={this.state.twitter} onChange={this.handleChange.bind(this)} />
                                    </div>
                                </div>
                            </div> :

                            this.state.render === 'contacts' ?
                                <RenderContacts toggleNewContact={this.toggleNewContact} toggleNewModal={this.props.toggleNewModal} {...this.state} /> :
                                <Submissions onChange={this.handleChange} handleYearChange={this.handleYearChangeSub} onClientChange={this.handleClientChange} onDateSubmitted={this.onDateSubmitted} addSubmission={this.addSubmission} updateSubmission={this.updateSubmission} deleteSubmission={this.deleteSubmission} toggleNewModal={this.props.toggleNewModal} updateSubsArray={this.updateSubsArray} updateSubsDateArray={this.updateSubsDateArray} {...this.state} />}

                    </div>

                </div>
            </div>
        );
    }
}

function getDaysBetweenDates(startDate, endDate) {
    var now = moment(startDate), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('MM/DD/YYYY'));
        now.add(1, 'days');
    }
    return dates;
};

const mapStateToProps = state => ({
    items: state.items,
    auth: state.auth
});

export default connect(mapStateToProps, { addFestival, updateFestival, festivalDupCheck })(FestivalModal);