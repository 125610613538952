import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NumberFormat from 'react-number-format';

export default function CommissionDialog(props) {
    const [stateComissionSelect, setComissionSelect] = useState('');

    // useEffect(() => {
    //     setComissionSelect(props.val);
    // }, [props.val])

    const closeDialog = () => {
        props.handleClose();
    };

    console.log(props.commission_percent)

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={props.handleClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Deal Comission</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
                    <div
                        style={{
                            paddingRight: 17,
                            height: "100%",
                            width: "100%",
                            boxSizing: "content-box",
                            overflow: "scroll"
                        }}>
                        <table style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Comission Amount:</td>
                                    <td style={{ width: '50%' }}><NumberFormat className="form-control-sm" style={{ fontSize: 12, border: '1px solid #000', borderRadius: 1, padding: 10, width: '100%' }} name="commission_percent" value={props.commission_percent} thousandSeparator={true} suffix={"%"} onChange={(event) => props.onChange(event, "commission")}/></td> 
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={closeDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}