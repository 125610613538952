import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/authActions';
import PropTypes from 'prop-types';

class Logout extends Component {

    static propTypes = {
        logout: PropTypes.func.isRequired
    }

    render() {
        return (
            <button className="Sidebar-close" onClick={this.props.logout} href="#">Logout</button>
        )
    }
}

export default connect( null, { logout })(Logout);