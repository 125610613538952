import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaPrint, FaDownload, FaForward } from 'react-icons/fa';
import { RenderDealMemo, RenderItinerary, clearPdfs } from '../../actions/pdfActions';

class PdfRender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            pdfindex: 0
        };
    }

    static propTypes = {
        offerdetail: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        pdf: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
    }

    componentDidMount() { 
        var pdftype = this.props.pdftype;
        if (pdftype === 'dealmemo' || pdftype === 'itinerary') {
            var deals = [];
            this.props.offerdetail.offerdetail.map((item, index) => {
                var artistid = item.artist_id;
                var venueid = item.venue_id;
                var buyerid = item.buyer_id;
                var artist = "";

                this.props.clients.clients.map(c => {
                    if (c._id === artistid) {
                        artist = c.client;
                    }
                });
                var venue = [];
                this.props.items.items.map(v => {
                    if (v._id === venueid) {
                        venue = {
                            venue: v.venue_name,
                            address: v.address.address,
                            address2: v.address.address2,
                            city: v.address.city,
                            state: v.address.state,
                            country: v.address.country,
                            postalcode: v.address.postal_code,
                            phone: v.contact.main_phone,
                            website: v.website
                        };

                    };
                });

                var buyer = [];
                this.props.buyers.buyers.map(b => {
                    if (b._id === buyerid) {
                        buyer = {
                            company: b.company,
                            address: b.address,
                            contact: b.contact
                        };
                    };
                });

                deals.push({
                    ...item, venue, buyer, artist
                })
            })

            var i = this.state.pdfindex;
            if (pdftype === "dealmemo") {
                this.props.RenderDealMemo(deals, i);
            } else if (pdftype === "itinerary") {
                this.props.RenderItinerary(deals, i);
            }

        } else {
            this.setState({
                error: true
            })
            alert('Error: no pdf to render')
        }
    }

    componentWillUnmount() {
        this.props.clearPdfs();
    }

    nextPdf = () => {
        if (this.state.pdfindex + 1 < this.state.offerchecked.length) {
            var artist = [];
            var dates = [];
            var deals = [];
            this.props.offerdetail.offerdetail.map((item, index) => {
                deals.push(item);
                var id = item.artist;
                this.props.clients.clients.map(c => {
                    if (c._id === id) {
                        artist = c.client;
                    }
                });
                dates = item.dates
            })
            var i = this.state.pdfindex + 1;
            this.props.Print(deals, i);

            this.setState({
                pdfindex: i,
            })
        } else {

        }
    }

    togglePrint = () => {
        var src = this.props.pdf.pdf[this.state.pdfindex];
        const theWindow = window.open(src);
        const theDoc = theWindow.document;
        const theScript = document.createElement('script');
        function injectThis() {
            window.print();
        }
        theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
        theDoc.body.appendChild(theScript);
    }

    render() {
        var src = this.props.pdf.pdf[this.state.pdfindex];
        //var length = this.props.offerdetail.offerdetail.length;
        //<img src={satellite} width={200} height={200} />
        //console.log(this.props.offerdetail.offerdetail)

        return (
            <div className="mode-s">
                <div className="mode-s-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                        <span className="close" onClick={this.props.close}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                                    </div>
                        </span>
                        <span className="close">
                            <FaPrint size="18" color="#cdcdcd" onClick={this.togglePrint} />
                        </span>
                        <span className="close">
                            <a href={src} target="_blank" rel="noopener noreferrer" download="Artist Deal Memo.pdf">
                                <FaDownload size="18" color="#cdcdcd" />
                            </a>
                        </span>
                        <FaForward size="36" color="#fff" style={{ marginLeft: '2%', paddingTop: 12 }} onClick={this.nextPdf} />
                    </div>
                    <div style={{ width: '100%', height: 600, backgroundColor: '#515659' }}>
                        {!this.props.pdf.loading && !this.state.error ?
                                <iframe
                                    id="FrameID"
                                    title="file"
                                    style={{ width: '100%', height: '100%' }}
                                    key={this.state.pdfindex}
                                    src={src + "#toolbar=0"}
                                /> :
                            !this.props.pdf.loading && this.state.error ?
                                <div style={{ width: '100%', height: '100%', backgroundColor: '#515659' }}>
                                    <h1 style={{ fontSize: 16, padding: 10, color: '#fff', fontWeight: 'bold' }}>Error loading pdf..</h1>
                                </div> :
                                <div style={{ width: '100%', height: '100%', backgroundColor: '#515659' }}>
                                    <h1 style={{ fontSize: 16, padding: 10, color: '#fff', fontWeight: 'bold' }}>Loading..</h1>
                                </div>}
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    offerdetail: state.offerdetail,
    clients: state.clients,
    pdf: state.pdf,
    items: state.items,
    buyers: state.buyers,
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { RenderDealMemo, RenderItinerary, clearPdfs })(PdfRender);