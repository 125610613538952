import {
    CONTACTS_LOADING,
    CONTACTS_GET,
    CONTACTS_FILTER,
    CONTACTS_SORT,
    CONTACTS_SOCKET,
    CONTACTS_SOCKET_DELETE
} from "../actions/types";
import _ from 'lodash';

const initialState = {
    contacts: [],
    filtered_contacts: [],
    filter: {
        last_name: '',
        first_name: '',
        status: '',
        _id: '',
        role: '',
        city: '',
        state: '',
        country: '',
        postal_code: ''
    },
    loading: false,
    sort: {
        cat: 'name.last',
        dir: 'asc'
    }
};

export default function (state = initialState, action) {
    let filtered_data = [...state.filtered_contacts];
    switch (action.type) {
        case CONTACTS_LOADING:
            return {
                ...state,
                loading: true
            };
        case CONTACTS_GET:
            var sortedContacts = _.orderBy(action.payload, [`name.last`.toLowerCase()], ['asc']);
            return {
                ...state,
                contacts: sortedContacts,
                filtered_contacts: sortedContacts,
                loading: false
            };
        case CONTACTS_FILTER:
            var contact_filter = action.payload;
            var contact_data = state.contacts
            if (contact_filter.last_name) {
                contact_data = contact_data.filter((item) => {
                    var filterBatch = item.name.last
                    return filterBatch.indexOf(contact_filter.last_name) >= 0
                })
            }
            if (contact_filter.first_name) {
                contact_data = contact_data.filter((item) => {
                    var filterBatch = item.name.first
                    return filterBatch.indexOf(contact_filter.first_name) >= 0
                })
            }
            if (contact_filter.status) {
                contact_data = contact_data.filter((item) => {
                    var filterBatch = item.status
                    if (item.status) {
                        return filterBatch.indexOf(contact_filter.status) >= 0
                    }
                })
            }
            if (contact_filter._id) {
                contact_data = contact_data.filter((item) => {
                    var filterBatch = item._id
                    return filterBatch.indexOf(contact_filter._id) >= 0
                })
            }
            if (contact_filter.state) {
                contact_data = contact_data.filter((item) => {
                    var filterBatch = item.address.state
                    return filterBatch.indexOf(contact_filter.state) >= 0
                })
            }
            if (contact_filter.country) {
                contact_data = contact_data.filter((item) => {
                    var filterBatch = item.address.country
                    return filterBatch.indexOf(contact_filter.country) >= 0
                })
            }
            var sort = state.sort;
            contact_data = _.orderBy(contact_data, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            return {
                ...state,
                filtered_contacts: contact_data,
                filter: contact_filter,
                loading: false
            };
        case CONTACTS_SORT:
            var sort = action.payload;
            var sortedContacts = _.orderBy(state.filtered_contacts, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            return {
                ...state,
                filtered_contacts: sortedContacts,
                sort: sort,
                loading: false
            };
        // case ADD_CONTACT:
        //     return {
        //         ...state,
        //         //contacts: [action.payload, ...state.contacts],
        //     };
        // case SADD_CONTACT:
        //     var contact_filter = state.filter;
        //     var newArray = [...state.contacts, action.payload];
        //     var contact_data = newArray.filter((item) => {
        //         var filterBatch = item.name.last
        //         return filterBatch.indexOf(contact_filter.last_name) >= 0
        //     })
        //     contact_data = contact_data.filter((item) => {
        //         var filterBatch = item.name.first
        //         return filterBatch.indexOf(contact_filter.first_name) >= 0
        //     })
        //     contact_data = contact_data.filter((item) => {
        //         var filterBatch = item.address.state
        //         return filterBatch.indexOf(contact_filter.state) >= 0
        //     })
        //     contact_data = contact_data.filter((item) => {
        //         var filterBatch = item.address.country
        //         return filterBatch.indexOf(contact_filter.country) >= 0
        //     })
        //     contact_data = _.orderBy(contact_data, [`${state.sort.cat}`.toLowerCase()], [state.sort.dir]);
        //     return {
        //         ...state,
        //         contacts: [action.payload, ...state.contacts],
        //         filtered_contacts: contact_data,
        //     };
        // case UPDATE_CONTACT_SOCKET:
        //     let data = [...state.contacts]
        //     var index = data.findIndex(obj => obj._id === action.payload._id)
        //     data[index] = action.payload

        //     const found = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //     if (found >= 0) {
        //         var filtered_index = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //         filtered_data[filtered_index] = action.payload;
        //     }
        //     return {
        //         ...state,
        //         contacts: data,
        //         filtered_contacts: filtered_data
        //     }
        case CONTACTS_SOCKET:
            var data = state.contacts;
            var f_data = state.filtered_contacts;
            var index = state.contacts.findIndex(obj => obj._id === action.payload._id);
            var f_index = state.filtered_contacts.findIndex(obj => obj._id === action.payload._id);
            if (index >= 0) {
                data[index] = action.payload;
                if (f_index >= 0) {
                    f_data[f_index] = action.payload;
                }
            } else {
                data = [action.payload, ...state.contacts];
                var contact_filter = state.filter;
                f_data = data;
                if(contact_filter.last_name){
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.name.last
                        return filterBatch.indexOf(contact_filter.last_name) >= 0
                    })
                }
                if(contact_filter.firt_name){
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.name.first
                        return filterBatch.indexOf(contact_filter.first_name) >= 0
                    })
                }
                if(contact_filter.state){
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.state
                        return filterBatch.indexOf(contact_filter.state) >= 0
                    })
                }
                if(contact_filter.country){
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.country
                        return filterBatch.indexOf(contact_filter.country) >= 0
                    })
                }
                f_data = _.orderBy(f_data, [`${state.sort.cat}`.toLowerCase()], [state.sort.dir]);
            }
            return {
                ...state,
                contacts: data,
                filtered_contacts: f_data
            }
        // case DELETE_CONTACT:
        //     return {
        //         ...state,
        //         //contacts: state.contacts.filter(contact => contact._id !== action.payload),
        //     };
        case CONTACTS_SOCKET_DELETE:
            return {
                ...state,
                contacts: state.contacts.filter(contact => contact._id !== action.payload._id),
                filtered_contacts: state.filtered_contacts.filter(contact => contact._id !== action.payload._id),
            };
        default:
            return state;
    }
}