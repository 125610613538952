import React, { useEffect, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

export default function PlacesCombo(props) {
  console.log(props)
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    props.pInput(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        var desc = description.split(',')
        setValue(desc[0], false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
          .then(async (results) => {
            const coord = await getLatLng(results[0])
            if(props.pIndex){
              props.pSelect(coord, results, description);
            } else {
              props.pSelect(coord, results, description);
            }
            
          })
          // .then(({ lat, lng }) => {
          //   console.log("📍 Coordinates: ", { lat, lng });
          // })
          .catch((error) => {
            console.log("😱 Error: ", error);
          });
      };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li className="autocompleteMenu" key={place_id} onClick={handleSelect(suggestion)}>
          <strong style={{fontWeight: 'none'}}>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
    
  return (
    <div style={{width: '100%', height: '100%'}} ref={ref}>
      <input
        style={{width: '100%', height: '100%', fontSize: 12}}
        value={!props.tour_venue ? props.tour_venue : value ? value : props.tour_venue}
        onChange={handleInput}
        disabled={!ready} 
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul style={{position: 'absolute', backgroundColor: '#fff', color: '#000', fontSize: '.8vw', listStyleType: 'none', margin: 0, padding: 0 }} >{renderSuggestions()}</ul>}
    </div>
  );
};