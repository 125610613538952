import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    PACKAGING_LOADING,
    PACKAGING_GET,
    PACKAGING_FILTER,
    PACKAGING_SOCKET,
    PACKAGING_SOCKET_DELETE,
    PACKAGING_SORT,
    NOTIFICATIONS
} from "./types";

export const setPackagingLoading = () => {
    return {
        type: PACKAGING_LOADING
    };
};

export const getPackaging = () => (dispatch, getState) => {
    dispatch(setPackagingLoading());
    axios.get(`${process.env.REACT_APP_API}/packaging/`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: PACKAGING_GET,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const addPackaging = packaging => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/packaging/add`, packaging, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //     type: ADD_PACKAGING,
            //     payload: res.data
            // })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const addPackagingSocket = packaging => (dispatch) => {
//     dispatch({
//         type: ADD_PACKAGING_SOCKET,
//         payload: packaging
//     })
// }

export const updatePackaging = (packaging, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/packaging/update/${id}`, packaging, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

// export const updatePackagingSocket = packaging => (dispatch) => {
//     dispatch({
//         type: UPDATE_PACKAGING,
//         payload: packaging
//     })
// }

export const packagingSocket = packaging => (dispatch) => {
    dispatch({
        type: PACKAGING_SOCKET,
        payload: packaging
    })
}

export const deletePackaging = id => (dispatch, getState)=> {
    return axios.delete(`${process.env.REACT_APP_API}/packaging/${id}`, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //     type: DELETE_PACKAGING,
            //     payload: id
            // })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const deletePackagingSocket = packaging => (dispatch) => {
    dispatch({
        type: PACKAGING_SOCKET_DELETE,
        payload: packaging
    })
}

export const applyPackagingFilter = (packaging_filter) => (dispatch, getState) => {
    dispatch(setPackagingLoading());
    dispatch({
        type: PACKAGING_FILTER,
        payload: packaging_filter
    })
};

export const packagingSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: PACKAGING_SORT,
        payload: sort
    })
};