import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { loadSocket, connectOff, loadUsers, getProfileImage, sendChatMessage, logout, removeGmail, connectGmail } from '../actions/authActions';
import { getOffers, getOfferDetail, getOfferDetailItinerary, offerCombine, offerCombineFinal } from '../actions_client/offerActions';
import { getVenues } from '../actions_client/venueActions';
import { getClients } from '../actions_client/clientActions';
import { getBuyers } from '../actions_client/buyerActions';
import { getContacts } from '../actions_client/contactActions';
import { getMarketing } from '../actions_client/marketingActions';
import { getCompanyUsers } from '../actions_client/companyActions';
import { getFestivals } from '../actions_client/festivalActions';
import { getCounts, countCompare } from '../actions_client/countActions';
import { spotifyLogin } from '../actions/spotifyActions';
import { handleProfileSave, handleProfileSaveImg, registerAccount, updateUsername } from '../actions_client/userActions';
import { downloadPdf, setPdfOffer, setPdfCounts, generatePdf } from '../actions/pdfActions';
import { mainListener } from '../helpers/socket-listeners/main';
import NavBar from "../components_client/global/navbar.component";
import { confirmAlert } from 'react-confirm-alert';
import LoadingMain from '../components/global/loading-main';
import RedirectMain from '../components/global/redirect-main';
import LoadingModal from '../components/global/loading-modal';
import OfferModal from '../modals_client/offer.modal';
import ClientModal from '../modals/client.modal';
import BuyerModal from '../modals/buyer.modal';
import VenueModal from '../modals/venue.modal';
import ContactModal from '../modals/contact.modal';
import FestivalModal from '../modals/festival.modal';
import PackagingModal from '../modals/packaging.modal';
import ARModal from '../modals/ar.modal';
import PdfModal from '../modals/pdf.modal';
import RegisterModal from '../components/auth/RegisterModal';
import CountComparison from '../modals/analytics/count-comparison.modal';
import SharedModal from '../modals/solar/shared.modal';
import ChatComponent from '../components/global/chat.component';
import PropTypes from 'prop-types';
import openSocket from 'socket.io-client';
import StatusSnackbar from '../components/global/snackbar.client-entered';
import StatusSnackbarExt from '../components/global/snackbar-ext';
import ErrorSnackbar from '../components/global/snackbar.error';
import DashMenu from '../components_client/dashboard/dash-menu';
import DashMenuShort from '../components_client/dashboard/dash-menu-short';
import SettingsScreen from '../components/dashboard/settings-screen.dash';
// import TemplatesScreen from '../components/dashboard/templates.screen';
import TemplatesScreen from '../pages/TemplatesScreen';
import CalendarScreen from '../components_client/calendar/calendar.screen';
import DatabaseClient from '../pages/DatabaseClient';
import AdminScreen from '../pages/AdminScreen';
import PdfRender from '../components/global/pdf-render';
import MapsModal from '../modals/maps.modal';
import RegistrationDialog from '../components/dialogs/registration.dialog';
import UsernameDialog from '../components/dialogs/username.dialog';
import { matchPath } from 'react-router-dom';

class ClientRoute extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            authenticated: false,
            sidebarOpen: true,
            route: 'missioncontrol',
            subroute: '',
            filter: false,
            usermodal: false,

            client_modal: null,
            render_client_data: [],
            client_checked: [],
            client_all_checked: false,

            offer_modal: null,
            render_offer_data: [],
            offer_checked: [],
            offer_all_checked: false,

            venue_modal: null,
            render_venue_data: [],
            venue_checked: [],
            venue_all_checked: false,

            buyer_modal: null,
            render_buyer_data: [],
            buyer_checked: [],
            buyer_all_checked: false,

            contact_modal: null,
            render_contact_data: [],
            contact_checked: [],
            contact_all_checked: false,

            festival_modal: null,
            render_festival_data: [],
            festival_checked: [],
            festival_all_checked: false,

            counts_modal: null,
            render_counts_data: [],
            counts_checked: [],
            counts_all_checked: false,

            packaging_modal: null,
            render_packaging_data: [],
            packaging_checked: [],
            packaging_all_checked: false,

            ar_modal: null,
            render_ar_data: [],
            ar_checked: [],
            ar_all_checked: false,

            finals_checked: [],
            finals_all_checked: false,

            register_modal: null,
            admin_user_data: [],

            clientchecked: [],
            renderofferdata: [],
            rendervenuedata: [],
            rendercontactdata: [],
            enterfestival: false,
            renderfestival: false,
            renderfestivaldata: [],
            spacechat: false,
            snackbar: false,
            snackbar_ext: false,
            snackbar_error: false,
            snackbartype: "",
            snackbar_message: '',
            contactdata: [],
            clientdata: [],
            venuedata: [],
            buyerdata: [],
            connectedusers: [],
            countcompare: false,
            countcomparedata: [],
            pdfrender: false,
            pdftype: '',
            pdffrom: '',
            socket: {},
            map_data: [],
            cal_tooltip: true,
            socket_loaded: false,
            userMessage: '',
            userMessageText: '',
            userMessageArray: [],
            ss_modal: false,
            sub: 'database',

            user_modal: '',
            user_data: {},
            user_registered: true
            //loading: true
        };
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.onCloseSidebar = this.onCloseSidebar.bind(this);
        this.toggleRenderOffer = this.toggleRenderOffer.bind(this);
        this.toggleRenderClient = this.toggleRenderClient.bind(this);
        this.toggleRenderBuyer = this.toggleRenderBuyer.bind(this);
        this.toggleRenderVenue = this.toggleRenderVenue.bind(this);
        this.toggleRenderContact = this.toggleRenderContact.bind(this);
        this.toggleItineraryPDF = this.toggleItineraryPDF.bind(this);
        this.iconRender = this.iconRender.bind(this);
        this.iconRenderOffer = this.iconRenderOffer.bind(this);
        this.iconRenderVenue = this.iconRenderVenue.bind(this);
        this.iconRenderBuyer = this.iconRenderBuyer.bind(this);
        this.iconRenderContact = this.iconRenderContact.bind(this);
        this.iconRenderClient = this.iconRenderClient.bind(this);
        this.iconRenderFestival = this.iconRenderFestival.bind(this);
        this.iconRenderPackaging = this.iconRenderPackaging.bind(this);
        this.iconRenderUser = this.iconRenderUser.bind(this);
        this.iconRenderAR = this.iconRenderAR.bind(this);
        this.countCompareContext = this.countCompareContext.bind(this);
        this.toggleDealMemoPDFContext = this.toggleDealMemoPDFContext.bind(this);
        this.toggleNewModal = this.toggleNewModal.bind(this);
        this.copyOffer = this.copyOffer.bind(this);
    }

    static propTypes = {
        offers: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        festivals: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        marketing: PropTypes.object.isRequired,
        packaging: PropTypes.object.isRequired,
        ar: PropTypes.object.isRequired,
        pdf: PropTypes.object.isRequired,
    }

    async componentDidMount() {
        if (!this.props.auth.token) { await this.props.logout() };

        this.setState({
            loading: true,
        })
        const need_array = await this.props.getOffers();
        const venue_data = {
            array: need_array.venue_ids
        }
        const buyer_data = {
            array: need_array.buyer_ids
        }
        console.log(need_array)
        const contact_data = {
            array: need_array.contact_ids.filter(c => c !== 'Custom')
        }
        await this.props.getVenues(venue_data);
        await this.props.getBuyers(buyer_data);
        await this.props.getClients();
        await this.props.getContacts(contact_data);
        await this.props.getFestivals(venue_data);
        // await this.props.getCalendarEntries();
        // await this.props.getHolds();
        await this.props.spotifyLogin();
        await this.props.getCompanyUsers();

        // if(this.props.buyers.init_loading || this.props.clients.init_loading || this.props.contacts.init_loading || this.props.festivals.init_loading || this.props.items.init_loading || this.props.offers.init_loading){
        //     this.offerCombine();
        // } else {
        //     await this.props.offerCombine();
        // }
        var arr = await this.props.offerCombine();
        var fin = await this.props.offerCombineFinal(arr);
        this.setState({
            loading: fin
        })

        var user = this.props.auth.user
        const name = user.name.first + " " + user.name.last;
        if (!this.props.auth.socket) {
            const socket = await openSocket(`${process.env.REACT_APP_SOCKET}`, {
                query: {
                    token: localStorage.getItem("token"),
                    name: name,
                    company: user.company.company,
                    tag: user.tag
                },
            });
            socket.on('connect', () => {
                // this.props.mainListener(socket);
                // socket.on("USERS_CONNECTED", async (connectedUsers) => {
                //     // var arrFil = connectedUsers.filter(item => item.id !== socket.id);
                //     // this.props.loadUsers(arrFil);

                //     // const newVals = await this.connect(connectedUsers);
                //     // this.setState({
                //     //     connectedusers: connectedUsers
                //     // })
                // })
                // socket.on("USER_CONNECTED", (user) => {
                //     if (user.id === socket.id) {
                //         console.log('same user')
                //     } else {
                //         var check = this.props.auth.connectedUsers.filter(item => item.id === user.id);
                //         if (check.length <= 0) {
                //             var connectedUsers = [...this.props.auth.connectedUsers, user];
                //             this.props.loadUsers(connectedUsers);
                //             // this.setState({
                //             //     connectedusers: [...this.state.connectedusers, user]
                //             // })
                //         }
                //     }

                //     // this.setState({
                //     //     connectedusers: connectedUsers
                //     // })
                // })
                // socket.on("USER_DISCONNECTED", (user) => {
                //     if (user.id === socket.id) {
                //         console.log('same user')
                //     } else {
                //         var connectedUsers = this.props.auth.connectedUsers.filter(usr => usr.id !== user.id);
                //         this.props.loadUsers(connectedUsers);
                //         // this.setState({
                //         //     connectedusers: this.state.connectedusers.filter(usr => usr.id !== user.id),
                //         // })
                //     }
                //     // this.setState({
                //     //     connectedusers: connectedUsers
                //     // })
                // })

                socket.on("connect_error", (err) => {
                    console.log(`connect_error due to ${err.message}`);
                });
            });
            //this.props.loadSocket(socket);
        }
    };

    componentWillUnmount() {
        if (this.props.auth && this.props.auth.socket) {
            const socket = this.props.auth.socket;
            // const id = socket.id;
            // const company = 'Space Agency Booking'
            // const data = {
            //     id: id,
            //     company: company
            // }
            //socket.emit("manual-disconnection", data)
            socket.disconnect();
            this.props.connectOff();
            this.setState({
                socket_loaded: false
            })
        }
    }

    connect = async (connectedUsers) => {
        var newConn = [];
        connectedUsers.forEach(async (item) => {
            const id = item.tag;
            const img = await this.props.getProfileImage(id);
            newConn = [...newConn, { ...item, img }]
        })
        return newConn
    }

    offerCombine = async () => {
        if (this.props.buyers.init_loading || this.props.clients.init_loading || this.props.contacts.init_loading || this.props.festivals.init_loading || this.props.items.init_loading || this.props.offers.init_loading) {
            setTimeout(() => { this.offerCombine(); }, 2000);
        } else {
            await this.props.offerCombine()
        }
    }

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    onCloseSidebar() {
        this.setState({ sidebarOpen: false });
    }

    toggleFilter = () => {
        this.setState({
            filter: !this.state.filter
        })
    }

    togglePage = (e) => {
        this.setState({
            route: e,
            subroute: '',
            filter: false,
            sidebarOpen: false,
            clientchecked: [],
            buyerchecked: [],
            venuechecked: [],
            contactchecked: [],
            clientchecked: [],
            offerchecked: [],
            renderofferdata: [],
            renderclientdata: [],
            renderbuyerdata: [],
            rendervenuedata: [],
            rendercontactdata: [],
        })
    }

    toggleSub = (e) => {
        this.setState({
            route: "database",
            subroute: e,
            //filter: false,
            sidebarOpen: false,
            clientchecked: [],
            buyerchecked: [],
            venuechecked: [],
            contactchecked: [],
            clientchecked: [],
            offerchecked: [],
            renderofferdata: [],
            renderclientdata: [],
            renderbuyerdata: [],
            rendervenuedata: [],
            rendercontactdata: [],
        });
    }

    iconRender(item) {
        this.setState({
            render_offer_data: item,
            offer_modal: 'render'
        })
    }

    iconRenderUser(item) {
        this.setState({
            admin_user_data: item,
            register_modal: 'render'
        })
    }

    toggleNewModal = (set, data) => {
        if (data) {
            this.setState({
                [set]: 'enter',
                [data]: []
            });
        } else {
            this.setState({
                [set]: 'enter',
            });
        }
    }

    toggleRenderModal(name, set, data) {
        this.setState({
            [set]: data,
            [name]: 'render'
        })
    }

    toggleNewOffer = () => {
        this.setState({
            render_offer: 'enter',
            render_offer_data: []
        });
    };

    toggleUserModal = () => {
        this.setState({
            usermodal: !this.state.usermodal
        });
    };

    closeRenderClient = () => {
        this.setState({
            client_modal: null,
            render_client_data: []
        });
    };

    closeRenderAR = () => {
        this.setState({
            ar_modal: null,
            render_ar_data: []
        });
    };

    toggleRenderClient(e) {
        this.props.clients.clients.map(item => {
            if (this.state.clientchecked.includes(item._id)) {
                const renderclientdata = [{
                    id: item._id,
                    client: item.client,
                    address: item.address,
                    agent: item.agent,
                    website: item.website,
                    facebook: item.facebook,
                    instagram: item.instagram,
                    twitter: item.twitter,
                    youtube: item.youtube,
                    bandcamp: item.bandcamp,
                    spotify: item.spotify,
                    applemusic: item.applemusic,
                    genre: item.genre,
                    status: item.status,
                    image: item.image
                }]
                this.setState({
                    renderclientdata: renderclientdata,
                    renderclient: true
                })
            }
        })
    }

    toggleNewBuyer = () => {
        this.setState({
            buyer_modal: 'enter',
            render_buyer_data: []
        });
    };

    toggleRenderBuyer(e) {
        this.props.buyers.buyers.map(item => {
            if (this.state.buyerchecked.includes(item._id)) {
                const renderbuyerdata = [{
                    id: item._id,
                    company: item.company,
                    address: item.address,
                    website: item.website,
                    facebook: item.facebook,
                    instagram: item.instagram,
                    contact: item.contact,
                    enteredBy: item.enteredBy
                }]
                this.setState({
                    renderbuyerdata: renderbuyerdata,
                    renderbuyer: true

                })
            }
        })
    }

    toggleNewVenue = () => {
        this.setState({
            venue_modal: 'enter',
            render_venue_data: []
        });
    };

    toggleRenderVenue(e) {
        this.props.items.items.map(item => {
            if (this.state.venuechecked.includes(item._id)) {
                const rendervenuedata = [{
                    id: item._id,
                    venuename: item.venuename,
                    address: item.address,
                    address2: item.address2,
                    city: item.city,
                    state: item.state,
                    country: item.country,
                    zipcode: item.zipcode,
                    capacity: item.capacity,
                    website: item.website,
                    phonenumber: item.phonenumber,
                    faxnumber: item.faxnumber,
                }]
                this.setState({
                    rendervenuedata: rendervenuedata,
                    rendervenue: true

                })
            }
        })
    }

    iconRenderVenue(item) {
        this.setState({
            render_venue_data: item,
            venue_modal: 'render'
        })
    }

    iconRenderBuyer(item) {
        this.setState({
            render_buyer_data: item,
            buyer_modal: 'render'
        })
    }

    toggleNewContact = () => {
        this.setState({
            contact_modal: 'enter',
            render_contact_data: []
        });
    };

    toggleRenderContact(e) {
        this.props.contacts.contacts.map(item => {
            if (this.state.contactchecked.includes(item._id)) {
                const rendercontactdata = [{
                    id: item._id,
                    company: item.company,
                    role: item.role,
                    address: item.address,
                    capacity: item.capacity,
                    website: item.website,
                    phonenumber: item.phonenumber,
                    faxnumber: item.faxnumber,
                    tags: item.tags
                }]
                this.setState({
                    rendercontactdata: rendercontactdata,
                    rendercontact: true

                })
            }
        })
    }

    toggleModal = (e) => {
        this.setState({
            [e]: !e
        })
    }

    toggleNewFestival = () => {
        this.setState({
            festival_modal: 'enter',
            render_festival_data: []
        });
    };

    toggleRenderFestival(e) {
        this.props.festivals.items.map(item => {
            if (this.state.contactchecked.includes(item._id)) {
                const renderfestivaldata = [{
                    id: item._id,
                    company: item.company,
                    role: item.role,
                    address: item.address,
                    address2: item.address2,
                    city: item.city,
                    state: item.state,
                    country: item.country,
                    zipcode: item.zipcode,
                    capacity: item.capacity,
                    website: item.website,
                    phonenumber: item.phonenumber,
                    faxnumber: item.faxnumber,
                }]
                this.setState({
                    renderfestivaldata: renderfestivaldata,
                    rendercontact: true

                })
            }
        })
    }

    toggleNewPackaging = () => {
        this.setState({
            enterpackaging: !this.state.enterpackaging
        });
    };

    togglePackagingModal = () => {
        this.setState({
            packaging_modal: null,
            render_packaging_data: []
        });
    };

    // toggleRegisterModal = () => {
    //     this.setState({
    //         register_modal: null,
    //         render_user_data: []
    //     });
    // };

    toggleCountCompare = () => {
        this.setState({
            countcompare: !this.state.countcompare
        })
    }

    iconRenderContact(item) {
        this.setState({
            render_contact_data: item,
            contact_modal: 'render'
        })
    }

    iconRenderClient(item) {
        this.setState({
            render_client_data: item,
            client_modal: 'render'
        })
    }

    iconRenderPackaging(item) {
        this.setState({
            packaging_modal: 'render',
            render_packaging_data: item
        })
    }

    iconRenderAR(item) {
        this.setState({
            ar_modal: 'render',
            render_ar_data: item
        })
    }

    countCompareContext(e) {
        var newArray = [];
        var idArray = [];
        this.props.offers.offers.map(item => {
            if (this.state.offer_checked.includes(item._id)) {
                var _id = item._id
                idArray.push({ _id }, ...idArray);
                const countcomparedata = [{
                    id: item._id,
                    artist: item.client_id,
                    dates: item.show_info,
                    venueid: item.venue_id,
                    buyerid: item.buyer_id,
                }]
                newArray.push(...countcomparedata);
            }
        })
        this.setState({
            countcomparedata: newArray,
            countcompare: true
        })
        const ids = {
            _id: idArray
        }
        this.props.countCompare(ids);
    }

    toggleRenderOffer(e) {
        var allData = [];
        this.props.offers.offers.map(item => {
            if (this.state.offer_checked.includes(item._id)) {
                allData.push(item);
            }
        })
        if (allData.length > 0) {
            this.setState({
                render_offer_data: allData[0],
                offer_modal: 'render'
            })
        } else {
            alert('Error: No deal to render')
        }
    }

    iconRenderOffer(item) {
        this.props.getOfferDetail(item._id)
        this.props.getMarketing(item._id)
        this.props.getCounts(item._id)

        //var showtimes; if(!item.showtimes){ showtimes = [] } else { showtimes = item.showtimes };
        //var scaling; if(!item.ticketscaling){ scaling = [] } else { scaling = item.ticketscaling };
        //var taxes; if(!item.taxes){ taxes = [] } else { taxes = item.taxes };
        //var expenses; if(!item.expenses){ expenses = [] } else { expenses = item.expenses };
        //var bonuses; if(!item.bonuses){ bonuses = [] } else { bonuses = item.bonuses };
        //var deposits; if(!item.deposits){ deposits = [] } else { deposits = item.deposits };

        this.setState({
            renderofferdata: item,
            renderoffer: true
        })
    }

    iconRenderFestival(item) {
        this.setState({
            render_festival_data: item,
            festival_modal: 'render'
        })
    }

    toggleDealMemoPDFContext(props) {
        var venueid = [];
        var buyerid = [];
        var contactid = [];
        var clientid = [];

        this.props.offers.offers.map(item => {
            if (props === item._id) {
                this.props.getOfferDetail(item._id)
                venueid = item.venueid
                buyerid = item.buyerid
                contactid = item.buyername
                clientid = item.artist
            }
        })

        this.props.items.items.map(venue => {
            if (venueid === venue._id) {
                const venuearray = {
                    venuename: venue.venuename,
                    address: venue.address,
                    address2: venue.address2,
                    city: venue.city,
                    state: venue.state,
                    zipcode: venue.zipcode,
                    country: venue.country,
                    phone: venue.phonenumber
                }
                this.setState({
                    venuedata: venuearray
                })
            }
        })
        this.props.buyers.buyers.map(buyer => {
            if (buyerid === buyer._id) {
                const buyerarray = {
                    company: buyer.company,
                    address: buyer.address,
                }
                this.setState({
                    buyerdata: buyerarray
                })
            }
        })
        this.props.contacts.contacts.map(contact => {
            if (contactid === contact._id) {
                const contactarray = {
                    firstname: contact.first_name,
                    lastname: contact.last_name,
                    email: contact.contact.email,
                    officephone: contact.contact.office_phone,
                    cellphone: contact.contact.cell_phone,
                }
                this.setState({
                    contactdata: contactarray
                })
            }
        })
        this.props.clients.clients.map(client => {
            if (clientid === client._id) {
                const clientarray = {
                    client: client.client,
                }
                this.setState({
                    clientdata: clientarray
                })
            }
        }
        )
        this.setState({
            dealmemopdf: true,
            render_pdf_data: props
        })
    }

    closeDealMemoPDF = () => {
        this.setState({
            dealmemopdf: false
        })
    }

    closeContractPDF = () => {
        this.setState({
            contractpdf: false
        })
    }

    closeItineraryPDF = () => {
        this.setState({
            itinerarypdf: false
        })
    }

    toggleItineraryPDF(e) {
        var idArray = [];
        this.props.offers.offers.map(item => {
            if (this.state.offerchecked.includes(item._id)) {
                var _id = item._id
                idArray.push({ _id }, ...idArray);
            }
        })
        const ids = {
            _id: idArray
        }
        this.props.getOfferDetailItinerary(ids);
        this.setState({
            itinerarypdf: true
        })
    }

    closeOfferModal = () => {
        this.setState({
            offer_modal: null,
            render_offer_data: [],
        })
    }

    closeBuyerModal = () => {
        this.setState({
            buyer_modal: null,
            render_buyer_data: []
        })
    }

    closeVenueModal = () => {
        this.setState({
            venue_modal: null,
            render_venue_data: []
        })
    }

    closeContactModal = () => {
        this.setState({
            contact_modal: null,
            render_contact_data: []
        })
    }

    closeFestivalModal = () => {
        this.setState({
            festival_modal: null,
            render_festival_data: []
        })
    }

    deleteDialog = (checked, name) => {
        if (this.state[checked].length > 0) {
            const addDialog = ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-z2">
                        <div style={{ width: '100%', height: '100%', padding: 15 }}>
                            <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Delete {name}</h1>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                Are you sure you'd like to delete the select {name}(s)?
                            </div>
                            <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                                <button style={{ borderRadius: 10 }} onClick={() => { this.delete(name); onClose(); }}>Yes</button>
                                <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={onClose}>No</button>
                            </div>
                        </div>
                    </div>
                )
            }
            const options = {
                closeOnEscape: true,
                closeOnClickOutside: true,
                customUI: addDialog
            }
            confirmAlert(options)
        } else {
            alert("No offers selected -- please select an offer to delete")
        }
    }

    delete = (name) => {
        if (name === 'offer') {
            
        } else if (name === 'client') {
            
        } else if (name === 'venue') {
            
        } else if (name === 'buyer') {
            
        } else if (name === 'contact') {
            
        } else if (name === 'festival') {
            
        } else if (name === 'packaging') {
            
        } else if (name === 'ar') {
            
        }
    }

    toggleSpaceChat = () => {
        this.setState({
            spacechat: !this.state.spacechat
        })
    }
    toggle = (s) => {
        this.setState({
            [s]: !this.state[s],
        })
    }
    toggleStatusSnackbar = (m) => {
        this.setState({
            snackbar_ext: false,
            snackbar_error: false,
            snackbar: !this.state.snackbar,
            snackbar_message: m,
        })
    }
    toggleExtSnackbar = (m) => {
        this.setState({
            snackbar_error: false,
            snackbar: false,
            snackbar_ext: !this.state.snackbar_ext,
            snackbar_message: m,
        })
    }
    toggleErrorSnackbar = (m) => {
        this.setState({
            snackbar_ext: false,
            snackbar: false,
            snackbar_error: !this.state.snackbar_error,
            snackbar_message: m,
        })
    }
    closeSnackbar = () => {
        this.setState({
            snackbar: false,
            snackbar_error: false,
            snackbar_ext: false,
            snackbar_ext_error: false,
            snackbartype: "",
            snackbar_message: ''
        })
    }
    toggleClientSnackbar = () => {
        this.setState({
            snackbar: !this.state.snackbar,
            snackbartype: "Client Entered"
        })
    }
    toggleOfferUpdatedSnackbar = () => {
        this.setState({
            snackbar: !this.state.snackbar,
            snackbartype: "Offer Updated"
        })
    }

    togglePdf = (e, s) => {
        if (s === 'finals') {
            if (this.state.finals_checked.length > 0) {
                this.setState({
                    pdfrender: !this.state.pdfrender,
                    pdftype: e,
                    pdffrom: s
                });
            } else {
                alert("Please select a deal to render.");
            }
        } else if (s === 'clients') {
            if (this.state.client_checked.length > 0) {
                this.setState({
                    pdfrender: !this.state.pdfrender,
                    pdftype: e,
                    pdffrom: s
                });
            } else {
                alert("Please select a client.");
            }
        } else {
            if (this.state.offer_checked.length > 0) {
                this.setState({
                    pdfrender: !this.state.pdfrender,
                    pdftype: e,
                    pdffrom: s
                });
            } else {
                alert("Please select a deal to render.");
            }
        }
    }

    downloadPdf = async (e, s, d) => {
        const pdfType = e;
        var data_array = [];
        if (s === 'clients') {
            this.toggleExtSnackbar('Generating pitch deck...')
            this.state.client_checked.forEach(item => {
                const cIndex = this.props.clients.clients.findIndex(cli => cli._id === item);
                if (cIndex >= 0) {
                    var client = this.props.clients.clients[cIndex];
                    data_array = [...data_array, client]
                }
            })

            const pdfData = {
                pdfData: data_array,
                addData: d
            }
            const gen = await this.props.downloadPdf(pdfData, pdfType);
            if (gen) {
                console.log('gen true')
                this.toggleStatusSnackbar('Pdf downloaded successfully')
            } else {
                this.toggleErrorSnackbar('Error downloading pdf')
            }

        } else {
            const dir = 'download';
            this.toggleExtSnackbar('Downloading...')
            if (e === 'dealmemo' || e === 'contract') {
                var newArray = await this.props.setPdfOffer(pdfType, this.state.offer_checked);
                newArray.map(async (item, index) => {
                    var pdfData = {
                        pdfData: item
                    }
                    var gen = await this.props.generatePdf(pdfData, pdfType, dir);
                    if (gen && newArray.length === index + 1) {
                        console.log('gen true')
                        this.toggleStatusSnackbar('Downloaded successfully')
                    }
                    // else {
                    //     this.toggleErrorSnackbar('Error downloading pdf')
                    // }
                })
            } else if (e === 'counts') {
                var newArray = await this.props.setPdfCounts(pdfType, this.state.offer_checked);
                const gen = await this.props.generatePdf(newArray, pdfType, dir);
                if (gen) {
                    console.log('gen true')
                    this.toggleStatusSnackbar('Downloaded successfully')
                }
            } else {
                var newArray = await this.props.setPdfOffer(pdfType, this.state.offer_checked);
                const pdfData = {
                    pdfData: newArray
                }
                const gen = await this.props.generatePdf(pdfData, pdfType, dir);
                if (gen) {
                    console.log('gen true')
                    this.toggleStatusSnackbar('Downloaded successfully')
                }
            }
        }

    }

    togglePdfSave = (e) => {
        if (this.state.offer_checked.length > 0) {
            var idArray = [];
            this.props.offers.offers.map(item => {
                if (this.state.offer_checked.includes(item._id)) {
                    var _id = item._id
                    idArray.push({ _id }, ...idArray);
                }
            });
            const ids = {
                _id: idArray
            };
            this.props.getOfferDetailItinerary(ids);
            this.setState({
                pdfrender: !this.state.pdfrender,
                pdftype: e
            });
        } else {
            alert("Please select a deal to render.");
        }
    }

    closePdf = () => {
        this.setState({
            pdfrender: !this.state.pdfrender,
            pdftype: ''
        });
    }

    formatDate = (date) => {

        const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = new Date(date);
        const day = days[d.getDay()];
        const mon = monthNames[d.getMonth()];
        const time = `${day}. ${mon} ${d.getDate()}, ${d.getFullYear()}`;
        return time;
    }

    handleSearchbarChange = (props) => {
        var data = props;
        if (props.client) {
            this.toggleRenderModal('client_modal', 'render_client_data', data)
        } else if (props.venue_name) {
            this.toggleRenderModal('venue_modal', 'render_venue_data', data)
        } else if (props.company) {
            this.toggleRenderModal('buyer_modal', 'render_buyer_data', data)
        }
    }

    toggleSsModal = () => {
        this.setState({
            ss_modal: !this.state.ss_modal
        })
    }

    handleDatabaseCheckbox = (e) => {
        var n = e.target.name;
        var item = e.target.value;
        if (this.state[n].includes(item)) {
            const items = this.state[n].filter(i => i !== item);
            this.setState({
                [n]: items
            })
        } else {
            const items = []
            items.push(...this.state[n], item)
            this.setState({
                [n]: items
            });
        };
    }

    handleDatabaseAllCheckbox = (e) => {
        var n = e.target.name;
        var all_sub = "";
        if (n === 'offer_checked') {
            all_sub = "offer_all_checked"
        } else if (n === 'venue_checked') {
            all_sub = "venue_all_checked"
        } else if (n === 'buyer_checked') {
            all_sub = "buyer_all_checked"
        } else if (n === 'contact_checked') {
            all_sub = "contact_all_checked"
        } else if (n === 'client_checked') {
            all_sub = "client_all_checked"
        } else if (n === 'festival_checked') {
            all_sub = "festival_all_checked"
        } else if (n === 'ar_checked') {
            all_sub = "ar_all_checked"
        } else if (n === 'packaging_checked') {
            all_sub = "packaging_all_checked"
        } else if (n === 'counts_checked') {
            all_sub = "counts_all_checked"
        }
        if (!this.state[all_sub]) {
            const items = []
            var all = [];
            if (n === 'offer_checked') {
                all = this.props.offers.filtered_offers
            } else if (n === 'venue_checked') {
                all = this.props.items.filtered_venues
            } else if (n === 'buyer_checked') {
                all = this.props.buyers.filtered_buyers
            } else if (n === 'contact_checked') {
                all = this.props.contacts.filtered_contacts
            } else if (n === 'client_checked') {
                all = this.props.clients.filtered_clients
            } else if (n === 'festival_checked') {
                all = this.props.festivals.filtered_festivals
            } else if (n === 'ar_checked') {
                all = this.props.ar.filtered_ar
            } else if (n === 'packaging_checked') {
                all = this.props.packaging.filtered_packaging
            } else if (n === 'counts_checked') {
                all = this.props.counts.counts_rol_filtered
            }
            if (n === 'counts_checked') {
                all.forEach(val => items.push(val._id + ", " + val.date_id))
            } else {
                all.forEach(val => items.push(val._id))
            }
            this.setState({
                [n]: items,
                [all_sub]: true
            })
        } else {
            this.setState({
                [n]: [],
                [all_sub]: false
            })
        }
    }

    handleDatabaseClearCheckbox = (n) => {
        var all_sub = "";
        if (n === 'offer_checked') {
            all_sub = "offer_all_checked"
        } else if (n === 'venue_checked') {
            all_sub = "venue_all_checked"
        } else if (n === 'buyer_checked') {
            all_sub = "buyer_all_checked"
        } else if (n === 'contact_checked') {
            all_sub = "contact_all_checked"
        } else if (n === 'client_checked') {
            all_sub = "client_all_checked"
        } else if (n === 'festival_checked') {
            all_sub = "festival_all_checked"
        } else if (n === 'packaging_checked') {
            all_sub = "packaging_all_checked"
        } else if (n === 'ar_checked') {
            all_sub = "ar_all_checked"
        } else if (n === 'counts_checked') {
            all_sub = "counts_all_checked"
        }
        this.setState({
            [n]: [],
            [all_sub]: false
        })
    }

    toggleGeoMap = () => {
        var newArray = [];
        this.props.offers.offers.map(item => {
            if (this.state.offer_checked.includes(item._id)) {
                const countcomparedata = [{
                    id: item._id,
                    artist: item.client_id,
                    dates: item.show_info,
                    venueid: item.venue_id,
                    buyerid: item.buyer_id,
                }]
                newArray.push(...countcomparedata);
            }
        })
        this.setState({
            map_data: newArray,
            map_geo: true
        })
    }

    closeGeoMap = () => {
        this.setState({
            map_geo: false,
            map_data: []
        })
    }

    copyOffer(e) {
        var allData = [];
        this.props.offers.offers.map(item => {
            if (e === "offers_db") {
                if (this.state.offer_checked.includes(item._id)) {
                    allData.push(item);
                }
            } else if (e === "finals_db") {
                if (this.state.finals_checked.includes(item._id)) {
                    allData.push(item);
                }
            }
        })
        if (allData.length > 0) {
            this.setState({
                render_offer_data: allData[0],
                offer_modal: 'copy'
            })
        } else {
            alert('Error: No deal to render')
        }
    }

    subSelect = (val) => {
        this.setState({
            sub: val
        })
    }

    dbSelect = (val) => {
        this.setState({
            route: "database",
            subroute: val,
            //filter: false,
            sidebarOpen: false,
            clientchecked: [],
            buyerchecked: [],
            venuechecked: [],
            contactchecked: [],
            clientchecked: [],
            offerchecked: [],
            renderofferdata: [],
            renderclientdata: [],
            renderbuyerdata: [],
            rendervenuedata: [],
            rendercontactdata: [],
        });
    }

    removeGmail = () => {
        this.props.removeGmail();
    }

    connectGmail = () => {
        this.props.connectGmail();
    }

    toggleUserModal = (user) => {
        if (!this.state.user_modal) {
            this.setState({
                user_modal: 'render',
                user_data: user
            })
        } else {
            this.setState({
                user_modal: '',
                user_data: {}
            })
        }
    }

    onProfileSave = (name, username, address, contact, file) => {

        if (file) {
            var _p = {
                type: file.type,
                name: file.name,
                date: new Date(),
                key: this.props.auth.user.tag
            }
            const data = {
                name: name,
                username: username,
                address: address,
                contact: contact,
                tag: this.props.auth.user.tag,
                _p: _p
            }
            this.props.handleProfileSaveImg(file, data);
        } else {
            const data = {
                name: name,
                username: username,
                address: address,
                contact: contact,
                tag: this.props.auth.user.tag,
            }
            this.props.handleProfileSave(data);
        }
    }

    sendTcEmails = async (type) => {
        var offers = this.props.offers.offers;
        offers = offers.filter((item) => {
            const today = new Date();
            var val = _.get(item, 'show_info')
            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            var filterBatch = new Date(closest.date)
            var start = today
            return filterBatch >= start
        })
        offers = offers.filter((item) => {
            var filterBatch = item.count_config.active
            return filterBatch === true
        })

        var emailArray = [];
        if (type === 'all') {
            var ren = [];
            // for(i = 0; i <= this.props.offer.offers.length; i++){
            //     for(v = 0; v <= this.props.offers.offers.length; i++){

            //     }
            // }
            // this.props.offer.offers.forEach(item => {
            //     var newObj = {

            //     }
            // })

            // this.props.offers.offers.reduce((acc, curr) => {
            //     const { key, buyer_id, venue_id } = curr;
            // })

            var mapObj = new Map();
            offers.forEach((item) => {
                if (item.count_config.active) {
                    const k = JSON.stringify(item.count_config.contacts);
                    if (!mapObj.has(k)) {
                        mapObj.set(k, item)
                    } else {
                        // map.values(item)
                        var vals = mapObj.get(k);
                        // mapObj.delete(k)
                        if (vals.length > 0) {
                            mapObj.set(k, [...vals, item])
                        } else {
                            mapObj.set(k, [vals, item])
                        }

                        // mapObj.get(k).push(item)
                    }
                }

                // return map;
            })

            const values = Array.from(mapObj.values());
            const keys = Array.from(mapObj.keys());
            const filValues = values.filter((item, index) => keys[index] !== "[]");

            filValues.forEach((item, index) => {
                var shows = [];
                var contacts = [];
                if (item.length > 1) {
                    item[0].count_config.contacts.forEach(con => {
                        const con_id = con._id;
                        const con_index = this.props.contacts.contacts.findIndex(i => i._id === con_id);
                        const con_email = this.props.contacts.contacts[con_index].contact.email;
                        contacts = [...contacts, con_email];
                    })
                    item.map(show => {
                        const single = show.artist_name + " - " + show.venue_name + " - " + show.venue_city + ", " + show.venue_state;
                        shows = [...shows, single]
                    })
                } else {
                    item.count_config.contacts.forEach(con => {
                        const con_id = con._id;
                        const con_index = this.props.contacts.contacts.findIndex(i => i._id === con_id);
                        const con_email = this.props.contacts.contacts[con_index].contact.email;
                        contacts = [...contacts, con_email];
                    })
                    const single = item.artist_name + " - " + item.venue_name + " - " + item.venue_city + ", " + item.venue_state;
                    shows = [...shows, single]
                }
                var showsArr = Object.values(shows);
                var contactsArr = Object.values(contacts);
                // Object.values(shows).map((ite, ind) => {
                //     showsArr = [...showsArr, ite]
                // })
                // Object.values(contacts).map((ite, ind) => {
                //     contactsArr = [...contactsArr, ite]
                // })
                const email = {
                    shows: showsArr,
                    contacts: contactsArr
                }
                emailArray.push(email);
                // console.log(Object.keys(shows), Object.keys(contacts), Object.values(shows), Object.values(contacts))
            })
            // const val = Array.from(this.props.offers.offers.reduce(function (map, item) {
            //     const k = item.counts_config.contacts.toString();
            //     if (!map.has(k)) {
            //         map.set(k, item)
            //     } else {
            //         // map.values(item)
            //         map.get(k).push(item)
            //     }
            //     return map;
            // }, new Map()).values());
        }
        const data = {
            email: emailArray
        }
    }

    registerAccount = (data) => {
        this.props.registerAccount(data);
    }

    createUsername = (data) => {
        this.props.updateUsername(data);
    }

    render() {

        return (
            <div style={{ minWidth: 1300, width: '100%', height: '100%', backgroundColor: '#111', margin: 0, position: 'absolute' }}>
                <RegistrationDialog open={!this.props.auth.user._init} register={this.registerAccount}/>
                <UsernameDialog open={this.props.auth.user._init && !this.props.auth.user.username} register={this.createUsername} />
                <div style={{ width: '100%', height: '100%' }}>
                    {!this.props.auth.isAuthenticated ? <RedirectMain /> :
                        this.state.loading ? <LoadingMain /> :
                            <div>
                                {this.props.offerdetail.loading || this.props.marketing.loading ? <LoadingModal /> : null}
                                {this.state.offer_modal ? <OfferModal render_offer_data={this.state.render_offer_data} close={this.closeOfferModal} type={this.state.offer_modal} toggleNewModal={this.toggleNewModal} toggleStatusSnackbar={this.toggleStatusSnackbar} toggleErrorSnackbar={this.toggleErrorSnackbar} /> : null}
                                {this.state.client_modal ? <ClientModal close={this.closeRenderClient} toggleStatusSnackbar={this.toggleStatusSnackbar} toggleErrorSnackbar={this.toggleErrorSnackbar} type={this.state.client_modal} render_client_data={this.state.render_client_data} /> : null}
                                {this.state.countcompare && !this.props.counts.loading ? <CountComparison toggle={this.toggleCountCompare} countdata={this.props.counts.compare} countcomparedata={this.state.countcomparedata} /> : null}
                                {this.state.pdfrender_notshown && !this.props.offerdetail.loading ? <PdfRender close={this.closePdf} pdftype={this.state.pdftype} /> : null}
                                {this.state.pdfrender ? <PdfModal close={this.closePdf} pdftype={this.state.pdftype} offer_checked={this.state.pdffrom === 'finals' ? this.state.finals_checked : this.state.offer_checked} client_checked={this.state.client_checked} /> : null}
                                {this.state.map_geo ? <MapsModal close={this.closeGeoMap} data={this.state.map_data} /> : null}
                                <NavBar onSetSidebarOpen={this.onSetSidebarOpen} onCloseSidebar={this.onCloseSidebar} logout={this.props.logout} togglePdf={this.togglePdf} toggleUserModal={this.toggleUserModal} toggleSpaceChat={this.toggleSpaceChat} toggleFilter={this.toggleFilter} togglePage={this.togglePage} toggleSub={this.toggleSub} toggleNewOffer={this.toggleNewOffer} toggleRenderOffer={this.toggleRenderOffer} toggleNewModal={this.toggleNewModal} toggleRenderClient={this.toggleRenderClient} toggleNewBuyer={this.toggleNewBuyer} toggleRenderBuyer={this.toggleRenderBuyer} toggleNewVenue={this.toggleNewVenue} toggleRenderVenue={this.toggleRenderVenue} toggleNewContact={this.toggleNewContact} toggleRenderContact={this.toggleRenderContact} toggleModal={this.toggleModal} clients={this.props.clients.clients} venues={this.props.items.items} buyers={this.props.buyers.buyers} user={this.props.auth.user} handleSearchbarChange={this.handleSearchbarChange} toggle={this.toggle} {...this.state} />
                                <StatusSnackbar open={this.state.snackbar} closeSnackbar={this.closeSnackbar} message={this.state.snackbar_message} />
                                <StatusSnackbarExt open={this.state.snackbar_ext} closeSnackbar={this.closeSnackbar} message={this.state.snackbar_message} />
                                <ErrorSnackbar open={this.state.snackbar_error} closeSnackbar={this.closeSnackbar} message={this.state.snackbar_message} />
                                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }} >
                                    <div style={{ width: this.state.sidebarOpen ? '18%' : '5%' }}>
                                        {this.state.sidebarOpen ?
                                            <DashMenu subSelect={this.subSelect} dbSelect={this.dbSelect} sub={this.state.sub} admin={false} /> :
                                            <DashMenuShort subSelect={this.subSelect} sub={this.state.sub} admin={false} />}
                                    </div>
                                    <div style={{ width: this.state.sidebarOpen ? '82%' : '95%' }}>
                                        {this.state.sub === 'templates' ?
                                            <TemplatesScreen /> :
                                            this.state.sub === 'settings' ?
                                                <SettingsScreen sidebarOpen={this.sidebarOpen} onChange={this.handleChange} onChangeObj={this.handleChangeObj} subSelect={this.subSelect} company={this.props.auth.user.company} user={this.props.auth.user} setUserPhoto={this.setUserPhoto} edit={this.state.profile_edit} toggleProfileEdit={this.toggleProfileEdit} onProfileCancel={this.onProfileCancel} onProfileSave={this.onProfileSave} user_name={this.props.auth.user.address || { address: '', address2: '', city: '', state: '', postal_code: '', country: '' }} user_address={this.state.user_address} user_contact={this.props.auth.user.contact} connectGmail={this.connectGmail} removeGmail={this.removeGmail} gmail={this.props.auth.user.google_connect} email={this.props.auth.user.email} {...this.state} /> :
                                                this.state.sub === 'calendar' ?
                                                    <CalendarScreen toggleFilter={this.toggleFilter} iconRender={this.iconRender} toggleNewModal={this.toggleNewModal} {...this.state} /> :
                                                        <DatabaseClient sidebarOpen={this.sidebarOpen} handleDatabaseAllCheckbox={this.handleDatabaseAllCheckbox} handleDatabaseClearCheckbox={this.handleDatabaseClearCheckbox} toggleSub={this.toggleSub} iconRenderOffer={this.iconRender} toggleDealMemoPDFContext={this.toggleDealMemoPDFContext} toggleItineraryPDF={this.toggleItineraryPDF} countCompareContext={this.countCompareContext} handleDatabaseCheckbox={this.handleDatabaseCheckbox} iconRenderVenue={this.iconRenderVenue} iconRenderBuyer={this.iconRenderBuyer} iconRenderContact={this.iconRenderContact} iconRenderClient={this.iconRenderClient} iconRenderFestival={this.iconRenderFestival} iconRenderPackaging={this.iconRenderPackaging} iconRenderAR={this.iconRenderAR} toggleRenderOffer={this.toggleRenderOffer} togglePdf={this.togglePdf} downloadPdf={this.downloadPdf} copyOffer={this.copyOffer} toggleGeoMap={this.toggleGeoMap} toggleFilter={this.toggleFilter} filter={this.props.filter} toggleNewModal={this.toggleNewModal} {...this.state} toggleExtSnackbar={this.toggleExtSnackbar} toggleStatusSnackbar={this.toggleStatusSnackbar} toggleErrorSnackbar={this.toggleErrorSnackbar} deleteDialog={this.deleteDialog} sendTcEmails={this.sendTcEmails} />}
                                    </div>
                                </div>
                            </div>}
                </div>
            </div>
        );
    };
};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) { return item[property]; };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const mapStateToProps = state => ({
    offers: state.offers,
    items: state.items,
    buyers: state.buyers,
    clients: state.clients,
    contacts: state.contacts,
    offerdetail: state.offerdetail,
    marketing: state.marketing,
    counts: state.counts,
    festivals: state.festivals,
    packaging: state.packaging,
    ar: state.ar,
    auth: state.auth,
    pdf: state.pdf,
    company: state.company,
});

export default connect(mapStateToProps, { loadSocket, connectOff, loadUsers, logout, getProfileImage, spotifyLogin, getOffers, getOfferDetailItinerary, offerCombine, getVenues, getBuyers, getClients, getContacts, getOfferDetail, getMarketing, getCounts, countCompare, getFestivals, getCompanyUsers, mainListener, sendChatMessage, removeGmail, connectGmail, handleProfileSave, handleProfileSaveImg, downloadPdf, setPdfOffer, setPdfCounts, generatePdf, offerCombineFinal, registerAccount, updateUsername })(ClientRoute);