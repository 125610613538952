import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function ContractGenDialog(props) {
    const [stateArtist, setStateArtist] = useState('');
    const [addendumSelect, setAddendumSelect] = useState('');
    const [customCheckbox, setCustomCheckbox] = useState(false);

    useEffect(() => {
        if (props.addendum) {
            const ind = props.addendum.findIndex(item => item.default);
            if (ind >= 0) {
                setAddendumSelect(props.addendum[ind].name)
            }
        }
    }, [])

    const cancelDialog = () => {
        props.onClose();
    };

    const handleCustomCheckbox = (rec) => {
        setCustomCheckbox(!customCheckbox)
    }

    const create = () => {
        const options = {

        }
        props.generate(options);
    }

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={props.onClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Create Contract</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: 500, marginBottom: 30, marginTop: 20 }}>
                    <div style={{ width: '100%', height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Addendum:</h2>
                        </span>
                        <select style={{ width: '80%', height: 30, fontSize: 12 }} name="addendumSelect" value={addendumSelect} onChange={(e) => setAddendumSelect(e.target.value)}>
                            <option value=""> </option>
                            {props.addendum.map((item, index) => {
                                return (
                                    <option value={item.name}>{item.default ? item.name + " (default)" : item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div style={{ width: 500, height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Custom:</h2>
                        </span>
                        <div style={{ width: '80%' }}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                color="default"
                                checked={customCheckbox}
                                onChange={handleCustomCheckbox}
                            />
                        </div>
                    </div>
                    {customCheckbox ?
                        <div style={{ width: '100%', height: 30, marginTop: 10, display: 'flex', flexDirection: 'row', borderTop: '1px solid #000' }}>
                            <span style={{ width: '20%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                                <h2 style={{ fontSize: 10 }}>Artist Name:</h2>
                            </span>
                            <input style={{ width: '80%', height: 30, fontSize: 12 }} type="text" name="stateArtist" value={stateArtist} onChange={(e) => setStateArtist(e.target.value)} />
                        </div> : null}
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={cancelDialog}>Cancel</Button>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={create}>Create</Button>
            </DialogActions>
        </Dialog>
    );
}