import {
    CALENDAR_LOADING,
    CALENDAR_GET,
    CALENDAR_SOCKET,
    CALENDAR_SOCKET_DELETE,
    CALENDAR_SET,
    CALENDAR_RESET,
    CALENDAR_FILTER,
    CALENDAR_FILTER_SUB
} from "../actions/types";

const initialState = {
    entries: [],
    events: [],
    events_filtered: [],
    loading: false,
    filter_check: ['confirmed', 'pending', 'holds', 'cal_entries'],
    filter: { client: [""], agent: [""] }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CALENDAR_LOADING:
            return {
                ...state,
                loading: true
            };
        case CALENDAR_GET:
            return {
                ...state,
                entries: action.payload,
                loading: false
            };
        // case ADD_CAL_ENTRY:
        //     return {
        //         ...state,
        //         //contacts: [action.payload, ...state.contacts],
        //     };
        case CALENDAR_SET: 
            var options = state.filter_check;
            //var filterKeys = Object.values(options);
            var events = [];
            action.payload.map((item, index) => {
                var ofs;
                options.forEach(eachKey => {
                    if (item.fil.toLowerCase() === eachKey) {
                        ofs = item;
                    }
                })
                events.push(ofs)
            });
            // filtered_offers_cal = filtered_offers_cal.filter(function (element) {
            //     return element !== undefined || element !== 'undefined';
            // });
            events = events.filter(cur => cur);

            return {
                ...state,
                events: action.payload,
                events_filtered: events
            };
        case CALENDAR_RESET:
            return {
                ...state,
                events_filtered: [...state.events_filtered]
            };
        case CALENDAR_FILTER:
            var options = action.payload;
            var events = [];
            state.events.map((item, index) => {
                options.forEach(eachKey => {
                    if (item.fil.toLowerCase() === eachKey) {
                        events.push(item)
                    }
                })
            });
            //events = events.filter(cur => cur);
            // var filter = action.payload;
            var newEvents = [];
            state.filter.client.map(c => {
                var ev = [];
                ev = events.filter((item) => {
                    var filterBatch = item.client_id
                    if (c) {
                        return filterBatch.indexOf(c) >= 0
                    } else {
                        return filterBatch
                    }
                })
                newEvents = [...newEvents, ...ev];
            })

            return {
                ...state,
                events_filtered: newEvents,
                filter_check: options
            };
        case CALENDAR_FILTER_SUB:
            var events = [];
            state.events.map((item, index) => {
                state.filter_check.forEach(eachKey => {
                    if (item.fil.toLowerCase() === eachKey) {
                        events.push(item)
                    }
                })
            });
            events = events.filter(cur => cur);
            var filter = action.payload;
            var newEvents = [];
            filter.client.map(c => {
                var ev = [];
                ev = events.filter((item) => {
                    var filterBatch = item.client_id
                    return filterBatch.indexOf(c) >= 0
                })
                newEvents = [...newEvents, ...ev];
            })

            return {
                ...state,
                events_filtered: newEvents,
                filter: filter
            };
        // case ADD_CAL_ENTRY_SOCKET:
        //     return {
        //         ...state,
        //         entries: [action.payload, ...state.entries],
        //     };
        // case UPDATE_CAL_ENTRY_SOCKET:
        //     var index = data.findIndex(obj => obj._id === action.payload._id);
        //     data[index] = action.payload;
        //     return {
        //         ...state,
        //         entries: data
        //     };
        case CALENDAR_SOCKET:
            var data = state.entries;
            var index = state.entries.findIndex(obj => obj._id === action.payload._id);
            if (index >= 0) {
                data[index] = action.payload;
            } else {
                data = [action.payload, ...state.entries];
            }
            return {
                ...state,
                entries: data
            };
        case CALENDAR_SOCKET_DELETE:
            return {
                ...state,
                entries: state.entries.filter(data => data._id !== action.payload),
            };
        default:
            return state;
    }
}