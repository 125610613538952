import {
    VENUES_INIT_LOADING,
    VENUES_LOADING,
    VENUES_GET,
    VENUES_FILTER,
    VENUES_SORT,
    VENUES_SOCKET,
    VENUES_SOCKET_DELETE
} from "../actions/types";
import _ from 'lodash';

const initialState = {
    items: [],
    filtered_venues: [],
    filter: {
        venue_name: '',
        status: '',
        _id: '',
        city: '',
        state: '',
        country: '',
        min_capacity: '',
        max_capacity: '',
    },
    loading: false,
    init_loading: false,
    sort: {
        cat: 'venue_name',
        dir: 'asc'
    }
};

export default function (state = initialState, action) {
    let filtered_data = [...state.filtered_venues];
    switch (action.type) {
        case VENUES_INIT_LOADING:
            return {
                ...state,
                init_loading: true
            };
        case VENUES_LOADING:
            return {
                ...state,
                loading: true
            };
        case VENUES_GET:
            var sortedVenues = _.orderBy(action.payload, [`venue_name`.toLowerCase()], ['asc']);
            return {
                ...state,
                items: sortedVenues,
                filtered_venues: sortedVenues,
                init_loading: false,
                loading: false
            };
        case VENUES_FILTER:
            var venue_filter = action.payload;
            var venue_data = state.items;
            if (venue_filter.venue_name) {
                venue_data = venue_data.filter((item) => {
                    var filterBatch = item.venue_name
                    return filterBatch.indexOf(venue_filter.venue_name) >= 0
                })
            }
            if (venue_filter.status) {
                venue_data = venue_data.filter((item) => {
                    var filterBatch = item.status
                    if (item.status) {
                        return filterBatch.indexOf(venue_filter.status) >= 0
                    }
                })
            }
            if (venue_filter._id) {
                venue_data = venue_data.filter((item) => {
                    var filterBatch = item._id
                    return filterBatch.indexOf(venue_filter._id) >= 0
                })
            }
            if (venue_filter.city) {
                venue_data = venue_data.filter((item) => {
                    var filterBatch = item.address.city
                    return filterBatch.indexOf(venue_filter.city) >= 0
                })
            }
            if (venue_filter.state) {
                venue_data = venue_data.filter((item) => {
                    var filterBatch = item.address.state
                    return filterBatch.indexOf(venue_filter.state) >= 0
                })
            }
            if (venue_filter.country) {
                venue_data = venue_data.filter((item) => {
                    var filterBatch = item.address.country
                    return filterBatch.indexOf(venue_filter.country) >= 0
                })
            }
            if (venue_filter.min_capacity) {
                venue_data = venue_data.filter((item) => {
                    return item.capacity >= venue_filter.min_capacity
                })
            }
            if (venue_filter.max_capacity) {
                venue_data = venue_data.filter((item) => {
                    return item.capacity <= venue_filter.max_capacity
                })
            }
            var sort = state.sort;
            venue_data = _.orderBy(venue_data, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            return {
                ...state,
                filtered_venues: venue_data,
                filter: venue_filter,
                loading: false
            };
        case VENUES_SORT:
            var sort = action.payload;
            var sortedVenues = _.orderBy(state.filtered_venues, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            return {
                ...state,
                filtered_venues: sortedVenues,
                sort: sort,
                loading: false
            };
        // case ADD_VENUE:
        //     return {
        //         ...state,
        //         items: [action.payload, ...state.items],
        //     };
        // case SADD_VENUE:
        //     var venue_filter = state.filter;
        //     var newArray = [...state.items, action.payload];
        //     var venue_data = newArray.filter((item) => {
        //         var filterBatch = item.venue_name
        //         return filterBatch.indexOf(venue_filter.venue_name) >= 0
        //     })
        //     venue_data = venue_data.filter((item) => {
        //         var filterBatch = item.address.city
        //         return filterBatch.indexOf(venue_filter.city) >= 0
        //     })
        //     venue_data = venue_data.filter((item) => {
        //         var filterBatch = item.address.state
        //         return filterBatch.indexOf(venue_filter.state) >= 0
        //     })
        //     venue_data = venue_data.filter((item) => {
        //         var filterBatch = item.address.country
        //         return filterBatch.indexOf(venue_filter.country) >= 0
        //     })
        //     if (venue_filter.min_capacity) {
        //         venue_data = venue_data.filter((item) => {
        //             return item.capacity >= venue_filter.min_capacity
        //         })
        //     }
        //     if (venue_filter.max_capacity) {
        //         venue_data = venue_data.filter((item) => {
        //             return item.capacity <= venue_filter.max_capacity
        //         })
        //     }
        //     venue_data = _.orderBy(venue_data, [`${state.sort.cat}`.toLowerCase()], [state.sort.dir]);
        //     return {
        //         ...state,
        //         items: [action.payload, ...state.items],
        //         filtered_venues: venue_data,
        //     };
        // case UPDATE_VENUE_SOCKET:
        //     let data = [...state.items]
        //     var index = data.findIndex(obj => obj._id === action.payload._id)
        //     data[index] = action.payload

        //     const found = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //     if (found >= 0) {
        //         var filtered_index = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //         filtered_data[filtered_index] = action.payload;
        //     }

        //     return {
        //         ...state,
        //         items: data,
        //         filtered_venues: filtered_data
        //     }
        case VENUES_SOCKET:
            var data = state.items;
            var f_data = state.filtered_venues;
            var index = state.items.findIndex(obj => obj._id === action.payload._id);
            var f_index = state.filtered_venues.findIndex(obj => obj._id === action.payload._id);
            if (index >= 0) {
                data[index] = action.payload;
                if (f_index >= 0) {
                    f_data[f_index] = action.payload;
                }
            } else {
                data = [action.payload, ...state.items];
                var venue_filter = state.filter;
                f_data = data;
                if (venue_filter.venue_name) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.venue_name
                        return filterBatch.indexOf(venue_filter.venue_name) >= 0
                    })
                }
                if (venue_filter.city) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.city
                        return filterBatch.indexOf(venue_filter.city) >= 0
                    })
                }
                if (venue_filter.state) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.state
                        return filterBatch.indexOf(venue_filter.state) >= 0
                    })
                }
                if (venue_filter.country) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.country
                        return filterBatch.indexOf(venue_filter.country) >= 0
                    })
                }
                if (venue_filter.min_capacity) {
                    f_data = f_data.filter((item) => {
                        return item.capacity >= venue_filter.min_capacity
                    })
                }
                if (venue_filter.max_capacity) {
                    f_data = f_data.filter((item) => {
                        return item.capacity <= venue_filter.max_capacity
                    })
                }
                f_data = _.orderBy(f_data, [`${state.sort.cat}`.toLowerCase()], [state.sort.dir]);
            }

            return {
                ...state,
                items: data,
                filtered_venues: f_data
            }
        case VENUES_SOCKET_DELETE:
            return {
                ...state,
                items: state.items.filter(venue => venue._id !== action.payload._id),
                filtered_venues: state.filtered_venues.filter(venue => venue._id !== action.payload._id),
            };
        default:
            return state;
    }
}