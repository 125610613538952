import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    CONNECT_ON,
    CONNECT_OFF,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    INIT_LOADING,
    INIT_LOADED,
    USER_UPDATED,
    USER_UPDATED_GOOGLE,
    USERS_LOAD,
    USERS_IMGS,
    USERS_SOCKET,
    USERS_SOCKET_DELETE,
    CHAT_SOCKET,
    COMPANY_EMAIL_GROUPS,
    COMPANY_TEMPLATES
} from "../actions/types";
import { loadUser } from "../actions/authActions";
import store from '../store';
import moment from 'moment';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    isLoading: false,
    initLoading: false,
    user: {},
    socket: null,
    connectedUsers: [],
    messages: [],
    tasks: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case USER_LOADED:
            if (Object.keys(action.payload).length > 0) {
                setTimeout(
                    () => store.dispatch(loadUser()),
                    (action.payload._exp * 1000)
                );
                // setInterval(function(){ 
                //     let last = localStorage.getItem('sessionTime');
                //     let now =  moment();

                //     if (last <= now) {
                //         store.dispatch(loadUser())
                //     }
                // }, 10000);
                return {
                    ...state,
                    isAuthenticated: true,
                    isLoading: false,
                    user: action.payload
                };
            }
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            if (action.payload.token) {
                // var time = moment();
                // time.add( Number(action.payload._exp), 'seconds' );
                // localStorage.setItem('sessionTime', time);
                localStorage.setItem('token', action.payload.token);
                setTimeout(
                    () => store.dispatch(loadUser()),
                    (action.payload._exp * 1000)
                );
                // setInterval(function(){ 
                //     //let last = localStorage.getItem('sessionTime');
                //     if (time <= moment()) {
                //         store.dispatch(loadUser())
                //     }
                // }, 10000);
                return {
                    ...state,
                    token: action.payload.token,
                    user: action.payload.user,
                    isAuthenticated: true,
                    isLoading: false,
                };
            }
        case CONNECT_ON:
            return {
                ...state,
                socket: action.payload
            };
        case CONNECT_OFF:
            return {
                ...state,
                socket: null,
                connectedUsers: []
            };
        case INIT_LOADING:
            return {
                ...state,
                initLoading: true
            };
        case INIT_LOADED:
            return {
                ...state,
                initLoading: false
            };
        case USERS_LOAD:
            return {
                ...state,
                connectedUsers: action.payload
            };
        case USER_UPDATED:
            var data = action.payload;
            var user = state.user;
            user.name = data.name;
            user.username = data.username;
            user.address = data.address;
            user.contact = data.contact;
            if (data._init) {
                user._init = data._init;
            }
            return {
                ...state,
                user: user
            };
        case USER_UPDATED_GOOGLE:
            var data = action.payload;
            var user = state.user;
            user.gmail = data;
            return {
                ...state,
                user: user
            };
        case USERS_IMGS:
            var connUsers = state.connectedUsers;
            connUsers.map(item => {
                if (item.tag === action.payload.id) {
                    item.img = action.payload.data
                }
            })
            return {
                ...state,
                connectedUsers: connUsers
            };
        case USERS_SOCKET:
            var data = state.connectedUsers;
            var index = data.findIndex(obj => obj.tag === action.payload.tag);
            if (index >= 0) {
                data[index] = action.payload;
            } else {
                data = [action.payload, ...state.connectedUsers];
            }
            return {
                ...state,
                connectedUsers: data
            };
        case USERS_SOCKET_DELETE:
            return {
                ...state,
                connectedUsers: state.connectedUsers.filter(user => user.tag !== action.payload.tag),
            };
        case CHAT_SOCKET:
            return {
                ...state,
                messages: [...state.messages, action.payload]
            };
        case COMPANY_EMAIL_GROUPS:
            var groups = action.payload;
            var newUser = state.user;
            if (newUser.company.email && newUser.company.email.groups) {
                newUser.company.email.groups = groups;
            } else {
                const groups = action.payload
                newUser.company.email = { groups: groups }
            }
            return {
                ...state,
                user: newUser
            };
        case COMPANY_TEMPLATES:
            var templates = action.payload;
            var newUser = state.user;
            newUser.company.templates = templates;
            return {
                ...state,
                user: newUser
            };
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case REGISTER_FAIL:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false,
                socket: null,
                connectedUsers: []
            };
        default:
            return state;
    }
}