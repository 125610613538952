import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';

export default function ContractReceivedDialog(props) {
    const [stateCurrencySelect, setCurrencySelect] = useState(null);
    const [stateDateSelect, setDateSelect] = useState(new Date());
    const [stateNotes, setNotes] = useState("");
    const [stateUser, setUser] = useState(props.user.first + " " + props.user.last);
    const [stateAmount, setAmount] = useState(0);

    const handleChange = (event) => {
        const value = event.target.value;
        setNotes(value);
    }

    const handleDateChange = (date) => {
        setDateSelect(date);
    };

    const handleNumberChange = (values) => {
        const { formattedtax1, value } = values;
        setAmount(value);
    }

    const handleSave = () => {
        if (props.type === 'contract') {
            const received = {
                date: stateDateSelect,
                user: stateUser,
                note: stateNotes
            }
            console.log(received);
        } else {
            const received = {
                date: stateDateSelect,
                user: stateUser,
                note: stateNotes,
                amount: stateAmount
            }
            console.log(received);
        }
        closeDialog();
    }

    const closeDialog = () => {
        props.handleClose();
    };

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={props.handleClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Contract Received</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
                    <div
                        style={{
                            paddingRight: 17,
                            height: "100%",
                            width: "100%",
                            boxSizing: "content-box",
                            overflow: "scroll"
                        }}>
                        <table style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Date:</td>
                                    <td style={{ width: '50%' }}>
                                        <DatePicker
                                            className="react-datepicker"
                                            style={{ fontSize: 12 }}
                                            value={stateDateSelect}
                                            selected={stateDateSelect}
                                            onChange={handleDateChange}
                                            dateFormat="MM/dd/yyyy"
                                            todayButton="Today"
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            {props.type === 'deposit' ?
                                <tbody>
                                    <tr>
                                        <td style={{ width: '50%', fontSize: 12 }}>Amount:</td>
                                        <td style={{ width: '50%' }}>
                                            <NumberFormat style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} className="form-control-sm" value={stateAmount} thousandSeparator={true} prefix={props.select_currency} onValueChange={handleNumberChange} />
                                        </td>
                                    </tr>
                                </tbody> : null}
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>User:</td>
                                    <td style={{ width: '50%' }}><input style={{ width: '100%', fontSize: 12 }} type="text" disabled={true} value={stateUser} /></td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Notes:</td>
                                    <td style={{ width: '50%' }}><textarea style={{ width: '100%', maxWidth: '100%', height: 35, fontSize: 12 }} name="notes" value={stateNotes} onChange={handleChange} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={closeDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}