import React, { useEffect, useState } from "react";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    label: {
        display: 'block',
    },
    input: {
        width: '100%',
        fontSize: 12,
        paddingTop: '2%', paddingBottom: '2%', paddingLeft: '4%',
        height: 28
    },
    listbox: {
        width: 280,
        margin: 0,
        padding: 10,
        zIndex: 1,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        fontSize: 12,
        maxHeight: 200,
        color: 'black',
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            backgroundColor: '#e6f7ff',
            color: 'black',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#e6f7ff',
            color: 'black',
        },
    },
}));

export default function ExpensesAutocomplete({ onChange, expSelect }) {
    const [stateExpSelect, setExpSelect] = useState(expSelect);

    useEffect(() => {
        setExpSelect(expSelect);
    }, [expSelect])

    const expenseList = ['Advertising', 'ASCAP', 'ASCAP/BMI/SESAC', 'Backline', 'Barricades', 'BMI', 'Box Office', 'Catering', 'Clean Up', 'Co-headline', 'Credit Cards', 'Fireman', 'Hotels', 'House Expense', 'Insurance', 'Loaders', 'Medical/EMT', 'Misc', 'Phone/Internet', 'Police', 'Prod/Stage Manager', 'Production', 'Rent', 'Riggers', 'Runners', 'Security', 'Security T-Shirt', 'SESAC', 'SOCAN', 'Sound/Lights', 'Spot Rental', 'Staffing', 'Stage Rental', 'Stagehands', 'Support', 'Ticket Commission', 'Ticket Printing', 'Ticket Takers', 'Towels', 'Transportation', 'Ushers']

    const classes = useStyles();
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-demo',
        inputValue: stateExpSelect,
        //value: stateExpSelect,
        options: expenseList,
        freeSolo: true,
        getOptionLabel: (option) => option,
        getOptionSelected: (option, value) => option === value,
        onChange: (event, newValue) => {
            if (newValue) {
                onChange(newValue)
            } else if (!newValue) {
                onChange('')
            };
        },
        onInputChange: (event, newValue) => {
            if (newValue) {
                onChange(newValue)
            } else if (!newValue) {
                onChange('')
            };
        }
    });

    return (
        <div>
            <div {...getRootProps()}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <input className={classes.input} {...getInputProps()} />
                </div>
            </div>
            {groupedOptions.length > 0 ? (
                <ul className={classes.listbox} {...getListboxProps()}>
                    {groupedOptions.map((option, index) => (
                        <li style={{ padding: 5 }} {...getOptionProps({ option, index })}>{option}</li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
}