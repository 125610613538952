import React, { useState, useCallback, useEffect } from 'react';
import { Card } from './Card';
import update from 'immutability-helper';
const style = {
    width: '100%'
};
export const Container = ({ scalingarray, updateScaling, deleteScaling, handleArrayUpdate, edit, scaling_edit, scaling_val, clearScalingUpdate, saveScalingUpdate, handleArrayFormattedChange, currency_symbol, seperate_scaling, scaling_pin, show_select, handlePinnedArray2Change, droppedCards }) => {
    {
        const [cards, setCards] = useState(scalingarray);

        useEffect(() => {
            setCards(scalingarray);
        }, [scalingarray.map(item => item._id)])

        const moveCard = useCallback((dragIndex, hoverIndex) => {
            const dragCard = cards;
            [dragCard[dragIndex], dragCard[hoverIndex]] = [
                dragCard[hoverIndex],
                dragCard[dragIndex]
            ];
            //setCards([...dragCard]);
            // const test = [
            //     [dragIndex, 1],
            //     [hoverIndex, 0, dragCard],
            // ];
            // console.log(test)
            droppedCards([...dragCard], 'scalingarray')
        }, [cards]);
        const renderCard = (card, index) => {
            return (<Card key={card._id} index={index} id={card._id} show_index={card.show_index} type={card.type} cap={card.capacity} comps={card.comps} kills={card.kills} price={card.price} facfee={card.fac_fee} addfeeA={card.add_fee_A} addfeeB={card.add_fee_B} updateScaling={updateScaling} deleteScaling={deleteScaling} handleArrayUpdate={handleArrayUpdate} edit={edit} scaling_edit={scaling_edit[card._id]} scaling_val={scaling_val[card._id]} clearScalingUpdate={clearScalingUpdate} saveScalingUpdate={saveScalingUpdate} handleArrayFormattedChange={handleArrayFormattedChange} currency_symbol={currency_symbol} scaling_pin={scaling_pin} show_select={show_select} handlePinnedArray2Change={handlePinnedArray2Change} moveCard={moveCard} />);
        };
        return (<>
            {seperate_scaling ?
                <div style={style}>{cards.map((card, i) => { if (card.show_id === show_select) { return (renderCard(card, i)) } })}</div> :
                <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>}
        </>);
    }
};
