import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    CONTACTS_LOADING,
    CONTACTS_GET,
    CONTACTS_FILTER,
    CONTACTS_SORT,
    CONTACTS_SOCKET,
    CONTACTS_SOCKET_DELETE,
    NOTIFICATIONS
} from "./types";

export const setContactsLoading = () => {
    return {
        type: CONTACTS_LOADING
    };
};

export const getContacts = () => (dispatch, getState) => {
    axios.get(`${process.env.REACT_APP_API}/contacts/`, tokenConfig(getState))
        .then(async (res) => {
            dispatch({
                type: CONTACTS_GET,
                payload: res.data
            })
            // res.data.map(item => {
            //     if(item.name.first === 'Robbie' && item.name.last === 'Baker'){
            //     }
            // })
            //await newUpdateTest(res.data, getState);
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

const newUpdateTest = (data, getState) => {
    var newItems = [];
    data.forEach(async (item) => {
        var contact = item;
        var office_phone = {
            dial: '',
            ext: ''
        }
        if (item.contact.office_phone) {
            office_phone = {
                dial: item.contact.office_phone,
                ext: ''
            }
        }
        var cell_phone = {
            dial: '',
            ext: ''
        }
        if (item.contact.cell_phone) {
            cell_phone = {
                dial: item.contact.cell_phone,
                ext: ''
            }
        }
        var home_phone = {
            dial: '',
            ext: ''
        }
        if (item.contact.home_phone) {
            home_phone = {
                dial: item.contact.home_phone,
                ext: ''
            }
        }
        var fax = {
            dial: '',
            ext: ''
        }
        if (item.contact.fax) {
            fax = {
                dial: item.contact.fax.dial,
                ext: ''
            }
        }
        var contactZ = {
            office: office_phone,
            home: home_phone,
            cell: cell_phone,
            fax: fax,
            email: item.contact.email
        }
        contact.contact = contactZ
        var id = contact._id;
        //newItems.push({ ...iT })
        //updateContact(contact, id)
        await axios.post(`${process.env.REACT_APP_API}/contacts/update/${id}`, contact, tokenConfig(getState))
            .then(res => {
                //return res.data
            })
            .catch(err => {
                //dispatch(returnErrors(err.response.data, err.response.status))
                //return err.response.data
            });
    })
}

export const applyContactFilter = (contact_filter) => (dispatch, getState) => {
    dispatch(setContactsLoading());
    dispatch({
        type: CONTACTS_FILTER,
        payload: contact_filter
    })
};

export const contactSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: CONTACTS_SORT,
        payload: sort
    })
};

export const addContact = contact => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/contacts/add`, contact, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_CONTACT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const addContactSocket = contact => (dispatch) => {
//     dispatch({
//         type: SADD_CONTACT,
//         payload: contact
//     })
// }

export const updateContact = (contact, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/contacts/update/${id}`, contact, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const updateContactSocket = contact => (dispatch) => {
//     dispatch({
//         type: UPDATE_CONTACT_SOCKET,
//         payload: contact
//     })
// }

export const contactSocket = contact => (dispatch) => {
    dispatch({
        type: CONTACTS_SOCKET,
        payload: contact
    })
}

export const updateContactTags = tags => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/contacts/tags/add`, tags, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

export const removeContactTagSocket = contact => (dispatch) => {
    // dispatch({
    //     type: REMOVE_CONTACT_TAG_SOCKET,
    //     payload: contact
    // })
}

export const removeContactTag = tags => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/contacts/tags/delete`, tags, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

export const deleteContact = id => (dispatch, getState) => {
    return axios.delete(`${process.env.REACT_APP_API}/contacts/${id}`, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: DELETE_CONTACT,
            //    payload: id
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const deleteContactSocket = contact => (dispatch) => {
    dispatch({
        type: CONTACTS_SOCKET_DELETE,
        payload: contact
    })
}

export const contactDupCheck = (dupCheck) => async (dispatch, getState) => {
    const contacts = getState().contacts.contacts;
    var check = [];
    contacts.map(item => {
        if(item.name.first === dupCheck.first && item.name.last === dupCheck.last){
            check = [...check, item]
        }
    })
    return check
}