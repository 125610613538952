import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    CALENDAR_LOADING,
    CALENDAR_GET,
    CALENDAR_SOCKET,
    CALENDAR_SOCKET_DELETE,
    CALENDAR_SET,
    CALENDAR_RESET,
    CALENDAR_FILTER,
    CALENDAR_FILTER_SUB,
    NOTIFICATIONS,
    OFFERS_GET
} from "./types";
import moment from 'moment';

export const setCalendarLoading = () => {
    return {
        type: CALENDAR_LOADING
    };
};

export const getCalendarEntries = () => (dispatch, getState) => {
    dispatch(setCalendarLoading());
    axios.get(`${process.env.REACT_APP_API}/calendar/`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: CALENDAR_GET,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const addCalendarEntry = entry => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API}/calendar/add`, entry, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: NOTIFICATIONS,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
}

export const deleteCalendarEntry = id => async (dispatch, getState) => {
    return await axios.delete(`${process.env.REACT_APP_API}/calendar/${id}`, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //     type: NOTIFICATIONS,
            //     payload: res.data
            // })
            return res.data
        })
        .catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const addCalendarEntrySocket = calendar => (dispatch) => {
//     dispatch({
//         type: ADD_CAL_ENTRY_SOCKET,
//         payload: calendar
//     })
// }

export const applyCalendarFilter = options => (dispatch) => {
    dispatch({
        type: CALENDAR_FILTER,
        payload: options
    })
}

export const applyCalendarFilter2 = options => (dispatch) => {
    dispatch({
        type: CALENDAR_FILTER_SUB,
        payload: options
    })
}

export const updateCalendarEntry = (entry, id) => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API}/calendar/update/${id}`, entry, tokenConfig(getState))
        .then(res => {

        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
}

// export const updateCalendarEntrySocket = calendar => (dispatch) => {
//     dispatch({
//         type: UPDATE_CAL_ENTRY_SOCKET,
//         payload: calendar
//     })
// }

export const calendarSocket = (type, values) => (dispatch, getState) => {
    const val = values;
    const { calendar } = getState();
    const { holds } = getState();
    var newEvents = calendar.events;
    if (type === 'clients/updated') {
        newEvents.map(item => {
            if (item.client_id === val._id) {
                item.artist_name = val.client;
                var description = '';
                if (item.sub === 'holds' || item.sub === 'offer holds') {
                    description = val.client + '<br>' + '(' + item.status + ' - Holds:' + item.all_holds + ')' + '<br>' + item.venue + '<br>' + item.venuecity + ', ' + item.venuestate;
                } else {
                    description = val.client + '<br>' + '(' + item.status + ')' + '<br>' + item.venue + '<br>' + item.venuecity + ', ' + item.venuestate;
                }
                item.title = val.client;
                item.description = description;
            }
        })
    }
    if (type === 'clients/deleted') {
        newEvents.map(item => {
            if (item.client_id === val._id) {
                item.artist_name = 'Undefined';
                var description = '';
                if (item.sub === 'holds' || item.sub === 'offer holds') {
                    description = 'Undefined' + '<br>' + '(' + item.status + ' - Holds:' + item.all_holds + ')' + '<br>' + item.venue + '<br>' + item.venuecity + ', ' + item.venuestate;
                } else {
                    description = 'Undefined' + '<br>' + '(' + item.status + ')' + '<br>' + item.venue + '<br>' + item.venuecity + ', ' + item.venuestate;
                }
                item.title = 'Undefined';
                item.description = description;
            }
        })
    }
    if (type === 'venues/updated') {
        newEvents.map(item => {
            if (item.venue_id === val._id) {
                item.venue = val.venue_name;
                var venuecity = val.address ? val.address.city : "";
                var venuestate = val.address ? val.address.state : "";
                item.venuecity = venuecity;
                item.venuestate = venuestate;
                var description = '';
                if (item.sub === 'holds' || item.sub === 'offer holds') {
                    description = item.artist + '<br>' + '(' + item.status + ' - Holds:' + item.all_holds + ')' + '<br>' + val.venue_name + '<br>' + venuecity + ', ' + venuestate;
                } else {
                    description = item.artist + '<br>' + '(' + item.status + ')' + '<br>' + val.venue_name + '<br>' + venuecity + ', ' + venuestate;
                }
                item.description = description;
            }
        })
    }
    if (type === 'venues/deleted') {
        newEvents.map(item => {
            if (item.venue_id === val._id) {
                item.venue = 'Undefined';
                item.venuecity = 'Undefined';
                item.venuestate = 'Undefined';
                var description = '';
                if (item.sub === 'holds' || item.sub === 'offer holds') {
                    description = item.artist_name + '<br>' + '(' + item.status + ' - Holds:' + item.all_holds + ')' + '<br>' + 'Undefined';
                } else {
                    description = item.artist_name + '<br>' + '(' + item.status + ')' + '<br>' + 'Undefined';
                }
                item.description = description;
            }
        })
    }
    if (type === 'offers/updated') {
        var events = [];
        var filterEvents = calendar.events.filter(item => item.id !== val._id);
        var oldEvents = calendar.events.filter(item => item.id === val._id);
        if (oldEvents.length > 0) {
            oldEvents = oldEvents[0]
        }
        var status = val.status;
        var artist = "";
        var venue = "";
        var venuecity = "";
        var venuestate = "";
        if (oldEvents.client_id === val.client_id) {
            artist = oldEvents.client_id
        } else {
            const { clients } = getState();
            var fc = clients.clients.filter(item => item._id === val.client_id)
            if (fc.length > 0) {
                fc = fc[0];
                artist = fc.client;
            } else {
                artist = "";
            }
        }
        if (oldEvents.venue_id === val.venue_id) {
            venue = oldEvents.venue;
            venuecity = oldEvents.venuecity;
            venuestate = oldEvents.venuestate;
        } else {
            const { items } = getState();
            var fv = items.items.filter(item => item._id === val.venue_id)
            if (fv.length > 0) {
                fv = fv[0];
                venue = fv.venue_name;
                venuecity = fv.address.city;
                venuestate = fv.address.state;
            } else {
                venue = "Undefined";
                venuecity = "";
                venuestate = "";
            }
        }
        const color = [];
        if (status === 'Confirmed') {
            color.push('#4460ff');
        } else if (status === 'Pending') {
            color.push('#333333');
        } else if (status === 'Cancelled') {
            color.push('red');
        }
        var h_array = [];
        if (status !== 'Pending') {
            var h_ind = holds.findIndex(h => h.connect_id === val._id);
            var h_arr = [];
            if (h_ind >= 0) {
                h_arr = holds[h_ind];
                h_array.push(h_arr._id);
            }
            val.show_info.forEach((item, index) => {
                const arrayOfApp = { title: artist, venue: venue, start: new Date(item.date), end: new Date(item.date), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: color, sub: "offer", fil: status, client_id: val.client_id, sub_id: item._id, venue_id: val.venue_id, artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                events.push(arrayOfApp);
                val.links.forEach(l => {
                    if (item._id === l._id) {
                        if (l.announce && l.announce.date) {
                            var nd = l.announce.date;
                            var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Announce", fil: 'announce', client_id: val.client_id, sub_id: item._id, venue_id: val.venue_id, artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                            events.push(arrayOfApp2)
                        }
                        if (l.presale && l.presale.date) {
                            var nd = l.presale.date;
                            var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Presale", fil: 'announce', client_id: val.client_id, sub_id: item._id, venue_id: val.venue_id, artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                            events.push(arrayOfApp2)
                        }
                        if (l.on_sale && l.on_sale.date) {
                            var nd = l.on_sale.date;
                            var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "On Sale", fil: 'announce', client_id: val.client_id, sub_id: item._id, venue_id: val.venue_id, artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                            events.push(arrayOfApp2)
                        }
                    }
                })
            })
            filterEvents.filter(i => {
                return !h_array.find(a => a === i._id)
            });
            newEvents = [...filterEvents, ...events];
        } else {
            var h_ind = holds.holds.findIndex(h => h.connect_id === val._id);
            var h_arr = [];
            var allHolds = null;
            if (h_ind >= 0) {
                h_arr = holds.holds[h_ind];
                h_array.push(h_arr._id);
                h_arr.holds.forEach((h, i) => {
                    var d = moment(h.date).utc().format('D');
                    if (i === 0) {
                        allHolds = allHolds + " " + h.hold + d
                    } else {
                        allHolds = allHolds + ", " + h.hold + d
                    }
                })
                h_arr.holds.forEach((h, i) => {
                    val.show_info.forEach((item, index) => {
                        //var start = moment(item.date).format('YYYY-MM-DD');
                        const arrayOfApp = { title: artist, venue: venue, start: new Date(h.date), end: new Date(h.date), description: artist + '<br>' + '(' + status + ' - Holds:' + allHolds + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: color, sub: "offer holds", hold: h.hold, fil: status, client_id: val.client_id || "Undefined", sub_id: item._id, venue_id: val.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status, all_holds: allHolds }
                        events.push(arrayOfApp)
                    })
                })
                val.links.forEach(l => {
                    if (val._id === l._id) {
                        if (l.announce && l.announce.date) {
                            var nd = l.announce.date;
                            var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Announce", fil: 'announce', client_id: val.client_id, sub_id: val._id, venue_id: val.venue_id, artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                            events.push(arrayOfApp2)
                        }
                        if (l.presale && l.presale.date) {
                            var nd = l.presale.date;
                            var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Presale", fil: 'announce', client_id: val.client_id, sub_id: val._id, venue_id: val.venue_id, artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                            events.push(arrayOfApp2)
                        }
                        if (l.on_sale && l.on_sale.date) {
                            var nd = l.on_sale.date;
                            var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "On Sale", fil: 'announce', client_id: val.client_id, sub_id: val._id, venue_id: val.venue_id, artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                            events.push(arrayOfApp2)
                        }
                    }
                })
            } else {
                val.show_info.forEach((item, index) => {
                    //var start = moment(item.date).format('YYYY-MM-DD');
                    const arrayOfApp = { title: artist, venue: venue, start: new Date(item.date), end: new Date(item.date), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: color, sub: "offer", fil: val.status, client_id: val.client_id || "Undefined", sub_id: item._id, venue_id: val.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                    events.push(arrayOfApp)
                    val.links.forEach(l => {
                        if (val._id === l._id) {
                            if (l.announce && l.announce.date) {
                                var nd = l.announce.date;
                                var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Announce", fil: 'announce', client_id: val.client_id || "Undefined", sub_id: item._id, venue_id: val.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                                events.push(arrayOfApp2)
                            }
                            if (l.presale && l.presale.date) {
                                var nd = l.presale.date;
                                var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Presale", fil: 'announce', client_id: val.client_id || "Undefined", sub_id: item._id, venue_id: val.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                                events.push(arrayOfApp2)
                            }
                            if (l.on_sale && l.on_sale.date) {
                                var nd = l.on_sale.date;
                                var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: val._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "On Sale", fil: 'announce', client_id: val.client_id || "Undefined", sub_id: item._id, venue_id: val.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                                events.push(arrayOfApp2)
                            }
                        }
                    })
                })
            }
            filterEvents.filter(i => {
                return !h_array.find(a => a === i._id)
            });
            newEvents = [...filterEvents, ...events];
        }
    }
    if (type === 'offers/deleted') {
        newEvents = newEvents.filter(item => {
            if (item.sub === 'holds') {
                return item
            } else if (item.id !== val._id) {
                return item
            }
        })
    }
    if (type === 'festivals/updated') {
        newEvents.map(item => {
            if (item.venue_id === val._id) {
                item.venue = val.festival;
                var venuecity = val.address ? val.address.city : "";
                var venuestate = val.address ? val.address.state : "";
                var description = '';
                if (item.sub === 'holds' || item.sub === 'offer holds') {
                    description = item.artist + '<br>' + '(' + item.status + ' - Holds:' + item.all_holds + ')' + '<br>' + val.festival + '<br>' + venuecity + ', ' + venuestate;
                } else {
                    description = item.artist + '<br>' + '(' + item.status + ')' + '<br>' + val.festival + '<br>' + venuecity + ', ' + venuestate;
                }
                item.description = description;
            }
        })
    }
    if (type === 'festivals/deleted') {
        newEvents.map(item => {
            if (item.venue_id === val._id) {
                item.venue = 'Undefined';
                item.venuecity = 'Undefined';
                item.venuestate = 'Undefined';
                var description = '';
                if (item.sub === 'holds' || item.sub === 'offer holds') {
                    description = item.artist_name + '<br>' + '(' + item.status + ' - Holds:' + item.all_holds + ')' + '<br>' + 'Undefined';
                } else {
                    description = item.artist_name + '<br>' + '(' + item.status + ')' + '<br>' + 'Undefined';
                }
                item.description = description;
            }
        })
    }
    if (type === 'holds/updated') {
        const { offers } = getState();
        const connect_id = val.connect_id;
        var filterEvents = calendar.events.filter(item => item.id !== connect_id);
        filterEvents = filterEvents.filter(item => item.id !== val._id);
        var oldEvents = offers.offers.filter(item => item._id === connect_id);
        var events = [];
        var h_array = [];
        var h_arr = val;
        h_array = [h_arr._id];
        if (oldEvents.length > 0) {

            oldEvents.map(og => {
                const status = og.status;
                const color = [];
                if (status === 'Confirmed') {
                    color.push('#4460ff');
                } else if (status === 'Pending') {
                    color.push('#333333');
                } else if (status === 'Cancelled') {
                    color.push('red');
                }

                if (status !== 'Pending') {
                    og.show_info.forEach((item, index) => {
                        const arrayOfApp = { title: og.artist_name, venue: og.venue_name, start: new Date(item.date), end: new Date(item.date), description: og.artist_name + '<br>' + '(' + status + ')' + '<br>' + og.venue_name + '<br>' + og.venue_city + ', ' + og.venue_state, id: og._id, backgroundColor: '#fff', borderColor: color, sub: "offer", fil: status, client_id: og.client_id, sub_id: item._id, venue_id: og.venue_id, artist: og.artist_name, venuecity: og.venue_city, venuestate: og.venue_state, status: status }
                        events.push(arrayOfApp);
                        og.links.forEach(l => {
                            if (item._id === l._id) {
                                if (l.announce && l.announce.date) {
                                    var nd = l.announce.date;
                                    var arrayOfApp2 = { title: og.artist_name, venue: og.venue_name, start: new Date(nd), end: new Date(nd), description: og.artist_name + '<br>' + '(' + status + ')' + '<br>' + og.venue_name + '<br>' + og.venue_city + ', ' + og.venue_state, id: og._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Announce", fil: 'announce', client_id: og.client_id, sub_id: item._id, venue_id: og.venue_id, artist: og.artist_name, venuecity: og.venue_city, venuestate: og.venue_state, status: status }
                                    events.push(arrayOfApp2)
                                }
                                if (l.presale && l.presale.date) {
                                    var nd = l.presale.date;
                                    var arrayOfApp2 = { title: og.artist_name, venue: og.venue_name, start: new Date(nd), end: new Date(nd), description: og.artist_name + '<br>' + '(' + status + ')' + '<br>' + og.venue_name + '<br>' + og.venue_city + ', ' + og.venue_state, id: og._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Presale", fil: 'announce', client_id: og.client_id, sub_id: item._id, venue_id: og.venue_id, artist: og.artist_name, venuecity: og.venue_city, venuestate: og.venue_state, status: status }
                                    events.push(arrayOfApp2)
                                }
                                if (l.on_sale && l.on_sale.date) {
                                    var nd = l.on_sale.date;
                                    var arrayOfApp2 = { title: og.artist_name, venue: og.venue_name, start: new Date(nd), end: new Date(nd), description: og.artist_name + '<br>' + '(' + status + ')' + '<br>' + og.venue_name + '<br>' + og.venue_city + ', ' + og.venue_state, id: og._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "On Sale", fil: 'announce', client_id: og.client_id, sub_id: item._id, venue_id: og.venue_id, artist: og.artist_name, venuecity: og.venue_city, venuestate: og.venue_state, status: status }
                                    events.push(arrayOfApp2)
                                }
                            }
                        })
                    })
                    filterEvents.filter(i => {
                        return !h_array.find(a => a === i._id)
                    });
                    newEvents = [...filterEvents, ...events];

                } else {
                    var allHolds = null;

                    h_arr.holds.forEach((h, i) => {
                        var d = moment(h.date).utc().format('D');
                        if (i === 0) {
                            allHolds = allHolds + " " + h.hold + d
                        } else {
                            allHolds = allHolds + ", " + h.hold + d
                        }
                    })

                    h_arr.holds.forEach((h, i) => {
                        og.show_info.forEach((item, index) => {
                            const arrayOfApp = { title: og.artist_name, venue: og.venue_name, start: new Date(h.date), end: new Date(h.date), description: og.artist_name + '<br>' + '(' + status + ' - Holds:' + allHolds + ')' + '<br>' + og.venue_name + '<br>' + og.venue_city + ', ' + og.venue_state, id: og._id, backgroundColor: '#fff', borderColor: color, sub: "offer holds", hold: h.hold, fil: status, client_id: og.client_id, sub_id: item._id, venue_id: og.venue_id, artist: og.artist_name, venuecity: og.venue_city, venuestate: og.venue_state, status: status }
                            events.push(arrayOfApp);
                        })
                    })

                    og.links.forEach(l => {
                        if (og._id === l._id) {
                            if (l.announce && l.announce.date) {
                                var nd = l.announce.date;
                                var arrayOfApp2 = { title: og.artist_name, venue: og.venue_name, start: new Date(nd), end: new Date(nd), description: og.artist_name + '<br>' + '(' + status + ')' + '<br>' + og.venue_name + '<br>' + og.venue_city + ', ' + og.venue_state, id: og._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Announce", fil: 'announce', client_id: og.client_id, sub_id: og._id, venue_id: og.venue_id, artist: og.artist_name, venuecity: og.venue_city, venuestate: og.venue_state, status: status }
                                events.push(arrayOfApp2)
                            }
                            if (l.presale && l.presale.date) {
                                var nd = l.presale.date;
                                var arrayOfApp2 = { title: og.artist_name, venue: og.venue_name, start: new Date(nd), end: new Date(nd), description: og.artist_name + '<br>' + '(' + status + ')' + '<br>' + og.venue_name + '<br>' + og.venue_city + ', ' + og.venue_state, id: og._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Presale", fil: 'announce', client_id: og.client_id, sub_id: og._id, venue_id: og.venue_id, artist: og.artist_name, venuecity: og.venue_city, venuestate: og.venue_state, status: status }
                                events.push(arrayOfApp2)
                            }
                            if (l.on_sale && l.on_sale.date) {
                                var nd = l.on_sale.date;
                                var arrayOfApp2 = { title: og.artist_name, venue: og.venue_name, start: new Date(nd), end: new Date(nd), description: og.artist_name + '<br>' + '(' + status + ')' + '<br>' + og.venue_name + '<br>' + og.venue_city + ', ' + og.venue_state, id: og._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "On Sale", fil: 'announce', client_id: og.client_id, sub_id: og._id, venue_id: og.venue_id, artist: og.artist_name, venuecity: og.venue_city, venuestate: og.venue_state, status: status }
                                events.push(arrayOfApp2)
                            }
                        }
                    })

                    filterEvents.filter(i => {
                        return !h_array.find(a => a === i._id)
                    });
                    newEvents = [...filterEvents, ...events];
                }
            })
            if (val.holds && val.holds.length > 0) {
                val.holds.map(item => {
                    var allHolds = "";
                    item.holds.forEach((h, i) => {
                        var d = moment(h.date).utc().format('D');
                        if (i === 0) {
                            allHolds = allHolds + " " + h.hold + d
                        } else {
                            allHolds = allHolds + ", " + h.hold + d
                        }
                    })
                    var artist = "";
                    var venue = "";
                    var venuecity = "";
                    var venuestate = "";
                    if (val.client) {
                        const { clients } = getState();
                        var fc = clients.clients.filter(item => item._id === val.client)
                        if (fc.length > 0) {
                            fc = fc[0];
                            artist = fc.client;
                        } else {
                            artist = "";
                        }
                    }
                    if (val.venue) {
                        const { items } = getState();
                        var fv = items.items.filter(item => item._id === val.venue)
                        if (fv.length > 0) {
                            fv = fv[0];
                            venue = fv.venue_name;
                            venuecity = fv.address.city;
                            venuestate = fv.address.state;
                        } else {
                            const { festivals } = getState();
                            var ff = festivals.items.filter(item => item._id === val.venue)
                            if (ff.length > 0) {
                                ff = ff[0];
                                venue = ff.venue_name;
                                venuecity = ff.address.city;
                                venuestate = ff.address.state;
                            } else {
                                venue = "Undefined";
                                venuecity = "";
                                venuestate = "";
                            }
                        }
                    }
                    item.holds.forEach((h, i) => {
                        const arrayOfApp = { title: artist, venue: venue, start: new Date(h.date), end: new Date(h.date), description: artist + '<br>' + '( Holds:' + allHolds + ' )' + '<br>' + venue, id: item._id, backgroundColor: '#fff', borderColor: '#ddd', sub: "holds", hold: h.hold, fil: 'holds', client_id: item.client || "Undefined", sub_id: h._id, venue_id: item.venue || "Undefined", artist: artist, venuecity: item.venu ? venuecity : '', venuestate: item.venue ? venuestate : '', all_holds: allHolds }
                        events.push(arrayOfApp);
                    })

                    filterEvents.filter(i => {
                        return !h_array.find(a => a === i._id)
                    });
                    newEvents = [...filterEvents, ...events];
                })
            }
        } else {
            if (val.holds.length > 0) {
                var allHolds = "";
                val.holds.forEach((h, i) => {
                    var d = moment(h.date).utc().format('D');
                    if (i === 0) {
                        allHolds = allHolds + " " + h.hold + d
                    } else {
                        allHolds = allHolds + ", " + h.hold + d
                    }
                })
                var artist = "";
                var venue = "";
                var venuecity = "";
                var venuestate = "";
                if (val.client) {
                    const { clients } = getState();
                    var fc = clients.clients.filter(item => item._id === val.client)
                    if (fc.length > 0) {
                        fc = fc[0];
                        artist = fc.client;
                    } else {
                        artist = "";
                    }
                }
                if (val.venue) {
                    const { items } = getState();
                    var fv = items.items.filter(item => item._id === val.venue)
                    if (fv.length > 0) {
                        fv = fv[0];
                        venue = fv.venue_name;
                        venuecity = fv.address.city;
                        venuestate = fv.address.state;
                    } else {
                        const { festivals } = getState();
                        var ff = festivals.items.filter(item => item._id === val.venue)
                        if (ff.length > 0) {
                            ff = ff[0];
                            venue = ff.venue_name;
                            venuecity = ff.address.city;
                            venuestate = ff.address.state;
                        } else {
                            venue = "Undefined";
                            venuecity = "";
                            venuestate = "";
                        }
                    }
                }
                val.holds.forEach((h, i) => {
                    const arrayOfApp = { title: artist, venue: venue, start: new Date(h.date), end: new Date(h.date), description: artist + '<br>' + '( Holds:' + allHolds + ' )' + '<br>' + venue, id: val._id, backgroundColor: '#fff', borderColor: '#ddd', sub: "holds", hold: h.hold, fil: 'holds', client_id: val.client || "Undefined", sub_id: h._id, venue_id: val.venue || "Undefined", artist: artist, venuecity: val.venue ? venuecity : '', venuestate: val.venue ? venuestate : '', all_holds: allHolds }
                    events.push(arrayOfApp);
                })
                filterEvents.filter(i => {
                    return !h_array.find(a => a === i._id)
                });
                newEvents = [...filterEvents, ...events];
            }
        }
    }
    if (type === 'holds/deleted') {
        //const { offers } = getState();
        const connect_id = val.connect_id;
        var filterEvents = calendar.events.filter(item => item.id !== val._id);
        var oldCheck = filterEvents.filter(item => item.id === connect_id);
        oldCheck = oldCheck.map(item => {
            if (item.hold) {
                item.hold = ''
            }
            return item
        })
        filterEvents = filterEvents.filter(item => item.id !== connect_id);
        newEvents = [...filterEvents, ...oldCheck];
    }
    if (type === 'calendar/updated') {
        var filterEvents = calendar.events.filter(item => item.id !== val._id);
        const arrayOfApp = { title: val.event, start: new Date(val.start), end: new Date(val.end), description: val.event + '<br>' + val.desc, id: val._id, backgroundColor: '#fff', borderColor: 'green', sub: "cal_entries", fil: 'cal_entries', client_id: val.client || "Undefined", sub_id: val._id, venue_id: val.venue || "Undefined" }
        newEvents = [...filterEvents, arrayOfApp];
        dispatch({
            type: CALENDAR_SOCKET,
            payload: val
        })
    }
    if (type === 'calendar/deleted') {
        var filterEvents = calendar.events.filter(item => item.id !== val._id);
        newEvents = [...filterEvents];
        dispatch({
            type: CALENDAR_SOCKET_DELETE,
            payload: val
        })
    }


    dispatch({
        type: CALENDAR_SET,
        payload: newEvents
    })
}

export const calendarSocketDelete = calendar => (dispatch) => {
    dispatch({
        type: CALENDAR_SOCKET_DELETE,
        payload: calendar
    })
}

export const deleteEntry = id => dispatch => {
    axios.delete(`${process.env.REACT_APP_API}/calendar/${id}`)
        .then(res =>
            dispatch({
                type: NOTIFICATIONS,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
}

// export const setCalendar = () => (dispatch, getState) => {
//     const calendar_set = getState().offers.calendar_set;
//     var events = [];
//     calendar_set.map(deal => {
//         var status = deal.status;
//         var artist = deal.artist_name;
//         var venue = deal.venue_name;
//         var venuecity = deal.venue_city;
//         var venuestate = deal.venue_state;
//         const color = []
//         if (status === 'Confirmed') {
//             color.push('#4460ff')
//             //color.push('green')
//         } else if (status === 'Pending') {
//             color.push('#333333')
//         } else if (status === 'Cancelled') {
//             color.push('red')
//         }
//         deal.show_info.forEach((item, index) => {
//             //var start = moment(item.date).format('YYYY-MM-DD');
//             const arrayOfApp = { title: artist, venue: venue, start: new Date(item.date), end: new Date(item.date), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: color, sub: "offer", fil: deal.status, client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
//             events.push(arrayOfApp)

//             deal.links.forEach(l => {
//                 if (item._id === l._id) {
//                     if (l.announce && l.announce.date) {
//                         var nd = moment(l.date);
//                         var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Announce", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
//                         events.push(arrayOfApp2)
//                     }
//                     if (l.presale && l.presale.date) {
//                         var nd = moment(l.date);
//                         var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Presale", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
//                         events.push(arrayOfApp2)
//                     }
//                     if (l.on_sale && l.on_sale.date) {
//                         var nd = moment(l.date);
//                         var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "On Sale", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
//                         events.push(arrayOfApp2)
//                     }
//                 }
//             })
//         })
//     })
//     var holds = getState().holds.holds;
//     if (holds && holds.length > 0) {
//         holds.map(item => {
//             var allHolds = "";
//             item.holds.forEach((h, i) => {
//                 var d = moment(h.date).format('D');
//                 if (i === 0) {
//                     allHolds = allHolds + " " + h.hold + d
//                 } else {
//                     allHolds = allHolds + ", " + h.hold + d
//                 }
//             })
//             item.holds.forEach((h, i) => {
//                 const arrayOfApp = { title: item.artist_name, venue: item.venue_name, start: new Date(h.date), end: new Date(h.date), description: item.artist_name + '<br>' + '( Holds:' + allHolds + ' )' + '<br>' + item.venue_name, id: item._id, backgroundColor: '#fff', borderColor: '#ddd', sub: "holds", hold: h.hold, fil: 'holds', client_id: item.client || "Undefined", sub_id: h._id, venue_id: item.venue || "Undefined", artist: item.artist_name, venuecity: item.venue_address.city, venuestate: item.venue_address.state }
//                 events.push(arrayOfApp);
//             })
//         })
//     }
//     var cal = getState().calendar.entries;
//     if (cal && cal.length > 0) {
//         cal.map(item => {
//             const arrayOfApp = { title: item.event, start: new Date(item.start), end: new Date(item.end), description: item.event + '<br>' + item.desc, id: item._id, backgroundColor: '#fff', borderColor: 'green', sub: "cal_entries", fil: 'cal_entries', client_id: item.client || "Undefined", sub_id: item._id, venue_id: item.venue || "Undefined" }
//             events.push(arrayOfApp);
//         })
//     }
//     dispatch({
//         type: CALENDAR_SET,
//         payload: events
//     })
// }

export const setCalendar = () => (dispatch, getState) => {
    const calendar_set = getState().offers.calendar_set;
    var holds = getState().holds.holds;
    var events = [];
    var h_array = [];
    var c_array = [];
    calendar_set.map(deal => {
        var status = deal.status;
        var artist = deal.artist_name;
        var venue = deal.venue_name;
        var venuecity = deal.venue_city;
        var venuestate = deal.venue_state;
        const color = [];
        if (status === 'Confirmed') {
            color.push('#4460ff')
            //color.push('green')
        } else if (status === 'Pending') {
            color.push('#333333')
        } else if (status === 'Cancelled') {
            color.push('red')
        }
        if (status !== 'Pending') {
            var h_ind = holds.findIndex(h => h.connect_id === deal._id);
            var h_arr = [];
            if (h_ind >= 0) {
                h_arr = holds[h_ind];
                c_array.push(h_arr._id);
            }
            deal.show_info.forEach((item, index) => {
                //var start = moment(item.date).format('YYYY-MM-DD');
                const arrayOfApp = { title: artist, venue: venue, start: new Date(item.date), end: new Date(item.date), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: color, sub: "offer", fil: deal.status, client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                events.push(arrayOfApp)
                deal.links.forEach(l => {
                    if (item._id === l._id) {
                        // var ann_date = null;
                        // var ann_data = null;
                        // var pre_date = null;
                        // var pre_data = null;
                        // var on_date = null;
                        // var on_data = null;

                        if (l.announce && l.announce.date) {
                            var nd = l.announce.date;
                            // ann_date = l.announce.date;
                            var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Announce", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status };
                            // ann_title = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Announce", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status };
                            events.push(arrayOfApp2)
                        }
                        if (l.presale && l.presale.date) {
                            var nd = l.presale.date;
                            // pre_date = l.presale.date;
                            var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Presale", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status };
                            // pre_title = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Presale", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status };
                            events.push(arrayOfApp2)
                        }
                        if (l.on_sale && l.on_sale.date) {
                            var nd = l.on_sale.date;
                            // on_date = l.on_sale.date;
                            var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "On Sale", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status };
                            // on_title = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "On Sale", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status };
                            events.push(arrayOfApp2)
                        }
                    }
                })
            })
        } else {
            var h_ind = holds.findIndex(h => h.connect_id === deal._id);
            var h_arr = [];
            var allHolds = null;
            if (h_ind >= 0) {
                h_arr = holds[h_ind];
                h_array.push(h_arr._id);
                h_arr.holds.forEach((h, i) => {
                    var d = moment(h.date).utc().format('D');
                    if (i === 0) {
                        allHolds = allHolds + " " + h.hold + d
                    } else {
                        allHolds = allHolds + ", " + h.hold + d
                    }
                })
                h_arr.holds.forEach((h, i) => {
                    deal.show_info.forEach((item, index) => {
                        //var start = moment(item.date).format('YYYY-MM-DD');
                        var check = h_arr.holds.findIndex(ck => new Date(ck.date).toString() === new Date(item.date).toString());
                        if (check >= 0) {
                            if (new Date(item.date).toString() === new Date(h.date).toString()) {
                                const arrayOfApp = { title: artist, venue: venue, start: new Date(moment(item.date).utc()), end: new Date(moment(item.date).utc()), description: artist + '<br>' + '(' + status + ' - Holds:' + allHolds + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: color, sub: "offer holds", hold: h.hold, fil: deal.status, client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status, all_holds: allHolds }
                                events.push(arrayOfApp)
                            } else {
                                const arrayOfAppB = { title: artist, venue: venue, start: new Date(moment(h.date).utc()), end: new Date(moment(h.date).utc()), description: artist + '<br>' + '(' + status + ' - Holds:' + allHolds + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: '#ddd', sub: "offer holds", hold: h.hold, fil: 'holds', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status, all_holds: allHolds }
                                events.push(arrayOfAppB)
                            }
                        } else {
                            if (i === 0) {
                                const arrayOfApp = { title: artist, venue: venue, start: new Date(moment(item.date).utc()), end: new Date(moment(item.date).utc()), description: artist + '<br>' + '(' + status + ' - Holds:' + allHolds + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: color, sub: "offer", hold: '', fil: deal.status, client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status, all_holds: allHolds }
                                events.push(arrayOfApp)
                            }
                            const arrayOfAppB = { title: artist, venue: venue, start: new Date(moment(h.date).utc()), end: new Date(moment(h.date).utc()), description: artist + '<br>' + '(' + status + ' - Holds:' + allHolds + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: '#ddd', sub: "offer holds", hold: h.hold, fil: 'holds', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status, all_holds: allHolds }
                            events.push(arrayOfAppB)
                        }
                    })
                })
                deal.show_info.forEach((item, index) => {
                    deal.links.forEach(l => {
                        if (item._id === l._id) {
                            if (l.announce && l.announce.date) {
                                var nd = l.announce.date;
                                var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Announce", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                                events.push(arrayOfApp2)
                            }
                            if (l.presale && l.presale.date) {
                                var nd = l.presale.date;
                                var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Presale", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                                events.push(arrayOfApp2)
                            }
                            if (l.on_sale && l.on_sale.date) {
                                var nd = l.on_sale.date;
                                var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "On Sale", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                                events.push(arrayOfApp2)
                            }
                        }
                    })
                })
            } else {
                deal.show_info.forEach((item, index) => {
                    //var start = moment(item.date).format('YYYY-MM-DD');
                    const arrayOfApp = { title: artist, venue: venue, start: new Date(item.date), end: new Date(item.date), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: color, sub: "offer", fil: deal.status, client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                    events.push(arrayOfApp)
                    deal.links.forEach(l => {
                        if (item._id === l._id) {
                            if (l.announce && l.announce.date) {
                                var nd = l.announce.date;
                                var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Announce", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                                events.push(arrayOfApp2)
                            }
                            if (l.presale && l.presale.date) {
                                var nd = l.presale.date;
                                var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "Presale", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                                events.push(arrayOfApp2)
                            }
                            if (l.on_sale && l.on_sale.date) {
                                var nd = l.on_sale.date;
                                var arrayOfApp2 = { title: artist, venue: venue, start: new Date(nd), end: new Date(nd), description: artist + '<br>' + '(' + status + ')' + '<br>' + venue + '<br>' + venuecity + ', ' + venuestate, id: deal._id, backgroundColor: '#fff', borderColor: 'orange', sub: "announce", type: "On Sale", fil: 'announce', client_id: deal.client_id || "Undefined", sub_id: item._id, venue_id: deal.venue_id || "Undefined", artist: artist, venuecity: venuecity, venuestate: venuestate, status: status }
                                events.push(arrayOfApp2)
                            }
                        }
                    })
                })
            }
        }

    })
    if (holds && holds.length > 0) {
        holds = holds.filter(i => {
            return !h_array.find(a => a === i._id)
        });
        holds = holds.filter(i => {
            return !c_array.find(a => a === i._id)
        });
        holds.map(item => {
            var allHolds = "";
            item.holds.forEach((h, i) => {
                var d = moment(h.date).utc().format('D');
                if (i === 0) {
                    allHolds = allHolds + " " + h.hold + d
                } else {
                    allHolds = allHolds + ", " + h.hold + d
                }
            })
            item.holds.forEach((h, i) => {
                const arrayOfApp = { title: item.artist_name, venue: item.venue_name, start: new Date(h.date), end: new Date(h.date), description: item.artist_name + '<br>' + '( Holds:' + allHolds + ' )' + '<br>' + item.venue_name, id: item._id, backgroundColor: '#fff', borderColor: '#ddd', sub: "holds", hold: h.hold, fil: 'holds', client_id: item.client || "Undefined", sub_id: h._id, venue_id: item.venue || "Undefined", artist: item.artist_name, venuecity: item.venue_address ? item.venue_address.city : '', venuestate: item.venue_address ? item.venue_address.state : '', all_holds: allHolds }
                events.push(arrayOfApp);
            })
        })
    }
    var cal = getState().calendar.entries;
    if (cal && cal.length > 0) {
        cal.map(item => {
            const arrayOfApp = { title: item.event, start: new Date(item.start), end: new Date(item.end), description: item.event + '<br>' + item.desc, id: item._id, backgroundColor: '#fff', borderColor: 'green', sub: "cal_entries", fil: 'cal_entries', client_id: item.client || "Undefined", sub_id: item._id, venue_id: item.venue || "Undefined" }
            events.push(arrayOfApp);
        })
    }
    dispatch({
        type: CALENDAR_SET,
        payload: events
    })
}

export const calendarListener = (calendar, type) => (dispatch) => {

    dispatch({
        type: CALENDAR_SOCKET,
        payload: calendar
    })
}

export const resetCalendar = () => (dispatch) => {
    dispatch({
        type: CALENDAR_RESET,
        payload: 'run reset'
    })
}