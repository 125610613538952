import React from 'react';
import { AlternateEmail } from '@material-ui/icons';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert'
import { returnErrors } from './errorActions';
//import openSocket from 'socket.io-client';
import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    CONNECT_ON,
    CONNECT_OFF,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    INIT_LOADING,
    INIT_LOADED,
    USERS_LOAD,
    USERS_IMGS,
    USERS_SOCKET,
    USERS_SOCKET_DELETE,
    USER_UPDATED,
    USER_UPDATED_GOOGLE,
    CHAT_SOCKET,
    CONTACTS_SOCKET_DELETE
} from "./types";

export const loadUser = () => (dispatch, getState) => {
    dispatch({ type: USER_LOADING });

    // Get token from localstorage
    const token = getState().auth.token;

    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    //If token, add to headers
    if (token) {
        config.headers['x-auth-token'] = token;
    }

    axios.get(`${process.env.REACT_APP_API}/auth/user`, tokenConfig(getState))
        .then(res => dispatch({
            type: USER_LOADED,
            payload: res.data
        }))
        .catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status));
            console.log(err)
            dispatch({
                type: AUTH_ERROR
            });
        });
};

//Register User
export const register = ({ name, email, password }) => dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ name, email, password });
    axios.post(`${process.env.REACT_APP_API}/users/add`, body, config)
        .then(res => dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL'));
            dispatch({
                type: REGISTER_FAIL
            });
        });
}

//Login User
export const login = ({ email, password }) => dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ email, password });
    axios.post(`${process.env.REACT_APP_API}/auth/add`, body, config)
        .then(res => {
            if (res.data.type === 'success' && Object.keys(res.data.data).length > 0) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data.data,
                })
            } else {
                alert(res.data.data)
                dispatch({
                    type: LOGIN_FAIL,
                    // payload: res.data.data,
                })
            }
        })
        .catch(err => {
            if (err.response) {
                dispatch(returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL'));
                dispatch({
                    type: LOGIN_FAIL
                });
            }
        });
}

// export const initSocket = (data) => {
//     const name = data.name.first + " " + data.name.last;
//     const socket = openSocket(`${process.env.REACT_APP_SOCKET}`, {
//         query: {
//             token: data.token,
//             name: name,
//             company: data.company.company
//         },
//     });
//     if (socket) {
//         return(
//             socket
//         )
//     } else {
//         return (
//             'error connecting'
//         )
//     }
// }

export const initLoading = () => {
    return {
        type: INIT_LOADING
    };
};


export const initLoaded = () => {
    return {
        type: INIT_LOADED
    };
};

export const loadSocket = socket => (dispatch) => {
    dispatch({
        type: CONNECT_ON,
        payload: socket
    })
}

export const connectOff = () => {
    return {
        type: CONNECT_OFF
    };
};

export const loadUsers = (users) => {
    return {
        type: USERS_LOAD,
        payload: users
    };
};

export const getProfileImage = (id) => (dispatch, getState) => {
    axios.get(`${process.env.REACT_APP_API}/users/profile/img/${id}`, tokenConfig(getState))
        .then(res => {
            var obj = {
                id: id,
                data: res.data
            }
            dispatch({
                type: USERS_IMGS,
                payload: obj
            })
        })
        .catch(err => {

        });
}

//Logout User
export const logout = () => (dispatch, getState) => {
    const socket = getState().auth.socket;
    if (socket) {
        socket.disconnect();
    }
    dispatch({
        type: LOGOUT_SUCCESS,
    })
    // return {
    //     type: LOGOUT_SUCCESS
    // };
};

// Setup config/headers and token
export const tokenConfig = getState => {
    // Get token from localstorage
    const token = getState().auth.token;

    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    //If token, add to headers
    if (token) {
        config.headers['x-auth-token'] = token;
    }

    return config;
};

export const tokenConfigFile = getState => {
    // Get token from localstorage
    const token = getState().auth.token;

    //Headers
    const config = {
        headers: {
            "Content-type": "multipart/form-data"
        }
    }

    //If token, add to headers
    if (token) {
        config.headers['x-auth-token'] = token;
    }

    return config;
};

export const verify = ({ email, password }) => dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ email, password });
    return axios.post(`${process.env.REACT_APP_API}/auth/verify`, body, config)
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const verifyToken = ({ token }) => dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ token });
    return axios.post(`${process.env.REACT_APP_API}/auth/verify/token`, body, config)
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const forgotPassword = user => dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(`${process.env.REACT_APP_API}/auth/reset/password`, user, config)
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const updatePassword = user => dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    return axios.post(`${process.env.REACT_APP_API}/auth/update/password`, user, config)
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const createPassword = user => dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    return axios.post(`${process.env.REACT_APP_API}/auth/create/password`, user, config)
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const uploadProfileImage = (file, key, date) => async (dispatch, getState) => {

    const info = [{
        key: key,
        date: date
    }]

    const json = JSON.stringify(info);
    const blob = new Blob([json], {
        type: 'application/json'
    });

    var formData = new FormData();
    formData.append('file', file);
    formData.set('info', blob);

    let data = {};

    // convert the key/value pairs
    for (var pair of formData.entries()) {
        data[pair[0]] = pair[1];
    }
    const token = getState().auth.token;

    return axios.post(`${process.env.REACT_APP_API}/user/update/img`, formData, tokenConfigFile(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

export const handleProfileSave = (file, data, id) => async (dispatch, getState) => {

    const info = [{
        name: data.name,
        address: data.address,
        contact: data.contact,
        tag: data.tag,
        _p: data._p
    }]

    const json = JSON.stringify(info);
    const blob = new Blob([json], {
        type: 'application/json'
    });

    var formData = new FormData();
    formData.append('file', file);
    formData.set('info', blob);

    return axios.post(`${process.env.REACT_APP_API}/users/profile/update/${id}`, formData, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: USER_UPDATED,
                payload: data
            })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const usersSocket = user => (dispatch) => {
    dispatch({
        type: USERS_SOCKET,
        payload: user
    })
}

export const usersSocketDelete = user => (dispatch) => {
    dispatch({
        type: USERS_SOCKET_DELETE,
        payload: user
    })
}

export const sendChatMessage = message => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/users/chat/message`, message, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //    type: NOTIFICATIONS,
            //    payload: res.data
            // })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const chatSocket = message => (dispatch) => {
    dispatch({
        type: CHAT_SOCKET,
        payload: message
    })
}

export const googleLogin = () => (dispatch) => {
    return axios.post(`${process.env.REACT_APP_API}/auth/google/login`, null)
        .then(res => {
            // window.open(res.data, "_blank");
            window.location.href = res.data;
            // return 'success'
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            alert(err.response.data.data)
            return err.response.data
        });
}

export const googleAuthCode = (loc, data) => (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    // new Promise(async (resolve, reject) => {

    //     axios.post(`${process.env.REACT_APP_API}/auth/google/code`, data, config)
    //         .then(res => {
    //             console.log(res.data)
    //             if (res.data.type === 'success' && Object.keys(res.data.data).length > 0) {
    //                 // dispatch({
    //                 //     type: REGISTER_SUCCESS,
    //                 //     payload: res.data.data
    //                 // })
    //                 resolve({ type: 'success' })
    //             } else if (res.data.type === 'connect') {
    //                 resolve({ type: 'connect', data: res.data.data })
    //             } else {
    //                 resolve(res.data)
    //             }
    //         })
    //         .catch(err => {
    //             //dispatch(returnErrors(err.response.data, err.response.status))
    //             resolve({ type: 'error', data: err })
    //         });
    // })

    axios.post(`${process.env.REACT_APP_API}/auth/google/code`, data, config)
        .then(res => {
            console.log(res.data, 'RES DATA')
            if (res.data.type === 'success' && Object.keys(res.data.data).length > 0) {
                loc.history.push('/')
                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: res.data.data
                })
                // resolve({ type: 'success' })
            } else if (res.data.type === 'connect') {
                const newObj = {
                    code: data.code,
                    email: res.data.data.email,
                    passcode: res.data.data.passcode
                }
                const msg = { type: 'connect', data: res.data.data };
                dispatch(googleLinkDialog(newObj, msg, loc))
                // console.log(res.data)
                // resolve({ type: 'connect', data: res.data.data })
            } else if (res.data.type === 'error') {
                // console.log(res.data)
                // resolve(res.data)
                alert(res.data.data)
                loc.history.push('/')
            } else if (res.data.type === 'msg') {
                alert(res.data.data)
                loc.history.push('/')
            } else {
                loc.history.push('/')
            }
        })
        .catch(err => {
            console.log(err)
            // alert('google login error')
            //dispatch(returnErrors(err.response.data, err.response.status))
            // resolve({ type: 'error', data: err })
        });
}

export const googleLinkAccount = (data, loc) => (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    axios.post(`${process.env.REACT_APP_API}/auth/google/code/link`, data, config)
        .then(res => {
            console.log(res.data, 'RES DATA2')
            if (res.data.type === 'success' && Object.keys(res.data.data).length > 0) {
                loc.history.push('/')
                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: res.data.data
                })
                // return { type: 'success' }
            } else if (res.data.type === 'connect') {
                // return { type: 'connect' }
            } else if (res.data.type === 'msg') {
                // return { type: 'msg', data: res.data.data }
            }
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return { type: 'error', data: err }
        });
}

export const connectGmail = user => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/auth/gmail/request`, null, tokenConfig(getState))
        .then(res => {

            window.open(res.data, "_blank");
            // dispatch({
            //     type: USER_UPDATED_GOOGLE,
            //     payload: res.data
            // })
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const removeGmail = user => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/auth/gmail/remove`, null, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: USER_UPDATED_GOOGLE,
                payload: res.data
            })
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
        });
}


export const googleLinkDialog = (newObj, msg, loc) => (dispatch) => {
    const data = newObj;
    const Dialog = ({ onClose }) => {
        return (
            <div className="react-confirm-alert-google">
                <div style={{ width: '65%', height: '100%', padding: 15 }}>
                    <h1 style={{ fontSize: 22, fontWeight: 'bold', color: '#fff' }}>Link Google Account:</h1>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', color: '#fff' }}>
                        By linking your Google account, we will switch your authentication method for accessing your Solar System account from email address to Log in with Google. Moving forward, your credentials will be managed by your Google account.
                    </div>
                    <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                        <button style={{ borderRadius: 10 }} onClick={() => { dispatch(googleLinkAccount(data, loc)); onClose(); }}>Yes, Link My Account</button>
                        <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { onClose(); loc.history.push('/') }}>Cancel, Return to Login</button>
                    </div>
                </div>
            </div>
        )
    }
    const options = {
        closeOnEscape: true,
        closeOnClickOutside: true,
        customUI: Dialog
    }
    confirmAlert(options)
}