import {
    FINALS_LOADING,
    FINALS_LOAD,
    FINALS_FILTER,
    FINALS_SORT,
    FINALS_SOCKET,
    FINALS_SOCKET_DELETE
} from "../actions/types";
import _ from 'lodash';
import moment from 'moment';

const initialState = {
    finals: [],
    filtered_finals: [],
    filter: {
        deal_id: '',
        status: 'Confirmed',
        client: '',
        agent: '',
        start_date: moment().format('MM/DD/YYYY'),
        end_date: moment().add(1, 'years').format('MM/DD/YYYY'),
        venue: '',
        city: '',
        state: '',
        country: '',
        unsettled: false
    },
    loading: false,
    sort: {
        cat: '',
        dir: 'desc'
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FINALS_LOADING:
            return {
                ...state,
                loading: true
            };
        case FINALS_LOAD:
            return {
                ...state,
                filtered_finals: action.payload.rol,
                finals: action.payload.all,
                loading: false
            };
        case FINALS_FILTER:
            var filter = action.payload;
            var finals = state.finals;
            var filtered_finals = finals.filter((item) => {
                var filterbatch = item.status
                return filterbatch.indexOf('Confirmed') >= 0
            })
            filtered_finals = filtered_finals.filter((item) => {
                var filterBatch = new Date(item.date)
                var start = new Date();
                return filterBatch < start
            })
            if (filter.unsettled) {
                filtered_finals = filtered_finals.filter(item => item.prog.settled === false)
            }
            if (filter.client) {
                filtered_finals = filtered_finals.filter((item) => {
                    var filterBatch = item.client_id
                    return filterBatch.indexOf(filter.client) >= 0
                })
            }
            if (filter.deal_id) {
                filtered_finals = filtered_finals.filter((item) => {
                    var filterBatch = item._id
                    return filterBatch.indexOf(filter.deal_id) >= 0
                })
            }
            if (filter.venue) {
                filtered_finals = filtered_finals.filter((item) => {
                    var filterBatch = item.venue_name
                    return filterBatch.indexOf(filter.venue) >= 0
                })
            }
            if (filter.state) {
                filtered_finals = filtered_finals.filter((item) => {
                    var filterBatch = item.venue_state
                    return filterBatch.indexOf(filter.state) >= 0
                })
            }
            if (filter.city) {
                filtered_finals = filtered_finals.filter((item) => {
                    var filterBatch = item.venue_city
                    return filterBatch.indexOf(filter.city) >= 0
                })
            }
            if (filter.country) {
                filtered_finals = filtered_finals.filter((item) => {
                    var filterBatch = item.venue_country
                    return filterBatch.indexOf(filter.country) >= 0
                })
            }
            filtered_finals = _.orderBy(filtered_finals, function (dateObj) {
                return new Date(dateObj.date);
            }, 'desc');
            return {
                ...state,
                filtered_finals: filtered_finals,
                filter: action.payload,
                loading: false
            };
        case FINALS_SORT:
            var sort = action.payload;
            var sortedFinals = [];
            if (sort.cat === '') {
                sortedFinals = _.sortBy(state.filtered_finals, function (dateObj) {
                    return new Date(dateObj.date);
                });
            } else {
                sortedFinals = _.orderBy(state.filtered_finals, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            }
            return {
                ...state,
                filtered_finals: sortedFinals,
                sort: sort,
                loading: false
            };
        case FINALS_SOCKET:
            var data = state.finals;
            var f_data = state.filtered_finals;
            action.payload.forEach(show => {
                var index = state.finals.findIndex(obj => obj._id === show._id && obj.date_id === show.date_id);
                var f_index = state.filtered_finals.findIndex(obj => obj._id === show._id && obj.date_id === show.date_id);
                if (index >= 0) {
                    data[index] = show;
                    if (f_index >= 0) {
                        f_data[f_index] = show;
                    }
                } else {
                    data = [show, ...state.offers];
                    var finals_filter = state.filter;
                    f_data = data;
                    if (finals_filter.start_date) {
                        f_data = f_data.filter((item) => {
                            const today = moment();
                            var val = _.get(item, 'show_info')
                            const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                            var filterBatch = new Date(closest.date)
                            var start = new Date(finals_filter.start_date)
                            return filterBatch > start
                        })
                    }
                    if (finals_filter.end_date) {
                        f_data = f_data.filter((item) => {
                            const today = new Date();
                            var val = _.get(item, 'show_info')
                            const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                            var filterBatch = new Date(furthest.date)
                            var end = new Date(finals_filter.end_date)
                            return filterBatch <= end
                        })
                    }
                    if (finals_filter.client) {
                        f_data = f_data.filter((item) => {
                            if (item.client_id) {
                                var filterBatch = item.client_id
                                return filterBatch.indexOf(finals_filter.client) >= 0
                            }
                        })
                    }
                    if (finals_filter.deal_id) {
                        f_data = f_data.filter((item) => {
                            var filterBatch = item._id
                            return filterBatch.indexOf(finals_filter.deal_id) >= 0
                        })
                    }
                    if (finals_filter.venue) {
                        f_data = f_data.filter((item) => {
                            var filterBatch = item.venue_name
                            return filterBatch.indexOf(finals_filter.venue) >= 0
                        })
                    }
                    if (finals_filter.state) {
                        f_data = f_data.filter((item) => {
                            var filterBatch = item.venue_state
                            return filterBatch.indexOf(finals_filter.state) >= 0
                        })
                    }
                    if (finals_filter.city) {
                        f_data = f_data.filter((item) => {
                            var filterBatch = item.venue_city
                            return filterBatch.indexOf(finals_filter.city) >= 0
                        })
                    }
                    if (finals_filter.country) {
                        f_data = f_data.filter((item) => {
                            var filterBatch = item.venue_country
                            return filterBatch.indexOf(finals_filter.country) >= 0
                        })
                    }

                }
            })

            f_data = _.orderBy(f_data, function (dateObj) {
                return new Date(dateObj.date);
            }, 'desc');

            // var sort = state.sort;
            // if (sort.cat === '') {
            //     f_data = _.sortBy(f_data, function (dateObj) {
            //         return new Date(dateObj.date);
            //     });
            // } else {
            //     f_data = _.sortBy(f_data, function (dateObj) {
            //         return new Date(dateObj.date);
            //     });
            //     f_data = _.orderBy(f_data, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            // }

            return {
                ...state,
                finals: data,
                filtered_finals: f_data
            };
        case FINALS_SOCKET_DELETE:
            return {
                ...state,
                finals: state.finals.filter(offer => offer._id !== action.payload._id),
                filtered_finals: state.filtered_finals.filter(offer => offer._id !== action.payload._id),
            };
        default:
            return state;
    }
}