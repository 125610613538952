import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { FaMosque, FaShoppingCart, FaTicketAlt, FaBoxes, FaEye, FaDatabase } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
    },
    '& :focus': {
      outline: 'none'
    },
  },
}));

export default function GridsButtonGroup(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonGroup
        orientation="vertical"
        color="default"
        variant="text"
        aria-label="outlined button group"
        style={{width: '100%'}}
      >
        <Button style={{backgroundColor: 'rgb(20, 20, 20)', height: 'auto'}} onClick={() => props.toggleSub('counts')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaTicketAlt size="20" color={props.subroute === "counts" ? '#673ab7' : '#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: props.subroute === "counts" ? '2px solid #673ab7' : '2px solid #cdcdcd' }}>Counts</p></div></Button>
        <Button style={{backgroundColor: 'rgb(20, 20, 20)', height: 'auto', borderTop: '1px solid #333'}} onClick={() => props.toggleSub('packaging')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaBoxes size="20" color={props.subroute === "packaging" ? '#4db6ac' : '#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: props.subroute === "packaging" ? '2px solid #4db6ac' : '2px solid #cdcdcd', opacity: .8 }}>Packaging</p></div></Button>
        <Button style={{backgroundColor: 'rgb(20, 20, 20)', height: 'auto', borderTop: '1px solid #333'}} onClick={() => props.toggleSub('ar')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaEye size="20" color={props.subroute === "ar" ? 'rgb(146, 146, 38)' : '#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: props.subroute === "ar" ? '2px solid rgb(146, 146, 38)' : '2px solid #cdcdcd' }}>A&R</p></div></Button>
        <Button style={{backgroundColor: '#333', height: 'auto', borderTop: '1px solid #333', borderBottom: '1px solid #333'}} onClick={() => props.toggleButtons('database')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaDatabase size="20" color={'#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: '2px solid #cdcdcd' }}>Database</p></div></Button>
      </ButtonGroup>
    </div>
  );
}