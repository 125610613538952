import React, { useEffect, useState } from "react";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block'
  },
  input: {
    width: '100%',
    fontSize: 12,
    paddingTop: '2%', paddingBottom: '2%', paddingLeft: '4%',
    height: 28
  },
  listbox: {
    width: '48%',
    margin: 0,
    padding: 10,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    fontSize: 12,
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#e6f7ff',
      color: 'black',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#e6f7ff',
      color: 'black', 
    },
  },
}));

export default function ContactTagsAutocomplete({ tagsList, onChange, tagSelect }) {
  const [stateTagsList, setTagsList] = useState(tagsList);
  const [stateTagSelect, setTagSelect] = useState(tagSelect);

  useEffect(() => {
    setTagsList(tagsList);
  }, [tagsList])
  useEffect(() => {
    setTagSelect(tagSelect);
  }, [tagSelect])

  const classes = useStyles();
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    name: 'contact',
    value: stateTagSelect,
    options: stateTagsList,
    getOptionLabel: (option) => { if(option.name){ return option.name.first + " " + option.name.last } else { return option.first_name + " " + option.last_name }},
    getOptionSelected: (option, value) => option._id === value._id,
    onChange: (event, newValue) => {
      if (newValue) {
        onChange(newValue)
      } else if (!newValue) {
        onChange('')
      };
    }
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input className={classes.input} {...getInputProps()} placeholder="Search"/>
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li style={{ padding: 5 }} {...getOptionProps({ option, index })}>{option.name.first} {option.name.last} ({option.contact.email})</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}