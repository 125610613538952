import {
    PDF_LOADING,
    PDF_LOADED,
    PDF_CLEAR,
} from "../actions/types";

const initialState = {
    loading: false,
    pdf: [],
    download: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PDF_LOADING:
            return {
                ...state,
                loading: true
            };
        case PDF_LOADED:
            return {
                ...state,
                pdf: [...state.pdf, action.payload],
                loading: false
            };
        case PDF_CLEAR:
            return {
                ...state,
                pdf: [],
                loading: false
            };
        default:
            return state;
    }
}