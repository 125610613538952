import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';

class Permissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: { admin: false, clients: false, venues: false, buyers: false, offers: false, contacts: false, festivals: false },
            offer_options: { access_all: true, financials: false, counts: false, deal_terms: false },
            offer_edit: false,
            client_edit: false,
            venue_edit: false,
            buyer_edit: false,
            contact_edit: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        offers: PropTypes.object.isRequired,
    }

    componentDidMount() {

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    toggleDetailEdit = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    }

    render() {

        return (
            <div style={{ display: 'flex', width: '100%', height: 550, overflowY: 'scroll' }}>
                <div style={{ width: '95%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', marginLeft: 60, marginTop: 30, width: '100%' }}>
                        <label style={{ width: '100%', fontSize: 15, color: 'rgb(1, 22, 48)' }}>User Permissions:</label>
                        <span style={{ display: 'flex', flexDirection: 'column', height: 'auto', marginTop: 20, width: '90%', backgroundColor: 'rgb(1, 22, 48)' }} className="form-control"></span>
                    </div>
                    {/* <div style={{ marginTop: 15, marginLeft: 60 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.permissions.admin}
                                    onChange={this.props.handlePermChange}
                                    name="admin"
                                    color="primary"
                                />
                            }
                            label="Admin"
                        />
                        <p style={{ fontSize: 12, color: '#000', marginLeft: 40 }}>*Admin's have access to see and edit all user and database data</p>
                        {!this.props.permissions.admin ?
                            <FormGroup column>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.props.permissions.offers}
                                            onChange={this.props.handlePermOfferChange}
                                            name="offers"
                                            color="primary"
                                        />
                                    }
                                    label={<div style={{ display: 'flex', flexDirection: 'row', width: 'auto', alignItems: 'center' }}><span style={{ fontSize: 14, marginRight: 15 }}>Offers</span><span style={{ fontSize: 11, fontStyle: 'italic', color: 'blue', marginRight: 5 }}>(read and write all offer data)</span>{this.props.edit ? <button style={{ width: 'auto', height: 'auto', fontSize: 10, borderRadius: 10 }} type="button" onClick={() => this.toggleDetailEdit('offer_edit')}>Edit</button> : null} </div>}
                                />

                                {this.props.permissions.offers && this.state.offer_edit ?
                                    <FormGroup style={{ marginLeft: 60 }}>
                                        <FormControlLabel
                                            control={<Checkbox size="small" color="primary" checked={this.props.offer_options.access_all} onChange={this.props.handlePermSubChange} name="access_all" />}
                                            label={<span style={{ fontSize: 12 }}>Read and write all offer data</span>}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox size="small" color="primary" disabled={this.props.offer_options.access_all ? true : false} checked={this.props.offer_options.access_all ? true : this.props.offer_options.deal_terms} onChange={this.props.handlePermSubChange} name="deal_terms" />}
                                            label={<span style={{ fontSize: 12 }}>Deal Terms</span>}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox size="small" color="primary" disabled={this.props.offer_options.access_all ? true : false} checked={this.props.offer_options.access_all ? true : this.props.offer_options.counts} onChange={this.props.handlePermSubChange} name="counts" />}
                                            label={<span style={{ fontSize: 12 }}>Ticket Counts & Marketing</span>}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox size="small" color="primary" disabled={this.props.offer_options.access_all ? true : false} checked={this.props.offer_options.access_all ? true : this.props.offer_options.financials} onChange={this.props.handlePermSubChange} name="financials" />}
                                            label={<span style={{ fontSize: 12 }}>Final Totals</span>}
                                        />
                                    </FormGroup> : null}
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={this.props.permissions.offers ? true : false}
                                            checked={this.props.permissions.clients}
                                            onChange={this.props.handlePermChange}
                                            name="clients"
                                            color="primary"
                                        />
                                    }
                                    label={<div style={{ display: 'flex', flexDirection: 'row', width: 'auto', alignItems: 'center' }}><span style={{ fontSize: 14, marginRight: 15 }}>Clients</span><span style={{ fontSize: 11, fontStyle: 'italic', color: 'blue', marginRight: 5 }}>(read and write all client data)</span></div>}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={this.props.permissions.offers ? true : false}
                                            checked={this.props.permissions.venues}
                                            onChange={this.props.handlePermChange}
                                            name="venues"
                                            color="primary"
                                        />
                                    }
                                    label={<div style={{ display: 'flex', flexDirection: 'row', width: 'auto', alignItems: 'center' }}><span style={{ fontSize: 14, marginRight: 15 }}>Venues</span><span style={{ fontSize: 11, fontStyle: 'italic', color: 'blue', marginRight: 5 }}>(read and write all venue data)</span></div>}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={this.props.permissions.offers ? true : false}
                                            checked={this.props.permissions.buyers}
                                            onChange={this.props.handlePermChange}
                                            name="buyers"
                                            color="primary"
                                        />
                                    }
                                    label={<div style={{ display: 'flex', flexDirection: 'row', width: 'auto', alignItems: 'center' }}><span style={{ fontSize: 14, marginRight: 15 }}>Buyers</span><span style={{ fontSize: 11, fontStyle: 'italic', color: 'blue', marginRight: 5 }}>(read and write all buyer data)</span></div>}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={this.props.permissions.offers ? true : false}
                                            checked={this.props.permissions.contacts}
                                            onChange={this.props.handlePermChange}
                                            name="contacts"
                                            color="primary"
                                        />
                                    }
                                    label={<div style={{ display: 'flex', flexDirection: 'row', width: 'auto', alignItems: 'center' }}><span style={{ fontSize: 14, marginRight: 15 }}>Contacts</span><span style={{ fontSize: 11, fontStyle: 'italic', color: 'blue', marginRight: 5 }}>(read and write all contact data)</span></div>}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={this.props.permissions.offers ? true : false}
                                            checked={this.props.permissions.festivals}
                                            onChange={this.props.handlePermChange}
                                            name="festivals"
                                            color="primary"
                                        />
                                    }
                                    label={<div style={{ display: 'flex', flexDirection: 'row', width: 'auto', alignItems: 'center' }}><span style={{ fontSize: 14, marginRight: 15 }}>Festivals</span><span style={{ fontSize: 11, fontStyle: 'italic', color: 'blue', marginRight: 5 }}>(read and write all festival data)</span></div>}
                                />
                            </FormGroup> : null}
                    </div> */}
                    <div style={{ marginTop: 15, marginLeft: 60 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.permissions.admin}
                                    onChange={this.props.handlePermChange}
                                    name="admin"
                                    color="primary"
                                    disabled={!this.props.edit}
                                />
                            }
                            label="Admin"
                        />
                        <p style={{ fontSize: 12, color: '#000', marginLeft: 40 }}>*Admin's have access to see and edit all user and database data</p>
                        {/* {!this.props.permissions.admin ? */}
                            <table style={{ width: '90%' }} id="permissions-table">
                                <thead>
                                    <tr>
                                        <th> </th>
                                        <th>Full Access</th>
                                        <th>Read</th>
                                        <th>Write</th>
                                        <th>Delete</th>
                                        <th>Reports</th>
                                        <th>Financials</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Offers</td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.permissions.admin} onChange={(e) => this.props.handlePermSubChange(e, 'offer_options')} name="full" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.permissions.admin ? true : this.props.offer_options.read} onChange={(e) => this.props.handlePermSubChange(e, 'offer_options')} name="read" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.permissions.admin ? true : this.props.offer_options.write} onChange={(e) => this.props.handlePermSubChange(e, 'offer_options')} name="write" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.permissions.admin ? true : this.props.offer_options.delete} onChange={(e) => this.props.handlePermSubChange(e, 'offer_options')} name="delete" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.permissions.admin ? true : this.props.offer_options.reports} onChange={(e) => this.props.handlePermSubChange(e, 'offer_options')} name="reports" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.permissions.admin ? true : this.props.offer_options.financials} onChange={(e) => this.props.handlePermSubChange(e, 'offer_options')} name="financials" /></td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>Clients</td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.client_options.full || this.props.permissions.admin} onChange={(e) => this.props.handlePermSubChange(e, 'client_options')} name="full" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.client_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.client_options.full || this.props.permissions.admin ? true : this.props.client_options.read} onChange={(e) => this.props.handlePermSubChange(e, 'client_options')} name="read" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.client_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.client_options.full || this.props.permissions.admin ? true : this.props.client_options.write} onChange={(e) => this.props.handlePermSubChange(e, 'client_options')} name="write" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.client_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.client_options.full || this.props.permissions.admin ? true : this.props.client_options.delete} onChange={(e) => this.props.handlePermSubChange(e, 'client_options')} name="delete" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.client_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.client_options.full || this.props.permissions.admin ? true : this.props.client_options.reports} onChange={(e) => this.props.handlePermSubChange(e, 'client_options')} name="reports" /></td>
                                        <td> </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>Venues</td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.venue_options.full || this.props.permissions.admin} onChange={(e) => this.props.handlePermSubChange(e, 'venue_options')} name="full" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.venue_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.venue_options.full || this.props.permissions.admin ? true : this.props.venue_options.read} onChange={(e) => this.props.handlePermSubChange(e, 'venue_options')} name="read" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.venue_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.venue_options.full || this.props.permissions.admin ? true : this.props.venue_options.write} onChange={(e) => this.props.handlePermSubChange(e, 'venue_options')} name="write" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.venue_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.venue_options.full || this.props.permissions.admin ? true : this.props.venue_options.delete} onChange={(e) => this.props.handlePermSubChange(e, 'venue_options')} name="delete" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.venue_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.venue_options.full || this.props.permissions.admin ? true : this.props.venue_options.reports} onChange={(e) => this.props.handlePermSubChange(e, 'venue_options')} name="reports" /></td>
                                        <td> </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>Buyers</td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.buyer_options.full || this.props.permissions.admin} onChange={(e) => this.props.handlePermSubChange(e, 'buyer_options')} name="full" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.buyer_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.buyer_options.full || this.props.permissions.admin ? true : this.props.buyer_options.read} onChange={(e) => this.props.handlePermSubChange(e, 'buyer_options')} name="read" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.buyer_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.buyer_options.full || this.props.permissions.admin ? true : this.props.buyer_options.write} onChange={(e) => this.props.handlePermSubChange(e, 'buyer_options')} name="write" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.buyer_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.buyer_options.full || this.props.permissions.admin ? true : this.props.buyer_options.delete} onChange={(e) => this.props.handlePermSubChange(e, 'buyer_options')} name="delete" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.buyer_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.buyer_options.full || this.props.permissions.admin ? true : this.props.buyer_options.reports} onChange={(e) => this.props.handlePermSubChange(e, 'buyer_options')} name="reports" /></td>
                                        <td> </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>Contacts</td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.contact_options.full || this.props.permissions.admin} onChange={(e) => this.props.handlePermSubChange(e, 'contact_options')} name="full" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.contact_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.contact_options.full || this.props.permissions.admin ? true : this.props.contact_options.read} onChange={(e) => this.props.handlePermSubChange(e, 'contact_options')} name="read" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.contact_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.contact_options.full || this.props.permissions.admin ? true : this.props.contact_options.write} onChange={(e) => this.props.handlePermSubChange(e, 'contact_options')} name="write" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.contact_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.contact_options.full || this.props.permissions.admin ? true : this.props.contact_options.delete} onChange={(e) => this.props.handlePermSubChange(e, 'contact_options')} name="delete" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.contact_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.contact_options.full || this.props.permissions.admin ? true : this.props.contact_options.reports} onChange={(e) => this.props.handlePermSubChange(e, 'contact_options')} name="reports" /></td>
                                        <td> </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>Festivals</td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.festival_options.full || this.props.permissions.admin} onChange={(e) => this.props.handlePermSubChange(e, 'festival_options')} name="full" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.festival_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.festival_options.full || this.props.permissions.admin ? true : this.props.festival_options.read} onChange={(e) => this.props.handlePermSubChange(e, 'festival_options')} name="read" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.festival_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.festival_options.full || this.props.permissions.admin ? true : this.props.festival_options.write} onChange={(e) => this.props.handlePermSubChange(e, 'festival_options')} name="write" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.festival_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.festival_options.full || this.props.permissions.admin ? true : this.props.festival_options.delete} onChange={(e) => this.props.handlePermSubChange(e, 'festival_options')} name="delete" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.offer_options.full || this.props.festival_options.full || this.props.permissions.admin} checked={this.props.offer_options.full || this.props.festival_options.full || this.props.permissions.admin ? true : this.props.festival_options.reports} onChange={(e) => this.props.handlePermSubChange(e, 'festival_options')} name="reports" /></td>
                                        <td> </td>
                                    </tr>
                                </tbody>
                                {/* <tbody>
                                    <tr>
                                        <td>Ticket Counts</td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit} checked={this.props.count_options.full} onChange={(e) => this.props.handlePermSubChange(e, 'count_options')} name="full" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.count_options.full} checked={this.props.count_options.full ? true : this.props.count_options.read} onChange={(e) => this.props.handlePermSubChange(e, 'count_options')} name="read" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.count_options.full} checked={this.props.count_options.full ? true : this.props.count_options.write} onChange={(e) => this.props.handlePermSubChange(e, 'count_options')} name="write" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.count_options.full} checked={this.props.count_options.full ? true : this.props.count_options.delete} onChange={(e) => this.props.handlePermSubChange(e, 'count_options')} name="delete" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.count_options.full} checked={this.props.count_options.full ? true : this.props.count_options.reports} onChange={(e) => this.props.handlePermSubChange(e, 'count_options')} name="reports" /></td>
                                        <td> </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>Packaging</td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit} checked={this.props.packaging_options.full} onChange={(e) => this.props.handlePermSubChange(e, 'packaging_options')} name="full" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.packaging_options.full} checked={this.props.packaging_options.full ? true : this.props.packaging_options.read} onChange={(e) => this.props.handlePermSubChange(e, 'packaging_options')} name="read" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.packaging_options.full} checked={this.props.packaging_options.full ? true : this.props.packaging_options.write} onChange={(e) => this.props.handlePermSubChange(e, 'packaging_options')} name="write" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.packaging_options.full} checked={this.props.packaging_options.full ? true : this.props.packaging_options.delete} onChange={(e) => this.props.handlePermSubChange(e, 'packaging_options')} name="delete" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.packaging_options.full} checked={this.props.packaging_options.full ? true : this.props.packaging_options.reports} onChange={(e) => this.props.handlePermSubChange(e, 'packaging_options')} name="reports" /></td>
                                        <td> </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>A&R</td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit} checked={this.props.ar_options.full} onChange={(e) => this.props.handlePermSubChange(e, 'ar_options')} name="full" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.ar_options.full} checked={this.props.ar_options.full ? true : this.props.ar_options.read} onChange={(e) => this.props.handlePermSubChange(e, 'ar_options')} name="read" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.ar_options.full} checked={this.props.ar_options.full ? true : this.props.ar_options.write} onChange={(e) => this.props.handlePermSubChange(e, 'ar_options')} name="write" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.ar_options.full} checked={this.props.ar_options.full ? true : this.props.ar_options.delete} onChange={(e) => this.props.handlePermSubChange(e, 'ar_options')} name="delete" /></td>
                                        <td><Checkbox size="small" color="primary" disabled={!this.props.edit || this.props.ar_options.full} checked={this.props.ar_options.full ? true : this.props.ar_options.reports} onChange={(e) => this.props.handlePermSubChange(e, 'ar_options')} name="reports" /></td>
                                        <td> </td>
                                    </tr>
                                </tbody> */}
                            </table>
                    </div>
                </div>
            </div>
        )
    }

}


const mapStateToProps = state => ({
    offers: state.offers,
});

export default connect(mapStateToProps, null)(Permissions);