import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { FaMosque, FaShoppingCart, FaIdCardAlt, FaCaretUp, FaCaretDown, FaFlagCheckered, FaMicrophoneAlt, FaAtom, FaToolbox } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
    },
    '& :focus': {
      outline: 'none'
    },
  },
}));

export default function ContactButtonGroup(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonGroup
        orientation="vertical"
        color="default"
        variant="text"
        aria-label="outlined button group"
        style={{width: '100%'}}
      >
        <Button style={{backgroundColor: 'rgb(20, 20, 20)', height: 'auto'}} onClick={() => props.toggleSub('offers')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaAtom size="20" color={props.subroute === "offers" ? 'green' : '#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: props.subroute === "offers" ? '2px solid green' : '2px solid #cdcdcd' }}>Offers</p></div></Button>
        <Button style={{backgroundColor: 'rgb(20, 20, 20)', height: 'auto', borderTop: '1px solid #333'}} onClick={() => props.toggleSub('venues')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaMosque size="20" color={props.subroute === "venues" ? '#536dfe' : '#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: props.subroute === "venues" ? '2px solid #536dfe' : '2px solid #cdcdcd', opacity: .8 }}>Venues</p></div></Button>
        <Button style={{backgroundColor: 'rgb(20, 20, 20)', height: 'auto', borderTop: '1px solid #333'}} onClick={() => props.toggleSub('buyers')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaShoppingCart size="20" color={props.subroute === "buyers" ? '#42a5f5' : '#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: props.subroute === "buyers" ? '2px solid #42a5f5' : '2px solid #cdcdcd' }}>Buyers</p></div></Button>
        <Button style={{backgroundColor: 'rgb(20, 20, 20)', height: 'auto', borderTop: '1px solid #333'}} onClick={() => props.toggleSub('contacts')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaIdCardAlt size="20" color={props.subroute === "contacts" ? 'rgb(146, 146, 38)' : '#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: props.subroute === "contacts" ? '2px solid rgb(146, 146, 38)' : '2px solid #cdcdcd' }}>Contacts</p></div></Button>
        <Button style={{backgroundColor: 'rgb(20, 20, 20)', height: 'auto', borderTop: '1px solid #333'}} onClick={() => props.toggleSub('clients')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaMicrophoneAlt size="20" color={props.subroute === "clients" ? 'rgb(165, 107, 0)' : '#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: props.subroute === "clients" ? '2px solid rgb(165, 107, 0)' : '2px solid #cdcdcd' }}>Clients</p></div></Button>
        <Button style={{backgroundColor: 'rgb(20, 20, 20)', height: 'auto', borderTop: '1px solid #333', borderBottom: '1px solid #333'}} onClick={() => props.toggleSub('festivals')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaFlagCheckered size="20" color={props.subroute === "festivals" ? 'rgb(185, 2, 2)' : '#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: props.subroute === "festivals" ? '2px solid rgb(185, 2, 2)' : '2px solid #cdcdcd' }}>Festivals</p></div></Button>
        <Button style={{backgroundColor: '#333', height: 'auto', borderTop: '1px solid #333', borderBottom: '1px solid #333'}} onClick={() => props.toggleButtons('tools')}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10}}><FaToolbox size="20" color={'#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: '2px solid #cdcdcd' }}>Toolbox</p></div></Button>
      </ButtonGroup>
    </div>
  );
}