import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';

class FinalTotals extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            date1Toggle: false,
            date2Toggle: false,
            date3Toggle: false,
            date4Toggle: false,
            date5Toggle: false,
            date6Toggle: false,
            depositadd: 1,
            withholding: false,
            deductions: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    componentDidMount() {
        var withholding = false;
        var deductions = false;
        if (this.props.settlement.withholding) {
            withholding = true;
        }
        if (this.props.settlement.deductions) {
            deductions = true;
        }
        this.setState({
            deductions: deductions,
            withholding: withholding
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    toggleWithholding = () => {
        this.setState({
            withholding: !this.state.withholding,
            withholdingamount: ''
        })
    }

    toggleDeductions = () => {
        this.setState({
            deductions: !this.state.deductions,
            deductionsamount: ''
        })
    }

    render() {

        var today = moment();
        var date_comp = moment(this.props.show_info_conf[0].date);

        var walkout = [];
        if (this.props.settlement.overage) {
            walkout = Number(this.props.guarantee) + Number(this.props.settlement.overage) - Number(this.props.settlement.withholding) - Number(this.props.settlement.deductions);
        } else {
            walkout = Number(this.props.guarantee) - Number(this.props.settlement.withholding) - Number(this.props.settlement.deductions)
        };

        const captotalval = this.props.captotalval;
        const compstotalval = this.props.compstotalval;
        const killstotalval = this.props.killstotalval;

        const sellablecap = captotalval - compstotalval - killstotalval;

        var sold_total = [];
        var unsold = [];
        var per_sold = [];
        if (this.props.finals[0].tickets_sold) {
            sold_total = this.props.finals.reduce(function (cnt, o) { return cnt + Number(o.tickets_sold); }, 0);
        } else {
            sold_total = ''
        };
        if (this.props.finals[0].tickets_sold) {
            unsold = sellablecap - sold_total;
        } else {
            unsold = ''
        };
        if (this.props.finals[0].tickets_sold) {
            per_sold = ((sold_total / sellablecap) * 100).toFixed(0);
        } else {
            per_sold = ''
        };

        var commission_amt = "";
        if (this.props.settle_net) {
            if (this.props.commission_percent) {
                commission_amt = (walkout * (this.props.commission_percent / 100)).toLocaleString("en", { useGrouping: false, maximumFractionDigits: 2 })
            } else {
                commission_amt = (walkout * .10).toLocaleString("en", { useGrouping: false, maximumFractionDigits: 2 })
            }
        } else {
            if (this.props.commission_percent) {
                commission_amt = ((Number(this.props.guarantee) + Number(this.props.settlement.overage)) * (this.props.commission_percent / 100)).toLocaleString("en", { useGrouping: false, maximumFractionDigits: 2 })
            } else {
                commission_amt = ((Number(this.props.guarantee) + Number(this.props.settlement.overage)) * .10).toLocaleString("en", { useGrouping: false, maximumFractionDigits: 2 })
            }
        }

        return (
            <div className="enterdealMain">
                <div style={{ width: '100%', height: '50%' }}>
                    <div>
                        <h1 style={{ marginTop: 20, marginLeft: 50, color: '#000', fontSize: 14 }}>Final Totals:</h1>
                    </div>
                    <div style={{ marginLeft: 50, height: 'auto', width: '90%' }} className="form-control">
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '15%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Show Date</th>
                                        <th style={{ width: '15%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Sellable Capacity</th>
                                        <th style={{ width: '15%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Tickets Sold</th>
                                        <th style={{ width: '17.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Gross Sales</th>
                                        <th style={{ width: '17.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Net Sales</th>
                                        <th style={{ width: '20%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Notes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.show_info_conf.map((item, index) => {
                                        var d = moment(item.date);
                                        var date = d.format('M/DD/YYYY');

                                        const single_captotal = this.props.scalingarray.filter(s => s.show_id === item._id).reduce(function (cnt, o) { return cnt + Number(o.capacity); }, 0);
                                        const single_compstotal = this.props.scalingarray.filter(s => s.show_id === item._id).reduce(function (cnt, o) { return cnt + Number(o.comps); }, 0);
                                        const single_killstotal = this.props.scalingarray.filter(s => s.show_id === item._id).reduce(function (cnt, o) { return cnt + Number(o.kills); }, 0);

                                        var sellable = single_captotal - (single_compstotal + single_killstotal)
                                        parseInt(sellable, 10)

                                        return (
                                            <tr>
                                                <td style={{ width: '15%', color: '#000', fontSize: 12, backgroundColor: '#fff', padding: 5, border: '1px solid #ddd' }}>{date}</td>
                                                <td style={{ width: '15%', color: '#000', fontSize: 12, backgroundColor: '#fff', padding: 5, border: '1px solid #ddd' }}>{sellable}</td>
                                                <td style={{ width: '15%', color: '#000', fontSize: 12, backgroundColor: '#fff', border: '1px solid #ddd' }}>
                                                    <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5, backgroundColor: this.props.edit && !this.props.finals[index].tickets_sold && d <= today && !this.props.finals_comp ? '#e6f7ff' : '#fff' }} disabled={today < d || this.props.finals_comp ? true : false} value={this.props.finals[index] && Number(this.props.finals[index].tickets_sold) > 0 ? Number(this.props.finals[index].tickets_sold) : null} displayType={this.props.edit ? null : 'text'} thousandSeparator={true} onChange={(e) => this.props.handleFinalsChange(e.target.value, date, 'tickets_sold', index)} />
                                                </td>
                                                <td style={{ width: '17.5%', color: '#000', fontSize: 12, backgroundColor: '#fff', border: '1px solid #ddd' }}>
                                                    <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5, backgroundColor: this.props.edit && !this.props.finals[index].gross_sales && d <= today && !this.props.finals_comp ? '#e6f7ff' : '#fff' }} disabled={today < d || this.props.finals_comp ? true : false} value={this.props.finals[index] && Number(this.props.finals[index].gross_sales) > 0 ? Number(this.props.finals[index].gross_sales) : null} displayType={this.props.edit ? null : 'text'} thousandSeparator={true} prefix={'$'} onChange={(e) => this.props.handleFinalsChange(e.target.value, date, 'gross_sales', index)} />
                                                </td>
                                                <td style={{ width: '17.5%', color: '#000', fontSize: 12, backgroundColor: '#fff', border: '1px solid #ddd' }}>
                                                    <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5, backgroundColor: this.props.edit && !this.props.finals[index].net_sales && d <= today && !this.props.finals_comp ? '#e6f7ff' : '#fff' }} disabled={today < d || this.props.finals_comp ? true : false} value={this.props.finals[index] && Number(this.props.finals[index].net_sales) > 0 ? Number(this.props.finals[index].net_sales) : null} displayType={this.props.edit ? null : 'text'} thousandSeparator={true} prefix={'$'} onChange={(e) => this.props.handleFinalsChange(e.target.value, date, 'net_sales', index)} />
                                                </td>
                                                {this.props.edit ?
                                                    <td style={{ width: '20%', color: '#000', fontSize: 12, backgroundColor: '#fff', border: '1px solid #ddd' }}><input style={{ width: '100%', height: '100%', color: '#000', border: d <= today && !this.props.finals_comp ? '2px solid rgb(44, 44, 44)' : '2px solid #ddd', fontSize: 12, height: 27, backgroundColor: this.props.edit && !this.props.finals[index].notes && d <= today && !this.props.finals_comp ? '#e6f7ff' : '#fff' }} type="text" disabled={today < date_comp || this.props.finals_comp ? true : false} name="notes" value={this.props.finals[index] && this.props.finals[index].notes ? this.props.finals[index].notes : null} onChange={(e) => this.props.handleFinalsChange(e.target.value, date, 'notes', index)} /></td> :
                                                    <td style={{ width: '15%', color: '#000', fontSize: 12, backgroundColor: '#fff', padding: 5, border: '1px solid #ddd' }}>{this.props.finals[index] && this.props.finals[index].notes ? this.props.finals[index].notes : null}</td>}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <table style={{ width: '40%', marginTop: 15 }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Guarantee</th>
                                            <th style={{ width: '50%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Overage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%', color: '#000', fontSize: 12, backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5, backgroundColor: '#fff' }} displayType={'text'} value={this.props.guarantee} thousandSeparator={true} prefix={'$'} /></td>
                                            <td style={{ width: '50%', color: '#000', fontSize: 12, backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5, backgroundColor: this.props.edit && !this.props.settlement.overage && date_comp <= today && !this.props.finals_comp ? '#e6f7ff' : '#fff' }} disabled={today < date_comp || this.props.finals_comp ? true : false} displayType={this.props.edit ? null : 'text'} name="overage" value={this.props.settlement.overage ? Number(this.props.settlement.overage) : this.props.settlement.overage} thousandSeparator={true} prefix={'$'} onChange={(event) => this.props.onFormattedChange(event, "settlement")} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {this.state.withholding ?
                                    <table style={{ width: '20%', marginTop: 15 }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '100%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Withholding</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '100%', color: '#000', fontSize: 12, backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5, backgroundColor: this.props.edit && !this.props.settlement.overage && date_comp <= today && !this.props.finals_comp ? '#e6f7ff' : '#fff' }} disabled={today < date_comp || this.props.finals_comp ? true : false} displayType={this.props.edit ? null : 'text'} name="withholding" value={this.props.settlement.withholding} thousandSeparator={true} prefix={'$'} onChange={(event) => this.props.onFormattedChange(event, "settlement")} /></td>
                                            </tr>
                                        </tbody>
                                    </table> : null}
                                {this.state.deductions ?
                                    <table style={{ width: '20%', marginTop: 15 }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '100%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Deductions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '100%', color: '#000', fontSize: 12, backgroundColor: '#fff', border: '1px solid #ddd', height: 28 }}><NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5, backgroundColor: this.props.edit && !this.props.settlement.overage && date_comp <= today && !this.props.finals_comp ? '#e6f7ff' : '#fff' }} disabled={today < date_comp || this.props.finals_comp ? true : false} displayType={this.props.edit ? null : 'text'} name="deductions" value={this.props.settlement.deductions} thousandSeparator={true} prefix={'$'} onChange={(event) => this.props.onFormattedChange(event, "settlement")} /></td>
                                            </tr>
                                        </tbody>
                                    </table> : null}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                {!this.props.finals_comp && this.props.edit ?
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'flex-end', marginTop: 10 }}>
                                        <div style={{ height: 14 }}>
                                            {!this.state.withholding ?
                                                <p style={{ fontSize: 12, color: 'blue', cursor: this.props.edit && date_comp <= today ? 'pointer' : null }} onClick={this.props.edit && date_comp <= today ? this.toggleWithholding : null}>ADD WITHHOLDING</p> :
                                                <p style={{ fontSize: 12, color: 'blue', cursor: this.props.edit && date_comp <= today ? 'pointer' : null }} onClick={this.props.edit && date_comp <= today ? this.toggleWithholding : null}>REMOVE WITHHOLDING</p>}
                                        </div>
                                        <div style={{ height: 14 }}>
                                            {!this.state.deductions ?
                                                <p style={{ fontSize: 12, color: 'blue', cursor: this.props.edit && date_comp <= today ? 'pointer' : null }} onClick={this.props.edit && date_comp <= today ? this.toggleDeductions : null}>ADD DEDUCTIONS</p> :
                                                <p style={{ fontSize: 12, color: 'blue', cursor: this.props.edit && date_comp <= today ? 'pointer' : null }} onClick={this.props.edit && date_comp <= today ? this.toggleDeductions : null}>REMOVE DEDUCTIONS</p>}
                                        </div>
                                    </div> : null}
                            </div>
                            <div style={{ marginTop: 20, display: 'flex', marginLeft: '50%', width: '50%', flexDirection: 'column', marginBottom: 10 }}>
                                <label style={{ fontSize: 14, color: '#000', width: '100%' }}>Settlement Notes:</label>
                                <textarea style={{ width: '100%', height: 100, color: '#000', border: '1px solid #333333', fontSize: 12, border: this.props.edit ? '1px solid #000' : '1px solid #ddd', resize: 'none' }} disabled={today < date_comp || this.props.finals_comp || !this.props.edit ? true : false} name="notes" value={this.props.settlement.notes} onChange={(event) => this.props.onFormattedChange(event, "settlement")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <div style={{ marginLeft: 50, width: '90%', height: 'auto', marginTop: 15, backgroundColor: 'rgb(1, 22, 48)' }} className="form-control">
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <h1 style={{ marginTop: 15, marginLeft: 5, color: '#cdcdcd', fontSize: 14 }}>Analysis:</h1>
                        </div>
                        <table style={{ width: '100%', marginBottom: 15 }} id="shorturl">
                            <thead>
                                <tr>
                                    <th style={{ width: '12.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>Tickets Sold</th>
                                    <th style={{ width: '12.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>Unsold</th>
                                    <th style={{ width: '12.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>% Sold</th>
                                    <th style={{ width: '12.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>Guarantee</th>
                                    <th style={{ width: '12.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>Overage</th>
                                    <th style={{ width: '12.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>Withholding</th>
                                    <th style={{ width: '12.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>Deductions</th>
                                    <th style={{ width: '12.5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>Walkout</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr>
                                    <td style={{ width: '12.5%', color: '#000', fontSize: 12, height: 30 }}>{this.props.finals_comp ? sold_total ? <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5 }} displayType={'text'} value={sold_total} thousandSeparator={true} /> : <p style={{ width: '100%', color: '#000', fontSize: 12 }}>-</p> : null}</td>
                                    <td style={{ width: '12.5%', color: '#000', fontSize: 12, height: 30 }}>{this.props.finals_comp ? unsold ? <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5 }} displayType={'text'} value={unsold} thousandSeparator={true} /> : <p style={{ width: '100%', color: '#000', fontSize: 12 }}>-</p> : null}</td>
                                    <td style={{ width: '12.5%', color: '#000', fontSize: 12, height: 30 }}>{this.props.finals_comp ? per_sold ? <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5 }} displayType={'text'} value={per_sold} thousandSeparator={true} suffix={'%'} /> : <p style={{ width: '100%', color: '#000', fontSize: 12 }}>-</p> : null}</td>
                                    <td style={{ width: '12.5%', color: '#000', fontSize: 12, height: 30 }}>{this.props.finals_comp ? this.props.guarantee ? <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5 }} displayType={'text'} value={this.props.guarantee} thousandSeparator={true} prefix={'$'} /> : <p style={{ width: '100%', color: '#000', fontSize: 12 }}>-</p> : null}</td>
                                    <td style={{ width: '12.5%', color: '#000', fontSize: 12, height: 30 }}>{this.props.finals_comp ? this.props.settlement.overage ? <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5 }} displayType={'text'} value={this.props.settlement.overage} thousandSeparator={true} prefix={'$'} /> : <p style={{ width: '100%', color: '#000', fontSize: 12 }}>-</p> : null}</td>
                                    <td style={{ width: '12.5%', color: '#000', fontSize: 12, height: 30 }}>{this.props.finals_comp ? this.props.settlement.withholding ? <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5 }} displayType={'text'} value={this.props.settlement.withholding} thousandSeparator={true} prefix={'$'} /> : <p style={{ width: '100%', color: '#000', fontSize: 12 }}>-</p> : null}</td>
                                    <td style={{ width: '12.5%', color: '#000', fontSize: 12, height: 30 }}>{this.props.finals_comp ? this.props.settlement.deductions ? <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5 }} displayType={'text'} value={this.props.settlement.deductions} thousandSeparator={true} prefix={'$'} /> : <p style={{ width: '100%', color: '#000', fontSize: 12 }}>-</p> : null}</td>
                                    <td style={{ width: '12.5%', color: '#000', fontSize: 12, height: 30 }}>{this.props.finals_comp ? walkout ? <NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5 }} displayType={'text'} value={walkout} thousandSeparator={true} prefix={'$'} /> : <p style={{ width: '100%', color: '#000', fontSize: 1 }}>-</p> : null}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ width: '33.2%', marginBottom: 15 }} id="shorturl">
                            <thead>
                                <tr>
                                    <th style={{ width: '50%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>Commission %</th>
                                    <th style={{ width: '50%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5 }}>Amount</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr>
                                    <td style={{ width: '50%', color: '#000', fontSize: 12, padding: 5 }}><NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5 }} displayType={'text'} value={this.props.finals_comp ? this.props.commission_percent ? this.props.commission_percent : 10 : null} thousandSeparator={true} suffix={'%'} /></td>
                                    <td style={{ width: '50%', color: '#000', fontSize: 12, padding: 5 }}><NumberFormat style={{ width: '100%', height: '100%', color: '#000', padding: 5 }} displayType={'text'} value={this.props.finals_comp ? commission_amt : null} thousandSeparator={true} prefix={'$'} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {})(FinalTotals);