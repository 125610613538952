import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import PropTypes from 'prop-types';
import { addOffer, updateOfferFull, getOfferDetail, offerDupCheck } from '../actions/offerActions';
import { verifyCounts } from '../actions/countActions';
import { getAccRecId } from '../actions/accountingActions';
import { getMarketing } from '../actions/marketingActions';
import { FaInfoCircle, FaTicketAlt, FaSave, FaAtlas, FaBullhorn, FaDollarSign, FaEdit, FaChartLine, FaDonate, FaDna, FaRss, FaPrint, FaDownload, FaEnvelope, FaCogs } from 'react-icons/fa';
import ShowInfo from "./modal-components/show-info.modal-component";
import TicketScaling from "./modal-components/ticket-scaling.modal-component";
import Expenses from "./modal-components/expenses.modal-component";
import DealTerms from "./modal-components/deal-terms.modal-component";
import AddNotes from "./modal-components/add-notes.modal-component";
import OfferTracking from "./modal-components/offer-tracking.modal-component";
import TicketCounts from "./modal-components/ticket-counts.modal-component";
import Marketing from "./modal-components/marketing.modal-component";
import FinalTotals from "./modal-components/final-totals.modal-component";
import OfferConfig from "./modal-components/offer-config.modal-component";
import Attachments from "./modal-components/attachments.modal-component";
import OfferButtonGroup from '../components/contacts/offer.button-group';
import { confirmAlert } from 'react-confirm-alert'
import _ from 'lodash';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import CurrencyAutocomplete from '../components/autocompletes/currency-autocomplete';
import CurrencyDialog from '../components/global/currency.dialog';
import CommissionDialog from '../components/global/commission.dialog';
import moment from 'moment';
import { FlareSharp, FlashOnRounded } from "@material-ui/icons";

class OfferModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            width: 0,
            height: 0,
            loading: true,
            render_type: 'enter',
            dealSection: "Show Info",
            id: '',
            header: '',
            dealcurrency: 'USD',
            artist: '', artistid: '', artistbatch: { _id: '', client: '' }, status: 'Pending', numberofshows: 1, date: '', date_id: '', confirmed_dates: [], dates_edit: {}, dates_val: {}, showtimes_array: [], showtimes_edit: {}, showtimes_val: {}, scaling_edit: {}, scaling_val: {}, tax_edit: {}, tax_val: {}, expense_edit: {}, expense_val: {}, deposit_edit: {}, deposit_val: {}, bonus_edit: {}, bonus_val: {},
            venue: '', venueid: '', venuebatch: { _id: '', venuename: '', address: [] }, buyercompany: '', buyercompanyid: '', buyerbatch: { _id: '', company: '', address: [] }, buyername: '', signatory: '',
            production_contact: '', production_contact_custom: { first_name: '', last_name: '', email: '', office_phone: '', cell_phone: '' }, marketing_contact: '', marketing_contact_custom: { first_name: '', last_name: '', email: '', office_phone: '', cell_phone: '' }, counts_contact: '', counts_contact_custom: { first_name: '', last_name: '', email: '', office_phone: '', cell_phone: '' },
            add_contacts: [], contactbatch: { _id: '', company: '', address: [], role: '' }, contact_edit: {}, add_contact_id: '', add_contact_role: '',
            billing: '', billingcustom: '', billing_array: [], agelimit: '', agelimitcustom: '', merchsoft: '', merchsoftval: '', merchhard: '', merchhardval: '', merchseller: '', merchnotes: '',
            showtime1: '', showtime1note: '', showtimenotes: '',
            tickettype1: '', ticketcap1: '', ticketcomps1: '', ticketkills1: '', ticketprice1: '', ticketprice1val: '', ticketff1: '', ticketff1val: '', ticketaf1a: '', ticketaf1aval: '', ticketaf1b: '', ticketaf1bval: '', ticketscalingnotes: '', dos_increase: '',
            tax1: '', taxtype1: '', taxamount1: '', taxamount1val: '', checkboxtax1: false, taxcappedamount1: '', taxcappedamount1val: '', taxnotes1: '',
            exp1: '', exptype1: '', expenseamount1: '', expenseamount1val: '', checkboxexp1: false, expcappedamount1: '', expcappedamount1val: '', expnotes1: '',
            dealcurrency: 'USD', feecurrency: 'USD', guarantee: '', guaranteeval: '', dealtype: '', dealpercentage: '', dealpercentageval: '', customwalkout: '', customwalkoutval: '',
            bonus1: '', bonus1type: '', bonus1val: '', bonus1atamount: '',
            dealpoints: '', deposit1: '', deposit1val: '', deposit1due: '', depositnotes: '',
            exclusivity: '', travelnotes: '', productionnotes: '', dealmemonotes: '',
            scalingarray: [], taxesarray: [], expensearray: [], showtimearray: [], datesarray: [], bonusarray: [], depositsarray: [],
            ticketlink: '', shorturl: [], facfeeAA: '', facefeeAAval: '', addfeeAA: '', addfeeAAval: '', addfeeBA: '', addfeeBAval: '',
            contractissued: '', contractissuednotes: '', contractissuedarray: [], contractreceived: false, contractreceviednotes: '', contractreceivedarray: [], depositreceivedamount: '', depositreceiveddate: '', depositreceivednotes: '', depositsreceivedarray: [],
            links: [], finals: [], settlement: { overage: '', withholding: '', deductions: '', settle_net: false },
            count_data: [], show_select_array: [], show_select: '', show_info: [], show_info_conf: [], billing_pin: false, age_limit_pin: false, merch_pin: false, show_times_pin: false, show_time_notes_pin: false, scaling_pin: false, expense_pin: false, tax_pin: false, merch_array: [],
            currency: { ticket_scaling: 'USD', expenses: 'USD', deal_terms: 'USD' }, currency_dialog: false, commission_dialog: false,
            seperate_scaling: false, seperate_taxes: false, seperate_expenses: false, bonus_toggle: false, commission_percent: '', count_contact: '', settle_net: false, finals_comp: false, checkbox_next_day: false, add_contact_custom: { first_name: '', last_name: '', email: '', office: '', cell: '' },
            tc_check: false, tc_auto: { monday: false, tuesday: false, wednesday: false, thursday: false, friday: false }, count_contacts: [], count_config: { active: false, email: false, contacts: [], notes: '', group: [] }, attachments: []
            //bonustype: 'At Tickets Sold', 
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleProdCustomChange = this.handleProdCustomChange.bind(this);
        this.handleMktCustomChange = this.handleMktCustomChange.bind(this);
        this.handleCtsCustomChange = this.handleCtsCustomChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleVenueChange = this.handleVenueChange.bind(this);
        this.handleBuyerChange = this.handleBuyerChange.bind(this);
        this.handleExpChange = this.handleExpChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.updateDate = this.updateDate.bind(this);
        this.updateShowtime = this.updateShowtime.bind(this);
        this.updateScaling = this.updateScaling.bind(this);
        this.updateTax = this.updateTax.bind(this);
        this.updateExpense = this.updateExpense.bind(this);
        this.updateDeposit = this.updateDeposit.bind(this);
        this.handleSettlementCheckbox = this.handleSettlementCheckbox.bind(this);
        this.formattedDepositReceived = this.formattedDepositReceived.bind(this);
        this.handleTcAutoCheckbox = this.handleTcAutoCheckbox.bind(this);
        this.handleTcCheckbox = this.handleTcCheckbox.bind(this);
        this.handleTcConChange = this.handleTcConChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        offerdetail: PropTypes.object.isRequired,
        marketing: PropTypes.object.isRequired,
        festivals: PropTypes.object.isRequired
    }

    async componentDidMount() {
        console.log(this.props.render_offer_data)
        if (this.props.type === 'render') {
            var data = this.props.render_offer_data;

            const id = data._id;
            this.setState({
                render_type: 'render',
                edit: false,
                header: data.artist_name.toUpperCase(),
                artist: data.artist_name,
                id: id,
            })
            var offer_data = await this.props.getOfferDetail(id);
            var receivedArray = await this.props.getAccRecId(id);
            //const marketing_data = await this.props.getMarketing(id);

            //TEND - fix once offer detail model is updated
            if (offer_data) {
                var contacts = {};
                if (data.contacts) {
                    contacts = data.contacts;
                } else {
                    contacts = {
                        add_contacts: [],
                        buyer_contact: '',
                        counts_contact: {},
                        marketing_contact: {},
                        production_contact: {},
                        signatory: ''
                    }
                }
                offer_data.contacts = contacts;
                this.initSetup(offer_data, receivedArray);

                this.setState({
                    depositsreceivedarray: receivedArray ? receivedArray.received : []
                })

                const socket = this.props.auth.socket;
                if (socket) {
                    socket.on(`updated_offer_detail/tracking/k_iss/${this.state.id}`, (offerdetail) => {
                        this.socketUpdateOffer(offerdetail, 'contractissuedarray')
                    })
                    socket.on(`updated_offer_detail/tracking/k_rec/${this.state.id}`, (offerdetail) => {
                        this.socketUpdateOffer(offerdetail, 'contractreceivedarray')
                    })
                    socket.on(`updated_offer_detail/tracking/d_rec/${this.state.id}`, (accrec) => {
                        this.socketUpdateOffer(accrec.received, 'depositsreceivedarray')
                    })
                    socket.on(`updated_offer_detail/${this.state.id}`, (offerdetail) => {
                        const offer_data = offerdetail;
                        this.initSetup(offer_data)
                    })
                    // socket.on(`offer/updated/finals/${this.state.id}`, (offerdetail) => {
                    //     const offer_data = offerdetail;
                    //     this.initSetup(offer_data)
                    // })
                };
            }

            setTimeout(
                () => this.setState({ loading: false }),
                250
            );
        } else if (this.props.type === 'copy') {
            var data = this.props.render_offer_data;
            const id = data._id;
            this.setState({
                render_type: 'enter',
                edit: true,
                header: 'NEW OFFER',
                //artist: data.artist_name,
                //id: id,
            })
            var offer_data = await this.props.getOfferDetail(id);
            //var receivedArray = await this.props.getAccRecId(id);
            //const marketing_data = await this.props.getMarketing(id);
            var receivedArray = [];
            //TEND - fix once offer detail model is updated
            var contacts = {};
            if (data.contacts) {
                contacts = data.contacts;
            } else {
                contacts = {
                    add_contacts: [],
                    buyer_contact: '',
                    counts_contact: {},
                    marketing_contact: {},
                    production_contact: {},
                    signatory: ''
                }
            }
            offer_data.contacts = contacts;
            offer_data.finals = [];
            offer_data.settlement = {};
            offer_data.links = [];
            offer_data.createdAt = '';
            offer_data.updatedAt = '';
            offer_data.attachments = [];
            var track = {
                contract_issued: [],
                contract_received: [],
                deposits_received: [],
            }
            offer_data.track = track

            this.initSetup(offer_data, receivedArray);
            setTimeout(
                () => this.setState({ loading: false }),
                250
            );
        } else {
            this.setState({
                render_type: 'enter',
                edit: true,
                header: 'NEW OFFER',
                loading: false
            })
        }
        //var id = data.id
        //this.props.getMarketing(id)
    }

    initSetup = async (offer_data) => {
        var data = this.props.render_offer_data;
        var sortDates = _.sortBy(data.show_info, function (dateObj) {
            return new Date(dateObj.date);
        });
        var showSelectArray = [];
        // var link_data = [];
        await sortDates.map((item, index) => {
            showSelectArray.push({ date: item.date, show_id: item._id });
            // link_data.push({ date: '', show_id: item._id, type: 'Announce', url: '' }, { date: '', show_id: item._id, type: 'Presale', url: '' }, { date: '', show_id: item._id, type: 'On Sale', url: '' })
        })

        // for (var i = 0; i < link_data.length; i++) {
        //     var show_id = link_data[i].show_id;
        //     links.map((l, i) => {
        //         if (show_id === l.show_id && l.type === 'Announce') {
        //             link_data[i].url = l.url;
        //             if (l.date) {
        //                 link_data[i].date = new Date(l.date);
        //             }
        //         }
        //         if (show_id === l.show_id && l.type === 'Presale') {
        //             link_data[i].url = l.url
        //             if (l.date) {
        //                 link_data[i].date = new Date(l.date);
        //             }
        //         }
        //         if (show_id === l.show_id && l.type === 'On Sale') {
        //             link_data[i].url = l.url
        //             if (l.date) {
        //                 link_data[i].date = new Date(l.date);
        //             }
        //         }
        //     })
        // }
        var finals = [];
        if (offer_data.finals && offer_data.finals[0] && offer_data.finals[0].hasOwnProperty('_id')) {
            finals = offer_data.finals;
        } else {
            data.show_info.forEach(show => {
                finals.push({ tickets_sold: '', gross_sales: '', net_sales: '', _id: show._id })
            })
        };
        var settlement = {};
        if (offer_data.settlement) {
            settlement = offer_data.settlement;
        } else {
            settlement = Object.assign({ overage: '' }, { withholding: '' }, { deductions: '' }, { settle_net: false })
        };
        const artist = {
            id: data.client_id,
            client: this.state.artist
        }

        var adr = "";
        var adr2 = "";
        if (data.venue_address) {
            adr = data.venue_address.address;
            adr2 = data.venue_address.address2;
        };

        var venue_address = {
            address: adr,
            address2: adr2,
            city: data.venue_city,
            state: data.venue_state,
            country: data.venue_country
        }
        const venuedata = {
            id: data.venue_id,
            venue_name: data.venue_name,
            address: venue_address
        }
        var buyer_address = {
            city: data.buyer_address.city,
            state: data.buyer_address.state,
            country: data.buyer_address.country
        }
        const buyerdata = {
            id: data.buyer_id,
            company: data.buyer_company,
            address: buyer_address
        }
        var bonus_toggle = false;
        if (offer_data.deal_terms.bonuses && offer_data.deal_terms.bonuses.length > 0) {
            bonus_toggle = true;
        };
        var production_contact = "";
        var production_contact_custom = { first_name: '', last_name: '', email: '', office_phone: '', cell_phone: '' };
        if (offer_data.production_contact !== undefined) {
            if ((offer_data.production_contact.hasOwnProperty("contact_id"))) {
                if (offer_data.production_contact.contact_id === "Custom") {
                    production_contact = "Custom";
                    production_contact_custom = {
                        first_name: offer_data.production_contact.first_name,
                        last_name: offer_data.production_contact.last_name,
                        email: offer_data.production_contact.email,
                        office_phone: offer_data.production_contact.office_phone,
                        cell_phone: offer_data.production_contact.cell_phone,
                    };
                } else {
                    production_contact = offer_data.production_contact.contact_id
                }
            }
        } else if (data.contacts !== undefined) {
            if ((data.contacts.production_contact && data.contacts.production_contact.hasOwnProperty("contact_id"))) {
                if (data.contacts.production_contact.contact_id === "Custom") {
                    production_contact = "Custom";
                    production_contact_custom = {
                        first_name: data.contacts.production_contact.first_name,
                        last_name: data.contacts.production_contact.last_name,
                        email: data.contacts.production_contact.email,
                        office_phone: data.contacts.production_contact.office_phone,
                        cell_phone: data.contacts.production_contact.cell_phone,
                    };
                } else {
                    production_contact = data.contacts.production_contact.contact_id
                }
            }
        }
        var marketing_contact = "";
        var marketing_contact_custom = { first_name: '', last_name: '', email: '', office_phone: '', cell_phone: '' };
        if (offer_data.marketing_contact !== undefined) {
            if ((offer_data.marketing_contact.hasOwnProperty("contact_id"))) {
                if (offer_data.marketing_contact.contact_id === "Custom") {
                    marketing_contact = "Custom";
                    marketing_contact_custom = {
                        first_name: offer_data.marketing_contact.first_name,
                        last_name: offer_data.marketing_contact.last_name,
                        email: offer_data.marketing_contact.email,
                        office_phone: offer_data.marketing_contact.office_phone,
                        cell_phone: offer_data.marketing_contact.cell_phone,
                    };
                } else {
                    marketing_contact = offer_data.marketing_contact.contact_id
                }
            }
        } else if (data.contacts !== undefined) {
            if ((data.contacts.marketing_contact && data.contacts.marketing_contact.hasOwnProperty("contact_id"))) {
                if (data.contacts.marketing_contact.contact_id === "Custom") {
                    marketing_contact = "Custom";
                    marketing_contact_custom = {
                        first_name: data.contacts.marketing_contact.first_name,
                        last_name: data.contacts.marketing_contact.last_name,
                        email: data.contacts.marketing_contact.email,
                        office_phone: data.contacts.marketing_contact.office_phone,
                        cell_phone: data.contacts.marketing_contact.cell_phone,
                    };
                } else {
                    production_contact = data.contacts.marketing_contact.contact_id
                }
            }
        }
        var counts_contact = "";
        var counts_contact_custom = { first_name: '', last_name: '', email: '', office_phone: '', cell_phone: '' };
        if (offer_data.counts_contact !== undefined) {
            if ((offer_data.counts_contact.hasOwnProperty("contact_id"))) {
                if (offer_data.counts_contact.contact_id === "Custom") {
                    counts_contact = "Custom";
                    counts_contact_custom = {
                        first_name: offer_data.counts_contact.first_name,
                        last_name: offer_data.counts_contact.last_name,
                        email: offer_data.counts_contact.email,
                        office_phone: offer_data.counts_contact.office_phone,
                        cell_phone: offer_data.counts_contact.cell_phone,
                    };
                } else {
                    counts_contact = offer_data.counts_contact.contact_id
                }
            }
        } else if (data.contacts !== undefined) {
            if ((data.contacts.counts_contact && data.contacts.counts_contact.hasOwnProperty("contact_id"))) {
                if (data.contacts.counts_contact.contact_id === "Custom") {
                    counts_contact = "Custom";
                    counts_contact_custom = {
                        first_name: data.contacts.counts_contact.first_name,
                        last_name: data.contacts.counts_contact.last_name,
                        email: data.contacts.counts_contact.email,
                        office_phone: data.contacts.counts_contact.office_phone,
                        cell_phone: data.contacts.counts_contact.cell_phone,
                    };
                } else {
                    counts_contact = data.contacts.counts_contact.contact_id
                }
            }
        }

        var signatory = "";
        if (offer_data.signatory_id !== undefined) {
            signatory = offer_data.signatory_id
        } else if (data.contacts !== undefined) {
            if (data.contacts.signatory) {
                signatory = data.contacts.signatory
            }
        }

        var buyername = "";
        if (offer_data.buyer_contact_id !== undefined) {
            buyername = offer_data.buyer_contact_id
        } else if (data.contacts !== undefined) {
            if (data.contacts.buyer_contact) {
                buyername = data.contacts.buyer_contact
            }
        }

        // var ticket_link = '';
        // var short_url = [];
        // if (marketing_data !== null) {
        //     ticket_link = marketing_data.ticketlink;
        //     short_url = marketing_data.short_url;
        // }

        if (this.props.type === 'copy') {
            this.setState({
                status: 'Pending'
            })
        } else {
            this.setState({
                artistbatch: artist,
                artistid: data.client_id,
                status: data.status,
            })
        }

        var notesObj = {
            exclusivity: '',
            production: '',
            deal_memo: '',
            travel: ''
        };

        if (offer_data.deal_notes) {
            notesObj = {
                exclusivity: offer_data.deal_notes.exclusivity,
                production: offer_data.deal_notes.production,
                deal_memo: offer_data.deal_notes.deal_memo,
                travel: offer_data.deal_notes.travel,
            }
        }


        var links = [];
        if (offer_data.links && offer_data.links.length > 0) {
            links = offer_data.links;
        } else if (data.links && data.links.length > 0) {
            links = data.links
        } else {
            data.show_info.forEach(d => {
                var nl = {
                    _id: d._id,
                    announce: { date: '', url: '' },
                    presale: { date: '', url: '' },
                    on_sale: { date: '', url: '' }
                }
                links.push(nl)
            })
        }

        var prog = {
            k_iss: false,
            k_rec: false,
            d_rec: false,
            d_due: [],
            settled: false
        }
        var count_config = {
            active: false,
            email: false,
            contacts: [],
            group: [],
            notes: ''
        }
        if (this.props.type !== 'copy') {
            if (data.count_config.active === undefined) {
                count_config.active = true;
            } else {
                count_config = data.count_config;

                this.props.contacts.contacts.map(item => {
                    item.tags.map((t, i) => {
                        if (t.tag === data.buyer_id || t.tag === data.venue_id) {
                            // const rolInd = data.contacts.add_contacts.findIndex(con => con.contact_id === item._id);
                            const conInd = count_config.contacts.findIndex(c => c._id === item._id);
                            if (conInd >= 0) {
                                count_config.contacts[conInd].name = item.name;
                            }
                            // if (rolInd >= 0 && conInd >= 0) {
                            //     const role = data.contacts.add_contacts[rolInd] ? data.contacts.add_contacts[rolInd].role : "";
                            //     count_config.contacts[conInd].role = role;
                            // }
                        }
                    })
                })

                if (!data.count_config.group) {
                    count_config.group = []
                }
            }
            prog = data.prog;
        }

        this.setState({
            //numberofshows: offer_data.number_of_shows || 1,
            status: data.status,
            datesarray: sortDates,
            confirmed_dates: sortDates,
            venuebatch: venuedata,
            venue: data.venue_name,
            venueid: data.venue_id,
            buyerbatch: buyerdata,
            buyercompany: data.buyer_company,
            buyercompanyid: data.buyer_id,
            buyername: buyername,
            signatory: signatory,
            production_contact: production_contact,
            production_contact_custom: production_contact_custom,
            marketing_contact: marketing_contact,
            marketing_contact_custom: marketing_contact_custom,
            counts_contact: counts_contact,
            counts_contact_custom: counts_contact_custom,
            // billing: offer_data.billing,
            // agelimit: offer_data.age_limit,
            merch_array: offer_data.merch,
            // showtimearray: offer_data.showtimes,
            // showtimenotes: offer_data.showtime_notes || '',
            scalingarray: offer_data.scaling.scaling,
            ticketscalingnotes: offer_data.scaling.notes || '',
            dos_increase: offer_data.scaling.dos_increase || '',
            taxesarray: offer_data.taxes,
            expensearray: offer_data.expenses,
            dealcurrency: offer_data.config.currency.deal_terms || 'USD',
            feecurrency: offer_data.config.currency.deal_terms || 'USD',
            guarantee: offer_data.deal_terms.guarantee || '',
            guaranteeval: offer_data.deal_terms.guarantee,
            dealtype: offer_data.deal_terms.deal_type || '',
            dealpercentage: offer_data.deal_terms.deal_percent || '',
            dealpercentageval: offer_data.deal_terms.deal_percent || '',
            customwalkout: offer_data.deal_terms.custom_walkout,
            customwalkoutval: offer_data.deal_terms.custom_walkout,
            bonusarray: offer_data.deal_terms.bonuses,
            //bonustype: offer_data.deal_terms.bonus_type,
            bonus_toggle: bonus_toggle,
            dealpoints: offer_data.deal_terms.deal_points || '',
            depositsarray: offer_data.deposits.deposits,
            depositnotes: offer_data.deposits.notes || '',
            exclusivity: notesObj.exclusivity,
            travelnotes: notesObj.travel,
            productionnotes: notesObj.production,
            dealmemonotes: notesObj.deal_memo,
            contractissuedarray: offer_data.track.contract_issued,
            contractreceivedarray: offer_data.track.contract_received,
            links: links,
            finals: finals,
            settlement: settlement,
            settle_net: settlement.settle_net ? settlement.settle_net : false,
            //ticketlink: ticket_link,
            //shorturl: short_url,
            createdAt: offer_data.createdAt,
            updatedAt: offer_data.updatedAt,
            attachments: offer_data.attachments,
            show_select_array: showSelectArray,
            show_select: showSelectArray[0].show_id,
            show_info: sortDates,
            show_info_conf: sortDates,
            finals_comp: prog.settled === 'true' || prog.settled === true ? true : false,
            seperate_scaling: offer_data.config.combined.scaling === "true" ? false : true,
            seperate_taxes: offer_data.config.combined.taxes === "true" ? false : true,
            seperate_expenses: offer_data.config.combined.expenses === "true" ? false : true,
            commission_percent: offer_data.config.commission_percent,
            currency: { ticket_scaling: offer_data.config.currency.ticket_scaling, expenses: offer_data.config.currency.expenses, deal_terms: offer_data.config.currency.deal_terms },
            billing_pin: offer_data.config.pinned.billing === "true" ? true : false,
            age_limit_pin: offer_data.config.pinned.age_limit === "true" ? true : false,
            merch_pin: offer_data.config.pinned.merch === "true" ? true : false,
            show_times_pin: offer_data.config.pinned.show_times === "true" ? true : false,
            show_time_notes_pin: offer_data.config.pinned.show_time_notes === "true" ? true : false,
            scaling_pin: offer_data.config.pinned.ticket_scaling === "true" ? true : false,
            tax_pin: offer_data.config.pinned.taxes === "true" ? true : false,
            expense_pin: offer_data.config.pinned.expenses === "true" ? true : false,
            add_contacts: data.contacts.add_contacts,
            count_config: count_config
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    socketUpdateOffer = (offerdetail, name) => {
        this.setState({
            [name]: offerdetail
        });
    }

    handleArrayUpdate = (event, id, set) => {
        const name = event.target.name;
        const val = event.target.value;

        const index = this.state[set].findIndex(item => item._id === id),
            array = [...this.state[set]]
        array[index][name] = val;
        this.setState({ [set]: array });
    };

    handleShowtimeArrayChange = (event, id, time_i, set, sn) => {
        const name = event.target.name;
        const val = event.target.value;

        const i = this.state[set].findIndex(item => item._id === id);
        if (i >= 0) {
            const index = this.state[set].findIndex(item => item._id === id),
                array = [...this.state[set]]
            if (array[index][sn][time_i][name]) {
                array[index][sn][time_i][name] = val;
            } else {
                var newObj = {
                    ...array[index][sn][time_i],
                    [name]: val
                }
                array[index][sn][time_i] = newObj;
            }

            this.setState({ [set]: array });

            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
                //array[index][sn].sort((a, b) => a.time - b.time).reverse();
                var data = array;
                for (var i = 0; i <= (array.length - 1); i++) {
                    data[i].show_times = await sortAtoZ(data[i].show_times)
                }
                this.setState({
                    [set]: data
                })
            }, 200);

        } else {
            console.log("date not logged")
            // const newVal = {
            //     _id: id,
            //     [name]: val
            // }
            // const array = [...this.state[set], newVal]
            // console.log(array)
            // this.setState({ [set]: array });
        }
    };

    handleArrayFormattedChange = (values, id, name, set) => {
        const { formattedValue, value } = values;
        if (id) {
            const index = this.state[set].findIndex(item => item._id === id);
            var array = [...this.state[set]]
            array[index][name] = value;
            this.setState({ [set]: array });
        }
    };

    handleArrayDateChange = (id, name, set, date) => {
        const now = new Date(date);
        // var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000).toISOString();
        // console.log(date, utc, now.toISOString())
        var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
            now.getUTCDate(), now.getUTCHours(),
            now.getUTCMinutes(), now.getUTCSeconds());

        const index = this.state[set].findIndex(item => item._id === id);
        var array = [...this.state[set]]
        array[index][name] = new Date(utc);
        this.setState({ [set]: array });
    };

    handleClickOutside = (e, target) => {
        if (e.relatedTarget === null) {
            this.setState({
                [target]: {}
            })
        }
    }

    handleCheckboxChange(event) {
        const name = event.target.name;
        this.setState({
            [name]: !this.state[name]
        });
    }

    handleProdCustomChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let production_contact_custom = Object.assign({}, prevState.production_contact_custom);
            production_contact_custom[name] = val;
            return { production_contact_custom };
        })
    }

    handleMktCustomChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let marketing_contact_custom = Object.assign({}, prevState.marketing_contact_custom);
            marketing_contact_custom[name] = val;
            return { marketing_contact_custom };
        })
    }

    handleCtsCustomChange(event) {
        var name = event.target.name;
        var val = event.target.value;
        this.setState(prevState => {
            let add_contact_custom = Object.assign({}, prevState.add_contact_custom);
            add_contact_custom[name] = val;
            return { add_contact_custom };
        })
    }

    handleClientChange(artist) {
        if (artist) {
            this.setState({
                artist: artist.client,
                artistid: artist._id,
                commission_percent: artist.commission_base,
                artistbatch: artist
            })
        } else {
            this.setState({
                artist: '',
                artistid: '',
                commission_percent: '',
                artistbatch: ''
            });
        }
    }
    handleVenueChange(venue) {
        if (venue) {
            if (venue.venue_name) {
                this.setState({
                    venue: venue.venue_name,
                    venueid: venue._id,
                    venuebatch: venue
                })
            } else {
                this.setState({
                    venue: venue.festival,
                    venueid: venue._id,
                    venuebatch: venue
                })
            }
        } else {
            this.setState({
                venue: '',
                venueid: '',
                venuebatch: { _id: '', venuename: '', address: [] }
            });
        }
    }
    handleBuyerChange(buyer) {
        if (buyer) {
            this.setState({
                buyercompany: buyer.company,
                buyercompanyid: buyer._id,
                buyerbatch: buyer
            })
        } else {
            this.setState({
                buyercompany: '',
                buyercompanyid: '',
                buyerbatch: ''
            });
        }
    }

    addContact = () => {
        let array = [...this.state.add_contacts];
        if (this.state.add_contact_id === 'Custom') {
            const name = {
                first: this.state.add_contact_custom.first_name,
                last: this.state.add_contact_custom.last_name,
            }
            var newValue = {
                contact_id: this.state.add_contact_id,
                role: this.state.add_contact_role,
                custom: {
                    name: name,
                    email: this.state.add_contact_custom.email,
                    office: this.state.add_contact_custom.office_phone,
                    cell: this.state.add_contact_custom.cell_phone
                }
            }
        } else {
            var newValue = {
                contact_id: this.state.add_contact_id,
                role: this.state.add_contact_role,
                custom: {}
            }
        }
        array.push(newValue)
        const batch = {
            _id: '', company: '', address: [], role: ''
        }
        this.setState({
            add_contacts: array,
            contactbatch: batch,
            add_contact_id: '',
            add_contact_role: '',
            add_contact_custom: { first_name: '', last_name: '', email: '', office: '', cell: '' }
        })
    }

    updateContact(props, name) {
        var contact_edit = {
            [props]: name
        }
        this.setState({
            contact_edit: contact_edit,
        })
    }

    deleteContact = (props) => {
        const newArray = this.state.add_contacts.filter((e, i) => i !== props);
        this.setState({ add_contacts: newArray })
    }

    handleExpChange(exp) {
        if (exp) {
            this.setState({
                exp1: exp,
            })
        } else {
            this.setState({
                exp1: '',
            })
        }
        // else {
        //     this.setState({
        //         exp1: '',
        //         artistid: '',
        //         commission_percent: '',
        //         artistbatch: ''
        //     });
        // }
    }

    toggleDealSection = (e) => {
        this.setState({
            dealSection: e
        })
    }

    addScaling = () => {
        var d = new Date();
        var id = `${d.getDate()}${d.getMonth()}${d.getFullYear()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}`;
        var gross = (Number(this.state.ticketcap1) - Number(this.state.ticketcomps1) - Number(this.state.ticketkills1)) * (Number(this.state.ticketprice1val) - Number(this.state.ticketff1) - Number(this.state.ticketaf1a) - Number(this.state.ticketaf1b))
        var newValue = {};
        let array = [...this.state.scalingarray];
        if (this.state.scaling_pin && this.state.seperate_scaling) {
            this.state.show_info.forEach((obj, i) => {
                newValue = {
                    //_id: id+i,
                    _id: obj._id + i,
                    type: this.state.tickettype1,
                    capacity: this.state.ticketcap1,
                    comps: this.state.ticketcomps1,
                    kills: this.state.ticketkills1,
                    price: this.state.ticketprice1val,
                    fac_fee: this.state.ticketff1val,
                    add_fee_A: this.state.ticketaf1aval,
                    add_fee_B: this.state.ticketaf1bval,
                    gross: gross,
                    show_id: obj._id,
                    show_index: array.length + i + 1
                }
                array.push(newValue)
            })
        } else {
            newValue = {
                _id: id,
                type: this.state.tickettype1,
                capacity: this.state.ticketcap1,
                comps: this.state.ticketcomps1,
                kills: this.state.ticketkills1,
                price: this.state.ticketprice1val,
                fac_fee: this.state.ticketff1val,
                add_fee_A: this.state.ticketaf1aval,
                add_fee_B: this.state.ticketaf1bval,
                gross: gross,
                show_id: this.state.show_select,
                show_index: this.state.scalingarray.length + 1
            }
            array.push(newValue)
        }

        this.setState({
            scalingarray: array,
            tickettype1: '',
            ticketcap1: '',
            ticketcomps1: '',
            ticketkills1: '',
            ticketprice1: '',
            ticketprice1val: '',
            ticketff1: '',
            ticketaf1a: '',
            ticketaf1b: ''
        })
    }

    updateScaling(props, name) {
        var scaling_edit = {
            [props]: name
        }
        this.setState({
            scaling_edit: scaling_edit,
        })
    }

    deleteScaling = (props) => {
        const newArray = this.state.scalingarray.filter((e, i) => e._id !== props);
        this.setState({ scalingarray: [] }, () => this.setState({ scalingarray: newArray }))
    }

    addTax = () => {
        var d = new Date();
        var id = `${d.getDate()}${d.getMonth()}${d.getFullYear()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}`;

        let array = [...this.state.taxesarray];
        if (this.state.tax_pin && this.state.seperate_taxes) {
            this.state.show_info.forEach((obj, i) => {
                var newValue = {
                    _id: id + i,
                    tax: this.state.tax1,
                    type: this.state.taxtype1,
                    amount: Number(this.state.taxamount1val),
                    cap: this.state.checkboxtax1,
                    capped_amount: Number(this.state.taxcappedamount1val),
                    notes: this.state.taxnotes1,
                    show_id: obj._id,
                    show_index: array.length + 1
                }
                array.push(newValue)
            })
        } else {
            var newValue = {
                _id: id,
                tax: this.state.tax1,
                type: this.state.taxtype1,
                amount: Number(this.state.taxamount1val),
                cap: this.state.checkboxtax1,
                capped_amount: Number(this.state.taxcappedamount1val),
                notes: this.state.taxnotes1,
                show_id: this.state.show_select,
                show_index: this.state.taxesarray.length + 1
            }
            array.push(newValue)
        }
        this.setState({
            taxesarray: array,
            tax1: '',
            taxtype1: '',
            taxamount1: '',
            taxamount1val: '',
            checkboxtax1: false,
            taxcappedamount1: '',
            taxcappedamount1val: '',
            taxnotes1: '',
        })
    }

    addExpense = () => {
        var d = new Date();
        var id = `${d.getDate()}${d.getMonth()}${d.getFullYear()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}`;
        let array = [...this.state.expensearray];
        if (this.state.expense_pin && this.state.seperate_expenses) {
            this.state.show_info.forEach((obj, i) => {
                var newValue = {
                    _id: id + i,
                    expense: this.state.exp1,
                    type: this.state.exptype1,
                    amount: Number(this.state.expenseamount1val),
                    cap: this.state.checkboxexp1,
                    capped_amount: Number(this.state.expcappedamount1val),
                    notes: this.state.expnotes1,
                    show_id: obj._id,
                    show_index: this.state.expensearray.length + 1
                }
                array.push(newValue)
            })
        } else {
            var newValue = {
                _id: id,
                expense: this.state.exp1,
                type: this.state.exptype1,
                amount: Number(this.state.expenseamount1val),
                cap: this.state.checkboxexp1,
                capped_amount: Number(this.state.expcappedamount1val),
                notes: this.state.expnotes1,
                show_id: this.state.show_select,
                show_index: this.state.expensearray.length + 1
            }
            array.push(newValue)
        }
        this.setState({
            expensearray: array,
            exp1: '',
            exptype1: '',
            expenseamount1: '',
            expenseamount1val: '',
            checkboxexp1: false,
            expcappedamount1: '',
            expcappedamount1val: '',
            expnotes1: '',
        })
    }

    updateExpense(props, name) {
        var expense_edit = {
            [props]: name
        }
        this.setState({
            expense_edit: expense_edit,
        })
    }

    deleteExpense = (props) => {
        const newArray = this.state.expensearray.filter((e, i) => e._id !== props);
        this.setState({ expensearray: newArray })
    }

    updateTax(props, name) {
        var tax_edit = {
            [props]: name
        }
        this.setState({
            tax_edit: tax_edit,
        })
    }

    deleteTax = (props) => {
        const newArray = this.state.taxesarray.filter((e, i) => e._id !== props);
        this.setState({ taxesarray: newArray })
    }

    addShowtime = async () => {
        var d = new Date();
        var id = `${d.getDate()}${d.getMonth()}${d.getFullYear()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}`;
        var newValue = {
            _id: id,
            time: this.state.showtime1,
            description: this.state.showtime1note,
            nd: this.state.checkbox_next_day
        }

        if (this.state.show_times_pin) {
            let data = this.state.show_info;
            for (var i = 0; i <= (data.length - 1); i++) {
                data[i].show_times.push(newValue)
                //await [...data[i].show_times, newValue];
                //data[i].show_times.sort(sortAtoZ);
                data[i].show_times = await sortAtoZ(data[i].show_times)
            }
            this.setState({
                show_info: data,
                showtime1: '',
                showtime1note: '',
                checkbox_next_day: false
            })
        } else {
            var data = this.state.show_info;
            const index = data.findIndex(obj => obj._id === this.state.show_select);
            // var newData = [...data[index].show_times, newValue];
            // newData = _.sortBy(newData, function (dateObj) {
            //     return new Date(dateObj.show_info[0].date);
            // })
            data[index].show_times = [...data[index].show_times, newValue];

            for (var i = 0; i <= (data.length - 1); i++) {
                data[i].show_times = await sortAtoZ(data[i].show_times)
            }

            this.setState({
                show_info: data,
                showtime1: '',
                showtime1note: '',
                checkbox_next_day: false
            })
        }

    }

    updateShowtime(props, name) {
        var showtimes_edit = {
            [props]: name
        }
        this.setState({
            showtimes_edit: showtimes_edit,
        })
    }

    deleteShowtime = async (props) => {
        if (this.state.show_times_pin && this.state.show_info.length > 1) {
            var newShowInfo = [];
            this.state.show_info.forEach(obj => {
                var newTimes = obj.show_times.filter((e, i) => i !== props)
                obj.show_times = newTimes
                newShowInfo.push(obj)
            })
            //const newArray = this.state.show_info.filter((e, i) => i !== props);
            this.setState({
                show_info: newShowInfo
            })
        } else {
            const index = this.state.show_info.findIndex(obj => obj._id === this.state.show_select);
            let array = [...this.state.show_info];
            var filtered = array[index].show_times.filter((e, i) => i !== props)
            array[index].show_times = filtered
            //const newArray = this.state.show_info.filter((e, i) => i !== props);
            this.setState({
                show_info: array
            })
        }
    }

    addDate = () => {
        var newValue = {};
        var newMerch = {};
        var newLinks = {};
        var newFinals = {};
        if (!this.state.date) {
            alert('Please select a show date to add.')
        } else {
            // if (this.state.date_id) {
            //     newValue = {
            //         _id: this.state.date_id,
            //         date: this.state.date.toString(),
            //         show_times: [],
            //         show_time_notes: '',
            //         billing: '',
            //         billing_custom: '',
            //         age_limit: '',
            //         age_limit_custom: ''
            //         //shows: this.state.numberofshows
            //     }
            // } else {
            var d = new Date();
            var id = `${d.getDate()}${d.getMonth()}${d.getFullYear()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}`;
            const now = new Date(this.state.date);
            // const iso = new Date(this.state.date).toISOString();
            // var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

            var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
                now.getUTCDate(), now.getUTCHours(),
                now.getUTCMinutes(), now.getUTCSeconds());

            newValue = {
                _id: id,
                date: new Date(utc),
                show_times: [],
                show_time_notes: '',
                billing: '',
                billing_custom: '',
                age_limit: '',
                age_limit_custom: ''
                //shows: this.state.numberofshows
            }
            //}
            if (this.state.billing_pin && this.state.show_info[0]) {
                newValue.billing = this.state.show_info[0].billing
            }
            if (this.state.age_limit_pin && this.state.show_info[0]) {
                newValue.age_limit = this.state.show_info[0].age_limit
            }
            if (this.state.show_times_pin && this.state.show_info[0]) {
                newValue.show_times = this.state.show_info[0].show_times
            }
            if (this.state.show_time_notes_pin && this.state.show_info[0]) {
                newValue.show_time_notes = this.state.show_info[0].show_time_notes
            }
            if (this.state.merch_pin && this.state.merch_array[0]) {
                var merch_curr = this.state.merch_array[0];
                newMerch = {
                    _id: id,
                    soft: merch_curr.soft,
                    hard: merch_curr.hard,
                    seller: merch_curr.seller,
                    notes: merch_curr.notes,
                }
            } else {
                newMerch = {
                    _id: id,
                    soft: '',
                    hard: '',
                    seller: '',
                    notes: '',
                }
            }

            newLinks = {
                _id: id,
                announce: {
                    date: '',
                    url: ''
                },
                presale: {
                    date: '',
                    url: ''
                },
                on_sale: {
                    date: '',
                    url: ''
                }
            }

            newFinals = {
                _id: id,
                tickets_sold: '',
                gross_sales: '',
                net_sales: '',
                notes: ''
            }

            const newArray = [];
            const newMerchArray = [];
            const newLinksArray = [];
            const newFinalsArray = [];
            newArray.push(...this.state.show_info, newValue);
            newMerchArray.push(...this.state.merch_array, newMerch);
            newLinksArray.push(...this.state.links, newLinks);
            newFinalsArray.push(...this.state.finals, newFinals);

            var sortArray = _.sortBy(newArray, function (dateObj) {
                return new Date(dateObj.date);
            });

            var show_select = "";
            if (this.state.show_select === "") {
                show_select = sortArray[0]._id
            } else {
                show_select = this.state.show_select
            };

            if (this.state.show_info.length === 0) {
                this.setState({
                    billing_pin: true,
                    age_limit_pin: true,
                    merch_pin: true,
                    show_times_pin: true,
                    show_time_notes_pin: true
                })
            }

            this.setState({
                show_info: sortArray,
                show_select: show_select,
                merch_array: newMerchArray,
                links: newLinksArray,
                finals: newFinalsArray,
                date: '',
                numberofshows: 1
            })
        }
    }

    updateDate(props, name) {
        var dates_edit = {
            [props]: name
        }
        this.setState({
            dates_edit: dates_edit,
        })
    }

    deleteDate = async (props) => {
        const data = {
            show_id: props,
            deal_id: this.state.id
        };
        var verify = await this.props.verifyCounts(data);
        console.log(verify)
        if (verify) {
            this.deleteDateDialog(props);
        } else {
            this.deleteDatePerm(props);
        }
    }

    deleteDateDialog = (props) => {
        const addDialog = ({ onClose }) => {
            return (
                <div class="react-confirm-alert-z2">
                    <div style={{ width: 400, height: 'auto', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Alert:</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            You have ticket count or marketing data attached to this show date.  Deleting it will permanently delete all data.  Please edit the show date if you'd like to keep it.
                        </div>
                        <div style={{ marginTop: 10 }} class="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.deleteDatePerm(); onClose(); }}>Delete</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => onClose()}>Cancel</button>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }
        confirmAlert(options)
    }

    deleteDatePerm = (props) => {
        const newArray = this.state.show_info.filter((e, i) => e._id !== props);
        const newMerch = this.state.merch_array.filter((e, i) => e._id !== props);
        const newLinks = this.state.links.filter((e, i) => e._id !== props);
        const newFinals = this.state.finals.filter((e, i) => e._id !== props);
        const newScaling = this.state.scalingarray.filter((e, i) => e.show_id !== props);
        const newTaxes = this.state.taxesarray.filter((e, i) => e.show_id !== props);
        const newExp = this.state.expensearray.filter((e, i) => e.show_id !== props);

        var show_select = "";
        if (newArray.length > 0) {
            if (this.state.show_select === props) {
                show_select = newArray[0]._id
            } else {
                show_select = this.state.show_select
            }
        }
        this.setState({
            show_select: show_select,
            merch_array: newMerch,
            links: newLinks,
            finals: newFinals,
            scalingarray: newScaling,
            taxesarray: newTaxes,
            expensearray: newExp,
            show_info: newArray,
        })
    }

    addBonus = () => {
        var d = new Date();
        var id = `${d.getDate()}${d.getMonth()}${d.getFullYear()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}`;
        var gross = (Number(this.state.ticketcap1) - Number(this.state.ticketcomps1) - Number(this.state.ticketkills1)) * (Number(this.state.ticketprice1val) - Number(this.state.ticketff1) - Number(this.state.ticketaf1a) - Number(this.state.ticketaf1b))
        var newValue = {
            _id: id,
            bonus: this.state.bonus1,
            type: this.state.bonus1type,
            at_amount: this.state.bonus1atamount
        }

        const newArray = []
        newArray.push(...this.state.bonusarray, newValue)

        this.setState({
            bonusarray: newArray,
            bonus1: '',
            bonus1type: '',
            bonus1atamount: ''
        })
    }

    updateBonus = (index, name) => {
        var bonus_edit = {
            [index]: name
        }
        this.setState({
            bonus_edit: bonus_edit,
        })
    }

    handleBonusUpdate = (event, id) => {
        const name = event.target.name;
        const val = event.target.value;
        this.setState(prevState => {
            let bonus_val = Object.assign({}, prevState.bonus_val);
            bonus_val[id][name] = val;
            return { bonus_val };
        })
    };

    deleteBonus = (props) => {
        const newArray = this.state.bonusarray.filter((e, i) => e._id !== props);
        this.setState({ bonusarray: newArray })
    }

    addDeposit = () => {
        // const now = new Date(this.state.deposit1due);
        // var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
        //     now.getUTCDate(), now.getUTCHours(),
        //     now.getUTCMinutes(), now.getUTCSeconds());

        var newValue = {
            _id: this.state.depositsarray.length + 1,
            amount: this.state.deposit1val,
            due: this.state.deposit1due.toString()
            // due: new Date(utc)
        }

        const newArray = []
        newArray.push(...this.state.depositsarray, newValue)

        var sortedArray = _.sortBy(newArray, function (dateObj) {
            return new Date(dateObj.due);
        });

        this.setState({
            depositsarray: sortedArray,
            deposit1: '',
            deposit1due: '',
            deposit1val: ''
        })
    }

    updateDeposit(props, name) {
        var deposit_edit = {
            [props]: name
        }
        this.setState({
            deposit_edit: deposit_edit,
        })
    }

    handleDepositDateUpdate = (id, date) => {
        const now = new Date(date);
        var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
            now.getUTCDate(), now.getUTCHours(),
            now.getUTCMinutes(), now.getUTCSeconds());

        this.setState(prevState => {
            let deposit_val = Object.assign({}, prevState.deposit_val);
            deposit_val[id].due = new Date(utc);
            return { deposit_val };
        })
    };

    deleteDeposit = (props) => {
        const newArray = this.state.depositsarray.filter((e, i) => e._id !== props);
        this.setState({ depositsarray: newArray })
    }

    addContractIssued = () => {
        // const now = new Date(this.state.deposit1due);
        // var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
        //     now.getUTCDate(), now.getUTCHours(),
        //     now.getUTCMinutes(), now.getUTCSeconds());

        var newValue = {
            date: this.state.contractissued.toString(),
            // date: new Date(utc),
            note: this.state.contractissuednotes
        }

        const newArray = []
        newArray.push(...this.state.contractissuedarray, newValue)

        this.setState({
            contractissuedarray: newArray,
            contractissued: '',
            contractissuednotes: '',
        })
    }

    updateContractIssued = (props) => {
        const issued = this.state.contractissuedarray[props];
        this.setState({
            contractissued: new Date(issued.date),
            contractissuednotes: issued.note,
        })
        const newArray = this.state.contractissuedarray.filter((e, i) => i !== props);
        this.setState({
            contractissuedarray: newArray
        })
    }

    deleteContractIssued = (props) => {
        const newArray = this.state.contractissuedarray.filter((e, i) => i !== props);
        this.setState({
            contractissuedarray: newArray
        })
    }

    addContractReceived = () => {
        // const now = new Date(this.state.deposit1due);
        // var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
        //     now.getUTCDate(), now.getUTCHours(),
        //     now.getUTCMinutes(), now.getUTCSeconds());

        var newValue = {
            date: this.state.contractreceived.toString(),
            // date: new Date(utc),
            note: this.state.contractreceivednotes
        }

        const newArray = []
        newArray.push(...this.state.contractreceivedarray, newValue)

        this.setState({
            contractreceivedarray: newArray,
            contractreceived: '',
            contractreceivednotes: '',
        })
    }

    updateContractReceived = (props) => {
        const received = this.state.contractreceivedarray[props];
        this.setState({
            contractreceived: new Date(received.date),
            contractreceivednotes: received.note,
        })
        const newArray = this.state.contractreceivedarray.filter((e, i) => i !== props);
        this.setState({
            contractreceivedarray: newArray
        })
    }

    deleteContractReceived = (props) => {
        const newArray = this.state.contractreceivedarray.filter((e, i) => i !== props);
        this.setState({
            contractreceivedarray: newArray
        })
    }

    // addDepositReceived = () => {
    //     var newValue = {
    //         amount: this.state.depositreceivedamount,
    //         date: this.state.depositreceiveddate.toString(),
    //         note: this.state.depositreceivednotes,
    //         user: this.props.auth.user.tag
    //     }

    //     const newArray = []
    //     newArray = [...this.state.depositsreceivedarray, newValue]

    //     this.setState({
    //         depositsreceivedarray: newArray,
    //         depositreceivedamount: '',
    //         depositreceiveddate: '',
    //         depositreceivednotes: '',
    //     })
    // }

    updateDepositReceived = (props) => {
        const received = this.state.depositsreceivedarray[props];
        this.setState({
            depositreceivedamount: received.amount,
            depositreceiveddate: new Date(received.date),
            depositreceivednotes: received.note,
        })
        const newArray = this.state.depositsreceivedarray.filter((e, i) => i !== props);
        this.setState({
            depositsreceivedarray: newArray
        })
    }

    deleteDepositReceived = (props) => {
        const newArray = this.state.depositsreceivedarray.filter((e, i) => i !== props);
        this.setState({
            depositsreceivedarray: newArray
        })
    }

    handleDateChange = date => {
        if (date === null && this.state.date_id) {
            alert("This date has ticket count data attached to it.  If you'd like to keep that date, hit cancel and edit the date instead of deleting it.  If you'd like to delete this date and all of it's ticket count data, hit delete.")
        }
        this.setState({
            date: date
        });
    };

    handleContractIssuedDate = date => {
        const now = new Date(date);
        var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
            now.getUTCDate(), now.getUTCHours(),
            now.getUTCMinutes(), now.getUTCSeconds());

        this.setState({
            contractissued: new Date(utc)
        });
    };
    handleContractReceivedDate = date => {
        const now = new Date(date);
        var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
            now.getUTCDate(), now.getUTCHours(),
            now.getUTCMinutes(), now.getUTCSeconds());

        this.setState({
            contractreceived: new Date(utc)
        });
    };
    handleDepositReceivedDate = date => {
        const now = new Date(date);
        var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
            now.getUTCDate(), now.getUTCHours(),
            now.getUTCMinutes(), now.getUTCSeconds());

        this.setState({
            depositreceiveddate: new Date(utc)
        });
    };

    handleDeposit1DateChange = date => {
        const now = new Date(date);
        var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
            now.getUTCDate(), now.getUTCHours(),
            now.getUTCMinutes(), now.getUTCSeconds());

        this.setState({
            deposit1due: new Date(utc)
        });
    };

    handleAnnounceDateChange = (index, set, date) => {
        const now = new Date(date);
        var utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
            now.getUTCDate(), now.getUTCHours(),
            now.getUTCMinutes(), now.getUTCSeconds());

        let links = [...this.state.links];
        links[index][set].date = new Date(utc);
        this.setState({ links });
    };

    handleAnnounceUrlChange = (event, index, set) => {
        const value = event.target.value;
        let links = [...this.state.links];
        links[index][set].url = value;
        this.setState({ links });
    };

    formattedSoftMerchChange = (values) => {
        const { formattedmerchsoft, value } = values; this.setState({ merchsoft: formattedmerchsoft, merchsoftval: value })
    }
    formattedHardMerchChange = (values) => {
        const { formattedmerchhard, value } = values; this.setState({ merchhard: formattedmerchhard, merchhardval: value })
    }
    formattedTP1Change = (values) => {
        const { formattedPrice1, value } = values; this.setState({ ticketprice1: formattedPrice1, ticketprice1val: value })
    }
    formattedFF1Change = (values) => {
        const { formattedff1, value } = values; this.setState({ ticketff1: formattedff1, ticketff1val: value })
    }
    formattedAF1aChange = (values) => {
        const { formattedaf1a, value } = values; this.setState({ ticketaf1a: formattedaf1a, ticketaf1aval: value })
    }
    formattedAF1bChange = (values) => {
        const { formattedaf1b, value } = values; this.setState({ ticketaf1b: formattedaf1b, ticketaf1bval: value })
    }
    formattedFFAAChange = (values) => {
        const { formattedfacfeeAA, value } = values; this.setState({ facfeeAA: formattedfacfeeAA, facfeeAAval: value })
    }
    formattedAFAAChange = (values) => {
        const { formattedaddfeeAA, value } = values; this.setState({ addfeeAA: formattedaddfeeAA, addfeeAAval: value })
    }
    formattedAFBAChange = (values) => {
        const { formattedaddfeeBA, value } = values; this.setState({ addfeeBA: formattedaddfeeBA, addfeeBAval: value })
    }
    formattedDOSChange = (values) => {
        const { formattedos, value } = values; this.setState({ dos_increase: value })
    }


    formattedTax1Change = (values) => {
        const { formattedtax1, value } = values; this.setState({ taxamount1: formattedtax1, taxamount1val: value })
    }
    formattedTaxCap1Change = (values) => {
        const { formattedtaxcappedamount1, value } = values; this.setState({ taxcappedamount1: formattedtaxcappedamount1, taxcappedamount1val: value })
    }

    formattedExp1Change = (values) => {
        const { formattedexpenseamount1, value } = values; this.setState({ expenseamount1: formattedexpenseamount1, expenseamount1val: value })
    }
    formattedExpCap1Change = (values) => {
        const { formattedexpcappedamount1, value } = values; this.setState({ expcappedamount1: formattedexpcappedamount1, expcappedamount1val: value })
    }

    formattedGuaranteeChange = (values) => {
        const { formattedguarantee, value } = values; this.setState({ guarantee: formattedguarantee, guaranteeval: value })
    }
    formattedDealPercChange = (values) => {
        const { formatteddealpercentage, value } = values; this.setState({ dealpercentage: formatteddealpercentage, dealpercentageval: value })
    }
    formattedCustomWalkoutChange = (values) => {
        const { formattedcustomwalkout, value } = values; this.setState({ customwalkout: formattedcustomwalkout, customwalkoutval: value })
    }


    formattedBonusChange = (name, values) => {
        const { formattedValue, value } = values;
        this.setState({
            [name]: value
        })
    }

    formattedDeposit1 = (values) => {
        const { formatteddeposit1, value } = values; this.setState({ deposit1: formatteddeposit1, deposit1val: value })
    }
    formattedDeposit2 = (values) => {
        const { formatteddeposit2, value } = values; this.setState({ deposit2: formatteddeposit2, deposit2val: value })
    }
    formattedDeposit3 = (values) => {
        const { formatteddeposit3, value } = values; this.setState({ deposit3: formatteddeposit3, deposit3val: value })
    }
    formattedDeposit4 = (values) => {
        const { formatteddeposit4, value } = values; this.setState({ deposit4: formatteddeposit4, deposit4val: value })
    }

    combineFacFee = () => {
        var newArray = this.state.scalingarray.map(item => { item.facfee = this.state.facfeeAAval; return item; })
        this.setState({
            scalingarray: newArray,
            facfeeAA: '',
            facfeeAAval: ''
        })
    }
    combineAddFeeA = () => {
        var newArray = this.state.scalingarray.map(item => { item.addfeeA = this.state.addfeeAAval; return item; })
        this.setState({
            scalingarray: newArray,
            addfeeAA: '',
            addfeeAAval: ''
        })
    }
    combineAddFeeB = () => {
        var newArray = this.state.scalingarray.map(item => { item.addfeeB = this.state.addfeeBAval; return item; })
        this.setState({
            scalingarray: newArray,
            addfeeBA: '',
            addfeeBAval: ''
        })
    }

    handleTax1CheckboxChange = () => {
        if (this.state.checkboxtax1 === false)
            this.setState({
                checkboxtax1: true,
            });
        else {
            this.setState({
                checkboxtax1: false,
                taxcappedamount1: '',
            })
        }
    }
    handleExp1CheckboxChange = () => {
        if (this.state.checkboxexp1 === false)
            this.setState({
                checkboxexp1: true,
            });
        else {
            this.setState({
                checkboxexp1: false,
                expcappedamount1: '',
            })
        }
    }
    handleTax3CheckboxChange = () => {
        if (this.state.checkboxtax3 === false)
            this.setState({
                checkboxtax3: true,
            });
        else {
            this.setState({
                checkboxtax3: false,
                taxcappedamount3: '',
            })
        }
    }
    handleTax4CheckboxChange = () => {
        if (this.state.checkboxtax4 === false)
            this.setState({
                checkboxtax4: true,
            });
        else {
            this.setState({
                checkboxtax4: false,
                taxcappedamount4: '',
            })
        }
    }


    toggleBuyerName = () => {
        this.props.getContacts();
    }

    handleClick = () => {
        this.props.toggle();
    };

    onEdit = () => {
        this.setState({
            edit: !this.state.edit
        })
    }

    onSave = async () => {
        // var production_contact = {};
        // var marketing_contact = {};
        // var counts_contact = {};
        // if (this.state.production_contact === "Custom") {
        //     production_contact = {
        //         contact_id: 'Custom',
        //         role: 'Production Manager',
        //         first_name: this.state.production_contact_custom.first_name,
        //         last_name: this.state.production_contact_custom.last_name,
        //         email: this.state.production_contact_custom.email,
        //         office_phone: this.state.production_contact_custom.office_phone,
        //         cell_phone: this.state.production_contact_custom.cell_phone,
        //     }
        // } else {
        //     production_contact = {
        //         contact_id: this.state.production_contact
        //     }
        // }
        // if (this.state.marketing_contact === "Custom") {
        //     marketing_contact = {
        //         contact_id: 'Custom',
        //         role: 'Marketing Manager',
        //         first_name: this.state.marketing_contact_custom.first_name,
        //         last_name: this.state.marketing_contact_custom.last_name,
        //         email: this.state.marketing_contact_custom.email,
        //         office_phone: this.state.marketing_contact_custom.office_phone,
        //         cell_phone: this.state.marketing_contact_custom.cell_phone,
        //     }
        // } else {
        //     marketing_contact = {
        //         contact_id: this.state.marketing_contact
        //     }
        // }
        // if (this.state.counts_contact === "Custom") {
        //     counts_contact = {
        //         contact_id: 'Custom',
        //         role: 'Ticket Count Contact',
        //         first_name: this.state.counts_contact_custom.first_name,
        //         last_name: this.state.counts_contact_custom.last_name,
        //         email: this.state.counts_contact_custom.email,
        //         office_phone: this.state.counts_contact_custom.office_phone,
        //         cell_phone: this.state.counts_contact_custom.cell_phone
        //     }
        // } else {
        //     counts_contact = {
        //         contact_id: this.state.counts_contact
        //     }
        // }
        if (this.state.show_info.length <= 0 || !this.state.artistid || !this.state.venueid) {
            alert('Please enter a valid date, artist, and venue')
        } else {
            const dupCheck = {
                client_id: this.state.artistid,
                show_info: this.state.show_info,
                venue_id: this.state.venueid,
                buyer_id: this.state.buyercompanyid,
            }
            var check = await this.props.offerDupCheck(dupCheck);
            if (check.length > 0 && this.state.render_type === 'enter') {
                this.duplicateDialog(check);
            } else {
                this.finishSave();
            }
        }
    }

    finishSave = async () => {
        if (this.state.billing === "Custom") {
            this.setState({
                billing: this.state.billingcustom
            })
        }
        if (this.state.agelimit === "Custom") {
            this.setState({
                agelimit: this.state.agelimitcustom
            })
        }

        var id = this.state.id;

        var sellableArray = [];
        if (this.state.scalingarray && this.state.show_select) {
            this.state.show_info.forEach(item => {
                var sellable = this.state.scalingarray.reduce((cnt, o) => {
                    var data = 0;
                    if (o.show_id === item._id) {
                        data = Number(o.capacity) - Number(o.comps) - Number(o.kills);
                    } return cnt + data
                }, 0);
                if (sellable > 0) {
                    const data = {
                        _id: item._id,
                        amt: Number(sellable)
                    }
                    sellableArray.push(data)
                } else {
                    const data = {
                        _id: item._id,
                        amt: 0
                    }
                    sellableArray.push(data)
                }
            })
        }

        const deal_terms = {
            currency: this.state.feecurrency,
            guarantee: this.state.guaranteeval,
            deal_type: this.state.dealtype,
            deal_percent: this.state.dealpercentageval,
            custom_walkout: this.state.customwalkoutval,
            //bonus_type: this.state.bonustype,
            bonuses: this.state.bonusarray,
            deal_points: this.state.dealpoints
        }

        const config = {
            combined: {
                scaling: !this.state.seperate_scaling,
                taxes: !this.state.seperate_taxes,
                expenses: !this.state.seperate_expenses
            },
            commission_percent: this.state.commission_percent,
            currency: {
                ticket_scaling: this.state.currency.ticket_scaling,
                expenses: this.state.currency.expenses,
                deal_terms: this.state.currency.deal_terms
            },
            pinned: {
                billing: this.state.billing_pin,
                age_limit: this.state.age_limit_pin,
                merch: this.state.merch_pin,
                show_times: this.state.show_times_pin,
                show_time_notes: this.state.show_time_notes_pin,
                ticket_scaling: this.state.scaling_pin,
                taxes: this.state.tax_pin,
                expenses: this.state.expense_pin,
            },
        }

        var k_iss = false;
        var k_rec = false;
        var d_rec = false;
        if (this.state.contractissuedarray.length > 0) {
            k_iss = true
        };
        if (this.state.contractreceivedarray.length > 0) {
            k_rec = true
        };
        if (this.state.depositsreceivedarray.length > 0 || this.state.depositsarray.length <= 0) {
            var dep_due = this.state.depositsarray.reduce((cnt, o) => { return cnt + Number(o.amount) }, 0);
            var dep_rec = this.state.depositsreceivedarray.reduce((cnt, o) => { return cnt + Number(o.amount) }, 0);
            if (dep_rec >= dep_due) {
                d_rec = true
            }
        };

        const prog = {
            k_iss: k_iss,
            k_rec: k_rec,
            d_rec: d_rec,
            d_due: this.state.depositsarray,
            settled: this.state.finals_comp
        }

        var walkout = '';
        var walkout_net = '';
        var commission = '';
        if (this.state.finals_comp) {
            if (this.state.settlement.overage) {
                walkout = Number(this.state.guarantee) + Number(this.state.settlement.overage);
                walkout_net = Number(this.state.guarantee) + Number(this.state.settlement.overage) - Number(this.state.settlement.withholding) - Number(this.state.settlement.deductions);
                if (this.state.settle_net) {
                    if (this.state.commission_percent) {
                        commission = walkout_net * (this.state.commission_percent / 100);
                    } else {
                        commission = walkout_net * .10;
                    }
                } else {
                    if (this.state.commission_percent) {
                        commission = walkout * (this.state.commission_percent / 100);
                    } else {
                        commission = walkout * .10;
                    }
                }
            } else {
                walkout = Number(this.state.guarantee);
                walkout_net = Number(this.state.guarantee) - Number(this.state.settlement.withholding) - Number(this.state.settlement.deductions);
                if (this.state.settle_net) {
                    if (this.state.commission_percent) {
                        commission = walkout_net * (this.state.commission_percent / 100);
                    } else {
                        commission = walkout_net * .10;
                    }
                } else {
                    if (this.state.commission_percent) {
                        commission = walkout * (this.state.commission_percent / 100);
                    } else {
                        commission = walkout * .10;
                    }
                }
            };

        }

        const financials = {
            guarantee: this.state.guaranteeval,
            potential: '',
            settlement_gross: walkout,
            settlement_net: walkout_net,
            commission: commission
        }

        //const add_contacts = this.state.add_contacts;
        //const add_contacts = [  ...production_contact, ...marketing_contact, ...counts_contact ];

        const contacts = {
            buyer_contact: this.state.buyername,
            signatory: this.state.signatory,
            // production_contact: production_contact,
            // marketing_contact: marketing_contact,
            // counts_contact: counts_contact,
            add_contacts: this.state.add_contacts
        }

        const offer = {
            client_id: this.state.artistid,
            //agent: this.state.agent,
            status: this.state.status,
            show_info: this.state.show_info,
            venue_id: this.state.venueid,
            buyer_id: this.state.buyercompanyid,
            //buyer_contact_id: this.state.buyername,
            //signatory_id: this.state.signatory,
            contacts: contacts,
            sellable_cap: sellableArray,
            deal_currency: this.state.feecurrency,
            guarantee: this.state.guaranteeval,
            //walkout_potential: '',
            financials: financials,
            prog: prog,
            links: this.state.links,
            count_config: this.state.count_config
        }

        var deposits = {
            deposits: this.state.depositsarray,
            notes: this.state.depositnotes
        }

        var track = {
            contract_issued: this.state.contractissuedarray,
            contract_received: this.state.contractreceivedarray,
            deposits_received: this.state.depositsreceivedarray,
        }

        var scaling = {
            scaling: this.state.scalingarray,
            dos_increase: this.state.dos_increase,
            notes: this.state.ticketscalingnotes
        }

        var deal_notes = {
            exclusivity: this.state.exclusivity,
            production: this.state.productionnotes,
            deal_memo: this.state.dealmemonotes,
            travel: this.state.travelnotes
        }

        var settlement = this.state.settlement;
        settlement.settle_net = this.state.settle_net;

        const offerdetail = {
            _id: id,
            merch: this.state.merch_array,
            scaling: scaling,
            expenses: this.state.expensearray,
            taxes: this.state.taxesarray,
            deal_terms: deal_terms,
            deposits: deposits,
            deal_notes: deal_notes,
            track: track,
            finals: this.state.finals,
            settlement: settlement,
            config: config
        }

        const full = {
            offer: offer,
            offerdetail: offerdetail
        }

        if (this.state.render_type === 'render') {
            const log_status = await this.props.updateOfferFull(full, id);
            if (log_status.type === 'error') {
                this.props.toggleErrorSnackbar(log_status);
            } else {
                this.props.toggleStatusSnackbar(log_status.msg);
                this.setState({
                    header: this.state.artistbatch.client,
                    edit: false,
                    render_type: 'render',
                    show_info_conf: this.state.show_info
                })
            }
        } else {
            const log_status = await this.props.addOffer(full);
            if (log_status.type === 'error') {
                this.props.toggleErrorSnackbar(log_status.msg);
            } else {
                this.props.toggleStatusSnackbar(log_status.msg);
                this.setState({
                    header: this.state.artistbatch.client,
                    edit: false,
                    render_type: 'render',
                    show_info_conf: this.state.show_info,
                    id: log_status.id
                }, () => this.setState({ render_type: 'render' }))
            }
        }
    }

    handleFinalsChange = (e, d, n, i) => {
        const index = i;
        var value = '';
        if (n === 'notes') {
            value = e
        } else {
            value = this.withoutSign(e);
        }
        let finals = [...this.state.finals];
        finals[index][n] = value;
        this.setState({ finals });
        //this.setState({
        //    finals: [{date: d, [n]: e}]
        //})
    }

    formattedDepositReceived = (e) => {
        // const { formatted, value } = values; 
        // console.log(formatted, value)
        var val = e.target.value.toString();
        val = val.replace(/%/g, "");
        val = val.replace('$', "");
        val = val.replace(/,/g, "");
        this.setState({ depositreceivedamount: val })
    }

    handleFormattedChange = (e, set) => {
        if (set === "settlement") {
            const name = e.target.name;
            var val = null;
            if (name === 'notes') {
                val = e.target.value
            } else {
                val = this.withoutSign(e.target.value)
            }
            this.setState(prevState => {
                let settlement = Object.assign({}, prevState.settlement);
                settlement[name] = val;
                return { settlement };
            })
        } else {
            const name = e.target.name;
            var val = e.target.value.toString();
            val = val.replace(/%/g, "");
            val = val.replace('$', "");
            val = val.replace(/,/g, "");
            this.setState({
                [name]: Number(val)
            })
        }
    }

    withoutSign = (value) => {
        var v = value.replace("$", "")
        return (
            parseFloat(v.replace(/,/g, ''))
        );
    };

    toggleDateSelect = (e) => {
        this.setState({
            show_select: e
        })
    }

    togglePin = (e) => {
        this.setState({
            [e]: !this.state[e]
        })
    }

    combinePinnedArrayFirst = (e, s, a) => {
        if (this.state[e] === false) {
            var array = [];
            this.state.datesarray.forEach(item => {
                const newItem = {
                    _id: item._id,
                    [a]: this.state[a]
                }
                array.push(newItem)
            })
            this.setState({
                [e]: true,
                [s]: array
            })
        } else {
            var array = [];
            this.state.datesarray.forEach(item => {
                const newItem = {
                    _id: item._id,
                    [a]: ''
                }
                array.push(newItem)
            })
            array[0] = this.state[s][0]
            this.setState({
                [e]: false,
                [s]: array
            })
        }
    }

    combinePinnedArray = (e, s, a, index) => {
        if (this.state[e] === false) {
            var val;
            if (a === "merch") {
                val = this.state[s][index]
            } else {
                val = this.state[s][index][a];
            };
            var array = [];
            if (val === "Custom") {
                var v = `${a}_custom`;
                var val_custom = this.state[s][index][v];
                this.state[s].forEach(function (item, index) {
                    item[a] = val;
                    item[v] = val_custom;
                    array.push(item)
                });
            } else {
                this.state[s].forEach(function (item, ind) {
                    if (a === "merch") {
                        item = {
                            _id: item._id,
                            hard: val.hard,
                            soft: val.soft,
                            seller: val.seller,
                            notes: val.notes
                        };
                    } else {
                        item[a] = val;
                    }
                    array.push(item)
                });
            }
            this.setState({
                [e]: true,
                [s]: array
            })
        } else {
            // var val = this.state.show_info[index][a];
            // var array = [];
            // this.state.show_info.forEach(function (item, index) {
            //     item[a] = "";
            //     array.push(item)
            // });
            // array[index][a] = val
            this.setState({
                [e]: false,
                // show_info: array
            }, () => this.setState({ [e]: false }))
        }
    }

    combinePinnedScalingArray = (e, s) => {
        if (this.state[e] === false) {
            let val = [];
            var array = [];
            this.state[s].map((item, ind) => {
                if (item.show_id === this.state.show_select) {
                    val.push(item)
                }
            })
            this.state.show_info.forEach(function (item, ind) {
                const show_id = item._id;
                var test = val.map((el, ind) => {
                    el.show_id = show_id;
                    return ({ ...el })
                })
                array.push(...test)
            });
            this.setState({
                [e]: true,
                [s]: array
            })
        } else {
            this.setState({
                [e]: false,
            }, () => this.setState({ [e]: false }))
        }
    }

    handlePinnedArray2Change = (event, set, show_index) => {
        const name = event.target.name;
        const val = event.target.value;

        var array = [];
        this.state[set].map((item, index) => {
            if (index === show_index) {
                item[name] = val;
                array.push({ ...item })
            } else {
                array.push({ ...item })
            }
        });
        this.setState({
            [set]: array
        })
    };

    handlePinnedArrayChange = (event, set) => {
        const name = event.target.name;
        const val = event.target.value;

        var array = [];
        this.state[set].forEach(function (item, index) {
            item[name] = val;
            array.push(item)
        });
        this.setState({
            [set]: array
        })
    };

    handlePinnedArrayFormattedChange = (values, id, name, set) => {
        const { formattedValue, value } = values;

        var array = [];
        this.state.merch_array.forEach(function (item, index) {
            item[name] = value;
            array.push(item)
        });
        this.setState({
            merch_array: array
        })
    };

    handleCurrencyChange = (event, set) => {

        this.setState(prevState => {
            let currency = Object.assign({}, prevState.currency);
            currency[set] = event.code;
            return { currency };
        })
    }

    toggleCurrencyDialog = () => {
        this.setState({
            currency_dialog: !this.state.currency_dialog
        })
    }

    toggleCommissionDialog = () => {
        this.setState({
            commission_dialog: !this.state.commission_dialog
        })
    }

    currencyDialog = () => {
        const addDialog = ({ onClose }) => {
            return (
                <div class="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold', width: 'auto' }}>Options:</h1>
                        <h1 style={{ width: '100%', fontSize: 14, marginTop: 20 }}>Change Currency:</h1>
                        <table style={{ width: '100%', marginTop: 10 }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Ticket Scaling</td>
                                    <td style={{ width: '50%' }}><CurrencyAutocomplete val={this.state.currency.ticket_scaling} onChange={this.handleCurrencyChange} set="ticket_scaling" /></td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Expenses</td>
                                    <td style={{ width: '50%' }}><CurrencyAutocomplete val={this.state.currency.expenses} onChange={this.handleCurrencyChange} set="expenses" /></td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', fontSize: 12 }}>Deal Terms</td>
                                    <td style={{ width: '50%' }}><CurrencyAutocomplete val={this.state.currency.deal_terms} onChange={this.handleCurrencyChange} set="deal_terms" /></td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ marginTop: 20 }} class="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { onClose(); }}>Save</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            )
        }

        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }

        confirmAlert(options)
    }

    toggleSeperateValues = (e, a) => {
        // if (this.state[e]) {
        //     var newArray = this.state[a].filter(item => item.show_id === this.state.show_select)
        //     newArray.map((item, index) => {
        //         item.show_id = "combined";
        //         item.show_index = index
        //     })
        //     this.setState({
        //         [a]: newArray,
        //         [e]: false
        //     })
        // } else {
        //     var newArray = this.state[a];
        //     newArray.map((item, index) => {
        //         item.show_id = this.state.show_select;
        //         item.show_index = index
        //     })
        //     this.setState({
        //         [a]: newArray,
        //         [e]: true
        //     })
        // }
        this.setState({
            [e]: !this.state[e]
        })
    }

    toggleBonus = () => {
        if (this.state.bonus_toggle) {
            if (this.state.bonusarray.length > 0) {
                this.confirmChangeDialog();
            } else {
                this.setState({
                    bonus_toggle: false,
                    bonusarray: []
                })
            }
        } else {
            this.setState({
                bonus_toggle: true
            })
        }

    }

    resetBonuses = () => {
        this.setState({
            bonus_toggle: false,
            bonusarray: []
        })
    }

    confirmChangeDialog = () => {
        const addDialog = ({ onClose }) => {
            return (
                <div class="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Alert:</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            You have bonuses entered.  Are you sure you'd like to remove these?
                        </div>
                        <div style={{ marginTop: 10 }} class="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.resetBonuses(); onClose(); }}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => onClose()}>No</button>
                        </div>
                    </div>
                </div>
            )
        }
        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }
        confirmAlert(options)
    }

    droppedCards = (cards, set) => {
        this.setState({
            [set]: cards
        })
    }

    handleSettlementCheckbox = (e) => {
        var sub = e.target.name;
        this.setState({
            [sub]: !this.state[sub]
        })
    }

    resetTracking = (type) => {
        if (type === 'contract_received') {
            this.setState({
                contractreceived: '',
                contractreceivednotes: '',
            })
        } else if (type === 'contract_issued') {
            this.setState({
                contractissued: '',
                contractissuednotes: '',
            })
        } else if (type === 'deposit_received') {
            this.setState({
                depositreceiveddate: '',
                depositreceivedamount: '',
                depositreceivednotes: '',
            })
        }
    }

    duplicateDialog = async (check) => {
        const checkData = await check.map(item => {
            var cIndex = this.props.clients.clients.findIndex(i => i._id === item.client_id);
            var client = '';
            if (cIndex >= 0) {
                client = this.props.clients.clients[cIndex].client;
            };
            var vIndex = this.props.items.items.findIndex(i => i._id === item.venue_id);
            var venue = '';
            if (vIndex >= 0) {
                venue = this.props.items.items[vIndex].venue_name;
            };
            var fIndex = this.props.festivals.items.findIndex(i => i._id === item.venue_id);
            if (fIndex >= 0) {
                venue = this.props.festivals.items[fIndex].venue_name;
            };
            const today = moment();
            var val = _.get(item, 'show_info')
            var closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            var furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            closest = moment(closest.date).utc().format('MM/DD/YYYY');
            furthest = moment(furthest.date).utc().format('MM/DD/YYYY');
            var date = '';
            if (closest === furthest) {
                date = closest;
            } else {
                date = closest + ' - ' + furthest;
            };
            const obj = {
                client: client,
                venue: venue,
                date: date
            }
            return obj
        })
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Duplicate Offers</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ fontSize: 14, color: '#000', marginBottom: 10 }}>The following deals are alredy entered in the system.  Would you like to continue saving?</p>
                            {checkData.map((item, index) => {
                                return (
                                    <p style={{ fontSize: 12, color: '#000' }} key={index}>{item.date} - {item.client} - {item.venue}</p>
                                )
                            })}
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.finishSave(); onClose(); }}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={onClose}>No</button>
                        </div>
                    </div>
                </div>
            )
        }

        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }

        confirmAlert(options)
    }

    handleCheckbox = (e) => {
        this.setState({
            [e]: !this.state[e]
        })
    }

    handleTcAutoCheckbox(name) {
        this.setState(prevState => {
            let tc_auto = Object.assign({}, prevState.tc_auto);
            tc_auto[name] = !tc_auto[name];
            return { tc_auto };
        })
    }

    handleTcCheckbox(name) {
        this.setState(prevState => {
            let count_config = Object.assign({}, prevState.count_config);
            count_config[name] = !count_config[name];
            return { count_config };
        })
    }

    checkMWF = () => {
        if (this.state.tc_auto.monday) {
            var tc_auto = {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false
            }
            this.setState({
                tc_auto: tc_auto
            })
        } else {
            var tc_auto = {
                monday: true,
                tuesday: false,
                wednesday: true,
                thursday: false,
                friday: true
            }
            this.setState({
                tc_auto: tc_auto
            })
        }
    }

    checkTT = () => {
        if (this.state.tc_auto.tuesday) {
            var tc_auto = {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false
            }
            this.setState({
                tc_auto: tc_auto
            })
        } else {
            var tc_auto = {
                monday: false,
                tuesday: true,
                wednesday: false,
                thursday: true,
                friday: false
            }
            this.setState({
                tc_auto: tc_auto
            })
        }
    }

    handleCountConChange = (rec) => {
        if (rec) {
            var newRec = this.state.count_config.contacts.filter(item => item._id === rec._id);
            var newArr = [...rec, ...newRec];
            this.setState(prevState => {
                let count_config = Object.assign({}, prevState.count_config);
                count_config.contacts = newArr;
                return { count_config };
            })
        } else {
            this.setState(prevState => {
                let count_config = Object.assign({}, prevState.count_config);
                count_config.contacts = [];
                return { count_config };
            })
        }
    }

    handleTcConChange(e) {
        if (e && e.target && e.target.name === 'group') {
            const obj = {
                _id: e.target.value
            }
            const arr = [obj, ...this.state.count_config.group];
            this.setState(prevState => {
                let count_config = Object.assign({}, prevState.count_config);
                count_config.group = arr;
                return { count_config };
            })
        } else {
            this.setState(prevState => {
                let count_config = Object.assign({}, prevState.count_config);
                count_config[e.target.name] = e.target.value;
                return { count_config };
            })
        }
    }

    removeEmailGroupOffer = (id) => {
        var group = this.state.count_config.group.filter(item => item._id !== id);
        this.setState(prevState => {
            let count_config = Object.assign({}, prevState.count_config);
            count_config.group = group;
            return { count_config };
        })
    }

    handleEmailBlur = (rec) => {
        if (rec) {
            var newArr = [...this.state.count_config.contacts, rec];
            this.setState(prevState => {
                let count_config = Object.assign({}, prevState.count_config);
                count_config.contacts = newArr;
                return { count_config };
            })
        }
    }

    render() {

        const captotal = this.state.scalingarray.reduce(function (cnt, o) { return cnt + Number(o.capacity); }, 0);
        const captotalval = parseInt(captotal, 10);

        const single_captotal = this.state.scalingarray.filter(item => item.show_id === this.state.show_select).reduce(function (cnt, o) { return cnt + Number(o.capacity); }, 0);
        const single_captotalval = parseInt(single_captotal, 10);

        const compstotal = this.state.scalingarray.reduce(function (cnt, o) { return cnt + Number(o.comps); }, 0);
        const compstotalval = parseInt(compstotal, 10)

        const single_compstotal = this.state.scalingarray.filter(item => item.show_id === this.state.show_select).reduce(function (cnt, o) { return cnt + Number(o.comps); }, 0);
        const single_compstotalval = parseInt(single_compstotal, 10)

        const killstotal = this.state.scalingarray.reduce(function (cnt, o) { return cnt + Number(o.kills); }, 0);
        const killstotalval = parseInt(killstotal, 10)

        const single_killstotal = this.state.scalingarray.filter(item => item.show_id === this.state.show_select).reduce(function (cnt, o) { return cnt + Number(o.kills); }, 0);
        const single_killstotalval = parseInt(single_killstotal, 10)

        // const grosstotal = this.state.scalingarray.reduce(function (cnt, o) { return cnt + Number(o.gross); }, 0);
        const grosstotal = this.state.scalingarray.reduce(function (cnt, o) { return cnt + ((Number(o.capacity) - Number(o.comps) - Number(o.kills)) * (Number(o.price) - Number(o.fac_fee) - Number(o.add_fee_A) - Number(o.add_fee_B))); }, 0);
        const grosstotalval = parseInt(grosstotal, 10)



        const gross_pot = this.state.scalingarray.reduce(function (cnt, o) { return cnt + ((Number(o.capacity) - Number(o.comps) - Number(o.kills)) * (Number(o.price) - Number(o.fac_fee) - Number(o.add_fee_A) - Number(o.add_fee_B))); }, 0);
        const gross_pot_val = parseInt(gross_pot, 10)

        var gross_pot_single = null;
        if (this.state.seperate_scaling) {
            var nArray = this.state.scalingarray
                .filter(scaling => scaling.show_id === this.state.show_select)
                .reduce(function (cnt, o) { return cnt + ((Number(o.capacity) - Number(o.comps) - Number(o.kills)) * (Number(o.price) - Number(o.fac_fee) - Number(o.add_fee_A) - Number(o.add_fee_B))); }, 0);
            gross_pot_single = parseInt(nArray, 10)
        } else {
            var nArray = this.state.scalingarray
                .reduce(function (cnt, o) { return cnt + ((Number(o.capacity) - Number(o.comps) - Number(o.kills)) * (Number(o.price) - Number(o.fac_fee) - Number(o.add_fee_A) - Number(o.add_fee_B))); }, 0);
            gross_pot_single = parseInt(nArray, 10) / 2
        }

        const single_gross_pot = this.state.scalingarray.filter(item => item.show_id === this.state.show_select).reduce(function (cnt, o) { return cnt + ((Number(o.capacity) - Number(o.comps) - Number(o.kills)) * (Number(o.price) - Number(o.fac_fee) - Number(o.add_fee_A) - Number(o.add_fee_B))); }, 0);
        const single_gross_pot_val = parseInt(single_gross_pot, 10)

        const gross_tickets = captotal - compstotal - killstotal
        const gross_tickets_single = single_captotal - single_compstotal - single_killstotal

        const flat_tax_total = this.state.taxesarray
            .filter(tax => tax.type === "Flat" && tax.cap === false || tax.type === "Flat" && tax.cap === 'false')
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        const flat_tax_total_single = this.state.taxesarray
            .filter(tax => tax.type === "Flat" && tax.cap === false && tax.show_id === this.state.show_select || tax.type === "Flat" && tax.cap === 'false' && tax.show_id === this.state.show_select)
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        const flat_tax_total_cap = this.state.taxesarray
            .filter(tax => tax.type === "Flat" && tax.cap === true || tax.type === "Flat" && tax.cap === 'true')
            .reduce(function (cnt, o) { var val = o.amount; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const flat_tax_total_cap_single = this.state.taxesarray
            .filter(tax => tax.type === "Flat" && tax.cap === true && tax.show_id === this.state.show_select || tax.type === "Flat" && tax.cap === 'true' && tax.show_id === this.state.show_select)
            .reduce(function (cnt, o) { var val = o.amount; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const percent_tax = this.state.taxesarray
            .filter(tax => tax.type === "Percent" && tax.cap === false || tax.type === "Percent" && tax.cap === 'false')
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const percent_tax_total = (percent_tax * .01) * gross_pot

        const percent_tax_single = this.state.taxesarray
            .filter(tax => tax.type === "Percent" && tax.cap === false && tax.show_id === this.state.show_select || tax.type === "Percent" && tax.cap === 'false' && tax.show_id === this.state.show_select)
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const percent_tax_total_single = (percent_tax_single * .01) * gross_pot_single

        const percent_tax_total_cap = this.state.taxesarray
            .filter(tax => tax.type === "Percent" && tax.cap === true || tax.type === "Percent" && tax.cap === 'true')
            .reduce(function (cnt, o) { var val = (o.amount * .01) * gross_pot; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const percent_tax_total_cap_single = this.state.taxesarray
            .filter(tax => tax.type === "Percent" && tax.cap === true && tax.show_id === this.state.show_select || tax.type === "Percent" && tax.cap === 'true' && tax.show_id === this.state.show_select)
            .reduce(function (cnt, o) { var val = (o.amount * .01) * gross_pot_single; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const percent_tax_divisor = this.state.taxesarray
            .filter(tax => tax.type === "Percent (divisor)" && tax.cap === false || tax.type === "Percent (divisor)" && tax.cap === 'false')
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const percent_tax_divisor_total = gross_pot - (gross_pot / (1 + (percent_tax_divisor / 100)))

        const percent_tax_divisor_single = this.state.taxesarray
            .filter(tax => tax.type === "Percent (divisor)" && tax.cap === false && tax.show_id === this.state.show_select || tax.type === "Percent (divisor)" && tax.cap === 'false' && tax.show_id === this.state.show_select)
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const percent_tax_divisor_total_single = gross_pot_single - (gross_pot_single / (1 + (percent_tax_divisor_single / 100)))

        const percent_tax_divisor_total_cap = this.state.taxesarray
            .filter(tax => tax.type === "Percent (divisor)" && tax.cap === true || tax.type === "Percent (divisor)" && tax.cap === 'true')
            .reduce(function (cnt, o) { var val = gross_pot - (gross_pot / (1 + (o.amount / 100))); if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const percent_tax_divisor_total_cap_single = this.state.taxesarray
            .filter(tax => tax.type === "Percent (divisor)" && tax.cap === true && tax.show_id === this.state.show_select || tax.type === "Percent (divisor)" && tax.cap === 'true' && tax.show_id === this.state.show_select)
            .reduce(function (cnt, o) { var val = gross_pot_single - (gross_pot_single / (1 + (o.amount / 100))); if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const perticket_tax = this.state.taxesarray
            .filter(tax => tax.type === "Per Ticket" && tax.cap === false || tax.type === "Per Ticket" && tax.cap === 'false')
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const perticket_tax_total = perticket_tax * gross_tickets

        const perticket_tax_single = this.state.taxesarray
            .filter(tax => tax.type === "Per Ticket" && tax.cap === false && tax.show_id === this.state.show_select || tax.type === "Per Ticket" && tax.cap === 'false' && tax.show_id === this.state.show_select)
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const perticket_tax_total_single = perticket_tax_single * gross_tickets_single

        const perticket_tax_cap = this.state.taxesarray
            .filter(tax => tax.type === "Per Ticket" && tax.cap === true || tax.type === "Per Ticket" && tax.cap === 'true')
            .reduce(function (cnt, o) { var val = o.amount * gross_tickets; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const perticket_tax_cap_single = this.state.taxesarray
            .filter(tax => tax.type === "Per Ticket" && tax.cap === true && tax.show_id === this.state.show_select || tax.type === "Per Ticket" && tax.cap === 'true' && tax.show_id === this.state.show_select)
            .reduce(function (cnt, o) { var val = o.amount * gross_tickets_single; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);


        const taxtotal = flat_tax_total + flat_tax_total_cap + ((percent_tax_total + percent_tax_total_cap) / this.state.show_info.length) + ((percent_tax_divisor_total + percent_tax_divisor_total_cap) / this.state.show_info.length) + perticket_tax_total + perticket_tax_cap
        const taxtotalval = parseInt(taxtotal, 10)

        const taxtotal_single = flat_tax_total_single + flat_tax_total_cap_single + percent_tax_total_single + percent_tax_total_cap_single + percent_tax_divisor_total_single + percent_tax_divisor_total_cap_single + perticket_tax_total_single + perticket_tax_cap_single
        const taxtotalval_single = parseInt(taxtotal_single, 10)

        const flat_exp_total = this.state.expensearray
            .filter(exp => exp.type === "Flat" && exp.cap === false || exp.type === "Flat" && exp.cap === 'false')
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        const flat_exp_total_single = this.state.expensearray
            .filter(exp => exp.type === "Flat" && exp.cap === false && exp.show_id === this.state.show_select || exp.type === "Flat" && exp.cap === 'false' && exp.show_id === this.state.show_select)
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        const flat_exp_total_cap = this.state.expensearray
            .filter(exp => exp.type === "Flat" && exp.cap === true || exp.type === "Flat" && exp.cap === 'true')
            .reduce(function (cnt, o) { var val = o.amount; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const flat_exp_total_cap_single = this.state.expensearray
            .filter(exp => exp.type === "Flat" && exp.cap === true && exp.show_id === this.state.show_select || exp.type === "Flat" && exp.cap === 'true' && exp.show_id === this.state.show_select)
            .reduce(function (cnt, o) { var val = o.amount; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const percent_exp = this.state.expensearray
            .filter(exp => exp.type === "Percent" && exp.cap === false || exp.type === "Percent" && exp.cap === 'false')
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const percent_exp_total = (percent_exp * .01) * grosstotal

        const percent_exp_single = this.state.expensearray
            .filter(exp => exp.type === "Percent" && exp.cap === false && exp.show_id === this.state.show_select || exp.type === "Percent" && exp.cap === 'false' && exp.show_id === this.state.show_select)
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const percent_exp_total_single = (percent_exp_single * .01) * gross_pot_single

        const percent_exp_total_cap = this.state.expensearray
            .filter(exp => exp.type === "Percent" && exp.cap === true || exp.type === "Percent" && exp.cap === 'true')
            .reduce(function (cnt, o) { var val = (o.amount * .01) * gross_pot; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const percent_exp_total_cap_single = this.state.expensearray
            .filter(exp => exp.type === "Percent" && exp.cap === true && exp.show_id === this.state.show_select || exp.type === "Percent" && exp.cap === 'true' && exp.show_id === this.state.show_select)
            .reduce(function (cnt, o) { var val = (o.amount * .01) * gross_pot_single; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const perticket_exp = this.state.expensearray
            .filter(exp => exp.type === "Per Ticket" && exp.cap === false || exp.type === "Per Ticket" && exp.cap === 'false')
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const perticket_exp_total = perticket_exp * gross_tickets;

        const perticket_exp_single = this.state.expensearray
            .filter(exp => exp.type === "Per Ticket" && exp.cap === false && exp.show_id === this.state.show_select || exp.type === "Per Ticket" && exp.cap === 'false' && exp.show_id === this.state.show_select)
            .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        const perticket_exp_total_single = perticket_exp_single * gross_tickets_single;

        const perticket_exp_cap = this.state.expensearray
            .filter(exp => exp.type === "Per Ticket" && exp.cap === true || exp.type === "Per Ticket" && exp.cap === 'true')
            .reduce(function (cnt, o) { var val = o.amount * gross_pot; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const perticket_exp_cap_single = this.state.expensearray
            .filter(exp => exp.type === "Per Ticket" && exp.cap === true && exp.show_id === this.state.show_select || exp.type === "Per Ticket" && exp.cap === 'true' && exp.show_id === this.state.show_select)
            .reduce(function (cnt, o) { var val = o.amount * gross_pot_single; if (val > o.capped_amount) { val = o.capped_amount } return cnt + Number(val); }, 0);

        const exptotal = flat_exp_total + flat_exp_total_cap + ((percent_exp_total + percent_exp_total_cap) / this.state.show_info.length) + perticket_exp_total + perticket_exp_cap;
        const exptotal_single = flat_exp_total_single + flat_exp_total_cap_single + percent_exp_total_single + percent_exp_total_cap_single + perticket_exp_total_single + perticket_exp_cap_single;

        const bonustotal = this.state.bonusarray.reduce(function (cnt, o) { return cnt + Number(o.bonus); }, 0);

        return (
            <div className="mode-s">
                <CurrencyDialog open={this.state.currency_dialog} handleClose={this.toggleCurrencyDialog} handleCurrencyChange={this.handleCurrencyChange} val={this.state.currency} />
                <CommissionDialog open={this.state.commission_dialog} handleClose={this.toggleCommissionDialog} onChange={this.handleFormattedChange} commission_percent={this.state.commission_percent} />
                <div className="mode-s-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                        <span className="close" onClick={this.props.close}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        {this.state.edit ?
                            <span className="close" onClick={this.onSave}>
                                <FaSave size="20" color="#cdcdcd" />
                            </span> :
                            <span className="close" onClick={this.onEdit}>
                                <FaEdit size="18" color="#cdcdcd" />
                            </span>}
                        {/* {this.state.render_type !== 'enter' ?
                            <span className="close" onClick={this.onEdit}>
                                <FaPrint size="18" color="#cdcdcd" />
                            </span> : null}
                        {this.state.render_type !== 'enter' ?
                            <span className="close" onClick={this.onEdit}>
                                <FaDownload size="18" color="#cdcdcd" />
                            </span> : null}
                        {this.state.render_type !== 'enter' ?
                            <span className="close" onClick={this.onEdit}>
                                <FaEnvelope size="18" color="#cdcdcd" />
                            </span> : null} */}
                        {/* <div style={{ display: 'flex', height: 'auto', marginLeft: '10%' }}>
                            <UncontrolledDropdown>
                                <DropdownToggle style={{ color: '#9a9fa5', fontWeight: 'bold', paddingTop: 15, fontSize: 13 }} nav caret>OPTIONS</DropdownToggle>
                                <DropdownMenu style={{ border: '2px solid #333333', backgroundColor: '#000', fontSize: 13 }} right>
                                    <DropdownItem style={{ fontSize: 13, color: '#cdcdcd' }} onClick={this.toggleCurrencyDialog}>Currency</DropdownItem>
                                    <DropdownItem style={{ fontSize: 13, color: '#cdcdcd' }} onClick={this.toggleCommissionDialog}>Commission Percent</DropdownItem>
                                    <DropdownItem style={{ fontSize: 13, color: '#cdcdcd' }} >Create Tour Deal</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown>
                                <DropdownToggle style={{ color: '#9a9fa5', fontWeight: 'bold', paddingTop: 15, fontSize: 13 }} nav caret>PRINT</DropdownToggle>
                                <DropdownMenu style={{ border: '2px solid #333333', backgroundColor: '#000', fontSize: 13 }} right>
                                    <DropdownItem style={{ fontSize: 13, color: '#cdcdcd' }} >Deal Memoo</DropdownItem>
                                    <DropdownItem style={{ fontSize: 13, color: '#cdcdcd' }} >Contract</DropdownItem>
                                    <DropdownItem style={{ fontSize: 13, color: '#cdcdcd' }} >Itinerary</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div> */}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 600, width: '100%', backgroundColor: '#fff' }}>
                        <div style={{ width: '9%', backgroundColor: 'rgb(1, 22, 48)', display: 'flex' }} className="chat-area">
                            <div className="enterdealSideNav">
                                <OfferButtonGroup toggleDealSection={this.toggleDealSection} dealsection={this.state.dealSection} type={this.state.render_type} />
                            </div>
                        </div>
                        <div style={{ width: '92%', height: 600, overflowY: 'scroll' }} >
                            {this.state.loading ?
                                <div style={{ marginLeft: 100, marginTop: 30 }}>
                                    <h2 style={{ fontSize: 14, color: '#000' }}>Loading...</h2>
                                </div> :
                                this.state.dealSection === "Show Info" ?
                                    <ShowInfo onChange={this.handleChange} onDateChange={this.handleDateChange} toggleDateSelect={this.toggleDateSelect} handleArrayUpdate={this.handleArrayUpdate} handlePinnedArrayChange={this.handlePinnedArrayChange} handleArrayFormattedChange={this.handleArrayFormattedChange} handlePinnedArrayFormattedChange={this.handlePinnedArrayFormattedChange} handleArrayDateChange={this.handleArrayDateChange} handleShowtimeArrayChange={this.handleShowtimeArrayChange} handleClickOutside={this.handleClickOutside} togglePin={this.togglePin} combinePinnedArray={this.combinePinnedArray} onVenueChange={this.handleVenueChange} onSoftMerchValueChange={this.formattedSoftMerchChange} onHardMerchValueChange={this.formattedHardMerchChange}
                                        onClientChange={this.handleClientChange} onBuyerChange={this.handleBuyerChange} addShowtime={this.addShowtime} updateShowtime={this.updateShowtime} deleteShowtime={this.deleteShowtime} addDate={this.addDate} updateDate={this.updateDate} deleteDate={this.deleteDate}
                                        handleProdCustomChange={this.handleProdCustomChange} handleMktCustomChange={this.handleMktCustomChange} handleCtsCustomChange={this.handleCtsCustomChange} datesarray={this.state.datesarray} toggleNewModal={this.props.toggleNewModal} handleCheckboxChange={this.handleCheckboxChange}
                                        addContact={this.addContact} updateContact={this.updateContact} deleteContact={this.deleteContact} venue_id={this.state.venueid} buyer_id={this.state.buyercompanyid} {...this.state} /> :

                                    this.state.dealSection === "Ticket Scaling" ?
                                        <TicketScaling onChange={this.handleChange} onTP1ValueChange={this.formattedTP1Change} onFF1ValueChange={this.formattedFF1Change} onAF1aValueChange={this.formattedAF1aChange} onAF1bValueChange={this.formattedAF1bChange}
                                            addScaling={this.addScaling} updateScaling={this.updateScaling} handleArrayUpdate={this.handleArrayUpdate} handleArrayFormattedChange={this.handleArrayFormattedChange} handleClickOutside={this.handleClickOutside} onFFAAValueChange={this.formattedFFAAChange} onAFAAValueChange={this.formattedAFAAChange} onAFBAValueChange={this.formattedAFBAChange}
                                            combineFacFee={this.combineFacFee} combineAddFeeA={this.combineAddFeeA} combineAddFeeB={this.combineAddFeeB} deleteScaling={this.deleteScaling}
                                            captotalval={captotalval} compstotalval={compstotalval} killstotalval={killstotalval} grosstotalval={grosstotalval} gross_pot_val={gross_pot_val}
                                            single_captotalval={single_captotalval} single_compstotalval={single_compstotalval} single_killstotalval={single_killstotalval} single_gross_pot_val={single_gross_pot_val}
                                            toggleSeperateValues={this.toggleSeperateValues} toggleDateSelect={this.toggleDateSelect}
                                            combinePinnedScalingArray={this.combinePinnedScalingArray} handlePinnedArray2Change={this.handlePinnedArray2Change}
                                            droppedCards={this.droppedCards} onDOSChange={this.formattedDOSChange} toggleCurrencyDialog={this.toggleCurrencyDialog} {...this.state} /> :

                                        this.state.dealSection === "Expenses" ?
                                            <Expenses onChange={this.handleChange} onTax1ValueChange={this.formattedTax1Change} onTaxCap1ValueChange={this.formattedTaxCap1Change}
                                                onExp1ValueChange={this.formattedExp1Change} onExpCap1ValueChange={this.formattedExpCap1Change}
                                                handleTax1CheckboxChange={this.handleTax1CheckboxChange} handleExp1CheckboxChange={this.handleExp1CheckboxChange} handleTax3CheckboxChange={this.handleTax3CheckboxChange} handleTax4CheckboxChange={this.handleTax4CheckboxChange}
                                                addTax={this.addTax} addExpense={this.addExpense} updateExpense={this.updateExpense} handleArrayUpdate={this.handleArrayUpdate} handleArrayFormattedChange={this.handleArrayFormattedChange} updateTax={this.updateTax} deleteTax={this.deleteTax} deleteExpense={this.deleteExpense}
                                                handleClickOutside={this.handleClickOutside}
                                                taxtotal={taxtotal} taxtotal_single={taxtotal_single} exptotal={exptotal} exptotal_single={exptotal_single} gross_pot_val={gross_pot_val} gross_pot_single={gross_pot_single} gross_tickets={gross_tickets} gross_tickets_single={gross_tickets_single}
                                                toggleSeperateValues={this.toggleSeperateValues} toggleDateSelect={this.toggleDateSelect}
                                                combinePinnedScalingArray={this.combinePinnedScalingArray} handlePinnedArray2Change={this.handlePinnedArray2Change}
                                                droppedCards={this.droppedCards} onExpChange={this.handleExpChange} toggleCurrencyDialog={this.toggleCurrencyDialog} {...this.state} /> :

                                            this.state.dealSection === "Deal Terms" ?
                                                <DealTerms onChange={this.handleChange} handleArrayUpdate={this.handleArrayUpdate} handleArrayDateChange={this.handleArrayDateChange} handleArrayFormattedChange={this.handleArrayFormattedChange} handleClickOutside={this.handleClickOutside} onGuaranteeValueChange={this.formattedGuaranteeChange} onDealPercValueChange={this.formattedDealPercChange} onCustomWalkoutValueChange={this.formattedCustomWalkoutChange}
                                                    onBonusValueChange={this.formattedBonusChange}
                                                    onDeposit1ValueChange={this.formattedDeposit1} onDeposit2ValueChange={this.formattedDeposit2} onDeposit3ValueChange={this.formattedDeposit3} onDeposit4ValueChange={this.formattedDeposit4} handleDeposit1DateChange={this.handleDeposit1DateChange}
                                                    addBonus={this.addBonus} updateBonus={this.updateBonus} handleBonusUpdate={this.handleBonusUpdate} deleteBonus={this.deleteBonus}
                                                    addDeposit={this.addDeposit} updateDeposit={this.updateDeposit} handleDepositDateUpdate={this.handleDepositDateUpdate} deleteDeposit={this.deleteDeposit}
                                                    grosstotalval={grosstotalval} taxtotal={taxtotalval} exptotal={exptotal} gross_pot_val={gross_pot_val} bonustotal={bonustotal}
                                                    toggleBonus={this.toggleBonus} toggleCurrencyDialog={this.toggleCurrencyDialog}
                                                    droppedCards={this.droppedCards} {...this.state} /> :

                                                this.state.dealSection === "Additional Terms" ?
                                                    <AddNotes onChange={this.handleChange} {...this.state} /> :
                                                    this.state.dealSection === "Deal Tracking" ?
                                                        <OfferTracking onChange={this.handleChange} handleContractIssuedDate={this.handleContractIssuedDate} handleContractReceivedDate={this.handleContractReceivedDate} handleDepositReceivedDate={this.handleDepositReceivedDate}
                                                            addContractIssued={this.addContractIssued} updateContractIssued={this.updateContractIssued} deleteContractIssued={this.deleteContractIssued}
                                                            addContractReceived={this.addContractReceived} updateContractReceived={this.updateContractReceived} deleteContractReceived={this.deleteContractReceived}
                                                            addDepositReceived={this.addDepositReceived} updateDepositReceived={this.updateDepositReceived} deleteDepositReceived={this.deleteDepositReceived}
                                                            onFormattedChange={this.handleFormattedChange} resetTracking={this.resetTracking} formattedDepositReceived={this.formattedDepositReceived} {...this.state} /> :
                                                        this.state.dealSection === "Ticket Counts" ?
                                                            <TicketCounts handleChange={this.handleChange} toggleDateSelect={this.toggleDateSelect}
                                                                captotalval={captotalval} compstotalval={compstotalval} killstotalval={killstotalval}
                                                                single_captotalval={single_captotalval} single_compstotalval={single_compstotalval} single_killstotalval={single_killstotalval} single_gross_pot_val={single_gross_pot_val}
                                                                countdata={this.state.count_data} id={this.state.id} {...this.state} /> :
                                                            this.state.dealSection === "Marketing" ?
                                                                <Marketing shorturl={this.state.shorturl} toggleDateSelect={this.toggleDateSelect} {...this.state} /> :
                                                                this.state.dealSection === "Config" ?
                                                                    <OfferConfig handleAnnounceDateChange={this.handleAnnounceDateChange} handleAnnounceUrlChange={this.handleAnnounceUrlChange} onChange={this.handleChange} toggleDateSelect={this.toggleDateSelect} handleCheckbox={this.handleCheckbox} handleTcAutoCheckbox={this.handleTcAutoCheckbox} handleTcCheckbox={this.handleTcCheckbox} checkMWF={this.checkMWF} checkTT={this.checkTT} handleCountConChange={this.handleCountConChange} handleEmailBlur={this.handleEmailBlur} handleTcConChange={this.handleTcConChange} removeEmailGroupOffer={this.removeEmailGroupOffer} count_config={this.state.count_config} email_groups={this.props.auth.user.company.email.groups} {...this.state} /> :
                                                                    this.state.dealSection === "Final Totals" ?
                                                                        <FinalTotals captotalval={captotalval} compstotalval={compstotalval} killstotalval={killstotalval} onFormattedChange={this.handleFormattedChange} handleFinalsChange={this.handleFinalsChange} handleSettlementCheckbox={this.handleSettlementCheckbox} {...this.state} /> :
                                                                        this.state.dealSection === "Attachments" ?
                                                                            <Attachments {...this.state} /> :
                                                                            null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function sortAtoZ(data) {
    return data.sort((a, b) => { if (Number(a.nd === true)) { return (Number(a.time.substring(0, 2) + 24) + (Number(a.time.substring(3, 5)) / 100)) - (Number(b.time.substring(0, 2)) + (Number(b.time.substring(3, 5)) / 100)) } else { return (Number(a.time.substring(0, 2)) + (Number(a.time.substring(3, 5)) / 100)) - (Number(b.time.substring(0, 2)) + (Number(b.time.substring(3, 5)) / 100)) } });
}

function sortAtoZsub(data) {
    return data.sort((a, b) => { if (Number(a.time.substring(0, 2)) <= 4) { return (Number(a.time.substring(0, 2) + 24) + (Number(a.time.substring(3, 5)) / 100)) - (Number(b.time.substring(0, 2)) + (Number(b.time.substring(3, 5)) / 100)) } else { return (Number(a.time.substring(0, 2)) + (Number(a.time.substring(3, 5)) / 100)) - (Number(b.time.substring(0, 2)) + (Number(b.time.substring(3, 5)) / 100)) } });
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    auth: state.auth,
    offerdetail: state.offerdetail,
    marketing: state.marketing,
    counts: state.counts,
    festivals: state.festivals
});

export default connect(mapStateToProps, { addOffer, updateOfferFull, getOfferDetail, getAccRecId, getMarketing, verifyCounts, offerDupCheck })(OfferModal);