import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    CONTACTS_LOADING,
    CONTACTS_GET,
    CONTACTS_FILTER,
    CONTACTS_SORT,
    CONTACTS_SOCKET,
    CONTACTS_SOCKET_DELETE,
    NOTIFICATIONS
} from "./types";

export const setContactsLoading = () => {
    return {
        type: CONTACTS_LOADING
    };
};

export const getContacts = (data) => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API_CLIENT}/contacts/`, data, tokenConfig(getState))
        .then((res) => {
            console.log(res.data, 'CONTACTS')
            dispatch({
                type: CONTACTS_GET,
                payload: res.data
            })
            // res.data.map(item => {
            //     if(item.name.first === 'Robbie' && item.name.last === 'Baker'){
            //     }
            // })
            //await newUpdateTest(res.data, getState);
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const contactSocket = contact => (dispatch) => {
    dispatch({
        type: CONTACTS_SOCKET,
        payload: contact
    })
}

export const deleteContactSocket = contact => (dispatch) => {
    dispatch({
        type: CONTACTS_SOCKET_DELETE,
        payload: contact
    })
}