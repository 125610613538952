import React, { Component } from "react";
import PhoneInput from 'react-phone-number-input';

class RenderNewContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addcontact: false,
            lastname: '',
            firstname: '',
            company: '',
            position: '',
            email: '',
            officephone: '',
            cellphone: '',
            faxnumber: '',
            notes: '',
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    toggleReturn = () => {
        this.props.toggleReturn();
    };

    render() {
        return (
            <div style={{ display: 'flex', width: '100%', height: 270 }}>
                <div style={{ width: '47.5%' }}>
                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 30 }} className="form-group">
                        <label>Contact Name:</label>
                        <input style={{ marginLeft: 10, width: '30%', fontSize: 14, border: '.4px solid #000', height: 24 }} className="form-control-sm" type="text" name="contact1firstname" placeholder="First Name" value={this.state.contact1firstname || ''} onChange={this.handleChange.bind(this)} />
                        <input style={{ marginLeft: 10, width: '30%', fontSize: 14, border: '.4px solid #000', height: 24 }} className="form-control-sm" type="text" name="contact1lastname" placeholder="Last Name" value={this.state.contact1lastname || ''} onChange={this.handleChange.bind(this)} />
                    </div>
                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                        <label>Company:</label>
                        <select style={{ marginLeft: 10, width: '50%', fontSize: 14, border: '.4px solid #000', height: 24, padding: 0 }} className="form-control-sm" value={this.state.contact1position || ''} name="contact1position" onChange={this.handleChange.bind(this)}>
                            <option value=""> </option>
                        </select>
                    </div>
                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                        <label>Position:</label>
                        <select style={{ marginLeft: 10, width: '50%', fontSize: 14, border: '.4px solid #000', height: 24, padding: 0 }} className="form-control-sm" value={this.state.contact1position || ''} name="contact1position" onChange={this.handleChange.bind(this)}>
                            <option value=""> </option>
                            <option value="Artist">Artist</option>
                            <option value="Custom">Industry Contact</option>
                            <option value="Manager">Manager</option>
                            <option value="Co-Manager">Co-Manager</option>
                            <option value="Label Rep">Label Rep</option>
                            <option value="Custom">Other</option>
                            <option value="Publicist">Publicist</option>
                            <option value="Radio">Radio</option>
                        </select>
                    </div>
                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                        <label>Email:</label>
                        <input style={{ marginLeft: 10, width: '50%', fontSize: 14, border: '.4px solid #000', height: 24 }} className="form-control-sm" type="text" name="contact1email" value={this.state.contact1email || ''} onChange={this.handleChange.bind(this)} />
                    </div>
                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                        <label>Office Phone:</label>
                        <PhoneInput
                            style={{ marginLeft: 10, width: '70%', fontSize: 14 }}
                            className="form-control-sm"
                            defaultCountry="US"
                            value={this.state.venuephonenumber}
                            onChange={venuephonenumber => this.setState({ venuephonenumber })} />
                    </div>
                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                        <label>Cell Phone:</label>
                        <PhoneInput
                            style={{ marginLeft: 10, width: '70%', fontSize: 14 }}
                            className="form-control-sm"
                            defaultCountry="US"
                            value={this.state.venuephonenumber}
                            onChange={venuephonenumber => this.setState({ venuephonenumber })} />
                    </div>
                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                        <label>Fax Number:</label>
                        <PhoneInput
                            style={{ marginLeft: 10, width: '70%', fontSize: 14 }}
                            className="form-control-sm"
                            defaultCountry="US"
                            value={this.state.venuephonenumber}
                            onChange={venuephonenumber => this.setState({ venuephonenumber })} />
                    </div>
                </div>
                <div style={{ width: '47.5%' }}>
                    <div style={{ display: 'flex', height: 40, marginLeft: 60, marginTop: 30 }} className="form-group">
                        <label style={{ alignSelf: 'center' }}>Notes:</label>
                        <textarea style={{ minHeight: 150, maxHeight: 150, marginLeft: 10, minWidth: '85%', maxWidth: '85%', border: '.4px solid #000' }} className="form-control" type="textarea" name="contact1notes" value={this.state.contact1notes || ''} onChange={this.handleChange.bind(this)} />
                    </div>
                    <div style={{ marginTop: 250, marginRight: 20, display: 'flex', justifyContent: 'flex-end' }}>
                        <button style={{ width: 80, height: 26, backgroundColor: 'rgb(1, 22, 48)', color: '#fff', marginTop: 40, fontSize: 14 }} onClick={this.addNewContact}>Add</button>
                        <button style={{ width: 80, height: 26, backgroundColor: 'rgb(1, 22, 48)', color: '#fff', marginTop: 40, fontSize: 14 }} onClick={this.toggleReturn}>Go Back</button>
                    </div>
                </div>
            </div>
        )
    }

}


export default RenderNewContact;