import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from "axios";
import moment from 'moment';
import _ from 'lodash';
import { FaPrint, FaDownload, FaForward, FaBackward, FaBars, FaEye, FaGripLinesVertical } from 'react-icons/fa';
import { RenderDealMemo, RenderItinerary, RenderContract, clearPdfs, generatePdf, retreivePdf, downloadPdf, setPdfOffer, setPdfCounts } from '../actions/pdfActions';
import { countFetch } from '../actions/countActions';

class PdfModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            pdfindex: 0,
            loading: true,
            pdf_data: [],
            pdftype: '',
            deal_select: '',
            menu: false,
            pdf_title: ''
        };
    }

    static propTypes = {
        offerdetail: PropTypes.object.isRequired,
        offers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        pdf: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        festivals: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired, 
    }

    async componentDidMount() {
        var pdftype = this.props.pdftype;
        if (pdftype === 'dealmemo' || pdftype === 'contract' || pdftype === 'tour-settlement-statement' || pdftype === 'finals-statement' || pdftype === "deposit-report") {
            var newArray = await this.props.setPdfOffer(pdftype, this.props.offer_checked);
            if (pdftype === 'dealmemo' || pdftype === 'contract') {

                // const pdfData = {
                //     pdfData: newArray[0]
                // }
                // const gen = await this.props.generatePdf(pdfData, pdftype);

                // var index = 0;
                // index = await this.genPdf(pdfData, pdftype, index)
                newArray.map(async (item, index) => {
                    var pdfData = {
                        pdfData: item
                    }
                    var gen = await this.props.generatePdf(pdfData, pdftype);
                    if (gen && index === 0) {
                        this.setState({
                            pdf_title: gen.title,
                            pdf_data: newArray,
                            deal_select: newArray[0]._id,
                            pdftype: pdftype,
                            loading: false
                        })
                    }
                })

                // if (gen) {
                //     this.setState({
                //         pdf_title: gen.title,
                //         pdf_data: newArray
                //     })
                // }
            } else {
                const pdfData = {
                    pdfData: newArray
                }
                const gen = await this.props.generatePdf(pdfData, pdftype);
                if (gen) {
                    this.setState({
                        pdf_title: gen.title,
                        pdf_data: newArray,
                        pdftype: pdftype,
                        loading: false
                    })
                }
            }
        } else if (pdftype === 'counts') {
            var newArray = await this.props.setPdfCounts(pdftype, this.props.offer_checked);
            await this.props.generatePdf(newArray, pdftype);
            this.setState({
                loading: false,
                pdf_data: newArray.pdfData,
                pdftype: pdftype
            })
        } else if (pdftype === 'itinerary') {
            var newArray = await this.props.setPdfOffer(pdftype, this.props.offer_checked);
            const pdfData = {
                pdfData: newArray,
                addData: this.props.pdfadd_data
            }
            const gen = await this.props.generatePdf(pdfData, pdftype);
            if (gen) {
                this.setState({
                    pdf_title: gen.title,
                    pdf_data: newArray,
                    pdftype: pdftype,
                    loading: false
                })
            }
            console.log(this.props.pdfadd_data, 'ADD DATA')
        } else {
            this.setState({
                error: true,
                loading: false,
                pdftype: pdftype
            })
            alert('Error: no pdf to render')
        }
    }

    componentWillUnmount() {
        this.props.clearPdfs();
    }

    // genPdf = async (pdfData, pdftype, index) => {
    //     var newIndex = 0;
    //     if(this.state.gen_index === null){
    //         newIndex = 0
    //     } else {
    //         newIndex = index + 1;
    //     }
    //     const pdfData = {
    //         pdfData: newArray[newIndex]
    //     }
    //     const gen = await this.props.generatePdf(pdfData, pdftype);
    //     if (gen) {
    //         this.setState({
    //             gen_index: newIndex,
    //             pdf_title: gen.title,
    //             pdf_data: newArray
    //         })
    //     }
    // }

    nextPdf = async () => {
        var pdftype = this.props.pdftype;
        if (this.state.pdfindex + 1 < this.state.pdf_data.length) {
            var i = this.state.pdfindex + 1;
            var deals = this.state.pdf_data;
            this.setState({
                loading: true,
                deal_select: deals[i]._id
            })

            if (pdftype === 'dealmemo' || pdftype === 'contract') {
                const pdfData = {
                    pdfData: deals[i]
                }
                await this.props.generatePdf(pdfData, pdftype);
            } else {
                const pdfData = {
                    pdfData: deals[i]
                }
                await this.props.generatePdf(pdfData, pdftype);
            }
            this.setState({
                loading: false,
                pdfindex: i,
                deal_select: deals[i]._id
            })
        } else {

        }
    }

    backPdf = () => {
        var pdftype = this.props.pdftype;
        if (this.state.pdfindex - 1 < this.state.pdf_data.length) {
            this.setState({
                loading: true
            })
            var i = this.state.pdfindex - 1;
            var deals = this.state.pdf_data;

            if (pdftype === "dealmemo") {
                this.props.RenderDealMemo(deals, i);
            } else if (pdftype === "itinerary") {
                this.props.RenderItinerary(deals, i);
            }
            this.setState({
                loading: false,
                pdfindex: i
            })
        } else {

        }
    }

    toggleDeal = async (id) => {
        // var pdftype = this.props.pdftype;
        // this.setState({
        //     loading: true,
        //     deal_select: id
        // })
        // var deals = this.state.pdf_data;
        // var i = deals.findIndex(obj => obj._id === id);

        // if (pdftype === 'dealmemo' || pdftype === 'contract') {
        //     const pdfData = {
        //         pdfData: deals[i]
        //     }
        //     await this.props.generatePdf(pdfData, pdftype);
        // } else {
        //     const pdfData = {
        //         pdfData: deals[i]
        //     }
        //     await this.props.generatePdf(pdfData, pdftype);
        // }
        // this.setState({
        //     loading: false,
        //     pdfindex: i,
        //     deal_select: deals[i]._id
        // })

        this.setState({
            loading: true,
            deal_select: id
        })
        var deals = this.state.pdf_data;
        var i = deals.findIndex(obj => obj._id === id);
        this.setState({
            loading: false,
            pdfindex: i,
        })
    }

    dealSelect = async (event) => {
        // var pdftype = this.props.pdftype;
        // this.setState({
        //     loading: true,
        //     deal_select: event.target.value
        // })
        // var deals = this.state.pdf_data;
        // var i = deals.findIndex(obj => obj._id === event.target.value);

        // if (pdftype === 'dealmemo' || pdftype === 'contract') {
        //     const pdfData = {
        //         pdfData: deals[i]
        //     }
        //     await this.props.generatePdf(pdfData, pdftype);
        // } else {
        //     const pdfData = {
        //         pdfData: deals[i]
        //     }
        //     await this.props.generatePdf(pdfData, pdftype);
        // }
        // this.setState({
        //     loading: false,
        //     pdfindex: i,
        // })

        this.setState({
            loading: true,
            deal_select: event.target.value
        })
        var deals = this.state.pdf_data;
        var i = deals.findIndex(obj => obj._id === event.target.value);
        this.setState({
            loading: false,
            pdfindex: i,
        })

    }

    togglePrint = async () => {
        var src = this.props.pdf.pdf[this.state.pdfindex];

        // let pdfWindow = window.open("")
        // pdfWindow.document.write("<html<head><title>Deal Memo</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
        // pdfWindow.document.write("<body><embed width='100%' height='100%' src='" + src + "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");
        // function injectThis() {
        //     window.print();
        // }
        // pdfWindow.document.innerHTML = `window.onload = ${injectThis.toString()};`;

        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        }

        // var pdfFrame = window.frames["pdf"];
        // pdfFrame.focus();
        // pdfFrame.print();
        var n = src.slice(28);
        var type = 'application/pdf';
        var nb = await b64toBlob(n, type)
        const dataUrl = window.URL.createObjectURL(nb);
        const pdfWindow = window.open(dataUrl);
        pdfWindow.print();

        // const theWindow = window.open(src);
        // const theDoc = theWindow.document;
        // const theScript = document.createElement('script');
        // function injectThis() {
        //     window.print();
        // }
        // theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
        // theDoc.body.appendChild(theScript);
    }

    toggleMenu = () => {
        this.setState({
            menu: !this.state.menu
        })
    }

    downloadPdf = (index) => {
        let pdf = this.props.pdf.pdf[index];
        const downloadLink = document.createElement("a");
        const fileName = `${pdf.title}.pdf`;
        downloadLink.href = pdf.url;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    render() {
        var src = null;
        if (this.props.pdf.pdf[this.state.pdfindex]) {
            src = this.props.pdf.pdf[this.state.pdfindex].url;
        }
        //var length = this.props.offerdetail.offerdetail.length;
        //<img src={satellite} width={200} height={200} />
        //console.log(this.props.offerdetail.offerdetail)

        return (
            <div className="mode-s">
                <div className="mode-s-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                        <span className="close" onClick={this.props.close}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        <span className="close">
                            <FaPrint size="18" color="#cdcdcd" onClick={this.togglePrint} />
                        </span>
                        <span className="close">
                            <a href={src} target="_blank" rel="noopener noreferrer" download={this.props.pdf.pdf[this.state.pdfindex] ? `${this.props.pdf.pdf[this.state.pdfindex].title}.pdf` : `${this.state.pdftype}.pdf`}>
                                <FaDownload size="18" color="#cdcdcd" />
                            </a>
                        </span>
                        <div style={{ display: 'flex', flexDirection: 'row', width: 'auto', height: '100%' }}>
                            {this.state.pdftype === 'dealmemo' || this.state.pdftype === 'contract' ?
                                <span style={{ height: '100%', width: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaGripLinesVertical style={{ cursor: 'pointer' }} size="16" color="#cdcdcd" onClick={this.toggleMenu} />
                                </span> : null}
                            <select style={{ width: 'auto', height: '100%', backgroundColor: 'rgb(1, 22, 48)', fontSize: 16, marginLeft: '.5%', color: '#fff', border: '1px solid rgb(1, 22, 48)' }} disabled={this.state.pdftype === 'itinerary' ? true : false} name="deal_select" value={this.state.deal_select} onChange={this.dealSelect}>
                                {this.state.pdf_data.map((item, index) => {
                                    const datesArray = item.show_info.map(d => moment(d.date));
                                    var end = moment.max(datesArray).format('MM/DD/YYYY');
                                    var start = moment.min(datesArray).format('MM/DD/YYYY');
                                    return (
                                        <option key={index} value={item._id}>{item.artist_name + " - " + start + " - " + end}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {this.state.pdfindex !== 0 && this.state.pdftype !== 'itinerary' ?
                            <button style={{ marginLeft: '2%', backgroundColor: '#333', borderRadius: 5, width: 60, height: 28, marginTop: 10 }}>
                                <FaBackward size="18" color="#fff" onClick={this.backPdf} />
                            </button> : null}
                        {this.state.pdf_data.length > this.state.pdfindex + 1 && this.state.pdftype !== 'itinerary' ?
                            <button style={{ marginLeft: this.state.pdfindex === 0 ? '2%' : 10, backgroundColor: '#333', borderRadius: 5, width: 60, height: 28, marginTop: 10 }}>
                                <FaForward size="18" color="#fff" onClick={this.nextPdf} />
                            </button> : null}
                    </div>
                    <div style={{ width: '100%', height: 600, display: 'flex' }}>
                        {this.state.pdftype === 'dealmemo' && this.state.menu === true || this.state.pdftype === 'contract' && this.state.menu === true ?
                            <div style={{ width: '15%', height: '100%', backgroundColor: 'rgb(1, 22, 48)' }}>
                                {this.state.pdf_data.map((item, index) => {
                                    const datesArray = item.show_info.map(d => moment(d.date));
                                    var end = moment.max(datesArray).format('MM/DD/YYYY');
                                    var start = moment.min(datesArray).format('MM/DD/YYYY');
                                    return (
                                        // <div style={{ width: '95%', border: this.state.deal_select === item._id ? '2px solid #9A9A9A' : '2px solid #333', backgroundColor: 'rgb(1, 22, 48)', height: 'auto', borderTopRightRadius: 5, borderBottomRightRadius: 5, marginBottom: 6, cursor: 'pointer' }} onClick={() => this.toggleDeal(item._id)}>
                                        <div style={{ width: '95%', border: this.state.deal_select === item._id ? '2px solid #9A9A9A' : '2px solid #333', backgroundColor: 'rgb(1, 22, 48)', height: 'auto', borderTopRightRadius: 5, borderBottomRightRadius: 5, marginBottom: 6, cursor: 'pointer' }}>
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                <div style={{ width: '85%' }} onClick={() => this.toggleDeal(item._id)}>
                                                    <h2 style={{ fontSize: 12, color: '#fff', paddingLeft: 5, paddingTop: 5, fontWeight: 'bold' }}>{item.artist_name}</h2>
                                                    <h2 style={{ fontSize: 10, color: '#fff', paddingLeft: 5 }}>{start}</h2>
                                                    <h2 style={{ fontSize: 10, color: '#fff', paddingLeft: 5 }}>{item.venue.venue}</h2>
                                                </div>
                                                <div style={{ width: '15%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <FaDownload style={{ marginLeft: 5, marginBottom: 6 }} size="14" color={this.props.pdf.pdf.length < index ? "#333" : "#cdcdcd"} onClick={() => this.downloadPdf(index)} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div> : null}
                        <div style={{ width: this.state.pdftype === 'itinerary' || this.state.menu === false ? '100%' : '85%', height: '100%', backgroundColor: '#515659' }}>
                            {!this.props.pdf.loading && !this.state.loading && !this.state.error ?
                                <iframe
                                    id="pdf"
                                    name="pdf"
                                    title="file"
                                    style={{ width: '100%', height: '100%' }}
                                    key={this.state.pdfindex}
                                    src={src + "#toolbar=0"}
                                /> :
                                !this.props.pdf.loading && !this.state.loading && this.state.error ?
                                    <div style={{ width: '100%', height: '100%', backgroundColor: '#515659', border: '2px solid #9A9A9A' }}>
                                        <h1 style={{ fontSize: 16, padding: 10, color: '#fff', fontWeight: 'bold' }}>Error loading pdf..</h1>
                                    </div> :
                                    <div style={{ width: '100%', height: '100%', backgroundColor: '#515659', border: '2px solid #9A9A9A' }}>
                                        <h1 style={{ fontSize: 16, padding: 10, color: '#fff', fontWeight: 'bold' }}>Loading..</h1>
                                    </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const highest = arr => (arr || []).reduce((acc, el) => {
    acc.k[el] = acc.k[el] ? acc.k[el] + 1 : 1
    acc.max = acc.max ? acc.max < acc.k[el] ? el : acc.max : el
    return acc
}, { k: {} }).max

const removeItem = (arr, val) => {
    var array = [...arr]; // make a separate copy of the array
    var index = array.indexOf(val)
    if (index !== -1) {
        array.splice(index, 1);
        return array
    }
}

const mapStateToProps = state => ({
    offerdetail: state.offerdetail,
    offers: state.offers,
    clients: state.clients,
    pdf: state.pdf,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    festivals: state.festivals,
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { RenderDealMemo, RenderItinerary, RenderContract, clearPdfs, generatePdf, retreivePdf, downloadPdf, setPdfOffer, setPdfCounts, countFetch })(PdfModal);