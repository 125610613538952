import {
    BUYERS_INIT_LOADING,
    BUYERS_LOADING,
    BUYERS_GET,
    BUYERS_FILTER,
    BUYERS_SORT,
    BUYERS_SOCKET,
    BUYERS_SOCKET_DELETE,
} from "../actions/types";
import _ from 'lodash';

const initialState = {
    buyers: [],
    filtered_buyers: [],
    filter: {
        company: '',
        status: '',
        _id: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        buyer_name: '',
    },
    loading: false,
    init_loading: false,
    sort: {
        cat: 'company',
        dir: 'asc'
    }
};

export default function (state = initialState, action) {
    let filtered_data = [...state.filtered_buyers];
    switch (action.type) {
        case BUYERS_INIT_LOADING:
            return {
                ...state,
                init_loading: true
            };
        case BUYERS_LOADING:
            return {
                ...state,
                loading: true
            };
        case BUYERS_GET:
            var sortedBuyers = _.orderBy(action.payload, [`company`.toLowerCase()], ['asc']);
            return {
                ...state,
                buyers: sortedBuyers,
                filtered_buyers: sortedBuyers,
                init_loading: false,
                loading: false
            };
        case BUYERS_FILTER:
            var buyer_filter = action.payload;
            var buyer_data = state.buyers
            if (buyer_filter.company) {
                buyer_data = buyer_data.filter((item) => {
                    var filterBatch = item.company.toLowerCase()
                    return filterBatch.indexOf(buyer_filter.company.toLowerCase()) >= 0
                })
            }
            if (buyer_filter.status) {
                buyer_data = buyer_data.filter((item) => {
                    var filterBatch = item.status.toLowerCase()
                    if (item.status) {
                        return filterBatch.indexOf(buyer_filter.status.toLowerCase()) >= 0
                    }
                })
            }
            if (buyer_filter._id) {
                buyer_data = buyer_data.filter((item) => {
                    var filterBatch = item._id
                    return filterBatch.indexOf(buyer_filter._id) >= 0
                })
            }
            if (buyer_filter.address) {
                buyer_data = buyer_data.filter((item) => {
                    var filterBatch = item.address.address.toLowerCase()
                    return filterBatch.indexOf(buyer_filter.address.toLowerCase()) >= 0
                })
            }
            if (buyer_filter.city) {
                buyer_data = buyer_data.filter((item) => {
                    var filterBatch = item.address.city.toLowerCase()
                    return filterBatch.indexOf(buyer_filter.city.toLowerCase()) >= 0
                })
            }
            if (buyer_filter.state) {
                buyer_data = buyer_data.filter((item) => {
                    var filterBatch = item.address.state.toLowerCase()
                    return filterBatch.indexOf(buyer_filter.state.toLowerCase()) >= 0
                })
            }
            if (buyer_filter.country) {
                buyer_data = buyer_data.filter((item) => {
                    var filterBatch = item.address.country.toLowerCase()
                    return filterBatch.indexOf(buyer_filter.country.toLowerCase()) >= 0
                })
            }
            if (buyer_filter.postal_code) {
                buyer_data = buyer_data.filter((item) => {
                    var filterBatch = item.address.postal_code.toLowerCase()
                    return filterBatch.indexOf(buyer_filter.postal_code.toLowerCase()) >= 0
                })
            }
            var sort = state.sort;
            buyer_data = _.orderBy(buyer_data, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            return {
                ...state,
                filtered_buyers: buyer_data,
                filter: buyer_filter,
                loading: false
            };
        case BUYERS_SORT:
            var sort = action.payload;
            var sortedBuyers = _.orderBy(state.filtered_buyers, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            return {
                ...state,
                filtered_buyers: sortedBuyers,
                sort: sort,
                loading: false
            };
        // case SADD_BUYER:
        //     var buyer_filter = state.filter;
        //     var newArray = [...state.buyers, action.payload];
        //     var buyer_data = newArray.filter((item) => {
        //         var filterBatch = item.company
        //         return filterBatch.indexOf(buyer_filter.company) >= 0
        //     })
        //     buyer_data = buyer_data.filter((item) => {
        //         var filterBatch = item.address.city
        //         return filterBatch.indexOf(buyer_filter.city) >= 0
        //     })
        //     buyer_data = buyer_data.filter((item) => {
        //         var filterBatch = item.address.state
        //         return filterBatch.indexOf(buyer_filter.state) >= 0
        //     })
        //     buyer_data = buyer_data.filter((item) => {
        //         var filterBatch = item.address.country
        //         return filterBatch.indexOf(buyer_filter.country) >= 0
        //     })
        //     buyer_data = _.orderBy(buyer_data, [`${state.sort.cat}`.toLowerCase()], [state.sort.dir]);
        //     return {
        //         ...state,
        //         buyers: [action.payload, ...state.buyers],
        //         filtered_buyers: buyer_data,
        //     };
        // case UPDATE_BUYER_SOCKET:
        //     var data = state.buyers;
        //     var f_data = state.filtered_buyers;
        //     var index = state.buyers.findIndex(obj => obj._id === action.payload._id);
        //     var f_index = state.filtered_buyers.findIndex(obj => obj._id === action.payload._id);
        //     if (index >= 0) {
        //         data[index] = action.payload;
        //         if (f_index >= 0) {
        //             f_data[f_index] = action.payload;
        //         }
        //     } else {
        //         data = [action.payload, ...state.buyers];
        //         var buyer_filter = state.filter;
        //         f_data = data;
        //         if (buyer_filter.company) {
        //             f_data = data.filter((item) => {
        //                 var filterBatch = item.company
        //                 return filterBatch.indexOf(buyer_filter.company) >= 0
        //             })
        //         }
        //         if (buyer_filter.city) {
        //             f_data = f_data.filter((item) => {
        //                 var filterBatch = item.address.city
        //                 return filterBatch.indexOf(buyer_filter.city) >= 0
        //             })
        //         }
        //         if (buyer_filter.state) {
        //             f_data = f_data.filter((item) => {
        //                 var filterBatch = item.address.state
        //                 return filterBatch.indexOf(buyer_filter.state) >= 0
        //             })
        //         }
        //         if (buyer_filter.country) {
        //             f_data = f_data.filter((item) => {
        //                 var filterBatch = item.address.country
        //                 return filterBatch.indexOf(buyer_filter.country) >= 0
        //             })
        //         }
        //         f_data = _.orderBy(f_data, [`${state.sort.cat}`.toLowerCase()], [state.sort.dir]);
        //     }
        //     // let data = [...state.buyers]
        //     // var index = data.findIndex(obj => obj._id === action.payload._id)
        //     // data[index] = action.payload

        //     // const found = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //     // if (found >= 0) {
        //     //     var filtered_index = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //     //     filtered_data[filtered_index] = action.payload;
        //     // }
        //     return {
        //         ...state,
        //         buyers: data,
        //         filtered_buyers: f_data
        //     }
        case BUYERS_SOCKET:
            var data = state.buyers;
            var f_data = state.filtered_buyers;
            var index = state.buyers.findIndex(obj => obj._id === action.payload._id);
            var f_index = state.filtered_buyers.findIndex(obj => obj._id === action.payload._id);
            if (index >= 0) {
                data[index] = action.payload;
                if (f_index >= 0) {
                    f_data[f_index] = action.payload;
                }
            } else {
                data = [action.payload, ...state.buyers];
                var buyer_filter = state.filter;
                f_data = data;
                if (buyer_filter.company) {
                    f_data = data.filter((item) => {
                        var filterBatch = item.company
                        return filterBatch.indexOf(buyer_filter.company) >= 0
                    })
                }
                if (buyer_filter.city) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.city
                        return filterBatch.indexOf(buyer_filter.city) >= 0
                    })
                }
                if (buyer_filter.state) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.state
                        return filterBatch.indexOf(buyer_filter.state) >= 0
                    })
                }
                if (buyer_filter.country) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.address.country
                        return filterBatch.indexOf(buyer_filter.country) >= 0
                    })
                }
                f_data = _.orderBy(f_data, [`${state.sort.cat}`.toLowerCase()], [state.sort.dir]);
            }
            // let data = [...state.buyers]
            // var index = data.findIndex(obj => obj._id === action.payload._id)
            // data[index] = action.payload

            // const found = filtered_data.findIndex(obj => obj._id === action.payload._id);
            // if (found >= 0) {
            //     var filtered_index = filtered_data.findIndex(obj => obj._id === action.payload._id);
            //     filtered_data[filtered_index] = action.payload;
            // }
            return {
                ...state,
                buyers: data,
                filtered_buyers: f_data
            }
        case BUYERS_SOCKET_DELETE:
            return {
                ...state,
                buyers: state.buyers.filter(buyer => buyer._id !== action.payload._id),
                filtered_buyers: state.filtered_buyers.filter(buyer => buyer._id !== action.payload._id),
            };
        default:
            return state;
    }
}