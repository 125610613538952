import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EmailAutocomplete from '../autocompletes/email-recipient-autocomplete';
import AttachmentsAutocomplete from '../autocompletes/email-attachment-autocomplete';
import { FaEnvelopeOpenText, FaEnvelope, FaPlus } from 'react-icons/fa';

export default function EmailDialog(props) {
    const [ccSelect, setCcSelect] = useState([]);
    const [singleRecSelect, setSingleRecSelect] = useState([]);
    const [attachSelect, setAttachSelect] = useState([]);
    const [stateMenu, setStateMenu] = useState("recipients");
    const [stateRecMenu, setStateRecMenu] = useState("Show Contacts");
    const [stateMessage, setStateMessage] = useState("Attached is the contract, rider, and W9 for the referenced show.  Please sign and return them back to me at your earliest convenience and let me know if you have any questions.");
    const [stateSubject, setStateSubject] = useState("");
    const [statePdfType, setStatePdfType] = useState("contract");
    const [stateRecipients, setStateRecipients] = useState([]);
    const [stateCcRecipients, setStateCcRecipients] = useState([]);
    const [stateAttachments, setStateAttachments] = useState([]);
    const [stateSendReceipt, setStateSendReceipt] = useState(false);
    // useEffect(() => {
    //     setComissionSelect(props.val);
    // }, [props.val])

    useEffect(() => {
        if (props.data[0]) {
            setStateSubject(props.data[0].subject);
        }
    }, [props])

    useEffect(() => {
        setStateRecipients([]);
        setStateCcRecipients([]);
        setCcSelect([]);
    }, [props])

    const handleCheckbox = (n, item) => {
        console.log(n, item)
        //var n = e.target.name;
        //var item = e.target.value;
        if (n === 'cc') {
            if (ccSelect.includes(item)) {
                const items = ccSelect.filter(i => i !== item);
                setCcSelect(items);
            } else {
                const items = []
                items.push(...ccSelect, item)
                setCcSelect(items);
            };
        } else if (n === 'singleRec') {
            if (singleRecSelect.includes(item)) {
                const items = singleRecSelect.filter(i => i !== item);
                setSingleRecSelect(items);
            } else {
                const items = []
                items.push(...singleRecSelect, item)
                setSingleRecSelect(items);
            };
        } else if (n === 'attach') {
            if (attachSelect.includes(item)) {
                const items = attachSelect.filter(i => i !== item);
                setAttachSelect(items);
            } else {
                const items = []
                items.push(...attachSelect, item)
                setAttachSelect(items);
            };
        }
    }

    const toggleSub = (sub) => {
        console.log(sub)
        setStateMenu(sub)
    };

    const handleChange = (e) => {
        var name = e.target.name;
        var val = e.target.value;
        if (name === 'stateMessage') {
            setStateMessage(val)
        } else if (name === 'stateSubject') {
            setStateSubject(val)
        } else if (name === 'stateRecMenu') {
            setStateRecMenu(val)
        } else if (name === 'statePdfType') {
            setStatePdfType(val)
        }
    };

    const cancelDialog = () => {
        props.handleClose();
    };

    const sendDialog = () => {
        var id = ""
        if (props.data[0]) {
            id = props.data[0]._id
            const email = {
                subject: stateSubject,
                body: stateMessage,
                // cc: ccSelect,
                cc: stateCcRecipients,
                single: stateRecipients,
                attach: stateAttachments,
                user_email: props.user_email
            }
            props.issuePdf(statePdfType, id, email)
            // props.handleClose();
        }
        //TEND -- SEND ERROR
    };

    const handleRecChange = (rec) => {
        if (rec) {
            var newRec = stateRecipients.filter(item => item._id === rec._id);
            var newArr = [...rec, ...newRec]
            setStateRecipients(newArr)
        } else {
            setStateRecipients([])
        }
    }

    const handleCcChange = (rec) => {
        if (rec) {
            var newRec = stateCcRecipients.filter(item => item._id === rec._id);
            var newArr = [...rec, ...newRec]
            setStateCcRecipients(newArr)
        } else {
            setStateCcRecipients([])
        }
    }

    const handleEmailBlur = (rec) => {
        if (rec) {
            var newArr = [...stateRecipients, rec];
            setStateRecipients(newArr);
        }
    }

    const handleEmailBlurCc = (rec) => {
        if (rec) {
            var newArr = [...stateCcRecipients, rec];
            setStateCcRecipients(newArr);
        }
    }

    const handleAttChange = (rec) => {
        if (rec) {
            // var newRec = stateAttachments.filter(item => item._id === rec._id);
            // var newArr = [...rec, ...newRec] 
            setStateAttachments(rec)
        } else {
            setStateAttachments([])
        }
    }

    const handleSendReceiptCheck = (rec) => {
        setStateSendReceipt(!stateSendReceipt)
    }

    return (
        <Dialog
            style={{ zIndex: 10000 }}
            open={props.open}
            onClose={props.handleClose}
            disableEnforceFocus={true}
            transitionDuration={0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd' }} id="alert-dialog-title">Send Email</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{ overflow: "hidden", height: "100%", width: 500 }}>
                    {/* <input style={{ width: '100%', height: 30 }} placeholder="To" /> */}
                    <div style={{ width: 500, minHeight: 30, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '15%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>To:</h2>
                        </span>
                        <div style={{ width: '85%', height: 'auto' }}>
                            <EmailAutocomplete onAgentChange={handleRecChange} agentSelect={stateRecipients} edit={true} agentList={props.data.length > 0 ? props.data[0].contact_data : []} handleEmailBlur={handleEmailBlur} />
                        </div>
                    </div>
                    <div style={{ width: '100%', minHeight: 30, display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                        <span style={{ width: '15%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>CC:</h2>
                        </span>
                        <div style={{ width: '85%', height: 'auto' }}>
                            <EmailAutocomplete onAgentChange={handleCcChange} agentSelect={stateCcRecipients} edit={true} agentList={props.data.length > 0 ? props.data[0].contact_data : []} handleEmailBlur={handleEmailBlurCc} />
                        </div>
                    </div>
                    <div style={{ width: '100%', height: 30, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '15%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Subject:</h2>
                        </span>
                        <input style={{ width: '85%', height: 30, fontSize: 12 }} type="text" name="stateSubject" value={stateSubject} onChange={handleChange} />
                    </div>
                    <div style={{ width: '100%', height: 30, marginTop: 5, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '15%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Pdf Attachment:</h2>
                        </span>
                        <select style={{ width: '85%', height: 30, fontSize: 12, color: statePdfType === '' ? 'rgba(46, 46, 46, 0.5)' : '#000' }} name="statePdfType" value={statePdfType} onChange={handleChange}>
                            <option value=""> </option>
                            <option value="contract">Contract</option>
                            <option value="deal memo">Deal Memo</option>
                        </select>
                    </div>
                    <div style={{ width: 500, height: 30, marginTop: 5, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '15%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Add Attachment:</h2>
                        </span>
                        <div style={{ width: '85%' }}>
                            <AttachmentsAutocomplete onAgentChange={handleAttChange} agentSelect={stateAttachments} edit={true} agentList={props.data.length > 0 ? props.data[0].client.attachments : []} />
                        </div>
                    </div>
                    {/* <div style={{ width: 500, height: 30, marginTop: 5, display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '15%', height: 30, display: 'flex', alignItems: 'flex-end' }}>
                            <h2 style={{ fontSize: 10 }}>Send Receipt:</h2>
                        </span>
                        <div style={{ width: '85%' }}>
                            <Checkbox 
                                icon={<CheckBoxOutlineBlankIcon style={{ color: '#000' }} fontSize="small" />}
                                checkedIcon={<CheckBoxIcon style={{ color: '#000' }} fontSize="small" />}
                                color="default"
                                checked={stateSendReceipt}
                                onChange={handleSendReceiptCheck}
                            />
                        </div>
                    </div> */}
                    <div style={{ width: 500, height: 140, marginTop: 5, display: 'flex', flexDirection: 'row' }}>
                        <textarea style={{ width: '100%', height: 120, marginTop: 5, fontSize: 12 }} placeholder="Message" name="stateMessage" value={stateMessage} onChange={handleChange} />
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(1, 22, 48)' }}>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={cancelDialog}>Close</Button>
                <Button style={{ backgroundColor: '#cdcdcd', color: 'rgb(1, 22, 48)' }} onClick={sendDialog}>Send</Button>
            </DialogActions>
        </Dialog>
    );
}