import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import MicIcon from '@material-ui/icons/Mic';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FileIcon from '@material-ui/icons/FileCopy';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import AdminIcon from '@material-ui/icons/SupervisorAccount';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Checkbox from '@material-ui/core/Checkbox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import StarBorder from '@material-ui/icons/StarBorder';
import Collapse from '@material-ui/core/Collapse';
import LogoHorizontal from '../../assets/SolarSystemlogo.png';
import { FaDatabase } from 'react-icons/fa';
import { List } from 'react-virtualized';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px #111',
            webkitBoxShadow: 'inset 0 0 6px #111'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#111',
            outline: '1px solid slategrey'
        }
    },
    listItemText: {
        fontSize: 10,//Insert your required size
    },
    divider: {
        // Theme Color, or use css color in quote
        background: '#111',
    },
    menu_action: {
        position: 'fixed',
        bottom: 3,
        width: '100%',
        height: 'auto',
        marginLeft: 30,
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: 'auto',
        marginLeft: 30,
    }
}));

export default function DashMenu(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [openAgents, setOpenAgents] = React.useState(false);
    const [openClients, setOpenClients] = React.useState(false);
    const [stateHover, setStateHover] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleClickAgents = () => {
        setOpenAgents(!openAgents);
    };

    const handleClickClients = () => {
        setOpenClients(!openClients);
    };

    const mouseEnter = (val) => {
        setStateHover(val)
    }
    const mouseLeave = () => {
        setStateHover(null)
    }

    const clientRenderer = ({ key, index, isScrolling, isVisible, style }) => {
        return (
            <ListItem key={key} style={style} button>
                <Checkbox
                    style={{ color: '#fff' }}
                    classes={{ root: 'custom-checkbox-root' }}
                    checked={props.client_checked.includes(props.clients[index]._id) ? true : false}
                    onChange={() => props.handleDatabaseCheckbox('client_checked', props.clients[index]._id)}
                    disableRipple
                />
                <ListItemText style={{ color: '#fff' }} className={{ primary: classes.listItemText }} primary={<Typography type="body2" style={{ color: '#FFFFFF', fontSize: 12 }}>{props.clients[index].client}</Typography>} />
            </ListItem>
        )
    }

    const agentRenderer = ({ key, index, isScrolling, isVisible, style }) => {
        return (
            <ListItem key={key} style={style} button>
                <Checkbox
                    style={{ color: '#fff' }}
                    classes={{ root: 'custom-checkbox-root' }}
                    checked={props.agentSelect.includes(props.agents[index].tag) ? true : false}
                    onChange={() => props.handleAgentSelect(props.agents[index].tag)}
                    disableRipple
                />
                {/* <ListItemIcon>
                                        <StarBorder  />
                                    </ListItemIcon> */}
                <ListItemText style={{ color: '#fff' }} className={{ primary: classes.listItemText }} primary={<Typography type="body2" style={{ color: '#FFFFFF', fontSize: 12 }}>{props.agents[index].name.first + " " + props.agents[index].name.last}</Typography>} />
            </ListItem>
        )
    }

    const handleClick = () => {
        props.subSelect('database')
        setOpen(!open);
    };

    return (
        <div style={{ height: '89.5vh', backgroundColor: '#111', width: '100%' }}>
            {/* <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}> */}
            <div style={{ height: '85%', overflowY: 'scroll', width: '100%' }}>

                <MList component="nav" dense>
                    <ListItem button onClick={handleClick} onMouseEnter={() => mouseEnter('database')} onMouseLeave={mouseLeave}>
                        <ListItemIcon>
                            <FaDatabase style={{ marginLeft: 5 }} color={props.sub === 'database' ? '#2ea1ff' : stateHover === 'database' ? 'rgb(196, 196, 196)' : '#fff'} />
                        </ListItemIcon>
                        <ListItemText style={{ color: '#fff' }} primary="Database" />
                    </ListItem>
                    {/* <ListItem button onClick={() => props.subSelect('artists')} onMouseEnter={() => mouseEnter('artists')} onMouseLeave={mouseLeave}>
                        <ListItemIcon>
                            <AdminIcon style={{ color: props.sub === 'artists' ? '#2ea1ff' : stateHover === 'artists' ? 'rgb(196, 196, 196)' : '#fff' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: '#fff' }} primary="Artists" />
                    </ListItem> */}
                    <ListItem button onClick={() => props.subSelect('calendar')} onMouseEnter={() => mouseEnter('calendar')} onMouseLeave={mouseLeave}>
                        <ListItemIcon>
                            <CalendarIcon style={{ color: props.sub === 'calendar' ? '#2ea1ff' : stateHover === 'calendar' ? 'rgb(196, 196, 196)' : '#fff' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: '#fff' }} primary="Calendar" />
                    </ListItem>
                    <ListItem button onClick={() => props.subSelect('settings')} onMouseEnter={() => mouseEnter('settings')} onMouseLeave={mouseLeave}>
                        <ListItemIcon>
                            <SettingsIcon style={{ color: props.sub === 'settings' ? '#2ea1ff' : stateHover === 'settings' ? 'rgb(196, 196, 196)' : '#fff' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: '#fff' }} primary="Profile & Settings" />
                    </ListItem>
                </MList>
            </div>
            {/* </Box> */}
            <div style={{ height: '15%', width: '100%' }}>
                <MList component="nav" className={classes.footer}>
                    <img width="180" height="68" src={LogoHorizontal} />
                </MList>
            </div>
        </div>
    );
}