import axios from 'axios';
import { tokenConfig } from './authActions';

export const accountLookup = (email) => async (dispatch, getState) => {
    const data = {
        email: email
    }
    return axios.post(`${process.env.REACT_APP_API}/users/account/exists`, data, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

export const grantClientAccess = (data) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/users/client/access/grant`, data, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

export const grantClientAccessNewAccount = (data) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/users/client/access/grant/new`, data, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

export const saveClientPermissions = (data, tag) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/users/client/permissions/update/${tag}`, data, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //    type: USER_UPDATED,
            //    payload: data
            // })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}
 
export const removeClientPermissions = (data, tag) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/users/client/permissions/remove/${tag}`, data, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

export const resetNewAccountToken = (data) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/users/client/access/grant/new/reset-token`, data, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
}

export const checkAccountStatus = (data) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/users/account/status`, data, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            alert(err.response.data)
            return
        });
}