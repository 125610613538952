import axios from "axios";
import { tokenConfig, tokenConfigFile } from '../authActions';
import { returnErrors } from '../errorActions';
import {
    OFFERS_INIT_LOADING,
    OFFERS_LOADING,
    OFFERDETAIL_LOADING,
    GET_OFFERS,
    OFFER_DATABASE_SET,
    OFFER_CALENDAR_SET,
    DASHBOARD_SET,
    GET_OFFERDETAIL,
    FILTER_OFFERS,
    SORT_OFFERS,
    DELETE_OFFER_SOCKET
} from "../types";

export const setOffersLoading = () => {
    return {
        type: OFFERS_LOADING
    };
};

export const updateStatus = (type, status, id) => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API}/${type}/update/status/${id}`, status, tokenConfig(getState))
        .then(res =>
            console.log(res)
        )
        .catch(err =>
            console.log(err)
        );
}

// export const updateStatusSocket = offer => (dispatch, getState) => {

//     const { items } = getState();
//     const { buyers } = getState();
//     const { clients } = getState();
//     const { festivals } = getState();
//     const venues = items;

//     var artist_name = "";
//     var venue_name = "";
//     var venue_address = {};
//     var venue_city = "";
//     var venue_state = "";
//     var venue_country = "";
//     var buyer_company = "";

//     clients.clients.map((c, i) => {
//         if (offer.client_id === c._id) {
//             artist_name = c.client
//         }
//     })
//     venues.items.map((v, i) => {
//         if (offer.venue_id === v._id) {
//             venue_name = v.venue_name;
//             venue_address = v.address;
//             venue_city = v.address.city;
//             venue_state = v.address.state;
//             venue_country = v.address.country;
//         }
//     })
//     festivals.items.map((f, i) => {
//         if (offer.venue_id === f._id) {
//             venue_name = f.festival;
//             venue_address = f.address;
//             venue_city = f.address.city;
//             venue_state = f.address.state;
//             venue_country = f.address.country;
//         }
//     })
//     buyers.buyers.map((b, i) => {
//         if (offer.buyer_id === b._id) {
//             buyer_company = b.company
//         }
//     })

//     const newOffer = {
//         ...offer,
//         artist_name: artist_name,
//         venue_name: venue_name,
//         venue_address: venue_address,
//         venue_city: venue_city,
//         venue_state: venue_state,
//         venue_country: venue_country,
//         buyer_company: buyer_company,
//     }

//     dispatch({
//         type: SUO_OFFER,
//         payload: newOffer
//     })
// }