import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import CountrySelect from '../countrySelect';

export default function VenueDbFilter(props) {
    const [stateFilter, setFilter] = useState(props.venue_filter);
    const [minCap, setMinCap] = useState(props.venue_filter.min_capacity);
    const [maxCap, setMaxCap] = useState(props.venue_filter.max_capacity);

    useEffect(() => {
        setFilter(props.venue_filter);
    }, [props.venue_filter]) 

    const filterChange = (event) => {
        var name = event.target.name;
        var val = event.target.value;
        let filter = Object.assign({}, stateFilter);
        filter[name] = val;
        setFilter(filter)
    }

    const filterCapChange = (values, nme) => {
        console.log(values, nme)
        const { formattedcap, value } = values;
        const name = nme;
        let filter = Object.assign({}, stateFilter);
        filter[name] = value
        console.log(filter)
        setFilter(filter)
    }

    const filterMinChange = (values, nme) => {
        const { formattedcap, value } = values;
        setMinCap(value)
        const e = {
            target: {
                value: value,
                name: 'min_capacity'
            }
        }
        props.filterChange(e, 'festival_filter')
    }

    const filterMaxChange = (values, nme) => {
        const { formattedcap, value } = values;
        setMaxCap(value)
        const e = {
            target: {
                value: value,
                name: 'max_capacity'
            }
        }
        props.filterChange(e, 'festival_filter')
    }

    const applyFilter = () => {
        props.applyVenueFilter(stateFilter)
    }

    const clearFilter = () => {
        var filter = {
            venue_name: '',
            status: 'Active',
            _id: '',
            city: '',
            state: '',
            country: '',
            min_capacity: '',
            max_capacity: '',
        };
        setFilter(filter);
    }

    return (
        <div style={{ paddingBottom: 26, backgroundColor: '#111', border: '2px solid #111' }} className="navbar-nav">
            <div style={{ display: 'flex', marginTop: 70, marginLeft: 50 }}>
                <div style={{ width: 100, marginTop: -30 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Id:</p>
                </div>
                <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Venue Name:</p>
                </div>
                <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>City:</p>
                </div>
                <div style={{ width: 150, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Minimum Capacity:</p>
                </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 50 }} className="form-group">
                <div style={{ width: 100, marginTop: -10 }}>
                    <input
                        className="form-control-sm"
                        style={{ width: 100, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                        onChange={(e) => props.filterChange(e, 'venue_filter')}
                        value={stateFilter._id}
                        name="_id"
                        type="text"
                    />
                </div>
                <div style={{ width: 200, marginTop: -10, marginLeft: 40 }}>
                    <input
                        style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                        className="form-control-sm"
                        onChange={(e) => props.filterChange(e, 'venue_filter')}
                        value={stateFilter.venue_name}
                        name="venue_name"
                        type="text"
                    />
                </div>
                <div style={{ width: 200, marginLeft: 40, marginTop: -10 }}>
                    <input
                        style={{ width: 200, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                        className="form-control-sm"
                        value={stateFilter.city}
                        name="city"
                        type="text"
                        onChange={(e) => props.filterChange(e, 'venue_filter')}
                    />
                </div>
                <div style={{ width: 150, marginTop: -10, marginLeft: 40 }}>
                    {/* <input
                        style={{ width: 150, fontSize: 12, height: 28, borderRadius: 5, marginTop: -2 }}
                        className="form-control-sm"
                        value={stateFilter.min_capacity}
                        name="min_capacity"
                        type="number"
                        onChange={(e) => filterChange(e)}
                    /> */}
                    <NumberFormat style={{ width: 150, border: '1px solid #000', fontSize: 12, borderRdius: 5 }} className="form-control-sm" value={minCap} thousandSeparator={true} onValueChange={filterMinChange} />
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 5, marginLeft: 50 }}>
                <div style={{ width: 100 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Status:</p>
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>State</p>
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Country:</p>
                </div>
                <div style={{ width: 150, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Maximum Capacity:</p>
                </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 50, marginTop: -10 }} className="form-group">
                <div style={{ width: 100, height: 28 }}>
                    {/* <StatusAutocomplete onStatusChange={handleStatusChange} statusSelect={stateFilter.status} edit={props.edit} /> */}
                    <select style={{ width: 98, height: 26, fontSize: 12, padding: 0 }} className="form-control" value={stateFilter.status} name="status" onChange={(e) => props.filterChange(e, 'venue_filter')}>
                        <option value=""> </option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                </div>
                <div style={{ width: 200, height: 28, marginLeft: 40 }}>
                    <select style={{ width: 212, height: 26, fontSize: 12 }} className="form-control-sm" value={stateFilter.state} name="state" onChange={(e) => props.filterChange(e, 'venue_filter')}>
                        <option value=""> </option>
                        <option value="Alabama">Alabama</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Arizona">Arizona</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="Delaware">Delaware</option>
                        <option value="District Of Columbia">District Of Columbia</option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Maine">Maine</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Montana">Montana</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="New York">New York</option>
                        <option value="North Carolina">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Utah">Utah</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Washington">Washington</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="Wyoming">Wyoming</option>
                    </select>
                </div>
                <div style={{ width: 200, marginLeft: 40, height: 28 }}>
                    <CountrySelect country={stateFilter.country} filterChange={(e) => props.filterChange(e, 'venue_filter')} />
                </div>
                <div style={{ width: 150, marginLeft: 40, height: 28 }}>
                    {/* <input
                        className="form-control-sm"
                        style={{ width: 150, fontSize: 12, height: 28, borderRadius: 5 }}
                        value={stateFilter.max_capacity}
                        name="max_capacity"
                        type="number"
                        onChange={(e) => filterChange(e)}
                    /> */}
                    <NumberFormat style={{ width: 150, border: '1px solid #000', fontSize: 12, borderRdius: 5 }} className="form-control-sm" value={maxCap} thousandSeparator={true} onValueChange={filterMaxChange} />
                </div>
            </div>
        </div>
    );
}