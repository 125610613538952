import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class AddNotes extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    render() {

        return (

            <div className="enterdealMain">
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '90%', height: '100%', marginLeft: 35 }}>
                        <div style={{ width: 800 }}>
                            <label style={{ width: 250, height: 12, marginTop: 30, fontSize: 15 }}>Exclusivity:</label>
                            <textarea
                                className="form-control"
                                style={{ maxHeight: 150, border: '.4px solid #000', fontSize: 12, minHeight: 65, backgroundColor: this.props.edit ? '#fff' : '#FAFAFA' }}
                                onChange={this.props.onChange}
                                value={this.props.exclusivity}
                                disabled={this.props.edit ? false : true}
                                name="exclusivity"
                                type="textarea"
                            />
                            <label style={{ width: 250, height: 12, marginTop: 30, fontSize: 15 }}>Travel / Accommodations:</label>
                            <textarea
                                className="form-control"
                                style={{ maxHeight: 150, border: '.4px solid #000', fontSize: 12, minHeight: 80, backgroundColor: this.props.edit ? '#fff' : '#FAFAFA' }}
                                onChange={this.props.onChange}
                                value={this.props.travelnotes}
                                disabled={this.props.edit ? false : true}
                                name="travelnotes"
                                type="textarea"
                            />
                            <label style={{ width: 250, height: 12, marginTop: 30, fontSize: 15 }}>Production Notes:</label>
                            <textarea
                                className="form-control"
                                style={{ maxHeight: 150, border: '.4px solid #000', fontSize: 12, minHeight: 80, backgroundColor: this.props.edit ? '#fff' : '#FAFAFA' }}
                                onChange={this.props.onChange}
                                value={this.props.productionnotes}
                                disabled={this.props.edit ? false : true}
                                name="productionnotes"
                                type="textarea"
                            />
                            <label style={{ width: 250, height: 12, marginTop: 30, fontSize: 15 }}>Deal Memo Notes:</label>
                            <textarea
                                className="form-control"
                                style={{ maxHeight: 150, marginBottom: 20, border: '.4px solid #000', fontSize: 12, minHeight: 100, backgroundColor: this.props.edit ? '#fff' : '#FAFAFA' }}
                                multiline={true}
                                onChange={this.props.onChange}
                                value={this.props.dealmemonotes}
                                disabled={this.props.edit ? false : true}
                                name="dealmemonotes"
                                type="textarea"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {})(AddNotes);