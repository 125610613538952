import React, { useEffect, useState } from "react";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { createFilterOptions } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import { FaUserPlus } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
    label: {
      display: 'block',
    },
    input: {
      width: '100%',
      fontSize: 12,
      paddingTop: '2%', paddingBottom: '2%', paddingLeft: '4%',
      height: 28
    },
    listbox: {
      width: '85%',
      margin: 0,
      padding: 10,
      zIndex: 1,
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
      maxHeight: 100,
      fontSize: 12,
      border: '1px solid rgba(0,0,0,.25)',
      '& li[data-focus="true"]': {
        backgroundColor: '#e6f7ff',
        color: 'black',
        cursor: 'pointer',
      },
      '& li:active': {
        backgroundColor: '#e6f7ff',
        color: 'black',
      },
    },
  }));

const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ConnectIdAutocomplete({ offersList, onChange, connectSelect }) {
    const [stateOffersList, setOffersList] = useState(offersList);
    const [stateConnectSelect, setConnectSelect] = useState(connectSelect);

    useEffect(() => {
        setOffersList(offersList);
    }, [offersList])
    useEffect(() => {
        setConnectSelect(connectSelect);
    }, [connectSelect])

    const classes = useStyles();
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        filterOptions: filterOptions,
        id: 'use-autocomplete-demo',
        value: stateConnectSelect,
        options: stateOffersList,
        getOptionLabel: (option) => option._id,
        getOptionSelected: (option, value) => option._id === value._id,
        onChange: (event, newValue) => {
            if (newValue) {
                onChange(newValue._id)
            } else if (!newValue) {
                onChange('')
            };
        }
    });

    return (
        <div>
            <div {...getRootProps()}>
                <input className={classes.input} placeholder="Connect Id.." {...getInputProps()} />
            </div>
            {groupedOptions.length > 0 ? (
                <ul className={classes.listbox} {...getListboxProps()}>
                    {groupedOptions.map((option, index) => (
                        <li style={{ padding: 5 }} {...getOptionProps({ option, index })}>{option._id}</li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
}