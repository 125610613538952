import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    AR_LOADING,
    AR_GET,
    AR_FILTER,
    AR_SOCKET,
    AR_SOCKET_DELETE
} from "./types";

export const setARLoading = () => {
    return {
        type: AR_LOADING
    };
};

export const getAR = () => async (dispatch, getState) => {
    dispatch(setARLoading());
    await axios.get(`${process.env.REACT_APP_API}/ar/`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: AR_GET,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const addAR = ar => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/ar/add`, ar, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: ADD_CLIENT,
            //    payload: res.data
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const addARSocket = ar => (dispatch) => {
//     // dispatch({
//     //     type: AR_ADD_SOCKET,
//     //     payload: ar
//     // })
// }

export const updateAR = (ar, id) => (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API}/ar/update/${id}`, ar, tokenConfig(getState))
        .then(res => {
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

// export const updateARSocket = ar => (dispatch) => {
//     dispatch({
//         type: UPDATE_AR,
//         payload: ar
//     })
// }

export const deleteAR = id => (dispatch, getState) => {
    return axios.delete(`${process.env.REACT_APP_API}/ar/${id}`, tokenConfig(getState))
        .then(res => {
            //dispatch({
            //    type: DELETE_CLIENT,
            //    payload: id
            //})
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const arSocket = ar => (dispatch) => {
    dispatch({
        type: AR_SOCKET,
        payload: ar
    })
}

export const arSocketDelete = ar => (dispatch, getState) => {
    dispatch({
        type: AR_SOCKET_DELETE,
        payload: ar
    })
}

export const applyArFilter = (ar_filter) => (dispatch, getState) => {
    dispatch(setARLoading());
    dispatch({
        type: AR_FILTER,
        payload: ar_filter
    })
};