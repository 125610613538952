import React, { useEffect, useState } from "react";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block'
  },
  input: {
    width: '100%',
    fontSize: 12,
    paddingTop: '2%', paddingBottom: '2%', paddingLeft: '4%',
    height: 28
  },
  listbox: {
    width: '44%',
    margin: 0,
    padding: 10,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: '#fff',
    color: '#000',
    overflow: 'auto',
    fontSize: 12,
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#e6f7ff',
      color: 'black',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#e6f7ff',
      color: 'black',
    },
  },
}));

export default function BuyerTagsAutocomplete({ tagsList, onChange, tagSelect }) {
  const [stateTagsList, setTagsList] = useState(tagsList);
  const [stateTagSelect, setTagSelect] = useState(tagSelect);

  useEffect(() => {
    setTagsList(tagsList);
  }, [tagsList])
  useEffect(() => {
    setTagSelect(tagSelect);
  }, [tagSelect])

  const classes = useStyles();
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    name: 'buyercompany',
    value: stateTagSelect,
    options: stateTagsList,
    getOptionLabel: (option) => option.company,
    getOptionSelected: (option, value) => option._id === value._id,
    onChange: (event, newValue) => {
      if (newValue) {
        onChange(newValue)
      } else if (!newValue) {
        onChange('')
      };
    }
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input className={classes.input} {...getInputProps()} />
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => {
            var city = option.address.city;
            var state = option.address.state;

            return (
              <li style={{ padding: 5 }} {...getOptionProps({ option, index })}>{option.company} <div style={{ fontSize: 10 }}>({city}, {state})</div></li>
            )
          })}
        </ul>
      ) : null}
    </div>
  );
}