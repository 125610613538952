import {
    COUNTS_LOADING,
    LOAD_COUNTS_ROL,
    GET_COUNTS,
    FILTER_COUNTS,
    SORT_COUNTS,
    ADD_COUNT,
    ADD_COUNT_SOCKET,
    ADD_COUNT_BULK_SOCKET,
    DELETE_COUNT,
    COUNT_COMPARE,
    COUNT_FETCH,
    SET_COUNTS_GRID,
    UPDATE_COUNTS_GRID
} from "../actions/types";
import _ from 'lodash';
import moment from 'moment';

const initialState = {
    counts: [{}],
    filtered_counts: [],
    counts_rol: [],
    counts_rol_filtered: [],
    filter: {
        client: '',
        agent: '',
        start_date: moment().add(-1, 'days').format('MM/DD/YYYY'),
        end_date: moment().add(1, 'years').format('MM/DD/YYYY'),
        venue: '',
        city: '',
        state: '',
        country: '',
        capacity: null
    },
    loading: false,
    compare: [],
    fetch: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COUNTS_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_COUNTS:
            return {
                ...state,
                counts: action.payload,
                //filtered_counts: action.payload,
                loading: false
            };
        case LOAD_COUNTS_ROL:
            var end_date = moment().add(1, 'years').format('MM/DD/YYYY');
            var start_date = moment().subtract(1, 'day').format('MM/DD/YYYY');
            var filtered_start = action.payload.filter((item) => {
                const today = moment();
                var val = _.get(item, 'show_info')
                const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                var filterBatch = new Date(closest.date)
                var start = new Date(start_date)
                return filterBatch >= start
            })
            var filtered_end = filtered_start.filter((item) => {
                const today = new Date();
                var val = _.get(item, 'show_info')
                const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                var filterBatch = new Date(furthest.date)
                var end = new Date(end_date)
                return filterBatch <= end
            })
            return {
                ...state,
                filtered_counts: filtered_end,
                loading: false
            };
        case SET_COUNTS_GRID:
            var render = action.payload;
            return {
                ...state,
                counts_rol: render,
                counts_rol_filtered: render,
                loading: false
            };
        case UPDATE_COUNTS_GRID:
            var cnts = action.payload;

            state.counts_rol_filtered.map((item) => {
                if (item._id === cnts._id) {
                    var counts = cnts.counts;
                    //var single = item;

                    var day = moment().format('YYYY-MM-DD').toString();
                    var countA = [];
                    var countADate = [];
                    var countB = [];
                    var countBDate = [];
                    var countC = [];
                    var countCDate = [];
                    var countD = [];
                    var countDDate = [];
                    var countT = [];
                    var notesA = [];
                    var notesT = [];
                    var increaseA = '';
                    var perincreaseA = '';
                    var increaseB = '';
                    var perincreaseB = '';
                    var increaseC = '';
                    var perincreaseC = '';
                    var increaseT = '';
                    var perincreaseT = '';
                    var diffAVal = [];
                    var diffBVal = [];
                    var diffCVal = [];
                    var diffTVal = [];

                    item.show_info.forEach((d, i) => {
                        counts.map(c => {
                            var dateObj = {};
                            if (item.date_id === c._id && d._id === c._id) {
                                var sortDate = _.sortBy(c.count_data.filter(o => o.date !== day), function (dateObj) {
                                    return new Date(dateObj.date);
                                }).reverse();
                                var sortToday = _.sortBy(c.count_data.filter(o => o.date === day), function (dateObj) {
                                    return new Date(dateObj.date);
                                }).reverse();

                                if (sortDate[0]) {
                                    countA.push(sortDate[0].count);
                                    countADate.push(sortDate[0].date);
                                    notesA.push(sortDate[0].notes);
                                }
                                if (sortDate[1]) {
                                    countB.push(sortDate[1].count);
                                    countBDate.push(sortDate[1].date);
                                }
                                if (sortDate[2]) {
                                    countC.push(sortDate[2].count);
                                    countCDate.push(sortDate[2].date);
                                }
                                if (sortDate[3]) {
                                    countD.push(sortDate[3].count);
                                    countDDate.push(sortDate[3].date);
                                }
                                if (sortToday[0]) {
                                    countT.push(sortToday[0].count);
                                    notesT.push(sortToday[0].notes);
                                }

                                var dA = moment(new Date(countADate));
                                var dB = moment(new Date(countBDate));
                                var dC = moment(new Date(countCDate));
                                var dD = moment(new Date(countDDate));
                                var dT = moment(new Date());
                                var diffA = dA.diff(dB, 'days');
                                var diffB = dB.diff(dC, 'days');
                                var diffC = dC.diff(dD, 'days');
                                var diffT = dT.diff(dA, 'days');
                                if (Number.isNaN(diffA)) { diffAVal = "-" } else { diffAVal = diffA };
                                if (Number.isNaN(diffB)) { diffBVal = "-" } else { diffBVal = diffB };
                                if (Number.isNaN(diffC)) { diffCVal = "-" } else { diffCVal = diffC };
                                if (Number.isNaN(diffT)) { diffTVal = "-" } else { diffTVal = diffT };

                                increaseA = countA - countB;
                                perincreaseA = parseFloat(((countA - countB) / countB) * 100).toFixed(0) + "%";

                                increaseB = countB - countC;
                                perincreaseB = parseFloat(((countB - countC) / countC) * 100).toFixed(0) + "%";

                                increaseC = countC - countD;
                                perincreaseC = parseFloat(((countC - countD) / countD) * 100).toFixed(0) + "%";

                                increaseT = countT - countA;
                                perincreaseT = parseFloat(((countT - countA) / countT) * 100).toFixed(0) + "%";

                                item.countT = countT;
                                item.perincreaseT = perincreaseT;
                                item.day = day;
                                item.diffTVal = diffTVal;
                                item.increaseT = increaseT;
                                item.notesA = notesA;
                                item.notesT = notesT;
                            }
                        })
                    })
                    //return item
                    //return { ...item, countT: countT, perincreaseT: perincreaseT, day: day, diffTVal: diffTVal, increaseT: increaseT }
                }
                // else {
                //     return item
                // }
            })

            return {
                ...state,
                // counts_rol: render,
                // counts_rol_filtered: state.counts_rol_filtered.map((item) => {
                //     if (item._id === cnts._id) {
                //         var counts = cnts.counts;
                //         //var single = item;

                //         var day = moment().format('YYYY-MM-DD').toString();
                //         var countA = [];
                //         var countADate = [];
                //         var countB = [];
                //         var countBDate = [];
                //         var countC = [];
                //         var countCDate = [];
                //         var countD = [];
                //         var countDDate = [];
                //         var countT = [];
                //         var notesT = [];
                //         var increaseA = '';
                //         var perincreaseA = '';
                //         var increaseB = '';
                //         var perincreaseB = '';
                //         var increaseC = '';
                //         var perincreaseC = '';
                //         var increaseT = '';
                //         var perincreaseT = '';
                //         var diffAVal = [];
                //         var diffBVal = [];
                //         var diffCVal = [];
                //         var diffTVal = [];

                //         item.show_info.forEach((d, i) => {
                //             counts.map(c => {
                //                 var dateObj = {};
                //                 if (item.date_id === c._id && d._id === c._id) {
                //                     var sortDate = _.sortBy(c.count_data.filter(o => o.date !== day), function (dateObj) {
                //                         return new Date(dateObj.date);
                //                     }).reverse();
                //                     var sortToday = _.sortBy(c.count_data.filter(o => o.date === day), function (dateObj) {
                //                         return new Date(dateObj.date);
                //                     }).reverse();

                //                     if (sortDate[0]) {
                //                         countA.push(sortDate[0].count);
                //                         countADate.push(sortDate[0].date);
                //                     }
                //                     if (sortDate[1]) {
                //                         countB.push(sortDate[1].count);
                //                         countBDate.push(sortDate[1].date);
                //                     }
                //                     if (sortDate[2]) {
                //                         countC.push(sortDate[2].count);
                //                         countCDate.push(sortDate[2].date);
                //                     }
                //                     if (sortDate[3]) {
                //                         countD.push(sortDate[3].count);
                //                         countDDate.push(sortDate[3].date);
                //                     }
                //                     if (sortToday[0]) {
                //                         countT.push(sortToday[0].count);
                //                         notesT.push(sortToday[0].notes);
                //                     }

                //                     var dA = moment(new Date(countADate));
                //                     var dB = moment(new Date(countBDate));
                //                     var dC = moment(new Date(countCDate));
                //                     var dD = moment(new Date(countDDate));
                //                     var dT = moment(new Date());
                //                     var diffA = dA.diff(dB, 'days');
                //                     var diffB = dB.diff(dC, 'days');
                //                     var diffC = dC.diff(dD, 'days');
                //                     var diffT = dT.diff(dA, 'days');
                //                     if (Number.isNaN(diffA)) { diffAVal = "-" } else { diffAVal = diffA };
                //                     if (Number.isNaN(diffB)) { diffBVal = "-" } else { diffBVal = diffB };
                //                     if (Number.isNaN(diffC)) { diffCVal = "-" } else { diffCVal = diffC };
                //                     if (Number.isNaN(diffT)) { diffTVal = "-" } else { diffTVal = diffT };

                //                     increaseA = countA - countB;
                //                     perincreaseA = parseFloat(((countA - countB) / countB) * 100).toFixed(0) + "%";

                //                     increaseB = countB - countC;
                //                     perincreaseB = parseFloat(((countB - countC) / countC) * 100).toFixed(0) + "%";

                //                     increaseC = countC - countD;
                //                     perincreaseC = parseFloat(((countC - countD) / countD) * 100).toFixed(0) + "%";

                //                     increaseT = countT - countA;
                //                     perincreaseT = parseFloat(((countT - countA) / countT) * 100).toFixed(0) + "%";

                //                     // item.countT = countT;
                //                     // item.perincreaseT = perincreaseT;
                //                     // item.day = day;
                //                     // item.diffTVal = diffTVal;
                //                     // item.increaseT = increaseT;
                //                 }
                //             })
                //         })
                //         //return item
                //         return { ...item, countT: countT, perincreaseT: perincreaseT, day: day, diffTVal: diffTVal, increaseT: increaseT }
                //     } 
                //     else {
                //         return item
                //     }
                // }),
                //loading: false
            };
        case FILTER_COUNTS:
            var counts_filter = action.payload;
            var filtered_start = state.counts_rol.filter((item) => {
                const today = moment();
                var val = _.get(item, 'show_info')
                const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                var filterBatch = new Date(closest.date)
                var start = counts_filter.start_date
                return filterBatch >= start
            })
            var filtered_end = filtered_start.filter((item) => {
                const today = new Date();
                var val = _.get(item, 'show_info')
                const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                var filterBatch = new Date(furthest.date)
                var end = counts_filter.end_date
                return filterBatch <= end
            })
            var filteredArtist = filtered_end.filter((item) => {
                var filterBatch = item.client_id
                return filterBatch.indexOf(counts_filter.client) >= 0
            })
            var filteredVenue = filteredArtist.filter((item) => {
                var filterBatch = item.venue_name
                return filterBatch.indexOf(counts_filter.venue) >= 0
            })
            var filteredState = filteredVenue.filter((item) => {
                var filterBatch = item.venue_state
                return filterBatch.indexOf(counts_filter.state) >= 0
            })
            var filteredCity = filteredState.filter((item) => {
                var filterBatch = item.venue_city
                return filterBatch.indexOf(counts_filter.city) >= 0
            })
            var filteredCountry = filteredCity.filter((item) => {
                var filterBatch = item.venue_country
                return filterBatch.indexOf(counts_filter.country) >= 0
            })
            if (counts_filter.capacity) {
                filteredCountry = filteredCountry.filter((item) => {
                    return item.sellable >= counts_filter.capacity
                })
            }

            return {
                ...state,
                counts_rol_filtered: filteredCountry,
                filter: counts_filter,
                loading: false
            };
        case SORT_COUNTS:
            var sort = action.payload;
            var sortedCounts = [];
            if (sort.cat === '') {
                sortedCounts = _.sortBy(state.counts_rol_filtered, function (dateObj) {
                    return new Date(dateObj.show_info[0].date);
                });
            } else {
                sortedCounts = _.orderBy(state.counts_rol_filtered, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            }
            return {
                ...state,
                counts_rol_filtered: sortedCounts,
                sort: sort,
                loading: false
            };
        case ADD_COUNT:
            return {
                ...state,
                //buyers: [action.payload, ...state.buyers],
            };
        case ADD_COUNT_SOCKET:
            return {
                ...state,
                counts: action.payload,
            };
        case ADD_COUNT_BULK_SOCKET:
            let data = [...state.counts];
            var index = data.findIndex(obj => obj._id === action.payload._id);
            data[index] = action.payload;
            return {
                ...state,
                counts: data,
            };
        case DELETE_COUNT:
            return {
                ...state,
                counts: state.counts.filter(count => count._id !== action.payload),
            };
        case COUNT_COMPARE:
            return {
                ...state,
                compare: action.payload,
                loading: false
            };
        case COUNT_FETCH:
            return {
                ...state,
                fetch: action.payload,
                loading: false
            };
        default:
            return state;
    }
}