import React, { Component } from 'react'
import BlackLogo from '../../assets/BlackLogo.png';

class ItineraryPDF extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //id: this.props.dealdata._id,
            //client: this.props.artist,
            //dates: this.props.dealdata.dates,
            //venue: this.props.venuedata.venuename,
            //venueaddress: this.props.venuedata.address,
            //venuecity: this.props.venuedata.city,
            //venuestate: this.props.venuedata.state,
            //venuezipcode: this.props.venuedata.zipcode,
            //venuecountry: this.props.venuedata.country,
            //venuephone: this.props.venuedata.phone,
            //numberofshows: this.props.dealdata.numberofshows,
            //status: this.props.dealdata.status,
            //billing: this.props.dealdata.billing,
            //guarantee: this.props.dealdata.guarantee,
            //dealtype: this.props.dealdata.dealtype,
            //agelimit: this.props.dealdata.agelimit,
            //showtimes: this.props.dealdata.showtimes,
            //merchhard: this.props.dealdata.merchhard,
            //merchsoft: this.props.dealdata.merchsoft,
            //merchseller: this.props.dealdata.merchseller,
            //merchnotes: this.props.dealdata.merchnotes,
            //buyercompany: this.props.buyerdata.company,
            //buyeraddress: this.props.buyerdata.address,
            //contactfn: this.props.contactdata.firstname,
            //contactln: this.props.contactdata.lastname,
            //contactemail: this.props.contactdata.email,
            //contactop: this.props.contactdata.officephone,
            //contactcp: this.props.contactdata.cellphone,
            //ticketscaling: this.props.dealdata.ticketscaling,
            //taxes: this.props.dealdata.taxes,
            //expenses: this.props.dealdata.expenses,
        };
    }

    componentDidMount() {
        //this.BuyerOpFormat(this.props.contactdata.officephone)
        //this.VenuePhoneFormat(this.props.venuedata.phone)
    }

    BuyerOpFormat = (text) => {
        let formatedNo = this.formatPhoneNumber(text);
        this.setState({ contactop: formatedNo });
    };

    VenuePhoneFormat = (text) => {
        let formatedNo = this.formatPhoneNumber(text);
        this.setState({ venuephone: formatedNo });
    };

    formatPhoneNumber = (text => {
        var cleaned = ("" + text).replace(/\D/g, "");
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = match[1] ? "+1 " : "",
                number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
                    ""
                );
            return number;
        }
        return text;
    })


    formatDate(date) {

        const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const d = new Date(date);
        const day = days[d.getDay()];
        const mon = monthNames[d.getMonth()];
        const time = `${day}. ${mon} ${d.getDate()}, ${d.getFullYear()}`;
        return time;
    }

    //onDocumentLoadSuccess({ numPages }) {
    //  this.setNumPages(numPages);
    //}

    render() {

        //const numberFormat = (value) =>
        //    new Intl.NumberFormat().format(value);

        const currencyFormat = (value) =>
            new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            }).format(value);

        //const captotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.capacity); }, 0);
        //const captotalval = parseInt(captotal, 10)

        //const compstotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.comps); }, 0);
        //const compstotalval = parseInt(compstotal, 10)

        //const killstotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.kills); }, 0);
        //const killstotalval = parseInt(killstotal, 10)

        //const grosstotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.gross); }, 0);
        //const grosstotalval = numberFormat(grosstotal)

        //const guarantee = numberFormat(this.state.guarantee)

        //const gross_tickets = captotal - compstotal - killstotal
        //const flat_tax_total = this.state.taxes
        //    .filter(tax => tax.type === "Flat")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        //const percent_tax = this.state.taxes
        //    .filter(tax => tax.type === "Percent")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const percent_tax_total = (percent_tax * .01) * grosstotal

        //const perticket_tax = this.state.taxes
        //    .filter(tax => tax.type === "Per Ticket")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const perticket_tax_total = perticket_tax * gross_tickets

        //const taxtotal = flat_tax_total + percent_tax_total + perticket_tax_total

        //const flat_exp_total = this.state.expenses
        //    .filter(exp => exp.type === "Flat")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        //const percent_exp = this.state.expenses
        //    .filter(exp => exp.type === "Percent")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const percent_exp_total = (percent_exp * .01) * grosstotal

        //const perticket_exp = this.state.expenses
        //    .filter(exp => exp.type === "Per Ticket")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const perticket_exp_total = perticket_exp * gross_tickets

        //const exptotal = flat_exp_total + percent_exp_total + perticket_exp_total;

        const dealstartlang = [];
        var dealtype = this.props.data.dealtype;
        if (dealtype === 'Flat') {
            dealstartlang.push("flat guarantee")
        } else if (dealtype === 'Versus Net') {
            dealstartlang.push("guarantee, versus the right to receive")
        } else if (dealtype === 'Versus Gross') {
            dealstartlang.push("guarantee, versus the right to receive")
        } else if (dealtype === 'Plus') {
            dealstartlang.push("guarantee, plus the right to receive")
        } else if (dealtype === 'Promoter Profit') {
            dealstartlang.push("guarantee, plus the right to receive")
        } else if (dealtype === 'Bonus') {
            dealstartlang.push("Plus the following bonuses")
        } else if (dealtype === 'Versus Net (Custom Walkout)') {
            dealstartlang.push("guarantee, versus the right to receive")
        } else if (dealtype === 'Versus Gross (Custom Walkout)') {
            dealstartlang.push("guarantee, versus the right to receive")
        } else if (dealtype === 'Plus (Custom Walkout)') {
            dealstartlang.push("guarantee, plus the right to receive")
        } else if (dealtype === 'Promoter Profit (Custom Walkout)') {
            dealstartlang.push("guarantee, plus the right to receive")
        } else {
            dealstartlang.push('');
        };

        var data = this.props.data;

        return (
            <html>
                <body>
                    <div>
                        <div style={{ width: 610 }}>
                            <img style={{ position: 'absolute', top: 5, left: 5 }} width="58px" height="60px" src={BlackLogo}></img>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-end', alignItems: 'center', height: 70, borderBottom: '2px solid #ddd' }}>
                                <h1 style={{ color: '#000', fontSize: '9px', fontWeight: 'bold' }}>{data[0].artist_name.toUpperCase()}</h1>
                                <h1 style={{ color: '#333333', fontSize: '7px' }}>TOUR ITINERARY</h1>
                            </div>
                            <table style={{ marginTop: 10, width: '98%', marginLeft: '1%', marginRight: '1%' }}>
                                <thead style={{ backgroundColor: '#000' }}>
                                    <tr>
                                        <th style={{ width: '2%' }}> </th>
                                        <th style={{ width: '13%', color: '#fff', fontSize: 6 }}>DATE</th>
                                        <th style={{ width: '13%', color: '#fff', fontSize: 6 }}>VENUE</th>
                                        <th style={{ width: '13%', color: '#fff', fontSize: 6 }}>PROMOTER</th>
                                        <th style={{ width: '20%', color: '#fff', fontSize: 6 }}>SHOW INFO</th>
                                        <th style={{ width: '13%', color: '#fff', fontSize: 6 }}>PRODUCTION</th>
                                        <th style={{ width: '13%', color: '#fff', fontSize: 6 }}>MERCH</th>
                                        <th style={{ width: '13%', color: '#fff', fontSize: 6 }}>SHOW TIMES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr style={{ border: '.3px solid #000' }}>
                                                <td style={{ width: '2%', color: '#000', fontSize: 6, borderRight: '.3px solid #000', padding: 5 }}>{index + 1}</td>
                                                <td style={{ width: '13%', color: '#000', fontSize: 6, borderRight: '.3px solid #000', padding: 5 }}>{item.show_info.map((d, i) => { return (<span>{this.formatDate(d.date)}<br /></span>) })}<br /><br /><i>Status:<br />{item.status}<br />Number of Shows: {item.numberofshows}</i></td>
                                                <td style={{ width: '13%', color: '#000', fontSize: 6, borderRight: '.3px solid #000', padding: 5 }}>{item.venue.venue}<br />{item.venue.address}<br />{item.venue.address2}<br/>{item.venue.city}, {item.venue.state} {item.venue.postalcode}<br/>{item.venue.country}</td>
                                                <td style={{ width: '13%', color: '#000', fontSize: 6, borderRight: '.3px solid #000', padding: 5 }}>{item.buyer.company}<br /><span>{item.buyer.address.address}<br/>{item.buyer.address.address2}<br/>{item.buyer.address.city}, {item.buyer.address.state} {item.buyer.address.postalcode}<br/>{item.buyer.address.country}</span></td>
                                                <td style={{ width: '20%', color: '#000', fontSize: 6, borderRight: '.3px solid #000', padding: 5 }}>SHOW INFO</td>
                                                <td style={{ width: '13%', color: '#000', fontSize: 6, borderRight: '.3px solid #000', padding: 5 }}>PRODUCTION</td>
                                                <td style={{ width: '13%', color: '#000', fontSize: 6, borderRight: '.3px solid #000', padding: 5 }}>MERCH</td>
                                                <td style={{ width: '13%', color: '#000', fontSize: 6, padding: 5 }}>SHOW TIMES</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div >
                    </div>
                </body>
            </html>
        )
    }
};

function toggle24hr(time, onoff) {
    if (onoff == undefined) onoff = isNaN(time.replace(':', ''))//auto-detect format
    var pm = time.toString().toLowerCase().indexOf('pm') > -1 //check if 'pm' exists in the time string
    time = time.toString().toLowerCase().replace(/[ap]m/, '').split(':') //convert time to an array of numbers
    time[0] = Number(time[0])
    if (onoff) {//convert to 24 hour:
        if ((pm && time[0] != 12)) time[0] += 12
        else if (!pm && time[0] == 12) time[0] = '00'  //handle midnight
        if (String(time[0]).length == 1) time[0] = '0' + time[0] //add leading zeros if needed
    } else { //convert to 12 hour:
        pm = time[0] >= 12
        if (!time[0]) time[0] = 12 //handle midnight
        else if (pm && time[0] != 12) time[0] -= 12
    }
    return onoff ? time.join(':') : time.join(':') + (pm ? ' PM' : ' AM')
}


export default ItineraryPDF;