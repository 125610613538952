import React, { useEffect, useState } from "react";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block',
  },
  input: {
    width: 200,
    fontSize: 12,
    paddingTop: '2%', paddingBottom: '2%', paddingLeft: '4%',
    height: 28,
    backgroundColor: '#000',
    border: '2px solid #333',
    borderRadius: 10,
    color: '#cdcdcd',
    boxShadow: "5px 5px 5px 0px #ddd"
  },
  listbox: {
    width: 300,
    margin: 0,
    padding: 10,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: '#000',
    color: '#cdcdcd',
    overflow: 'auto',
    fontSize: 12,
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#e6f7ff',
      color: 'black',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#e6f7ff',
      color: 'black',
    },
  },
}));

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function SearchbarAutocomplete({ clientList, venueList, buyerList, onChange, clientSelect }) {
  const [open, setOpen] = React.useState(false);
  const [stateClientList, setClientList] = useState(clientList);
  const [stateVenueList, setVenueList] = useState(venueList);
  const [stateBuyerList, setBuyerList] = useState(buyerList);
  const [stateClientSelect, setClientSelect] = useState(clientSelect);
  const [optionsA, setOptions] = React.useState([]);
  const loading = open && optionsA.length === 0;

  useEffect(() => {
    setClientList(clientList);
  }, [clientList])
  useEffect(() => {
    setVenueList(venueList);
  }, [venueList])
  useEffect(() => {
    setBuyerList(buyerList);
  }, [buyerList])
  useEffect(() => {
    setClientSelect(clientSelect);
  }, [clientSelect])

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const options = await stateClientList.concat(stateVenueList).concat(stateBuyerList);
      await sleep(1e3); // For demo purposes.

      console.log(options)
      if (active) {
        setOptions(options);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const classes = useStyles();
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    open: open,
    onOpen: () => {
      setOpen(true);
    },
    onClose: () => {
      setOpen(false);
    },
    value: stateClientSelect,
    options: optionsA,
    getOptionLabel: (option) => option.client || option.venue_name || option.company,
    getOptionSelected: (option, value) => option._id === value._id,
    loading: loading,
    onChange: (event, newValue) => {
      if (newValue) {
        onChange(newValue)
      } else if (!newValue) {
        onChange('')
      };
    }
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input className="searchbar" placeholder="Search.." {...getInputProps()} />
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            option.client ?
              <li style={{ padding: 5 }} {...getOptionProps({ option, index })}>{option.client} (client)</li> :
              option.venue_name ?
                <li style={{ padding: 5 }} {...getOptionProps({ option, index })}>{option.venue_name} (venue)</li> :
                <li style={{ padding: 5 }} {...getOptionProps({ option, index })}>{option.company} (buyer)</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}