import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    BUYERS_INIT_LOADING,
    BUYERS_LOADING,
    BUYERS_GET,
    BUYERS_FILTER,
    BUYERS_SORT,
    BUYERS_SOCKET,
    BUYERS_SOCKET_DELETE,
    NOTIFICATIONS
} from "./types";

export const setBuyersLoading = () => {
    return {
        type: BUYERS_LOADING
    };
};

export const setBuyersInitLoading = () => {
    return {
        type: BUYERS_INIT_LOADING
    };
};

export const getBuyers = (data) => (dispatch, getState) => {
    dispatch(setBuyersInitLoading());
    axios.post(`${process.env.REACT_APP_API_CLIENT}/buyers/`, data, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: BUYERS_GET,
                payload: res.data
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const buyerSocket = buyer => (dispatch) => {
    dispatch({
        type: BUYERS_SOCKET,
        payload: buyer
    })
}

export const buyerSocketDelete = buyer => (dispatch) => {
    dispatch({
        type: BUYERS_SOCKET_DELETE,
        payload: buyer
    })
}