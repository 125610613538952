
export default function getBadWords(str) {
    let badwords = records;
    let words = []
    badwords.forEach(r =>
        str.replace(/ /g, '').toLowerCase()
            .includes(r.word.replace(/ /g, '').toLowerCase()) ? words.push(r.word) : null)
    return words
}

const records = [
    {
        "word": "anal",
        "language": "en"
    },
    {
        "word": "anus",
        "language": "en"
    },
    {
        "word": "arse",
        "language": "en"
    },
    {
        "word": "ass",
        "language": "en"
    },
    {
        "word": "balls",
        "language": "en"
    },
    {
        "word": "ballsack",
        "language": "en"
    },
    {
        "word": "bastard",
        "language": "en"
    },
    {
        "word": "biatch",
        "language": "en"
    },
    {
        "word": "bitch",
        "language": "en"
    },
    {
        "word": "bloody",
        "language": "en"
    },
    {
        "word": "blow job",
        "language": "en"
    },
    {
        "word": "blowjob",
        "language": "en"
    },
    {
        "word": "bollock",
        "language": "en"
    },
    {
        "word": "bollok",
        "language": "en"
    },
    {
        "word": "boner",
        "language": "en"
    },
    {
        "word": "boob",
        "language": "en"
    },
    {
        "word": "bugger",
        "language": "en"
    },
    {
        "word": "bum",
        "language": "en"
    },
    {
        "word": "butt",
        "language": "en"
    },
    {
        "word": "buttplug",
        "language": "en"
    },
    {
        "word": "clitoris",
        "language": "en"
    },
    {
        "word": "cock",
        "language": "en"
    },
    {
        "word": "coon",
        "language": "en"
    },
    {
        "word": "crap",
        "language": "en"
    },
    {
        "word": "cunt",
        "language": "en"
    },
    {
        "word": "damn",
        "language": "en"
    },
    {
        "word": "dick",
        "language": "en"
    },
    {
        "word": "dildo",
        "language": "en"
    },
    {
        "word": "dyke",
        "language": "en"
    },
    {
        "word": "f u c k",
        "language": "en"
    },
    {
        "word": "fag",
        "language": "en"
    },
    {
        "word": "feck",
        "language": "en"
    },
    {
        "word": "felching",
        "language": "en"
    },
    {
        "word": "fellate",
        "language": "en"
    },
    {
        "word": "fellatio",
        "language": "en"
    },
    {
        "word": "flange",
        "language": "en"
    },
    {
        "word": "fuck",
        "language": "en"
    },
    {
        "word": "fudge packer",
        "language": "en"
    },
    {
        "word": "fudgepacker",
        "language": "en"
    },
    {
        "word": "God damn",
        "language": "en"
    },
    {
        "word": "Goddamn",
        "language": "en"
    },
    {
        "word": "hell",
        "language": "en"
    },
    {
        "word": "homo",
        "language": "en"
    },
    {
        "word": "jerk",
        "language": "en"
    },
    {
        "word": "jizz",
        "language": "en"
    },
    {
        "word": "knob end",
        "language": "en"
    },
    {
        "word": "knobend",
        "language": "en"
    },
    {
        "word": "labia",
        "language": "en"
    },
    {
        "word": "lmao",
        "language": "en"
    },
    {
        "word": "lmfao",
        "language": "en"
    },
    {
        "word": "muff",
        "language": "en"
    },
    {
        "word": "nigga",
        "language": "en"
    },
    {
        "word": "nigger",
        "language": "en"
    },
    {
        "word": "omg",
        "language": "en"
    },
    {
        "word": "penis",
        "language": "en"
    },
    {
        "word": "piss",
        "language": "en"
    },
    {
        "word": "poop",
        "language": "en"
    },
    {
        "word": "prick",
        "language": "en"
    },
    {
        "word": "pube",
        "language": "en"
    },
    {
        "word": "pussy",
        "language": "en"
    },
    {
        "word": "queer",
        "language": "en"
    },
    {
        "word": "s hit",
        "language": "en"
    },
    {
        "word": "scrotum",
        "language": "en"
    },
    {
        "word": "sex",
        "language": "en"
    },
    {
        "word": "sh1t",
        "language": "en"
    },
    {
        "word": "shit",
        "language": "en"
    },
    {
        "word": "slut",
        "language": "en"
    },
    {
        "word": "smegma",
        "language": "en"
    },
    {
        "word": "spunk",
        "language": "en"
    },
    {
        "word": "tit",
        "language": "en"
    },
    {
        "word": "tosser",
        "language": "en"
    },
    {
        "word": "turd",
        "language": "en"
    },
    {
        "word": "twat",
        "language": "en"
    },
    {
        "word": "vagina",
        "language": "en"
    },
    {
        "word": "wank",
        "language": "en"
    },
    {
        "word": "whore",
        "language": "en"
    },
    {
        "word": "wtf",
        "language": "en"
    },
    {
        "word": "سكس",
        "language": "ar"
    },
    {
        "word": "طيز",
        "language": "ar"
    },
    {
        "word": "شرج",
        "language": "ar"
    },
    {
        "word": "لعق",
        "language": "ar"
    },
    {
        "word": "لحس",
        "language": "ar"
    },
    {
        "word": "مص",
        "language": "ar"
    },
    {
        "word": "تمص",
        "language": "ar"
    },
    {
        "word": "بيضان",
        "language": "ar"
    },
    {
        "word": "ثدي",
        "language": "ar"
    },
    {
        "word": "بز",
        "language": "ar"
    },
    {
        "word": "بزاز",
        "language": "ar"
    },
    {
        "word": "حلمة",
        "language": "ar"
    },
    {
        "word": "مفلقسة",
        "language": "ar"
    },
    {
        "word": "بظر",
        "language": "ar"
    },
    {
        "word": "كس",
        "language": "ar"
    },
    {
        "word": "فرج",
        "language": "ar"
    },
    {
        "word": "شهوة",
        "language": "ar"
    },
    {
        "word": "شاذ",
        "language": "ar"
    },
    {
        "word": "مبادل",
        "language": "ar"
    },
    {
        "word": "عاهرة",
        "language": "ar"
    },
    {
        "word": "جماع",
        "language": "ar"
    },
    {
        "word": "قضيب",
        "language": "ar"
    },
    {
        "word": "زب",
        "language": "ar"
    },
    {
        "word": "لوطي",
        "language": "ar"
    },
    {
        "word": "لواط",
        "language": "ar"
    },
    {
        "word": "سحاق",
        "language": "ar"
    },
    {
        "word": "سحاقية",
        "language": "ar"
    },
    {
        "word": "اغتصاب",
        "language": "ar"
    },
    {
        "word": "خنثي",
        "language": "ar"
    },
    {
        "word": "احتلام",
        "language": "ar"
    },
    {
        "word": "نيك",
        "language": "ar"
    },
    {
        "word": "متناك",
        "language": "ar"
    },
    {
        "word": "متناكة",
        "language": "ar"
    },
    {
        "word": "شرموطة",
        "language": "ar"
    },
    {
        "word": "عرص",
        "language": "ar"
    },
    {
        "word": "خول",
        "language": "ar"
    },
    {
        "word": "قحبة",
        "language": "ar"
    },
    {
        "word": "لبوة",
        "language": "ar"
    },
    {
        "word": "bordel",
        "language": "cs"
    },
    {
        "word": "buzna",
        "language": "cs"
    },
    {
        "word": "čumět",
        "language": "cs"
    },
    {
        "word": "čurák",
        "language": "cs"
    },
    {
        "word": "debil",
        "language": "cs"
    },
    {
        "word": "do piče",
        "language": "cs"
    },
    {
        "word": "do prdele",
        "language": "cs"
    },
    {
        "word": "dršťka",
        "language": "cs"
    },
    {
        "word": "držka",
        "language": "cs"
    },
    {
        "word": "flundra",
        "language": "cs"
    },
    {
        "word": "hajzl",
        "language": "cs"
    },
    {
        "word": "hovno",
        "language": "cs"
    },
    {
        "word": "chcanky",
        "language": "cs"
    },
    {
        "word": "chuj",
        "language": "cs"
    },
    {
        "word": "jebat",
        "language": "cs"
    },
    {
        "word": "kokot",
        "language": "cs"
    },
    {
        "word": "kokotina",
        "language": "cs"
    },
    {
        "word": "koňomrd",
        "language": "cs"
    },
    {
        "word": "kunda",
        "language": "cs"
    },
    {
        "word": "kurva",
        "language": "cs"
    },
    {
        "word": "mamrd",
        "language": "cs"
    },
    {
        "word": "mrdat",
        "language": "cs"
    },
    {
        "word": "mrdka",
        "language": "cs"
    },
    {
        "word": "mrdník",
        "language": "cs"
    },
    {
        "word": "oslošoust",
        "language": "cs"
    },
    {
        "word": "piča",
        "language": "cs"
    },
    {
        "word": "píčus",
        "language": "cs"
    },
    {
        "word": "píchat",
        "language": "cs"
    },
    {
        "word": "pizda",
        "language": "cs"
    },
    {
        "word": "prcat",
        "language": "cs"
    },
    {
        "word": "prdel",
        "language": "cs"
    },
    {
        "word": "prdelka",
        "language": "cs"
    },
    {
        "word": "sračka",
        "language": "cs"
    },
    {
        "word": "srát",
        "language": "cs"
    },
    {
        "word": "šoustat",
        "language": "cs"
    },
    {
        "word": "šulin",
        "language": "cs"
    },
    {
        "word": "vypíčenec",
        "language": "cs"
    },
    {
        "word": "zkurvit",
        "language": "cs"
    },
    {
        "word": "zkurvysyn",
        "language": "cs"
    },
    {
        "word": "zmrd",
        "language": "cs"
    },
    {
        "word": "žrát",
        "language": "cs"
    },
    {
        "word": "bøsserøv",
        "language": "da"
    },
    {
        "word": "fisse",
        "language": "da"
    },
    {
        "word": "fissehår",
        "language": "da"
    },
    {
        "word": "hestepik",
        "language": "da"
    },
    {
        "word": "kussekryller",
        "language": "da"
    },
    {
        "word": "lort",
        "language": "da"
    },
    {
        "word": "luder",
        "language": "da"
    },
    {
        "word": "pik",
        "language": "da"
    },
    {
        "word": "pikhår",
        "language": "da"
    },
    {
        "word": "pikslugeri",
        "language": "da"
    },
    {
        "word": "piksutteri",
        "language": "da"
    },
    {
        "word": "pis",
        "language": "da"
    },
    {
        "word": "røv",
        "language": "da"
    },
    {
        "word": "røvhul",
        "language": "da"
    },
    {
        "word": "røvskæg",
        "language": "da"
    },
    {
        "word": "røvspræke",
        "language": "da"
    },
    {
        "word": "analritter",
        "language": "de"
    },
    {
        "word": "arsch",
        "language": "de"
    },
    {
        "word": "arschficker",
        "language": "de"
    },
    {
        "word": "arschlecker",
        "language": "de"
    },
    {
        "word": "arschloch",
        "language": "de"
    },
    {
        "word": "bimbo",
        "language": "de"
    },
    {
        "word": "bratze",
        "language": "de"
    },
    {
        "word": "bumsen",
        "language": "de"
    },
    {
        "word": "bonze",
        "language": "de"
    },
    {
        "word": "dödel",
        "language": "de"
    },
    {
        "word": "fick",
        "language": "de"
    },
    {
        "word": "ficken",
        "language": "de"
    },
    {
        "word": "flittchen",
        "language": "de"
    },
    {
        "word": "fotze",
        "language": "de"
    },
    {
        "word": "fratze",
        "language": "de"
    },
    {
        "word": "hackfresse",
        "language": "de"
    },
    {
        "word": "hure",
        "language": "de"
    },
    {
        "word": "hurensohn",
        "language": "de"
    },
    {
        "word": "ische",
        "language": "de"
    },
    {
        "word": "kackbratze",
        "language": "de"
    },
    {
        "word": "kacke",
        "language": "de"
    },
    {
        "word": "kacken",
        "language": "de"
    },
    {
        "word": "kackwurst",
        "language": "de"
    },
    {
        "word": "kampflesbe",
        "language": "de"
    },
    {
        "word": "kanake",
        "language": "de"
    },
    {
        "word": "kimme",
        "language": "de"
    },
    {
        "word": "lümmel",
        "language": "de"
    },
    {
        "word": "MILF",
        "language": "de"
    },
    {
        "word": "möpse",
        "language": "de"
    },
    {
        "word": "morgenlatte",
        "language": "de"
    },
    {
        "word": "möse",
        "language": "de"
    },
    {
        "word": "mufti",
        "language": "de"
    },
    {
        "word": "muschi",
        "language": "de"
    },
    {
        "word": "nackt",
        "language": "de"
    },
    {
        "word": "neger",
        "language": "de"
    },
    {
        "word": "nippel",
        "language": "de"
    },
    {
        "word": "nutte",
        "language": "de"
    },
    {
        "word": "onanieren",
        "language": "de"
    },
    {
        "word": "orgasmus",
        "language": "de"
    },
    {
        "word": "pimmel",
        "language": "de"
    },
    {
        "word": "pimpern",
        "language": "de"
    },
    {
        "word": "pinkeln",
        "language": "de"
    },
    {
        "word": "pissen",
        "language": "de"
    },
    {
        "word": "pisser",
        "language": "de"
    },
    {
        "word": "popel",
        "language": "de"
    },
    {
        "word": "poppen",
        "language": "de"
    },
    {
        "word": "porno",
        "language": "de"
    },
    {
        "word": "reudig",
        "language": "de"
    },
    {
        "word": "rosette",
        "language": "de"
    },
    {
        "word": "schabracke",
        "language": "de"
    },
    {
        "word": "schlampe",
        "language": "de"
    },
    {
        "word": "scheiße",
        "language": "de"
    },
    {
        "word": "scheisser",
        "language": "de"
    },
    {
        "word": "schiesser",
        "language": "de"
    },
    {
        "word": "schnackeln",
        "language": "de"
    },
    {
        "word": "schwanzlutscher",
        "language": "de"
    },
    {
        "word": "schwuchtel",
        "language": "de"
    },
    {
        "word": "tittchen",
        "language": "de"
    },
    {
        "word": "titten",
        "language": "de"
    },
    {
        "word": "vögeln",
        "language": "de"
    },
    {
        "word": "vollpfosten",
        "language": "de"
    },
    {
        "word": "wichse",
        "language": "de"
    },
    {
        "word": "wichsen",
        "language": "de"
    },
    {
        "word": "wichser",
        "language": "de"
    },
    {
        "word": "bugren",
        "language": "eo"
    },
    {
        "word": "bugri",
        "language": "eo"
    },
    {
        "word": "bugru",
        "language": "eo"
    },
    {
        "word": "ĉiesulino",
        "language": "eo"
    },
    {
        "word": "ĉiesulo",
        "language": "eo"
    },
    {
        "word": "diofek",
        "language": "eo"
    },
    {
        "word": "diofeka",
        "language": "eo"
    },
    {
        "word": "fek",
        "language": "eo"
    },
    {
        "word": "feken",
        "language": "eo"
    },
    {
        "word": "fekfikanto",
        "language": "eo"
    },
    {
        "word": "feklekulo",
        "language": "eo"
    },
    {
        "word": "fekulo",
        "language": "eo"
    },
    {
        "word": "fik",
        "language": "eo"
    },
    {
        "word": "fikado",
        "language": "eo"
    },
    {
        "word": "fikema",
        "language": "eo"
    },
    {
        "word": "fikfek",
        "language": "eo"
    },
    {
        "word": "fiki",
        "language": "eo"
    },
    {
        "word": "fikiĝi",
        "language": "eo"
    },
    {
        "word": "fikiĝu",
        "language": "eo"
    },
    {
        "word": "fikilo",
        "language": "eo"
    },
    {
        "word": "fikklaŭno",
        "language": "eo"
    },
    {
        "word": "fikota",
        "language": "eo"
    },
    {
        "word": "fiku",
        "language": "eo"
    },
    {
        "word": "forfiki",
        "language": "eo"
    },
    {
        "word": "forfikiĝu",
        "language": "eo"
    },
    {
        "word": "forfiku",
        "language": "eo"
    },
    {
        "word": "forfurzu",
        "language": "eo"
    },
    {
        "word": "forpisi",
        "language": "eo"
    },
    {
        "word": "forpisu",
        "language": "eo"
    },
    {
        "word": "furzulo",
        "language": "eo"
    },
    {
        "word": "kacen",
        "language": "eo"
    },
    {
        "word": "kaco",
        "language": "eo"
    },
    {
        "word": "kacsuĉulo",
        "language": "eo"
    },
    {
        "word": "kojono",
        "language": "eo"
    },
    {
        "word": "piĉen",
        "language": "eo"
    },
    {
        "word": "piĉo",
        "language": "eo"
    },
    {
        "word": "zamenfek",
        "language": "eo"
    },
    {
        "word": "Asesinato",
        "language": "es"
    },
    {
        "word": "asno",
        "language": "es"
    },
    {
        "word": "bastardo",
        "language": "es"
    },
    {
        "word": "Bollera",
        "language": "es"
    },
    {
        "word": "Cabron",
        "language": "es"
    },
    {
        "word": "Caca",
        "language": "es"
    },
    {
        "word": "Chupada",
        "language": "es"
    },
    {
        "word": "Chupapollas",
        "language": "es"
    },
    {
        "word": "Chupetón",
        "language": "es"
    },
    {
        "word": "concha",
        "language": "es"
    },
    {
        "word": "Concha de tu madre",
        "language": "es"
    },
    {
        "word": "Coño",
        "language": "es"
    },
    {
        "word": "Coprofagía",
        "language": "es"
    },
    {
        "word": "Culo",
        "language": "es"
    },
    {
        "word": "Drogas",
        "language": "es"
    },
    {
        "word": "Esperma",
        "language": "es"
    },
    {
        "word": "Fiesta de salchichas",
        "language": "es"
    },
    {
        "word": "Follador",
        "language": "es"
    },
    {
        "word": "Follar",
        "language": "es"
    },
    {
        "word": "Gilipichis",
        "language": "es"
    },
    {
        "word": "Gilipollas",
        "language": "es"
    },
    {
        "word": "Hacer una paja",
        "language": "es"
    },
    {
        "word": "Haciendo el amor",
        "language": "es"
    },
    {
        "word": "Heroína",
        "language": "es"
    },
    {
        "word": "Hija de puta",
        "language": "es"
    },
    {
        "word": "Hijaputa",
        "language": "es"
    },
    {
        "word": "Hijo de puta",
        "language": "es"
    },
    {
        "word": "Hijoputa",
        "language": "es"
    },
    {
        "word": "Idiota",
        "language": "es"
    },
    {
        "word": "Imbécil",
        "language": "es"
    },
    {
        "word": "infierno",
        "language": "es"
    },
    {
        "word": "Jilipollas",
        "language": "es"
    },
    {
        "word": "Kapullo",
        "language": "es"
    },
    {
        "word": "Lameculos",
        "language": "es"
    },
    {
        "word": "Maciza",
        "language": "es"
    },
    {
        "word": "Macizorra",
        "language": "es"
    },
    {
        "word": "maldito",
        "language": "es"
    },
    {
        "word": "Mamada",
        "language": "es"
    },
    {
        "word": "Marica",
        "language": "es"
    },
    {
        "word": "Maricón",
        "language": "es"
    },
    {
        "word": "Mariconazo",
        "language": "es"
    },
    {
        "word": "martillo",
        "language": "es"
    },
    {
        "word": "Mierda",
        "language": "es"
    },
    {
        "word": "Nazi",
        "language": "es"
    },
    {
        "word": "Orina",
        "language": "es"
    },
    {
        "word": "Pedo",
        "language": "es"
    },
    {
        "word": "Pervertido",
        "language": "es"
    },
    {
        "word": "Pezón",
        "language": "es"
    },
    {
        "word": "Pinche",
        "language": "es"
    },
    {
        "word": "Prostituta",
        "language": "es"
    },
    {
        "word": "Puta",
        "language": "es"
    },
    {
        "word": "Racista",
        "language": "es"
    },
    {
        "word": "Ramera",
        "language": "es"
    },
    {
        "word": "Sádico",
        "language": "es"
    },
    {
        "word": "Semen",
        "language": "es"
    },
    {
        "word": "Sexo",
        "language": "es"
    },
    {
        "word": "Sexo oral",
        "language": "es"
    },
    {
        "word": "Soplagaitas",
        "language": "es"
    },
    {
        "word": "Soplapollas",
        "language": "es"
    },
    {
        "word": "Tetas grandes",
        "language": "es"
    },
    {
        "word": "Tía buena",
        "language": "es"
    },
    {
        "word": "Travesti",
        "language": "es"
    },
    {
        "word": "Trio",
        "language": "es"
    },
    {
        "word": "Verga",
        "language": "es"
    },
    {
        "word": "vete a la mierda",
        "language": "es"
    },
    {
        "word": "Vulva",
        "language": "es"
    },
    {
        "word": "آب کیر",
        "language": "fa"
    },
    {
        "word": "ارگاسم",
        "language": "fa"
    },
    {
        "word": "برهنه",
        "language": "fa"
    },
    {
        "word": "پورن",
        "language": "fa"
    },
    {
        "word": "پورنو",
        "language": "fa"
    },
    {
        "word": "تجاوز",
        "language": "fa"
    },
    {
        "word": "تخمی",
        "language": "fa"
    },
    {
        "word": "جق",
        "language": "fa"
    },
    {
        "word": "جقی",
        "language": "fa"
    },
    {
        "word": "جلق",
        "language": "fa"
    },
    {
        "word": "جنده",
        "language": "fa"
    },
    {
        "word": "چوچول",
        "language": "fa"
    },
    {
        "word": "حشر",
        "language": "fa"
    },
    {
        "word": "حشری",
        "language": "fa"
    },
    {
        "word": "داف",
        "language": "fa"
    },
    {
        "word": "دودول",
        "language": "fa"
    },
    {
        "word": "ساک زدن",
        "language": "fa"
    },
    {
        "word": "سکس",
        "language": "fa"
    },
    {
        "word": "سکس کردن",
        "language": "fa"
    },
    {
        "word": "سکسی",
        "language": "fa"
    },
    {
        "word": "سوپر",
        "language": "fa"
    },
    {
        "word": "شق کردن",
        "language": "fa"
    },
    {
        "word": "شهوت",
        "language": "fa"
    },
    {
        "word": "شهوتی",
        "language": "fa"
    },
    {
        "word": "شونبول",
        "language": "fa"
    },
    {
        "word": "فیلم سوپر",
        "language": "fa"
    },
    {
        "word": "کس",
        "language": "fa"
    },
    {
        "word": "کس دادن",
        "language": "fa"
    },
    {
        "word": "کس کردن",
        "language": "fa"
    },
    {
        "word": "کسکش",
        "language": "fa"
    },
    {
        "word": "کوس",
        "language": "fa"
    },
    {
        "word": "کون",
        "language": "fa"
    },
    {
        "word": "کون دادن",
        "language": "fa"
    },
    {
        "word": "کون کردن",
        "language": "fa"
    },
    {
        "word": "کونکش",
        "language": "fa"
    },
    {
        "word": "کونی",
        "language": "fa"
    },
    {
        "word": "کیر",
        "language": "fa"
    },
    {
        "word": "کیری",
        "language": "fa"
    },
    {
        "word": "لاپا",
        "language": "fa"
    },
    {
        "word": "لاپایی",
        "language": "fa"
    },
    {
        "word": "لاشی",
        "language": "fa"
    },
    {
        "word": "لخت",
        "language": "fa"
    },
    {
        "word": "لش",
        "language": "fa"
    },
    {
        "word": "منی",
        "language": "fa"
    },
    {
        "word": "هرزه",
        "language": "fa"
    },
    {
        "word": "alfred nussi",
        "language": "fi"
    },
    {
        "word": "bylsiä",
        "language": "fi"
    },
    {
        "word": "haahka",
        "language": "fi"
    },
    {
        "word": "haista paska",
        "language": "fi"
    },
    {
        "word": "haista vittu",
        "language": "fi"
    },
    {
        "word": "hatullinen",
        "language": "fi"
    },
    {
        "word": "helvetisti",
        "language": "fi"
    },
    {
        "word": "hevonkuusi",
        "language": "fi"
    },
    {
        "word": "hevonpaska",
        "language": "fi"
    },
    {
        "word": "hevonperse",
        "language": "fi"
    },
    {
        "word": "hevonvittu",
        "language": "fi"
    },
    {
        "word": "hevonvitunperse",
        "language": "fi"
    },
    {
        "word": "hitosti",
        "language": "fi"
    },
    {
        "word": "hitto",
        "language": "fi"
    },
    {
        "word": "huorata",
        "language": "fi"
    },
    {
        "word": "hässiä",
        "language": "fi"
    },
    {
        "word": "juosten kustu",
        "language": "fi"
    },
    {
        "word": "jutku",
        "language": "fi"
    },
    {
        "word": "jutsku",
        "language": "fi"
    },
    {
        "word": "jätkä",
        "language": "fi"
    },
    {
        "word": "kananpaska",
        "language": "fi"
    },
    {
        "word": "koiranpaska",
        "language": "fi"
    },
    {
        "word": "kuin esterin perseestä",
        "language": "fi"
    },
    {
        "word": "kulli",
        "language": "fi"
    },
    {
        "word": "kullinluikaus",
        "language": "fi"
    },
    {
        "word": "kuppainen",
        "language": "fi"
    },
    {
        "word": "kusaista",
        "language": "fi"
    },
    {
        "word": "kuseksia",
        "language": "fi"
    },
    {
        "word": "kusettaa",
        "language": "fi"
    },
    {
        "word": "kusi",
        "language": "fi"
    },
    {
        "word": "kusipää",
        "language": "fi"
    },
    {
        "word": "kusta",
        "language": "fi"
    },
    {
        "word": "kyrpiintynyt",
        "language": "fi"
    },
    {
        "word": "kyrpiintyä",
        "language": "fi"
    },
    {
        "word": "kyrpiä",
        "language": "fi"
    },
    {
        "word": "kyrpä",
        "language": "fi"
    },
    {
        "word": "kyrpänaama",
        "language": "fi"
    },
    {
        "word": "kyrvitys",
        "language": "fi"
    },
    {
        "word": "lahtari",
        "language": "fi"
    },
    {
        "word": "lutka",
        "language": "fi"
    },
    {
        "word": "molo",
        "language": "fi"
    },
    {
        "word": "molopää",
        "language": "fi"
    },
    {
        "word": "mulkero",
        "language": "fi"
    },
    {
        "word": "mulkku",
        "language": "fi"
    },
    {
        "word": "mulkvisti",
        "language": "fi"
    },
    {
        "word": "muna",
        "language": "fi"
    },
    {
        "word": "munapää",
        "language": "fi"
    },
    {
        "word": "munaton",
        "language": "fi"
    },
    {
        "word": "mutakuono",
        "language": "fi"
    },
    {
        "word": "mutiainen",
        "language": "fi"
    },
    {
        "word": "naida",
        "language": "fi"
    },
    {
        "word": "nainti",
        "language": "fi"
    },
    {
        "word": "narttu",
        "language": "fi"
    },
    {
        "word": "neekeri",
        "language": "fi"
    },
    {
        "word": "nekru",
        "language": "fi"
    },
    {
        "word": "nuolla persettä",
        "language": "fi"
    },
    {
        "word": "nussia",
        "language": "fi"
    },
    {
        "word": "nussija",
        "language": "fi"
    },
    {
        "word": "nussinta",
        "language": "fi"
    },
    {
        "word": "paljaalla",
        "language": "fi"
    },
    {
        "word": "palli",
        "language": "fi"
    },
    {
        "word": "pallit",
        "language": "fi"
    },
    {
        "word": "paneskella",
        "language": "fi"
    },
    {
        "word": "panettaa",
        "language": "fi"
    },
    {
        "word": "panna",
        "language": "fi"
    },
    {
        "word": "pano",
        "language": "fi"
    },
    {
        "word": "pantava",
        "language": "fi"
    },
    {
        "word": "paska",
        "language": "fi"
    },
    {
        "word": "paskainen",
        "language": "fi"
    },
    {
        "word": "paskamainen",
        "language": "fi"
    },
    {
        "word": "paskanmarjat",
        "language": "fi"
    },
    {
        "word": "paskantaa",
        "language": "fi"
    },
    {
        "word": "paskapuhe",
        "language": "fi"
    },
    {
        "word": "paskapää",
        "language": "fi"
    },
    {
        "word": "paskattaa",
        "language": "fi"
    },
    {
        "word": "paskiainen",
        "language": "fi"
    },
    {
        "word": "paskoa",
        "language": "fi"
    },
    {
        "word": "pehko",
        "language": "fi"
    },
    {
        "word": "pentele",
        "language": "fi"
    },
    {
        "word": "perkele",
        "language": "fi"
    },
    {
        "word": "perkeleesti",
        "language": "fi"
    },
    {
        "word": "persaukinen",
        "language": "fi"
    },
    {
        "word": "perse",
        "language": "fi"
    },
    {
        "word": "perseennuolija",
        "language": "fi"
    },
    {
        "word": "perseet olalla",
        "language": "fi"
    },
    {
        "word": "persereikä",
        "language": "fi"
    },
    {
        "word": "perseääliö",
        "language": "fi"
    },
    {
        "word": "persläpi",
        "language": "fi"
    },
    {
        "word": "perspano",
        "language": "fi"
    },
    {
        "word": "persvako",
        "language": "fi"
    },
    {
        "word": "pilkunnussija",
        "language": "fi"
    },
    {
        "word": "pillu",
        "language": "fi"
    },
    {
        "word": "pillut",
        "language": "fi"
    },
    {
        "word": "pipari",
        "language": "fi"
    },
    {
        "word": "piru",
        "language": "fi"
    },
    {
        "word": "pistää",
        "language": "fi"
    },
    {
        "word": "pyllyvako",
        "language": "fi"
    },
    {
        "word": "reikä",
        "language": "fi"
    },
    {
        "word": "reva",
        "language": "fi"
    },
    {
        "word": "ripsipiirakka",
        "language": "fi"
    },
    {
        "word": "runkata",
        "language": "fi"
    },
    {
        "word": "runkkari",
        "language": "fi"
    },
    {
        "word": "runkkaus",
        "language": "fi"
    },
    {
        "word": "runkku",
        "language": "fi"
    },
    {
        "word": "ryssä",
        "language": "fi"
    },
    {
        "word": "rättipää",
        "language": "fi"
    },
    {
        "word": "saatanasti",
        "language": "fi"
    },
    {
        "word": "suklaaosasto",
        "language": "fi"
    },
    {
        "word": "tavara",
        "language": "fi"
    },
    {
        "word": "toosa",
        "language": "fi"
    },
    {
        "word": "tuhkaluukku",
        "language": "fi"
    },
    {
        "word": "tumputtaa",
        "language": "fi"
    },
    {
        "word": "turpasauna",
        "language": "fi"
    },
    {
        "word": "tussu",
        "language": "fi"
    },
    {
        "word": "tussukka",
        "language": "fi"
    },
    {
        "word": "tussut",
        "language": "fi"
    },
    {
        "word": "vakipano",
        "language": "fi"
    },
    {
        "word": "vetää käteen",
        "language": "fi"
    },
    {
        "word": "viiksi",
        "language": "fi"
    },
    {
        "word": "vittu",
        "language": "fi"
    },
    {
        "word": "vittuilla",
        "language": "fi"
    },
    {
        "word": "vittuilu",
        "language": "fi"
    },
    {
        "word": "vittumainen",
        "language": "fi"
    },
    {
        "word": "vittuuntua",
        "language": "fi"
    },
    {
        "word": "vittuuntunut",
        "language": "fi"
    },
    {
        "word": "vitun",
        "language": "fi"
    },
    {
        "word": "vitusti",
        "language": "fi"
    },
    {
        "word": "vituttaa",
        "language": "fi"
    },
    {
        "word": "vitutus",
        "language": "fi"
    },
    {
        "word": "äpärä",
        "language": "fi"
    },
    {
        "word": "baiser",
        "language": "fr"
    },
    {
        "word": "bander",
        "language": "fr"
    },
    {
        "word": "bigornette",
        "language": "fr"
    },
    {
        "word": "bite",
        "language": "fr"
    },
    {
        "word": "bitte",
        "language": "fr"
    },
    {
        "word": "bloblos",
        "language": "fr"
    },
    {
        "word": "bosser",
        "language": "fr"
    },
    {
        "word": "bourré",
        "language": "fr"
    },
    {
        "word": "bourrée",
        "language": "fr"
    },
    {
        "word": "brackmard",
        "language": "fr"
    },
    {
        "word": "branlage",
        "language": "fr"
    },
    {
        "word": "branler",
        "language": "fr"
    },
    {
        "word": "branlette",
        "language": "fr"
    },
    {
        "word": "branleur",
        "language": "fr"
    },
    {
        "word": "branleuse",
        "language": "fr"
    },
    {
        "word": "brouter le cresson",
        "language": "fr"
    },
    {
        "word": "cailler",
        "language": "fr"
    },
    {
        "word": "chatte",
        "language": "fr"
    },
    {
        "word": "chiasse",
        "language": "fr"
    },
    {
        "word": "chier",
        "language": "fr"
    },
    {
        "word": "chiottes",
        "language": "fr"
    },
    {
        "word": "clito",
        "language": "fr"
    },
    {
        "word": "con",
        "language": "fr"
    },
    {
        "word": "connard",
        "language": "fr"
    },
    {
        "word": "connasse",
        "language": "fr"
    },
    {
        "word": "conne",
        "language": "fr"
    },
    {
        "word": "couilles",
        "language": "fr"
    },
    {
        "word": "cramouille",
        "language": "fr"
    },
    {
        "word": "cul",
        "language": "fr"
    },
    {
        "word": "déconne",
        "language": "fr"
    },
    {
        "word": "déconner",
        "language": "fr"
    },
    {
        "word": "drague",
        "language": "fr"
    },
    {
        "word": "emmerdant",
        "language": "fr"
    },
    {
        "word": "emmerder",
        "language": "fr"
    },
    {
        "word": "emmerdeur",
        "language": "fr"
    },
    {
        "word": "emmerdeuse",
        "language": "fr"
    },
    {
        "word": "enculé",
        "language": "fr"
    },
    {
        "word": "enculée",
        "language": "fr"
    },
    {
        "word": "enculeur",
        "language": "fr"
    },
    {
        "word": "enculeurs",
        "language": "fr"
    },
    {
        "word": "enfoiré",
        "language": "fr"
    },
    {
        "word": "enfoirée",
        "language": "fr"
    },
    {
        "word": "étron",
        "language": "fr"
    },
    {
        "word": "fille de pute",
        "language": "fr"
    },
    {
        "word": "fils de pute",
        "language": "fr"
    },
    {
        "word": "folle",
        "language": "fr"
    },
    {
        "word": "foutre",
        "language": "fr"
    },
    {
        "word": "gerbe",
        "language": "fr"
    },
    {
        "word": "gerber",
        "language": "fr"
    },
    {
        "word": "gouine",
        "language": "fr"
    },
    {
        "word": "grande folle",
        "language": "fr"
    },
    {
        "word": "grogniasse",
        "language": "fr"
    },
    {
        "word": "gueule",
        "language": "fr"
    },
    {
        "word": "jouir",
        "language": "fr"
    },
    {
        "word": "la putain de ta mère",
        "language": "fr"
    },
    {
        "word": "MALPT",
        "language": "fr"
    },
    {
        "word": "ménage à trois",
        "language": "fr"
    },
    {
        "word": "merde",
        "language": "fr"
    },
    {
        "word": "merdeuse",
        "language": "fr"
    },
    {
        "word": "merdeux",
        "language": "fr"
    },
    {
        "word": "meuf",
        "language": "fr"
    },
    {
        "word": "nègre",
        "language": "fr"
    },
    {
        "word": "nique ta mère",
        "language": "fr"
    },
    {
        "word": "nique ta race",
        "language": "fr"
    },
    {
        "word": "palucher",
        "language": "fr"
    },
    {
        "word": "pédale",
        "language": "fr"
    },
    {
        "word": "pédé",
        "language": "fr"
    },
    {
        "word": "péter",
        "language": "fr"
    },
    {
        "word": "pipi",
        "language": "fr"
    },
    {
        "word": "pouffiasse",
        "language": "fr"
    },
    {
        "word": "pousse-crotte",
        "language": "fr"
    },
    {
        "word": "putain",
        "language": "fr"
    },
    {
        "word": "pute",
        "language": "fr"
    },
    {
        "word": "ramoner",
        "language": "fr"
    },
    {
        "word": "sac à merde",
        "language": "fr"
    },
    {
        "word": "salaud",
        "language": "fr"
    },
    {
        "word": "salope",
        "language": "fr"
    },
    {
        "word": "suce",
        "language": "fr"
    },
    {
        "word": "tapette",
        "language": "fr"
    },
    {
        "word": "teuf",
        "language": "fr"
    },
    {
        "word": "tringler",
        "language": "fr"
    },
    {
        "word": "trique",
        "language": "fr"
    },
    {
        "word": "trou du cul",
        "language": "fr"
    },
    {
        "word": "turlute",
        "language": "fr"
    },
    {
        "word": "veuve",
        "language": "fr"
    },
    {
        "word": "zigounette",
        "language": "fr"
    },
    {
        "word": "zizi",
        "language": "fr"
    },
    {
        "word": "aand",
        "language": "hi"
    },
    {
        "word": "aandu",
        "language": "hi"
    },
    {
        "word": "balatkar",
        "language": "hi"
    },
    {
        "word": "beti chod",
        "language": "hi"
    },
    {
        "word": "bhadva",
        "language": "hi"
    },
    {
        "word": "bhadve",
        "language": "hi"
    },
    {
        "word": "bhandve",
        "language": "hi"
    },
    {
        "word": "bhootni ke",
        "language": "hi"
    },
    {
        "word": "bhosad",
        "language": "hi"
    },
    {
        "word": "bhosadi ke",
        "language": "hi"
    },
    {
        "word": "boobe",
        "language": "hi"
    },
    {
        "word": "chakke",
        "language": "hi"
    },
    {
        "word": "chinaal",
        "language": "hi"
    },
    {
        "word": "chinki",
        "language": "hi"
    },
    {
        "word": "chod",
        "language": "hi"
    },
    {
        "word": "chodu",
        "language": "hi"
    },
    {
        "word": "chodu bhagat",
        "language": "hi"
    },
    {
        "word": "chooche",
        "language": "hi"
    },
    {
        "word": "choochi",
        "language": "hi"
    },
    {
        "word": "choot",
        "language": "hi"
    },
    {
        "word": "choot ke baal",
        "language": "hi"
    },
    {
        "word": "chootia",
        "language": "hi"
    },
    {
        "word": "chootiya",
        "language": "hi"
    },
    {
        "word": "chuche",
        "language": "hi"
    },
    {
        "word": "chuchi",
        "language": "hi"
    },
    {
        "word": "chudai khanaa",
        "language": "hi"
    },
    {
        "word": "chudan chudai",
        "language": "hi"
    },
    {
        "word": "chut",
        "language": "hi"
    },
    {
        "word": "chut ke baal",
        "language": "hi"
    },
    {
        "word": "chut ke dhakkan",
        "language": "hi"
    },
    {
        "word": "chut maarli",
        "language": "hi"
    },
    {
        "word": "chutad",
        "language": "hi"
    },
    {
        "word": "chutadd",
        "language": "hi"
    },
    {
        "word": "chutan",
        "language": "hi"
    },
    {
        "word": "chutia",
        "language": "hi"
    },
    {
        "word": "chutiya",
        "language": "hi"
    },
    {
        "word": "gaand",
        "language": "hi"
    },
    {
        "word": "gaandfat",
        "language": "hi"
    },
    {
        "word": "gaandmasti",
        "language": "hi"
    },
    {
        "word": "gaandufad",
        "language": "hi"
    },
    {
        "word": "gandu",
        "language": "hi"
    },
    {
        "word": "gashti",
        "language": "hi"
    },
    {
        "word": "gasti",
        "language": "hi"
    },
    {
        "word": "ghassa",
        "language": "hi"
    },
    {
        "word": "ghasti",
        "language": "hi"
    },
    {
        "word": "harami",
        "language": "hi"
    },
    {
        "word": "haramzade",
        "language": "hi"
    },
    {
        "word": "hawas",
        "language": "hi"
    },
    {
        "word": "hawas ke pujari",
        "language": "hi"
    },
    {
        "word": "hijda",
        "language": "hi"
    },
    {
        "word": "hijra",
        "language": "hi"
    },
    {
        "word": "jhant",
        "language": "hi"
    },
    {
        "word": "jhant chaatu",
        "language": "hi"
    },
    {
        "word": "jhant ke baal",
        "language": "hi"
    },
    {
        "word": "jhantu",
        "language": "hi"
    },
    {
        "word": "kamine",
        "language": "hi"
    },
    {
        "word": "kaminey",
        "language": "hi"
    },
    {
        "word": "kanjar",
        "language": "hi"
    },
    {
        "word": "kutta",
        "language": "hi"
    },
    {
        "word": "kutta kamina",
        "language": "hi"
    },
    {
        "word": "kutte ki aulad",
        "language": "hi"
    },
    {
        "word": "kutte ki jat",
        "language": "hi"
    },
    {
        "word": "kuttiya",
        "language": "hi"
    },
    {
        "word": "loda",
        "language": "hi"
    },
    {
        "word": "lodu",
        "language": "hi"
    },
    {
        "word": "lund",
        "language": "hi"
    },
    {
        "word": "lund choos",
        "language": "hi"
    },
    {
        "word": "lund khajoor",
        "language": "hi"
    },
    {
        "word": "lundtopi",
        "language": "hi"
    },
    {
        "word": "lundure",
        "language": "hi"
    },
    {
        "word": "maa ki chut",
        "language": "hi"
    },
    {
        "word": "maal",
        "language": "hi"
    },
    {
        "word": "madar chod",
        "language": "hi"
    },
    {
        "word": "mooh mein le",
        "language": "hi"
    },
    {
        "word": "mutth",
        "language": "hi"
    },
    {
        "word": "najayaz",
        "language": "hi"
    },
    {
        "word": "najayaz aulaad",
        "language": "hi"
    },
    {
        "word": "najayaz paidaish",
        "language": "hi"
    },
    {
        "word": "paki",
        "language": "hi"
    },
    {
        "word": "pataka",
        "language": "hi"
    },
    {
        "word": "patakha",
        "language": "hi"
    },
    {
        "word": "raand",
        "language": "hi"
    },
    {
        "word": "randi",
        "language": "hi"
    },
    {
        "word": "saala",
        "language": "hi"
    },
    {
        "word": "saala kutta",
        "language": "hi"
    },
    {
        "word": "saali kutti",
        "language": "hi"
    },
    {
        "word": "saali randi",
        "language": "hi"
    },
    {
        "word": "suar",
        "language": "hi"
    },
    {
        "word": "suar ki aulad",
        "language": "hi"
    },
    {
        "word": "tatte",
        "language": "hi"
    },
    {
        "word": "tatti",
        "language": "hi"
    },
    {
        "word": "teri maa ka bhosada",
        "language": "hi"
    },
    {
        "word": "teri maa ka boba chusu",
        "language": "hi"
    },
    {
        "word": "teri maa ki chut",
        "language": "hi"
    },
    {
        "word": "tharak",
        "language": "hi"
    },
    {
        "word": "tharki",
        "language": "hi"
    },
    {
        "word": "balfasz",
        "language": "hu"
    },
    {
        "word": "balfaszok",
        "language": "hu"
    },
    {
        "word": "balfaszokat",
        "language": "hu"
    },
    {
        "word": "balfaszt",
        "language": "hu"
    },
    {
        "word": "barmok",
        "language": "hu"
    },
    {
        "word": "barmokat",
        "language": "hu"
    },
    {
        "word": "barmot",
        "language": "hu"
    },
    {
        "word": "barom",
        "language": "hu"
    },
    {
        "word": "baszik",
        "language": "hu"
    },
    {
        "word": "bazmeg",
        "language": "hu"
    },
    {
        "word": "buksza",
        "language": "hu"
    },
    {
        "word": "bukszák",
        "language": "hu"
    },
    {
        "word": "bukszákat",
        "language": "hu"
    },
    {
        "word": "bukszát",
        "language": "hu"
    },
    {
        "word": "búr",
        "language": "hu"
    },
    {
        "word": "búrok",
        "language": "hu"
    },
    {
        "word": "csöcs",
        "language": "hu"
    },
    {
        "word": "csöcsök",
        "language": "hu"
    },
    {
        "word": "csöcsöket",
        "language": "hu"
    },
    {
        "word": "csöcsöt",
        "language": "hu"
    },
    {
        "word": "fasz",
        "language": "hu"
    },
    {
        "word": "faszfej",
        "language": "hu"
    },
    {
        "word": "faszfejek",
        "language": "hu"
    },
    {
        "word": "faszfejeket",
        "language": "hu"
    },
    {
        "word": "faszfejet",
        "language": "hu"
    },
    {
        "word": "faszok",
        "language": "hu"
    },
    {
        "word": "faszokat",
        "language": "hu"
    },
    {
        "word": "faszt",
        "language": "hu"
    },
    {
        "word": "fing",
        "language": "hu"
    },
    {
        "word": "fingok",
        "language": "hu"
    },
    {
        "word": "fingokat",
        "language": "hu"
    },
    {
        "word": "fingot",
        "language": "hu"
    },
    {
        "word": "franc",
        "language": "hu"
    },
    {
        "word": "francok",
        "language": "hu"
    },
    {
        "word": "francokat",
        "language": "hu"
    },
    {
        "word": "francot",
        "language": "hu"
    },
    {
        "word": "geci",
        "language": "hu"
    },
    {
        "word": "gecibb",
        "language": "hu"
    },
    {
        "word": "gecik",
        "language": "hu"
    },
    {
        "word": "geciket",
        "language": "hu"
    },
    {
        "word": "gecit",
        "language": "hu"
    },
    {
        "word": "kibaszott",
        "language": "hu"
    },
    {
        "word": "kibaszottabb",
        "language": "hu"
    },
    {
        "word": "kúr",
        "language": "hu"
    },
    {
        "word": "kurafi",
        "language": "hu"
    },
    {
        "word": "kurafik",
        "language": "hu"
    },
    {
        "word": "kurafikat",
        "language": "hu"
    },
    {
        "word": "kurafit",
        "language": "hu"
    },
    {
        "word": "kurvák",
        "language": "hu"
    },
    {
        "word": "kurvákat",
        "language": "hu"
    },
    {
        "word": "kurvát",
        "language": "hu"
    },
    {
        "word": "leggecibb",
        "language": "hu"
    },
    {
        "word": "legkibaszottabb",
        "language": "hu"
    },
    {
        "word": "legszarabb",
        "language": "hu"
    },
    {
        "word": "marha",
        "language": "hu"
    },
    {
        "word": "marhák",
        "language": "hu"
    },
    {
        "word": "marhákat",
        "language": "hu"
    },
    {
        "word": "marhát",
        "language": "hu"
    },
    {
        "word": "megdöglik",
        "language": "hu"
    },
    {
        "word": "pele",
        "language": "hu"
    },
    {
        "word": "pelék",
        "language": "hu"
    },
    {
        "word": "picsa",
        "language": "hu"
    },
    {
        "word": "picsákat",
        "language": "hu"
    },
    {
        "word": "picsát",
        "language": "hu"
    },
    {
        "word": "pina",
        "language": "hu"
    },
    {
        "word": "pinák",
        "language": "hu"
    },
    {
        "word": "pinákat",
        "language": "hu"
    },
    {
        "word": "pinát",
        "language": "hu"
    },
    {
        "word": "pofa",
        "language": "hu"
    },
    {
        "word": "pofákat",
        "language": "hu"
    },
    {
        "word": "pofát",
        "language": "hu"
    },
    {
        "word": "pöcs",
        "language": "hu"
    },
    {
        "word": "pöcsök",
        "language": "hu"
    },
    {
        "word": "pöcsöket",
        "language": "hu"
    },
    {
        "word": "pöcsöt",
        "language": "hu"
    },
    {
        "word": "punci",
        "language": "hu"
    },
    {
        "word": "puncik",
        "language": "hu"
    },
    {
        "word": "segg",
        "language": "hu"
    },
    {
        "word": "seggek",
        "language": "hu"
    },
    {
        "word": "seggeket",
        "language": "hu"
    },
    {
        "word": "segget",
        "language": "hu"
    },
    {
        "word": "seggfej",
        "language": "hu"
    },
    {
        "word": "seggfejek",
        "language": "hu"
    },
    {
        "word": "seggfejeket",
        "language": "hu"
    },
    {
        "word": "seggfejet",
        "language": "hu"
    },
    {
        "word": "szajha",
        "language": "hu"
    },
    {
        "word": "szajhák",
        "language": "hu"
    },
    {
        "word": "szajhákat",
        "language": "hu"
    },
    {
        "word": "szajhát",
        "language": "hu"
    },
    {
        "word": "szar",
        "language": "hu"
    },
    {
        "word": "szarabb",
        "language": "hu"
    },
    {
        "word": "szarik",
        "language": "hu"
    },
    {
        "word": "szarok",
        "language": "hu"
    },
    {
        "word": "szarokat",
        "language": "hu"
    },
    {
        "word": "szart",
        "language": "hu"
    },
    {
        "word": "allupato",
        "language": "it"
    },
    {
        "word": "ammucchiata",
        "language": "it"
    },
    {
        "word": "anale",
        "language": "it"
    },
    {
        "word": "arrapato",
        "language": "it"
    },
    {
        "word": "arrusa",
        "language": "it"
    },
    {
        "word": "arruso",
        "language": "it"
    },
    {
        "word": "assatanato",
        "language": "it"
    },
    {
        "word": "bagascia",
        "language": "it"
    },
    {
        "word": "bagassa",
        "language": "it"
    },
    {
        "word": "bagnarsi",
        "language": "it"
    },
    {
        "word": "baldracca",
        "language": "it"
    },
    {
        "word": "balle",
        "language": "it"
    },
    {
        "word": "battere",
        "language": "it"
    },
    {
        "word": "battona",
        "language": "it"
    },
    {
        "word": "belino",
        "language": "it"
    },
    {
        "word": "biga",
        "language": "it"
    },
    {
        "word": "bocchinara",
        "language": "it"
    },
    {
        "word": "bocchino",
        "language": "it"
    },
    {
        "word": "bofilo",
        "language": "it"
    },
    {
        "word": "boiata",
        "language": "it"
    },
    {
        "word": "bordello",
        "language": "it"
    },
    {
        "word": "brinca",
        "language": "it"
    },
    {
        "word": "bucaiolo",
        "language": "it"
    },
    {
        "word": "budiùlo",
        "language": "it"
    },
    {
        "word": "buona donna",
        "language": "it"
    },
    {
        "word": "busone",
        "language": "it"
    },
    {
        "word": "cacca",
        "language": "it"
    },
    {
        "word": "caccati in mano e prenditi a schiaffi",
        "language": "it"
    },
    {
        "word": "caciocappella",
        "language": "it"
    },
    {
        "word": "cadavere",
        "language": "it"
    },
    {
        "word": "cagare",
        "language": "it"
    },
    {
        "word": "cagata",
        "language": "it"
    },
    {
        "word": "cagna",
        "language": "it"
    },
    {
        "word": "cammello",
        "language": "it"
    },
    {
        "word": "cappella",
        "language": "it"
    },
    {
        "word": "carciofo",
        "language": "it"
    },
    {
        "word": "carità",
        "language": "it"
    },
    {
        "word": "casci",
        "language": "it"
    },
    {
        "word": "cazzata",
        "language": "it"
    },
    {
        "word": "cazzimma",
        "language": "it"
    },
    {
        "word": "cazzo",
        "language": "it"
    },
    {
        "word": "checca",
        "language": "it"
    },
    {
        "word": "chiappa",
        "language": "it"
    },
    {
        "word": "chiavare",
        "language": "it"
    },
    {
        "word": "chiavata",
        "language": "it"
    },
    {
        "word": "ciospo",
        "language": "it"
    },
    {
        "word": "ciucciami il cazzo",
        "language": "it"
    },
    {
        "word": "coglione",
        "language": "it"
    },
    {
        "word": "coglioni",
        "language": "it"
    },
    {
        "word": "cornuto",
        "language": "it"
    },
    {
        "word": "cozza",
        "language": "it"
    },
    {
        "word": "culattina",
        "language": "it"
    },
    {
        "word": "culattone",
        "language": "it"
    },
    {
        "word": "di merda",
        "language": "it"
    },
    {
        "word": "ditalino",
        "language": "it"
    },
    {
        "word": "duro",
        "language": "it"
    },
    {
        "word": "fare unaŠ",
        "language": "it"
    },
    {
        "word": "fava",
        "language": "it"
    },
    {
        "word": "femminuccia",
        "language": "it"
    },
    {
        "word": "fica",
        "language": "it"
    },
    {
        "word": "figa",
        "language": "it"
    },
    {
        "word": "figlio di buona donna",
        "language": "it"
    },
    {
        "word": "figlio di puttana",
        "language": "it"
    },
    {
        "word": "figone",
        "language": "it"
    },
    {
        "word": "finocchio",
        "language": "it"
    },
    {
        "word": "fottere",
        "language": "it"
    },
    {
        "word": "fottersi",
        "language": "it"
    },
    {
        "word": "fracicone",
        "language": "it"
    },
    {
        "word": "fregna",
        "language": "it"
    },
    {
        "word": "frocio",
        "language": "it"
    },
    {
        "word": "froscio",
        "language": "it"
    },
    {
        "word": "fuori come un balcone",
        "language": "it"
    },
    {
        "word": "goldone",
        "language": "it"
    },
    {
        "word": "grilletto",
        "language": "it"
    },
    {
        "word": "guanto",
        "language": "it"
    },
    {
        "word": "guardone",
        "language": "it"
    },
    {
        "word": "incazzarsi",
        "language": "it"
    },
    {
        "word": "incoglionirsi",
        "language": "it"
    },
    {
        "word": "ingoio",
        "language": "it"
    },
    {
        "word": "l'arte bolognese",
        "language": "it"
    },
    {
        "word": "leccaculo",
        "language": "it"
    },
    {
        "word": "lecchino",
        "language": "it"
    },
    {
        "word": "lofare",
        "language": "it"
    },
    {
        "word": "loffa",
        "language": "it"
    },
    {
        "word": "loffare",
        "language": "it"
    },
    {
        "word": "lumaca",
        "language": "it"
    },
    {
        "word": "manico",
        "language": "it"
    },
    {
        "word": "mannaggia",
        "language": "it"
    },
    {
        "word": "merda",
        "language": "it"
    },
    {
        "word": "merdata",
        "language": "it"
    },
    {
        "word": "merdoso",
        "language": "it"
    },
    {
        "word": "mignotta",
        "language": "it"
    },
    {
        "word": "minchia",
        "language": "it"
    },
    {
        "word": "minchione",
        "language": "it"
    },
    {
        "word": "mona",
        "language": "it"
    },
    {
        "word": "monta",
        "language": "it"
    },
    {
        "word": "montare",
        "language": "it"
    },
    {
        "word": "mussa",
        "language": "it"
    },
    {
        "word": "nave scuola",
        "language": "it"
    },
    {
        "word": "nerchia",
        "language": "it"
    },
    {
        "word": "nudo",
        "language": "it"
    },
    {
        "word": "padulo",
        "language": "it"
    },
    {
        "word": "palle",
        "language": "it"
    },
    {
        "word": "palloso",
        "language": "it"
    },
    {
        "word": "patacca",
        "language": "it"
    },
    {
        "word": "patonza",
        "language": "it"
    },
    {
        "word": "pecorina",
        "language": "it"
    },
    {
        "word": "pesce",
        "language": "it"
    },
    {
        "word": "picio",
        "language": "it"
    },
    {
        "word": "pincare",
        "language": "it"
    },
    {
        "word": "pipa",
        "language": "it"
    },
    {
        "word": "pippone",
        "language": "it"
    },
    {
        "word": "pirla",
        "language": "it"
    },
    {
        "word": "pisciare",
        "language": "it"
    },
    {
        "word": "piscio",
        "language": "it"
    },
    {
        "word": "pisello",
        "language": "it"
    },
    {
        "word": "pistola",
        "language": "it"
    },
    {
        "word": "pistolotto",
        "language": "it"
    },
    {
        "word": "pomiciare",
        "language": "it"
    },
    {
        "word": "pompa",
        "language": "it"
    },
    {
        "word": "pompino",
        "language": "it"
    },
    {
        "word": "porca",
        "language": "it"
    },
    {
        "word": "porca madonna",
        "language": "it"
    },
    {
        "word": "porca miseria",
        "language": "it"
    },
    {
        "word": "porca puttana",
        "language": "it"
    },
    {
        "word": "porco due",
        "language": "it"
    },
    {
        "word": "porco zio",
        "language": "it"
    },
    {
        "word": "potta",
        "language": "it"
    },
    {
        "word": "puppami",
        "language": "it"
    },
    {
        "word": "puttana",
        "language": "it"
    },
    {
        "word": "quaglia",
        "language": "it"
    },
    {
        "word": "recchione",
        "language": "it"
    },
    {
        "word": "regina",
        "language": "it"
    },
    {
        "word": "rincoglionire",
        "language": "it"
    },
    {
        "word": "rizzarsi",
        "language": "it"
    },
    {
        "word": "rompiballe",
        "language": "it"
    },
    {
        "word": "ruffiano",
        "language": "it"
    },
    {
        "word": "sbattere",
        "language": "it"
    },
    {
        "word": "sbattersi",
        "language": "it"
    },
    {
        "word": "sborra",
        "language": "it"
    },
    {
        "word": "sborrata",
        "language": "it"
    },
    {
        "word": "sborrone",
        "language": "it"
    },
    {
        "word": "sbrodolata",
        "language": "it"
    },
    {
        "word": "scopare",
        "language": "it"
    },
    {
        "word": "scopata",
        "language": "it"
    },
    {
        "word": "scorreggiare",
        "language": "it"
    },
    {
        "word": "sega",
        "language": "it"
    },
    {
        "word": "slinguare",
        "language": "it"
    },
    {
        "word": "slinguata",
        "language": "it"
    },
    {
        "word": "smandrappata",
        "language": "it"
    },
    {
        "word": "soccia",
        "language": "it"
    },
    {
        "word": "socmel",
        "language": "it"
    },
    {
        "word": "sorca",
        "language": "it"
    },
    {
        "word": "spagnola",
        "language": "it"
    },
    {
        "word": "spompinare",
        "language": "it"
    },
    {
        "word": "sticchio",
        "language": "it"
    },
    {
        "word": "stronza",
        "language": "it"
    },
    {
        "word": "stronzata",
        "language": "it"
    },
    {
        "word": "stronzo",
        "language": "it"
    },
    {
        "word": "succhiami",
        "language": "it"
    },
    {
        "word": "sveltina",
        "language": "it"
    },
    {
        "word": "sverginare",
        "language": "it"
    },
    {
        "word": "tarzanello",
        "language": "it"
    },
    {
        "word": "terrone",
        "language": "it"
    },
    {
        "word": "testa di cazzo",
        "language": "it"
    },
    {
        "word": "tette",
        "language": "it"
    },
    {
        "word": "tirare",
        "language": "it"
    },
    {
        "word": "topa",
        "language": "it"
    },
    {
        "word": "troia",
        "language": "it"
    },
    {
        "word": "trombare",
        "language": "it"
    },
    {
        "word": "uccello",
        "language": "it"
    },
    {
        "word": "vacca",
        "language": "it"
    },
    {
        "word": "vaffanculo",
        "language": "it"
    },
    {
        "word": "vangare",
        "language": "it"
    },
    {
        "word": "venire",
        "language": "it"
    },
    {
        "word": "zinne",
        "language": "it"
    },
    {
        "word": "zio cantante",
        "language": "it"
    },
    {
        "word": "zoccola",
        "language": "it"
    },
    {
        "word": "3p",
        "language": "ja"
    },
    {
        "word": "g スポット",
        "language": "ja"
    },
    {
        "word": "s ＆ m",
        "language": "ja"
    },
    {
        "word": "sm",
        "language": "ja"
    },
    {
        "word": "sm女王",
        "language": "ja"
    },
    {
        "word": "xx",
        "language": "ja"
    },
    {
        "word": "アジアのかわいい女の子",
        "language": "ja"
    },
    {
        "word": "アスホール",
        "language": "ja"
    },
    {
        "word": "アナリングス",
        "language": "ja"
    },
    {
        "word": "アナル",
        "language": "ja"
    },
    {
        "word": "いたずら",
        "language": "ja"
    },
    {
        "word": "イラマチオ",
        "language": "ja"
    },
    {
        "word": "ウェブカメラ",
        "language": "ja"
    },
    {
        "word": "エクスタシー",
        "language": "ja"
    },
    {
        "word": "エスコート",
        "language": "ja"
    },
    {
        "word": "エッチ",
        "language": "ja"
    },
    {
        "word": "エロティズム",
        "language": "ja"
    },
    {
        "word": "エロティック",
        "language": "ja"
    },
    {
        "word": "オーガズム",
        "language": "ja"
    },
    {
        "word": "オカマ",
        "language": "ja"
    },
    {
        "word": "おしっこ",
        "language": "ja"
    },
    {
        "word": "おしり",
        "language": "ja"
    },
    {
        "word": "オシリ",
        "language": "ja"
    },
    {
        "word": "おしりのあな",
        "language": "ja"
    },
    {
        "word": "おっぱい",
        "language": "ja"
    },
    {
        "word": "オッパイ",
        "language": "ja"
    },
    {
        "word": "オナニー",
        "language": "ja"
    },
    {
        "word": "オマンコ",
        "language": "ja"
    },
    {
        "word": "おもらし",
        "language": "ja"
    },
    {
        "word": "お尻",
        "language": "ja"
    },
    {
        "word": "カーマスートラ",
        "language": "ja"
    },
    {
        "word": "カント",
        "language": "ja"
    },
    {
        "word": "クリトリス",
        "language": "ja"
    },
    {
        "word": "グループ・セックス",
        "language": "ja"
    },
    {
        "word": "グロ",
        "language": "ja"
    },
    {
        "word": "クンニリングス",
        "language": "ja"
    },
    {
        "word": "ゲイ・セックス",
        "language": "ja"
    },
    {
        "word": "ゲイの男性",
        "language": "ja"
    },
    {
        "word": "ゲイボーイ",
        "language": "ja"
    },
    {
        "word": "ゴールデンシャワー",
        "language": "ja"
    },
    {
        "word": "コカイン",
        "language": "ja"
    },
    {
        "word": "ゴックン",
        "language": "ja"
    },
    {
        "word": "サディズム",
        "language": "ja"
    },
    {
        "word": "しばり",
        "language": "ja"
    },
    {
        "word": "スウィンガー",
        "language": "ja"
    },
    {
        "word": "スカートの中",
        "language": "ja"
    },
    {
        "word": "スカトロ",
        "language": "ja"
    },
    {
        "word": "ストラップオン",
        "language": "ja"
    },
    {
        "word": "ストリップ劇場",
        "language": "ja"
    },
    {
        "word": "スラット",
        "language": "ja"
    },
    {
        "word": "スリット",
        "language": "ja"
    },
    {
        "word": "セクシーな",
        "language": "ja"
    },
    {
        "word": "セクシーな 10 代",
        "language": "ja"
    },
    {
        "word": "セックス",
        "language": "ja"
    },
    {
        "word": "ソドミー",
        "language": "ja"
    },
    {
        "word": "ちんこ",
        "language": "ja"
    },
    {
        "word": "ディープ・スロート",
        "language": "ja"
    },
    {
        "word": "ディック",
        "language": "ja"
    },
    {
        "word": "ディルド",
        "language": "ja"
    },
    {
        "word": "デートレイプ",
        "language": "ja"
    },
    {
        "word": "デブ",
        "language": "ja"
    },
    {
        "word": "テレフォンセックス",
        "language": "ja"
    },
    {
        "word": "ドッグスタイル",
        "language": "ja"
    },
    {
        "word": "トップレス",
        "language": "ja"
    },
    {
        "word": "なめ",
        "language": "ja"
    },
    {
        "word": "ニガー",
        "language": "ja"
    },
    {
        "word": "ヌード",
        "language": "ja"
    },
    {
        "word": "ネオ・ナチ",
        "language": "ja"
    },
    {
        "word": "ハードコア",
        "language": "ja"
    },
    {
        "word": "パイパン",
        "language": "ja"
    },
    {
        "word": "バイブレーター",
        "language": "ja"
    },
    {
        "word": "バック・スタイル",
        "language": "ja"
    },
    {
        "word": "パンティー",
        "language": "ja"
    },
    {
        "word": "ビッチ",
        "language": "ja"
    },
    {
        "word": "ファック",
        "language": "ja"
    },
    {
        "word": "ファンタジー",
        "language": "ja"
    },
    {
        "word": "フィスト",
        "language": "ja"
    },
    {
        "word": "フェティッシュ",
        "language": "ja"
    },
    {
        "word": "フェラチオ",
        "language": "ja"
    },
    {
        "word": "ふたなり",
        "language": "ja"
    },
    {
        "word": "ぶっかけ",
        "language": "ja"
    },
    {
        "word": "フック",
        "language": "ja"
    },
    {
        "word": "プリンス アルバート ピアス",
        "language": "ja"
    },
    {
        "word": "プレイボーイ",
        "language": "ja"
    },
    {
        "word": "ベアバック",
        "language": "ja"
    },
    {
        "word": "ペニス",
        "language": "ja"
    },
    {
        "word": "ペニスバンド",
        "language": "ja"
    },
    {
        "word": "ボーイズラブ",
        "language": "ja"
    },
    {
        "word": "ボールギャグ",
        "language": "ja"
    },
    {
        "word": "ボールを蹴る",
        "language": "ja"
    },
    {
        "word": "ぽっちゃり",
        "language": "ja"
    },
    {
        "word": "ホモ",
        "language": "ja"
    },
    {
        "word": "ポルノ",
        "language": "ja"
    },
    {
        "word": "ポルノグラフィー",
        "language": "ja"
    },
    {
        "word": "ボンテージ",
        "language": "ja"
    },
    {
        "word": "マザー・ファッカー",
        "language": "ja"
    },
    {
        "word": "マスターベーション",
        "language": "ja"
    },
    {
        "word": "まんこ",
        "language": "ja"
    },
    {
        "word": "やおい",
        "language": "ja"
    },
    {
        "word": "やりまん",
        "language": "ja"
    },
    {
        "word": "ユダヤ人",
        "language": "ja"
    },
    {
        "word": "ラティーナ",
        "language": "ja"
    },
    {
        "word": "ラバー",
        "language": "ja"
    },
    {
        "word": "ランジェリー",
        "language": "ja"
    },
    {
        "word": "レイプ",
        "language": "ja"
    },
    {
        "word": "レズビアン",
        "language": "ja"
    },
    {
        "word": "ローター",
        "language": "ja"
    },
    {
        "word": "ロリータ",
        "language": "ja"
    },
    {
        "word": "淫乱",
        "language": "ja"
    },
    {
        "word": "陰毛",
        "language": "ja"
    },
    {
        "word": "革抑制",
        "language": "ja"
    },
    {
        "word": "騎上位",
        "language": "ja"
    },
    {
        "word": "巨根",
        "language": "ja"
    },
    {
        "word": "巨乳",
        "language": "ja"
    },
    {
        "word": "強姦犯",
        "language": "ja"
    },
    {
        "word": "玉なめ",
        "language": "ja"
    },
    {
        "word": "玉舐め",
        "language": "ja"
    },
    {
        "word": "緊縛",
        "language": "ja"
    },
    {
        "word": "近親相姦",
        "language": "ja"
    },
    {
        "word": "嫌い",
        "language": "ja"
    },
    {
        "word": "後背位",
        "language": "ja"
    },
    {
        "word": "合意の性交",
        "language": "ja"
    },
    {
        "word": "拷問",
        "language": "ja"
    },
    {
        "word": "殺し方",
        "language": "ja"
    },
    {
        "word": "殺人事件",
        "language": "ja"
    },
    {
        "word": "殺人方法",
        "language": "ja"
    },
    {
        "word": "支配",
        "language": "ja"
    },
    {
        "word": "児童性虐待",
        "language": "ja"
    },
    {
        "word": "自己愛性",
        "language": "ja"
    },
    {
        "word": "射精",
        "language": "ja"
    },
    {
        "word": "手コキ",
        "language": "ja"
    },
    {
        "word": "獣姦",
        "language": "ja"
    },
    {
        "word": "女の子",
        "language": "ja"
    },
    {
        "word": "女王様",
        "language": "ja"
    },
    {
        "word": "女子高生",
        "language": "ja"
    },
    {
        "word": "女装",
        "language": "ja"
    },
    {
        "word": "新しいポルノ",
        "language": "ja"
    },
    {
        "word": "人妻",
        "language": "ja"
    },
    {
        "word": "人種",
        "language": "ja"
    },
    {
        "word": "性交",
        "language": "ja"
    },
    {
        "word": "正常位",
        "language": "ja"
    },
    {
        "word": "生殖器",
        "language": "ja"
    },
    {
        "word": "精液",
        "language": "ja"
    },
    {
        "word": "挿入",
        "language": "ja"
    },
    {
        "word": "足フェチ",
        "language": "ja"
    },
    {
        "word": "足を広げる",
        "language": "ja"
    },
    {
        "word": "大陰唇",
        "language": "ja"
    },
    {
        "word": "脱衣",
        "language": "ja"
    },
    {
        "word": "茶色のシャワー",
        "language": "ja"
    },
    {
        "word": "中出し",
        "language": "ja"
    },
    {
        "word": "潮吹き女",
        "language": "ja"
    },
    {
        "word": "潮吹き男性",
        "language": "ja"
    },
    {
        "word": "直腸",
        "language": "ja"
    },
    {
        "word": "剃毛",
        "language": "ja"
    },
    {
        "word": "貞操帯",
        "language": "ja"
    },
    {
        "word": "奴隷",
        "language": "ja"
    },
    {
        "word": "二穴",
        "language": "ja"
    },
    {
        "word": "乳首",
        "language": "ja"
    },
    {
        "word": "尿道プレイ",
        "language": "ja"
    },
    {
        "word": "覗き",
        "language": "ja"
    },
    {
        "word": "売春婦",
        "language": "ja"
    },
    {
        "word": "縛り",
        "language": "ja"
    },
    {
        "word": "噴出",
        "language": "ja"
    },
    {
        "word": "糞",
        "language": "ja"
    },
    {
        "word": "糞尿愛好症",
        "language": "ja"
    },
    {
        "word": "糞便",
        "language": "ja"
    },
    {
        "word": "平手打ち",
        "language": "ja"
    },
    {
        "word": "変態",
        "language": "ja"
    },
    {
        "word": "勃起する",
        "language": "ja"
    },
    {
        "word": "夢精",
        "language": "ja"
    },
    {
        "word": "毛深い",
        "language": "ja"
    },
    {
        "word": "誘惑",
        "language": "ja"
    },
    {
        "word": "幼児",
        "language": "ja"
    },
    {
        "word": "幼児性愛者",
        "language": "ja"
    },
    {
        "word": "裸",
        "language": "ja"
    },
    {
        "word": "裸の女性",
        "language": "ja"
    },
    {
        "word": "乱交",
        "language": "ja"
    },
    {
        "word": "両性",
        "language": "ja"
    },
    {
        "word": "両性具有",
        "language": "ja"
    },
    {
        "word": "両刀",
        "language": "ja"
    },
    {
        "word": "輪姦",
        "language": "ja"
    },
    {
        "word": "卍",
        "language": "ja"
    },
    {
        "word": "宦官",
        "language": "ja"
    },
    {
        "word": "肛門",
        "language": "ja"
    },
    {
        "word": "膣",
        "language": "ja"
    },
    {
        "word": "강간",
        "language": "ko"
    },
    {
        "word": "개새끼",
        "language": "ko"
    },
    {
        "word": "개자식",
        "language": "ko"
    },
    {
        "word": "개좆",
        "language": "ko"
    },
    {
        "word": "개차반",
        "language": "ko"
    },
    {
        "word": "거유",
        "language": "ko"
    },
    {
        "word": "계집년",
        "language": "ko"
    },
    {
        "word": "고자",
        "language": "ko"
    },
    {
        "word": "근친",
        "language": "ko"
    },
    {
        "word": "노모",
        "language": "ko"
    },
    {
        "word": "니기미",
        "language": "ko"
    },
    {
        "word": "뒤질래",
        "language": "ko"
    },
    {
        "word": "딸딸이",
        "language": "ko"
    },
    {
        "word": "때씹",
        "language": "ko"
    },
    {
        "word": "또라이",
        "language": "ko"
    },
    {
        "word": "뙤놈",
        "language": "ko"
    },
    {
        "word": "로리타",
        "language": "ko"
    },
    {
        "word": "망가",
        "language": "ko"
    },
    {
        "word": "몰카",
        "language": "ko"
    },
    {
        "word": "미친",
        "language": "ko"
    },
    {
        "word": "미친새끼",
        "language": "ko"
    },
    {
        "word": "바바리맨",
        "language": "ko"
    },
    {
        "word": "변태",
        "language": "ko"
    },
    {
        "word": "병신",
        "language": "ko"
    },
    {
        "word": "보지",
        "language": "ko"
    },
    {
        "word": "불알",
        "language": "ko"
    },
    {
        "word": "빠구리",
        "language": "ko"
    },
    {
        "word": "사까시",
        "language": "ko"
    },
    {
        "word": "섹스",
        "language": "ko"
    },
    {
        "word": "스와핑",
        "language": "ko"
    },
    {
        "word": "쌍놈",
        "language": "ko"
    },
    {
        "word": "씨발",
        "language": "ko"
    },
    {
        "word": "씨발놈",
        "language": "ko"
    },
    {
        "word": "씨팔",
        "language": "ko"
    },
    {
        "word": "씹",
        "language": "ko"
    },
    {
        "word": "씹물",
        "language": "ko"
    },
    {
        "word": "씹빨",
        "language": "ko"
    },
    {
        "word": "씹새끼",
        "language": "ko"
    },
    {
        "word": "씹알",
        "language": "ko"
    },
    {
        "word": "씹창",
        "language": "ko"
    },
    {
        "word": "씹팔",
        "language": "ko"
    },
    {
        "word": "암캐",
        "language": "ko"
    },
    {
        "word": "애자",
        "language": "ko"
    },
    {
        "word": "야동",
        "language": "ko"
    },
    {
        "word": "야사",
        "language": "ko"
    },
    {
        "word": "야애니",
        "language": "ko"
    },
    {
        "word": "엄창",
        "language": "ko"
    },
    {
        "word": "에로",
        "language": "ko"
    },
    {
        "word": "염병",
        "language": "ko"
    },
    {
        "word": "옘병",
        "language": "ko"
    },
    {
        "word": "유모",
        "language": "ko"
    },
    {
        "word": "육갑",
        "language": "ko"
    },
    {
        "word": "은꼴",
        "language": "ko"
    },
    {
        "word": "자위",
        "language": "ko"
    },
    {
        "word": "자지",
        "language": "ko"
    },
    {
        "word": "잡년",
        "language": "ko"
    },
    {
        "word": "종간나",
        "language": "ko"
    },
    {
        "word": "좆",
        "language": "ko"
    },
    {
        "word": "좆만",
        "language": "ko"
    },
    {
        "word": "죽일년",
        "language": "ko"
    },
    {
        "word": "쥐좆",
        "language": "ko"
    },
    {
        "word": "직촬",
        "language": "ko"
    },
    {
        "word": "짱깨",
        "language": "ko"
    },
    {
        "word": "쪽바리",
        "language": "ko"
    },
    {
        "word": "창녀",
        "language": "ko"
    },
    {
        "word": "포르노",
        "language": "ko"
    },
    {
        "word": "하드코어",
        "language": "ko"
    },
    {
        "word": "호로",
        "language": "ko"
    },
    {
        "word": "화냥년",
        "language": "ko"
    },
    {
        "word": "후레아들",
        "language": "ko"
    },
    {
        "word": "후장",
        "language": "ko"
    },
    {
        "word": "희쭈그리",
        "language": "ko"
    },
    {
        "word": "aardappels afgieteng",
        "language": "nl"
    },
    {
        "word": "achter het raam zitten",
        "language": "nl"
    },
    {
        "word": "afberen",
        "language": "nl"
    },
    {
        "word": "aflebberen",
        "language": "nl"
    },
    {
        "word": "afrossen",
        "language": "nl"
    },
    {
        "word": "afrukken",
        "language": "nl"
    },
    {
        "word": "aftrekken",
        "language": "nl"
    },
    {
        "word": "afwerkplaats",
        "language": "nl"
    },
    {
        "word": "afzeiken",
        "language": "nl"
    },
    {
        "word": "afzuigen",
        "language": "nl"
    },
    {
        "word": "anderhalve man en een paardekop",
        "language": "nl"
    },
    {
        "word": "anita",
        "language": "nl"
    },
    {
        "word": "asbak",
        "language": "nl"
    },
    {
        "word": "aso",
        "language": "nl"
    },
    {
        "word": "bagger schijten",
        "language": "nl"
    },
    {
        "word": "balen",
        "language": "nl"
    },
    {
        "word": "bedonderen",
        "language": "nl"
    },
    {
        "word": "befborstelg",
        "language": "nl"
    },
    {
        "word": "beffen",
        "language": "nl"
    },
    {
        "word": "bekken",
        "language": "nl"
    },
    {
        "word": "belazeren",
        "language": "nl"
    },
    {
        "word": "besodemieterd zijn",
        "language": "nl"
    },
    {
        "word": "besodemieteren",
        "language": "nl"
    },
    {
        "word": "beurt",
        "language": "nl"
    },
    {
        "word": "boemelen",
        "language": "nl"
    },
    {
        "word": "boerelul",
        "language": "nl"
    },
    {
        "word": "boerenpummelg",
        "language": "nl"
    },
    {
        "word": "bokkelul",
        "language": "nl"
    },
    {
        "word": "botergeil",
        "language": "nl"
    },
    {
        "word": "broekhoesten",
        "language": "nl"
    },
    {
        "word": "brugpieperg",
        "language": "nl"
    },
    {
        "word": "buffelen",
        "language": "nl"
    },
    {
        "word": "buiten de pot piesen",
        "language": "nl"
    },
    {
        "word": "da's kloten van de bok",
        "language": "nl"
    },
    {
        "word": "de ballen",
        "language": "nl"
    },
    {
        "word": "de hoer spelen",
        "language": "nl"
    },
    {
        "word": "de hond uitlaten",
        "language": "nl"
    },
    {
        "word": "de koffer induiken",
        "language": "nl"
    },
    {
        "word": "delg",
        "language": "nl"
    },
    {
        "word": "de pijp aan maarten geven",
        "language": "nl"
    },
    {
        "word": "de pijp uitgaan",
        "language": "nl"
    },
    {
        "word": "dombo",
        "language": "nl"
    },
    {
        "word": "draaikontg",
        "language": "nl"
    },
    {
        "word": "driehoog achter wonen",
        "language": "nl"
    },
    {
        "word": "drolg",
        "language": "nl"
    },
    {
        "word": "drooggeiler",
        "language": "nl"
    },
    {
        "word": "droogkloot",
        "language": "nl"
    },
    {
        "word": "een beurt geven",
        "language": "nl"
    },
    {
        "word": "een nummertje maken",
        "language": "nl"
    },
    {
        "word": "een wip maken",
        "language": "nl"
    },
    {
        "word": "eikel",
        "language": "nl"
    },
    {
        "word": "engerd",
        "language": "nl"
    },
    {
        "word": "flamoes",
        "language": "nl"
    },
    {
        "word": "flikken",
        "language": "nl"
    },
    {
        "word": "flikker",
        "language": "nl"
    },
    {
        "word": "gadverdamme",
        "language": "nl"
    },
    {
        "word": "galbak",
        "language": "nl"
    },
    {
        "word": "gat",
        "language": "nl"
    },
    {
        "word": "gedoogzone",
        "language": "nl"
    },
    {
        "word": "geilneef",
        "language": "nl"
    },
    {
        "word": "gesodemieter",
        "language": "nl"
    },
    {
        "word": "godverdomme",
        "language": "nl"
    },
    {
        "word": "graftak",
        "language": "nl"
    },
    {
        "word": "gras maaien",
        "language": "nl"
    },
    {
        "word": "gratenkutg",
        "language": "nl"
    },
    {
        "word": "greppeldel",
        "language": "nl"
    },
    {
        "word": "griet",
        "language": "nl"
    },
    {
        "word": "hoempert",
        "language": "nl"
    },
    {
        "word": "hoer",
        "language": "nl"
    },
    {
        "word": "hoerenbuurt",
        "language": "nl"
    },
    {
        "word": "hoerenloper",
        "language": "nl"
    },
    {
        "word": "hoerig",
        "language": "nl"
    },
    {
        "word": "hol",
        "language": "nl"
    },
    {
        "word": "hufter",
        "language": "nl"
    },
    {
        "word": "huisdealer",
        "language": "nl"
    },
    {
        "word": "johny",
        "language": "nl"
    },
    {
        "word": "kanen",
        "language": "nl"
    },
    {
        "word": "kettingzeugg",
        "language": "nl"
    },
    {
        "word": "klaarkomen",
        "language": "nl"
    },
    {
        "word": "klerebeer",
        "language": "nl"
    },
    {
        "word": "klojo",
        "language": "nl"
    },
    {
        "word": "klooien",
        "language": "nl"
    },
    {
        "word": "klootjesvolk",
        "language": "nl"
    },
    {
        "word": "klootoog",
        "language": "nl"
    },
    {
        "word": "klootzak",
        "language": "nl"
    },
    {
        "word": "kloten",
        "language": "nl"
    },
    {
        "word": "knor",
        "language": "nl"
    },
    {
        "word": "kontg",
        "language": "nl"
    },
    {
        "word": "kontneuken",
        "language": "nl"
    },
    {
        "word": "krentekakker",
        "language": "nl"
    },
    {
        "word": "kut",
        "language": "nl"
    },
    {
        "word": "kuttelikkertje",
        "language": "nl"
    },
    {
        "word": "kwakkieg",
        "language": "nl"
    },
    {
        "word": "liefdesgrot",
        "language": "nl"
    },
    {
        "word": "lul",
        "language": "nl"
    },
    {
        "word": "lul-de-behanger",
        "language": "nl"
    },
    {
        "word": "lulhannes",
        "language": "nl"
    },
    {
        "word": "mafketel",
        "language": "nl"
    },
    {
        "word": "matennaaierg",
        "language": "nl"
    },
    {
        "word": "matje",
        "language": "nl"
    },
    {
        "word": "mof",
        "language": "nl"
    },
    {
        "word": "mutsg",
        "language": "nl"
    },
    {
        "word": "naaien",
        "language": "nl"
    },
    {
        "word": "naakt",
        "language": "nl"
    },
    {
        "word": "neuken",
        "language": "nl"
    },
    {
        "word": "neukstier",
        "language": "nl"
    },
    {
        "word": "nicht",
        "language": "nl"
    },
    {
        "word": "oetlul",
        "language": "nl"
    },
    {
        "word": "opgeilen",
        "language": "nl"
    },
    {
        "word": "opkankeren",
        "language": "nl"
    },
    {
        "word": "oprotten",
        "language": "nl"
    },
    {
        "word": "opsodemieteren",
        "language": "nl"
    },
    {
        "word": "op z'n hondjes",
        "language": "nl"
    },
    {
        "word": "op z'n sodemieter geven",
        "language": "nl"
    },
    {
        "word": "opzouten",
        "language": "nl"
    },
    {
        "word": "ouwehoer",
        "language": "nl"
    },
    {
        "word": "ouwehoeren",
        "language": "nl"
    },
    {
        "word": "ouwe rukker",
        "language": "nl"
    },
    {
        "word": "paal",
        "language": "nl"
    },
    {
        "word": "paardelul",
        "language": "nl"
    },
    {
        "word": "palen",
        "language": "nl"
    },
    {
        "word": "penozeg",
        "language": "nl"
    },
    {
        "word": "piesen",
        "language": "nl"
    },
    {
        "word": "pijpbekkieg",
        "language": "nl"
    },
    {
        "word": "pijpen",
        "language": "nl"
    },
    {
        "word": "pleurislaaier",
        "language": "nl"
    },
    {
        "word": "poep",
        "language": "nl"
    },
    {
        "word": "poepen",
        "language": "nl"
    },
    {
        "word": "poot",
        "language": "nl"
    },
    {
        "word": "portiekslet",
        "language": "nl"
    },
    {
        "word": "pot",
        "language": "nl"
    },
    {
        "word": "potverdorie",
        "language": "nl"
    },
    {
        "word": "publiciteitsgeil",
        "language": "nl"
    },
    {
        "word": "raaskallen",
        "language": "nl"
    },
    {
        "word": "reet",
        "language": "nl"
    },
    {
        "word": "reetridder",
        "language": "nl"
    },
    {
        "word": "reet trappen, voor zijn",
        "language": "nl"
    },
    {
        "word": "remsporeng",
        "language": "nl"
    },
    {
        "word": "reutelen",
        "language": "nl"
    },
    {
        "word": "rothoer",
        "language": "nl"
    },
    {
        "word": "rotzak",
        "language": "nl"
    },
    {
        "word": "rukhond",
        "language": "nl"
    },
    {
        "word": "rukken",
        "language": "nl"
    },
    {
        "word": "schatje",
        "language": "nl"
    },
    {
        "word": "schijt",
        "language": "nl"
    },
    {
        "word": "schijten",
        "language": "nl"
    },
    {
        "word": "schoft",
        "language": "nl"
    },
    {
        "word": "schuinsmarcheerder",
        "language": "nl"
    },
    {
        "word": "slempen",
        "language": "nl"
    },
    {
        "word": "sletg",
        "language": "nl"
    },
    {
        "word": "sletterig",
        "language": "nl"
    },
    {
        "word": "slik mijn zaad",
        "language": "nl"
    },
    {
        "word": "snolg",
        "language": "nl"
    },
    {
        "word": "spuiten",
        "language": "nl"
    },
    {
        "word": "standje",
        "language": "nl"
    },
    {
        "word": "standje-69g",
        "language": "nl"
    },
    {
        "word": "stoephoer",
        "language": "nl"
    },
    {
        "word": "stootje",
        "language": "nl"
    },
    {
        "word": "strontg",
        "language": "nl"
    },
    {
        "word": "sufferdg",
        "language": "nl"
    },
    {
        "word": "tapijtnek",
        "language": "nl"
    },
    {
        "word": "teefg",
        "language": "nl"
    },
    {
        "word": "temeier",
        "language": "nl"
    },
    {
        "word": "teringlijer",
        "language": "nl"
    },
    {
        "word": "toeter",
        "language": "nl"
    },
    {
        "word": "tongzoeng",
        "language": "nl"
    },
    {
        "word": "triootjeg",
        "language": "nl"
    },
    {
        "word": "trottoir prostituée",
        "language": "nl"
    },
    {
        "word": "trottoirteef",
        "language": "nl"
    },
    {
        "word": "vergallen",
        "language": "nl"
    },
    {
        "word": "verkloten",
        "language": "nl"
    },
    {
        "word": "verneuken",
        "language": "nl"
    },
    {
        "word": "viespeuk",
        "language": "nl"
    },
    {
        "word": "vingeren",
        "language": "nl"
    },
    {
        "word": "vleesroos",
        "language": "nl"
    },
    {
        "word": "voor jan lul",
        "language": "nl"
    },
    {
        "word": "voor jan-met-de-korte-achternaam",
        "language": "nl"
    },
    {
        "word": "watje",
        "language": "nl"
    },
    {
        "word": "welzijnsmafia",
        "language": "nl"
    },
    {
        "word": "wijf",
        "language": "nl"
    },
    {
        "word": "wippen",
        "language": "nl"
    },
    {
        "word": "wuftje",
        "language": "nl"
    },
    {
        "word": "zaadje",
        "language": "nl"
    },
    {
        "word": "zakkenwasser",
        "language": "nl"
    },
    {
        "word": "zeiken",
        "language": "nl"
    },
    {
        "word": "zeiker",
        "language": "nl"
    },
    {
        "word": "zuigen",
        "language": "nl"
    },
    {
        "word": "zuiplap",
        "language": "nl"
    },
    {
        "word": "drittsekk",
        "language": "no"
    },
    {
        "word": "faen i helvete",
        "language": "no"
    },
    {
        "word": "fitte",
        "language": "no"
    },
    {
        "word": "jævla",
        "language": "no"
    },
    {
        "word": "kuk",
        "language": "no"
    },
    {
        "word": "kukene",
        "language": "no"
    },
    {
        "word": "kuker",
        "language": "no"
    },
    {
        "word": "pikk",
        "language": "no"
    },
    {
        "word": "sotrør",
        "language": "no"
    },
    {
        "word": "ståpikk",
        "language": "no"
    },
    {
        "word": "ståpikkene",
        "language": "no"
    },
    {
        "word": "ståpikker",
        "language": "no"
    },
    {
        "word": "burdel",
        "language": "pl"
    },
    {
        "word": "burdelmama",
        "language": "pl"
    },
    {
        "word": "chujnia",
        "language": "pl"
    },
    {
        "word": "ciota",
        "language": "pl"
    },
    {
        "word": "cipa",
        "language": "pl"
    },
    {
        "word": "cyc",
        "language": "pl"
    },
    {
        "word": "dmuchać",
        "language": "pl"
    },
    {
        "word": "do kurwy nędzy",
        "language": "pl"
    },
    {
        "word": "dupa",
        "language": "pl"
    },
    {
        "word": "dupek",
        "language": "pl"
    },
    {
        "word": "duperele",
        "language": "pl"
    },
    {
        "word": "dziwka",
        "language": "pl"
    },
    {
        "word": "fiut",
        "language": "pl"
    },
    {
        "word": "gówno",
        "language": "pl"
    },
    {
        "word": "gówno prawda",
        "language": "pl"
    },
    {
        "word": "huj",
        "language": "pl"
    },
    {
        "word": "jajco",
        "language": "pl"
    },
    {
        "word": "jajeczko",
        "language": "pl"
    },
    {
        "word": "jajko",
        "language": "pl"
    },
    {
        "word": "jajo",
        "language": "pl"
    },
    {
        "word": "ja pierdolę",
        "language": "pl"
    },
    {
        "word": "jebać",
        "language": "pl"
    },
    {
        "word": "jebany",
        "language": "pl"
    },
    {
        "word": "kurwa",
        "language": "pl"
    },
    {
        "word": "kurwy",
        "language": "pl"
    },
    {
        "word": "kutafon",
        "language": "pl"
    },
    {
        "word": "kutas",
        "language": "pl"
    },
    {
        "word": "lizać pałę",
        "language": "pl"
    },
    {
        "word": "obciągać chuja",
        "language": "pl"
    },
    {
        "word": "obciągać fiuta",
        "language": "pl"
    },
    {
        "word": "obciągać loda",
        "language": "pl"
    },
    {
        "word": "pieprzyć",
        "language": "pl"
    },
    {
        "word": "pierdolec",
        "language": "pl"
    },
    {
        "word": "pierdolić",
        "language": "pl"
    },
    {
        "word": "pierdolnięty",
        "language": "pl"
    },
    {
        "word": "pierdoła",
        "language": "pl"
    },
    {
        "word": "pierdzieć",
        "language": "pl"
    },
    {
        "word": "pojeb",
        "language": "pl"
    },
    {
        "word": "popierdolony",
        "language": "pl"
    },
    {
        "word": "robic loda",
        "language": "pl"
    },
    {
        "word": "ruchać",
        "language": "pl"
    },
    {
        "word": "rzygać",
        "language": "pl"
    },
    {
        "word": "skurwysyn",
        "language": "pl"
    },
    {
        "word": "sraczka",
        "language": "pl"
    },
    {
        "word": "srać",
        "language": "pl"
    },
    {
        "word": "suka",
        "language": "pl"
    },
    {
        "word": "syf",
        "language": "pl"
    },
    {
        "word": "wkurwiać",
        "language": "pl"
    },
    {
        "word": "zajebisty",
        "language": "pl"
    },
    {
        "word": "aborto",
        "language": "pt"
    },
    {
        "word": "amador",
        "language": "pt"
    },
    {
        "word": "aranha",
        "language": "pt"
    },
    {
        "word": "ariano",
        "language": "pt"
    },
    {
        "word": "balalao",
        "language": "pt"
    },
    {
        "word": "bicha",
        "language": "pt"
    },
    {
        "word": "biscate",
        "language": "pt"
    },
    {
        "word": "bissexual",
        "language": "pt"
    },
    {
        "word": "boceta",
        "language": "pt"
    },
    {
        "word": "bosta",
        "language": "pt"
    },
    {
        "word": "braulio de borracha",
        "language": "pt"
    },
    {
        "word": "bumbum",
        "language": "pt"
    },
    {
        "word": "burro",
        "language": "pt"
    },
    {
        "word": "cabrao",
        "language": "pt"
    },
    {
        "word": "cacete",
        "language": "pt"
    },
    {
        "word": "cagar",
        "language": "pt"
    },
    {
        "word": "camisinha",
        "language": "pt"
    },
    {
        "word": "caralho",
        "language": "pt"
    },
    {
        "word": "cerveja",
        "language": "pt"
    },
    {
        "word": "chochota",
        "language": "pt"
    },
    {
        "word": "chupar",
        "language": "pt"
    },
    {
        "word": "cocaína",
        "language": "pt"
    },
    {
        "word": "colhoes",
        "language": "pt"
    },
    {
        "word": "comer",
        "language": "pt"
    },
    {
        "word": "cona",
        "language": "pt"
    },
    {
        "word": "consolo",
        "language": "pt"
    },
    {
        "word": "corno",
        "language": "pt"
    },
    {
        "word": "cu",
        "language": "pt"
    },
    {
        "word": "dar o rabo",
        "language": "pt"
    },
    {
        "word": "dum raio",
        "language": "pt"
    },
    {
        "word": "esporra",
        "language": "pt"
    },
    {
        "word": "fecal",
        "language": "pt"
    },
    {
        "word": "filho da puta",
        "language": "pt"
    },
    {
        "word": "foda",
        "language": "pt"
    },
    {
        "word": "foda-se",
        "language": "pt"
    },
    {
        "word": "foder",
        "language": "pt"
    },
    {
        "word": "frango assado",
        "language": "pt"
    },
    {
        "word": "gozar",
        "language": "pt"
    },
    {
        "word": "grelho",
        "language": "pt"
    },
    {
        "word": "heterosexual",
        "language": "pt"
    },
    {
        "word": "homem gay",
        "language": "pt"
    },
    {
        "word": "homoerótico",
        "language": "pt"
    },
    {
        "word": "homosexual",
        "language": "pt"
    },
    {
        "word": "inferno",
        "language": "pt"
    },
    {
        "word": "lésbica",
        "language": "pt"
    },
    {
        "word": "lolita",
        "language": "pt"
    },
    {
        "word": "mama",
        "language": "pt"
    },
    {
        "word": "paneleiro",
        "language": "pt"
    },
    {
        "word": "passar um cheque",
        "language": "pt"
    },
    {
        "word": "pau",
        "language": "pt"
    },
    {
        "word": "peidar",
        "language": "pt"
    },
    {
        "word": "pinto",
        "language": "pt"
    },
    {
        "word": "porra",
        "language": "pt"
    },
    {
        "word": "puta que pariu",
        "language": "pt"
    },
    {
        "word": "puta que te pariu",
        "language": "pt"
    },
    {
        "word": "queca",
        "language": "pt"
    },
    {
        "word": "sacanagem",
        "language": "pt"
    },
    {
        "word": "saco",
        "language": "pt"
    },
    {
        "word": "torneira",
        "language": "pt"
    },
    {
        "word": "transar",
        "language": "pt"
    },
    {
        "word": "vai-te foder",
        "language": "pt"
    },
    {
        "word": "vai tomar no cu",
        "language": "pt"
    },
    {
        "word": "veado",
        "language": "pt"
    },
    {
        "word": "vibrador",
        "language": "pt"
    },
    {
        "word": "xana",
        "language": "pt"
    },
    {
        "word": "xochota",
        "language": "pt"
    },
    {
        "word": "bychara",
        "language": "ru"
    },
    {
        "word": "byk",
        "language": "ru"
    },
    {
        "word": "chernozhopyi",
        "language": "ru"
    },
    {
        "word": "dolboy'eb",
        "language": "ru"
    },
    {
        "word": "ebalnik",
        "language": "ru"
    },
    {
        "word": "ebalo",
        "language": "ru"
    },
    {
        "word": "ebalom sch'elkat",
        "language": "ru"
    },
    {
        "word": "gol",
        "language": "ru"
    },
    {
        "word": "mudack",
        "language": "ru"
    },
    {
        "word": "opizdenet",
        "language": "ru"
    },
    {
        "word": "osto'eblo",
        "language": "ru"
    },
    {
        "word": "ostokhuitel'no",
        "language": "ru"
    },
    {
        "word": "ot'ebis",
        "language": "ru"
    },
    {
        "word": "otmudohat",
        "language": "ru"
    },
    {
        "word": "otpizdit",
        "language": "ru"
    },
    {
        "word": "otsosi",
        "language": "ru"
    },
    {
        "word": "padlo",
        "language": "ru"
    },
    {
        "word": "pedik",
        "language": "ru"
    },
    {
        "word": "perdet",
        "language": "ru"
    },
    {
        "word": "petuh",
        "language": "ru"
    },
    {
        "word": "pidar gnoinyj",
        "language": "ru"
    },
    {
        "word": "pizdato",
        "language": "ru"
    },
    {
        "word": "pizdatyi",
        "language": "ru"
    },
    {
        "word": "piz'det",
        "language": "ru"
    },
    {
        "word": "pizdetc",
        "language": "ru"
    },
    {
        "word": "pizdoi nakryt'sja",
        "language": "ru"
    },
    {
        "word": "pizd'uk",
        "language": "ru"
    },
    {
        "word": "piz`dyulina",
        "language": "ru"
    },
    {
        "word": "podi ku'evo",
        "language": "ru"
    },
    {
        "word": "poeben",
        "language": "ru"
    },
    {
        "word": "po'imat' na konchik",
        "language": "ru"
    },
    {
        "word": "po'iti posrat",
        "language": "ru"
    },
    {
        "word": "po khuy",
        "language": "ru"
    },
    {
        "word": "poluchit pizdy",
        "language": "ru"
    },
    {
        "word": "pososi moyu konfetku",
        "language": "ru"
    },
    {
        "word": "prissat",
        "language": "ru"
    },
    {
        "word": "proebat",
        "language": "ru"
    },
    {
        "word": "promudobl'adsksya pizdopro'ebina",
        "language": "ru"
    },
    {
        "word": "propezdoloch",
        "language": "ru"
    },
    {
        "word": "prosrat",
        "language": "ru"
    },
    {
        "word": "raspeezdeyi",
        "language": "ru"
    },
    {
        "word": "raspizdatyi",
        "language": "ru"
    },
    {
        "word": "raz'yebuy",
        "language": "ru"
    },
    {
        "word": "raz'yoba",
        "language": "ru"
    },
    {
        "word": "s'ebat'sya",
        "language": "ru"
    },
    {
        "word": "shalava",
        "language": "ru"
    },
    {
        "word": "styervo",
        "language": "ru"
    },
    {
        "word": "sukin syn",
        "language": "ru"
    },
    {
        "word": "svodit posrat",
        "language": "ru"
    },
    {
        "word": "svoloch",
        "language": "ru"
    },
    {
        "word": "trakhat'sya",
        "language": "ru"
    },
    {
        "word": "trimandoblydskiy pizdoproyob",
        "language": "ru"
    },
    {
        "word": "ubl'yudok",
        "language": "ru"
    },
    {
        "word": "uboy",
        "language": "ru"
    },
    {
        "word": "u'ebitsche",
        "language": "ru"
    },
    {
        "word": "vafl'a",
        "language": "ru"
    },
    {
        "word": "vafli lovit",
        "language": "ru"
    },
    {
        "word": "v pizdu",
        "language": "ru"
    },
    {
        "word": "vyperdysh",
        "language": "ru"
    },
    {
        "word": "vzdrochennyi",
        "language": "ru"
    },
    {
        "word": "yeb vas",
        "language": "ru"
    },
    {
        "word": "za'ebat",
        "language": "ru"
    },
    {
        "word": "zaebis",
        "language": "ru"
    },
    {
        "word": "zalupa",
        "language": "ru"
    },
    {
        "word": "zalupat",
        "language": "ru"
    },
    {
        "word": "zasranetc",
        "language": "ru"
    },
    {
        "word": "zassat",
        "language": "ru"
    },
    {
        "word": "zlo'ebuchy",
        "language": "ru"
    },
    {
        "word": "бардак",
        "language": "ru"
    },
    {
        "word": "бздёнок",
        "language": "ru"
    },
    {
        "word": "блядки",
        "language": "ru"
    },
    {
        "word": "блядовать",
        "language": "ru"
    },
    {
        "word": "блядство",
        "language": "ru"
    },
    {
        "word": "блядь",
        "language": "ru"
    },
    {
        "word": "бугор",
        "language": "ru"
    },
    {
        "word": "во пизду",
        "language": "ru"
    },
    {
        "word": "встать раком",
        "language": "ru"
    },
    {
        "word": "выёбываться",
        "language": "ru"
    },
    {
        "word": "гандон",
        "language": "ru"
    },
    {
        "word": "говно",
        "language": "ru"
    },
    {
        "word": "говнюк",
        "language": "ru"
    },
    {
        "word": "голый",
        "language": "ru"
    },
    {
        "word": "дать пизды",
        "language": "ru"
    },
    {
        "word": "дерьмо",
        "language": "ru"
    },
    {
        "word": "дрочить",
        "language": "ru"
    },
    {
        "word": "другой дразнится",
        "language": "ru"
    },
    {
        "word": "ёбарь",
        "language": "ru"
    },
    {
        "word": "ебать",
        "language": "ru"
    },
    {
        "word": "ебать-копать",
        "language": "ru"
    },
    {
        "word": "ебло",
        "language": "ru"
    },
    {
        "word": "ебнуть",
        "language": "ru"
    },
    {
        "word": "ёб твою мать",
        "language": "ru"
    },
    {
        "word": "жопа",
        "language": "ru"
    },
    {
        "word": "жополиз",
        "language": "ru"
    },
    {
        "word": "играть на кожаной флейте",
        "language": "ru"
    },
    {
        "word": "измудохать",
        "language": "ru"
    },
    {
        "word": "каждый дрочит как он хочет",
        "language": "ru"
    },
    {
        "word": "какая разница",
        "language": "ru"
    },
    {
        "word": "как два пальца обоссать",
        "language": "ru"
    },
    {
        "word": "курите мою трубку",
        "language": "ru"
    },
    {
        "word": "лысого в кулаке гонять",
        "language": "ru"
    },
    {
        "word": "малофя",
        "language": "ru"
    },
    {
        "word": "манда",
        "language": "ru"
    },
    {
        "word": "мандавошка",
        "language": "ru"
    },
    {
        "word": "мент",
        "language": "ru"
    },
    {
        "word": "муда",
        "language": "ru"
    },
    {
        "word": "мудило",
        "language": "ru"
    },
    {
        "word": "мудозмон",
        "language": "ru"
    },
    {
        "word": "наебать",
        "language": "ru"
    },
    {
        "word": "наебениться",
        "language": "ru"
    },
    {
        "word": "наебнуться",
        "language": "ru"
    },
    {
        "word": "на фиг",
        "language": "ru"
    },
    {
        "word": "на хуй",
        "language": "ru"
    },
    {
        "word": "на хую вертеть",
        "language": "ru"
    },
    {
        "word": "на хуя",
        "language": "ru"
    },
    {
        "word": "нахуячиться",
        "language": "ru"
    },
    {
        "word": "невебенный",
        "language": "ru"
    },
    {
        "word": "не ебет",
        "language": "ru"
    },
    {
        "word": "ни за хуй собачу",
        "language": "ru"
    },
    {
        "word": "ни хуя",
        "language": "ru"
    },
    {
        "word": "обнаженный",
        "language": "ru"
    },
    {
        "word": "обоссаться можно",
        "language": "ru"
    },
    {
        "word": "один ебётся",
        "language": "ru"
    },
    {
        "word": "опесдол",
        "language": "ru"
    },
    {
        "word": "офигеть",
        "language": "ru"
    },
    {
        "word": "охуеть",
        "language": "ru"
    },
    {
        "word": "охуйтельно",
        "language": "ru"
    },
    {
        "word": "половое сношение",
        "language": "ru"
    },
    {
        "word": "секс",
        "language": "ru"
    },
    {
        "word": "сиски",
        "language": "ru"
    },
    {
        "word": "спиздить",
        "language": "ru"
    },
    {
        "word": "срать",
        "language": "ru"
    },
    {
        "word": "ссать",
        "language": "ru"
    },
    {
        "word": "траxать",
        "language": "ru"
    },
    {
        "word": "ты мне ваньку не валяй",
        "language": "ru"
    },
    {
        "word": "фига",
        "language": "ru"
    },
    {
        "word": "хапать",
        "language": "ru"
    },
    {
        "word": "хер с ней",
        "language": "ru"
    },
    {
        "word": "хер с ним",
        "language": "ru"
    },
    {
        "word": "хохол",
        "language": "ru"
    },
    {
        "word": "хрен",
        "language": "ru"
    },
    {
        "word": "хуёво",
        "language": "ru"
    },
    {
        "word": "хуёвый",
        "language": "ru"
    },
    {
        "word": "хуем груши околачивать",
        "language": "ru"
    },
    {
        "word": "хуеплет",
        "language": "ru"
    },
    {
        "word": "хуило",
        "language": "ru"
    },
    {
        "word": "хуиней страдать",
        "language": "ru"
    },
    {
        "word": "хуиня",
        "language": "ru"
    },
    {
        "word": "хуй",
        "language": "ru"
    },
    {
        "word": "хуйнуть",
        "language": "ru"
    },
    {
        "word": "хуй пинать",
        "language": "ru"
    },
    {
        "word": "arsle",
        "language": "sv"
    },
    {
        "word": "brutta",
        "language": "sv"
    },
    {
        "word": "discofitta",
        "language": "sv"
    },
    {
        "word": "dra åt helvete",
        "language": "sv"
    },
    {
        "word": "fan",
        "language": "sv"
    },
    {
        "word": "fitta",
        "language": "sv"
    },
    {
        "word": "fittig",
        "language": "sv"
    },
    {
        "word": "för helvete",
        "language": "sv"
    },
    {
        "word": "helvete",
        "language": "sv"
    },
    {
        "word": "hård",
        "language": "sv"
    },
    {
        "word": "jävlar",
        "language": "sv"
    },
    {
        "word": "knulla",
        "language": "sv"
    },
    {
        "word": "kuksås",
        "language": "sv"
    },
    {
        "word": "kötthuvud",
        "language": "sv"
    },
    {
        "word": "köttnacke",
        "language": "sv"
    },
    {
        "word": "moona",
        "language": "sv"
    },
    {
        "word": "moonade",
        "language": "sv"
    },
    {
        "word": "moonar",
        "language": "sv"
    },
    {
        "word": "moonat",
        "language": "sv"
    },
    {
        "word": "mutta",
        "language": "sv"
    },
    {
        "word": "olla",
        "language": "sv"
    },
    {
        "word": "pippa",
        "language": "sv"
    },
    {
        "word": "pitt",
        "language": "sv"
    },
    {
        "word": "prutt",
        "language": "sv"
    },
    {
        "word": "pök",
        "language": "sv"
    },
    {
        "word": "runka",
        "language": "sv"
    },
    {
        "word": "röv",
        "language": "sv"
    },
    {
        "word": "rövhål",
        "language": "sv"
    },
    {
        "word": "rövknulla",
        "language": "sv"
    },
    {
        "word": "satan",
        "language": "sv"
    },
    {
        "word": "skita",
        "language": "sv"
    },
    {
        "word": "skit ner dig",
        "language": "sv"
    },
    {
        "word": "skäggbiff",
        "language": "sv"
    },
    {
        "word": "snedfitta",
        "language": "sv"
    },
    {
        "word": "snefitta",
        "language": "sv"
    },
    {
        "word": "stake",
        "language": "sv"
    },
    {
        "word": "subba",
        "language": "sv"
    },
    {
        "word": "sås",
        "language": "sv"
    },
    {
        "word": "sätta på",
        "language": "sv"
    },
    {
        "word": "tusan",
        "language": "sv"
    },
    {
        "word": "กระดอ",
        "language": "th"
    },
    {
        "word": "กระเด้า",
        "language": "th"
    },
    {
        "word": "กระหรี่",
        "language": "th"
    },
    {
        "word": "กะปิ",
        "language": "th"
    },
    {
        "word": "กู",
        "language": "th"
    },
    {
        "word": "ขี้",
        "language": "th"
    },
    {
        "word": "ควย",
        "language": "th"
    },
    {
        "word": "จิ๋ม",
        "language": "th"
    },
    {
        "word": "จู๋",
        "language": "th"
    },
    {
        "word": "เจ๊ก",
        "language": "th"
    },
    {
        "word": "เจี๊ยว",
        "language": "th"
    },
    {
        "word": "ดอกทอง",
        "language": "th"
    },
    {
        "word": "ตอแหล",
        "language": "th"
    },
    {
        "word": "ตูด",
        "language": "th"
    },
    {
        "word": "น้ําแตก",
        "language": "th"
    },
    {
        "word": "มึง",
        "language": "th"
    },
    {
        "word": "แม่ง",
        "language": "th"
    },
    {
        "word": "เย็ด",
        "language": "th"
    },
    {
        "word": "รูตูด",
        "language": "th"
    },
    {
        "word": "ล้างตู้เย็น",
        "language": "th"
    },
    {
        "word": "ส้นตีน",
        "language": "th"
    },
    {
        "word": "สัด",
        "language": "th"
    },
    {
        "word": "เสือก",
        "language": "th"
    },
    {
        "word": "หญิงชาติชั่ว",
        "language": "th"
    },
    {
        "word": "หลั่ง",
        "language": "th"
    },
    {
        "word": "ห่า",
        "language": "th"
    },
    {
        "word": "หํา",
        "language": "th"
    },
    {
        "word": "หี",
        "language": "th"
    },
    {
        "word": "เหี้ย",
        "language": "th"
    },
    {
        "word": "อมนกเขา",
        "language": "th"
    },
    {
        "word": "ไอ้ควาย",
        "language": "th"
    },
    {
        "word": "ghuy'cha'",
        "language": "tlh"
    },
    {
        "word": "QI'yaH",
        "language": "tlh"
    },
    {
        "word": "Qu'vatlh",
        "language": "tlh"
    },
    {
        "word": "am",
        "language": "tr"
    },
    {
        "word": "amcığa",
        "language": "tr"
    },
    {
        "word": "amcığı",
        "language": "tr"
    },
    {
        "word": "amcığın",
        "language": "tr"
    },
    {
        "word": "amcık",
        "language": "tr"
    },
    {
        "word": "amcıklar",
        "language": "tr"
    },
    {
        "word": "amcıklara",
        "language": "tr"
    },
    {
        "word": "amcıklarda",
        "language": "tr"
    },
    {
        "word": "amcıklardan",
        "language": "tr"
    },
    {
        "word": "amcıkları",
        "language": "tr"
    },
    {
        "word": "amcıkların",
        "language": "tr"
    },
    {
        "word": "amcıkta",
        "language": "tr"
    },
    {
        "word": "amcıktan",
        "language": "tr"
    },
    {
        "word": "amı",
        "language": "tr"
    },
    {
        "word": "amlar",
        "language": "tr"
    },
    {
        "word": "çingene",
        "language": "tr"
    },
    {
        "word": "Çingenede",
        "language": "tr"
    },
    {
        "word": "Çingeneden",
        "language": "tr"
    },
    {
        "word": "Çingeneler",
        "language": "tr"
    },
    {
        "word": "Çingenelerde",
        "language": "tr"
    },
    {
        "word": "Çingenelerden",
        "language": "tr"
    },
    {
        "word": "Çingenelere",
        "language": "tr"
    },
    {
        "word": "Çingeneleri",
        "language": "tr"
    },
    {
        "word": "Çingenelerin",
        "language": "tr"
    },
    {
        "word": "Çingenenin",
        "language": "tr"
    },
    {
        "word": "Çingeneye",
        "language": "tr"
    },
    {
        "word": "Çingeneyi",
        "language": "tr"
    },
    {
        "word": "göt",
        "language": "tr"
    },
    {
        "word": "göte",
        "language": "tr"
    },
    {
        "word": "götler",
        "language": "tr"
    },
    {
        "word": "götlerde",
        "language": "tr"
    },
    {
        "word": "götlerden",
        "language": "tr"
    },
    {
        "word": "götlere",
        "language": "tr"
    },
    {
        "word": "götleri",
        "language": "tr"
    },
    {
        "word": "götlerin",
        "language": "tr"
    },
    {
        "word": "götte",
        "language": "tr"
    },
    {
        "word": "götten",
        "language": "tr"
    },
    {
        "word": "götü",
        "language": "tr"
    },
    {
        "word": "götün",
        "language": "tr"
    },
    {
        "word": "götveren",
        "language": "tr"
    },
    {
        "word": "götverende",
        "language": "tr"
    },
    {
        "word": "götverenden",
        "language": "tr"
    },
    {
        "word": "götverene",
        "language": "tr"
    },
    {
        "word": "götvereni",
        "language": "tr"
    },
    {
        "word": "götverenin",
        "language": "tr"
    },
    {
        "word": "götverenler",
        "language": "tr"
    },
    {
        "word": "götverenlerde",
        "language": "tr"
    },
    {
        "word": "götverenlerden",
        "language": "tr"
    },
    {
        "word": "götverenlere",
        "language": "tr"
    },
    {
        "word": "götverenleri",
        "language": "tr"
    },
    {
        "word": "götverenlerin",
        "language": "tr"
    },
    {
        "word": "kaltağa",
        "language": "tr"
    },
    {
        "word": "kaltağı",
        "language": "tr"
    },
    {
        "word": "kaltağın",
        "language": "tr"
    },
    {
        "word": "kaltak",
        "language": "tr"
    },
    {
        "word": "kaltaklar",
        "language": "tr"
    },
    {
        "word": "kaltaklara",
        "language": "tr"
    },
    {
        "word": "kaltaklarda",
        "language": "tr"
    },
    {
        "word": "kaltaklardan",
        "language": "tr"
    },
    {
        "word": "kaltakları",
        "language": "tr"
    },
    {
        "word": "kaltakların",
        "language": "tr"
    },
    {
        "word": "kaltakta",
        "language": "tr"
    },
    {
        "word": "kaltaktan",
        "language": "tr"
    },
    {
        "word": "orospu",
        "language": "tr"
    },
    {
        "word": "orospuda",
        "language": "tr"
    },
    {
        "word": "orospudan",
        "language": "tr"
    },
    {
        "word": "orospular",
        "language": "tr"
    },
    {
        "word": "orospulara",
        "language": "tr"
    },
    {
        "word": "orospularda",
        "language": "tr"
    },
    {
        "word": "orospulardan",
        "language": "tr"
    },
    {
        "word": "orospuları",
        "language": "tr"
    },
    {
        "word": "orospuların",
        "language": "tr"
    },
    {
        "word": "orospunun",
        "language": "tr"
    },
    {
        "word": "orospuya",
        "language": "tr"
    },
    {
        "word": "orospuyu",
        "language": "tr"
    },
    {
        "word": "otuz birci",
        "language": "tr"
    },
    {
        "word": "otuz bircide",
        "language": "tr"
    },
    {
        "word": "otuz birciden",
        "language": "tr"
    },
    {
        "word": "otuz birciler",
        "language": "tr"
    },
    {
        "word": "otuz bircilerde",
        "language": "tr"
    },
    {
        "word": "otuz bircilerden",
        "language": "tr"
    },
    {
        "word": "otuz bircilere",
        "language": "tr"
    },
    {
        "word": "otuz bircileri",
        "language": "tr"
    },
    {
        "word": "otuz bircilerin",
        "language": "tr"
    },
    {
        "word": "otuz bircinin",
        "language": "tr"
    },
    {
        "word": "otuz birciye",
        "language": "tr"
    },
    {
        "word": "otuz birciyi",
        "language": "tr"
    },
    {
        "word": "saksocu",
        "language": "tr"
    },
    {
        "word": "saksocuda",
        "language": "tr"
    },
    {
        "word": "saksocudan",
        "language": "tr"
    },
    {
        "word": "saksocular",
        "language": "tr"
    },
    {
        "word": "saksoculara",
        "language": "tr"
    },
    {
        "word": "saksocularda",
        "language": "tr"
    },
    {
        "word": "saksoculardan",
        "language": "tr"
    },
    {
        "word": "saksocuları",
        "language": "tr"
    },
    {
        "word": "saksocuların",
        "language": "tr"
    },
    {
        "word": "saksocunun",
        "language": "tr"
    },
    {
        "word": "saksocuya",
        "language": "tr"
    },
    {
        "word": "saksocuyu",
        "language": "tr"
    },
    {
        "word": "sıçmak",
        "language": "tr"
    },
    {
        "word": "sik",
        "language": "tr"
    },
    {
        "word": "sike",
        "language": "tr"
    },
    {
        "word": "siker sikmez",
        "language": "tr"
    },
    {
        "word": "siki",
        "language": "tr"
    },
    {
        "word": "sikilir sikilmez",
        "language": "tr"
    },
    {
        "word": "sikin",
        "language": "tr"
    },
    {
        "word": "sikler",
        "language": "tr"
    },
    {
        "word": "siklerde",
        "language": "tr"
    },
    {
        "word": "siklerden",
        "language": "tr"
    },
    {
        "word": "siklere",
        "language": "tr"
    },
    {
        "word": "sikleri",
        "language": "tr"
    },
    {
        "word": "siklerin",
        "language": "tr"
    },
    {
        "word": "sikmek",
        "language": "tr"
    },
    {
        "word": "sikmemek",
        "language": "tr"
    },
    {
        "word": "sikte",
        "language": "tr"
    },
    {
        "word": "sikten",
        "language": "tr"
    },
    {
        "word": "siktir",
        "language": "tr"
    },
    {
        "word": "siktirir siktirmez",
        "language": "tr"
    },
    {
        "word": "taşağa",
        "language": "tr"
    },
    {
        "word": "taşağı",
        "language": "tr"
    },
    {
        "word": "taşağın",
        "language": "tr"
    },
    {
        "word": "taşak",
        "language": "tr"
    },
    {
        "word": "taşaklar",
        "language": "tr"
    },
    {
        "word": "taşaklara",
        "language": "tr"
    },
    {
        "word": "taşaklarda",
        "language": "tr"
    },
    {
        "word": "taşaklardan",
        "language": "tr"
    },
    {
        "word": "taşakları",
        "language": "tr"
    },
    {
        "word": "taşakların",
        "language": "tr"
    },
    {
        "word": "taşakta",
        "language": "tr"
    },
    {
        "word": "taşaktan",
        "language": "tr"
    },
    {
        "word": "yarağa",
        "language": "tr"
    },
    {
        "word": "yarağı",
        "language": "tr"
    },
    {
        "word": "yarağın",
        "language": "tr"
    },
    {
        "word": "yarak",
        "language": "tr"
    },
    {
        "word": "yaraklar",
        "language": "tr"
    },
    {
        "word": "yaraklara",
        "language": "tr"
    },
    {
        "word": "yaraklarda",
        "language": "tr"
    },
    {
        "word": "yaraklardan",
        "language": "tr"
    },
    {
        "word": "yarakları",
        "language": "tr"
    },
    {
        "word": "yarakların",
        "language": "tr"
    },
    {
        "word": "yarakta",
        "language": "tr"
    },
    {
        "word": "yaraktan",
        "language": "tr"
    },
    {
        "word": "13.",
        "language": "zh"
    },
    {
        "word": "13点",
        "language": "zh"
    },
    {
        "word": "三级片",
        "language": "zh"
    },
    {
        "word": "下三烂",
        "language": "zh"
    },
    {
        "word": "下贱",
        "language": "zh"
    },
    {
        "word": "个老子的",
        "language": "zh"
    },
    {
        "word": "九游",
        "language": "zh"
    },
    {
        "word": "乳",
        "language": "zh"
    },
    {
        "word": "乳交",
        "language": "zh"
    },
    {
        "word": "乳头",
        "language": "zh"
    },
    {
        "word": "乳房",
        "language": "zh"
    },
    {
        "word": "乳波臀浪",
        "language": "zh"
    },
    {
        "word": "交配",
        "language": "zh"
    },
    {
        "word": "仆街",
        "language": "zh"
    },
    {
        "word": "他奶奶",
        "language": "zh"
    },
    {
        "word": "他奶奶的",
        "language": "zh"
    },
    {
        "word": "他奶娘的",
        "language": "zh"
    },
    {
        "word": "他妈",
        "language": "zh"
    },
    {
        "word": "他妈ㄉ王八蛋",
        "language": "zh"
    },
    {
        "word": "他妈地",
        "language": "zh"
    },
    {
        "word": "他妈的",
        "language": "zh"
    },
    {
        "word": "他娘",
        "language": "zh"
    },
    {
        "word": "他马的",
        "language": "zh"
    },
    {
        "word": "你个傻比",
        "language": "zh"
    },
    {
        "word": "你他马的",
        "language": "zh"
    },
    {
        "word": "你全家",
        "language": "zh"
    },
    {
        "word": "你奶奶的",
        "language": "zh"
    },
    {
        "word": "你她马的",
        "language": "zh"
    },
    {
        "word": "你妈",
        "language": "zh"
    },
    {
        "word": "你妈的",
        "language": "zh"
    },
    {
        "word": "你娘",
        "language": "zh"
    },
    {
        "word": "你娘卡好",
        "language": "zh"
    },
    {
        "word": "你娘咧",
        "language": "zh"
    },
    {
        "word": "你它妈的",
        "language": "zh"
    },
    {
        "word": "你它马的",
        "language": "zh"
    },
    {
        "word": "你是鸡",
        "language": "zh"
    },
    {
        "word": "你是鸭",
        "language": "zh"
    },
    {
        "word": "你马的",
        "language": "zh"
    },
    {
        "word": "做爱",
        "language": "zh"
    },
    {
        "word": "傻比",
        "language": "zh"
    },
    {
        "word": "傻逼",
        "language": "zh"
    },
    {
        "word": "册那",
        "language": "zh"
    },
    {
        "word": "军妓",
        "language": "zh"
    },
    {
        "word": "几八",
        "language": "zh"
    },
    {
        "word": "几叭",
        "language": "zh"
    },
    {
        "word": "几巴",
        "language": "zh"
    },
    {
        "word": "几芭",
        "language": "zh"
    },
    {
        "word": "刚度",
        "language": "zh"
    },
    {
        "word": "刚瘪三",
        "language": "zh"
    },
    {
        "word": "包皮",
        "language": "zh"
    },
    {
        "word": "十三点",
        "language": "zh"
    },
    {
        "word": "卖B",
        "language": "zh"
    },
    {
        "word": "卖比",
        "language": "zh"
    },
    {
        "word": "卖淫",
        "language": "zh"
    },
    {
        "word": "卵",
        "language": "zh"
    },
    {
        "word": "卵子",
        "language": "zh"
    },
    {
        "word": "双峰微颤",
        "language": "zh"
    },
    {
        "word": "口交",
        "language": "zh"
    },
    {
        "word": "口肯",
        "language": "zh"
    },
    {
        "word": "叫床",
        "language": "zh"
    },
    {
        "word": "吃屎",
        "language": "zh"
    },
    {
        "word": "后庭",
        "language": "zh"
    },
    {
        "word": "吹箫",
        "language": "zh"
    },
    {
        "word": "塞你公",
        "language": "zh"
    },
    {
        "word": "塞你娘",
        "language": "zh"
    },
    {
        "word": "塞你母",
        "language": "zh"
    },
    {
        "word": "塞你爸",
        "language": "zh"
    },
    {
        "word": "塞你老师",
        "language": "zh"
    },
    {
        "word": "塞你老母",
        "language": "zh"
    },
    {
        "word": "处女",
        "language": "zh"
    },
    {
        "word": "外阴",
        "language": "zh"
    },
    {
        "word": "大卵子",
        "language": "zh"
    },
    {
        "word": "大卵泡",
        "language": "zh"
    },
    {
        "word": "大鸡巴",
        "language": "zh"
    },
    {
        "word": "奶",
        "language": "zh"
    },
    {
        "word": "奶奶的熊",
        "language": "zh"
    },
    {
        "word": "奶子",
        "language": "zh"
    },
    {
        "word": "奸",
        "language": "zh"
    },
    {
        "word": "奸你",
        "language": "zh"
    },
    {
        "word": "她妈地",
        "language": "zh"
    },
    {
        "word": "她妈的",
        "language": "zh"
    },
    {
        "word": "她马的",
        "language": "zh"
    },
    {
        "word": "妈B",
        "language": "zh"
    },
    {
        "word": "妈个B",
        "language": "zh"
    },
    {
        "word": "妈个比",
        "language": "zh"
    },
    {
        "word": "妈个老比",
        "language": "zh"
    },
    {
        "word": "妈妈的",
        "language": "zh"
    },
    {
        "word": "妈比",
        "language": "zh"
    },
    {
        "word": "妈的",
        "language": "zh"
    },
    {
        "word": "妈的B",
        "language": "zh"
    },
    {
        "word": "妈逼",
        "language": "zh"
    },
    {
        "word": "妓",
        "language": "zh"
    },
    {
        "word": "妓女",
        "language": "zh"
    },
    {
        "word": "妓院",
        "language": "zh"
    },
    {
        "word": "妳她妈的",
        "language": "zh"
    },
    {
        "word": "妳妈的",
        "language": "zh"
    },
    {
        "word": "妳娘的",
        "language": "zh"
    },
    {
        "word": "妳老母的",
        "language": "zh"
    },
    {
        "word": "妳马的",
        "language": "zh"
    },
    {
        "word": "姘头",
        "language": "zh"
    },
    {
        "word": "姣西",
        "language": "zh"
    },
    {
        "word": "姦",
        "language": "zh"
    },
    {
        "word": "娘个比",
        "language": "zh"
    },
    {
        "word": "娘的",
        "language": "zh"
    },
    {
        "word": "婊子",
        "language": "zh"
    },
    {
        "word": "婊子养的",
        "language": "zh"
    },
    {
        "word": "嫖娼",
        "language": "zh"
    },
    {
        "word": "嫖客",
        "language": "zh"
    },
    {
        "word": "它妈地",
        "language": "zh"
    },
    {
        "word": "它妈的",
        "language": "zh"
    },
    {
        "word": "密洞",
        "language": "zh"
    },
    {
        "word": "射你",
        "language": "zh"
    },
    {
        "word": "小乳头",
        "language": "zh"
    },
    {
        "word": "小卵子",
        "language": "zh"
    },
    {
        "word": "小卵泡",
        "language": "zh"
    },
    {
        "word": "小瘪三",
        "language": "zh"
    },
    {
        "word": "小肉粒",
        "language": "zh"
    },
    {
        "word": "小骚比",
        "language": "zh"
    },
    {
        "word": "小骚货",
        "language": "zh"
    },
    {
        "word": "小鸡巴",
        "language": "zh"
    },
    {
        "word": "小鸡鸡",
        "language": "zh"
    },
    {
        "word": "屁眼",
        "language": "zh"
    },
    {
        "word": "屁股",
        "language": "zh"
    },
    {
        "word": "屄",
        "language": "zh"
    },
    {
        "word": "屌",
        "language": "zh"
    },
    {
        "word": "干x娘",
        "language": "zh"
    },
    {
        "word": "干七八",
        "language": "zh"
    },
    {
        "word": "干你",
        "language": "zh"
    },
    {
        "word": "干你妈",
        "language": "zh"
    },
    {
        "word": "干你娘",
        "language": "zh"
    },
    {
        "word": "干你老母",
        "language": "zh"
    },
    {
        "word": "干你良",
        "language": "zh"
    },
    {
        "word": "干妳妈",
        "language": "zh"
    },
    {
        "word": "干妳娘",
        "language": "zh"
    },
    {
        "word": "干妳老母",
        "language": "zh"
    },
    {
        "word": "干妳马",
        "language": "zh"
    },
    {
        "word": "干您娘",
        "language": "zh"
    },
    {
        "word": "干机掰",
        "language": "zh"
    },
    {
        "word": "干死CS",
        "language": "zh"
    },
    {
        "word": "干死GM",
        "language": "zh"
    },
    {
        "word": "干死你",
        "language": "zh"
    },
    {
        "word": "干死客服",
        "language": "zh"
    },
    {
        "word": "幹",
        "language": "zh"
    },
    {
        "word": "强奸",
        "language": "zh"
    },
    {
        "word": "强奸你",
        "language": "zh"
    },
    {
        "word": "性",
        "language": "zh"
    },
    {
        "word": "性器",
        "language": "zh"
    },
    {
        "word": "性无能",
        "language": "zh"
    },
    {
        "word": "性爱",
        "language": "zh"
    },
    {
        "word": "情色",
        "language": "zh"
    },
    {
        "word": "想上你",
        "language": "zh"
    },
    {
        "word": "懆您妈",
        "language": "zh"
    },
    {
        "word": "懆您娘",
        "language": "zh"
    },
    {
        "word": "懒8",
        "language": "zh"
    },
    {
        "word": "懒八",
        "language": "zh"
    },
    {
        "word": "懒叫",
        "language": "zh"
    },
    {
        "word": "懒教",
        "language": "zh"
    },
    {
        "word": "成人",
        "language": "zh"
    },
    {
        "word": "我操你祖宗十八代",
        "language": "zh"
    },
    {
        "word": "扒光",
        "language": "zh"
    },
    {
        "word": "打炮",
        "language": "zh"
    },
    {
        "word": "打飞机",
        "language": "zh"
    },
    {
        "word": "抽插",
        "language": "zh"
    },
    {
        "word": "招妓",
        "language": "zh"
    },
    {
        "word": "插你",
        "language": "zh"
    },
    {
        "word": "插死你",
        "language": "zh"
    },
    {
        "word": "撒尿",
        "language": "zh"
    },
    {
        "word": "操你",
        "language": "zh"
    },
    {
        "word": "操你全家",
        "language": "zh"
    },
    {
        "word": "操你奶奶",
        "language": "zh"
    },
    {
        "word": "操你妈",
        "language": "zh"
    },
    {
        "word": "操你娘",
        "language": "zh"
    },
    {
        "word": "操你祖宗",
        "language": "zh"
    },
    {
        "word": "操你老妈",
        "language": "zh"
    },
    {
        "word": "操你老母",
        "language": "zh"
    },
    {
        "word": "操妳",
        "language": "zh"
    },
    {
        "word": "操妳全家",
        "language": "zh"
    },
    {
        "word": "操妳妈",
        "language": "zh"
    },
    {
        "word": "操妳娘",
        "language": "zh"
    },
    {
        "word": "操妳祖宗",
        "language": "zh"
    },
    {
        "word": "操机掰",
        "language": "zh"
    },
    {
        "word": "操比",
        "language": "zh"
    },
    {
        "word": "操逼",
        "language": "zh"
    },
    {
        "word": "放荡",
        "language": "zh"
    },
    {
        "word": "日他娘",
        "language": "zh"
    },
    {
        "word": "日你",
        "language": "zh"
    },
    {
        "word": "日你妈",
        "language": "zh"
    },
    {
        "word": "日你老娘",
        "language": "zh"
    },
    {
        "word": "日你老母",
        "language": "zh"
    },
    {
        "word": "日批",
        "language": "zh"
    },
    {
        "word": "月经",
        "language": "zh"
    },
    {
        "word": "机八",
        "language": "zh"
    },
    {
        "word": "机巴",
        "language": "zh"
    },
    {
        "word": "机机歪歪",
        "language": "zh"
    },
    {
        "word": "杂种",
        "language": "zh"
    },
    {
        "word": "浪叫",
        "language": "zh"
    },
    {
        "word": "淫",
        "language": "zh"
    },
    {
        "word": "淫妇",
        "language": "zh"
    },
    {
        "word": "淫棍",
        "language": "zh"
    },
    {
        "word": "淫水",
        "language": "zh"
    },
    {
        "word": "淫秽",
        "language": "zh"
    },
    {
        "word": "淫荡",
        "language": "zh"
    },
    {
        "word": "淫西",
        "language": "zh"
    },
    {
        "word": "湿透的内裤",
        "language": "zh"
    },
    {
        "word": "激情",
        "language": "zh"
    },
    {
        "word": "灨你娘",
        "language": "zh"
    },
    {
        "word": "烂货",
        "language": "zh"
    },
    {
        "word": "烂逼",
        "language": "zh"
    },
    {
        "word": "爛",
        "language": "zh"
    },
    {
        "word": "狗屁",
        "language": "zh"
    },
    {
        "word": "狗日",
        "language": "zh"
    },
    {
        "word": "狗狼养的",
        "language": "zh"
    },
    {
        "word": "玉杵",
        "language": "zh"
    },
    {
        "word": "王八蛋",
        "language": "zh"
    },
    {
        "word": "瓜娃子",
        "language": "zh"
    },
    {
        "word": "瓜婆娘",
        "language": "zh"
    },
    {
        "word": "瓜批",
        "language": "zh"
    },
    {
        "word": "瘪三",
        "language": "zh"
    },
    {
        "word": "白烂",
        "language": "zh"
    },
    {
        "word": "白痴",
        "language": "zh"
    },
    {
        "word": "白癡",
        "language": "zh"
    },
    {
        "word": "祖宗",
        "language": "zh"
    },
    {
        "word": "私服",
        "language": "zh"
    },
    {
        "word": "笨蛋",
        "language": "zh"
    },
    {
        "word": "精子",
        "language": "zh"
    },
    {
        "word": "老二",
        "language": "zh"
    },
    {
        "word": "老味",
        "language": "zh"
    },
    {
        "word": "老母",
        "language": "zh"
    },
    {
        "word": "老瘪三",
        "language": "zh"
    },
    {
        "word": "老骚比",
        "language": "zh"
    },
    {
        "word": "老骚货",
        "language": "zh"
    },
    {
        "word": "肉壁",
        "language": "zh"
    },
    {
        "word": "肉棍子",
        "language": "zh"
    },
    {
        "word": "肉棒",
        "language": "zh"
    },
    {
        "word": "肉缝",
        "language": "zh"
    },
    {
        "word": "肏",
        "language": "zh"
    },
    {
        "word": "肛交",
        "language": "zh"
    },
    {
        "word": "肥西",
        "language": "zh"
    },
    {
        "word": "色情",
        "language": "zh"
    },
    {
        "word": "花柳",
        "language": "zh"
    },
    {
        "word": "荡妇",
        "language": "zh"
    },
    {
        "word": "賤",
        "language": "zh"
    },
    {
        "word": "贝肉",
        "language": "zh"
    },
    {
        "word": "贱B",
        "language": "zh"
    },
    {
        "word": "贱人",
        "language": "zh"
    },
    {
        "word": "贱货",
        "language": "zh"
    },
    {
        "word": "贼你妈",
        "language": "zh"
    },
    {
        "word": "赛你老母",
        "language": "zh"
    },
    {
        "word": "赛妳阿母",
        "language": "zh"
    },
    {
        "word": "赣您娘",
        "language": "zh"
    },
    {
        "word": "轮奸",
        "language": "zh"
    },
    {
        "word": "迷药",
        "language": "zh"
    },
    {
        "word": "逼",
        "language": "zh"
    },
    {
        "word": "逼样",
        "language": "zh"
    },
    {
        "word": "野鸡",
        "language": "zh"
    },
    {
        "word": "阳具",
        "language": "zh"
    },
    {
        "word": "阳萎",
        "language": "zh"
    },
    {
        "word": "阴唇",
        "language": "zh"
    },
    {
        "word": "阴户",
        "language": "zh"
    },
    {
        "word": "阴核",
        "language": "zh"
    },
    {
        "word": "阴毛",
        "language": "zh"
    },
    {
        "word": "阴茎",
        "language": "zh"
    },
    {
        "word": "阴道",
        "language": "zh"
    },
    {
        "word": "阴部",
        "language": "zh"
    },
    {
        "word": "雞巴",
        "language": "zh"
    },
    {
        "word": "靠北",
        "language": "zh"
    },
    {
        "word": "靠母",
        "language": "zh"
    },
    {
        "word": "靠爸",
        "language": "zh"
    },
    {
        "word": "靠背",
        "language": "zh"
    },
    {
        "word": "靠腰",
        "language": "zh"
    },
    {
        "word": "驶你公",
        "language": "zh"
    },
    {
        "word": "驶你娘",
        "language": "zh"
    },
    {
        "word": "驶你母",
        "language": "zh"
    },
    {
        "word": "驶你爸",
        "language": "zh"
    },
    {
        "word": "驶你老师",
        "language": "zh"
    },
    {
        "word": "驶你老母",
        "language": "zh"
    },
    {
        "word": "骚比",
        "language": "zh"
    },
    {
        "word": "骚货",
        "language": "zh"
    },
    {
        "word": "骚逼",
        "language": "zh"
    },
    {
        "word": "鬼公",
        "language": "zh"
    },
    {
        "word": "鸡8",
        "language": "zh"
    },
    {
        "word": "鸡八",
        "language": "zh"
    },
    {
        "word": "鸡叭",
        "language": "zh"
    },
    {
        "word": "鸡吧",
        "language": "zh"
    },
    {
        "word": "鸡奸",
        "language": "zh"
    },
    {
        "word": "鸡巴",
        "language": "zh"
    },
    {
        "word": "鸡芭",
        "language": "zh"
    },
    {
        "word": "鸡鸡",
        "language": "zh"
    },
    {
        "word": "龟儿子",
        "language": "zh"
    },
    {
        "word": "龟头",
        "language": "zh"
    }
]