import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { updateContactTags } from '../../actions/contactActions';
import ContactTagsAutocomplete from '../../components/autocompletes/contact-tags-autocomplete';
import { FaPlusSquare, FaEdit, FaTimesCircle, FaUserPlus, FaArrowLeft } from 'react-icons/fa';

class ContactTagsAlt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag_array: [],
            contact_batch: { _id: '', first_name: '', last_name: '', company: '' },
            contact_id: '',
            sub_id: '',
            contact_role: '',
            contact_role_custom: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggleTabInfo = this.toggleTabInfo.bind(this);
        this.toggleTabTags = this.toggleTabTags.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        contacts: PropTypes.object.isRequired,
    }

    componentDidMount(){
        if(this.props.sub_id){
            this.setState({
                sub_id: this.props.sub_id
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleClick = () => {
        this.props.toggle();
    };

    toggleTabTags() {
        this.setState({
            render: 'tags'
        })
    }
    toggleTabInfo() {
        this.setState({
            render: 'info'
        })
    }

    handleContactChange(contact) {
        if (contact) {
            const contact_batch = {
                _id: contact._id, first_name: contact.name.first, last_name: contact.name.last, company: contact.company
            }
            this.setState({
                contact_batch: contact_batch,
                contact_id: contact._id
            })
        } else {
            this.setState({
                contact_batch: { _id: '', first_name: '', last_name: '', company: '' },
                contact_id: ''
            });
        }
    }

    addTag = () => {
        const tag = {
            date: moment().format('MM/DD/YYYY'),
            contact_id: this.state.contact_id,
            role: this.state.contact_role
        }

        const newArray = []
        newArray.push(...this.state.tag_array, tag);
        const contact_batch = { _id: '', first_name: '', last_name: '', company: '' }

        this.setState({
            tag_array: newArray,
            contact_batch: contact_batch,
            contact_id: '',
            contact_role: ''
        })
    }

    updateTag = (props) => {
        const val = this.state.tag_array[props];
        this.props.contacts.contacts.map((item, index) => {
            if (item._id === val.contact_id) {
                const contact_batch = {
                    _id: item._id,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    company: item.company
                }
                this.setState({
                    contact_batch: contact_batch,
                    contact_id: item._id
                })
                const newArray = this.state.tag_array.filter((e, i) => i !== props);
                this.setState({
                    tag_array: newArray
                })
            }
        })
    }

    deleteTag = (props) => {
        const newArray = this.state.tag_array.filter((e, i) => i !== props);
        this.setState({
            tag_array: newArray
        })
    }

    saveTags = async () => {
        // var type = 'new';
        // this.props.contacts.contacts.map((item, inex) => {
        //     this.state.tag_array.map(t => {
        //         if (t.contact_id === item._id) {
        //             type = 'update'
        //         }
        //     })
        // })
        const tags = {
            tags: this.state.tag_array,
            sub_id: { tag: this.state.sub_id },
        }
        var log_status = await this.props.updateContactTags(tags);
        console.log(log_status)
        if (log_status.includes('Error:')) {
            alert(log_status);
        } else {
            this.props.toggleSub('contacts');
        }
    }

    toggleNewContact = () => {
        this.props.toggleNewModal('contact_modal', 'render_contact_data')
    }

    clearRole = () => {
        this.setState({
            contact_role: ''
        })
    }


    render() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 500 }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 450, alignItems: 'center' }}>
                    <table style={{ width: '90%', marginTop: 30 }} id="#customers">
                        <thead>
                            <tr>
                                <th style={{ width: '50%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Contact</th>
                                <th style={{ width: '50%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Role</th>
                            </tr>
                        </thead>
                    </table>
                    <table style={{ width: '90%' }} id="#customers">
                        <thead>
                            <tr>
                                <th style={{ width: '50%', backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd' }}>
                                    <ContactTagsAutocomplete tagsList={this.props.contacts.contacts} onChange={this.handleContactChange} tagSelect={this.state.contact_batch} />
                                </th>
                                <th style={{ width: '40%', backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                    {this.state.contact_role !== 'Custom' && this.props.type === 'client' ?
                                        <select style={{ width: '100%', height: '100%', backgroundColor: '#fff', color: '#000', fontSize: 12 }} type="text" name="contact_role" value={this.state.contact_role} onChange={this.handleChange}>
                                            <option value=""></option>
                                            <option value="Label Rep">Label Rep</option>
                                            <option value="Manager">Manager</option>
                                            <option value="Manager Assistant">Manager Assistant</option>
                                            <option value="Personal Assistant">Personal Assistant</option>
                                            <option value="Publicist">Publicist</option>
                                            <option value="Tour Manager">Tour Manager</option>
                                            <option value="Tour Manager Assistant">Tour Manager Assistant</option>
                                            <option value="Tour Crew">Tour Crew</option>
                                            <option value="Custom">Custom</option>
                                        </select> :
                                        this.state.contact_role !== 'Custom' ?
                                            <select style={{ width: '100%', height: '100%', backgroundColor: '#fff', color: '#000', fontSize: 12 }} type="text" name="contact_role" value={this.state.contact_role} onChange={this.handleChange}>
                                                <option value=""></option>
                                                <option value="Buyer">Buyer</option>
                                                <option value="Buyer">Buyer Assistant</option>
                                                <option value="Contract Admin">Contract Admin</option>
                                                <option value="Marketing">Marketing</option>
                                                <option value="Marketing Manager">Marketing Manager</option>
                                                <option value="Production">Production</option>
                                                <option value="Production Manager">Production Manager</option>
                                                <option value="Ticket Counts">Ticket Counts</option>
                                                <option value="Custom">Custom</option>
                                            </select> :
                                            <div style={{ width: '100%', height: '100%' }}>
                                                <input style={{ width: '92%', height: '100%', backgroundColor: '#fff', color: '#000', fontSize: 12 }} type="text" name="contact_role_custom" value={this.state.contact_role_custom} onChange={this.handleChange} />
                                                <span style={{ width: '8%', height: '100%', backgroundColor: 'rgb(1, 22, 48)' }} onClick={this.clearRole}>
                                                    <FaArrowLeft size="20" color="#cdcdcd" />
                                                </span>
                                            </div>}
                                </th>
                                <th style={{ width: '5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                                        <FaPlusSquare size="20" color="#cdcdcd" onClick={this.addTag} />
                                    </div>
                                </th>
                                <th style={{ width: '5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', border: '1px solid #ddd', height: 30 }}>
                                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                                        <FaUserPlus size="20" color="#cdcdcd" onClick={this.toggleNewContact} />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div style={{ marginTop: this.state.tag_array ? 15 : 0, minHeight: 50, maxHeight: 'auto', width: '90%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd' }}>
                        {this.state.tag_array.map((item, index) => {
                            var contact = [];
                            var company = [];
                            this.props.contacts.contacts.map((val, ind) => {
                                if (val._id === item.contact_id) {
                                    contact = val.name.first + " " + val.name.last;
                                    company = val.company
                                }
                            })
                            return (
                                <div style={{ border: '1px dashed gray', paddingLeft: '0.5rem', backgroundColor: '#f1f1f1', width: '100%' }}>
                                    <table style={{ width: '100%', height: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '15%', color: '#000', fontSize: 12, padding: 5, height: 50 }}>{item.date}</td>
                                                <td style={{ width: '79%', color: '#000', fontSize: 12, padding: 5 }}>{contact} ({item.role})</td>
                                                <td style={{ width: '6%', color: '#000' }}>
                                                    <FaEdit style={{ cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.updateTag(index)} />
                                                    <FaTimesCircle style={{ marginLeft: 10, cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.deleteTag(index)} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
                    <button style={{ width: 160, height: 30, backgroundColor: 'rgb(1, 22, 48)', color: '#fff', marginTop: 40, fontSize: 14, borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', cursor: 'pointer' }} onClick={() => this.props.toggleSub('contacts')}>Return</button>
                    <button style={{ width: 160, height: 30, backgroundColor: 'rgb(1, 22, 48)', color: '#fff', marginTop: 40, fontSize: 14, borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', cursor: 'pointer' }} onClick={this.saveTags}>Save</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    contacts: state.contacts,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { updateContactTags })(ContactTagsAlt);