import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { FaPlusSquare, FaTimesCircle } from 'react-icons/fa';
import ClientAutocomplete from '../../components/autocompletes/client-autocomplete';

class ClientTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sub_edit: []
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        contacts: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        packaging: PropTypes.object.isRequired,
    }

    componentDidMount() {

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    editSub = (index, name) => {
        var sub_edit = {
            [index]: name
        }
        this.setState({
            sub_edit: sub_edit
        })

    };

    render() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'auto', alignItems: 'center' }}>
                <table style={{ width: '90%', marginTop: 30 }} id="#customers">
                    <thead>
                        <tr>
                            <th style={{ width: '100%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>CLIENT TAGS</th>
                        </tr>
                    </thead>
                </table>
                <table style={{ width: '90%' }} id="#customers">
                    <thead>
                        <tr>
                            <th style={{ width: '39.7%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Client(s)</th>
                            <th style={{ width: '15.3%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Date Added</th>
                            <th style={{ width: '40%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}>Notes</th>
                            <th style={{ width: '5%', color: '#cdcdcd', fontSize: 12, backgroundColor: 'rgb(1, 22, 48)', padding: 5, border: '1px solid #ddd' }}> </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr> 
                            <th style={{ width: '39.7%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff', border: '1px solid #ddd' }}><ClientAutocomplete clientList={this.props.clients.clients} onChange={this.props.onClientChange} clientSelect={this.props.artistbatch} sub="packaging" /></th>
                            <th style={{ width: '15.3%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff', border: '1px solid #ddd', height: 30 }}>
                                <DatePicker
                                    className="react-datepicker"
                                    style={{ fontSize: 12 }}
                                    selected={this.props.date_submitted}
                                    onChange={this.props.onDateSubmitted}
                                /></th>
                            <th style={{ width: '40%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff', border: '1px solid #ddd', height: 30 }}>
                                <input style={{ width: '100%', height: '100%', fontSize: 12, color: '#000' }} type="text" name="submission_note" value={this.props.submission_note} onChange={this.props.onChange} /></th>
                            <th style={{ width: '5%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff', border: '1px solid #ddd' }}>
                                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={this.props.addSubmission}>
                                    <FaPlusSquare size="20" color="#cdcdcd" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div style={{ marginTop: this.props.tour_dates ? 15 : 0, minHeight: 50, maxHeight: 'auto', width: '90%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#cdcdcd' }}>
                    {this.props.submissions.map((item, index) => {
                        var date = moment(item.date_submitted).format('MM/DD/YYYY');
                        var client = '';
                        this.props.clients.clients.map((c, i) => {
                            if (item.client === c._id) {
                                client = c.client;
                            };
                        });
                        return (
                            <div style={{ border: '1px dashed gray', paddingLeft: '0.5rem', backgroundColor: '#f1f1f1', width: '100%' }}>
                                <table style={{ width: '100%', height: '100%' }}>
                                    <tbody>
                                        <tr>
                                            {this.state.sub_edit[item._id] === 'client' ?
                                                <td style={{ width: '39.7%', color: '#000', fontSize: 12, padding: 5, height: 50 }}><input style={{ fontSize: 12, width: '100%', height: '100%' }} type="text" value={client} onChange={(event) => this.props.updateSubsArray(index, 'client', event)} /></td> :
                                                // <td style={{ width: '39.7%', color: '#000', fontSize: 12, padding: 5, height: 50 }} onClick={this.props.edit ? () => this.editSub(item._id, 'client') : null}>{client}</td>}
                                                <td style={{ width: '39.7%', color: '#000', fontSize: 12, padding: 5, height: 50 }}>{client}</td>}
                                            {this.state.sub_edit[item._id] === 'date' ?
                                                <td style={{ width: '15.3%', color: '#000', fontSize: 12, padding: 5 }}>
                                                    <DatePicker
                                                        className="react-datepicker"
                                                        selected={item.date_submitted.toString() !== 'Invalid date' ? new Date(item.date_submitted) : ''}
                                                    //onChange={this.props.updateTourDateArray.bind(this, index)}
                                                    /></td> :
                                                // <td style={{ width: '15.3%', color: '#000', fontSize: 12, padding: 5 }} onClick={this.props.edit ? () => this.editSub(item._id, 'date') : null}>{date}</td>}
                                                <td style={{ width: '15.3%', color: '#000', fontSize: 12, padding: 5 }}>{date}</td>}
                                            {this.state.sub_edit[item._id] === 'notes' ?
                                                <td style={{ width: '39%', color: '#000', fontSize: 12, padding: 5 }}><input style={{ fontSize: 12, width: '100%', height: '100%' }} type="text" value={item.note} /></td> :
                                                // <td style={{ width: '39%', color: '#000', fontSize: 12, padding: 5 }} onClick={this.props.edit ? () => this.editSub(item._id, 'notes') : null}>{item.note}</td>}
                                                <td style={{ width: '39%', color: '#000', fontSize: 12, padding: 5 }}>{item.note}</td>}
                                            <td style={{ width: '6%', color: '#000' }}>
                                                <FaTimesCircle style={{ marginLeft: 20, cursor: 'pointer' }} size="18" color='#cdcdcd' onClick={() => this.props.deleteSubmission(index, client)} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    items: state.items,
    contacts: state.contacts,
    clients: state.clients,
    packaging: state.packaging,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {})(ClientTags);