import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FaMosque, FaShoppingCart, FaIdCardAlt, FaCaretUp, FaCaretDown, FaFlagCheckered, FaMicrophoneAlt, FaAtom, FaPlus } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(0),
        },
        '& :focus': {
            outline: 'none'
        },
    },
}));

export default function CalendarButtonGroup(props) {
    const classes = useStyles();

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: 'rgb(20, 20, 20)', borderBottom: '1px solid #333' }} className={classes.root}>
            <ButtonGroup
                orientation="vertical"
                color="default"
                variant="text"
                aria-label="outlined button group"
                style={{ width: '100%' }}
            >
                {/* <Button style={{ backgroundColor: 'rgb(20, 20, 20)', height: 'auto', width: '100%' }}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10 }}><FaPlus size="20" color={'#fff'} /><p style={{ fontSize: 9, color: '#fff', borderBottom: '2px solid #cdcdcd' }}>Add</p></div></Button> */}
                <Button style={{ backgroundColor: 'rgb(20, 20, 20)', height: 'auto', width: '100%', borderTop: '1px solid #333' }}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10 }} onClick={props.toggleCalendarFilter.bind(this, "confirmed")}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ color: 'blue' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ color: 'blue' }} fontSize="small" />}
                        color="default"
                        checked={props.calendar_filter.confirmed || false}
                    //onChange={props.toggleCalendarFilter.bind(this, "confirmed")}
                    /><p style={{ fontSize: 8, color: '#fff', borderBottom: '2px solid blue' }}>Confirmed</p></div></Button>
                <Button style={{ backgroundColor: 'rgb(20, 20, 20)', height: 'auto', width: '100%', borderTop: '1px solid #333' }}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10 }} onClick={props.toggleCalendarFilter.bind(this, "pending")}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ color: '#555' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ color: '#555' }} fontSize="small" />}
                        color="default"
                        checked={props.calendar_filter.pending || false}
                    //onChange={props.toggleCalendarFilter.bind(this, "pending")}
                    /><p style={{ fontSize: 8, color: '#fff', borderBottom: '2px solid #555' }}>Pending</p></div></Button>
                <Button style={{ backgroundColor: 'rgb(20, 20, 20)', height: 'auto', width: '100%', borderTop: '1px solid #333' }}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10 }} onClick={props.toggleCalendarFilter.bind(this, "cancelled")}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ color: 'red' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ color: 'red' }} fontSize="small" />}
                        color="default"
                        checked={props.calendar_filter.cancelled || false}
                    //onChange={props.toggleCalendarFilter.bind(this, "cancelled")}
                    /><p style={{ fontSize: 8, color: '#fff', borderBottom: '2px solid red' }}>Cancelled</p></div></Button>
                <Button style={{ backgroundColor: 'rgb(20, 20, 20)', height: 'auto', width: '100%', borderTop: '1px solid #333' }}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10 }} onClick={props.toggleCalendarFilter.bind(this, "holds")}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ color: '#ddd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ color: '#ddd' }} fontSize="small" />}
                        color="default"
                        checked={props.calendar_filter.holds || false}
                    //onChange={props.toggleCalendarFilter.bind(this, "holds")}
                    /><p style={{ fontSize: 8, color: '#fff', borderBottom: '2px solid #ddd' }}>Holds</p></div></Button>
                <Button style={{ backgroundColor: 'rgb(20, 20, 20)', height: 'auto', width: '100%', borderTop: '1px solid #333', borderBottom: '1px solid #333' }}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10 }} onClick={props.toggleCalendarFilter.bind(this, "announce")}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ color: 'orange' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ color: 'orange' }} fontSize="small" />}
                        color="default"
                        checked={props.calendar_filter.announce || false}
                    //onChange={props.toggleCalendarFilter.bind(this, "cal_entries")}
                    /><p style={{ fontSize: 8, color: '#fff', borderBottom: '2px solid orange' }}>Announce / On Sale</p></div></Button>
                <Button style={{ backgroundColor: 'rgb(20, 20, 20)', height: 'auto', width: '100%', borderTop: '1px solid #333', borderBottom: '1px solid #333' }}><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10 }} onClick={props.toggleCalendarFilter.bind(this, "cal_entries")}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ color: 'green' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ color: 'green' }} fontSize="small" />}
                        color="default"
                        checked={props.calendar_filter.cal_entries || false}
                    //onChange={props.toggleCalendarFilter.bind(this, "cal_entries")}
                    /><p style={{ fontSize: 8, color: '#fff', borderBottom: '2px solid green' }}>Cal Entries</p></div></Button>
            </ButtonGroup>
        </div>
    );
}