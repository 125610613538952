import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { green, blue } from '@material-ui/core/colors';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import profileIcon from '../../assets/profileIcon.png';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    blue: {
        color: '#fff',
        backgroundColor: blue[500],
    },
    paper: {
        border: '1px solid #cdcdcd',
        padding: theme.spacing(1),
        backgroundColor: '#000',
        color: '#cdcdcd',
        fontSize: 12
    },
    typography: {
        padding: theme.spacing(2),
    },
    popover: {
        marginTop: 10,
      },
}));

export default function UserAvatar(props) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    //<Avatar className={classes.orange}>J</Avatar>
    const initial = props.user.name.first.charAt(0).toUpperCase();

    return (
        <div className={classes.root}>
            <img width="60" height="45" src={profileIcon} />
            <h1 style={{fontSize: 18, position: 'absolute', marginTop: 8, marginLeft: 20, color: '#fff'}}>{initial}</h1>
        </div>
    );
}