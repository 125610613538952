import React, { Component } from "react";
import { addClient } from '../actions/clientActions';
import { removeContactTag, removeContactTagSocket } from '../actions/contactActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import socketIOClient from "socket.io-client";
import ContactAccordion from '../modals/modal-components/render-contacts.accordion';
import ContactTagsAlt from '../modals/modal-components/contact-tags-alt.modal-component';
import _ from 'lodash';

class RenderContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactarray: [],
            addcontact: false,
            checkedItems: new Map(),
            render: 'info',
            clientname: '',
            city: '',
            state: '',
            country: '',
            agent1: '',
            agent2: '',
            agent3: '',
            agent4: '',
            website: '',
            facebook: '',
            instagram: '',
            twitter: '',
            youtube: '',
            bandcamp: '',
            spotify: '',
            applemusic: '',
            toggle_new: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
    }

    componentDidMount() {
        var contact_array = [];
        this.props.contacts.contacts.map(item => {
            item.tags.map(tag => {
                if (tag.tag === this.props.id) {
                    contact_array.push({ ...item, role: tag.role })
                }
            })
        })
        contact_array = _.orderBy(contact_array, [`name.last`.toLowerCase()], ['asc']);
        this.setState({
            contactarray: contact_array,
        })
        const id = this.props.id;
        const socket = this.props.auth.socket;
        if (socket) {
            socket.on(`new_contact_tags/${id}`, (contact) => {
                //this.props.updateContactSocket(contact);
                console.log(contact)
                var contact_array = [];
                contact.tags.map(tag => {
                    if (tag.tag === this.props.id) {
                        contact_array.push({ ...contact, role: tag.role })
                    }
                })
                contact_array = [...this.state.contactarray, ...contact_array];
                console.log(contact_array)
                //contact_array = [...this.state.contactarray, contact_array];
                contact_array = _.orderBy(contact_array, [`name.last`.toLowerCase()], ['asc']);
                this.setState({
                    contactarray: contact_array
                })
            })
            socket.on(`updated_contact_tags/${id}`, (contact) => {
                console.log(contact)
                //this.props.updateContactSocket(contact);
                var data = this.state.contactarray.filter(cts => cts._id !== contact._id)
                console.log(data)
                this.setState({
                    contactarray: data
                })
            })
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleCheckboxChange(event) {
        var isChecked = event.target.checked;
        var item = event.target.value;

        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    }
    handleCheckboxChangeUn(event) {
        var item = event.target.value;
        var isChecked = event.target.checked;
        this.state.checkedItems.delete(item, isChecked)

        this.setState(({ checkedItems: this.state.checkedItems }))
    }

    handleClick = () => {
        this.props.toggle();
    };

    toggleNewTag = () => {
        this.setState({
            toggle_new: !this.state.toggle_new
        })
    }

    removeTag = async (index) => {
        var _id = this.state.contactarray[index]._id

        const tags = {
            tag: this.props.id,
            _id: _id,
            send_type: 'tag'
        }
        console.log(tags)
        var log_status = await this.props.removeContactTag(tags);
        console.log(log_status)
        if (log_status.includes('Error:')) {
            alert(log_status);
        } else {
            //this.props.toggleSub('contacts');
        }
    }

    render() {

        return (
            <div style={{ width: '100%', height: 500 }}>
                {!this.state.toggle_new ?
                    <div style={{ width: '100%', height: '100%' }}>
                        <h2 style={{ marginTop: 30, color: '#000', fontSize: 18, marginLeft: 60, height: 15 }}>Contacts:</h2>
                        {this.state.contactarray.length > 0 ?
                            <div style={{ minHeight: 420, maxHeight: 420, overflowY: 'scroll', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <ContactAccordion contactarray={this.state.contactarray} removeTag={this.removeTag} />
                            </div> :
                            <div style={{ minHeight: 420, maxHeight: 420, overflowY: 'scroll', width: '100%' }}>
                                <h2 style={{ fontSize: 14, color: '#000', marginLeft: 60, marginTop: 15 }}>No contacts tagged...</h2>
                            </div>}

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
                            <button style={{ width: 160, height: 30, backgroundColor: 'rgb(1, 22, 48)', color: '#fff', marginTop: 10, fontSize: 14, borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }} onClick={this.toggleNewTag}>Tag a contact</button>
                        </div>

                    </div>
                    :
                    <ContactTagsAlt toggleSub={this.toggleNewTag} sub_id={this.props.id} toggleNewModal={this.props.toggleNewModal} type={this.props.type ? this.props.type : 'null'} />}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    items: state.items,
    contacts: state.contacts,
    auth: state.auth
});

export default connect(mapStateToProps, { addClient, removeContactTag, removeContactTagSocket })(RenderContacts);