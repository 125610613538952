import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import { Line, Bar } from 'react-chartjs-2';


export default function StatsScreen(props) {

    var colors = props.graph_data.map(x => getRandomColor());

    const data = {
        labels: props.graph_data.map(x => x.x),
        datasets: [
            {
                label: 'Guarantees',
                backgroundColor: colors,
                borderColor: 'black',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: props.graph_data.map(x => x.y)
            }
        ]
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={{ height: '100%', backgroundColor: '#fff', overflowY: 'scroll', overflowX: 'hidden', borderRight: '2px solid #333', borderBottom: '2px solid #333', borderTop: '2px solid #333', borderLeft: '2px solid #333', width: '100%' }}>
                {/* <div style={{ paddingBottom: 20, borderBottom: '2px solid #333', backgroundColor: '#111', width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', paddingTop: 20, marginLeft: 20 }}>
                        <div style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                            <h2 style={{ fontSize: 14, color: '#ddd', marginTop: 5, width: 100 }}>Agent:</h2>
                            <select style={{ width: 200, height: 24, fontSize: 12 }} name="agent_select" value={props.agent_select} onChange={props.handleAgentSelect}>
                                <option value="">All</option>
                                {props.agents.map((item, index) => {
                                    return (
                                        <option value={item.tag}>{item.name.first} {item.name.last}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div style={{ width: 'auto', display: 'flex', alignItems: 'center', marginTop: 20, marginLeft: 20 }}>
                        <div style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                            <h2 style={{ fontSize: 14, color: '#ddd', marginTop: 5, width: 100 }}>Start Date:</h2>
                            <div style={{ width: 200, height: 23 }}>
                                <DatePicker
                                    className="react-datepicker"
                                    style={{ fontSize: 12 }}
                                    value={props.startDate}
                                    selected={props.startDate}
                                    onChange={props.handleStartDateChange}
                                    dateFormat="EEE. MMMM d, yyyy"
                                    todayButton="Today"
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        <div style={{ width: 'auto', display: 'flex', alignItems: 'center', marginLeft: 50 }}>
                            <h2 style={{ fontSize: 14, color: '#ddd', marginTop: 5, width: 100 }}>End Date:</h2>
                            <div style={{ width: 200, height: 23 }}>
                                <DatePicker
                                    className="react-datepicker"
                                    style={{ fontSize: 12 }}
                                    value={props.endDate}
                                    selected={props.endDate}
                                    onChange={props.handleEndDateChange}
                                    dateFormat="EEE. MMMM d, yyyy"
                                    todayButton="Today"
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div style={{ width: '100%', height: 'auto', display: 'flex' }}>
                    <div style={{ width: '30%', height: 'auto' }}>
                        <table style={{ marginLeft: '2.5%', marginRight: '2.5%', marginTop: 30, width: '95%' }} id="admin_totals">
                            <thead>
                                <tr>
                                    <th style={{ width: '100%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff' }}>Confirmed (Settled)</th>
                                </tr>
                            </thead>
                        </table>
                        <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="admin_totals">
                            <tbody>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>No. of Deals</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.no_deals} thousandSeparator={true} /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>No. of Shows</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.no_shows} thousandSeparator={true} /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>Guarantees</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.guarantees} thousandSeparator={true} prefix={'$'} /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>Gross Earnings</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.gross_earnings} thousandSeparator={true} prefix={'$'} /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>Agency Commission</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.commissions} thousandSeparator={true} prefix={'$'} /></th>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ marginLeft: '2.5%', marginRight: '2.5%', marginTop: 10, width: '95%' }} id="admin_totals">
                            <thead>
                                <tr>
                                    <th style={{ width: '100%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff' }}>Confirmed (Unsettled)</th>
                                </tr>
                            </thead>
                        </table>
                        <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="admin_totals">
                            <tbody>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>No. of Deals</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.no_deals_us} thousandSeparator={true} /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>No. of Shows</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.no_shows_us} thousandSeparator={true} /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>Guarantees</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.guarantees_us} thousandSeparator={true} prefix={'$'} /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>Gross Earnings</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.gross_earnings_us} thousandSeparator={true} prefix={'$'} /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>Agency Commission</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.commissions_us} thousandSeparator={true} prefix={'$'} /></th>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ marginLeft: '2.5%', marginRight: '2.5%', marginTop: 10, width: '95%' }} id="admin_totals">
                            <thead>
                                <tr>
                                    <th style={{ width: '100%', backgroundColor: 'rgb(1, 22, 48)', color: '#fff' }}>Pending</th>
                                </tr>
                            </thead>
                        </table>
                        <table style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '95%' }} id="admin_totals">
                            <tbody>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>No. of Deals</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.pending.no_deals} thousandSeparator={true} /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>No. of Shows</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.pending.no_shows} thousandSeparator={true} /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>Guarantees</th>
                                    <th style={{ width: '50%' }}><NumberFormat style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 150 }} displayType="text" disabled={true} value={props.pending.guarantees} thousandSeparator={true} prefix={'$'}/></th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', border: '1px solid rgb(1, 22, 48)', color: '#000' }}>Walkout Potential</th>
                                    <th style={{ width: '50%' }}> </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ width: '70%', height: '100%', display: 'flex', justifyContent: 'center', overflowY: 'scroll', overflowX: 'scroll' }}>
                        <div style={{ width: 550, height: 'auto', marginTop: 40 }}>
                            <Bar
                                width={550}
                                height={350}
                                data={data}
                                options={{
                                    maintainAspectRatio: false, legend: {
                                        display: false
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}