import React, { Component } from "react";
import satellite from '../../assets/satellite.png';

class LoadingMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        //<img src={satellite} width={200} height={200} />

        return (
            <div style={{ width: '100%', height: '100vh', backgroundColor: '#000', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <h2 style={{ color: '#cdcdcd', fontSize: 18, fontWeight: 'bold', marginTop: 26 }}>Loading...</h2>
                    <div className="spinner-border text-secondary" role="status">
                    </div>
                </div>
            </div>
        );
    }
}


export default LoadingMain;