import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    COUNTS_LOADING,
    GET_COUNTS,
    LOAD_COUNTS_ROL,
    FILTER_COUNTS,
    SORT_COUNTS,
    ADD_COUNT,
    ADD_COUNT_SOCKET,
    ADD_COUNT_BULK_SOCKET,
    UPDATE_COUNT,
    DELETE_COUNT,
    COUNT_COMPARE,
    COUNT_FETCH,
    SET_COUNTS_GRID,
    UPDATE_COUNTS_GRID
} from "./types";
import _ from 'lodash';
import moment from 'moment';

export const setCountsLoading = () => {
    return {
        type: COUNTS_LOADING
    };
};

export const getCounts = id => (dispatch, getState) => {
    //dispatch(setCountsLoading());
    return axios.get(`${process.env.REACT_APP_API_CLIENT}/counts/${id}`, tokenConfig(getState))
        .then(res => {
            if (res.data) {
                dispatch({
                    type: GET_COUNTS,
                    payload: res.data.counts
                })
                return res.data.counts
            } else {
                dispatch({
                    type: GET_COUNTS,
                    payload: null
                })
                return null
            }
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};

export const addCountSocket = counts => (dispatch) => {
    dispatch({
        type: ADD_COUNT_SOCKET,
        payload: counts.counts
    })
}

export const addCountBulkSocket = counts => (dispatch) => {
    dispatch({
        type: ADD_COUNT_BULK_SOCKET,
        payload: counts
    })
}

export const countCompare = (ids) => (dispatch, getState) => {
    dispatch(setCountsLoading());
    axios.post(`${process.env.REACT_APP_API_CLIENT}/counts/fetch`, ids, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: COUNT_COMPARE,
                payload: res.data
            })
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const countFetch = (ids) => (dispatch, getState) => {
    //dispatch(setCountsLoading());
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/counts/fetch`, ids, tokenConfig(getState))
        .then(res => {
            return res.data
            // dispatch({
            //     type: COUNT_FETCH,
            //     payload: res.data
            // })
        })
        .catch(err => {
            return err.response.data
            //dispatch(returnErrors(err.response.data, err.response.status))
        });
};
