import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function UserAvatar(props) {
  const classes = useStyles();
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const handleImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      // props.setUserPhoto(e);
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
        console.log(current, e)
      };
      reader.readAsDataURL(file);
      props.loadImage(file);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: '100%', color: '#cdcdcd', marginTop: 20, fontSize: 10 }}>
      <input type="file" accept="image/*" onChange={handleImageUpload} ref={imageUploader} style={{ display: "none" }} />
      <div style={{ height: 190, width: 190, backgroundColor: '#cdcdcd', borderRadius: 140 }} onClick={props.edit ? () => imageUploader.current.click() : null}>
        <img ref={uploadedImage} src={props.image} style={{ width: "100%", height: "100%", position: "relative", borderRadius: 140 }} />
      </div>
      {props.edit ?
        <p>Click to upload Image</p> : <p> </p>}
    </div>
  );
}