import React from 'react';
import {
    OFFERS_LOADING,
    OFFERS_GET,
    OFFERS_DB_INIT,
    OFFERS_DASH_SET,
    OFFERS_CAL_SET,
    OFFERS_SORT,
    OFFERS_FILTER_RESET,
    OFFERS_FILTER,
    OFFERS_SOCKET,
    OFFERS_SOCKET_DELETE
} from "../actions/types";
import _ from 'lodash';
import moment from 'moment';

const initialState = {
    offers: [],
    filtered_offers: [],
    dashboard_offers: [],
    dashboard: {
        render: 'Upcoming Shows',
        grouped: true,
    },
    filter: {
        deal_id: '',
        status: 'Confirmed',
        client: '',
        agent: '',
        start_date: moment().format('MM/DD/YYYY'),
        end_date: moment().add(1, 'years').format('MM/DD/YYYY'),
        venue: '',
        city: '',
        state: '',
        country: ''
    },
    calendar_set: [],
    loading: false,
    sort: {
        cat: '',
        dir: 'asc'
    },
    status: '',
    init: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case OFFERS_LOADING:
            return {
                ...state,
                loading: true
            };
        case OFFERS_GET:
            var offersDateSort = _.sortBy(action.payload, function (dateObj) {
                return new Date(dateObj.show_info[0].date);
            });
            return {
                ...state,
                offers: offersDateSort,
                loading: false
            };
        case OFFERS_DB_INIT:
            // var dashboard = _.orderBy(action.payload, ['artist_name'], ['asc']);

            // const dashFunc = (list) => {
            //     new Promise(async (resolve, reject) => {
            //         var dashboard = _.orderBy(list, ['artist_name'], ['asc']);
            //         resolve(dashboard)
            //     })
            // }
            // const databaseFunc = (list) => {
            //     new Promise(async (resolve, reject) => {
            //         var database = _.sortBy(list, function (dateObj) {
            //             return new Date(dateObj.show_info[0].date);
            //         }).filter((item) => {
            //             const today = moment();
            //             var val = _.get(item, 'show_info')
            //             const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            //             var filterBatch = new Date(closest.date)
            //             var start = new Date(start_date)
            //             return filterBatch > start
            //         }).filter((item) => {
            //             const today = new Date();
            //             var val = _.get(item, 'show_info')
            //             const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            //             var filterBatch = new Date(furthest.date)
            //             var end = new Date(end_date)
            //             return filterBatch <= end
            //         }).filter((item) => {
            //             var filterBatch = item.status
            //             return filterBatch.indexOf(state.filter.status) >= 0
            //         })
            //         resolve(database)
            //     })
            // }

            // const pay = action.payload;


            // const databaseFunc = (list) => {
            //     new Promise(async (resolve, reject) => {
            //         var db = _.sortBy(list, function (dateObj) {
            //             return new Date(dateObj.show_info[0].date);
            //         });
            //         resolve(db)
            //     })
            // }


            // var dashboard = dashFunc(pay)
            // var database = databaseFunc(pay);

            // var end_date = moment().add(1, 'years').format('MM/DD/YYYY');
            // var start_date = moment().add(-1, 'days').format('MM/DD/YYYY');


            // var database = _.sortBy(pay, function (dateObj) {
            //     return new Date(dateObj.show_info[0].date);
            // }).filter((item) => {
            //     const today = moment();
            //     var val = _.get(item, 'show_info')
            //     const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
            //     var filterBatch = new Date(closest.date)
            //     var start = new Date(start_date)
            //     return filterBatch > start
            // }).filter((item) => {
            //     const today = new Date();
            //     var val = _.get(item, 'show_info')
            //     const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
            //     var filterBatch = new Date(furthest.date)
            //     var end = new Date(end_date)
            //     return filterBatch <= end
            // }).filter((item) => {
            //     var filterBatch = item.status
            //     return filterBatch.indexOf(state.filter.status) >= 0
            // })

            // filtered_end = filtered_end.filter((item) => {
            //     var filterBatch = item.status
            //     return filterBatch.indexOf('Pending') >= 0 || filterBatch.indexOf('Confirmed') >= 0
            // })
            return {
                ...state,
                dashboard_offers: action.payload.dashboard,
                offers: action.payload.pay,
                filtered_offers: action.payload.database,
                loading: false,
                init: true
            };
        case OFFERS_DASH_SET:
            var dashboard = _.orderBy(state.offers, ['artist_name'], ['asc']);
            return {
                ...state,
                dashboard_offers: dashboard,
                loading: false
            };
        case OFFERS_CAL_SET:
            return {
                ...state,
                calendar_set: state.offers,
                loading: false
            };
        case OFFERS_SORT:
            var sort = action.payload;
            var sortedOffers = [];
            if (sort.cat === '') {
                sortedOffers = _.sortBy(state.filtered_offers, function (dateObj) {
                    return new Date(dateObj.show_info[0].date);
                });
            } else {
                sortedOffers = _.orderBy(state.filtered_offers, [`${sort.cat}`.toLowerCase()], [sort.dir]);
            }
            return {
                ...state,
                filtered_offers: sortedOffers,
                sort: sort,
                loading: false
            };
        case OFFERS_FILTER_RESET:
            var end_date = moment().add(1, 'years').format('MM/DD/YYYY');
            var start_date = moment().add(-1, 'days').format('MM/DD/YYYY');
            var f_data = state.offers;
            f_data = f_data.filter((item) => {
                const today = moment();
                var val = _.get(item, 'show_info')
                const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                var filterBatch = new Date(closest.date)
                var start = new Date(start_date)
                return filterBatch > start
            })
            f_data = f_data.filter((item) => {
                const today = new Date();
                var val = _.get(item, 'show_info')
                const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                var filterBatch = new Date(furthest.date)
                var end = new Date(end_date)
                return filterBatch <= end
            })
            // filtered_end = filtered_end.filter((item) => {
            //     var filterBatch = item.status
            //     return filterBatch.indexOf('Pending') >= 0 || filterBatch.indexOf('Confirmed') >= 0
            // })
            return {
                ...state,
                filtered_offers: f_data,
                filter: [],
                loading: false
            };
        case OFFERS_FILTER:
            var offer_filter = action.payload;
            var f_data = state.offers;
            if (offer_filter.start_date) {
                f_data = f_data.filter((item) => {
                    const today = moment().add(-1, 'days');
                    var val = _.get(item, 'show_info')
                    const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                    var filterBatch = new Date(closest.date)
                    var start = offer_filter.start_date
                    return filterBatch >= start
                })
            }
            if (offer_filter.end_date) {
                f_data = f_data.filter((item) => {
                    const today = new Date();
                    var val = _.get(item, 'show_info')
                    const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                    var filterBatch = new Date(furthest.date)
                    var end = offer_filter.end_date
                    return filterBatch <= end
                })
            }
            console.log(f_data, offer_filter.client)
            if (offer_filter.client) {
                f_data = f_data.filter((item) => {
                    var filterBatch = item.client_id
                    console.log(filterBatch)
                    return filterBatch.indexOf(offer_filter.client) >= 0
                })
            }
            if (offer_filter.deal_id) {
                f_data = f_data.filter((item) => {
                    var filterBatch = item._id
                    return filterBatch.indexOf(offer_filter.deal_id) >= 0
                })
            }
            if (offer_filter.status) {
                f_data = f_data.filter((item) => {
                    var filterBatch = item.status
                    return filterBatch.indexOf(offer_filter.status) >= 0
                })
            }
            //FILTER MULTI STATUS (need to add sort function)
            // var filteredStatus;
            // offer_filter.status.forEach(it => {
            //     var fB = filteredId.filter((item) => {
            //         var filterBatch = item.status
            //         return filterBatch.indexOf(it) >= 0
            //     })
            //     if(filteredStatus){
            //         filteredStatus = [...filteredStatus, ...fB]
            //     } else {
            //         filteredStatus = fB
            //     }
            // })
            if (offer_filter.venue) {
                f_data = f_data.filter((item) => {
                    var filterBatch = item.venue_name
                    return filterBatch.indexOf(offer_filter.venue) >= 0
                })
            }
            if (offer_filter.state) {
                f_data = f_data.filter((item) => {
                    var filterBatch = item.venue_state
                    return filterBatch.indexOf(offer_filter.state) >= 0
                })
            }
            if (offer_filter.city) {
                f_data = f_data.filter((item) => {
                    var filterBatch = item.venue_city
                    return filterBatch.indexOf(offer_filter.city) >= 0
                })
            }
            if (offer_filter.country) {
                f_data = f_data.filter((item) => {
                    var filterBatch = item.venue_country
                    return filterBatch.indexOf(offer_filter.country) >= 0
                })
            }
            return {
                ...state,
                filtered_offers: f_data,
                filter: offer_filter,
                loading: false
            };
        // case ADD_OFFER:
        //     return {
        //         ...state,
        //         status: action.payload,
        //     };
        // case SADD_OFFER:
        //     var offer_filter = state.filter;
        //     var newArray = [...state.offers, action.payload];
        //     var filtered_start = newArray.filter((item) => {
        //         const today = moment();
        //         var val = _.get(item, 'show_info')
        //         const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
        //         var filterBatch = new Date(closest.date)
        //         var start = new Date(offer_filter.start_date)
        //         return filterBatch > start
        //     })
        //     var filtered_end = filtered_start.filter((item) => {
        //         const today = new Date();
        //         var val = _.get(item, 'show_info')
        //         const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
        //         var filterBatch = new Date(furthest.date)
        //         var end = new Date(offer_filter.end_date)
        //         return filterBatch <= end
        //     })
        //     var filteredArtist = filtered_end.filter((item) => {
        //         if (item.client_id) {
        //             var filterBatch = item.client_id
        //             return filterBatch.indexOf(offer_filter.client) >= 0
        //         } else {
        //         }
        //     })
        //     var filteredId = filteredArtist.filter((item) => {
        //         var filterBatch = item._id
        //         return filterBatch.indexOf(offer_filter.deal_id) >= 0
        //     })
        //     var filteredStatus = filteredId.filter((item) => {
        //         var filterBatch = item.status
        //         return filterBatch.indexOf(offer_filter.status) >= 0
        //     })
        //     var filteredVenue = filteredStatus.filter((item) => {
        //         var filterBatch = item.venue_name
        //         return filterBatch.indexOf(offer_filter.venue) >= 0
        //     })
        //     var filteredState = filteredVenue.filter((item) => {
        //         var filterBatch = item.venue_state
        //         return filterBatch.indexOf(offer_filter.state) >= 0
        //     })
        //     var filteredCity = filteredState.filter((item) => {
        //         var filterBatch = item.venue_city
        //         return filterBatch.indexOf(offer_filter.city) >= 0
        //     })
        //     var filteredCountry = filteredCity.filter((item) => {
        //         var filterBatch = item.venue_country
        //         return filterBatch.indexOf(offer_filter.country) >= 0
        //     })
        //     var sort = state.sort;
        //     var sortedOffers = [];
        //     if (sort.cat === '') {
        //         sortedOffers = _.sortBy(filteredCountry, function (dateObj) {
        //             return new Date(dateObj.show_info[0].date);
        //         });
        //     } else {
        //         sortedOffers = _.sortBy(filteredCountry, function (dateObj) {
        //             return new Date(dateObj.show_info[0].date);
        //         });
        //         sortedOffers = _.orderBy(sortedOffers, [`${sort.cat}`.toLowerCase()], [sort.dir]);
        //     }
        //     return {
        //         ...state,
        //         offers: [action.payload, ...state.offers],
        //         filtered_offers: sortedOffers
        //     };
        // case UPDATE_OFFER:
        //     var index = data.findIndex(obj => obj._id === action.payload._id);
        //     data[index] = action.payload;

        //     var found = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //     if (found >= 0) {
        //         var filtered_index = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //         filtered_data[filtered_index] = action.payload;
        //     }

        //     return {
        //         ...state,
        //         offers: data,
        //         filtered_offers: filtered_data
        //     };
        case OFFERS_SOCKET:
            var data = state.offers;
            var f_data = state.filtered_offers;
            var index = data.findIndex(obj => obj._id === action.payload._id);
            var f_index = f_data.findIndex(obj => obj._id === action.payload._id);
            if (index >= 0) {
                data[index] = action.payload;
                if (f_index >= 0) {
                    f_data[f_index] = action.payload;
                }
            } else {
                data = [action.payload, ...data];
                var offer_filter = state.filter;
                f_data = data;
                if (offer_filter.start_date) {
                    f_data = f_data.filter((item) => {
                        const today = moment();
                        var val = _.get(item, 'show_info')
                        const closest = val.reduce((a, b) => new Date(a.date) - new Date(today) < new Date(b.date) - new Date(today) ? a : b);
                        var filterBatch = new Date(closest.date)
                        var start = new Date(offer_filter.start_date)
                        return filterBatch > start
                    })
                }
                if (offer_filter.end_date) {
                    f_data = f_data.filter((item) => {
                        const today = new Date();
                        var val = _.get(item, 'show_info')
                        const furthest = val.reduce((a, b) => new Date(a.date) - today > new Date(b.date) - today ? a : b);
                        var filterBatch = new Date(furthest.date)
                        var end = new Date(offer_filter.end_date)
                        return filterBatch <= end
                    })
                }
                if (offer_filter.client) {
                    f_data = f_data.filter((item) => {
                        if (item.client_id) {
                            var filterBatch = item.client_id
                            return filterBatch.indexOf(offer_filter.client) >= 0
                        }
                    })
                }
                if (offer_filter.deal_id) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item._id
                        return filterBatch.indexOf(offer_filter.deal_id) >= 0
                    })
                }
                if (offer_filter.status) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.status
                        return filterBatch.indexOf(offer_filter.status) >= 0
                    })
                }
                if (offer_filter.venue) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.venue_name
                        return filterBatch.indexOf(offer_filter.venue) >= 0
                    })
                }
                if (offer_filter.state) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.venue_state
                        return filterBatch.indexOf(offer_filter.state) >= 0
                    })
                }
                if (offer_filter.city) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.venue_city
                        return filterBatch.indexOf(offer_filter.city) >= 0
                    })
                }
                if (offer_filter.country) {
                    f_data = f_data.filter((item) => {
                        var filterBatch = item.venue_country
                        return filterBatch.indexOf(offer_filter.country) >= 0
                    })
                }
                var sort = state.sort;
                if (sort.cat === '') {
                    f_data = _.sortBy(f_data, function (dateObj) {
                        return new Date(dateObj.show_info[0].date);
                    });
                } else {
                    f_data = _.sortBy(f_data, function (dateObj) {
                        return new Date(dateObj.show_info[0].date);
                    });
                    f_data = _.orderBy(f_data, [`${sort.cat}`.toLowerCase()], [sort.dir]);
                }
            }

            return {
                ...state,
                offers: data,
                filtered_offers: f_data
            };
        // case SUO_OFFER:
        //     var index = data.findIndex(obj => obj._id === action.payload._id);
        //     data[index] = action.payload;

        //     var found = state.filtered_offers.findIndex(obj => obj._id === action.payload._id);
        //     if (found >= 0) {
        //         var filtered_index = filtered_data.findIndex(obj => obj._id === action.payload._id);
        //         filtered_data[filtered_index] = action.payload;
        //     }

        //     return {
        //         ...state,
        //         offers: data,
        //         filtered_offers: filtered_data
        //     };
        case OFFERS_SOCKET_DELETE:
            return {
                ...state,
                offers: state.offers.filter(offer => offer._id !== action.payload._id),
                filtered_offers: state.filtered_offers.filter(offer => offer._id !== action.payload._id),
            };
        //COUNTS REDUCER
        default:
            return state;
    }
}