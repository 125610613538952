import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

export default function StatsAdminFilter(props) {
    const [stateFilter, setFilter] = useState(props.stats_filter);

    useEffect(() => {
        setFilter(props.stats_filter);
    }, [props.stats_filter])

    return (
        <div style={{ paddingBottom: 26, backgroundColor: '#111', border: '2px solid #111' }} className="navbar-nav">
            <div style={{ display: 'flex', marginTop: 70, marginLeft: 50 }}>
                <div style={{ width: 200, marginTop: -30 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Agent:</p>
                </div>
                <div style={{ width: 200, marginTop: -30, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>Start Date:</p>
                </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 50 }} className="form-group">
                <div style={{ width: 200, marginTop: -10 }}>
                    <select style={{ width: 200, height: 24, fontSize: 12 }} name="agent" value={stateFilter.agent} onChange={(e) => props.filterChange(e, 'stats_filter')}>
                        <option value="">All</option>
                        {props.agents ? props.agents.map((item, index) => {
                            return (
                                <option value={item.tag}>{item.name.first} {item.name.last}</option>
                            )
                        }) : null}
                    </select>
                </div>
                <div style={{ width: 200, marginTop: -10, marginLeft: 40 }}>
                    <DatePicker
                        className="react-datepicker"
                        style={{ fontSize: 12 }}
                        value={stateFilter.start_date}
                        selected={stateFilter.start_date}
                        onChange={(v) => props.filterDateChange(v, 'start_date', 'stats_filter')}
                        todayButton="Today"
                        showMonthDropdown
                        showYearDropdown
                    />
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 5, marginLeft: 50 }}>
                <div style={{ width: 200 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}> </p>
                </div>
                <div style={{ width: 200, marginLeft: 40 }}>
                    <p style={{ color: '#cdcdcd', fontSize: 10 }}>End Date</p>
                </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 50, marginTop: -10 }} className="form-group">
                <div style={{ width: 200, height: 28 }}>

                </div>
                <div style={{ width: 200, height: 28, marginLeft: 40 }}>
                    <DatePicker
                        className="react-datepicker"
                        style={{ fontSize: 12 }}
                        value={stateFilter.end_date}
                        selected={stateFilter.end_date}
                        onChange={(v) => props.filterDateChange(v, 'end_date', 'stats_filter')}
                        todayButton="Today"
                        showMonthDropdown
                        showYearDropdown
                    />
                </div>
            </div>
        </div>
    );
}