import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { FaEye, FaShoppingCart, FaFlagCheckered, FaCaretDown, FaCaretUp, FaBoxOpen, FaSort, FaExternalLinkSquareAlt, FaSave, FaFacebookSquare, FaInstagramSquare, FaSpotify, FaTwitterSquare, FaMinusSquare } from 'react-icons/fa';
import { Menu, Item, Separator, Submenu, MenuProvider, theme, animation } from 'react-contexify';
import { ContextMenu, MenuItem, ContextMenuTrigger, SubMenu } from "react-contextmenu";
import GridsButtonGroup from '../components/contacts/grids.button-group';
import { AutoSizer, Column, Table } from 'react-virtualized';
import CustomChip from '../components/customStatus';

import "react-datepicker/dist/react-datepicker.css";

class ARRolodex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            artist: '',
            agent: '',
            startDate: '',
            endDate: '',
            sidebarOpen: false,
            seen: false,
            entervenue: false,
            buyers: [],
            sortdirection: 'asc',
            sortcat: 'company',
            todayscount: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.myRef = React.createRef();
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        ar: PropTypes.object.isRequired,
        counts: PropTypes.object.isRequired,
    }

    componentDidMount() {

    }

    handleEventPositioned() {

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    toggleSort = () => {
        if (this.state.sortdirection === "asc") {
            this.setState({
                sortdirection: 'desc'
            })
        } else {
            this.setState({
                sortdirection: 'asc'
            })
        }
    }

    toggleSortCatCompany = () => {
        this.setState({
            sortcat: 'company'
        })
    }
    toggleSortCatCity = () => {
        this.setState({
            sortcat: 'city'
        })
    }
    toggleSortCatState = () => {
        this.setState({
            sortcat: 'state'
        })
    }
    toggleSortCatCountry = () => {
        this.setState({
            sortcat: 'country'
        })
    }
    toggleSortCatZipcode = () => {
        this.setState({
            sortcat: 'zipcode'
        })
    }

    toggleLink = (link) => {
        if (link) {
            window.open(link, 'Data');
        }
    }

    handleDatabaseCheckbox = (n, v) => {
        const e = {
            target: {
                name: n,
                value: v
            }
        }
        this.props.handleDatabaseCheckbox(e)
    }

    renderColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={this.props.ar_checked.includes(cellData._id) || false}
                    color="default"
                    value={cellData._id}
                    name="ar_checked"
                    onChange={this.props.handleDatabaseCheckbox}
                />
                {/* <Chip
                    size="small"
                    style={{ opacity: .8 }}
                    color={"primary"}
                    clickable
                    label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
                    onClick={this.props.iconRenderAR.bind(this, cellData)}
                /> */}
                <span style={{ width: 25, height: '100%' }} onClick={this.props.iconRenderAR.bind(this, cellData)}>
                    <FaExternalLinkSquareAlt size="16" className="dashboard-offer-icons-exp" />
                </span>
            </div>
        )
    }

    socialsColumnData = ({ cellData, dataKey }) => {
        var facebook = '';
        var instagram = '';
        var twitter = '';
        var spotify = '';
        cellData.links.map(item => {
            if (item.desc === 'Facebook') {
                facebook = item.url;
            };
            if (item.desc === 'Instagram') {
                instagram = item.url;
            };
            if (item.desc === 'Twitter') {
                twitter = item.url;
            };
            if (item.desc === 'Spotify') {
                spotify = item.url;
            };
        })
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: '100%', display: 'flex' }}>
                    {facebook ? <FaFacebookSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(facebook)} /> : null}
                    {instagram ? <FaInstagramSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(instagram)} /> : null}
                    {twitter ? <FaTwitterSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(twitter)} /> : null}
                    {spotify ? <FaSpotify style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(spotify)} /> : null}
                </div>
            </MenuProvider>
        )
    }

    statusColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <Chip
                    size="small"
                    variant="outlined"
                    style={{ opacity: .8 }}
                    label={cellData.status}
                    color={cellData.status === "Inactive" ? "secondary" : "primary"}
                    clickable
                //onClick={this.props.iconRenderOffer.bind(this, item)}
                /> */}
                <CustomChip
                    size={.65}
                    // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                    color={cellData.status === "Inactive" ? "secondary" : "primary"}
                    label={cellData.status}
                    onClick={() => this.handleStatusDialog(cellData)}
                />
            </div>
        )
    }

    headerStartRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                <div style={{ width: '50%' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        color="default"
                        checked={this.props.ar_all_checked}
                        //value={props.checked}
                        name="ar_checked"
                        onChange={this.props.handleDatabaseAllCheckbox}
                    /></div>
                <div style={{ width: '50%' }}>
                    {this.props.ar_checked.length > 0 ?
                        <FaMinusSquare size="18" color="#cdcdcd" onClick={() => this.props.handleDatabaseClearCheckbox('ar_checked')} />
                        : null}
                </div>
            </div>
        )
    }

    rowStyleFormat(row) {
        if (row.index < 0) return;
        const list = this.props.ar.filtered_ar;
        if (this.props.ar_checked.includes(list[row.index]._id)) {
            return {
                backgroundColor: '#e6f7ff'
            };
        }
        return {
            backgroundColor: null,
        };
    }

    // rowSelect(row) {
    //     if (row.index < 0) return;
    //     const list = this.props.ar.ar;
    //     this.handleDatabaseCheckbox('ar_checked', list[row.index]._id);
    // }

    normCellRender = ({ cellData, dataKey }) => {
        return (
            <MenuProvider key={cellData._id} id="menu_id" component="span" value={cellData._id}>
                <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center' }} onClick={this.rowSelect.bind(this, cellData.id)}>
                    {cellData.cell}
                </div>
            </MenuProvider>
        )
    }

    rowSelect(id) {
        if (!id) return;
        this.handleDatabaseCheckbox('ar_checked', id);
    }

    rowDoubleClick(row) {
        if (row.index < 0) return;
        this.props.iconRenderAR(row.rowData)
    }

    headerRowRenderer = ({ className, columns, style }) => {
        return (
            <div
                className={className}
                role='row'
                style={style}
            >
                {columns}
            </div>
        )
    }

    headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        if (this.props.ar_sort.sort_cat === dataKey) {
            return (
                <div style={{ width: '100%' }} onClick={() => this.props.toggleSortAR(dataKey)}><div style={{ display: 'flex', width: '100%' }}><div style={{ width: dataKey === 'artist' || dataKey === 'genre' ? '90%' : '85%' }}>{label}</div><div style={{ width: '10%' }}>{this.props.festival_sort.sort_direction === 'asc' ? <FaCaretUp size="15" color="#cdcdcd" /> : <FaCaretDown size="15" color="#cdcdcd" />}</div></div></div>
            )
        } else {
            return (
                <div onClick={() => this.props.toggleSortCatAR(dataKey)}>{label}</div>
            )
        }
    }

    render() {

        const MyAwesomeMenu = () => (
            <Menu id='menu_id' theme={theme.dark} animation={animation.fade}>
                <Item onClick={this.props.toggleRenderAR}>View / Edit</Item>
                <Item onClick={() => this.props.deleteDialog('ar_checked', 'ar')}>Delete Artist</Item>
            </Menu>
        );

        // {this.props.ar.filtered_ar.map((item, index) => {
        //     var date_added = moment(item.createdAt).format('MM/DD/YYYY');

        //     return (
        //         <ContextMenuTrigger id={'SIMPLE'} >
        //             <table id="customers">
        //                 <tbody>
        //                     <tr style={{ backgroundColor: "#fff" }}>
        //                         <td style={{ width: '5%' }}>
        //                             <Checkbox
        //                                 icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //                                 checkedIcon={<CheckBoxIcon fontSize="small" />}
        //                                 color="default"
        //                                 name="buyerchecked"
        //                             />
        //                             <Chip
        //                                 size="small"
        //                                 style={{ opacity: .8 }}
        //                                 color={"primary"}
        //                                 clickable
        //                                 label={<FaExternalLinkSquareAlt size="14" color="#fff" />}
        //                                 onClick={this.props.iconRenderAR.bind(this, item)}
        //                             />
        //                         </td>
        //                         <td style={{ width: '8%', fontSize: 12, height: 32, paddingLeft: 5 }}>{date_added}</td>
        //                         <td style={{ width: '5%', fontSize: 12, height: 32, paddingLeft: 5 }}>
        //                             <Chip
        //                                 size="small"
        //                                 variant="outlined"
        //                                 style={{ opacity: .8 }}
        //                                 label={item.status}
        //                                 color={item.status === "Inactive" ? "secondary" : "primary"}
        //                                 clickable
        //                             //onClick={this.props.iconRenderOffer.bind(this, item)}
        //                             />
        //                         </td>
        //                         <td style={{ width: '15%', fontSize: 12, height: 32, paddingLeft: 5 }}>{item.artist}</td>
        //                         <td style={{ width: '10%', fontSize: 12, height: 32, paddingLeft: 5 }}>{item.contact.name}</td>
        //                         <td style={{ width: '15%', fontSize: 12, height: 32, paddingLeft: 5 }}>{item.genre}</td>
        //                         <td style={{ width: '10%', fontSize: 12, height: 32, paddingLeft: 5 }}><div style={{ width: '100%', display: 'flex' }}>{item.facebook ? <FaFacebookSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(item.facebook)} /> : null}{item.instagram ? <FaInstagramSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(item.instagram)} /> : null}{item.twitter ? <FaTwitterSquare style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(item.twitter)} /> : null}{item.spotify ? <FaSpotify style={{ marginLeft: 10 }} className="database-link" onClick={() => this.toggleLink(item.spotify.url)} /> : null}</div></td>
        //                         <td style={{ width: '12%', fontSize: 12, height: 32, paddingLeft: 5 }}>{item.notes}</td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //         </ContextMenuTrigger>
        //     )
        // })}
        const list = this.props.ar.filtered_ar;

        if (this.props.ar.loading) return <div>loading...</div>;
        return (
            <div style={{ width: '100%' }}>
                <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            width={width}
                            height={height}
                            headerHeight={30}
                            rowHeight={30}
                            overscanRowCount={10}
                            headerRowRenderer={this.headerRowRenderer.bind(this)}
                            rowCount={list.length}
                            rowGetter={({ index }) => list[index]}
                            rowStyle={this.rowStyleFormat.bind(this)}
                            //onRowClick={this.rowSelect.bind(this)}
                            onRowDoubleClick={this.rowDoubleClick.bind(this)}
                        >
                            <Column
                                label=''
                                width={75}
                                dataKey='_id'
                                headerRenderer={this.headerStartRenderer}
                                cellDataGetter={({ rowData }) => rowData}
                                cellRenderer={
                                    this.renderColumnData
                                }
                            />
                            <Column
                                width={120}
                                label='Date Added'
                                headerRenderer={this.headerRenderer}
                                dataKey='date_added'
                                cellDataGetter={({ rowData }) => { return ({ cell: moment(rowData.createdAt).utc().format('MM/DD/YYYY'), id: rowData._id, width: 120 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            />
                            <Column
                                width={80}
                                label='Status'
                                headerRenderer={this.headerRenderer}
                                dataKey='status'
                                //cellDataGetter={({ rowData }) => rowData.name.last}
                                cellDataGetter={({ rowData }) => rowData}
                                cellRenderer={
                                    this.statusColumnData
                                }
                            />
                            <Column
                                width={200}
                                label='Artist'
                                headerRenderer={this.headerRenderer}
                                dataKey='artist'
                                cellDataGetter={({ rowData }) => { return ({ cell: rowData.artist, id: rowData._id, width: 200 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            />
                            <Column
                                width={120}
                                label='Type'
                                headerRenderer={this.headerRenderer}
                                dataKey='type'
                                cellDataGetter={({ rowData }) => { return ({ cell: rowData.type, id: rowData._id, width: 120 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            />
                            {/* <Column
                                width={150}
                                label='Contact'
                                dataKey='contact.name'
                                cellDataGetter={({ rowData }) => { return ({ cell: rowData.contact.name, id: rowData._id, width: 200 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            /> */}
                            <Column
                                width={115}
                                label='City'
                                headerRenderer={this.headerRenderer}
                                dataKey='city'
                                cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.city, id: rowData._id, width: 115 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            />
                            <Column
                                width={115}
                                label='State'
                                headerRenderer={this.headerRenderer}
                                dataKey='state'
                                cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.state, id: rowData._id, width: 115 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            />
                            <Column
                                width={115}
                                label='Country'
                                headerRenderer={this.headerRenderer}
                                dataKey='country'
                                cellDataGetter={({ rowData }) => { return ({ cell: rowData.address.country, id: rowData._id, width: 115 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            />
                            <Column
                                width={200}
                                label='Genre'
                                dataKey='genre'
                                cellDataGetter={({ rowData }) => { return ({ cell: rowData.genre, id: rowData._id, width: 200 }) }}
                                cellRenderer={
                                    this.normCellRender
                                }
                            />
                            <Column
                                width={200}
                                label='Socials'
                                dataKey='socials'
                                cellDataGetter={({ rowData }) => rowData}
                                cellRenderer={
                                    this.socialsColumnData
                                }
                            />
                        </Table>
                    )}
                </AutoSizer>
                <MyAwesomeMenu />
                {/* <ContextMenu id={'SIMPLE'}>
                    <MenuItem onClick={this.handleClick} data={{ item: 'item 1' }}>View / Edit</MenuItem>
                    <MenuItem onClick={this.handleClick} data={{ item: 'item 1' }}>Issue Contract</MenuItem>
                    <SubMenu title='Print'>
                        <MenuItem onClick={this.handleClick} data={{ item: 'subitem a1' }}>Deal Memo</MenuItem>
                        <MenuItem onClick={this.handleClick} data={{ item: 'subitem a1' }}>Contract</MenuItem>
                    </SubMenu>
                    <SubMenu title='Email'>
                        <MenuItem onClick={this.handleClick} data={{ item: 'subitem b1' }}>Deal Memo</MenuItem>
                        <MenuItem onClick={this.handleClick} data={{ item: 'subitem b1' }}>Contract</MenuItem>
                    </SubMenu>
                    <MenuItem onClick={this.handleClick} data={{ item: 'item 1' }}>Delete Offer</MenuItem>
                </ContextMenu> */}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    buyers: state.buyers,
    contacts: state.contacts,
    clients: state.clients,
    items: state.items,
    counts: state.counts,
    offers: state.offers,
    ar: state.ar,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, null)(ARRolodex);