import React, { Component } from "react";

class LoadingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <div className="mode-t">
                <div style={{ postition: 'absolute'}}>
                    <h2 style={{ color: '#cdcdcd', fontSize: 14 }}>Loading...</h2>
                </div>
            </div>
        );
    }
}


export default LoadingModal;