import { calendarSocket } from '../../actions/calendarActions';

export const calendarListener = () => (dispatch, getState) => {
    const { auth } = getState();
    const socket = auth.socket;
    if (socket) {
        socket.on("clients/update", (client) => {
            dispatch(calendarSocket('clients/updated', client))
        })
        socket.on("clients/delete", (client) => {
            dispatch(calendarSocket('clients/deleted', client))
        })
        socket.on("offers/update", (offer) => {
            dispatch(calendarSocket('offers/updated', offer))
        })
        socket.on("offers/delete", (offer) => {
            dispatch(calendarSocket('offers/deleted', offer))
        })
        socket.on("venues/update", (venue) => {
            dispatch(calendarSocket('venues/updated', venue))
        })
        socket.on("venues/delete", (venue) => {
            dispatch(calendarSocket('venues/deleted', venue))
        })
        socket.on("festivals/update", (festival) => {
            dispatch(calendarSocket('festivals/updated', festival))
        })
        socket.on("festivals/delete", (festival) => {
            dispatch(calendarSocket('festivals/deleted', festival))
        })
        socket.on("calendar/entries/update", (calendar) => {
            dispatch(calendarSocket('calendar/entry/updated', calendar))
        })
        socket.on("calendar/entries/delete", (calendar) => {
            dispatch(calendarSocket('calendar/entry/deleted', calendar))
        })
        socket.on("holds/update", (holds) => {
            dispatch(calendarSocket('holds/updated', holds))
        })
        socket.on("holds/delete", (holds) => {
            dispatch(calendarSocket('holds/deleted', holds))
        })
        socket.on("calendar/update", (holds) => {
            dispatch(calendarSocket('calendar/updated', holds))
        })
        socket.on("calendar/delete", (holds) => {
            dispatch(calendarSocket('calendar/deleted', holds))
        })
        return "Calendar socket connected"
    } else {
        return "Error: No Socket Connected"
    }
};