import React, { Component } from 'react';
import WhiteLogo from '../assets/WhiteLogo.png';
import loginBackdrop from '../assets/loginBackdrop.png';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from "../components/history";
import { login, verify, updatePassword, logout, googleAuthCode } from '../actions/authActions';
import { clearErrors } from '../actions/errorActions';
import LoadingMain from '../components/global/loading-main';
import '../App.css';

class GoogleLogin extends Component {

    constructor() {
        super();
        this.state = {
            error: null,
            email: "",
            temp_pass: "",
            password: "",
            password_conf: "",
            ran: false
        };
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        error: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired,
    }

    async componentDidMount() {

        let search = this.props.location.search;
        // let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        if (code) {
            var data = {
                code: code
            }
            // const prom = new Promise(async (resolve, reject) => {

            //     resolve(data);
            //   });
            // var msg = {};
            // if (!ran) {
            //     msg = await this.props.googleAuthCode(data);
            // }
            // ran = true

            const loc = this.props;
            this.props.googleAuthCode(loc, data);

            // const config = {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // }
            // axios.post(`${process.env.REACT_APP_API}/auth/google/code`, data, config)
            //     .then(res => {
            //         console.log(res.data)
            //         // if (res.data && res.data.type === 'success' && Object.keys(res.data.data).length > 0) {
            //         //     // dispatch({
            //         //     //     type: REGISTER_SUCCESS,
            //         //     //     payload: res.data.data
            //         //     // })
            //         //     return ({ type: 'success' })
            //         // } else if (res.data && res.data.type === 'connect') {
            //         //     return ({ type: 'connect', data: res.data.data })
            //         // } else {
            //         //     return (res.data)
            //         // }
            //         //return res.data

            //         const msg = res.data
            //         if (msg === undefined) {

            //         } else if (msg && msg.type === 'success') {
    
            //         } else if (msg && msg.type === 'msg') {
            //             this.props.history.push('/login')
            //             alert(msg.data)
            //         } else if (msg && msg.type === 'connect') {
            //             data = {
            //                 code: code,
            //                 email: msg.data.email,
            //                 passcode: msg.data.passcode
            //             }
            //             this.googleLinkDialog(data)
            //         } else if (msg && msg.type === 'error') {
            //             this.props.history.push('/login')
            //             alert(msg.data)
            //         } else {
            //             this.props.history.push('/login')
            //         }
            //     })
            //     .catch(err => {
            //         //dispatch(returnErrors(err.response.data, err.response.status))
            //         return ({ type: 'error', data: err })
            //     });
                
        } else {
            // this.props.history.push('/login')
        }
    }

    // googleLinkDialog = (data, msg) => {
    //     const Dialog = ({ onClose }) => {
    //         return (
    //             <div className="react-confirm-alert-google">
    //                 <div style={{ width: '65%', height: '100%', padding: 15 }}>
    //                     <h1 style={{ fontSize: 22, fontWeight: 'bold', color: '#fff' }}>Link Google Account:</h1>
    //                     <div style={{ width: '100%', display: 'flex', flexDirection: 'column', color: '#fff' }}>
    //                         By linking your Google account, we will switch your authentication method for accessing your Solar System account from email address to Log in with Google. Moving forward, your credentials will be managed by your Google account.
    //                     </div>
    //                     <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
    //                         <button style={{ borderRadius: 10 }} onClick={() => { this.googleLinkAccount(data, msg); onClose(); }}>Yes, Link My Account</button>
    //                         <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={() => { onClose(); this.props.history.push('/login') }}>Cancel, Return to Login</button>
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     }
    //     const options = {
    //         closeOnEscape: true,
    //         closeOnClickOutside: true,
    //         customUI: Dialog
    //     }
    //     confirmAlert(options)
    // }

    // googleLinkAccount = (data) => {
    //     this.props.googleLinkAccount(data);
    // }

    render() {

        return (
            <div className="App">
                <LoadingMain />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error
})

export default connect(mapStateToProps, { login, verify, updatePassword, clearErrors, logout, googleAuthCode })(GoogleLogin);