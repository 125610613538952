import React, { Component } from 'react'
import BlackLogo from '../../assets/BlackLogo.png';

class ContractPDF extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: this.props.data.artist,
        };
    }

    componentDidMount() {
        //this.BuyerOpFormat(this.props.contactdata.officephone)
        //this.VenuePhoneFormat(this.props.venuedata.phone)
    }

    BuyerOpFormat = (text) => {
        let formatedNo = this.formatPhoneNumber(text);
        this.setState({ contactop: formatedNo });
    };

    VenuePhoneFormat = (text) => {
        let formatedNo = this.formatPhoneNumber(text);
        this.setState({ venuephone: formatedNo });
    };

    formatPhoneNumber = (text => {
        var cleaned = ("" + text).replace(/\D/g, "");
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = match[1] ? "+1 " : "",
                number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
                    ""
                );
            return number;
        }
        return text;
    })


    formatDate(date) {

        const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = new Date(date);
        const day = days[d.getDay()];
        const mon = monthNames[d.getMonth()];
        const time = `${day}. ${mon} ${d.getDate()}, ${d.getFullYear()}`;
        return time;
    }

    //onDocumentLoadSuccess({ numPages }) {
    //  this.setNumPages(numPages);
    //}

    render() {

        //const numberFormat = (value) =>
        //    new Intl.NumberFormat().format(value);

        const currencyFormat = (value) =>
            new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            }).format(value);

        //const captotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.capacity); }, 0);
        //const captotalval = parseInt(captotal, 10)

        //const compstotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.comps); }, 0);
        //const compstotalval = parseInt(compstotal, 10)

        //const killstotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.kills); }, 0);
        //const killstotalval = parseInt(killstotal, 10)

        //const grosstotal = this.state.ticketscaling.reduce(function (cnt, o) { return cnt + Number(o.gross); }, 0);
        //const grosstotalval = numberFormat(grosstotal)

        //const guarantee = numberFormat(this.state.guarantee)

        //const gross_tickets = captotal - compstotal - killstotal
        //const flat_tax_total = this.state.taxes
        //    .filter(tax => tax.type === "Flat")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        //const percent_tax = this.state.taxes
        //    .filter(tax => tax.type === "Percent")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const percent_tax_total = (percent_tax * .01) * grosstotal

        //const perticket_tax = this.state.taxes
        //    .filter(tax => tax.type === "Per Ticket")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const perticket_tax_total = perticket_tax * gross_tickets

        //const taxtotal = flat_tax_total + percent_tax_total + perticket_tax_total

        //const flat_exp_total = this.state.expenses
        //    .filter(exp => exp.type === "Flat")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);

        //const percent_exp = this.state.expenses
        //    .filter(exp => exp.type === "Percent")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const percent_exp_total = (percent_exp * .01) * grosstotal

        //const perticket_exp = this.state.expenses
        //    .filter(exp => exp.type === "Per Ticket")
        //    .reduce(function (cnt, o) { return cnt + Number(o.amount); }, 0);
        //const perticket_exp_total = perticket_exp * gross_tickets

        //const exptotal = flat_exp_total + percent_exp_total + perticket_exp_total;
        var data = this.props.data;

        var walkout = 0;
        var guarantee = Number(data.guarantee);
        var customwalkout = Number(data.custom_walkout);
        //var grosstotalval = Number(this.props.grosstotalval);
        //var exptotal = Number(this.props.exptotal);
        //var taxtotal = Number(this.props.taxtotal);

        const dealstartlang = [];
        const dealendlang = [];
        var dealtype = this.props.data.deal_type;
        if (dealtype === 'Flat') {
            dealstartlang.push("flat guarantee");
            dealendlang.push("");
            if (guarantee === Math.floor(guarantee)) {
                walkout = guarantee
            } else {
                walkout = guarantee.toFixed(2)
            }
        } else if (dealtype === 'Versus Net') {
            dealstartlang.push("guarantee, versus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Versus Gross') {
            dealstartlang.push("guarantee, versus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Plus') {
            dealstartlang.push("guarantee, plus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Promoter Profit') {
            dealstartlang.push("guarantee, plus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax, the approved expenses listed below, and a 15% promoter profit have been deducted.");
        } else if (dealtype === 'Bonus') {
            dealstartlang.push("Plus the following bonuses:");
            dealendlang.push("");
        } else if (dealtype === 'Versus Net (Custom Walkout)') {
            dealstartlang.push("guarantee, versus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Versus Gross (Custom Walkout)') {
            dealstartlang.push("guarantee, versus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Plus (Custom Walkout)') {
            dealstartlang.push("guarantee, plus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax and the approved expenses listed below have been deducted.");
        } else if (dealtype === 'Promoter Profit (Custom Walkout)') {
            dealstartlang.push("guarantee, plus the right to receive");
            dealendlang.push("of the gross box office receipts after applicable tax, the approved expenses listed below, and a 15% promoter profit have been deducted.");
        } else {
            dealstartlang.push("");
            dealendlang.push("");
        };

        var merch = `${data.merch.hard}% Hard<br/>${data.merch.soft}% Soft<br/>${data.merch.seller} sells`

        var venuestate = convertStateToAbbr(data.venue.state);
        console.log(data)

        var state = convertStateToAbbr(data.buyer.address.state);

        var td = new Date();
        const today = `${td.getMonth() + 1}/${td.getDate()}/${td.getFullYear()}`

        return (
            <html id="'print-section'">
                <body>
                    <div style={{ width: 500 }}>
                        <div style={{ width: '85%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <img style={{ marginTop: '3px' }} width="60px" height="62px" src={BlackLogo}></img>
                            <h1 style={{ fontSize: '8px', fontFamily: 'sans-serif', marginTop: 5 }}>4063 Glenalbyn Dr<br />Los Angeles, CA 90065<br />Joey@spaceagencybooking.com<br />818-274-4950</h1>
                        </div>
                        <div style={{ width: '85%', height: '14px', marginTop: '1.5px', backgroundColor: '#000', display: 'flex', flexDirection: 'row', borderRadius: '5px' }}>
                            <div style={{ width: '50%' }}>
                                <h1 style={{ color: '#fff', fontSize: '8px', marginLeft: '5px', paddingTop: '1.5px' }}>{this.props.data.artist_name.toUpperCase()}</h1>
                            </div>
                        </div>
                        <div style={{ width: '85%', marginTop: '1.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif' }}>ARTIST RIDER AND ADDENDA ATTACHED HERETO HEREBY MADE A PART OF THIS CONTRACT</h1>
                        </div>
                        <div style={{ width: '85%', marginTop: '1.5px', display: 'flex', flexDirection: 'column' }}>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif' }}>Agreement made {today} between {this.props.data.artist_name} (herein referred to as "PRODUCER") furnishing the services of {this.props.data.artist_name} (herein referred to as "ARTIST") and {data.venue.venue} (herein referred to as "PURCHASER")</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px' }}>It is mutually agreed between the parties as follows:</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px' }}>The PURCHASER hereby engages the PRODUCER to furnish the services of ARTIST for the Engagement (as described herein) upon all the terms and conditions herein set forth, including, without limitation, Addendum "A" (Additional Terms and Conditions), the Artist Rider, and any other PRODUCER addenda referenced herein (if any), all of which are attached hereto and fully incorporated herein by reference.</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>1. ENGAGEMENT VENUE:</h1>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginLeft: '5%', width: '15%' }}>VENUE:</h1>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', width: '80%' }}>{data.venue.venue}</h1>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginLeft: '5%', width: '15%' }}>ADDRESS:</h1>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', width: '80%' }}>
                                    {data.venue.address ?
                                        data.venue.address : null}
                                    {data.venue.address ?
                                        <br style={{ lineHeight: 2 }} /> : null}
                                    {data.venue.address2 ?
                                        data.venue.address2 : null}
                                    {data.venue.address2 ?
                                        <br style={{ lineHeight: 2 }} /> : null}
                                    {data.venue.city ?
                                        data.venue.city + ", " + convertStateToAbbr(data.venue.state) + " " + data.venue.postalcode : null}
                                    {data.venue.city ?
                                        <br style={{ lineHeight: 2 }} /> : null}
                                    {data.venue.country ?
                                        data.venue.country : null}
                                </h1>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginLeft: '5%', width: '15%' }}>NO. OF SHOWS:</h1>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', width: '80%' }}>{data.show_info.length}</h1>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginLeft: '5%', width: '15%' }}>AGE LIMIT:</h1>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', width: '80%' }}> </h1>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginLeft: '5%', width: '15%' }}>SHOW SCHEDULE:</h1>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', width: '80%' }}> </h1>
                            </div>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>2. BILLING (in all forms of advertising):</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>3. COMPENSATION:</h1>
                            <p style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '2px', marginLeft: '10px' }}>{data.deal_terms.guarantee}</p>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>4. PRODUCTION AND CATERING:</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>5. TRANSPORTATION AND ACCOMMODATIONS:</h1>
                            <p style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '2px', marginLeft: '10px' }}>Any changes to the above mentioned arrangements are subject to the sole and exclusive prior written approval of PRODUCER</p>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>6. ARTIST RIDER:</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>7. PAYMENT TERMS:</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>8. SCALING AND TICKET PRICES:</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>9. EXPENSES:</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>10. MERCHANDISING:</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '5px', fontWeight: 'bold' }}>11. SPECIAL PROVISIONS:</h1>
                            <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif', marginTop: '10px', fontStyle: 'italic' }}>ADDENDUM "A" (ADDITIONAL TERMS AND CONDITIONS), ARTIST RIDER, AND ANY OTHER PRODUCER ADDENDA REFERENCED HEREIN (IF ANY), ARE ALL ATTACHED HERETO AND FULLY INCORPORATED HEREIN BY REFERENCE.</h1>
                            <div style={{ width: '100%', marginTop: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <h1 style={{ color: '#000', fontSize: '6px', fontFamily: 'sans-serif' }}>IN WITNESS WHEREOF, the parties hereto have hereunto set their names and seals on the day and year first above written.</h1>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        )
    }
};

function toggle24hr(time, onoff) {
    if (onoff == undefined) onoff = isNaN(time.replace(':', ''))//auto-detect format
    var pm = time.toString().toLowerCase().indexOf('pm') > -1 //check if 'pm' exists in the time string
    time = time.toString().toLowerCase().replace(/[ap]m/, '').split(':') //convert time to an array of numbers
    time[0] = Number(time[0])
    if (onoff) {//convert to 24 hour:
        if ((pm && time[0] != 12)) time[0] += 12
        else if (!pm && time[0] == 12) time[0] = '00'  //handle midnight
        if (String(time[0]).length == 1) time[0] = '0' + time[0] //add leading zeros if needed
    } else { //convert to 12 hour:
        pm = time[0] >= 12
        if (!time[0]) time[0] = 12 //handle midnight
        else if (pm && time[0] != 12) time[0] -= 12
    }
    return onoff ? time.join(':') : time.join(':') + (pm ? ' PM' : ' AM')
}

const _MapAbbrFullName = { "AZ": "Arizona", "AL": "Alabama", "AK": "Alaska", "AR": "Arkansas", "CA": "California", "CO": "Colorado", "CT": "Connecticut", "DC": "District of Columbia", "DE": "Delaware", "FL": "Florida", "GA": "Georgia", "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "IA": "Iowa", "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MD": "Maryland", "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", "MO": "Missouri", "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", "NH": "New Hampshire", "NJ": "New Jersey", "NM": "New Mexico", "NY": "New York", "NC": "North Carolina", "ND": "North Dakota", "OH": "Ohio", "OK": "Oklahoma", "OR": "Oregon", "PA": "Pennsylvania", "RI": "Rhode Island", "SC": "South Carolina", "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VT": "Vermont", "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming", "AB": "Alberta", "BC": "British Columbia", "MB": "Manitoba", "NB": "New Brunswick", "NF": "Newfoundland", "NT": "Northwest Territory", "NS": "Nova Scotia", "NU": "Nunavut", "ON": "Ontario", "PE": "Prince Edward Island", "QC": "Quebec", "SK": "Saskatchewan", "YT": "Yukon" };

const _MapFullNameAbbr = { "arizona": "AZ", "alabama": "AL", "alaska": "AK", "arkansas": "AR", "california": "CA", "colorado": "CO", "connecticut": "CT", "districtofcolumbia": "DC", "delaware": "DE", "florida": "FL", "georgia": "GA", "hawaii": "HI", "idaho": "ID", "illinois": "IL", "indiana": "IN", "iowa": "IA", "kansas": "KS", "kentucky": "KY", "louisiana": "LA", "maine": "ME", "maryland": "MD", "massachusetts": "MA", "michigan": "MI", "minnesota": "MN", "mississippi": "MS", "missouri": "MO", "montana": "MT", "nebraska": "NE", "nevada": "NV", "newhampshire": "NH", "newjersey": "NJ", "newmexico": "NM", "newyork": "NY", "northcarolina": "NC", "northdakota": "ND", "ohio": "OH", "oklahoma": "OK", "oregon": "OR", "pennsylvania": "PA", "rhodeisland": "RI", "southcarolina": "SC", "southdakota": "SD", "tennessee": "TN", "texas": "TX", "utah": "UT", "vermont": "VT", "virginia": "VA", "washington": "WA", "westvirginia": "WV", "wisconsin": "WI", "wyoming": "WY", "alberta": "AB", "britishcolumbia": "BC", "manitoba": "MB", "newbrunswick": "NB", "newfoundland": "NF", "northwestterritory": "NT", "novascotia": "NS", "nunavut": "NU", "ontario": "ON", "princeedwardisland": "PE", "quebec": "QC", "saskatchewan": "SK", "yukon": "YT" }

function convertStateToAbbr(input) {
    if (input === undefined) return input;
    var strInput = input.trim();
    if (strInput.length === 2) {
        // already abbr, check if it's valid
        var upStrInput = strInput.toUpperCase();
        return _MapAbbrFullName[upStrInput] ? upStrInput : undefined;
    }
    var strStateToFind = strInput.toLowerCase().replace(/\ /g, '');
    var foundAbbr = _MapFullNameAbbr[strStateToFind];
    return foundAbbr;
}

function convertStateToFullName(input) {
    if (input === undefined) return input;
    var strInput = input.trim();
    if (strInput.length !== 2) {
        // already full name, return formatted fullname
        return _MapAbbrFullName[convertStateToAbbr(strInput)];
    }
    var strStateToFind = strInput.toLowerCase().replace(/\ /g, '');
    var foundFullName = _MapAbbrFullName[strStateToFind];
    return foundFullName;
}

export default ContractPDF;