import { offerSocket, deleteOfferSocket } from '../../actions/offerActions';
import { deleteVenueSocket, venueSocket } from '../../actions/venueActions';
import { deleteClientSocket, clientSocket } from '../../actions/clientActions';
import { buyerSocketDelete, buyerSocket } from '../../actions/buyerActions';
import { contactSocket, deleteContactSocket } from '../../actions/contactActions';
import { packagingSocket, deletePackagingSocket } from '../../actions/packagingActions';
import { arSocket, arSocketDelete } from '../../actions/arActions';
import { festivalSocket, deleteFestivalSocket } from '../../actions/festivalActions';
import { addCountSocket, addCountBulkSocket } from '../../actions/countActions';
import { calendarSocket, calendarSocketDelete } from '../../actions/calendarActions';
import { holdsSocket, holdsSocketDelete } from '../../actions/holdsActions';
import { usersSocket, chatSocket } from '../../actions/authActions';
import { companyEmailGroupsSocket, companyTemplatesSocket } from '../../actions/companyActions';
import {
    CONNECT_ON,
} from "../../actions/types";

export const mainListener = (socket) => (dispatch, getState) => {
    if (socket) {
        socket.on("buyers/update", (buyer) => {
            dispatch(buyerSocket(buyer))
        })
        socket.on("buyers/delete", (buyer) => {
            dispatch(buyerSocketDelete(buyer))
        })
        socket.on("clients/update", (client) => {
            dispatch(clientSocket(client))
        })
        socket.on("clients/delete", (client) => {
            dispatch(deleteClientSocket(client))
        })
        socket.on("offers/update", (offer) => {
            console.log(offer)
            dispatch(offerSocket(offer))
        })
        socket.on("offers/delete", (offer) => {
            dispatch(deleteOfferSocket(offer))
        })
        socket.on("venues/update", (venue) => {
            dispatch(venueSocket(venue))
        })
        socket.on("venues/delete", (venue) => {
            dispatch(deleteVenueSocket(venue))
        })
        socket.on("contacts/update", (contact) => {
            dispatch(contactSocket(contact))
        })
        socket.on("contacts/delete", (contact) => {
            dispatch(deleteContactSocket(contact))
        })
        socket.on("festivals/update", (festival) => {
            dispatch(festivalSocket(festival))
        })
        socket.on("festivals/delete", (festival) => {
            dispatch(deleteFestivalSocket(festival))
        })
        socket.on("log_count", (counts) => {
            dispatch(addCountSocket(counts))
        })
        socket.on("log_count_bulk", (counts) => {
            dispatch(addCountBulkSocket(counts))
        })
        socket.on("calendar/update", (calendar) => {
            dispatch(calendarSocket(calendar))
        })
        socket.on("calendar/delete", (calendar) => {
            dispatch(calendarSocketDelete(calendar))
        })
        socket.on("holds/update", (holds) => {
            dispatch(holdsSocket(holds))
        })
        socket.on("holds/delete", (holds) => {
            dispatch(holdsSocketDelete(holds))
        })
        socket.on("ar/update", (ar) => {
            dispatch(arSocket(ar)) 
        })
        socket.on("ar/delete", (ar) => {
            dispatch(arSocketDelete(ar))
        })
        socket.on("packaging/update", (packaging) => {
            dispatch(packagingSocket(packaging))
        })
        socket.on("packaging/delete", (packaging) => {
            dispatch(deletePackagingSocket(packaging))
        })
        socket.on("users/update", (user) => {
            dispatch(usersSocket(user))
        })
        socket.on("users/delete", (user) => {
            dispatch(usersSocket(user))
        })
        socket.on("company/email/groups", (company) => {
            dispatch(companyEmailGroupsSocket(company))
        })
        socket.on("company/templates", (company) => {
            dispatch(companyTemplatesSocket(company))
        })
        socket.on("message/new", (message) => {
            dispatch(chatSocket(message))
        })
        dispatch({
            type: CONNECT_ON,
            payload: socket
        })
        return "Socket Loaded"
    } else {
        return "Error: No Socket Connected"
    }
};