import {
    SPOTIFY_LOADING,
    SPOTIFY_LOADED,
    GET_SPOTIFY,
    GET_SPOTIFY_DATA,
} from "../actions/types";

const initialState = {
    token: localStorage.getItem('spotify_token'),
    spotify: [],
    artist_data: "",
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SPOTIFY_LOADING:
            return {
                ...state,
                loading: true
            };
        case SPOTIFY_LOADED:
            const now = new Date()
            const item = {
                token: action.payload,
                expiry: now.getTime() + 3500,
            }
            localStorage.setItem('spotify_token', JSON.stringify(item));
            return {
                ...state,
                token: JSON.stringify(item),
                loading: false
            };
        case GET_SPOTIFY:
            return {
                ...state,
                spotify: action.payload,
                loading: false
            };
        case GET_SPOTIFY_DATA:
            return {
                ...state,
                artist_data: action.payload,
                loading: false
            };
        default:
            return state;
    }
}