import axios from "axios";
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    SOCIALS_LOADING,
    GET_INSTAGRAM,
} from "./types";

export const setSocialsLoading = () => {
    return {
        type: SOCIALS_LOADING
    };
};

export const getSocials = (social_data) => (dispatch, getState) => {
    // axios.post(`${process.env.REACT_APP_API}/socials/`, social_data, tokenConfig(getState))
    // .then(res =>
    //     dispatch({
    //         type: GET_INSTAGRAM,
    //         payload: res.data
    //     })
    // )
    // .catch(err =>
    //     dispatch(returnErrors(err.response.data, err.response.status))
    // );

    return axios.post(`${process.env.REACT_APP_API}/socials/facebook`, null, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //     type: GET_INSTAGRAM,
            //     payload: res.data
            // })
            return res.data
        })
        .catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
};

export const getGoogleTrendsTime = (obj) => async (dispatch, getState) => {
    return await axios.post(`${process.env.REACT_APP_API}/socials/trends/time`, obj, tokenConfig(getState))
        .then(res => {
            console.log(res.data)
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
};

export const getGoogleTrendsRegion = (obj) => async (dispatch, getState) => {
    return await axios.post(`${process.env.REACT_APP_API}/socials/trends/region`, obj, tokenConfig(getState))
        .then(res => {
            console.log(res.data)
            return res.data
        })
        .catch(err => {
            return err.response.data
        });
};