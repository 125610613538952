import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FaPlusSquare, FaMinusSquare, FaLink, FaUnlink } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TaxesMovable from '../../components/moveables/taxes-movable/taxes-movable';
import ExpensesMovable from '../../components/moveables/expenses-movable/expenses-movable';
import getSymbolFromCurrency from 'currency-symbol-map';
import ExpensesAutocomplete from '../../components/autocompletes/expenses-autocomplete';
import moment from 'moment';

class Expenses extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dealcurrency: 'USD',
            tax1: '',
            taxtype1: '',
            taxamount1: '',
            taxcap1: '',
            taxcapamount1: '',
            taxnotes1: '',
            exp1: '',
            exptype1: '',
            expamount1: '',
            expcap1: '',
            expcapamount1: '',
            expnotes1: '',
            tax_gross_single: null,
            tax_gross: null,
            exp_gross_single: null,
            exp_gross: null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        contacts: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        buyers: PropTypes.object.isRequired,
        clients: PropTypes.object.isRequired,
    }

    componentDidMount = () => {
        // var tax_gross_single;
        // if (this.props.taxtype1 === "Flat") {
        //     tax_gross_single = this.props.taxamount1val;
        // } else if (this.props.taxtype1 === "Per Ticket") {
        //     tax_gross_single = this.props.gross_tickets * this.props.taxamount1val;
        // } else if (this.props.taxtype1 === "Percent") {
        //     tax_gross_single = this.props.gross_pot_val * (this.props.taxamount1val / 100);
        // };

        // var tax_gross = [];
        // if (!tax_gross_single) {
        //     tax_gross = ""
        // } else {
        //     var t = Number(tax_gross_single)
        //     tax_gross = parseFloat(t).toLocaleString("en-IN", { useGrouping: false, maximumFractionDigits: 2 });
        // }

        // var exp_gross_single;
        // if (this.props.exptype1 === "Flat") {
        //     exp_gross_single = this.props.expenseamount1val;
        // } else if (this.props.exptype1 === "Per Ticket") {
        //     exp_gross_single = this.props.gross_tickets * this.props.expenseamount1val;
        // } else if (this.props.exptype1 === "Percent") {
        //     exp_gross_single = this.props.gross_pot_val * (this.props.expenseamount1val / 100);
        // };

        // var exp_gross = [];
        // if (!exp_gross_single) {
        //     exp_gross = ""
        // } else {
        //     exp_gross = Number(exp_gross_single)
        // }

        // this.setState({
        //     tax_gross_single: tax_gross_single,
        //     tax_gross: tax_gross,
        //     exp_gross_single: exp_gross_single,
        //     exp_gross: exp_gross
        // })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleBoxChange(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    }

    handleCheckboxChange(event) {
        var isChecked = event.target.checked;
        var item = event.target.value;

        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    }

    handleCheckboxChangeUn(event) {
        var item = event.target.value;
        var isChecked = event.target.checked;

        this.state.checkedItems.delete(item, isChecked)

        this.setState(({ checkedItems: this.state.checkedItems }))
    }

    handleTax1CheckboxChange = () => {
        if (this.state.checkboxtax1 === false)
            this.setState({
                checkboxtax1: true,
            });
        else {
            this.setState({
                checkboxtax1: false,
                taxcappedamount1: '',
            })
        }
    }
    handleTax2CheckboxChange = () => {
        if (this.state.checkboxtax2 === false)
            this.setState({
                checkboxtax2: true,
            });
        else {
            this.setState({
                checkboxtax2: false,
                taxcappedamount2: '',
            })
        }
    }
    handleTax3CheckboxChange = () => {
        if (this.state.checkboxtax3 === false)
            this.setState({
                checkboxtax3: true,
            });
        else {
            this.setState({
                checkboxtax3: false,
                taxcappedamount3: '',
            })
        }
    }
    handleTax4CheckboxChange = () => {
        if (this.state.checkboxtax4 === false)
            this.setState({
                checkboxtax4: true,
            });
        else {
            this.setState({
                checkboxtax4: false,
                taxcappedamount4: '',
            })
        }
    }

    handleClick = () => {
        this.props.toggle();
    };

    deleteTax = (props) => {
        this.props.deleteTax(props)
    };

    deleteExpense = (props) => {
        this.props.deleteExpense(props)
    };

    render() {

        var singleTaxArray = this.props.taxesarray.filter(item => item.show_id === this.props.show_select);
        var singleExpArray = this.props.expensearray.filter(item => item.show_id === this.props.show_select)

        const currency_symbol = getSymbolFromCurrency(this.props.currency.expenses);

        var tax_gross_single;
        if (this.props.taxtype1 === "Flat") {
            tax_gross_single = this.props.taxamount1val;
        } else if (this.props.taxtype1 === "Per Ticket") {
            tax_gross_single = this.props.gross_tickets * this.props.taxamount1val;
        } else if (this.props.taxtype1 === "Percent") {
            tax_gross_single = this.props.gross_pot_val * (this.props.taxamount1val / 100);
        } else if (this.props.taxtype1 === "Percent (divisor)") {
            tax_gross_single = this.props.gross_pot_val - (this.props.gross_pot_val / (1 + (this.props.taxamount1val / 100)))
        };

        var tax_gross = [];
        if (!tax_gross_single) {
            tax_gross = ""
        } else {
            var t = Number(tax_gross_single)
            tax_gross = parseFloat(t).toLocaleString("en-IN", { useGrouping: false, maximumFractionDigits: 2 });
        }

        var exp_gross_single;
        if (this.props.exptype1 === "Flat") {
            exp_gross_single = this.props.expenseamount1val;
        } else if (this.props.exptype1 === "Per Ticket") {
            exp_gross_single = this.props.gross_tickets * this.props.expenseamount1val;
        } else if (this.props.exptype1 === "Percent") {
            exp_gross_single = this.props.gross_pot_val * (this.props.expenseamount1val / 100);
        };

        var exp_gross = [];
        if (!exp_gross_single) {
            exp_gross = ""
        } else {
            exp_gross = Number(exp_gross_single)
        }

        var tax_total = this.props.taxtotal.toLocaleString("en", { useGrouping: false, maximumFractionDigits: 2 });
        var tax_total_single = this.props.taxtotal_single.toLocaleString("en", { useGrouping: false, maximumFractionDigits: 2 });

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <table style={{ marginLeft: 50, width: '86%', marginTop: 35 }}>
                    <thead>
                        <tr>
                            <th>
                                <button style={{ width: 'auto', height: 'auto', fontSize: 12, fontStyle: 'italic', cursor: this.props.edit ? 'pointer' : 'default' }} type="button" onClick={this.props.edit ? this.props.toggleCurrencyDialog : null}>
                                    Tax / Expense Currency: {this.props.currency.expenses}
                                </button>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div style={{ width: '100%', height: '50%' }}>
                    <div>
                        <h1 style={{ marginTop: 20, marginLeft: 50, color: '#000', fontSize: 14 }}>Taxes:</h1>
                    </div>
                    <div style={{ marginLeft: 50, height: 'auto', width: '92%' }} className="form-control">
                        {this.props.show_info.length > 1 ?
                            <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'flex', width: 'auto', marginLeft: 20 }}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon style={{ color: "rgb(1, 22, 48)" }} fontSize="small" />}
                                        checked={this.props.seperate_taxes ? false : true}
                                        disabled={this.props.edit ? false : true}
                                        color="default"
                                        onChange={this.props.seperate_taxes ? () => this.props.toggleSeperateValues("seperate_taxes", "taxesarray") : null}
                                    />
                                    <p style={{ fontSize: 10, color: '#000', marginTop: 15 }}>Combined Taxes</p>
                                </div>
                                <div style={{ display: 'flex', width: 'auto', marginLeft: 20 }}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon style={{ color: "rgb(1, 22, 48)" }} fontSize="small" />}
                                        checked={this.props.seperate_taxes ? true : false}
                                        disabled={this.props.edit ? false : true}
                                        color="default"
                                        onChange={!this.props.seperate_taxes ? () => this.props.toggleSeperateValues("seperate_taxes", "taxesarray") : null}
                                    />
                                    <p style={{ fontSize: 10, color: '#000', marginTop: 15 }}>Separate Taxes</p>
                                </div>
                                {this.props.seperate_taxes ?
                                    <select style={{ width: 150, fontSize: 12, color: '#000', height: 28, marginLeft: 20 }} name="show_select" value={this.props.show_select} onChange={(e) => this.props.toggleDateSelect(e.target.value)}>
                                        {this.props.show_info.map((item, index) => {

                                            var firstItem = '';
                                            var lastItem = '';

                                            if (item.show_times[0]) {
                                                var ft = item.show_times[0].time;

                                                var FI = +ft.substr(0, 2);
                                                var f = (FI % 12) || 12;
                                                f = (f < 10) ? ("0" + f) : f;  // leading 0 at the left for 1 digit hours
                                                var ampmF = FI < 12 ? " AM" : " PM";
                                                firstItem = f + ft.substr(2, 3) + ampmF;

                                                var lt = item.show_times[item.show_times.length - 1].time;
                                                var LI = +lt.substr(0, 2);
                                                var l = (LI % 12) || 12;
                                                l = (l < 10) ? ("0" + l) : l;  // leading 0 at the left for 1 digit hours
                                                var ampmL = LI < 12 ? " AM" : " PM";
                                                lastItem = l + lt.substr(2, 3) + ampmL;
                                            }

                                            if (firstItem === "") {
                                                firstItem = "TBA"
                                            }

                                            var date = moment(item.date).format('M/DD/YYYY');
                                            return (
                                                <option value={item._id}>{date} ({firstItem})</option>
                                            )
                                        })}
                                    </select> : null}
                                {this.props.edit && this.props.seperate_taxes && this.props.tax_pin ?
                                    <button style={{ height: 18, width: 20, borderRadius: 6, marginLeft: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#cdcdcd", padding: 0 }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedScalingArray("tax_pin", "taxesarray") : null}><FaUnlink style={{ padding: 1 }} size="12" color={"#000"} /></button> :
                                    this.props.edit && this.props.seperate_taxes && !this.props.tax_pin ?
                                        <button style={{ height: 18, width: 20, borderRadius: 6, marginLeft: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#111", padding: 0 }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedScalingArray("tax_pin", "taxesarray") : null}><FaLink style={{ padding: 1 }} size="12" color={"#cdcdcd"} /></button> : null}
                            </div> : null}
                        <table style={{ marginLeft: 20, width: '92%' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '18%' }}><label style={{ fontSize: 11, width: '100%' }}>Tax</label></th>
                                    <th style={{ width: '11%' }}><label style={{ fontSize: 11, width: '100%' }}>Type</label></th>
                                    <th style={{ width: '11%' }}><label style={{ fontSize: 11, width: '100%' }}>Amount</label></th>
                                    <th style={{ width: '8%' }}><label style={{ fontSize: 11, width: '100%' }}><div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>Cap</div></label></th>
                                    <th style={{ width: '11%' }}><label style={{ fontSize: 11, width: '100%' }}>Amount</label></th>
                                    <th style={{ width: '11%' }}><label style={{ fontSize: 11, width: '100%' }}>Total</label></th>
                                    <th style={{ width: '18%' }}><label style={{ fontSize: 11, width: '100%' }}>Notes</label></th>
                                    <th style={{ width: '8%' }}> </th>
                                </tr>
                            </thead>
                            {this.props.edit ?
                                <tbody>
                                    <tr>
                                        <td style={{ width: '18%' }}><input style={{ width: '100%', border: '1px solid #000', fontSize: 12 }}
                                            className="form-control-sm"
                                            type="text"
                                            onChange={this.props.onChange}
                                            value={this.props.tax1}
                                            name="tax1"></input></td>
                                        <td style={{ width: '11%' }}>
                                            <select style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} className="form-control-sm" value={this.props.taxtype1} name="taxtype1" onChange={this.props.onChange}>
                                                <option value=" "> </option>
                                                <option value="Flat">Flat</option>
                                                <option value="Percent">Percent (multiplier) *default</option>
                                                <option value="Percent (divisor)">Percent (divisor)</option>
                                                <option value="Per Ticket">Per Ticket</option>
                                            </select>
                                        </td>
                                        <td style={{ width: '11%' }}>
                                            {this.props.taxtype1 === 'Percent' || this.props.taxtype1 === "Percent (divisor)" ?
                                                <NumberFormat style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} className="form-control-sm" value={this.props.taxamount1} thousandSeparator={true} suffix={'%'} onValueChange={this.props.onTax1ValueChange} /> :
                                                <NumberFormat style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} className="form-control-sm" value={this.props.taxamount1} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onTax1ValueChange} />}</td>
                                        <td style={{ width: '8%' }}><div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}><input style={{ border: '1px solid #000', fontSize: 12 }}
                                            type="checkbox"
                                            onChange={this.props.handleTax1CheckboxChange}
                                            checked={this.props.checkboxtax1}
                                            name="checkboxtax1"></input></div></td>
                                        <td style={{ width: '11%' }}>
                                            <NumberFormat className="form-control-sm" style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} disabled={!this.props.checkboxtax1 ? "disabled" : null} value={this.state.taxcappedamount1} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onTaxCap1ValueChange} /></td>
                                        <td style={{ width: '11%' }}>
                                            <NumberFormat className="form-control-sm" style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} disabled="disabled" value={tax_gross} thousandSeparator={true} prefix={currency_symbol} /></td>
                                        <td style={{ width: '18%' }}><input style={{ width: '100%', border: '1px solid #000', fontSize: 12 }}
                                            className="form-control-sm"
                                            type="text"
                                            onChange={this.props.onChange}
                                            value={this.props.taxnotes1}
                                            name="taxnotes1"></input></td>
                                        <td style={{ width: '8%', fontSize: 13 }}><FaPlusSquare style={{ cursor: 'pointer' }} size="20" color="rgb(1, 22, 48)" onClick={this.props.addTax} /></td>
                                    </tr>
                                </tbody> : null}
                        </table>
                        <div style={{ marginLeft: 20, marginTop: 15, minHeight: 100, maxHeight: 'auto', width: '92%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#b3b9c1' }}>
                            {this.props.edit ?
                                <div tabIndex={this.props.edit ? "0" : null} onBlur={this.props.edit ? (e) => this.props.handleClickOutside(e, 'tax_edit') : null}>
                                    <TaxesMovable taxesarray={this.props.taxesarray} updateTax={this.props.updateTax} deleteTax={this.props.deleteTax} edit={this.props.edit} gross_tickets={this.props.gross_tickets} gross_pot_val={this.props.gross_pot_val} gross_pot_val_single={this.props.gross_pot_single} gross_tickets_single={this.props.gross_tickets_single} tax_edit={this.props.tax_edit} tax_val={this.props.tax_val} handleArrayUpdate={this.props.handleArrayUpdate} handleArrayFormattedChange={this.props.handleArrayFormattedChange} currency_symbol={currency_symbol} tax_pin={this.props.tax_pin} seperate_taxes={this.props.seperate_taxes} show_select={this.props.show_select} handlePinnedArray2Change={this.props.handlePinnedArray2Change} droppedCards={this.props.droppedCards} />
                                </div> :
                                this.props.seperate_taxes ?
                                    singleTaxArray.map((item, index) => {
                                        var tax_gross_single;
                                        if (item.type === "Flat") {
                                            tax_gross_single = item.amount;
                                        } else if (item.type === "Per Ticket") {
                                            var t = this.props.gross_tickets_single * item.amount;
                                            if (item.cap === true || item.cap === "true") {
                                                if (t > item.capped_amount) {
                                                    t = item.capped_amount
                                                }
                                            }
                                            tax_gross_single = parseFloat(t).toLocaleString("en-IN", { useGrouping: false, maximumFractionDigits: 2 });
                                        } else if (item.type === "Percent") {
                                            var t = this.props.gross_pot_single * (item.amount / 100);
                                            if (item.cap === true || item.cap === "true") {
                                                if (t > item.capped_amount) {
                                                    t = item.capped_amount
                                                }
                                            }
                                            tax_gross_single = parseFloat(t).toLocaleString("en-IN", { useGrouping: false, maximumFractionDigits: 2 });
                                        } else if (item.type === "Percent (divisor)"){
                                            var t = this.props.gross_pot_single - (this.props.gross_pot_single / (1 + (item.amount / 100)));
                                            if (item.cap === true || item.cap === "true") {
                                                if (t > item.capped_amount) {
                                                    t = item.capped_amount
                                                }
                                            }
                                            tax_gross_single = parseFloat(t).toLocaleString("en-IN", { useGrouping: false, maximumFractionDigits: 2 });
                                        };
                                        return (<div style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%' }}>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '18%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, paddingLeft: 5 }}>{index + 1}. {item.tax}</p></td>
                                                        <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{item.type}</p></td>
                                                        <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} prefix={item.type === 'Flat' || item.type === 'Per Ticket' ? currency_symbol : ''} suffix={item.type === 'Percent' || item.type === 'Percent (divisor)' ? '%' : ''} /></p></td>
                                                        <td style={{ width: '8%' }}><div style={{ color: '#000', fontSize: 12, width: '100%', display: 'flex', alignItems: 'center' }}><input style={{ marginTop: 3, width: '100%' }}
                                                            type="checkbox"
                                                            disabled={true}
                                                            checked={item.cap === 'true' || item.cap === true ? true : false}></input></div></td>
                                                        {item.capped_amount > 0 ?
                                                            <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={item.capped_amount} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td> :
                                                            <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>-</p></td>}
                                                        <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={tax_gross_single} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td>
                                                        <td style={{ width: '18%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{item.notes}</p></td>
                                                        <td style={{ width: '8%' }}> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        )
                                    }) :
                                    this.props.taxesarray.map((item, index) => {
                                        var tax_gross_single;
                                        if (item.type === "Flat") {
                                            tax_gross_single = item.amount;
                                        } else if (item.type === "Per Ticket") {
                                            var t = this.props.gross_tickets * item.amount;
                                            if (item.cap === true || item.cap === "true") {
                                                if (t > item.capped_amount) {
                                                    t = item.capped_amount
                                                }
                                            }
                                            tax_gross_single = parseFloat(t).toLocaleString("en-IN", { useGrouping: false, maximumFractionDigits: 2 });
                                        } else if (item.type === "Percent") {
                                            var t = this.props.gross_pot_val * (item.amount / 100);
                                            if (item.cap === true || item.cap === "true") {
                                                if (t > item.capped_amount) {
                                                    t = item.capped_amount
                                                }
                                            }
                                            tax_gross_single = parseFloat(t).toLocaleString("en-IN", { useGrouping: false, maximumFractionDigits: 2 });
                                        };
                                        return (<div style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%' }}>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '18%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, paddingLeft: 5 }}>{index + 1}. {item.tax}</p></td>
                                                        <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{item.type}</p></td>
                                                        <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} prefix={item.type === 'Flat' || item.type === 'Per Ticket' ? currency_symbol : ''} suffix={item.type === 'Percent' ? '%' : ''} /></p></td>
                                                        <td style={{ width: '8%' }}><div style={{ color: '#000', fontSize: 12, width: '100%', display: 'flex', alignItems: 'center' }}><input style={{ marginTop: 3, width: '100%' }}
                                                            type="checkbox"
                                                            disabled={true}
                                                            checked={item.cap === 'true' || item.cap === true ? true : false}></input></div></td>
                                                        {item.capped_amount > 0 ?
                                                            <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={item.capped_amount} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td> :
                                                            <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>-</p></td>}
                                                        <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={tax_gross_single} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td>
                                                        <td style={{ width: '18%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{item.notes}</p></td>
                                                        <td style={{ width: '8%' }}> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        )
                                    })}
                        </div>
                        {this.props.seperate_taxes && this.props.show_info.length > 1 ?
                            <div style={{ marginLeft: '50%', display: 'flex', width: '100%' }}>
                                <div style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', height: 30, minWidth: '20%', marginTop: 10, borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }}>
                                    <p style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: 10 }}>Select Show Total: </p>
                                    <NumberFormat style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: -5 }} displayType={'text'} value={tax_total_single} thousandSeparator={true} prefix={currency_symbol} />
                                </div>
                                <div style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', height: 30, minWidth: '20%', marginTop: 10, borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', marginLeft: 15 }}>
                                    <p style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: 10 }}>Combined Total: </p>
                                    <NumberFormat style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: -5 }} displayType={'text'} value={tax_total} thousandSeparator={true} prefix={currency_symbol} />
                                </div>
                            </div> :
                            <div style={{ marginLeft: '70%', backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', height: 30, width: '20%', marginTop: 10, borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }}>
                                <p style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: 10 }}>Total: </p>
                                <NumberFormat style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: -5 }} displayType={'text'} value={tax_total} thousandSeparator={true} prefix={currency_symbol} />
                            </div>}
                    </div>
                </div>
                <div style={{ width: '100%', height: '50%' }}>
                    <div>
                        <h1 style={{ marginTop: 20, marginLeft: 50, color: '#000', fontSize: 14 }}>Expenses:</h1>
                    </div>
                    <div style={{ marginLeft: 50, height: 'auto', width: '92%' }} className="form-control">
                        {this.props.show_info.length > 1 ?
                            <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'flex', width: 'auto', marginLeft: 20 }}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon style={{ color: "rgb(1, 22, 48)" }} fontSize="small" />}
                                        checked={this.props.seperate_expenses ? false : true}
                                        disabled={this.props.edit ? false : true}
                                        color="default"
                                        onChange={this.props.seperate_expenses ? () => this.props.toggleSeperateValues("seperate_expenses", "expensearray") : null}
                                    />
                                    <p style={{ fontSize: 10, color: '#000', marginTop: 15 }}>Combined Expenses</p>
                                </div>
                                <div style={{ display: 'flex', width: 'auto', marginLeft: 20 }}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon style={{ color: "rgb(1, 22, 48)" }} fontSize="small" />}
                                        checked={this.props.seperate_expenses ? true : false}
                                        disabled={this.props.edit ? false : true}
                                        color="default"
                                        onChange={!this.props.seperate_expenses ? () => this.props.toggleSeperateValues("seperate_expenses", "expensearray") : null}
                                    />
                                    <p style={{ fontSize: 10, color: '#000', marginTop: 15 }}>Separate Expenses</p>
                                </div>
                                {this.props.seperate_expenses ?
                                    <select style={{ width: 150, fontSize: 12, color: '#000', height: 28, marginLeft: 20 }} name="show_select" value={this.props.show_select} onChange={(e) => this.props.toggleDateSelect(e.target.value)}>
                                        {this.props.show_info.map((item, index) => {

                                            var firstItem = '';
                                            var lastItem = '';

                                            if (item.show_times[0]) {
                                                var ft = item.show_times[0].time;

                                                var FI = +ft.substr(0, 2);
                                                var f = (FI % 12) || 12;
                                                f = (f < 10) ? ("0" + f) : f;  // leading 0 at the left for 1 digit hours
                                                var ampmF = FI < 12 ? " AM" : " PM";
                                                firstItem = f + ft.substr(2, 3) + ampmF;

                                                var lt = item.show_times[item.show_times.length - 1].time;
                                                var LI = +lt.substr(0, 2);
                                                var l = (LI % 12) || 12;
                                                l = (l < 10) ? ("0" + l) : l;  // leading 0 at the left for 1 digit hours
                                                var ampmL = LI < 12 ? " AM" : " PM";
                                                lastItem = l + lt.substr(2, 3) + ampmL;
                                            }

                                            if (firstItem === "") {
                                                firstItem = "TBA"
                                            }

                                            var date = moment(item.date).format('M/DD/YYYY');
                                            return (
                                                <option value={item._id}>{date} ({firstItem})</option>
                                            )
                                        })}
                                    </select> : null}
                                {this.props.edit && this.props.seperate_expenses && this.props.expense_pin ?
                                    <button style={{ height: 18, width: 20, borderRadius: 6, marginLeft: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#cdcdcd", padding: 0 }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedScalingArray("expense_pin", "expensearray") : null}><FaUnlink style={{ padding: 1 }} size="12" color={"#000"} /></button> :
                                    this.props.edit && this.props.seperate_expenses && !this.props.expense_pin ?
                                        <button style={{ height: 18, width: 20, borderRadius: 6, marginLeft: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#111", padding: 0 }} type="button" onClick={this.props.edit ? () => this.props.combinePinnedScalingArray("expense_pin", "expensearray") : null}><FaLink style={{ padding: 1 }} size="12" color={"#cdcdcd"} /></button> : null}
                            </div> : null}
                        <table style={{ marginLeft: 20, width: '92%' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '18%' }}><label style={{ fontSize: 11 }}>Expense</label></th>
                                    <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Type</label></th>
                                    <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Amount</label></th>
                                    <th style={{ width: '8%' }}><label style={{ fontSize: 11, width: '100%' }}><div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>Cap</div></label></th>
                                    <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Amount</label></th>
                                    <th style={{ width: '11%' }}><label style={{ fontSize: 11 }}>Total</label></th>
                                    <th style={{ width: '18%' }}><label style={{ fontSize: 11 }}>Notes</label></th>
                                    <th style={{ width: '8%' }}> </th>
                                </tr>
                            </thead>
                            {this.props.edit ?
                                <tbody>
                                    <tr>
                                        <td style={{ width: '18%', position: 'relative', zIndex: 105 }}>
                                            <ExpensesAutocomplete onChange={this.props.onExpChange} expSelect={this.props.exp1} />
                                        </td>
                                        <td style={{ width: '11%' }}>
                                            <select style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} className="form-control-sm" value={this.props.exptype1} name="exptype1" onChange={this.props.onChange}>
                                                <option value=" "> </option>
                                                <option value="Flat">Flat</option>
                                                <option value="Percent">Percent</option>
                                                <option value="Per Ticket">Per Ticket</option>
                                            </select>
                                        </td>
                                        <td style={{ width: '11%' }}>
                                            {this.props.exptype1 === 'Percent' ?
                                                <NumberFormat className="form-control-sm" style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} value={this.props.expenseamount1} thousandSeparator={true} suffix={'%'} onValueChange={this.props.onExp1ValueChange} /> :
                                                <NumberFormat className="form-control-sm" style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} value={this.props.expenseamount1} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onExp1ValueChange} />}</td>
                                        <td style={{ width: '8%' }}><div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}><input style={{ border: '1px solid #000', fontSize: 12 }}
                                            type="checkbox"
                                            onChange={this.props.handleExp1CheckboxChange}
                                            checked={this.props.checkboxexp1}
                                            name="checkboxtax1"></input></div></td>
                                        <td style={{ width: '11%' }}>
                                            <NumberFormat className="form-control-sm" style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} disabled={!this.props.checkboxexp1 ? "disabled" : null} value={this.props.expcappedamount1} thousandSeparator={true} prefix={currency_symbol} onValueChange={this.props.onExpCap1ValueChange} /></td>
                                        <td style={{ width: '11%' }}>
                                            <NumberFormat className="form-control-sm" style={{ width: '100%', border: '1px solid #000', fontSize: 12 }} disabled="disabled" value={exp_gross} thousandSeparator={true} prefix={currency_symbol} /></td>
                                        <td style={{ width: '18%' }}><input style={{ width: '100%', border: '1px solid #000', fontSize: 12 }}
                                            className="form-control-sm"
                                            type="text"
                                            onChange={this.props.onChange}
                                            value={this.props.expnotes1}
                                            name="expnotes1"></input></td>
                                        <td style={{ width: '8%', fontSize: 13 }}><FaPlusSquare style={{ cursor: 'pointer' }} size="20" color="rgb(1, 22, 48)" onClick={this.props.addExpense} /></td>
                                    </tr>
                                </tbody>
                                : null}
                        </table>
                        <div style={{ marginLeft: 20, marginTop: 15, minHeight: 100, maxHeight: 'auto', width: '92%', borderRadius: 5, boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', backgroundColor: '#b3b9c1' }}>
                            {this.props.edit ?
                                <div tabIndex="0" onBlur={(e) => this.props.handleClickOutside(e, 'expense_edit')}>
                                    <ExpensesMovable expensearray={this.props.expensearray} updateExpense={this.props.updateExpense} deleteExpense={this.deleteExpense} edit={true} gross_tickets={this.props.gross_tickets} gross_pot_val={this.props.gross_pot_val} expense_edit={this.props.expense_edit} expense_val={this.props.expense_val} handleArrayUpdate={this.props.handleArrayUpdate} handleArrayFormattedChange={this.props.handleArrayFormattedChange} currency_symbol={currency_symbol} expense_pin={this.props.expense_pin} seperate_expenses={this.props.seperate_expenses} show_select={this.props.show_select} handlePinnedArray2Change={this.props.handlePinnedArray2Change} droppedCards={this.props.droppedCards} />
                                </div> :
                                this.props.seperate_expenses ?
                                    singleExpArray.map((item, index) => {
                                        console.log(item)
                                        if (item.show_id === this.props.show_select) {
                                            var exp_gross_single;
                                            if (item.type === "Flat") {
                                                exp_gross_single = item.amount;
                                            } else if (item.type === "Per Ticket") {
                                                exp_gross_single = this.props.gross_tickets * item.amount;
                                                exp_gross_single = Math.round((exp_gross_single + Number.EPSILON) * 100) / 100;
                                                if (item.cap === true || item.cap === "true") {
                                                    if (exp_gross_single > item.capped_amount) {
                                                        exp_gross_single = item.capped_amount
                                                    }
                                                }
                                            } else if (item.type === "Percent") {
                                                exp_gross_single = this.props.gross_pot_val * (item.amount / 100);
                                                exp_gross_single = Math.round((exp_gross_single + Number.EPSILON) * 100) / 100;
                                                if (item.cap === true || item.cap === "true") {
                                                    if (exp_gross_single > item.capped_amount) {
                                                        exp_gross_single = item.capped_amount
                                                    }
                                                }
                                            };
                                            return (
                                                <div style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%' }}>
                                                    <table style={{ width: '100%' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: '18%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, paddingLeft: 5 }}>{index + 1}. {item.expense}</p></td>
                                                                <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{item.type}</p></td>
                                                                <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} prefix={item.type === 'Flat' || item.type === 'Per Ticket' ? currency_symbol : ''} suffix={item.type === 'Percent' ? '%' : ''} /></p></td>
                                                                <td style={{ width: '8%' }}><div style={{ color: '#000', fontSize: 12, width: '100%', display: 'flex', alignItems: 'center' }}><input style={{ marginTop: 3, width: '100%' }}
                                                                    type="checkbox"
                                                                    disabled={true}
                                                                    checked={item.cap === 'true' || item.cap === true ? true : false}></input></div></td>
                                                                {item.capped_amount > 0 ?
                                                                    <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={item.capped_amount} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td> :
                                                                    <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>-</p></td>}
                                                                <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={exp_gross_single} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td>
                                                                <td style={{ width: '18%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{item.notes}</p></td>
                                                                <td style={{ width: '8%' }}> </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                        }
                                    }) :
                                    this.props.expensearray.map((item, index) => {
                                        var exp_gross_single;
                                        if (item.type === "Flat") {
                                            exp_gross_single = item.amount;
                                        } else if (item.type === "Per Ticket") {
                                            exp_gross_single = this.props.gross_tickets * item.amount;
                                        } else if (item.type === "Percent") {
                                            exp_gross_single = this.props.gross_pot_val * (item.amount / 100);
                                        };
                                        return (
                                            <div style={{ border: '1px dashed gray', backgroundColor: '#f1f1f1', width: '100%' }}>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '18%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20, paddingLeft: 5 }}>{index + 1}. {item.expense}</p></td>
                                                            <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{item.type}</p></td>
                                                            <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} prefix={item.type === 'Flat' || item.type === 'Per Ticket' ? currency_symbol : ''} suffix={item.type === 'Percent' ? '%' : ''} /></p></td>
                                                            <td style={{ width: '8%' }}><div style={{ color: '#000', fontSize: 12, width: '100%', display: 'flex', alignItems: 'center' }}><input style={{ marginTop: 3, width: '100%' }}
                                                                type="checkbox"
                                                                disabled={true}
                                                                checked={item.cap === 'true' ? true : false}></input></div></td>
                                                            {item.capped_amount > 0 ?
                                                                <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={item.capped_amount} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td> :
                                                                <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>-</p></td>}
                                                            <td style={{ width: '11%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}><NumberFormat value={exp_gross_single} displayType={'text'} thousandSeparator={true} prefix={currency_symbol} /></p></td>
                                                            <td style={{ width: '18%' }}><p style={{ color: '#000', fontSize: 12, width: '100%', paddingTop: 20 }}>{item.notes}</p></td>
                                                            <td style={{ width: '8%' }}> </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })}
                        </div>
                        {this.props.seperate_expenses && this.props.show_info.length > 1 ?
                            <div style={{ marginLeft: '50%', display: 'flex', width: '100%' }}>
                                <div style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', height: 30, minWidth: '20%', marginTop: 10, borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }}>
                                    <p style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: 10 }}>Select Show Total: </p>
                                    <NumberFormat style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: -5 }} displayType={'text'} value={this.props.exptotal_single} thousandSeparator={true} prefix={currency_symbol} />
                                </div>
                                <div style={{ backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', height: 30, minWidth: '20%', marginTop: 10, borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)', marginLeft: 15 }}>
                                    <p style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: 10 }}>Combined Total: </p>
                                    <NumberFormat style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: -5 }} displayType={'text'} value={this.props.exptotal} thousandSeparator={true} prefix={currency_symbol} />
                                </div>
                            </div> :
                            <div style={{ marginLeft: '70%', backgroundColor: 'rgb(1, 22, 48)', color: '#cdcdcd', height: 30, width: '20%', marginTop: 10, borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: '1px 1px 6px rgba(0,0,0,0.5)' }}>
                                <p style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: 10 }}>Total: </p>
                                <NumberFormat style={{ fontSize: 14, color: '#cdcdcd', marginLeft: 10, marginTop: -5 }} displayType={'text'} value={this.props.exptotal} thousandSeparator={true} prefix={currency_symbol} />
                            </div>}
                    </div>
                    <div style={{ width: '100%', height: 100 }}></div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    clients: state.clients,
    items: state.items,
    buyers: state.buyers,
    contacts: state.contacts,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {})(Expenses);