import {
    OFFERDETAIL_LOADING,
    GET_OFFERDETAIL,
} from "../actions/types";

const initialState = {
    offerdetail: [{}],
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case OFFERDETAIL_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_OFFERDETAIL:
            return {
                ...state,
                offerdetail: action.payload,
                loading: false
            };
        default:
            return state;
    }
}