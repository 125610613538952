import React, { useEffect, useState } from "react";
import { AutoSizer, Column, Table } from 'react-virtualized';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CustomChip from '../customStatus';
import { FaExternalLinkSquareAlt, FaCaretDown, FaCaretUp, FaRedoAlt, FaMinusSquare, FaCircle, FaEnvelope } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import { Line, Bar } from 'react-chartjs-2';
 

export default function PaymentDetailsScreen(props) {

    const headerRowRenderer = ({ className, columns, style }) => {
        return (
            <div
                className={className}
                role='row'
                style={style}
            >
                {columns}
            </div>
        )
    }

    const rowStyleFormat = (row) => {
        // if (row.index < 0) return;
        // const list = this.props.offers.filtered_offers;
        // if (this.props.offer_checked.includes(list[row.index]._id)) {
        //     return {
        //         backgroundColor: '#e6f7ff'
        //     };
        // }
        return {
            backgroundColor: null,
        };
    }

    const headerStartRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: 'auto' }}>
                <div style={{ width: '60%' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        checkedIcon={<CheckBoxIcon style={{ backgroundColor: '#333', color: '#cdcdcd' }} fontSize="small" />}
                        color="default"
                        //checked={this.props.offer_all_checked}
                        //value={props.checked}
                        name="emp_all_checked"
                    //onChange={this.props.handleDatabaseAllCheckbox}
                    /></div>
                <div style={{ width: '40%', height: 30 }}>
                    {/* {this.props.offer_sort.sort_cat ?
                        <FaRedoAlt style={{ marginTop: 14 }} size="12" color="#cdcdcd" onClick={() => this.props.clearSorted('offer_sort')} /> :
                        this.props.offer_checked.length > 0 ?
                            <FaMinusSquare style={{ marginTop: 10 }} size="18" color="#cdcdcd" onClick={() => this.props.handleDatabaseClearCheckbox('offer_checked')} />
                            : null} */}
                </div>
            </div>
        )
    }

    const renderColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', fontSize: 10 }}>
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    //checked={this.props.offer_checked.includes(cellData._id) || false}
                    color="default"
                    value={cellData._id}
                    name="emp_checked"
                //onChange={this.props.handleDatabaseCheckbox}
                />
                <span style={{ width: 25, height: '100%' }}>
                    {/* <FaExternalLinkSquareAlt size="16" className="dashboard-offer-icons-exp" onClick={() => props.toggleUserModal(cellData)}/> */}
                    <FaExternalLinkSquareAlt size="16" className="dashboard-offer-icons-exp" />
                </span>
                <span style={{ width: 25, height: '100%' }}>
                    <FaEnvelope size="14" className="dashboard-offer-icons-email" />
                </span>
            </div>
        )
    }

    const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        // if (this.props.offer_sort.sort_cat === dataKey) {
        //     return (
        //         <div style={{ width: '100%' }} onClick={() => this.props.toggleSortOffers(dataKey)}><div style={{ display: 'flex', width: '100%' }}><div style={{ width: dataKey === 'artist_name' || dataKey === 'venue_name' || dataKey === 'buyer_company' ? '86%' : '80%' }}>{label}</div><div style={{ width: '10%' }}>{this.props.offer_sort.sort_direction === 'asc' ? <FaCaretUp size="15" color="#cdcdcd" /> : <FaCaretDown size="15" color="#cdcdcd" />}</div></div></div>
        //     )
        // } else {
        //     return (
        //         <div onClick={() => this.props.toggleSortCatOffers(dataKey)}>{label}</div>
        //     )
        // }
        return (
            <div style={{ width: '100%' }}>
                {label}
            </div>
        )
    }

    const normCellRender = ({ cellData, dataKey }) => {
        return (
            // <MenuProvider key={cellData.id} id="menu_id" component="span" value={cellData.id}>
            <div style={{ width: cellData.width, height: 30, display: 'flex', alignItems: 'center', fontSize: 10 }}>
                {cellData.cell}
            </div>
            // </MenuProvider>
        )
    }

    const statusColumnData = ({ cellData, dataKey }) => {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                <CustomChip
                    size={.65}
                    // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                    color={"primary"}
                    label={cellData.type}
                />
            </div>
        )
    }

    const rowDoubleClick = (row) => {
        if (row.index < 0) return;
        props.toggleModal(row.rowData)
    }

    const list = props.accounting.payment_accounts;

    return (
        <div style={{ width: '100%' }}>
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={height}
                        headerHeight={30}
                        rowHeight={30}
                        overscanRowCount={10}
                        headerRowRenderer={headerRowRenderer}
                        rowCount={list.length}
                        rowGetter={({ index }) => list[index]}
                        rowStyle={rowStyleFormat}
                        //onRowClick={this.rowSelect.bind(this)}
                        onRowDoubleClick={rowDoubleClick}
                    >
                        <Column
                            label=''
                            width={130}
                            dataKey='_id'
                            headerRenderer={headerStartRenderer}
                            cellDataGetter={({ rowData }) => rowData}
                            cellRenderer={
                                renderColumnData
                            }
                        />
                        <Column
                            label='Type'
                            width={130}
                            dataKey='type'
                            headerRenderer={headerRenderer}
                            cellDataGetter={({ rowData }) => rowData}
                            cellRenderer={
                                statusColumnData
                            }
                        />
                        <Column
                            width={180}
                            label='Account Name'
                            headerRenderer={headerRenderer}
                            dataKey='account_name'
                            cellDataGetter={({ rowData }) => { return ({ cell: rowData.account_name, id: rowData._id, width: 130 }) }}
                            // cellDataGetter={({ rowData }) => rowData}
                            cellRenderer={
                                normCellRender
                            }
                        />
                        <Column
                            width={180}
                            label='Bank Name (if app)'
                            headerRenderer={headerRenderer}
                            dataKey='account_details'
                            cellDataGetter={({ rowData }) => { return ({ cell: rowData.type === "Wire Transfer" || rowData.type === "ACH Transfer" ? rowData.bank_name : '', id: rowData._id, width: 130 }) }}
                            // cellDataGetter={({ rowData }) => rowData}
                            cellRenderer={
                                normCellRender
                            }
                        />
                        <Column
                            width={450}
                            label='Application'
                            headerRenderer={headerRenderer}
                            dataKey='application'
                            cellDataGetter={({ rowData }) => { return ({ cell: 'Contracts: ' + rowData.application.contracts + ', Settlement Statements: ' + rowData.application.settlement_statements + ', Agents: All', id: rowData._id, width: 450 }) }}
                            // cellDataGetter={({ rowData }) => rowData}
                            cellRenderer={
                                normCellRender
                            }
                        />
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
}