import axios from 'axios';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
    USER_UPDATED,
} from "./types";

export const handleProfileSaveImg = (file, data) => async (dispatch, getState) => {

    const info = [{
        name: data.name,
        address: data.address,
        contact: data.contact,
        tag: data.tag,
        _p: data._p
    }]

    const json = JSON.stringify(info);
    const blob = new Blob([json], {
        type: 'application/json'
    });

    var formData = new FormData();
    formData.append('file', file);
    formData.set('info', blob);

    return axios.post(`${process.env.REACT_APP_API_CLIENT}/users/update/profile/img`, formData, tokenConfig(getState))
        .then(res => {
            // dispatch({
            //    type: USER_UPDATED,
            //    payload: data
            // })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const handleProfileSave = (data) => async (dispatch, getState) => {

    return axios.post(`${process.env.REACT_APP_API_CLIENT}/users/update/profile`, data, tokenConfig(getState))
        .then(res => {
            dispatch({
               type: USER_UPDATED,
               payload: data
            })
            return res.data
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            return err.response.data
        });
}

export const registerAccount = (data) => async (dispatch, getState) => {
    console.log(data, "DATA")
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/users/register/account`, data, tokenConfig(getState))
        .then(res => {
            console.log(res.data, 'res data')
            dispatch({
               type: USER_UPDATED,
               payload: res.data
            })
            return true
        })
        .catch(err => {
            console.log(err, 'err')
            //dispatch(returnErrors(err.response.data, err.response.status))
            alert(err.response.data)
            return false
        });
}

export const updateUsername = (data) => async (dispatch, getState) => {
    return axios.post(`${process.env.REACT_APP_API_CLIENT}/users/update/username`, data, tokenConfig(getState))
        .then(res => {
            console.log(res.data, 'res data')
            dispatch({
                type: USER_UPDATED,
                payload: res.data
            })
            return true
        })
        .catch(err => {
            //dispatch(returnErrors(err.response.data, err.response.status))
            alert(err.response.data)
            return false
        });
}


