import React, { Component } from "react";
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { addContact, updateContact, contactDupCheck } from '../actions/contactActions';
import { FaInfoCircle, FaTags, FaSave, FaEdit } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import ContactTags from './modal-components/contact-tags.modal-component';
import ContactTagsAlt2 from './modal-components/contact-tags-alt2.modal-component';
import LocationAutocomplete from '../components/autocompletes/location-autocomplete';
import countryDialCode from '../components/global/countries';
import ActiveSwitch from '../components/contacts/active-switch';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert'
import 'react-phone-input-2/lib/style.css'

class ContactModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: true,
            render: 'info',
            id: '',
            header: '',
            firstname: '',
            lastname: '',
            company: '',
            role: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            countryCode: '',
            zipcode: '',
            website: '',
            facebook: '',
            instagram: '',
            email: '',
            officephone: '',
            officephone_cc: '',
            officephone_ext: '',
            officephone_ext_check: false,
            cellphone: '',
            cellphone_cc: '',
            cellphone_ext: '',
            cellphone_ext_check: false,
            homephone: '',
            homephone_cc: '',
            homephone_ext: '',
            homephone_ext_check: false,
            fax: '',
            fax_cc: '',
            fax_ext: '',
            fax_ext_check: false,
            tagarray: [],
            buyercompany: '',
            venuename: '',
            client: '',
            render_type: 'enter',
            status: 'Active',
            notes: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.toggleSub = this.toggleSub.bind(this);
        this.toggleTabInfo = this.toggleTabInfo.bind(this);
        this.toggleTabTags = this.toggleTabTags.bind(this);
        this.handleBuyerChange = this.handleBuyerChange.bind(this);
        this.handleVenueChange = this.handleVenueChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
    }

    componentDidMount() {
        if (this.props.type === 'render') {
            const contact_obj = this.props.render_contact_data;
            var tags = [];
            contact_obj.tags.map(t => {
                let data = [...contact_obj.tags];
                this.props.buyers.buyers.map(b => {
                    if (b._id === t.tag) {
                        var index = data.findIndex(obj => obj.tag === b._id);
                        data[index] = {
                            name: b.company,
                            tag: t.tag,
                            cat: 'Buyer Rolodex',
                            role: t.role,
                            address: b.address,
                            contact: b.contact
                        }
                        tags.push(data[index]);
                    }
                })
                this.props.clients.clients.map(c => {
                    if (c._id === t.tag) {
                        var index2 = data.findIndex(obj => obj.tag === c._id);
                        data[index2] = {
                            name: c.client,
                            tag: t.tag,
                            cat: 'Client Rolodex',
                            role: t.role,
                            address: c.address,
                            agent: c.agent,
                            contact: { main_phone: '-', email: '-', office: { dial: '-', ext: '-' }, cell: { dial: '-', ext: '-' }, fax: '' }
                        };
                        tags.push(data[index2]);
                    }
                })
                this.props.items.items.map(v => {
                    if (v._id === t.tag) {
                        var index = data.findIndex(obj => obj.tag === v._id);
                        data[index] = {
                            name: v.venue_name,
                            tag: t.tag,
                            cat: 'Venue Rolodex',
                            role: t.role,
                            address: v.address,
                            contact: v.contact,
                            capacity: v.capacity
                        };
                        tags.push(data[index]);
                    }
                })
            })

            tags = _.orderBy(tags, [`name`.toLowerCase()], ['asc']);

            this.setState({
                id: contact_obj._id,
                render_type: 'render',
                edit: false,
                header: contact_obj.name.first.toUpperCase() + " " + contact_obj.name.last.toUpperCase(),
                firstname: contact_obj.name.first,
                lastname: contact_obj.name.last,
                company: contact_obj.company,
                role: contact_obj.role,
                address: contact_obj.address.address,
                address2: contact_obj.address.address2,
                city: contact_obj.address.city,
                state: contact_obj.address.state,
                country: contact_obj.address.country,
                zipcode: contact_obj.address.postal_code,
                email: contact_obj.contact.email,
                officephone: contact_obj.contact.office.dial,
                cellphone: contact_obj.contact.cell.dial,
                homephone: contact_obj.contact.home.dial,
                fax: contact_obj.contact.fax.dial,
                website: contact_obj.website,
                facebook: contact_obj.facebook,
                instagram: contact_obj.instagram,
                tagarray: tags,
                status: contact_obj.status,
            })

            const id = contact_obj._id;
            const socket = this.props.auth.socket;
            if (socket) {
                socket.on(`new_contact_tags/${id}`, (contact) => {
                    console.log(contact)
                    //this.props.updateContactSocket(contact);
                    var tags_array = [];


                    contact.tags.map(t => {
                        let data = [...contact.tags];
                        this.props.buyers.buyers.map(b => {
                            if (b._id === t.tag) {
                                var index = data.findIndex(obj => obj.tag === b._id);
                                data[index] = {
                                    name: b.company,
                                    tag: t.tag,
                                    cat: 'Buyer Rolodex',
                                    role: t.role,
                                    address: b.address,
                                    contact: b.contact
                                }
                                tags_array.push(data[index]);
                            }
                        })
                        this.props.clients.clients.map(c => {
                            if (c._id === t.tag) {
                                var index2 = data.findIndex(obj => obj.tag === c._id);
                                data[index2] = {
                                    name: c.client,
                                    tag: t.tag,
                                    cat: 'Client Rolodex',
                                    role: t.role,
                                    address: c.address,
                                    contact: { main_phone: '-', email: '-', office: { dial: '-', ext: '-' }, cell: { dial: '-', ext: '-' }, fax: '' }
                                };
                                tags_array.push(data[index2]);
                            }
                        })
                        this.props.items.items.map(v => {
                            if (v._id === t.tag) {
                                var index = data.findIndex(obj => obj.tag === v._id);
                                data[index] = {
                                    name: v.venue_name,
                                    tag: t.tag,
                                    cat: 'Venue Rolodex',
                                    role: t.role,
                                    address: v.address,
                                    contact: v.contact
                                };
                                tags_array.push(data[index]);
                            }
                        })
                    })

                    console.log(tags_array)
                    //tags_array = [...this.state.tagarray, ...tags_array];
                    tags_array = _.orderBy(tags_array, [`name`.toLowerCase()], ['asc']);

                    this.setState({
                        tagarray: tags_array
                    })
                })
                socket.on(`updated_contact_tags/${id}`, (contact) => {
                    //this.props.updateContactSocket(contact);
                    console.log(contact)
                    var data = this.state.tagarray.filter(cts => cts._id !== contact._id)
                    console.log(data)
                    this.setState({
                        tagarray: data
                    })
                })
            };

        } else {
            this.setState({
                render_type: 'enter',
                header: 'NEW CONTACT'
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleEventPositioned() {
        ReactTooltip.rebuild();
    }

    handleCheckboxChange(event) {
        const name = event.target.name;
        this.setState({
            [name]: !this.state[name]
        });
    }

    async handleCountryChange(event) {
        const val = event.target.value;
        const codes = await countryDialCode(val);
        var officephone = this.state.officephone;
        var cellphone = this.state.cellphone;
        var homephone = this.state.homephone;
        var fax = this.state.fax;
        // if (codes.code) {
        this.setState({
            country: val,
            // countryCode: codes.code,
            // officephone,
            // cellphone,
            // homephone,
            // fax
        });
        if (!officephone) {
            this.setState({
                officephone: '',
                officephone_cc: codes.code
            })
        }
        if (!cellphone) {
            this.setState({
                cellphone: '',
                cellphone_cc: codes.code
            })
        }
        if (!homephone) {
            this.setState({
                homephone: '',
                homephone_cc: codes.code
            })
        }
        if (!fax) {
            this.setState({
                fax: '',
                fax_cc: codes.code
            })
        }
        // } else {
        //     if (!officephone) { officephone = '' }
        //     if (!cellphone) { cellphone = '' }
        //     if (!homephone) { homephone = '' }
        //     if (!fax) { fax = '' }
        //     this.setState({
        //         country: '',
        //         countryCode: '',
        //         officephone,
        //         cellphone,
        //         homephone,
        //         fax
        //     });
        // }
    }

    updatedContactTagsSocket = (contact) => {
        console.log(contact)
        const contact_obj = contact;
        var tags = [];
        contact.tags.map(t => {
            let data = [...contact_obj.tags];
            this.props.buyers.buyers.map(b => {
                if (b._id === t.tag) {
                    var index = data.findIndex(obj => obj.tag === b._id);
                    data[index] = {
                        name: b.company,
                        tag: t.tag,
                        cat: 'Buyer Rolodex',
                        role: t.role,
                        contact: t.contact
                    }
                    tags.push(data[index]);
                }
            })
            this.props.clients.clients.map(c => {
                if (c._id === t.tag) {
                    var index2 = data.findIndex(obj => obj.tag === c._id);
                    data[index2] = {
                        name: c.client,
                        tag: t.tag,
                        cat: 'Client Rolodex',
                        role: t.role,
                        contact: { main_phone: '-', email: '-', office: { dial: '-', ext: '-' }, cell: { dial: '-', ext: '-' }, fax: '' }
                    };
                    tags.push(data[index2]);
                }
            })
            this.props.items.items.map(v => {
                if (v._id === t.tag) {
                    var index = data.findIndex(obj => obj.tag === v._id);
                    data[index] = {
                        name: v.venue_name,
                        tag: t.tag,
                        cat: 'Venue Rolodex',
                        role: t.role,
                        contact: t.contact
                    };
                    tags.push(data[index]);
                }
            })
        })

        console.log(tags)
        this.setState({
            tagarray: tags,
        })
    }

    handleLocation = (results, title) => {
        var street = "";
        var route = "";
        var subpremise = "";
        var locality = "";
        var state = "";
        var country = "";
        var postal_code = "";

        results[0].address_components.map(item => {
            if (item.types.includes('subpremise')) {
                subpremise = item.long_name
            }
            if (item.types.includes('sublocality')) {
                subpremise = item.long_name
            }
            if (item.types.includes('street_number')) {
                street = item.long_name
            }
            if (item.types.includes('route')) {
                route = item.short_name
            }
            if (item.types.includes('locality')) {
                locality = item.long_name
            }
            if (item.types.includes('administrative_area_level_1')) {
                state = item.long_name
            }
            if (item.types.includes('country')) {
                country = item.long_name
            }
            if (item.types.includes('postal_code')) {
                postal_code = item.long_name
            }
        })
        this.setState({
            address: street + ' ' + route,
            address2: subpremise,
            country: country,
            city: locality,
            state: state,
            zipcode: postal_code,
            company: title,
        })
    }

    handleClick = () => {
        this.props.toggle();
    };

    onEdit = () => {
        if (!this.state.edit) {
            this.setState({
                edit: true
            })
            if (this.state.country) {
                const event = { target: { value: this.state.country } };
                this.handleCountryChange(event)
            }
        } else {
            this.setState({
                edit: false
            })
        }
    }

    toggleSub = (e) => {
        console.log(e)
        this.setState({
            render: e,
        })
    }

    toggleTabTags() {
        this.setState({
            render: 'tags'
        })
    }
    toggleTabInfo() {
        this.setState({
            render: 'info'
        })
    }

    toggleStatus = () => {
        if (this.state.status === 'Active') {
            this.setState({
                status: "Inactive"
            })
        } else {
            this.setState({
                status: "Active"
            })
        }
    }

    handleBuyerChange(buyer) {
        if (buyer) {
            this.setState({
                buyercompany: buyer.company,
                tag: buyer._id,
            })
        } else {
            this.setState({
                buyercompany: '',
                tag: '',
            });
        }
    }

    handleVenueChange(venue) {
        if (venue) {
            this.setState({
                venuename: venue.venuename,
                tag: venue._id,
            })
        } else {
            this.setState({
                venuename: '',
                tag: '',
            });
        }
    }

    handleClientChange(client) {
        if (client) {
            this.setState({
                client: client.client,
                tag: client._id,
            })
        } else {
            this.setState({
                client: '',
                tag: '',
            });
        }
    }

    addTag = () => {

        if (this.state.buyercompany !== '') {
            var newValue = {
                tag: this.state.tag,
                category: this.state.tagcat,
                name: this.state.buyercompany
            }
        } else if (this.state.venuename !== '') {
            var newValue = {
                tag: this.state.tag,
                category: this.state.tagcat,
                name: this.state.venuename
            }
        } else if (this.state.client !== '') {
            var newValue = {
                tag: this.state.tag,
                category: this.state.tagcat,
                name: this.state.client
            }
        }

        const newArray = []
        newArray.push(...this.state.tagarray, newValue)

        this.setState({
            tagarray: newArray,
            tag: '',
            tagcat: '',
            buyercompany: '',
            venuename: '',
            client: ''
        })
    }

    updateTag = (props) => {
        //change to delete
        const newArray = this.state.tagarray.filter((e, i) => i !== props);
        this.setState({
            tagarray: newArray
        })
    }

    onSave = async () => {
        if (!this.state.firstname && !this.state.lastname) {
            alert('Please enter a contact first and last name');
        } else {
            const name = {
                first: this.state.firstname,
                last: this.state.lastname
            }
            const dupCheck = name;
            var check = await this.props.contactDupCheck(dupCheck);
            if (check.length > 0 && this.state.render_type === 'enter') {
                this.duplicateDialog(check);
            } else {
                this.finishSave();
            }
        }
    }

    finishSave = async () => {
        const id = this.state.id;
        const address = {
            address: this.state.address,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            postal_code: this.state.zipcode,
        }
        const contactdata = {
            email: this.state.email,
            office: {
                dial: this.state.officephone,
                ext: this.state.officephone_ext
            },
            cell: {
                dial: this.state.cellphone,
                ext: this.state.cellphone_ext
            },
            home: {
                dial: this.state.homephone,
                ext: this.state.homephone_ext
            },
            fax: {
                dial: this.state.fax,
                ext: this.state.fax_ext
            }
        }
        const name = {
            first: this.state.firstname,
            last: this.state.lastname
        }
        const contact = {
            name: name,
            address: address,
            contact: contactdata,
            notes: this.state.notes,
            tags: this.state.tagarray,
            status: this.state.status
        }

        if (this.state.render_type === 'render') {
            const log_status = await this.props.updateContact(contact, id);
            if (log_status.includes('Error:')) {
                this.props.toggleErrorSnackbar(log_status);
            } else {
                this.props.toggleStatusSnackbar('Contact updated successfully');
                this.setState({
                    header: this.state.firstname + ' ' + this.state.lastname,
                    edit: false,
                    render_type: 'render',
                })
            }
        } else {
            const log_status = await this.props.addContact(contact);
            if (log_status.includes('Error:')) {
                this.props.toggleErrorSnackbar(log_status);
            } else {
                this.props.toggleStatusSnackbar('Contact added successfully');
                this.setState({
                    header: this.state.firstname + ' ' + this.state.lastname,
                    edit: false,
                    render_type: 'render',
                    id: log_status,
                })
            }
        }
    }

    duplicateDialog = async (check) => {
        const checkData = await check.map(item => {
            const obj = {
                name: item.name,
                email: item.contact.email,
                office: item.contact.office.dial,
                cell: item.contact.cell.dial
            }
            return obj
        })
        const addDialog = ({ onClose }) => {
            return (
                <div className="react-confirm-alert-z2">
                    <div style={{ width: '100%', height: '100%', padding: 15 }}>
                        <h1 style={{ fontSize: 22, fontWeight: 'bold' }}>Duplicate</h1>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ fontSize: 14, color: '#000', marginBottom: 10 }}>The following contact is alredy entered in the system.  Would you like to continue saving?</p>
                            {checkData.map((item, index) => {
                                return (
                                    <p style={{ fontSize: 12, color: '#000' }} key={index}>{item.name.first} {item.name.last} - {item.email ? item.email + ', ' : null} {item.office ? item.office + '(office) ' : null} {item.cell ? item.cell + '(cell) ' : null}</p>
                                )
                            })}
                        </div>
                        <div style={{ marginTop: 10 }} className="react-confirm-alert-button-group">
                            <button style={{ borderRadius: 10 }} onClick={() => { this.finishSave(); onClose(); }}>Yes</button>
                            <button style={{ borderRadius: 10, marginLeft: 10 }} onClick={onClose}>No</button>
                        </div>
                    </div>
                </div>
            )
        }

        const options = {
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: addDialog
        }

        confirmAlert(options)
    }

    loadTags = () => {

    }

    render() {

        return (
            <div className="mode-t">
                <div className="mode-t-tag">
                    <div style={{ backgroundColor: 'rgb(1, 22, 48)', height: 50 }}>
                        <span className="close" onClick={this.props.close}>
                            <div style={{ color: '#cdcdcd' }}>
                                &times;
                            </div>
                        </span>
                        {this.state.edit ?
                            <span className="close" onClick={this.onSave}>
                                <FaSave size="20" color="#cdcdcd" />
                            </span> :
                            <span className="close" onClick={this.onEdit}>
                                <FaEdit size="18" color="#cdcdcd" />
                            </span>}
                        {this.state.status === "Active" ?
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>ACTIVE</p>
                                <div style={{ backgroundColor: 'green', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span> :
                            <span style={{ display: 'flex', marginTop: 22 }} className="close" onClick={this.state.edit ? this.toggleStatus : null}>
                                <p style={{ fontSize: 10, color: '#cdcdcd' }}>INACTIVE</p>
                                <div style={{ backgroundColor: 'red', height: 10, width: 10, borderRadius: 50, marginLeft: 1 }} />
                            </span>}
                        <h1 style={{ color: '#cdcdcd', marginLeft: 60, fontFamily: 'Helvetica', paddingTop: 12, fontSize: 22, fontWeight: 'bold' }}>{this.state.header}</h1>
                    </div>
                    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                        <div style={{ width: '5%', backgroundColor: 'rgb(1, 22, 48)', height: '91.8%' }}>
                            {/* {this.props.tooltip ? */}
                            <ReactTooltip multiline={true} clickable={true} delayHide={500} effect='solid' border={true} >
                            </ReactTooltip>
                            <div style={{ height: 50, backgroundColor: this.state.render === 'info' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render === 'info' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('info')} data-tip={'Contact Info'} onMouseEnter={this.handleEventPositioned}>
                                <FaInfoCircle style={{ fontSize: 20 }} />
                            </div>
                            {this.state.render_type !== 'enter' ?
                                <div style={{ height: 50, backgroundColor: this.state.render !== 'info' ? '#fff' : 'rgb(1, 22, 48)', color: this.state.render !== 'info' ? 'rgb(1, 22, 48)' : '#fff', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.toggleSub('tags')} data-tip={'Tags'} onMouseEnter={this.handleEventPositioned}>
                                    <FaTags style={{ fontSize: 20 }} />
                                </div> : null}
                        </div>

                        {this.state.render === 'info' ?
                            <div style={{ display: 'flex', width: '100%', height: 500, overflowY: 'scroll' }}>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, marginTop: 30 }} className="form-group">
                                        <label style={{ width: '15%', fontSize: 14 }}>Name:</label>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '65%', marginLeft: 10 }}>
                                            <input style={{ width: '50%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" placeholder="First Name" name="firstname" value={this.state.firstname} onChange={this.handleChange.bind(this)} />
                                            <input style={{ marginLeft: 10, width: '50%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" placeholder="Last Name" name="lastname" value={this.state.lastname} onChange={this.handleChange.bind(this)} />
                                        </div>
                                    </div>
                                    {this.state.render_type === 'enter' ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60, width: '90%', marginTop: 15 }} className="form-group">
                                            <LocationAutocomplete handleLocation={this.handleLocation} Gkey={this.props.auth.user.api.google} />
                                        </div> : null}
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Address (Main):</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="address" value={this.state.address} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}> </label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="address2" value={this.state.address2} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Country:</label>
                                        <select style={{ marginLeft: 10, width: '55%', fontSize: 12, height: 28, border: '.4px solid #000', color: '#000' }} className="form-control-sm" disabled={this.state.edit ? false : true} name="country" value={this.state.country} onChange={this.handleCountryChange.bind(this)}>
                                            <option value=""> </option>
                                            <option value="United States">United States</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Afganistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bonaire">Bonaire</option>
                                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canary Islands">Canary Islands</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Channel Islands">Channel Islands</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos Island">Cocos Island</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote DIvoire">Cote DIvoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curaco">Curacao</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="East Timor">East Timor</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands">Falkland Islands</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Ter">French Southern Ter</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Great Britain">Great Britain</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Hawaii">Hawaii</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="India">India</option>
                                            <option value="Iran">Iran</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea North">Korea North</option>
                                            <option value="Korea Sout">Korea South</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Laos">Laos</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libya">Libya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macau">Macau</option>
                                            <option value="Macedonia">Macedonia</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Midway Islands">Midway Islands</option>
                                            <option value="Moldova">Moldova</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Nambia">Nambia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherland Antilles">Netherland Antilles</option>
                                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                            <option value="Nevis">Nevis</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau Island">Palau Island</option>
                                            <option value="Palestine">Palestine</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Phillipines">Philippines</option>
                                            <option value="Pitcairn Island">Pitcairn Island</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                                            <option value="Republic of Serbia">Republic of Serbia</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russia">Russia</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="St Barthelemy">St Barthelemy</option>
                                            <option value="St Eustatius">St Eustatius</option>
                                            <option value="St Helena">St Helena</option>
                                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                            <option value="St Lucia">St Lucia</option>
                                            <option value="St Maarten">St Maarten</option>
                                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                            <option value="Saipan">Saipan</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Samoa American">Samoa American</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syria">Syria</option>
                                            <option value="Tahiti">Tahiti</option>
                                            <option value="Taiwan">Taiwan</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania">Tanzania</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Erimates">United Arab Emirates</option>
                                            <option value="Uraguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Vatican City State">Vatican City State</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                            <option value="Wake Island">Wake Island</option>
                                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zaire">Zaire</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            <label style={{ width: '25%', fontSize: 14 }}>City:</label>
                                            <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="city" value={this.state.city} onChange={this.handleChange.bind(this)} />
                                        </div> : null}
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            {this.state.country === 'United States' ?
                                                <label style={{ width: '25%', fontSize: 14 }}>State:</label> :
                                                <label style={{ width: '25%', fontSize: 14 }}>Province:</label>}
                                            {this.state.country === 'United States' ?
                                                <select style={{ marginLeft: 10, width: '55%', border: '.4px solid #000', fontSize: 12, color: '#000' }} className="form-control-sm" disabled={this.state.edit ? false : true} value={this.state.state} name="state" onChange={this.handleChange.bind(this)}>
                                                    <option value=""> </option>
                                                    <option value="Alabama">Alabama</option>
                                                    <option value="Alaska">Alaska</option>
                                                    <option value="Arizona">Arizona</option>
                                                    <option value="Arkansas">Arkansas</option>
                                                    <option value="California">California</option>
                                                    <option value="Colorado">Colorado</option>
                                                    <option value="Connecticut">Connecticut</option>
                                                    <option value="Delaware">Delaware</option>
                                                    <option value="District Of Columbia">District Of Columbia</option>
                                                    <option value="Florida">Florida</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Idaho">Idaho</option>
                                                    <option value="Illinois">Illinois</option>
                                                    <option value="Indiana">Indiana</option>
                                                    <option value="Iowa">Iowa</option>
                                                    <option value="Kansas">Kansas</option>
                                                    <option value="Kentucky">Kentucky</option>
                                                    <option value="Louisiana">Louisiana</option>
                                                    <option value="Maine">Maine</option>
                                                    <option value="Maryland">Maryland</option>
                                                    <option value="Massachusetts">Massachusetts</option>
                                                    <option value="Michigan">Michigan</option>
                                                    <option value="Minnesota">Minnesota</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="Missouri">Missouri</option>
                                                    <option value="Montana">Montana</option>
                                                    <option value="Nebraska">Nebraska</option>
                                                    <option value="Nevada">Nevada</option>
                                                    <option value="New Hampshire">New Hampshire</option>
                                                    <option value="New Jersey">New Jersey</option>
                                                    <option value="New Mexico">New Mexico</option>
                                                    <option value="New York">New York</option>
                                                    <option value="North Carolina">North Carolina</option>
                                                    <option value="North Dakota">North Dakota</option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Oklahoma">Oklahoma</option>
                                                    <option value="Oregon">Oregon</option>
                                                    <option value="Pennsylvania">Pennsylvania</option>
                                                    <option value="Rhode Island">Rhode Island</option>
                                                    <option value="South Carolina">South Carolina</option>
                                                    <option value="South Dakota">South Dakota</option>
                                                    <option value="Tennessee">Tennessee</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="Utah">Utah</option>
                                                    <option value="Vermont">Vermont</option>
                                                    <option value="Virginia">Virginia</option>
                                                    <option value="Washington">Washington</option>
                                                    <option value="West Virginia">West Virginia</option>
                                                    <option value="Wisconsin">Wisconsin</option>
                                                    <option value="Wyoming">Wyoming</option>
                                                </select> :
                                                <input style={{ marginLeft: 10, width: '55%', border: '.4px solid #000', fontSize: 12, color: '#000' }} className="form-control-sm" type="text" disabled={this.state.edit ? false : true} value={this.state.state} name="state" onChange={this.handleChange.bind(this)} />}
                                        </div> : null}
                                    {this.state.country ?
                                        <div style={{ display: 'flex', height: 40, alignItems: 'center', marginLeft: 60 }} className="form-group">
                                            <label style={{ width: '25%', fontSize: 14 }}>Postal Code:</label>
                                            <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="zipcode" value={this.state.zipcode} onChange={this.handleChange.bind(this)} />
                                        </div> : null}
                                </div>
                                <div style={{ width: '47.5%' }}>
                                    <div style={{ display: 'flex', height: 40, alignItems: 'center', marginTop: 30 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Email Address:</label>
                                        <input style={{ marginLeft: 10, width: '55%', fontSize: 12, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} type="text" name="email" value={this.state.email} onChange={this.handleChange.bind(this)} />
                                    </div>
                                    <div style={{ display: 'flex', height: !this.state.officephone_ext_check ? 40 : 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Office Phone:</label>
                                        <div style={{ width: '70%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ width: '100%', height: 30, display: 'flex', alignItems: 'center' }}>
                                                <PhoneInput
                                                    containerStyle={{ marginLeft: 10, width: '80%', borderRadius: 5, border: '.4px solid #000' }}
                                                    inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                                    className="form-control-sm"
                                                    country={this.state.officephone_cc ? this.state.officephone_cc : ''}
                                                    disableCountryGuess={!this.state.officephone_cc ? false : true}
                                                    disabled={!this.state.edit ? true : false}
                                                    placeholder={''}
                                                    renderStringAsFlag={!this.state.edit && !this.state.countryCode ? true : false}
                                                    value={this.state.officephone}
                                                    onChange={officephone => this.setState({ officephone })} />
                                                {this.state.edit || this.state.officephone_ext_check ?
                                                    <p style={{ fontSize: 10, marginLeft: 5, marginTop: 22 }}>Ext. <input type="checkbox" name="officephone_ext_check" select={this.state.officephone_ext_check} onChange={this.handleCheckboxChange} /></p> : null}
                                            </div>
                                            {this.state.officephone_ext_check ?
                                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} className="form-group">
                                                    {/* <label style={{ width: '15%', fontSize: 14 }}>Ext:</label> */}
                                                    <div style={{ width: '13%', marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <p style={{ fontSize: 12, marginTop: 10 }}>Ext </p>
                                                    </div>
                                                    <input style={{ width: '67%', borderRadius: 5, border: '.4px solid #000', height: 28, fontSize: 12 }} type="number" name="officephone_ext" value={this.state.officephone_ext} onChange={this.handleChange} />
                                                </div> : null}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', height: !this.state.cellphone_ext_check ? 40 : 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Cell Phone:</label>
                                        <div style={{ width: '70%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ width: '100%', height: 30, display: 'flex', alignItems: 'center' }}>
                                                <PhoneInput
                                                    containerStyle={{ marginLeft: 10, width: '80%', borderRadius: 5, border: '.4px solid #000' }}
                                                    inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                                    className="form-control-sm"
                                                    country={this.state.cellphone_cc ? this.state.cellphone_cc : ''}
                                                    disableCountryGuess={!this.state.cellphone_cc ? false : true}
                                                    disabled={!this.state.edit ? true : false}
                                                    placeholder={''}
                                                    renderStringAsFlag={!this.state.edit && !this.state.countryCode ? true : false}
                                                    value={this.state.cellphone}
                                                    onChange={cellphone => this.setState({ cellphone })} />
                                                {this.state.edit || this.state.cellphone_ext_check ?
                                                    <p style={{ fontSize: 10, marginLeft: 5, marginTop: 22 }}>Ext. <input type="checkbox" name="cellphone_ext_check" select={this.state.cellphone_ext_check} onChange={this.handleCheckboxChange} /></p> : null}
                                            </div>
                                            {this.state.cellphone_ext_check ?
                                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} className="form-group">
                                                    {/* <label style={{ width: '15%', fontSize: 14 }}>Ext:</label> */}
                                                    <div style={{ width: '13%', marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <p style={{ fontSize: 12, marginTop: 10 }}>Ext </p>
                                                    </div>
                                                    <input style={{ width: '67%', borderRadius: 5, border: '.4px solid #000', height: 28, fontSize: 12 }} type="number" name="cellphone_ext" value={this.state.cellphone_ext} onChange={this.handleChange} />
                                                </div> : null}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', height: !this.state.homephone_ext_check ? 40 : 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Home Phone:</label>
                                        <div style={{ width: '70%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ width: '100%', height: 30, display: 'flex', alignItems: 'center' }}>
                                                <PhoneInput
                                                    containerStyle={{ marginLeft: 10, width: '80%', borderRadius: 5, border: '.4px solid #000' }}
                                                    inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                                    className="form-control-sm"
                                                    country={this.state.homephone_cc ? this.state.homephone_cc : ''}
                                                    disableCountryGuess={!this.state.homephone_cc ? false : true}
                                                    disabled={!this.state.edit ? true : false}
                                                    placeholder={''}
                                                    renderStringAsFlag={!this.state.edit && !this.state.countryCode ? true : false}
                                                    value={this.state.homephone}
                                                    onChange={homephone => this.setState({ homephone })} />
                                                {this.state.edit || this.state.homephone_ext_check ?
                                                    <p style={{ fontSize: 10, marginLeft: 5, marginTop: 22 }}>Ext. <input type="checkbox" name="homephone_ext_check" select={this.state.homephone_ext_check} onChange={this.handleCheckboxChange} /></p> : null}
                                            </div>
                                            {this.state.homephone_ext_check ?
                                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} className="form-group">
                                                    {/* <label style={{ width: '15%', fontSize: 14 }}>Ext:</label> */}
                                                    <div style={{ width: '13%', marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <p style={{ fontSize: 12, marginTop: 10 }}>Ext </p>
                                                    </div>
                                                    <input style={{ width: '67%', borderRadius: 5, border: '.4px solid #000', height: 28, fontSize: 12 }} type="number" name="homephone_ext" value={this.state.homephone_ext} onChange={this.handleChange} />
                                                </div> : null}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', height: !this.state.fax_ext_check ? 40 : 60 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Fax Number:</label>
                                        <div style={{ width: '70%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ width: '100%', height: 30, display: 'flex', alignItems: 'center' }}>
                                                <PhoneInput
                                                    containerStyle={{ marginLeft: 10, width: '80%', borderRadius: 5, border: '.4px solid #000' }}
                                                    inputStyle={{ width: '100%', height: 28, borderRadius: 5, fontSize: 12 }}
                                                    className="form-control-sm"
                                                    country={this.state.fax_cc ? this.state.fax_cc : ''}
                                                    disableCountryGuess={!this.state.fax_cc ? false : true}
                                                    disabled={!this.state.edit ? true : false}
                                                    placeholder={''}
                                                    renderStringAsFlag={!this.state.edit && !this.state.countryCode ? true : false}
                                                    value={this.state.fax}
                                                    onChange={fax => this.setState({ fax })} />
                                                {this.state.edit || this.state.fax_ext_check ?
                                                    <p style={{ fontSize: 10, marginLeft: 5, marginTop: 22 }}>Ext. <input type="checkbox" name="fax_ext_check" select={this.state.fax_ext_check} onChange={this.handleCheckboxChange} /></p> : null}
                                            </div>
                                            {this.state.fax_ext_check ?
                                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} className="form-group">
                                                    {/* <label style={{ width: '15%', fontSize: 14 }}>Ext:</label> */}
                                                    <div style={{ width: '13%', marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <p style={{ fontSize: 12, marginTop: 10 }}>Ext </p>
                                                    </div>
                                                    <input style={{ width: '67%', borderRadius: 5, border: '.4px solid #000', height: 28, fontSize: 12 }} type="number" name="fax_ext" value={this.state.fax_ext} onChange={this.handleChange} />
                                                </div> : null}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }} className="form-group">
                                        <label style={{ width: '25%', fontSize: 14 }}>Notes:</label>
                                        <textarea style={{ marginLeft: 10, width: '55%', fontSize: 12, minHeight: 100, maxHeight: 100, border: '.4px solid #000' }} className="form-control-sm" disabled={this.state.edit ? false : true} name="notes" value={this.state.notes} onChange={this.handleChange.bind(this)} />
                                    </div>
                                </div>
                            </div> :

                            this.state.render === "tags" ?
                                <ContactTags toggleSub={this.toggleSub} onChange={this.handleChange} onBuyerChange={this.handleBuyerChange} onVenueChange={this.handleVenueChange} onClientChange={this.handleClientChange} addTag={this.addTag} updateTag={this.updateTag} {...this.state} /> :
                                <ContactTagsAlt2 toggleSub={this.toggleSub} {...this.state} />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    contacts: state.contacts,
    buyers: state.buyers,
    items: state.items,
    clients: state.clients,
    auth: state.auth
});

export default connect(mapStateToProps, { addContact, updateContact, contactDupCheck })(ContactModal);