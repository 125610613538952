import {
    SOCIALS_LOADING,
    GET_INSTAGRAM,
} from "../actions/types";

const initialState = {
    instagram: { data: "", loading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SOCIALS_LOADING:
            return {
                ...state,
                instagram: { data: "", loading: true },
            };
        case GET_INSTAGRAM:
            return {
                ...state,
                instagram: { data: action.payload, loading: false },
            };
        default:
            return state;
    }
}